import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {Trans} from 'react-i18next'
import {Link, withRouter} from 'react-router-dom'
import {flowRight as compose, get, includes, map, filter, size, isEmpty, startsWith} from 'lodash'

import {styled} from '@mui/system'
import withStyles from '@mui/styles/withStyles'
import {ExpandMore as ArrowDown, Menu as MenuIcon} from '@mui/icons-material'
import {
  AppBar, Badge, Box, Button, IconButton, Grid, Hidden, Menu, MenuItem, Toolbar, Typography
} from '@mui/material'

import {kycStatuses, languages} from '@bdswiss/common-enums'

import config from '../../config'
import {withMediaQuery} from '../Common'
import TopLevelRoutes from '../../routes'
import messages from '../../assets/messages'
import BentoMenu from '../BentoMenu/BentoMenu'
import ThemeSelection from '../ThemeSelection'
import {isMobile} from '../../common/utils/browser'
import {isRtlLang} from '../../common/utils/uioptions'
import AppContext from '../Common/contexts/AppContext'
import ForexTradeNow from '../Accounts/Forex/ForexTradeNow'
import DownloadMobileApp from '../DownloadMobileApp/DownloadMobileApp'
import {logEventCustomParams} from '../../common/utils/firebaseEvents'
import {isDarkTheme, getCurrentTheme} from '../../common/utils/general'
import {
  getItem, getSessInfoFromLocalStorage, isAffiliatesAccount, isBitnukAccount, isCentAccount, isForexAccount,
  isIntroducingBrokerAccount, isMAMAccount, isLdWalletAccount, disableDepositWithSpecificCountries, isZuluAccount,
  isCopyTradingAccount
} from '../../common/utils'

import FlagIcon from '../FlagIcon'
import {ReactComponent as Missing} from '../../assets/images/unverified.svg'
import {ReactComponent as VerifiedIcon} from '../../assets/images/verified.svg'
import {ReactComponent as AccountCircle} from '../../assets/images/profile-icon.svg'
import {ReactComponent as NotificationsIcon} from '../../assets/images/notifications-icon.svg'

const ButtonWrap = styled(Grid)(({theme}) => ({
  '&.MuiGrid-item': {
    margin: '0 12px',
  },
}))

const IconButtonWrap = styled(Grid)(({theme, elem}) => ({
  backgroundColor: 'transparent',
  '& .MuiGrid-item': {
    padding: 0,
  },
  '&.active': {
    borderRadius: isMobile() ? 8 : 50,
    background: theme.palette.mode === 'dark' ? '#1F1F1F' : '#EBEBEB',
  },
  '& > button': {
    margin: 0,
    padding: isMobile() ? 6 : 12,
    borderRadius: isMobile() ? 8 : 50,
    '&:hover': {
      background: theme.palette.mode === 'dark' ? '#1F1F1F' : '#EBEBEB',
    },
    ...(isMobile()
      ? {
        zIndex: elem ? 1400 : 1200,
        backgroundColor: elem ? (theme.palette.mode === 'dark' ? '#1F1F1F' : '#EBEBEB') : 'transparent',
      } : {
        cursor: 'pointer',
      }),
  },
  '& svg': {
    fill: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.dark,
  },
}))

const DepositButton = styled(Button)(({theme}) => ({
  height: 40,
  padding: 12,
  color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
  backgroundColor: theme.palette.mode === 'dark' ? '#303033' : '#E3E6ED',
  boxShadow: 'none',
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
    padding: 4,
    minHeight: 40,
    height: 'inherit',
  }
}))

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
    width: '100%',
    // [theme.direction === 'rtl' ? 'paddingLeft' : 'paddingRight']: 2,
    [theme.breakpoints.down('lg')]: {
      paddingTop: 0,
      marginBottom: 0,
    },
  },
  rootRegistration: {
    backgroundColor: 'transparent',
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    margin: '0 auto',
    paddingTop: 32,
  },
  menuIcon: {
    marginRight: '14px'
  },
  logo: {
    maxWidth: 180,
    maxHeight: 28,
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      maxWidth: 156,
      height: 24,
      paddingRight: 12,
    },
  },
  fullWidth: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
    },
  },
  link: {
    paddingLeft: 25,
    fontSize: 14,
    color: theme.palette.grey.color
  },
  arrowLink:{
    verticalAlign: 'middle',
    padding: 1,
    marginTop: '-3px',
    color: theme.palette.secondary.dark,
  },
  pointer:{
    cursor: 'pointer'
  },
  headerPadding: {
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 16,
      paddingRight: 6,
    },
  },
  toolbarWithDepositButton: {
    paddingRight: 0,
    paddingLeft: 0,
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  box: {
    minWidth: 400
  },
  menu: {
    borderRadius: 4,
    marginTop: 10,
    minWidth: 320,
    maxWidth: 480,
    boxShadow: theme.boxShadowStyle,
    backgroundColor: theme.palette.extralightgreyBackground.color,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px'
  },
  firstMenuItem: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 16,
    paddingBottom: 16,
  },
  nameText:{
    lineHeight: '24px',
    fontSize: 14,
  },
  statusIcon: {
    'vertical-align':'text-top',
    'font-size':'15px',
    'margin-right':'4px',
  },
  pendingIcon: {
    'color': theme.palette.yellow.color,
    fontSize: 14,
  },
  doneIcon: {
    'color': theme.palette.green.color,
    fontSize: 14,
  },
  verificationLink:{
    'color': theme.palette.yellow.color,
    fontSize: 14,
  },
  menuItemContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 16,
    paddingBottom:16,
    height: 48,
    'align-self': 'stretch',
  },
  logoutItem: {
    color: theme.palette.red.color,
    fontSize: 14,
    letterSpacing: '0.25px',
    lineHeight: '20px',
    fontStyle: 'mormal',
    fontWeight: 400,
  },
  dot: {
    '& .MuiBadge-dot': {
      height: 4,
      minWidth: 4,
      borderRadius: 10
    }
  },
  logoAlign: {
    marginLeft: 24,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  languageItem: {
    color: theme.palette.mode === 'dark' ?'#999999' : '#858585',
    fontSize: 14,
    letterSpacing: '0.25px',
    lineHeight: '20px',
    fontStyle: 'mormal',
    fontWeight: 400,
  },
  textHeaderMenu: {
    fontSize: 14,
    letterSpacing: '0.25px',
    lineHeight: '20px',
    fontStyle: 'mormal',
    fontWeight: 400,
  },
})

class Header extends React.Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    this.handleToggleModalState = this.handleToggleModalState.bind(this)
    this.state = {
      anchorBentoMenuBtn: null,
      anchorProfileBtn: null,
      anchorWebtraderBtn: null,
      anchorDownloadMobileAppBtn: null,
    }
  }

  handleToggleModalState = (anchor, event) => {
    this.setState({[anchor]: event?.currentTarget})
  }

  accountVerification(kycStatus, classes) {
    let verificationIcon
    if (kycStatus === kycStatuses.approved.value) {
      verificationIcon = <React.Fragment>
        <Typography variant="caption" className={classes.doneIcon}>
          <VerifiedIcon className={classNames({[classes.statusIcon]: true, [classes.doneIcon]: true})}/>
          <Trans {...messages.verified} />
        </Typography>
      </React.Fragment>
    } else {
      verificationIcon = <React.Fragment>
        <Typography variant="caption" className={classes.pendingIcon}>
          <Missing className={classNames({[classes.statusIcon]: true, [classes.pendingIcon]: true})}/>
          <Link to={'/accounts/verification'} className={classes.verificationLink}><Trans {...messages.unVerified} /> </Link>
        </Typography>
      </React.Fragment>
    }
    return verificationIcon
  }

  depositIntent() {
    const {history} = this.props
    logEventCustomParams('depositIntent', {source: 'Dashboard', userId: get(this.context, 'clientId')})
    return history.push('/transactions/deposit')
  }

  logoClick() {
    const {history, linkTo} = this.props
    const onClickLink = linkTo || '/dashboard'
    logEventCustomParams('clickedLogo', {
      userId: get(this.context, 'clientId'),
    })
    return history.push(onClickLink)
  }

  closeLanguage() {
    logEventCustomParams('changeLanguageIntent', {userId: get(this.context, 'clientId')})
    if (this.props.location.pathname==='/change-language' || this.props.location.pathname==='/language') {
      if (get(this.props.history.location, 'state.prevPath') === '/login') {
        this.props.history.push('/accounts')
      } else {
        this.props.history.goBack()
      }
    }
  }

  logOut() {
    const {viewer} = this.props
    const clientId = get(viewer, 'id')
    const params = {
      reason: `logout clicked - ${clientId}`,
      userId: clientId,
    }
    logEventCustomParams('logout', params)
    return this.context.logout()
  }

  render() {
    const {accounts, companyObject} = this.context
    const {anchorBentoMenuBtn, anchorProfileBtn, anchorDownloadMobileAppBtn} = this.state
    const {classes, handleSidebarToggle, outer, customBackground, location: {pathname}, history, viewer, widthDown} = this.props
    const {logoFileName, logoWhiteFileName, name: logoAlt, common:{signupGroupMetrics},
      featuresConfig: {bentoMenu}} = config
    let locale = (this.context.clientType) ? this.context.locale:getItem('locale','en')

    if (!includes(map(filter(languages, (lang) => !lang.disabled && lang.client), 'value'), locale)) locale = 'en'

    const themePreference = getCurrentTheme(this.context)

    let logo = ''
    const logoAsset = !customBackground && isDarkTheme(themePreference) ? logoWhiteFileName : logoFileName
    try {
      logo = require(`../../assets/images/${logoAsset}`)
    } catch (e)
    {
      //
    }
    const isMobileView = this.props.location.pathname==='/more'
    if (isMobileView && !isMobile()) history.push('/')
    const topLevelRoutes = get(TopLevelRoutes(), 'props.children')
    const excludeRoutes = topLevelRoutes && filter(map(topLevelRoutes, (child) => get(child, 'props.path') || get(child, 'props.to')), (route) => route && !includes(['/'], route))
    const isRegistrationPath = includes(pathname, '/register')
    const isLoginPath = includes(pathname, '/login')

    const tradingAccounts = filter(accounts, (account) => !isAffiliatesAccount(account) && !isIntroducingBrokerAccount(account)  && (!isZuluAccount(account) || !isCopyTradingAccount(account))
      && !isMAMAccount(account) && (isForexAccount(account) || (isCentAccount(account) || includes(account?.accountSubType, 'cent')))
      && !account.isArchived && !account.isViewOnly && !isBitnukAccount(account)
    )

    const hasLdWalletAccount = filter(accounts, (account) => isLdWalletAccount(account)).length

    const showDepositButton =
      disableDepositWithSpecificCountries(viewer)
        ? false
        : (!outer && !isMobile() && !isMobileView && !includes(excludeRoutes, pathname) && !!(tradingAccounts.length || hasLdWalletAccount ))

    const showTradeNowButton = showDepositButton && !startsWith(pathname, '/accounts/')
    const isLoggedIn = !isEmpty(viewer)

    return (
      <AppBar position='static'
        elevation={0}
        color='default'
        className={customBackground || (isRegistrationPath && !signupGroupMetrics) ? classes.rootRegistration : classes.root}
      >
        <Toolbar className={classNames(isRegistrationPath && !signupGroupMetrics && classes.headerPadding, (showDepositButton || isLoggedIn) && classes.toolbarWithDepositButton)}>
          <Hidden mdUp>
            {!isMobileView && getSessInfoFromLocalStorage() && !includes(excludeRoutes, pathname) && (
              <IconButton
                aria-label='Open drawer'
                className={classes.menuIcon}
                onClick={(e) => handleSidebarToggle && handleSidebarToggle(e)}
              >
                <MenuIcon />
              </IconButton>
            )}
          </Hidden>

          <Grid container alignItems='center' justifyContent='space-between'>
            <Grid item className={classes.logoAlign}>
              <Grid container alignItems='center'>
                <Grid item style={{display: 'flex', alignItems: 'center'}}>
                  <img src={logo} alt={logoAlt} className={classNames(classes.logo,classes.pointer)} onClick={()=> this.logoClick()} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container alignItems='center'>
                {!showDepositButton && (isLoginPath || isRegistrationPath) && (
                  <Grid item>
                    <Link to={{pathname: (outer)?'/language':'/change-language', state: {prevLocation: this.props.location}}}
                      className={classes.fullWidth} onClick={() => {this.closeLanguage()}}>
                      <Grid lang={locale}>
                        <FlagIcon
                          code={languages[locale].flag}
                          className={classes.link}
                          size={'lg'}
                        />
                        <Box
                          component='span'
                          className={classes.arrowLink}
                          sx={{
                            fontSize: 14,
                          }}
                        >
                          {widthDown ? ' ' : languages[locale].native}
                        </Box>
                        <ArrowDown className={classes.arrowLink} />
                      </Grid>
                    </Link>
                  </Grid>
                )}

                {showDepositButton && (
                  <ButtonWrap item>
                    <DepositButton
                      onClick={() => this.depositIntent()}
                      variant='contained'
                      size='large'
                      color='secondary'
                      style={{padding: '8px 24px'}}
                    >
                      <Trans {...messages.deposit} />
                    </DepositButton>
                  </ButtonWrap>
                )}

                {showTradeNowButton && (
                  <ButtonWrap item>
                    <ForexTradeNow accounts={accounts} type='button' testSwissMarkets={true}/>
                  </ButtonWrap>
                )}

                {(!isMobile() && isLoggedIn) && (
                  <IconButtonWrap item>
                    <DownloadMobileApp
                      anchorEl={anchorDownloadMobileAppBtn}
                      onToggleModalState={this.handleToggleModalState}/>
                  </IconButtonWrap>
                )}

                {(!isMobile() && isLoggedIn) && (
                  <IconButtonWrap item >
                    <IconButton onClick={()=> history.push('/notifications')}>
                      <Badge
                        className={classes.dot}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        variant={size(get(viewer, 'pendingNotices')) > 0 ? 'dot' : 'standard'}
                        color="error"
                      >
                        <NotificationsIcon />
                      </Badge>
                    </IconButton>
                  </IconButtonWrap>
                )}

                {(!isMobile() && isLoggedIn) && (
                  <IconButtonWrap item >
                    <IconButton edge="start" color='secondary' onMouseOver={(event) => this.handleToggleModalState('anchorProfileBtn', event)}>
                      <AccountCircle className={classes.checkIcon}/>
                    </IconButton>
                    <Menu
                      id="primary-menu"
                      elevation={0}
                      anchorEl={anchorProfileBtn}
                      disableAutoFocusItem
                      open={Boolean(anchorProfileBtn)}
                      onClose={() => this.handleToggleModalState('anchorProfileBtn', null)}
                      onMouseLeave={() => this.handleToggleModalState('anchorProfileBtn', null)}
                      autoFocus={false}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: includes(isRtlLang, locale) ? 'left' : 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: includes(isRtlLang, locale) ? 'left' : 'right',
                      }}
                      marginThreshold={2}
                      slotProps={{paper: {className: classes.menu}}}
                      dir={includes(isRtlLang, locale) ? 'rtl' : 'ltr'}
                    >
                      <MenuItem dir={includes(isRtlLang, locale) ? 'rtl' : 'ltr'} className={classes.firstMenuItem} onClick={() => this.handleToggleModalState('anchorProfileBtn', null)}>
                        <Grid container direction="column">
                          <Typography variant="body2" className={classes.nameText}>{[get(viewer, 'firstName'), get(viewer, 'lastName')].join(' ')}</Typography>
                          <Typography variant="body2" className={classes.nameText}>
                            <Trans {...messages.profileInformation}
                              values={{
                                company: companyObject?.brandLabel,
                                clientId: get(viewer, 'id'),
                              }} />
                          </Typography>
                          {this.accountVerification(get(viewer, 'kycStatus'), classes)}
                        </Grid>
                      </MenuItem>
                      <MenuItem onClick={() => this.handleToggleModalState('anchorProfileBtn', null)} className={classes.menuItemContent}>
                        <Link to={{pathname: (outer)?'/language':'/change-language', state: {prevLocation: this.props.location}}}
                          className={classes.fullWidth} onClick={() => {this.closeLanguage()}}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item>
                              <Typography variant="body2" className={classes.textHeaderMenu}><Trans {...messages.language} /></Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="body2" className={classes.languageItem}>{widthDown ? ' ' : languages[locale].native}</Typography>
                            </Grid>
                          </Grid>
                        </Link>
                      </MenuItem>
                      <MenuItem className={classNames(classes.menuItemContent, classes.fullWidth)}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid item>
                            <Typography variant="body2" className={classes.textHeaderMenu}><Trans {...messages.darkTheme} /></Typography>
                          </Grid>
                          <Grid item>
                            <ThemeSelection />
                          </Grid>
                        </Grid>
                      </MenuItem>
                      <MenuItem  to={'/logout'} onClick={() => this.logOut()} className={classNames(classes.menuItemContent, classes.fullWidth)}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid item>
                            <Typography variant="body2" className={classes.logoutItem}><Trans {...messages.logout} /></Typography>
                          </Grid>
                        </Grid>
                      </MenuItem>
                    </Menu>
                  </IconButtonWrap>
                )}

                {!outer && (bentoMenu && bentoMenu.enabled) && (
                  <IconButtonWrap item elem={anchorBentoMenuBtn} className={Boolean(anchorBentoMenuBtn) ? 'active' : ''}>
                    <BentoMenu
                      anchorEl={anchorBentoMenuBtn}
                      onToggleModalState={this.handleToggleModalState}
                      viewer={viewer}/>
                  </IconButtonWrap>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    )
  }
}

Header.propTypes = {
  handleSidebarToggle: PropTypes.func,
}

export default compose(
  withRouter,
  withStyles(styles, {withTheme: true}),
  withMediaQuery('md')
)(Header)
