import React, {Component} from 'react'
import {map, orderBy, flowRight as compose, isEmpty, get, find, has, min, includes} from 'lodash'
import PropTypes from 'prop-types'
import {Trans, withNamespaces} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import Avatar from '@mui/material/Avatar'
import ListItem from '@mui/material/ListItem'
import {currencies, depositVendors} from '@bdswiss/common-enums'
import Typography from '@mui/material/Typography'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import messages from '../../../assets/messages'
import {getFormattedAmount, isDarkTheme} from '../../../common/utils'
import PageSubTitle from '../../Common/PageSubTitle'
import {isMobile} from '../../../common/utils/browser'
import {isCentAccount} from '../../../common/utils/accounts'
import NotificationBar from '../../Common/NotificationBar'
import AppContext from '../../Common/contexts/AppContext'
import Images from '../../Common/Images'

const style = (theme) => ({
  highlightedText: {
    color: theme.palette.primary.main,
    cursor:'pointer'
  },
  logoAvatar: {
    width: 60,
    height: 60,
    borderRadius: '5%',
    background: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      width: 40,
      height: 40,
    },
  },
  logoImg: {
    width: '100%',
    height: 'unset'
  },
  listItemRoot: {
    paddingTop: '0px',
    paddingBottom: '0px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
      '& .MuiTypography-body2': {
        fontSize: '16px',
        fontWeight: '400',
        letterSpacing: '0.5px',
      },
    },
    '&:hover': {
      borderBottom: 'none',
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 4px 10px rgba(0, 122, 255, 0.25)',
      '& .MuiTypography-body1, .MuiTypography-caption': {
        color: theme.palette.primary.main,
        fontWeight: '500',
      },
    },
  },
  listItemRootDark: {
    paddingTop: '0px',
    paddingBottom: '0px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
      '& .MuiTypography-body2': {
        fontSize: '16px',
        fontWeight: '400',
        letterSpacing: '0.5px',
      },
    },
    '&:hover': {
      boxShadow: '0px 4px 10px rgba(255, 255, 255, 0.25)',
      '& .MuiTypography-body1, .MuiTypography-caption': {
        color: '#FFFFFF',
        fontWeight: '500',
      },
    },
  },
  listItemDivider: {
    borderBottom: `1px solid ${theme.palette.lightgrey.color}`,
  },
  listItemDividerDark: {
    borderBottom: `1px solid ${theme.palette.lightgrey.color}`,
  },
  listItemGutter: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
  },
  listItemText: {
    padding: '0 16px',
  },
  goToPortal: {
    display: 'flex',
    'vertical-align': 'middle',
    'justify-content': 'center',
    'align-items': 'center',
    [theme.breakpoints.down('sm')]: {
      'justify-content': 'start',
    },
  },
  processingLabel: {
    display: 'flex',
    fontSize: '10px',
  },
  processingLabelMobile: {
    display: 'flex',
    color: '#666666',
    fontSize: '13px',
    fontWeight: '400',
    paddingLeft: '2px',
    paddingRight: '2px',
  }
})

export class PaymentMethodSelection extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    this.state = {
      idHovered: '',
    }
  }

  nFormatter(num) {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M'
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K'
    }
    return num
  }

  renderPaymentMethodDetails(i, paymentOption) {
    const {themePreference} = this.context
    const {classes, account} = this.props

    const processingTime = get(paymentOption, 'processingTime')
    const processingTimeValue = get(paymentOption, 'processingTimeValue')
    const transactionFee = get(paymentOption, 'transactionFee')
    const minDepositAmounts = get(paymentOption, 'minDepositAmounts')
    const maxDepositAmounts = get(paymentOption, 'maxDepositAmounts')
    const currencyMinDeposit = find(minDepositAmounts, {currency: account.currency})
    const paymentOptionMaxDeposit = find(maxDepositAmounts, {currency: account.currency})
    const accountMaxDeposit = get(account, 'maximumDepositAllowed')

    if (isEmpty(processingTime) && isEmpty(transactionFee) && isEmpty(minDepositAmounts)
      && isEmpty(maxDepositAmounts) && isEmpty(accountMaxDeposit)) return

    let processingTimeLabel, minMaxDepositLabel, transactionFeesLabel, minPaymentOptionAmount, maxPaymentOptionAmount

    if (processingTimeValue === '1' && processingTime === 'days') {
      processingTimeLabel = <div> {processingTimeValue} <Trans {...messages.day} /> </div>
    } else if (processingTimeValue === '1' && processingTime === 'hours') {
      processingTimeLabel = <div> {processingTimeValue} <Trans {...messages.hour} /> </div>
    } else if (processingTimeValue === '1' && processingTime === 'minutes') {
      processingTimeLabel = <div> {processingTimeValue} <Trans {...messages.minute} /> </div>
    } else {
      processingTimeLabel = <div> {processingTimeValue} <Trans {...messages[processingTime]} /> </div>
    }

    if (currencyMinDeposit) {
      if (has(currencies[account.currency], 'baseCurrency')) {
        minPaymentOptionAmount = currencyMinDeposit.amount / currencies[account.currency].baseCurrencyRate
      } else {
        minPaymentOptionAmount = currencyMinDeposit.amount
      }
    } else if (get(account, 'minimumPartialDeposit') > 0) {
      if (has(currencies[account.currency], 'baseCurrency')) {
        minPaymentOptionAmount = get(account, 'minimumPartialDeposit') / currencies[account.currency].baseCurrencyRate
      } else {
        minPaymentOptionAmount = get(account, 'minimumPartialDeposit')
      }
    } else {
      if (has(currencies[account.currency], 'baseCurrency')) {
        minPaymentOptionAmount = get(account, 'minimumDeposit') / currencies[account.currency].baseCurrencyRate
      } else {
        minPaymentOptionAmount = get(account, 'minimumDeposit')
      }
    }

    minPaymentOptionAmount = this.nFormatter(Number(minPaymentOptionAmount))

    if (paymentOptionMaxDeposit && accountMaxDeposit) {
      if (has(currencies[account.currency], 'baseCurrency')) {
        maxPaymentOptionAmount = min([Number(paymentOptionMaxDeposit.amount), Number(accountMaxDeposit.amount)]) / currencies[account.currency].baseCurrencyRate
      } else {
        maxPaymentOptionAmount = min([Number(paymentOptionMaxDeposit.amount), Number(accountMaxDeposit.amount)])
      }
    } else if (paymentOptionMaxDeposit && !accountMaxDeposit) {
      if (has(currencies[account.currency], 'baseCurrency')) {
        maxPaymentOptionAmount = paymentOptionMaxDeposit.amount / currencies[account.currency].baseCurrencyRate
      } else {
        maxPaymentOptionAmount = paymentOptionMaxDeposit.amount
      }
    } else if (!paymentOptionMaxDeposit && accountMaxDeposit) {
      if (has(currencies[account.currency], 'baseCurrency')) {
        maxPaymentOptionAmount = accountMaxDeposit.amount / currencies[account.currency].baseCurrencyRate
      } else {
        maxPaymentOptionAmount = accountMaxDeposit.amount
      }
    }

    const maxDepositFormat = !paymentOptionMaxDeposit && !accountMaxDeposit
      ? ''
      : this.nFormatter(Number(maxPaymentOptionAmount))

    let currencyLabel = account.currency

    if (has(currencies[account.currency], 'baseCurrency')) {
      currencyLabel = currencies[account.currency].baseCurrency
    } else {
      currencyLabel = account.currency
    }

    if (!paymentOptionMaxDeposit && !accountMaxDeposit) {
      minMaxDepositLabel = <div> <Trans {...messages.min} /> {minPaymentOptionAmount} {currencyLabel} </div>
    } else if ((paymentOptionMaxDeposit && paymentOptionMaxDeposit.amount > 0)
      || (accountMaxDeposit && accountMaxDeposit.amount > 0)) {
      minMaxDepositLabel = `${minPaymentOptionAmount} - ${maxDepositFormat} ${currencyLabel}`
    }

    if (transactionFee === 0) {
      transactionFeesLabel = <div> <Trans {...messages.noFees} /> </div>
    } else if (transactionFee > 0) {
      transactionFeesLabel = `${transactionFee} ${currencyLabel}`
    }

    return !isMobile()
      ? <Grid container>
        {((processingTime === 'instant') || (processingTime && !isEmpty(processingTimeValue))) && <Grid item md={4}>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <img width='23px' height='23px'
                src={this.state.idHovered === i && !isDarkTheme(themePreference)
                  ? Images['ProcessingTimeBlue.svg']
                  : isDarkTheme(themePreference)
                    ? Images['ProcessingTimeDark.svg']
                    : Images['ProcessingTime.svg']
                }
                alt='Icon'
              />
            </Grid>
            <Grid item>
              <Typography className={classes.processingLabel}> {processingTimeLabel} </Typography>
            </Grid>
          </Grid>
        </Grid>}
        {minMaxDepositLabel && <Grid item md={5}>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <img width='23px' height='23px'
                src={this.state.idHovered === i && !isDarkTheme(themePreference)
                  ? Images['CashbagsBlue.svg']
                  : isDarkTheme(themePreference)
                    ? Images['CashbagsDark.svg']
                    : Images['Cashbags.svg']
                }
                alt='Icon'
              />
            </Grid>
            <Grid item>
              <Typography className={classes.processingLabel}> {minMaxDepositLabel} </Typography>
            </Grid>
          </Grid>
        </Grid>}
        {transactionFee !== null && <Grid item md={3}>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <img width='20px' height='20px'
                src={this.state.idHovered === i && !isDarkTheme(themePreference)
                  ? Images['CalculatorBlue.svg']
                  : isDarkTheme(themePreference)
                    ? Images['CalculatorDark.svg']
                    : Images['Calculator.svg']
                }
                alt='Icon'
              />
            </Grid>
            <Grid item>
              <Typography className={classes.processingLabel}> {transactionFeesLabel} </Typography>
            </Grid>
          </Grid>
        </Grid>}
      </Grid>
      : <Grid container>
        {((processingTime === 'instant') || (processingTime && !isEmpty(processingTimeValue))) && <Grid item>
          <Typography variant='caption' className={classes.processingLabelMobile}>
            {processingTimeLabel}
          </Typography>
        </Grid>}
        {processingTime && <Grid item>
          <Typography variant='caption' className={classes.processingLabelMobile}> · </Typography>
        </Grid>}
        {minMaxDepositLabel && <Grid item>
          <Typography variant='caption' className={classes.processingLabelMobile}> {minMaxDepositLabel} </Typography>
        </Grid>}
        {transactionFee !== null && <Grid item>
          <Typography variant='caption' className={classes.processingLabelMobile}> · </Typography>
        </Grid>}
        {transactionFee !== null && <Grid item>
          <Typography variant='caption' className={classes.processingLabelMobile}>
            {transactionFeesLabel}
          </Typography>
        </Grid>}
      </Grid>
  }

  render() {
    const {themePreference} = this.context
    const {amount, account, locale, countryPaymentOptions, classes, onPaymentMethodSelected,
      selectedPaymentMethodId, goBack} = this.props
    const formattedAmount = (isCentAccount(account) || includes(account?.accountSubType, 'cent'))
      ? getFormattedAmount({amount: amount / currencies.CUD.baseCurrencyRate, currency: currencies.CUD.baseCurrency, locale})
      : getFormattedAmount({amount, currency: account.currency, locale})
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <PageSubTitle>
            <Trans
              {...messages.selectDepositMethodForAmount}
              values={{amount: formattedAmount}}
              components={[<span onClick={() => goBack()} className={classes.highlightedText}>amount</span>]}
            />
          </PageSubTitle>
          {(isCentAccount(account) || includes(account?.accountSubType, 'cent')) && <NotificationBar
            noMargin
            status="info"
            title={<Trans {...messages.receiveInCUD} values={{amount: Number(amount).toLocaleString()}} />}
          />}
        </Grid>
        <Grid item xs={12}>
          {this.props.children}
          <List>
            {
              map(orderBy(countryPaymentOptions, (opt) => opt.rank, ['desc']), (opt, i) => {
                const {paymentOption} = opt
                let paymentOptionName
                if (isEmpty(paymentOption.localizationKey)) {
                  paymentOptionName = paymentOption.name || depositVendors[paymentOption.provider].label
                } else {
                  if (depositVendors[paymentOption.provider].label === depositVendors[paymentOption.provider].localization.t(locale)) {
                    paymentOptionName = paymentOption.name || depositVendors[paymentOption.provider].label
                  } else {
                    paymentOptionName = depositVendors[paymentOption.provider].localization.t(locale)
                  }
                }

                return (
                  <React.Fragment key={i}>
                    <ListItem divider key={i} button onClick={(e) => onPaymentMethodSelected(opt.id)}
                      classes={{
                        gutters: classes.listItemGutter,
                        root: isDarkTheme(themePreference) ? classes.listItemRootDark : classes.listItemRoot,
                        divider: isDarkTheme(themePreference) ? classes.listItemDividerDark : classes.listItemDivider,
                      }}
                      onMouseEnter={() => {
                        this.setState({
                          idHovered: i,
                        })
                      }}
                      onMouseLeave={() => {
                        this.setState({
                          idHovered: '',
                        })
                      }}>
                      <Grid container justifyContent="space-between" spacing={1}>
                        <Grid item xs={2} className={classes.goToPortal}>
                          <Grid container>
                            <Grid item xs={12}>
                              <Avatar
                                alt={paymentOption.name}
                                src={paymentOption.logoUrl}
                                className={classes.logoAvatar}
                                slotProps={{
                                  img: {className: classes.logoImg}
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={9}>
                          <Grid container direction="row"
                            justifyContent="flex-start"
                            alignItems="center">
                            <Grid item xs={12}>
                              <Typography variant='body2'>{paymentOptionName}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                              {this.renderPaymentMethodDetails(i, paymentOption)}
                            </Grid>
                          </Grid>
                        </Grid>
                        {!isMobile() && <Grid item xs={1} className={classes.goToPortal}>
                          <Grid container>
                            <Grid item xs={12}>
                              { opt.id === selectedPaymentMethodId &&
                                <ListItemSecondaryAction>
                                  <img src={isDarkTheme(themePreference) ? Images['RoundBlueCheckmarkDark.png'] : Images['RoundBlueCheckmark.png']} alt='test' />
                                </ListItemSecondaryAction>
                              }
                            </Grid>
                          </Grid>
                        </Grid>}
                      </Grid>
                    </ListItem>
                  </React.Fragment>
                )
              })
            }
          </List>
        </Grid>
      </Grid>
    )
  }
}

PaymentMethodSelection.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  locale: PropTypes.string.isRequired,
  countryPaymentOptions: PropTypes.array.isRequired
}

export default compose(
  withStyles(style),
  withNamespaces(),
)(PaymentMethodSelection)
