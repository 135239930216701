import React from 'react'
import {get, first, filter, includes, isEmpty, flowRight as compose, toUpper} from 'lodash'
import {graphql} from 'react-apollo'
import {withNamespaces, Trans} from 'react-i18next'
import Grid from '@mui/material/Grid'
import messages from '../../assets/messages'
import PageTitle from '../Common/PageTitle'
import {ACCOUNTS_QUERY, REFERRAL_DETAILS_QUERY, CLIENT_DATA_QUERY, CLIENT_REFER_CAMPAIGN_QUERY} from '../../graphql/queries'
import {ACCEPT_REVEAL_REFER_A_FRIEND_MUTATION, IB_INTERESTED_MUTATION} from '../../graphql/mutations'
import AppContext from '../Common/contexts/AppContext'
import withStyles from '@mui/styles/withStyles'
import UiNotification from '../Common/UiNotification'
import {isDemoAccount, isForexAccount, hasWalletProductAccount, isCentAccount} from '../../common/utils/accounts'
import ReferralsTable from './ReferralsTable'
import FinancialInfo from './FinancialInfo'
import ReferralDetails from './ReferralDetails'
import Instructions from './Instructions'
import config from '../../config'
import {getPlatform} from '../../common/utils'
import {Loading} from '../Common/Loading'
import {kycStatuses, referralStatuses, tradingStatuses} from '@bdswiss/common-enums'
import {withRouter} from 'react-router-dom'
import ClaimRewardPopupMessage from '../ReferAndEarn/claimRewards'
import PageBody from '../Common/PageBody'
import BecomeIB from './BecomeIB'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import {logEventCustomParams} from '../../common/utils/firebaseEvents'

const styles = theme => ({
  notificationDiv: {
    margin: '12px 12px 30px',
    borderRadius: 5,
    padding:10,
    backgroundColor: theme.palette.lightyellow.color
  },
})
class ReferAndEarn extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {
      showMessage: true,
      showClaim: false,
      showReferralLimit: true,
      account: '',
      loading: false,
      status: '',
      submitMessageError: '',
      referrals: [],
      rewards: 0,
      rewardDetails: {},
      closeReveal: false,
      loadingReveal: false,
      statusReveal: '',
      clickRevealed: false,
    }
  }

  componentDidMount() {
    const {accounts} = this.props
    this.setState({account: get(first(accounts), 'id')})
  }

  handleAccountChange(accountId) {
    this.setState({account: accountId})
  }

  becomeAnIb() {
    logEventCustomParams('referAndEarnBecomeIbClicked')
    const {history} = this.props
    const {allowIbAccount} = config
    const variables = {sendEmail: !allowIbAccount}
    this.props.ibInterested({variables}).then(() => {
      if (allowIbAccount) {
        history.push( {
          pathname: '/accounts/add-account',
          state: {accountType: 'IntroducingBrokerBDSwissVanuatuAccount'}
        })
      } else {
        this.setState({showReferralLimit: false})
        this.context.showNotification({
          type: 'document-upload',
          status: 'success',
          title: 'ibInterestSubmitted',
          content: this.props.t(messages.finalisePartnerRegistration.i18nKey, messages.finalisePartnerRegistration.defaults),
          buttonMessage: this.props.t(messages.close.i18nKey, messages.close.defaults),
          onClose: () => history.push('/referAFriend'),
        })
      }
    }).catch({})
  }

  renderLimitReachedNotification() {
    const {t, location} = this.props
    const {showReferralLimit} = this.state

    return <UiNotification
      open={showReferralLimit}
      onClose={() => this.setState({showReferralLimit: false})}
      title={'referralLimit'}
      type={'limit'}
      status={'failure'}
      children={<React.Fragment><span>{t(messages.referralLimitReached.i18nKey, messages.referralLimitReached.defaults)}</span>
        <span>{t(messages.referralLimitText.i18nKey, messages.referralLimitText.defaults)}</span></React.Fragment>}
      buttonMessage={t(messages.becomeAnIbButton.i18nKey, messages.becomeAnIbButton.defaults)}
      buttonAction={() => this.becomeAnIb()}
      linkAction={get(location, 'pathname')}
      linkActionMessage={t(messages.viewMyPerformance.i18nKey, messages.viewMyPerformance.defaults)}
    />
  }

  claimReward() {
    const {account} = this.state
    const {preselectedAccount} = this.props
    this.setState({status: '', loading: true})
    const variables = {
      accountId: account || preselectedAccount,
    }

    this.props.claimReward({variables}).then((succ) => {
      this.setState({loading: false, status: 'success', submitMessageError: '', showClaim: false, rewardDetails: get(succ, 'data.claimReward')})
    })
      .catch((err) => {
        this.setState({loading: false, status: 'failure', submitMessageError: get(err, 'graphQLErrors[0].message') || err.message})
      })
  }

  revealLink() {
    this.setState({loadingReveal: true, statusReveal: '', clickRevealed: true})
    this.props.acceptRevealReferAFriend().then(() => {
      this.setState({statusReveal: 'success', loadingReveal: false, closeReveal: true})
    }).catch(() => {
      this.setState({statusReveal: 'failure', loadingReveal: false})
    })
  }

  render() {
    const {history, t, accounts, referralDetails, referralLoading, accountsLoading, preselectedAccount, linkedPartnerClientId, verified, referAndEarn, reveleadLink, viewer, referCampaign, nonDemoforexAccounts, classes} = this.props
    const {showMessage, showClaim, rewardDetails, closeReveal, statusReveal, loadingReveal, clickRevealed} = this.state
    const {locale, companyObject} = this.context
    const {common:{rafFirstVersionEndDate, hideReferCountries}, featuresConfig:{referAFriend}} = config

    if (accountsLoading || referralLoading) return  <Loading />

    const countryDisallowNew = includes(hideReferCountries, toUpper(get(viewer, 'address.country')))

    const referrals = get(rewardDetails, 'referrals') || get(referralDetails, 'referrals')

    if ((countryDisallowNew && !get(referralDetails, 'link')) || isEmpty(referrals)) history.push('/')

    const activated = filter(referrals, (r) => r.status === referralStatuses.activated.value)
    const claimed = filter(referrals, (r) => r.status === referralStatuses.claimed.value)
    const awards = referralDetails && JSON.parse(referralDetails.awards)
    const conditions = referralDetails && JSON.parse(referralDetails.conditions)
    const rewardsObj = (!isEmpty(rewardDetails) && rewardDetails) || (referralDetails && referralDetails.rewardsObj)
    const isIbOrAffiliate = hasWalletProductAccount(accounts) || linkedPartnerClientId

    const limitReached = referrals && (activated.length + claimed.length) >= conditions.maxReferrals
    const pendingRewards = get(rewardsObj, 'pendingRewards', 0)
    if (!referAndEarn || (isIbOrAffiliate && !get(referralDetails, 'rewardsObj.pendingRewards', 0))
      || get(referAFriend, 'disabled')) history.push('/')

    const oldReferrer = get(referralDetails, 'oldReferrer')
    const today = moment()
    const notificationReferEndDate = moment(rafFirstVersionEndDate)
    const notificationnReferDateCheck = oldReferrer && today.isAfter(notificationReferEndDate)
    const notificationRefer = (notificationnReferDateCheck) ? 'secondNotificationRefer' : 'firstNotificationRefer'

    return <React.Fragment>
      <PageTitle hideArrow title={t(messages.referAFriend.i18nKey, messages.referAFriend.defaults)} onBack={() => history.push('/accounts')} />
      <PageBody removePadding>
        <Grid container spacing={0}>
          {!(!showMessage || limitReached) && !oldReferrer && <Grid item xs={12}>
            <Instructions onClick={() => this.setState({showMessage: false})} referCampaign={referCampaign}/>
          </Grid>}
          {!oldReferrer && <Grid item xs={12}>
            <ReferralDetails referralDetails={referralDetails} awards={awards} limitReached={limitReached}
              isIbOrAffiliate={isIbOrAffiliate} isVerified={verified} reveleadLink={clickRevealed || reveleadLink}
              revealLink={() => this.revealLink()} closeReveal={closeReveal} statusReveal={statusReveal} loadingReveal={loadingReveal}
              referCampaign={referCampaign}/>
          </Grid>}
          {oldReferrer && <Grid item xs={12} className={classes.notificationDiv}>
            <Typography variant="body1"> <Trans {...messages[notificationRefer]} values={{company: get(companyObject, 'brandLabel')}}/> </Typography>
          </Grid>}
          <BecomeIB becomeAnIb={() => this.becomeAnIb()}/>
          {!notificationnReferDateCheck && <Grid item xs={12}>
            <FinancialInfo referrals={referrals} referralDetails={referralDetails} rewardsObj={rewardsObj}
              pendingRewards={pendingRewards}/>
          </Grid>}
          {!notificationnReferDateCheck && <Grid item xs={12}>
            <ReferralsTable referralDetails={referralDetails} referrals={referrals} onClaim={() => this.setState({showClaim: true})}
              pendingRewards={pendingRewards} locale={locale} isVerified={verified} reveleadLink={clickRevealed || reveleadLink}/>
          </Grid>}
          {!notificationnReferDateCheck && <ClaimRewardPopupMessage
            open={showClaim}
            rewardsObj={rewardsObj}
            accounts={filter(nonDemoforexAccounts, (account) => !isDemoAccount(account) && (isForexAccount(account) || isCentAccount(account)))}
            preselectedAccount={preselectedAccount}
            rewardType='referrer'
            onClose={() => this.setState({showClaim: false})}
            viewer={viewer}
            referCampaign={referCampaign}
          />}
          {limitReached && !isIbOrAffiliate && !notificationnReferDateCheck && this.renderLimitReachedNotification()}
        </Grid>
      </PageBody>
    </React.Fragment>
  }
}

export default compose(
  withNamespaces(),
  withStyles(styles),
  withRouter,
  graphql(ACCOUNTS_QUERY, {
    options: () => ({fetchPolicy: 'network-only'}),
    props: ({data: {error, loading}, data}) => {
      const accounts = get(data, 'viewer.accounts')
      const nonDemoforexAccounts = filter(accounts, (account) => !isDemoAccount(account) && (isForexAccount(account) || isCentAccount(account)))
      return {
        accountsError: error,
        accountsLoading: loading,
        accounts,
        nonDemoforexAccounts,
        preselectedAccount: get(nonDemoforexAccounts, '[0].id'),
      }
    }}),
  graphql(REFERRAL_DETAILS_QUERY, {
    options: () => ({
      variables: {platform: JSON.stringify(getPlatform())},
      fetchPolicy: 'network-only',
    }),
    props: ({data: {error, loading}, data}) => ({
      referralError: error,
      referralLoading: loading,
      referralDetails: get(data, 'viewer.referralDetails'),
      linkedPartnerClientId:  get(data, 'viewer.linkedPartnerClientId'),
      verified:  (get(data, 'viewer.kycStatus') === kycStatuses.approved.value &&
        includes([tradingStatuses.registered.value, tradingStatuses.depositor.value,
          tradingStatuses.active.value],get(data, 'viewer.tradingStatus'))),
      referAndEarn: get(data, 'viewer.referAndEarn'),
      reveleadLink: get(data, 'viewer.referralDetails.referAndEarnReveleadLink'),
    })
  }),
  graphql(IB_INTERESTED_MUTATION, {
    name: 'ibInterested',
  }),
  graphql(ACCEPT_REVEAL_REFER_A_FRIEND_MUTATION, {
    name: 'acceptRevealReferAFriend',
    options: () => ({
      refetchQueries: [{query: REFERRAL_DETAILS_QUERY}],
    }),
  }),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading: viewerLoading}, data}) => ({
      error,
      viewerLoading,
      viewer: get(data, 'viewer'),
    })
  }),
  graphql(CLIENT_REFER_CAMPAIGN_QUERY, {
    props: ({data: {error, loading}, data}) => ({
      errorRefer: error,
      loadingRefer: loading,
      referCampaign: get(data, 'viewer.referCampaign'),
    })
  }),
)(ReferAndEarn)
