import React, {Component} from 'react'
import {withApollo, graphql} from 'react-apollo'
import {Link, Redirect} from 'react-router-dom'
import {get, some, isEmpty, find, map, includes, omit, filter, has, keys, difference,
  intersection, uniq, endsWith, flowRight as compose, trim, size, toString, toLower, last, toUpper, first,
  lowerCase, sortBy, union, concat, reduce, isNil, reject, isNumber} from 'lodash'
import {withNamespaces, Trans} from 'react-i18next'
import moment from 'moment'
import {LinearProgress} from '@mui/material'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import withStyles from '@mui/styles/withStyles'
import FormHelperText from '@mui/material/FormHelperText'
import config from '../../../config'
import messages from '../../../assets/messages'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import {companies, countries, accountTypes, signupSteps, whiteLabels, currencies, regulators,
  nationalities, regionsPartners, clientTypes, accountSubtypes, regions, canUseDefaultCountryCurrency,
} from '@bdswiss/common-enums'
import Select from '@mui/material/Select'
import LoadingButton from '../../Common/LoadingButton'
import {register, checkEmail, getIPCountry, checkReferralCode, getConfigVars, getCampaignCookie, getCountries, setCampaignCookie} from '../../../common/utils/requests'
import Typography from '@mui/material/Typography'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import AppContext from '../../Common/contexts/AppContext'
import {fireRegistrationEvent, fireOnBoardingEvent} from '../../../common/utils/tagsManager'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import {CLIENT_DATA_QUERY} from '../../../graphql/queries'
import {validateEmail, validatePhone, validateLength, validateDate, validateDOB, validateCharacters, validatePartnerCode, validateAffiliateEmail, validateOnlyNumbers} from '../../../common/utils/validations'
import queryString from 'qs'
import {europeCountries, localesUpperNameFields, fnsLocales, organicTraficParams, platformLabels, serversLabel, ibAppendix8, isRtlLang, leverageTypeTooltip} from '../../../common/utils/uioptions'
import {getItem, storeItem, storeSessInfoInLocalStorage, findCompany, isWhiteLabel, checkPassword, isMobile, getCookie, setCookie, executeSmartPixelButtonScript} from '../../../common/utils'
import {getAccountSubtype, isZuluAccount} from '../../../common/utils/accounts'
import i18nApp from '../../../i18n'
import NotificationBar from '../../Common/NotificationBar'
import {getBrandCompanies, validCountries, safeParseJSON} from '../../../common/utils/general'
import ReCAPTCHA from 'react-google-recaptcha'
import {Password} from '../../Common/Password'
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3'
import * as dateFnsLocales from 'date-fns/locale'

import {logEventCustomParams} from '../../../common/utils/firebaseEvents'
import HelpOutline from '@mui/icons-material/HelpOutline'
import classNames from 'classnames'
import CustomTooltip from '../../Common/CustomTooltip'
import CountriesSelect from '../../Common/CountriesSelect'
import UiNotification from '../../Common/UiNotification'
import SwitchButton from '../../Common/SwitchButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Popover from '@mui/material/Popover'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import Fab from '@mui/material/Fab'
import LivechatBubble from '../../Common/LivechatBubble'
/* eslint-disable */

const styles = theme => ({
  form: {
    padding: '40px 0px 40px 0px',
    [theme.breakpoints.down('lg')]: {
      padding: '20px 10px 15px 10px',
      marginBottom: 0,
    },
  },
  checkboxLabel: {
    fontSize: 14,
    lineHeight: 1.5,
    [theme.breakpoints.down('md')]: {
      fontSize: 13,
    },
  },
  checkBoxError: {
    color: theme.palette.red.color
  },
  errorMessage: {
    color:  theme.palette.error.main,
    display: 'inline-block',
    verticalAlign: 'bottom',
    margin: '13px 13px 13px 0'
  },
  errorRoot: {
    lineHeight: 1.5
  },
  riskText:{
    fontWeight:300
  },
  steps: {
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  link: {
    color:theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight:400
  },
  selectMenu:{
    maxWidth: 500,
    height: 0,
    [theme.breakpoints.down('md')]: {
      maxWidth: 300,
    },
  },
  inline: {
    display: 'inline-flex',
  },
  grey: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  helpText:{
    color: theme.palette.grey[500],
    float: theme.direction === 'rtl' ? 'right' : 'left',
  },
  nameField:{
    display:'none'
  },
  underline: {
    textDecoration: 'underline'
  },
  dateInput:{
    width: '100%'
  },
  menuBorder: {
    borderBottom: `1px solid ${theme.palette.lightgrey.color}`,
  },
  legalRepresentative: {
    display: 'flex',
    marginTop: 15,
    marginBottom: 0,
  },
  tooltipText:{
    color: theme.palette.secondary.light,
    fontSize:14
  },
  tooltipHelpIcon: {
    marginLeft: 10,
    marginTop: 10,
    [theme.breakpoints.down('md')]: {
      marginLeft: 4,
      marginTop: 4,
    }
  },
  textHelp: {
    color: theme.palette.grey[400],
    '&:hover':{
      color: theme.palette.primary.main,
    },
  },
  platformLabel: {
    color: theme.palette.grey[400],
  },
  platformTrack: {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main
  },
  platformSwitchRoot: {
    width: '50px',
  },
  platformSwitchBase: {
    '&.Mui-checked': {
      transform: 'translate(24px)',
    },
  },
  platformPopover: {
    pointerEvents: 'none',
    width: '75%'
  },
  mobilePopover: {
    pointerEvents: 'none',
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    whiteSpace: 'pre-wrap',
  },
  platformPaper: {
    padding: theme.spacing(1)
  },
  checkIcon: {
    color: theme.palette.green.color,
    marginBottom: -7,
    [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: 10,
    width: 16
  },
  buttonPopper: {
    background: 'none',
    position: 'absolute',
    top:0,
    [theme.direction === 'rtl' ? 'left' : 'right']: 0,
    boxShadow: 'none',
    color: theme.palette.black.color,
    '&:hover':{
      backgroundColor: theme.palette.background.default,
    },
    '&:active':{
      backgroundColor: theme.palette.background.default,
    }
  },
  platformContainer: {
    backgroundColor: theme.palette.extralightgrey.color,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    borderRadius: 4,
  },
  popoverTitle: {
    padding: theme.spacing(2),
    paddingBottom: 0,
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginBottom: 8,
    },
  },
  popoverListItem: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  labelPlacementStart: {
    marginRight: 5,
    marginLeft: 5
  },
  switchBtn: {
    [theme.breakpoints.down('lg')]: {
      marginTop: -7,
    },
  },
  formControlLabelVAligned: {
    display:'flex',
    alignItems:'flex-start'
  },
  checkboxControlVAligned: {
    paddingTop:'0'
  },
  centeredText: {
    textAlign: 'center',
  },
  toggleMobile: {
    [theme.breakpoints.down(400)]: {
      width: '100%',
      maxWidth: '100%',
      flexBasis: '100%',
      justifyContent: 'flex-start'
    }
  },
  leverageTypePopover: {
    padding: theme.spacing(1),
    width: 500,
  },
})

let companyObject = findCompany(get(config, 'forcedCompany'))
const isWhiteLabelCompany = isWhiteLabel()

const recaptchaRef = React.createRef()

const paramTypeToAccountType = {
  corporate: 'basic'
}

const getPlatformTypesBasedOnRegUrlType = (urlRegType = '') => ({
  mt4: urlRegType !== accountSubtypes.cent.key ? 'forexMt4' : 'cent',
  mt5: urlRegType !== accountSubtypes.cent.key ? 'forexMt5' : 'centMt5',
  productNameSubString: urlRegType !== accountSubtypes.cent.key ? 'forex' : 'cent'
})

class RegisterWidget extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    const paramType = get(props.match.params, 'type')
    const paramSubType = get(props.match.params, 'subType')
    this.eligiblePlatforms = getPlatformTypesBasedOnRegUrlType(paramType)
    const product = this.findProduct()
    const availablePlatforms = this.findAvailablePlatforms(paramType, paramSubType)
    this.state = {
      form: {
        firstName:'',
        lastName: '',
        prefix: '',
        phone:'',
        email:'',
        password: '',
        country: '',
        acceptTerms: '',
        emailExists: '',
        emailNotValidDomain: '',
        affiliateEmailExists: '',
        skypeId: '',
        website: '',
        affiliateCountries: [],
        isCorporate: '',
        currency: '',
        birthday: '',
        checkField: '',
        closedAccount: '',
        partnerReferenceNumber: '',
        acceptTermsCysec1: '',
        acceptTermsCysec2: '',
        acceptTermsCysec3: '',
        corporateCompany: '',
        platform: first(availablePlatforms),
        leverageType: ''
      },
      recaptcha:'',
      showPassword: false,
      errors: {},
      loading: false,
      status: '',
      submitMessageError: '',
      passwordError: '',
      activeStep: 0,
      prefixChanged: false,
      countryChanged: false,
      company:null,
      selectAllAffCountries:false,
      showCountries: false,
      prefixFlag:'',
      countryFlag:'',
      showCountriesPrefix: false,
      visibleSidebar: true,
      selectedProduct: product,
      companyReg: '',
      showEmbeddedUi: false,
      showRegistrationWidgetSuccess: false,
      showRegisterWidgetNotification: false,
      embedded: window.sessionStorage && sessionStorage.getItem('embedded'),
      errorsKeys: {},
      fields: (has(config.registrationFields, product.category) || has(config.registrationFields, product.subCategory) || get(clientTypes[paramType], 'allowClientRegistration'))
        ? ((config.registrationFields[product.category]) ? config.registrationFields[product.category] : config.registrationFields[product.subCategory] || config.registrationFields[paramType])
        : config.registrationFields['default'],
      showReferral: false,
      referralDetails: {},
      showRedirectionModal: false,
      showForbiddenCountryPopup: false,
      showForbiddenCountryPopupStrict: false,
      listCountries: [],
      licenseAcknowledgement: '',
      regionCountriesValues: map(regionsPartners, 'value'),
      showAgeWarningLimit: false,
      lastInput: '',
      goToDashboard: '',
      showPartnerEntityNotice: false,
      partnerCountryRegulator: '',
      partnerCountryCompany: '',
    }
  }

  async componentDidMount() {
    const {location} = this.props
    getConfigVars()
      .then((res) => {
        this.setState({isRecaptchaEnabled: res.recaptchaEnabled, isRecaptchaInvisible: res.recaptchaInvisible})
      })
      .catch((err) => {
        this.setState({isRecaptchaEnabled: true, isRecaptchaInvisible: true})
      })

    const urlParams = queryString.parse(location.search.replace('?', ''))

    // TODO: #refactor: remove code soon ( language detection in upper level - appProvider.js )
    //
    // const urlLang = (urlParams && get(urlParams, 'lang')) || getItem('locale')
    // const supportedLanguages = map(filter(languages, (a) => a.client && !a.disabled), 'value')
    // if (urlLang && includes(supportedLanguages,urlLang)) {
    //   const storageLang = window.sessionStorage && sessionStorage.getItem('lang')
    //   const registrationWidget = includes(get(this.props, 'location.pathname'), '/register-widget')
    //   if (!storageLang || storageLang!==urlLang) {
    //     window.sessionStorage && sessionStorage.setItem('lang', urlLang)
    //     i18nApp.init(urlLang)
    //     storeItem('locale', urlLang)
    //     registrationWidget && window.location.reload()
    //   }
    // }

    const getCountriesParams = config.supportedCompanies
      ? [undefined, undefined, undefined]
      : [this.state.selectedProduct.productName, companyObject.value, get(this.props.match.params, 'type')]

    await this.getCountries(...getCountriesParams)
      .then(async () => {
        const country = get(urlParams, 'country')
        this.getCountry(country && country.toUpperCase())
      })

    const registrationWidget = includes(get(this.props, 'location.pathname'), '/register-widget')
    const themePreference = get(urlParams, 'themePreference')
    if (registrationWidget && themePreference) {
      if (themePreference) {
        this.context.toggleTheme(themePreference)
        storeItem('pendingPreference', themePreference)
        storeItem('themePreference', themePreference)
      }
    }

    if (get(urlParams, 'embedded'))
      window.sessionStorage && sessionStorage.setItem('embedded', true)

    const paramKeys = keys(urlParams)
    const allowedPrefilled =['firstName', 'lastName', 'phone', 'email']
    const prefilledFields = intersection(paramKeys, allowedPrefilled)
    const extraFields = difference(paramKeys, allowedPrefilled)
    !isEmpty(prefilledFields) &&
    this.setState(state => ({
      form: {
        ...state.form,
        ...omit(urlParams, extraFields),
      },
    }))

    if (get(urlParams, 'referralCode')) {
      checkReferralCode({referralCode: urlParams.referralCode})
        .then((res) => {
          if (get(res, 'result.redirectUrl')) window.location.href = res.result.redirectUrl
          this.setState({showReferral: res.result.valid,
            referralDetails: {awards: res.result.awards, conditions: res.result.conditions}})
        })
        .catch((err) => {
          this.setState({showReferral: false})
        })
    }

    if (get(urlParams, 'campaign') || !isEmpty(intersection(organicTraficParams, keys(urlParams)))) {
      setCampaignCookie(JSON.stringify({...urlParams, pathname: get(location, 'pathname')}))
        .then(() => {
          this.campaignCookiePartner()
        })
        .catch(() => {
        })
    } else {
      await this.campaignCookiePartner()
    }
  }

  componentWillUnmount () {
    const {lastInput, selectedProduct} = this.state
    const params = {
      lastInput: lastInput,
      type: get(selectedProduct, 'value'),
    }
    logEventCustomParams('signupUserLeftScreen', params)
  }

  componentDidUpdate(prevProps, prevState) {
    const {registrationCountriesRedirect, supportedCompanies} = config
    const {countryFromIp} = this.state
    let prevStateCountries = get(prevState, 'listCountries')
    if (supportedCompanies) prevStateCountries = filter(prevStateCountries, (country) => !country.isEU)
    if (includes(get(registrationCountriesRedirect, 'from'), countryFromIp) && isEmpty(prevStateCountries)
      && !isEmpty(get(this.state, 'listCountries'))) {
      this.handleChangeCountry(countryFromIp)
    }
  }

  async campaignCookiePartner() {
    const {handleChangeParent} = this.props
    await getCampaignCookie()
      .then((res) => {
        this.setState({campaignCookie: get(res, 'result'), partnerId: get(res, 'partnerId')})
        handleChangeParent && handleChangeParent({campaignCookie: get(res, 'result'), partnerId: get(res, 'partnerId')})
        const params = {}
        if (get(res, 'partnerId')) {
          params.partnerId = get(res, 'partnerId')
        }
        logEventCustomParams('signupIntent', params)
        if (!!get(res, 'partnerId')) {
          config.supportedCompanies ? this.getCountries() : this.getCountries(this.state.selectedProduct.productName, companyObject.value)
        }
      })
      .catch()
  }

  async getCountries(product, company, subType) {
    const {handleChangeParent, match} = this.props
    const {common: {allowedRegisterEUCountriesUnderCampaign}} = config
    await getCountries(product, company, subType)
      .then((res) => {
        const isGroup = isNil(product) && isNil(company) && isNil(subType)
        const paramType = get(match, 'params') && get(match.params, 'type')
        if (isEmpty(res.countries) && window.location.pathname !== '/register') {
          window.location.href = '/register'
          return
        }
        const {supportedCompanies, supportedSubtypes, registrationCountriesDisabledTemporary} = config
        let countriesAllowed = res.countries
        if (registrationCountriesDisabledTemporary) countriesAllowed = filter(countriesAllowed, (country) => !includes(registrationCountriesDisabledTemporary, get(country, 'value')))
        if (supportedCompanies) countriesAllowed = filter(countriesAllowed, (country) => !country.isEU || includes(allowedRegisterEUCountriesUnderCampaign, country?.value))

        if (isGroup && includes(supportedSubtypes, paramType)) {
          const supportedProducts = filter(accountTypes, (a) => (
            !a.deleted && !a.disabled && a.clientOpenPermitted && a.category === paramType
          ))
          let disallowedCountries = []
          for (const product of supportedProducts) {
            disallowedCountries = [...product.disallowedCountries]
            uniq(disallowedCountries)
          }

          countriesAllowed = reject(countriesAllowed, (c) => includes(disallowedCountries, c.value))
        }

        const country = get(this.state, 'form.country', '') ||  get(this.state, 'countryFromIp', '')
        const countryCode = size(countriesAllowed) === 1 ? get(countriesAllowed, '[0].value') : (!isEmpty(res.countries) && includes(map(countriesAllowed, 'value'), country))
          ? country : ''
        const selectedCountryCode = !isEmpty(countryCode) ? find(res.countries,(country)=> country.value === countryCode) : ''
        const prefixCode = !isEmpty(countryCode) ? `+${countries[toLower(countryCode)].callingCode}` : ''
        this.setState(state => ({
          form: {...state.form, country: countryCode, prefix: prefixCode},
          countryFlag: selectedCountryCode,
          prefixFlag: countryCode,
          listCountries: countriesAllowed,
        }))
        handleChangeParent &&
        handleChangeParent({
          countryFlag: selectedCountryCode,
          prefixFlag: countryCode,
          listCountries: countriesAllowed,
        })
        const regulator = this.findDefaultRegulator(countryCode)
        const companyReg = this.getRegulatorCompany(regulator)
        if (includes(forceEntityCountries, res['country_code'])) this.setState({selectedRegulator: get(forceEntityOfCountryObj, res['country_code']) || ''})
        else if (companyReg) this.setState({selectedRegulator:companyReg.key})
      })
      .catch((err) => err)
  }

  findRegulatorFromParams(regulator) {
    return get(find(regulators, (r) => toLower(r.label) === regulator), 'value')
  }

  findAllowedRegulators(country) {
    const selectedCountry = find(get(this.state, 'listCountries') || [],(c) => c.countryCode === country)
    return  get(selectedCountry,'regulators')
  }

  findDefaultRegulator(selectedCountry = false) {
    const country = selectedCountry || get(this.state, 'country', '') ||  get(this.state, 'countryFromIp', '')
    const urlParams = queryString.parse(this.props.location.search.replace('?', ''))
    if (urlParams.regulator && selectedCountry) {
      const regulatorFromParams = this.findRegulatorFromParams(urlParams.regulator)
      const companyFromParams = this.getRegulatorCompany(regulatorFromParams)
      if (!includes(europeCountries, selectedCountry) &&
        includes(this.findAllowedRegulators(selectedCountry), companyFromParams.value)) {
        return this.findRegulatorFromParams(urlParams.regulator)
      }
    }

    const forbiddenCountries = map(filter(countries, country => country.forbidden), 'value')
    const forbiddenCountry = country && includes(forbiddenCountries, country)
    let regulator = '', defaultSelectedCompany
    const allowedRegulators = !forbiddenCountry && this.findAllowedRegulators(country)

    if (country && allowedRegulators && !isEmpty(allowedRegulators)) {
      if (allowedRegulators.length === 1) {
        defaultSelectedCompany = allowedRegulators[0]
      } else {
        const selectedCountry = find(get(this.state, 'listCountries') || [],(c) => c.countryCode === country)
        defaultSelectedCompany = get(selectedCountry,'defaultRegulator')
      }
      regulator = defaultSelectedCompany && companies[defaultSelectedCompany].regulator
    }

    return regulator
  }

  findRegulator(company) {
    const {location} = this.props
    const {supportedCompanies} = config
    const urlParams = queryString.parse(location.search.replace('?', ''))
    // const urlParams = ''
    let companyReg
    if (urlParams.regulator)
      companyReg = this.getRegulatorCompany(this.findRegulatorFromParams(urlParams.regulator))

    if (!urlParams.regulator && supportedCompanies) {
      let regulator = this.findDefaultRegulator()

      if (!regulator)
        regulator =  companies[company].regulator

      companyReg = this.getRegulatorCompany(regulator)
    }

    return companyReg
  }

  getCurrentLocale() {
    return getItem('locale','en')
  }

  getRegulatorCompany(regulator) {
    const brand = isWhiteLabel() ? (get(config, 'companyBrand') || config.brand)  : config.brand
    return find(companies, (company) => company.regulator === regulator && company.brand === brand)
  }

  handleChange(prop, value) {
    const ageWarningLimit = prop === 'birthday' && value && moment().diff(new Date(moment(value,'DD/MM/YYYY').format()), 'years')
    if (prop === 'affiliateCountries' && value.indexOf('selectAll') !== -1 && !this.state.selectAllAffCountries) {
      const {match} = this.props
      const paramType = get(match, 'params') && get(match.params, 'type')
      this.setState(state => ({
        form: {...state.form, affiliateCountries: map([...map(filter(regionsPartners, (region) => !paramType || (paramType && !includes(region?.hiddenAccountCategories, paramType)))), ...validCountries(true, true, true, true)], country => country.value)},
        errors:{...state.errors, 'affiliateCountries': !value,},
        selectAllAffCountries:true,
        lastInput: prop,
      }))
    } else if (prop === 'affiliateCountries' && value.indexOf('selectAll') !== -1 && this.state.selectAllAffCountries)
      this.setState(state => ({
        form: {...state.form, affiliateCountries: []},
        errors:{...state.errors, 'affiliateCountries': !value,},
        selectAllAffCountries:false,
        lastInput: prop,
      }))
    else if (this.state.referredByPartner && prop === 'partnerReferenceNumber') {
      const checkPartnerCode = validatePartnerCode(value, 4, 10)
      if (!validateOnlyNumbers(value) || !value ) {
        this.setState(state => ({
          lastInput: prop,
          form: {...state.form, [prop]: value,},
          errorsKeys: {...state.errorsKeys, [prop]: checkPartnerCode},
          errors: {...state.errors,[prop]: !!checkPartnerCode, websiteLength: false},
        }))
      }
    }
    else {
      this.setState(state => ({
        lastInput: prop,
        form: {...state.form,[prop]: value,},
        errors: {...state.errors,[prop]: !value, websiteLength: false},
        showAgeWarningLimit: ageWarningLimit && ageWarningLimit >= 70
      }))
    }
    const {common: {forceEntityOfCountry}} = config
    const forceEntityOfCountryObj = safeParseJSON(forceEntityOfCountry)
    const forceEntityCountries = keys(forceEntityOfCountryObj)
    if (includes(forceEntityCountries, value)) this.setState({selectedRegulator: get(forceEntityOfCountryObj, value) || ''})
  }

  handleChangeCountry(value) {
    const {handleChangeParent} = this.props
    const {prefixChanged, countryChanged, selectedRegulator, selectedProduct} = this.state
    const {registrationCountriesRedirect, supportedCompanies, common: {allowedRegisterEUCountriesUnderCampaign, forceEntityOfCountry},
      supportsDefaultLeverageType} = config
    let countriesCheck = countries
    const forceEntityOfCountryObj = safeParseJSON(forceEntityOfCountry)
    const forceEntityCountries = keys(forceEntityOfCountryObj)
    const excludePartnerRegulatorCountries = ['AE']
    if (supportedCompanies) countriesCheck = filter(countriesCheck, (country) => !country.isEU || includes(allowedRegisterEUCountriesUnderCampaign, country?.value))
    const selectedCountry = find(countriesCheck,(country) => country.value === value)
    if (registrationCountriesRedirect && includes(get(registrationCountriesRedirect, 'from'), value))
      this.setState({showRedirectionModal: true})
    if (prefixChanged && !countryChanged)
      this.setState({prefixChanged:false})
    if (!prefixChanged || !countryChanged) {
      this.handleChangePrefix(value)
      this.handleChange ('prefix', `+${get(selectedCountry,'callingCode','')}`)
    }


    const defaultLeverageType = supportsDefaultLeverageType && get(selectedProduct, 'supportsDynamicLeverage')
      ? selectedCountry?.defaultLeverageType : ''

    this.setState((state) => ({
      showCountries:false,
      countryFlag:selectedCountry,
      form: {...state.form, leverageType: defaultLeverageType || ''}
    }))

    if ((this.isIb() || this.isAffiliate()) && !includes(excludePartnerRegulatorCountries, value)) {
      const showPartnerEntityNotice = companyObject.regulator !== countries[toLower(value)].defaultRegulator.value

      this.setState({showPartnerEntityNotice,
        partnerCountryRegulator: countries[toLower(value)].defaultRegulator,
        partnerCountryCompany: find(companies, {regulator: countries[toLower(value)].defaultRegulator.value})
      })
      handleChangeParent && handleChangeParent({showPartnerEntityNotice,
        partnerCountryRegulator: countries[toLower(value)].defaultRegulator,
        partnerCountryCompany: find(companies, {regulator: countries[toLower(value)].defaultRegulator.value})
      })
    } else {
      this.setState({partnerCountryRegulator: '', partnerCountryCompany: ''})
      handleChangeParent && handleChangeParent({partnerCountryRegulator: '', partnerCountryCompany: ''})
    }

    //check regulator based on country selection
    const allowedRegulators = this.findAllowedRegulators(value)

    if (!includes(forceEntityCountries, value) && (!selectedRegulator || (europeCountries.indexOf(value) !== -1)
     || (selectedRegulator && !includes(allowedRegulators, selectedRegulator)))) {
      const regulator = this.findDefaultRegulator(value)
      const companyReg = this.getRegulatorCompany(regulator)
      if (companyReg) {
        this.setState({selectedRegulator:companyReg.key})
      } else {
        this.setState({selectedRegulator:null})
      }
    }

    if (includes(forceEntityCountries, value)) {
      this.setState({selectedRegulator: get(forceEntityOfCountryObj, value) || ''})
    }
  }

  handleClickShowPassword() {
    this.setState(state => ({
      showPassword: !state.showPassword
    }))
  }

  validateFrom(companyReg, showLeverageField) {
    const {form, form:{email}, passwordError, selectedProduct, referredByPartner, licenseAcknowledgement,
      countryFlag, selectedRegulator, partnerId} = this.state
    const {defaultAccountSubType, registrationFields, supportedCompanies, licenseAcknowledgementCountries, registrationDisabledTemporary, registrationDisabledPermanently, common: {registrationSubtypesExceptionFields}, registerOnlyTestAccounts} = config
    const {t, match} = this.props
    const paramType = get(match, 'params') && get(match.params, 'type')
    const paramSubType = (get(match, 'params') && get(match.params, 'subType')) || defaultAccountSubType
    const errors = {}
    const exceptions = ['emailExists', 'emailNotValidDomain', 'affiliateEmailExists', 'closedAccount', 'platform']
    let selectedCompany, currentProduct = selectedProduct

    if (registrationDisabledTemporary || registrationDisabledPermanently) return

    if (supportedCompanies && companyReg) {
      selectedCompany = companyReg
      currentProduct = this.findAccountType(paramTypeToAccountType[paramType] || paramType, paramSubType, selectedCompany)
    } else {
      selectedCompany=companyObject.key
    }

    const subtypeExceptions = get(safeParseJSON(registrationSubtypesExceptionFields), paramType) || get(safeParseJSON(registrationSubtypesExceptionFields), paramSubType)

    const isIb = this.isIb()
    const isAffiliate = this.isAffiliate()
    const isCorporate = this.isCorporate()
    const isContractor = this.isIb() && includes(this.props.match.params?.contractor, 'contractor')

    if (!isAffiliate)
      exceptions.push(...difference([...registrationFields['affiliate'],  ...registrationFields['default']], (isIb) ? registrationFields['ib'] : registrationFields['default']))

    if (!isIb)
      exceptions.push(...difference([...registrationFields['ib'],  ...registrationFields['default']], (isAffiliate) ? registrationFields['affiliate'] : registrationFields['default']))

    if (!referredByPartner)
      exceptions.push('partnerReferenceNumber')

    if (isCorporate)
      exceptions.push(...difference(registrationFields['default'], registrationFields['corporate']))
    else if (!isCorporate)
      exceptions.push(...difference(registrationFields['corporate'], registrationFields['default']))

    if (isContractor) {
      exceptions.push('isContractor')
    }
    if (subtypeExceptions) {
      exceptions.push(...subtypeExceptions)
    }

    if (!showLeverageField) {
      exceptions.push('leverageType')
    }

    for (const field of Object.keys(form)) {
      if (field === 'phone') {
        errors[field] = isEmpty(form[field]) || !validatePhone(form[field])
      } else if (field === 'email') {
        errors[field] = isEmpty(form[field]) || isAffiliate ? !validateAffiliateEmail(form[field]) : !validateEmail(form[field])
        if (registerOnlyTestAccounts && !(new RegExp('@bdswiss.com|@example.com')).test(form[field])) errors[field] = true
      } else if (field === 'website') {
        errors[field] = isEmpty(form[field])
        errors['websiteLength'] = !validateLength(form[field], 150)
      } else if (field === 'birthday') {
        errors[field] = !validateDOB(form[field])
      } else if ((field === 'firstName') || (field === 'lastName')) {
        const nameError = validateCharacters(trim(form[field]))
        this.setState(state => ({errorsKeys: {...state.errorsKeys, [field]: nameError}}))
        errors[field] = !!nameError
      } else if (field === 'partnerReferenceNumber') {
        const checkPartnerCode = validatePartnerCode(form[field], 4, 10)
        if (!validateOnlyNumbers(form[field]) || !form[field] ) {
          this.setState(state => ({errorsKeys: {...state.errorsKeys, [field]: checkReferralCode}}))
          errors[field]= !!checkPartnerCode
        }
      } else {
        if (field !== 'checkField') {
          errors[field] = isEmpty(form[field])
        } else {
          errors[field] = !isEmpty(form[field])
        }
      }
    }
    const params = {
      type: paramType || get(currentProduct, 'key'),
      partnerId: partnerId,
      platform: get(this.state, 'form.platform'),
    }
    logEventCustomParams('signupAttempted', params)
    if (supportedCompanies) {
      if (!selectedRegulator) {
        errors['selectedRegulator'] = true
        this.setState(state => ({errors: {...state.errors}}))
      }
      else {
        errors['selectedRegulator'] = false
      }
    }

    if (includes(licenseAcknowledgementCountries, get(countryFlag, 'value')) && !licenseAcknowledgement
        && !isAffiliate && !isIb && !registrationDisabledTemporary) {
      errors['licenseAcknowledgement'] = true
    }
    let checkErrors
    if (get(config, 'cysecRegulator')) {
      checkErrors = omit(errors, ['acceptTerms'])
      this.setState({errors: checkErrors})
    } else {
      checkErrors = omit(errors, ['acceptTermsCysec1', 'acceptTermsCysec2', 'acceptTermsCysec3'])
      this.setState({errors: checkErrors})
    }

    if (some(omit(checkErrors, uniq(exceptions)))) {
      return
    }
    if (!passwordError) {
      this.setState({status: '', loading: true})
      checkEmail(email,selectedCompany, get(currentProduct, 'productName', ''))
        .then((res) => {
          if (res === 'rateLimit') {
            this.setState({status: 'failure', loading: false,
              submitMessageError: t(messages.rateLimit.i18nKey,messages.rateLimit.defaults)})
          }
          if (res === 'emailIsUsed') {
            logEventCustomParams('existingEmailSignupPopupIntent')
          }
          this.setState(state => ({
            form: {
              ...state.form,
              emailExists: res === 'emailIsUsed',
              emailNotValidDomain: res === 'invalidEmail',
              affiliateEmailExists: res === 'affiliateEmailIsUsed',
              closedAccount: res === 'emailIsUsedAccountClosed'
            }
          }))
          if (!res)
            this.handleSubmit()
          else
            this.setState({loading: false})
        })
        .catch((err) => {
          this.setState({status: 'failure', loading: false, submitMessageError: t(messages.connectionError.i18nKey,messages.connectionError.defaults)})
          this.setState(state => ({form: {...state.form}}))
        })
    }
  }

  findAvailablePlatforms(paramType, paramSubType) {
    const {supportedCompanies, defaultAccountSubType} = config
    const subType = this.findAccountSubType(paramType, paramSubType, companyObject.key) || defaultAccountSubType
    const accountSubType = subType || 'basic'
    const brandCompanies = supportedCompanies && map(getBrandCompanies(companyObject.key), 'key')
    const selectedRegulator = get(this, 'state.selectedRegulator')

    if (paramType === clientTypes.affiliate.value) {
      return []
    } else if (paramType === clientTypes.ib.value) {
      return [this.eligiblePlatforms.mt4]
    } else if (paramType === 'mt5') {
      return [this.eligiblePlatforms.mt5]
    } else {
      return map(filter(accountTypes, (a) => (includes(a.supportedSubtypes, toUpper(accountSubType))
      || includes(a.supportedSubtypes, toUpper(get(find(accountSubtypes, {label: toUpper(accountSubType)}), 'key')))
      // This condition covers the CENT mt4/mt5.
      || includes(a.supportedSubtypes, toUpper(get(find(accountSubtypes, (s) => s.matchesUrlParamType?.(accountSubType)), 'associatedKey')))
      )
      && !a.isDemo && a.clientOpenPermitted
      && (supportedCompanies
        ? ((selectedRegulator)
          ? (a.company === selectedRegulator) : includes(brandCompanies, a.company))
        : a.company === companyObject.key)
      ), 'category')
    }
  }

  findAccountSubType(type, subType, company, accountTypeObj, platform) {
    const {supportedCompanies} = config
    const brandCompanies = supportedCompanies && map(getBrandCompanies(company), 'key')
    const selectedSubType = subType ? `${type}_${subType}` : type
    const selectedSubTypeDetails = getAccountSubtype(selectedSubType)
    const validSubType = selectedSubTypeDetails && !selectedSubTypeDetails.hideFromClients
    const selectedRegulator = get(this, 'state.selectedRegulator')

    const checkSubtype = type
      && find(accountTypes, (accountType) =>
        (supportedCompanies
          ? ((selectedRegulator)
            ? (accountType.company === selectedRegulator) : includes(brandCompanies, accountType.company))
          : accountType.company === company)
      && accountType.clientOpenPermitted && validSubType &&
       (includes(accountType.supportedSubtypes,(subType && toUpper(`${type}_${subType}`)) || toUpper(type))
       || includes(accountType.supportedSubtypes, selectedSubTypeDetails.value)
       || includes(accountType.supportedSubtypes, toUpper(selectedSubTypeDetails.value)))
       && (!platform || (platform && platform === accountType.category)))

    return checkSubtype ?
      (accountTypeObj ? checkSubtype : selectedSubType) : false
  }

  findAccountType(type, subType, company) {
    const {key, supportedCompanies} = config
    const availablePlatforms = this.findAvailablePlatforms(type, subType)
    const platform = get(this.state, 'form.platform', availablePlatforms.length > 1 ? this.eligiblePlatforms.mt4 : first(availablePlatforms))
    const paramType = type || get(find(accountTypes, {company: company, baseProduct: 'ForexAccount'}), 'value')
    const brandCompanies = supportedCompanies && map(getBrandCompanies(company), 'key')
    const selectedRegulator = get(this, 'state.selectedRegulator')

    const selectedAccountType = paramType
    && find(accountTypes, (accountType) =>
      (supportedCompanies
        ? ((selectedRegulator)
          ? (accountType.company === selectedRegulator) : includes(brandCompanies, accountType.company))
        : accountType.company === company)
        && accountType.clientOpenPermitted && (!subType && !accountType.subscription)
        && ((!subType && includes(accountType.productName, paramType)) || accountType.category === paramType || accountType.subCategory === paramType)
        && (isWhiteLabelCompany && whiteLabels[key].baseProducts ? includes(whiteLabels[key].baseProducts, accountType.key) : true)
        && (!platform || (platform && platform === accountType.category)))

    return selectedAccountType || this.findAccountSubType(type, subType, company, true, platform)
  }

  findProduct() {
    const {supportedCompanies} = config
    const {match, history} = this.props
    const paramType = get(match, 'params') && get(match.params, 'type')
    const paramSubType = get(match, 'params')&& get(match.params, 'subType')
    let company = companyObject.key
    const companyReg = this.findRegulator(company)
    if (companyReg && supportedCompanies) {
      company = companyReg.value
    }

    const accountType =  this.findAccountType(paramTypeToAccountType[paramType] || paramType, paramSubType,company)
    const accountSubType = this.findAccountSubType(paramType, paramSubType,company)

    if (paramType && !accountType && !accountSubType && !get(clientTypes[paramType], 'allowClientRegistration'))
      history.push('/register')

    return accountType
  }

  checkPassword(password) {
    const passwordError = checkPassword(password)
    if (isEmpty(passwordError)) {
      this.setState({passwordError: ''})
    } else {
      this.setState({passwordError: <Trans {...messages[passwordError]} />})
    }
  }

  findCurrencyByCountry(country, type, accountSubtype) {
    const {disallowedCurrencies} = type
    if ((['PL'].indexOf(country) !== -1) && (!includes(disallowedCurrencies, 'PLN'))) {
      return 'PLN'
    }
    else if ((['CH'].indexOf(country) !== -1) && (!includes(disallowedCurrencies, 'CHF'))) {
      return 'CHF'
    }
    else if ((['GB', 'UK'].indexOf(country) !== -1) && (!includes(disallowedCurrencies, 'GBP'))) {
      return 'GBP'
    }
    else if (europeCountries.indexOf(country) !== -1) {
      return 'EUR'
    }
    else if (['ZA'].indexOf(country) !== -1 && (!includes(disallowedCurrencies, 'ZAR')
      || canUseDefaultCountryCurrency(country, 'ZAR', accountSubtype, type))) {
      return 'ZAR'
    }
    else {
      return 'USD'
    }
  }

  isAffiliate() {
    const {match} = this.props
    const product = this.state.selectedProduct
    return !!(get(match, 'params') && get(match, 'params.type') ===  clientTypes.affiliate.value && includes(product.category, 'affiliate'))
  }

  isIb() {
    const {match} = this.props
    const product = this.state.selectedProduct
    return !!(get(match, 'params') && get(match, 'params.type') === clientTypes.ib.value && includes(product.subCategory, 'ib'))
  }

  isCorporate() {
    const {match} = this.props
    return !!(get(match, 'params') && get(match, 'params.type') === clientTypes.corporate.value)
  }

  getSteps() {
    const locale = getItem('locale','en')
    const steps = []

    for (const step of companyObject.registrationSteps)
      steps.push(signupSteps[step].localization.t(locale))

    return steps
  }

  steps() {
    const {activeStep} = this.state
    const {classes} = this.props
    const steps = this.getSteps()
    return (
      <div>
        <Stepper activeStep={activeStep} alternativeLabel classes={{root:classes.steps}}>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    )
  }

  errorHandling(res) {
    const {t, classes, match : {params}} = this.props
    const paramType = params && params.type
    const {form:{country}} = this.state
    const key = get(res, 'key')
    const message = get(res, 'message')
    const countryObj = find(countries, {value: country})
    const obj = {
      reason: get(res, 'message'),
      type: paramType,
    }
    logEventCustomParams('signupError', obj)
    this.setState({
      loading: false,
      status: 'failure',
      submitMessageError: messages[key] ?
        <Trans {...messages[key]} values={{entity: companyObject.trademark, country: get(countryObj, 'label')}} components={[<Link to={'/login'} className={classes.link}> logging </Link>]} />
        : (message && key) ? message.toString() : t(messages.registrationError.i18nKey)})
  }

  async handleSubmit() {
    const {common:{autoTestEmails}} = config
    const {form:{firstName, lastName, phone, email, password, country, prefix, acceptTerms, skypeId, website,
      affiliateCountries, isCorporate, currency, birthday, checkField, partnerReferenceNumber, acceptTermsCysec1,
      acceptTermsCysec2, acceptTermsCysec3, corporateCompany, platform, leverageType},
    embedded, referredByPartner, licenseAcknowledgement, regionCountriesValues, partnerId,
    referralDetails, showReferral, partnerCountryCompany, isRecaptchaInvisible} = this.state
    const {history, match : {params}, client: apolloClient, location} = this.props
    const {key, gTagContainerId, supportedCompanies, defaultAccountSubType} = config

    let recaptcha
    const autoTestEmailsEndsWith = get(autoTestEmails, 'endsWith')
    const autoTestEmailsContains = get(autoTestEmails, 'contains')
    if (this.state.isRecaptchaEnabled) {
      if ((!autoTestEmailsEndsWith || (autoTestEmailsEndsWith && !endsWith(email, autoTestEmailsEndsWith)))
        && (!autoTestEmailsContains || (autoTestEmailsContains && !(includes(email, autoTestEmailsContains) && endsWith(email, '@bdswiss.com'))))) {
        if (isRecaptchaInvisible) {
          recaptcha = await recaptchaRef.current.executeAsync()
          recaptchaRef.current.reset()
        } else {
          recaptcha = recaptchaRef.current.getValue()
        }
      }
    }

    let whiteLabel = filter(whiteLabels, (whitelabel) => whitelabel.value === key)
    const locale = this.getCurrentLocale()

    const companyReg = get(companies,this.state.selectedRegulator)
    let companyRegSelected = ''
    if (supportedCompanies) {
      companyRegSelected = companyReg.value
      const whiteLabelKey = get(supportedCompanies[companyRegSelected], 'whiteLabelKey')
      whiteLabel = filter(whiteLabels, (whitelabel) => whitelabel.value === whiteLabelKey)
    }
    if (partnerCountryCompany) {
      companyRegSelected = partnerCountryCompany.value
    }

    const whiteLabelValue = whiteLabel[0] ? whiteLabel[0].value : undefined
    const company = !isEmpty(companyRegSelected) ? companyRegSelected : companyObject.key
    const paramType = params && params.type
    const paramSubType = params && params.subType
    const defaultProduct = find(accountTypes, (accountType) => accountType.company === company && accountType.clientOpenPermitted
      && includes(accountType.productName, this.eligiblePlatforms.productNameSubString) && (!platform || (platform && platform === accountType.category)))?.productName
    const type = this.findAccountType(paramTypeToAccountType[paramType] || paramType, paramSubType,company) || find(accountTypes, {value: defaultProduct})
    const isAffiliate = this.isAffiliate()
    const cor = country.toUpperCase()
    const subType = this.findAccountSubType(paramType, paramSubType, companyObject.key) || defaultAccountSubType
    const accountSubtype = get(getAccountSubtype(subType), 'value')
    const curr = (currency) ? currency : isAffiliate ? 'USD' : this.findCurrencyByCountry(cor, type, accountSubtype)
    const risk = (acceptTerms || (acceptTermsCysec1 && acceptTermsCysec2 && acceptTermsCysec3))?true:false
    const queryParams = queryString.parse(location.search.replace('?', ''))
    const bta = (isAffiliate && get(queryParams,'bta')) || ''
    const am = (isAffiliate && get(queryParams,'am')) || ''
    const subID = get(queryParams, 'subID', '')
    const webinarid = get(queryParams,'webinarid') || sessionStorage.getItem('webinarid') || ''
    const regulationAcceptance = getCookie('RegulationAccepted')
    const countriesAffiliate = (affiliateCountries.length)? filter(affiliateCountries, (value) => !includes(regionCountriesValues, value)) :''

    const products = []
    products.push((type && type.productName) ? type.productName : defaultProduct )

    const referralCode = get(queryParams, 'referralCode') || ''
    const referral = get(queryParams, 'referral') || ''

    const customDomain = get(config, 'customDomain')
    const dob = birthday && moment(birthday, validateDate(birthday, true, true)).format('YYYY-MM-DD')
    const corporate = isCorporate || this.isCorporate()
    const isContractor = this.isIb() && includes(this.props.match.params?.contractor, 'contractor')
    const isIb = this.isIb()
    await register(trim(firstName),trim(lastName),curr,prefix,phone,email,password,country,true,risk,true,true,products,accountSubtype,true,
      locale,skypeId,website,countriesAffiliate,bta,am,companyRegSelected, whiteLabelValue, corporate, customDomain,
      dob, checkField, recaptcha, showReferral && referralCode, referral, regulationAcceptance, referredByPartner && partnerReferenceNumber, licenseAcknowledgement, '',
      isIb, corporateCompany, leverageType, subID, isContractor)
      .then((res) => {
        if (res.result === 'success') {
          const registrationWidget = includes(get(this.props, 'location.pathname'), '/register-widget')
          if (gTagContainerId) {
            fireRegistrationEvent(res.id,products[0])
            fireOnBoardingEvent(res.id,country)
          }
          // Smart pixel macro code when registration is successful
          executeSmartPixelButtonScript('dashboard-register-account')
          const params = {
            type: accountSubtype,
            partnerId: partnerId
          }
          logEventCustomParams('signupCompleted', params)
          if (!isEmpty(referralDetails) && showReferral) {
            storeItem('referralDetails', referralDetails)
          }
          if (registrationWidget) {
            const checkEntity = get(first(get(res, 'relatedEntities')), 'entity')
            let frontendUrl
            if (supportedCompanies && checkEntity) {
              frontendUrl = supportedCompanies[checkEntity]?.frontendUrl || window.location.host
            } else {
              frontendUrl = get(first(get(res, 'relatedEntities')), 'frontendUrl')
            }
            this.setState({showRegistrationWidgetSuccess: true, goToDashboard: frontendUrl})
            sessionStorage.removeItem('embedded')
          } else {
            apolloClient.query({query: CLIENT_DATA_QUERY, fetchPolicy:'network-only'})
              .then((res) => {
                if (embedded) {
                  this.setState({showEmbeddedUi: true})
                }
                else {
                  if (companyObject.registrationSteps && companyObject.registrationSteps.length<2) {
                    storeSessInfoInLocalStorage(res['data']['viewer'].company)
                    if (!isEmpty(webinarid)) {
                      history.push({pathname:'/webinar', state: {webinarid: webinarid, locationTrack: 'signup'}})
                    } else {
                      supportedCompanies && res['data']['viewer'].company
                        ? window.location.href=supportedCompanies[res['data']['viewer'].company].frontendUrl
                        : history.push({pathname:'/', state: {queryParams: queryParams}})
                    }
                  }
                  else {
                    (includes(companyObject.registrationSteps,'step2')) ? history.push('/register/step2') : history.push('/register/step3')
                  }
                }
              }).catch((err) => {
                if (supportedCompanies) {
                  const params = {
                    reason: get(err, 'networkError.result.data.redirectUrl', 'supportedCompaniesError'),
                    type: accountSubtype,
                    partnerId: partnerId,
                  }
                  logEventCustomParams('signupError', params)
                  if (get(err, 'networkError.result.data.redirectUrl')) {
                    const url = new URL(get(err, 'networkError.result.data.redirectUrl'))
                    if (!isEmpty(webinarid)) {
                      window.location.href = url.origin + '/webinar' + url.search + '&webinarid='+ webinarid +'&locationTrack=signup'
                      return null
                    } else {
                      history.push({pathname:'/', state: {queryParams: queryParams}})
                    }
                  } else {
                    history.push({pathname:'/', state: {queryParams: queryParams}})
                  }
                } else {
                  history.push({pathname:'/', state: {queryParams: queryParams}})
                }
              })
          }
        }
        else {
          this.errorHandling(res)
        }
      })
      .catch((err) => {
        this.errorHandling(err)
      })
  }

  getCountry(paramCountry) {
    const {handleChangeParent} = this.props
    const {featuresConfig:{countriesSpecificLocale}, registrationCountriesDisabledTemporary, supportedCompanies, common: {allowedRegisterEUCountriesUnderCampaign, forceEntityOfCountry, defaultDynamicLeverageCountries}} = config

    getIPCountry()
      .then((res) => {
        const countryFromIp = res['country_code']
        this.setState({countryFromIp})
        handleChangeParent && handleChangeParent({countryFromIp})
        const {listCountries} = this.state
        let countryList = listCountries
        if (registrationCountriesDisabledTemporary) countryList = filter(countryList, (country) => !includes(registrationCountriesDisabledTemporary, get(country, 'value')))
        if (supportedCompanies) countryList = filter(countryList, (country) => !country.isEU || includes(allowedRegisterEUCountriesUnderCampaign, country?.value))
        const singleCheck = paramCountry ? paramCountry : countryList.length === 1 ? get(countryList[0], 'value') : ''
        let countryCode = singleCheck && Boolean(find(countryList, {value: singleCheck})) ? singleCheck : res['country_code']
        const forceEntityOfCountryObj = safeParseJSON(forceEntityOfCountry)
        const forceEntityCountries = keys(forceEntityOfCountryObj)
        if (supportedCompanies && includes(europeCountries, res['country_code']) && !includes(forceEntityCountries, res['country_code'])) countryCode = ''
        if (Boolean(find(countryList, {value: countryCode}))) {
          this.handleChange ('prefix', `+${countries[toLower(res['country_code'])].callingCode}`)
          this.handleChangeCountry(countryCode)
          this.handleChange('country', countryCode)
          this.handleChangePrefix (countryCode)
          const countriesLocale = JSON.parse(countriesSpecificLocale)
          if (get(countriesLocale, countryCode)) {
            const storageLang = window.sessionStorage && sessionStorage.getItem('lang')
            if (!storageLang || storageLang!==countriesLocale[countryCode]) {
              window.sessionStorage && sessionStorage.setItem('lang', countriesLocale[countryCode])
              i18nApp.init(countriesLocale[countryCode])
              storeItem('locale', countriesLocale[countryCode])
            }
          }
        }
        const forbiddenCountries = map(filter(countries, country => country.forbidden), 'value')
        const forbiddenCountry = includes(forbiddenCountries, countryCode) || includes(forbiddenCountries, res['country_code'])
        const showForbiddenCountryPopup = forbiddenCountry && (includes(config.common.forbiddenCountryPopupSpecific, countryCode) || includes(config.common.forbiddenCountryPopupSpecific, res['country_code']))
        const showForbiddenCountryPopupStrict = forbiddenCountry && (includes(config.common.forbiddenCountryPopupSpecificStrict, countryCode) || includes(config.common.forbiddenCountryPopupSpecificStrict, res['country_code']))
        && !((this.isIb() || this.isAffiliate()) && countryCode === countries.gb.value)
        this.setState({showForbiddenCountryPopup, showForbiddenCountryPopupStrict})
        handleChangeParent && handleChangeParent({showForbiddenCountryPopup, showForbiddenCountryPopupStrict})

        if (includes(forceEntityCountries, res['country_code'])) this.setState({selectedRegulator: get(forceEntityOfCountryObj, res['country_code']) || ''})

        if (includes(defaultDynamicLeverageCountries, countryFromIp)) {
          this.handleChange('leverageType', 'dynamic_leverage')
        }
      })
      .catch((err) => err)
  }

  handleChangeTab = (event, value) => {
    this.setState({value})
  }

  setStateOuter = (prop, value) => {
    this.setState({[prop]: value})
  }

  handleChangePrefix(value) {
    this.setState({showCountriesPrefix:false,prefixFlag:value})
  }

  closeEmbeddedIframe() {
    sessionStorage.removeItem('embedded')
    const {weblinks:{websiteUrl}} = config
    window.parent.postMessage('success', websiteUrl)
  }


  affiliateCountriesChange(country) {
    const {regionCountriesValues, form: {affiliateCountries}} = this.state
    if (includes(regionCountriesValues, country)) {
      const regionCountries = map(get(find(map(regionsPartners), {value: country}), 'countries'), 'value')
      const validRegionCountries = filter(regionCountries, (country) => {
        if (find(validCountries(true, true, true, true), {value: country})) {
          return country
        }
      })
      if (includes(affiliateCountries, country)) {
        this.handleChange('affiliateCountries', difference(affiliateCountries, [...validRegionCountries, country]))
      } else {
        this.handleChange('affiliateCountries', uniq([...validRegionCountries, ...affiliateCountries, country]))
      }

    }
  }

  goToDashboard() {
    const {goToDashboard} = this.state
    return  goToDashboard && window.open(goToDashboard, '_blank')
  }

  handlePopoverOpen = (event) => {
    event.preventDefault()
    this.setState({anchorEl: event.currentTarget})
  }

  handlePopoverClose = () => {
    this.setState({anchorEl: null})
  }

  handlePopoverOpenLeverageTooltip = (event) => {
    event.preventDefault()
    this.setState({anchorElLeverageTooltip: event.currentTarget})
  }

  handlePopoverCloseLeverageTooltip = () => {
    this.setState({anchorElLeverageTooltip: null})
  }

  renderPlaformTooltip() {
    const {classes} = this.props
    const open = Boolean(this.state.anchorEl)

    return <Grid container direction='row' justifyContent='flex-start' alignItems='center' spacing={1}>
      <Grid item>
        <Typography variant='body2' className={classes.platformLabel}>
          <Trans {...messages.tradingPlatform} />
        </Typography>
      </Grid>
      <Grid item>
        <HelpOutline
          className={classes.textHelp}
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={this.handlePopoverOpen}
          onMouseLeave={this.handlePopoverClose}
        />
        <Popover
          id="mouse-over-popover"
          className={isMobile() ? classes.mobilePopover : classes.platformPopover}
          classes={{
            paper: classes.platformPaper
          }}
          open={open}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          onClose={this.handlePopoverClose}
          disableRestoreFocus
        >
          <Grid container>
            <Fab className={classes.buttonPopper} onClick={() => this.onHandlePopoverClose()}>
              <CloseIcon/>
            </Fab>
            <Grid item xs={12} md={12} className={classes.popoverTitle}>
              <Trans {...messages.tradingPlatformTooltipTitle} />
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid container>
                <Grid item>
                  <List>
                    <ListItem classes={{root: classes.popoverListItem}}> <Typography variant='body2'> <Trans {...messages[platformLabels.forexmt4.title]} /> </Typography> </ListItem>
                    {map(platformLabels.forexmt4.content, (label) => (
                      <ListItem key={label} classes={{root: classes.popoverListItem}}>
                        <CheckIcon className={classes.checkIcon} /> <Trans {...messages[label]} />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
                <Grid item>
                  <List>
                    <ListItem classes={{root: classes.popoverListItem}}> <Typography variant='body2'> <Trans {...messages[platformLabels.forexmt5.title]} /> </Typography> </ListItem>
                    {map(platformLabels.forexmt5.content, (label) =>(
                      <ListItem key={label} classes={{root: classes.popoverListItem}}>
                        <CheckIcon className={classes.checkIcon} /> <Trans {...messages[label]} />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Popover>
      </Grid>
    </Grid>
  }

  renderLeverageTooltip() {
    const {classes} = this.props
    const openLeverageTooltip = Boolean(this.state.anchorElLeverageTooltip)
    const showFifferentTitle = false

    return <Grid container direction='row' justifyContent='flex-start' alignItems='center' spacing={1}>
      <Grid item>
        <HelpOutline
          className={classes.textHelp}
          aria-owns={openLeverageTooltip ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={this.handlePopoverOpenLeverageTooltip}
          onMouseLeave={this.handlePopoverCloseLeverageTooltip}
        />
        <Popover
          id="mouse-over-popover"
          className={isMobile() ? classes.mobilePopover : classes.platformPopover}
          classes={{
            paper: classes.leverageTypePopover
          }}
          open={openLeverageTooltip}
          anchorEl={this.state.anchorElLeverageTooltip}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          onClose={this.handlePopoverCloseLeverageTooltip}
          disableRestoreFocus
        >
          <Grid container>
            <Fab className={classes.buttonPopper} onClick={() => this.handlePopoverCloseLeverageTooltip()}>
              <CloseIcon/>
            </Fab>

            {map(leverageTypeTooltip , (a, index)=> <div key={index} className={classes.platformLabel}>
              <Grid item xs={12} md={12} className={classes.popoverListItem}>
                <Typography variant='body2'>
                  <Trans {...messages[a.title(showFifferentTitle)?.i18nKey]} />
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} className={classes.platformLabel}>
                <Typography variant='body1'>
                  <Trans {...messages[a.description?.i18nKey]} />
                </Typography>
              </Grid>
            </div>
            )}
          </Grid>
        </Popover>
      </Grid>
    </Grid>
  }

  getDynamicLeverageSubTypes(paramType) {
    const {company} = this.context
    const {form: {country}, fields, selectedProduct} = this.state
    const {common:{dynamicLeverageExtraConditions}} = config

    const isDynamic = includes(fields, 'leverageType') && get(selectedProduct, 'supportsDynamicLeverage')

    const k = map(filter(accountSubtypes, (a) => {
      const specialGroup = a.specialGroup && a.specialGroup(country, isDynamic)

      return specialGroup?.[company]?.isDynamic
    }), 'key')
    const l = map(map(filter(accountSubtypes, (a) => {
      const specialGroup = a.specialGroup && a.specialGroup(country, isDynamic)

      return specialGroup?.[company]?.isDynamic
    }), 'label'), lowerCase)

    const merged = union(k, l)

    return paramType && includes(dynamicLeverageExtraConditions, paramType) ? concat(merged, paramType) : merged
  }

  getAppendixNumber(checkAppendixNumber) {
    const {t} = this.props
    let appendix
    if (isNumber(checkAppendixNumber)) {
      appendix= t(messages.appendix.i18nKey, {appendixNumber: checkAppendixNumber})
    } else if (checkAppendixNumber) {
      appendix = t(messages[checkAppendixNumber].i18nKey)
    }
    return appendix
  }

  render() {
    const {classes, t, loadingProp, viewer, location, match, allowfullwidth, hideLiveChat} = this.props
    const {errors, showPassword, form:{firstName, lastName, phone, email, password, emailExists, acceptTerms, acceptTermsCysec1, acceptTermsCysec2, acceptTermsCysec3,
      skypeId, website, affiliateCountries, isCorporate, currency, emailNotValidDomain, affiliateEmailExists, platform,
      birthday, prefix, checkField, closedAccount, partnerReferenceNumber, corporateCompany, leverageType, country}, loading, status, submitMessageError, passwordError,
    countryFromIp, selectAllAffCountries, prefixFlag, countryFlag, showEmbeddedUi, errorsKeys,
    fields, isRecaptchaEnabled, referredByPartner, campaignCookie, listCountries, showRegistrationWidgetSuccess,
    licenseAcknowledgement, selectedRegulator, regionCountriesValues, showRegisterWidgetNotification, goToDashboard, partnerId, isRecaptchaInvisible} = this.state
    const {weblinks: {termsAndConditions, complaints, legalDocuments, privacyPolicy, affiliateTermsAndConditions, affiliateGuidelines, affiliateCodeConduct,
      ibTermsAndConditions, ibGuidelines, ibCodeConduct, ibMasterTermsAndConditions, websiteUrl, orderExecutionPolicy, clientCategorizationPolicy, summaryConflictsInterestPolicy, keyInformationDocuments}, complaintsEmail, entityWebsite,
    supportedCompanies, euRegulation: {redirectTo, excludeProducts}, translatedPartnerGuidelines,
    common:{reCaptcha:{siteKey}, autoTestEmails, allowedRegisterEUCountriesUnderCampaign}, supportEmail, partnerCodeCountries,
    licenseAcknowledgementCountries, registrationDisabledTemporary, documents, showLocationNotification, registrationDisabledPermanently, common:{signupGroupMetrics}} = config
    const {themePreference} = this.context
    const accountType = this.state.selectedProduct
    const locale = this.getCurrentLocale()
    let partnerCodeRegCountries = partnerCodeCountries

    let weblinks = {
      complaints: complaints.replace('{lang}', locale),
      privacyPolicy: privacyPolicy,
      termsAndConditions: termsAndConditions,
      complaintsEmail: complaintsEmail,
      entityWebsite: entityWebsite,
      legalDocuments: legalDocuments,
      websiteUrl,
      orderExecutionPolicy,
      clientCategorizationPolicy,
      summaryConflictsInterestPolicy,
      keyInformationDocuments,
    }

    const urlParamsString = location.search.replace('?', '')
    const urlParams = queryString.parse(urlParamsString)

    const checkExcludedProducts = (accountType && excludeProducts && (includes(excludeProducts, accountType.category) || includes(excludeProducts, accountType.subCategory)))
    const forbiddenCountries = map(filter(countries, country => country.forbidden), 'value')
    const forbiddenCountry = countryFromIp && includes(forbiddenCountries, countryFromIp)
    const reverseRedirect = !forbiddenCountry && countryFromIp && (redirectTo && redirectTo.from && get(redirectTo, 'reverse', false) ? redirectTo.from.indexOf(countryFromIp) === -1 : redirectTo.from.indexOf(countryFromIp) !== -1)
    const checkRegulationRedirect =  (!checkExcludedProducts && redirectTo && redirectTo.from && reverseRedirect && !isEmpty(redirectTo.to))
    checkRegulationRedirect && !urlParams.noredirect && (window.location.href = (redirectTo.to + window.location.pathname +  window.location.search))
    const companyReg = (typeof selectedRegulator !== 'undefined') ? get(companies,selectedRegulator) : this.findRegulator(companyObject.key)
    const fallbackWarning =  (typeof selectedRegulator === 'undefined') && forbiddenCountry
    const defaultSupportCompany = find(keys(supportedCompanies), (supportedCompany) => supportedCompanies[supportedCompany]?.default)
    const referIdError = get(errorsKeys, 'partnerReferenceNumber')

    if (supportedCompanies && companyReg) {
      const supportedCompany = supportedCompanies[companyReg.value] || supportedCompanies[defaultSupportCompany]
      companyObject =  isWhiteLabelCompany ? findCompany(companyReg.value) : companyObject
      weblinks = !fallbackWarning ? supportedCompany : weblinks
      partnerCodeRegCountries = get(supportedCompany, 'partnerCodeCountries')
    }

    const regSelected = (companyReg && companyReg.value) || ''

    /*Add noredirect flag on group registration page => access correct regulator docs from anywhere*/
    if (supportedCompanies || this.isIb()) {
      const cookieDomain = window.location.hostname.match(/[^.]+\.\w+$/) ?
        window.location.hostname.match(/[^.]+\.\w+$/)[0] : window.location.hostname
      setCookie('noredirect_flag', true, (5/(24*60)), '.'+cookieDomain)
    }

    if (loadingProp) {
      document.body.classList.add('loadingApp')
      return <LinearProgress />
    } else {
      document.body.classList.remove('loadingApp')
    }
    const registrationWidget = includes(get(this.props, 'location.pathname'), '/register-widget')
    let countryList = listCountries || []
    if (supportedCompanies) countryList = filter(countryList, (country) => !country.isEU || includes(allowedRegisterEUCountriesUnderCampaign, country?.value))
    const viewerType = get(viewer, '__typename')
    if (!registrationWidget && viewer && viewerType === 'Client') {
      return <Redirect to='/' />
    }
    if (isZuluAccount(accountType)) {
      countryList = filter(countryList, (country) => !country.isMENA)
    }
    const paramType = match.params && match.params.type
    const paramSubType = match.params && match.params.subType
    const hasCorporateCompany = includes(fields, 'corporateCompany')
    const corporate = this.isCorporate()
    const maxDate = moment().subtract(18,'year').format('YYYY-MM-DD')
    const minDate = moment().subtract(120,'year').format('YYYY-MM-DD')
    const birthdayFormat =  birthday && validateDate(birthday, true, true)
    const birthdayValidFormat = birthdayFormat && moment(birthday, birthdayFormat).format()

    const autoTestEmailsEndsWith = get(autoTestEmails, 'endsWith')
    const autoTestEmailsContains = get(autoTestEmails, 'contains')
    let automationEmail = false
    if (window.liveChat && ((autoTestEmailsEndsWith && endsWith(email, autoTestEmailsEndsWith))
      || (autoTestEmailsContains && includes(email, autoTestEmailsContains) && endsWith(email, '@bdswiss.com')))) {
      automationEmail = true
    }

    const countryValue = get(countryFlag, 'value')
    const objectCompany = (supportedCompanies && companyReg) ? findCompany(companyReg.value) : companyObject
    const showLicenseAcknowledgement = includes(licenseAcknowledgementCountries, countryValue)
    const authority = find(nationalities, {alpha3Code: get(countryFlag, 'alpha3Code')})
    const regulatorObj = regulators[objectCompany.regulator]
    const isIb = this.isIb()
    const isAffiliate = this.isAffiliate()
    const referredByPartnerShow = !campaignCookie && prefixFlag && !!get(partnerCodeRegCountries, 'enabled') &&
      !includes(get(partnerCodeRegCountries, 'disabledCountries', []), prefixFlag) && !isAffiliate
    const ipEuCountry = includes(europeCountries, countryFromIp)
    const nonEuCountry = countryFlag && !includes(europeCountries, get(countryFlag, 'value'))
    const queryParams = queryString.parse(location.search.replace('?', ''))
    const getLang = get(queryParams,'lang')
    const accessDenied = ipEuCountry && partnerId && !isIb && !isAffiliate && !includes(allowedRegisterEUCountriesUnderCampaign, countryFromIp)
    if (!!accessDenied) return <UiNotification
      open={!!accessDenied}
      status={'failure'}
      title={'accessDenied'}
      subTitleChildren={<Typography variant='h3'>
        <Trans {...messages.noPermissionAccess} />
      </Typography>}
      type="page-not-found"
      hideButton={true}
      hideClose={true}
    />

    const ibAppendixAsia1Counties = keys(get(documents, 'ibAppendixAsia1'))
    const getAppendixLinkByCountry = get(documents, `ibAppendixAsia1[${lowerCase(countryFromIp)}]`)
    const getAppendixReferenceByCountry = get(documents, `appendixNumber[${lowerCase(countryFromIp)}]`)
    const checkAppendixLink = includes(ibAppendix8, lowerCase(countryFromIp)) ?
      (get(documents, `ibAppendixMena[${locale}]`) || get(documents, 'ibAppendixMena.default') || get(documents, 'ibAppendixMena'))
      : includes(ibAppendixAsia1Counties,lowerCase(countryFromIp)) ? getAppendixLinkByCountry :
        (get(documents, `ibAppendix[${locale}]`) || get(documents, 'ibAppendix.default') || get(documents, 'ibAppendix'))
    const checkAppendixNumber = includes(ibAppendix8, lowerCase(countryFromIp)) ?
      get(documents, 'appendixNumberMena')
      : includes(ibAppendixAsia1Counties,lowerCase(countryFromIp)) ? getAppendixReferenceByCountry
        : get(documents, 'appendixNumber.default')
    const affiliateTermsAndCond = affiliateTermsAndConditions?.[locale] || affiliateTermsAndConditions?.default || affiliateTermsAndConditions
    const ibTermsAndCond = ibTermsAndConditions?.[locale] || ibTermsAndConditions?.default || ibTermsAndConditions
    const affiliateCodeCond =  affiliateCodeConduct?.[locale] || affiliateCodeConduct?.default || affiliateCodeConduct
    const ibCodeCond =  ibCodeConduct?.[locale] || ibCodeConduct?.default || ibCodeConduct
    const affiliateGuid =  affiliateGuidelines?.[locale] || affiliateGuidelines?.default || affiliateGuidelines
    const ibGuid = isIb && (ibGuidelines?.[locale] || ibGuidelines?.default || ibGuidelines)
    const ibMasterTermsAndCond =  ibMasterTermsAndConditions?.[locale] || ibMasterTermsAndConditions?.default || ibMasterTermsAndConditions

    const menaCountries = map(get(regions?.mena, 'countries', []), 'value')
    const isNotMenaCountry = !includes(menaCountries, country)
    const accountSubTypesDynamicLeverage = this.getDynamicLeverageSubTypes(paramType)
    const showLeverageField = isNotMenaCountry && !isEmpty(prefixFlag) && includes(fields,'leverageType') && get(accountType, 'supportsDynamicLeverage') && (paramType ?  includes(accountSubTypesDynamicLeverage, paramType) : true)

    const notFilledRequiredFields = reduce(this.state.fields, (acc, val) => {
      if (this.state.form[val] === '') {
        if (val === 'leverageType' && !showLeverageField) {
          return Object.assign(acc,  null)
        }
        return Object.assign(acc, {[val]: this.state.form[val]})
      } else {
        return Object.assign(acc,  null)
      }
    }, {})

    const disallowedCountryCurrencies = get(accountType, 'disallowedCountryCurrencies')
    const currentCountry= get(this.state.countryFlag, 'value', '')
    const customDisallowedCurrencies = get(find(disallowedCountryCurrencies, {country: currentCountry}), 'currencies', [])

    return (<React.Fragment>
      <LivechatBubble hideLiveChat={hideLiveChat || false}/>
      <Grid item md={(((isAffiliate || !signupGroupMetrics) && !registrationWidget) || allowfullwidth) ? 12 : 6} xl={(((isAffiliate || !signupGroupMetrics) && !registrationWidget) || allowfullwidth) ? 12 : 6} xs={12}>
        <Grid container spacing={3}>
          {hasCorporateCompany && <Grid item xs={12}>
            <TextField
              autoFocus={!registrationWidget}
              fullWidth
              required
              id="corporateCompany"
              name="corporateCompany"
              label={t(messages.corporateCompany.i18nKey, messages.corporateCompany.defaults)}
              error={errors.corporateCompany}
              onChange={(e) => this.handleChange('corporateCompany', e.target.value)}
              value={corporateCompany}
            />
          </Grid>}
          {corporate && <Grid item xs={12}>
            <Typography variant='h3' className={classes.legalRepresentative}>
              <Trans {...messages.legalRepresentative} />
              <CustomTooltip
                title={<Typography variant='caption' className={classes.tooltipText}>{t(messages.legalRepresentativeTooltip.i18nKey, messages.legalRepresentativeTooltip.defaults)}</Typography>}
                placement={'top-start'}
              >
                <HelpOutline className={classNames(classes.tooltipHelpIcon, classes.textHelp)} />
              </CustomTooltip>
            </Typography>
          </Grid>}
          {includes(fields,'firstName') && <Grid item xs={12}>
            <TextField
              autoFocus={!hasCorporateCompany && !registrationWidget}
              fullWidth
              required
              id="firstName"
              name="firstName"
              label={t(messages.firstName.i18nKey, messages.firstName.defaults)}
              error={errors.firstName}
              onChange={(e) => this.handleChange('firstName', e.target.value)}
              value={firstName}
              autoComplete="given-name"
            />
            {firstName && errors.firstName && messages[errorsKeys.firstName] ? <FormHelperText className={classes.errorMessage}><Trans {...messages[errorsKeys.firstName]} /></FormHelperText>
              : <FormHelperText className={classes.helpText}><i>
                <Trans {...messages.explanationField} values={{field: includes(localesUpperNameFields, locale) ? t(messages.firstName.i18nKey, messages.firstName.defaults) : toLower(t(messages.firstName.i18nKey, messages.firstName.defaults))}}/>
              </i></FormHelperText>
            }
          </Grid>}
          {includes(fields,'lastName') && <Grid item xs={12}>
            <TextField
              fullWidth
              required
              id="lastName"
              name="lastName"
              label={t(messages.lastName.i18nKey, messages.lastName.defaults)}
              error={errors.lastName}
              onChange={(e) => this.handleChange('lastName', e.target.value)}
              value={lastName}
              autoComplete="family-name"
            />
            {lastName && errors.lastName && messages[errorsKeys.lastName] ? <FormHelperText className={classes.errorMessage}><Trans {...messages[errorsKeys.lastName]} /></FormHelperText>
              : <FormHelperText className={classes.helpText}><i>
                <Trans {...messages.explanationField} values={{field: includes(localesUpperNameFields, locale) ? t(messages.lastName.i18nKey, messages.lastName.defaults) : toLower(t(messages.lastName.i18nKey, messages.lastName.defaults))}} />
              </i></FormHelperText>
            }
          </Grid>}
          {includes(fields,'country') && <Grid item xs={12} id="countryGrid">
            <CountriesSelect
              countryList={sortBy(countryList, 'label')}
              handleChangeField={(e) => {
                this.handleChangeCountry(e)
                referredByPartner && this.setStateOuter('referredByPartner', !includes(get(partnerCodeRegCountries, 'disabledCountries', []), e))
              }}
              handleChange={this.handleChange.bind(this)}
              setStateOuter={this.setStateOuter.bind(this)}
              errors={errors}
              value={countryFlag && countryFlag['label']}
              name="country"
              label={t(messages.countryLabel.i18nKey, messages.countryLabel.defaults)}
              showRequired
            />
          </Grid>}
          {includes(fields,'prefix') && <Grid item sm={4} xs={5} id="prefixGrid">
            <CountriesSelect
              countryList={countryList}
              handleChangeField={this.handleChangePrefix.bind(this)}
              handleChange={this.handleChange.bind(this)}
              setStateOuter={this.setStateOuter.bind(this)}
              errors={errors}
              value={prefixFlag}
              name="prefix"
              label={t(messages.phoneCode.i18nKey, messages.phoneCode.defaults)}
              phonePrefix
              prefixValue={prefix}
              showRequired
            />
          </Grid>}
          {includes(fields,'phone') && <Grid item sm={8} xs={7} className={classes.phoneInp}>
            <TextField
              fullWidth
              required
              id="phone"
              name="phone"
              label={t(messages.phone.i18nKey, messages.phone.defaults)}
              error={errors.phone}
              onChange={(e) => this.handleChange('phone', e.target.value)}
              value={phone}
              inputProps={{pattern: '[0-9]*', inputMode:'numeric'}}
            />
            {get(errors, 'phone') && <FormHelperText className={classes.errorMessage}><Trans {...messages.phoneValidation} /></FormHelperText>}
          </Grid>}
          {includes(fields, 'birthday') && <Grid item xs={12} sm={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={get(dateFnsLocales, locale) || get(dateFnsLocales, fnsLocales[locale]) || get(dateFnsLocales, fnsLocales['default'])} >
              <DatePicker
                id="birthday"
                label={t(messages.dateOfBirth.i18nKey, messages.dateOfBirth.defaults)}
                format="dd/MM/yyyy"
                value={birthday ? new Date(birthdayValidFormat) : null}
                onChange={(selectedDate) => this.handleChange('birthday', new Date(selectedDate))}
                className={classes.dateInput}
                maxDate={new Date(maxDate)}
                minDate={new Date(minDate)}
                slotProps={{
                  textField: {
                    helperText: null,
                    required: true,
                    error: !!this.state.errors.birthday
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>}
          {includes(fields,'email') && <Grid item xs={12}>
            <TextField
              fullWidth
              required
              id="email"
              label={t(messages.email.i18nKey, messages.email.defaults)}
              onChange={(e) => this.handleChange('email', e.target.value)}
              error={get(errors, 'email', false)}
              value={email}
            />
            {emailExists &&
            <FormHelperText className={classes.errorMessage}><Trans {...messages.emailExistsLogin}
              components={[<Link to={`/login?email=${encodeURIComponent(email)}`} className={classes.link}><Trans {...messages.login} /></Link>]}/>
            </FormHelperText>}
            {closedAccount &&
            <FormHelperText className={classes.errorMessage}><Trans {...messages.emailExistsClosed}
              components={[<a href={`mailto:${supportEmail}`} className={classes.link}>support</a>]}/></FormHelperText>}
            {emailNotValidDomain &&
            <FormHelperText className={classes.errorMessage}><Trans {...messages.emailNotValidDomain} /></FormHelperText>}
            {affiliateEmailExists &&
            <FormHelperText className={classes.errorMessage}><Trans {...messages.affiliateEmailExists} /></FormHelperText>}
            {get(errors, 'email') && <FormHelperText className={classes.errorMessage}><Trans {...messages.emailValidation} /></FormHelperText>}
          </Grid>}
          {includes(fields,'password') && <Grid item xs={12}>
            <Password
              classes={classes}
              showPassword={showPassword}
              onChange={(e)=>{
                this.handleChange('password',e.target.value)
                this.checkPassword(e.target.value)
              }}
              error={errors.password}
              onClickShow={()=> this.handleClickShowPassword('showPassword')}
              fullWidth
              errorText={passwordError}
              value={password}
              showErrorText={true}
              required
            />
            <FormHelperText className={classes.helpText}><i><Trans {...messages.passwordExplanationField}/></i></FormHelperText>
          </Grid>}
          {showLeverageField && <Grid item xs={12}>
            <Grid container direction='row' justifyContent='flex-start' alignItems='center' spacing={1}>
              <Grid item xs={11}>
                <FormControl fullWidth>
                  <InputLabel error={errors.leverageType} required>
                    <Trans {...messages.leverageType} />
                  </InputLabel>
                  <Select
                    value={leverageType}
                    onChange={(e) => this.handleChange('leverageType', e.target.value)}
                    error={errors.leverageType}
                    required
                  >
                    <MenuItem key={'default_leverage'} value={'default_leverage'}><Trans {...messages.defaultLeverage} /></MenuItem>
                    <MenuItem key={'dynamic_leverage'} value={'dynamic_leverage'}><Trans {...messages.dynamicLeverage} /></MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={1}>
                {this.renderLeverageTooltip()}
              </Grid>
            </Grid>
          </Grid>}
          {(includes(fields,'platform') && size(this.findAvailablePlatforms(paramType, paramSubType)) > 1) && <Grid item xs={12}>
            <Grid container className={classes.platformContainer} direction='row' justifyContent='center'>
              <Grid item xs={6} className={classes.toggleMobile}>
                {this.renderPlaformTooltip()}
              </Grid>
              <Grid item xs={6} className={classNames(classes.switchBtn, classes.toggleMobile)}>
                <Grid container direction='row' justifyContent={isMobile() ? 'flex-start' : 'flex-end'} alignItems='center' spacing={1} className={classes.toggleMobile}>
                  <Grid item>
                    <Typography> {serversLabel[this.eligiblePlatforms.mt4]} </Typography>
                  </Grid>
                  <Grid item>
                    <SwitchButton
                      iOSSwitch
                      classes={{track: classes.platformTrack, switchBase: classes.platformSwitchBase, switchRoot: classes.platformSwitchRoot}}
                      classesLabel={{labelPlacementStart: classes.labelPlacementStart}}
                      checked={includes(isRtlLang, locale) ? platform === this.eligiblePlatforms.mt4 : platform === this.eligiblePlatforms.mt5}
                      onChange={(e) => {
                        if (includes(isRtlLang, locale)) {
                          this.handleChange('platform', e.target.checked ? this.eligiblePlatforms.mt4 : this.eligiblePlatforms.mt5)
                        } else {
                          this.handleChange('platform', e.target.checked ? this.eligiblePlatforms.mt5 : this.eligiblePlatforms.mt4)
                        }
                        logEventCustomParams('signupPlatformSwitch', {platform})
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography> {serversLabel[this.eligiblePlatforms.mt5]} </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>}
          {includes(fields,'currency') && <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel error={errors.currency} required>
                <Trans {...messages.currency} />
              </InputLabel>
              <Select
                value={currency}
                onChange={(e) => this.handleChange('currency', e.target.value)}
                error={errors.currency}
                required
              >
                {map(filter(currencies, (currency)=> !includes(accountType.disallowedCurrencies, currency.key)
                && !includes(customDisallowedCurrencies, currency.key)), (option) =>
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>}
          {includes(fields,'skypeId') && <Grid item xs={12}>
            <TextField
              fullWidth
              required
              id="skypeId"
              label={t(messages.skypeId.i18nKey, messages.skypeId.defaults)}
              onChange={(e) => this.handleChange('skypeId', e.target.value)}
              error={errors.skypeId}
              value={skypeId}
            />
          </Grid>}
          {includes(fields,'website') && <Grid item xs={12}>
            <TextField
              fullWidth
              required
              id="website"
              label={t(messages.website.i18nKey, messages.website.defaults)}
              onChange={(e) => this.handleChange('website', e.target.value)}
              error={errors.website || errors.websiteLength}
              value={website}
            />
            {errors.websiteLength && <FormHelperText className={classes.checkBoxError}>
              <Trans {...messages.maxLengthError} values={{length: 150}}/>
            </FormHelperText>}
          </Grid>}
          {includes(fields,'affiliateCountries') && <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="affiliateCountries" error={errors.affiliateCountries} required>
                {t(messages.affiliateCountries.i18nKey, messages.affiliateCountries.defaults)}
              </InputLabel>
              <Select
                multiple
                required
                id="affiliateCountries"
                placeholder="affiliateCountries"
                name="affiliateCountries"
                onChange={(e) => {
                  if (!includes(regionCountriesValues, difference(e.target.value, affiliateCountries)[0]) && !includes(regionCountriesValues, difference(affiliateCountries, e.target.value)[0]))
                    this.handleChange('affiliateCountries', e.target.value)
                }}
                error={errors.affiliateCountries}
                value={affiliateCountries}
                renderValue={selected => filter(selected, (value) => !includes(regionCountriesValues, value)).join(', ')}
                classes={{select: classes.selectMenu}}
              >
                <MenuItem value={'selectAll'}>
                  <Checkbox checked={selectAllAffCountries} />
                  <ListItemText primary={t(messages.selectAll.i18nKey, messages.selectAll.defaults)} />
                </MenuItem>
                {map([...map(filter(regionsPartners, (region) => !includes(region.hiddenAccountCategories, paramType))), ...validCountries(true,true,true,true)], (option) => (
                  <MenuItem key={option.value} value={option.value} className={option.value === last(regionCountriesValues) ? classes.menuBorder: ''}>
                    <Checkbox checked={affiliateCountries.indexOf(option.value) > -1} onClick={() => this.affiliateCountriesChange(option.value)} />
                    <ListItemText primary={get(option, 'localization.t') ? option.localization.t(locale) : option.label} onClick={() => this.affiliateCountriesChange(option.value)} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>}
          {includes(fields,'isCorporate') && <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel error={errors.isCorporate} required>
                <Trans {...messages.company} /> / <Trans {...messages.individual} />
              </InputLabel>
              <Select
                value={isCorporate}
                onChange={(e) => this.handleChange('isCorporate', e.target.value)}
                error={errors.isCorporate}
                required
              >
                <MenuItem key={'individual'} value={'false'}><Trans {...messages.individual} /></MenuItem>
                <MenuItem key={'company'} value={'true'}><Trans {...messages.company} /></MenuItem>
              </Select>
            </FormControl>
          </Grid>}
          {(isCorporate === 'true') && <Grid item xs={12}>
            <NotificationBar
              status={'warning'}
              variant={'caption'}
              classes={{notificationMessage: classes.riskText}}
              title={<Trans {...messages.corporateNote} components={[<span className={classes.underline}>corporateVerification</span>]}/>}
            />
          </Grid>}
          <Grid item xs={12} className={classes.nameField}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                id="name"
                label={t(messages.name.i18nKey, messages.name.defaults)}
                onChange={(e) => this.handleChange('checkField', e.target.value)}
                value={checkField}
              />
            </FormControl>
          </Grid>
          {/* {supportedCompanies && <RegulationBar location={location}
            regSelected ={(regulator)=> this.setState({selectedRegulator: regulator})}
            error={!selectedRegulator && errors.selectedRegulator}
            country={get(countryFlag, 'value') || countryFromIp}
            selectedRegulator ={selectedRegulator}
            regulator={get(countryFlag, 'value') && companyReg && companyReg.regulator}
          />} */}
          {referredByPartnerShow && <Grid item xs={12}>
            <FormControl>
              <FormControlLabel
                className={classes.formControlLabelVAligned}
                classes={{label:classes.checkboxLabel}}
                control={
                  <Checkbox
                    checked={!!referredByPartner}
                    onChange={(e) => this.setStateOuter('referredByPartner', e.target.checked)}
                    color="primary"
                    value={toString(referredByPartner)}
                    className={classes.checkboxControlVAligned}
                  />
                }
                label={<Trans {...messages.referredByPartner} />}
              />
            </FormControl>
          </Grid>}
          {referredByPartnerShow && referredByPartner && <Grid item xs={12}>
            <TextField
              fullWidth
              required={referredByPartner}
              id="referenceCode"
              name="referenceCode"
              label={t(messages.partnerReferenceNumber.i18nKey, messages.partnerReferenceNumber.defaults)}
              error={errors.partnerReferenceNumber}
              onChange={(e) => this.handleChange('partnerReferenceNumber', e.target.value)}
              value={partnerReferenceNumber}
            />
            {get(errors, 'partnerReferenceNumber') &&  <FormHelperText className={classes.errorMessage}><Trans {...messages[referIdError]} /></FormHelperText>}
          </Grid>}
          {!get(config, 'cysecRegulator') && <Grid item xs={12}>
            <FormControl>
              <FormControlLabel
                classes={{label:classes.checkboxLabel}}
                className={classes.formControlLabelVAligned}
                control={
                  <Checkbox
                    required
                    checked={this.state.acceptTerms}
                    onChange={(e) => this.handleChange('acceptTerms', e.target.checked? 'accept' : '')}
                    color="primary"
                    value={acceptTerms}
                    className={classNames(errors.acceptTerms ? classes.checkBoxError : '', classes.checkboxControlVAligned)}
                  />
                }
                label={this.isAffiliate() ?
                  <Trans {...messages.signUpAffiliateCheckbox} components={[
                    <a href={weblinks.termsAndConditions.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>terms</a>,
                    <a href={weblinks.legalDocuments.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>documents</a>,
                    <a href={weblinks.privacyPolicy.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>data</a>,
                    <a href={affiliateTermsAndCond} target='_blank' rel='noreferrer noopener' className={classes.link}>terms</a>,
                    <a href={affiliateCodeCond} target='_blank' rel='noreferrer noopener' className={classes.link}>code</a>,
                    <a href={includes(translatedPartnerGuidelines, locale) ? affiliateGuid.replace('.pdf', `_${locale.toUpperCase()}.pdf`) : affiliateGuid}
                      target='_blank' rel='noreferrer noopener' className={classes.link}>guideliness</a>,
                  ]} />
                  : this.isIb() ? <Trans {...messages.signUpIBCheckbox} values={{company:companyObject.brandLabel, appendixNumber: this.isIb() && this.getAppendixNumber(checkAppendixNumber)}} components={[
                    <a href={weblinks.termsAndConditions.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>terms</a>,
                    <a href={weblinks.legalDocuments.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>documents</a>,
                    <a href={weblinks.privacyPolicy.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>data</a>,
                    <a href={ibCodeCond} target='_blank' rel='noreferrer noopener' className={classes.link}>code</a>,
                    <a href={includes(translatedPartnerGuidelines, locale) ? ibGuid.replace('.pdf', `_${locale.toUpperCase()}.pdf`) : ibGuid}
                      target='_blank' rel='noreferrer noopener' className={classes.link}>guideliness</a>,
                    <a href={ibTermsAndCond} target='_blank' rel='noreferrer noopener' className={classes.link}>ibTerms</a>,
                    <a href={ibMasterTermsAndCond} target='_blank' rel='noreferrer noopener' className={classes.link}>ibMasterTerms</a>,
                    <a href={checkAppendixLink} target='_blank' rel='noreferrer noopener' className={classes.link}>appendix</a>
                  ]} />
                    : <Trans {...messages.signUpCheckbox} values={{company:companyObject.brandLabel}} components={[
                      <a href={weblinks.termsAndConditions.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>terms</a>,
                      <a href={weblinks.legalDocuments.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>documents</a>,
                      <a href={weblinks.privacyPolicy.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>data</a>,
                    ]} />}
              />
            </FormControl>
          </Grid>}
          {get(config, 'cysecRegulator') && <Grid item xs={12}>
            <FormControl>
              <FormControlLabel
                className={classes.formControlLabelVAligned}
                classes={{label:classes.checkboxLabel}}
                control={
                  <Checkbox
                    required
                    checked={this.state.acceptTermsCysec1}
                    onChange={(e) => this.handleChange('acceptTermsCysec1', e.target.checked? 'accept' : '')}
                    color="primary"
                    value={acceptTermsCysec1}
                    className={classNames(errors.acceptTermsCysec1 ? classes.checkBoxError : '', classes.checkboxControlVAligned)}
                  />
                }
                label={<Trans {...messages.termsCysecP1} components={[
                  <a href={weblinks.privacyPolicy.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>Privacy Policy</a>]}
                />}
              />
            </FormControl>
          </Grid>}
          {get(config, 'cysecRegulator') && <Grid item xs={12}>
            <FormControl>
              <FormControlLabel
                className={classes.formControlLabelVAligned}
                classes={{label:classes.checkboxLabel}}
                control={
                  <Checkbox
                    required
                    checked={this.state.acceptTermsCysec2}
                    onChange={(e) => this.handleChange('acceptTermsCysec2', e.target.checked? 'accept' : '')}
                    color="primary"
                    value={acceptTermsCysec2}
                    className={classNames(errors.acceptTermsCysec2 ? classes.checkBoxError : '', classes.checkboxControlVAligned)}
                  />
                }
                label={<Trans {...messages.termsCysecP2} components={[
                  <a href={weblinks.termsAndConditions.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>Terms and Conditions</a>,
                  <a href={weblinks.privacyPolicy.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>Privacy Policy</a>,
                  <a href={weblinks.legalDocuments.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>legal documents</a>,
                  <a href={weblinks.termsAndConditions.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>Risk Disclosure</a>,
                  <a href={weblinks.orderExecutionPolicy.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>Order Execution Policy</a>,
                  <a href={weblinks.clientCategorizationPolicy.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>Client Categorisation Policy</a>,
                  <a href={weblinks.summaryConflictsInterestPolicy.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>Summary of Conflicts of Interest Policy</a>,
                  <a href={weblinks.keyInformationDocuments.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>Key Information Documents</a>,
                ]} />}
              />
            </FormControl>
          </Grid>}
          {get(config, 'cysecRegulator') && <Grid item xs={12}>
            <FormControl>
              <FormControlLabel
                className={classes.formControlLabelVAligned}
                classes={{label:classes.checkboxLabel}}
                control={
                  <Checkbox
                    required
                    checked={this.state.acceptTermsCysec3}
                    onChange={(e) => this.handleChange('acceptTermsCysec3', e.target.checked? 'accept' : '')}
                    color="primary"
                    value={acceptTermsCysec3}
                    className={classNames(errors.acceptTermsCysec3 ? classes.checkBoxError : '', classes.checkboxControlVAligned)}
                  />
                }
                label={<Trans {...messages.termsCysecP3} />}
              />
            </FormControl>
          </Grid>}
          {showLicenseAcknowledgement && (!supportedCompanies || (supportedCompanies && selectedRegulator)) && !isAffiliate && !isIb && !registrationDisabledTemporary && <Grid item xs={12}>
            <FormControl>
              <FormControlLabel
                className={classes.formControlLabelVAligned}
                classes={{label:classes.checkboxLabel}}
                control={
                  <Checkbox
                    required
                    checked={!!licenseAcknowledgement}
                    onChange={(e) => this.setState({licenseAcknowledgement: e.target.checked ? moment().format() : ''})}
                    color="primary"
                    value={licenseAcknowledgement}
                    className={classNames(errors.licenseAcknowledgement ? classes.checkBoxError : '', classes.checkboxControlVAligned)}
                  />
                }
                label={<Trans {...messages.licenseAcknowledgementCountriesText2} values={{company: objectCompany.trademark,
                  authority: get(authority, 'label'), license: get(regulatorObj, 'license'), regulation: get(regulatorObj, 'name').localization.t(locale),
                  regulationLabel: get(regulatorObj, 'label'), regulationCountry: get(regulatorObj, 'country')}}/>}
              />
            </FormControl>
          </Grid>}
          {isRecaptchaEnabled && !automationEmail && <Grid item xs={12}>
            <ReCAPTCHA
              sitekey={siteKey}
              ref={recaptchaRef}
              theme={themePreference}
              size={isRecaptchaInvisible ? 'invisible' : 'normal'}
              badge={'bottomleft'}
            />
          </Grid>}
          <Grid item xs={12}>
            <LoadingButton
              id='loadingButton'
              onClick={() => this.validateFrom(regSelected, showLeverageField)}
              disabled={!!(loading || registrationDisabledTemporary || registrationDisabledPermanently || !isEmpty(notFilledRequiredFields) || !acceptTerms)}
              status={status}
              fullWidth={isMobile() || (!registrationWidget && !signupGroupMetrics)}
              hideProgressBar={!!registrationDisabledTemporary || !!registrationDisabledPermanently || !isEmpty(notFilledRequiredFields) || !acceptTerms}
            >
              <Trans {...messages.submit} />
            </LoadingButton>
            {status==='failure' &&
            <FormHelperText classes={{root: classes.errorRoot}}className={classes.errorMessage}>{submitMessageError}</FormHelperText>}
          </Grid>
          {!registrationWidget && !signupGroupMetrics && <Grid item xs={12}>
            <Typography variant='body1' className={classes.centeredText}>
              <Trans {...messages.alreadyRegistered} components={[<Link to={`/login${!isEmpty(getLang) ? `?lang=${getLang}` : ''}`} className={classes.link}> Log In</Link>]} />
            </Typography>
          </Grid>}
          {(supportedCompanies || showLocationNotification) && ipEuCountry && nonEuCountry && <Grid item xs={12} className={classes.locatedEurope}>
            <Typography variant='caption'>
              <Trans {...messages.locatedInEuropeNote} />
            </Typography>
          </Grid>}
        </Grid>
        {showRegisterWidgetNotification && <UiNotification
          open={showRegisterWidgetNotification}
          status={'success'}
          type="document-upload"
          buttonMessage={t(messages.close.i18nKey)}
          onClose={() => this.closeEmbeddedIframe()}
        />}
      </Grid>
      {showEmbeddedUi && <UiNotification
        open={true}
        status={'success'}
        type="document-upload"
        buttonMessage={t(messages.close.i18nKey)}
        onClose={() => this.closeEmbeddedIframe()}
      />}
      {showRegistrationWidgetSuccess && <UiNotification
        open={true}
        hideClose={true}
        status={'success'}
        type="document-upload"
        buttonMessage={t(messages.goToDashboard.i18nKey)}
        onClose={() => !isEmpty(goToDashboard) && this.goToDashboard()}
      />}
    </React.Fragment>)
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withApollo,
  withNamespaces(),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {loading, error}, data}) => ({
      loadingProp:loading,
      redirectUrl: get(error, 'networkError.result.data.redirectUrl'),
      viewer: get(data, 'viewer')
    })
  }),
)(RegisterWidget)
