import React, {Component} from 'react'
import withStyles from '@mui/styles/withStyles'
import {Trans, withNamespaces} from 'react-i18next'
import messages from '../../../assets/messages'
import Typography from '@mui/material/Typography'
import {filter, find, flowRight as compose, get, includes, isEmpty} from 'lodash'
import Grid from '@mui/material/Grid'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import moment from 'moment'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import AccountSearchSelect from '../../Common/AccountSearchSelect'
import FormHelperText from '@mui/material/FormHelperText'
import LoadingButton from '../../Common/LoadingButton'
import AppContext from '../../Common/contexts/AppContext'
import classnames from 'classnames'
import {isMobile, safeParseJSON} from '../../../common/utils'
import config from '../../../config'
import {accountTypes, leverageTypes} from '@bdswiss/common-enums'

const styles = theme => ({
  container: {
    padding: '20px 0'
  },
  checkboxLabel: {
    fontSize: 14,
    lineHeight: 1.5,
    [theme.breakpoints.down('md')]: {
      fontSize: 13,
    },
  },
  checkBoxError: {
    color: theme.palette.error.main,
  },
  textCenter: {
    textAlign: 'center'
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  disabledDiv: {
    pointerEvents: 'none'
  }
})

class CompetitionForm extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    this.state = {
      availableAccounts: [],
    }
  }

  componentDidMount() {
    if (this.props.accounts) {
      this.setAvailableAccounts()
    }
  }

  setAvailableAccounts() {
    const {accounts, handleSetError, state: {competition}} = this.props
    const {featuresConfig: {competitions: competitionsConfig}} = config

    let availableAccounts = filter(accounts, (a) =>
      includes(get(competitionsConfig, 'accountSubTypes', []), a.accountSubtype) &&
      includes(get(competitionsConfig, 'accountTypesCategory', []), accountTypes[a.__typename].category) &&
      !a.isViewOnly &&
      !a.isArchived
    )

    availableAccounts = filter(availableAccounts, (a) => a.leverageType === leverageTypes.defaultLeverage.value)

    if (competition.isNewlyCreatedAccountsOnly) {
      availableAccounts = filter(availableAccounts, (a) => moment(a.createdAt).unix() > moment(competition.startDate).unix())
    }

    if (competition.isNewlyCreatedAccountsOnly && isEmpty(availableAccounts)) {
      handleSetError('competitionIsNewlyCreatedAccountsOnly')
      return
    }

    availableAccounts = competition.isDemoAccountsOnly
      ? filter(availableAccounts, (a) => a.isDemo)
      : filter(availableAccounts, (a) => !a.isDemo)

    if (isEmpty(availableAccounts) && !competition.isDemoAccountsOnly) {
      handleSetError('noClassicError')
      return
    }

    this.setState({availableAccounts: availableAccounts})
  }

  render() {
    const {classes, t, client, rules, handleChange, handleChangeAccount, competitionSign} = this.props
    const {
      state: {
        form: {nickname, account, acceptTerms}, errors, loading, status, competitionError, competitionErrorValues,
        nicknameExists, competition, participatedCompetition, cannotParticipateCompetition
      }
    } = this.props
    const {locale, companyObject} = this.context
    const {availableAccounts} = this.state
    const {
      weblinks: {termsAndConditions},
    } = config
    const entryFee = get(find(rules, (r) => r.rule === 'minimumBalance'), 'values.balance')
    const active = moment(get(competition, 'endDate')).utc() > moment().utc()
    const hasCredit = account && get(find(availableAccounts, (acc) => acc.id === account), 'credit')
    const meta = safeParseJSON(get(competition, 'meta', '{}'))

    return (active) && <Grid container spacing={isMobile() ? 1 : 3} className={classes.container}>
      <Grid item xs={12} sm={5}>
        <TextField
          variant="standard"
          id="nickname"
          name="nickname"
          label={t(messages.nickname.i18nKey, messages.nickname.defaults)}
          fullWidth
          error={errors.nickname || errors.nicknameLength}
          value={nickname}
          onChange={(e) => handleChange('nickname', e.target.value)}
          disabled={!!client.nickname || !!competitionError}/>
        {(nicknameExists || errors.nicknameLength) &&
          <FormHelperText
            className={classes.checkBoxError}><Trans {...messages[(errors.nicknameLength) ? 'maxLengthError' : 'nicknameExists']}
              values={{length: 15}}/></FormHelperText>}
      </Grid>
      <Grid item xs={12} sm={7} className={(isEmpty(availableAccounts)) ? classes.disabledDiv : ''}>
        <AccountSearchSelect
          accounts={availableAccounts}
          label={t(messages.selectCompetitionAccount.i18nKey, messages.selectCompetitionAccount.defaults)}
          handleAccountChange={(p, v) => handleChangeAccount(p, v, availableAccounts, entryFee)}
          value={account}
          name="account"
          locale={locale}
          errors={errors}
          hiddenBalance
          disabled={isEmpty(availableAccounts)}
          removeRequired={true}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="standard">
          <FormControlLabel
            classes={{label: classes.checkboxLabel}}
            control={
              <Checkbox
                required
                checked={!!acceptTerms}
                onChange={(e) => handleChange('acceptTerms', e.target.checked ? 'accept' : '')}
                color="primary"
                value={acceptTerms}
                className={errors.acceptTerms ? classes.checkBoxError : ''}
                disabled={!!competitionError}
              />
            }

            label={<Trans {...messages.signUpCompetition} values={{company: companyObject['brandLabel']}}
              components={[
                <a href={
                  meta?.isBDSwissUrl
                    ? termsAndConditions.replace('{lang}', locale)
                    : competition.termsLink
                }
                target="_blank"
                rel="noreferrer noopener"
                className={classes.link}>
                  terms
                </a>
              ]}/>}
          />
        </FormControl>
      </Grid>
      {!!competitionError && <Grid item xs={12} className={classes.textCenter}>
        <Typography variant="caption" className={classes.checkBoxError}>
          <Trans {...messages[`${competitionError}Error`]} components={competitionErrorValues}/>
        </Typography>
      </Grid>}
      {!!hasCredit && <Grid item xs={12} className={classes.textCenter}>
        <Typography variant="caption" className={classes.checkBoxError}>
          <Trans {...messages.bonusCompetitionError} components={[<br key={'br'}/>]}/>
        </Typography>
      </Grid>}
      <Grid item xs={12} className={classes.textCenter}>
        <LoadingButton
          id="competitionSign"
          onClick={() => competitionSign()}
          disabled={loading || !!competitionError || !!hasCredit}
          status={status}
          noMargin={true}
          hideProgressBar={!!competitionError || !!hasCredit}
        >
          <Trans {...messages.startCompetition} />
        </LoadingButton>
        {participatedCompetition &&
          <FormHelperText
            className={classnames(classes.checkBoxError, classes.textCenter)}><Trans {...messages.participatedCompetition} /></FormHelperText>}
        {cannotParticipateCompetition &&
          <FormHelperText
            className={classnames(classes.checkBoxError, classes.textCenter)}><Trans {...messages.cannotParticipateCompetition} /></FormHelperText>}
      </Grid>
    </Grid>
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
)(CompetitionForm)
