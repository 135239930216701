import React, {Component} from 'react'
import {flowRight as compose, get, isEmpty, map, slice, some, truncate} from 'lodash'
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TablePagination,
  TextField,
  Typography,
} from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import {Trans, withNamespaces} from 'react-i18next'
import messages from '../../assets/messages'
import AppContext from '../Common/contexts/AppContext'
import classNames from 'classnames'
import {graphql, withApollo} from 'react-apollo'
import {CLIENT_DATA_QUERY, PARTNER_FAQ_QUERY, PARTNER_TUTORIAL_VIDEOS_QUERY} from '../../graphql/queries'
import {AlertDialog} from '../Common/Dialog'
import LoadingButton from '../Common/LoadingButton'
import 'react-image-lightbox/style.css'
import ReactImageVideoLightbox from 'react-image-video-lightbox'
import {isMobile} from '../../common/utils'
import {validateEmail, validatePhone} from '../../common/utils/validations'
import {CREATE_EVENT_REQUEST_FORM} from '../../graphql/mutations'
import Loading from '../Common/Loading'
import AccordionCustom from '../Common/AccordionCustom'
import config from '../../config'

const styles = theme => ({
  root: {
    width: '100%',
    paddingLeft: 10,
    paddingRight:10,
  },
  pointer: {
    cursor: 'pointer',
  },
  formControl: {
    marginTop: 30,
  },
  container: {
    width: '100%',
  },
  noMargin:{
    marginTop: 0
  },
  link: {
    color: theme.palette.primary.main,
    'font-weight': '500',
  },
  media: {
    height: 140,
  },
  viewTutorials: {
    textAlign: 'center',
    'font-weight': 500,
  },
  paginationDialog: {
    display:'flex',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'left',
    paddingTop:'10px',
  },
  supportForm: {
    border: `1px solid ${theme.palette.lightgrey.color}`,
    'border-radius': '6px',
    padding: 20,
  },
  videoModal: {
    zIndex: 99999999999,
  },
  faqTitle: {
    textAlign: 'center',
  },
  centerLink: {
    textAlign: 'center',
  },
  stillHaveQuestions: {
    textAlign: 'center',
    padding: 20,
  },
  videoLightbox: {
    zIndex: 9999999999,
    position: 'fixed'
  },
  loadingPadding: {
    padding: 20,
  },
  dialogTitle: {
    textAlign: 'start',
    paddingLeft: 15,
    fontWeight: 300,
  },
  dialogVideos: {
    minHeight: 600,
  },
  helpText:{
    color: theme.palette.grey[500],
    float: theme.direction === 'rtl' ? 'right' : 'left',
  },
  seeAll: {
    paddingBottom: 10,
  }
})
export class Support extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    this.state = {
      activeQuestion: '',
      expanded: '',
      panel: '',
      viewTutorials: false,
      page: 0, rowsPerPage: isMobile() ? 2 : 6, clickedEvent:false, openVideo: false,
      status: '',
      loading: false,
      errors: {},
      videoIndex: 0,
      form: {
        natureOfRequest: '',
        phone: '',
        email: '',
        details:'',
      },
      supportFormStatus: '',
      supportFormLoading: false,
      videos: [],
      loadingVideos: false,
    }
  }

  componentDidMount() {
    this.loadVideos()
  }

  closeViewTutorialsModal() {
    this.setState({viewTutorials: false})
  }

  handleFormChange (name, value) {
    this.setState(state => ({
      form: {
        ...state.form,
        [name]: value
      },
      errors: {
        ...state.errors,
        [name]: !value,
      }
    }))
  }

  onSubmit() {
    const {viewer:{id}} = this.props
    const {form, form:{natureOfRequest, phone, email, details}, errors} = this.state
    for (const field of Object.keys(form)) {
      if (field === 'email') {
        errors[field] = isEmpty(form[field]) || !validateEmail(form[field])
      } else if (field === 'phone') {
        errors[field] = isEmpty(form[field]) || !validatePhone(form[field], true)
      } else {
        errors[field] = isEmpty(form[field])
      }
    }
    if (some(errors)) {
      this.setState({errors})
      return
    }
    this.setState({supportFormLoading: true})
    this.props.createEventRequestForm({variables: {clientId: id, email, phone, message: details, natureOfRequest, type: 'supportForm'}})
      .then(() => {
        this.setState({supportFormLoading: false, supportFormStatus: 'success', email: '', phone: '', details: '', natureOfRequest: '', sendLoading: false, updgradeDialog: false,
          sendStatus: 'success'})
        this.context.showNotification({
          subTitle: <Trans {...messages.supportTicketSubmitted} />,
          type: 'document-upload',
          status: 'success',
          content: <Trans {...messages.supportTicketSubmittedContent} />,
          buttonMessage: <Trans {...messages.close} />,
          onClose: () => this.props.history.push('/partner-zone/overview'),
          subTitleCenter: true,
        })
      })
  }

  // Conditional load videos using apollo client
  loadVideos() {
    const {client: apolloClient} = this.props
    const {common:{showPartnerZoneSupportVideosSection}} = config
    showPartnerZoneSupportVideosSection && apolloClient.query({
      query: PARTNER_TUTORIAL_VIDEOS_QUERY,
      fetchPolicy: 'network-only'
    })
      .then(({data:{loading}, data}) => this.setState({videos: get(data, 'partnerTutorialVideos', []), loadingVideos: loading}))
      .catch((_) => this.setState({videos: [], loadingVideos: false}))
  }

  render() {
    const {t, classes, partnerFaqs, loadingFaq} = this.props
    const {common:{showPartnerZoneSupportVideosSection}} = config
    const {viewTutorials, page, rowsPerPage, openVideo, errors, submitMessageError,
      videoIndex, form: {email, phone, details, natureOfRequest}, supportFormLoading, supportFormStatus,
      videos, loadingVideos} = this.state
    const videoList = !isEmpty(videos) && slice(videos, rowsPerPage*page, rowsPerPage*page + rowsPerPage)
    if (loadingFaq || loadingVideos) return <Loading speed={1} className={classes.loadingPadding} />
    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={12} className={classes.faqTitle}>
            <Typography variant="h1"><Trans {...messages.faqs} /></Typography>
          </Grid>
          <Grid item xs={12} className={classes.root}>
            <AccordionCustom items={partnerFaqs} viewMore={true} />
          </Grid>
          {showPartnerZoneSupportVideosSection && <Grid item xs={12} className={classes.centerLink}>
            <Typography variant="h1"><Trans {...messages.videoTutorials} /></Typography>
          </Grid>}
          {showPartnerZoneSupportVideosSection && <Grid item xs={12} className={classes.centerLink}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                {map(slice(videos, 0, 3), (video, index) => (
                  <Grid item xs={isMobile() ? 12 : 4} key={`Image${index}`} onClick={()=> this.setState({openVideo: true, currentSlide: video, viewTutorials: false, videoIndex: index})}>
                    <Card>
                      <CardActionArea>
                        <CardMedia
                          className={classes.media}
                          image={get(video, 'thumbnail')}
                          title={get(video, 'title')}
                        />
                        <CardContent>
                          <Typography gutterBottom variant="body1">
                            {truncate(get(video, 'title'), {'length': 30})}</Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>}
          {showPartnerZoneSupportVideosSection && <Grid item xs={12} className={classNames(classes.seeAll, classes.viewTutorials, classes.pointer)} onClick={()=> this.setState({viewTutorials: true})}>
            <Typography variant='subtitle1' className={classes.link}><Trans {...messages.viewAll} /></Typography>
          </Grid>}
          <Grid item xs={12} className={classes.stillHaveQuestions}>
            <Typography variant="h2"><Trans {...messages.stillHaveQuestions} /></Typography>
          </Grid>
          <Grid item xs={12} className={classes.supportForm}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormControl variant="standard" className={classes.formControl} fullWidth>
                  <InputLabel id="demo-simple-select-label">{'Nature of Request*'}</InputLabel>
                  <Select
                    variant="standard"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={natureOfRequest}
                    error={errors.natureOfRequest}
                    onChange={(e)=> this.handleFormChange('natureOfRequest', e.target.value)}>
                    <MenuItem value={'Start introducing IBs'}>{'Start introducing IBs'}</MenuItem>
                    <MenuItem value={'Upgrade campaign'}>{'Upgrade campaign'}</MenuItem>
                    <MenuItem value={'Commission Withdrawals'}>{'Commission Withdrawals'}</MenuItem>
                    <MenuItem value={'Corporate IB request'}>{'Corporate IB request'}</MenuItem>
                    <MenuItem value={'Other'}>{'Other'}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} lg={6}>
                <FormControl variant="standard" className={classes.formControl} fullWidth>
                  <TextField
                    variant="standard"
                    required
                    label={t(messages.phone.i18nKey, messages.phone.defaults)}
                    value={phone}
                    onChange={(e)=>this.handleFormChange('phone',e.target.value)}
                    error={errors.phone} />
                </FormControl>
              </Grid>
              <Grid item xs={6} lg={6}>
                <FormControl variant="standard" className={classes.formControl} fullWidth>
                  <TextField
                    variant="standard"
                    required
                    label={t(messages.email.i18nKey, messages.email.defaults)}
                    value={email}
                    onChange={(e)=>this.handleFormChange('email',e.target.value)}
                    error={errors.email} />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="standard" className={classes.formControl} fullWidth>
                  <TextField
                    variant="standard"
                    required
                    multiline
                    label={t(messages.provideMoreDetails.i18nKey, messages.provideMoreDetails.defaults)}
                    value={details}
                    onChange={(e)=>this.handleFormChange('details', e.target.value)}
                    error={errors['details']} />
                  <FormHelperText className={classes.helpText}><i>
                    <Trans {...messages.supportHelperText} />
                  </i></FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} className={classes.textCenter}>
                <LoadingButton
                  id='loadingButton'
                  onClick={() => this.onSubmit()}
                  disabled={supportFormLoading || supportFormStatus === 'failure'}
                  status={supportFormStatus}
                  fullWidth={true}
                  noMargin
                >
                  <Trans {...messages.submit} />
                </LoadingButton>
                {supportFormStatus === 'failure' &&
                <FormHelperText classes={{root: classes.errorRoot}}className={classes.error}>{submitMessageError}</FormHelperText>}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {showPartnerZoneSupportVideosSection && <AlertDialog
          open={viewTutorials}
          onClose={() => this.closeViewTutorialsModal()}
          title ={<Typography variant='h2' className={classes.dialogTitle}><Trans {...messages.videoTutorials} /></Typography>}
          closeBtn
          disabledTypography
          maxWidth="md"
        >
          <Grid container className={classes.dialogVideos}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                {map(videoList,(video, index) => <Grid item xs={isMobile() ? 12 : 4} key={`Image${index}`} onClick={()=> this.setState({openVideo: true, currentSlide: video, viewTutorials: false, videoIndex: index})}>
                  <Card>
                    <CardActionArea>
                      <CardMedia
                        className={classes.media}
                        image={get(video, 'thumbnail')}
                        title={get(video, 'title')}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="body1">
                          {truncate(get(video, 'title'), {'length': 55})}</Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>)}
              </Grid>
              <Grid item xs={12} className={classes.viewTutorials}>
                {!isEmpty(videos) && videos.length > rowsPerPage &&
                <TablePagination
                  rowsPerPageOptions={[rowsPerPage]}
                  count={videos.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  component="div"
                  className={classes.paginationDialog}
                  onPageChange={(e, page) => this.setState({page})}
                />}
              </Grid>
            </Grid>
          </Grid>
        </AlertDialog>}
        {showPartnerZoneSupportVideosSection && openVideo && <div className={classes.videoLightbox} id='videoLightbox'><ReactImageVideoLightbox
          data={videos}
          startIndex={videoIndex}
          showResourceCount={true}
          onCloseCallback={()=> this.setState({openVideo: false})}
        /></div>}
      </React.Fragment>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading, viewer}}) => ({error, loading, viewer})
  }),
  graphql(PARTNER_FAQ_QUERY, {
    options: () => ({fetchPolicy: 'network-only'}),
    props: ({data: {loading, error}, data}) => ({
      loadingFaq: loading,
      errorFaq: error,
      partnerFaqs: get(data, 'partnerFaq'),
    })
  }),
  graphql(CREATE_EVENT_REQUEST_FORM, {
    name: 'createEventRequestForm',
  }),
  withApollo,
)(Support)
