import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import withStyles from '@mui/styles/withStyles'
import {flowRight as compose, debounce, get, isEmpty, map, values} from 'lodash'
import {FormControl, FormHelperText, Grid, Input, InputLabel, MenuItem, Select} from '@mui/material'
import {frontends, beetellerDocumentTypes} from '@bdswiss/common-enums'
import {PaymentActionButton, withCreateDeposit} from './helpers'
import messages from '../../../../assets/messages'
import {withNamespaces, Trans} from 'react-i18next'
import {getPlatform, getSourceBasedOnLocationPrevPath, logEventCustomParams} from '../../../../common/utils'


const style = (theme) => ({
  formControl: {
    margin: `${theme.spacing(1)} 0`
  },
  error: {
    color: theme.palette.error.main
  },
  listItemRoot: {
    borderBottom: `1px solid ${theme.palette.lightgrey.color}`,
    padding: '6px 0 7px',
  },
  bankIcon: {display: 'inline'},
  bankImage: {maxWidth: '60px'},
  paymentMethod: {
    color: 'rgba(0, 0, 0, 0.5) !important'
  },
  paymentMethodSelected: {
    color: 'rgba(0, 0, 0, 0.87) !important'
  },
})

const initialState = {
  form: {street:'', district:'', city:'', zipCode:'', state:'', documentType:'', documentNumber:'', accountType:'', accountNumber:'', bankCode:'', cashType:''},
  errors: {},
  creatingDeposit: false
}

class BeetellerProvider extends Component {
  constructor(props) {
    super(props)
    this.validateFields = debounce(this.validateFields, 200)
    this.state = initialState
    this.validators = this.getValidators(props)
    // const additionalFields = JSON.parse(props.providerProperties.additionalFields)
    // this.banks = additionalFields.banks.map((bank)=> ({value: bank.code, label: bank.name}))
    // this.cashTypes = additionalFields.cashTypes.map((cashType) => ({value: cashType, label: cashType}))
  }

  getValidators = () => ({
    documentNumber: (value) => {
      if (!value) return messages.requiredField
    },
    documentType: (value) => {
      if (!value) return messages.requiredField
    },
  })

  handleChange(key, value) {
    const {form} = this.state
    this.setState({
      form: {
        ...form,
        [key]: value
      }
    }, () => this.validateFields())
  }

  validateFields() {
    const {form} = this.state
    const errors = {}
    Object.keys(form).forEach((k) => {
      const validate = this.validators[k]
      errors[k] = validate && validate(form[k])
    })
    this.setState({errors})
  }

  formIsValid() {
    const {form, errors} = this.state
    return isEmpty(values(errors).filter((v) => v)) && !isEmpty(values(form).filter((v) => v))
  }

  doCreateDeposit() {
    const {form} = this.state

    this.setState({creatingDeposit: true})
    const {
      providerProperties: {provider, paymentKey}, amount, account, onError, history: {location},
    } = this.props

    const args = {
      paymentKey,
      platform: getPlatform(),
      ...form,
    }

    const variables = {
      accountId: account.id, amount, vendor: provider, frontend: frontends.web2.value, args: JSON.stringify(args),
    }

    const firebaseParams = {
      source: getSourceBasedOnLocationPrevPath(get(location, 'state', {prevPath: get(location, 'pathname')})),
      login: account.id,
      currency: account.currency,
      vendor: provider,
      amount,
    }
    logEventCustomParams('depositAttempted', firebaseParams)

    this.props.createDepositRequest({variables})
      .then(({data: {newDeposit}}) => {
        const {payment} = newDeposit
        const {history} = this.props

        this.setState({creatingDeposit: false})
        if (payment.url) {
          window.location = payment.url
        } else {
          history.push(`/transactions/${account.id}/deposit/result/failed`)
        }
      })
      .catch((e) => {
        this.setState({creatingDeposit: false})
        onError && onError(e)
      })
  }


  render() {
    const {classes, viewer: {address: {country} = {}}, canProceed} = this.props
    const {form, errors, creatingDeposit} = this.state

    return (
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12}>

          <FormControl variant="standard" fullWidth>
            <InputLabel htmlFor="documentType">
              <Trans {...messages.documentType} />
            </InputLabel>
            <Select
              variant="standard"
              value={get(form, 'documentType', '')}
              onChange={(e) => this.handleChange('documentType', e.target.value)}>
              {map(beetellerDocumentTypes[country].options, (documentType) => <MenuItem key={documentType.value} value={documentType.value}>{documentType.label}</MenuItem>)}
            </Select>
            {errors.documentType &&
            <FormHelperText className={classes.error}><Trans {...messages.requiredField} /></FormHelperText>}
          </FormControl>

          <FormControl variant="standard" className={classes.formControl} fullWidth>
            <InputLabel htmlFor="documentNumber">
              <Trans {...messages.documentNumber} />
            </InputLabel>
            <Input
              id="documentNumber"
              type="text"
              value={get(form, 'documentNumber', '')}
              onChange={(e) => this.handleChange('documentNumber', e.target.value)} />
            {errors.documentNumber &&
            <FormHelperText className={classes.error}><Trans {...errors.documentNumber} /></FormHelperText>}
          </FormControl>

          <PaymentActionButton
            loading={creatingDeposit}
            disable={canProceed || !this.formIsValid()}
            onClick={() => this.doCreateDeposit()}
          />

        </Grid>
      </Grid>
    )
  }
}

BeetellerProvider.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  providerProperties: PropTypes.shape({
    name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    paymentKey: PropTypes.string.isRequired,
  }).isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

export default compose(
  withStyles(style),
  withCreateDeposit,
  withNamespaces(),
  withRouter,
)(BeetellerProvider)
