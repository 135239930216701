import React, {Component} from 'react'
import {get, first, includes, flowRight as compose, isEmpty, find} from 'lodash'
import {Redirect} from 'react-router-dom'
import {graphql} from 'react-apollo'
import {Trans, withNamespaces} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import {Loading} from '../../Common/Loading'
import config from '../../../config'
import messages from '../../../assets/messages'
import UiNotification from '../../Common/UiNotification'
import {accountTypes, kycStatuses} from '@bdswiss/common-enums'
import {isForexAccount} from '../../../common/utils/accounts'
import {getMissingVerifications} from '../../../common/utils/general'
import {CLIENT_DATA_QUERY, PROFILE_SETTINGS_QUERY, ACCOUNTS_QUERY, PAYMENT_METHODS_QUERY} from '../../../graphql/queries'
import {fireDepositSuccesfulEvent, fireDepositFailedEvent, fireDepositSuccesfulFTDEvent} from '../../../common/utils/tagsManager'
import {Grid, Typography} from '@mui/material'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import classNames from 'classnames'
import {logEventCustomParams} from '../../../common/utils/firebaseEvents'

const validStatuses = ['success', 'pending', 'failed', 'cancelled']
const paymentStatusToNotificationStatusMap = {
  success: 'success',
  failed: 'failure',
  pending: 'pending',
  cancelled: 'failure'
}

const styles = theme => ({
  amount: {
    fontWeight: 400,
  },
  note: {
    color: theme.palette.primary.main,
  },
  infoIcon: {
    marginBottom: -6,
    marginRight: 5
  },
  inline: {
    display: 'inline'
  }
})

class DepositNotification extends Component {
  state = {}

  static getDerivedStateFromProps(nextProps, prevState) {
    const {match: {params: {resultstatus}}} = nextProps
    if (!prevState.notificationSeen && resultstatus && validStatuses.includes(resultstatus)) {
      return {showNotification: true, status: resultstatus}
    }
    return null
  }

  getStatusDetails(status) {

    const {
      location: {state: locationState = {}},
      match: {params: {accountId}},
      viewer,
      account,
      latestAptest,
      globalQuestionnaire,
      classes,
      t,
    } = this.props

    const {formattedAmount, isPartialApproval, depositId, showActualError, errorMessage} = locationState
    const {productConfigs, accountVerification} = config
    let category =  get(accountTypes, `${get(account, '__typename')}.category`)
    const accountType =  get(account, '__typename')
    const paymentMethods = get(viewer, 'paymentMethods')

    if (account && isForexAccount(account)) category = 'forex' //TODO: to be removed - fix category name

    const verificationFields = get(productConfigs, `${category}.accountVerification`, accountVerification)

    const missingVerifications  = getMissingVerifications(viewer, latestAptest, globalQuestionnaire, '', verificationFields)

    const msgKeySuffix = formattedAmount ? 'WithAmount': ''
    const translationValues = formattedAmount ? {amount: formattedAmount}  : {}
    const redirectToDocuments = viewer.kycStatus === kycStatuses.approved.value && !!find(paymentMethods, (method) => !get(method, 'confirmed'))
    const firstTimeDeposit = isEmpty(get(viewer, 'ftdDate'))

    let message, redirectTo, subTitleChildren, content, cancelButton, onCancel
    if (includes(missingVerifications, 'appropriatenessTest')) {
      redirectTo = '/settings/profile/appropriateness-test'
    } else if (includes(missingVerifications, 'economicProfile')) {
      redirectTo = '/settings/profile/economic-profile'
    } else if (includes(missingVerifications, 'profile')) {
      logEventCustomParams('profileUpdateIntent', {source: 'cta', userId: get(viewer, 'id')})
      redirectTo = '/settings/profile/personal-profile/details'
    } else if (missingVerifications.length > 0) {
      redirectTo = '/accounts/verification'
    } else if (redirectToDocuments) {
      redirectTo = '/settings/profile/documents/upload/pof'
    } else {
      redirectTo = `/accounts/${accountId}`
    }

    let linkAction, linkActionMessage
    const params = {
      login: accountId,
      category: category,
      accountType: accountType,
      userId: get(viewer, 'id'),
      source: 'PushNotification',
    }
    switch (status) {
      case 'failed' :
        message = messages[`depositResultError${msgKeySuffix}`]
        redirectTo = `/transactions/${accountId}/deposit`
        fireDepositFailedEvent(accountId,accountType)
        logEventCustomParams('depositFailed', params)
        break
      case 'cancelled' :
        message = messages[`depositResultCancelled${msgKeySuffix}`]
        redirectTo = `/transactions/${accountId}/deposit`
        break
      case 'success' :
        message = messages[`depositResultSuccess${msgKeySuffix}`]
        fireDepositSuccesfulEvent(accountId,accountType)
        logEventCustomParams('depositCompleted', params)
        firstTimeDeposit && fireDepositSuccesfulFTDEvent(accountId,accountType)
        firstTimeDeposit && logEventCustomParams('depositCompletedFTD', {
          accountId,
          accountType,
          userId: get(viewer, 'id'),
        })
        if (redirectToDocuments) {
          subTitleChildren = messages[`depositResultSuccess${msgKeySuffix}`]
          content = <Grid container spacing={2}>
            <Grid item xs={12} className={classes.note}>
              <InfoIcon className={classNames(classes.infoIcon, classes.note)} color="secondary"/>
              <Typography variant='body1' className={classNames(classes.inline, classes.note)}><Trans {...messages.pleaseNoteColon} /></Typography>
            </Grid>
            <Grid item xs={12} className={classes.note}>
              <Typography variant='body1'><Trans {...messages.noWithdrawalPop} /></Typography>
              <Typography variant='body1'><Trans {...messages.documentsSubjectReview} /></Typography>
            </Grid>
          </Grid>
          cancelButton = t(messages.willDoItLater.i18nKey, messages.willDoItLater.defaults)
          onCancel = () => this.setState({showNotification: false, notificationSeen: true, redirectPath: '/accounts'})
        }
        break
      case 'pending' :
      case 'held' :
      default:
        if (isPartialApproval && depositId) {
          message = messages.depositResultHeldPartial
          redirectTo = `/transactions/${accountId}/complete/deposit/${depositId}`
          linkAction = `/transactions/${accountId}/cancel/deposit/${depositId}`
          linkActionMessage = <Trans {...messages.cancelTransaction} />
        } else if (get(viewer, 'manualDeposits') !== true) {
          message = messages[`depositResultPending${msgKeySuffix}`]
        }
        break
    }

    return {
      message: (showActualError || message) && (<Typography variant="body1">
        {showActualError ? errorMessage : <Trans {...message} values={{...translationValues}} components={[<span key='1' className={classes.amount}>amount</span>,<br key='2'/>]}/>}
      </Typography>),
      redirectTo,
      linkAction,
      linkActionMessage,
      content,
      subTitleChildren: <Typography variant='subtitle1'><Trans {...subTitleChildren} values={{...translationValues}} components={[<span key='1' className={classes.amount}>amount</span>,<br key='2'/>]}/></Typography>,
      cancelButton,
      onCancel
    }
  }

  render() {
    const {viewerLoading, profileSettingsLoading, accountLoading, location: {state: locationState = {}}, paymentMethod, viewer, t} = this.props
    const {isPartialApproval} = locationState

    if (viewerLoading || profileSettingsLoading || accountLoading) return <Loading />

    const {showNotification, status, redirectPath = '/'} = this.state

    if (!showNotification) return <Redirect to={{pathname: redirectPath, state: {afterDeposit: true, paymentMethod}}} />
    const paymentMethods = get(viewer, 'paymentMethods')
    const {message, redirectTo, linkAction, linkActionMessage, subTitleChildren, cancelButton, content, onCancel} = this.getStatusDetails(status)
    const redirectToDocuments = viewer.kycStatus === kycStatuses.approved.value && !!find(paymentMethods, (method) => !get(method, 'confirmed'))

    return (
      <UiNotification
        open={showNotification}
        status={paymentStatusToNotificationStatusMap[status]}
        type='payment'
        children={message}
        buttonMessage={isPartialApproval
          ? <Trans {...messages.continue} />
          : status === 'cancelled' || status ==='failed' ?
            <Trans {...messages.tryAgain} /> :
            <Trans {...messages[status === 'success' && redirectToDocuments ? 'uploadTheDoc' : 'close']} values={{docCategory: t(messages.pof.i18nKey, messages.pof.defaults)}}/>
        }
        onClose={() => this.setState({showNotification: false, notificationSeen: true, redirectPath: redirectTo})}
        linkAction={linkAction}
        linkActionMessage={linkActionMessage}
        subTitleChildren={subTitleChildren}
        cancelButton={cancelButton}
        onCancel={onCancel}
        content={content}
      />
    )
  }
}

export default  compose(
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {loading: viewerLoading, error: viewerError}, data}) => {
      const viewer = get(data, 'viewer')
      return {
        viewerLoading,
        viewerError,
        viewer
      }
    }
  }),
  graphql(PROFILE_SETTINGS_QUERY, {
    props: ({data: {loading: profileSettingsLoading, error:  profileSettingsError}, data}) => {
      const latestAptest = first(get(data, 'viewer.appropTests'))
      const globalQuestionnaire = get(data, 'viewer.globalQuestionnaire')
      return {
        profileSettingsLoading,
        profileSettingsError,
        latestAptest,
        globalQuestionnaire
      }
    }
  }),
  graphql(ACCOUNTS_QUERY, {
    skip: (props) => isNaN(Number(get(props, 'match.params.accountId'))),
    options: (props) => ({
      variables: {id: get(props, 'match.params.accountId')}
    }),
    props: ({data: {loading: accountLoading, error}, data}) => {
      const account = first(get(data, 'viewer.accounts'))
      return {
        accountLoading,
        error,
        account
      }
    }
  }),
  graphql(PAYMENT_METHODS_QUERY, {
    name: 'getPaymentMethods',
    options: (props) => ({
      variables: {depositId: get(props, 'location.state.depositId')},
    }),
    props: ({getPaymentMethods: {loading: confirmLoading, error: confirmError, variables, viewer}}) => ({
      confirmLoading,
      confirmError,
      paymentMethod: get(variables, 'depositId') ? get(viewer, 'paymentMethods[0]', {}) : {}
    })
  }),
)(DepositNotification)
