import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {frontends, depositVendors, depositStatuses} from '@bdswiss/common-enums'
import {flowRight as compose, get} from 'lodash'
import {graphql} from 'react-apollo'
import {withCreateDeposit, PaymentActionButton, withCreateDepositPCT} from './helpers'
import {getPlatform, safeParseJSON, getFormattedAmount, getSourceBasedOnLocationPrevPath, logEventCustomParams} from '../../../../common/utils'
import {SC_OPEN_BANKING_DEPOSIT_BANKS_QUERY} from '../../../../graphql/queries'
import Grid from '@mui/material/Grid'
import {FormControl, FormHelperText, InputLabel, LinearProgress} from '@mui/material'
import Input from '@mui/material/Input'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import SelfPostingForm from './SelfPostingForm'
import {Trans, withNamespaces} from 'react-i18next'
import {withRouter} from 'react-router-dom'
import {extractIBAN} from 'ibantools'
import messages from '../../../../assets/messages'
import withStyles from '@mui/styles/withStyles'

const styles = theme => ({
  errorMessage: {
    color: theme.palette.error.main,
  },
})

class OpenBanking extends Component {
  state = {doSubmitForm: false, args: {}, formData: {}, selectedBankId: ''}

  handleChange = event => {
    const {valid, countryCode} = extractIBAN(event.target.value)
    const invalidIban = event.target.name === 'ob_iban' ? !!get(event, 'target.value.length') && (!valid && countryCode !== this.props.viewer.address.country.toUpperCase()) : get(this.state, 'invalidIban')

    this.setState({invalidIban, args: {...this.state.args, [event.target.name]: event.target.value}})
  }

  doCreateDeposit() {
    if (this.state.invalidIban) {
      return
    }

    const {form, cardType, showAddressDetails, showCustomCardDetails} = this.state

    this.setState({creatingDeposit: true})
    const {
      providerProperties: {provider, paymentKey}, amount, account, onError, history, locale, useVirtualPaymentPage, bonusAmount, bonusTerms,
      history: {location}
    } = this.props

    const args = {
      paymentKey, platform: getPlatform(), useCardFlow: showCustomCardDetails, cardDetails: {
        ...form, cardType, showAddressDetails,
      }, useVirtualPaymentPage, bonusAmount, bonusTerms, ...this.state.args
    }

    if (provider === depositVendors.safecharge.value) {
      args.safechargeVersion = 2
      args.cardDetails = {}
    }

    const firebaseParams = {
      source: getSourceBasedOnLocationPrevPath(get(location, 'state', {prevPath: get(location, 'pathname')})),
      login: account.id,
      currency: account.currency,
      vendor: provider,
      amount,
    }
    logEventCustomParams('depositAttempted', firebaseParams)

    const variables = {
      accountId: account.id, amount, vendor: provider, frontend: frontends.web2.value, args: JSON.stringify(args),
    }

    this.props.createDepositRequest({variables})
      .then(({data: {newDeposit}}) => {
        const {payment, deposit} = newDeposit
        if (deposit.status !== depositStatuses.pending.key) {
          const {isPartialApproval} = safeParseJSON(get(deposit, 'payment.meta', '{}'))
          const resultStatus = deposit.status === depositStatuses.completed.key ? 'success' : deposit.status === depositStatuses.failed.key ? 'failed' : 'pending'
          history.push(`/transactions/${account.id}/deposit/result/${resultStatus}`, {
            depositId: deposit.id, isPartialApproval, formattedAmount: getFormattedAmount({amount: deposit.amount, currency: account.currency, locale})
          })
        } else {
          if (payment.url) {
            window.location = payment.url
          } else {
            const payload = JSON.parse(payment.payload)
            if (payload.sessionToken) {
              if (provider === depositVendors.safecharge.key) {
                this.handleSafechargeResponse(payload)
              } else {
                this.setState({formData: payload, creatingDeposit: false, doSubmitForm: true})
              }
            } else {
              this.setState({formData: payload, creatingDeposit: false, doSubmitForm: true})
            }
          }
        }
      })
      .catch((e) => {
        this.setState({creatingDeposit: false})
        onError && onError(e)
      })
  }

  render() {
    const {classes, providerProperties: {paymentKey}, canProceed} = this.props
    const paymentMethod = this.props.paymentMethods.find(v => v.paymentMethod === paymentKey)

    if (!paymentMethod) {
      document.body.classList.add('loadingApp')
      return <LinearProgress />
    } else {
      document.body.classList.remove('loadingApp')
    }

    const bankField = paymentMethod.fields.find(f => ['ob_bank_id', 'instantOb_bankId'].includes(f.name))

    let mandatoryFields = []
    if (this.state.args.ob_bank_id || this.state.args.instantOb_bankId) {

      const bankCode = this.state.args.ob_bank_id || this.state.args.instantOb_bankId
      const bank = bankField.listValues.find(b => b.code === bankCode)
      mandatoryFields = bank.mandatoryFields.map(fname => paymentMethod.fields.find(f => f.name === fname))
    }

    const fields = [bankField].concat(mandatoryFields).map((f) => {
      let input = <Input id={f.name} type="text" name={f.name} value={this.state.args[f.name] || ''} onChange={this.handleChange} />

      if (f.listValues) {
        const items = f.listValues.map(v => (<MenuItem key={v.code} value={v.code}>{v.caption}</MenuItem>))

        input = (<Select
          variant="standard"
          name={f.name}
          value={this.state.args[f.name] || ''}
          onChange={this.handleChange}>{items}</Select>)
      }

      return (
        <Grid key={f.name} item xs={12}>
          <FormControl variant="standard" fullWidth>
            <InputLabel htmlFor={f.name}>{f.caption[0].message}</InputLabel>
            {input}
            {this.state.invalidIban && f.name === 'ob_iban' && <FormHelperText className={classes.errorMessage}
              id="iban-helper-text">
              <Trans {...messages.ibanValidation} />
            </FormHelperText>}
          </FormControl>
        </Grid>
      )
    })

    return (<Grid container direction="row" spacing={3}>
      {fields}
      <Grid item xs={12}>
        <PaymentActionButton
          loading={this.state.creatingDeposit}
          disable={canProceed || this.state.invalidIban}
          onClick={() => this.doCreateDeposit()}
        />
      </Grid>
      <SelfPostingForm formData={this.state.formData} doSubmitForm={this.state.doSubmitForm} onSubmit={this.props.onSubmit} />
    </Grid>)
  }
}

OpenBanking.propTypes = {
  createDepositRequest: PropTypes.func.isRequired, providerProperties: PropTypes.shape({
    name: PropTypes.string.isRequired, provider: PropTypes.string.isRequired, paymentKey: PropTypes.string.isRequired,
  }).isRequired, account: PropTypes.shape({
    id: PropTypes.number.isRequired, currency: PropTypes.string.isRequired
  }).isRequired,
  amount: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  withRouter,
  withCreateDeposit,
  withCreateDepositPCT,
  graphql(SC_OPEN_BANKING_DEPOSIT_BANKS_QUERY, {
    options: (props) => ({
      variables: {
        country: get(props, 'viewer.address.country', ''), currency: get(props, 'account.currency', ''),
      }
    }), props: ({data}) => ({
      error: data.error,
      loading: data.loading,
      paymentMethods: get(data, 'safeChargePaymentMethods', []).map(v => JSON.parse(v)),
    })
  }))(OpenBanking)
