import React, {Component} from 'react'
import {AlertDialog} from '../Common/Dialog'
import {flowRight as compose, isEmpty} from 'lodash'
import {Typography} from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import {withNamespaces, Trans} from 'react-i18next'
import AppContext from '../Common/contexts/AppContext'
import PropTypes from 'prop-types'
import messages from '../../assets/messages'
import config from '../../config'
import moment from 'moment'

const styles = theme => ({
  dialogTitle: {
    textAlign: 'left',
  },
  content: {
    lineHeight: '24px',
  },
  link: {
    color: theme.palette.primary.main,
  },
  doubleLineBreak: {
    content: '""',
    margin: '16px',
    display: 'block',
  },
})

export class WhiteLabelTerminationPopup extends Component {
  static contextType = AppContext

  render() {
    const {terminationLinks:{websiteUrl, frontendUrl}} = config
    const {t, classes, open, onClose, whiteLabelTerminationObject} = this.props
    const {companyObject} = this.context
    if (isEmpty(whiteLabelTerminationObject) || (!isEmpty(whiteLabelTerminationObject) &&
      moment(moment(), whiteLabelTerminationObject?.dateFormat).isAfter(moment(whiteLabelTerminationObject?.suppressPopupDate, whiteLabelTerminationObject?.dateFormat)))) return null
    return (
      <AlertDialog
        open={open}
        title={t(messages.importantNotice.i18nKey, messages.importantNotice.defaults)}
        children={
          <Typography variant='body1' className={classes.content}>
            <Trans {...messages.terminationModalMessage}
              values={{
                brandLabel: companyObject?.brandLabel,
                migratedTradingName: whiteLabelTerminationObject?.migratedTradingName,
                migratedBrandLabel: whiteLabelTerminationObject?.migratedBrandLabel,
                terminationDate: moment(whiteLabelTerminationObject?.suppressPopupDate, whiteLabelTerminationObject?.dateFormat).format('LL'),
                dashboardLink: frontendUrl,
                websiteLink: websiteUrl,
              }}
              components={[
                <br className={classes.doubleLineBreak} key='br1'/>,
                <a href={frontendUrl} target='_blank' rel='noreferrer noopener' className={classes.link} key='dashboardLink'>link</a>,
                <br key='br2'/>,
                <a href={websiteUrl} target='_blank' rel='noreferrer noopener' className={classes.link} key='websiteLink'>link</a>,
              ]}
            />
          </Typography>
        }
        agreeText={t(messages.okButton.i18nKey, messages.okButton.defaults)}
        onClose={onClose}
        classes={{dialogTitle: classes.dialogTitle}}
      />
    )
  }
}

WhiteLabelTerminationPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  whiteLabelTerminationObject: PropTypes.object.isRequired,
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
)(WhiteLabelTerminationPopup)
