import React, {Component} from 'react'
import AppContext from '../Common/contexts/AppContext'
import withStyles from '@mui/styles/withStyles'
import Typography from '@mui/material/Typography'
import {Trans, withNamespaces} from 'react-i18next'
import {assign, filter, find, first, flowRight as compose, get, includes, intersectionBy, isEmpty, isNil, map, sortBy, unionBy, uniq} from 'lodash'
import {CustomDialog} from '../Common/Dialog'
import {languages, promoMaterialFileTypes, promoMaterialTypes} from '@bdswiss/common-enums'
import {Box, Grid, SvgIcon, Tooltip} from '@mui/material'
import messages from '../../assets/messages'
import Filter from './PromoMaterials/Filter'
import classNames from 'classnames'
import {ReactComponent as DownloadButton} from '../../assets/images/promoMaterialsDownloadButton.svg'
import {ReactComponent as CopyCodeButton} from '../../assets/images/promoMaterialsCopyCodeButton.svg'
import {ReactComponent as CloseIcon} from '../../assets/images/close-icon-dark.svg'
import CopyToClipboard from 'react-copy-to-clipboard'
import {getCurrentTheme, isDarkTheme, isMobile, logEventCustomParams} from '../../common/utils'
import PropTypes from 'prop-types'
import Loading from '../Common/Loading'

const styles = (theme) => ({
  dialogPaper: {
    minHeight: '80%',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      minHeight: 'unset',
      width: 'unset',
    },
  },
  dialogTitleRoot: {
    margin: '0px',
    padding: '16px',
    textAlign: theme.direction === 'rtl' ? 'right': 'left',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '8px',
    },
    '& .MuiTypography-root': {
      fontSize: '16px'
    }
  },
  dialogContentRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0px 16px 16px 16px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 24px 24px',
    },
  },
  closeIcon: {
    position: 'absolute',
    top: '15px',
    [theme.direction === 'rtl' ? 'left' : 'right']: '15px',
    color: theme.palette.grey[400],
    cursor: 'pointer'
  },
  imageContainer: {
    cursor: 'pointer',
    gap: '16px',
    [theme.breakpoints.down('sm')]: {
      gap: '8px',
    },
  },
  itemImage: {
    position: 'relative',
    borderRadius: '6px',
    display: 'block',
  },
  gridImage: {
    height: '100%',
    width: '100%',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  customDialogContainer: {
    gap: '24px',
    wordBreak: 'break-word',
  },
  rightPanel: {
    margin: '16px',
    gap: '16px',
    paddingBottom: '24px',
    [theme.breakpoints.down('sm')]: {
      margin: 'unset',
      paddingBottom: 'unset',
    }
  },
  leftPanel: {
    borderRadius: '6px',
    border: '1px solid #EBEBEB',
    padding: '24px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
      height: 'unset',
    },
    '&.dark': {
      border: `1px solid ${theme.palette.grey[700]}`,
    }
  },
  linkSection: {
    padding: '4px 12px',
  },
  linkElement: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    gap: '5px',
  },
  linkText: {
    color: theme.palette.primary.main,
    fontWeight: '400',
  },
  codeSnippetSection: {
    border: '1px solid #EBEBEB',
    borderRadius: '4px',
    padding: '12px',
    '&.dark': {
      border: `1px solid ${theme.palette.grey[700]}`,
    }
  },
  codeSnippetText: {
    direction: 'ltr',
    color: theme.palette.secondary.dark,
    wordBreak: 'break-all',
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  previewImage: {
    maxWidth: 'inherit',
  },
  assetName: {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.25px',
    },
  },
  bold: {
    fontWeight: '400',
  },
  lineHeight: {
    lineHeight: '24px',
  },
  codeSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
})

// Custom react-select style
const filtersCustomStyle = (theme, isDark) => ({
  control: (baseStyles, {isDisabled}) => ({
    ...baseStyles,
    backgroundColor: isDark ? isDisabled ? theme.palette.grey[900] : '#333333' : get(baseStyles, 'backgroundColor'),
    border: isDark ? `1px solid ${isDisabled ? '#333333' : theme.palette.grey[700]}` : get(baseStyles, 'border')
  }),
  menu: (baseStyles, _) => ({
    ...baseStyles,
    backgroundColor: isDark ? '#333333' : get(baseStyles, 'backgroundColor'),
  }),
  option: (baseStyles, {isSelected}) => ({
    ...baseStyles,
    color: isDark ? theme.palette.secondary.light : '#000000',
    backgroundColor: isDark ? '#333333': isSelected ? 'transparent' : get(baseStyles, 'backgroundColor'),
    '&:hover': {
      backgroundColor: isDark ? '#2A2A2A' : theme.palette.extralightgrey.color,
    },
  }),
  singleValue: (baseStyles, {isDisabled}) => ({
    ...baseStyles,
    color: isDark ? isDisabled ? theme.palette.grey[600]: theme.palette.secondary.light : get(baseStyles, 'color'),
  }),
  dropdownIndicator: (baseStyles, {isDisabled}) => ({
    ...baseStyles,
    color: isDark ? isDisabled ? theme.palette.grey[400]: theme.palette.secondary.light : get(baseStyles, 'color'),
    '&:hover' : {
      color: isDark ? isDisabled ? theme.palette.grey[400]: theme.palette.secondary.light : get(baseStyles, 'color'),
    },
  })
})

const codeTemplates = {
  hyperlink: '<a href="{campaignUrl}&lang={language}" target="_BLANK"><img src="{sourceFileUrl}" width="{width}" height="{height}"/></a>',
  videoIframe: '<iframe width="{width}" height="{height}" title="{assetName}" src="{assetUrl}" frameborder="0" allow="accelerometer; autoplay; ' +
  'clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
  url: '{assetUrl}{campaignUrlParams}&lang={language}',
  pdfIframe: '<iframe width="{width}" title="{assetName}" height="{height}" src="{sourceFileUrl}" frameborder="0"></iframe>'
}

class Asset extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {
      size: '',
      language: '',
      ibId: '',
      currentPromoMaterial: null,
      siblings: [],
      filters: [],
      open: false,
      copyCode: false,
      loading: false,
      iframeLoading: false,
    }
  }

  componentDidUpdate(_, prevState) {
    const {siblings} = this.state
    const {siblings: prevSiblings} = prevState
    if (prevSiblings !== siblings) return this.setFilterData()
  }

  getIbIds() {
    const {account} = this.props
    return map(filter(get(account, 'partnerDetails', []), ({ibId}) => !isNil(ibId)), ({ibId}) => ibId)
  }

  setFilterData() {
    const {theme, innerFilterIds} = this.props
    const {ibId, size, language} = this.state
    const themePreference = getCurrentTheme(this.context)
    const isDark = isDarkTheme(themePreference)
    const customStyles = filtersCustomStyle(theme, isDark)
    const filters = [
      {id: 'language', title: messages.promoMaterialsLanguages.i18nKey, value: language, onChangeHandle: (key, e) => this.onChangeHandle(key, e),
        ...this.constructOptions(languages, 'language', true, '', true), customStyles},
      {id: 'size', title: messages.promoMaterialsSize.i18nKey, value: size, onChangeHandle: (key, e) => this.onChangeHandle(key, e),
        ...this.constructOptions(null, 'size', true, '', true), customStyles},
      {id: 'ibId', title: messages.promoMaterialsIbIdsFilter.i18nKey, value: ibId, onChangeHandle: (key, e) => this.onChangeHandle(key, e),
        ...this.constructOptions(this.getIbIds(), 'ibId', false, 'IB ID:', true), customStyles}]
    this.setState({filters: intersectionBy(filters, innerFilterIds, 'id'), loading: false})
  }

  // Constructs the filter's options.
  // Implemented special handling for size filter.
  // When hidden mode is set to true, the filter is completely hidden rather than disabling it.
  constructOptions(options, key, includeId=false, labelPrefix='', hiddenMode=false) {
    const {siblings} = this.state
    const res = {options: [], disabled: false, isHidden: false}
    res.options = key === 'size'
      ? unionBy(sortBy(map(siblings, ({id, width, height}) =>
        ({...(includeId && {id}), width, height, key: `size_${width}_${height}`, value: `size_${width}_${height}`, label: `${width}x${height}`})), 'label'), 'label')
      : !Array.isArray(options) ?
        unionBy(sortBy(map(siblings, ({id, [key]:keyVal}) => ({...(includeId && {id}), ...find(options, (o) => o.value === keyVal)})), 'label'), 'label')
        : map(uniq(options), (o) => ({key: o, value: o, label: `${labelPrefix} ${o}`}))
    if (hiddenMode) res.isHidden = hiddenMode && res.options.length <= 1
    return res
  }

  // Because all the filters depend each other, is necessary to update all filters when one filter changes
  onChangeHandle(key, e) {
    const {siblings, currentPromoMaterial, filters} = this.state
    const id = get(e, 'id') || get(e, 'target.id') || ''
    const changedPromoMaterial = find(siblings, (s) => get(s, 'id', '') === id) || currentPromoMaterial
    const {width, height} = changedPromoMaterial
    const otherFilters = assign({}, ...map(filters, ({id}) => ({[id]: id === 'size' ? `size_${width}_${height}` : changedPromoMaterial[id] || this.state[id]})))
    this.setState({...otherFilters, [key]: get(e, 'value') || get(e, 'target.value') || '', currentPromoMaterial: changedPromoMaterial, loading: true}, () => this.setFilterData())
  }

  getCodeSectionElement(key, defaults) {
    const {t} = this.props
    return <Trans {...messages.promoMaterialsDownloadAsset} values={{asset: t(key, defaults)}}/>
  }

  getLeftPanelContent(type) {
    const {classes, promoMaterial} = this.props
    const {open, currentPromoMaterial, loading, iframeLoading} = this.state
    const dynamicAsset = currentPromoMaterial || promoMaterial
    const {assetName, sourceFileUrl, sourceFilePreviewUrl, assetUrl} = dynamicAsset
    return type === promoMaterialFileTypes.video.key
      ? <React.Fragment>
        {iframeLoading && <Loading />}
        <iframe
          hidden={iframeLoading}
          src={open ? assetUrl : ''}
          title={assetName}
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          frameBorder='0'
          allowFullScreen
          width='100%'
          height='70%'
          onLoad={() => this.setState({iframeLoading: false})}
        />
      </React.Fragment>
      : loading
        ? <Loading/>
        : <img
          className={classNames(classes.itemImage, classes.flexCenter, classes.previewImage)}
          src={sourceFilePreviewUrl || sourceFileUrl}
          alt={assetName}
        />
  }

  getContent() {
    const {promoMaterial: {type}} = this.props
    const {filters} = this.state
    const content = {filters, message: '', codeSection: '', downloadTranslation: null, downloadExt: '', leftPanel: null}
    switch (type) {
      case promoMaterialTypes.banner.key:
        content.message = {...messages.promoMaterialsBannerDesc}
        content.downloadTranslation = this.getCodeSectionElement(messages.promoMaterialsBanner.i18nKey, messages.promoMaterialsBanner.defaults)
        content.downloadExt = promoMaterialFileTypes.jpeg.value
        content.codeSection = this.constructCodeSnippet(codeTemplates.hyperlink)
        content.leftPanel = this.getLeftPanelContent(promoMaterialFileTypes.jpeg.key)
        break
      case promoMaterialTypes.logo.key:
        content.message = {...messages.promoMaterialsLogoDesc}
        content.downloadTranslation = this.getCodeSectionElement(messages.promoMaterialsLogo.i18nKey, messages.promoMaterialsLogo.defaults)
        content.downloadExt = promoMaterialFileTypes.jpeg.value
        content.leftPanel = this.getLeftPanelContent(promoMaterialFileTypes.jpeg.key)
        break
      case promoMaterialTypes.presentation.key:
        content.message = {...messages.promoMaterialsPresentationDesc}
        content.downloadTranslation = this.getCodeSectionElement(messages.promoMaterialsPresentation.i18nKey, messages.promoMaterialsPresentation.defaults)
        content.downloadExt = promoMaterialFileTypes.pdf.value
        content.codeSection = this.constructCodeSnippet(codeTemplates.pdfIframe, 540, 584)
        content.leftPanel = this.getLeftPanelContent(promoMaterialFileTypes.jpeg.key)
        break
      case promoMaterialTypes.brochure.key:
        content.message = {...messages.promoMaterialsBrochureDesc}
        content.downloadTranslation = this.getCodeSectionElement(messages.promoMaterialsBrochure.i18nKey, messages.promoMaterialsBrochure.defaults)
        content.downloadExt = promoMaterialFileTypes.pdf.value
        content.codeSection = this.constructCodeSnippet(codeTemplates.pdfIframe, 540, 584)
        content.leftPanel = this.getLeftPanelContent(promoMaterialFileTypes.jpeg.key)
        break
      case promoMaterialTypes.landing_page.key:
        content.message = {...messages.promoMaterialsLandingPageDesc}
        content.codeSection = this.constructCodeSnippet(codeTemplates.url)
        content.leftPanel = this.getLeftPanelContent(promoMaterialFileTypes.jpeg.key)
        break
      case promoMaterialTypes.video.key:
        content.message = {...messages.promoMaterialsVideoDesc}
        content.codeSection = this.constructCodeSnippet(codeTemplates.videoIframe, 560, 315)
        content.leftPanel = this.getLeftPanelContent(promoMaterialFileTypes.video.key)
        break
      default:
    }
    return content
  }

  // Fetch the siblings of the current asset and set state accordingly
  handleClickOpen() {
    const {onClickFetchSiblings, promoMaterial} = this.props
    onClickFetchSiblings && onClickFetchSiblings().then(({data}) => {
      const sortedSiblings = sortBy(get(data, 'promoMaterials', []), ({id}) => id !== get(promoMaterial, 'id', ''))
      const currentPromoMaterial = first(sortedSiblings)
      const ibId = first(this.getIbIds())
      const {language, width, height} = currentPromoMaterial
      const size = `size_${width}_${height}`
      this.setState({siblings: sortedSiblings, currentPromoMaterial, language, size, ibId, loading: true, iframeLoading: true}, this.setFilterData())
    }).catch((_) => {
      this.setState({siblings: [], currentPromoMaterial: null, language: '', size: '', ibId: ''})
    })
    this.setState({open: true})
  }

  handleClose() {
    this.setState({open: false, siblings: [], currentPromoMaterial: null, language: '', size: '', ibId: ''})
  }

  constructCodeSnippet(template, defaultWidth = undefined, defaultHeight = undefined) {
    const {ibId, currentPromoMaterial} = this.state
    const {account} = this.props
    const partnerDetails = get(account, 'partnerDetails', [])
    const isIbRegistrationLink = includes(template, '{campaignUrl}') || includes(template, '{campaignUrlParams}')
    if (!currentPromoMaterial || !template) return ''
    const {sourceFilePreviewUrl, sourceFileUrl, width, height, language, assetUrl, assetName} = currentPromoMaterial
    if (isIbRegistrationLink) {
      const partnerDetailsUrl = get(find(partnerDetails, ({ibId: cIbId}) => cIbId === ibId), 'clientCampaignUrl')
      if (includes(template, '{campaignUrl}'))
        template = template.replace(/{campaignUrl}/g, partnerDetailsUrl)
      if (includes(template, '{campaignUrlParams}'))
        template = template.replace(/{campaignUrlParams}/g, partnerDetailsUrl?.substring(partnerDetailsUrl?.indexOf('?'), partnerDetailsUrl?.length))
    }
    return template
      .replace(/{assetUrl}/g, assetUrl)
      .replace(/{sourceFileUrl}/g, sourceFileUrl || sourceFilePreviewUrl)
      .replace(/{ibId}/g, ibId)
      .replace(/p2=(.*?)(?=&|$)/g, `p2=${ibId}`)
      .replace(/{language}/g, language)
      .replace(/{width}/g, defaultWidth || width ? `${defaultWidth || width}px` : '100%')
      .replace(/{height}/g, defaultHeight || height ? `${defaultHeight || height}px` : 'auto')
      .replace(/{assetName}/g, assetName)
  }

  downloadAsset(e, fileName, ext, link, id, type) {
    e.preventDefault()
    fetch(`${link}?timestamp=${Date.now()}`, {method: 'GET', cache: 'no-store'})
      .then((res) => {
        res.arrayBuffer().then((buffer) => {
          const urlExt = link.split('.')?.pop()
          const url = window.URL.createObjectURL(new Blob([buffer]))
          const linkTag = document.createElement('a')
          linkTag.href = url
          linkTag.setAttribute('download', `${fileName}.${includes(find(promoMaterialFileTypes, (t) => t.key === ext)?.types, urlExt) ? urlExt : ext}`)
          document.body.appendChild(linkTag)
          linkTag.click()
          // Clear up the link
          setTimeout(() => {
            document.body.removeChild(linkTag)
            window.URL.revokeObjectURL(url)
          }, 200)
        })
      })
      .catch((_) => {})
    const params = {
      assetId: id,
      assetName: fileName,
      assetType: type,
      platform: isMobile() ? 'mobile' : 'desktop',
      userId: get(this.context, 'clientId'),
    }
    logEventCustomParams('marketingAssetDownloadClicked', params)
  }

  copyCode(assetId, assetName, assetType) {
    this.setState({copyCode: true})
    const params = {
      assetId,
      assetName,
      assetType,
      platform: isMobile() ? 'mobile' : 'desktop',
      userId: get(this.context, 'clientId'),
    }
    logEventCustomParams('marketingAssetCopyClicked', params)
  }

  // We override the default close icon of the custom dialog component with a custom close icon
  customCloseComponent() {
    const {classes} = this.props
    return (
      <SvgIcon
        viewBox='-6 -6 24 24'
        className={classes.closeIcon}
        onClick={() => this.handleClose()}
        component={CloseIcon}/>
    )
  }

  render() {
    const {t, classes, promoMaterial, promoMaterial:{assetName, sourceFileUrl, sourceFilePreviewUrl}} = this.props
    const {currentPromoMaterial, copyCode} = this.state
    const dynamicAsset = currentPromoMaterial || promoMaterial
    const {id: currentAssetId, assetName: currentAssetName, sourceFileUrl: currentSourceFileUrl,
      assetDescription: currentAssetDescription, type: currentAssetType} = dynamicAsset
    const content = this.getContent()
    const themePreference = getCurrentTheme(this.context)
    const isDark = isDarkTheme(themePreference)
    return (
      <React.Fragment>
        <Grid container className={classes.imageContainer} onClick={() => this.handleClickOpen()}>
          <img
            className={classNames(classes.itemImage, classes.gridImage)}
            src={sourceFilePreviewUrl || sourceFileUrl}
            alt={assetName}/>
          <Typography className={classes.assetName} variant={'subtitle1'}>{assetName}</Typography>
        </Grid>
        <CustomDialog
          open={this.state.open}
          onClose={() => this.handleClose()}
          onCloseIcon={() => this.handleClose()}
          title={currentAssetName}
          maxWidth={'lg'}
          customCloseComponent={this.customCloseComponent()}
          customClasses={{
            dialogTitle: classes.dialogTitleRoot,
            dialogContent: classes.dialogContentRoot,
            closeIcon: classes.closeIcon,
            classes: {paper: classes.dialogPaper}
          }}
        >
          <Grid className={classes.customDialogContainer} container>
            <Grid className={classNames(classes.leftPanel, isDark ? 'dark' : '')} item xs sm>
              {content.leftPanel}
            </Grid>
            <Grid className={classNames(classes.flexColumn, classes.rightPanel)} item xs={12} sm={4}>
              {currentAssetDescription && <Box>
                <Typography className={classes.bold} variant={'body1'} gutterBottom>Description</Typography>
                <Typography className={classes.lineHeight} variant={'body1'} gutterBottom>{currentAssetDescription}</Typography>
              </Box>}
              <Box>
                <Typography className={classes.lineHeight} variant={'body1'} gutterBottom>
                  <Trans {...content.message} />
                </Typography>
              </Box>
              {!isEmpty(filter(content.filters, (f) => !f?.isHidden)) && <Box className={classes.flexColumn}>
                {map(content.filters, (f) => <Filter key={f.id} {...f}/>)}
              </Box>}
              {content.downloadExt && content.downloadTranslation && <Box className={classes.linkSection}>
                <Box className={classes.flexCenter}>
                  <a
                    className={classNames(classes.linkElement, classes.flexCenter)}
                    href={currentSourceFileUrl}
                    onClick={(e) => this.downloadAsset(e, currentAssetName, content.downloadExt, currentSourceFileUrl, currentAssetId, currentAssetType)}
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                    download>
                    <SvgIcon viewBox='-6 -6 24 24' component={DownloadButton} />
                    <Typography className={classes.linkText} variant='subtitle2'>
                      {content.downloadTranslation}
                    </Typography>
                  </a>
                </Box>
              </Box>}
              {content.codeSection && <Box className={classes.codeSection}>
                <Box className={classNames(classes.codeSnippetSection, isDark ? 'dark' : '')}>
                  <Typography className={classes.codeSnippetText} variant='caption'>
                    {content.codeSection}
                  </Typography>
                </Box>
                <Box className={classes.linkSection}>
                  <CopyToClipboard text={content.codeSection}>
                    <Box className={classNames(classes.linkElement, classes.flexCenter)}>
                      <SvgIcon viewBox='-6 -6 24 24' component={CopyCodeButton} />
                      <Typography className={classes.linkText} variant='subtitle2'
                        onClick={()=> this.copyCode(currentAssetId, currentAssetName, currentAssetType)}
                        onMouseLeave={()=> this.setState({copyCode: false})}>
                        <Trans {...messages.promoMaterialsCopyCode}/>
                      </Typography>
                      <Tooltip
                        open={copyCode}
                        title={t(messages.promoMaterialCodeCopied.i18nKey, messages.promoMaterialCodeCopied.defaults)}
                        placement='right'
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        onClose={() => this.setState({copyCode: false})}
                      >
                        <span />
                      </Tooltip>
                    </Box>
                  </CopyToClipboard>
                </Box>
              </Box>}
            </Grid>
          </Grid>
        </CustomDialog>
      </React.Fragment>
    )
  }
}

Asset.propTypes = {
  key: PropTypes.any,
  promoMaterial: PropTypes.object.isRequired,
  onClickFetchSiblings: PropTypes.func,
  innerFilterIds: PropTypes.array.isRequired,
  account: PropTypes.object,
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
)(Asset)
