const rtlOverrides = {
  direction: 'rtl',
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 16,
          marginRight: -14,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        formControl: {
          right: 0,
        },
        shrink: {
          transformOrigin: 'top right',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          textAlign: 'right',
          paddingTop: 11,
          paddingBottom: 11,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          marginLeft: 16,
          marginRight: 0,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          transform: 'scaleX(-1)',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          paddingLeft: 32,
          paddingRight: 0,
        },
        icon: {
          left: 0,
          right: 'unset',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
            backgroundColor: '#0C0C0C',
          },
          height: 'unset',
        },
      },
    },
  },
}

export default rtlOverrides

