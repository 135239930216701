import React, {Component} from 'react'
import {find, first, flowRight as compose, get, isEmpty, map} from 'lodash'
import {withStyles} from '@mui/styles'
import {Trans, withNamespaces} from 'react-i18next'
import AppContext from '../../Common/contexts/AppContext'
import PromoMaterialsRoutes from './routes'
import {Grid, Typography} from '@mui/material'
import Button from '@mui/material/Button'
import {promoMaterialTypes} from '@bdswiss/common-enums'
import classNames from 'classnames'
import messages from '../../../assets/messages'
import {getCurrentTheme, isDarkTheme} from '../../../common/utils'
import {graphql} from 'react-apollo'
import {CLIENT_DATA_QUERY} from '../../../graphql/queries'

const styles = theme => ({
  container: {
    gap: '40px',
    paddingTop: 'unset',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '8px',
      gap: '32px',
    },
  },
  pillsSection: {
    borderRadius: '8px',
    backgroundColor: '#F1F8FF',
    display: 'flex',
    justifyContent: 'center',
    padding: '24px !important',
    [theme.breakpoints.down('sm')]: {
      padding: '16px 16px !important',
    },
    '&.dark': {
      backgroundColor: '#051424',
    },
  },
  pillsSectionContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    [theme.breakpoints.down('sm')]: {
      gap: '0px',
    }
  },
  pillsContainerTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  pillsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      gap: '8px',
      padding: '0px 0px 16px !important',
    },
  },
  pillButton: {
    color: '#858585',
    borderRadius: '48px',
    backgroundColor: theme.palette.secondary.light,
    border: '1px solid #D6EAFF',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
    '&.dark': {
      backgroundColor: '#000000',
      border: '1px solid #0C3059',
      '&:hover': {
        backgroundColor: '#000000'
      },
    },
    '&.activePill': {
      border: `1px solid ${theme.palette.primary.main} !important`,
      color: theme.palette.primary.main,
    },
  },
  pillsTitle: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '34px',
    lineHeight: '40px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.25px',
    },
  },
  subRoutesContainer: {
    paddingLeft: '0px !important',
  },
  pillButtonTrans: {
    padding: '0px 8px',
  },
})

const pillItems = [{key: promoMaterialTypes.banner.key, title: messages.promoMaterialsBanners, relativePath: '/banners', disabled: false},
  {key: promoMaterialTypes.logo.key, title: messages.promoMaterialsLogos, relativePath: '/logos', disabled: false},
  {key: promoMaterialTypes.presentation.key, title: messages.promoMaterialsPresentations, relativePath: '/presentations', disabled: false},
  {key: promoMaterialTypes.video.key, title: messages.promoMaterialsVideos, relativePath: '/videos', disabled: false},
  {key: promoMaterialTypes.brochure.key, title: messages.promoMaterialsBrochures, relativePath: '/brochures', disabled: false},
  {key: promoMaterialTypes.landing_page.key, title: messages.promoMaterialsLandingPages, relativePath: '/landing-pages', disabled: false}]

class PromoMaterials extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {
      activePill: first(pillItems).key,
    }
  }

  setActivePill(key) {
    if (key) return this.setState({activePill: key})
    const {location} = this.props
    const path = get(location, 'pathname', '')
    const lastPath = path.substring(path.lastIndexOf('/'))
    if (lastPath === '/promo-materials') return this.setState({activePill: first(pillItems).key})
    const activePillItem = find(pillItems, (p) => p.relativePath === lastPath)
    this.setState({activePill: !isEmpty(activePillItem) ? activePillItem.key : ''})
  }

  componentDidMount() {
    // if (includes(promoMaterialsAllowedClientIds, toString(viewer.id))) {
    //   history.push('/partner-zone')
    // }
    this.setActivePill()

  }

  handlePillClick(key, relativePath) {
    const {history, match} = this.props
    history.push(`${match.url}${relativePath}`)
    this.setActivePill(key)
  }

  render() {
    const {classes} = this.props
    const {activePill} = this.state
    const themePreference = getCurrentTheme(this.context)
    const isDark = isDarkTheme(themePreference)
    return (
      <React.Fragment>
        <Grid className={classes.container} container direction='column'>
          <Grid className={classNames(classes.pillsSection, isDark ? 'dark' : '')} item xs>
            <Grid spacing={2} className={classes.pillsSectionContainer} direction='column' container>
              <Grid item xs className={classes.pillsContainerTitle}>
                <Typography className={classes.pillsTitle} variant='h2'>
                  <Trans {...messages.promoMaterialsFilterPillsTitle} />
                </Typography>
              </Grid>
              <Grid item xs className={classes.pillsContainer}>
                {
                  map(pillItems, ({key, title, relativePath, disabled}) =>
                    <Button
                      key={key}
                      onClick={() => this.handlePillClick(key, relativePath)}
                      disabled={disabled}
                      className={classNames(classes.pillButton, activePill === key ? 'activePill' : '', isDark ? 'dark' : '')}>
                      <span className={classes.pillButtonTrans}><Trans {...title}/></span>
                    </Button>)
                }
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs className={classes.subRoutesContainer}>
            <PromoMaterialsRoutes />
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading, viewer}}) => ({error, loading, viewer})
  }),
)(PromoMaterials)
