import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {flowRight as compose, get,} from 'lodash'
import {frontends} from '@bdswiss/common-enums'
import {withNamespaces, Trans} from 'react-i18next'
import Grid from '@mui/material/Grid'
import {getPlatform, getSourceBasedOnLocationPrevPath, logEventCustomParams, safeParseJSON} from '../../../../common/utils'
import {withCreateDeposit, PaymentActionButton} from './helpers'
import Typography from '@mui/material/Typography'
import messages from '../../../../assets/messages'

class _1_2_PAY extends Component {

  getDepositWalletAddress() {
    const {account, amount, providerProperties: {provider, paymentKey}, onError, useVirtualPaymentPage, bonusAmount, bonusTerms,
      history: {location}} = this.props

    const firebaseParams = {
      source: getSourceBasedOnLocationPrevPath(get(location, 'state', {prevPath: get(location, 'pathname')})),
      login: account.id,
      currency: account.currency,
      vendor: provider,
      amount,
    }
    logEventCustomParams('depositAttempted', firebaseParams)

    this.setState({gettingWalletAddress: true})
    const variables = {
      accountId: account.id,
      amount,
      vendor: provider,
      frontend: frontends.web2.value,
      args: JSON.stringify({
        platform: getPlatform(),
        depositCurrency: paymentKey,
        useVirtualPaymentPage,
        bonusAmount,
        bonusTerms,
      }),
    }

    this.props.createDepositRequest({variables})
      .then(({data: {newDeposit}}) => {
        const {payment: {url: data}} = newDeposit
        const parsedData = safeParseJSON(data) || data

        const qrCode = parsedData.qrCode
        this.setState({qrCode, disable:true})
      })
      .catch((e) => {
        this.setState({gettingWalletAddress: false})
        onError && onError(e)
      })
  }

  render() {
    const {canProceed} = this.props
    return (
      <React.Fragment>
        <Grid container alignItems="center" alignContent="center" justifyContent="center" spacing={3}>
          {!this.state?.disable &&<PaymentActionButton
            onClick={() => this.getDepositWalletAddress()}
            disable={canProceed || this.state?.disable}
          />
          }
          {this.state?.qrCode && <Grid container justifyContent="center" spacing={0}>
            <Typography variant="body2">
              <Trans {...messages.scanQRCode12Pay} />
            </Typography>
            <Grid item>
              <img alt='' src={'data:image/png;base64,' + this.state?.qrCode }/>
            </Grid>
          </Grid>
          }
        </Grid>
      </React.Fragment>
    )
  }
}

_1_2_PAY.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  amount: PropTypes.number.isRequired,
  providerProperties: PropTypes.shape({
    name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    paymentKey: PropTypes.string.isRequired,
  }).isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

export default compose(
  withNamespaces(),
  withCreateDeposit
)(_1_2_PAY)
