import {map, flowRight as compose, isEmpty} from 'lodash'
import React, {Component} from 'react'
import {withNamespaces} from 'react-i18next'
import {apTestSteps} from '@bdswiss/common-enums'
import {Typography} from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'
import messages from '../../../../assets/messages'
import withStyles from '@mui/styles/withStyles'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import AppContext from '../../../Common/contexts/AppContext'
import AppropTestExplanation from '../../../Common/AppropTestExplanationModal'
import AppropTestInitialModal from '../../../Common/AppropTestInitialModal'
import config from '../../../../config'

const styles = theme => ({
  appTestHeader : {
    marginTop:30
  },
  link: {
    color: theme.palette.primary.main,
  },
  supportText:{
    textAlign: 'center',
    paddingTop: '1rem',
    fontWeight: 400
  }
})

export class APTestHeader extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)

    this.state = {
      openPopup: !!(this.props.forced || this.props.afterDepositPage) && !config.cysecRegulator,
    }
  }

  componentDidMount() {
    this.setState({openPopupInitial: true})
  }

  handleOpen = () => {
    this.setState({openPopup: true})
  }

  handleClose = () => {
    this.setState({openPopup: false})
  }
  handleOpenInitial = () => {
    this.setState({openPopupInitial: true})
  }

  handleCloseInitial = () => {
    this.setState({openPopupInitial: false})
  }

  render() {
    const {classes,t, progress, activeStep, afterDepositPage, redirectedFromMigration, appropTest} = this.props
    const {locale} = this.context
    const {cysecRegulator} = config
    const showInitialMessage = cysecRegulator && isEmpty(appropTest)
    return (
      <div>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button onClick={this.handleOpen} align="right">
            <Typography align="right" variant="body2" color="primary"> {t(messages.whyIsThisNeeded.i18nKey, messages.whyIsThisNeeded.defaults)} </Typography>
          </Button>
        </Grid>
        <LinearProgress variant="determinate" color="primary" value={progress}/>
        <Typography variant="body1"> {activeStep + 1}/3 </Typography>
        {map(apTestSteps, (step, stepKey) =>
          activeStep === step.stepPosition && (<Typography key={stepKey} variant="h3"> {step.localization.t(locale)} </Typography>)
        )}
        <AppropTestExplanation
          open={this.state.openPopup}
          onClose={() => this.handleClose()}
          redirectedFromDeposit={afterDepositPage}
          redirectedFromMigration={redirectedFromMigration}
        />
        {showInitialMessage && <AppropTestInitialModal
          open={this.state.openPopupInitial}
          onClose={() => this.handleCloseInitial()}
          redirectedFromDeposit={afterDepositPage}
          redirectedFromMigration={redirectedFromMigration}
        />}
      </div>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces()
)(APTestHeader)
