import React, {Component} from 'react'
import {Trans} from 'react-i18next'
import {flowRight as compose} from 'lodash'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {CustomDialog} from '../Common/Dialog'
import messages from '../../assets/messages'
import withStyles from '@mui/styles/withStyles'
import AppContext from '../Common/contexts/AppContext'
import {companies} from '@bdswiss/common-enums'

const styles = theme => ({
  italic: {
    fontStyle: 'italic',
  }
})

export class CompanyRetailClosurePopup extends Component {
  static contextType = AppContext

  renderPopupContent() {
    const {classes} = this.props
    const {company, companyObject} = this.context
    const companyDetails = companies[company] || companies[companyObject?.key]

    return (
      <Box display='flex' flexDirection='column' gap='16px'>
        <Box>
          <Typography variant='body1' className={classes.italic}>
            <Trans {...messages.dearClient} />
          </Typography>
        </Box>
        <Box>
          <Typography variant='body1' className={classes.italic}>
            <Trans {...messages.companyRetailClosureText}
              values={{
                trademark: companyDetails?.trademark,
                company: companyDetails?.brandLabel
              }}
              components={[
                <strong key='0'> text </strong>
              ]}
            />
          </Typography>
        </Box>
        <Box>
          <Typography variant='body1' className={classes.italic}>
            <Trans {...messages.companyRetailClosureSubtext} />
          </Typography>
        </Box>
        <Box>
          <Typography variant='body1' className={classes.italic}>
            <Trans {...messages.weApologiseForInconvinienceCaused} />
          </Typography>
        </Box>
        <Box>
          <Typography variant='body1' className={classes.italic}>
            <Trans {...messages.thankYouInAdvance} />
          </Typography>
        </Box>
        <Box>
          <Typography variant='body1' className={classes.italic}>
            <Trans {...messages.kindRegards} />
          </Typography>
        </Box>
        <Box>
          <Typography variant='body1'>
            <Trans {...messages.companyTeam}
              values={{company: companyDetails?.brandLabel}}
            />
          </Typography>
        </Box>
      </Box>
    )
  }

  render() {
    return (
      <CustomDialog
        open={this.props.open}
        onClose={this.props.onClose}
        customClasses={{
          dialogContent: this.props.classes.overflowVisible,
          classes: {paperScrollPaper: this.props.classes.overflowVisible}
        }}
      >
        {this.renderPopupContent()}
      </CustomDialog>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
)(CompanyRetailClosurePopup)
