import React from 'react'
import {Redirect, Route, Switch, withRouter} from 'react-router-dom'
import Banners from './Banners'
import Brochures from './Brochures'
import LandingPages from './LandingPages'
import Logos from './Logos'
import Presentations from './Presentations'
import Videos from './Videos'

const PromoMaterialsRoutes = ({match: {path}}) =>
  <Switch>
    <Route exact path={`${path}/banners`} component={Banners} />
    <Route exact path={`${path}/logos`} component={Logos} />
    <Route exact path={`${path}/presentations`} component={Presentations} />
    <Route exact path={`${path}/videos`} component={Videos} />
    <Route exact path={`${path}/brochures`} component={Brochures} />
    <Route exact path={`${path}/landing-pages`} component={LandingPages} />
    <Route path={`${path}/:parentPath/:childPath+`} render={({match}) => <Redirect to={`${path}/${match.params.parentPath}`} />} />
    <Redirect from={`${path}`} to={`${path}/banners`} />
  </Switch>

export default withRouter(PromoMaterialsRoutes)
