import React, {Component} from 'react'
import {graphql} from 'react-apollo'
import {
  range,
  get,
  map,
  reject,
  includes,
  isEmpty,
  pickBy,
  size,
  startsWith,
  keys,
  omit,
  flowRight as compose,
  omitBy,
  has,
  forEach,
  filter,
  find,
  toLower,
} from 'lodash'
import {withRouter} from 'react-router-dom'
import withStyles from '@mui/styles/withStyles'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Check from '@mui/icons-material/Check'
import {withNamespaces, Trans} from 'react-i18next'
import {supportQuestions, yesNo, supportComplaintFormFields} from '@bdswiss/common-enums'
import PageTitle from '../Common/PageTitle'
import PageBody from '../Common/PageBody'
import messages from '../../assets/messages'
import chatIcon from '../../assets/images/icon-contact-chat@2x.png'
import config from '../../config'
import {
  scrollElementIntoView,
  putFile,
  isWhiteLabel,
  getCountryByTimeZone,
  isMobile,
  hasValidAccountSubTypes,
  logEventCustomParams,
} from '../../common/utils'
import {CLIENT_DATA_QUERY, FAQ_QUERY} from '../../graphql/queries'
import {CREATE_SUPPORT_TICKET, CREATE_COMPLAINT_FORM, SIGN_UPLOAD_URL_MUTATION} from '../../graphql/mutations'
import AppContext from '../Common/contexts/AppContext'
import LoadingButton from '../Common/LoadingButton'
import Images from '../Common/Images'
import {validateEmail, checkFileMimeType} from '../../common/utils/validations'
import moment from 'moment'
import CustomNotificationAlert from '../Common/CustomNotificationAlert'
import SelectDocument from '../Settings/Documents/SelectDocument'
import LivechatBubble from '../Common/LivechatBubble'
import classNames from 'classnames'
import {Loading} from '../Common/Loading'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import platform from 'platform'
import packageInfo from '../../../package.json'

const styles = theme => ({
  icon: {
    maxWidth: 44,
    maxHeight: 44,
  },
  listItemRoot: {
    [theme.direction === 'rtl' ? 'paddingRight' : 'paddingLeft']: 5,
  },
  listItemBorder: {
    borderBottom: `1px solid ${theme.palette.lightgrey.color}`,
  },
  pointer: {
    cursor: 'pointer',
  },
  checkMark: {
    color: theme.palette.secondary.main,
    transform: 'scale(0.8)',
  },
  instructionsTitle: {
    paddingLeft: 60,
  },
  instructionsStep: {
    fontSize: 16,
    color: '#000',
  },
  formControl: {
    [theme.breakpoints.up('sm')]: {
      marginTop: 22,
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(3),
    },
  },
  buttonsAlign: {
    textAlign: 'center',
  },
  hiddenInput: {
    display: 'none',
  },
  defaultImage: {
    width: 85,
  },
  uploadFileImage: {
    cursor: 'pointer',
  },
  container: {
    width: '100%',
    margin: 0,
  },
  noMargin: {
    marginTop: 0,
  },
  itemRoot: {
    paddingLeft: 0,
  },
  faqContent: {
    paddingBottom: 25,
    '& a': {
      color: theme.palette.primary.main,
    },
    '& em': {
      fontStyle: 'normal',
    },
  },
  faqContainer: {
    [theme.breakpoints.up('sm')]: {
      marginTop: 5,
    },
  },
  link: {
    color: theme.palette.primary.main,
  },
  helperTextRadioGroup: {
    fontWeight: 400,
  },
  helperTextTextfield: {
    fontWeight: 300,
    fontStyle: 'italic',
    letterSpacing: '0.4px',
    lineHeight: theme.spacing(2),
  },
  qrCode: {
    marginTop: 10,
    textAlign: 'center',
  },
  disabled: {
    opacity: 0.5,
  },
  complaintFormWrap: {
    [theme.breakpoints.up('sm')]: {
      marginTop: 22,
    },
  },
})

const SupportMethodDetailsGridId = 'support-method-details'

const WhatsappSupport = ({classes, company, phone}) => (
  <div>
    <Typography variant="h3" classes={{root: classes.instructionsTitle}}>
      <Trans {...messages.whatsappInstructionsTitle} />
    </Typography>
    {range(5).map(step => (
      <ListItem key={step} button>
        <ListItemIcon>
          <Typography variant="caption">{step + 1}. </Typography>
        </ListItemIcon>
        <ListItemText inset classes={{primary: classes.instructionsStep}}>
          <Typography variant="body1">
            <Trans
              {...messages[`whatsappInstructionsStep${step + 1}`]}
              values={{company: company.brandLabel, companyWhatsapp: phone}}
            />
          </Typography>
        </ListItemText>
      </ListItem>
    ))}
  </div>
)

const TelegramSupport = ({classes, company, phone}) => {
  const countSteps =
    (messages[`${company.brand}telegramInstructionsStep1`] &&
      size(pickBy(messages, (v, k) => includes(k, `${company.brand}telegramInstructionsStep`)))) ||
    size(pickBy(messages, (v, k) => startsWith(k, 'telegramInstructionsStep')))
  return (
    <div>
      <Typography variant="h3" classes={{root: classes.instructionsTitle}}>
        <Trans {...messages.telegramInstructionsTitle} />
      </Typography>
      {range(countSteps).map(step => (
        <ListItem key={step} button>
          <ListItemIcon>
            <Typography variant="caption">{step + 1}. </Typography>
          </ListItemIcon>
          <ListItemText inset classes={{primary: classes.instructionsStep}}>
            {messages[`${company.brand}telegramInstructionsStep${step + 1}`] && (
              <Typography variant="body1">
                <Trans
                  {...messages[`${company.brand}telegramInstructionsStep${step + 1}`]}
                  values={{company: company.brandLabel, companyTelegram: phone}}
                />
              </Typography>
            )}
            {!messages[`${company.brand}telegramInstructionsStep${step + 1}`] && (
              <Typography variant="body1">
                <Trans
                  {...messages[`telegramInstructionsStep${step + 1}`]}
                  values={{company: company.brandLabel, companyTelegram: phone}}
                />
              </Typography>
            )}
          </ListItemText>
        </ListItem>
      ))}
    </div>
  )
}

const CallBackSupport = ({classes, company, history}) => (
  <Grid container spacing={3}>
    <Grid container item justifyContent="center">
      <Typography variant="h3">
        <Trans {...messages.bookCall} />
      </Typography>
    </Grid>
    <Grid container item justifyContent="center">
      <Typography variant="body1">
        <Trans {...messages.bookCallp1} values={{company: company.brandLabel}} />
      </Typography>
    </Grid>
    <Grid container item justifyContent="center">
      <Typography variant="body1">
        <Trans {...messages.bookCallp2} />
      </Typography>
    </Grid>
    <Grid container spacing={1} item direction="row" justifyContent="center" alignItems="center">
      <Grid item className={classes.buttonsAlign}>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => history.push({pathname: '/callback', state: {type: 'support', prevPath: 'support'}})}
        >
          <Trans {...messages.requestCallback} />
        </Button>
      </Grid>
    </Grid>
  </Grid>
)

const OneOnOneCallback = ({classes, history}) => (
  <Grid container spacing={3}>
    <Grid container item justifyContent="center">
      <Typography variant="h3">
        <Trans {...messages.oneOnOne} />
      </Typography>
    </Grid>
    <Grid container item justifyContent="center">
      <Typography variant="body1">
        <Trans {...messages.oneOnOnep1} />
      </Typography>
    </Grid>
    <Grid container spacing={1} item direction="row" justifyContent="center" alignItems="center">
      <Grid item className={classes.buttonsAlign}>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => history.push({pathname: '/callback', state: {type: 'oneOnOne', prevPath: '/support'}})}
        >
          <Trans {...messages.requestCallback} />
        </Button>
      </Grid>
    </Grid>
  </Grid>
)

const FAQs = ({classes, faqs, activeQuestion, selectQuestion, clientId}) => (
  <Grid container>
    <Grid item xs={12}>
      <Typography variant="h3" className={classes.noMargin}>
        <Trans {...messages.frequentlyAskedQuestions} />
      </Typography>
    </Grid>
    <Grid item xs={12} className={classes.faqContainer}>
      <List>
        {map(faqs, (faq, key) => (
          <React.Fragment key={key}>
            <ListItem
              classes={{
                root: classNames(classes.itemRoot, classes.pointer, activeQuestion !== key && classes.listItemBorder),
              }}
              onClick={() => selectQuestion(key)}
            >
              <ListItemText>
                <Typography variant="subtitle1">{faq.faqTitle}</Typography>
              </ListItemText>
              {activeQuestion === key ? (
                <ExpandMoreIcon className={classes.checkMark} />
              ) : (
                <ChevronRightIcon className={classes.checkMark} />
              )}
            </ListItem>
            {activeQuestion === key && (
              <Typography variant="body1" className={classNames(classes.listItemBorder, classes.faqContent)}>
                {logEventCustomParams('faqClicked', {
                  questionName: get(faq, 'faqTitle'),
                  platform: isMobile() ? 'mobile' : 'web',
                  userId: clientId,
                })}
                <span dangerouslySetInnerHTML={{__html: faq.faqContent.replace(/\n/g, '<br />')}} />
              </Typography>
            )}
          </React.Fragment>
        ))}
        <ListItem
          classes={{root: classNames(classes.itemRoot, classes.pointer, classes.listItemBorder)}}
          onClick={() =>
            window.open(
              `${get(config, 'weblinks.websiteUrl')}/${get(config, 'featuresConfig.contactMethods.faqs.url')}`,
              '_blank',
            )
          }
        >
          <ListItemText>
            <Typography variant="subtitle1" className={classes.link}>
              <Trans {...messages.seeAllFaqs} />
            </Typography>
          </ListItemText>
        </ListItem>
      </List>
    </Grid>
  </Grid>
)

const LineMessaging = ({classes, url}) => (
  <Grid container>
    <Grid item xs={12}>
      <Typography variant="h3" className={classes.noMargin}>
        <Trans {...messages.line} />
      </Typography>
    </Grid>
    <Grid item xs={12} className={classes.faqContainer}>
      <Typography variant="body1">
        <Trans {...messages.lineMessagingDescription} />
      </Typography>
    </Grid>
    <Grid item xs={12} className={classes.qrCode}>
      {!isMobile() ? (
        <img src={Images['lineQRcode.png']} alt="lineQRcode" width={260} height={260} />
      ) : (
        <Button variant="contained" color="primary" className={classes.downloadButton} href={url} target="_blank">
          <Trans {...messages.joinLineMessaging} />
        </Button>
      )}
    </Grid>
  </Grid>
)

class EmailSupportComponent extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    const startDate = moment(new Date().setDate(new Date().getDate() - 30)).format('DD/MM/YYYY')
    const endDate = moment(new Date()).format('DD/MM/YYYY')
    this.state = {
      topic:
        props.location.search.indexOf('complaint') > 0
          ? 'complaintQuestion'
          : props.location.search.indexOf('account') > 0
            ? 'accountQuestion'
            : '',
      sendLoading: false,
      sendStatus: '',
      message:
        props.location.search.indexOf('account') > 0
          ? props.t(messages.proFreeTicks.i18nKey, {startDate: startDate, endDate: endDate})
          : '',
      uploadedFile: '',
      errorFiles: {},
    }
  }

  handleFileUpload(prop, e, files) {
    const filesToUpload = get(e, 'target.files') ? e.target.files : files
    this.setState({[prop]: filesToUpload, fileChecked: false})
    const {errorFiles} = this.state
    map(filesToUpload, file => {
      try {
        checkFileMimeType(file).then(res => {
          this.setState({fileChecked: true})
          const fileFormat = res && res.indexOf('image') === -1 && res.indexOf('pdf') === -1
          const fileSize = file.size > 1 * 1024 * 1024
          if (fileFormat || fileSize) {
            logEventCustomParams('documentUploadError', {
              location: 'support',
              reason: 'wrong fileFormat or fileSize',
              userId: get(this.context, 'clientId'),
            })
            this.setState(prevState => ({
              errorFiles: {
                ...prevState.errorFiles,
                [prop]: {
                  name: file.name,
                  error: fileFormat ? 'format' : 'size',
                },
              },
            }))
          } else {
            const errors = omit(errorFiles, [prop])
            this.setState({errorFiles: errors})
          }
        })
      } catch (e) {
        console.log(e) /* eslint-disable-line */
      }
    })
  }

  async getSignedFileKeys() {
    const {clientId, signUploadUrl} = this.props
    const {uploadedFile} = this.state
    return Promise.all(
      map(uploadedFile, async file =>
        signUploadUrl({variables: {clientId}}).then(res => {
          const {signedUrl, plainUrl} = res.data.signedDetails
          return putFile(file, signedUrl).then(() => plainUrl)
        }),
      ),
    )
  }

  async sendMessage(context) {
    const {name, phone, topic, message, uploadedFile} = this.state
    const {email} = this.props
    const {locale} = context
    const {companyObject} = this.context
    const {complaintsEmail, supportEmail} = config
    this.setState({sendLoading: true})

    let to = `${supportQuestions[topic].email}@${companyObject.domain}`
    if (isWhiteLabel()) {
      switch (topic) {
        case 'complaintQuestion':
          to = complaintsEmail
          break
        default:
          to = supportEmail
      }
    }

    const subject = supportQuestions[topic].localization.t(locale)
    const fileLinks = !isEmpty(uploadedFile) && (await this.getSignedFileKeys())
    const deviceTimezone = get(Intl.DateTimeFormat()?.resolvedOptions(), 'timeZone')

    this.props
      .createOwnSupportTicket({
        variables: {
          name,
          email,
          phone,
          topic: subject,
          message,
          to,
          attachments: fileLinks,
          appVersion: process.env.BUILD_NUMBER || packageInfo.version,
          ...Object.fromEntries(
            Object.entries({
              browserName: get(platform, 'name'),
              browserEngine: get(platform, 'layout'),
              browserVersion: get(platform, 'version'),
              deviceManufacturer: get(platform, 'manufacturer'),
              deviceSystemName: get(platform, 'os.family'),
              deviceSystemVersion: get(platform, 'os.version'),
              deviceArchitecture: get(platform, 'os.architecture'),
              deviceCountry: getCountryByTimeZone(deviceTimezone),
              deviceLocale: get(navigator, 'language'),
              deviceTimezone,
            }).filter(([, value]) => !isEmpty(value)),
          ),
        },
      })
      .then(() => {
        this.setState({name: '', phone: '', topic: '', message: '', sendLoading: false, sendStatus: 'success'})
        const params = {
          isLoggedIn: 'true',
          type: 'email',
          platform: isMobile() ? 'mobile' : 'web',
          userId: get(this.context, 'clientId'),
        }
        logEventCustomParams('supportRequestSent', params)
        context.showNotification({
          type: 'document-upload',
          status: 'success',
          content: <Trans {...messages.supportMessageSuccess} />,
          buttonMessage: <Trans {...messages.continue} />,
          onClose: () => this.props.history.push('/'),
        })
      })
  }

  render() {
    const {
      t,
      classes,
      context,
      context: {locale, company},
      email,
    } = this.props
    const {sendLoading, sendStatus, uploadedFile, errorFiles} = this.state
    const {themePreference} = this.context

    return (
      <Grid container>
        <Typography variant="h3" className={classes.noMargin}>
          <Trans {...messages.contactForm} />
        </Typography>
        <FormControl variant="standard" className={classes.formControl} fullWidth>
          <TextField
            variant="standard"
            required
            label={t(messages.name.i18nKey, messages.name.defaults)}
            value={this.state.name}
            onChange={e => this.setState({name: e.target.value})} />
        </FormControl>
        <Grid container spacing={2}>
          <Grid item xs={6} lg={6}>
            <FormControl
              variant="standard"
              className={classNames(classes.formControl, classes.disabled)}
              fullWidth>
              <TextField
                variant="standard"
                required
                label={t(messages.email.i18nKey, messages.email.defaults)}
                value={email}
                disabled />
            </FormControl>
          </Grid>
          <Grid item xs={6} lg={6}>
            <FormControl variant="standard" className={classes.formControl} fullWidth>
              <TextField
                variant="standard"
                label={t(messages.phone.i18nKey, messages.phone.defaults)}
                value={this.state.phone}
                onChange={e => this.setState({phone: e.target.value})} />
            </FormControl>
          </Grid>
        </ Grid>
        <FormControl variant="standard" className={classes.formControl} fullWidth>
          <InputLabel required htmlFor="supportTopic">
            <Trans {...messages.supportYourQuestion} />
          </InputLabel>
          <Select
            variant="standard"
            value={this.state.topic}
            onChange={e => {
              this.setState({topic: e.target.value})
            }}
            inputProps={{id: 'supportTopic'}}>
            {map(
              reject(supportQuestions, q => {
                if (q.disabled || includes(q.blockedCompanies, company)) {
                  return q
                }
              }),
              m => (
                <MenuItem value={m.key} key={m.key}>
                  {m.localization.t(locale)}
                </MenuItem>
              ),
            )}
          </Select>
        </FormControl>
        <FormControl variant="standard" className={classes.formControl} fullWidth>
          <TextField
            variant="standard"
            required
            multiline
            label={t(messages.supportYourMessage.i18nKey, messages.supportYourMessage.defaults)}
            value={this.state.message}
            onChange={e => this.setState({message: e.target.value})} />
        </FormControl>
        <FormControl variant="standard" className={classes.formControl} fullWidth>
          <Grid container>
            <Grid item md={5}>
              <SelectDocument
                multiple
                uploadedFile={uploadedFile}
                onChange={(e, files) => this.handleFileUpload('uploadedFile', e, files)}
                category={`document-${themePreference}`}
                status={
                  uploadedFile && uploadedFile.length > 0
                    ? includes(keys(errorFiles), 'uploadedFile')
                      ? 'error'
                      : 'success'
                    : ''
                }
                errors={!isEmpty(errorFiles) && errorFiles.uploadedFile}
                src={Images[`upload-document-${themePreference}.png`]}
                fileKey="1"
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={12}>
              <Typography variant="caption">
                {' '}
                <Trans {...messages.uploadRules} />{' '}
              </Typography>
            </Grid>
          </Grid>
        </FormControl>
        <LoadingButton
          id="sendEmailMessageButton"
          onClick={() => this.sendMessage(context)}
          disabled={
            !this.state.name ||
            !email ||
            !this.state.topic ||
            !this.state.message ||
            sendLoading ||
            !isEmpty(errorFiles)
          }
          status={sendStatus}
          hideProgressBar={
            (!this.state.name || !email || !this.state.topic || !this.state.message || !isEmpty(errorFiles)) &&
            !sendLoading
          }
        >
          <Trans {...messages.sendMessage} />
        </LoadingButton>
      </Grid>
    )
  }
}

const EmailSupport = compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  graphql(CREATE_SUPPORT_TICKET, {
    name: 'createOwnSupportTicket',
  }),
  graphql(SIGN_UPLOAD_URL_MUTATION, {
    name: 'signUploadUrl',
  }),
  withRouter,
)(EmailSupportComponent)

class ComplaintFormComponent extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)

    const form = {}
    const formErrors = {}
    forEach(supportComplaintFormFields, field => {
      form[field.key] = ''

      if (field.required) {
        formErrors[field.key] = false
      }
    })

    this.state = {
      form,
      formErrors,
    }
  }

  handleFormChange(key, value) {
    const existingForm = this.state.form
    const existingFormErrors = this.state.formErrors
    const requiredFields = map(filter(supportComplaintFormFields, {required: true}), 'key')

    if (includes(requiredFields, key) && value === '') {
      this.setState({
        formErrors: {
          ...existingFormErrors,
          [key]: true,
        },
      })
    } else if (includes(requiredFields, key) && value !== '') {
      this.setState({
        formErrors: {
          ...existingFormErrors,
          [key]: false,
        },
      })
    }

    this.setState(
      {
        form: {
          ...existingForm,
          [key]: value,
        },
      },
      () => this.validateForm(),
    )
  }

  validateForm() {
    const requiredFields = map(
      filter(supportComplaintFormFields, field => {
        if (
          field.required &&
          has(field, 'prerequiredField') &&
          this.state.form[field.prerequiredField] === field.prerequiredValue
        ) {
          if (this.state.form[field.key] === '') return field
        } else if (field.required && !has(field, 'prerequiredField')) {
          if (this.state.form[field.key] === '') return field
          if (field.validateEmail && !validateEmail(this.state.form[field.key])) return field
        }
      }),
      'key',
    )

    const removeFields = reject(supportComplaintFormFields, field => !includes(requiredFields, field.key))

    this.setState({
      formErrors: map(removeFields, 'key').reduce((acc, key) => {
        acc[key] = true
        return acc
      }, {}),
    })
  }

  handleFileUpload(key, e, files) {
    const filesToUpload = get(e, 'target.files') ? e.target.files : files
    this.setState({
      form: {
        ...this.state.form,
        [key]: filesToUpload,
      },
      fileChecked: false,
    })
    const {errorFiles} = this.state
    map(filesToUpload, file => {
      try {
        checkFileMimeType(file).then(res => {
          this.setState({fileChecked: true})
          const fileSize = file.size > 1 * 1024 * 1024
          if (fileSize) {
            this.setState(prevState => ({
              errorFiles: {
                ...prevState.errorFiles,
                [key]: {
                  name: file.name,
                  error: 'size',
                },
              },
            }))
          } else {
            const errors = omit(errorFiles, [key])
            this.setState({errorFiles: errors})
          }
        })
      } catch (e) {
        console.log(e) /* eslint-disable-line */
      }
    })
  }

  async getSignedFileKeys() {
    const {clientId, signUploadUrl} = this.props
    const attachmentField = find(supportComplaintFormFields, {type: 'attachment'})

    return Promise.all(
      map(this.state.form[attachmentField.key], async file =>
        signUploadUrl({variables: {clientId}}).then(res => {
          const {signedUrl, plainUrl} = res.data.signedDetails
          return putFile(file, signedUrl).then(() => plainUrl)
        }),
      ),
    )
  }

  async sendComplaintForm() {
    const {complaintsEmail} = config
    this.setState({sendLoading: true})

    this.validateForm()

    const attachmentField = find(supportComplaintFormFields, {type: 'attachment'})
    const fileLinks = !isEmpty(this.state.form[attachmentField.key]) ? await this.getSignedFileKeys() : []

    const form = {
      ...this.state.form,
      complaintSubject: messages.complaintForm.defaults,
      complaintEmail: complaintsEmail,
      [attachmentField.key]: fileLinks,
    }

    this.props.createOwnComplaintForm({variables: {...form}}).then(() => {
      this.setState({sendLoading: false})
      this.context.showNotification({
        type: 'document-upload',
        status: 'success',
        content: <Trans {...messages.supportMessageSuccess} />,
        buttonMessage: <Trans {...messages.continue} />,
        onClose: () => this.props.history.push('/'),
      })
    })
  }

  render() {
    const {classes} = this.props
    const {locale, themePreference} = this.context

    return (
      <Box display="flex" flexDirection="column">
        <Box>
          <Typography variant="h3" className={classes.noMargin}>
            {' '}
            Complaint Form{' '}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap="16px" className={classes.complaintFormWrap}>
          {map(supportComplaintFormFields, field => {
            if (field.type === 'textfield') {
              if (
                (has(this.state.form, field.prerequiredField) &&
                  this.state.form[field.prerequiredField] === field.prerequiredValue) ||
                !field.prerequiredField
              ) {
                return (
                  <FormControl variant="standard" fullWidth key={`complaint-form-${field.key}`}>
                    <TextField
                      variant="standard"
                      required={field.required}
                      value={this.state.form[field.key]}
                      label={field.localization.t(locale)}
                      helperText={has(field, 'helperText') && field.helperText.localization.t(locale)}
                      FormHelperTextProps={{
                        classes: {
                          root: classes.helperTextTextfield,
                        },
                      }}
                      onChange={e => this.handleFormChange(field.key, e.target.value)}
                    />
                  </FormControl>
                )
              }
            } else if (field.type === 'radiogroup') {
              const helperText = find(supportComplaintFormFields[field.key].helperText, {
                value: this.state.form[field.key],
              })

              return (
                <Box display="flex" flexDirection="column" gap="16px">
                  <FormControl variant="standard" name={field.key} key={`complaint-form-${field.key}`}>
                    <Box display="flex" flexDirection="column" gap="8px">
                      <FormLabel>{field.localization.t(locale)}</FormLabel>
                      <RadioGroup
                        row
                        name={field.key}
                        value={this.state[field.key]}
                        onChange={e => this.handleFormChange(field.key, e.target.value)}
                      >
                        {map(yesNo, option => (
                          <FormControlLabel
                            key={option.key}
                            value={option.value}
                            control={<Radio />}
                            label={option.localization.t(locale)}
                          />
                        ))}
                      </RadioGroup>
                    </Box>
                  </FormControl>
                  {helperText ? (
                    <Typography className={classes.helperTextRadioGroup} variant="body1">
                      {' '}
                      {helperText.localization.t(locale)}{' '}
                    </Typography>
                  ) : (
                    ''
                  )}
                </Box>
              )
            } else if (field.type === 'attachment') {
              if (
                (has(this.state.form, field.prerequiredField) &&
                  this.state.form[field.prerequiredField] === field.prerequiredValue) ||
                !field.prerequiredField
              ) {
                return (
                  <Box display="flex" flexDirection="column" gap="16px">
                    <FormLabel>{field.localization.t(locale)}</FormLabel>
                    <SelectDocument
                      multiple
                      uploadedFile={this.state.form[field.key]}
                      onChange={(e, files) => this.handleFileUpload(field.key, e, files)}
                      category={`document-${themePreference}`}
                      status={
                        this.state.form[field.key] && this.state.form[field.key].length > 0
                          ? includes(keys(this.state.errorFiles), 'uploadedFile')
                            ? 'error'
                            : 'success'
                          : ''
                      }
                      src={Images['upload-icon.png']}
                      fileKey="1"
                    />
                  </Box>
                )
              }
            }
          })}
        </Box>
        <Box>
          <LoadingButton
            disabled={
              this.state.sendLoading ||
              !isEmpty(omitBy(this.state.formErrors, value => value === false)) ||
              isEmpty(reject(this.state.form, value => value === ''))
            }
            fullWidth
            hideProgressBar={!this.state.sendLoading}
            onClick={() => this.sendComplaintForm()}
          >
            <Trans {...messages.submit} />
          </LoadingButton>
        </Box>
      </Box>
    )
  }
}

const ComplaintForm = compose(
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
  graphql(SIGN_UPLOAD_URL_MUTATION, {
    name: 'signUploadUrl',
  }),
  graphql(CREATE_COMPLAINT_FORM, {
    name: 'createOwnComplaintForm',
  }),
)(ComplaintFormComponent)

class Support extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    const {
      featuresConfig: {contactMethods},
    } = config
    const hasFaq = get(contactMethods, 'faqs') && !get(contactMethods, 'faqs.disabled')
    const availableMethods = omitBy(contactMethods, method => method.disabled)
    let initialActiveMethod
    if (props.location.search.indexOf('complaint') > 0 ) {
      initialActiveMethod = 'complaintForm'
    } else if (props.location.search.indexOf('account') > 0) {
      initialActiveMethod = 'email'
    } else {
      initialActiveMethod = Object.keys(availableMethods)[0]
    }
    this.state = {
      activeMethod: initialActiveMethod ? initialActiveMethod : hasFaq ? 'faqs' : '',
    }
  }

  startLiveChat() {
    const params = {
      isLoggedIn: 'true',
      type: 'chat',
      platform: isMobile() ? 'mobile' : 'web',
      userId: get(this.context, 'clientId'),
    }
    logEventCustomParams('supportRequestSent', params)
    this.setState({activeMethod: 'liveChat'})
  }

  handleSupportMethodChange(activeMethod) {
    this.setState({activeMethod}, () => scrollElementIntoView(SupportMethodDetailsGridId, 250))
  }

  selectLineMessaging() {
    const params = {
      isLoggedIn: 'true',
      type: 'lineMessaging',
      platform: isMobile() ? 'mobile' : 'web',
      userId: get(this.context, 'clientId'),
    }
    logEventCustomParams('supportRequestSent', params)
    this.setState({activeMethod: 'lineMessaging'})
  }

  render() {
    const {classes, t, viewer: {email, firstName, lastName, address}, history, faqs, loading,
      loadingFaq} = this.props
    const country = toLower(get(address, 'country', ''))
    const {activeMethod, activeQuestion} = this.state
    const {
      featuresConfig: {livechat, contactMethods, lineMessaging},
    } = config
    const fullName = [firstName, lastName].join(' ')
    const {companyObject, accounts, themePreference} = this.context
    const open_live_chat= true

    if (loading || loadingFaq) return <Loading />
    const showLineMessagingOption = lineMessaging && includes(get(lineMessaging, 'countriesAvailable'), country)

    return (
      <React.Fragment>
        {activeMethod === 'liveChat' && <LivechatBubble openLiveChat={open_live_chat} fullName={fullName} email={email} />}
        <PageTitle
          withoutPadding={isMobile()}
          themePreference={themePreference}
          hideArrow={!isMobile()}
          title={t(messages.support.i18nKey, messages.support.defaults)}
          onBack={() => isMobile ? history.push('/more') : history.push('/accounts')}
        />
        <PageBody>
          <Grid container>
            <Grid item xs={12}>
              <CustomNotificationAlert support />
            </Grid>
          </Grid>
          <Grid container pt={isMobile() ? 3 : 2} className={classes.container}>
            <Grid item xs={12} lg={5}>
              <Typography variant="h3" className={classes.noMargin}>
                <Trans {...messages.supportMethodsSubtitle} />
              </Typography>
              <List>
                {map(
                  pickBy(contactMethods, c => !c.disabled),
                  (method, key) => {
                    if (method.accountSubTypes && !hasValidAccountSubTypes(accounts, method.accountSubTypes)) {
                      return
                    }
                    return (
                      <React.Fragment key={key}>
                        <ListItem
                          classes={{root: classNames(classes.listItemRoot, classes.pointer, classes.listItemBorder)}}
                          onClick={() => this.handleSupportMethodChange(key)}
                        >
                          <ListItemIcon>
                            <img src={Images[`icon-contact-${method.key}@2x.png`]} alt="" className={classes.icon} />
                          </ListItemIcon>
                          <ListItemText>
                            <Trans {...messages[method.key]} />
                          </ListItemText>
                          {activeMethod === key && <Check className={classes.checkMark} />}
                        </ListItem>
                      </React.Fragment>
                    )
                  },
                )}
                {livechat && (
                  <React.Fragment>
                    <ListItem
                      classes={{root: classNames(classes.listItemRoot, classes.pointer, classes.listItemBorder)}}
                      onClick={() => this.startLiveChat()}
                    >
                      <ListItemIcon>
                        <img src={chatIcon} alt="" className={classes.icon} />
                      </ListItemIcon>
                      <ListItemText>
                        <Trans {...messages.liveChat} />
                      </ListItemText>
                      {activeMethod === 'liveChat' && <Check className={classes.checkMark} />}
                    </ListItem>
                  </React.Fragment>
                )}
                {showLineMessagingOption && (
                  <React.Fragment>
                    <ListItem
                      classes={{root: classNames(classes.listItemRoot, classes.pointer, classes.listItemBorder)}}
                      onClick={() => this.selectLineMessaging()}
                    >
                      <ListItemIcon>
                        <img src={chatIcon} alt="" className={classes.icon} />
                      </ListItemIcon>
                      <ListItemText>
                        <Trans {...messages.line} />
                      </ListItemText>
                      {activeMethod === 'lineMessaging' && <Check className={classes.checkMark} />}
                    </ListItem>
                  </React.Fragment>
                )}
              </List>
            </Grid>
            <Grid id={SupportMethodDetailsGridId} item xs={12} lg={6} sx={{pt: {xs: 2, sm: 0}, pl: {md: 3}}}>
              {activeMethod === 'whatsapp' && (
                <WhatsappSupport classes={classes} company={companyObject} phone={contactMethods.whatsapp.phone} />
              )}
              {activeMethod === 'telegram' && (
                <TelegramSupport classes={classes} company={companyObject} phone={contactMethods.telegram.phone} />
              )}
              {activeMethod === 'email' && (
                <EmailSupport context={this.context} clientId={this.props.viewer.id} email={email} />
              )}
              {activeMethod === 'callback' && (
                <CallBackSupport classes={classes} company={companyObject} history={history} />
              )}
              {activeMethod === 'oneOnOneSession' && (
                <OneOnOneCallback classes={classes} history={history} company={companyObject} />
              )}
              {activeMethod === 'complaintForm' && <ComplaintForm history={history} clientId={this.props.viewer.id} />}
              {activeMethod === 'lineMessaging' && (
                <LineMessaging
                  classes={classes}
                  history={history}
                  clientId={this.props.viewer.id}
                  url={get(lineMessaging, 'url')}
                />
              )}
              {activeMethod === 'faqs' && (
                <FAQs
                  classes={classes}
                  history={history}
                  company={companyObject}
                  faqs={faqs}
                  activeQuestion={activeQuestion}
                  selectQuestion={key => this.setState({activeQuestion: activeQuestion === key ? '' : key})}
                  clientId={get(this.props, 'viewer.id')}
                />
              )}
            </Grid>
          </Grid>
        </PageBody>
      </React.Fragment>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  withRouter,
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {loading, error}, data}) => {
      const viewer = get(data, 'viewer')
      return {
        loading,
        error,
        viewer,
      }
    },
  }),
  graphql(FAQ_QUERY, {
    options: () => ({
      fetchPolicy: 'network-only',
      variables: {customDomain: get(config, 'featuresConfig.contactMethods.faqs.customDomain')},
    }),
    props: ({data: {loading, error}, data}) => ({
      loadingFaq: loading,
      errorFaq: error,
      faqs: get(data, 'faq'),
    }),
  }),
)(Support)
