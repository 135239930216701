import {getAnalytics, logEvent} from 'firebase/analytics'
import * as firebase from 'firebase/app'
import {get, isEmpty} from 'lodash'
import config from '../../config'

export const firebaseInit = () => {
  if (config.firebase) {
    return firebase.initializeApp({
      apiKey: config.firebase.apiKey,
      authDomain: config.firebase.authDomain,
      projectId: config.firebase.projectId,
      storageBucket: config.firebase.storageBucket,
      messagingSenderId: config.firebase.messagingSenderId,
      appId: config.firebase.appId,
      measurementId: config.firebase.measurementId
    })
  }
}

export const logEventCustomParams = (eventName, params) => {
  if (!isEmpty(firebase.getApps()) && config.firebase) {
    const analytics = getAnalytics()
    return logEvent(analytics, eventName, params)
  }
}

export const getSourceBasedOnLocationPrevPath = (locationState) => {
  const prevPath = get(locationState, 'prevPath', '')

  switch (prevPath) {
    case '/dashboard': {
      return 'Home'
    }
    default: {
      return 'Direct'
    }
  }
}
