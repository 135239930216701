import React, {Component} from 'react'
import {Link, Redirect} from 'react-router-dom'
import {graphql} from 'react-apollo'
import classnames from 'classnames'
import {get, some, isEmpty, size, includes, flowRight as compose, endsWith} from 'lodash'
import {withNamespaces, Trans} from 'react-i18next'
import moment from 'moment'
import {countries, apiErrors} from '@bdswiss/common-enums'
import {LinearProgress} from '@mui/material'
import Grid from '@mui/material/Grid'
import Hidden from '@mui/material/Hidden'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import Header from '../App/Header'
import config from '../../config'
import messages from '../../assets/messages'
import {loginRequest, logoutRequest, getConfigVars} from '../../common/utils/requests'
import {CLIENT_DATA_QUERY} from '../../graphql/queries'
import {storeSessInfoInLocalStorage, removeSessInfoFromLocalStorage, getItem, findCompany} from '../../common/utils'
import PageTitle from '../Common/PageTitle'
import {getCookie} from '../../common/utils/browser'
import queryString from 'qs'
import NotificationBar from '../Common/NotificationBar'
import UiNotification from '../Common/UiNotification'
import AppContext from '../Common/contexts/AppContext'
import Password from '../Common/Password'
import ReCAPTCHA from 'react-google-recaptcha'
import LoadingButton from '../Common/LoadingButton'
import {firebaseInit, logEventCustomParams} from '../../common/utils/firebaseEvents'
import LivechatBubble from '../Common/LivechatBubble'

const styles = theme => ({
  mainBody:{
    maxWidth: theme.mainBodyWidth,
    margin:'auto',
    minHeight: '100%',
    position: 'relative',
  },
  wrapper: {
    maxWidth: 450,
    margin: 30
  },
  grid: {
    marginBottom: 40,
  },
  errorGrid: {
    marginBottom: 15,
  },
  error: {
    color: theme.palette.error.main
  },
  icon: {
    marginRight: 8,
    color: '#FF6600'
  },
  formControl: {
    flexBasis: 200,
  },
  signupBtn: {
    padding: 0,
    height: 44,
  },
  highlightedText:{
    fontWeight: 400,
  },
  pointer:{
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  header: {
    paddingLeft: 0
  },
  registerBtn: {
    cursor: 'pointer',
  }
})

const recaptchaRef = React.createRef()

class Login extends Component {
  static contextType = AppContext
  state = {
    form: {
      email: '',
      password: '',
    },
    showPassword: false,
    loginError: '',
    errors: {},
    showEmbeddedUi: false,
    embedded: window.sessionStorage && sessionStorage.getItem('embedded'),
    submitLoading: false,
  }

  componentDidMount() {
    const {location} = this.props
    const {captchaDisabled} = config
    const queryParams = queryString.parse(location.search.replace('?', ''))
    if (get(queryParams, 'error')) {
      const message = get(apiErrors, queryParams.error)
      this.setState({loginError: message.localization.t(getItem('locale') || 'en')})
    }
    if (get(queryParams, 'embedded'))
      window.sessionStorage && sessionStorage.setItem('embedded', get(queryParams, 'embedded', true))

    if (get(queryParams, 'webinarid'))
      window.sessionStorage && sessionStorage.setItem('webinarid', get(queryParams, 'webinarid'))

    if (get(queryParams, 'email'))
      this.setState(state => ({form: {...state.form, email: get(queryParams, 'email')}}))

    const startFirbase = firebaseInit()
    startFirbase && logEventCustomParams('loginIntent')
    if (!captchaDisabled) {
      getConfigVars()
        .then((res) => {
          this.setState({isRecaptchaLoginEnabled: res.recaptchaLoginEnabled, isRecaptchaInvisible: res.recaptchaInvisible})
        })
        .catch((err) => {
          this.setState({isRecaptchaLoginEnabled: false, isRecaptchaInvisible: true})
        })
    }

    // TODO: #refactor: remove code soon ( language detection in upper level - appProvider.js )
    //
    // const urlLang = (queryParams && get(queryParams, 'lang')) || getItem('locale')
    // const supportedLanguages = map(filter(languages, (a) => a.client && !a.disabled), 'value')
    // if (urlLang && includes(supportedLanguages,urlLang)) {
    //   const storageLang = window.sessionStorage && sessionStorage.getItem('lang')
    //   if (!storageLang || storageLang!==urlLang) {
    //     window.sessionStorage && sessionStorage.setItem('lang', urlLang)
    //     i18nApp.init(urlLang)
    //     storeItem('locale', urlLang)
    //   }
    // }
  }

  handleChange(prop, value) {
    this.setState(state => ({
      form: {
        ...state.form,
        [prop]: value,
      },
      loginError: '',
      errors: {
        ...state.errors,
        [prop]: !value,
      },
      submitLoading: false,
    }))
  }

  handleClickShowPassword() {
    this.setState(state => ({
      showPassword: !state.showPassword
    }))
  }

  storeSession(relatedEntities, company) {
    const sessionInfo = {
      relatedEntities,
      company,
    }
    storeSessInfoInLocalStorage(sessionInfo)
  }

  async onLogin(redirectTo) {
    const {form, form: {email, password}, isRecaptchaLoginEnabled, isRecaptchaInvisible} = this.state
    const {location} = this.props
    const {supportedCompanies, common: {autoTestEmails}, forcedCompany} = config
    const autoTestEmailsEndsWith = get(autoTestEmails, 'endsWith')
    const autoTestEmailsContains = get(autoTestEmails, 'contains')
    const company = findCompany(forcedCompany)
    const queryParams = queryString.parse(location.search.replace('?', ''))
    const webinarid = get(queryParams,'webinarid') || sessionStorage.getItem('webinarid') || ''
    const embedded = sessionStorage.getItem('embedded')
    const embeddedTrue = embedded && (embedded === 'true')

    let recaptcha
    if (isRecaptchaLoginEnabled) {
      if ((!autoTestEmailsEndsWith || (autoTestEmailsEndsWith && !endsWith(email, autoTestEmailsEndsWith)))
      && (!autoTestEmailsContains || (autoTestEmailsContains && !(includes(email, autoTestEmailsContains) && endsWith(email, '@bdswiss.com'))))) {
        if (isRecaptchaInvisible) {
          recaptcha = await recaptchaRef.current.executeAsync()
        } else {
          recaptcha = recaptchaRef.current.getValue()
        }
      }
    }

    const errors = {}
    for (const field of Object.keys(form)) {
      errors[field] = isEmpty(form[field])
    }
    logEventCustomParams('loginAttempted')
    if (some(errors)) {
      this.setState({errors})
      return
    }

    this.setState({submitLoading: true})
    loginRequest(email, password, recaptcha).then((res) => {
      const {relatedEntities} = res
      const params = {
        login: relatedEntities[0].frontendUrl
      }
      logEventCustomParams('loginCompleted', params)
      if (window?.localStorage && window.localStorage.getItem('activity_detector')) {
        window.localStorage.removeItem('activity_detector')
      }
      if (res.lastCountryWarning && relatedEntities.length === 1 && !res.wtRedirect) {
        this.props.history.push('/login-verification')
      } else {
        if (embeddedTrue) {
          this.setState({showEmbeddedUi: true})
        } else if (!isEmpty(webinarid)) {
          window.location = relatedEntities[0].frontendUrl + '/webinar?webinarid='+ webinarid +'&locationTrack=login'
        } else {
          if (relatedEntities.length > 1) {
            this.setState({relatedEntities})
            this.props.history.push('/choose-entity', {entities:relatedEntities, redirectTo: redirectTo})
          } else {
            const sameCompany = relatedEntities[0].entity === get(company, 'value')
            sameCompany && this.storeSession(relatedEntities, relatedEntities[0].entity)
            if (supportedCompanies) {
              window.location = relatedEntities[0].frontendUrl
            }

            if (sameCompany &&  this.isEligibleForBDSMigration()) {
              this.props.history.push('/migrate', {redirectTo: redirectTo})
            } else {
              if (window.location.host !== relatedEntities[0].frontendUrl && includes(relatedEntities[0].frontendUrl, 'client-autologin')) {
                window.location = relatedEntities[0].frontendUrl
              } else {
                this.props.history.push(/logout/i.test(redirectTo) ? '/'
                  : redirectTo, {prevPath: this.props.location.pathname, redirectTo: redirectTo})
              }
            }
          }
        }
      }
    }).catch((e) => {
      const {classes} = this.props
      const {weblinks: {contact}} = config
      const elementError = get(messages, e) && <Trans {...messages[e]}
        components={[<a href={contact} target="_blank"  rel="noopener noreferrer" className={classnames(classes.pointer, classes.error)}>support</a>]} />
      const message = (elementError) ? elementError
        : (e instanceof Error ?
          (get(messages, e.message) ? <Trans {...messages[e.message]} className={classnames(classes.error)} /> :  e.message)
          : e)
      const params = {
        method: '',
        reason: e || message,
      }
      logEventCustomParams('loginError', params)
      this.setState({loginError: message, submitLoading: false})
    })
  }

  isEligibleForBDSMigration() {
    const {relatedEntities} = this.state
    return size(relatedEntities) < 2 && config.featuresConfig.migrateCompany.enable
      && !getCookie('__mig-rejected') && !!getCookie('RegulationAccepted')
  }

  closeEmbeddedIframe() {
    sessionStorage.removeItem('embedded')
    const {weblinks:{websiteUrl}} = config
    window.parent.postMessage('success', websiteUrl)
  }

  onLink(link) {
    window.location.href = link
  }

  render() {
    const {classes, t, viewer, loading, redirectUrl, location: {state: locationState}} = this.props
    const {errors, loginError, showPassword, apolloClient, showEmbeddedUi, isRecaptchaLoginEnabled, submitLoading, form: {email, password}, isRecaptchaInvisible} = this.state
    const {common:{reCaptcha:{siteKey}, autoTestEmails}, registrationDisabledPermanently, removeLoginRegistration} = config

    const autoTestEmailsEndsWith = get(autoTestEmails, 'endsWith')
    const autoTestEmailsContains = get(autoTestEmails, 'contains')
    const viewerType = get(viewer, '__typename')
    const fromUrl = get(locationState, 'from.pathname', '/')
    const fromUrlParams = get(locationState, 'from.search', '')
    const redirectAfterLogin = !includes(fromUrl, 'logout') ? `${fromUrl}${fromUrlParams}` : '/'
    const urlParam = this.props.location.search.replace('?', '')
    const trackingEventsUrl = queryString.parse(this.props.location.search.replace('?', ''))
    const eventUrlKey = get(trackingEventsUrl,'subcampid','')
    const eventID = !eventUrlKey.includes('LTCOMP') && eventUrlKey //exclude Live Trading Competition Events
    const eventCountry = eventID && get(get(countries, eventID.substring(0,2).toLowerCase()),'label')
    const eventDate = eventID && moment(eventID.substring(2), 'YYYYMMDD').isValid() && moment(eventID.substring(2), 'YYYYMMDD').format('DD/MM/YYYY')
    const showEventNotification = eventID && eventCountry && eventDate
    const webinarid = get(queryString.parse(urlParam), 'webinarid')

    let automationEmail = false
    if (window.liveChat && ((autoTestEmailsEndsWith && endsWith(email, autoTestEmailsEndsWith))
      || (autoTestEmailsContains && includes(email, autoTestEmailsContains) && endsWith(email, '@bdswiss.com')))) {
      automationEmail = true
    }

    const {themePreference} = this.context

    if (redirectUrl) {
      window.location = redirectUrl
      return null
    }

    if (viewer && viewerType === 'Client') {
      if (eventID) {
        logoutRequest()
          .then(() => {
            removeSessInfoFromLocalStorage()
            apolloClient && apolloClient.clearStore()
          })
      } else {
        return <Redirect to='/' />
      }
    }

    if (loading) {
      document.body.classList.add('loadingApp')
      return <LinearProgress />
    } else {
      document.body.classList.remove('loadingApp')
    }

    return (
      <div className={classes.mainBody}>
        <LivechatBubble/>
        <Header handleSidebarToggle={this.handleSidebarToggle} linkTo={'/'} outer/>
        <Grid container>
          <Hidden smDown>
            <Grid item sm={3} />
          </Hidden>
          <Grid item sm={9} xs={12} className={classes.wrapper}>
            <Grid container className={!showEventNotification ? classes.grid : ''}>
              <PageTitle modal customClasses={{header: classes.header}}>
                <Trans {...messages.login} />
              </PageTitle>
            </Grid>
            {showEventNotification && <Grid container spacing={1} className={classes.grid}>
              <NotificationBar
                status='info'
                title={<Trans {...messages.registerForEventLogin} values={{eventCountry: eventCountry, eventDate:eventDate}}
                  components={[<span className={classes.highlightedText}>registration</span>]}
                />}
              />
            </Grid>}
            {get(locationState, 'resetPassword') && <Grid container spacing={1} className={classes.grid}>
              <NotificationBar status='success' title={<Trans {...messages.resetPasswordNotification} />} />
            </Grid>}
            <Grid container alignItems="stretch" direction="column" spacing={4} className={classes.grid}>
              <Grid item>
                <TextField
                  variant="standard"
                  id="email"
                  label={t(messages.email.i18nKey, messages.email.defaults)}
                  type="email"
                  value={email}
                  fullWidth
                  autoFocus
                  onChange={(e) => this.handleChange('email', e.target.value)}
                  error={get(errors, 'email', false)}
                  helperText={get(errors, 'email', false) && t(messages.requiredField.i18nKey, messages.requiredField.defaults)} />
              </Grid>
              <Grid item>
                <Password
                  classes={classes}
                  showPassword={showPassword}
                  onChange={(e) => this.handleChange('password', e.target.value)}
                  onKeyPress={(e) => e.charCode === 13 && this.onLogin(redirectAfterLogin)}
                  error={get(errors, 'password', false)}
                  onClickShow={() => this.handleClickShowPassword()}
                  errorText={<Trans {...messages.requiredField} />}
                  value={password}
                />
              </Grid>
              <Grid item>
                <Grid container justifyContent={'flex-end'}>
                  <Grid item>
                    <Link to={(urlParam) ? `/forgot-password?${urlParam}` : '/forgot-password'} onClick={()=> logEventCustomParams('forgotPasswordClicked')}>
                      <Typography variant='body2' color="primary">
                        <Trans {...messages.forgotPassword} />
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {loginError && <Grid container justifyContent="center" className={classes.errorGrid}>
              <Typography variant='body1' className={classes.error}>
                {loginError}
              </Typography>
            </Grid>}
            <Grid container spacing={4} alignItems="center">
              <Grid item sm={6} xs={12}>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  className={classes.noTransform}
                  onKeyPress={(e) => e.charCode === 13 && this.onLogin(redirectAfterLogin)}
                  onClick={() => this.onLogin(redirectAfterLogin)}
                  disabled={isEmpty(errors) || some(errors) || submitLoading}
                  noMargin
                  hideProgressBar={!submitLoading || some(errors)}
                >
                  <Trans {...messages.loginButton} />
                </LoadingButton>
              </Grid>
              {!registrationDisabledPermanently && !removeLoginRegistration && <Grid item sm={6} xs={12}>
                <Typography variant='body2' color="primary" className={classes.registerBtn}
                  onClick={() => this.onLink((urlParam) ? (!isEmpty(webinarid)) ? `/register?${urlParam}` : `/register?${urlParam}` : '/register')}>
                  <Trans {...messages.signUp} />
                </Typography>
              </Grid>
              }
            </Grid>
          </Grid>
          {isRecaptchaLoginEnabled && !automationEmail && <Grid item xs={12}>
            <ReCAPTCHA
              sitekey={siteKey}
              ref={recaptchaRef}
              theme={themePreference}
              size={isRecaptchaInvisible ? 'invisible' : 'normal'}
              badge={'bottomleft'}
            />
          </Grid>}
        </Grid>
        {showEmbeddedUi && <UiNotification
          open={true}
          status={'success'}
          type="document-upload"
          buttonMessage={t(messages.close.i18nKey, messages.close.defaults)}
          onClose={() => this.closeEmbeddedIframe()}
        />}
      </div>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {loading, error}, data}) => ({
      loading,
      redirectUrl: get(error, 'networkError.result.data.redirectUrl'),
      viewer: get(data, 'viewer')
    }),
    skip: (props) => {
      const {location: {state: locationState}} = props
      const isError = get(locationState, 'isError', false)
      const loggedOut = get(locationState, 'loggedOut', false)
      return isError || loggedOut
    }
  })
)(Login)
