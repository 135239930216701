import React, {Component} from 'react'
import {Trans, withNamespaces} from 'react-i18next'
import {get, flowRight as compose} from 'lodash'
import {graphql} from 'react-apollo'
import {AlertDialog} from '../Common/Dialog'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import messages from '../../assets/messages'
import AppContext from '../Common/contexts/AppContext'
import {CLIENT_DATA_QUERY} from '../../graphql/queries'
import {CLIENT_PARTNERS_LOYALTY_MUTATION} from '../../graphql/mutations'
import withStyles from '@mui/styles/withStyles'
import {isMobile} from '../../common/utils'

const styles = (theme) => ({
  alertBody: {
    padding: theme.spacing(isMobile() ? 2 : 3),
  },
})

export class ConfirmationDialog extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {
      submitMessageError: ''
    }
  }

  participate() {
    const {onClose, clientId} = this.props
    this.setState({status: '', loading: true})
    const variables = {
      action: 'start',
      clientId: clientId
    }

    this.props.partnersLoyalty({variables}).then((succ) => {
      this.setState({loading: false, status: 'success', submitMessageError: ''})
      onClose()
    })
      .catch((err) => {
        this.setState({loading: false, status: 'failure', submitMessageError: get(err, 'graphQLErrors[0].message') || err.message})
      })
  }

  render() {
    const {t, open, onClose, company, classes} = this.props
    const {submitMessageError, loading, status} = this.state
    return (
      <AlertDialog
        open={open}
        onClose={() => onClose()}
        onAgree={() => this.participate()}
        disagreeText={t(messages.cancel.i18nKey, messages.cancel.defaults)}
        agreeText={t(messages.confirm.i18nKey, messages.confirm.defaults)}
        errorText={submitMessageError}
        buttonLoading={loading}
        buttonStatus={status}
        alertBodyClass={classes.alertBody}
      >
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <Typography variant="body1">
              <Trans {...messages.loyaltyProgramConfirmationText} values={{company}} />
            </Typography>
          </Grid>
        </Grid>
      </AlertDialog>
    )
  }
}
export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  graphql(CLIENT_PARTNERS_LOYALTY_MUTATION, {
    name: 'partnersLoyalty',
    options: {
      refetchQueries: () => [{query: CLIENT_DATA_QUERY}],
    },
  }),
)(ConfirmationDialog)
