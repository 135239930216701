import React, {Component} from 'react'
import {flowRight as compose} from 'lodash'

import withStyles from '@mui/styles/withStyles'

import config from '../../config'
import AppContext from './contexts/AppContext'
import {getCurrentTheme, isDarkTheme} from '../../common/utils'

const styles = () => ({
  bdSwiss: {
    width: 100,
    height: 24,
  },
})

class Logo extends Component {
  static contextType = AppContext

  render() {
    const {styles} = this.props
    const {brand, logoFileName, logoWhiteFileName} = config
    const themePreference = getCurrentTheme(this.context)
    const logoUrl = require(`../../assets/images/${isDarkTheme(themePreference) ? logoWhiteFileName : logoFileName}`)

    return <img className={`classes.${brand}`} style={styles} alt={`${brand} logo`} src={logoUrl} />
  }
}

export default compose(
  withStyles(styles),
)(Logo)
