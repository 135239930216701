import React, {Component} from 'react'
import {flowRight as compose, get, map, has} from 'lodash'
import withStyles from '@mui/styles/withStyles'
import {Trans, withNamespaces} from 'react-i18next'
import messages from '../../../assets/messages'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import AppContext from '../../Common/contexts/AppContext'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import classNames from 'classnames'
import DocumentDetails from './DocumentDetails'
import {documentHelperText} from '../../../common/utils/uioptions'

const styles = theme => ({
  makeSureDiv: {
    marginTop: 30,
    marginLeft: 4,
    padding: '10px 20px',
    backgroundColor: theme.palette.lightyellow.color,
    color: theme.palette.black.color,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginLeft: 0,
      width: 'calc(100% + 14px)',
    },
  },
  zeroMarginTop:{
    marginTop:0
  },
  infoIcon: {
    marginBottom: -6,
    marginRight: 5,
  },
  list: {
    padding: '5px 0'
  },
  inline: {
    display: 'inline'
  },
  pointer: {
    cursor: 'pointer',
  },
  checkIcon: {
    color: theme.palette.primary.main
  },
})

class MakeSureSection extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {
      openPopup: false,
    }
  }

  renderSection(section) {
    const {classes, category, selectedDocTypeKey, displayTypes} = this.props

    switch (section) {
      case 'acceptedDocuments': {
        return get(documentHelperText[category], 'acceptedDocuments') && <React.Fragment>
          <Grid item xs={12}>
            <Typography variant="body2"> <Trans {...messages.acceptedDocuments}/> </Typography>
          </Grid>
          <ul className={classNames(classes.zeroMarginTop)}>
            {map(documentHelperText[category].acceptedDocuments, (key) => (
              <li key={key} className={classNames(classes.list)}> <Typography variant="body1" classes={{root: classes.inline}}> <Trans {...messages[key]} /> </Typography> </li>
            ))}
          </ul>
        </React.Fragment>
      }
      case 'makeSure': {
        return <React.Fragment>
          <Grid item xs={12}>
            <Typography variant="body2"> <Trans {...messages.pleaseMakeSure} /> </Typography>
          </Grid>
          <ul className={classNames(classes.zeroMarginTop)}>
            {map(documentHelperText[category].makeSure[selectedDocTypeKey] ?
              documentHelperText[category].makeSure[selectedDocTypeKey] :
              documentHelperText[category].makeSure, (key) => {
              const checkDoubleSide = get(displayTypes[selectedDocTypeKey], 'doubleSided', false)
              if (!checkDoubleSide && key === 'makeSureDocumentsTextPoi3') return
              return <li key={key} className={classNames(classes.list)}> <Typography variant="body1" classes={{root: classes.inline}}> <Trans {...messages[key]} /> </Typography> </li>
            })}
          </ul>
        </React.Fragment>
      }
      default: {
        return (documentHelperText[category].shouldInclude || get(documentHelperText[category], `${selectedDocTypeKey}.shouldInclude`)) && <React.Fragment>
          <Grid item xs={12}>
            <Typography variant="body2"> <Trans {...messages.shouldInclude}/> </Typography>
          </Grid>
          <ul className={classNames(classes.zeroMarginTop)}>
            {map(get(documentHelperText[category], `${selectedDocTypeKey}.shouldInclude`) ? documentHelperText[category][selectedDocTypeKey].shouldInclude : documentHelperText[category].shouldInclude, (key) => (
              <li key={key} className={classNames(classes.list)}> <Typography variant="body1" classes={{root: classes.inline}}> <Trans {...messages[key]} /> </Typography> </li>
            ))}
          </ul>
        </React.Fragment>
      }
    }
  }

  render() {
    const {openPopup} = this.state
    const {classes, documentHelperText, category, selectedDocTypeKey, displayTypes, t} = this.props
    const {themePreference, companyObject, locale} = this.context

    return <React.Fragment>
      <Grid container className={classes.makeSureDiv} spacing={3}>
        {get(documentHelperText[category], `${selectedDocTypeKey}`) ?
          map(documentHelperText[category][selectedDocTypeKey], (_, value) => <span key={value}>{this.renderSection(value, true)}</span>) :
          map(documentHelperText[category], (_, value) => <span key={value}>{this.renderSection(value)}</span>)}
        {get(documentHelperText[category], `${selectedDocTypeKey}.instructions`) && <React.Fragment>
          <Grid item xs={12}>
            <Typography variant="body2"> <Trans {...messages.instructions} /> </Typography>
            <Typography variant="body1"> <Trans {...messages[get(documentHelperText[category], `${selectedDocTypeKey}.instructions`)]}
              values={{company: companyObject.brandLabel}}/> </Typography>
          </Grid>
        </React.Fragment>}
        <Grid item xs={12} onClick={() => this.setState({openPopup: true})} className={classes.pointer}>
          <InfoIcon className={classNames(classes.infoIcon, classes.checkIcon)} />
          <Typography variant="body2" className={classNames(classes.inline, classes.checkIcon, classes[`${themePreference}Icon`])}> <Trans {...messages.properlyUploadDocuments} /> </Typography>
        </Grid>
      </Grid>
      {openPopup && <DocumentDetails category={category} selectedDocTypeKey={selectedDocTypeKey} displayTypes={displayTypes}
        onClose={() => this.setState({openPopup: false})} getLocalizedLabel={(label) => has(label, 'label.i18nKey') ?
          t(label.label.i18nKey, label.label.defaults) :
          label.localization.t(locale)} />
      }
    </React.Fragment>
  }
}

export default compose(
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
)(MakeSureSection)
