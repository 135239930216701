import React from 'react'
import {isFunction} from 'lodash'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import Images from './Images'
import {isMobile} from '../../common/utils'

const styles = (theme) => ({
  notificationBox: {
    borderRadius: 8,
    lineHeight: theme.spacing(3),
    padding: `14px ${theme.spacing(2)} ${theme.spacing(2)}`,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  notificationMessage: {
    fontWeight: 400,
    lineHeight: theme.spacing(3),
    [theme.breakpoints.down('lg')]: {
      lineHeight: theme.spacing(3),
      fontSize: 14,
    },
  },
  success: {
    backgroundColor:  theme.palette.notificationBar.greenBackground,
  },
  successMessage: {
    color:  theme.palette.secondary.dark,
  },
  successCTAMessage: {
    cursor: 'pointer',
    fontWeight: 'initial',
    color: theme.palette.notificationBar.greenTextColor,
  },
  error: {
    backgroundColor:  theme.palette.notificationBar.redBackground,
  },
  errorMessage:{
    color:  theme.palette.secondary.dark,
  },
  errorCTAMessage: {
    cursor: 'pointer',
    fontWeight: 'initial',
    color: theme.palette.notificationBar.redTextColor,
  },
  info: {
    backgroundColor:  theme.palette.notificationBar.blueBackground,
  },
  infoMessage:{
    color:  theme.palette.secondary.dark,
  },
  infoCTAMessage: {
    cursor: 'pointer',
    fontWeight: 'initial',
    color: theme.palette.notificationBar.blueTextColor,
  },
  warning: {
    backgroundColor:  theme.palette.notificationBar.yellowBackground,
  },
  warningMessage: {
    color:  theme.palette.secondary.dark,
  },
  warningCTAMessage: {
    cursor: 'pointer',
    fontWeight: 'initial',
    color: theme.palette.notificationBar.yellowTextColor,
  },
  noMargin: {
    marginBottom: 0
  },
  closeIcon: {
    fontSize: 20,
    cursor: 'pointer',
    color: theme.palette.grey[400],
  }
})

/**
  * Authored by Alexandros Yiangou 19/06/2024 copyrights reserved ©
  * Some clarifications for the props of this component.
  *
  * title: The main content of the notification bar
  * ctaMessage: The CTA string to be displayed on the right side of the notification bar
  * ctaAction: The action behind the ctaMessage
  * ctaComponent: A fallback for creating a custom CTA compoenent to be shown on the right side of the notification bar
  *
  * In general you always need to use title + either the combination of ctaMessage & ctaAction
  * or just use a custom ctaComponent.
  **/

const NotificationBar = props => (
  <Paper className={classNames(props.classes.notificationBox, props.classes[props.status], (props.noMargin) && props.classes.noMargin)} elevation={0}>
    {isMobile()
      ? <Box display='flex' flexWrap={props.ctaComponent ? 'wrap' : 'nowrap'} justifyContent='space-between' onClick={() => !props.ctaComponent && isFunction(props.ctaAction) && props.ctaAction()}>
        <Typography component={props.component ? props.component : 'p'} variant={props.variant ? props.variant : 'body1'} className={classNames(props.classes.notificationMessage,props.classes[`${props.status}Message`])}>
          {props.title}
        </Typography>
        {props.ctaMessage &&
          <img
            alt={'chevron-right'}
            src={Images['chevron_right.svg']}
            className={props.classes.closeIcon}
          />
        }
        {props.ctaComponent && <Typography component={props.component ? props.component : 'p'} variant={props.variant ? props.variant : 'body1'} className={classNames(props.classes.notificationMessage,props.classes[`${props.status}Message`])}>
          {props.ctaComponent}
        </Typography>}
        {props.onClose && <img
          alt={'close'}
          src={Images['close.svg']}
          onClick={() => props.onClose()}
          className={props.classes.closeIcon}
        />}
      </Box>
      : <Box display='flex' justifyContent='space-between' onClick={() => !props.ctaComponent && isFunction(props.ctaAction) && props.ctaAction()}>
        <Typography component={props.component ? props.component : 'p'} variant={props.variant ? props.variant : 'body1'} className={classNames(props.classes.notificationMessage,props.classes[`${props.status}Message`])}>
          {props.title}
        </Typography>
        {(props.ctaMessage || props.ctaComponent) && <Typography
          className={classNames(props.classes.notificationMessage,props.classes[`${props.status}CTAMessage`])}
          component={props.component ? props.component : 'p'}
          variant={props.variant ? props.variant : 'body1'}
        >
          {props.ctaMessage || props.ctaComponent}
        </Typography>}
        {props.onClose && <img
          alt={'close'}
          src={Images['close.svg']}
          onClick={() => props.onClose()}
          className={props.classes.closeIcon}
        />}
      </Box>
    }
  </Paper>
)

NotificationBar.propTypes = {
  title: PropTypes.any.isRequired,
  ctaMessage: PropTypes.any,
  ctaAction: PropTypes.any,
  classes: PropTypes.object.isRequired,
  status: PropTypes.oneOf(['', 'success', 'error', 'info', 'warning'])
}
export default withStyles(styles)(NotificationBar)
