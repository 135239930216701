import PropTypes from 'prop-types'
import {graphql} from 'react-apollo'
import React, {Component} from 'react'
import Grid from '@mui/material/Grid'
import {withRouter} from 'react-router-dom'
import {frontends, countries} from '@bdswiss/common-enums'
import {withNamespaces, Trans} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import {debounce, isEmpty, values, get, flowRight as compose} from 'lodash'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Input from '@mui/material/Input'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import Typography from '@mui/material/Typography'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {PaymentActionButton, withCreateDeposit} from './helpers'
import messages from '../../../../assets/messages'
import {getPlatform, getSourceBasedOnLocationPrevPath, logEventCustomParams} from '../../../../common/utils'
import {validateEmail, validateNumber, validatePersonalIdPayretailers} from '../../../../common/utils/validations'
import {PAYRETAILERS_PAYMENT_METHODS_QUERY} from '../../../../graphql/queries'
import {Loading} from '../../../Common/Loading'

const style = (theme) => ({
  formControl: {
    margin: theme.spacing(1)
  },
  error: {
    color: theme.palette.error.main
  },
  listItemRoot: {
    borderBottom: `1px solid ${theme.palette.lightgrey.color}`,
    padding: '6px 0 7px',
  },
  bankIcon: {display: 'inline'},
  bankImage: {maxWidth: '60px'},
  paymentMethod: {
    color: 'rgba(0, 0, 0, 0.5) !important'
  },
  paymentMethodSelected: {
    color: 'rgba(0, 0, 0, 0.87) !important'
  },
})

const initialState = {
  form: {paymentMethodId: '', email: '', personalId: '', city: '', address: '', phone: '', currency: ''},
  errors: {}, creatingDeposit: false, doSubmitForm: false,
  anchorEl: null, selectedIndex: null,
}

const countriesForPersonalId = [countries.br.key, countries.cl.key, countries.co.key, countries.cr.key, countries.mx.key]

class PayRetailersProvider extends Component {

  constructor(props) {
    super(props)
    this.validateFields = debounce(this.validateFields, 200)
    this.state = initialState
    this.validators = this.getValidators(props)
  }

  getValidators = (props) => {
    const country = this.props.viewer.address.country
    return {
      paymentMethodId: (value) => {
        if (!value) return messages.requiredField
      },
      email: (value) => {
        if (!value) return messages.requiredField
        if (!validateEmail(value)) return messages.emailValidation
      },
      ...([countries.br.key, countries.cl.key, countries.co.key, countries.cr.key, countries.pe.key, countries.mx.key, countries.gt.key].includes(country)) ? {
        personalId: (value) => {
          if ([countries.pe.key,countries.gt.key].includes(country) && !this.props?.depositOptions?.data[this.state?.selectedIndex]?.requirePersonalId) {
            return
          }
          if (!value) return messages.requiredField
          if (!validatePersonalIdPayretailers(country, value)) {
            return messages.invalidPid
          }
        },
      } : {},
      ...(country === countries.br.key) ? {
        city: (value) => {
          if (!value) return messages.requiredField
        },
        address: (value) => {
          if (!value) return messages.requiredField
        },
        phone: (value) => {
          if (!(validateNumber(value) && value.indexOf('55') === 0)) {
            return messages.validateBrazilianPhone
          }
        }
      } : {}
    }
  }

  handleClickListItem = (event) => {
    this.setState({anchorEl: event.currentTarget})
  }

  handleMenuItemClick = (event, index) => {
    const {depositOptions: {data: payretailersPaymentMethods}} = this.props
    const {form} = this.state
    this.setState({
      selectedIndex: index,
      anchorEl: null,
      form: {
        ...form,
        paymentMethodId: payretailersPaymentMethods[index].paymentMethodId,
        currency: payretailersPaymentMethods[index].currency,
      },
    }, () => this.validateFields())
  }

  handleClose = () => {
    this.setState({
      anchorEl: null,
    })
  }

  handleChange(key, value) {
    const {form} = this.state
    this.setState({
      form: {
        ...form,
        [key]: value
      }
    }, () => this.validateFields())
  }

  validateFields() {
    const {form} = this.state
    const errors = {}
    Object.keys(form).forEach((k) => {
      const validate = this.validators[k]
      errors[k] = validate && validate(form[k])
    })
    this.setState({errors})
  }

  formIsValid() {
    const {form, errors} = this.state
    return isEmpty(values(errors).filter((v) => v)) && !isEmpty(values(form).filter((v) => v))
  }

  doCreateDeposit() {
    if (!this.formIsValid()) return

    this.setState({creatingDeposit: true})

    const {amount, account, onError, useVirtualPaymentPage, history: {location},
      providerProperties: {provider, id: paymentOptionId}, bonusAmount, bonusTerms,
    } = this.props
    const {form: {paymentMethodId, email, personalId, phone, city, address, currency}} = this.state

    let personalIdWithoutSpecialChar = personalId.replace(/[^a-zA-Z0-9 ]/g, '')
    personalIdWithoutSpecialChar = personalIdWithoutSpecialChar.replace(/\s/g, '')

    const firebaseParams = {
      source: getSourceBasedOnLocationPrevPath(get(location, 'state', {prevPath: get(location, 'pathname')})),
      login: account.id,
      currency: account.currency,
      vendor: provider,
      amount,
    }
    logEventCustomParams('depositAttempted', firebaseParams)

    const variables = {
      accountId: account.id,
      amount,
      vendor: provider,
      paymentOptionId,
      frontend: frontends.web2.value,
      args: JSON.stringify({
        platform: getPlatform(),
        paymentMethodId,
        currency,
        email,
        personalId: personalIdWithoutSpecialChar,
        phone,
        city,
        address,
        useVirtualPaymentPage,
        bonusAmount,
        bonusTerms,
      }),
    }
    this.props.createDepositRequest({variables})
      .then(({data: {newDeposit}}) => {
        const {payment: {url}} = newDeposit
        window.location = url
      })
      .catch((e) => {
        this.setState({creatingDeposit: false})
        onError && onError(e)
      })
  }

  render() {
    const {classes, depositOptions: {loading, data: payretailersPaymentMethods},
      viewer: {address: {country}}, canProceed} = this.props
    const {form, errors, creatingDeposit, selectedIndex, anchorEl} = this.state
    const paymentMethodClass = selectedIndex !== null ? classes.paymentMethodSelected : classes.paymentMethod
    if (loading || !payretailersPaymentMethods) return <Loading />

    return (
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12}>
          <FormControl variant="standard" className={classes.formControl} fullWidth>
            <List>
              <ListItem
                button
                onClick={this.handleClickListItem}
                className={classes.listItemRoot}
              >
                {selectedIndex !== null && <ListItemIcon classes={{root: classes.bankIcon}}>
                  <img
                    src={payretailersPaymentMethods[selectedIndex].imageUrl}
                    alt={payretailersPaymentMethods[selectedIndex].name}
                    className={classes.bankImage} />
                </ListItemIcon>}
                <ListItemText
                  primary={selectedIndex !== null
                    ? payretailersPaymentMethods[selectedIndex].name
                    : <Trans {...messages.paymentMethod} />}
                  className={paymentMethodClass}
                />
                <ArrowDropDownIcon className={paymentMethodClass} />
              </ListItem>
            </List>
            <Menu
              id="lock-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
            >
              {payretailersPaymentMethods.map((o, index) => (
                <MenuItem
                  key={o.paymentMethodId}
                  selected={index === selectedIndex}
                  onClick={(event) => this.handleMenuItemClick(event, index)}
                >
                  <ListItemIcon classes={{root: classes.bankIcon}}>
                    <img src={o.imageUrl} alt={o.name} className={classes.bankImage} />
                  </ListItemIcon>
                  <ListItemText primary={o.name} className={paymentMethodClass} />
                </MenuItem>
              ))}
            </Menu>
            {errors.paymentMethodId &&
              <FormHelperText className={classes.error}><Trans {...errors.paymentMethodId} /></FormHelperText>}
          </FormControl>
          <FormControl variant="standard" className={classes.formControl} fullWidth>
            <InputLabel htmlFor="email">
              <Trans {...messages.email} />
            </InputLabel>
            <Input
              id="email"
              type="text"
              value={get(form, 'email', '')}
              onChange={(e) => this.handleChange('email', e.target.value)}
            />
            {errors.email &&
              <FormHelperText className={classes.error}><Trans {...errors.email} /></FormHelperText>
            }
          </FormControl>
          {(countriesForPersonalId.includes(country) || payretailersPaymentMethods[selectedIndex]?.requirePersonalId)
          && <FormControl className={classes.formControl} fullWidth>
            <InputLabel htmlFor="personalId">
              <Trans {...messages.personalIdentificationNumber} />
            </InputLabel>
            <Input
              id="personalId"
              type="text"
              value={get(form, 'personalId', '')}
              onChange={(e) => this.handleChange('personalId', e.target.value)}
            />
            {errors.personalId &&
              <FormHelperText className={classes.error}><Trans {...errors.personalId} /></FormHelperText>
            }
          </FormControl>}
          {country === countries.br.key && <>
            <FormControl variant="standard" className={classes.formControl} fullWidth>
              <InputLabel htmlFor="city">
                <Trans {...messages.cityLabel} />
              </InputLabel>
              <Input
                id="city"
                type="text"
                value={get(form, 'city', '')}
                onChange={(e) => this.handleChange('city', e.target.value)}
              />
              {errors.city &&
                <FormHelperText className={classes.error}><Trans {...errors.city} /></FormHelperText>
              }
            </FormControl>
            <FormControl variant="standard" className={classes.formControl} fullWidth>
              <InputLabel htmlFor="address">
                <Trans {...messages.address} />
              </InputLabel>
              <Input
                id="address"
                type="text"
                value={get(form, 'address', '')}
                onChange={(e) => this.handleChange('address', e.target.value)}
              />
              {errors.address &&
                <FormHelperText className={classes.error}><Trans {...errors.address} /></FormHelperText>
              }
            </FormControl>
            <FormControl variant="standard" className={classes.formControl} fullWidth>
              <InputLabel htmlFor="phone">
                <Trans {...messages.phoneNumber} />
              </InputLabel>
              <Input
                id="phone"
                type="text"
                value={get(form, 'phone', '')}
                onChange={(e) => this.handleChange('phone', e.target.value)}
              />
              {errors.phone &&
                <FormHelperText className={classes.error}><Trans {...errors.phone} /></FormHelperText>
              }
            </FormControl>
          </>}
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" variant="h6">
            <Trans {...messages.importantInformation} />
          </Typography>
          <Typography variant="body1">
            <Trans {...messages.payretailersLocalBanksNotice} />
          </Typography>
        </Grid>
        <PaymentActionButton
          loading={creatingDeposit}
          disable={canProceed || !this.formIsValid()}
          onClick={() => this.doCreateDeposit()}
        />
      </Grid>
    )
  }
}

PayRetailersProvider.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  providerProperties: PropTypes.shape({
    name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    paymentKey: PropTypes.string.isRequired,
    paymentOptionId: PropTypes.number.isRequired,
  }).isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

export default compose(
  withStyles(style),
  withCreateDeposit,
  withNamespaces(),
  withRouter,
  graphql(PAYRETAILERS_PAYMENT_METHODS_QUERY, {
    options: (props) => ({variables: {country: props.viewer.address.country}}),
    props: ({data: {error, loading}, data}) => ({
      depositOptions: {
        loading,
        error,
        data: get(data, 'payretailersPaymentMethods')
      }
    })
  })
)(PayRetailersProvider)
