import React from 'react'
import PropTypes from 'prop-types'
import {withNamespaces, Trans} from 'react-i18next'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import {AlertDialog} from '../../Common/Dialog'
import messages from '../../../assets/messages'
import config from '../../../config'
import AppContext from '../../Common/contexts/AppContext'
import {get, flowRight as compose} from 'lodash'

const styles = theme => ({
  subtitleGrid: {
    padding: 8,
  },
  labelField: {
    paddingRight: 4,
  },
  valueField: {
    paddingLeft: 4,
  },
  link: {
    color:theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight:400
  },
})

const PammModal = (props) => {
  const {open, classes, t, onAccept, onCancel} = props
  const {weblinks:{termsAndConditions}} = config
  const {locale} = get(get(AppContext,'_currentValue',''),'locale','en')
  const terms = termsAndConditions.replace('eu.','')
  return (
    <AlertDialog
      open={open}
      title=""
      agreeText={t(messages.agreeContinue.i18nKey, messages.agreeContinue.defaults)}
      onAgree={() => onAccept()}
      disagreeText={t(messages.cancel.i18nKey, messages.cancel.defaults)}
      onDisagree={() => onCancel()}
    >
      <Grid container direction="row" alignContent="center" justifyContent="center">
        <Grid item xs={12} className={classes.subtitleGrid}>
          <Typography variant="body1"><Trans {...messages.dearClient} /></Typography>
          <br />
          <Typography variant="body1"><Trans {...messages.pammDisclaimer1} /></Typography>
          <Typography variant="body1"><Trans {...messages.pammDisclaimer2} /></Typography>
          <Typography variant="body1"><Trans {...messages.pammDisclaimer3} /></Typography>
          <Typography variant="body1"><Trans {...messages.pammDisclaimer4} /></Typography>
          <ul>
            <li><Typography variant="body1"><Trans {...messages.pammDisclaimerMessage1} /></Typography></li>
            <li><Typography variant="body1"><Trans {...messages.pammDisclaimerMessage2} /></Typography></li>
            <li><Typography variant="body1"><Trans {...messages.pammDisclaimerMessage3}
              components={[
                <a href={terms.replace('{lang}', locale)} target='_blank' rel='noreferrer noopener' className={classes.link}>termsAndConditions</a>
              ]}/></Typography></li>
          </ul>
        </Grid>
      </Grid>
    </AlertDialog>
  )
}

PammModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
)(PammModal)
