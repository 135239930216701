import React, {Component} from 'react'
import {withNamespaces} from 'react-i18next'
import {flowRight as compose, get, includes, replace, map, last, split} from 'lodash'
import {FullScreenDialog} from '../Common/Dialog'
import AppContext from '../Common/contexts/AppContext'
import withStyles from '@mui/styles/withStyles'
import {withRouter} from 'react-router-dom'
import Grid from '@mui/material/Grid'
import {CLIENT_DATA_QUERY} from '../../graphql/queries'
import {graphql} from 'react-apollo'
import CorporateRoutes from './routes'
import messages from '../../assets/messages'
import PageTitle from '../Common/PageTitle'
import PageBody from '../Common/PageBody'
import {corporateSteps} from '@bdswiss/common-enums'

const paths = map(corporateSteps, 'key')
class Corporate extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)

    this.state = {
      activeStep: paths.indexOf(replace(get(props, 'location.pathname'), '/corporate/', '')) + 1
        || get(props.viewer, 'corporateSignupStep'),
    }
  }

  componentDidMount() {
    const {viewer, history} = this.props
    !get(viewer, 'corporateSignupStep') && history.push('/')
  }

  handleClose(step, forceStay) {
    const {history, t} = this.props
    return (forceStay && step === 1) ? this.context.logout(t(messages.companyOnboarding.i18nKey, messages.companyOnboarding.defaults))
      : (step === 1) ? history.push('/settings') : history.push(`/corporate/${paths[step-2]}`)
  }

  render() {
    const {t, viewer, location: {pathname}, history} = this.props
    const {activeStep}= this.state
    const forcedStep = paths.indexOf(replace(pathname, '/corporate/', '')) + 1
    const viewerStep = get(viewer, 'corporateSignupStep')
    let step = forcedStep || activeStep
    const internal = includes(pathname, '/corporate/')
    const prevSettings = includes(get(this.props, 'location.state.prevPath'), 'settings')
    const prevVerification = includes(get(this.props, 'location.state.prevPath'), 'verification')
    step = get(this.props, 'location.state.step') || ((step > viewerStep) ? viewerStep : step)
    const corporateStatus = get(viewer, 'corporateStatus')
    const stepName = last(split(pathname, '/'))

    if (!includes(map(corporateSteps, 'value'), stepName)) history.push('/corporate')

    return (
      <div>
        <FullScreenDialog
          desktopOnly
          open={true}
          fullScreen
          onClose={() => (prevVerification) ? history.push('/accounts/verification')
            : (prevSettings || corporateStatus) ? history.push('/settings/profile') : this.handleClose(step, !corporateStatus)}
          id={'scroll-grid-corporate'}
        >
          <PageTitle
            title={t(messages.companyOnboarding.i18nKey, messages.companyOnboarding.defaults)}
          >
          </PageTitle>
          <PageBody>
            <Grid container spacing={3}>
              {(step === 1 && !includes(pathname, 'corporate/general') && (!internal || forcedStep)) ? history.push('/corporate/general') : ''}
              {(step === 2 && !includes(pathname, 'corporate/beneficiariesDirectors') && (!internal || forcedStep)) ? history.push('/corporate/beneficiariesDirectors') : ''}
              {(step === 3 && !includes(pathname, 'corporate/representative') && (!internal || forcedStep)) ? history.push('/corporate/representative') : ''}
              {(step === 4 && !includes(pathname, 'corporate/financialInformation') && (!internal || forcedStep)) ? history.push('/corporate/financialInformation') : ''}
              {(step === 5 && !includes(pathname, 'corporate/appropiatenessTest') && (!internal || forcedStep)) ? history.push('/corporate/appropiatenessTest') : ''}
              {(step === 6 && !includes(pathname, 'corporate/documents') && (!internal || forcedStep)) ? history.push('/corporate/documents') : ''}
              {(step >= 7 && !includes(pathname, 'corporate/signing') && (!internal || forcedStep)) ? history.push({pathname: '/corporate/signing', state:{fromCorporate: !internal}}) : ''}
            </Grid>
          </PageBody>
          <CorporateRoutes />
        </FullScreenDialog>
      </div>
    )
  }
}

export default compose(
  withNamespaces(),
  withRouter,
  withStyles({withTheme: true}),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {loading, error}, data}) => ({
      loading,
      error,
      viewer: get(data, 'viewer')
    })
  }),
)(Corporate)
