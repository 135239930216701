import React, {Component} from 'react'
import withStyles from '@mui/styles/withStyles'
import {frontends} from '@bdswiss/common-enums'
import {getPlatform, getSourceBasedOnLocationPrevPath, logEventCustomParams} from '../../../../common/utils'
import {flowRight as compose, get} from 'lodash'
import {withCreateDeposit, PaymentActionButton} from './helpers'
import {withNamespaces, Trans} from 'react-i18next'
import Dialog from '@mui/material/Dialog'
import messages from '../../../../assets/messages'

const style = (theme) => ({
  iframe: {
    overflow: 'scroll',
    width: '100%',
    minHeight: '99%'
  },
  fullHeight: {
    height: '100%',
  },
  note: {
    color: theme.palette.red.color,
  },
  root: {
    zIndex: 2147483640
  }
})

class MifinityProvider extends Component {
  state = {openFrameDialog: false}

  getIframeUrl() {
    const {providerProperties: {provider, paymentKey, id: paymentOptionId}, account, amount, onError, bonusAmount, bonusTerms,
      history: {location}} = this.props

    this.setState({gettingIframeUrl: true})

    const firebaseParams = {
      source: getSourceBasedOnLocationPrevPath(get(location, 'state', {prevPath: get(location, 'pathname')})),
      login: account.id,
      currency: account.currency,
      vendor: provider,
      amount,
    }
    logEventCustomParams('depositAttempted', firebaseParams)

    const variables = {
      accountId: account.id,
      amount,
      vendor: provider,
      paymentOptionId,
      frontend: frontends.web2.value,
      args: JSON.stringify({
        platform: getPlatform(),
        paymentKey,
        bonusAmount,
        bonusTerms,
      }),
    }

    this.props.createDepositRequest({variables})
      .then(({data: {newDeposit}}) => {
        const {payment: {url: iframeUrl}} = newDeposit

        this.setState({openFrameDialog: true}, () => {
          const widget = window.showPaymentIframe('widget-container', {
            token: iframeUrl,
            success: function() {
              setTimeout(() => {
                window.location.href = `${window.location.pathname}/result/success`
              }, 1000)
            },
            fail: function() {
              setTimeout(() => {
                window.location.href = `${window.location.pathname}/result/failed`
              }, 1000)
            },
            complete: function() {
              setTimeout(() => {widget.close()}, 1000)
            },
          })
        })
      })
      .catch((e) => {
        this.setState({gettingIframeUrl: false})
        onError && onError(e)
      })
  }

  validateAddress() {
    const {viewer: {address}} = this.props

    return address.line1 && address.country && address.zip && address.city
  }

  render() {
    const {classes, canProceed} = this.props
    const {gettingIframeUrl, openFrameDialog} = this.state

    return (
      <React.Fragment>
        {this.validateAddress() ?
          (<PaymentActionButton
            disable={canProceed}
            loading={gettingIframeUrl}
            onClick={() =>  this.getIframeUrl()}
          />) : (<Trans {...messages.noAddress} />)
        }
        <Dialog style={openFrameDialog ? {} : {display: 'none'}} fullScreen={openFrameDialog} open onClose={() => this.setState({openFrameDialog: false})}
          disableEnforceFocus={true}>
          <div style={{width: 'inherit'}} id="widget-container" />
        </Dialog>
      </React.Fragment>
    )
  }
}

export default compose(
  withNamespaces(),
  withStyles(style),
  withCreateDeposit
)(MifinityProvider)
