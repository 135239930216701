import React from 'react'
import PropTypes from 'prop-types'
import {NavLink} from 'react-router-dom'
import withStyles from '@mui/styles/withStyles'
import ListItem from '@mui/material/ListItem'
import classNames from 'classnames'
import {get} from 'lodash'
import {ListItemIcon} from '@mui/material'

const styles = theme => ({
  root: {
    ...theme.typography.body1,
    lineHeight: '2.2rem',
    color: theme.palette.secondary.main,
    fontSize: 16,
    [theme.breakpoints.down('lg')]: {
      color: theme.palette.secondary.dark,
    }
  },
  navLink: {
    display: 'block'
  },
  selected: {
    [theme.direction === 'rtl' ? 'borderRight' : 'borderLeft']: `2px solid ${get(theme.palette, 'main.color') || theme.palette.red.color}`,
    color:theme.palette.secondary.dark,
    [theme.direction === 'rtl' ? 'marginRight' : 'marginLeft']: '0px'
  },
  icon: {
    marginRight: theme.spacing(1),
  },
})

const isActiveLink = (match, location, isIndex) =>
  (!!match || (location && location.pathname === '/' && isIndex))


class ListItemLink extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    to: PropTypes.string.isRequired,
    isIndex: PropTypes.bool,
    onClick: PropTypes.func,
    removeSelection: PropTypes.bool,
  }

  render() {
    const {classes, to, children, onClick, isIndex, classesItem, removeSelection, icon} = this.props
    return (
      <ListItem component={NavLink} to={to} className={classNames(classes.navLink,classes.root, classesItem)} activeClassName={!removeSelection ? classes.selected : ''} isActive={(m, l) => isActiveLink(m, l, isIndex)}
        button
        onClick={onClick}
      >
        {icon && (
          <ListItemIcon className={classes.icon}>
            {React.cloneElement(icon, {fontSize: 'small'})}
          </ListItemIcon>
        )}
        {!icon && children}
      </ListItem>
    )
  }
}

export default withStyles(styles, {withTheme: true})(ListItemLink)
