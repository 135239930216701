import React from 'react'
import {flowRight as compose, isEmpty, filter, get, size, orderBy} from 'lodash'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'
import withStyles from '@mui/styles/withStyles'
import {withNamespaces, Trans} from 'react-i18next'
import messages from '../../../assets/messages'
import Button from '@mui/material/Button'
import {isMAMScSubAccount, getTradingAccountsDefault} from '../../../common/utils/accounts'
import {isAndroid, isIos, isMobile} from '../../../common/utils/browser'
import Typography from '@mui/material/Typography'
import AppContext from '../../Common/contexts/AppContext'
import classNames from 'classnames'
import Grid from '@mui/material/Grid'
import {logEventCustomParams} from '../../../common/utils/firebaseEvents'
import ForexWebtraderModal from './ForexWebtraderModal'
import ForexWebtraderRedirectionModal from './ForexWebtraderRedirectionModal'
import config from '../../../config'

const styles = theme => ({
  tradeNowButton: {
    padding: isMobile() ? '5px 20px' : '10px 50px',
    minHeight: 'auto',
    [theme.breakpoints.down('lg')]: {
      boxShadow: 'none',
      marginBottom: 6,
    },
  },
  tradeNowLink:{
    cursor: 'pointer'
  },
  textLeft:{
    textAlign: 'left'
  },
  checkboxLabel: {
    padding: '10px 0',
  },
  alertBodyClass: {
    [theme.breakpoints.up('md')]: {
      minWidth: 600
    }
  },
  depositButton: {
    height: 40,
    padding: 12,
    backgroundColor: '#007AFF',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      padding: 4,
      minHeight: 40,
      height: 'inherit',
    }
  },
})

class ForexTradeNow extends React.Component {
  static contextType = AppContext
  state = {
    showMobileTradeModal: false,
    showAccountsModal: false,
    showRedirectionModal: false,
  }

  openWebtraderAccountsModal() {
    this.setState({showAccountsModal: true, showRedirectionModal: false})
  }

  closeWebtraderAccountsModal() {
    this.setState({showAccountsModal: false})
  }

  openWebtraderRedirectionModal() {
    this.setState({showRedirectionModal: true})
    this.logCommonEventParams('onClickRedirectionPopupPresented')
  }

  closeWebtraderRedirectionModal() {
    this.setState({showRedirectionModal: false})
  }

  onTrade(tradingAccounts, redirect) {
    const {account, accounts} = this.props
    const {tradeNowSmRedirection}= config
    const liveAccounts = orderBy(filter(tradingAccounts, (account) => !account.isDemo), ['balance'], ['desc'])
    const demoAccounts = orderBy(filter(tradingAccounts, (account) => account.isDemo), ['balance'], ['desc'])
    const login = get(account, 'login') || get(liveAccounts, '[0].login') || get(demoAccounts, '[0].login')
    const liveAccountsSize = size(liveAccounts)
    const demoAccountsSize = size(demoAccounts)
    const params = {
      location: 'account',
      userId: get(this.context, 'clientId'),
    }

    logEventCustomParams('tradeNowButtonClicked', params)

    if (tradeNowSmRedirection) {
      window.open(tradeNowSmRedirection?.platformsRedirectionLink, '_blank')
    } else if (isEmpty(accounts) || redirect || liveAccountsSize === 1 || (!liveAccountsSize && demoAccountsSize === 1)) {
      window.open(`/trade/forex/${login}`, '_blank')
    } else {
      this.openWebtraderAccountsModal()
    }
  }

  onOpenMobileApp() {
    const {tradeNowRedirectionPopUp:{downloadAppUrl}} = config
    this.closeWebtraderRedirectionModal()
    window.location.href = isIos() || isAndroid() ? downloadAppUrl : ''
    this.logCommonEventParams('appButtonClicked')
  }

  onMobileTrade(tradingAccounts, redirect) {
    this.onTrade(tradingAccounts, redirect)
    this.closeWebtraderRedirectionModal()
    this.logCommonEventParams('webTraderButtonClicked')
  }

  logCommonEventParams(eventName) {
    const {companyObject} = this.context
    logEventCustomParams(eventName, {source: isIos() ? 'iOS' : isAndroid() ? 'Android' : '', brand: companyObject.brandLabel})
  }

  render() {
    const {account, classes, type, alignLeft, buttonClass, accounts, sideBar, testSwissMarkets} = this.props
    const {showAccountsModal, showRedirectionModal} = this.state
    const {tradeNowRedirectionPopUp:{showRedirectionPopUp}} = config
    const tradingAccounts = getTradingAccountsDefault(accounts)
    const isMobileDevice = isMobile()
    let renderedObject

    if (testSwissMarkets) {
      renderedObject = <Button
        className={classes.depositButton}
        onClick={() => isMobileDevice && showRedirectionPopUp ? this.openWebtraderRedirectionModal() : this.onTrade(tradingAccounts, false)}
        variant='contained'
        size='large'
        color='primary'
        style={{padding: '8px 24px'}}
      >
        <Trans {...messages.trade} />
      </Button>
    } else if (type === 'button') {
      renderedObject = <Button
        onClick={() => isMobileDevice && showRedirectionPopUp ? this.openWebtraderRedirectionModal() : this.onTrade(tradingAccounts, false)}
        variant={sideBar ? 'text' : 'contained'}
        size="large"
        color="primary"
        className={classNames(classes.tradeNowButton, (alignLeft) ? classes.textLeft : '', buttonClass)}
      >
        <Trans {...messages.trade} />
      </Button>
    } else {
      renderedObject = <Typography
        variant='body2'
        onClick={() => isMobileDevice && showRedirectionPopUp ? this.openWebtraderRedirectionModal() : this.onTrade(tradingAccounts)}
        color="primary"
        className={classNames(classes.tradeNowLink, (alignLeft) ? classes.textLeft : '')}
      >
        <Trans {...messages.trade} />
      </Typography>
    }

    return (<Grid container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
    >
      {((account && !isMAMScSubAccount(account) && !account.isArchived && !account.isViewOnly) || !isEmpty(tradingAccounts)) && renderedObject}
      {showRedirectionPopUp && isMobileDevice && <ForexWebtraderRedirectionModal
        showRedirectionModal={showRedirectionModal}
        onCloseRedirectionModal={() => this.closeWebtraderRedirectionModal()}
        onMobileTrade={() => this.onMobileTrade(tradingAccounts)}
        onOpenMobileApp={() => this.onOpenMobileApp()}
      />}
      {<ForexWebtraderModal
        tradingAccounts={tradingAccounts}
        showAccountsModal={showAccountsModal}
        onClose={() => this.closeWebtraderAccountsModal()}
      />}
    </Grid>)
  }
}

ForexTradeNow.propTypes = {
  children: PropTypes.any,
  classes: PropTypes.object.isRequired,
  alignLeft: PropTypes.bool,
}


export default compose(
  withNamespaces(),
  withRouter,
  withStyles(styles, {withTheme: true}),
)(ForexTradeNow)
