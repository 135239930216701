import React, {Component} from 'react'
import {graphql} from 'react-apollo'
import {filter, find, first, flowRight as compose, get, isEmpty, slice, some} from 'lodash'
import Typography from '@mui/material/Typography'
import {withNamespaces, Trans} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import messages from '../../assets/messages'
import {Container, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TablePagination, TextField} from '@mui/material'
import {fnsLocales} from '../../common/utils/uioptions'
import 'react-image-lightbox/style.css'
import LoadingButton from '../Common/LoadingButton'
import {countries} from '@bdswiss/common-enums'
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3'
import * as dateFnsLocales from 'date-fns/locale'
import AppContext from '../Common/contexts/AppContext'
import moment from 'moment'
import {validateDate, validateEmail, validatePhone} from '../../common/utils/validations'
import classNames from 'classnames'
import {AlertDialog} from '../Common/Dialog'
import Gallery from 'react-grid-gallery'
import {isMobile} from '../../common/utils'
import {CREATE_EVENT_REQUEST_FORM} from '../../graphql/mutations'
import {CLIENT_DATA_QUERY, PARTNER_GALLERY_QUERY} from '../../graphql/queries'
import CountriesSelect from '../Common/CountriesSelect'
import Loading from '../Common/Loading'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  eventsText: {
    paddingTop: 10,
  },
  countrySelect: {
    height: '1.3em',
    paddingBottom: 10
  },
  eventsArea: {
    paddingTop: 20,
    paddingBottom: 20
  },
  viewGallery: {
    textAlign: 'center',
    paddingTop: 15,
    paddingBottom: 15,
  },
  pointer: {
    cursor: 'pointer',
  },
  media: {
    width: 320,
    height: 140,
  },
  link: {
    color: theme.palette.primary.main,
  },
  requestForm: {
    border: `1px solid ${theme.palette.lightgrey.color}`,
    borderRadius: 6,
    padding: 10,
  },
  dateInput:{
    width: '100%'
  },
  eventsTitle: {
    textAlign: 'center',
  },
  galleryMain: {
    display: 'block',
    minHeight: '1px',
    width: '100%',
    background: 'none',
    textAlign: 'center',
    overflow: 'auto',
    cursor: 'none',
    'pointer-events': 'none'
  },
  eventsRequestForm: {
    textAlign: 'center',
    paddingTop: 10
  },
  dialogTitle: {
    textAlign: 'start',
    paddingLeft: 15,
    fontWeight: 300,
  },
  bold: {
    fontWeight: 400,
  },
  list: {
    color: theme.palette.primary.main,
  },
  Pagination: {
    display:'flex',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'left',
    paddingTop:'10px',
  },
  dialogGallery: {
    minHeight: 600
  },
  helpText:{
    color: theme.palette.grey[500],
    float: theme.direction === 'rtl' ? 'right' : 'left',
  },
})

class Events extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {
      status: '',
      loading: false,
      errors: {},
      itemIndex: 0,
      showEventsGallery: false,
      page: 0,
      rowsPerPage: isMobile() ? 6 : 12,
      countryChanged: false,
      countryFlag:'',
      form: {
        country: '',
        guests: '',
        eventDate:'',
        phone: '',
        email: '',
        details:'',
      },
      eventFormStatus: '',
      eventFormLoading: false,
      countryList: filter(countries, (country) => !country.forbidden && !country.hidden)
    }
  }

  handleChange (name, value) {
    this.setState(state => ({
      form: {
        ...state.form,
        [name]: value
      },
      errors: {
        ...state.errors,
        [name]: !value,
      }
    }))
  }

  openGallery(value) {
    const {galleryImages} = this.props
    return (<Gallery images={galleryImages}/>)
  }

  onSubmit() {
    const {viewer:{id}} = this.props
    const {form, form:{country, guests, eventDate, phone, email, details}, errors, countryList} = this.state
    for (const field of Object.keys(form)) {
      if (field === 'email') {
        errors[field] = isEmpty(form[field]) || !validateEmail(form[field])
      } else if (field === 'eventDate') {
        errors[field] = isEmpty(form[field]) || !validateDate(form[field], true)
      } else if (field === 'phone') {
        errors[field] = isEmpty(form[field]) || !validatePhone(form[field], true)
      } else {
        errors[field] = isEmpty(form[field])
      }
    }
    if (some(errors)) {
      this.setState({errors})
      return
    }
    const countryLabel = get(find(countryList, (a) => a.value === country), 'label')
    this.setState({eventFormLoading: true})
    this.props.createEventRequestForm({variables: {clientId: id, email, phone, message: details, eventDate, guests, country: countryLabel, type: 'eventsForm'}})
      .then(() => {
        this.setState({eventFormLoading: false, eventFormStatus: 'success', country: '', email: '', phone: '', details: '', guests: '', eventDate: '', sendLoading: false, updgradeDialog: false,
          sendStatus: 'success'})
        this.context.showNotification({
          subTitle: <Trans {...messages.supportTicketSubmitted} />,
          type: 'document-upload',
          status: 'success',
          content: <Trans {...messages.supportTicketSubmittedContent} />,
          buttonMessage: <Trans {...messages.close} />,
          onClose: () => this.props.history.push('/partner-zone/overview'),
          subTitleCenter: true,
        })
      })
  }

  handleChangeCountry(value) {
    this.setState({showCountries:false,countryFlag:filter(countries,(country)=>country.value===value), status: ''})
  }

  setStateOuter = (prop, value) => {
    this.setState({[prop]: value})
  }
  render() {
    const {t, classes, galleryImages, loadingGallery} = this.props
    const {locale} = this.context
    const {showEventsGallery, rowsPerPage, page, errors, submitMessageError,
      form: {eventDate, guests, email, phone, details}, eventFormLoading, eventFormStatus, countryFlag, countryList} = this.state
    if (loadingGallery) return <Loading speed={1} className={classes.loadingPadding} />
    const eventDateFormat =  eventDate && validateDate(eventDate, true)
    const eventDateValidFormat = eventDate && moment(eventDate, eventDateFormat).format('YYYY-MM-DD')
    const imageList = !isEmpty(galleryImages) && slice(galleryImages, rowsPerPage*page, rowsPerPage*page + rowsPerPage)
    const featuredImages = slice(galleryImages, 0, 4)

    return (
      <Container maxWidth="md"><Grid container>
        <Grid item xs={12} className={classes.eventsTitle}>
          <Typography variant="h1"><Trans {...messages.eventsTitle} /></Typography>
        </Grid>
        <Grid item xs={12} className={classes.eventsText}>
          <Typography variant="body1"><Trans {...messages.eventsText} /></Typography>
        </Grid>
        <Grid item xs={12}>
          <ul key="information" className={classes.list}>
            <li className={classes.listItem}>
              <Typography variant='body1'>
                <Trans {...messages.eventsListText1}
                  components={[<span className={classes.bold}>Bespoke guidance and support</span>]}/>
              </Typography>
            </li>
            <li className={classes.listItem}>
              <Typography variant='body1'>
                <Trans {...messages.eventsListText2}
                  components={[<span className={classes.bold}>Printed Marketing Materials, goody bags, and presentations</span>]}/>
              </Typography>
            </li>
            <li className={classes.listItem}>
              <Typography variant='body1'>
                <Trans {...messages.eventsListText3}
                  components={[<span className={classes.bold}>Additional support such as venue hire, flights and accomodation</span>]}/>
              </Typography>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          {featuredImages &&<div className={classes.galleryMain}>
            <Gallery
              images={featuredImages}
              enableLightbox={false}
              showLightboxThumbnails={false}
              enableImageSelection={false}
              backdropClosesModal={true}
              rowHeight={152}
              rowWidth={'100%'}
            />
          </div>}
        </Grid>
        <Grid item xs={12} className={classNames(classes.viewGallery, classes.pointer)} onClick={()=> this.setState({showEventsGallery: true})}>
          <Typography variant='body2' className={classes.link}><Trans {...messages.viewAll} /></Typography>
        </Grid>
        <Grid item xs={12} className={classes.requestForm}>
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.eventsRequestForm}>
              <Typography variant="h2"><Trans {...messages.eventsRequestForm} /></Typography>
            </Grid>
            <Grid item xs={isMobile() ? 12 : 4}>
              <CountriesSelect
                countryList={countryList}
                handleChangeField={(e) => this.handleChangeCountry(e)}
                handleChange={this.handleChange.bind(this)}
                setStateOuter={this.setStateOuter.bind(this)}
                errors={errors}
                value={countryFlag && first(countryFlag)['label']}
                name="country"
                label={t(messages.countryOfEvent.i18nKey, messages.countryOfEvent.defaults)}
                showRequired
              />
            </Grid>
            <Grid item xs={isMobile() ? 12 : 4}>
              <FormControl variant="standard" className={classes.formControl} fullWidth required>
                <InputLabel id="demo-simple-select-label"><Trans {...messages.guests} /></InputLabel>
                <Select
                  variant="standard"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={guests}
                  error={errors.guests}
                  onChange={(e)=> this.handleChange('guests', e.target.value)}>
                  <MenuItem value={'less than 50'}>{'less than 50'}</MenuItem>
                  <MenuItem value={'50-99'}>{'50-99'}</MenuItem>
                  <MenuItem value={'100+'}>{'100+'}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={isMobile() ? 12 : 4}>
              <FormControl variant="standard" className={classes.formControl} fullWidth>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={get(dateFnsLocales, locale) || get(dateFnsLocales, fnsLocales[locale]) || get(dateFnsLocales, fnsLocales['default'])} >
                  <DatePicker
                    id="eventDate"
                    name="eventDate"
                    label={t(messages.date.i18nKey, messages.date.defaults)}
                    format="dd/MM/yyyy"
                    value={eventDate ? new Date(eventDateValidFormat) : null}
                    onChange={(selectedDate) => this.handleChange('eventDate', moment(selectedDate).format('DD/MM/YYYY'))}
                    className={classes.dateInput}
                    minDate={new Date(moment().add(1, 'days').format('YYYY-MM-DD'))}
                    maxDate={new Date(moment().add(1, 'years').format('YYYY-MM-DD'))}
                    slotProps={{
                      textField: {
                        helperText: null,
                        required: true,
                        error: !!errors['eventDate']
                      },
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={isMobile() ? 12 : 6} lg={6}>
              <FormControl variant="standard" className={classes.formControl} fullWidth>
                <TextField
                  variant="standard"
                  required
                  label={t(messages.phone.i18nKey, messages.phone.defaults)}
                  value={phone}
                  onChange={(e)=> this.handleChange('phone', e.target.value)}
                  error={errors['phone']} />
              </FormControl>
            </Grid>
            <Grid item xs={isMobile() ? 12 : 6} lg={6}>
              <FormControl variant="standard" className={classes.formControl} fullWidth>
                <TextField
                  variant="standard"
                  required
                  label={t(messages.email.i18nKey, messages.email.defaults)}
                  value={email}
                  onChange={(e)=> this.handleChange('email', e.target.value)}
                  error={errors.email} />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="standard" className={classes.formControl} fullWidth>
                <TextField
                  variant="standard"
                  required
                  multiline
                  label={t(messages.provideMoreDetails.i18nKey, messages.provideMoreDetails.defaults)}
                  value={details}
                  onChange={(e)=> this.handleChange('details', e.target.value)}
                  error={errors['details']} />
                <FormHelperText className={classes.helpText}><i>
                  <Trans {...messages.eventFormHelperText} />
                </i></FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} className={classes.textCenter}>
              <LoadingButton
                id='loadingButton'
                onClick={() => this.onSubmit()}
                disabled={eventFormLoading || eventFormStatus === 'failure'}
                status={eventFormStatus}
                fullWidth={true}
                noMargin
              >
                <Trans {...messages.submit} />
              </LoadingButton>
              {eventFormStatus ==='failure' &&
                <FormHelperText classes={{root: classes.errorRoot}}className={classes.error}>{submitMessageError}</FormHelperText>}
            </Grid>
          </Grid>
        </Grid>
        <AlertDialog
          open={showEventsGallery}
          onClose={() => this.setState({showEventsGallery: false})}
          title ={<Typography variant='h2' className={classes.dialogTitle}><Trans {...messages.gallery} /></Typography>}
          closeBtn
          disabledTypography
          maxWidth="md"
        >
          <Grid container className={classes.dialogGallery}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} className={classes.eventsArea}>
                  {imageList && <Gallery
                    images={imageList}
                    showLightboxThumbnails={true}
                    enableImageSelection={false}
                    backdropClosesModal={true}
                  />}
                </Grid>

                <Grid item xs={12} className={classes.viewGallery}>
                  {!isEmpty(galleryImages) && galleryImages.length > rowsPerPage &&
                    <TablePagination
                      rowsPerPageOptions={[rowsPerPage]}
                      count={galleryImages.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      component="div"
                      className={classes.Pagination}
                      onPageChange={(e, page) => this.setState({page})}
                    />}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AlertDialog>
      </Grid>
      </Container>
    )
  }
}

export default compose(
  withStyles(styles),
  withNamespaces(),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading, viewer}}) => ({error, loading, viewer})
  }),
  graphql(CREATE_EVENT_REQUEST_FORM, {
    name: 'createEventRequestForm',
  }),
  graphql(PARTNER_GALLERY_QUERY, {
    options: () => ({fetchPolicy: 'network-only'}),
    props: ({data: {loading, error}, data}) => ({
      loadingGallery: loading,
      errorGallery: error,
      galleryImages: get(data, 'partnerGallery'),
    })
  }),
)(Events)
