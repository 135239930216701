import React from 'react'
import classNames from 'classnames'
import Images from '../Common/Images'
import {flowRight as compose} from 'lodash'
import withStyles from '@mui/styles/withStyles'
import AppContext from '../Common/contexts/AppContext'

const styles = (theme) => ({
  arrowContainer: {
    cursor: 'pointer',
    position: 'absolute',
    '&.left': {
      left: 'calc(0% - 32px)'
    },
    '&.right': {
      right: 'calc(0% - 32px)',
    }
  },
  marketAnalysis: {
    cursor: 'pointer',
    position: 'absolute',
    '&.left': {
      left: theme.direction === 'rtl' ? 'calc(0% - 32px)' : '225px'
    },
    '&.right': {
      right: theme.direction === 'rtl' ? 'calc(0% + 225px)' : 'calc(0% - 32px)'
    }
  }
})

class CustomCarouselArrow extends React.Component {
  static contextType = AppContext

  render() {
    const {themePreference} = this.context
    const {classes, onClick, direction, location} = this.props
    return (
      <div
        onClick={() => {
          onClick() //onClick function implementation inside react-multi-carousel package
        }}
        className={classNames(
          direction,
          location ==='marketAnalysis' ? classes.marketAnalysis : classes.arrowContainer,
        )}
      >
        <img
          alt='test'
          src={Images[`rounded-arrow-${direction}-${themePreference}.svg`]}
        />
      </div>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
)(CustomCarouselArrow)
