import React, {Component} from 'react'
import {withNamespaces, Trans} from 'react-i18next'
import {graphql, withApollo} from 'react-apollo'
import withStyles from '@mui/styles/withStyles'
import {ELECTRONIC_ID_VERIFICATION} from '../../../graphql/mutations'
import {CLIENT_DATA_QUERY} from '../../../graphql/queries'
import LoadingButton from '../LoadingButton'
import messages from '../../../assets/messages'
import SocketManager from '../../../socketManager'
import {Typography} from '@mui/material'
import {isMobile} from '../../../common/utils/browser'
import ElectronicVerificationModal from './ElectronicVerificationModal'
import {get, flowRight as compose} from 'lodash'
import {logEventCustomParams} from '../../../common/utils/firebaseEvents'

const styles = theme => ({
  root: {
    marginTop: 12,
  },
})

class ElectronicVerification extends Component {
  state = {
    showButton: true,
    buttonDisabled: false,
    extraFieldsForm:false
  }

  constructor(props) {
    super(props)
    if (!SocketManager.connection) SocketManager.initialize(get(props.viewer, 'id'))
    SocketManager.connection.on('electronicIdVerification', this.handleElectronicIdVerificationResult)
  }

  componentDidMount() {
    logEventCustomParams('expressKYCCTAPresented', {
      userId: get(this.props, 'viewer.id'),
    })
  }

  handleButtonClick = async (fields) => {
    const {extraFields, viewer} = this.props
    this.setState({buttonDisabled: true})
    if (extraFields)
      await this.props.doElectronicIDVerification({variables: fields})
    else  await this.props.doElectronicIDVerification()
    logEventCustomParams('expressKYCIntent', {
      userId: get(viewer, 'id'),
    })
  }

  handleExtraFields = () => {
    this.setState({extraFieldsForm: true})
  }

  handleModalClose = () => {
    this.setState({extraFieldsForm: false})
  }

  handleElectronicIdVerificationResult = (payload) => {
    this.setState({buttonDisabled: false, showButton: false})

    if (payload.error) {
      this.setState({message: <Trans {...messages.kycCannotContactServiceExplanation} />})
      console.log(payload.error) // eslint-disable-line
      logEventCustomParams('expressKYCError', {
        reason: 'kycCannotContactServiceExplanation',
        userId: get(this.props, 'viewer.id'),
      })
    } else if (payload.idReliability === 'good') {
      logEventCustomParams('expressKYCCompleted', {userId: get(this.props, 'viewer.id')})
      this.props.client.query({query: CLIENT_DATA_QUERY, fetchPolicy: 'network-only'})
    } else {
      logEventCustomParams('expressKYCError', {
        reason: 'kycAutoRejectedExplanation',
        userId: get(this.props, 'viewer.id'),
      })
      this.setState({message: <Trans {...messages.kycAutoRejectedExplanation} />})
    }
  }

  render() {
    const {classes, disabled, extraFields, clientCountry} = this.props
    const {buttonDisabled, showButton, message, extraFieldsForm} = this.state
    return (<React.Fragment>
      {!message && <Typography variant="body1"><Trans {...messages.kycAutoDescription} /></Typography>}
      {message && <Typography variant="body1">{message}</Typography>}
      {showButton && <LoadingButton
        className={classes.root}
        disabled={buttonDisabled || disabled}
        variant="contained"
        size="medium"
        onClick={extraFields ? this.handleExtraFields : this.handleButtonClick}
        fullWidth={isMobile()}
        hideProgressBar = {disabled}
      >
        <Trans {...messages.expressAccountVerification} />
      </LoadingButton>}
      {extraFields && <ElectronicVerificationModal open={extraFieldsForm} fields={extraFields} onSubmit={this.handleButtonClick} onClose={this.handleModalClose} country={clientCountry}/>}
    </React.Fragment>)
  }
}

export default compose(
  withNamespaces(),
  withStyles( styles, {withTheme: true}),
  withApollo,
  graphql(ELECTRONIC_ID_VERIFICATION, {
    name: 'doElectronicIDVerification',
    options: {
      refetchQueries: [{query: CLIENT_DATA_QUERY}],
    }
  }),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {loading, error}, data}) => ({
      loading,
      error,
      viewer: get(data, 'viewer')
    })
  }),
)(ElectronicVerification)
