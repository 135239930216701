import React, {Component} from 'react'
import {isEmpty, map, get, includes, slice, flowRight as compose} from 'lodash'
import {Trans, withNamespaces} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import config from '../../config'
import messages from '../../assets/messages'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableFooter from '@mui/material/TableFooter'
import TablePagination from '@mui/material/TablePagination'
import Typography from '@mui/material/Typography'
import NotificationBar from '../Common/NotificationBar'
import moment from 'moment'
import Grid from '@mui/material/Grid'
import {isMobile} from '../../common/utils'
import {referralStatuses} from '@bdswiss/common-enums'
import classNames from 'classnames'
import PageSubTitle from '../Common/PageSubTitle'
import {referAndEarnDetails} from '../../common/utils/uioptions'
import CopyToClipboard from 'react-copy-to-clipboard'
import Tooltip from '@mui/material/Tooltip'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'

const styles = theme => ({
  divTable: {
    overflowX: 'auto',
  },
  notificationBox: {
    backgroundColor: theme.palette.extralightgrey.color,
    cursor: 'pointer'
  },
  redText: {
    color: theme.palette.red.color
  },
  greenText: {
    color: theme.palette.green.color
  },
  yellowText: {
    color: theme.palette.yellow.color
  },
  container: {
    borderBottom: `1px solid ${theme.palette.lightgrey.color}`
  },
  textRight: {
    textAlign: 'right'
  },
  inline: {
    display: 'inline'
  },
  claimedText: {
    paddingRight: 5
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: 400,
  },
  disabledText:{
    color: theme.palette.lightgrey.color
  },
  activeLink:{
    cursor: 'pointer'
  },
  claimLink:{
    marginBottom:15,
    marginTop: 15,
    display: 'inline-flex'
  },
  dot: {
    fontSize: 12,
    marginRight: 5
  }
})

class ReferralsTable extends Component {

  constructor(props) {
    super(props)
    this.state = {page: 0, rowsPerPage: 10, clickedEvent:false}
  }

  getReferralStatus(status) {
    if (includes([referralStatuses.clientDeclined.value, referralStatuses.activationOverdue.value], status)) {
      return 'red'
    } else if (includes([referralStatuses.claimed.value, referralStatuses.activated.value], status)) {
      return 'green'
    } else {
      return 'yellow'
    }
  }

  renderTable(referrals) {
    const {classes, locale} = this.props
    const {page, rowsPerPage} = this.state
    const {currency} = referAndEarnDetails

    return <Table>
      <TableHead>
        <TableRow>
          <TableCell><Typography variant='caption'>{`${config.name} ID`}</Typography></TableCell>
          <TableCell><Typography variant='caption'><Trans {...messages.registrationDate} /></Typography></TableCell>
          <TableCell><Typography variant='caption'><Trans {...messages.status} /></Typography></TableCell>
          <TableCell><Typography variant='caption'><Trans {...messages.deposit} /></Typography></TableCell>
          <TableCell><Typography variant='caption'><Trans {...messages.volumeTitle} /></Typography></TableCell>
          <TableCell className={classes.textRight}><Typography variant='caption'><Trans {...messages.reward} />, {`${currency}`}</Typography></TableCell>
        </TableRow>
      </TableHead>
      {!isEmpty(referrals) && <TableBody>
        {map(slice(referrals, rowsPerPage*page, rowsPerPage*page + rowsPerPage), (r) => <TableRow key={r.referralId}>
          <TableCell><Typography variant='body1'>{r.referralId}</Typography></TableCell>
          <TableCell><Typography variant='body1'>{moment(r.registrationDate).format('MMM Do YYYY HH:mm')}</Typography></TableCell>
          <TableCell><Typography variant='body1'>
            <FiberManualRecordIcon className={classNames(classes.dot, includes([referralStatuses.activated.value, referralStatuses.claimed.value], r.status) ? classes.greenText : classes.redText)}/>
            {r.status === referralStatuses.claimed.value ?
              referralStatuses.activated.localization.t(locale) : referralStatuses[r.status].localization.t(locale)}
          </Typography>
          </TableCell>
          <TableCell>
            <FiberManualRecordIcon className={classNames(classes.dot, r.deposit ? classes.greenText : classes.redText)}/>
            <Typography variant='body1' className={classes.inline}><Trans {...messages[r.deposit ? 'deposited' : 'pending']} /></Typography>
          </TableCell>
          <TableCell><Typography variant='body1'>{r.volume}</Typography></TableCell>
          <TableCell className={classes.textRight}>
            {r.status === referralStatuses.claimed.value &&
              <Typography variant='body1' className={classNames(classes.inline, classes.claimedText)}>({referralStatuses.claimed.localization.t(locale)})</Typography>}
            <Typography variant='body1' className={classes.inline}>{get(r, 'amountReferrer', 0)}</Typography>
          </TableCell>
        </TableRow>)}
      </TableBody>}
      {!isEmpty(referrals) && referrals.length > rowsPerPage && <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[rowsPerPage]}
            count={referrals.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => this.setState({page})}
          />
        </TableRow>
      </TableFooter>}
    </Table>
  }

  renderTableMobile(referrals) {
    const {classes, locale} = this.props
    const {page, rowsPerPage} = this.state
    const {currency} = referAndEarnDetails
    const referralsList = !isEmpty(referrals) && slice(referrals, rowsPerPage*page, rowsPerPage*page + rowsPerPage)
    return <Grid container>
      {referralsList && map(referralsList, (r, index) => <Grid container spacing={1} key={r.referralId} className={referralsList.length > index+1 ? classes.container : ''}>
        <Grid item xs={12}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={6}>
              <Typography variant='caption'>{`${config.name} ID`}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1'>{r.referralId}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={6}>
              <Typography variant='caption'><Trans {...messages.registrationDate} /></Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1'>{moment(r.registrationDate).format('MMM Do YYYY HH:mm')}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={6}>
              <Typography variant='caption'><Trans {...messages.status} /></Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1'>
                <FiberManualRecordIcon className={classNames(classes.dot, includes([referralStatuses.activated.value, referralStatuses.claimed.value], r.status) ? classes.greenText : classes.redText)}/>
                {r.status === referralStatuses.claimed.value ?
                  referralStatuses.activated.localization.t(locale) : referralStatuses[r.status].localization.t(locale)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={6}>
              <Typography variant='caption'><Trans {...messages.reward} />, {`${currency}`}</Typography>
            </Grid>
            <Grid item xs={6}>
              {r.status === referralStatuses.claimed.value &&
                <Typography variant='body1' className={classNames(classes.inline, classes.claimedText)}>({referralStatuses.claimed.localization.t(locale)})</Typography>}
              <Typography variant='body1' className={classes.inline}>{get(r, 'amountReferrer', 0)}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={6}>
              <Typography variant='caption'><Trans {...messages.deposit} /></Typography>
            </Grid>
            <Grid item xs={6}>
              <FiberManualRecordIcon className={classNames(classes.dot, r.deposit ? classes.greenText : classes.redText)}/>
              <Typography variant='body1' className={classes.inline}><Trans {...messages[r.deposit ? 'deposited' : 'pending']} /></Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={6}>
              <Typography variant='caption'><Trans {...messages.volumeTitle} /></Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body1'>{r.volume}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      )}
      <Grid item xs={12}>
        {!isEmpty(referrals) && referrals.length > rowsPerPage && <React.Fragment>
          <Table>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[rowsPerPage]}
                  count={referrals.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(e, page) => this.setState({page})}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </React.Fragment>}
      </Grid>
    </Grid>
  }

  render() {
    const {classes, referrals, pendingRewards, onClaim, referralDetails, t, isVerified, reveleadLink} = this.props
    const showLink = isVerified && reveleadLink
    return <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={9}>
            <PageSubTitle><Trans {...messages.yourReferrals} /></PageSubTitle>
          </Grid>
          <Grid item xs={3} className={classes.textRight}>
            <Typography
              variant='body2'
              onClick={() => pendingRewards && onClaim()}
              color="primary"
              className={classNames(!pendingRewards ? classes.disabledText : classes.activeLink, classes.claimLink)}
            >
              <Trans {...messages.claimReward} />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.divTable}>
        {!isMobile() ? this.renderTable(referrals) : this.renderTableMobile(referrals)}
        {isEmpty(referrals) &&
          <NotificationBar
            status=""
            classes={{notificationBox: classes.notificationBox}}
            title={<React.Fragment>
              <CopyToClipboard text={showLink ? get(referralDetails, 'link') : ''}>
                <Typography variant='body1' component="span" onClick={()=> showLink ? this.setState({clickedEvent: true}) : ''}
                  onMouseLeave={()=> this.setState({clickedEvent: false})}>
                  <Trans {...messages.copyShareReward} components={[<span className={showLink ? classes.link : ''}>yourLink</span>]}/>
                </Typography>
              </CopyToClipboard>
              <Tooltip
                open={this.state.clickedEvent}
                title={t(messages.partnerLinkCopied.i18nKey,messages.partnerLinkCopied.defaults)}
                placement="right"
                disableFocusListener
                disableHoverListener
                disableTouchListener
                onClose={() => this.setState({clickedEvent: false})}
              ><span></span>
              </Tooltip>
            </React.Fragment>}
          />
        }
      </Grid>
      <Grid item xs={12} className={classes.divTable}>
        <Typography variant='body1'><Trans {...messages.noteReferralDataUpdate} /></Typography>
      </Grid>
    </Grid>
  }
}

export default  compose(
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
)(ReferralsTable)
