import React, {Component} from 'react'
import moment from 'moment'
import classnames from 'classnames'
import {map, get, some, flowRight as compose} from 'lodash'
import {graphql} from 'react-apollo'
import Grid from '@mui/material/Grid'
import Table from '@mui/material/Table'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import TimerIcon from '@mui/icons-material/TimerOutlined'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import {withNamespaces, Trans} from 'react-i18next'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import {documentStatuses, documentTypes, documentRejectionReasons, kycStatuses} from '@bdswiss/common-enums'
import {Loading} from '../../Common/Loading'
import messages from '../../../assets/messages'
import {CLIENT_DATA_QUERY, PROFILE_SETTINGS_QUERY} from '../../../graphql/queries'
import {DELETE_DOCUMENT_MUTATION} from '../../../graphql/mutations'
import Tooltip from '@mui/material/Tooltip'
import Images from '../../Common/Images'
import NotificationBar from '../../Common/NotificationBar'
import AppContext from '../../Common/contexts/AppContext'
import LoadingButton from '../../Common/LoadingButton'

const styles = theme => ({
  error: {
    color: theme.palette.red.color
  },
  checkIcon: {
    color: theme.palette.green.color
  },
  timerIcon: {
    color: theme.palette.yellow.color
  },
  lastCell: {
    maxWidth:150
  },
  dateTime:{
    color: theme.palette.secondary.main
  },
  emptyListsIcon: {
    ...theme.emptyListsIcon
  },
  noTradesIconWidth: {
    textAlign: 'center'
  },
  notificationBarWrap: {
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(2),
    },
  },
})

class DocumentsHistory extends Component {
  static contextType = AppContext

  state = {
    loadingDoc: false,
  }

  getIcon(classes, status) {
    if (status === documentStatuses.accepted.key) {
      return <CheckIcon className={classes.checkIcon} />
    } else if (status === documentStatuses.rejected.key) {
      return <CloseIcon className={classes.error} />
    }
    return <TimerIcon className={classes.timerIcon} />
  }

  transformTooltip(text) {
    return text.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^\w/, c => c.toUpperCase())
  }

  otherReasonMessage(reason, locale) {
    if (reason !== documentRejectionReasons.other.key) {
      return (
        documentRejectionReasons[reason].localization.t(locale)
      )
    } else {
      return (
        <Trans {...messages.documentsOtherReasonMessage} />
      )
    }
  }

  onCancelClick = (d) => {
    this.setState((state) => ({loadingDoc: {...state.loadingDoc,[d.id]: true}}))
    this.props.removeDocument({variables: {documentId: d.id}})
      .then(() => this.setState((state) => ({loadingDoc: {...state.loadingDoc,[d.id]: false}})))
      .catch(() => this.setState((state) => ({loadingDoc: {...state.loadingDoc,[d.id]: false}})))
  }

  render() {
    const {loadingDoc} = this.state
    const {t, loading, documentsLoading, classes, documents, viewer: {locale, kycStatus}} = this.props
    const {themePreference} = this.context
    if (loading || documentsLoading) return <Loading />

    const hasRejectedDocs = some(documents, (d) => d.status === documentStatuses.rejected.key)
    const showCancelButton = kycStatus !== kycStatuses.approved.value && some(documents, (d) => d.status === documentStatuses.notProcessed.key)
    return (
      <Grid container>
        {documents.length < 1 && <Grid container className={classes.notificationBarWrap}>
          <Grid item xs={12}>
            <NotificationBar status='info' title={<Trans {...messages.noDocuments} />} />
            <Grid container
              direction="column"
              justifyContent="space-between"
              alignItems="center">
              <Grid item className={classes.noTradesIconWidth}>
                <img className={classes.emptyListsIcon} src={Images[`documents-empty-${themePreference}.png`]} alt='noTrades' />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        }
        {documents.length > 0 && <Grid item xs={12} sm={8} lg={6}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell/>
                <TableCell><Trans {...messages.documentType} /></TableCell>
                <TableCell><Trans {...messages.date} /></TableCell>
                {(hasRejectedDocs || showCancelButton) && <TableCell className={classes.cell} />}
              </TableRow>
            </TableHead>
            <TableBody>
              {map(documents, (d) =>
                <TableRow key={d.id} aria-label={d.status}>
                  <TableCell className={classes.cell} component="th" scope="row">
                    <Tooltip title={t(messages[d.status].i18nKey, messages[d.status].defaults)} placement="top">
                      {this.getIcon(classes, d.status)}
                    </Tooltip>
                  </TableCell>
                  <TableCell className={classnames([classes.cell])}>
                    <Typography variant="body1">{documentTypes[d.type].localization.t(locale)}</Typography>
                  </TableCell>
                  <TableCell className={classes.cell}>
                    <Typography variant="body1" className={classes.dateTime}>{moment(d.createdAt).format('DD MMM YYYY')}</Typography>
                  </TableCell>
                  {(hasRejectedDocs && d.status === documentStatuses.rejected.key) ?
                    <TableCell className={classnames([classes.cell, classes.lastCell])}>
                      <Typography variant="caption">
                        {d.rejectionReasonCode && this.otherReasonMessage(d.rejectionReasonCode, locale)}
                      </Typography>
                    </TableCell> : (showCancelButton && d.status === documentStatuses.notProcessed.key) ?
                      <TableCell className={classnames([classes.cell,classes.lastCell])}>
                        <LoadingButton
                          color="primary"
                          onClick={() => this.onCancelClick(d)}
                          disabled={!!loadingDoc[d.id]}
                          variant={'text'}
                          noMargin={true}
                          noPadding={true}
                          className = {'noButtonStyle'}
                        >
                          <Trans {...messages.cancel}/>
                        </LoadingButton>
                      </TableCell>: <TableCell />
                  }
                </TableRow>)
              }
            </TableBody>
          </Table>
        </Grid>}
      </Grid>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  graphql(PROFILE_SETTINGS_QUERY, {
    props: ({data: {error, loading: documentsLoading}, data}) => ({
      error,
      documentsLoading,
      documents: get(data, 'viewer.documents', []),
    })
  }),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {loading, error}, data}) => ({
      loading,
      error,
      viewer: get(data, 'viewer'),
    })
  }),
  graphql(DELETE_DOCUMENT_MUTATION, {
    name: 'removeDocument',
    options: {
      refetchQueries: [{query: CLIENT_DATA_QUERY}],
    }
  }),
)(DocumentsHistory)
