import React from 'react'
import {withNamespaces} from 'react-i18next'
import {flowRight as compose} from 'lodash'
import {withRouter} from 'react-router-dom'
import TransferRoutes from './routes'

class LocalDepositorTransfer extends React.Component {

  render() {
    return <TransferRoutes />
  }
}

export default compose(
  withNamespaces(),
  withRouter,
)(LocalDepositorTransfer)
