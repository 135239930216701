import React from 'react'
import {Route, Switch, withRouter} from 'react-router-dom'
import Transfer from './Transfer'

const TransferRoutes = ({match: {path}}) =>(
  <Switch>
    <Route path={`${path}`} component={Transfer}/>
  </Switch>
)

export default withRouter(TransferRoutes)
