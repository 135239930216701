import React, {Component} from 'react'
import {graphql} from 'react-apollo'
import {get, flowRight as compose, isEmpty} from 'lodash'
import queryString from 'qs'
import withStyles from '@mui/styles/withStyles'
import {withNamespaces, Trans} from 'react-i18next'
import messages from '../../assets/messages'
import UiNotification from './UiNotification'
import {LinearProgress, Typography} from '@mui/material'
import {WEBINAR_URL_MUTATION} from '../../graphql/mutations'

const styles = theme => ({
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight: 400,
  },
})

class WebinarDialog extends Component {
  state = {
    joinUrl: '',
    webinarLoading: false,
    webinarError: '',
  }

  componentDidMount() {
    const {location} = this.props
    const queryParams = queryString.parse(location.search.replace('?', ''))
    const webinarid =
      get(location, 'state.webinarid') || sessionStorage.getItem('webinarid') || get(queryParams, 'webinarid')
    const locationTrack =
      get(location, 'state.locationTrack') ||
      sessionStorage.getItem('locationTrack') ||
      get(queryParams, 'locationTrack')
    const variables = {webinarId: webinarid, locationTrack: locationTrack}
    this.setState({webinarLoading: true})
    return this.props
      .webinarUrl({variables})
      .then(res => {
        this.setState({
          joinUrl: res['data']['webinarUrl'].joinUrl,
          webinarLoading: false,
        })
        sessionStorage.removeItem('locationTrack')
        sessionStorage.removeItem('webinarid')
      })
      .catch(err => {
        this.setState({webinarLoading: false, webinarError: err.message.replace('GraphQL error: ', '')})
      })
  }

  handleClose() {
    this.props.history.push('/')
  }

  render() {
    const {classes, t} = this.props
    const {joinUrl, webinarLoading, webinarError} = this.state
    if (webinarLoading) {
      document.body.classList.add('loadingApp')
      return <LinearProgress />
    } else {
      document.body.classList.remove('loadingApp')
    }

    return (
      <UiNotification
        title={!isEmpty(webinarError) ? 'webinarErrorText' : 'webinarRegistration'}
        open={true}
        status={isEmpty(webinarError) ? 'success' : 'failure'}
        type="document-upload"
        buttonMessage={t(messages.close.i18nKey, messages.close.defaults)}
        onClose={() => this.handleClose()}
      >
        <Typography variant="body1">
          {isEmpty(webinarError) ? (
            <Trans
              {...messages.webinarRegistrationText}
              components={[
                <a target="_blank" rel="noopener noreferrer" href={joinUrl} className={classes.link}>
                  joinUrl
                </a>,
              ]}
              values={{joinUrl: joinUrl}}
            />
          ) : (
            <Trans {...messages.registrationError} />
          )}
        </Typography>
      </UiNotification>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  graphql(WEBINAR_URL_MUTATION, {
    name: 'webinarUrl',
  }),
)(WebinarDialog)
