import React, {Component} from 'react'
import {map, find, get, isEmpty, flowRight as compose, includes} from 'lodash'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import withStyles from '@mui/styles/withStyles'
import {withNamespaces, Trans} from 'react-i18next'
import {getFormattedAmount} from '../../../common/utils'
import messages from '../../../assets/messages'
import LoadingButton from '../../Common/LoadingButton'
import NotificationBar from '../../Common/NotificationBar'
import FormHelperText from '@mui/material/FormHelperText'
import AmountInput from '../../Common/AmountInput'
import AccountSelect from '../../Common/AccountSelect'
import PageSubTitle from '../../Common/PageSubTitle'
import {accountSubtypes, accountTypes, currencies} from '@bdswiss/common-enums'
import ErrorIcon from '@mui/icons-material/ErrorOutlineRounded'
import Amount from '../../Common/Amount'
import {getAccountSubtype, isCentAccount, isCentAccountBasedOnCurrency, isZuluAccount} from '../../../common/utils/accounts'
import classNames from 'classnames'
import SwitchButton from '../../Common/SwitchButton'
import Badge from '@mui/material/Badge'
import config from '../../../config'
import {withRouter} from 'react-router'
import {graphql} from 'react-apollo'
import {LOCAL_DEPOSITOR_DETAILS, REFERRED_BY_QUERY} from '../../../graphql/queries'

const style = (theme) => ({
  prefilledButton: {
    backgroundColor: theme.palette.extralightgreyBackground.color,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.extralightgreyBackground.color,
    },
    [theme.breakpoints.down('md')]: {
      height: 56,
      padding: theme.spacing(2),
    },
  },
  prefilledButtonSelected: {
    backgroundColor: theme.palette.lightgrey.color,
  },
  bottomSpacing: {
    marginBottom: theme.spacing(3),
  },
  highlightedText: {
    color: theme.palette.secondary.main
  },
  btnText:{
    color: theme.palette.grey.color
  },
  continueButton:{
    marginTop:30
  },
  textLink: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  errorIcon: {
    color: theme.palette.yellow.color,
    verticalAlign: 'sub',
    marginRight: 5,
    marginBottom: '-2px'
  },
  notificationDiv: {
    padding: 30,
    [theme.breakpoints.down('md')]: {
      padding: '20px 0 0 0',
    },
  },
  badge: {
    width: '100%',
  },
  badgeRoot: {
    width: 'auto',
    borderRadius: 6,
    padding: '5px 15px',
    backgroundColor: theme.palette.cyan.color,
    color: theme.palette.secondary.light,
    [theme.breakpoints.down('md')]: {
      height: 24,
      padding: `0 ${theme.spacing(1)}`,
      transform: 'scale(1) translate(12px, -50%)',
    },
  },
  greenText: {
    color: theme.palette.green.color,
  },
  invisible: {
    display: 'none'
  },
  pointer: {
    cursor: 'pointer',
  },
  topUp:{
    paddingBottom: 30
  },
  helpText:{
    color: theme.palette.grey[500],
    marginTop: 10
  },
  errorMessage:{
    color:  theme.palette.error.main,
    marginTop: 10
  },
  notificaiton: {
    marginLeft: -12
  },
  zarReferral: {
    padding: 12,
    backgroundColor:  theme.palette.lightyellow.color,
  },
  warningCTA: {
    color: theme.palette.notificationBar.yellowTextColor,
  },
  subTitle: {
    marginTop: 0,
  },
})

export class AccountAmountSelector extends Component {

  state = {}

  render() {
    const {t, locale, amount, classes, accounts, onAmountChanged, preFilledAmounts, selectedAccountId,
      minimumDeposit = {amount: 0}, onSelectedAccountChanged, onNextStep, ftdDate,
      minimumDepositDefault, eligibleBonus, bonusAmount, bonus, showMaximumEquityWarning,
      maximumAccountEquity, showSelectAmountEquityWarning, country, depositStatus, depositLoading, submitMessageError,
      viewer, minimumPartialDeposit, minimumBonusDeposit, referredBy, localDepositorSubscriptions, maximumDeposit,
    } = this.props
    const {showAmountEditor} = this.state
    const {countriesBonusTerms, defaultCurrency, minimumDepositFootnoteKey} = config

    const selectedAccount = find(accounts, {id: selectedAccountId})

    const formattedPrefilledAmounts = []
    const accountSubtype = get(selectedAccount, 'accountSubtype')
    const selectedSubType = getAccountSubtype(accountSubtype)
    const selectedAccountType = find(accountTypes, {key: get(selectedAccount, '__typename')})

    const allowPartialDeposits = get(accountSubtypes[accountSubtype], 'allowedPartialDeposits')
    let finalMinimumDeposit
    if (isZuluAccount(selectedAccount)) {
      finalMinimumDeposit = minimumBonusDeposit
    } else {
      finalMinimumDeposit = bonus ? minimumBonusDeposit : (minimumPartialDeposit || minimumDeposit)
    }

    const finalMaximumDeposit = maximumDeposit?.amount

    const invalidAmount = isNaN(amount) || (selectedAccount?.isDemo
      ? (amount <= 0)
      : (+amount < +finalMinimumDeposit || +amount > +Number(finalMaximumDeposit)))

    let helperText = (invalidAmount && +amount < +finalMinimumDeposit && !selectedAccount?.isDemo)
      ? (
        <Trans
          {...messages.minimumDepositAmount}
          values={{
            amount: getFormattedAmount({
              currency: isCentAccount(selectedAccount) || isCentAccountBasedOnCurrency(selectedAccount) ? currencies.CUD.baseCurrency : selectedAccount.currency,
              amount: isCentAccount(selectedAccount) || isCentAccountBasedOnCurrency(selectedAccount) ? finalMinimumDeposit / currencies.CUD.baseCurrencyRate : finalMinimumDeposit,
              locale
            })
          }}
        />
      ) : invalidAmount && +amount > +finalMaximumDeposit && !selectedAccount?.isDemo
        ? (
          <Trans
            {...messages.maximumDepositAmount}
            values={{
              amount: getFormattedAmount({
                currency: isCentAccount(selectedAccount) || isCentAccountBasedOnCurrency(selectedAccount) ? currencies.CUD.baseCurrency : selectedAccount.currency,
                amount: isCentAccount(selectedAccount) || isCentAccountBasedOnCurrency(selectedAccount) ? finalMaximumDeposit / currencies.CUD.baseCurrencyRate : finalMaximumDeposit,
                locale
              })
            }}
          />
        ) : ''

    const maximumAmountForEquity = maximumAccountEquity[selectedAccountType.subCategory]
    if (showMaximumEquityWarning && !invalidAmount) {
      helperText = (
        <Trans
          {...messages.equityWarning}
          values={{amount: getFormattedAmount({currency: selectedAccount.currency, amount: maximumAmountForEquity, locale})}}
        />
      )
    }

    if (preFilledAmounts && Array.isArray(preFilledAmounts) && preFilledAmounts.length > 0 && selectedAccount) {
      const {currency} = selectedAccount
      preFilledAmounts.sort((a, b) => a < b).forEach((p) => {
        const formattedValue = isCentAccount(selectedAccount) || isCentAccountBasedOnCurrency(selectedAccount)
          ? getFormattedAmount({locale, amount: p / currencies.CUD.baseCurrencyRate, currency: currencies.CUD.baseCurrency})
          : getFormattedAmount({locale, amount: p, currency})
        formattedPrefilledAmounts.push({value: p, formattedValue})
      })
      formattedPrefilledAmounts.push({showEditor: true, value: finalMinimumDeposit, formattedValue: t(messages.other.i18nKey, messages.other.defaults)})
    }

    const showPartialMsg = showAmountEditor && allowPartialDeposits && selectedAccount.isReadOnly
    const bonusEligible = get(viewer, 'eligibleBonus.eligibleBonus') && get(selectedAccount, 'eligibleBonus.eligibleBonus')
    const bonusPercentage = get(eligibleBonus, 'percentage')
    const bonusMaxAmount = get(eligibleBonus, 'maxAmount')
    const bonusCountry = find(countriesBonusTerms, (bonus) => includes(bonus.countries, country))
    const bonusTerms =  bonusEligible && (get(eligibleBonus, 'terms') || get((bonusCountry || config.weblinks), 'bonusTerms').replace('{lang}', locale))

    const amountEditor = isCentAccount(selectedAccount) || isCentAccountBasedOnCurrency(selectedAccount) ? amount / currencies.CUD.baseCurrencyRate : amount
    const accountCurrency = get(selectedAccount, 'currency')
    const currencyEditor = isCentAccount(selectedAccount) || isCentAccountBasedOnCurrency(selectedAccount) ? currencies.CUD.baseCurrency : accountCurrency
    const isZar = selectedAccount.currency === currencies.ZAR.value

    return (
      <Grid container>
        <Grid container>
          <Grid item xs={12} lg={6} >
            <Grid item xs={12}>
              <PageSubTitle customClasses={{header: classes.subTitle}}><Trans {...messages.chooseAccount} /> </PageSubTitle>
            </Grid>
            <Grid item xs={12} className={classes.bottomSpacing}>
              <AccountSelect
                label={t(messages.selectAccountToTopUp.i18nKey, messages.selectAccountToTopUp.defaults)}
                accounts={accounts}
                localDepositorSubscriptions={localDepositorSubscriptions}
                value={selectedAccountId}
                locale={locale}
                id="payments-deposit-account-selector"
                name="payments-deposit-account-selector"
                onChange={(e) => onSelectedAccountChanged(e.target.value)}
                t={t}
              />
            </Grid>
            {bonusEligible && <React.Fragment>
              <Grid item xs={12}>
                <SwitchButton iOSSwitch={true} id={'bonusSwitch'} checked={bonus} value={bonus} onChange={() => this.props.changeSwitch()}
                  label={t(messages.receiveBonus.i18nKey, messages.receiveBonus.defaults)}/>
              </Grid>
              <Typography variant="caption" align='justify'>
                <Trans {...messages.receiveBonusNote} values={{percentage: `${bonusPercentage*100}%`, maxAmount: getFormattedAmount({locale, amount: bonusMaxAmount, currency: selectedAccount.currency})}} />
              </Typography>
              <Typography variant="caption" align='justify'>
                <Trans {...messages.bonusTerms} components={[
                  <a target="_blank" rel="noopener noreferrer" href={bonusTerms || ''} className={classNames(classes.textLink, classes.pointer)}>termsLink</a>
                ]} />
              </Typography>
            </React.Fragment>
            }
            {selectedAccount.isDemo &&
            <Grid item xs={12} sm={12}>
              <NotificationBar
                status='warning'
                title={<Trans {...messages.demoReduceBalance}
                  components={[<Link to={`/transactions/${selectedAccountId}/withdraw`} className={classes.warningCTA}>Withdrawals</Link>]}
                />}
              />
            </Grid>}
            {showSelectAmountEquityWarning && !showAmountEditor && <NotificationBar
              status="warning"
              title={<Trans {...messages.selectAmountEquityWarning}
                values={{amount: getFormattedAmount({currency: selectedAccount.currency, amount: maximumAmountForEquity, locale})}}
              />}
            />
            }
            {isZar && referredBy && isEmpty(ftdDate) && <Grid item xs={12}>
              <div className={classes.zarReferral}>
                <Typography variant="body1"><Trans {...messages.zarReferralDeposit} /></Typography>
              </div>
            </Grid>}
            <Grid item xs={12}>
              <PageSubTitle><Trans {...messages.selectDepositAmount} /> </PageSubTitle>
            </Grid>
            {
              ((formattedPrefilledAmounts.length < 1 || showAmountEditor) || selectedAccount.isDemo) &&
          (<div>
            <Grid item xs={12}>
              <AmountInput
                jumbo
                value={amountEditor}
                locale={locale}
                currency={currencyEditor}
                onChange={(e, newAmount) => {
                  if ((isCentAccount(selectedAccount) || isCentAccountBasedOnCurrency(selectedAccount))&& +newAmount * currencies.CUD.baseCurrencyRate === +amount) {
                    return
                  }
                  onAmountChanged(isCentAccount(selectedAccount) || isCentAccountBasedOnCurrency(selectedAccount) ? +newAmount * currencies.CUD.baseCurrencyRate : newAmount, false)
                }}
                id="payments-deposit-amount-input"
                name="payments-deposit-amount-input"
                TextFieldProps={{error: invalidAmount || showMaximumEquityWarning, helperText}}
              />
            </Grid>
            {!invalidAmount && bonusEligible && bonus && <Grid item xs={12}>
              <Typography variant="body1"><Trans {...messages.receiveAmountBonus} components={[<span className={classes.greenText}>amount</span>]}
                values={{amount: getFormattedAmount({locale, amount: bonusAmount, currency: selectedAccount.currency})}}/> </Typography></Grid>}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <LoadingButton
                  onClick={(e, amount) => onNextStep()}
                  disabled={!selectedAccountId || invalidAmount || depositLoading}
                  className={classes.continueButton}
                  status={depositStatus}
                  hideProgressBar={!selectedAccountId || invalidAmount}
                >
                  <Trans {...messages.continue} />
                </LoadingButton>
                {depositStatus==='failure' && <FormHelperText className={classes.errorMessage}>{submitMessageError}</FormHelperText>}
              </Grid>
            </Grid>
          </div>)
            }
            {
              (!showAmountEditor && formattedPrefilledAmounts.length > 0) && selectedAccount.isDemo === false &&
              <Grid container item xs={12} spacing={3} pb={2} sx={{pt: {xs: 2, sm: 0}}}>
                {
                  map(formattedPrefilledAmounts, (p, i) => {
                    const prefilledButtonClasses = [classes.prefilledButton]
                    const bonusAmount = bonus && p.value * bonusPercentage
                    const finalBonusAmount = bonusAmount && (bonusAmount < get(eligibleBonus, 'maxAmount') ? bonusAmount
                      : get(eligibleBonus, 'maxAmount'))
                    let badgeContent = `+ ${getFormattedAmount({locale, amount: finalBonusAmount, currency: selectedAccount.currency})}`
                    let badgeClasses = classNames(classes.badgeRoot, (!bonusEligible || !bonus || p.showEditor) && classes.invisible)

                    if (isCentAccount(selectedAccount) || isCentAccountBasedOnCurrency(selectedAccount)) {
                      badgeContent = `${getFormattedAmount({locale, amount: p.value, currency: selectedAccount.currency})}`
                      badgeClasses = classNames(classes.badgeRoot, p.showEditor ? classes.invisible : '')
                    }

                    return (
                      <Grid item xs={6} md={4} key={`pre-selected-amount-${p.value}-${i}`}>
                        <Badge badgeContent={badgeContent} className={classes.badge}
                          classes={{badge: badgeClasses}}>
                          <Button
                            fullWidth
                            variant="contained"
                            className={classNames(prefilledButtonClasses)}
                            onClick={(e) => {
                              if (p.showEditor) {
                                this.setState({showAmountEditor: true})
                                onAmountChanged(p.value, false)
                              } else {
                                onAmountChanged(p.value, true)
                              }
                            }}
                          >
                            <Typography variant="body2" className={classes.btnText}>{p.formattedValue}</Typography>
                          </Button>
                        </Badge>
                      </Grid>
                    )
                  })
                }
              </Grid>
            }
            {isEmpty(ftdDate) && !selectedAccount.isDemo && <Grid item xs={12}>
              <Typography variant="caption" align='justify'>
                <Trans {...messages[minimumDepositFootnoteKey || 'minimumDepositFootnote']}
                  values={{amount: getFormattedAmount({currency: accountCurrency || defaultCurrency || 'USD', amount: minimumDeposit || minimumDepositDefault, locale})}}/>
              </Typography>
            </Grid>}
          </Grid>
          {showPartialMsg && <Grid item xs={12} md={6} lg={6} className={classes.notificationDiv}>
            <NotificationBar
              status="warning"
              title={<React.Fragment>
                <ErrorIcon className={classes.errorIcon} />
                <Trans {...messages.pleaseNote} /><br />
                <Trans {...messages.partialDepositNote} values={{type: selectedAccount.accountSubtype && get(selectedSubType, 'label')}}
                  components={[<Amount key={'amount'} value={minimumDeposit} currency={selectedAccount.currency} locale="en-US" />]} />
              </React.Fragment>}
            />
          </Grid>}
        </Grid>
      </Grid>
    )
  }
}

AccountAmountSelector.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape({
    id:  PropTypes.number.isRequired,
    remoteId:  PropTypes.string.isRequired,
    balance: PropTypes.number,
    currency: PropTypes.string.isRequired,
    __typename: PropTypes.string.isRequired
  })).isRequired,
  selectedAccountId: PropTypes.number.isRequired,
  onSelectedAccountChanged: PropTypes.func.isRequired,
  onAmountChanged: PropTypes.func.isRequired,
  minimumDeposit: PropTypes.number.isRequired,
  preFilledAmounts: PropTypes.arrayOf(PropTypes.number),
  country: PropTypes.string,
}

export default compose(
  withRouter,
  withStyles(style, {withTheme: true}),
  withNamespaces(),
  graphql(REFERRED_BY_QUERY, {
    props: ({data: {error, loading}, data}) => ({
      error,
      loading,
      referredBy: get(data, 'viewer.referredBy'),
    })
  }),
  graphql(LOCAL_DEPOSITOR_DETAILS, {
    props: ({data: {error, loading}, data}) => ({
      errorLocalDepositor: error,
      loadingLocalDepositor: loading,
      localDepositorSubscriptions: get(data, 'viewer.localDepositorSubscriptions'),
      localDepositorSubscriptionsCount: get(data, 'viewer.localDepositorSubscriptionsCount'),
    })
  }),
)(AccountAmountSelector)
