import React, {Component} from 'react'
import {flowRight as compose, get, isEmpty, map, toLower} from 'lodash'
import {withRouter} from 'react-router-dom'
import {withNamespaces} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import AppContext from '../../Common/contexts/AppContext'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import NotificationSlide from './NotificationSlide'
import {getCurrentTheme, isDarkTheme} from '../../../common/utils'
import NotificationButtonGroup from './NotificationButtonGroup'
import {getResponsiveObject} from './utils'
import classNames from 'classnames'
import NotificationLoadingWrapper from './NotificationLoadingWrapper'
import {graphql, withApollo} from 'react-apollo'
import {CLIENT_DATA_QUERY, NOTIFICATION_AREA_QUERY} from '../../../graphql/queries'
import PropTypes from 'prop-types'

const styles = (theme) => ({
  itemClassNextSlide: {
    [theme.direction === 'rtl' ? 'paddingLeft' : 'paddingRight']: theme.spacing(1),
    [theme.breakpoints.down('lg')]: {
      [theme.direction === 'rtl' ? 'paddingLeft' : 'paddingRight']: ({isMobileDevice}) => theme.spacing(isMobileDevice ? 0.5 : 1),
    },
  },
  itemClassHiddenSlides: {
    '&[aria-hidden=true]': {
      height: 0,
      [theme.breakpoints.down('lg')]: {
        height: ({isMobileDevice}) => isMobileDevice ? 'initial' : 0,
      },
    }
  },
  hideCarousel: {
    visibility: 'hidden',
    maxHeight: 0,
  },
})

class NotificationArea extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {
      notifications: [],
      loading: true,
      imgLoaded: false,
    }
  }

  componentDidMount() {
    const {client: apolloClient, viewer} = this.props
    apolloClient.query({
      query: NOTIFICATION_AREA_QUERY,
      variables: {
        company: get(viewer, 'company', ''),
        country: toLower(get(viewer, 'address.country', '')),
        shuffle: true,
        fallsBetweenDate: new Date().toISOString(),
        limit: 3,
      },
      fetchPolicy: 'network-only',
    })
      .then(({data: {loading}, data}) => this.setState({loading, notifications: get(data, 'notifications', [])}))
      .catch((_) => this.setState({loading: false, notifications: []}))
  }

  handleImageLoaded = () => {
    this.setState({imgLoaded: true})
  }

  render() {
    const {classes, theme, isMobileDevice} = this.props
    const {imgLoaded, notifications, loading} = this.state
    const themePreference = getCurrentTheme(this.context)
    const isDark = isDarkTheme(themePreference)
    const responsive = getResponsiveObject(theme, isMobileDevice)
    const hasManyNotifications = notifications?.length > 1
    if (isEmpty(notifications)) return null

    return (
      <>
        {(!imgLoaded || loading) && <NotificationLoadingWrapper isDark={isDark} isMobileDevice={isMobileDevice}/>}
        <Carousel
          responsive={responsive}
          customButtonGroup={<NotificationButtonGroup hide={!hasManyNotifications} hideOnDeviceTypes={['sm', 'xs']} isMobileDevice={isMobileDevice} />}
          itemClass={classNames(classes.itemClassNextSlide, hasManyNotifications && classes.itemClassHiddenSlides)}
          containerClass={(!imgLoaded || loading) && classes.hideCarousel}
          autoPlaySpeed={10000}
          showDots={false}
          arrows={false}
          draggable={false}
          rtl={theme?.direction === 'rtl'}
          partialVisible={hasManyNotifications}
          shouldResetAutoplay={hasManyNotifications}
          swipeable={hasManyNotifications}
          infinite={hasManyNotifications}
          autoPlay={hasManyNotifications}
        >
          {map(notifications, (item, i) =>
            <NotificationSlide
              item={item}
              key={i}
              isDark={isDark}
              isMobileDevice={isMobileDevice}
              handleImageLoaded={() => this.handleImageLoaded()}
            />)}
        </Carousel>
      </>
    )
  }
}

NotificationButtonGroup.propTypes = {
  isMobileDevice: PropTypes.bool.isRequired,
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  withApollo,
  withRouter,
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {loading}, data}) => ({
      loading,
      viewer: get(data, 'viewer'),
    })
  }),
)(NotificationArea)
