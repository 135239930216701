import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {flowRight as compose, get} from 'lodash'
import {frontends} from '@bdswiss/common-enums'
import {getPlatform} from '../../../../common/utils/browser'
import {withCreateDeposit, PaymentActionButton} from './helpers'
import SelfPostingForm from './SelfPostingForm'
import {getSourceBasedOnLocationPrevPath, logEventCustomParams} from '../../../../common/utils'

class GenericSelfPostingProvider extends Component {
  state = {doSubmitForm: false, formData: {}}

  doCreateDeposit() {
    const {account, providerProperties:{provider, paymentKey, id: paymentOptionId}, amount, onError, useVirtualPaymentPage, bonusAmount, bonusTerms,
      history: {location}} = this.props

    this.setState({creatingDeposit: true})

    const firebaseParams = {
      source: getSourceBasedOnLocationPrevPath(get(location, 'state', {prevPath: get(location, 'pathname')})),
      login: account.id,
      currency: account.currency,
      vendor: provider,
      amount,
    }
    logEventCustomParams('depositAttempted', firebaseParams)

    const variables = {
      accountId: account.id,
      amount,
      vendor: provider,
      paymentOptionId,
      frontend: frontends.web2.value,
      args: JSON.stringify({
        platform: getPlatform(),
        paymentKey,
        useVirtualPaymentPage,
        bonusAmount,
        bonusTerms,
      }),
    }

    this.props.createDepositRequest({variables})
      .then(({data: {newDeposit}}) => {
        const {payment} = newDeposit
        const parsedFormData = JSON.parse(payment.url)
        this.setState({formData: parsedFormData, doSubmitForm: true})
      })
      .catch((e) => {
        this.setState({creatingDeposit: false})
        onError && onError(e)
      })
  }

  render() {
    const {onSubmit, canProceed} = this.props
    const {formData, doSubmitForm, creatingDeposit} = this.state

    return (
      <React.Fragment>
        <PaymentActionButton
          disable={canProceed}
          loading={creatingDeposit}
          onClick={() => this.doCreateDeposit()}
        />
        <SelfPostingForm formData={formData}  doSubmitForm={doSubmitForm} onSubmit={onSubmit}/>
      </React.Fragment>
    )
  }
}

GenericSelfPostingProvider.propTypes = {
  createDepositRequest: PropTypes.func.isRequired,
  providerProperties: PropTypes.shape({
    name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    paymentKey: PropTypes.string.isRequired,
    paymentOptionId: PropTypes.number,
  }).isRequired,
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired
  }).isRequired,
  amount: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

export default compose(
  withCreateDeposit,
)(GenericSelfPostingProvider)
