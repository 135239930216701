import React from 'react'
import {Route, Switch, Redirect} from 'react-router-dom'
import Notifications from '../Notifications'
import Settings from '../Settings'
import Payments from '../Payments'
import LanguageSelection from '../LanguageSelection'
import Support from '../Support'
import AccountRoutes from '../Accounts/routes'
import WebtraderRoutes from '../Webtraders/routes'
import EmailVerification from '../EmailVerification'
import ForexTrader from '../ForexTrader'
import Sidebar from './sidebar/Sidebar'
import PriorityServiceLP from '../ContentPages/LP_PriorityService'
import CallBackForm from '../ContentPages/CallbackForm'
import Verification from '../Verification'
import TermsDialog from '../Common/TermsDialog'
import EmailVerificationNeeded from '../EmailVerification/EmailVerificationNeeded'
import CompareAccountTypes from '../ContentPages/CompareAccountTypes'
import TradingCompetitions from '../Accounts/TradingCompetitions'
import ReferAndEarn from '../ReferAndEarn'
import EducationalVideos from '../EducationalVideos'
import TradingVideos from '../TradingVideos'
import eBookDialog from '../Common/eBookDialog'
import TradingTools from '../Tools/'
import Autochartist from '../Tools/Autochartist'
// import TradeCompanion from '../Tools/TradeCompanion'
import TradingCentral from '../Tools/TradingCentral'
import LoyaltyProgram from '../LoyaltyProgram'
import PartnerZone from '../PartnerZone'
import Corporate from '../Corporate'
import EconomicCalendar from '../Tools/EconomicCalendar'
import Promotions from '../Promotions'
import BlackAccount from '../ContentPages/LP_BlackAccount'
import Dashboard from '../Dashboard'

const AppRoutes = (props) => (
  <Switch>
    <Route exact path={'/clientNotice'} component={TermsDialog} />
    <Route path={'/transactions'} component={Payments} />
    <Route exact path={'/notifications'} component={Notifications} />
    <Redirect exact from={'/settings'} to={'/settings/profile'} />
    <Route path={'/settings'} component={Settings} />
    <Route exact path={'/support'} component={Support} />
    <Route exact path={'/change-language'} component={LanguageSelection} />
    <Route path={'/trade'} component={WebtraderRoutes} />
    <Route path={'/dashboard'} component={Dashboard} />
    <Route path={'/accounts'} component={AccountRoutes} />
    <Route exact path={'/forexTrader'}  component={ForexTrader}/>
    <Route exact path={'/forextrader'}  component={ForexTrader}/>
    <Route exact path={'/confirm-email/:confirmEmailToken'} component={EmailVerification} />
    <Route exact path={'/more'} component={Sidebar} />
    <Route exact path={'/vip-account'} component={BlackAccount} />
    <Route exact path={'/priority'} component={PriorityServiceLP} />
    <Route exact path={'/callback'} component={CallBackForm} />
    <Route exact path={'/email-verification-needed'} component={EmailVerificationNeeded} />
    <Route exact path={'/verification'} component={Verification} />
    <Route path={'/partner-zone'} component={PartnerZone} />
    <Route exact path={'/loyalty-program'} component={LoyaltyProgram} />
    <Route exact path={'/compare-accounts'} component={CompareAccountTypes} />
    <Route exact path={'/trading-competitions'} component={TradingCompetitions} />
    <Route exact path={'/referAFriend'} component={ReferAndEarn} />
    <Route path={'/educational-videos'} component={EducationalVideos} />
    <Route exact path={'/trading-videos'} component={TradingVideos} />
    <Route exact path={'/trading-tools/economicCalendar'} component={EconomicCalendar} />
    <Route exact path={'/trading-tools/autochartist'} component={Autochartist} />
    {/*<Route path={'/trading-tools/tradeCompanion'} component={TradeCompanion} />*/}
    <Route exact path={'/trading-tools/ebook'} component={eBookDialog} to={{pathname: '/ebook'} }/>
    <Route exact path={'/trading-tools/tradingcentral'} component={TradingCentral} />
    <Route exact path={'/trading-tools'} component={TradingTools} />
    <Route path={'/corporate'} component={Corporate} />
    <Route exact path={'/promotions'} component={Promotions} />
    <Redirect exact from={'/tools'} to={'/trading-tools'} />
    <Redirect exact from={'/tools/ebook'} to={'/trading-tools/ebook'} />
    <Redirect exact from={'/tools/autochartist'} to={'/trading-tools/autochartist'} />
    {/* <Redirect exact from={'/tools/tradeCompanion'} to={'/trading-tools/tradeCompanion'} /> */}
    <Redirect exact from={'/tools/tradingcentral'} to={'/trading-tools/tradingcentral'} />
    <Redirect exact from={'/tools/economicCalendar'} to={'/trading-tools/economicCalendar'} />
    <Redirect exact from={'/profile'} to={'/settings/profile/personal-profile/details'} />
    <Redirect exact from={'/documents'} to={'settings/profile/documents'} />
    <Redirect exact from={'/appropriateness-test'} to={'settings/profile/appropriateness-test'} />
    <Redirect exact from={'/withdraw'} to={'/transactions/withdraw'} />
    <Redirect exact from={'/deposit'} to={'/transactions/deposit'} />
    <Redirect exact from={'/change-password'} to={'/settings/change-password'} />
    <Redirect exact from={'/contact'} to={'/support'} />
    <Redirect exact from={'/gdpr'} to={'/settings/privacy'} />
    <Redirect exact from={'/forex/:id/new'} to={'/trade/forex/:id'} />
    <Redirect exact from={'/dashboard/:id'} to={'/accounts/:id'} />
    <Redirect exact from={'/dashboard/:id/deposit/'} to={'/transactions/:id/deposit'} />
    <Redirect exact from={'/dashboard/:id/withdrawal/'} to={'/transactions/:id/withdraw'} />
    <Redirect exact from={'/dashboard/:id/transferfunds/'} to={'/transactions/transfer'} />
    <Redirect exact from={'/spoa'} to={'/settings/profile/spoa-authorization'} />
    <Redirect exact from={'/payments/:accountId/deposit/result/:resultstatus'} to={'/transactions/:accountId/deposit/result/:resultstatus'} />
    <Redirect exact from={'/payments/:accountId/:action/deposit/:depositId'} to={'/transactions/:accountId/:action/deposit/:depositId'} />
    <Redirect exact from={'/payments/:accountId/deposit'} to={'/transactions/:accountId/deposit'} />
    <Redirect exact from={'/payments/:accountId/transfer/transferFunds'} to={'/transactions/:accountId/transfer/transferFunds'} />
    <Redirect exact from={'/payments/transfer/transferFunds'} to={'/transactions/transfer/transferFunds'} />
    <Redirect exact from={'/payments/transfer/transferSend'} to={'/transactions/transfer/transferSend'} />
    <Redirect exact from={'/payments/transfer/transferSpoa'} to={'/transactions/transfer/transferSpoa'} />
    <Redirect exact from={'/payments/transfer/transferIb'} to={'/transactions/transfer/transferIb'} />
    <Redirect exact from={'/payments/:accountId/transfer/transferSend'} to={'/transactions/:accountId/transfer/transferSend'} />
    <Redirect exact from={'/payments/:accountId/transfer/transferSpoa'} to={'/transactions/:accountId/transfer/transferSpoa'} />
    <Redirect exact from={'/payments/:accountId/transfer/transferIb'} to={'/transactions/:accountId/transfer/transferIb'} />
    <Redirect exact from={'/payments/:accountId/transfer'} to={'/transactions/transfer'} />
    <Redirect exact from={'/payments/transfer'} to={'/transactions/transfer'} />
    <Redirect exact from={'/payments/withdraw'} to={'/transactions/withdraw'} />
    <Redirect exact from={'/payments/history'} to={'/transactions/history'} />
    <Redirect exact from={'/payments'} to={'/transactions'} />
    <Redirect exact from={'/forex/:id/custom'} to={'/'} />
    <Redirect exact from={'/black-account'} to={'/vip-account'} />
    <Route path="/:parentPath/:childPath" render={({match}) => <Redirect to={`/${match.params.parentPath}`} />} />
    <Route path={'/'} component={AccountRoutes} />
  </Switch>
)

export default AppRoutes
