import React, {Component} from 'react'
import {Trans, withNamespaces} from 'react-i18next'
import {get, flowRight as compose} from 'lodash'
import {AlertDialog} from '../Common/Dialog'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import messages from '../../assets/messages'
import AppContext from '../Common/contexts/AppContext'

export class RedeemPrizeDialog extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {
      submitMessageError: ''
    }
  }

  redeemPrizeAction() {
    const {onClose, clientId, prizeKey, partnerLoyalty:{id}} = this.props
    this.setState({status: '', loading: true})
    const variables = {
      id:id,
      action: 'redeem',
      clientId: clientId,
      prize: prizeKey
    }
    this.props.action({variables}).then((succ) => {
      this.setState({loading: false, status: '', submitMessageError: ''})
      onClose()
    })
      .catch((err) => {
        this.setState({loading: false, status: 'failure', submitMessageError: get(err, 'graphQLErrors[0].message') || err.message})
      })
  }

  render() {
    const {open, onClose, company, prize} = this.props
    const {submitMessageError, loading, status} = this.state
    return (
      <AlertDialog
        open={open}
        title={<Trans {...messages.redeemPrizeTitle} />}
        onClose={() => onClose()}
        onAgree={() => this.redeemPrizeAction()}
        disagreeText={<Trans {...messages.cancel} />}
        agreeText={<Trans {...messages.confirm} />}
        errorText={submitMessageError}
        buttonLoading={loading}
        buttonStatus={status}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Typography variant="body1">
              <Trans {...messages.redeemPrizeConfirmationText} values={{company, prize}} />
            </Typography>
          </Grid>
        </Grid>
      </AlertDialog>
    )
  }
}
export default compose(
  withNamespaces()
)(RedeemPrizeDialog)
