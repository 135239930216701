import React, {Component} from 'react'
import Grid from '@mui/material/Grid'
import {withNamespaces, Trans} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import withTheme from '@mui/styles/withTheme'
import messages from '../../../../assets/messages'
import {flowRight as compose, get, keys, some, includes, toString, isEmpty} from 'lodash'
import {CardComponent, CardNumber, CardExpiry, CardCVV} from '@chargebee/chargebee-js-react-wrapper'
import LoadingButton from '../../../Common/LoadingButton'
import {FormHelperText} from '@mui/material'
import Loading from '../../../Common/Loading'
import classNames from 'classnames'
import {getSourceBasedOnLocationPrevPath, logEventCustomParams, safeParseJSON} from '../../../../common/utils'
import config from '../../../../config'

const style = (theme) => ({
  form: {
    width: '100%',
    padding: 15,
    fontSize: 16
  },
  field: {
    margin: '34px 0',
    borderBottom: `1px solid ${theme.palette.lightgrey.color}`,
  },
  redText:{
    color:  theme.palette.error.main,
  },
  errorField:{
    marginTop: -15
  },
})

class ChargeBeeProvider extends Component {

  constructor(props) {
    super(props)
    this.state = {
      cardReady: false,
      errors: {},
      errorMessage: '',
      status: '',
      form: {
        number: false,
        expiry: false,
        cvv: false,
      },
      loading: false
    }

    window.Chargebee.init({
      site: config.chargeBee.siteKey,
      publishableKey: config.chargeBee.publicKey,
    })
    this.CardRef = React.createRef()
  }

  onChange = (status) => {
    const errors = {
      ...this.state.errors,
      [status.field]: status.error
    }
    this.setState(state => ({
      errors,
      form: {
        ...state.form,
        [status.field]: status.complete,
      },
      submitted: false,
      status: ''
    }))
  }

  componentDidUpdate(_, prevState) {
    const {onThreeDError, onThreeDSuccess} = this.props
    const {status:prevStatus} = prevState
    const {status, errorMessage} = this.state
    if (prevStatus !== status && !isEmpty(status)) {
      if (status === 'failure' && !isEmpty(errorMessage) && onThreeDError) onThreeDError(errorMessage)
      if (status === 'success' && isEmpty(errorMessage) && onThreeDSuccess) onThreeDSuccess()
    }
  }

  onSubmit() {
    const {errors, form} = this.state
    const {chargeBeeAction, t, history: {location}, account, amount, providerProperties: {provider}} = this.props
    this.setState({submitted: true})

    const firebaseParams = {
      source: getSourceBasedOnLocationPrevPath(get(location, 'state', {prevPath: get(location, 'pathname')})),
      login: account.id,
      currency: account.currency,
      vendor: provider,
      amount,
    }
    logEventCustomParams('depositAttempted', firebaseParams)

    if (some(keys(errors), (err) => errors[err]) || some(keys(form), (value) => !form[value])) return
    this.setState({loading: true, status: ''})
    this.CardRef.current.tokenize().then((tokenData) => {
      const {token} = tokenData

      chargeBeeAction('token', '').then((res) => {
        const result = toString(res)
        if (includes(result.toString(), 'GraphQL error')) {
          this.setState({status: 'failure', loading: false, errorMessage: result.toString().replace('Error: GraphQL error: ', '')})
        } else {
          const paymentIntent = safeParseJSON(
            get(res, 'data.createRawSubscription.tokenThreeD') || get(res, 'data.updateRawSubscriptionBillingInfo.tokenThreeD') ||
            get(res, 'data.createVpsSubscription.tokenThreeD') || get(res, 'data.changeVpsBillingInfo.tokenThreeD')
          )
          if (paymentIntent) {
            this.CardRef.current.authorizeWith3ds(paymentIntent).then((data) => {
              if (get(data, 'status') === 'authorized') {
                chargeBeeAction('token', token, paymentIntent.id).then((res) => {
                  if (includes(res.toString(), 'GraphQL error')) {
                    this.setState({status: 'failure', loading: false, errorMessage: res.toString().replace('Error: GraphQL error: ', '')})
                  } else {
                    this.setState({status: 'success', loading: false, errorMessage: ''})
                  }
                })
              } else {
                this.setState({status: 'failure', loading: false, errorMessage: t(messages.somethingWrongError.i18nKey)})
              }
            }).catch((e) => {
              this.setState({status: 'failure', loading: false, errorMessage: t(messages.somethingWrongError.i18nKey)})
            })
          } else {
            this.setState({status: 'success', loading: false, errorMessage: ''})
          }
        }
      }).catch((e) => this.setState({status: 'failure', loading: false, errorMessage: e.message.replace('GraphQL error: ', '')}))
    })
  }

  render() {
    const {classes, t,theme} = this.props
    const {errors, cardReady, status, form, submitted, loading, errorMessage,} = this.state
    return (
      <Grid container justifyContent="center" spacing={2} className={classes.top}>
        <form className={classes.form}>
          <CardComponent
            ref={this.CardRef}
            onReady={() => this.setState({cardReady: true})}
            styles={{base: {color: theme.palette.secondary.dark}, invalid: {color: theme.palette.error.main}}}
          >
            <CardNumber className={(cardReady) ? classes.field : ''} onChange={this.onChange} />
            {(errors.number || (submitted && !form.number)) &&
              <FormHelperText className={classNames(classes.errorField, classes.redText)}>{(errors.number) ? get(errors, 'number.message') : t(messages.requiredField.i18nKey)}</FormHelperText>}
            <CardExpiry className={(cardReady) ? classes.field : ''} onChange={this.onChange}/>
            {(errors.expiry || (submitted && !form.expiry)) &&
              <FormHelperText className={classNames(classes.errorField, classes.redText)}>{(errors.expiry) ? get(errors, 'expiry.message') : t(messages.requiredField.i18nKey)}</FormHelperText>}
            <CardCVV className={(cardReady) ? classes.field : ''} onChange={this.onChange}/>
            {(errors.cvv || (submitted && !form.cvv)) &&
              <FormHelperText className={classNames(classes.errorField, classes.redText)}>{(errors.cvv) ? get(errors, 'cvv.message') : t(messages.requiredField.i18nKey)}</FormHelperText>}
            {!cardReady && <Loading />}
          </CardComponent>
          {cardReady && <Grid item xs={12}>
            <LoadingButton
              id='loadingButton'
              size="large"
              onClick={() => this.onSubmit()}
              disabled={loading || !!status}
              status={status}
              hideProgressBar={!!status}
            ><Trans {...messages.proceedButton} />
            </LoadingButton>
            {(status==='failure') &&
              <FormHelperText className={classes.redText}>{errorMessage || t(messages.somethingWrongError.i18nKey)}</FormHelperText>}
          </Grid>}
        </form>
      </Grid>
    )
  }
}

export default compose(
  withNamespaces(),
  withStyles(style),
)(withTheme(ChargeBeeProvider))
