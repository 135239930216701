import React, {Component} from 'react'
import {graphql} from 'react-apollo'
import {withNamespaces, Trans} from 'react-i18next'
import {flowRight as compose} from 'lodash'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import {CustomDialog} from '../Common/Dialog'
import LoadingButton from '../Common/LoadingButton'
import messages from '../../assets/messages'
import {ACKNOWLEDGE_AFFILIATE_TERMS} from '../../graphql/mutations'
import {CLIENT_DATA_QUERY} from '../../graphql/queries'
import withStyles from '@mui/styles/withStyles'
import AppContext from '../Common/contexts/AppContext'
import {isMobile} from '../../common/utils'

const styles = theme => ({
  selectClass:  {
    [theme.breakpoints.down('sm')]: {
      paddingTop: 17,
    },
  },
  overflowVisible: {
    overflow: 'visible'
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
})

export class AffiliateAgreementPopup extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {
      formStatus: '',
      loading: false,
    }
  }

  handleSubmit() {
    this.setState({loading: true, formStatus: ''})
    this.props.acknowledgeAffiliateTerms({variables: {status: 'acknowledged'}})
      .then((res) => {
        this.setState({formStatus: 'success'})
      })
      .catch((err) => {
        this.setState({loading: false, formStatus: 'failure', formErrors: {onSubmit: err}})
      })
  }

  renderPopupContent() {
    const {formStatus, loading} = this.state
    const {classes, affiliateAggreementUrls} = this.props
    return (
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <Typography variant="body1"> <Trans {...messages.commissionAgreementInitial}
            components={[<a href={affiliateAggreementUrls[0]}
              target='_blank' rel='noreferrer noopener' className={classes.link}>Affiliate Agreement</a>]} /> </Typography>
          <Grid container justifyContent="flex-end">
            <LoadingButton
              fullWidth={isMobile()}
              hideProgressBar={!!formStatus}
              disabled={loading || formStatus === 'failure'}
              status={formStatus}
              onClick={() => this.handleSubmit()}
              size="medium"
              noMargin={!isMobile()}
            > <Trans {...messages.acknowledge} />
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  render() {
    return (
      <CustomDialog
        open={this.props.open}
        title={ <Trans {...messages.commissionAgreementAcceptance} />}
        customClasses={{
          dialogContent: this.props.classes.overflowVisible,
          classes: {paperScrollPaper: this.props.classes.overflowVisible}
        }}
      >
        {this.renderPopupContent()}
      </CustomDialog>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  graphql(ACKNOWLEDGE_AFFILIATE_TERMS, {
    name: 'acknowledgeAffiliateTerms',
    options: () => ({
      refetchQueries: [{query: CLIENT_DATA_QUERY}],
    }),
  }),
)(AffiliateAgreementPopup)
