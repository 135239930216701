import React, {Component} from 'react'
import {Trans, withNamespaces} from 'react-i18next'
import {get, some, flowRight as compose} from 'lodash'
import withStyles from '@mui/styles/withStyles'
import Grid from '@mui/material/Grid'
import {CLIENT_DATA_QUERY, CONFIG_QUERY} from '../../graphql/queries'
import {EMAIL_LOGIN_VERIFICATION_MUTATION, PHONE_VERIFICATION_MUTATION} from '../../graphql/mutations'
import {graphql} from 'react-apollo'
import {Loading} from '../Common/Loading'
import {FullScreenDialog} from '../Common/Dialog'
import PageTitle from '../Common/PageTitle'
import messages from '../../assets/messages'
import {Typography} from '@mui/material'
import TextField from '@mui/material/TextField'
import LoadingButton from '../Common/LoadingButton'
import FormHelperText from '@mui/material/FormHelperText'
import {withRouter} from 'react-router-dom'
import TextsmsOutlined from '@mui/icons-material/TextsmsOutlined'
import CallOutlined from '@mui/icons-material/CallOutlined'
import Button from '@mui/material/Button'
import config from '../../config'
import AppContext from '../Common/contexts/AppContext'
import {removeItem} from '../../common/utils/localStorage'
import {phoneVerificationTypes} from '@bdswiss/common-enums'
import {callerNumber} from '../../common/utils/variables'
import classNames from 'classnames'

const styles = theme => ({
  errorMessage: {
    color:  theme.palette.error.main,
    display: 'inline-block',
    verticalAlign: 'bottom',
    margin: '13px 13px 13px 0'
  },
  link: {
    color:theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight:400,
    fontSize: 16
  },
  chip: {
    padding: 10,
    cursor: 'pointer'
  },
  messageIcon: {
    marginRight: 30,
    [theme.breakpoints.down('md')]: {
      marginRight: 10,
    }
  },
  textCenter: {
    textAlign: 'center'
  },
  button: {
    marginTop: -15
  },
  infoText: {
    fontSize: '0.75rem',
    color: theme.palette.grey.color
  },
  switchSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  grayBoldText: {
    color: theme.palette.grey[600],
    fontWeight: '400',
  },
  disabledSwitch: {
    pointerEvents: 'none',
    color: theme.palette.grey[400],
  },
})

class AwaitingVerification extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {
      form: {code: ''},
      errors: {},
      status: '',
      loading: false,
      loadingVerification: false
    }
  }

  componentDidUpdate(prevProps, _) {
    const {toggleToPhone} = this.props
    const {toggleToPhone: prevToggleToPhone} = prevProps
    if (prevToggleToPhone !== toggleToPhone) {
      this.setState({
        form: {code: ''},
        errors: {},
        status: '',
        loadingVerification: false,
      })
    }
  }

  handleChange(name, value) {
    this.setState(state => ({
      form: {
        ...state.form,
        [name]: value
      },
      errors: {
        ...state.errors,
        [name]: !value,
      }
    }))
  }

  phoneVerification(verificationId, code) {
    const {phoneVerification} = this.props
    const variables = {phoneVerificationId: verificationId, code}
    phoneVerification({variables})
      .then((res) => {
        this.setState({loadingVerification: false})
        if (res['data']['verifyPhone']) {
          this.removeVerificationObjects()
          this.setState({status: 'success'})
          window.location.href = '/accounts'
        }
        else this.setState({status: 'failure'})
      })
      .catch((_) => {
        this.setState({loadingVerification: false, status: 'failure'})
      })
  }

  emailLoginVerification(code) {
    const {emailLoginVerification} = this.props
    const variables = {code}
    emailLoginVerification({variables})
      .then((res) => {
        this.setState({loadingVerification: false})
        if (res['data']['verifyEmailLogin']) {
          this.removeVerificationObjects()
          this.setState({status: 'success'})
          window.location.href = '/accounts'
        }
        else this.setState({status: 'failure'})
      })
      .catch((_) => {
        this.setState({loadingVerification: false, status: 'failure'})
      })
  }

  handleSubmit() {
    const {form:{code}} = this.state
    const {verificationId, toggleToPhone} = this.props
    const errors = this.validateCode(code)

    if (some(errors)) return

    this.setState({loadingVerification: true})
    return toggleToPhone ? this.phoneVerification(verificationId, code) : this.emailLoginVerification(code)
  }

  validateCode(code) {
    const {toggleToPhone} = this.props
    const errors = {}
    errors['code'] = toggleToPhone ? !RegExp(/^[0-9]{4}$/).test(code) : !RegExp(/^[0-9]{6}$/).test(code)
    this.setState({errors})
    return errors
  }

  closeDialog() {
    this.context.logout()
  }

  removeVerificationObjects() {
    const {verificationObjectTypes, viewer:{id}} = this.props
    for (const key of Object.keys(verificationObjectTypes))
      removeItem(`verificationObject${verificationObjectTypes[key]}${id}`)
  }

  setPhoneState() {
    const {handleChangeParent, showPhone} = this.props
    handleChangeParent('showPhone', !showPhone)
  }

  toggleVerificationMode() {
    const {handleChangeParent, toggleToPhone, verificationSwitchButtonDisabled} = this.props
    if (verificationSwitchButtonDisabled) return
    handleChangeParent('toggleToPhone', !toggleToPhone)
  }

  handleOnChangeCode(e) {
    this.handleChange((e.target.id || 'code'), e.target.value)
    this.validateCode(e.target.value)
  }

  render() {
    const {classes, loading, t, secondsToTime, checkTime, disableSend, verificationStatus, submitTime, verificationError,
      configVariables, toggleToPhone, verificationSwitchButtonDisabled} = this.props
    const {weblinks: {contact}} = config
    const {locale} = this.context
    const textToSpeechEnabled = get(configVariables, 'textToSpeechVerificationEnabled')
    if (loading) return <Loading />
    const {form: {code}, status, loadingVerification, errors} = this.state

    return (
      <FullScreenDialog
        desktopOnly
        open
        onClose={() => this.closeDialog()}
      >
        <Grid container spacing={3} className={classes.textCenter} alignItems="center" justifyContent="center">
          <PageTitle onBack={() => this.closeDialog()}>
            <Trans {...messages.awaitingVerification} />
          </PageTitle>
          <Grid container item xs={12} spacing={3} alignItems="center" justifyContent="center" className={classes.textCenter}>
            <Grid item xs={12}>
              <Typography variant="body1">
                {toggleToPhone ? <Trans {...messages.smsCode} /> : <Trans {...messages.emailCode} />}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9} md={5}>
              <TextField
                variant="standard"
                id="code"
                name="code"
                label={toggleToPhone
                  ? t(messages.enterCode.i18nKey, messages.enterCode.defaults)
                  : t(messages.enterEmailCode.i18nKey, messages.enterEmailCode.defaults)}
                fullWidth
                autoComplete="code"
                error={errors.code}
                value={code}
                InputLabelProps={{classes: {root: classes.textField}}}
                onChange={(e) => this.handleOnChangeCode(e)} />
            </Grid>
            {(verificationStatus !== 'failure') && <Grid item xs={12} sm={12} className={classes.button}>
              <LoadingButton
                id='loadingButton'
                onClick={() => this.handleSubmit()}
                disabled={loadingVerification}
                status={status}
              ><Trans {...messages.proceedSecure} />
              </LoadingButton>
              {(status === 'failure') && <Grid item xs={12}>
                <FormHelperText className={classes.errorMessage}>{t(messages.wrongCode.i18nKey, messages.wrongCode.defaults)}</FormHelperText>
              </Grid>}
            </Grid>}
            {(verificationStatus === 'failure') && messages[verificationError] && <Grid item xs={12} sm={12}>
              <FormHelperText className={classes.errorMessage}>
                {t(messages[verificationError].i18nKey, messages[verificationError].defaults)}
              </FormHelperText>
            </Grid>}
            <Grid className={classes.switchSection} item xs={12}>
              <span>
                <Typography variant="body1" className={classes.grayBoldText}>
                  {toggleToPhone ? <Trans {...messages.couldNotReceiveSecurityCode} /> : <Trans {...messages.couldNotReceiveEmailSecurityCode} />}
                </Typography>
              </span>
              <div>
                <span className={classNames(classes.link, verificationSwitchButtonDisabled ? classes.disabledSwitch : classes.link)}
                  onClick={() => this.toggleVerificationMode()}>
                  {!toggleToPhone ? <Trans {...messages.phoneVerificationSwitch} /> : <Trans {...messages.emailVerificationSwitch} />}
                </span>
              </div>
              {toggleToPhone && <div>
                <span className={classes.link} onClick={() => this.setPhoneState()}>
                  <Trans {...messages.changePhoneNumber} />
                </span>
              </div>}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={() => checkTime({...(toggleToPhone && {type: phoneVerificationTypes.sms.key})}, true)}
              className={classes.chip}
              disabled={disableSend}
              color="secondary"
              variant="contained"
            >
              <TextsmsOutlined className={classes.messageIcon}/>
              {!toggleToPhone
                ? t(messages.resendEmail.i18nKey, messages.resendEmail.defaults)
                : t(messages.resendSms.i18nKey, messages.resendSms.defaults)}{disableSend && `: ${secondsToTime(submitTime)}`}
            </Button>
          </Grid>
          {textToSpeechEnabled && <Grid item xs={12}>
            <Button
              onClick={() => checkTime({...(toggleToPhone && {type: phoneVerificationTypes.textToSpeech.key})}, true)}
              className={classes.chip}
              disabled={disableSend}
              color="secondary"
              variant="contained"
            >
              <CallOutlined className={classes.messageIcon}/>
              {t(messages.call.i18nKey, messages.call.defaults)}{disableSend && `: ${secondsToTime(submitTime)}`}
            </Button>
            <Grid item xs={12}>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={5} className={classes.infoText}>
                  <Trans {...messages.infoCallText} values={{callerNumber}} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>}
          <Grid item xs={12}>
            <Grid container direction="column" justifyContent="center">
              <Typography variant="body1" className={classes.grayBoldText}>
                <Trans {...messages.refreshPageText} />
              </Typography>
              <a rel="noopener noreferrer" target="_blank" href={contact.replace('{lang}', locale)}>
                <Typography variant="body1" className={classes.link}>
                  <Trans {...messages.problemPersistContactText} />
                </Typography>
              </a>
            </Grid>
          </Grid>

        </Grid>
      </FullScreenDialog>
    )
  }
}

export default compose (
  withStyles(styles),
  withNamespaces(),
  withRouter,
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading}, data}) => {
      const viewer = get(data, 'viewer')
      return {
        error,
        loading,
        viewer:viewer,
      }
    }
  }),
  graphql(PHONE_VERIFICATION_MUTATION, {
    name: 'phoneVerification',
    update: cache => {
      cache.writeData({data: {props: []}})
    },
  }),
  graphql(EMAIL_LOGIN_VERIFICATION_MUTATION, {
    name: 'emailLoginVerification',
    update: cache => {
      cache.writeData({data: {props: []}})
    },
  }),
  graphql(CONFIG_QUERY, {
    props: ({data: {error, loading: configLoading}, data}) => ({
      error,
      configLoading,
      configVariables: get(data, 'config'),
    })
  }),
)(AwaitingVerification)
