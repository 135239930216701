import React from 'react'
import {withRouter} from 'react-router-dom'
import {withNamespaces} from 'react-i18next'
import {graphql, withApollo} from 'react-apollo'
import {SOCIALMEDIALINKS_QUERY} from '../../graphql/queries'
import {flowRight as compose, first, get, isEmpty, map, omitBy} from 'lodash'
import AppContext from './contexts/AppContext'
import {List, ListItem} from '@mui/material'
import {withStyles} from '@mui/styles'
import {getCurrentTheme} from '../../common/utils'
import {socialMediaIcons} from '../../common/utils/uioptions'

const styles = theme => ({
  socialMediaList:{
    display:'flex',
    flexDirection:'row',
    gap:'20px',
    padding: 0
  },
  listIteam:{
    height:'24px',
    width:'24px',
    padding: 0,
    cursor: 'pointer',
  },
  iconRoot: {
    width: '24px',
    '&:hover': {
      fill: theme.palette.black.color,
    }
  }
})
class SocialMediaSection extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {
      data: null
    }
  }
  render() {
    const {links, classes}= this.props
    const themePreference = getCurrentTheme(this.context)
    const sortedLinks= omitBy(links, value => isEmpty(value) || value === 'socialMediaType')

    return (
      <List className={classes.socialMediaList}>
        {map(sortedLinks, (url, platform) => socialMediaIcons[platform] && socialMediaIcons[platform].svgIcon &&socialMediaIcons[platform].svgIcon[themePreference] && <ListItem className={classes.listIteam} key={`social-${platform}`} onClick={() => window.open(url)}>
          {socialMediaIcons[platform]?.svgIcon[themePreference](classes.iconRoot)}
        </ListItem>)
        }
      </List>
    )
  }
}

export default compose(
  withRouter,
  withNamespaces(),
  withApollo,
  withStyles(styles, {withTheme: true}),
  graphql(SOCIALMEDIALINKS_QUERY,{
    options: () => ({
      fetchPolicy: 'network-only',
    }),
    props: ({data: {loading, error}, data}) =>
      ({
        loadingSocialMedia: loading,
        errorSocialMedia: error,
        links: first(get(data, 'socialMediaLinks'))
      }),
  }),
)(SocialMediaSection)
