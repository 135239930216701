import React from 'react'

import {styled} from '@mui/system'
import {Divider, Typography} from '@mui/material'


const DividerWrap = styled('div')({
  position: 'relative',
})

const DividerLine = styled(Divider)(({theme}) => ({
  borderColor: theme.palette.mode === 'dark' ? '#FFFFFF1A' : '#EBEBEB',
  margin: '30px 0 10px',
  [theme.breakpoints.down('md')]: {
    margin: '4px 0',
  },
}))

const DividerText = styled(Typography)(({theme}) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translateX(-50%) translateY(-50%)',
  zIndex: 1,
  padding: '0 15px',
  fontSize: 14,
  lineHeight: 1,
  backgroundColor: theme.palette.mode === 'dark' ? '#1F1F1F' : '#FFFFFF',
}))

const ModalDivider = () => (
  <DividerWrap>
    <DividerLine />
    <DividerText>or</DividerText>
  </DividerWrap>
)

export default ModalDivider
