import React from 'react'

/*
credit: https://medium.com/javascript-inside/render-props-render-callbacks-and-higher-order-components-are-all-interchangeable-1f01f56437fa
*/
export const toHoc = Render => Comp => props => (
  <Render {...props} render={props => <Comp {...props} />} />
)

export const fromHoc = hoc => hoc(props => props.render(props))
