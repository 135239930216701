import React, {Component} from 'react'
import {flowRight as compose, isNil, get} from 'lodash'
import Grid from '@mui/material/Grid'
import withStyles from '@mui/styles/withStyles'
import {Trans, withNamespaces} from 'react-i18next'
import Typography from '@mui/material/Typography'
import AppContext from '../Common/contexts/AppContext'
import messages from '../../assets/messages'
import config from '../../config'
import {Link} from 'react-router-dom'
import {hasBinaryProductAccount} from '../../common/utils/accounts'

const styles = theme => ({
  footerMain: {
    fontWeight: 400,
    marginTop: (props) => props.noMarginTop ? 0 : 40,
    letterSpacing: '0.25px',
    [theme.breakpoints.down('sm')]: {
      marginTop: 16,
    }
  },
  complaints: {
    color: theme.palette.secondary.main,
    fontWeight: 400,
    textDecoration: 'underline',
    fontStyle: 'normal',
    fontSize: 14,
    cursor: 'pointer',
    letterSpacing: '0.4px',
    lineHeight: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: '0.25px',
    }
  },
  icon: {
    height: 15
  },
  showGBFooter : {
    paddingTop: 10
  },
  bold: {
    fontWeight: 400,
    fontSize: 14,
  },
  footerText: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '20px',
    letterSpacing: '0.25px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: '0.25px',
      fontStyle: 'normal',
      fontWeight: 300,
    },
  }
})

class Footer extends Component {
  static contextType = AppContext

  render () {
    const {classes, viewer} = this.props
    const {weblinks: {legalDocuments, termsAndConditions, privacyPolicy, binaryDocuments}} = config
    const {locale, companyObject, accounts} = this.context
    const checkBinary = hasBinaryProductAccount(accounts)
    const bonusTerms = get(viewer, 'bonusTerms')

    return (
      <Grid container className={classes.footerMain}>
        <Grid item xs={12}>
          <Typography variant="caption" className={classes.footerText}>
            {(!checkBinary || isNil(binaryDocuments)) && !bonusTerms && <Trans {...messages.footer}
              values={{company: companyObject.trademark}}
              components={[
                <span className={classes.bold} key="1">company</span>,
                <span className={classes.bold} key="2">company</span>,
                <a key="3" href={legalDocuments.replace('{lang}', locale)} className={classes.complaints} target="_blank" rel="noopener noreferrer">legal documents</a>,
                <a key="4" href={termsAndConditions.replace('{lang}', locale)} className={classes.complaints} target="_blank" rel="noopener noreferrer">terms and conditions</a>,
                <a key="5" href={privacyPolicy.replace('{lang}', locale)} className={classes.complaints} target="_blank" rel="noopener noreferrer">privacy policy</a>,
              ]}
            />}
            {(checkBinary && !isNil(binaryDocuments)) && !bonusTerms && <Trans {...messages.footerBinary}
              values={{company: companyObject.trademark}}
              components={[
                <span className={classes.bold} key="1">company</span>,
                <span className={classes.bold} key="2">company</span>,
                <a key="3" href={legalDocuments.replace('{lang}', locale)} className={classes.complaints} target="_blank" rel="noopener noreferrer">legal documents</a>,
                <a key="4" href={termsAndConditions.replace('{lang}', locale)} className={classes.complaints} target="_blank" rel="noopener noreferrer">terms and conditions</a>,
                <a key="5" href={binaryDocuments} className={classes.complaints} target="_blank" rel="noopener noreferrer">binary documents</a>,
                <a key="6" href={privacyPolicy.replace('{lang}', locale)} className={classes.complaints} target="_blank" rel="noopener noreferrer">privacy policy</a>,
              ]}
            />}
            {bonusTerms && <Trans {...messages.footerBonus}
              values={{company: companyObject.trademark}}
              components={[
                <strong key="1">company</strong>,
                <strong key="2">company</strong>,
                <a key="3" href={legalDocuments.replace('{lang}', locale)} className={classes.complaints} target="_blank" rel="noopener noreferrer">legal documents</a>,
                <a key="4" href={termsAndConditions.replace('{lang}', locale)} className={classes.complaints} target="_blank" rel="noopener noreferrer">terms and conditions</a>,
                <a key="5" href={binaryDocuments} className={classes.complaints} target="_blank" rel="noopener noreferrer">binary documents</a>,
                <a key="6" href={bonusTerms} className={classes.complaints} target="_blank" rel="noopener noreferrer">bonus terms</a>,
              ]}
            />}
            <Trans {...messages.sendComplaint}
              components={[
                <Link key="6" to={'/support?complaint'} className={classes.complaints}> </Link>
              ]}
            />
          </Typography>
        </Grid>
      </Grid>
    )
  }
}

export default compose(
  withStyles( styles, {withTheme: true}),
  withNamespaces(),
)(Footer)
