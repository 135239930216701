import React, {Component} from 'react'
import {graphql} from 'react-apollo'
import {withNamespaces, Trans} from 'react-i18next'
import {Loading} from '../../../Common/Loading'
import classNames from 'classnames'
import {kycStatuses, suitabilityTestSteps, suitabilityTestQuestions, currencies, accountTypes, companies} from '@bdswiss/common-enums'
import {find, mapValues, omit, isEmpty, get, keys, filter, pickBy, concat, omitBy,
  includes, pull, last, identity, map, orderBy, reject, size, capitalize, flowRight as compose} from 'lodash'
import Grid from '@mui/material/Grid'
import PageTitle from '../../../Common/PageTitle'
import messages from '../../../../assets/messages'
import {PROFILE_SETTINGS_QUERY, CLIENT_DATA_QUERY} from '../../../../graphql/queries'
import AppContext from '../../../Common/contexts/AppContext'
import {scrollElementIntoView, getMissingDocs, setCookie, isMobile} from '../../../../common/utils'
import withStyles from '@mui/styles/withStyles'
import {withRouter} from 'react-router-dom'
import {SUITABILITY_TEST_MUTATION} from '../../../../graphql/mutations'
import {PortfolioManagementHeader} from './PortfolioManagementHeader'
import PortfolioManagementQuestions from './PortfolioManagementQuestions'
import PortfolioManagementButtonsToolbar from './PortfolioManagementButtonsToolbar'
import {PortfolioManagementPopupMessage} from './PortfolioManagementPopupMessage'
import Images from '../../../Common/Images'
import {Card, Avatar, Typography} from '@mui/material'
import NotificationBar from '../../../Common/NotificationBar'

const gridScroll = 'scroll-grid'

const styles = theme => ({
  orangeButton: {
    backgroundColor: '#ef9756',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#ef7e2b',
    }
  },
  cardRoot: {
    padding: 20,
    margin: '20px 0px',
    cursor: 'pointer',
    position: 'relative',
  },
  currIcon:{
    marginRight: '18px'
  },
  label:{
    padding: '6px 0px'
  },
  badgeDiv:{
    paddingTop: 4
  },
  typeBadge: {
    height: 40,
    width: 40,
    fontSize: 15,
    lineHeight: '40px',
    borderRadius: 20,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  blueAvatar: {
    marginRight: '18px',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.lightblue.color,
    [theme.breakpoints.down('sm')]: {
      margin: '0 10px 0 0',
      height: 35,
      width: 35,
      lineHeight: 37,
    }
  },
  highlight:{
    fontWeight:400
  }
})

class PortfolioManagement extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    this.state = {
      activeStep:  0,
      progressStep: 0,
      progress: 0,
      form: {answers: this.initSuitabilityTestForm(), questions:{}},
      formErrors : this.initSuitabilityTestForm(),
      formState: 'pending',
      showMessage: false,
      currency: '',
      subTypeSelection:'',
    }
  }
  componentDidMount() {
    const {viewer, history} = this.props
    const {companyObject} = this.context

    if (!get(viewer, 'hasPortfolioManagement'))
      history.push('/settings/profile')

    const getSubTypes = filter(accountTypes, (p)=> p.subCategory === 'fundManagement' && (get(companyObject, 'value') === p.company))
    const checkSuitabilityTestSteps = filter(suitabilityTestSteps, (s) =>
      !s.skip(!isEmpty(get(viewer, 'suitabilityTests')) ||
    ((s.key === suitabilityTestSteps.accountSelection.key) && getSubTypes.length <= 1)))
    this.setState({
      progress: checkSuitabilityTestSteps && (1/size(checkSuitabilityTestSteps)) * 100,
      progressStep: checkSuitabilityTestSteps && (1/size(checkSuitabilityTestSteps)) * 100})
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {form, formErrors} = prevState
    const hasSuitabilityTest = !isEmpty(nextProps.suitabilityTest) && {answers: nextProps.suitabilityTest.answers}
    const answerKeys = keys(omitBy(hasSuitabilityTest.answers, isEmpty))
    prevState.form = hasSuitabilityTest || form
    prevState.formErrors = (hasSuitabilityTest && omit(formErrors, answerKeys)) || formErrors
    return prevState
  }

  initSuitabilityTestForm() {
    return mapValues(suitabilityTestQuestions, (q) => '')
  }

  getPortfolioManagementQuestions(answers, activeStep) {
    const {companyObject} = this.context
    const {suitabilityTest} = this.props
    return pickBy(suitabilityTestQuestions, (q) => {
      const {step: {stepPosition}} = q
      const getSubTypes = filter(accountTypes, (p)=> p.subCategory === 'fundManagement' && (get(companyObject, 'value') === p.company))
      const firstStep = getSubTypes.length <= 1
      const disableSubmit = !isEmpty(suitabilityTest)
      const checkCompanies = filter(q.company, (o) => o.value === companyObject.key)
      return (stepPosition(disableSubmit, firstStep) === activeStep && !isEmpty(checkCompanies) &&  q.relevant(answers,'key'))
    })
  }


  handleNextStep() {
    this.scrollUp()
    const {hasEmptyQuestion} = this.validateStep(this.state.activeStep)
    if (isEmpty(hasEmptyQuestion)) {
      this.setState({activeStep: this.state.activeStep + 1, progress: this.state.progress + this.state.progressStep})
    } else {
      this.setState({formState: 'validation'},this.scrollUp(this.state.formErrors))
    }
  }

  handlePreviousStep() {
    this.setState({activeStep: this.state.activeStep - 1, progress: this.state.progress - this.state.progressStep})
  }

  handleClose(history, state) {
    return (state && state.force ) ? this.context.logout() : history.push('/settings/profile')
  }

  handleOptionInput(event, key) {
    const {form, formErrors} = this.state
    const questionExists = get(form.answers, key)
    if (event.type === 'radio') {
      const {name: questionKey, value: answerKey} = event
      const {form, formErrors} = this.state
      const questionExists = find(form, ['answers', questionKey])
      if (questionExists) {
        form.answers[questionKey] = answerKey
        this.setState({form: form})
      } else {
        form.answers[questionKey] = answerKey
        const newFormErrors = omit(formErrors, [questionKey])
        this.setState({form: form, formErrors: newFormErrors})
      }
    } else if (event.type === 'checkbox') {
      if (questionExists) {
        const answerExist = includes(questionExists, event.value)
        if (!answerExist &&  event.checked) {
          form.answers[key].push(event.value)
        } else if (answerExist && !event.checked) {
          form.answers[key] = pull(form.answers[key],event.value)
        }
        this.setState({form: form})
      } else {
        form.answers[key] = [event.value]
        const newFormErrors = omit(formErrors, [key])
        this.setState({form: form, formErrors: newFormErrors})
      }
    } else {
      form.answers[key] = event
      const newFormErrors = omit(formErrors, [key])
      this.setState({form: form, formErrors: newFormErrors})
    }
  }

  validateStep(activeStep) {
    const {form, formErrors} = this.state
    const activeStepObject = find(suitabilityTestSteps, ['stepPosition', activeStep])
    const activeStepQuestions = filter(suitabilityTestQuestions, ['step', activeStepObject])
    const activeQuestions =  this.getPortfolioManagementQuestions(form.answers, activeStep)
    let newFormErrors=[]
    const hasEmptyQuestion = filter(activeQuestions, (question) => {
      const mandatoryField = suitabilityTestQuestions[question.key].mandatory(form.answers, 'key')
      if (isEmpty(form.answers[question.key]) && mandatoryField) {
        formErrors[question.key] = ''
        return question
      } else {
        newFormErrors = concat(newFormErrors,question.key)
      }
    })

    filter(activeStepQuestions, (question) => {
      if (!activeQuestions[question.key]) {
        newFormErrors = concat(newFormErrors,question.key)
        form.answers = omit(form.answers, question.key)
      }
    })
    const updatedFormErrors = omit(formErrors, newFormErrors)
    this.setState({form: form, formErrors: updatedFormErrors})
    return {form, hasEmptyQuestion, updatedFormErrors}
  }

  validateForm() {
    this.setState({formState: 'validation'})
    const {hasEmptyQuestion, updatedFormErrors} = this.validateStep(this.state.activeStep)
    if (isEmpty(hasEmptyQuestion) && isEmpty(updatedFormErrors)) {
      return true
    } else {
      this.scrollUp(this.state.formErrors)
      return false
    }
  }

  scrollUp(errors) {
    const scrollTo = errors ? Object.keys(errors)[0] : gridScroll
    this.setState(() => scrollElementIntoView(scrollTo, 250))
  }

  handleMessageClose() {
    this.setState({showMessage: false})
    this.redirectPage()
  }

  chooseCurrency(props) {
    this.setState({currency:props})
    this.handleNextStep()
  }

  subTypeSelection(props) {
    this.setState({subTypeSelection: props})
    this.handleNextStep()
  }

  redirectPage() {
    const {viewer} = this.props
    if (viewer.kycStatus === kycStatuses.approved.value || isEmpty(reject(getMissingDocs(viewer, viewer.documents), (doc) => doc === false))) {
      return this.props.history.push('/accounts')
    } else {
      return this.props.history.push('/settings/profile/documents')
    }
  }

  submitSuitabilityTest() {
    const {currency, subTypeSelection} = this.state
    let answers = this.state.form.answers
    this.setState({formState: 'submit',status: '', submitLoading: true})
    if (answers.__typename) {
      answers = omit(this.state.form.answers, ['__typename'])
    }


    this.props.updateOwnSuitabilityTest({variables: {answers: pickBy(answers, identity), currency, subTypeSelection}})
      .then((res) => {
        this.context.showNotification({
          type: 'document-upload',
          status: 'success',
          content: this.props.t(messages.suitabilityTestSubmitted.i18nKey, messages.suitabilityTestSubmitted.defaults),
          buttonMessage: this.props.t(messages.continue.i18nKey, messages.continue.defaults),
          onClose: () => res.data.createSuitabilityTest.scoreResult &&
            this.setState({
              submitLoading: false,
              showMessage: true,
              score:res.data.createSuitabilityTest.portfolioPoints,
              scoreResult:res.data.createSuitabilityTest.scoreResult})
        })
        setCookie('suitability_test_completed', true, 1)
      })
      .catch((err) => {
        this.setState({
          submitLoading: false, status: 'failure',
        })
        this.context.showNotification({
          type: 'document-upload',
          status: 'failure',
          content: get(err, 'graphQLErrors[0].message') || err.message,
          buttonMessage: <Trans {...messages.cancel} />
        })
      })
  }

  submitForm() {
    if (this.validateForm()) {
      this.submitSuitabilityTest()
    }
  }

  getScoreMessage(scoreResult) {
    const {classes, t} = this.props
    const {companyObject} = this.context
    let message
    const msgRegulator = capitalize(companies[companyObject.key].regulator)
    if (scoreResult === 'high') {
      message =  <Trans {...messages[`suitabilityTestMessageHigh${msgRegulator}`]}
        values={{company: companyObject.brandLabel, scoreResult: scoreResult, scoreResultStrategy: t(messages[`${scoreResult}RiskStrategy`].i18nKey)}}
        components={[<span className={classes.highlight}>scoreResultStrategy</span>]} />
    } else {
      message =  <Trans {...messages[`suitabilityTestMessageLowMedium${msgRegulator}`]}
        values={{company: companyObject.brandLabel, scoreResult: scoreResult, scoreResultStrategy: t(messages[`${scoreResult}RiskStrategy`].i18nKey)}}
        components={[<span className={classes.highlight}>scoreResultStrategy</span>]} />
    }
    return message
  }

  getCurrenciesList() {
    let currenciesList = []
    currenciesList = filter(currencies, (p)=> p.portfolioManagement)
    currenciesList = map(currenciesList, (o) => ({
      ...o,
      label: `${o.label} ( ${o.symbol} )`,
      icon: Images[`${o.key.toLowerCase()}.png`],
    }))
    return currenciesList
  }

  checkIfHasAlreadySubmitted() {
    return <NotificationBar status="info" title={<Trans {...messages.suitabilityTestAlreadySubmitted} />} />
  }

  render() {
    const {t, loading, suitabilityTest, history, classes} = this.props
    const {locale, companyObject} = this.context
    const {submitLoading, status}= this.state

    if (loading || !suitabilityTest) return <Loading />

    const {activeStep, form, formState, formErrors, progress, scoreResult, showMessage} = this.state
    const portfolioManagementQuestions = this.getPortfolioManagementQuestions(form.answers, activeStep)
    const allowedCurrencyList = this.getCurrenciesList()
    const getSubTypes = filter(accountTypes, (p)=> p.subCategory === 'fundManagement' && (get(companyObject, 'value') === p.company))
    const disableSubmit = !isEmpty(suitabilityTest)
    const checkSuitabilityTestSteps = filter(suitabilityTestSteps, (s) => !s.skip(!isEmpty(suitabilityTest) || ((s.key === suitabilityTestSteps.accountSelection.key) && getSubTypes.length <= 1)))
    const portfolioManagementComponent =
      <Grid container id={gridScroll}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <PageTitle onBack={() => history.push('/settings/profile')}>
              {t(messages.suitabilityAssessment.i18nKey, messages.suitabilityAssessment.defaults)}
            </PageTitle>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <PortfolioManagementHeader
              t={t}
              activeStep={activeStep}
              progress={progress}
              disableSubmit={disableSubmit}
            />
          </Grid>
          {disableSubmit && <Grid container spacing={0}>
            <Grid item xs={12}>
              {this.checkIfHasAlreadySubmitted()}
            </Grid>
          </Grid>}
        </Grid>
        {find(checkSuitabilityTestSteps, (s) => s.key === suitabilityTestSteps.accountSelection.key) && activeStep === 0  && <Grid container spacing={3}>
          <Grid item xs={isMobile() ? 12 : 6}>
            {map(getSubTypes, (option) => (<Grid item xs={12} key={option.key}>
              <Card classes={{root: classes.cardRoot}} key={option.key} onClick={()=> this.subTypeSelection(option.key)}>
                <Grid container>
                  <Avatar className={classes.blueAvatar}>
                    <div className={classNames(classes.typeBadge)}>{option.shortLabel}</div>
                  </Avatar>
                  <Grid item>
                    <Typography variant='subtitle1' className={classes.label}>{option.label}</Typography>
                  </Grid>
                </Grid>
              </Card>
            </Grid>))}
          </Grid>
        </Grid>}
        {(find(checkSuitabilityTestSteps, (s) => s.key === suitabilityTestSteps.currency.key) && ((activeStep === 1 && getSubTypes.length > 1)  || (getSubTypes.length <= 1 && activeStep === 0))) && <Grid container spacing={3}>
          <Grid item xs={isMobile() ? 12 : 6}>
            {allowedCurrencyList.map( (currency) =>
              <Card classes={{root: classes.cardRoot}} key={currency.key} onClick={()=> this.chooseCurrency(currency.key)}>
                <Grid container>
                  <Avatar alt={currency['icon']} src={currency['icon']} className={classes.currIcon}/>
                  <Grid item>
                    <Typography variant='subtitle1' className={classes.label}>{currency['label']}</Typography>
                  </Grid>
                </Grid>
              </Card>
            )}
          </Grid>
        </Grid>}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <PortfolioManagementQuestions
              locale={locale}
              activeStep={activeStep}
              form={form}
              formState={formState}
              errors={formErrors}
              questions={portfolioManagementQuestions}
              disableSubmit={disableSubmit}
              onChange={(e, questionKey) => this.handleOptionInput(e, questionKey)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <PortfolioManagementButtonsToolbar
              activeStep={activeStep}
              nextStep={() => this.handleNextStep()}
              previousStep={() => this.handlePreviousStep()}
              submitForm={() => this.submitForm()}
              submitButtonFeatures ={{submitLoading , status}}
              disableSubmit={disableSubmit}
              totalSteps={size(checkSuitabilityTestSteps)}
            />
          </Grid>
        </Grid>
      </Grid>

    return (
      <React.Fragment>
        {showMessage && <PortfolioManagementPopupMessage
          open={showMessage}
          title={<Trans {...messages.warning} />}
          agreeText={<Trans {...messages.continue} />}
          children={scoreResult && this.getScoreMessage(scoreResult)}
          onAgree={() => this.handleMessageClose()}
        />}
        {portfolioManagementComponent}
      </React.Fragment>
    )
  }
}

export default compose(
  withNamespaces(),
  withRouter,
  withStyles(styles, {withTheme: true}),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading}, data}) => {
      const clientId = get(data, 'viewer.id')
      const viewer = get(data, 'viewer')
      return {
        error,
        loading,
        clientId,
        viewer,
      }
    }
  }),
  graphql(PROFILE_SETTINGS_QUERY, {
    props: ({data: {error, loading}, data}) => {
      const suitabilityTests = get(data, 'viewer.suitabilityTests')
      let suitabilityTest = last(orderBy(filter(suitabilityTests, ['status', 'approved']), ['createdAt']))
      if (!suitabilityTest) {
        suitabilityTest = last(orderBy(suitabilityTests, ['createdAt']))
      }
      return {
        error,
        loading,
        suitabilityTest: suitabilityTest || {},
      }
    }
  }),
  graphql(SUITABILITY_TEST_MUTATION, {
    name: 'updateOwnSuitabilityTest',
    options: {
      refetchQueries: [{query: PROFILE_SETTINGS_QUERY}],
    },
    update: cache => {
      cache.writeData({data: {props: []}})
    },
  }),
)(PortfolioManagement)
