import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {flowRight as compose, get, filter, isEmpty, head, map, pickBy} from 'lodash'
import {Trans} from 'react-i18next'
import {countries, exactlyPaymentTypes, frontends, fundingCategories} from '@bdswiss/common-enums'
import withStyles from '@mui/styles/withStyles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import {withCreateDeposit, PaymentActionButton, withCreateDepositPCT} from './helpers'
import PageSubTitle from '../../../Common/PageSubTitle'
import CardToken from '../../../Common/CardToken'
import messages from '../../../../assets/messages'
import {getPlatform, isMobile} from '../../../../common/utils/browser'
import {getSourceBasedOnLocationPrevPath, logEventCustomParams} from '../../../../common/utils'

const style = (theme) => ({
  address: {
    fontWeight: 400,
    margin: '10px 0 0 0',
  },
  addCard: {
    cursor: 'pointer',
  },
})
class ExactlyProvider extends Component {
  constructor(props) {
    super(props)

    const initialState = {doSubmitForm: false, formData: {}, gettingUrl: false, paymentMethod: ''}
    const cardTokens = get(props, 'viewer.paymentCardTokens', [])
    const methodCardTokens = filter(cardTokens, (t) => t.vendor === props.providerProperties.provider)
    if (!isEmpty(methodCardTokens)) {
      initialState.selectedCardTokenId = head(methodCardTokens).id
    }
    this.state = initialState
  }

  getUrl() {
    const {providerProperties: {provider, paymentKey}, account, amount, onError, useVirtualPaymentPage, bonusAmount,
      createDepositRequest, createDepositRequestPCT, history: {location}} = this.props
    const {selectedCardTokenId, paymentMethod} = this.state

    this.setState({gettingUrl: true})

    const firebaseParams = {
      source: getSourceBasedOnLocationPrevPath(get(location, 'state', {prevPath: get(location, 'pathname')})),
      login: account.id,
      currency: account.currency,
      vendor: provider,
      amount,
    }
    logEventCustomParams('depositAttempted', firebaseParams)

    const variables = {
      accountId: account.id,
      amount,
      vendor: provider,
      paymentCardTokenId: selectedCardTokenId,
      frontend: frontends.web2.value,
      args: JSON.stringify({
        platform: getPlatform(),
        paymentKey,
        useVirtualPaymentPage,
        bonusAmount,
        paymentMethod,
      }),
    }

    const depositRequest = selectedCardTokenId ? createDepositRequestPCT : createDepositRequest

    depositRequest({variables})
      .then(({data: {newDeposit}}) => {
        const {payment: {url}} = newDeposit
        window.location = url
      })
      .catch((e) => {
        this.setState({gettingUrl: false})
        onError && onError(e)
      })
  }

  handlePaymentMethodChange = ({target: {value}}) => this.setState({paymentMethod: value})

  render() {
    const {providerProperties: {provider}, viewer: {paymentCardTokens, address: {country}}, classes, canProceed} = this.props
    const {gettingUrl, selectedCardTokenId, paymentMethod} = this.state
    const methodCardTokens = pickBy(paymentCardTokens, (t) => t.vendor === provider)
    const thaiPaymentTypes = filter(exactlyPaymentTypes, {fundingCategory: fundingCategories.onlineBanking.value})

    return (
      <React.Fragment>
        {country === countries.th.key &&
        <FormControl variant="standard" fullWidth>
          <InputLabel htmlFor="payment_method"><Trans {...messages.paymentMethod} /></InputLabel>
          <Select
            variant="standard"
            value={paymentMethod}
            onChange={this.handlePaymentMethodChange}
            inputProps={{
              name: 'payment_method',
              id: 'payment_method',
            }}>
            {map(thaiPaymentTypes, o => <MenuItem value={o.value} key={o.key}>{o.label}</MenuItem>)}
          </Select>
        </FormControl>
        }
        {!isEmpty(methodCardTokens) && country !== countries.th.key &&
          <React.Fragment>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={12}>
                <PageSubTitle className={classes.address}><Trans {...messages.yourCards} /></PageSubTitle>
              </Grid>
              {map(methodCardTokens, (t) =>
                <Grid item xs={12} key={t.id}>
                  <CardToken
                    cardToken={t}
                    isSelected={selectedCardTokenId === t.id}
                    onClick={(id) => this.setState({selectedCardTokenId: id})} />
                </Grid>
              )}
            </Grid>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={12}>
                <Typography
                  variant={isMobile() ? 'body1' : 'body2'}
                  color="primary"
                  onClick={() => {
                    this.setState((state) => ({
                      selectedCardTokenId: null,
                    }), () => this.getUrl())
                  }}
                  className={classes.addCard}
                >
                  <Trans {...messages.addNewCard} />
                </Typography>
              </Grid>
            </Grid>
          </React.Fragment>
        }
        <PaymentActionButton
          loading={gettingUrl}
          onClick={() => this.getUrl()}
          disable={canProceed || (country === countries.th.key && !paymentMethod)}
        />
      </React.Fragment>
    )
  }
}

ExactlyProvider.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  amount: PropTypes.number.isRequired,
  providerProperties: PropTypes.shape({
    name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    paymentKey: PropTypes.string,
  }).isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

export default compose(
  withStyles(style),
  withCreateDeposit,
  withCreateDepositPCT,
)(ExactlyProvider)
