import React, {Component} from 'react'
import PlanRoutes from './routes'

export class Plan extends Component {

  render() {
    return <PlanRoutes />
  }
}

export default (Plan)
