const vivernoOverrides = {
  inputColor: 'rgba(255,255,255)',
  palette: {
    mode: 'light',
    primary: {
      main: '#36e680',
      dark: '#005b33',
      light: '#72eea6',
    },
    error: {
      main: '#EF2D34',
    },
    green: {
      color: '#72eea6',
    },
    lightgreen: {
      color: 'rgba(114, 238, 166, 0.2)',
      textColor: '#4d8c70',
    },
    lightblue: {
      color: 'rgba(94, 106, 235, 0.1)',
      textColor: '#5E6AEB',
    },
    yellow: {
      color: '#F0CB2B',
    },
    lightyellow: {
      color: 'rgba(240, 203, 43, 0.2)',
    },
    red: {
      color: '#F7230C',
    },
    lightred: {
      color: 'rgba(247, 35, 12, 0.1)',
    },
    grey: {
      color: 'rgba(0,0,0,0.75)',
    },
    main: {
      color: '#36e680',
    },
    mainDark: {
      color: ' #005b33',
    },
    mainLight: {
      color: ' #3394FF',
    },
    pink: {
      color: '#E55CE5',
    },
    lightpink: {
      color: '#491B49',
    },
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: ({theme}) => ({
          backgroundColor: theme.palette.main.color,
        }),
      },
    },
  },
}

export default vivernoOverrides
