import React from 'react'
import {get, find, map, isEmpty, sortBy, isFunction, includes, flowRight as compose, omitBy} from 'lodash'
import {branch} from 'recompose'
import {graphql} from 'react-apollo'
import {withNamespaces, Trans} from 'react-i18next'
import Grid from '@mui/material/Grid'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import withStyles from '@mui/styles/withStyles'
import FormHelperText from '@mui/material/FormHelperText'
import {withdrawalPaymentFields, countries, withdrawalPaymentVendors, directa24TaxNumberLabels} from '@bdswiss/common-enums'
import messages from '../../../assets/messages'
import {safeParseJSON} from '../../../common/utils/general'
import AppContext from '../../Common/contexts/AppContext'
import {DYNAMIC_WITHDRAWAL_FIELDS_QUERY} from '../../../graphql/queries'
import Loading from '../../Common/Loading'

const styles = theme => ({
  error: {
    color: theme.palette.red.color
  },
})

class WithdrawalFieldsView extends React.Component {
  static contextType = AppContext
  render() {
    const {method, country, errors, form, availableMethod, methodFields, t, classes, dynamicFields, handleChange, netAmount, showCCFee, ccFee} = this.props
    const {locale, clientType} = this.context

    const localeParams = {}
    if (method === withdrawalPaymentVendors.directa24.value) {
      localeParams.id_type = get(directa24TaxNumberLabels, country.toLowerCase()) ? directa24TaxNumberLabels[country.toLowerCase()].label : 'ID'
    } else if (method === withdrawalPaymentVendors.payRetailers.value) {
      localeParams.id_type = '#'
    }

    const specificErrors = ['iban', 'swiftCode', 'routingNumber']
    return (
      <Grid container spacing={3}>
        {map(omitBy(methodFields, (f) => f.key === withdrawalPaymentFields.termsAndConditions.key), (field, i) => {
          const label = field.localization.t(locale, localeParams)
          const options = isFunction(field.options)
            ? field.options({
              withdrawalPaymentVendor: method,
              dynamicFields,
              country: get(countries[country], 'value'),
              paymentOption: get(availableMethod, 'paymentOption') || get(form, 'paymentOption'),
              bankCode: get(form, 'provider'),
              currency: get(form, 'cryptoCurrency'),
              clientType,
            }) : field.options
          let additionalProps = {}
          if (field.key === withdrawalPaymentFields.cardNumber.key) {
            additionalProps = {inputProps: {pattern: '[0-9]*', maxLength: 4, inputMode:'numeric'}}
          } else if (field.key === 'iban') {
            additionalProps = {inputProps: {style: {textTransform: 'uppercase'}}}
          }
          return (
            <Grid item xs={12} key={i}>
              {!options && <TextField
                variant="standard"
                label={label}
                value={get(form, field.key, '')}
                onChange={(e) => handleChange(field.key, e.target.value)}
                id={field.key}
                fullWidth
                error={!!get(errors, field.key, false)}
                helperText={(includes(specificErrors, field.key) && get(errors, field.key) === 'validation')
                  ? t(messages[`${field.key}Validation`].i18nKey)
                  : (get(errors, field.key, false) && t(messages.requiredField.i18nKey, messages.requiredField.defaults))}
                disabled={!isEmpty(get(availableMethod, field.key))}
                {...additionalProps} />
              }
              {field.key === 'swiftCode' && get(errors, 'ibanSwiftNotCoherent') && <FormHelperText className={classes.error}>
                <Trans {...messages.ibanSwiftNotCoherent} /></FormHelperText>}
              {options && <FormControl variant="standard" fullWidth>
                <InputLabel htmlFor={field.key}>{field.localization.t(locale)}</InputLabel>
                <Select
                  variant="standard"
                  value={get(form, field.key, '')}
                  onChange={(e) => {
                    handleChange(field.key, e.target.value, get(find(options, {key: e.target.value}), 'label'))
                    if (field.key === withdrawalPaymentFields.provider.key) {
                      const methodFieldKeys = map(methodFields, 'key')
                      const branchCodeKey = withdrawalPaymentFields.branchCode.key
                      if (includes(methodFieldKeys, branchCodeKey) && get(form, branchCodeKey)) {
                        handleChange(branchCodeKey, '')
                      }
                    }
                  }}
                  error={get(errors, field.key, false)}
                  inputProps={{
                    name: field.key,
                    id: field.key,
                  }}
                  disabled={!isEmpty(get(availableMethod, field.key))}>
                  {map(sortBy(options, 'label'), (o) => (
                    <MenuItem
                      value={o.key}
                      label={o.label}
                      key={o.key}
                    >
                      {o.label}
                    </MenuItem>
                  ))}
                </Select>
                {get(errors, field.key, false) &&
                  <FormHelperText className={classes.error}><Trans {...messages.requiredField} /></FormHelperText>
                }
              </FormControl>
              }
            </Grid>
          )
        })}
        {showCCFee && <React.Fragment>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              label={t(messages.withdrawalFee.i18nKey, messages.withdrawalFee.defaults)}
              value={ccFee}
              id={'withdrawalFee'}
              fullWidth
              disabled={true} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              label={t(messages.amountToWithdrawal.i18nKey, messages.amountToWithdrawal.defaults)}
              value={netAmount}
              id={'amountToWithdrawal'}
              fullWidth
              disabled={true} />
          </Grid>
        </React.Fragment>}
      </Grid>
    )
  }
}

const dynamicFieldsQuery = graphql(DYNAMIC_WITHDRAWAL_FIELDS_QUERY, {
  options: ({method, currency}) => ({
    fetchPolicy: 'no-cache',
    variables: {providerName: method, currency},
  }),
  name: 'dynamicFields'}
)

const dynamicWithdrawalFields = (Component) => dynamicFieldsQuery(({dynamicFields, ...other}) => {
  if (dynamicFields.loading) {
    return <Loading />
  }
  const fields = get(dynamicFields, 'dynamicWithdrawalFields.fields', '{}')
  const withdrawalFields = safeParseJSON(fields)
  other.setDynamicFields(withdrawalFields)
  return <Component dynamicFields={withdrawalFields} {...other} />
})

export default compose(
  branch(
    ({method}) => get(find(withdrawalPaymentVendors, {key: method}), 'hasDynamicWithdrawalFields', false),
    dynamicWithdrawalFields
  ),
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
)(WithdrawalFieldsView)
