import React, {useRef} from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'

/**
 * This HOC was added to provide backward compatibility for legacy components, since withWidth was removed from the API by
 * newer versions of material. Do not use it in newer components, instead use the new mui5 features such as [useMediaQuery](https://mui.com/material-ui/react-use-media-query/)
 */
export const withMediaQuery = (breakpoint) => (WrappedComponent) => (props) => {
  const widthUp = useMediaQuery((theme) => theme.breakpoints.up(breakpoint))
  const widthDown = useMediaQuery((theme) => theme.breakpoints.down(breakpoint))
  return <WrappedComponent {...props} widthUp={widthUp} widthDown={widthDown} />
}

/**
 * This HOC adds a `breakpoint` property based on the current screen resolution.
 */
export const withBreakpoint = (WrappedComponent) => (props) => <WrappedComponent {...props} breakpoint={
  Object.entries({
    xs: useMediaQuery((theme) => theme.breakpoints.up('xs')),
    sm: useMediaQuery((theme) => theme.breakpoints.up('sm')),
    md: useMediaQuery((theme) => theme.breakpoints.up('md')),
    lg: useMediaQuery((theme) => theme.breakpoints.up('lg')),
    xl: useMediaQuery((theme) => theme.breakpoints.up('xl')),
  }).filter(([, isMatch]) => isMatch).map(([key]) => key).pop()} />


/**
 * Higher-Order Component that wraps the content of a given component
 * with another component specified via the `wrapComponent` prop.
 *
 * @param WrappedComponent - The component to be wrapped.
 * @returns A new component that conditionally wraps its children with `wrapComponent`.
 *
 * Usage:
 * const SomeComponent = withWrap(MyComponent)
 *
 * <SomeComponent wrapComponent={SomeWrapper} wrapProps={...props} ...otherProps />
 *
 * If `wrapComponent` is provided, the content will be wrapped with it.
 * If not, the content will be rendered without any wrapping.
 */
export const withWrap = (WrappedComponent) => ({wrapComponent: WrapComponent, wrapProps, ...props}) => {
  const wrapRef = useRef(null)
  if (WrapComponent) {
    return (
      <WrapComponent ref={wrapRef} {...wrapProps}>
        <WrappedComponent wrapRef={wrapRef} {...props}/>
      </WrapComponent>
    )
  }
  return <WrappedComponent {...props} />
}
