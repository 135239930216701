import PropTypes from 'prop-types'
import React, {Component} from 'react'
import Grid from '@mui/material/Grid'
import {withRouter} from 'react-router-dom'
import withStyles from '@mui/styles/withStyles'
import {Trans, withNamespaces} from 'react-i18next'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import {flowRight as compose, get} from 'lodash'
import {frontends, transact365Types} from '@bdswiss/common-enums'
import messages from '../../../../assets/messages'
import {PaymentActionButton, withCreateDeposit} from './helpers'
import {getPlatform, getSourceBasedOnLocationPrevPath, logEventCustomParams} from '../../../../common/utils'
import Input from '@mui/material/Input'

const style = (theme) => ({
  formControl: {
    margin: theme.spacing(1)
  },
  error: {
    color: theme.palette.error.main
  },
})

class Transact365Provider extends Component {

  constructor(props) {
    super(props)
    this.state = {
      form: {
        vpaAddress: ''
      },
      errors: {},
      creatingDeposit: false,
    }
  }

  handleChange(key, value) {
    const {form} = this.state
    this.setState({
      form: {
        ...form,
        [key]: value
      }
    })
  }


  doCreateDeposit() {
    this.setState({creatingDeposit: true})

    const {
      amount, account: {id: accountId, currency}, onError, useVirtualPaymentPage, history: {location},
      providerProperties: {provider, paymentKey, id: paymentOptionId}, bonusAmount, bonusTerms,
    } = this.props
    const {form: {vpaAddress}} = this.state

    const firebaseParams = {
      source: getSourceBasedOnLocationPrevPath(get(location, 'state', {prevPath: get(location, 'pathname')})),
      login: accountId,
      currency: currency,
      vendor: provider,
      amount,
    }
    logEventCustomParams('depositAttempted', firebaseParams)

    const variables = {
      accountId: accountId,
      amount,
      vendor: provider,
      paymentOptionId,
      frontend: frontends.web2.value,
      args: JSON.stringify({
        platform: getPlatform(),
        paymentKey,
        vpaAddress,

        useVirtualPaymentPage,
        bonusAmount,
        bonusTerms,
      }),
    }

    this.props.createDepositRequest({variables})
      .then(({data: {newDeposit}}) => {
        const {payment: {url}} = newDeposit
        window.location = url
        this.setState({creatingDeposit: false})
      })
      .catch((e) => {
        onError && onError(e)
        this.setState({creatingDeposit: false})
      })
  }

  render() {
    const {classes, providerProperties: {paymentKey}, canProceed} = this.props
    const {form: {vpaAddress}, errors, creatingDeposit} = this.state

    return (
      <React.Fragment>
        <Grid container direction="row" spacing={3}>
          {paymentKey === transact365Types.unionpay.value &&
            <Grid item xs={12}>
              <FormControl variant="standard" className={classes.formControl} fullWidth>
                <InputLabel htmlFor="vpaAddress">
                  <Trans {...messages.vpaAddress} />
                </InputLabel>
                <Input
                  id="vpaAddress"
                  type="text"
                  error={!!errors.vpaAddress}
                  value={vpaAddress}
                  onChange={(e) => this.handleChange('vpaAddress', e.target.value)}
                />
                {errors.vpaAddress && <FormHelperText className={classes.error}><Trans {...errors.vpaAddress} /></FormHelperText>}
              </FormControl>
            </Grid>
          }
          <PaymentActionButton
            disable={canProceed}
            loading={creatingDeposit}
            onClick={() => this.doCreateDeposit()}
          />
        </Grid>
      </React.Fragment>
    )
  }
}

Transact365Provider.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  amount: PropTypes.number.isRequired,
  providerProperties: PropTypes.shape({
    name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    paymentKey: PropTypes.string.isRequired,
    paymentOptionId: PropTypes.number.isRequired,
  }).isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

export default compose(
  withStyles(style),
  withCreateDeposit,
  withNamespaces(),
  withRouter,
)(Transact365Provider)
