import {Trans} from 'react-i18next'
import React, {Component} from 'react'
import Grid from '@mui/material/Grid'
import withStyles from '@mui/styles/withStyles'
import messages from '../../../../assets/messages'
import LoadingButton from '../../../Common/LoadingButton'
import FormHelperText from '@mui/material/FormHelperText'
import AppContext from '../../../Common/contexts/AppContext'

const styles = theme => ({
  button:{
    marginTop:0
  },
  errorMessage: {
    color: theme.palette.error.main,
    display: 'inline-block',
    verticalAlign: 'bottom',
    margin: '13px 13px 13px 0'
  },
})

export class DueDiligenceButtonsToolbar extends Component {
  static contextType = AppContext
  render() {
    const {activeStep, submitForm, classes, submitButtonFeatures:{submitLoading,status}, disableSubmit} = this.props
    return (
      <Grid container spacing={2}>
        {activeStep === 1 &&
        <Grid item>
          <LoadingButton
            id='loadingButton'
            onClick={submitForm}
            disabled={submitLoading || status==='failure' || disableSubmit}
            hideProgressBar={(!submitLoading && status==='failure') || disableSubmit}
            status={status}
            className={classes.button}
          ><Trans {...messages.submit} />
          </LoadingButton>
          {status==='failure' &&
            <FormHelperText className={classes.errorMessage}><Trans {...messages.generalError} /></FormHelperText>}
        </Grid>}
      </Grid>
    )
  }
}

export default withStyles(styles, {withTheme: true})(DueDiligenceButtonsToolbar)
