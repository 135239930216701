import React, {Component} from 'react'
import {graphql} from 'react-apollo'
import {withRouter} from 'react-router-dom'
import PropTypes from 'prop-types'
import {withNamespaces, Trans} from 'react-i18next'
import {find, findIndex, first, map, flowRight as compose, get, filter, startsWith} from 'lodash'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import withStyles from '@mui/styles/withStyles'
import messages from '../../assets/messages'
import PageTitle from '../Common/PageTitle'
import PageBody from '../Common/PageBody'
import PaymentRoutes from './routes'
import AppContext from '../Common/contexts/AppContext'
import {isMobile} from '../../common/utils'
import {logEventCustomParams} from '../../common/utils/firebaseEvents'
import {CLIENT_DATA_QUERY} from '../../graphql/queries'
import {paymentsTabs} from '../../common/utils/uioptions'


const styles = (theme) => ({
  header: {
    marginBottom: 40,
  },
})
export class Payments extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    const availableTabs = filter(paymentsTabs, (option) => option.show(get(props, 'viewer')))
    this.state = {
      activeTab: findIndex(availableTabs, first(availableTabs)),
      isAccountsRedirection: startsWith(get(this.props.location, 'state.prevPath', ''), '/accounts/'),
      goBackLink: this.props.location.state ? this.props.location.state.prevPath : '/accounts'
    }
  }

  handleTabChanged(activeTabIndex) {
    const {history, match, viewer} = this.props
    const {blockedDeposit} = this.context
    this.setState({activeTab: activeTabIndex})
    let availableTabs = filter(paymentsTabs, (option) => option.show(viewer))
    if (blockedDeposit) {
      availableTabs = availableTabs.filter((tab) => tab.key!=='deposit')
    }
    const currentTab = availableTabs[activeTabIndex]
    history.push(`${match.url}${currentTab.relativePath}`)
  }

  goBack() {
    const {goBackLink} = this.state
    logEventCustomParams('leftTransactions', {
      method: 'return',
      userId: get(this.context, 'clientId'),
    })
    return this.props.history.push(goBackLink)
  }

  componentDidMount() {
    const {blockedDeposit} = this.context
    if (blockedDeposit) {
      const {match, history} = this.props
      const currentTab = this.getCurrentTab()
      history.push(`${match.url}${currentTab.relativePath}`)
    }
  }

  getCurrentTab() {
    const {location, viewer} = this.props
    const {blockedDeposit} = this.context
    let availableTabs = filter(paymentsTabs, (option) => option.show(viewer))
    if (blockedDeposit) {
      availableTabs = availableTabs.filter((tab) => tab.key!=='deposit')
    }
    return find(availableTabs, (tab) => location.pathname.indexOf(`${tab.relativePath}`) > -1) || first(availableTabs)
  }

  render() {
    const {classes, t, viewer} = this.props
    const {blockedDeposit, themePreference} = this.context
    let availableTabs = filter(paymentsTabs, (option) => option.show(viewer))
    if (blockedDeposit) {
      availableTabs = availableTabs.filter((tab) => tab.key!=='deposit')
    }

    const currentTab = this.getCurrentTab()
    const currentTabIndex = findIndex(availableTabs, currentTab)

    return (
      <React.Fragment>
        <PageTitle
          themePreference={themePreference}
          hideArrow={!this.state.isAccountsRedirection}
          title={t(messages.payments.i18nKey, messages.payments.defaults)}
          onBack={()=> this.goBack()}
          withoutPadding={this.state.isAccountsRedirection && isMobile()}
        >
          <Tabs
            value={currentTabIndex}
            onChange={(_, activeTab) => this.handleTabChanged(activeTab)}
            variant={isMobile()? 'scrollable' : 'standard'}
            scrollButtons={false}
          >
            {map(availableTabs, (tab) => <Tab key={tab.key} label={<Trans {...tab.title} />} />)}
          </Tabs>
        </PageTitle>
        <PageBody>
          <PaymentRoutes />
        </PageBody>
      </React.Fragment>
    )
  }
}

Payments.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object
}

export default compose(
  withRouter,
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading: viewerLoading}, data}) => ({
      error,
      viewerLoading,
      viewer: get(data, 'viewer'),
    })
  })
)(Payments)
