import React, {Component} from 'react'
import Payment from 'payment'
import PropTypes from 'prop-types'
import Cards from 'react-credit-cards'
import Grid from '@mui/material/Grid'
import Input from '@mui/material/Input'
import {withRouter} from 'react-router-dom'
import Select from '@mui/material/Select'
import 'react-credit-cards/es/styles-compiled.css'
import {withNamespaces, Trans} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import Typography from '@mui/material/Typography'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import {frontends, depositStatuses, depositVendors} from '@bdswiss/common-enums'
import {
  isEmpty, values, get, omit, map, upperCase, startsWith, some, head, filter, includes, pick,
  flowRight as compose, has, isNil,
} from 'lodash'
import SelfPostingForm from './SelfPostingForm'
import {withCreateDeposit, withCreateDepositPCT, PaymentActionButton, BillingAddressDetails} from './helpers'
import CardToken from '../../../Common/CardToken'
import config from '../../../../config'
import messages from '../../../../assets/messages'
import {getPlatform, getSourceBasedOnLocationPrevPath, logEventCustomParams} from '../../../../common/utils'
import {isMobile} from '../../../../common/utils/browser'
import {yearRange, monthRange, safeParseJSON, getFormattedAmount} from '../../../../common/utils/general'
import PageSubTitle from '../../../Common/PageSubTitle'
import CheckoutProvider from './CheckoutProvider'
import {specificProviderValidations, validateIsStringContainsEmoji} from '../../../../common/utils/validations'
import SafeChargeProvider from './SafeChargeProvider'

const style = (theme) => ({
  formControl: {
    margin: `${theme.spacing(1)} 0`
  },
  cvc: {
    paddingTop: 3,
  },
  address: {
    fontWeight: 400,
    margin: '10px 0 0 0',
  },
  addCard: {
    cursor: 'pointer',
  },
  error: {
    color: theme.palette.red.color
  },
})

function validateSpecificProviderField(provider, value, key) {
  return value && get(specificProviderValidations[provider], key)
  && !(new RegExp(specificProviderValidations[provider][key]).test(value))
}

const validators = {
  cardNumber: (value) => !value && messages.requiredField,
  name: (value) => {
    if (!value) return messages.requiredField
    if (validateIsStringContainsEmoji(value)) return messages.invalidValue
  },
  expiryMonth: (value, yearValue) => {
    if (!value) return messages.requiredField
    if (isNaN(Number(value))) return messages.invalidMonth
    if (Number(value) < 1 || Number(value) > 12) {
      return messages.invalidMonth
    }
    const yearNow = new Date().getFullYear()
    const monthNow = new Date().getMonth() + 1
    if (yearValue && Number(value) < monthNow && Number(yearValue) === yearNow) {
      return messages.invalidMonth
    }
  },
  expiryYear: (value) => {
    if (!value) return messages.requiredField
    if (isNaN(Number(value))) return messages.invalidYear
    const startYear = new Date().getFullYear()
    if (Number(value) < startYear) {
      return messages.invalidYear
    }
  },
  cvc: (value) => !value && messages.requiredField,
  street: (value, _, showAddressDetails, provider) => validateSpecificProviderField(provider, value, 'street') ||  validateIsStringContainsEmoji(value)
    ? messages.invalidValue
    : showAddressDetails && !value && messages.requiredField,
  city: (value, _, showAddressDetails, provider) => validateSpecificProviderField(provider, value, 'city') || validateIsStringContainsEmoji(value)
    ? messages.invalidValue
    : showAddressDetails && !value && messages.requiredField,
  zip: (value, _, showAddressDetails, provider) => validateSpecificProviderField(provider, value, 'zip') || validateIsStringContainsEmoji(value)
    ? messages.invalidValue
    : showAddressDetails && !value && messages.requiredField,
  country: (value, _, showAddressDetails) => showAddressDetails && !value && messages.requiredField,
}

class CreditCardProvider extends Component {

  constructor(props) {
    super(props)

    const country = get(props, 'viewer.address.country')
    const provider = get(props, 'providerProperties.provider')
    const initialState = {
      form: {cardNumber: '', name: '', expiryMonth: '', expiryYear: '', cvc: '', street: '', city: '', zip: '', country: upperCase(get(props, 'viewer.address.country', ''))},
      focused: 'number',
      errors: {},
      creatingDeposit: false,
      callback: {},
      doSubmitForm: false,
      selectedCardTokenId: null,
      showAddressDetails: includes(config.cardProvider.restrictedCountryList, country) || [
        depositVendors.cardpay.value,
        depositVendors.checkout.value,
        depositVendors.safecharge.value,
      ].includes(provider),
      showCustomCardDetails: ![depositVendors.checkout.value, depositVendors.safecharge.value].includes(provider),
    }

    const cardTokens = get(props, 'viewer.paymentCardTokens', [])

    // TODO: ccType filter removed for https://bdswiss.atlassian.net/browse/ITIN-15030
    const methodCardTokens = filter(cardTokens, (t) => t.vendor === props.providerProperties.provider)
    if (!isEmpty(methodCardTokens)) {
      initialState.selectedCardTokenId = head(methodCardTokens).id
    }
    this.state = initialState
  }

  componentDidMount() {
    document.querySelector('[name="cardNumber"]') && Payment.formatCardNumber(document.querySelector('[name="cardNumber"]'))
    document.querySelector('[name="cvc"]') && Payment.formatCardCVC(document.querySelector('[name="cvc"]'))
    document.getElementById('cardNumber') && document.getElementById('cardNumber').focus()
  }

  handleChange(e) {
    const {form} = this.state
    const {viewer, providerProperties: {provider}} = this.props
    const country = get(viewer, 'address.country')
    const target = e.target
    const value = target.name === 'cardNumber' ? target.value.replace(/ /g, '') : target.value
    const showAddressDetails = includes(config.cardProvider.restrictedCountryList, country) ||
      (target.name === 'cardNumber'
        ? some(config.cardProvider.restrictedBinList, (bin) => startsWith(value, bin))
        : this.state.showAddressDetails
      ) || [depositVendors.cardpay.value, depositVendors.checkout.value].includes(provider)
    this.setState({
      form: {
        ...form,
        [target.name]: value,
      },
      showAddressDetails,
    }, () => this.validateFields(target.name))
  }

  validateFields(field = '') {
    const {form, showAddressDetails} = this.state
    const {providerProperties: {provider}} = this.props
    const errors = this.state.errors || {}
    if (field) {
      const validate = validators[field]
      errors[field] = validate && validate(form[field], form.expiryYear, '', provider)
      if (field === 'expiryYear' && !isEmpty(form['expiryMonth']) && validators['expiryMonth']) {
        errors['expiryMonth'] = validators['expiryMonth'](form['expiryMonth'], form.expiryYear, '', provider)
      }
    } else {
      Object.keys(omit(form, 'cardNumber')).forEach((k) => {
        const validate = validators[k]
        errors[k] = validate && validate(form[k], form.expiryYear, showAddressDetails, provider)
      })
    }
    this.setState({errors})
    return errors
  }

  formIsValid() {
    const {form, showCustomCardDetails} = this.state
    let errors = this.validateFields()
    errors = showCustomCardDetails ? errors : pick(errors, ['name', 'street', 'zip', 'city', 'country'])
    const formFields = showCustomCardDetails ? form : pick(form, ['name', 'street', 'zip', 'city', 'country'])
    return isEmpty(values(errors).filter((v) => v)) && !isEmpty(values(formFields).filter((v) => v))
  }

  doCreateDeposit(ccTempToken) {
    const {form, cardType, showAddressDetails, selectedCardTokenId} = this.state
    if (!this.formIsValid() && !selectedCardTokenId) {
      return
    }

    this.setState({creatingDeposit: true})
    const {providerProperties: {provider, paymentKey, id: paymentOptionId}, amount, account, onError, history, locale, useVirtualPaymentPage,
      bonusAmount, bonusTerms, history: {location}} = this.props

    const firebaseParams = {
      source: getSourceBasedOnLocationPrevPath(get(location, 'state', {prevPath: get(location, 'pathname')})),
      login: account.id,
      currency: account.currency,
      vendor: provider,
      amount,
    }
    logEventCustomParams('depositAttempted', firebaseParams)

    const args = {
      paymentKey,
      platform: getPlatform(),
      useCardFlow: provider !== depositVendors.checkout.value,
      cardDetails: {
        ...form,
        cardType,
        showAddressDetails,
      },
      useVirtualPaymentPage,
      bonusAmount,
      bonusTerms,
    }

    if (provider === depositVendors.safecharge.value) {
      args.safechargeVersion = 2
      args.cardDetails = {}
    }

    let variables = {
      accountId: account.id,
      amount,
      vendor: provider,
      paymentOptionId,
      frontend: frontends.web2.value,
      ccTempToken,
      args: JSON.stringify(args),
    }
    let depositFunction = this.props.createDepositRequest
    if (selectedCardTokenId) {
      depositFunction = this.props.createDepositRequestPCT
      variables = {
        accountId: account.id,
        amount,
        paymentCardTokenId: selectedCardTokenId,
        frontend: frontends.web2.value,
        args: JSON.stringify({
          paymentKey,
          platform: getPlatform(),
          useVirtualPaymentPage,
          bonusAmount,
          bonusTerms,
        }),
      }
    }

    depositFunction({variables})
      .then(({data: {newDeposit}}) => {
        const {payment, deposit} = newDeposit
        if (deposit.status !== depositStatuses.pending.key) {
          const {isPartialApproval} = safeParseJSON(get(deposit, 'payment.meta', '{}'))
          const resultStatus = deposit.status === depositStatuses.completed.key
            ? 'success' : deposit.status === depositStatuses.failed.key ? 'failed' : 'pending'
          history.push(`/transactions/${account.id}/deposit/result/${resultStatus}`, {
            depositId: deposit.id,
            isPartialApproval,
            formattedAmount: getFormattedAmount({amount: deposit.amount, currency: account.currency, locale})
          })
        } else {
          if (payment.url) {
            window.location = payment.url
          } else {
            const payload = JSON.parse(payment.payload)
            if (payload.sessionToken && provider === depositVendors.safecharge.key) {
              this.setState({safechargePayload: payload, creatingDeposit: false})
            } else {
              this.setState({formData: payload, creatingDeposit: false, doSubmitForm: true})
            }
          }
        }
      })
      .catch((e) => {
        this.setState({creatingDeposit: false})
        onError && onError(e)
      })
  }

  checkCardNumber() {
    const {callback: {type, isValid}, errors, form: {cardNumber}} = this.state
    if (get(type, 'issuer') === 'unknown' || !isValid) {
      const isMaestro = new RegExp('^(5[06789]|6)[0-9]{0,}$').test(cardNumber)
      if (isMaestro) {
        this.setState({
          cardType: 'maestro',
        })
      } else {
        this.setState({
          errors: {
            ...errors,
            cardNumber: messages.invalidCardNumber,
          },
        })
      }
    } else if (get(type, 'issuer') !== 'unknown' && isValid) {
      this.setState({
        cardType: get(type, 'issuer'),
        errors: omit(errors, 'cardNumber'),
      })
    }
  }

  render() {
    const {classes, viewer: {paymentCardTokens}, providerProperties: {provider}, canProceed} = this.props
    const {form, creatingDeposit, formData, safechargePayload, errors, focused, doSubmitForm, showAddressDetails,
      selectedCardTokenId, showCustomCardDetails,
    } = this.state

    // TODO: ccType filter removed for https://bdswiss.atlassian.net/browse/ITIN-15030
    const methodCardTokens = filter(paymentCardTokens, (t) => t.vendor === provider)

    const expiry = `${get(form, 'expiryMonth', '')}/${get(form, 'expiryYear', '')}`
    const disablePayButton = !selectedCardTokenId && (has(errors, 'cardNumber') || get(errors, 'name') !== false ||
      !isNil(get(errors, 'expiryMonth')) || !isNil(get(errors, 'expiryYear')) || get(errors, 'cvc') !== false)

    return (
      <React.Fragment>
        <SelfPostingForm formData={formData} doSubmitForm={doSubmitForm} />
        {!isEmpty(methodCardTokens) &&
          <React.Fragment>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={12}>
                <PageSubTitle className={classes.address}><Trans {...messages.yourCards} /></PageSubTitle>
              </Grid>
              {map(methodCardTokens, (t) =>
                <Grid item xs={12} key={t.id}>
                  <CardToken
                    cardToken={t}
                    isSelected={selectedCardTokenId === t.id}
                    onClick={(id) => this.setState({selectedCardTokenId: id})} />
                </Grid>
              )}
            </Grid>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={12}>
                <Typography
                  variant={isMobile() ? 'body1' : 'body2'}
                  color="primary"
                  onClick={() => {
                    this.setState((state) => ({
                      selectedCardTokenId: null,
                      errors: {
                        ...state.errors,
                        cardNumber: messages.invalidCardNumber,
                      },
                    }))
                  }}
                  className={classes.addCard}
                >
                  <Trans {...messages.addNewCard} />
                </Typography>
              </Grid>
            </Grid>
          </React.Fragment>
        }
        <React.Fragment>
          {(!selectedCardTokenId && showCustomCardDetails) &&
          <Grid container
            direction="row"
            spacing={1}
          >
            <Grid item xs={12}>
              <Cards
                number={get(form, 'cardNumber', '')}
                name={get(form, 'name', '')}
                expiry={expiry}
                cvc={get(form, 'cvc', '').replace(/[0-9]/g, '*')}
                focused={focused}
                callback={(type, isValid) => this.setState({callback: {type, isValid}})}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl variant="standard" className={classes.formControl} fullWidth>
                <InputLabel htmlFor="cardNumber">
                  <Trans {...messages.cardNumber} />
                </InputLabel>
                <Input
                  id="cardNumber"
                  type="text"
                  name="cardNumber"
                  error={!!errors.cardNumber}
                  onKeyUp={(e) => this.handleChange(e)}
                  onFocus={(e) => this.setState({focused: 'number'})}
                  onBlur={() => this.checkCardNumber()}
                  inputProps={{pattern: '[0-9]*', inputMode: 'numeric', autoComplete: 'off'}}
                />
                {
                  errors.cardNumber &&
                  <FormHelperText className={classes.error}><Trans {...errors.cardNumber} /></FormHelperText>
                }
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="standard" className={classes.formControl} fullWidth>
                <InputLabel htmlFor="name">
                  <Trans {...messages.fullName} />
                </InputLabel>
                <Input
                  id="name"
                  type="text"
                  name="name"
                  error={!!errors.name}
                  onChange={(e) => this.handleChange(e)}
                  onFocus={(e) => this.setState({focused: e.target.name})}
                  inputProps={{autoComplete: 'off'}}
                />
                {
                  errors.name &&
                  <FormHelperText className={classes.error}><Trans {...errors.name} /></FormHelperText>
                }
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={4}>
              <FormControl variant="standard" className={classes.formControl} fullWidth>
                <InputLabel htmlFor="expiryMonth">
                  <Trans {...messages.expiryMonth} />
                </InputLabel>
                <Select
                  variant="standard"
                  native
                  id="expiryMonth"
                  name="expiryMonth"
                  type="text"
                  value={get(form, 'expiryMonth', '')}
                  onChange={(e) => this.handleChange(e)}
                  inputProps={{name: 'expiryMonth'}}
                  onFocus={(e) => this.setState({focused: e.target.name})}>
                  <option disabled key="empty" value=""/>
                  {map(monthRange, (m) => (<option key={m} value={m}>{m}</option>))}
                </Select>
                {
                  errors.expiryMonth &&
                  <FormHelperText className={classes.error}><Trans {...errors.expiryMonth} /></FormHelperText>
                }
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={4}>
              <FormControl variant="standard" className={classes.formControl} fullWidth>
                <InputLabel htmlFor="expiryYear">
                  <Trans {...messages.expiryYear} />
                </InputLabel>
                <Select
                  variant="standard"
                  native
                  id="expiryYear"
                  name="expiryYear"
                  type="text"
                  value={get(form, 'expiryYear', '')}
                  onChange={(e) => this.handleChange(e)}
                  inputProps={{name: 'expiryYear'}}
                  onFocus={(e) => this.setState({focused: e.target.name})}>
                  <option disabled key="empty" value=""/>
                  {map(yearRange, (m) => (<option key={m} value={m}>{m}</option>))}
                </Select>
                {
                  errors.expiryYear &&
                  <FormHelperText className={classes.error}><Trans {...errors.expiryYear} /></FormHelperText>
                }
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={4}>
              <FormControl variant="standard" className={classes.formControl} fullWidth>
                <InputLabel htmlFor="cvc">
                  <Trans {...messages.cvv} />
                </InputLabel>
                <Input
                  id="cvc"
                  type="text"
                  className={classes.cvc}
                  name="cvc"
                  error={!!errors.cvc}
                  onChange={(e) => {
                    document.getElementById('cvc').type = 'password'
                    this.handleChange(e)
                  }}
                  onFocus={(e) => this.setState({focused: e.target.name})}
                  inputProps={{pattern: '[0-9]*', maxLength: 4, inputMode: 'numeric', autoComplete: 'off', className: classes.cvc}}
                />
                {
                  errors.cvc &&
                  <FormHelperText className={classes.error}><Trans {...errors.cvc} /></FormHelperText>
                }
              </FormControl>
            </Grid>
            {showAddressDetails && <BillingAddressDetails
              errors={errors}
              form={form}
              handleChange={(e) => this.handleChange(e)}
              onCardFocus={() => this.setState({focused: 'number'})}
            />}
          </Grid>}

          {(selectedCardTokenId || showCustomCardDetails) && <PaymentActionButton
            loading={creatingDeposit}
            disable={canProceed || disablePayButton}
            onClick={() => this.doCreateDeposit()}
          />}
        </React.Fragment>
        {(!selectedCardTokenId && provider === depositVendors.checkout.value) &&
          <CheckoutProvider
            doCreateDeposit={(token) => this.doCreateDeposit(token)}
            creatingDeposit={creatingDeposit}
            canProceed={canProceed}
            selectedCardTokenId={selectedCardTokenId}
            errors={errors}
            form={form}
            handleChange={(e) => this.handleChange(e)}
            onCardFocus={() => this.setState({focused: 'number'})}
            formIsValid={() => this.formIsValid()}
          />
        }
        {((!selectedCardTokenId || safechargePayload) && provider === depositVendors.safecharge.value) &&
          <SafeChargeProvider
            form={form}
            errors={errors}
            canProceed={canProceed}
            payload={safechargePayload}
            viewer={this.props.viewer}
            creatingDeposit={creatingDeposit}
            handleChange={(e) => this.handleChange(e)}
            providerProperties={this.props.providerProperties}
            doCreateDeposit={() => this.doCreateDeposit()}
            formIsValid={() => this.formIsValid()}
          />
        }
      </React.Fragment>
    )
  }
}

CreditCardProvider.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  amount: PropTypes.number.isRequired,
  providerProperties: PropTypes.shape({
    name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    paymentKey: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

export default compose(
  withStyles(style),
  withNamespaces(),
  withRouter,
  withCreateDeposit,
  withCreateDepositPCT,
)(CreditCardProvider)
