const vivernoDarkOverrides = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#36e680',
      dark: '#005b33',
      light: '#72eea6',
    },
    error: {
      dark: '#FF7C81',
    },
    green: {
      color: '#72eea6',
    },
    lightgreen: {
      color: 'rgba(114, 238, 166, 0.2)',
      textColor: '#4d8c70',
    },
    lightblue: {
      color: 'rgba(94, 106, 235, 0.2)',
    },
    yellow: {
      color: '#F0CB2B',
    },
    lightyellow: {
      color: 'rgba(240, 203, 43, 0.2)',
    },
    red: {
      color: '#F7230C',
    },
    lightred: {
      color: 'rgba(247, 35, 12, 0.1)',
    },
    grey: {
      color: 'rgba(255,255,255,0.75)',
    },
    lightgrey: {
      color: 'rgba(255,255,255,0.12)',
    },
    extralightgreyBackground: {
      color: 'rgba(255,255,255,0.12)',
    },
    main: {
      color: '#005b33',
    },
    mainDark: {
      color: '#36e680',
    },
    mainLight: {
      color: ' #3394FF',
    },
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: ({theme}) => ({
          backgroundColor: theme.palette.main.color,
        }),
      },
    },
  },
}

export default vivernoDarkOverrides
