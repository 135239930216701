import {Trans} from 'react-i18next'
import React, {Component} from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import withStyles from '@mui/styles/withStyles'
import messages from '../../../../assets/messages'
import LoadingButton from '../../../Common/LoadingButton'
import FormHelperText from '@mui/material/FormHelperText'

const styles = theme => ({
  button:{
    marginTop:0
  },
  errorMessage: {
    color: theme.palette.error.main,
    display: 'inline-block',
    verticalAlign: 'bottom',
    margin: '13px 13px 13px 0'
  },
})

export class PortfolioManagementButtonsToolbar extends Component {
  render() {
    const {activeStep, previousStep, nextStep, submitForm, disableSubmit, classes, totalSteps,
      submitButtonFeatures:{submitLoading,status}} = this.props
    return (
      <Grid container spacing={2}>
        {activeStep > 0 &&
        <Grid item>
          <Button variant="contained" size="large" onClick={previousStep}>
            <Trans {...messages.back} />
          </Button>
        </Grid>}
        {activeStep < totalSteps-1 &&
        <Grid item>
          <Button color="primary" size="large" variant="contained" onClick={nextStep}>
            <Trans {...messages.continue} />
          </Button>
        </Grid>}
        {activeStep === totalSteps-1 && !disableSubmit &&
        <Grid item>
          <LoadingButton
            id='loadingButton'
            onClick={submitForm}
            disabled={submitLoading || status==='failure'}
            hideProgressBar={!submitLoading && status==='failure'}
            status={status}
            className={classes.button}
          ><Trans {...messages.submit} />
          </LoadingButton>
          {status==='failure' &&
            <FormHelperText className={classes.errorMessage}><Trans {...messages.generalError} /></FormHelperText>}
        </Grid>}
      </Grid>
    )
  }
}

export default withStyles(styles, {withTheme: true})(PortfolioManagementButtonsToolbar)
