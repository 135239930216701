import React, {Component} from 'react'
import {graphql, withApollo} from 'react-apollo'
import {flowRight as compose} from 'lodash'
import {Card, Grid, Tooltip, Typography} from '@mui/material'
import {Trans, withNamespaces} from 'react-i18next'
import messages from '../../assets/messages'
import classNames from 'classnames'
import CustomTooltip from '../Common/CustomTooltip'
import HelpIcon from '@mui/icons-material/HelpOutline'
import {IB_SSO_LINK_MUTATION} from '../../graphql/mutations'
import CopyToClipboard from 'react-copy-to-clipboard'
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined'

export class IbLinkCard extends Component {
  state = {
    ibClipboardTooltip: false,
    clickedEvent: false,
  }
  copyLink() {
    this.setState({ibClipboardTooltip: true})
  }
  render() {
    const {t, classes, ibCampaignUrl} = this.props
    const {ibClipboardTooltip} = this.state
    return (<Grid item xs={12} md={12} lg={6} className={classNames(classes.displayFlex)}>
      <Card classes={{root: classes.cardHover}}>
        <Grid container spacing={1} justifyContent="space-between">
          <Grid item xs={12}>
            <Typography variant='caption' className={classes.inline}><Trans {...messages.linkToIntroduceIbs} /></Typography>
            <CustomTooltip
              title={<Typography variant='caption' className={classes.tooltipText}>{t(messages.introduceIbsTooltip.i18nKey, messages.introduceIbsTooltip.defaults)}</Typography>}
              placement={'top'}
            >
              <HelpIcon className={classes.icon} onClick={() => this.setState({linkIbTooltip: true})}/>
            </CustomTooltip>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3} alignItems="center">
              <Grid item md={10} xs={12}>
                <Typography variant='body1' className={classNames(classes.inline, classes.clientLink)}>{ibCampaignUrl}</Typography>
              </Grid>
              <Grid item md={2} xs={12} className={classes.displayPadding}>
                <CopyToClipboard text={ibCampaignUrl}>
                  <Typography variant='caption' className={classNames(classes.link, classes.pointer)}
                    onClick={()=> this.copyLink()}
                    onMouseLeave={()=> this.setState({ibClipboardTooltip: false})}>
                    <FileCopyOutlined />
                  </Typography>
                </CopyToClipboard>
                <Tooltip
                  open={ibClipboardTooltip}
                  title={t(messages.partnerLinkCopied.i18nKey,messages.partnerLinkCopied.defaults)}
                  placement="right"
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  onClose={() => this.setState({ibClipboardTooltip: false})}
                ><span></span>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Grid>
    )
  }
}

export default compose(
  withApollo,
  withNamespaces(),
  graphql(IB_SSO_LINK_MUTATION, {
    name: 'createIBSingleSignOnLink',
  }),
)(IbLinkCard)
