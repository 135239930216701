import {accountTypes} from '@bdswiss/common-enums'

/**
 * Each custom event trigger configured on server and supported by web app should have its name inside this
 * enumeration. Fire events only through constants defined here.
 */
export const customEventsEnum = {
  appropriatenessTestDone: 'kyc-test-done',
  depositSuccessful: 'deposit-success',
  depositFailed: 'deposit-failed',
  registration: 'SSORegistration',
  onBoarding: 'SSOOnboarding',
  depositSuccessfulFTD: 'deposit-success-ftd',
  ebookDownloaded: 'ebook_downloaded',
  entitiesSelection: 'entitiesSelection',
}

/**
 * List of variables configured on the server and supported as web app. Server configuration has to list them as
 * 'data layer' type.
 */
export const customVariablesEnum = {
  loggedClientId: 'SSOUserID',
  productType: 'SSOProductID',
  country: 'SSOCountry',
  ebookDownloaded: 'SSOEbookDownloaded',
}

/**
 * Send an event together with a bunch of variables. If a variable with similar name already exists, it will be
 * overridden.
 *
 * Note 1: variables and events do nothing unless there are corresponding variables/events configured on google
 * servers. Keep knowhow about server-configured variables inside this file.
 *
 * Note 2: depending on configuration, change of variable value can trigger yet another tag (e.g. tracking event or
 * just random javascript configured via google api and not under our control). Therefore, related variables must
 * always be updated together.
 *
 * Due to its nature, avoid using this function directly - create convenience function which will collect all
 * related values in mandatory parameters instead.
 *
 * @param event
 * @param variables
 */
const pushEvent = (eventName, variables = {}) => {
  if (!window || !window.dataLayer) {
    //do nothing in mobile app environment
    return
  }

  if (!eventName) {
    console.error('Empty google tags manager event name.') // eslint-disable-line no-console
  }

  window.dataLayer.push({
    event: eventName,
    ...variables,
  })
}

export const fireAppropriatenessTestDoneEvent = (clientId) => {
  pushEvent(customEventsEnum.appropriatenessTestDone, {
    [customVariablesEnum.loggedClientId]: clientId,
  })
}

export const fireDepositSuccesfulEvent = (clientId,productType) => {
  pushEvent(customEventsEnum.depositSuccessful, {
    [customVariablesEnum.loggedClientId]: clientId,
    [customVariablesEnum.productType]: accountTypes[productType] ? accountTypes[productType].value : productType,
  })
}

export const fireDepositFailedEvent = (clientId,productType) => {
  pushEvent(customEventsEnum.depositFailed, {
    [customVariablesEnum.loggedClientId]: clientId,
    [customVariablesEnum.productType]:  accountTypes[productType] ? accountTypes[productType].value : productType,
  })
}

export const fireRegistrationEvent = (clientId, productType) => {
  pushEvent(customEventsEnum.registration, {
    [customVariablesEnum.loggedClientId]: clientId,
    [customVariablesEnum.productType]: productType,
  })
}

export const fireOnBoardingEvent = (clientId, country) => {
  pushEvent(customEventsEnum.onBoarding, {
    [customVariablesEnum.loggedClientId]: clientId,
    [customVariablesEnum.country]: country,
  })
}

export const fireDepositSuccesfulFTDEvent = (clientId,productType) => {
  pushEvent(customEventsEnum.depositSuccessfulFTD, {
    [customVariablesEnum.loggedClientId]: clientId,
    [customVariablesEnum.productType]: accountTypes[productType] ? accountTypes[productType].value : productType,
  })
}

export const fireDownloadEbookEvent = (clientId, ebookKey) => {
  pushEvent(customEventsEnum.ebookDownloaded, {
    [customVariablesEnum.loggedClientId]: clientId,
    [customVariablesEnum.ebookDownloaded]: ebookKey,
  })
}

export const fireEntitiesSelectionEvent = (country) => {
  pushEvent(customEventsEnum.entitiesSelection, {
    [customVariablesEnum.country]: country,
  })
}
