import React, {Component} from 'react'
import {withNamespaces} from 'react-i18next'
import {Redirect} from 'react-router-dom'
import {maxBy, filter, includes, get, isEmpty, flowRight as compose} from 'lodash'
import {accountTypes} from '@bdswiss/common-enums'
import withStyles from '@mui/styles/withStyles'
import {graphql} from 'react-apollo'
import {Loading} from '../Common/Loading'
import {ACCOUNTS_QUERY} from '../../graphql/queries'
import {isPAMMFundManagerAccount, isMt5ForexAccount} from '../../common/utils/accounts'
import {isMobile} from '../../common/utils/browser'
import {getEurXRate} from '../../common/utils/general'
import {getItem} from '../../common/utils/localStorage'

export class ForexTrader extends Component {


  getForexAccountIdWithMaxBalance (accounts) {
    const forexAccountTypes =
      filter(accountTypes, (a) => includes(['forexMt4', 'forexMt5'], a.category) && !a.isDemo).map((a) => a.key)
    const ForexAccounts = Object.values(accounts)
      .filter((a) => (!a.hidden && includes(forexAccountTypes, a.__typename)))
    const accountWithMaxBalance = maxBy(ForexAccounts,
      (account) => account.balance * getEurXRate(account.currency))
    if (accountWithMaxBalance) {
      return accountWithMaxBalance
    }
  }

  render() {
    const {loading, accounts} = this.props
    if (loading) return <Loading />
    if (!isEmpty(accounts)) {
      const  autochartistClientId = getItem('autochartist_id')
      const autochartistClient = autochartistClientId && filter(accounts, (a) => a.id === Number(autochartistClientId))[0]
      const updatedAccountId = autochartistClient || this.getForexAccountIdWithMaxBalance(accounts)

      if (isMobile() || isMt5ForexAccount(updatedAccountId.__typename)) {
        return <Redirect to={`/accounts/${updatedAccountId.id}/downloadTrader/`} />
      } else {
        return <Redirect to={`/trade/forex/${updatedAccountId.login}`} />
      }
    } else {
      return <Redirect to={'/accounts'} />
    }
  }
}

export default compose (
  withStyles({withTheme: true}),
  withNamespaces(),
  graphql(ACCOUNTS_QUERY, {
    options: () => ({fetchPolicy: 'network-only'}),
    props: ({data: {error, loading}, data}) => {
      const accounts = filter(get(data, 'viewer.accounts'), (account) => !isPAMMFundManagerAccount(account))

      return {
        error,
        loading,
        accounts,
      }
    }
  }),
)(ForexTrader)
