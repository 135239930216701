import React from 'react'
import {styled} from '@mui/system'

import Logo from '../Common/Logo'
import {isMobile} from '../../common/utils'
import CloseButton from '../Common/CloseButton'

const ModalTitleWrap = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& div:first-of-type': {
    width: '100%',
    justifyContent: 'end',
  },
  '& img': {
    width: 180,
    height: 'auto',
    marginTop: 24,
  },
  '& h2': {
    margin: 0,
    padding: isMobile() ? '24px 8px' : 0,
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '1.6rem',
  },
})

const ModalTitle = ({close, children, ...rest}) => (
  <ModalTitleWrap>
    {close && <CloseButton onClose={close} />}
    {isMobile() && <Logo />}
    <h2 {...rest}>{children}</h2>
  </ModalTitleWrap>
)

export default ModalTitle
