import React from 'react'
import {Trans} from 'react-i18next'
import {withRouter} from 'react-router-dom'
import {flowRight as compose, includes} from 'lodash'
import withStyles from '@mui/styles/withStyles'
import {Box, Typography} from '@mui/material'
import messages from '../../../assets/messages'
import moment from 'moment'
import Amount from '../../Common/Amount'
import classNames from 'classnames'
import EastIcon from '@mui/icons-material/East'
import {isTransferTransactionType} from '../../../common/utils/transactions'
import {transactionCategories} from '../../../common/utils/uioptions'
import LoadingWrapper from '../LoadingWrapper'
import {transactionTypes} from '@bdswiss/common-enums/lib/esm'
import {logEventCustomParams} from '../../../common/utils'
import AppContext from '../../Common/contexts/AppContext'
import {getTransactionStatusCategory} from './utils'

const styles = (theme) => ({
  body2Text: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: theme.palette.secondary.dark,
    '&.completed': {
      color: theme.palette.green.color,
    },
    '&.pending': {
      color: theme.palette.yellow.color,
    },
    '&.rejected': {
      color: theme.palette.red.color,
    },
    '&.link': {
      color: theme.palette.primary.main,
    },
    '&.secondaryMain': {
      color: theme.palette.secondary.main,
    }
  },
  viewContainer: {
    display: 'flex',
    gap: theme.spacing(3),
    flexDirection: 'column',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  titleText: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '30px',
    letterSpacing: '0.15px',
  },
  tableContainer: {
    display: 'flex',
    borderRadius: '8px',
    gap: theme.spacing(3),
    flexDirection: 'column',
    padding: theme.spacing(2),
    border: theme.palette.mode === 'dark' ? '1px solid #1F1F1F' : '1px solid #EBEBEB',
  },
  tableItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  leftColumnContainer: {
    gap: '2px',
    display: 'flex',
    flexDirection: 'column'
  },
  rightColumnContainer: {
    gap: '2px',
    flexDirection: 'column'
  },
  typeCellContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'flex-start',
    '&.transferCell': {
      gap: 'inherit',
      flexDirection: 'column'
    }
  },
  remoteIdContainer: {
    display: 'flex',
    padding: '2px 4px',
    borderRadius: '4px',
    alignItems: 'center',
    border: theme.palette.mode === 'dark' ? '1px solid #1F1F1F' : '1px solid #EBEBEB',
  },
  remoteIdTransferContainer: {
    gap: '2px',
    display: 'flex',
    padding: '2px 4px',
    borderRadius: '4px',
    alignItems: 'center',
    border: theme.palette.mode === 'dark' ? '1px solid #1F1F1F' : '1px solid #EBEBEB',
  },
  remoteIdTransferToArrow: {
    width: '16px',
    height: '16px',
    color: theme.palette.secondary.main,
  },
  dateCellContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  amountCellContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  statusCellContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  seeAllContainer: {
    display: 'flex',
    padding: '10px 20px',
    justifyContent: 'center',
  },
  remoteIdText: {
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: theme.palette.secondary.main,
  },
  highlightText: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: theme.palette.secondary.dark
  }
})

const hideSensitiveData = '***'

class MobileView extends React.Component {
  static contextType = AppContext

  renderTypeCell(type, remoteId, transferAccount) {
    const {classes, showSensitiveData} = this.props

    return <Box className={classNames(classes.typeCellContainer, {transferCell: isTransferTransactionType(type)})}>
      <Typography variant='body2' className={classes.highlightText}> <Trans {...transactionCategories[type].message} /> </Typography>
      {isTransferTransactionType(type)
        ? <Box className={classes.remoteIdTransferContainer}>
          {showSensitiveData
            ? <React.Fragment>
              <Typography variant='caption' className={classes.remoteIdText}> #{remoteId} </Typography>
              <EastIcon className={classes.remoteIdTransferToArrow} />
              <Typography variant='caption' className={classes.remoteIdText}> #{transferAccount.remoteId} </Typography>
            </React.Fragment>
            : <Typography variant='caption'className={classes.remoteIdText}> {hideSensitiveData} </Typography>
          }
        </Box>
        : <Box className={classes.remoteIdContainer}>
          <Typography variant='caption' className={classes.remoteIdText}>
            {showSensitiveData ?`#${remoteId}` : hideSensitiveData}
          </Typography>
        </Box>
      }
    </Box>
  }

  renderDateCell(createdAt) {
    const {classes, showSensitiveData} = this.props

    return <Box className={classes.dateCellContainer}>
      <Typography variant='body2' className={classNames(classes.body2Text, {secondaryMain: true})}>
        {showSensitiveData ? moment(createdAt).format('DD MMM YYYY, HH:MM') : <span> {hideSensitiveData} </span>}
      </Typography>
    </Box>
  }

  renderAmountCell(amount, currency, type, transferAccount) {
    const {clientId} = this.context
    const {classes, showSensitiveData} = this.props
    const transferTypes = [transactionTypes.transfer.value, transactionTypes.ldTransfer.value]
    const isNegativeAmount = type === transactionTypes.withdrawal.value || (includes(transferTypes, type) && clientId === transferAccount.memberId)

    return <Box className={classes.amountCellContainer}>
      {showSensitiveData
        ? <Amount
          classes={{
            value: classes.highlightText,
            currency: classes.highlightText,
            fraction: classes.highlightText,
            minusSign: classes.highlightText,
          }}
          value={isNegativeAmount ? `-${amount}` : amount}
          currency={currency}
          locale='en-US'
        />
        : <span> {hideSensitiveData} </span>
      }
    </Box>
  }

  renderStatusCell(status) {
    const {classes} = this.props
    const statusCategory = getTransactionStatusCategory(status)

    return <Box className={classes.statusCellContainer}>
      <Typography variant='body2' className={classNames(classes.body2Text, statusCategory)}>
        <Trans {...messages[statusCategory]} />
      </Typography>
    </Box>
  }

  render() {
    const {clientId} = this.context
    const {classes, history, loading, transactions} = this.props

    return <Box className={classes.viewContainer}>
      <Box className={classes.titleContainer}>
        <LoadingWrapper loading={loading}> <Typography className={classes.titleText}> <Trans {...messages.recentTransactions} /> </Typography> </LoadingWrapper>
      </Box>
      <Box className={classes.tableContainer}>
        {transactions.map((t, index) => {
          const {type, createdAt, amount, currency, status, remoteId, transferAccount} = t

          return <Box key={`${type} - ${index}`} className={classes.tableItemContainer}>
            <Box className={classes.leftColumnContainer}>
              <LoadingWrapper loading={loading}> {this.renderTypeCell(type, remoteId, transferAccount)} </LoadingWrapper>
              <LoadingWrapper loading={loading}> {this.renderDateCell(createdAt)} </LoadingWrapper>
            </Box>
            <Box className={classes.rightColumnContainer}>
              <LoadingWrapper loading={loading}> {this.renderAmountCell(amount, currency, type, transferAccount)} </LoadingWrapper>
              <LoadingWrapper loading={loading}> {this.renderStatusCell(status)} </LoadingWrapper>
            </Box>
          </Box>
        })}
      </Box>
      <Box className={classes.seeAllContainer}>
        <Typography
          variant='body2'
          className={classNames(classes.body2Text, {link: true})}
          onClick={() => {
            logEventCustomParams('seeAllTransactionsClicked', {
              userId:  clientId,
            })
            history.push('/transactions/history')}
          }>
          <Trans {...messages.seeAll} />
        </Typography>
      </Box>
    </Box>
  }
}

export default compose(
  withRouter,
  withStyles(styles, {withTheme: true}),
)(MobileView)
