import gql from 'graphql-tag'

const competitionBasicFields = `
    id
    name
    startDate
    endDate
    rules
    prizes
    icon
    termsLink
    participantsCount
    isDemoAccountsOnly
    isNewlyCreatedAccountsOnly
    acceptPreviousWinners
    createdAt
    isPublished
`

export const GET_COMPETITIONS_APPLICABLE_QUERY = gql`
  query competitionsApplicable($memberId: Int) {
    competitionsApplicable(memberId: $memberId) {
      ${competitionBasicFields}
      meta
    }
  }
`

export const GET_COMPETITIONS_CREATED_BY_CLIENT_QUERY = gql`
  query competitionsCreatedByClient($createdByMemberId: Int!) {
    competitionsCreatedByClient(createdByMemberId: $createdByMemberId, orderDirection: descending) {
      ${competitionBasicFields}
      totalDeposits
      competitionCreatedByClientData{
        isRejected
      }
    }
  }
`

export const GET_COMPETITIONS_APPLICABLE_COUNT_QUERY = gql`
  query competitionsApplicableCount($memberId: Int) {
    competitionsApplicableCount(memberId: $memberId)
  }
`
