import React, {Component} from 'react'
import {graphql} from 'react-apollo'
import messages from '../../assets/messages'
import AppContext from '../Common/contexts/AppContext'
import PageTitle from '../Common/PageTitle'
import {withNamespaces} from 'react-i18next'
import {flowRight as compose, get} from 'lodash'
import {getCurrentTheme, isMobile} from '../../common/utils'
import {Fade} from '@mui/material'
import IframeResizer from 'iframe-resizer-react'
import withStyles from '@mui/styles/withStyles'
import {TRADING_CENTRAL_LINK} from '../../graphql/queries'
import Loading from '../Common/Loading'

const styles = () => ({
  iframe:{
    overflow: 'scroll',
    minHeight: '75vh',
    width: '100%',
  },
})

export class EconomicCalendar extends Component {
  static contextType = AppContext

  render() {
    const {themePreference} = this.context
    const {t, history, classes, loading, error, link} = this.props
    if (loading) return <Loading />
    if (error) return `Error! ${error.message}`

    return (
      <PageTitle
        withoutPadding={isMobile()}
        themePreference={themePreference}
        onBack={() => history.push('/tools')}
        title={t(messages.economicCalendar.i18nKey, messages.economicCalendar.defaults)}
      >
        <Fade in={true} timeout={2000}>
          <div>
            <IframeResizer
              scrolling
              src={link}
              allowFullScreen={true}
              frameBorder="0"
              id="economicCalendar"
              title="economicCalendar"
              className={classes.iframe}
            />
          </div>
        </Fade>
      </PageTitle>
    )
  }
}

export default compose(
  graphql(TRADING_CENTRAL_LINK, {
    options: () => ({
      fetchPolicy: 'no-cache',
      variables: {
        page: 'economic_calendar',
        theme: getCurrentTheme(),
      }
    }),
    props: ({data: {error, loading}, data}) => ({
      error,
      loading,
      link: get(data, 'tradingCentralLink'),
    }),
  }),
  withStyles(styles),
  withNamespaces(),
)(EconomicCalendar)
