import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'
import {withNamespaces} from 'react-i18next'
import {companies, frontends} from '@bdswiss/common-enums'
import {flowRight as compose, get, toUpper} from 'lodash'
import {withCreateDeposit} from './helpers'
import config from '../../../../config'
import {getCurrentTheme, getItem, getPlatform, getSourceBasedOnLocationPrevPath, isDarkTheme, logEventCustomParams, safeParseJSON} from '../../../../common/utils'

class ApplePayProvider extends Component {

  constructor(props) {
    super(props)
    this.state = {errors: {}}
  }

  componentDidMount() {
    if (window.ApplePaySession) {
      this.setState({canMakePayments: true})

      // const {merchantId: merchantIdentifier} = safeParseJSON(this.props.providerProperties.additionalFields)
      // console.log('ApplePay canMakePaymentsWithActiveCard merchantIdentifier', merchantIdentifier)
      // const promise = window.ApplePaySession.canMakePaymentsWithActiveCard(merchantIdentifier)
      // promise.then((canMakePayments) => {
      //   console.log('ApplePay canMakePaymentsWithActiveCard res', {canMakePayments})
      //   this.setState({canMakePayments})
      // })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.state.canMakePayments) {
      return
    }

    if (!this.state.sessionToken && !this.state.creatingDeposit) {
      this.createDeposit()
    }

    const applePayButton = document.getElementById('apple-pay-button')
    if (!this.state.initiated && applePayButton) {
      applePayButton.addEventListener('click', () => this.doDeposit({}))
      this.setState({initiated: true})
    }
  }

  createDeposit() {
    this.setState({creatingDeposit: true})

    const {
      amount, account, onError, bonusAmount, bonusTerms, providerProperties: {
        id: paymentOptionId, provider, paymentKey, history: {location}
      },
    } = this.props

    const args = {
      paymentKey, platform: getPlatform(), useCardFlow: true, bonusTerms: bonusTerms, bonusAmount: bonusAmount, cardDetails: {}, safechargeVersion: 2,
    }

    const firebaseParams = {
      source: getSourceBasedOnLocationPrevPath(get(location, 'state', {prevPath: get(location, 'pathname')})),
      login: account.id,
      currency: account.currency,
      vendor: provider,
      amount,
    }
    logEventCustomParams('depositAttempted', firebaseParams)

    const variables = {
      accountId: account.id, amount: amount, vendor: provider, frontend: frontends.web2.value, paymentOptionId: paymentOptionId, args: JSON.stringify(args),
    }

    this.props.createDepositRequest({variables})
      .then(({data: {newDeposit: {payment}}}) => JSON.parse(payment.payload))
      .then((payload) => {
        this.setState({
          sessionToken: payload.sessionToken,
          clientUniqueId: payload.clientUniqueId,
          creatingDeposit: false,
        })
      })
      .catch((e) => {
        this.setState({creatingDeposit: false})
        onError && onError(e)
      })
  }

  doDeposit() {
    const {providerProperties, viewer, account, history} = this.props
    const {sessionToken} = this.state
    const additionalFields = safeParseJSON(providerProperties.additionalFields)

    const sfc = window.SafeCharge({
      env: config.common.safecharge.env,
      merchantId: additionalFields.merchantId,
      merchantSiteId: additionalFields.merchantSiteId,
    })

    const applePayPAyment = {
      sessionToken: sessionToken,     //received from openOrder API
      merchantId: additionalFields.merchantId,        //as assigned by Nuvei
      merchantSiteId: additionalFields.merchantSiteId, //as assigned by Nuvei
      env: config.common.safecharge.env, // Nuvei API environment - 'int' (integration) or 'prod' (production - default if omitted)
      applicationData: '', // use your own 64-bit encoded data
      // merchantCapabilities : ['supports3DS','supportsCredit'] // optional
      // supportedNetworks: ['discover', 'visa', 'masterCard'] // optional
      countryCode: toUpper(get(this.props, 'viewer.address.country')),
      currencyCode: toUpper(account.currency),
      total: {
        label: companies[viewer.company].label,
        amount: this.props.amount.toString(),
      },
    }

    return sfc.createApplePayPayment(applePayPAyment, (res) => {
      if (!res.cancelled && res.result === 'APPROVED') {
        history.push(`/transactions/${account.id}/deposit/result/success`)
      } else {
        history.push(`/transactions/${account.id}/deposit/result/failed`)
      }
    })
  }

  render() {
    if (!this.state.canMakePayments) {
      return <></>
    }

    const themePreference = getCurrentTheme(this.context)
    return <apple-pay-button
      style={{
        ...this.props.style,
        '--apple-pay-button-width': '100%',
        '--apple-pay-button-height': '48px',
        '--apple-pay-button-padding': '7px',
        '--apple-pay-button-box-sizing': 'border-box',
        '--apple-pay-button-border-radius': '24px',
      }}
      id="apple-pay-button"
      buttonstyle={isDarkTheme(themePreference) ? 'white' : 'black'}
      type="add-money"
      locale={getItem('locale', 'en')}
    />
  }
}

ApplePayProvider.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired, currency: PropTypes.string.isRequired,
  }).isRequired, amount: PropTypes.number.isRequired, providerProperties: PropTypes.shape({
    id: PropTypes.number.isRequired, name: PropTypes.string.isRequired, provider: PropTypes.string.isRequired, paymentKey: PropTypes.string.isRequired,
  }).isRequired, onError: PropTypes.func.isRequired, onSubmit: PropTypes.func,
}

export default compose(
  withNamespaces(),
  withRouter,
  withCreateDeposit,
)(ApplePayProvider)
