/* eslint-disable */
import {get, last, filter,some, includes, isEmpty, map, sortBy, find, toUpper, sumBy, replace, toLower, has,
  forEach, isNil, orderBy,
  difference, upperCase, concat} from 'lodash'
import {accountTypes, campaignTypes, accountSubtypes, clientTypes, whiteLabels, regions, currencies, 
  countries} from '@bdswiss/common-enums'
import config from '../../config'
import {getCurrencyRate, isMenaCountry, isWhiteLabel} from '.'
import messages from '../../assets/messages'
import moment from 'moment'

export function isDemoAccount({__typename} = 0) {
  return __typename ? accountTypes[__typename].isDemo : false
}

export function isForexAccount({__typename}) {
  return ![
    accountTypes.PAMMAccount.key,
    accountTypes.PAMMSMAccount.key,
    accountTypes.PAMMMauritiusAccount.key,
    accountTypes.PAMMVanuatuAccount.key,
    accountTypes.PAMMComorosAccount.key,
    accountTypes.PAMMSMMauritiusAccount.key,
    accountTypes.PortfolioManagementAccount.key,
    accountTypes.PortfolioManagementMauritiusAccount.key,
    accountTypes.IntroducingBrokerSwissMarketsAccount.key,
    accountTypes.IntroducingBrokerAccount.key,
    accountTypes.IntroducingBrokerSwissMarketsMauritiusAccount.key,
    accountTypes.IntroducingBrokerSwissMarketsVanuatuAccount.key,
    accountTypes.IntroducingBrokerMauritiusAccount.key,
    accountTypes.IntroducingBrokerBDSwissVanuatuAccount.key,
    accountTypes.IntroducingBrokerSouthAfricaAccount.key,
    accountTypes.IntroducingBrokerComorosAccount.key,
    accountTypes.BondAccount.key,
    accountTypes.BondDemoAccount.key,
    accountTypes.BondMauritiusAccount.key,
    accountTypes.BondMauritiusDemoAccount.key,
    accountTypes.BondVanuatuAccount.key,
    accountTypes.BondVanuatuDemoAccount.key,
  ].includes(__typename) &&
    filter(accountTypes, (a) => includes(['forexMt4', 'forexMt5'], a.category)).map((c) => c.key).includes(__typename)
}

export function isMAMAccount({__typename}) {
  return get(accountTypes, [__typename, 'category']) === 'mam'
}

export function isMAMMasterAccount({__typename, accountSubtype}) {
  return get(accountTypes, [__typename, 'category']) === 'mam' &&
    accountSubtype === accountSubtypes.master.key
}

export function isMAMScSubAccount({__typename, accountSubtype}) {
  return get(accountTypes, [__typename, 'category']) === 'mam' &&
    accountSubtype === accountSubtypes.sc_sub.key
}

export function isPremiumAccount({__typename}) {
  return includes([
    accountTypes.ForexPremiumMauritiusAccount.key,
    accountTypes.ForexPremiumVanuatuAccount.key,
  ], __typename)
}

export function isPAMMFundManagerAccount({__typename}) {
  return [
    accountTypes.PAMMFundManagerAccount.key,
    accountTypes.PAMMSMFundManagerAccount.key,
    accountTypes.PAMMFundManagerMauritiusAccount.key,
    accountTypes.PAMMSMFundManagerMauritiusAccount.key,
    accountTypes.PAMMSMFundManagerVanuatuAccount.key,
    accountTypes.PAMMFundManagerVanuatuAccount.key,
  ].includes(__typename)
}

export function isPortfolioManagementAccount({__typename}) {
  return [
    accountTypes.PortfolioManagementAccount.key,
    accountTypes.PortfolioManagementMauritiusAccount.key,
  ].includes(__typename)
}

export function isPAMMCysecProduct({__typename}) {
  return [
    accountTypes.PAMMAccount.key,
    accountTypes.PAMMSMAccount.key,
  ].includes(__typename)
}

export function isAffiliatesAccount({__typename}) {
  return [
    accountTypes.AffiliateAccount.key,
    accountTypes.AffiliateVanuatuAccount.key,
    accountTypes.AffiliateMauritiusAccount.key,
    accountTypes.AffiliateComorosAccount.key,
    accountTypes.AffiliateSouthAfricaAccount.key,
  ].includes(__typename)
}

export function isIntroducingBrokerAccount({__typename}) {
  return [accountTypes.IntroducingBrokerAccount.key,
    accountTypes.IntroducingBrokerSwissMarketsAccount.key,
    accountTypes.IntroducingBrokerMauritiusAccount.key,
    accountTypes.IntroducingBrokerSwissMarketsMauritiusAccount.key,
    accountTypes.IntroducingBrokerSwissMarketsVanuatuAccount.key,
    accountTypes.IntroducingBrokerBDSwissVanuatuAccount.key,
    accountTypes.IntroducingBrokerSouthAfricaAccount.key,
    accountTypes.IntroducingBrokerComorosAccount.key,
  ].includes(__typename)
}

export function isTradesmarterAccount({__typename}) {
  return filter(accountTypes, {category: 'tradeSmarter'}).map((c) => c.key).includes(__typename)
}

export function isBitnukAccount({__typename}) {
  return filter(accountTypes, {subCategory: 'bitnuk'}).map((c) => c.key).includes(__typename)
}

export function blockedDepositAccount({__typename, isViewOnly, isArchived}) {
  if (isViewOnly || isArchived) return true
  return includes(filter(accountTypes, {clientDepositPermitted: false, isDemo:false}).map((c) => c.key),__typename)
}

export function blockedWithdrawalAccount({__typename}) {
  return includes(filter(accountTypes, {clientWithdrawalPermitted: false, isDemo:false}).map((c) => c.key),__typename)
}

export function isWalletAccountClientType(clientType) {
  return [clientTypes.affiliate.value,
    clientTypes.affiliateCorporate.value,
    clientTypes.ib.value,
    clientTypes.ibCorporate.value,
  ].includes(clientType)
}

export function hasOnlyDepositBlockedAccounts(accounts) {
  const otherAccounts = filter(accounts, (__typename) => !blockedDepositAccount(__typename))
  return accounts && otherAccounts.length === 0
}

export function hasAffiliateAccount(accounts) {
  const affiliateAccount = filter(accounts, (__typename) => isAffiliatesAccount(__typename))
  return accounts && affiliateAccount.length > 0
}

export function hasIntroducingBrokerAccount(accounts) {
  const ibAccount = filter(accounts, (__typename) => isIntroducingBrokerAccount(__typename))
  return accounts && ibAccount.length > 0
}

export function getIbIds(accounts) {
  if (!accounts) return []
  if (!hasIntroducingBrokerAccount(accounts)) return []
  return map(accounts, (account) => map(account?.partnerDetails || [], ({ibId}) => ibId)).flat().filter(Boolean)
}

export function isMt5ForexAccount(__typename) {
  return get(accountTypes, [__typename, 'category']) === 'forexMt5'
}

export function isForexBdxAccount({__typename}) {
  return filter(accountTypes, {subCategory: 'bdx'}).map((c) => c.key).includes(__typename)
}

export function isSMPrimeWalletAccount({__typename}) {
  return filter(accountTypes, {category: 'smPrime'}).map((c) => c.key).includes(__typename)
}

export function isPAMMForexAccount(__typename) {
  return get(accountTypes, [__typename, 'category']) === 'pammForex'
}

export function isEmptySubscriptionAccount(account) {
  return  includes(filter(accountTypes, {subscription: true}).map((c) => c.key),account.__typename) ? isEmpty(account.subscription) : false
}

export function isAffiliateClient(clientType) {
  return [
    clientTypes.affiliate.value,
    clientTypes.affiliateCorporate.value,
  ].includes(clientType)
}

export function isIBClient(clientType) {
  return [
    clientTypes.ib.value,
    clientTypes.ibCorporate.value,
  ].includes(clientType)
}

export function isIBClientSubIb(clientType, registrationCampaigns) {
  const registrationCampaign = last(registrationCampaigns)
  const isRegistrationCampaignUnderIb = registrationCampaign?.campaign?.type === campaignTypes.ib.value
  return isIBClient(clientType) && isRegistrationCampaignUnderIb
}

export function hasWalletProductAccount(accounts) {
  const walletProductAccount = filter(accounts, (a) => get(accountTypes[a.__typename], 'walletProduct'))
  return accounts && walletProductAccount.length > 0
}

export function hasVipOrRaw(accounts, demo) {
  return some(accounts, (a) => (demo ? a.isDemo : !a.isDemo) && includes([accountSubtypes.black.key, accountSubtypes.raw.key], a.accountSubtype))
}

export function isVipOrRaw(account, type, demo) {
  return includes([accountSubtypes.black.key, accountSubtypes.raw.key], get(account,'accountSubtype'))
    || (type && (!demo || (demo && type.isDemo)) && includes([accountSubtypes.black.key, accountSubtypes.raw.key], get(account,'key')))
}

export function hasVipAccount(accounts) {
  return some(accounts, (a) => includes([accountSubtypes.black.key], a.accountSubtype))
}

export function hasOnlyWalletProductAccount(accounts) {
  const walletProductAccount = filter(accounts, (a) => get(accountTypes[a.__typename], 'walletProduct'))
  return accounts && walletProductAccount.length === accounts.length
}

export function isCentAccount({__typename}) {
  return [
    accountTypes.CentMauritiusAccount.key,
    accountTypes.CentVanuatuAccount.key,
    accountTypes.CentComorosAccount.key,
    accountTypes.CentMT5MauritiusAccount.key,
    accountTypes.CentMT5VanuatuAccount.key,
    accountTypes.CentMT5ComorosAccount.key,
    accountTypes.CentMT5MauritiusDemoAccount.key,
    accountTypes.CentMT5VanuatuDemoAccount.key,
    accountTypes.CentMT5ComorosDemoAccount.key,
    accountTypes.CentMT5SouthAfricaDemoAccount.key,
    accountTypes.CentMT5SouthAfricaAccount.key,
    accountTypes.CentSouthAfricaAccount.key,
  ].includes(__typename)
}

export function isCentMt5Account(__typename) {
  return get(accountTypes, [__typename, 'category']) === 'centMt5'
}

export function getAllowedAccounts(accounts, companyObject, t, allowedAccountTypes, client) {
  const {key} = config
  if (!accounts) return []
  const allowedCategories = ['forexMt4', 'forexMt5', 'pammForex', 'cent', 'centMt5']
  const hasWalletProduct = get(client, 'hasPartnerAccount')
  const whiteLabel = isWhiteLabel()
  const whiteLabelSupportedSubtypes = whiteLabel && map(get(whiteLabels[key], 'supportedSubtypes'), 'value')
  const country = toUpper(get(client, 'address.country'))
  const countryRegions = map(filter(regions, (r) => includes(map(r.countries, 'value'), country)), 'value')

  const allowedAccount = filter(accountTypes, (a) => !a.deprecated && a.clientOpenPermitted && a.company === companyObject.key
    && (
      (includes(allowedCategories, a.category)
        && (a.isDemo === false || a.openSeperateDemo)
        && (!a.subCategory || (a.subCategory && (a.subCategory === 'premium' || a.subscription)))
      )
      || (a.walletProduct && !hasWalletProduct)
    ) &&
    (whiteLabel && whiteLabels[key].baseProducts ? includes(whiteLabels[key].baseProducts, a.key) : true) &&
    (
      !a.disallowedCountries ||
      (
        a.disallowedCountries &&
        (
          a.manuallyAllowed
            ? ((a.clientPermittedByCountry && !includes(a.disallowedCountries, country)) || includes(client.manuallyAllowedAccounts, a.key))
            : !includes(a.disallowedCountries, country)
        )
      )
    )
    && (!a.clientMaxAllowed || (a.clientMaxAllowed && sumBy(accounts, (account) => account.__typename === a.key) < a.clientMaxAllowed))
  )
  const allowedAccountsList = []
  forEach(allowedAccount, (type) => {
    const baseProduct = type.baseProduct && type.baseProduct.replace(/^\w/, c => c.toLowerCase())
    if (type.supportedSubtypes && !type.subscription) {
      forEach(type.supportedSubtypes, (subType) => {
        if (!isEmpty(whiteLabelSupportedSubtypes) && !includes(whiteLabelSupportedSubtypes, subType)) return
        const selectedSubType = getAccountSubtype(subType)
        if (get(selectedSubType, 'disallowedCountries') && includes(selectedSubType.disallowedCountries, country)) {
          return
        }
        if (get(selectedSubType, 'clientOpenAllowedDashboard') && !includes(selectedSubType?.clientOpenAllowedDashboard, country)) {
          return
        }
        if (has(client.allowedAccountLimits, selectedSubType.key)) {
          const config = get(client.allowedAccountLimits, selectedSubType.key)
          selectedSubType.maxAllowed = get(config, toLower(country), get(config, 'default', selectedSubType.maxAllowed))
        }

        if (!isEmpty(allowedAccountTypes) && !includes(allowedAccountTypes, selectedSubType.key) && !type.isDemo) return

        const isAffOrIbClient = isWalletAccountClientType(client.clientType)
        const forbiddenCountries = map(filter(countries, country => country.isEU || country.forbidden), 'value')
        const isForbiddenCountry = includes(forbiddenCountries, client.address.country.toUpperCase())
        const isForexOrCentAccount = isForexAccount({__typename: type.key}) || isCentAccount({__typename: type.key})

        if (isAffOrIbClient && isForbiddenCountry && isForexOrCentAccount) return

        const regionCountMinDate = get(selectedSubType, 'regionCountMinDate')
        const regionExclusion = regionCountMinDate && find(countryRegions, (cr) => includes(regionCountMinDate?.excludeRegions, cr))
        const accountsCountRegionDate = !regionExclusion && regionCountMinDate?.date

        if (type.isDemo && selectedSubType.clientOpenPermittedDemo === false) return
        const clientAccounts = filter(accounts, (a) => a.accountSubtype === selectedSubType.key && a.isDemo === type.isDemo && !a.isViewOnly && !a.isArchived
          && (!accountsCountRegionDate || (accountsCountRegionDate && moment(accountsCountRegionDate).isBefore(moment(a.createdAt)))
          )
        )
        const clientAccountsCount = get(clientAccounts, 'length')
        const subTypeAlreadyExists = find(allowedAccountsList, (a) => a.accountSubtype === selectedSubType.key
           && (!selectedSubType.allowDemo || (selectedSubType.allowDemo && a.isDemo)) && (a.isDemo === type.isDemo))
        if (subTypeAlreadyExists) {
          subTypeAlreadyExists.accountTypesList && subTypeAlreadyExists.accountTypesList.push(type.key)
        } else {
          if (!selectedSubType.hideFromClients && (clientAccountsCount < selectedSubType.maxAllowed || !selectedSubType.maxAllowed)) {
            const subtypeLabel = get(selectedSubType, 'localization') ? selectedSubType.localization.t(get(client, 'locale') || 'en') : get(selectedSubType, 'label')
            const accountSubTypeObject = {
              label: `${subtypeLabel}  ${t(messages.account.i18nKey, messages.account.defaults)}`,
              icon: get(selectedSubType,'shortLabel') || type.shortLabel,
              accountSubtype: selectedSubType.key,
              accountType: type.key,
              accountTypesList: [type.key],
              category: type.category,
              subCategory: type.subCategory || '',
              baseProduct,
              walletProduct: type.walletProduct,
              maxAllowed: selectedSubType.maxAllowed,
              available: (selectedSubType.maxAllowed) ? selectedSubType.maxAllowed - clientAccountsCount : '',
              needDeposit: client.hasCampaign &&
                clientAccountsCount &&
                find(clientAccounts, (a) => !a.minimumDepositMet) &&
                !selectedSubType.skipFundRestriction,
              isDemo: type.isDemo,
              showDemoLabel: type.showDemoLabel,
            }
            allowedAccountsList.push(accountSubTypeObject)
          }
        }
      })
    } else {
      const clientAccountsLimitCount = get(filter(accounts, (a) => a.__typename === type.key && !type.supportsMultipleAccounts), 'length')
      if (clientAccountsLimitCount === 0 || !clientAccountsLimitCount) {
        const account = {
          label:`${type.label}`,
          icon: type.shortLabel,
          accountType: type.key,
          category: type.category,
          subCategory: type.subCategory || '',
          baseProduct,
          walletProduct: type.walletProduct,
          isDemo: type.isDemo,
        }
        allowedAccountsList.push(account)
      }

    }
  })
  return sortBy(allowedAccountsList, (a) => !!a.walletProduct)
}


export function getForcedServerName(account, Images) {
  const forcedServerNameDetails = get(config, 'serverNameForced')
  let forcedServerName = null
  let forcedServerExampleImage = null
  if (forcedServerNameDetails) {
    const validDetails =  (!isEmpty(forcedServerNameDetails) && !forcedServerNameDetails.validUntil && !forcedServerNameDetails.date) || (((moment(account.createdAt) < moment(forcedServerNameDetails.date))
    && (moment() < moment(forcedServerNameDetails.validUntil))))
    forcedServerName = validDetails && get(forcedServerNameDetails.serverName, account.isDemo ? 'demo' : account.server)
    forcedServerExampleImage = (get(forcedServerNameDetails, 'exampleImages.showEmpty')) || (validDetails && get(Images,get(forcedServerNameDetails.exampleImages, account.isDemo ? 'demo' : account.server)))
  }
  return {forcedServerName, forcedServerExampleImage}
}

export function getAccountSubtype(subType) {
  const {key} = config
  const whiteLabelObj = find(whiteLabels, (wl) => wl.value === key)
  const subtypes = get(whiteLabelObj, 'supportedSubtypes') || accountSubtypes
  const selectedSubtype = find(subtypes, (t) => t.value === toUpper(subType) || t.key === subType
    || t.label === toUpper(subType) || toLower(t.label) === replace(subType, '_', '')
    || toLower(replace(t.label, /\s+/g, '')) === replace(subType, '_', ''))
  return selectedSubtype
}

export function getAccountLabel(account, locale, accountSubtype, t, clientCountry, shouldTranslate) {
  const {excludeAccountLabel, hideMt4Mentions} = config
  const accountType = accountTypes[account?.__typename] || accountTypes[account?.type] || accountTypes[account?.accountType]
  const tradeSmarter = account?.isViewOnly && isTradesmarterAccount(account) && 'Wallet'
  let newLabel
  newLabel = shouldTranslate && (get(accountType, 'localization') && accountType.localization.t(locale)) || get(accountType, 'label')
  const subtypeLabel = shouldTranslate && get(accountSubtype, 'localization') ? accountSubtype.localization.t(locale) : get(accountSubtype, 'label')
  // const copyTradeSubTypes = ['copy_classic', 'copy_zero', 'copy_cent', 'copy_vip', 'copy_classic_sp',
  //   'copy_zero_sp', 'copy_cent_sp', 'copy_vip_sp']
  
  if (!excludeAccountLabel && !isNil(accountSubtype)) {
    newLabel = `${subtypeLabel} ${get(account, 'isDemo')
        ? shouldTranslate
          ?`${t(messages.demo.i18nKey, messages.demo.defaults)}`
          : messages.demo.defaults
        : shouldTranslate
          ? `${t(messages.live.i18nKey, messages.live.defaults)}`
          : messages.live.defaults
        }`
  }
  if (account?.swapFree) {
    newLabel = `${shouldTranslate ? t(messages.swapFree.i18nKey, messages.swapFree.defaults) : messages.swapFree.defaults} ${newLabel}`
  }
  let returnLabel = tradeSmarter || get(account, 'accountName') || newLabel

  if (isCopyTradingAccount(account)) {
    returnLabel = !account?.swapFree ? get(accountSubtype, 'label') : `${messages.swapFree.defaults} ${get(accountSubtype, 'label')}`
  }
  if (hideMt4Mentions) {
    returnLabel = replace(returnLabel, /MT4|MT5/gi, '')
  }

  return returnLabel
}

export function hasBinaryProductAccount(accounts) {
  const binaryProductAccount = filter(accounts, (a) => get(accountTypes[a.__typename], 'category') === 'tradeSmarter')
  return accounts && binaryProductAccount.length > 0
}

export function checkAccountCopyTrading(account) {
  const {featuresConfig:{copyTradingEnabled}} = config
  const accountTypeName = get(account,'__typename')
  const accountType = accountTypeName && accountTypes[account.__typename]
  const accountSubtype = get(account,'accountSubtype')
  return includes(get(copyTradingEnabled,'accountSubTypes',[]), accountSubtype) &&
  includes(get(copyTradingEnabled,'accountTypesCategory',[]), accountType.category)
  && !account.isDemo && !account.isViewOnly  && !account.isArchived
}

export function isWalletAccount(__typename) {
  return !!get(accountTypes[__typename], 'walletProduct')
}

export function hasValidAccountSubTypes(accounts, accountSubTypes, notReadOnly, nodeDemo) {
  return some(accounts, (a) => (includes(accountSubTypes, a.accountSubtype)
  && (!notReadOnly || (notReadOnly && !a.isReadOnly))
  && (!nodeDemo || (nodeDemo && !a.isDemo))))
}

export function getAccountTypeForStats(account) {
  if (['cent', 'centMt5'].includes(account.category)) return 'cent'
  return account.accountSubtype
}

/**
 * Filters and returns an array of trading accounts
 * @param {Array} accounts The array of account objects to filter
 * @return {Array} The filtered array of trading accounts
 */
export function getTradingAccountsDefault(accounts) {
  return filter(accounts, (account) =>
    !isAffiliatesAccount(account)
    && !isIntroducingBrokerAccount(account)
    && !isMAMAccount(account)
    && ((isForexAccount(account) || isCentAccount(account)))
    && !account.isArchived
    && !account.isViewOnly
    && !isBitnukAccount(account)
  )
}

/**
 * Automatically find and returns a trading account based on predefined criteria
 * @param {Array} accounts The array of account objects to select from
 * @return {Object|null} The selected account object, or null if no account is selected
 */
export function autoSelectTradingAccount(accounts){
  const tradingAccounts = getTradingAccountsDefault(accounts)
  const liveAccounts = orderBy(filter(tradingAccounts, (account) => !account.isDemo), ['balance'], ['desc'])
  const demoAccounts = orderBy(filter(tradingAccounts, (account) => account.isDemo), ['balance'], ['desc'])

  if(liveAccounts.length === 1) return liveAccounts[0]
  if(!liveAccounts.length && demoAccounts.length === 1) return demoAccounts[0]
  return null
}

/**
 * Automatically selects and returns the trading account with the highest balance converted to USD.
 * @param {Array} accounts The array of account objects to select from.
 * @return {Promise<Object>} A promise that resolves to the selected account object with the highest balance in USD.
 * @throws {Error} Throws an error if no trading accounts are found.
 * @async
 */
export async function autoSelectTradingAccountWithHighestBalance(accounts){
  const tradingAccounts = filter(getTradingAccountsDefault(accounts), (account) => !account.isDemo)

  if(!tradingAccounts.length) throw new Error('No trading accounts found')

  const tradingAccountsInUSD = await Promise.all(tradingAccounts.map(async (account) => {
    if(account.currency !== currencies.USD.value){
      const {amount} = await getCurrencyRate(account.currency, currencies.USD.value, account.balance)
      return {...account, balanceInUSD: amount}
    }
    return {...account, balanceInUSD: account.balance}
  }))

  const [tradingAccountInUSDOrdered] = orderBy(tradingAccountsInUSD, ['balanceInUSD'], ['desc'])
  return tradingAccountInUSDOrdered
}

export function onlyDemoAccounts(accounts) {
  const otherAccounts = filter(accounts, (account) => !account.isDemo)
  return accounts && otherAccounts.length === 0
}

export function isCorporateClient(clientType) {
  return [
    clientTypes.corporate.value,
  ].includes(clientType)
}

export function isLdWalletAccount({__typename}) {
  return includes([
    accountTypes.LdWalletAccount.key,
    accountTypes.LdWalletMauritiusAccount.key,
    accountTypes.LdWalletVanuatuAccount.key,
    accountTypes.LdWalletComorosAccount.key,
  ], __typename)
}

export function isLdClientWalletAccount({__typename}) {
  return includes([
    accountTypes.LdClientWalletAccount.key,
    accountTypes.LdClientWalletMauritiusAccount.key,
    accountTypes.LdClientWalletVanuatuAccount.key,
    accountTypes.LdClientWalletComorosAccount.key,
  ], __typename)
}

export function isZuluAccount(account) {
  const supportedProducts = ['forex_zulu_account_mt5_vanuatu']
  const copyTradeSubTypes = ['black_zulu', 'zero_zulu', 'cent_zulu', 'basic_zulu']
  return includes(copyTradeSubTypes, get(account, 'accountSubtype')) ||
    includes(supportedProducts, get(account, 'value'))
}

export function hasZuluAccount(accounts) {
  const zuluAccount = filter(accounts, (a) => isZuluAccount(a))
  return accounts && zuluAccount.length > 0
}

export function isCentAccountBasedOnCurrency(account) {
  return currencies.CUD.value === account?.currency
}

export function getAccountTypeStyles(theme, account) {
  if (!theme || !account) {
    return {
      color: theme.palette.red.color,
      backgroundColor:  theme.palette.lightred.color,
    }
  }

  const accountType = accountTypes[account.__typename]
  const accountSubtype = getAccountSubtype(account.accountSubtype)
  const accountCategory = accountType.category
  const accountSubcategory = accountType.subCategory

  const purpleSubcategories = ['ib', 'affiliate']
  const pinkSubtypes = ['raw', 'zero', 'black']
  const blueCategories = ['forexMt4', 'forexMt5', 'cent', 'centMt5']

  if (account.isDemo || account.isViewOnly || account.isReadOnly) {
    return {
      color: theme.palette.mode === 'dark' ? '#C2C2C2' : '#707070',
      backgroundColor: theme.palette.mode === 'dark' ? '#474747' : '#EBEBEB',
    }
  } else if (accountSubcategory && includes(purpleSubcategories, accountSubcategory)) {
    return {
      color: theme.palette.mode === 'dark' ? '#38136A' : theme.palette.purple.color,
      backgroundColor: theme.palette.mode === 'dark' ? '#A967FD' : theme.palette.lightpurple.color,
    }
  } else if (accountSubtype && includes(pinkSubtypes, accountSubtype.key)) {
    return {
      color: theme.palette.mode === 'dark' ? '#E55CE5': theme.palette.pink.color,
      backgroundColor: theme.palette.mode === 'dark' ? '#491B49' : theme.palette.lightpink.color,
    }
  } else if (accountCategory && includes(blueCategories, accountCategory)) {
    return {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.mode === 'dark' ? '#0C3059' : theme.palette.lightblue.color,
    }
  } else {
    return {
      color: theme.palette.red.color,
      backgroundColor:  theme.palette.lightred.color,
    }
  }
}

export function isCopyTradingAccount(account) {
  const supportedProducts = ['forex_copy_trading_account_mt5_vanuatu', 'forex_copy_trading_account_mt5_comoros']
  const copyTradeSubTypes = ['copy_classic', 'copy_zero', 'copy_cent', 'copy_vip', 'copy_classic_sp',
    'copy_zero_sp', 'copy_cent_sp', 'copy_vip_sp']
  return includes(copyTradeSubTypes, get(account, 'accountSubtype')) ||
    includes(supportedProducts, get(account, 'value'))
}

export function hasCopyTradingAccount(accounts) {
  const copyTradingAccount = filter(accounts, (a) => isCopyTradingAccount(a))
  return accounts && copyTradingAccount.length > 0
}

export function isCopyTradingAccountFollower(account) {
  const supportedProducts = ['forex_copy_trading_account_mt5_vanuatu', 'forex_copy_trading_account_mt5_comoros']
  const copyTradeSubTypes = ['copy_classic', 'copy_zero', 'copy_cent', 'copy_vip']
  return includes(copyTradeSubTypes, get(account, 'accountSubtype')) ||
    includes(supportedProducts, get(account, 'value'))
}

export function hasCopyTradingFollowerAccounts(accounts) {
  const copyTradingAccount = filter(accounts, (a) => isCopyTradingAccountFollower(a))
  return accounts && copyTradingAccount.length > 0
}

export function getAccountCurrencies (account, country, seperator = '/') {
  if (!account) return ''

  const addAccountException = map(filter(currencies, 'addAccountException'), 'value')
  const accountType = account?.accountType
  const accountSubtype = account?.accountSubtype
  
  const subtype = find(accountSubtypes, { key: accountSubtype }) || {}
  const disallowedCurrencies = accountTypes[accountType]?.disallowedCurrencies || []
  const additionalCountryCurrencies = accountTypes[accountType]?.additionalCountryCurrencies

  let accountCurrencies = map(
    filter(currencies, (currency) => !includes(disallowedCurrencies, currency.key) && !includes(addAccountException, currency.key)),
    'value'
  )

  accountCurrencies = difference(accountCurrencies, subtype.disallowedCurrencies)

  if (additionalCountryCurrencies) {
    const countryCurrencies = difference(get(
      find(additionalCountryCurrencies, (a) => a.country === upperCase(country)),
      'currencies',
      []
    ), addAccountException)

    if (!isEmpty(countryCurrencies)) {
      accountCurrencies = concat(accountCurrencies, countryCurrencies)
    }
  }

  return {allowedCurrencies: accountCurrencies.join(seperator), equivalentCurrencies: addAccountException.join(seperator)}
}
