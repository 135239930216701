import React from 'react'

import {styled} from '@mui/system'
import {Divider, Grid} from '@mui/material'

const DividerWrap = styled(Grid)({
  padding: '4px 0',
})

const DividerLine = styled(Divider)(({theme}) => ({
  borderColor: theme.palette.mode === 'dark' ? '#474747' : '#EBEBEB',
}))

const BentoDivider = () => (
  <DividerWrap>
    <DividerLine />
  </DividerWrap>
)

export default BentoDivider
