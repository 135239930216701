import React from 'react'
import {Route, Switch, Redirect} from 'react-router-dom'
import Accounts from './index'
import AccountView from './AccountView'
import AccountVerification from './AccountVerification/index'
import AddAccount from '../Accounts/AddAccount'

const routes = ({location :{search}, match: {path}}) =>
  path === '/'
    ? <Redirect to={{pathname:'/accounts' ,query:{search}}} />
    : (
      <Switch>
        <Route exact path={`${path}/add-account`} component={AddAccount} />
        <Route exact path={`${path}/verification`}  component={AccountVerification} />
        <Route exact path={`${path}/verification/:accountId`}  component={AccountVerification} />
        <Route path={`${path}/:accountId`} component={AccountView} />
        <Route path={`${path}/:parentPath/:childPath+`} render={({match}) => <Redirect to={`${path}/${match.params.parentPath}`} />} />
        <Route path={`${path}`} component={Accounts} />
      </Switch>
    )

export default routes
