import React from 'react'
import BackIcon from '@mui/icons-material/ArrowBack'
import makeStyles from '@mui/styles/makeStyles'
import {Fab} from '@mui/material'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
  sidebarIcon:{
    marginTop: 35,
    background: theme.palette.extralightgreyBackground.color,
    color: theme.palette.black.color,
    boxShadow:theme.boxShadowStyle,
    [theme.breakpoints.down('sm')]: {
      float: theme.direction === 'lrt' ? 'right' : 'left',
      marginRight: '1rem'
    },[theme.breakpoints.up('sm')]: {
      float: theme.direction === 'lrt' ? 'left' : 'right',
      marginRight:'12%'
    },
    transform: theme.direction === 'rtl' ? 'rotate(180deg)' : 'none',
  },
  smallWidth: {
    float: 'left',
    marginRight:'12%',
    marginLeft: '20px'
  },
}))

const SidebarDialog = (props) => {
  const {onClick, className, smallSidebar} = props
  const classes = useStyles()
  if (!onClick) return null

  return (
    <div className={className}>
      <Fab className={smallSidebar ? classNames(classes.sidebarIcon, classes.smallWidth) : classes.sidebarIcon} onClick={onClick}>
        <BackIcon />
      </Fab>
    </div>
  )
}

export default SidebarDialog
