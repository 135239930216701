import React, {Component} from 'react'
import {Trans, withNamespaces} from 'react-i18next'
import {get, first, flowRight as compose, find} from 'lodash'
import {graphql} from 'react-apollo'
import {AlertDialog} from '../Common/Dialog'
import AccountSelect from '../Common/AccountSelect'
import Grid from '@mui/material/Grid'
import {Typography, FormControl, FormControlLabel, Checkbox} from '@mui/material'
import messages from '../../assets/messages'
import AppContext from '../Common/contexts/AppContext'
import {referAndEarnDetails} from '../../common/utils/uioptions'
import {REFERRAL_DETAILS_QUERY} from '../../graphql/queries'
import {CLAIM_REWARD_MUTATION} from '../../graphql/mutations'
import {getPlatform} from '../../common/utils'
import {logEventCustomParams} from '../../common/utils/firebaseEvents'
import withStyles from '@mui/styles/withStyles'
import {kycStatuses} from '@bdswiss/common-enums'
import {Link} from 'react-router-dom'

const styles = theme => ({
  link: {
    color:theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight:400,
    textDecoration: 'none',
    '&:hover':{
      textDecoration: 'none',
    },
    '&:active':{
      textDecoration: 'none',
    },
  },
  checkboxLabel: {
    fontSize: 14,
    lineHeight: 1.5,
    [theme.breakpoints.down('md')]: {
      fontSize: 13,
    },
  },
  checkBoxError: {
    color: theme.palette.red.color
  },
})

export class ClaimRewardPopupMessage extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {
      account: get(first(props.accounts), 'id') || props.preselectedAccount,
      showClaim: true,
      acceptTerms: ''
    }
  }

  claimReward() {
    const {account} = this.state
    const {preselectedAccount, rewardType, onClose, rewardsObj, accounts} = this.props
    this.setState({status: '', loading: true})
    const variables = {
      accountId: account || preselectedAccount,
      rewardType
    }
    const accountSelected = find(accounts, (a) => a.id === (account || preselectedAccount))
    logEventCustomParams('referAndEarnClaimRewardClicked',
      {
        amount: `${referAndEarnDetails.currency}${get(rewardsObj, 'pendingRewards')}`,
        rewardType: rewardType,
        userId: get(this.context, 'clientId'),
      })

    this.props.claimReward({variables}).then((succ) => {
      this.setState({loading: false, status: 'success', submitMessageError: '',rewardDetails: get(succ, 'data.claimReward')})
      const params = {
        accountId: account || preselectedAccount,
        accountCurrency: get(accountSelected, 'currency'),
        amount: `${referAndEarnDetails.currency}${get(rewardsObj, 'pendingRewards')}`,
        rewardType: rewardType,
        userId: get(this.context, 'clientId'),
      }
      logEventCustomParams('referAndEarnClaimRewardSuccess', params)
      onClose()
    })
      .catch((err) => {
        logEventCustomParams('referAndEarnClaimRewardError',
          {
            amount: `${referAndEarnDetails.currency}${get(rewardsObj, 'pendingRewards')}`,
            rewardType: rewardType,
            reason: get(err, 'graphQLErrors[0].message') || err.message,
            accountId: account || preselectedAccount,
            userId: get(this.context, 'clientId'),
          })
        this.setState({loading: false, status: 'failure', submitMessageError: get(err, 'graphQLErrors[0].message') || err.message})
      })
  }

  handleAccountChange(accountId) {
    const {account} = this.state
    const {accounts} = this.props
    const accountSelected = find(accounts, (a) => a.id === account)
    logEventCustomParams('referAndEarnAccountChanged', {
      accountCurrency: get(accountSelected, 'currency'),
      userId: get(this.context, 'clientId'),
    })
    this.setState({account: accountId})
  }

  closeDialog() {
    const {rewardsObj, onClose} = this.props
    logEventCustomParams('referAndEarnCancelClaimReward', {
      amount: `${referAndEarnDetails.currency}${get(rewardsObj, 'pendingRewards')}`,
      userId: get(this.context, 'clientId'),
    })
    return onClose()
  }

  render() {
    const {t, rewardsObj, open, accounts, classes, viewer, referCampaign, rewardType} = this.props
    const {account, submitMessageError, loading, status, acceptTerms, errorAcceptTerms} = this.state
    const {locale} = this.context
    const kycApproved = get(viewer, 'kycStatus') === kycStatuses.approved.value
    const check = kycApproved || rewardType === 'referrer'

    return (
      <AlertDialog
        open={open}
        onClose={() => this.closeDialog()}
        title={t(messages.claimReward.i18nKey, messages.claimReward.defaults)}
        agreeText={check && t(messages.claim.i18nKey, messages.claim.defaults)}
        onAgree={() => check && this.claimReward()}
        disagreeText={check && t(messages.cancel.i18nKey, messages.cancel.defaults)}
        onDisagree={() => check && this.closeDialog()}
        errorText={submitMessageError}
        buttonLoading={loading}
        buttonStatus={status}
        buttonDisabled={!acceptTerms}
        hideProgressBar={!acceptTerms}
      >
        <Grid container spacing={3}>
          {check ? <React.Fragment>
            <Grid item xs={12}>
              <Typography variant="body1"><Trans {...messages[(rewardType === 'referrer') ? 'creditRewardMessage' : 'creditRewardMessageReferee']} values={{amount: `${referAndEarnDetails.currency}${get(rewardsObj, 'pendingRewards')}`}}/></Typography>
            </Grid>
            <Grid item xs={12}>
              <AccountSelect
                label={t(messages.selectAccount.i18nKey, messages.selectAccount.defaults)}
                accounts={accounts}
                value={account}
                locale={locale}
                id="account"
                name="account"
                onChange={(e) => this.handleAccountChange(e.target.value)}
                t={t}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="standard">
                <FormControlLabel
                  classes={{label:classes.checkboxLabel}}
                  control={
                    <Checkbox
                      required
                      checked={!!acceptTerms}
                      onChange={(e) => this.setState({acceptTerms: e.target.checked ? 'accept' : ''})}
                      color="primary"
                      value={acceptTerms}
                      className={errorAcceptTerms ? classes.error : ''}
                    />
                  }
                  label={<Trans {...messages.claimRewardTerms} components={[<a href={get(referCampaign, 'referTerms')} target='_blank' rel='noreferrer noopener'
                    className={classes.link}>terms</a>]} />
                  }
                />
              </FormControl>
            </Grid>
          </React.Fragment> : <Grid item xs={12}>
            <Typography variant="body1"><Trans {...messages.claimRewardVerify} components={[<Link className={classes.link} to={'/settings/profile'}>link</Link>]} /></Typography>
          </Grid>}
        </Grid>
      </AlertDialog>
    )
  }
}
export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  graphql(CLAIM_REWARD_MUTATION, {
    name: 'claimReward',
    options: {
      refetchQueries: () => [{query: REFERRAL_DETAILS_QUERY, variables: {platform: JSON.stringify(getPlatform())}}],
    },
  }),
)(ClaimRewardPopupMessage)
