import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {withNamespaces, Trans} from 'react-i18next'
import {flowRight as compose} from 'lodash'
import withStyles from '@mui/styles/withStyles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import {Link} from 'react-router-dom'
import messages from '../../../assets/messages'

const styles = theme => ({
  pendingAccount: {
    margin: '2rem 0',
    width: '100%',
    border: '1px solid rgba(224, 224, 224, 1)',
    '& a': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
      fontWeight: 400,
    },
    '& th': {
      padding: '0 12px'
    },
    '& td': {
      padding: '0 12px'
    },
    '& tr': {
      height: 48
    },
  },
  pendingAccountText: {
    fontWeight: 500,
  },
})

class AccountVerificationStatus extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.string,
    ]),
  }

  render() {
    const {classes, children, link} = this.props

    return (
      <Table className={classes.pendingAccount} id='pendingNotice'>
        <TableBody>
          <TableRow key={'pendingAccount'}>
            <TableCell component='th' scope='row' className={classes.pendingAccountText}>
              {children}
            </TableCell>
            <TableCell align='right'>
              {link && <Link to={link}><Trans {...messages.getVerified} /></Link>}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }
}

export default compose(
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
)(AccountVerificationStatus)
