import React, {Component} from 'react'
import {Trans, withNamespaces} from 'react-i18next'
import {flowRight as compose, map, get, find, includes, isEmpty, findKey, some, omit, size} from 'lodash'
import AppContext from '../Common/contexts/AppContext'
import withStyles from '@mui/styles/withStyles'
import {Link, withRouter} from 'react-router-dom'
import {corporateSteps, corporateStatuses} from '@bdswiss/common-enums'
import Grid from '@mui/material/Grid'
import {checkFileMimeType} from '../../common/utils/validations'
import {putFile, scrollElementIntoView, safeParseJSON, isMobile} from '../../common/utils'
import messages from '../../assets/messages'
import {graphql} from 'react-apollo'
import {ADD_CORPORATE_STEP, CREATE_OWN_DOCUMENT_MUTATION, SIGN_UPLOAD_URL_MUTATION} from '../../graphql/mutations'
import {CLIENT_DATA_QUERY, PROFILE_SETTINGS_QUERY} from '../../graphql/queries'
import ButtonsToolbar from './ButtonsToobar'
import CorporateStepper from './CorporateStepper'
import {Button, Typography} from '@mui/material'
import Questions, {getStepQuestions} from './Questions'
import {maxFileSize} from '../../common/utils/variables'
import Promise from 'bluebird'
import NotificationBar from '../Common/NotificationBar'

const gridScroll = 'scroll-grid'

const styles = theme => ({
  item: {
    marginTop: 20
  },
  notificationBox: {
    marginTop: 20,
    position: 'relative'
  },
  infoCTA: {
    color: theme.palette.notificationBar.blueTextColor,
  },
})

class SigningStep extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    const activeStep = corporateSteps.signing.rank
    this.state = {
      form: {},
      errors : {},
      questions: [],
      loading: false,
      status: '',
      submitted: false,
      formSubmitted: false,
      uploadedFile: {},
      errorFiles: {},
      initialForm: {},
      formChanged: {},
      activeStep,
      prevStep: find(corporateSteps, (step) => step.rank === activeStep - 1)
    }
  }

  componentDidMount() {
    const {viewer} = this.props
    const {activeStep} = this.state
    const clientDocuments = get(viewer, 'documents') || []
    getStepQuestions(viewer, activeStep, (state) => this.setState(state), false, null, clientDocuments)
  }

  handleChange (name, value) {
    const {initialForm} = this.state
    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        [name]: value
      },
      errors: {
        ...state.errors,
        [name]: !value,
      },
      formChanged:{
        ...state.formChanged,
        [name]: isEmpty(initialForm) || (value !== initialForm[name]),
      },
      status: ''
    }))
  }

  scrollUp(errors) {
    this.setState(() => scrollElementIntoView(isEmpty(errors) ? gridScroll : `drop_zone-${findKey(errors)}`, 250))
  }

  async onUploadDocuments() {
    const {viewer: {id}} = this.props
    const {uploadedFile} = this.state

    const keys = Object.keys(uploadedFile)
    const uploadedFiles = map(keys, (key) => (uploadedFile[key]))

    return await Promise.map([...uploadedFiles], async (file, key) => {
      if (file instanceof File) {
        const keySelected = keys[key]
        const internalTag = `${id}-${keySelected}-${Date.now()}`
        await this.uploadDocument(keySelected, file, internalTag, file.name).then(() => true)
      }
    })
  }

  async uploadDocument(docType, file, internalTag, fileDescription, owner) {

    if (!file) return Promise.resolve()
    const {signUploadUrl, createOwnDocument, viewer: {id}} = this.props

    return signUploadUrl({variables: {clientId: id}}).then((res) => {
      const {key, signedUrl} = res.data.signedDetails
      if (size(fileDescription) > 20) fileDescription = `${fileDescription.substring(0, 17)}...`
      return putFile(file, signedUrl).then(() =>
        createOwnDocument({variables: {type: docType, key, internalTag, fileDescription, owner}}).then((res) => true)
      )
    })
  }

  async handleSubmit() {
    const {form, errorFiles} = this.state
    const errors = {}
    const keys = Object.keys(form)

    for (const field of keys) {
      errors[field] = isEmpty(form[field]) && !(form[field] instanceof File) && form[field] !== undefined
    }

    const hasErrors = some(errors)
    if (hasErrors || !isEmpty(errorFiles)) {
      this.setState({errors},this.scrollUp(hasErrors ? errors : errorFiles))
      return
    }
    this.setState({loading: true, status: ''})
    await this.onUploadDocuments().then(() => {
      const {viewer} = this.props
      const {activeStep} = this.state
      this.setState({status: 'success', submitted: true})
      if (get(viewer, 'corporateSignupStep') === activeStep) {
        this.props.addCorporateStep({variables: {step: activeStep + 1}})
      }
    }).catch(() => {
      this.setState({loading: false, status: 'failure', uploadedFile: '', secUploadedFile: ''})
    })
  }

  handleFileUpload(prop, e, questionKey, files) {
    const {form, initialForm} = this.state
    const filesToUpload =  get(e, 'target.files') ? e.target.files : files
    const updatedProp = filesToUpload[0]
    this.setState(prevState => ({
      [prop]: {
        ...prevState[prop],
        [questionKey]: updatedProp,
      }
    }), this.handleChange(questionKey, filesToUpload[0]))
    const {errorFiles} = this.state
    map(filesToUpload,(file) => {
      try {
        checkFileMimeType(file).then( res => {
          this.setState({fileChecked: true})
          const fileFormat = res && res.indexOf('image') === -1 && res.indexOf('pdf') === -1
          const fileSize = file.size > maxFileSize * 1024 * 1024
          if (fileFormat || fileSize) {
            this.setState(prevState => ({
              errorFiles: {
                ...prevState.errorFiles,
                [questionKey]: {
                  name: file.name,
                  error: fileFormat ? 'format' : 'size'
                }
              }
            }))
          }
          else {
            const errors = omit(errorFiles,[questionKey])
            this.setState({errorFiles:errors})
          }
        })

      } catch (e) {
        console.log(e) /* eslint-disable-line */
      }
    })
    if (isEmpty(filesToUpload) && (form[questionKey] || form[questionKey] === undefined) && !initialForm[questionKey]) {
      this.setState(prevState => ({form: {...prevState.form, [questionKey]: ''},
        errors: {...prevState.errors, [questionKey]: false}, errorFiles: {...prevState.errorFiles, [questionKey]: false}
      }))
    }
  }

  render() {
    const {classes, t, history, viewer} = this.props
    const {errorFiles, uploadedFile, errors, form, questions, disabled, loading, submitted, formChanged, formSubmitted, activeStep, prevStep}= this.state
    const {companyObject, locale} = this.context
    const corporateStatus = get(viewer, 'corporateStatus')
    const disabledFields = !!corporateStatus && !get(safeParseJSON(get(viewer, 'missingDocuments')), 'corporate', false)
    const isFormChanged = includes(formChanged, true)
    const fromCorporate = get(this.props, 'location.state.fromCorporate')
    const missingCorporateDocuments = get(safeParseJSON(get(viewer, 'missingDocuments')), 'corporate', false)
    const showNotification = !!corporateStatus && !get(safeParseJSON(get(viewer, 'missingDocuments')), 'corporate', false)
    const submittedCheck = (submitted && corporateStatus) || (showNotification && fromCorporate)

    return <React.Fragment>
      {!includes(get(this.props, 'location.state.prevPath'), 'settings') &&
        <CorporateStepper activeStep={submittedCheck ? activeStep : activeStep - 1} viewerStep={get(viewer, 'corporateSignupStep')}/>}
      {showNotification && !fromCorporate && !submitted && <NotificationBar
        status="info"
        classes={{notificationBox: classes.notificationBox}}
        title={<Trans {...messages.corporateCannotChangeDetails}
          components={[<Link className={classes.infoCTA} to={'/support'}>support</Link>]}
          values={{corporateStatus: corporateStatus && corporateStatuses[corporateStatus].localization.t(locale)}}
        />}
      />}
      {(submittedCheck) ? <Grid container spacing={3}>
        <Grid item sm={8} xs={12}>
          <Typography variant='h3'>
            <Trans {...messages.applicationSubmitted} />
          </Typography>
        </Grid>
        <Grid item sm={8} xs={12}>
          <Typography variant='body1'>
            <Trans {...messages.completingCorporateForm} values={{company: companyObject.brandLabel}}/>
          </Typography>
        </Grid>
        <Grid item sm={8} xs={12}>
          <Typography variant='body1'>
            <Trans {...messages.meantimeYouCan} />
          </Typography>
          <ul>
            <li>
              <Typography variant='body1'>
                <Trans {...messages.familiriazeYourself} />
              </Typography>
            </li>
            <li>
              <Typography variant='body1'>
                <Trans {...messages.openDemoTrades} />
              </Typography>
            </li>
            <li>
              <Typography variant='body1'>
                <Trans {...messages.reachOutSupport} />
              </Typography>
            </li>
          </ul>
        </Grid>
        <Grid item sm={8} xs={12}>
          <Button variant={'contained'} size={'large'} color={'primary'} onClick={() => history.push('/')} fullWidth={isMobile()}>
            <Trans {...messages.goToDashboard} />
          </Button>
        </Grid>
      </Grid>
        : <Grid container spacing={3}>
          <Questions
            questions={questions}
            form={form}
            errors={errors}
            handleChange={(name, value) => this.handleChange(name, value)}
            step={activeStep}
            uploadedFile={uploadedFile}
            errorFiles={errorFiles}
            handleFileUpload={(prop, e, questionKey, files) => this.handleFileUpload(prop, e, questionKey, files)}
            disabled={disabledFields}
          />
          <Grid item xs={12} className={classes.item}>
            <ButtonsToolbar
              handleSubmit={() => (isFormChanged || !formSubmitted) ? this.handleSubmit() : history.push('/')}
              disabled={disabled || loading || (submitted && corporateStatus)}
              label={t(messages[(isFormChanged || !formSubmitted) ? 'submitAndComplete' : 'next'].i18nKey, messages[(isFormChanged || !formSubmitted) ? 'submitAndComplete' : 'next'].defaults)}
              onBack={() => history.push({pathname: `/corporate/${prevStep.key}`,
                state: {prevPath: corporateStatus && get(this.props, 'location.state.prevPath')}})}
              hideBack={corporateStatus && !missingCorporateDocuments}
              hideButton={corporateStatus && !missingCorporateDocuments}
            />
          </Grid>
        </Grid>}
    </React.Fragment>
  }
}

export default compose(
  withNamespaces(),
  withRouter,
  withStyles(styles, {withTheme: true}),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {loading, error}, data}) => ({
      loading,
      error,
      viewer: get(data, 'viewer')
    })
  }),
  graphql(SIGN_UPLOAD_URL_MUTATION, {
    name: 'signUploadUrl',
  }),
  graphql(CREATE_OWN_DOCUMENT_MUTATION, {
    name: 'createOwnDocument',
    options: {
      refetchQueries: [{query: PROFILE_SETTINGS_QUERY}],
    }
  }),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {loading: loadingViewer, error: errorViewer}, data}) => ({
      loadingViewer,
      errorViewer,
      viewer: get(data, 'viewer'),
    })
  }),
  graphql(PROFILE_SETTINGS_QUERY, {
    props: ({data: {error: documentsError, loading: documentsLoading}, data}) => ({
      documentsError,
      documentsLoading,
      documents: get(data, 'viewer.documents', []),
    })
  }),
  graphql(ADD_CORPORATE_STEP, {
    name: 'addCorporateStep',
    options: {
      refetchQueries: [{query: CLIENT_DATA_QUERY}],
    }
  }),
)(SigningStep)
