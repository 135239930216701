import React from 'react'
import {withRouter} from 'react-router-dom'
import {withNamespaces, Trans} from 'react-i18next'
import {find, isEmpty, flowRight as compose} from 'lodash'
import Grid from '@mui/material/Grid'
import {EDUCATIONAL_VIDEOS_QUERY} from '../../graphql/queries'
import {withApollo} from 'react-apollo'
import AppContext from '../Common/contexts/AppContext'
import Loading from '../Common/Loading'
import messages from '../../assets/messages'
import {disableDepositWithSpecificCountries, isIos, isMobile} from '../../common/utils'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import {CustomDialog} from '../Common/Dialog'
import NotificationBar from '../Common/NotificationBar'

const styles = theme => ({
  link: {
    color: theme.palette.primary.main,
    [theme.breakpoints.up('sm')]: {
      float: 'right'
    },
  },
  thumbnailImage: {
    borderRadius: '6px'
  },
  thumbnailTitle: {
    paddingTop: '8px',
    fontSize: '14px',
    lineHeight: '1.5rem',
  },
  closeIcon: {
    position: 'absolute',
    top: 15,
    right: 17,
    color: theme.palette.grey[400],
    cursor: 'pointer',
    [`${theme.breakpoints.down('lg')} and (orientation: portrait)`]: {
      zIndex: 1,
      top: isIos ? 14 : 15,
      right: isIos() ? 47 : 17,
    },
    [`${theme.breakpoints.down('lg')} and (orientation: landscape)`]: {
      top: 4,
      right: isIos() ? 60 : 30,
      zIndex: 1,
    },
  },
  dialog: {
    '& .MuiDialogTitle-root': {
      textAlign: 'left',
    },
  },
  dialogMobile: {
    '& .MuiDialogContent-root': {
      overflowY: 'hidden'
    },
    '& .MuiBackdrop-root': {
      backgroundColor: 'black'
    },
    '& .MuiPaper-root': {
      backgroundColor: 'black'
    },
    '& .MuiDialog-paper': {
      margin: 0
    }
  },
  item: {
    padding: `${theme.spacing(2)} ${theme.spacing(2)} 0px 0px`
  },
  itemMobile: {
    padding: `${theme.spacing(2)} ${theme.spacing(2)} 0px ${theme.spacing(2)}`
  },
  infoCTA: {
    color: theme.palette.notificationBar.blueTextColor,
  },
})

class VideosLevel extends React.Component {
  static contextType = AppContext

  constructor() {
    super()
    this.state = {
      loading: true,
      selectedVideo: null,
      showVideoModal: false,
    }
  }

  componentDidMount() {
    const {client: apolloClient, history} = this.props

    apolloClient.query({
      query: EDUCATIONAL_VIDEOS_QUERY,
      variables: {
        clientId: this.context.clientId,
      },
      fetchPolicy: 'network-only',
    })
      .then((res) => {
        const educationalVideos = res.data.getEducationalVideos

        this.setState({loading: false, educationalVideos})
      })
      .catch((error) => {
        history.pop()
      })
  }

  handleVideoModalOpen(selectedVideo) {
    this.setState({showVideoModal: true, selectedVideo: selectedVideo})
  }

  handleVideoModalClose() {
    this.setState({showVideoModal: false, selectedVideo: null})
  }

  render() {
    const {classes, history, match: {params}} = this.props
    const {loading, selectedVideo, educationalVideos} = this.state
    const {country} = this.context

    if (loading) return <Loading />

    const selectedVideos = find(educationalVideos, {level: {value: params.level}})

    return (isEmpty(selectedVideos?.educationalVideos)
      ? (!disableDepositWithSpecificCountries(null, country))
        ? <NotificationBar status='info'
          title={<Trans {...messages.educationalVideosFundAccountNotification} />}
          ctaMessage={<Trans {...messages.depositNow} />}
          ctaAction={() => history.push('/transactions')}
        />
        : ''
      : <React.Fragment>
        <Grid container>
          {selectedVideos.educationalVideos.map((video) => (
            <Grid item
              xs={isMobile() ? 12 : 3}
              className={isMobile() ? classes.itemMobile : classes.item}
              key={`${params.level}-${video.key}`}
              onClick={() => this.handleVideoModalOpen(video)}
            >
              <Grid container direction='column'>
                <Grid item>
                  <img className={classes.thumbnailImage} src={video.thumbUrl} alt='test' width='100%' />
                </Grid>
                <Grid item>
                  <Typography className={classes.thumbnailTitle} variant='body2'> {video.title} </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
        {!isEmpty(selectedVideo) &&
          <CustomDialog
            open={this.state.showVideoModal}
            maxWidth={isMobile() ? 'xs' : 'lg'}
            title={isMobile() ? '' : <Trans {...messages[`educationalVideo${selectedVideo.key}Title`]} />}
            customClasses={{
              closeIcon: classes.closeIcon,
              dialog: isMobile() ? classes.dialogMobile : classes.dialog
            }}
            onClose={() => this.handleVideoModalClose()}
            onCloseIcon={() => this.handleVideoModalClose()}
          >
            <video controls autoPlay={isMobile() ? true : false} src={selectedVideo.videoUrl} width='100%' />
          </CustomDialog>
        }
      </React.Fragment>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  withRouter,
  withApollo,
)(VideosLevel)
