import React, {Component} from 'react'
import ReactExport from 'react-export-excel'
import PropTypes from 'prop-types'
import {get, map, flowRight as compose, keys} from 'lodash'
import {Button} from '@mui/material'
import {withNamespaces} from 'react-i18next'
import messages from '../../assets/messages'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

export class DownloadExcel extends Component {
  render() {
    const {classes, variant, color, data, text, name, t} = this.props
    const columns = keys(get(data, '[0]'))
    return (
      <ExcelFile filename={name} element={<Button variant={variant || 'text'} color={color || 'primary'} className={classes}>
        {text}
      </Button>}>
        <ExcelSheet data={data} name={name}>
          {map(columns, (column) => <ExcelColumn key={column} value={column}
            label={get(messages, column) ? t(messages[column]?.i18nKey, messages[column]?.defaults) : column}/>)}
        </ExcelSheet>
      </ExcelFile>
    )
  }
}

DownloadExcel.propTypes = {
  data: PropTypes.array.isRequired,
  text: PropTypes.string,
  name: PropTypes.string,
}

export default compose(withNamespaces())(DownloadExcel)
