import React, {Component} from 'react'
import {flowRight as compose} from 'lodash'
import {withNamespaces} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import Grid from '@mui/material/Grid'
import {withRouter} from 'react-router-dom'
import LanguagesSelection from '../../components/LanguageSelection'
import Header from '../App/Header'

const styles = theme => ({
  mainBody:{
    maxWidth: theme.mainBodyWidth,
    margin:'auto',
    minHeight: '100%',
    position: 'relative',
  },
  list: {
    listStyle: 'none',
    paddingLeft: 0,
    columns: 2,
    '-webkit-columns': 2,
    '-moz-columns': 2,
  },
  li: {
    marginBottom: 10,
    fontSize: 15,
  },
  item: {
    paddingLeft: 20,
    fontSize: '1em',
    cursor: 'pointer',
    'white-space': 'pre',
  },
  flatItem: {
    marginRight: 10,
  }
})

class Language extends Component {

  render() {
    const {classes, location} = this.props
    return (
      <div className={classes.mainBody}>
        <Header linkTo={'/'} outer/>
        <Grid container>
          <Grid item md={3} />
          <Grid item md={9} xs={12}>
            <LanguagesSelection outer prevLocation={location}/>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default compose(
  withRouter,
  withStyles(styles, {withTheme: true}),
  withNamespaces()
)(Language)
