import {withStyles} from '@mui/styles'
import React, {Component} from 'react'
import {flowRight as compose} from 'lodash'
import Loading from '../../Common/Loading'
import PropTypes from 'prop-types'

const styles = theme => ({
  container: {
    maxHeight: '100%',
    width: '100%',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      padding: ({isMobileDevice}) => theme.spacing(isMobileDevice ? 2 : 3),
      marginTop: ({isMobileDevice}) => isMobileDevice ? theme.spacing(1) : 'initial',
      marginBottom: ({isMobileDevice}) => theme.spacing(isMobileDevice ? 2 : 4),
    },
    backgroundColor: ({isDark}) => isDark ? theme.palette.cardBackground.color : theme.palette.extralightgrey.color,
    borderRadius: theme.spacing(1),
    border: ({isDark}) => `1px solid ${theme.palette.grey[isDark ? 900 : 200]}`,
    '& svg': {
      height: 140,
    },
  },
})

export class NotificationLoadingWrapper extends Component {
  render() {
    const {classes} = this.props
    return (<div className={classes.container}><Loading /></div>)
  }
}

NotificationLoadingWrapper.propTypes = {
  isDark: PropTypes.bool.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
}

export default compose(withStyles(styles, {withTheme: true}))(NotificationLoadingWrapper)
