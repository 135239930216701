export function getResponsiveObject(theme, isMobileDevice) {
  if (!theme) return {
    'xl': {
      breakpoint: {max: 4000, min: 3000},
      items: 1,
    },
    'lg': {
      breakpoint: {max: 3000, min: 1024},
      items: 1,
    },
    'sm': {
      breakpoint: {max: 1024, min: 464},
      items: 1,
      partialVisibilityGutter: 10,
    },
    'xs': {
      breakpoint: {max: 464, min: 0},
      items: 1,
      partialVisibilityGutter: 10,
    }
  }
  return ({
    'xl': {
      breakpoint: {max: Number.MAX_SAFE_INTEGER, min: theme.breakpoints.values['lg']},
      items: 1
    },
    'lg': {
      breakpoint: {max: theme.breakpoints.values['lg'], min: theme.breakpoints.values['md']},
      items: 1,
    },
    'sm': {
      breakpoint: {max: theme.breakpoints.values['md'], min: theme.breakpoints.values['sm']},
      items: 1,
      ...(isMobileDevice && {partialVisibilityGutter: theme.spacing(1).replace('px', '')}),
    },
    'xs': {
      breakpoint: {max: theme.breakpoints.values['sm'], min: theme.breakpoints.values['xs']},
      items: 1,
      ...(isMobileDevice && {partialVisibilityGutter: theme.spacing(1).replace('px', '')}),
    }
  })
}
