import React,{Component} from 'react'
import {List} from 'react-content-loader'
import {isMobile} from '../../common/utils/browser'
import AppContext from '../Common/contexts/AppContext'
import {isDarkTheme} from '../../common/utils/general'
import {includes} from 'lodash'
import {isRtlLang} from '../../common/utils/uioptions'

export class Loading extends Component {

  static contextType = AppContext

  render() {
    const {themePreference, locale} =this.context
    const backgroundColor = isDarkTheme(themePreference) ? '#333333' : '#f5f6f7'
    const foregroundColor = isDarkTheme(themePreference) ? '#4c4a4a' : '#eee'
    return (
      isMobile() ? <List width="500" backgroundColor={backgroundColor} foregroundColor={foregroundColor} rtl={includes(isRtlLang, locale)}/>
        : <List backgroundColor={backgroundColor} foregroundColor={foregroundColor} rtl={includes(isRtlLang, locale)}/>
    )
  }
}

export default Loading

