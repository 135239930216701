import gql from 'graphql-tag'

export const REQUEST_ACCESS_TO_MANAGE_COMPETITIONS_MUTATION = gql`
  mutation($memberId: Int!) {
    requestAccessToManageCompetitions(memberId: $memberId)
  }
`
export const SIGN_COMPETITION_FILE_URL_MUTATION = gql`
  mutation($competitionName:String!) {
    signCompetitionFileUrl(competitionName: $competitionName) {
      key
      plainUrl
      signedUrl
    }
  }
`
export const UPSERT_COMPETITION_CREATED_BY_CLIENT = gql`
  mutation (
    $id: Int
    $name: String!
    $startDate: UnsetOrDateTime
    $endDate: UnsetOrDateTime
    $prizes: String
    $icon: String
    $termsLink: String
    $applicableClientTypes: [ClientType]
    $applicableCompanies: [Company]
    $applicableCountries: [Country]
    $rules: String
    $acceptPreviousWinners: Boolean
    $applicableIbIds: String
    $isDemoAccountsOnly: Boolean
    $isNewlyCreatedAccountsOnly: Boolean
    $memberId: Int!
  ) {
    upsertCompetitionCreatedByClient(
      id: $id
      name: $name
      startDate: $startDate
      endDate: $endDate
      prizes: $prizes
      icon: $icon
      termsLink: $termsLink
      applicableClientTypes: $applicableClientTypes
      applicableCompanies: $applicableCompanies
      applicableCountries: $applicableCountries
      rules: $rules
      acceptPreviousWinners: $acceptPreviousWinners
      applicableIbIds: $applicableIbIds
      isDemoAccountsOnly: $isDemoAccountsOnly
      isNewlyCreatedAccountsOnly: $isNewlyCreatedAccountsOnly
      memberId: $memberId
    ) {
      id
    }
  }
`
export const CLIENT_COMPETITION_MUTATION = gql`
  mutation($accountId: Int!, $competitionId: Int!, $memberId: Int!, $nickname: String, , $acceptTerms: String) {
    clientCompetition(accountId: $accountId, competitionId: $competitionId, memberId: $memberId, nickname: $nickname, acceptTerms: $acceptTerms){
      id
    }
  }
`
export const CLIENT_EXIT_COMPETITION_MUTATION = gql`
  mutation($accountId: Int!, $competitionId: Int!, $memberId: Int!) {
    clientExitCompetition(accountId: $accountId, competitionId: $competitionId, memberId: $memberId){
      id
    }
  }
`
