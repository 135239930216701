import React, {Component} from 'react'
import {Trans, withNamespaces} from 'react-i18next'
import {flowRight as compose, isEmpty, findKey, get, includes, map, find, some, omit, uniq, trim, every, isNil, size,
  isNull, reject, toLower, values} from 'lodash'
import AppContext from '../Common/contexts/AppContext'
import withStyles from '@mui/styles/withStyles'
import {Link, withRouter} from 'react-router-dom'
import Grid from '@mui/material/Grid'
import {scrollElementIntoView, isMobile} from '../../common/utils'
import ButtonsToolbar from './ButtonsToobar'
import CorporateStepper from './CorporateStepper'
import {Button, Checkbox, FormControl, FormControlLabel, Typography} from '@mui/material'
import messages from '../../assets/messages'
import {appropTestPoliticallyExposedReason, beneficiaryTypeOptions, corporateSteps, corporateFields, relationTypeOptions, corporateStatuses} from '@bdswiss/common-enums'
import Questions, {getStepQuestions} from './Questions'
import {validateCharacters, validateDOB, validateEmail, validatePhone, validateWebsite, validateAddress} from '../../common/utils/validations'
import {graphql} from 'react-apollo'
import {ADD_CORPORATE_DETAILS} from '../../graphql/mutations'
import {CLIENT_DATA_QUERY} from '../../graphql/queries'
import PepDialog from '../Common/PepDialog'
import NotificationBar from '../Common/NotificationBar'
import CloseIcon from '@mui/icons-material/HighlightOffOutlined'
import {AlertDialog} from '../Common/Dialog'

const gridScroll = 'scroll-grid'

const styles = theme => ({
  gridScroll: {
    paddingTop: 20
  },
  grid: {
    margin: '30px 0',
    borderBottom: `1px solid ${theme.palette.lightgrey.color}`
  },
  tableRow: {
    borderTop: `1px solid ${theme.palette.lightgrey.color}`,
  },
  editButton: {
    '&:hover': {backgroundColor:'transparent'}
  },
  addNewBtn: {
    marginRight: 10,
    [theme.breakpoints.down('md')]: {
      marginBottom: 10,
    }
  },
  closeIconDiv: {
    float: 'right',
    display: 'inline-block',
    marginTop: 6
  },
  closeIcon: {
    cursor: 'pointer',
    color: theme.palette.lightgrey.color,
    '&:hover': {color:theme.palette.red.color}
  },
  span: {
    fontWeight: 400
  },
  notificationBox: {
    marginTop: 20,
    position: 'relative'
  },
  infoCTA: {
    color: theme.palette.notificationBar.blueTextColor,
  },
})

class BeneficiariesDirectorsStep extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    const activeStep = corporateSteps.beneficiariesDirectors.rank
    this.state = {
      form: {},
      errors : {},
      questions: [],
      forceGetStepQuestions: false,
      loading: false,
      disabled: false,
      status: '',
      addNew: '',
      ownersAndDirectors: [],
      hidden: [],
      initialForm: {},
      formChanged: {},
      prefixChanged: {},
      activeStep,
      nextStep: find(corporateSteps, (step) => step.rank === activeStep + 1),
      prevStep: find(corporateSteps, (step) => step.rank === activeStep - 1)
    }
  }

  componentDidMount() {
    const {viewer} = this.props
    const {activeStep} = this.state
    getStepQuestions(viewer, activeStep, (state) => this.setState(state))
  }

  // We need to refetch the step questions because the addCorporateDetails mutation mutated the viewer
  // when we added a new beneficiary director
  componentDidUpdate(_, prevState) {
    const {viewer} = this.props
    const {activeStep, forceGetStepQuestions} = this.state
    const {forceGetStepQuestions: prevForceGetStepQuestions} = prevState
    forceGetStepQuestions && forceGetStepQuestions !== prevForceGetStepQuestions && getStepQuestions(viewer, activeStep, (state) => this.setState(state))
  }

  scrollUp(errors) {
    this.setState(() => scrollElementIntoView(isEmpty(errors) ? gridScroll : findKey(errors), 250))
  }

  handleChange (name, value, prefixChange, checkbox) {
    const {initialForm, form, prefixChanged} = this.state
    const pepRemoveReason = get(form, 'politicallyExposedReason') && name === corporateFields.politicallyExposedPerson.value && value === corporateFields.politicallyExposedPerson.options.no.value
    const company = name === corporateFields.beneficiaryType.value && value === beneficiaryTypeOptions.company.value

    if (get(corporateFields[name], 'isCountry') && !prefixChange) {
      if (corporateFields[name].isPrefix) {
        this.setState(state => ({prefixChanged: {...state.prefixChanged, [name]: true}}))
      } else if (!prefixChanged[name]) {
        const country = find(corporateFields[name].options, (country) => country.value === value)
        const nextPrefix = corporateFields[name].prefixChange
        if (nextPrefix && !prefixChanged[nextPrefix]) {
          this.setState(state => ({
            selectedCountry: {...state.selectedCountry, [nextPrefix]: country}}),
          this.handleChange (nextPrefix, `+${get(country,'callingCode', )}`, true))
        }
      }
    }

    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        [name]: value,
        politicallyExposedReason: (pepRemoveReason) ?  '' : (name === 'politicallyExposedReason') ? value : form.politicallyExposedReason,
        relationType: company ? [relationTypeOptions.shareholder.value] : (name === corporateFields.relationType.value) ? value : form.relationType
      },
      errors: {
        ...state.errors,
        [name]: !checkbox && !value,
      },
      formChanged:{
        ...state.formChanged,
        [name]: isEmpty(initialForm) || (value !== initialForm[name]),
      },
      disabledFields: [(name === corporateFields.beneficiaryType.value ? company : form.beneficiaryType === beneficiaryTypeOptions.company.value)
        ? corporateFields.relationType.key : ''],
      status: ''
    }))
  }

  removeSpecialCharacters(value) {
    return value.replace(/ /g,'')
  }

  handleSubmit() {
    const {form, activeStep, ownersAndDirectors, selectedOwner, hidden, addNew, formChanged} = this.state
    const exceptions = ['correspondenceAddress', 'beneficiaryType']
    const emptyForm = every(omit(form, ['correspondenceAddress']), (value) => isEmpty(value))
    const errors = {}
    const addressFields = ['city', 'correspondenceCity', 'cityAddress', 'street', 'streetAddress', 'correspondenceStreet']

    for (const field of Object.keys(form)) {
      if (corporateFields[field] && corporateFields[field].required(form, undefined, activeStep, hidden)) {
        if (includes(toLower(field), 'email')) {
          errors[field] = isEmpty(form[field]) || !validateEmail(form[field])
        } else if (field === 'firstName' || field === 'lastName') {
          const nameError = validateCharacters(trim(form[field]))
          errors[field] = !!nameError
        } else if (field === 'birthday') {
          errors[field] = !validateDOB(form[field])
        } else if (corporateFields[field].isPhone) {
          errors[field] = isEmpty(form[field]) || !validatePhone(form[field])
        } else if (field === 'website') {
          errors[field] = isEmpty(form[field]) || !validateWebsite(form[field])
        } else if (includes(addressFields, field)) {
          const addressError = validateAddress(form[field])
          errors[field] = !!addressError
        } else {
          errors[field] = isEmpty(form[field])
        }
      }
    }

    if (some(omit(errors, uniq(exceptions)))) {
      this.setState({errors},this.scrollUp(errors))
      return
    }
    let owners = isEmpty(ownersAndDirectors) ? [] : ownersAndDirectors
    if (!isNil(selectedOwner)) {
      map(owners, (value, key) => {
        if (selectedOwner === key) owners[key] = {...form, docName: get(formChanged, corporateFields.beneficiaryType.value) ? '' : value.docName}
      })
    } else {
      !emptyForm && owners.push(form)
    }

    owners = map(owners, (owner, key) => ({...owner,
      docName: !owner.docName ? this.removeSpecialCharacters(`${owner.companyName}${owner.firstName}${owner.lastName}${owner.beneficiaryType}${key}`) : owner.docName}))
    this.setState({forceGetStepQuestions: true, loading: true, status: '', ownersAndDirectors: owners, selectedOwner: null})
    this.props.addCorporateDetails({variables: {step: activeStep, answers: JSON.stringify(owners)}})
      .then((_) => {
        this.setState(state => ({
          forceGetStepQuestions: false,
          loading: false,
          status: '',
          showOwnerForm: addNew,
          form: {
            ...state.form,
            beneficiaryType: (addNew) ? beneficiaryTypeOptions.individual.value : ''
          },
          formSubmitted: true,
          addNew: ''
        }))
      })
      .catch((_) => this.setState({loading: false, forceGetStepQuestions: false, status: 'failure'}))
    this.setState({forceGetStepQuestions: false})
  }

  setOuterState (name, key ,value) {
    !isNil(value) ? this.setState(state => ({
      [name]: {
        ...state[name],
        [key]: value
      }
    })) : this.setState({[name]: key})
  }

  handleAgreePep(key) {
    const {form: {politicallyExposedReason}} = this.state
    if (!politicallyExposedReason)
      this.setState({pepCheckboxError: true})
    else
      this.setState(state => ({
        form: {
          ...state.form,
          politicallyExposedPerson: (politicallyExposedReason === appropTestPoliticallyExposedReason.none.value)
            ? corporateFields.politicallyExposedPerson.options.no.value : corporateFields.politicallyExposedPerson.options.yes.value,
        },
        alert: {
          ...state.alert,
          [key]: false
        },
        pepCheckboxError: false
      }))
  }

  handleDeleteChip (key, value) {
    this.setState(state => {
      const chipData = [...state.form[key]]
      const chipToDelete = chipData.indexOf(value)
      chipData.splice(chipToDelete, 1)
      return {
        form: {
          ...state.form,
          [key]: chipData
        }
      }
    })
  }

  removeOwner() {
    const {activeStep, ownersAndDirectors, removeOwner} = this.state
    const ownersLast = reject(ownersAndDirectors, (owner, key) => key === removeOwner)
    this.setState({forceGetStepQuestions: true, loading: true})
    this.props.addCorporateDetails({variables: {step: activeStep, answers: JSON.stringify(ownersLast)}})
      .then((_) => {
        this.setState({
          forceGetStepQuestions: false,
          loading: false,
          removeOwner: null,
          showRemovePopup: false,
          ownersAndDirectors: ownersLast})
      })
      .catch((_) => {
        this.setState({forceGetStepQuestions: false, loading: false, status: 'failure'})
      })
  }

  render() {
    const {classes, t, viewer, history} = this.props
    const {locale} = this.context
    const {showOwnerForm, selectedCountry, loading, disabled, questions, form, errors, addNew, ownersAndDirectors, alert, pepCheckboxError,
      formSubmitted, formChanged, disabledFields, selectedOwner, showRemovePopup, removeOwner, activeStep, nextStep, prevStep}= this.state
    const emptyForm = every(omit(form, ['correspondenceAddress']), (value) => isEmpty(value))
    const isFormChanged = includes(formChanged, true)
    const corporateStatus = get(viewer, 'corporateStatus')
    const ownerRemove = find(ownersAndDirectors, (owner, key) => key === removeOwner)
    const ownersDirectors = values(ownersAndDirectors)

    return (
      <React.Fragment>
        {!corporateStatus && <CorporateStepper activeStep={activeStep - 1} viewerStep={get(viewer, 'corporateSignupStep')}/>}
        {corporateStatus && <NotificationBar
          status="info"
          classes={{notificationBox: classes.notificationBox}}
          title={<Trans {...messages.corporateCannotChangeDetails}
            values={{corporateStatus: corporateStatus && corporateStatuses[corporateStatus].localization.t(locale)}}
            components={[<Link className={classes.infoCTA} to={'/support'}>support</Link>]}
          />}
        />}
        <Grid container id={gridScroll} spacing={3} className={classes.gridScroll}>
          {!showOwnerForm && <React.Fragment>
            <Grid item xs={12} sm={8}>
              <Typography variant='body1'>
                <Trans {...messages.addDirectorsInformation}/>
              </Typography>
              {ownersDirectors.length > 0 && <React.Fragment>
                <Grid item xs={12} className={classes.grid}>
                  {map(ownersDirectors, (value, key) => {
                    const company = value.beneficiaryType === beneficiaryTypeOptions.company.value
                    return <Grid container key={key} direction="row" justifyContent="center" alignItems="center" className={classes.tableRow}>
                      <Grid item xs={4}><Typography variant='body1'>{(company) ? value.companyName : `${value.firstName} ${value.lastName}`}</Typography></Grid>
                      <Grid item xs={4}>
                        <Typography variant='body1'>
                          {map(value.relationType, (type, key) => `${relationTypeOptions[type].localization.t(locale)}${(key+1 < size(value.relationType)) ? ', ' : ''}`)}
                          {(company) && ` (${t(messages.company.i18nKey, messages.company.defaults)})`}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Button className={classes.editButton} variant='text' color='primary' onClick={() => getStepQuestions(viewer, activeStep, (state) => this.setState(state), false, key)}>
                          <Trans {...messages[(corporateStatus) ? 'show' : 'edit']} />
                        </Button>
                        {!corporateStatus && <span className={classes.closeIconDiv}>
                          <CloseIcon onClick={() => this.setState({showRemovePopup: true, removeOwner: key})} className={classes.closeIcon}/>
                        </span>}
                      </Grid>
                    </Grid>})}
                </Grid>
              </React.Fragment>}
            </Grid>
            {!corporateStatus && <Grid item xs={12} sm={8}>
              {isEmpty(ownersAndDirectors) && <Button className={classes.addNewBtn} variant="outlined" size="large" color="primary"
                onClick={() => history.push(`/corporate/${prevStep.key}`)} fullWidth={isMobile()}>
                <Trans {...messages.back} />
              </Button>}
              <Button variant={ownersAndDirectors.length > 0 ? 'outlined' : 'contained'} size="large" color="primary" className={classes.addNewBtn}
                onClick={() => this.setState({showOwnerForm: true, selectedOwner: null})} fullWidth={isMobile()}>
                <Trans {...messages.addNewOwnerDirector}/>
              </Button>
              {ownersAndDirectors.length > 0 && <Button variant="contained" size="large" fullWidth={isMobile()} color="primary" onClick={() => {
                history.push(`/corporate/${nextStep.key}`)
              }}>
                <Trans {...messages.proceedButton}/>
              </Button>}
            </Grid>}
          </React.Fragment>}
          {showOwnerForm && <React.Fragment>
            <Questions
              setOuterState={(name, key ,value) => this.setOuterState(name, key ,value)}
              selectedCountry={selectedCountry}
              questions={questions}
              form={form}
              errors={errors}
              handleChange={(name, value, _, checkbox) => this.handleChange(name, value, _, checkbox)}
              step={activeStep}
              hidden={this.state.hidden}
              disabled={!!corporateStatus}
              handleDeleteChip={(key, value) => this.handleDeleteChip(key, value)}
              disabledFields={disabledFields}
            />
            {!emptyForm && <React.Fragment>
              {get(form, 'beneficiaryType') === beneficiaryTypeOptions.individual.value && <Grid item xs={12} sm={12}>
                <FormControl variant="standard">
                  <FormControlLabel
                    control={
                      <Checkbox
                        required
                        checked={!!addNew}
                        onChange={(e) => this.setState({addNew: e.target.checked ? 'checked' : ''})}
                        color="primary"
                        value={addNew}
                        disabled={!!corporateStatus}
                      />
                    }
                    label={t(messages.addNewOwnerDirectorNext.i18nKey, messages.addNewOwnerDirectorNext.defaults)}
                  />
                </FormControl>
              </Grid>}
              {isEmpty(selectedOwner) && form.beneficiaryType === beneficiaryTypeOptions.company.value && <Grid item xs={12}>
                <NotificationBar status="warning" classes={{notificationBox: classes.notificationBox}}>
                  <Trans {...messages.ownersCompanyNote} />
                </NotificationBar>
              </Grid>}
              {(!corporateStatus || !isNull(selectedOwner)) && <ButtonsToolbar
                disabled={disabled || loading || false}
                handleSubmit={() => (isFormChanged) ? this.handleSubmit() : (addNew)
                  ? this.setState({addNew: ''}, () => {
                    getStepQuestions(viewer, activeStep, (state) => this.setState(state), true)
                    this.scrollUp()
                    this.setState(state => ({
                      form: {
                        ...state.form,
                        beneficiaryType: beneficiaryTypeOptions.individual.value
                      },
                    }))
                  })
                  : this.setState({showOwnerForm: false, selectedOwner: null})}
                label={t(messages[(isFormChanged) ? 'submitAndProceed' : 'next'].i18nKey, messages[(isFormChanged) ? 'submitAndProceed' : 'next'].defaults)}
                disabledNext={!formSubmitted}
                onBack={() => {
                  getStepQuestions(viewer, activeStep, (state) => this.setState(state))
                  this.setState({showOwnerForm: false, selectedOwner: null})
                }}
                hideButton={(!isFormChanged && !addNew) || (corporateStatus && !isNull(selectedOwner))}
              />}
            </React.Fragment>}
          </React.Fragment>}
          <PepDialog open={get(alert, 'politicallyExposedPerson')} onChange={(e) => this.handleChange('politicallyExposedReason', e.target.value)}
            onAgree={() => this.handleAgreePep('politicallyExposedPerson')} pepCheckboxError={pepCheckboxError} form={form}/>
          {ownerRemove && <AlertDialog
            open={showRemovePopup}
            disagreeText={t(messages.close.i18nKey, messages.close.defaults)}
            agreeText={t(messages.remove.i18nKey, messages.remove.defaults)}
            onDisagree={()=> this.setState({showRemovePopup, removeOwner: null})}
            onAgree={()=> this.removeOwner()}
            onClose={()=> this.setState({showRemovePopup, removeOwner: null})}
          >
            <Typography variant="body1"><Trans {...messages.sureRemoveOwner}
              values={{owner: ownerRemove.companyName ? ownerRemove.companyName : `${ownerRemove.firstName} ${ownerRemove.lastName}`}}
              components={[<span className={classes.span}> </span>]}
            /></Typography>

          </AlertDialog>}
        </Grid>
      </React.Fragment>
    )
  }
}

export default compose(
  withNamespaces(),
  withRouter,
  withStyles(styles, {withTheme: true}),
  graphql(ADD_CORPORATE_DETAILS, {
    name: 'addCorporateDetails',
    options: {
      refetchQueries: [{query: CLIENT_DATA_QUERY}],
    }
  }),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {loading, error}, data}) => ({
      loading,
      error,
      viewer: get(data, 'viewer')
    })
  }),
)(BeneficiariesDirectorsStep)
