import React from 'react'
import {Route, Switch, withRouter, Redirect} from 'react-router-dom'
import ForexAccountOverview from './ForexAccountOverview'
import ForexAccountSettings from './ForexAccountSettings'
import ForexAccountDownloadTrader from './ForexAccountDownloadTrader'
import Plan from './Plan'
import CopyTrading from './CopyTrading'
import TradingCompetitions from '../TradingCompetitions'
import ProviderStats from './ProviderStats'

const ForexRoutes = ({match: {path,url}}) => (
  <Switch>
    <Redirect exact from={`${path}/transactions/history`} to={{pathname: '/transactions/history', state: {prevPath: url}} }/>
    <Redirect exact from={`${path}/transactions`} to={{pathname: '/transactions/:accountId/deposit', state: {prevPath: url}} }/>
    <Route exact path={`${path}/settings`} component={ForexAccountSettings} />
    <Route exact path={`${path}/plan`} component={Plan} />
    <Route exact path={`${path}/downloadTrader`} component={ForexAccountDownloadTrader} />
    <Redirect exact from={`${path}/tools/autochartist`} to={'/tools/autochartist'}/>
    {/*<Redirect exact from={`${path}/tools/tradeCompanion`} to={'/tools/tradeCompanion'}/>*/}
    <Redirect exact from={`${path}/tools/ebook`} to={'/tools/ebook'}/>
    <Redirect exact from={`${path}/tools`} to={'/tools'}/>
    <Route exact path={`${path}/copyTrading`} component={CopyTrading} />
    <Route exact path={`${path}/tradingCompetitions`} component={TradingCompetitions} />
    <Route exact path={`${path}/providerStats`} component={ProviderStats} />
    <Route path={`${url}/:parentPath/:childPath+`} render={({match}) => <Redirect to={`${url}/${match.params.parentPath}`} />} />
    <Route path={`${path}`} component={ForexAccountOverview} />
  </Switch>
)

export default withRouter(ForexRoutes)
