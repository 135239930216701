import React from 'react'
import {graphql} from 'react-apollo'
import {get, flowRight as compose} from 'lodash'
import {accountTypes, kycStatuses} from '@bdswiss/common-enums'
import {ACCOUNTS_QUERY, CLIENT_DATA_QUERY, LOCAL_DEPOSITOR_DETAILS} from '../../graphql/queries'
import IbAccount from './Ib'
import MamAccount from './Mam'
import PammAccount from './Pamm'
import ForexAccount from './Forex'
import BinaryAccount from './Binary'
import AffiliateAccount from './Affiliate'
import SMPrimeWalletAccount from './SMPrimeWallet'
import BitnukAccount from './Bitnuk'
import {isBitnukAccount, isIntroducingBrokerAccount} from '../../common/utils/accounts'
import {Loading} from '../Common/Loading'
import AppContext from '../Common/contexts/AppContext'
import {Redirect} from 'react-router-dom'
import {logEventCustomParams} from '../../common/utils/firebaseEvents'

class AccountView extends React.Component {
  static contextType = AppContext

  closeDialog() {
    const {history, account, viewer} = this.props
    const params = {
      method: 'return',
      entity: get(account, '__typename'),
      type: get(account, 'accountSubtype'),
      userId: get(viewer, 'id'),
    }
    logEventCustomParams('leftAccount', params)
    history.location.state && history.location.state.from && history.location.state.from === '/login' ?
      this.context.logout() : history.push('/accounts')
  }

  render() {
    const {loading, account, viewer, viewerLoading} = this.props
    if (loading || viewerLoading || !account) return (<Loading />)
    const accountCategory = get(accountTypes, [account.__typename, 'category'])
    const category = isIntroducingBrokerAccount(account) || isBitnukAccount(account)
      ? get(accountTypes, [account.__typename, 'subCategory']) : accountCategory

    if (isBitnukAccount(account) && get(viewer, 'kycStatus') !== kycStatuses.approved.value)
      return <Redirect to={{pathname: '/accounts', state: {showBitnukPopup: true}}} />

    switch (category) {
      case 'forexMt5':
      case 'forexMt4':
      case 'cent':
      case 'centMt5':
        return <ForexAccount />
      case 'mam':
        return <MamAccount />
      case 'tradeSmarter':
        return <BinaryAccount />
      case 'pammForex':
        return <PammAccount />
      case 'affiliate':
        return <AffiliateAccount />
      case 'ib':
        return <IbAccount />
      case 'smPrime':
        return <SMPrimeWalletAccount />
      case 'bitnuk':
        return <BitnukAccount />
      case 'ldWallet':
        return <Redirect from={'/accounts'} to="/transactions/local-depositor-transfer"/>
      default:
        return (<Loading />)
    }
  }
}

export default compose(
  graphql(ACCOUNTS_QUERY, {
    options: (props) => ({variables: {id: props.match.params.accountId}}),
    props: ({data: {error, loading}, data}) => ({
      error,
      loading,
      account: get(data, 'viewer.accounts.0'),
    }),
  }),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {loading}, data}) => ({
      viewerLoading: loading,
      viewer: get(data, 'viewer'),
    }),
  }),
  graphql(LOCAL_DEPOSITOR_DETAILS, {
    props: ({data: {error, loading}, data}) => ({
      errorLocalDepositor: error,
      loadingLocalDepositor: loading,
      localDepositorSubscriptions: get(data, 'viewer.localDepositorSubscriptions'),
      localDepositorSubscriptionsCount: get(data, 'viewer.localDepositorSubscriptionsCount'),
    }),
  }),
)(AccountView)
