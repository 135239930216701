import React, {Component} from 'react'
import {withNamespaces} from 'react-i18next'
import {get, size, isEmpty, flowRight as compose} from 'lodash'
import withStyles from '@mui/styles/withStyles'
import {CLIENT_DATA_QUERY} from '../../graphql/queries'
import {SEND_EMAIL_LOGIN_VERIFICATION_CODE_MUTATION, SEND_PHONE_VERIFICATION_CODE_MUTATION} from '../../graphql/mutations'
import {graphql} from 'react-apollo'
import {Loading} from '../Common/Loading'
import AwaitingVerification from './AwaitingVerification'
import PhoneVerification from './PhoneVerification'
import {storeItem, getItem} from '../../common/utils'
import {withRouter} from 'react-router-dom'
import {phoneVerificationTypes, securityTokenTypes} from '@bdswiss/common-enums'
import {logEventCustomParams} from '../../common/utils/firebaseEvents'

const styles = theme => ({
  div:{
    display: 'block',
    margin: theme.spacing(1),
    width: '100%'
  }
})

const errors = {
  'errors.common.rateLimit' : 'rateLimit',
  'errors.common.phoneVerificationRequestLimit' : 'phoneVerificationRequestLimit',
  'errors.common.emailLoginVerificationRequestLimit' : 'emailLoginVerificationRequestLimit',
  'errors.client.phoneIsUsed': 'usedPhone',
  'errors.client.emailIsUsed': 'usedEmail'
}

class Verification extends Component {
  constructor(props) {
    super(props)
    const {viewer: {isPhoneVerificationRequired, isEmailLoginVerificationRequired}} = this.props
    this.state = {
      form: {code: ''},
      errors: {},
      showPhone: false,
      loading: false,
      submitTime: 30,
      sendMessageTimers: {
        1: 30,
        2: 60,
        3: 180,
        4: 300,
        5: 21600,
      },
      verificationId: '',
      disableSend: false,
      toggleToPhone: !isEmailLoginVerificationRequired && isPhoneVerificationRequired,
      verificationSwitchButtonDisabled: !(isPhoneVerificationRequired && isEmailLoginVerificationRequired),
      smsVerificationError: '',
      emailLoginVerificationError: '',
      smsVerificationStatus: '',
      emailVerificationStatus: '',
      verificationObjectTypes: {
        sms: phoneVerificationTypes.sms.key,
        email: securityTokenTypes.emailLoginVerification.key,
      },
    }
  }

  componentDidMount() {
    const {loading, viewer: {isPhoneVerificationRequired, isEmailLoginVerificationRequired, isPhoneVerified, isEmailLoginVerified},
      history} = this.props
    const {toggleToPhone} = this.state
    if (toggleToPhone) logEventCustomParams('smsVerificationRequired', {userId: get(this.props, 'viewer.id')})
    else logEventCustomParams('emailLoginVerificationRequired')
    if (isPhoneVerified || isEmailLoginVerified || (!isPhoneVerificationRequired && !isEmailLoginVerificationRequired)) history.push('/')
    if (!loading && ((isPhoneVerificationRequired && !isPhoneVerified) || (isEmailLoginVerificationRequired && !isEmailLoginVerified))) {
      this.initVerificationObject()
    }
  }

  componentDidUpdate(_, prevState) {
    const {toggleToPhone:prevToggleToPhone} = prevState
    const {toggleToPhone} = this.state
    if (prevToggleToPhone !== toggleToPhone) {
      clearInterval(this.timer)
      this.initVerificationObject()
    }
  }

  initVerificationObject() {
    const {viewer:{id, phone, email}} = this.props
    const {toggleToPhone} = this.state
    const type = this.getVerificationObjectType()
    const verificationObject = getItem(`verificationObject${type}${id}`)
    const now = Math.floor(Date.now() / 1000)
    if ((toggleToPhone && !isEmpty(phone)) || (!toggleToPhone && !isEmpty(email))) {
      if (!verificationObject || now > verificationObject.available) {
        this.checkTime({...(toggleToPhone && {type: phoneVerificationTypes.sms.key})}, true)
      } else {
        this.setState({disableSend: true, submitTime: verificationObject.available - now})
        this.timer = setInterval(() => this.tick(verificationObject), 1000)
      }
    }
  }

  getVerificationObjectType() {
    const {toggleToPhone, verificationObjectTypes} = this.state
    return toggleToPhone ? verificationObjectTypes.sms : verificationObjectTypes.email
  }

  secondsToTime(seconds) {
    const date = new Date(null)
    date.setSeconds(seconds)
    return date.toISOString().substr(11, 8)
  }

  sendSmsVerification(params) {
    const {viewer: {phone}, sendSmsVerification} = this.props
    const variables = {
      phone: params.phone || phone,
      type: params.type
    }

    sendSmsVerification({variables})
      .then((res) => {
        logEventCustomParams('smsVerificationSuccessful', {
          userId: get(this.props, 'viewer.id'),
        })
        this.setState({verificationId: res['data']['sendSmsVerification']['id'], smsVerificationStatus: 'success', smsVerificationError: ''})
      })
      .catch((error, _) => {
        const graphQLError = get(error, 'graphQLErrors[0]')
        const smsVerificationError = (graphQLError && errors[graphQLError.code]) || 'unableToSend'
        logEventCustomParams('smsVerificationFailed', {
          reason: smsVerificationError,
          userId: get(this.props, 'viewer.id'),
        })
        this.setState({smsVerificationStatus: 'failure', smsVerificationError})
        this.checkTime(variables, false)
      })
  }

  sendEmailLoginVerification(_params) {
    const {sendEmailLoginVerification} = this.props
    sendEmailLoginVerification({})
      .then((res) => {
        logEventCustomParams('emailLoginVerificationSuccessful')
        if (res['data']['sendEmailLoginVerification']) this.setState({emailVerificationStatus: 'success', emailLoginVerificationError: ''})
        else throw new Error('unableToSendEmail')
      })
      .catch((error, _) => {
        const graphQLError = get(error, 'graphQLErrors[0]')
        const emailLoginVerificationError = (graphQLError && errors[graphQLError.code]) || 'unableToSendEmail'
        logEventCustomParams('emailLoginVerificationFailed', {reason: emailLoginVerificationError})
        this.setState({emailVerificationStatus: 'failure', emailLoginVerificationError})
        this.checkTime({}, false)
      })
  }

  sendVerification(params) {
    const {toggleToPhone} = this.state
    return toggleToPhone ? this.sendSmsVerification(params) : this.sendEmailLoginVerification(params)
  }

  componentWillUnmount () {
    clearInterval(this.timer)
  }

  tick(verificationObject) {
    const {submitTime} = this.state
    const now = Math.floor(Date.now() / 1000)
    if (now > verificationObject.available) {
      this.setState({disableSend: false})
      clearInterval(this.timer)
    }
    else
      this.setState({submitTime: (submitTime - 1)})
  }

  checkTime(variables, sendCode = true) {
    const {sendMessageTimers} = this.state
    const {viewer: {id}} = this.props
    const now = Math.floor(Date.now() / 1000)
    const type = this.getVerificationObjectType()
    const verificationObject = getItem(`verificationObject${type}${id}`)
      ? getItem(`verificationObject${type}${id}`)
      : {time: '', clicks: 0, validUntil: now + 86400}
    if (now > verificationObject.validUntil) {
      verificationObject.clicks = 0
      verificationObject.validUntil = now + 86400
    }
    if (verificationObject.clicks < size(sendMessageTimers))
      verificationObject.clicks++

    if (!sendCode) verificationObject.clicks = 3

    verificationObject.time = now
    verificationObject.available = now + sendMessageTimers[verificationObject.clicks]
    storeItem(`verificationObject${type}${id}`, verificationObject)
    clearInterval(this.timer)

    sendCode && this.sendVerification(variables)
    this.timer = setInterval(() => this.tick(verificationObject), 1000)
    this.setState({disableSend: true, submitTime: verificationObject.available - now})
  }

  handleChangeParent(name, value) {
    this.setState({[name]: value})
  }

  render() {
    const {loading, viewer:{phone}} = this.props

    if (loading) return <Loading />
    const {smsVerificationStatus, emailVerificationStatus, emailLoginVerificationError, smsVerificationError, showPhone,
      submitTime, disableSend, verificationId, toggleToPhone, verificationSwitchButtonDisabled, verificationObjectTypes} = this.state
    const phoneForce = isEmpty(phone) || showPhone
    return <React.Fragment>
      {!phoneForce &&
        <AwaitingVerification
          secondsToTime={this.secondsToTime.bind(this)}
          checkTime={this.checkTime.bind(this)}
          disableSend={disableSend}
          handleChangeParent={this.handleChangeParent.bind(this)}
          verificationStatus={toggleToPhone ? smsVerificationStatus : emailVerificationStatus}
          submitTime={submitTime}
          verificationError={toggleToPhone ? smsVerificationError : emailLoginVerificationError}
          verificationId={verificationId}
          showPhone={showPhone}
          toggleToPhone={toggleToPhone}
          verificationSwitchButtonDisabled={verificationSwitchButtonDisabled}
          verificationObjectTypes={verificationObjectTypes}
        />
      }
      {phoneForce &&
        <PhoneVerification
          showPhone={phoneForce}
          handleChangeParent={this.handleChangeParent.bind(this)}
          checkTime={this.checkTime.bind(this)}
          disableSend={disableSend}
          secondsToTime={this.secondsToTime.bind(this)}
          submitTime={submitTime}
        />
      }
    </React.Fragment>
  }
}

export default compose (
  withStyles(styles),
  withNamespaces(),
  withRouter,
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {loading, error}, data}) => ({
      loading,
      error,
      viewer: get(data, 'viewer')
    })
  }),
  graphql(SEND_PHONE_VERIFICATION_CODE_MUTATION, {
    name: 'sendSmsVerification',
    update: cache => {
      cache.writeData({data: {props: []}})
    },
  }),
  graphql(SEND_EMAIL_LOGIN_VERIFICATION_CODE_MUTATION, {
    name: 'sendEmailLoginVerification',
    update: cache => {
      cache.writeData({data: {props: []}})
    },
  }),
)(Verification)
