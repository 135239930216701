/*eslint-disable */
import React, {Component} from 'react'
import {graphql} from 'react-apollo'
import {get,isEmpty, flowRight as compose, size, map, replace, cloneDeep, omitBy, find} from 'lodash'
import Grid from '@mui/material/Grid'
import withStyles from '@mui/styles/withStyles'
import Table from '@mui/material/Table'
import {Link} from 'react-router-dom'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import classNames from 'classnames'
import {Trans, withNamespaces} from 'react-i18next'
import {Loading} from '../Common/Loading'
import messages from '../../assets/messages'
import {ACCOUNTS_QUERY, CLIENT_DATA_QUERY} from '../../graphql/queries'
import PageSubTitle from '../Common/PageSubTitle'
import config from '../../config'
import AppContext from '../Common/contexts/AppContext'
import TradingAlerts from '../ContentPages/Trading_Alerts'
import {isMobile} from '../../common/utils/browser'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import {hasVipOrRaw, hasOnlyWalletProductAccount} from '../../common/utils/accounts'
import Images from '../Common/Images'
import Badge from '@mui/material/Badge'
import moment from 'moment'
import {logEventCustomParams} from '../../common/utils/firebaseEvents'
import {Box} from '@mui/material'
import Button from '@mui/material/Button'
import * as enums from '@bdswiss/common-enums'

const styles = theme => ({
  textBlue:{
    color: theme.palette.primary.main
  },
  textGreen:{
    color: theme.palette.green.color
  },
  textRed:{
    color: theme.palette.red.color
  },
  textYellow:{
    color: theme.palette.yellow.color
  },
  uppercase: {
    textTransform: 'uppercase'
  },
  floatRight: {
    float: 'right'
  },
  divTable: {
    width: '100%',
    overflowX: 'auto',
  },
  capitalize: {
    textTransform: 'capitalize'
  },
  getLiveSignals: {
    textAlign: 'right',
    marginTop: 17,
    [theme.breakpoints.down('md')]: {
      marginTop: 5,
    },
  },
  textCentered: {
    textAlign: 'center',
  },
  dateTime: {
    textTransform: 'uppercase',
    color: theme.palette.secondary.main
  },
  liveSignals: {
    cursor: 'pointer',
  },
  signalResult1: {
    textTransform: 'uppercase',
    textAlign: 'start',
  },
  signalResult2: {
    textTransform: 'capitalize',
    textAlign: 'end',
  },
  signalDetails: {
    marginTop: 10,
  },
  list : {
    padding: 0,
  },
  listItem : {
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down('md')]:{
      borderBottom: '1px solid #ccc',
      '&:last-child': {
        borderBottom:'none'
      },
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    }
  },
  rootBadge: {
    alignItems: 'center'
  },
  badge: {
    width: 'fit-content',
    height: 16,
    backgroundColor:  get(theme.palette, 'mainDark.color') || theme.palette.red.color,
    color: theme.palette.red.textColor,
    position: theme.direction === 'rtl' ? 'relative' : 'unset',
    borderRadius: '32px',
    padding: 5,
    transform: 'none',
    marginLeft: 8,
  },
  invisible: {
    display: 'none'
  },
  tool: {
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: 16,
    borderRadius: 6,
    cursor: 'pointer',
    height: '100%',
    '&:hover': {
      borderColor: theme.palette.primary.main,
      '& span:not(.MuiBadge-badge)': {
        color: theme.palette.black.color
      },
    },
    transition: '0.2s',
  },
  tool_disabled: {
    filter: 'grayscale(100%)',
  },
  toolsImg: {
    maxHeight: 32
  },
  header: {
    paddingLeft: -0,
    marginLeft: -4,
  },
  toolMinHeight: {
    minHeight: 240,
    position: 'relative',
  },
})

class Tools extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    this.state = {
      liveSignals:[],
      closedSignals:[],
      vipAccount:false
    }
  }

  componentDidMount() {
    const {accounts} = this.props
    hasVipOrRaw(accounts) ? this.setState({vipAccount:true},this.fetchData) :
      this.setState({vipAccount:false},this.fetchData)
  }

  fetchData = () => {
    const {vipAccount} = this.state
    const {weblinks:{tradingSignalsClosed,tradingSignalsLive}} = config

    if (tradingSignalsClosed || tradingSignalsLive) {
      (!vipAccount)?
        fetch(tradingSignalsClosed)
          .then(res => res.json()).then (data => {
            const closedSignals = data[0]['latest_closed_signals'] && data[0]['latest_closed_signals'].slice(-10).reverse()
            this.setState({closedSignals})
          }).catch(() => []) :
        tradingSignalsLive ? fetch(tradingSignalsLive,  {cache: 'no-store'})
          .then(res => res.json()).then (data => {
            const liveSignals =  data[0]['live_signals'] && data[0]['live_signals'].slice(-10).reverse()
            this.setState({liveSignals})
            if (!liveSignals) {
              fetch(tradingSignalsClosed)
                .then(res => res.json()).then (data => {
                  const closedSignals = data[0]['latest_closed_signals'] && data[0]['latest_closed_signals'].slice(-10).reverse()
                  this.setState({closedSignals})
                })
                .catch(() => [])
            }
          }).catch(() => []) :
          fetch(tradingSignalsClosed)
            .then(res => res.json()).then(data => {
              const closedSignals = data[0]['latest_closed_signals'] && data[0]['latest_closed_signals'].slice(-10).reverse()
              this.setState({closedSignals})
            })
            .catch(() => [])
    }
  }

  renderClosedSignals() {
    const {classes} = this.props
    const {closedSignals} = this.state

    return (
      <TableBody key='closedSignalsBody'>
        {closedSignals.map(signal =>
          <TableRow key={signal.latest_closed_signals_pair+'_'+signal.latest_closed_signals_time_closed}>
            <TableCell className={classes.dateTime}>{signal.latest_closed_signals_time_closed}</TableCell>
            <TableCell>{signal.latest_closed_signals_pair}</TableCell>
            <TableCell className={signal.latest_closed_signals_action==='sell'?classes.textRed:classes.textGreen}>
              <span className={classes.uppercase}>{signal.latest_closed_signals_action}</span>
            </TableCell>
            <TableCell>{signal.latest_closed_signals_entry_price}</TableCell>
            <TableCell>
              {signal.latest_closed_signals_successful_trade === 'tp' ? '-' :
                signal.latest_closed_signals_closed_price}
            </TableCell>
            <TableCell>
              {signal.latest_closed_signals_successful_trade === 'sl' ? '-' :
                signal.latest_closed_signals_closed_price}
            </TableCell>
            <TableCell className={classes.textCentered}>
              { signal.latest_closed_signals_successful_trade!=='no'?
                (signal.latest_closed_signals_successful_trade === 'tp' ? <DoneIcon className={classes.textGreen}/>
                  : <CloseIcon className={classes.textRed}/>):''}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    )
  }

  renderClosedSignalsMobile() {
    const {classes} = this.props
    const {closedSignals} = this.state

    return (
      <List className={classes.list}>
        {closedSignals.map(signal =>
          <React.Fragment  key={signal.latest_closed_signals_pair+'_'+signal.latest_closed_signals_time_closed}>
            <ListItem className={classes.listItem}>
              <ListItemText>
                <Grid container justifyContent="space-between" direction="row">
                  <Grid item xs={6} className={classes.signalResult1}>
                    <Typography variant="body1">
                      {signal.latest_closed_signals_pair}{' | '}
                      <span className={signal.latest_closed_signals_action==='sell' ?
                        classes.textRed:classes.textGreen}>{signal.latest_closed_signals_action}</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={6} className={classes.signalResult2}>
                    { signal.latest_closed_signals_successful_trade!=='no' ?
                      (signal.latest_closed_signals_successful_trade === 'tp' ?
                        <Typography variant="body1" className={classes.textGreen}>
                          <Trans {...messages.successSignal} />
                        </Typography> : <Typography variant="body1" className={classes.textRed}>
                          <Trans {...messages.failureSignal} />
                        </Typography>):''}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="caption" className={classes.dateTime}>
                      {signal.latest_closed_signals_time_closed}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="space-between" direction="row" alignContent="center"
                  className={classes.signalDetails}>
                  <Grid item xs={4}>
                    <Typography variant="caption">
                      <Trans {...messages.entryPrice} />
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="caption">
                      <Trans {...messages.stopLossPrice} />
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="caption">
                      <Trans {...messages.takeProfitPrice} />
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="space-between" direction="row">
                  <Grid item xs={4}>
                    <Typography variant="body1">
                      {signal.latest_closed_signals_entry_price}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body1">
                      {signal.latest_closed_signals_successful_trade === 'sl' ? '-'
                        : signal.latest_closed_signals_closed_price}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body1">
                      {signal.latest_closed_signals_successful_trade === 'tp' ? '-'
                        : signal.latest_closed_signals_closed_price}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItemText>
            </ListItem>
          </React.Fragment>
        )}
      </List>
    )
  }

  renderLiveSignals() {
    const {classes} = this.props
    const {liveSignals} = this.state
    return (
      <TableBody key='liveSignalsBody'>
        {liveSignals.map(signal =>
          <TableRow key={signal.live_signals_pair}>
            <TableCell >{signal.live_signals_pair}</TableCell>
            <TableCell className={signal.live_signals_action==='sell'?classes.textRed:classes.textGreen}>
              <span className={classes.uppercase}>{signal.live_signals_action}</span></TableCell>
            <TableCell className={classes.capitalize}>
              {signal.live_signals_status!=='get_ready' ?
                (signal.live_signals_status === 'active' ?
                  <Typography variant="body1">
                    <Trans {...messages.activeSignal} />
                  </Typography> : <Typography variant="body1">
                    <Trans {...messages.closedSignal} />
                  </Typography>):
                (<Typography variant="body1">
                  <Trans {...messages.getReadySignal} />
                </Typography>)
              }
            </TableCell>
            <TableCell>{signal.live_signals_entry_price}</TableCell>
            <TableCell>{signal.live_signals_stop_loss}</TableCell>
            <TableCell>{signal.live_signals_take_profit}
              <DoneIcon className={classNames([classes.textGreen, classes.floatRight])} /></TableCell>
            <TableCell>{signal.live_signals_comments.replace(/<(?:.|\n)*?>/gm, '')}</TableCell>
          </TableRow>
        )}
      </TableBody>
    )
  }

  renderLiveSignalsMobile() {
    const {classes} = this.props
    const {liveSignals} = this.state

    return (
      <List className={classes.list}>
        {liveSignals.map(signal =>
          <React.Fragment key={signal.live_signals_pair}>
            <ListItem dense className={classes.listItem} >
              <ListItemText>
                <Grid container justifyContent="space-between" direction="row">
                  <Grid item xs={6} className={classes.signalResult1}>
                    <Typography variant="body1">
                      {signal.live_signals_pair}{' | '}<span className={signal.live_signals_action==='sell' ?
                        classes.textRed:classes.textGreen}>{signal.live_signals_action}</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={6} className={classes.signalResult2}>
                    { signal.live_signals_status!=='get_ready' ?
                      (signal.live_signals_status === 'active' ?
                        <Typography variant="body1" className={classes.textGreen}>
                          <Trans {...messages.activeSignal} />
                        </Typography> : <Typography variant="body1" className={classes.textRed}>
                          <Trans {...messages.closedSignal} />
                        </Typography>):
                      (<Typography variant="body1" className={classes.textYellow}>
                        <Trans {...messages.getReadySignal} />
                      </Typography>)
                    }
                  </Grid>
                </Grid>
                <Grid container justifyContent="space-between" direction="row" alignContent="center"
                  className={classes.signalDetails}>
                  <Grid item xs={4}>
                    <Typography variant="caption">
                      <Trans {...messages.entryPrice} />
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="caption">
                      <Trans {...messages.stopLossPrice} />
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="caption">
                      <Trans {...messages.takeProfitPrice} />
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="space-between" direction="row">
                  <Grid item xs={4}>
                    {signal.live_signals_entry_price}
                  </Grid>
                  <Grid item xs={4}>
                    {signal.live_signals_stop_loss}
                  </Grid>
                  <Grid item xs={4}>
                    {signal.live_signals_take_profit}
                  </Grid>
                </Grid>
                {signal.live_signals_comments && <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      <span className={classes.signalComment}><Trans {...messages.comments} />{': '}</span>
                      {signal.live_signals_comments.replace(/<(?:.|\n)*?>/gm, '')}
                    </Typography>
                  </Grid>
                </Grid>}
              </ListItemText>
            </ListItem>
          </React.Fragment>
        )}
      </List>
    )
  }

  showTradingAlerts() {
    const {classes} = this.props
    this.context.showNotification({
      type: 'trading-alerts',
      status: 'tradingAlerts',
      content: <TradingAlerts />,
      buttonMessage: <Trans {...messages.continue} />,
      subTitle:  <Trans {...messages.viaTelegram}
        components={[<span className={classes.textBlue}>Telegram</span>]} />,
      hideButton: true
    })
  }

  toolClick(tool, key) {
    const {history, clientData} = this.props
    const {locale} = this.context
    const params = {userId: get(clientData, 'id')}

    if(key === 'telegramVIPAlerts') {
      window.open(tool.url, '_blank')
      return
    }

    key === 'ebook'
      ? logEventCustomParams('forexEbookClicked', params)
      : logEventCustomParams(`${key}Clicked`, params)

    if (get(tool, 'internal')) {
      history.push(`/trading-tools/${key}`)
    } else {
      if (get(tool, 'url')) {
        if (get(tool, `${locale}Url`)) {
          window.open(tool[`${locale}Url`], '_blank')
        } else {
          window.open(tool.url, '_blank')
        }
      } else {
        window.open(replace(tool, '{lang}', locale), '_blank')
      }
    }
  }

  toolCardDisabledStateWithDepositAction(text, depositAmount) {
    const {classes, history} = this.props
    const handleClickDepositWithPreselectedAmount = () => {
      history.push({
        pathname: '/transactions/deposit',
        state: {
          preselectedCustom: {
            amount: depositAmount,
            currency: enums.currencies.USD.value,
          }
        }
      })
    }
    return (
      <>
        <Typography variant="body2" className={classes.textBlue}>
          <Trans {...messages.activate} />
        </Typography>
        <Typography variant="caption"> {text} </Typography>
        <Button variant={'contained'} sx={{
          mt:1,
          position: 'absolute',
          bottom: 16,
          width: "calc(100% - 32px)",
        }} fullWidth onClick={handleClickDepositWithPreselectedAmount}>
          <Trans {...messages.deposit} />
        </Button>
      </>
    )
  }

  toolCard(toolCheck, tool, key) {
    const {classes, t, clientData} = this.props
    const messageKey = messages[`${key}New`] || messages[key]
    const messageDescriptionKey = messages[`${key}DescriptionNew`] || messages[`${key}Description`]
    const badgeClasses = [classes.badge]
    const rootClasses = [classes.rootBadge]
    const newLabel = get(tool, 'newLabelUntil') && moment(tool.newLabelUntil).isSameOrAfter(moment(), 'day')
    if (!newLabel) badgeClasses.push(classes.invisible)
    const toolsSize = size(toolCheck)
    const smItem = toolsSize > 6 ? 3 : ((toolsSize % 3) === 0) ? 4 : (((toolsSize < 5 && toolsSize % 2) === 0) ? 6 : 4)

    let isDisabled = false
    const isHoveredDisabledToolCard = this.state[`isHoveredDisabledToolCard${key}`]

    if (key === 'tradingCentral' && !clientData?.tradingCentralEnabled) {
      isDisabled = true
    }
    if(key === 'telegramVIPAlerts' && !clientData?.hasTelegram) {
      isDisabled = true
    }
    const handleMouseEnter = () => {
      if (isDisabled) this.setState({[`isHoveredDisabledToolCard${key}`]: true})
    }
    const handleMouseLeave = () => {
      if (isDisabled) this.setState({[`isHoveredDisabledToolCard${key}`]: false})
    }

    return (
      <Grid item key={key} xs={6} sm={smItem}
        onClick={() => {
          if (isDisabled) return
          this.toolClick(tool, key)
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Grid className={classNames(classes.tool,toolsSize > 3 && classes.toolMinHeight)}>
          {
            isHoveredDisabledToolCard
              ?
                <div>
                  { key === 'tradingCentral' && this.toolCardDisabledStateWithDepositAction(<Trans {...messages.activateTradingCentral} />, 250) }
                  { key === 'telegramVIPAlerts' && this.toolCardDisabledStateWithDepositAction(<Trans {...messages.activateTelegramVIPAlerts} />, 500) }
                </div>

              :
              <Box className={classNames(isDisabled && classes.tool_disabled)}>
                <Grid item xs={12}>
                  <img src={Images[`${key}Tool.png`]} alt={key} className={classes.toolsImg}/>
                </Grid>
                <Grid item xs={12}>
                  <Badge badgeContent={t(messages.new.i18nKey, messages.new.defaults)}
                    classes={{root: classNames(rootClasses), badge: classNames(badgeClasses)}}>
                    <Typography variant="body2" className={classes.textBlue}>
                      <Trans {...messageKey} />
                    </Typography>
                  </Badge>
                </Grid>
                <Grid item xs={12} sx={{
                  mt: 1
                }}>
                  <Typography variant="caption">
                    <Trans {...messageDescriptionKey} />
                  </Typography>
                </Grid>
              </Box>
          }
        </Grid>
      </Grid>
    )
  }

  render() {
    const {classes, loading, accounts, clientData, clientLoading} = this.props
    const {vipAccount, liveSignals} = this.state
    const {featuresConfig:{skipWaitingList, tools}} = config
    if (loading || clientLoading) return <Loading />
    const onlyWalletAccount = hasOnlyWalletProductAccount(accounts)
    const {weblinks: {tradingSignalsClosed, tradingSignalsLive}} = config
    const toolCheck = omitBy(cloneDeep(tools), (tool)=> !!(tool.clientDataMandatory ? find(tool.clientDataMandatory, (data) => !clientData[data]) : onlyWalletAccount))

    return (
      <React.Fragment>
        <Grid container spacing={isMobile() ? 2 : 3} sx={{pl: {xs: 2, sm: 0}, pr: {xs: 2, sm: 0}, pb: {xs: 2, sm: 0}}}>
          <Grid item xs={12}>
            <Grid container spacing={isMobile() ? 2 : 3}>
              {map(toolCheck, (tool, key) => this.toolCard(toolCheck, tool, key))}
            </Grid>
          </Grid>
        </Grid>

        {!onlyWalletAccount && skipWaitingList && (tradingSignalsLive || tradingSignalsClosed) && <Grid container sx={{mt:1, p: {xs: 2, sm: 0}}}>
          <Grid item xs={6} sm={8}>
            <PageSubTitle><Trans {...messages.tradingSignals} /></PageSubTitle>
          </Grid>
          {!hasVipOrRaw(accounts) && <Grid item xs={6} sm={4} className={classes.getLiveSignals}>
            <Link to="/vip-account" onClick={()=> logEventCustomParams('blackAccountEnquiry', {
              userId: get(clientData, 'id'),
            })}>
              <Typography variant='body2' className={classNames(classes.textBlue, classes.liveSignals)}>
                <Trans {...messages.getLiveSignals} />
              </Typography>
            </Link>
          </Grid>}
        </Grid>}

        {!onlyWalletAccount && !isMobile() && (tradingSignalsLive || tradingSignalsClosed) && <div className={classes.divTable}>
          {tradingSignalsClosed && (!vipAccount || (vipAccount && isEmpty(liveSignals))) &&
                    [<Table key='closedSignalsTable'>
                      <TableHead key='closedSignalsHead'>
                        <TableRow>
                          <TableCell><Trans {...messages.timeClosed} /></TableCell>
                          <TableCell><Trans {...messages.pair} /></TableCell>
                          <TableCell><Trans {...messages.action} /></TableCell>
                          <TableCell><Trans {...messages.entryPrice} /></TableCell>
                          <TableCell><Trans {...messages.stopLossPrice} /></TableCell>
                          <TableCell><Trans {...messages.takeProfitPrice} /></TableCell>
                          <TableCell className={classes.textCentered}><Trans {...messages.successfulTrade} /></TableCell>
                        </TableRow>
                      </TableHead>
                      {this.renderClosedSignals()}
                    </Table>
                    ]}
          {tradingSignalsLive && vipAccount && !isEmpty(liveSignals) &&
                    [<Table  key='liveSignalsTable' className={classes.table}>
                      <TableHead key='liveSignalsHead'>
                        <TableRow>
                          <TableCell><Trans {...messages.pair} /></TableCell>
                          <TableCell><Trans {...messages.action} /></TableCell>
                          <TableCell><Trans {...messages.status} /></TableCell>
                          <TableCell><Trans {...messages.entryPrice} /></TableCell>
                          <TableCell><Trans {...messages.stopLossPrice} /></TableCell>
                          <TableCell><Trans {...messages.takeProfitPrice} /></TableCell>
                          <TableCell><Trans {...messages.comments} /></TableCell>
                        </TableRow>
                      </TableHead>
                      {this.renderLiveSignals()}
                    </Table>
                    ]}
        </div>}
        {!onlyWalletAccount && isMobile() && tradingSignalsClosed && (!vipAccount || (vipAccount && isEmpty(liveSignals))) && this.renderClosedSignalsMobile()}
        {!onlyWalletAccount && isMobile() && tradingSignalsLive && vipAccount  && !isEmpty(liveSignals)  && this.renderLiveSignalsMobile()}
      </React.Fragment>
    )
  }
}

export default compose(
  withStyles(styles),
  withNamespaces(),
  graphql(ACCOUNTS_QUERY, {
    props: ({data: {error, loading}, data}) => ({
      error,
      loading,
      accounts: get(data, 'viewer.accounts', [])
    })
  }),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {loading, error}, data, ownProps: {history}}) => ({
      clientLoading: loading,
      error,
      history,
      clientData: get(data, 'viewer'),
    }),
    options: {
      fetchPolicy: 'network-only',
    },
  }),
)(Tools)
