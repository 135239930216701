import React from 'react'
import {isBoolean, omit} from 'lodash'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import withStyles from '@mui/styles/withStyles'
import classNames from 'classnames'

const styles = theme => ({
  root: {
    display: 'inline-block',
    alignItems: 'center',
    position: 'relative',
    marginTop: 0,
    [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: 15,
    '&.success': {
      backgroundColor: theme.palette.green.color,
      '&:hover': {
        backgroundColor: theme.palette.green.color,
        boxShadow: 'none',
      },
    },
    '&.failure': {
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.color,
        boxShadow: 'none',
      },
    },
    '&.progress':{
      color: theme.palette.primary.main,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      [theme.direction === 'rtl' ? 'marginRight' : 'marginLeft']: -12,
    },
    '&.noMargin':{
      margin: 0
    },
    '&.noButtonStyle':{
      backgroundColor: 'transparent',
      textAlign: 'inherit'
    },
    '&.noPadding':{
      padding: 0
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: 10,
    },
    [theme.breakpoints.down('sm')]: {
      [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: 0,
    },
  },
})

const LoadingButton = (props) => {
  const {disabled, children, onClick, classes, status, hideProgressBar, fullWidth, className, noMargin, size, variant, noPadding, noBackgroundColor} = props
  return (
    <Button
      variant={variant || 'contained'}
      color="primary"
      size={size || 'large'}
      disabled={disabled}
      onClick={onClick}
      fullWidth={fullWidth}
      className={classNames(classes.root, status, className, (noMargin) ? 'noMargin' : '', (noPadding) ? 'noPadding' : '',
        isBoolean(noBackgroundColor) ? (noBackgroundColor ? 'success' : 'failure') : '')}
      {...omit(props, ['hideProgressBar', 'noMargin', 'className', 'disabled', 'noPadding'])}
    >{children} {disabled &&!hideProgressBar && <CircularProgress size={24} className={classNames(classes.root, 'progress')} />}</Button>
  )
}

LoadingButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  status: PropTypes.oneOf(['', 'success', 'failure']),
  hideProgressBar: PropTypes.bool,
  fullWidth: PropTypes.bool,
  noMargin: PropTypes.bool,
  noPadding: PropTypes.bool,
}

export default withStyles(styles)(LoadingButton)
