import React from 'react'
import config from '../../../config'
import {getItem, findCompany, getCurrentTheme} from '../../../common/utils'
import {get} from 'lodash'

const AppContext = React.createContext({
  showNotification: () => {},
  locale: getItem('locale', 'en'),
  themePreference: getCurrentTheme(),
  company: config.key,
  companyObject: findCompany(get(config, 'forcedCompany')),
})

export default AppContext
