import React from 'react'
import PropTypes from 'prop-types'
import {Skeleton} from '@mui/material'
import {flowRight as compose} from 'lodash'
import withStyles from '@mui/styles/withStyles'

const styles = (theme) => ({
  root: {
    borderRadius: '20px',
  }
})

class LoadingWrapper extends React.Component {
  render() {
    const {classes, children, loading, variant} = this.props
    return (loading
      ? <Skeleton width={this.props.fullWidth ? '100%' : ''} classes={{root: classes.root}} variant={variant}>
        {children}
      </Skeleton>
      : children
    )
  }
}

LoadingWrapper.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
  }),
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['circular', 'rectangular', 'rounded', 'text', 'string'])
}

LoadingWrapper.defaultProps = {
  loading: true,
  variant: 'text'
}

export default compose(
  withStyles(styles, {withTheme: true}),
)(LoadingWrapper)
