import React, {Component} from 'react'
import {flowRight as compose, get, isEmpty, map} from 'lodash'
import {withNamespaces, Trans} from 'react-i18next'
import Grid from '@mui/material/Grid'
import withStyles from '@mui/styles/withStyles'
import Typography from '@mui/material/Typography'
import messages from '../../../assets/messages'
import Button from '@mui/material/Button'
import classNames from 'classnames'
import AppContext from '../../Common/contexts/AppContext'
import Dialog from '@mui/material/Dialog'
import Images from '../../Common/Images'
import CloseIcon from '@mui/icons-material/Close'
import Star from '@mui/icons-material/Star'
import {accountSubtypes} from '@bdswiss/common-enums'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import {isMobile} from '../../../common/utils'
import {Fab} from '@mui/material'
import {logEventCustomParams} from '../../../common/utils/firebaseEvents'
import config from '../../../config'
import {Redirect} from 'react-router-dom'

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.common.black,
    maxWidth: 1000,
    margin:'0 auto',
    position: 'relative',
  },
  title:{
    fontSize: '3em',
    paddingBottom: 25,
    [theme.breakpoints.down('md')]: {
      paddingTop: 10,
      lineHeight:'2.5rem'
    },
  },
  whiteText: {
    color: theme.palette.secondary.light
  },
  captionText: {
    paddingBottom: 10,
    color: '#B9C3C3'
  },
  buttonClose: {
    background: 'none',
    '&:hover':{
      backgroundColor: theme.palette.common.black,
    },
    position: 'absolute',
    top: 0,
    right: 0,
    [theme.breakpoints.down('md')]: {
      top: -5,
      right: -5,
    },
  },
  button: {
    boxShadow: 'none',
    backgroundColor: '#9c8258',
    '&:hover':{
      backgroundColor:'#b79a6c'
    },
  },
  paper: {
    backgroundColor: theme.palette.common.black,
  },
  closeButtonDiv:{
    textAlign: `${theme.direction === 'rtl' ? 'left' : 'right'}`
  },
  greyText:{
    color: '#778281',
    fontWeight: 400
  },
  boldText:{
    fontWeight: 500
  },
  goldText:{
    color: '#9c8258'
  },
  img:{
    maxWidth: 45
  },
  upperCase:{
    textTransform:'uppercase'
  },
  closeIcon:{
    fontSize: 35,
    [theme.breakpoints.down('md')]: {
      fontSize: 25,
    },
  },
  subtitle:{
    paddingBottom: 7,
    lineHeight: 1.3,
    fontWeight:600,
    color: theme.palette.secondary.light,
    textTransform:'uppercase'
  },
  mb15:{
    marginBottom:15
  },
  mb70:{
    marginBottom:70
  },
  cardRoot: {
    backgroundColor: theme.palette.common.black,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    color: theme.palette.secondary.light,
    border: '1px solid #9c8258',
    '&:hover':{
      backgroundColor: 'transparent',
    },
    padding:0,
  },
  cardHeader:{
    padding:0
  },
  pointContent:{
    lineHeight: 1.3,
    fontWeight:400,
    color: theme.palette.secondary.light,
  },
  textCenter: {
    textAlign: 'center',
  },
  upgradeText: {
    maxWidth: 300
  },
  fontBigger: {
    fontSize: 18,
  },
  lightBold: {
    fontWeight: 400
  },
  mobileLeftText: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'left'
    },
  }
})

class BlackAccount extends Component {
  static contextType = AppContext

  openAccount() {
    const {history} = this.props
    logEventCustomParams('blackAccountCTAClicked', {
      userId: get(this.context, 'clientId'),
    })
    return history.push({pathname:'/accounts/add-account'})
  }
  render() {
    const {classes, history, location, t} = this.props
    const closePath = (location.search && location.search === '?close') ? '/' : (get(location, 'state.from') ? location.state.from : null)
    const {companyObject} = this.context
    const {vipAccountKeys} = config
    const defaultKeys = ['vipAccountAdvantage', 'vipAccountAdvantage2', 'vipAccountAdvantage3', 'vipAccountAdvantage4', 'vipAccountAdvantage5', 'vipAccountAdvantage6',
      'vipAccountAdvantage7', 'vipAccountAdvantage8']
    const allKeys = !isEmpty(vipAccountKeys) ? vipAccountKeys : defaultKeys

    if (config.common.deprecateVipLP) {
      return <Redirect to={{pathname: '/priority', state: location.state}} />
    }

    return (
      <Dialog
        fullScreen
        open={true}
        PaperProps={{classes: {root: classes.paper}}}
        disableEnforceFocus={true}
      >
        <Grid container spacing={3} className={classNames(classes.root)} align='center'>
          <Fab className={classNames(classes.buttonClose,classes.goldText)} onClick={() => closePath ? history.push(closePath) : history.goBack()}>
            <CloseIcon className={classes.closeIcon}/>
          </Fab>
          <Grid item xs={12} className={classes.goldText}>
            <Star /><Star /><Star /><Star /><Star />
          </Grid>
          <Grid item xs={12} className={classes.textCenter}>
            <Typography className={classNames(classes.title, classes.goldText, classes.boldText, classes.upperCase)}>
              <Trans {...messages.joinVipClubTitle} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid item sm={12} xs={12}>
              <Typography variant="body1" className={classNames(classes.lightBold, classes.upgradeText, classes.whiteText, classes.fontBigger)}>
                <Trans {...messages.upgradeAccountAccess}/>
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.mb15}>
            <Grid item sm={12} xs={12}>
              <Typography variant="body1" className={classes.whiteText}>
                <Trans {...messages.vipAccountPrivilagedText2} values={{company:companyObject.brandLabel}}/>
              </Typography>
            </Grid>
          </Grid>
          <Grid item md={12} xs={12} className={classes.mb70}>
            <Grid container spacing={isMobile() ? 5 : 3}>
              {map(allKeys, (vipKey) => <Grid item md={3} xs={12} key={vipKey}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <img className={classNames(classes.img)} src={Images[`${vipKey}.png`]} alt="vipKey" />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="caption" className={classNames(classes.subtitle)} align='left'>
                      <Trans {...messages[vipKey]}/>
                    </Typography>
                    <Typography variant="caption" className={classes.pointContent} align='left'>
                      <Trans {...messages[`${vipKey}Text`]} values={{company: get(companyObject, 'brandLabel')}}/>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>)}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item sm={12} xs={12}>
              <Typography variant="body1" className={classNames(classes.lightBold, classes.fontBigger, classes.whiteText)}>
                <Trans {...messages.takeAdvantage}/>
              </Typography>
            </Grid>
          </Grid>
          <Grid item md={12} xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} align='center'>
                <Card className={classes.cardRoot}>
                  <CardHeader
                    title={t(messages.vipAccountTitle.i18nKey)}
                    titleTypographyProps={{variant:'h3', align: 'center'}}
                    classes={{root: classes.cardHeader, title: classNames(classes.whiteText, classes.boldText)}}
                  />
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item xs={6} align='right'>
                        <Typography variant="body1" className={ classNames(classes.whiteText, classes.boldText)}>
                          <Trans {...messages.spreadsFrom} />
                        </Typography>
                      </Grid>
                      <Grid item xs={6} align='center'>
                        <Typography variant="body1" className={ classNames(classes.whiteText, classes.boldText, classes.mobileLeftText)}>1.1</Typography>
                      </Grid>
                      <Grid item xs={6} align='right'>
                        <Typography variant="body1" className={ classNames(classes.whiteText, classes.boldText)}>
                          <Trans {...messages.commission} />
                        </Typography>
                      </Grid>
                      <Grid item xs={6} align='center'>
                        <Typography variant="body1" className={ classNames(classes.whiteText, classes.boldText, classes.mobileLeftText)}>$0</Typography>
                      </Grid>
                      <Grid item xs={6} align='right'>
                        <Typography variant="body1" className={ classNames(classes.whiteText, classes.boldText)}>
                          <Trans {...messages.from} />
                        </Typography>
                      </Grid>
                      <Grid item xs={6} align='center'>
                        <Typography variant="body1" className={ classNames(classes.whiteText, classes.boldText, classes.mobileLeftText)}>$3,000</Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          size="medium"
                          onClick={() => this.openAccount()}
                          className={classNames(classes.button,classes.whiteText)}
                        >
                          <Trans {...messages.openAccountType} values={{type: accountSubtypes.black.label}}/>
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" className={classNames(classes.greyText,classes.boldText)}>
                          <Trans {...messages.yourCapitalRisk} />
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} md={6} align='center'>
                <Card className={classes.cardRoot}>
                  <CardHeader
                    title={t(messages.rawAccountTitle.i18nKey)}
                    titleTypographyProps={{variant:'h3', align: 'center'}}
                    classes={{root: classes.cardHeader, title: classNames(classes.whiteText, classes.boldText)}}
                  />
                  <CardContent className={classes.cardContent}>
                    <Grid container spacing={1}>
                      <Grid item xs={6} align='right'>
                        <Typography variant="body1" className={ classNames(classes.whiteText, classes.boldText)}>
                          <Trans {...messages.spreadsFrom} />
                        </Typography>
                      </Grid>
                      <Grid item xs={6} align='center'>
                        <Typography variant="body1" className={ classNames(classes.whiteText, classes.boldText, classes.mobileLeftText)}>0.0</Typography>
                      </Grid>
                      <Grid item xs={6} align='right'>
                        <Typography variant="body1" className={ classNames(classes.whiteText, classes.boldText)}>
                          <Trans {...messages.commission} />
                        </Typography>
                      </Grid>
                      <Grid item xs={6} align='center'>
                        <Typography variant="body1" className={ classNames(classes.whiteText, classes.boldText, classes.mobileLeftText)}>$5</Typography>
                      </Grid>
                      <Grid item xs={6} align='right'>
                        <Typography variant="body1" className={ classNames(classes.whiteText, classes.boldText)}>
                          <Trans {...messages.from} />
                        </Typography>
                      </Grid>
                      <Grid item xs={6} align='center'>
                        <Typography variant="body1" className={classNames(classes.whiteText, classes.boldText, classes.mobileLeftText)}>$5,000</Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions className={classes.cardActions}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          size="medium"
                          onClick={() => this.openAccount()}
                          className={classNames(classes.button,classes.whiteText)}
                        >
                          <Trans {...messages.openAccountType} values={{type: accountSubtypes.raw.label}}/>
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" className={classNames(classes.greyText,classes.boldText)}>
                          <Trans {...messages.yourCapitalRisk} />
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
)(BlackAccount)
