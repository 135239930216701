import React from 'react'
import PropTypes from 'prop-types'
import {map, get} from 'lodash'
import {currencies} from '@bdswiss/common-enums'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import withStyles from '@mui/styles/withStyles'
import {getAccountLabel, getAccountSubtype, isCentAccount, isCentAccountBasedOnCurrency} from '../../common/utils/accounts'
import {isMobile, getFormattedAmount} from '../../common/utils'
import classNames from 'classnames'

const styles = theme => ({
  root:  {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 10,
    paddingBottom: 10
  },
  subnote: {
    fontSize: 14,
    textAlign: 'right',
  },
  textRight: {
    textAlign: 'right'
  },
  greyText: {
    color: theme.palette.grey[500],
  }
})

const AccountSelect = withStyles(styles, {withTheme: true})((props) => {
  const {t, accounts, classes, locale, ...otherProps} = props
  return (
    <FormControl variant="standard" fullWidth={true}>
      <TextField
        variant="standard"
        inputProps={{className: classNames(!isMobile() && classes.root)}}
        select
        {...otherProps}>
        {
          map(accounts, (a) => {
            let formattedPendingAmount, usdForCentAccount
            if (get(a, 'pendingAmount', 0) > 0) {
              formattedPendingAmount = getFormattedAmount({locale, amount: a.pendingAmount, currency: a.currency})
            }
            if (isCentAccount(a) || isCentAccountBasedOnCurrency(a)) {
              usdForCentAccount = getFormattedAmount({locale, amount: a.balance / currencies.CUD.baseCurrencyRate, currency: currencies.CUD.baseCurrency})
            }
            const formattedBalance = getFormattedAmount({locale, amount: a.balance, currency: a.currency})
            const hasBalance = a.balance || a.balance >= 0
            const accountLabel = getAccountLabel(a, locale, getAccountSubtype(get(a,'accountSubtype')), t)
            return (
              <MenuItem value={a.id} key={a.id} className={classNames(!isMobile() && classes.root)} >
                {!isMobile() ? <React.Fragment><div>{accountLabel} <span className={classes.greyText}>- {a?.remoteId || a?.id}</span></div>
                  {hasBalance && <div className={classes.greyText}>
                    {formattedBalance}
                    {usdForCentAccount && !formattedPendingAmount && <div className={classes.subnote}>{usdForCentAccount}</div>}
                    {formattedPendingAmount && <div className={classes.subnote}>(-{formattedPendingAmount})</div>}
                  </div>}
                </React.Fragment>
                  : <Grid container>
                    <Grid item xs={8}>
                      <div>{accountLabel}</div>
                      <div><span className={classes.greyText}>{a?.remoteId}</span></div>
                    </Grid>
                    <Grid item xs={4} className={classes.textRight}>
                      {hasBalance && <div className={classes.greyText}>
                        {formattedBalance}
                        {usdForCentAccount && !formattedPendingAmount && <div className={classes.subnote}>{usdForCentAccount}</div>}
                        {formattedPendingAmount && <div className={classes.subnote}>(-{formattedPendingAmount})</div>}
                      </div>}
                    </Grid>
                  </Grid>
                }
              </MenuItem>
            )
          })
        }
      </TextField>
    </FormControl>
  )
})

AccountSelect.propTypes = {
  locale: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  accounts: PropTypes.arrayOf(PropTypes.shape({
    id:  PropTypes.number.isRequired,
    remoteId :  PropTypes.string.isRequired,
    balance: PropTypes.number,
    currency: PropTypes.string.isRequired,
    __typename: PropTypes.string.isRequired
  })).isRequired,
}

export default (AccountSelect)
