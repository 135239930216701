import {flowRight as compose, get, map} from 'lodash'
import messages from '../../../../assets/messages'
import {Trans, withNamespaces} from 'react-i18next'
import React, {useContext, useEffect, useState} from 'react'
import SelectDocument from '../../../Settings/Documents/SelectDocument'
import Images from '../../../Common/Images'
import * as enums from '@bdswiss/common-enums'
import AppContext from '../../../Common/contexts/AppContext'
import LoadingButton from '../../../Common/LoadingButton'
import {graphql} from 'react-apollo'
import {getIbIds, putFile} from '../../../../common/utils'
import classNames from 'classnames'
import {
  SIGN_COMPETITION_FILE_URL_MUTATION,
  UPSERT_COMPETITION_CREATED_BY_CLIENT
} from '../../../../graphql/competitions/mutations'
import withStyles from '@mui/styles/withStyles'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import {Add, Close} from '@mui/icons-material'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import {MenuItem} from '@mui/material'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3'
import * as dateFnsLocales from 'date-fns/locale'
import {fnsLocales} from '../../../../common/utils/uioptions'
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers'
import FormHelperText from '@mui/material/FormHelperText'
import moment from 'moment'
import LogoDefaultForCompetition from './SubComponents/LogoDefaultForCompetition'
import AlertForSubmittedForm from './SubComponents/AlertForSubmittedForm'

const styles = theme => ({
  form: {
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: 6,
    padding: 24,
    marginTop: 30
  },
  form__input_margin_15: {
    marginBottom: 15,
  },
  form__input_margin_30: {
    marginBottom: 30,
  },
  form__title_center: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center'
  },
  for__input_minHeight_90: {
    minHeight: 90,
  },
  form__description: {
    marginTop: 30,
  },
  button__submit: {
    marginTop: 30
  }
})


function TradingCompetitionForm(props) {
  const {
    classes,
    signCompetitionFileUrl,
    upsertCompetitionCreatedByClient,
    competition = null,
    handleUpdate
  } = props
  const context = useContext(AppContext)

  const [form, setForm] = useState({
    name: '',
    icon: '',
    termsLink: '',
    startDate: '',
    endDate: '',
    prizes: {},
    rules: [],
    acceptPreviousWinners: competition ? false : null,
    isDemoAccountsOnly: competition ? false : null,
    isNewlyCreatedAccountsOnly: competition ? false : null
  })

  const [uploadedFile, setUploadedFile] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isReadOnly, setIsReadOnly] = useState(false)
  const [fileError, setFileError] = useState('')
  const [longFieldsErrors, setLongFieldsErrors] = useState({
    isLongName: false,
    isLongTermsLink: false,
  })
  const [errors, setErrors] = useState({
    name: false,
    startDate: false,
    endDate: false,
    prizes: false,
    rules: false,
    isNoRules: false,
    acceptPreviousWinners: false,
    isDemoAccountsOnly: false,
    isNewlyCreatedAccountsOnly: false,
  })

  useEffect(() => {
    if (competition) {
      setForm({
        name: competition.name,
        icon: competition.icon,
        termsLink: competition.termsLink,
        startDate: competition.startDate,
        endDate: competition.endDate,
        prizes: JSON.parse(competition.prizes),
        rules: JSON.parse(competition.rules),
        acceptPreviousWinners: competition.acceptPreviousWinners,
        isDemoAccountsOnly: competition.isDemoAccountsOnly,
        isNewlyCreatedAccountsOnly: competition.isNewlyCreatedAccountsOnly,
      })
      setIsReadOnly(true)
    }
  }, [competition])

  const handleInputChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    })
  }

  const handleRestCustomChange = (key, val) => {
    setForm({
      ...form,
      [key]: val,
    })
  }
  const handlePrizesChange = (prizeKey, val) => {
    form.prizes[prizeKey] = val
    setForm({
      ...form,
      prizes: form.prizes
    })
  }

  const handleFileUpload = async (e, files) => {
    const filesToUpload = e.target?.files || files
    if (!filesToUpload.length) return
    const uploadedFile = filesToUpload[0]
    const fileType = uploadedFile.type
    const maxFileSize = 5 * 1024 * 1024

    setFileError('')

    if (!fileType.startsWith('image/')) {
      setFileError('formatFileError')
      return
    }
    if (uploadedFile.size > maxFileSize) {
      setFileError('sizeFileError')
      return
    }
    if (uploadedFile && uploadedFile.name.length > 250) {
      setFileError('inputReachedMaxLengthError')
      return
    }
    setUploadedFile(uploadedFile)
  }

  const addRule = () => {
    setForm({
      ...form,
      rules: [
        ...form.rules,
        {rule: '', values: {}}
      ]
    })
    setErrors({...errors, isNoRules: false})
  }

  const removeRule = (index) => {
    form.rules.splice(index, 1)
    setForm({...form})
  }

  const setRule = (index, ruleKey) => {
    const values = {}
    for (const valueKey of enums.competitionRules[ruleKey].values) values[valueKey] = ''
    form.rules[index] = {rule: ruleKey, values}
    setForm({...form})
  }

  const checkSubmitFormErrors = () => {
    const newErrors = {
      name: !form.name,
      startDate: !form.startDate,
      endDate: !form.endDate,
      acceptPreviousWinners: form.acceptPreviousWinners === null,
      isDemoAccountsOnly: form.isDemoAccountsOnly === null,
      isNewlyCreatedAccountsOnly: form.isNewlyCreatedAccountsOnly === null,
      prizes: !form.prizes['1'] || !form.prizes['2'] || !form.prizes['3'],
      rules: form.rules.length
        ? form.rules.some((rule) => !rule.rule || Object.values(rule.values).some(value => !value))
        : false,
      isNoRules: !form.rules.length,
      isRulesDuplicated: false
    }
    if (moment(form.startDate).valueOf() > moment(form.endDate).valueOf()) {
      newErrors.startDate = true
      newErrors.endDate = true
    }
    if (form.rules.some(rule => form.rules.filter(filterItem => filterItem.rule === rule.rule).length > 1)) {
      newErrors.isRulesDuplicated = true
    }
    setLongFieldsErrors({
      ...longFieldsErrors,
      isLongName: form.name.length > 250,
      isLongTermsLink: form.termsLink.length > 250,
    })
    setErrors(newErrors)
    return !Object.values({...newErrors, ...longFieldsErrors}).some(error => error)
  }

  const uploadFileAndGetPath = async () => {
    if (uploadedFile) {
      const {data} = await signCompetitionFileUrl({variables: {competitionName: context.clientId + '-' + form.name}})
      const {plainUrl, signedUrl} = data.signCompetitionFileUrl
      await putFile(uploadedFile, signedUrl)
      return plainUrl
    }
    return ''
  }

  const submitForm = async () => {
    const isValid = checkSubmitFormErrors()
    if (!isValid) return
    setIsLoading(true)

    try {
      await upsertCompetitionCreatedByClient({
        variables: {
          id: null,
          name: form.name,
          startDate: form.startDate,
          endDate: form.endDate,
          prizes: JSON.stringify(form.prizes),
          icon: await uploadFileAndGetPath(),
          termsLink: form.termsLink,
          applicableClientTypes: [],
          applicableCompanies: [],
          applicableCountries: [],
          rules: JSON.stringify(form.rules),
          acceptPreviousWinners: form.acceptPreviousWinners,
          applicableIbIds: getIbIds(context.accounts),
          isDemoAccountsOnly: form.isDemoAccountsOnly,
          isNewlyCreatedAccountsOnly: form.isNewlyCreatedAccountsOnly,
          memberId: context.clientId
        }
      })
    } catch (e) {
      console.log(e) /* eslint-disable-line */
    } finally {
      handleUpdate(() => setIsLoading(false))
    }
  }

  return (
    <div id={'form-ib-comp'}>
      <Grid>

        <Grid className={classes.form__title_center}>
          <Typography variant={'h1'}>
            <Trans {...messages.setUpCompetition} />
          </Typography>
          <Typography className={classNames(classes.form__description)}>
            <Trans {...messages.setUpCompetitionDescription} />
          </Typography>
        </Grid>

        <div className={classes.form}>
          <Typography variant={'h3'}> <Trans {...messages.generalInformation} /> </Typography>
          <Grid container spacing={4}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                required
                name="name"
                id={'input-comp-name'}
                label={<Trans {...messages.name} />}
                onChange={(e) => handleInputChange(e)}
                value={form.name}
                error={errors.name && !form.name}
                disabled={isReadOnly}
                className={classNames(classes.form__input_margin_15)}
              />
              {
                longFieldsErrors.isLongName && <FormHelperText sx={{mb: 2, fontSize: '1rem'}} error={true}>
                  <Trans  {...messages.inputReachedMaxLengthError} />
                </FormHelperText>
              }
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                name="termsLink"
                id={'input-comp-terms'}
                label={<Trans {...messages.termsLink} />}
                onChange={(e) => handleInputChange(e)}
                value={form.termsLink}
                error={errors.termsLink && !form.termsLink}
                disabled={isReadOnly}
                className={classNames(classes.form__input_margin_15)}
              />
              {
                longFieldsErrors.isLongTermsLink && <FormHelperText sx={{mb: 2, fontSize: '1rem'}} error={true}>
                  <Trans  {...messages.inputReachedMaxLengthError} />
                </FormHelperText>
              }
            </Grid>
          </Grid>
          <Grid container spacing={4} className={classes.form__input_margin_30} id={'start-date-picker'}>
            <Grid item md={6} xs={12}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={get(dateFnsLocales, context.locale) || get(dateFnsLocales, fnsLocales[context.locale]) || get(dateFnsLocales, fnsLocales['default'])}
              >
                <DatePicker
                  fullWidth
                  disabled={isReadOnly}
                  format="dd/MM/yyyy"
                  label={<Trans {...messages.startDate} />}
                  slotProps={{
                    textField: {
                      required: true,
                      name: 'startDate',
                      id: 'input-start-date',
                      fullWidth: true,
                      readOnly: true,
                      error: errors.startDate,
                      inputProps: {
                        style: {'cursor': 'default'}
                      }
                    }
                  }}
                  onChange={(e) => handleRestCustomChange('startDate', moment(e).startOf('date').toDate())}
                  value={form.startDate ? new Date(form.startDate) : null}
                  className={classNames(classes.form__input_margin_15)}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item md={6} xs={12} id={'end-date-picker'}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={get(dateFnsLocales, context.locale) || get(dateFnsLocales, fnsLocales[context.locale]) || get(dateFnsLocales, fnsLocales['default'])}
              >
                <DatePicker
                  fullWidth
                  disabled={isReadOnly}
                  format="dd/MM/yyyy"
                  label={<Trans {...messages.endDate} />}
                  minDate={new Date(moment().format('YYYY-MM-DD'))}
                  slotProps={{
                    textField: {
                      required: true,
                      name: 'endDate',
                      id: 'input-end-date',
                      fullWidth: true,
                      readOnly: true,
                      error: errors.endDate,
                      inputProps: {
                        style: {'cursor': 'default'}
                      }
                    }
                  }}
                  onChange={(e) => handleRestCustomChange('endDate', moment(e).endOf('date').toDate())}
                  value={form.endDate ? new Date(form.endDate) : null}
                  className={classNames(classes.form__input_margin_15)}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>

          <Grid lg={12} id={'upload-comp-logo'}>
            <InputLabel
              className={classes.form__input_margin_15}
            ><Trans {...messages.chooseCompetitionLogo} />
            </InputLabel>
            {
              fileError && <FormHelperText sx={{mb: 2, fontSize: '1rem'}} error={true}>
                <Trans {...messages[fileError]} />
              </FormHelperText>
            }
            {
              competition?.icon
                ? <img src={competition.icon} alt="icon" style={{width: 100, height: 'auto'}}/>
                : isReadOnly
                  ? <LogoDefaultForCompetition/>
                  : <SelectDocument
                    uploadedFile={uploadedFile}
                    onChange={(e, files) => handleFileUpload(e, files)}
                    type={'upload'}
                    src={Images['upload-icon.png']}
                    key={'upload'}
                    disabled={isReadOnly}
                  />
            }
          </Grid>

          <Typography variant={'h3'}> <Trans {...messages.options} /> </Typography>
          <Grid lg={12}>
            <FormControl fullWidth>
              <InputLabel required><Trans {...messages.acceptPreviousWinners} /></InputLabel>
              <Select
                className={classes.form__input_margin_15}
                value={form.acceptPreviousWinners}
                name={'acceptPreviousWinners'}
                id={'prev-winner'}
                onChange={(e) => handleRestCustomChange('acceptPreviousWinners', e.target.value)}
                required
                disabled={isReadOnly}
                error={errors.acceptPreviousWinners && form.acceptPreviousWinners === null}
              >
                <MenuItem key="acceptPreviousWinners.true" value={true}>
                  <Trans {...messages.yes} />
                </MenuItem>
                <MenuItem key="acceptPreviousWinners.false" value={false}>
                  <Trans {...messages.no} />
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel required><Trans {...messages.competitionOptionsDemoAccountsOnly} /></InputLabel>
              <Select
                className={classes.form__input_margin_15}
                value={form.isDemoAccountsOnly}
                name={'isDemoAccountsOnly'}
                id={'demo-accounts-only'}
                onChange={(e) => handleRestCustomChange('isDemoAccountsOnly', e.target.value)}
                required
                disabled={isReadOnly}
                error={errors.isDemoAccountsOnly && form.isDemoAccountsOnly === null}
              >
                <MenuItem key="isDemoAccountsOnly.true" value={true}>
                  <Trans {...messages.yes} />
                </MenuItem>
                <MenuItem key="isDemoAccountsOnly.false" value={false}>
                  <Trans {...messages.no} />
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel required><Trans {...messages.competitionOptionsNewlyCreatedAccount} /></InputLabel>
              <Select
                className={classes.form__input_margin_15}
                value={form.isNewlyCreatedAccountsOnly}
                name={'isNewlyCreatedAccountsOnly'}
                id={'newly-created-only'}
                onChange={(e) => handleRestCustomChange('isNewlyCreatedAccountsOnly', e.target.value)}
                required
                disabled={isReadOnly}
                error={errors.isNewlyCreatedAccountsOnly && form.isNewlyCreatedAccountsOnly === null}
              >
                <MenuItem key="isNewlyCreatedAccountsOnly.true" value={true}>
                  <Trans {...messages.yes} />
                </MenuItem>
                <MenuItem key="isNewlyCreatedAccountsOnly.false" value={false}>
                  <Trans {...messages.no} />
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Typography variant={'h3'}> <Trans {...messages.prizes} /> </Typography>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                required
                id={'input-first-prize'}
                label={<Trans {...messages.prizeFirst} />}
                onChange={(e) => handlePrizesChange('1', e.target.value)}
                value={get(form.prizes, '1') || ''}
                error={errors.prizes && !form.prizes['1']}
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                required
                id={'input-second-prize'}
                label={<Trans {...messages.prizeSecond} />}
                onChange={(e) => handlePrizesChange('2', e.target.value)}
                value={get(form.prizes, '2') || ''}
                error={errors.prizes && !form.prizes['2']}
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                required
                id={'input-third-prize'}
                label={<Trans {...messages.prizeThird} />}
                onChange={(e) => handlePrizesChange('3', e.target.value)}
                value={get(form.prizes, '3') || ''}
                error={errors.prizes && !form.prizes['3']}
                disabled={isReadOnly}
              />
            </Grid>
          </Grid>
          <Typography variant={'h3'}> <Trans {...messages.rules} /> </Typography>
          {
            errors.isNoRules && <FormHelperText sx={{mb: 2, fontSize: '1rem'}} error={true}>
              <Trans {...messages.competitionRulesAreMandatory} />
            </FormHelperText>
          }
          {
            errors.isRulesDuplicated && <FormHelperText sx={{mb: 2, fontSize: '1rem'}} error={true}>
              <Trans {...messages.competitionRulesAreDuplicates} />
            </FormHelperText>
          }
          <Grid lg={12}>
            {form.rules.map((rule, index) => (
              <Grid
                container
                spacing={4}
                style={{alignItems: 'end'}}
                className={classNames(classes.form__input_margin_15, classes.for__input_minHeight_90)}
              >
                <Grid item xs={7}>
                  <FormControl fullWidth>
                    <InputLabel><Trans {...messages.rule} /> #{index + 1}</InputLabel>
                    <Select
                      id={'rule-' + (index + 1)}
                      value={(enums.competitionRules[rule.rule] || {}).key || ''}
                      onChange={(e) => setRule(index, e.target.value)}
                      error={errors.rules && !rule.rule}
                      disabled={isReadOnly}
                    >
                      {
                        map(enums.competitionRules, (option) => (
                          <MenuItem key={option.key} value={option.key}>
                            <ListItemText primary={option.localization.t(context.locale)}/>
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  {
                    map(rule.values, (value, key) => (
                      <TextField
                        fullWidth
                        required
                        type={'number'}
                        label={key}
                        id={'input-' + key}
                        onChange={(e) => {
                          rule.values[key] = e.target.value
                          setForm({...form})
                        }}
                        value={value}
                        error={errors.rules && !value}
                        disabled={isReadOnly}
                      />
                    ))
                  }
                </Grid>
                <Grid hidden={isReadOnly} item xs={2}>
                  <Button size="small" onClick={() => removeRule(index)}><Close/></Button>
                </Grid>
              </Grid>
            ))}
            <Grid hidden={isReadOnly}>
              <Button size="small" id={'btn-comp-rule'} onClick={() => addRule()}><Add/></Button>
            </Grid>
          </Grid>
          {isReadOnly && <AlertForSubmittedForm/>}
          <Grid item xs={12} className={classNames(classes.button__submit)}>
            {isReadOnly
              ? <Button
                color="primary"
                variant={'contained'}
                fullWidth
                size={'large'}
                disabled={isReadOnly}
              > <Trans {...messages.requestSubmitted} />
              </Button>
              : <LoadingButton
                disabled={isLoading}
                id={'btn-comp-submit'}
                onClick={() => submitForm()}
                fullWidth={true}
              >
                <Trans {...messages.submit} />
              </LoadingButton>
            }
          </Grid>
        </div>
      </Grid>
    </div>
  )
}

export default compose(
  withStyles(styles),
  withNamespaces(),
  graphql(SIGN_COMPETITION_FILE_URL_MUTATION, {
    name: 'signCompetitionFileUrl',
  }),
  graphql(UPSERT_COMPETITION_CREATED_BY_CLIENT, {
    name: 'upsertCompetitionCreatedByClient',
  })
)(TradingCompetitionForm)
