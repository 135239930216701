import React, {Component} from 'react'
import withStyles from '@mui/styles/withStyles'
import {withNamespaces, Trans} from 'react-i18next'
import AppContext from '../../../Common/contexts/AppContext'
import {Typography} from '@mui/material'
import messages from '../../../../assets/messages'
import {map, isEmpty, flowRight as compose} from 'lodash'
import moment from 'moment'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import config from '../../../../config'
import Amount from '../../../Common/Amount'
import classNames from 'classnames'

const styles = theme => ({
  greenText: {
    color: theme.palette.green.color,
  },
  redText: {
    color: theme.palette.red.color,
  },
  yellowText: {
    color: theme.palette.yellow.color,
  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  listPagination: {
    paddingTop: 0
  },
  item: {
    marginTop: -5,
    marginBottom: 5
  }
})

class FollowersTableMobile extends Component {
  static contextType = AppContext

  render() {
    const {classes, followers, showPagination, activeMonth, renderPagination, isIb, accountPerformanceFee} = this.props
    return <List>
      {map(followers, (follower, index) => <React.Fragment key={(isIb) ? follower.remoteId : follower.accountId}>
        <ListItem dense classes={{root: classes.listItem}} id={(isIb) ? follower.remoteId : follower.accountId}>
          <ListItemText classes={{root: classes.listItem}}>
            <Grid container justifyContent="space-between" direction="row">
              <Grid item>
                <Typography variant="caption">
                  {(isIb) ? <Trans {...messages.affiliateAccountId} /> : `${config.name} ID`}
                </Typography>
              </Grid>
              <Grid item className={classes.item}>
                <Typography variant="body1">{(isIb) ? follower.remoteId : follower.accountId} </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between" direction="row">
              <Grid item>
                <Typography variant="caption">
                  <Trans {...messages.startCopyingDate} />
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">{moment(follower.startCopyingDate).format('DD/MM/YYYY')}</Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between" direction="row">
              <Grid item>
                <Typography variant="caption">
                  <Trans {...messages.profitPerPeriod} />
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  <Amount value={follower.profitPerPeriod} currency={follower.currency} locale='en-US' />
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between" direction="row">
              <Grid item>
                <Typography variant="caption">
                  <Trans {...messages.stopCopyingDate} />
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {isEmpty(follower.stopCopyingDate) ? <Trans {...messages.copying} />: moment(follower.stopCopyingDate).format('DD/MM/YYYY')}
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between" direction="row">
              <Grid item>
                <Typography variant="caption">
                  <Trans {...messages.performanceFeePaid} />
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {accountPerformanceFee ? <Amount
                    value={follower.performanceFeePaid} currency={follower.currency} locale='en-US' classes={{
                      root: (follower.performanceFeePaid > 0) ? classes.greenText :
                        (activeMonth && !follower.stopCopyingDate) ? classes.yellowText : classes.redText,
                    }} /> : <Trans {...messages.notApplicableAcronym} />}
                </Typography>
              </Grid>
            </Grid>
            {activeMonth && <Grid container justifyContent="space-between" direction="row">
              <Grid item>
                <Typography variant="caption">
                  <Trans {...messages.performanceFee} />
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  <Amount
                    value={follower.projectionPerformanceFee} currency={follower.currency} locale='en-US' classes={{
                      root: classes.yellowText,
                    }} />
                </Typography>
              </Grid>
            </Grid>}
          </ListItemText>
        </ListItem>
        <Divider />
      </React.Fragment>
      )}
      {showPagination && <ListItem classes={{root: classNames(classes.listItem, classes.listPagination)}}>
        {renderPagination()}
      </ListItem>}
    </List>
  }
}


export default compose(
  withStyles(styles),
  withNamespaces(),
)(FollowersTableMobile)
