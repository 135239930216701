import React from 'react'
import {withApollo} from 'react-apollo'
import withStyles from '@mui/styles/withStyles'
import {withNamespaces} from 'react-i18next'
import {withRouter, Switch, Route} from 'react-router-dom'
import {map, find, first, findIndex, flowRight as compose, forEach} from 'lodash'
import PageTitle from '../Common/PageTitle'
import Loading from '../Common/Loading'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import {isMobile} from '../../common/utils'
import PageBody from '../Common/PageBody'
import VideosLevel from './VideosLevel'
import AppContext from '../Common/contexts/AppContext'
import {EDUCATIONAL_VIDEOS_QUERY} from '../../graphql/queries'
import {educationalVideoLevels} from '@bdswiss/common-enums'
import messages from '../../assets/messages'

const educationalVideoTabs = map(educationalVideoLevels, (level) => (
  {
    key: level.key,
    label: (locale) => educationalVideoLevels[level.value].localization.t(locale),
    relativePath: `/${level.value}`,
  }
))

const styles = theme => ({
  header: {
    paddingLeft: '0px',
  }
})

class EducationalVideos extends React.Component {
  static contextType = AppContext

  constructor() {
    super()
    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    const {client: apolloClient, history, match} = this.props

    apolloClient.query({
      query: EDUCATIONAL_VIDEOS_QUERY,
      variables: {
        clientId: this.context.clientId,
      }
    })
      .then((res) => {
        let isFirstAvailable = true
        const availableLevels = map(res.data.getEducationalVideos, 'level')

        forEach(availableLevels, (level) => {
          if (isFirstAvailable) {
            history.push(`${match.url}/${level.value}`)
            isFirstAvailable = false
          }
        })
        this.setState({loading: false, availableLevels: availableLevels})
      })
      .catch((error) => {
        history.pop()
      })
  }

  handleChange(value) {
    const {history, match} = this.props
    const currentTab = educationalVideoTabs[value]
    history.push(`${match.url}${currentTab.relativePath}`)
    this.setState({value: value})
  }

  render() {
    const {locale} = this.context
    const {loading, availableLevels} = this.state

    if (loading) return <Loading />

    const {t, classes, history, location, match} = this.props

    const currentTab =
      find(educationalVideoTabs, (tab) => location.pathname.indexOf(`${match.url}${tab.relativePath}`) > -1) || first(educationalVideoTabs)
    const currentTabIndex = findIndex(educationalVideoTabs, currentTab)

    return (
      <React.Fragment>
        <PageTitle
          hideArrow={!isMobile()}
          classes={{
            header: classes.header,
          }}
          title={t(messages.educationalVideosNew.i18nKey, messages.educationalVideosNew.defaults)}
          onBack={() => isMobile ? history.push('/more') : history.push('/accounts')}
        >
          <Tabs
            value={currentTabIndex}
            onChange={(_, i) => this.handleChange(i)}
            variant={!isMobile() ? 'standard' : 'fullWidth'}
          >
            {map(educationalVideoTabs, (tab) => (
              <Tab disabled={!find(availableLevels, {value: tab.key})} key={tab.key} label={tab.label(locale)} />
            ))}
          </Tabs>
        </PageTitle>
        <PageBody removePadding>
          <Switch>
            <Route path={`${match.path}/:level`} component={VideosLevel} />
          </Switch>
        </PageBody>
      </React.Fragment>
    )
  }
}

export default compose(
  withStyles(styles),
  withNamespaces(),
  withApollo,
  withRouter,
)(EducationalVideos)
