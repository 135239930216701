const {nationalities} = require('@bdswiss/common-enums')

/* eslint-disable quotes */
module.exports = {
  email: {
    i18nKey: 'email',
    defaults: 'Email'
  },
  password: {
    i18nKey: 'password',
    defaults: 'Password'
  },
  login: {
    i18nKey: 'login',
    defaults: 'Login'
  },
  loginButton: {
    i18nKey: 'loginButton',
    defaults: 'Login'
  },
  signUp: {
    i18nKey: 'signUp',
    defaults: 'No account? Signup'
  },
  forgotPassword: {
    i18nKey: 'forgotPassword',
    defaults: 'Forgot Password?'
  },
  facebook: {
    i18nKey: 'facebook',
    defaults: 'Facebook'
  },
  recipient: {
    i18nKey: 'recipient',
    defaults: 'Recipient / Crediting / Beneficiary Account name'
  },
  bankName: {
    i18nKey: 'bankName',
    defaults: 'Bank Name'
  },
  vpaAddress: {
    i18nKey: 'vpaAddress',
    defaults: 'VPA address'
  },
  bank: {
    i18nKey: 'bank',
    defaults: 'Bank'
  },
  hukouBook: {
    i18nKey: 'hukouBook',
    defaults: 'Hukou Book'
  },
  cardType: {
    i18nKey: 'cardType',
    defaults: 'Payment Card Type'
  },
  personalIdentificationNumber: {
    i18nKey: 'personalIdentificationNumber',
    defaults: 'Personal Identification Number'
  },
  enterPaymentDetails: {
    i18nKey: 'enterPaymentDetails',
    defaults: 'Enter payment Details'
  },
  providerUnavailable: {
    i18nKey: 'providerUnavailable',
    defaults: 'Provider is temporarily unavailable, please choose another'
  },
  address: {
    i18nKey: 'address',
    defaults: 'Address'
  },
  cityName: {
    i18nKey: 'cityName',
    defaults: 'City Name'
  },
  countryName: {
    i18nKey: 'countryName',
    defaults: 'Country Name'
  },
  referenceNumber: {
    i18nKey: 'referenceNumber',
    defaults: 'Reference Number'
  },
  cardNumber: {
    i18nKey: 'cardNumber',
    defaults: 'Card Number'
  },
  expiryMonth: {
    i18nKey: 'expiryMonth',
    defaults: 'Expiry Month'
  },
  expiryYear: {
    i18nKey: 'expiryYear',
    defaults: 'Expiry Year'
  },
  invalidCardNumber: {
    i18nKey: 'invalidCardNumber',
    defaults: 'Card number is invalid'
  },
  invalidValue: {
    i18nKey: 'invalidValue',
    defaults: 'Value supplied is invalid'
  },
  cvv: {
    i18nKey: 'cvv',
    defaults: 'CVV'
  },
  enterCardDetails: {
    i18nKey: 'enterCardDetails',
    defaults: 'Enter Card Details'
  },
  wireTransferNote: {
    i18nKey: 'wireTransferNote',
    defaults: 'Please fill in all the transfer information exactly as shown below to avoid funds being returned.'
  },
  cryptoDepositNote1: {
    i18nKey: 'cryptoDepositNote1',
    defaults: 'Please only fund your {{company}} account using your private wallet from providers such as Blockchain.info, jaxx, electrum, myetherwallet etc.'
  },
  cryptoDepositNote2: {
    i18nKey: 'cryptoDepositNote2',
    defaults: 'Never use an exchange wallet as we will not be able to process your withdrawals back to the exchange wallet.'
  },
  cryptoDepositNote3: {
    i18nKey: 'cryptoDepositNote3',
    defaults: 'Please ensure to provide us with the sending BTC/ETH/BCH address, to avoid delays upon making a withdrawal, by sending the wallet address to funding@{{emailDomain}}.'
  },
  cryptoDepositNote4: {
    i18nKey: 'cryptoDepositNote4',
    defaults: 'When making a withdrawal you will need to provide us with the same wallet address that your deposit/s came from, otherwise your withdrawal will be rejected!'
  },
  cryptoDepositNote5: {
    i18nKey: 'cryptoDepositNote5',
    defaults: "Make sure you've secured the private keys of your wallet. Should you lose access to the wallet (phone breaks, gets stolen, gets lost), you will always be able to restore the wallet back on other device and your funds only by having the private key."
  },
  cryptoCurrency: {
    i18nKey: 'cryptoCurrency',
    defaults: 'Crypto Currency'
  },
  copyCryptoWalletAddress: {
    i18nKey: 'copyCryptoWalletAddress',
    defaults: 'Please copy the one time use {{walletType}} address below before proceeding to any futher action, as the address would be lost upon your next action'
  },
  cryptoTag: {
    i18nKey: 'cryptoTag',
    defaults: 'Tag',
  },
  depositAmount: {
    i18nKey: 'depositAmount',
    defaults: 'Deposit amount {{walletType}} {{amount}}, Please note that the provisional amount will be different than the final amount following the relevant blockchain confirmations',
  },
  ProvisionalExchangeRate: {
    i18nKey: 'ProvisionalExchangeRate',
    defaults: 'Provisional exchange Rate {{rate}}, The final exchange rate will be defined only when the cryptocurrency amount is received.',
  },
  cryptoWalletAddressCopied: {
    i18nKey: 'cryptoWalletAddressCopied',
    defaults: 'Address copied, please proceed with a transfer from your {{walletType}} wallet account to the {{company}} wallet account',
  },
  cryptoTagCopied: {
    i18nKey: 'cryptoTagCopied',
    defaults: 'Tag copied',
  },
  belowWalletAddressMessage: {
    i18nKey: 'belowWalletAddressMessage',
    defaults: 'The {{walletType}} address is only valid for one time use; a new address should be used for each deposit.',
  },
  depositResultSuccess: {
    i18nKey: 'depositSuccess',
    defaults: 'Your deposit is successful and account has been credited!'
  },
  depositResultSuccessWithAmount: {
    i18nKey: 'depositResultSuccessWithAmount',
    defaults: 'Your deposit of <0>{{amount}}</0> is successful and account has been credited!'
  },
  depositResultError: {
    i18nKey: 'depositResultError',
    defaults: 'Your deposit attempt failed, please try again!'
  },
  depositResultCancelled: {
    i18nKey: 'depositResultCancelled',
    defaults: 'Your deposit attempt has been cancelled!'
  },
  depositResultErrorWithAmount: {
    i18nKey: 'depositResultErrorWithAmount',
    defaults: 'Your deposit attempt of <0>{{amount}}</0> failed, please try again!'
  },
  depositResultPending: {
    i18nKey: 'depositResultPending',
    defaults: "Your deposit is pending, as further action is required from your side. For more information, please check your Dashboard and email"
  },
  depositResultPendingWithAmount: {
    i18nKey: 'depositResultPendingWithAmount',
    defaults: "Your deposit of <0>{{amount}}</0> is pending, as further action is required from your side. For more information, please check your Dashboard and email"
  },
  depositErrorRetry: {
    i18nKey: 'depositErrorRetry',
    defaults: "Something went wrong while processing your deposit, please try again using another method"
  },
  paymentMethodKycRequired: {
    i18nKey: 'paymentMethodKycRequired',
    defaults: "The current payment method is available only to fully KYC verified clients. Please proceed with another payment option or with the verification of your account to be able to continue. "
  },
  getCryptoWalletAddress: {
    i18nKey: 'getCryptoWalletAddress',
    defaults: 'Get Wallet Address to Deposit'
  },
  importantNotes: {
    i18nKey: 'importantNotes',
    defaults: 'Important Notes'
  },
  paymentMethodUnavailable: {
    i18nKey: 'paymentMethodUnavailable',
    defaults: 'This payment method is temporarily unavailable, please select another'
  },
  google: {
    i18nKey: 'google',
    defaults: 'Google'
  },
  publishedOn: {
    i18nKey: 'publishedOn',
    defaults: 'Published on <0>{{date}}</0>'
  },
  noNotification: {
    i18nKey: 'noNotification',
    defaults: 'You do not have any notifications available.'
  },
  emailOrPassword: {
    i18nKey: 'emailOrPassword',
    defaults: 'Or use your email and password'
  },
  selectAccount: {
    i18nKey: 'selectAccount',
    defaults: 'Select Account'
  },
  selectAccountToTopUp: {
    i18nKey: 'selectAccountToTopUp',
    defaults: 'Select account to top-up'
  },
  selectDepositAmount: {
    i18nKey: 'selectDepositAmount',
    defaults: 'Select amount to deposit'
  },
  chooseAccount: {
    i18nKey: 'chooseAccount',
    defaults: 'Choose account'
  },
  competitionSubmittedAlert: {
    i18nKey: 'competitionSubmittedAlert',
    defaults: 'Your competition has been submitted. Please wait for our partner agent to review and approve it.'
  },
  pageError: {
    i18nKey: 'pageError',
    defaults: 'An Error Occurred'
  },
  pageErrorInfo: {
    i18nKey: 'pageErrorInfo',
    defaults: 'An error occurred while processing your previous action. Our team has been notified and would be working to resolve this issue. Please try again later and if issue persists, contact support.'
  },
  termsChangeDisclaimer: {
    i18nKey: 'termsChangeDisclaimer',
    defaults: `If you continue using our services and do not take any action to decline the new terms and conditions within {{noticePeriod}} days of the publication of this notice, you are considered to have agreed to them.
      If you disagree with any of the new terms and conditions as presented above, please send an email to support@{{emailDomain}} listing the terms you do not accept, so that we can proceed with your account's closure.`
  },
  contactSupport: {
    i18nKey: 'contactSupport',
    defaults: 'Contact support'
  },
  backToDashboard: {
    i18nKey: 'backToDashboard',
    defaults: 'Back to dashboard'
  },
  other: {
    i18nKey: 'other',
    defaults: 'Other'
  },
  new: {
    i18nKey: 'new',
    defaults: 'New'
  },
  actionSeen: {
    i18nKey: 'actionSeen',
    defaults: 'Seen'
  },
  actionAccepted: {
    i18nKey: 'actionAccepted',
    defaults: 'Accepted'
  },
  actionDismissed: {
    i18nKey: 'actionDismissed',
    defaults: 'Dismissed'
  },
  actionRemindLater: {
    i18nKey: 'actionRemindLater',
    defaults: 'Remind me Later'
  },
  accept: {
    i18nKey: 'accept',
    defaults: 'Accept'
  },
  activateTradingCentral: {
    i18nKey: 'activateTradingCentral',
    defaults: 'To access Trading Central products, you will need to make a one-time deposit of $250.00'
  },
  activateTelegramVIPAlerts: {
    i18nKey: 'activateTelegramVIPAlerts',
    defaults: 'To access VIP Telegram channel, you will need to make a one-time deposit of $500.00'
  },
  dismiss: {
    i18nKey: 'dismiss',
    defaults: 'Dismiss'
  },
  requiredField: {
    i18nKey: 'requiredField',
    defaults: 'Required Field'
  },
  noPhone: {
    i18nKey: 'noPhone',
    defaults: 'No phone was provided. Please add a phone to your profile first.',
  },
  optInMarketing: {
    i18nKey: 'optInMarketing',
    defaults: 'I want to receive marketing and promotional material.',
  },
  optInSms: {
    i18nKey: 'optInSms',
    defaults: 'I want to receive SMS messages for deposits, withdrawals and account activity.',
  },
  hotAssetsToggleText: {
    i18nKey: 'hotAssetsToggleText',
    defaults: 'I want to receive “Hot Assets” daily email.'
  },
  gdprExportData: {
    i18nKey: 'gdprExportData',
    defaults: 'Export my personal data',
  },
  gdprExportDataSuccess: {
    i18nKey: 'gdprExportDataSuccess',
    defaults: 'We are preparing an archive with your information. You will receive an email with a download link shortly.',
  },
  gdprDeleteData: {
    i18nKey: 'gdprDeleteData',
    defaults: 'Delete my account',
  },
  gdprDeleteDataConfirmTitle: {
    i18nKey: 'gdprDeleteDataConfirmTitle',
    defaults: 'Confirm account deletion',
  },
  delete: {
    i18nKey: 'delete',
    defaults: 'Delete',
  },
  cancel: {
    i18nKey: 'cancel',
    defaults: 'Cancel',
  },
  enterPassword: {
    i18nKey: 'enterPassword',
    defaults: 'Enter your password',
  },
  allAccounts: {
    i18nKey: 'allAccounts',
    defaults: 'All Accounts'
  },
  payments: {
    i18nKey: 'payments',
    defaults: 'Transactions'
  },
  deposit: {
    i18nKey: 'deposit',
    defaults: 'Deposit'
  },
  sendWithdraw: {
    i18nKey: 'sendWithdraw',
    defaults: 'Send / Withdraw'
  },
  transfer: {
    i18nKey: 'transfer',
    defaults: 'Transfer'
  },
  send: {
    i18nKey: 'send',
    defaults: 'Send'
  },
  history: {
    i18nKey: 'history',
    defaults: 'History'
  },
  appropriatenessTest: {
    i18nKey: 'appropriatenessTest',
    defaults: 'Appropriateness Test'
  },
  dueDiligence: {
    i18nKey: 'dueDiligence',
    defaults: 'Due diligence'
  },
  transferFrom: {
    i18nKey: 'transferFrom',
    defaults: 'Transfer from'
  },
  transferTo: {
    i18nKey: 'transferTo',
    defaults: 'Transfer to'
  },
  transferFromAccount: {
    i18nKey: 'transferFromAccount',
    defaults: 'Transfer from account'
  },
  transferToAccount: {
    i18nKey: 'transferToAccount',
    defaults: 'Transfer to account'
  },
  selectAmount: {
    i18nKey: 'selectAmount',
    defaults: 'Select amount'
  },
  withdrawAccounts: {
    i18nKey: 'withdrawAccounts',
    defaults: 'Select account to withdraw'
  },
  back: {
    i18nKey: 'back',
    defaults: 'Back'
  },
  continue: {
    i18nKey: 'continue',
    defaults: 'Continue'
  },
  none: {
    i18nKey: 'none',
    defaults: 'None'
  },
  noSameTransferAccounts: {
    i18nKey: 'noSameTransferAccounts',
    defaults: 'Transfer From/To accounts cannot be the same!'
  },
  noAccountsSelected: {
    i18nKey: 'noAccountsSelected',
    defaults: 'No accounts selected!'
  },
  noZeroTransferAmount: {
    i18nKey: 'noZeroTransferAmount',
    defaults: 'Transfer amount cannot be negative or 0'
  },
  attention: {
    i18nKey: 'attention',
    defaults: 'Attention'
  },
  uploadDocuments: {
    i18nKey: 'uploadDocuments',
    defaults: 'Upload documents'
  },
  uploadDocument: {
    i18nKey: 'uploadDocument',
    defaults: 'Upload document'
  },
  pendingKYCDocs: {
    i18nKey: 'pendingKYCDocs',
    defaults: 'Withdrawals limited on live accounts due to pending account verification.'
  },
  reviewingKYCDocs: {
    i18nKey: 'reviewingKYCDocs',
    defaults: 'Your documents are being reviewed'
  },
  withdrawalMethod: {
    i18nKey: 'withdrawalMethod',
    defaults: 'Withdrawal Method'
  },
  withdrawalMessage: {
    i18nKey: 'withdrawalMessage',
    defaults: 'Select method for withdrawal <0>{{amount}}</0> from <1>{{accountLabel}}</1>'
  },
  bankTransferTitle: {
    i18nKey: 'bankTransferTitle',
    defaults: 'Direct bank transfer to {{company}}'
  },
  bankTransferTitle2: {
    i18nKey: 'bankTransferTitle2',
    defaults: 'Direct bank transfer ONLY in EURO currency'
  },
  selectDepositMethodForAmount: {
    i18nKey: 'selectDepositMethodForAmount',
    defaults: 'Select payment method for <0>{{amount}}</0>'
  },
  minimumDepositAmount: {
    i18nKey: 'minimumDepositAmount',
    defaults: 'Minimum allowed deposit amount is {{amount}}'
  },
  maximumDepositAmount: {
    i18nKey: 'maximumDepositAmount',
    defaults: 'Maximum allowed deposit amount is {{amount}}'
  },
  proceedToPay: {
    i18nKey: 'proceedToPay',
    defaults: 'Proceed to Pay'
  },
  withdraw: {
    i18nKey: 'withdraw',
    defaults: 'Withdraw'
  },
  withdrawalTermsAndConditions: {
    i18nKey: 'withdrawalTermsAndConditions',
    defaults: 'Please review and accept our <0>Terms and Conditions</0>',
  },
  cryptoWithdrawalTermsAndConditions: {
    i18nKey: 'cryptoWithdrawalTermsAndConditions',
    defaults: 'Please review and accept our <0>Terms and Conditions</0>. By checking this box, you declare that you are the rightful owner of the wallet that will be used for receiving funds.',
  },
  insufficientFunds: {
    i18nKey: 'insufficientFunds',
    defaults: 'Insufficient Funds',
  },
  close: {
    i18nKey: 'close',
    defaults: 'Close',
  },
  tryAgain: {
    i18nKey: 'tryAgain',
    defaults: 'Try Again',
  },
  success: {
    i18nKey: 'success',
    defaults: 'Success!',
  },
  failure: {
    i18nKey: 'failure',
    defaults: 'Failure!',
  },
  successWithdrawal: {
    i18nKey: 'successWithdrawal',
    defaults: 'Your withdrawal has been successfully submitted and is pending review by our Back Office team. You will be notified via email {{email}} /SMS once your withdrawal is approved. <1>If your withdrawal has not been processed within 24 hours of your transaction submission, please check for any email messages from us.</1> <2>Your withdrawal should be processed within 48 hours for wire transfers and 24 hours for all other withdrawal methods. If your withdrawal is not approved by then, please check for any email messages from us.</2>',
  },
  failureWithdrawal: {
    i18nKey: 'failureWithdrawal',
    defaults: 'There was an error when you tried to withdraw <0>{{amount}}</0>.',
  },
  support: {
    i18nKey: 'support',
    defaults: 'Support (24/5)',
  },
  supportTitle: {
    i18nKey: 'supportTitle',
    defaults: 'Support',
  },
  supportMessageSuccess: {
    i18nKey: 'supportMessageSuccess',
    defaults: 'Thank you for your message. We will get back to you shortly.',
  },
  supportMethodsSubtitle: {
    i18nKey: 'supportMethodsSubtitle',
    defaults: 'How would you like to contact us?',
  },
  whatsapp: {
    i18nKey: 'whatsapp',
    defaults: 'WhatsApp',
  },
  telegram: {
    i18nKey: 'telegram',
    defaults: 'Telegram',
  },
  supportEmail: {
    i18nKey: 'supportEmail',
    defaults: 'Email',
  },
  sendMessage: {
    i18nKey: 'sendMessage',
    defaults: 'Send Message',
  },
  supportTopic1: {
    i18nKey: 'supportTopic1',
    defaults: 'I have a question about my account'
  },
  supportTopic2: {
    i18nKey: 'supportTopic2',
    defaults: 'I have a question about my deposit'
  },
  supportTopic3: {
    i18nKey: 'supportTopic3',
    defaults: 'I have a technical problem'
  },
  supportTopic4: {
    i18nKey: 'supportTopic4',
    defaults: 'I have a question for my Account Manager/Dealing Room'
  },
  supportTopic5: {
    i18nKey: 'supportTopic5',
    defaults: 'I’d like to make a complaint'
  },
  supportTopic6: {
    i18nKey: 'supportTopic6',
    defaults: 'I have a question regarding the Partner Program'
  },
  supportTopic7: {
    i18nKey: 'supportTopic7',
    defaults: 'Other'
  },
  callback: {
    i18nKey: 'callback',
    defaults: 'Support Call back',
  },
  oneOnOneSession: {
    i18nKey: 'oneOnOneSession',
    defaults: 'One on one session',
  },
  liveChat: {
    i18nKey: 'liveChat',
    defaults: 'Live chat',
  },
  whatsappInstructionsTitle: {
    i18nKey: 'whatsappInstructionsTitle',
    defaults: 'How to contact us on WhatsApp',
  },
  whatsappInstructionsStep1: {
    i18nKey: 'whatsappInstructionsStep1',
    defaults: 'Add the following {{company}} number to your contact list: {{companyWhatsapp}}',
  },
  whatsappInstructionsStep2: {
    i18nKey: 'whatsappInstructionsStep2',
    defaults: 'Open Whatsapp',
  },
  whatsappInstructionsStep3: {
    i18nKey: 'whatsappInstructionsStep3',
    defaults: 'Click the “New Chat” icon in the top right corner',
  },
  whatsappInstructionsStep4: {
    i18nKey: 'whatsappInstructionsStep4',
    defaults: 'Select the {{company}} number you added in step 1',
  },
  whatsappInstructionsStep5: {
    i18nKey: 'whatsappInstructionsStep5',
    defaults: 'Start chatting!',
  },
  telegramInstructionsTitle: {
    i18nKey: 'telegramInstructionsTitle',
    defaults: 'How to contact us on Telegram',
  },
  telegramInstructionsStep1: {
    i18nKey: 'telegramInstructionsStep1',
    defaults: 'Add the following {{company}} number to your contact list: {{companyTelegram}}',
  },
  telegramInstructionsStep2: {
    i18nKey: 'telegramInstructionsStep2',
    defaults: 'Open Telegram',
  },
  telegramInstructionsStep3: {
    i18nKey: 'telegramInstructionsStep3',
    defaults: 'Click on “Contacts”',
  },
  telegramInstructionsStep4: {
    i18nKey: 'telegramInstructionsStep4',
    defaults: 'Select the {{company}} number you added in step 1',
  },
  telegramInstructionsStep5: {
    i18nKey: 'telegramInstructionsStep5',
    defaults: 'Start chatting!',
  },
  bdSwisstelegramInstructionsStep1: {
    i18nKey: 'bdSwisstelegramInstructionsStep1',
    defaults: 'Open Telegram',
  },
  bdSwisstelegramInstructionsStep2: {
    i18nKey: 'bdSwisstelegramInstructionsStep2',
    defaults: 'Search for {{company}} Support ({{companyTelegram}})',
  },
  bdSwisstelegramInstructionsStep3: {
    i18nKey: 'bdSwisstelegramInstructionsStep3',
    defaults: 'Open the chat and click “Start”',
  },
  contactForm: {
    i18nKey: 'contactForm',
    defaults: 'Contact form',
  },
  supportYourMessage: {
    i18nKey: 'supportYourMessage',
    defaults: 'Your message',
  },
  supportYourQuestion: {
    i18nKey: 'supportYourQuestion',
    defaults: 'Your question',
  },
  profile: {
    i18nKey: 'profile',
    defaults: 'Profile'
  },
  profileMissing: {
    i18nKey: 'profileMissing',
    defaults: 'Profile details are missing'
  },
  profileSubmitted: {
    i18nKey: 'profileSubmitted',
    defaults: 'Profile submitted'
  },
  economicProfileSubmitted: {
    i18nKey: 'economicProfileSubmitted',
    defaults: 'Economic Profile submitted'
  },
  economicProfileMissing: {
    i18nKey: 'economicProfileMissing',
    defaults: 'Economic Profile is missing'
  },
  appropriatenessTestSubmitted: {
    i18nKey: 'appropriatenessTestSubmitted',
    defaults: 'Appropriateness Test submitted'
  },
  appropriatenessTestRejected: {
    i18nKey: 'appropriatenessTestRejected',
    defaults: 'Appropriateness Test rejected'
  },
  appropriatenessTestMissing: {
    i18nKey: 'appropriatenessTestMissing',
    defaults: 'Appropriateness Test is missing'
  },
  kycStatusApproved: {
    i18nKey: 'kycStatusApproved',
    defaults: 'KYC Status Approved'
  },
  kycStatusRejected: {
    i18nKey: 'kycStatusRejected',
    defaults: 'KYC Status Rejected'
  },
  kycStatusPending: {
    i18nKey: 'kycStatusPending',
    defaults: 'KYC Status Pending'
  },
  kycAutoRejectedExplanation: {
    i18nKey: 'kycAutoRejectedExplanation',
    defaults: 'Unfortunately we could not verify your identity and address electronically. Please submit required documents manually.'
  },
  kycCannotContactServiceExplanation: {
    i18nKey: 'kycCannotContactServiceExplanation',
    defaults: 'Could not contact verification service. Please submit required documents manually.'
  },
  kycAutoDescription:{
    i18nKey: 'kycAutoDescription',
    defaults: 'Please verify your identity and residential address.'
  },
  kycStatusApprovedDescription:{
    i18nKey: 'kycStatusApprovedDescription',
    defaults: 'We have successfully verified your account! No additional documents are required at this stage.*'
  },
  accountFunded: {
    i18nKey: 'accountFunded',
    defaults: 'Account Funded'
  },
  depositFailed: {
    i18nKey: 'depositFailed',
    defaults: 'Deposit Failed'
  },
  depositPending: {
    i18nKey: 'depositPending',
    defaults: 'Start trading by depositing to your account.'
  },
  accountPending: {
    i18nKey: 'accountPending',
    defaults: 'Your account is pending'
  },
  accountVerification: {
    i18nKey: 'accountVerification',
    defaults: 'Account Verification'
  },
  accountID: {
    i18nKey: 'accountID',
    defaults: 'Account ID: {{accountId}}'
  },
  getVerified: {
    i18nKey: 'getVerified',
    defaults: 'Get Verified'
  },
  submit: {
    i18nKey: 'submit',
    defaults: 'Submit'
  },
  completed: {
    i18nKey: 'completed',
    defaults: 'Completed'
  },
  notCompleted: {
    i18nKey: 'notCompleted',
    defaults: 'Not completed'
  },
  missing: {
    i18nKey: 'missing',
    defaults: 'Missing'
  },
  appropTestHeaderMessage: {
    i18nKey: 'appropTestHeaderMessage',
    defaults: '{{company}} is a financially regulated company and is obliged by law to collect certain information about our clients. The following questions are part of this process to protect clients by ensuring that our services are appropriate for them.'
  },
  dueDiligenceHeaderMessage: {
    i18nKey: 'dueDiligenceHeaderMessage',
    defaults: '{{company}} lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum.'
  },
  whyIsThisNeeded: {
    i18nKey: 'whyIsThisNeeded',
    defaults: 'Why is this needed?',
  },
  netWorthDesc: {
    i18nKey: 'appropTestNetWorthDesc',
    defaults: 'This is the total of all your assets (including any savings accounts and property) minus any personal liabilities you have (money you owe to others, such as credit card debt or bank loans)', // eslint-disable-line max-len
  },
  initialInvestmentDesc: {
    i18nKey: 'appropTestiIitialInvestmentDesc',
    defaults: 'This is the range of money in Euros you expect to deposit before start trading',
  },
  leverage: {
    i18nKey: 'leverage',
    defaults: 'Leverage',
  },
  downloadMT4Subtitle:{
    i18nKey: 'downloadMT4Subtitle',
    defaults:'{{product}} trading software download'
  },
  windows:{
    i18nKey: 'windows',
    defaults:'{{product}} for Windows'
  },
  iosAndroid:{
    i18nKey: 'iosAndroid',
    defaults:'{{product}} for iOS / Android'
  },
  mac:{
    i18nKey: 'mac',
    defaults:'{{product}} for Mac'
  },
  mt4MoreInfo:{
    i18nKey: 'mt4MoreInfo',
    defaults:'More information on {{product}} Trading Software'
  },
  loginDetails:{
    i18nKey: 'loginDetails',
    defaults:'Login Details'
  },
  server:{
    i18nKey: 'server',
    defaults:'Server'
  },
  clickToCopy:{
    i18nKey: 'clickToCopy',
    defaults:'Click to copy'
  },
  mt4Example:{
    i18nKey: 'mt4Example',
    defaults:'{{product}} login example ( Screenshot)'
  },
  yourPasswordText: {
    i18nKey: 'yourPasswordText',
    defaults:'Your password'
  },
  addAccount: {
    i18nKey: 'addAccount',
    defaults: 'Add account',
  },
  addNewAccount: {
    i18nKey: 'addNewAccount',
    defaults: 'Add new account',
  },
  setPassword: {
    i18nKey: 'setPassword',
    defaults: 'Set Password',
  },
  selectAccountType: {
    i18nKey: 'selectAccountType',
    defaults: 'Select account type',
  },
  selectCurrency: {
    i18nKey: 'selectCurrency',
    defaults: 'Select currency',
  },
  newPassword: {
    i18nKey: 'newPassword',
    defaults: 'New password'
  },
  passwordConfirmation: {
    i18nKey: 'passwordConfirmation',
    defaults: 'Password Confirmation'
  },
  currentPassword: {
    i18nKey: 'currentPassword',
    defaults: 'Current Password'
  },
  passwordChanged: {
    i18nKey: 'passwordChanged',
    defaults: 'Password changed successfully!'
  },
  transferSuccess: {
    i18nKey: 'transferSuccess',
    defaults: 'Your transfer was completed successfully!',
  },
  upload: {
    i18nKey: 'upload',
    defaults: 'Upload',
  },
  verificationDocuments: {
    i18nKey: 'verificationDocuments',
    defaults: 'Verification documents',
  },
  documentsList: {
    i18nKey: 'documentsList',
    defaults: 'Documents list',
  },
  optionalDocumentsList: {
    i18nKey: 'optionalDocumentsList',
    defaults: 'Optional Documents list',
  },
  daysToVerifyAccount: {
    i18nKey: 'daysToVerifyAccount',
    defaults: 'Please <0>verify your account</0> as soon as possible. Meanwhile, you can start <1>trading now</1>',
  },
  completePersonalProfile: {
    i18nKey: 'completePersonalProfile',
    defaults: 'Please complete the<0></0><1>Personal Profile</1> first',
  },
  whyNecessary: {
    i18nKey: 'whyNecessary',
    defaults: 'Why is it necessary?',
  },
  poiTitle: {
    i18nKey: 'poiTitle',
    defaults: 'Proof of Identity',
  },
  porTitle: {
    i18nKey: 'porTitle',
    defaults: 'Proof of Residence',
  },
  poiTitlenotUploaded: {
    i18nKey: 'poiTitlenotUploaded',
    defaults: 'Proof of Identity is required',
  },
  poiTitleapproved: {
    i18nKey: 'poiTitleapproved',
    defaults: 'Proof of Identity approved',
  },
  poiTitlepending: {
    i18nKey: 'poiTitlepending',
    defaults: 'Proof of Identity is pending review',
  },
  poiTitlerejected: {
    i18nKey: 'poiTitlerejected',
    defaults: 'Proof of Identity rejected',
  },
  porTitlenotUploaded: {
    i18nKey: 'porTitlenotUploaded',
    defaults: 'Proof of Residence is required',
  },
  porTitleapproved: {
    i18nKey: 'porTitleapproved',
    defaults: 'Proof of Residence approved',
  },
  porTitlepending: {
    i18nKey: 'porTitlepending',
    defaults: 'Proof of Residence is pending review',
  },
  porTitlerejected: {
    i18nKey: 'porTitlerejected',
    defaults: 'Proof of Residence rejected',
  },
  skipWaitingList: {
    i18nKey: 'skipWaitingList',
    defaults: 'Skip Waiting List',
  },
  documentType: {
    i18nKey: 'documentType',
    defaults: 'Document type',
  },
  documentNumber: {
    i18nKey: 'documentNumber',
    defaults: 'Document Number',
  },
  cashType: {
    i18nKey: 'cashType',
    defaults: 'Cash Type',
  },
  date: {
    i18nKey: 'date',
    defaults: 'Date',
  },
  poiUpload: {
    i18nKey: 'poiUpload',
    defaults: 'upload Proof of Identity',
  },
  porUpload: {
    i18nKey: 'porUpload',
    defaults: 'upload Proof of Residence',
  },
  pngOrJpeg: {
    i18nKey: 'pngOrJpeg',
    defaults: '(png or jpeg image)',
  },
  id: {
    i18nKey: 'id',
    defaults: 'ID',
  },
  drivingLicense: {
    i18nKey: 'drivingLicense',
    defaults: 'Driving Licence',
  },
  card: {
    i18nKey: 'card',
    defaults: 'Credit/Debit Card Copy',
  },
  documentUploaded: {
    i18nKey: 'documentUploaded',
    defaults: 'Document has been successfully uploaded.',
  },
  documentUploadedMessage: {
    i18nKey: 'documentUploadedMessage',
    defaults: 'We\'re checking the uploaded documents as fast as we can, but at the moment there\'s a short waiting list ' +
      'for our basic accounts. It may take us some time to check everything',
  },
  placeAtWaitingList: {
    i18nKey: 'placeAtWaitingList',
    defaults: 'Your place on the waiting list is:',
  },
  oldPofTitle: {
    i18nKey: 'oldPofTitle',
    defaults: 'Deposit Verification',
  },
  personalTitle: {
    i18nKey: 'personalTitle',
    defaults: 'Personal Documents',
  },
  personalUpload: {
    i18nKey: 'personalUpload',
    defaults: 'Personal Documents upload',
  },
  pofUpload: {
    i18nKey: 'pofUpload',
    defaults: 'Verify your Payment method',
  },
  warning: {
    i18nKey: 'warning',
    defaults: 'Warning'
  },
  level1AppropTestMessage: {
    i18nKey: 'leverageLevel1Message',
    defaults: `Please note that based on your answers you do not have an appropriate understanding of what CFD trading
    involves, and you lack the knowledge necessary to invest in leveraged products. According to our Company
    regulations, and to protect our clients from investing in a product they do not fully comprehend, we cannot
    accept your account’s funding at this time.<0/> The Appropriateness test can be retaken after {{failedAppropTestTrainingPeriod}} days; however,
    we highly recommend visiting our <1>Trading Academy</1>, where you can learn more about online trading and practice your skills on our free Demo Account.`,
  },
  level1AppropTestMessageOpenPosition: {
    i18nKey: 'level1AppropTestMessageOpenPosition',
    defaults: `Please note that based on your answers you do not have an appropriate understanding of what CFD trading
    involves, and you lack the knowledge necessary to invest in leveraged products. According to our Company
    regulations, and to protect our clients from investing in a product they do not fully comprehend, the Appropriateness test must be retaken immediately.
    <0/> We highly recommend visiting our <1>Trading Academy</1>, where you can learn more about online trading and practice your skills on our free Demo Account.`,
  },
  level2AppropTestMessage: {
    i18nKey: 'leverageLevel2Message',
    defaults: `Congratulations, you have successfully completed the Appropriateness test. You seem to possess
    enough knowledge and experience to trade with real money. Though {{company}} is warning you that this carries a
    high degree of risk, you can proceed further.`,
  },
  level15AppropTestMessage: {
    i18nKey: 'leverageLevel15Message',
    defaults: `Thank you for completing your Appropriateness Test!<0/>
    Please note that based on your answers you only have a moderate understanding of CFD trading.<1/>
    Although you are eligible to start trading, we would highly recommend you first gain a better understanding
    of what speculative trading involves, by visiting our <2>Trading Academy.</2><3/>
    By clicking <4>"Proceed"</4> you acknowledge the risks involved in trading forex and CFDs.`,
  },
  level15AppropTestMessageMaubdSwiss: {
    i18nKey: 'level15AppropTestMessageMaubdSwiss',
    defaults: `Thank you for completing your Appropriateness Test!<0/>
    Please note that based on your answers you seem to have a moderate understanding of CFD trading.<1/>
    You can gain a better understanding of what speculative trading involves, by visiting the <2>BDSwiss Trading Academy.</2><3/>
    Your account shall be set to a maximum <4>1:{{leverage}}</4> default leverage. However, you can choose to increase your leverage at your own risk.
    For more information, please contact our <5>Support Team</5>.<6/> By clicking <4>"Proceed"</4> you acknowledge the risks involved in trading forex and CFDs.`,
  },
  level1AppropTestMessageMaubdSwiss: {
    i18nKey: 'level1AppropTestMessageMaubdSwiss',
    defaults: `Please note that based on your answers you do not have an appropriate understanding of what CFD trading involves, and you lack the knowledge necessary to invest in leveraged products. According to our Company regulations, and to protect our clients from investing in a product they do not fully comprehend, we cannot accept your account’s funding at this time.<1/>
    You may re-submit your Appropriateness test immediately. Alternatively, you may choose to improve your skills by visiting our <0>Trading Academy.</0><2/>
    If you have already deposited funds into your account but don’t wish to proceed with trading on our platform, you can withdraw them at any time by contacting our <3>Support Team</3>.`,
  },
  level15AppropTestMessageMauswissMarkets: {
    i18nKey: 'level15AppropTestMessageMauswissMarkets',
    defaults: `Thank you for completing your Appropriateness Test!<0/>
    Please note that based on your answers you seem to have a moderate understanding of CFD trading.<1/>
    Your account shall be set to a maximum <4>1:{{leverage}}</4> default leverage. However, you can choose to increase your leverage at your own risk.
    For more information, please contact our <5>Support Team</5>.<6/>
    By clicking <4>"Proceed"</4> you acknowledge the risks involved in trading forex and CFDs.`,
  },
  level1AppropTestMessageMauswissMarkets: {
    i18nKey: 'level1AppropTestMessageMauswissMarkets',
    defaults: `Please note that based on your answers you do not have an appropriate understanding of what CFD trading involves, and you lack the knowledge necessary to invest in leveraged products. According to our Company regulations, and to protect our clients from investing in a product they do not fully comprehend, we cannot accept your account’s funding at this time.<1/>
    You may re-submit your Appropriateness test immediately.<2/> If you have already deposited funds into your account but don’t wish to proceed with trading on our platform, you can withdraw them at any time by contacting our <3>Support Team</3>.`,
  },
  tradeNow: {
    i18nKey: 'tradeNode',
    defaults: 'Trade Now',
  },
  balance: {
    i18nKey: 'balance',
    defaults: 'Balance',
  },
  equity: {
    i18nKey: 'equity',
    defaults: 'Equity',
  },
  margin: {
    i18nKey: 'margin',
    defaults: 'Margin',
  },
  freeMargin: {
    i18nKey: 'freeMargin',
    defaults: 'Free Margin',
  },
  marginLevel: {
    i18nKey: 'marginLevel',
    defaults: 'Margin Level',
  },
  openPositions: {
    i18nKey: 'openPositions',
    defaults: 'Open Positions',
  },
  overview: {
    i18nKey: 'overview',
    defaults: 'Overview',
  },
  tools: {
    i18nKey: 'tools',
    defaults: 'Tools',
  },
  settings: {
    i18nKey: 'settings',
    defaults: 'Settings',
  },
  tradingTools: {
    i18nKey: 'tradingTools',
    defaults: 'Trading Tools',
  },
  tradingToolsSmall: {
    i18nKey: 'tradingToolsSmall',
    defaults: 'Trading tools',
  },
  forexCalculator: {
    i18nKey: 'forexCalculator',
    defaults: 'Forex Calculator',
  },
  tradingAcademy: {
    i18nKey: 'tradingAcademy',
    defaults: 'Trading Academy',
  },
  tradingSignals: {
    i18nKey: 'tradingSignals',
    defaults: 'Trading Signals',
  },
  tradingSignalsSmall: {
    i18nKey: 'tradingSignalsSmall',
    defaults: 'Trading signals',
  },
  getLiveSignals: {
    i18nKey: 'getLiveSignals',
    defaults: 'How to get live signals?',
  },
  timeClosed: {
    i18nKey: 'timeClosed',
    defaults: 'Time Closed',
  },
  pair: {
    i18nKey: 'pair',
    defaults: 'Pair',
  },
  action: {
    i18nKey: 'action',
    defaults: 'Action',
  },
  entryPrice: {
    i18nKey: 'entryPrice',
    defaults: 'Entry Price',
  },
  stopLossPrice: {
    i18nKey: 'stopLossPrice',
    defaults: 'Stop Loss Price',
  },
  takeProfitPrice: {
    i18nKey: 'takeProfitPrice',
    defaults: 'Take Profit Price',
  },
  successfulTrade: {
    i18nKey: 'successfulTrade',
    defaults: 'Successful Trade',
  },
  status: {
    i18nKey: 'status',
    defaults: 'Status',
  },
  comments: {
    i18nKey: 'comments',
    defaults: 'Comments',
  },
  documentFailedUploadMessage: {
    i18nKey: 'documentFailedUploadMessage',
    defaults: 'Document upload failed. Please try again.',
  },
  account:{
    i18nKey: 'account',
    defaults: 'Account',
  },
  transactionStatus:{
    i18nKey: 'transactionStatus',
    defaults: 'Transaction Status',
  },
  transactionTypes:{
    i18nKey: 'transactionTypes',
    defaults: 'Transaction Types',
  },
  newsSentimentAnalysis: {
    i18nKey: 'newsSentimentAnalysis',
    defaults: 'News sentiment analysis',
  },
  pending:{
    i18nKey: 'pending',
    defaults: 'Pending',
  },
  rejected:{
    i18nKey: 'rejected',
    defaults: 'Rejected',
  },
  rejectedCompetitionAlert: {
    i18nKey: 'rejectedCompetitionAlert',
    defaults: 'Your competition was rejected by the Partner agent',
  },
  approved:{
    i18nKey: 'approved',
    defaults: 'Approved',
  },
  withdrawal:{
    i18nKey: 'withdrawal',
    defaults: 'Withdrawal',
  },
  time:{
    i18nKey: 'time',
    defaults: 'Time',
  },
  type:{
    i18nKey: 'type',
    defaults: 'Type',
  },
  amount:{
    i18nKey: 'amount',
    defaults: 'Amount',
  },
  filters:{
    i18nKey: 'filters',
    defaults: 'Filters',
  },
  generalWithdrawalDescription: {
    i18nKey: 'generalWithdrawalDescription',
    defaults: '{{company}} reserves the right to charge a fee of 10 EUR (or trading account currency equivalent) where the withdrawal amount is below or equal to 20 EUR (or trading account currency equivalent).',
  },
  generalWithdrawalDescriptionPartner: {
    i18nKey: 'generalWithdrawalDescriptionPartner',
    defaults: 'Any payment method used may generate withdrawal fees, the amount of which varies depending on the method chosen and/or the value of the transaction.',
  },
  creditCardWithdrawalDescription1: {
    i18nKey: 'creditCardWithdrawalDescription1',
    defaults: 'All deposits must be withdrawn back to the same credit/debit card before other methods may be offered for withdrawal.',
  },
  creditCardWithdrawalOldlDescription2: {
    i18nKey: 'creditCardWithdrawalOldlDescription2',
    defaults: 'Please note that you can withdraw the total sum of the deposits you placed via credit card back to the same card with no additional charge. For Credit Card withdrawals exceeding the sum of your deposits, an additional €4 fee will apply.',
  },
  creditCardWithdrawalDescription3: {
    i18nKey: 'creditCardWithdrawalDescription3',
    defaults: 'Please note that you can withdraw the total sum of the deposits you placed via credit card back to the same card with no additional charge. Please note that there are no credit card withdrawal fees for trading profits.',
  },
  eWalletWithdrawalDescription1: {
    i18nKey: 'eWalletWithdrawalDescription1',
    defaults: 'Ensure to submit your withdrawal request back to the same e-wallet / e-mail address you used for deposit/s.',
  },
  bankTransferWithdrawalDescription1: {
    i18nKey: 'bankTransferWithdrawalDescription1',
    defaults: 'Please ensure to submit your withdrawal to the same bank account that you deposited with. In case when you didn’t previously deposit using bank wire, please email us your bank statement showing your name and the bank details you wish to withdraw your funds to.',
  },
  bankTransferWithdrawalDescription2: {
    i18nKey: 'bankTransferWithdrawalDescription2',
    defaults: 'Withdrawals by Bank Wire are processed within {{hours}} hours on working days upon receipt of your withdrawal request. However, the funds are credited to your bank account within 2-5 working days<1> for SEPA transfers and 7-10 working days for International transfers</1>.',
  },
  bankTransferWithdrawalDescription3: {
    i18nKey: 'bankTransferWithdrawalDescription3',
    defaults: 'The minimum withdrawable net amount (i.e. amount after the deduction of fees) for international transfers is 50 EUR and 5 EUR for SEPA transfers. We are unable to process withdrawals below these limits, thus in such cases, an alternative withdrawal method may be used, if available.',
  },
  bankTransferOldWithdrawalDescription4: {
    i18nKey: 'bankTransferOldWithdrawalDescription4',
    defaults: '{{company}} reserves the right to charge a fixed fee of 10 EUR or trading account currency equivalent on bank wire withdrawals below or equal to 100 EUR or trading account currency equivalent.',
  },
  onlineBankingWithdrawalDescription1: {
    i18nKey: 'onlineBankingWithdrawalDescription1',
    defaults: 'Please ensure to submit your withdrawal to the same bank account that you deposited with. In case you didn’t previously deposit using bank wire, please provide us with bank statement showing your name and the bank details you wish to withdraw your funds to.',
  },
  onlineBankingWithdrawalDescription2: {
    i18nKey: 'onlineBankingWithdrawalDescription2',
    defaults: 'You may be required to provide us with a screenshot of the transactions made to BDSwiss upon deposit/withdrawal, for confirmation of your bank details.',
  },
  coinbaseWithdrawalDescription2: {
    i18nKey: 'coinbaseWithdrawalDescription2',
    defaults: '<0>Please note that your withdrawal amount will be converted to your selected cryptocurrency before reaching your crypto wallet.</0>'
  },
  coinbaseWithdrawalDescription1: {
    i18nKey: 'coinbaseWithdrawalDescription1',
    defaults: 'Please ensure to provide us with the same wallet address that your deposit/s came from, otherwise your withdrawal will be rejected.',
  },
  gigadatWithdrawalFootnote: {
    i18nKey: 'gigadatWithdrawalFootnote',
    defaults: 'Trade-mark of Interac Corp. Used Under Licence.',
  },
  interacWithdrawalFootnote: {
    i18nKey: 'interacWithdrawalFootnote',
    defaults: 'Trade-mark of Interac Corp. Used Under Licence.',
  },
  submitWithdrawalTitle: {
    i18nKey: 'submitWithdrawalTitle',
    defaults: 'Are you sure you want to proceed?',
  },
  edit: {
    i18nKey: 'edit',
    defaults: 'Edit',
  },
  correctDetails: {
    i18nKey: 'correctDetails',
    defaults: 'Please ensure you have provided us with the correct details',
  },
  creditCardWithdrawalSuccess: {
    i18nKey: 'creditCardWithdrawalSuccess',
    defaults: 'Please note that credit card withdrawals are processed automatically but it takes 2-7 working days for the funds to appear in your bank account.',
  },
  eWalletWithdrawalSuccess: {
    i18nKey: 'eWalletWithdrawalSuccess',
    defaults: 'Please note that it takes up to 1 hour for the funds to reflect on your e-wallet account, once your withdrawal has been approved by our Back Office Team.',
  },
  bankTransferWithdrawalSuccess: {
    i18nKey: 'bankTransferWithdrawalSuccess',
    defaults: 'Please note that it takes (on average) up to {{days}} working days for the funds to reflect on your bank account, once your withdrawal has been approved by our Back Office Team.',
  },
  onlineBankingWithdrawalSuccess: {
    i18nKey: 'onlineBankingWithdrawalSuccess',
    defaults: 'Please note that it takes up to 3 days for the funds to reflect on your bank account, once your withdrawal has been approved by our Back Office Team.',
  },
  coinbaseWithdrawalSuccess: {
    i18nKey: 'coinbaseWithdrawalSuccess',
    defaults: 'Please note that it takes 1-2 hours for the funds to reflect on your wallet account, once your withdrawal has been approved by our Back Office Team.',
  },
  createAccountSuccess: {
    i18nKey: 'createAccountSuccess',
    defaults: 'New account has been created.'
  },
  openTime: {
    i18nKey: 'openTime',
    defaults: 'Open time'
  },
  symbol: {
    i18nKey: 'symbol',
    defaults: 'Symbol'
  },
  direction: {
    i18nKey: 'direction',
    defaults: 'Direction'
  },
  multiplier: {
    i18nKey: 'multiplier',
    defaults: 'Multiplier'
  },
  value: {
    i18nKey: 'value',
    defaults: 'Value'
  },
  profit: {
    i18nKey: 'profit',
    defaults: 'Profit'
  },
  tradesHeader_open: {
    i18nKey: 'tradesHeaderOpen',
    defaults: 'My open trades',
  },
  tradesHeader_closed: {
    i18nKey: 'tradesHeaderClosed',
    defaults: 'My closed trades',
  },
  tradesHeader_pending: {
    i18nKey: 'tradesHeaderPending',
    defaults: 'My pending trades',
  },
  tradesEmpty_open: {
    i18nKey: 'tradesEmptyOpen',
    defaults: 'You do not have any open trades',
  },
  tradesEmpty_closed: {
    i18nKey: 'tradesEmptyClosed',
    defaults: 'You do not have any closed trades',
  },
  tradesEmpty_pending: {
    i18nKey: 'tradesEmptyPending',
    defaults: 'You do not have any pending trades',
  },
  personalProfileCard: {
    i18nKey: 'personalProfileCard',
    defaults: 'Personal Profile'
  },
  firstName: {
    i18nKey: 'firstName',
    defaults: 'First Name'
  },
  lastName: {
    i18nKey: 'lastName',
    defaults: 'Last Name'
  },
  dateOfBirth: {
    i18nKey: 'dateOfBirth',
    defaults: 'Date of birth'
  },
  secondaryEmailText: {
    i18nKey: 'secondaryEmailText',
    defaults: 'Add Secondary email'
  },
  secondaryEmailLabel: {
    i18nKey: 'secondaryEmailLabel',
    defaults: 'Secondary Email'
  },
  phone: {
    i18nKey: 'phone',
    defaults: 'Phone'
  },
  secondaryPhoneText: {
    i18nKey: 'secondaryPhoneText',
    defaults: 'Add Secondary phone'
  },
  secondaryPhoneLabel: {
    i18nKey: 'secondaryPhoneLabel',
    defaults: 'Secondary Phone'
  },
  streetLabel: {
    i18nKey: 'streetLabel',
    defaults: 'Street Name'
  },
  cityLabel: {
    i18nKey: 'cityLabel',
    defaults: 'City'
  },
  districtlabel: {
    i18nKey: 'districtLabel',
    defaults: 'District'
  },
  houseNumberLabel: {
    i18nKey: 'houseNumberLabel',
    defaults: 'House Number'
  },
  zipLabel: {
    i18nKey: 'zipLabel',
    defaults: 'Postal Code'
  },
  regionLabel: {
    i18nKey: 'regionLabel',
    defaults: 'Region'
  },
  stateLabel: {
    i18nKey: 'stateLabel',
    defaults: 'State'
  },
  countryLabel: {
    i18nKey: 'countryLabel',
    defaults: 'Country of residence'
  },
  nationalityLabel: {
    i18nKey: 'nationalityLabel',
    defaults: 'Nationality'
  },
  saveButtonSettings: {
    i18nKey: 'saveButtonSettings',
    defaults: 'Save'
  },
  kycSettingsCard: {
    i18nKey: 'kycSettingsCard',
    defaults: 'KYC documentation'
  },
  profileSettingsCard: {
    i18nKey: 'profileSettingsCard',
    defaults: 'Personal information'
  },
  apTestSettingsCard: {
    i18nKey: 'apTestSettingsCard',
    defaults: 'For forex and other trading accounts only'
  },
  dueDiligenceSettingsCard: {
    i18nKey: 'dueDiligenceSettingsCard',
    defaults: 'Due Diligence questionnaire'
  },
  kycSettingsCardTitle: {
    i18nKey: 'kycSettingsCardTitle',
    defaults: 'Verification Documents'
  },
  profileSettingsCardTitle: {
    i18nKey: 'profileSettingsCardTitle',
    defaults: 'Personal Profile'
  },
  profileSettingsTooltip1: {
    i18nKey: 'profileSettingsTooltip1',
    defaults: 'Personal profile is filled'
  },
  profileSettingsTooltip1Pending: {
    i18nKey: 'profileSettingsTooltip1Pending',
    defaults: 'Personal profile is pending'
  },
  profileSettingsTooltip2: {
    i18nKey: 'profileSettingsTooltip2',
    defaults: 'Economic profile is filled'
  },
  profileSettingsTooltip2Pending: {
    i18nKey: 'profileSettingsTooltip2Pending',
    defaults: 'Economic profile is pending'
  },
  documentSettingsStatus: {
    i18nKey: 'documentSettingsStatus',
    defaults: 'uploaded'
  },
  settingsProfileTab: {
    i18nKey: 'settingsProfileTab',
    defaults: 'Profile'
  },
  privacy: {
    i18nKey: 'privacy',
    defaults: 'Privacy'
  },
  changePassword: {
    i18nKey: 'changePassword',
    defaults: 'Change Password'
  },
  personalDetails: {
    i18nKey: 'personalDetails',
    defaults: 'Personal Details'
  },
  personalDetailsLowerCase: {
    i18nKey: 'personalDetailsLowerCase',
    defaults: 'Personal details',
  },
  economicProfile: {
    i18nKey: 'economicProfile',
    defaults: 'Economic profile'
  },
  financialDetails: {
    i18nKey: 'financialDetails',
    defaults: 'Financial Details',
  },
  financialDetailsLowerCase: {
    i18nKey: 'financialDetailsLowerCase',
    defaults: 'Financial details',
  },
  webtraderWelcomeTitle: {
    i18nKey: 'webtraderWelcomeTitle',
    defaults: 'You have successfully registered',
  },
  webtraderWelcomePracticeCredit: {
    i18nKey: 'webtraderWelcomePracticeCredit',
    defaults: 'Credited to your practice account:',
  },
  webtraderWelcomeRealAccountCreated: {
    i18nKey: 'webtraderWelcomeRealAccountCreated',
    defaults: 'We have also created a real account for you:',
  },
  webtraderWelcomeNoWithdrawals: {
    i18nKey: 'webtraderWelcomeNoWithdrawals',
    defaults: 'No withdrawals',
  },
  webtraderWelcomeEasyWithdrawals: {
    i18nKey: 'webtraderWelcomeEasyWithdrawals',
    defaults: 'Easy withdrawals',
  },
  webtraderWelcomeFreeTopUp: {
    i18nKey: 'webtraderWelcomeFreeTopUp',
    defaults: 'Free top-up',
  },
  webtraderWelcomeMinAmount: {
    i18nKey: 'webtraderWelcomeMinAmount',
    defaults: 'Minimum amount {amount}',
  },
  webtraderWelcomeStartTradingTitle: {
    i18nKey: 'webtraderWelcomeStartTradingTitle',
    defaults: 'START TRADING',
  },
  webtraderWelcomeStartTradingDemoSubtitle: {
    i18nKey: 'webtraderWelcomeStartTradingDemoSubtitle',
    defaults: 'on a practice account',
  },
  webtraderWelcomeStartTradingRealSubtitle: {
    i18nKey: 'webtraderWelcomeStartTradingRealSubtitle',
    defaults: 'on a real account',
  },
  authKeyError: {
    i18nKey: 'authKeyError',
    defaults: 'Could not authenticate, please try later.',
  },
  positiveNumber: {
    i18nKey: 'positiveNumber',
    defaults: 'Amount has to be a positive number',
  },
  resetPassword: {
    i18nKey: 'resetPassword',
    defaults: 'Reset Password',
  },
  backToLogin: {
    i18nKey: 'backToLogin',
    defaults: 'Back to login page'
  },
  forgotPasswordNotification: {
    i18nKey: 'forgotPasswordNotification',
    defaults: 'Password reset request submitted. You will receive an email shortly to confirm your password reset request '
  },
  passwordMismatch: {
    i18nKey: 'passwordMismatch',
    defaults: 'Passwords do not match'
  },
  resetPasswordNotification: {
    i18nKey: 'resetPasswordNotification',
    defaults: 'Password reset successful. You can now login with your new password'
  },
  changeLeverageNotice: {
    i18nKey: 'changeLeverageNotice',
    defaults: 'Please note that the current leverage only affects when trading currencies (forex)',
  },
  changeLeverageNoticeExample: {
    i18nKey: 'changeLeverageNoticeExample',
    defaults: 'For example, if your leverage is 1:400, then this will only impact trading on currencies such as EUR/USD or EUR/GBP. Commodities and indices stay at fixed leverage', // eslint-disable-line max-len
  },
  contactSupportForMore: {
    i18nKey: 'contactSupportForMore',
    defaults: 'For more information please contact our support team.',
  },
  successfulEmailConfirmation: {
    i18nKey: 'successfulEmailConfirmation',
    defaults: 'Email was successfully confirmed.',
  },
  failedEmailConfirmation: {
    i18nKey: 'failedEmailConfirmation',
    defaults: 'Email confirmation failed.',
  },
  language: {
    i18nKey: 'language',
    defaults: 'Language'
  },
  startTrading: {
    i18nKey: 'startTrading',
    defaults: 'To starting trading',
  },
  step: {
    i18nKey: 'step',
    defaults: 'Step {{number}}',
  },
  fundYourAccount: {
    i18nKey: 'campaignPortalFundYourAccount',
    defaults: '<0>Fund your account</0> to starting trading',
  },
  downloadMt4App: {
    i18nKey: 'downloadMt4App',
    defaults: '<0>Download</0> the {{product}} App',
  },
  openMt4App: {
    i18nKey: 'openMt4App',
    defaults: 'Open the {{product}} mobile App',
  },
  addAccountClickLogin: {
    i18nKey: 'addAccountClickLogin',
    defaults: 'Add your account by clicking to "<0>Login to an existing account</0>"',
  },
  searchBdswiss: {
    i18nKey: 'searchBdswiss',
    defaults: 'Search for BDSwiss and select <0>{{server}}</0>',
  },
  writeOrScreenshot: {
    i18nKey: 'writeOrScreenshot',
    defaults: 'Write these details down or take a screenshot!',
  },
  name: {
    i18nKey: 'name',
    defaults: 'Name',
  },
  notifications: {
    i18nKey: 'notifications',
    defaults: 'Notifications'
  },
  more: {
    i18nKey: 'more',
    defaults: 'More'
  },
  accounts: {
    i18nKey: 'accounts',
    defaults: 'Accounts'
  },
  logout: {
    i18nKey: 'logout',
    defaults: 'Logout'
  },
  settingsSpoaTitle: {
    i18nKey: 'settingsSpoaTitle',
    defaults: 'SPOA Authorisation',
  },
  settingsSpoaCaption: {
    i18nKey: 'settingsSpoaCaption',
    defaults: 'Grant special power of attorney to your IB',
  },
  spoaAuthorization: {
    i18nKey: 'spoaAuthorization',
    defaults: 'You have granted SPOA Authorisation to {{firstName}} {{lastName}} ({{id}}).'
  },
  spoaAuthorizationMissing: {
    i18nKey: 'spoaAuthorizationMissing',
    defaults: 'Click to grant SPOA Authorisation.'
  },
  spoaAcceptedTerms: {
    i18nKey: 'spoaAcceptedTerms',
    defaults: 'I have read and accepted the <0>Terms and Conditions</0>'
  },
  granted: {
    i18nKey: 'granted',
    defaults: 'Granted'
  },
  ibClientId: {
    i18nKey: 'ibClientId',
    defaults: 'IB Client ID'
  },
  grantAuthorization: {
    i18nKey: 'grantAuthorization',
    defaults: 'Grant Authorisation'
  },
  revokeAuthorization: {
    i18nKey: 'revokeAuthorization',
    defaults: 'Revoke Authorisation'
  },
  requiredClientId: {
    i18nKey: 'requiredClientId',
    defaults: 'You have to supply a Client ID'
  },
  requiredAgreeToTerms: {
    i18nKey: 'requiredAgreeToTerms',
    defaults: 'You have to agree to the terms and conditions'
  },
  transferDeposit: {
    i18nKey: 'transferDeposit',
    defaults: 'Transfer Deposit'
  },
  transferWithdrawal: {
    i18nKey: 'transferWithdrawal',
    defaults: 'Transfer Withdrawal'
  },
  transferSpoaFrom: {
    i18nKey: 'transferSpoaFrom',
    defaults: 'Transfer SPOA from {{account}}'
  },
  transferSpoaTo: {
    i18nKey: 'transferSpoaTo',
    defaults: 'Transfer SPOA to {{account}}'
  },
  vpsFee: {
    i18nKey: 'vpsFee',
    defaults: 'VPS Fee'
  },
  inactivityFee: {
    i18nKey: 'inactivityFee',
    defaults: 'Inactivity Fee'
  },
  archived: {
    i18nKey: 'archived',
    defaults: 'Archived'
  },
  archivedMessage: {
    i18nKey: 'archivedMessage',
    defaults: 'This account was archived'
  },
  archivedTooltip: {
    i18nKey: 'archivedTooltip',
    defaults: 'Please contact support'
  },
  demo: {
    i18nKey: 'demo',
    defaults: 'Demo'
  },
  mifir: {
    i18nKey: 'mifir',
    defaults: 'MiFIR',
  },
  mifirPopupMessage: {
    i18nKey: 'mifirPopupMessage',
    defaults: 'To comply with the Markets in Financial Instruments Regulation ("MiFIR") we must report all client transactions. Hereof, it is compulsory to request and verify you National Client Identifier ("NCI") which is based on your citizenship and must be assigned in accordance with the highest priority identifier available.',
  },
  mifirPopupNationalitySelectLabel: {
    i18nKey: 'mifirIdNationalitySelectLabel',
    defaults: 'Please enter your nationality: ',
  },
  mifirPopupSelectLabel: {
    i18nKey: 'mifirIdSelectLabel',
    defaults: 'Please choose your NCI document type on the field below: ',
  },
  mifirPopupInputLabel: {
    i18nKey: 'mifirPopupInputLabel',
    defaults: 'Please enter your NCI number on the field below: ',
  },
  mifirPopuplFirstOptionsPortugal: {
    i18nKey: 'mifirPopupFirstOptionPortugal',
    defaults: 'First Priority Identifier: Your Citizen Card or Taxpayer card which indicates your Tax Idntification Number ("TIN").',
    countriesAvailable: [nationalities.portuguese.key],
    defaultMifirType: 'nid',
  },
  mifirPopupSecondOptionsPortugal: {
    i18nKey: 'mifirPopupSecondOptionPortugal',
    defaults: 'Second Priority Identifier: Your Passport in case it was not already submitted and the first priority is not available.',
    countriesAvailable: [nationalities.portuguese.key],
    defaultMifirType: 'pid',
  },
  mifirPopupFirstOptionUK: {
    i18nKey: 'mifirPopupFirstOptionUK',
    defaults: 'Any document which indicates your UK National Insurance Number ("NINO").',
    countriesAvailable: [nationalities.british.key],
    defaultMifirType: 'nid',
  },
  mifirPopupFirstOptionGreece: {
    i18nKey: 'mifirPopupFirstOptionGreece',
    defaults: 'A document which indicates your 10 Dematerialised Securities System ("DSS") digit investor share.',
    countriesAvailable: [nationalities.greek.key],
    defaultMifirType: 'nid',
  },
  mifirPopupFirstOptionPoland: {
    i18nKey: 'mifirPopupFirstOptionPoland',
    defaults: 'First Priority Identifier: Your National ID or Driving Licence which indicates your National Identification Number (PESEL).',
    countriesAvailable: [nationalities.polish.key],
    defaultMifirType: 'nid',
  },
  mifirPopupSecondOptionPoland: {
    i18nKey: 'mifirPopupSecondOptionPoland',
    defaults: 'Second Priority Identifier: Your Tax Number (identyfikacji podatkowej).',
    countriesAvailable: [nationalities.polish.key],
    defaultMifirType: 'nid',
  },
  mifirPopupFirstOptionSpain: {
    i18nKey: 'mifirPopupFirstOptionSpain',
    defaults: 'Your Tax Identification Number (Código de identificación fiscal) existing on your National Identification Card, your driving licence or your social secutiry card.',
    countriesAvailable: [nationalities.spanish.key],
    defaultMifirType: 'nid',
  },
  mifirPopupSecondOptionItaly: {
    i18nKey: 'mifirPopupSecondOptionItaly',
    defaults: 'Your Tax Identification Number (Codice Fiscale) existing on your Fiscal Code Card, or your Health Card.',
    countriesAvailable: [nationalities.italian.key],
    defaultMifirType: 'nid',
  },
  mifirPopupFirstOptionBulgaria: {
    i18nKey: 'mifirPopupFirstOptionBulgaria',
    defaults: 'Your Personal Identification Code/Number existing on your National ID Card.',
    countriesAvailable: [nationalities.bulgarian.key, nationalities.danish.key, nationalities.finnish.key, nationalities.croatian.key, nationalities.latvian.key, nationalities.norwegian.key, nationalities.swedish.key, nationalities.slovene.key],
    defaultMifirType: 'nid',
  },
  mifirPopupFirstOptionLithuania: {
    i18nKey: 'mifirPopupFirstOptionLithuania',
    defaults: 'First Priority Identifier: Your Personal Identification Code/Number existing on your National ID Card.',
    countriesAvailable: [nationalities.czech.key, nationalities.lithuanian.key, nationalities.romanian.key, nationalities.slovak.key],
    defaultMifirType: 'nid',
  },
  mifirPopupSecondOptionLithuania: {
    i18nKey: 'mifirPopupSecondOptionLithuania',
    defaults: 'Second Priority Identifier: Your Passport in case your ID card is not available.',
    countriesAvailable: [nationalities.czech.key, nationalities.lithuanian.key, nationalities.romanian.key, nationalities.slovak.key],
    defaultMifirType: 'pid',
  },
  mifirPopupFirstOptionNetherlands: {
    i18nKey: 'mifirPopupFirstOptionNetherlands',
    defaults: 'First Priority Identifier: Your Passport ID Number.',
    countriesAvailable: [nationalities.liechtensteiner.key, nationalities.dutch.key],
    defaultMifirType: 'pid',
  },
  mifirPopupSecondOptionNetherlands: {
    i18nKey: 'mifirPopupSecondOptionNetherlands',
    defaults: 'Second Priority Identifier: Your National ID Card in case the Passport is not available.',
    countriesAvailable: [nationalities.liechtensteiner.key, nationalities.dutch.key],
    defaultMifirType: 'nid',
  },
  mifirPopupFirstOptionMalta: {
    i18nKey: 'mifirPopupFirstOptionMalta',
    defaults: 'First Priority Identifier: Your National ID Card.',
    countriesAvailable: [nationalities.maltese.key],
    defaultMifirType: 'nid',
  },
  mifirPopupSecondOptionMalta: {
    i18nKey: 'mifirPopupSecondOptionMalta',
    defaults: 'Second Priority Identifier: Your Passport in case the ID card is not available.',
    countriesAvailable: [nationalities.maltese.key],
    defaultMifirType: 'pid',
  },
  mifirPopupFirstOptionEstonia: {
    i18nKey: 'mifirPopupFirstOptionEstonia',
    defaults: 'Your Personal Identification Code (Isikukood) existing on the National ID.',
    countriesAvailable: [nationalities.estonian.key],
    defaultMifirType: 'nid',
  },
  mifirPopupFirstOptionIceland: {
    i18nKey: 'mifirPopupFirstOptionIceland',
    defaults: 'Your Personal Identification Code (Kennitala) existing on the Passport.',
    countriesAvailable: [nationalities.icelander.key],
    defaultMifirType: 'nid',
  },
  mifirPopupGeneral: {
    i18nKey: 'mifirPopupGeneral',
    defaults: 'Your Passport in case it was not already submitted.',
    defaultMifirType: 'pid',
  },
  mifirSuccessfulUpdate: {
    i18nKey: 'mifirSuccessfulUpdate',
    defaults: 'Your MiFIR details have been successfully updated.',
  },
  mifirFailedUpdate: {
    i18nKey: 'mifirFailedUpdate',
    defaults: 'Your MifIR details have failed to be updated.',
  },
  requestCallback: {
    i18nKey: 'requestCallback',
    defaults: 'Request Callback'
  },
  spreadsDepending: {
    i18nKey: 'spreadsDepending',
    defaults: '1. Trading Alerts are provided by BDSwiss’ professional traders in real time via our VIP Telegram Channel. All Black Account holders are given automatic access to the channel.'
  },
  noCommisions: {
    i18nKey: 'noCommisions',
    defaults: '2. No Commissions apply on Index products.'
  },
  subjectAppropriate: {
    i18nKey: 'subjectAppropriate',
    defaults: '3. Spreads can vary depending on the asset and type of account you choose to trade with and may increase the costs of your trades. View our full list of assets and average spreads.'
  },
  averageSuccess: {
    i18nKey: 'averageSuccess',
    defaults: '4. Priority of Transactions i.e. faster withdrawal processing.'
  },
  joinVipTelegram: {
    i18nKey: 'joinVipTelegram',
    defaults: 'Join our VIP Telegram channel to receive live trading alerts'
  },
  alreadyBlack: {
    i18nKey: 'alreadyBlack',
    defaults: 'Already a Black Account Holder?'
  },
  askManager: {
    i18nKey: 'askManager',
    defaults: 'Ask your Account Manager to add you to the channel today!'
  },
  riskWarningTitle: {
    i18nKey: 'riskWarningTitle',
    defaults: 'Risk Warning:'
  },
  riskWarning: {
    i18nKey: 'riskWarning',
    defaults: 'CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage. {{percentage}} % of retail investor accounts lose money when trading CFDs with this provider. You should consider whether you understand how CFDs work and whether you can afford to take the high risk of losing your money. Swiss Markets and BDSwiss are registered trademarks of BDSwiss Holding Ltd. BDSwiss Holding Ltd is authorized and regulated by the Cyprus Securities and Exchange Commission (the <2>“CySEC“</2>, license no. 199/13 and registration no. HE 300153). The address of BDSwiss Holding Ltd is Apostolou Andrea Street 11, Hyper Tower, 5th Floor, 4007 Mesa Yeitonia, Limassol, Cyprus. Please review the company policies regarding the regulation <0>here</0>. <3 />Registered address: Ioanni Stylianou 6, 2nd floor, Office 202, 2003 Nicosia, Cyprus. <4 />German address (for postal letters): Mainzer Landstrasse 41, 60329-Frankfurt, Germany'
  },
  riskWarningMau: {
    i18nKey: 'riskWarningMau',
    defaults: 'Trading in Forex/ CFDs and Other Derivatives is highly speculative and carries a high level of risk. It is possible to lose all your capital. These products may not be suitable for everyone and you should ensure that you understand the risks involved. Seek independent advice if necessary. Speculate only with funds that you can afford to lose. BDS Markets is an investment firm, incorporated in the Parliamentary Republic of Mauritius (Company Number: 143350) and is authorised and regulated by the Financial Services Commission of Mauritius (<2>FSC</2>) under License Number: C116016172. Registered address: BDS Markets, 6th Floor, Tower 1, Nexteracom Building 72201 Ebene – Mauritius. Please review the company policies regarding the regulation <0>here</0>.'
  },
  riskWarningVa: {
    i18nKey: 'riskWarningVa',
    defaults: 'Trading in Forex/ CFDs and Other Derivatives is highly speculative and carries a high level of risk. It is possible to lose all your capital. These products may not be suitable for everyone and you should ensure that you understand the risks involved. Seek independent advice if necessary. Speculate only with funds that you can afford to lose. Domain usage rights belong to BDS Ltd. BDS Ltd (Registration No. 8424660-1) is authorized and regulated by the Financial Services Authority (the <2>FSA</2>, licence no. SD047) Registered address: Suite 3, Global Village, Jivan’s Complex, Mont Fleuri, Mahe, Seychelles. Please review the company policies regarding the regulation <0>here</0>.'
  },
  riskWarningZa: {
    i18nKey: 'riskWarningZa',
    defaults: 'CFDs are complex instruments and come with a high risk of losing money due to leverage.You should consider whether you understand how CFDs work and whether you can afford to take the high risk of losing your money”. Please read and understand the Company’s <9>Risk Disclosure Notice</9>. <3 /><2>BDSwiss Markets SA (Pty) Ltd is an Authorized and Regulated Financial Services Provider (License Number FSP 49479).  Office address: 90 Rivonia Road, 4th Floor, North Wing, Sandhurst, Sandton, Gauteng 2196, Johannesburg, South Africa. Registered address: Office 32 1st. Floor, 101 Isaiah Ntshangase Road, Stamford Hill Durban, Kwa-Zulu Natal 4001.</2> <5 />BDSwiss Markets SA (Pty) Ltd acts solely as an intermediary in terms of the FAIS Act, rendering only an intermediary service (i.e., no market making is conducted by BDSwiss Markets SA (Pty) Ltd) in relation to derivative products (CFDs) offered by BDS Markets Ltd (i.e., the Product Supplier). <6 />BDSwiss Markets SA (Pty) Ltd does not act as the principal or the counterparty to any of its transactions. BDSwiss does not direct its website and services to any individual in any country in which the use of its website and services are prohibited by local laws or regulations. Upon accessing this website from a country in which its use may or may not be prohibited, it is the user\'s responsibility to ensure that any use of the website or services adhere to local laws or regulations. BDSwiss does not affirm that the information on its website is suitable to all jurisdictions.'
  },
  riskWarningKm: {
    i18nKey: 'riskWarningKm',
    defaults: 'Trading is highly speculative and carries a high level of risk. It is possible to lose all of your invested capital. These products may not be suitable for everyone, and you should ensure that you fully understand the risks taking into consideration your investment objectives, level of experience, personal circumstances as well as personal resources. Speculate only with funds that you can afford to lose. Seek independent advice if necessary. Please refer to our Risk Disclosure. BDSwiss Investments LTD (Registration No. HY00423197) is authorised and regulated by the Mwali International Services Authority (MISA) with License No. T2023244. Registered office: Bonovo Road, Fomboni Island of Mohéli in the Comoros Union. Please review the company policies regarding the regulation here.'
  },
  riskWarningSMEu: {
    i18nKey: 'riskWarningSMEu',
    defaults: 'CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage. {{percentage}} % of retail investor accounts lose money when trading CFDs with this provider. You should consider whether you understand how CFDs work and whether you can afford to take the high risk of losing your money. Swiss Markets and BDSwiss are registered trademarks of BDSwiss Holding Ltd. BDSwiss Holding Ltd is authorized and regulated by the Cyprus Securities and Exchange Commission (the <2>“CySEC“</2>, license no. 199/13 and registration no. HE 300153). The address of BDSwiss Holding Ltd is Apostolou Andrea Street 11, Hyper Tower, 5th Floor, 4007 Mesa Yeitonia, Limassol, Cyprus. Please review the company policies regarding the regulation <0>here</0>. <3 />Registered address: Ioanni Stylianou 6, 2nd floor, Office 202, 2003 Nicosia, Cyprus. <4 />German address (for postal letters): Mainzer Landstrasse 41, 60329-Frankfurt, Germany'
  },
  riskWarningSMMau: {
    i18nKey: 'riskWarningSMMau',
    defaults: 'Trading in Forex/ CFDs and Other Derivatives is highly speculative and carries a high level of risk. It is possible to lose all your capital. These products may not be suitable for everyone and you should ensure that you understand the risks involved. Seek independent advice if necessary. Speculate only with funds that you can afford to lose. BDS Markets is an investment firm, incorporated in the Parliamentary Republic of Mauritius (Company Number: 143350) and is authorised and regulated by the Financial Services Commission of Mauritius (<2>FSC</2>) under License Number: C116016172. Registered address: BDS Markets, 6th Floor, Tower 1, Nexteracom Building 72201 Ebene – Mauritius. Please review the company policies regarding the regulation <0>here</0>. <4 />Swissmarkets.com is operated by BDS Swiss Markets Global Services Ltd which is the primary payment provider and website operator. BDS Swiss Markets Global Services Ltd is acting on behalf of its related company, BDS Markets.'
  },
  riskWarningSMVa: {
    i18nKey: 'riskWarningSMVa',
    defaults: 'Trading in Forex/ CFDs and Other Derivatives is highly speculative and carries a high level of risk. It is possible to lose all your capital. These products may not be suitable for everyone and you should ensure that you understand the risks involved. Seek independent advice if necessary. Speculate only with funds that you can afford to lose. Domain usage rights belong to BDS Ltd. BDS Ltd (Registration No. 8424660-1) is authorized and regulated by the Financial Services Authority (the <2>FSA</2>, licence no. SD047) Registered address: Suite 3, Global Village, Jivan’s Complex, Mont Fleuri, Mahe, Seychelles. Please review the company policies regarding the regulation <0>here</0>.'
  },
  legal: {
    i18nKey: 'legal',
    defaults: 'Legal'
  },
  privacyPolicy: {
    i18nKey: 'privacyPolicy',
    defaults: 'Privacy Policy'
  },
  disclaimer: {
    i18nKey: 'disclaimer',
    defaults: 'Disclaimer'
  },
  termsAndConditions: {
    i18nKey: 'termsAndConditions',
    defaults: 'Terms and conditions'
  },
  regulation: {
    i18nKey: 'regulation',
    defaults: 'Regulation'
  },
  complaints: {
    i18nKey: 'complaints',
    defaults: 'Complaints'
  },
  getAppstore: {
    i18nKey: 'getAppstore',
    defaults: 'Get it on App Store'
  },
  getGoogleplay: {
    i18nKey: 'getGoogleplay',
    defaults: 'Get it on Google Play'
  },
  likeTwitter: {
    i18nKey: 'likeTwitter',
    defaults: 'Like us on Twitter'
  },
  likeFacebook: {
    i18nKey: 'likeFacebook',
    defaults: 'Like us on Facebook'
  },
  followTelegram: {
    i18nKey: 'followTelegram',
    defaults: 'Follow us on Telegram'
  },
  lowerSpreads: {
    i18nKey: 'lowerSpreads',
    defaults: 'Lower Spreads'
  },
  feesDeposits: {
    i18nKey: 'feesDeposits',
    defaults: 'Fees on Deposits & No Commissions'
  },
  priorityTransactions: {
    i18nKey: 'priorityTransactions',
    defaults: 'Priority of Transactions'
  },
  supportPersonalManager: {
    i18nKey: 'supportPersonalManager',
    defaults: 'Dedicated Multilingual Support & Personal Account Manager'
  },
  complimentarySessions: {
    i18nKey: 'complimentarySessions',
    defaults: 'Complimentary 1:1 Trading Session'
  },
  liveAlerts: {
    i18nKey: 'liveAlerts',
    defaults: 'Live Trading Alerts'
  },
  blackTitleCallback: {
    i18nKey: 'blackTitleCallback',
    defaults: 'Learn how you can upgrade to BDSwiss Black Account'
  },
  supportTitleCallback: {
    i18nKey: 'supportTitleCallback',
    defaults: 'Schedule support call-back'
  },
  oneOnOneTitleCallback: {
    i18nKey: 'oneOnOneTitleCallback',
    defaults: 'Book a free one-on-one session'
  },
  fullName: {
    i18nKey: 'fullName',
    defaults: 'Full Name'
  },
  phoneNumber: {
    i18nKey:'phoneNumber',
    defaults: 'Phone Number'
  },
  bdswissEmail: {
    i18nKey:'bdswissEmail',
    defaults: 'BDSwiss email account'
  },
  timeHours: {
    i18nKey:'timeHours',
    defaults: 'Time, hours'
  },
  minutes: {
    i18nKey:'minutes',
    defaults: 'Minutes'
  },
  timeZone: {
    i18nKey:'timezone',
    defaults: 'Timezone'
  },
  preferableLanguage: {
    i18nKey:'preferableLanguage',
    defaults: 'Preferable Language'
  },
  blackAccountNote: {
    i18nKey:'blackAccountNote',
    defaults: '* Please note that our Account Manager will not be providing investment advice of any kind. You are solely responsible for any investment you choose to make using your own funds.'
  },
  tradingAlerts: {
    i18nKey:'tradingAlerts',
    defaults: 'Get real time trading alerts'
  },
  tradingAlertsp1: {
    i18nKey:'tradingAlertsp1',
    defaults: 'Exclusively for '
  },
  tradingAlertsp1Strong: {
    i18nKey:'tradingAlertsp1Strong',
    defaults: 'Exclusively for <0>Black Account Holders</0>'
  },
  tradingAlertsp2: {
    i18nKey:'tradingAlertsp2',
    defaults: 'BDSwiss analysts study the market constantly, taking into consideration all the different parameters that may affect key assets, to provide our VIP clients with live trading alerts via our offcial Telegram channel.'
  },
  tradingAlertsp3: {
    i18nKey:'tradingAlertsp3',
    defaults: 'Get started in two easy steps'
  },
  tradingAlertsStep1: {
    i18nKey:'tradingAlertsStep1Text',
    defaults: '<0>Step1:</0> Download Telegram app on your phone and set up your account'
  },
  tradingAlertsbtn1: {
    i18nKey:'tradingAlertsbtn1',
    defaults: 'Telegram for Android'
  },
  tradingAlertsbtn2: {
    i18nKey:'tradingAlertsbtn2',
    defaults: 'Telegram for iPhone and iPad'
  },
  tradingAlertsbtn3: {
    i18nKey:'tradingAlertsbtn3',
    defaults: 'Telegram for web'
  },
  tradingAlertsStep2: {
    i18nKey:'tradingAlertsStep2',
    defaults: '<0>Step 2:</0> Request to join our VIP Telegram channel'
  },
  accessVipAlertsTextAbove: {
    i18nKey:'accessVipAlertsTextAbove',
    defaults: 'Already <0>Black Account Holder?</0>'
  },
  accessVipAlerts: {
    i18nKey:'accessVipAlerts',
    defaults: 'Access VIP Alerts channel'
  },
  inputReachedMaxLengthError: {
    i18nKey: 'inputReachedMaxLengthError',
    defaults: 'The length of the entered field value exceeds the allowed limit. Please reduce the length of the value'
  },
  joinBdswissTextAbove: {
    i18nKey:'joinBdswissTextAbove',
    defaults: 'Get a taste. Discover benefits.'
  },
  joinBdswiss: {
    i18nKey:'joinBdswiss',
    defaults: 'Join BDSwiss channel'
  },
  viaTelegram: {
    i18nKey:'viaTelegram',
    defaults: 'Via <0>Telegram</0>'
  },
  skipWaitingListMessage: {
    i18nKey:'skipWaitingListMessage',
    defaults: '<0>BDSwiss Black Accounts</0> get priority for all KYC document processing and fund withdrawals among many other benefits such as tighter spreads.'
  },
  upgradeNow: {
    i18nKey:'upgradeNow',
    defaults: 'Upgrade now'
  },
  skip: {
    i18nKey:'skip',
    defaults: 'How to skip the waiting list'
  },
  isReadonlyAccount: {
    i18nKey: 'isReadonlyAccount',
    defaults: 'Your account is currently readonly.',
  },
  accountReadonlyVerificationNeeded: {
    i18nKey: 'accountReadonlyVerificationNeeded',
    defaults: 'Your account is currently readonly, as it is not fully verified yet. <0> Click to verify now. </0>',
  },
  isReadonlyAccountWithReason: {
    i18nKey: 'isReadonlyAccountWithReason',
    defaults: 'Your account is currently readonly. Reason:',
  },
  financialInfo: {
    i18nKey: 'financialInfo',
    defaults: 'Financial info',
  },
  managedAccount: {
    i18nKey: 'managedAccount',
    defaults: 'Managed Account',
  },
  agreeContinue: {
    i18nKey: 'agreeContinue',
    defaults: 'Agree & Continue',
  },
  dearClient: {
    i18nKey: 'dearClient',
    defaults: 'Dear Client,',
  },
  pammDisclaimer1: {
    i18nKey: 'pammDisclaimer1',
    defaults: 'Managed Accounts are not offered to clients of BDSwiss Holding Ltd (CYSEC).'
  },
  pammDisclaimer2: {
    i18nKey: 'pammDisclaimer2',
    defaults: 'Clients wishing to operate a Managed Account must do so with BDS Markets, a regulated entity with the FSC.'
  },
  pammDisclaimer3: {
    i18nKey: 'pammDisclaimer3',
    defaults: 'BDS Markets is a BDSwiss Group Company licensed with the FSC.'
  },
  pammDisclaimer4: {
    i18nKey: 'pammDisclaimer4',
    defaults: 'By continuing and accepting, you acknowledge and accept the following:'
  },
  pammDisclaimerMessage1: {
    i18nKey: 'pammDisclaimerMessage1',
    defaults: 'You allow BDSwiss Holding Ltd to forward your personal information to BDS Markets for account opening and operations.',
  },
  pammDisclaimerMessage2: {
    i18nKey: 'pammDisclaimerMessage2',
    defaults: 'You accept BDSwiss Holding Ltd to act only as a payment intermediary for the Managed Account on behalf of BDS Markets.',
  },
  pammDisclaimerMessage3: {
    i18nKey: 'pammDisclaimerMessage3',
    defaults: 'You are bound by the <0>Terms and Conditions</0> of BDS Markets',
  },
  commission: {
    i18nKey: 'commission',
    defaults: 'Commission',
  },
  partnershipPlatform: {
    i18nKey: 'partnershipPlatform',
    defaults: 'Partnership platform',
  },
  inProgress: {
    i18nKey:'inProgress',
    defaults: 'In progress'
  },
  inQueue: {
    i18nKey:'inQueue',
    defaults: 'Position {{position}} in queue'
  },
  downloadMT4:{
    i18nKey:'downloadMT4',
    defaults: 'Download {{product}}'
  },
  footer: {
    i18nKey:'footer',
    defaults: 'Your account is operated by <0>{{company}}</0>, a company belonging to the <1>BDSwiss</1> group of companies. Please ensure  that you are aware of all our <2>legal documents</2>, <3>T&Cs</3> and <4>Privacy Policy</4>.<5> </5>'
  },
  footerBinary: {
    i18nKey:'footerBinary',
    defaults: 'Your account is operated by <0>{{company}}</0>, a company belonging to the <1>BDSwiss</1> group of companies. Please ensure  that you are aware of all our <2>legal documents</2>, <3>T&Cs</3>, <4>Binary Options T&Cs</4> and <5>Privacy Policy</5>.<6> </6>'
  },
  openTrades: {
    i18nKey: 'openTrades',
    defaults: 'Open trades',
  },
  closedTrades: {
    i18nKey: 'closedTrades',
    defaults: 'Closed trades',
  },
  pendingTrades: {
    i18nKey: 'pendingTrades',
    defaults: 'Pending trades',
  },
  sendComplaint: {
    i18nKey: 'sendComplaint',
    defaults: '<0>Send Complaint</0>',
  },
  depositNow: {
    i18nKey: 'depositNow',
    defaults: 'Deposit now',
  },
  outstandingActivationDeposit: {
    i18nKey: 'outstandingActivationDeposit',
    defaults: 'Your account is currently readonly and needs to be funded before activation'
  },
  personalDetailsChanged: {
    i18nKey: 'personalDetailsChanged',
    defaults: 'Personal details submitted successfully!'
  },
  economicProfileChanged: {
    i18nKey: 'economicProfileChanged',
    defaults: 'Economic profile submitted successfully!'
  },
  personalDetailsChangedDashboardLink: {
    i18nKey: 'personalDetailsChangedDashboardLink',
    defaults: 'Personal details submitted successfully!'
  },
  oneOnOne: {
    i18nKey: 'oneOnOne',
    defaults: 'One on One'
  },
  oneOnOnep1: {
    i18nKey: 'oneOnOnep1',
    defaults: 'Book your 5-minute slot with one of our expert trading coaches and access the training you need to redefine your trading, they can help you get started and discuss any personal challenges you may be facing. You get to choose the topic!'
  },
  bookCall: {
    i18nKey: 'bookCall',
    defaults: 'Book a call'
  },
  bookCallp1: {
    i18nKey: 'bookCallp1',
    defaults: 'Have any questions about your account settings, dashboard, verification process or trading platform?'
  },
  bookCallp2: {
    i18nKey: 'bookCallp2',
    defaults: 'Have a chat with a support representative at your preferred time and date by requesting a free call-back.'
  },
  bookCallp3: {
    i18nKey: 'bookCallp3',
    defaults: 'They’ll walk you through the platform and help you discover the tools only the pros know about.'
  },
  transferFunds: {
    i18nKey: 'transferFunds',
    defaults: 'Transfer funds'
  },
  isSuspendedTradingStatusWithReason: {
    i18nKey: 'isSuspendedTradingStatusWithReason',
    defaults: 'Your trading status is currently suspended.',
  },
  withdrawalAccountWithoutBalance: {
    i18nKey: 'withdrawalAccountWithoutBalance',
    defaults: 'You do not have any available funds for withdrawal. Fund your account first.'
  },
  withdrawalAccountWithoutBalanceWithoutDeposit: {
    i18nKey: 'withdrawalAccountWithoutBalanceWithoutDeposit',
    defaults: 'You do not have any available funds for withdrawal.'
  },
  apply: {
    i18nKey: 'apply',
    defaults: 'Apply'
  },
  noTransactions: {
    i18nKey: 'noTransactions',
    defaults: 'There are no transactions.'
  },
  noticeMauritiusMigrationTerms: {
    i18nKey: 'notice_mauritius_migration_terms',
    defaults: `Dear Client,{lineBreak}{lineBreak}
    Due to recent changes in the regulatory environment, we were mandated to transfer your account to another BDSwiss entity within the BDSwiss Group.{lineBreak}
    Please note that we have taken various provisions to make this transition as seamless as possible for our clients. {lineBreak}
    And in that effort we want to reassure you that this transfer will not impact the business relationship between you and the Company. {lineBreak}
    The transfer has no impact on your account balance, trading activity or your history in any way, meaning that you will be able to continue trading as normal, on a different BDSwiss site.{lineBreak}
    You are advised to read the <a href="https://global.bdswiss.com/general-terms-and-conditions/">Terms and Conditions</a> of BDS Markets prior to proceeding.{lineBreak}{lineBreak}
    Should you have any questions regarding the transfer please do not hesitate to contact us at <b>xfer@bdswiss.com</b>.{lineBreak}{lineBreak}
    You were automatically redirected to a different BDSwiss site. We kindly ask you to acknowledge this transition.{lineBreak}
    By clicking on the link below you agree to your account's transfer. {lineBreak}
    And again, we are available for any question you might have, just reach out to us at <b>xfer@bdswiss.com</b>.{lineBreak}{lineBreak}`,
  },
  frenchDisclaimer: {
    i18nKey: 'french_disclaimer',
    defaults: `<div>
      <p>Dear Customer,</p>
      <p> Due to recent new regulations in France for Investor Protection we must take extra precautions for French customers to
        understand the risks involved in trading highly speculative products which {company} offers. </p>
      <p> You hereby acknowledge:
        <ul>
          <li>That you fully understand the risks involved in trading binary and or CFDs contracts</li>
          <li>You understand that your losses may exceed your winnings </li>
          <li>Although the account balance may shall never be negative it may be reduced to 0 if your speculative trades are unsuccessful</li>
          <li>You were not incentivised by {company} or its agents to trade</li>
          <li>You were not solicited by {company} to open an account with {company}</li>
          <li>You deem yourself suitable for trading the products which {company} offers and can demonstrate it upon request. </li>
        </ul>
      </p>
    </div>`,
  },
  clientAgreementChanges20180420: {
    i18nKey: 'client_agreement_changes_20180420',
    defaults: `<div style="text-align: justify">
      <h3>Client Agreement Changes</h3>
      <p> Dear Trader,</p>
      <p> This is a formal announcement in relation to amendments we have made in our Client Agreement.</p>
      <p> We invite you to review the new version of the Client Agreement governing the relationship between {trademark}. and its clients.</p>
      <p>The new version of the Client Agreement will come into force 7 calendar days after the date of the publication of this present notice 20th April 2017.</p>
      <p>
        <b> Important: </b>
          If you do not agree with the conditions of the new version of the Client Agreement, within 7 calendar days of the publication of the present notice,
          you must send a request to terminate your account to support@{emailDomain}.
          If such a request is not received within 7 calendar days from the date of publication of the present notice, the updated Client Agreement will automatically be deemed to have your approval and come into effect.
          If you have any questions, feel free to contact our multilingual, friendly Customer Support who are ready to assist you Monday - Friday, from 09:00 to 18:00 (GMT+3).
      </p>
    </div>`,
  },
  gdprMessage: {
    i18nKey: 'gdpr_message',
    defaults: `<div style="text-align: justify">
    <h3>Client Agreement Changes</h3>
    <p> Dear Client,</p>
    <p> Please note that starting May 25th, we will update our Privacy Policy and Terms and Conditions in accordance with the new EU Data Protection Law which safeguards your personal information from corruption, compromise or loss. </p>
  </div>`,
  },
  genericTermAndConditionUpdate: {
    i18nKey: 'generic_term_and_condition_update',
    defaults: `<div style="text-align: justify">
      <h3> Important Notice: Update to Terms and Conditions </h3>
      <p> Dear Client, </p> <p>  Please see our updated <a target="_blank" href={termsAndConditions}> Terms and Conditions </a>.</p>
      <p>We would like to draw your attention to the changes in the withdrawal terms, please read carefully. </p>
    </div>`,
  },
  blackAccountDateNote: {
    i18nKey: 'blackAccountDateNote',
    defaults: 'Please select the date and time at which you would like to be contacted between Monday to Friday 09:30 and 19:30 EEST time.',
  },
  supportAccountDateNote: {
    i18nKey: 'supportAccountDateNote',
    defaults: 'Please select the date and time at which you would like to be contacted from our support team Monday to Friday (24/5)',
  },
  oneOnOneAccountDateNote: {
    i18nKey: 'oneOnOneAccountDateNote',
    defaults: 'Please select the date and time at which you would like to be contacted between Monday to Friday 09:30 and 19:30 EEST time.',
  },
  thankYou:{
    i18nKey: 'thankYou',
    defaults: 'Thank you!',
  },
  messageBlack:{
    i18nKey: 'messageBlack',
    defaults: 'Your message has been successfully submitted.',
  },
  blackContact:{
    i18nKey: 'blackContact',
    defaults: '{{company}} representative will contact you on the selected day & time.',
  },
  iban: {
    i18nKey: 'iban',
    defaults: 'IBAN'
  },
  accountholdername: {
    i18nKey: 'accountholdername',
    defaults: 'Account holder name'
  },
  bic: {
    i18nKey: 'bic',
    defaults: 'BIC'
  },
  front: {
    i18nKey: 'front',
    defaults: 'Front',
  },
  frontSide: {
    i18nKey: 'frontSide',
    defaults: 'Front side',
  },
  backSide: {
    i18nKey: 'backSide',
    defaults: 'Back side',
  },
  uploadDocumentsText: {
    i18nKey: 'uploadDocumentsText',
    defaults: '{{company}}, being a regulated company, is obliged by law to verify your identity and ensure compliance with Anti-Money Laundering rules and regulations. These measures are put in place to protect you and ensure that no unauthorized person can register an account in your name. Accordingly, please provide us with the following documents:',
  },
  poiText: {
    i18nKey: 'poiText',
    defaults: 'Passport and/or national identity card. The attached file must be valid (i.e. not expired) and clearly readable.',
  },
  porText: {
    i18nKey: 'porText',
    defaults: 'A utility bill such as: electricity, water, gas or landline phone bill or a bank statement, showing your name and address as listed on your application and issued within the last 3 months. Please note that we do not accept mobile phone bills and bank receipts as proof of residence. In case you do not have any of the above documents you may provide us with a certificate of residence from the local authority of your country of residence.',
  },
  updateEconomicProfile: {
    i18nKey: 'updateEconomicProfile',
    defaults: 'Next please complete your <0>Economic Profile</0>.',
  },
  mt4LoginDetails: {
    i18nKey: 'mt4LoginDetails',
    defaults: '{{product}} App login details',
  },
  yourBalance: {
    i18nKey: 'yourBalance',
    defaults: 'Your balance is {{balance}}',
  },
  pepQuestion: {
    i18nKey: 'pepQuestion',
    defaults: 'Are you Politically Exposed?',
  },
  pepText: {
    i18nKey: 'pepText',
    defaults: 'A politically exposed person (PEP) is any natural person who is authorised to perform prominent public functions in a country. This includes governors of state, members of Parliament, high ranking military officers, and heads of local bodies among others. You could also qualify as a PEP if you are a family member, a partner, or a close relative of such an individual.',
  },
  pepText2: {
    i18nKey: 'pepText2',
    defaults: 'Please note that Politically Exposed Persons require additional documentation in order to qualify for an Account Opening.',
  },
  pepSuccessButton: {
    i18nKey: 'pepSuccessButton',
    defaults: 'Yes, I am Politically Exposed',
  },
  pepCancelButton: {
    i18nKey: 'pepCancelButton',
    defaults: 'No, I am not Politically Exposed',
  },
  loginExistingAccount: {
    i18nKey: 'loginExistingAccount',
    defaults: 'Select "Login to existing account"',
  },
  usDeclarationTitle: {
    i18nKey: 'usDeclarationTitle',
    defaults: 'U.S. Reportable Citizen Declaration',
  },
  usSuccessButton: {
    i18nKey: 'usSuccessButton',
    defaults: 'Yes, I am a U.S. reportable person',
  },
  usModalTextIntro: {
    i18nKey: 'usModalTextIntro',
    defaults: 'A US Reportable Citizen generally includes the following:',
  },
  usModalText1: {
    i18nKey: 'usModalText1',
    defaults: 'A US citizen or resident individual, including US citizens living outside the US or US citizens and residents operating a business outside the US (This includes green card holders.)',
  },
  usModalText2: {
    i18nKey: 'usModalText2',
    defaults: 'Anyone maintaining a US address (including a P.O. Box address)',
  },
  usModalText3: {
    i18nKey: 'usModalText3',
    defaults: 'A corporation or partnership organized in the US or under the laws of the US or any state thereof, including where the entity is operating a business outside the US',
  },
  usModalText4: {
    i18nKey: 'usModalText4',
    defaults: 'A trust if (i) a court within the US would have authority under applicable law to render orders or judgments concerning substantially all issues regarding administration of the trust, and (ii) one or more US persons have the authority to control all substantial decisions of the trust, or an estate of a decedent that is a citizen or resident of the US',
  },
  usModalText5: {
    i18nKey: 'usModalText5',
    defaults: 'Power of attorney or signatory authority granted to someone with a US address and/or',
  },
  usModalText6: {
    i18nKey: 'usModalText6',
    defaults: 'US telephone number',
  },
  networkError: {
    i18nKey: 'networkError',
    defaults: 'Network Error',
  },
  usCancelButton: {
    i18nKey: 'usCancelButton',
    defaults: 'No, I am not U.S. reportable',
  },
  welcome:{
    i18nKey: 'welcome',
    defaults: 'Welcome',
  },
  affiliateWelcomeTitle:{
    i18nKey: 'affiliateWelcomeTitle',
    defaults: 'Thank you and welcome',
  },
  affiliateWelcomeNotificationText1:{
    i18nKey: 'affiliateWelcomeNotificationText1',
    defaults: 'Your account is pending approval. You will receive an email as soon as we have verified your marketing activities and activated your account.',
  },
  affiliateWelcomeNotificationText2:{
    i18nKey: 'affiliateWelcomeNotificationText2',
    defaults: '(Approval usually happens within 1 working day).',
  },
  affiliateWelcomeNotificationText3:{
    i18nKey: 'affiliateWelcomeNotificationText3',
    defaults: 'For further questions, do not hesitate to contact us via affiliates@{{company}}',
  },
  accountcysecSummary:{
    i18nKey: 'accountcysecSummary',
    defaults: 'EU Account with leverage restrictions',
  },
  accountfscSummary:{
    i18nKey: 'accountfscSummary',
    defaults: 'Offshore account without restrictions',
  },
  accountvfscSummary:{
    i18nKey: 'accountvfscSummary',
    defaults: 'Offshore account without restrictions',
  },
  successSignal: {
    i18nKey: 'successSignal',
    defaults: 'Success',
  },
  failureSignal: {
    i18nKey: 'failureSignal',
    defaults: 'Failure',
  },
  activeSignal:{
    i18nKey: 'activeSignal',
    defaults: 'Active',
  },
  getReadySignal:{
    i18nKey: 'getReadySignal',
    defaults: 'Get Ready',
  },
  closedSignal:{
    i18nKey: 'closedSignal',
    defaults: 'Closed',
  },
  profileSettingsStatus:{
    i18nKey: 'profileSettingsStatus',
    defaults: 'filled'
  },
  pendingApproval:{
    i18nKey: 'pendingApproval',
    defaults: 'Pending Approval',
  },
  verified:{
    i18nKey: 'verified',
    defaults: 'Verified',
  },
  notVerified:{
    i18nKey: 'notVerified',
    defaults: 'Not Verified',
  },
  resetBalanceSubTitle: {
    i18nKey: 'resetBalanceSubTitle',
    defaults: 'Reset Demo Account Balance'
  },
  resetBalance: {
    i18nKey: 'resetBalance',
    defaults: 'Reset Balance'
  },
  resetBalanceSuccess: {
    i18nKey: 'resetBalanceSuccess',
    defaults: 'Your demo balance has been reset! Go to <0>Dashboard</0>'
  },
  noAccounts: {
    i18nKey: 'noAccounts',
    defaults: 'You do not have any live account.'
  },
  withdrawalsOtherReasonMessage: {
    i18nKey: 'withdrawalsOtherReasonMessage',
    defaults: 'Your withdrawal was rejected. Please check your email for details or contact support'
  },
  documentsOtherReasonMessage: {
    i18nKey: 'documentsOtherReasonMessage',
    defaults: 'Your document was rejected. Please check your email for details or contact support'
  },
  transactionDetails: {
    i18nKey: 'transactionDetails',
    defaults: 'Transaction Details'
  },
  verifyAccount: {
    i18nKey: 'verifyAccount',
    defaults: 'Verify Account'
  },
  rejectionReasonTitle:{
    i18nKey: 'rejectedReasonTitle',
    defaults: 'Rejection Reason'
  },
  pendingReasonTitle:{
    i18nKey: 'pendingReasonTitle',
    defaults: 'Pending Reason'
  },
  accountMigration: {
    i18nKey: 'accountMigration',
    defaults: 'Account Migration',
  },
  dearTrader: {
    i18nKey: 'dearTrader',
    defaults: 'Dear Trader,',
  },
  clientMigrationText1: {
    i18nKey: 'clientMigrationText1',
    defaults: 'You have attempted to log in to BDS Markets, regulated by the FSC Mauritius (License No. C116016172 - Domain: {{domain}}).',
  },
  clientMigrationText2: {
    i18nKey: 'clientMigrationText2',
    defaults: 'We have detected that your account is currently under BDSwiss Holding Ltd, regulated by CySec (License No. 199/13 - Domain: {{companyDomain}}.)',
  },
  clientMigrationText3: {
    i18nKey: 'clientMigrationText3',
    defaults: '<0>BDS Markets</0> is a regulated subsidiary of the <0>BDSwiss Group</0>. Clients requesting to trade with BDS Markets are required to open a new account or request to migrate their existing account. Please note that in order to migrate your account any open positions need to be closed.',
  },
  clientMigrationText31: {
    i18nKey: 'clientMigrationText31',
    defaults: 'After migration you may be provided with new MT4 Account details in which case any balances will be automatically transferred to your new trading account and your old account will be disabled from trading and will be only available for viewing your trading history.',
  },
  clientMigrationText4: {
    i18nKey: 'clientMigrationText4',
    defaults: 'Click <0>Confirm</0> to migrate your account and login to {{domain}}.',
  },
  migrateAccept: {
    i18nKey: 'migrateAccept',
    defaults: 'Confirm',
  },
  migrationReject: {
    i18nKey: 'migrationReject',
    defaults: 'No, don\'t migrate my account',
  },
  bdswissMigrationConfirmDisclaimer: {
    i18nKey: 'bdswissMigrationConfirmDisclaimer',
    defaults: '*By clicking “Confirm”, you agree that you have read, understood and accept the <0>Terms and Conditions</0> of BDS Markets.',
  },
  appropriatenessTestNotification: {
    i18nKey: 'appropriatenessTestNotification',
    defaults: 'Please complete your <0>Appropriateness Test</0>.'
  },
  appropriatenessTestLink: {
    i18nKey: 'appropriatenessTestLink',
    defaults: 'Complete approriateness test'
  },
  depositHeldNotification: {
    i18nKey: 'depositHeldNotification',
    defaults: 'You have <0> held deposits </0>, please verify your account.',
  },
  accountPleaseVerify: {
    i18nKey: 'accountPleaseVerify',
    defaults: 'Please <0> verify your account </0> otherwise your account may be suspended.'
  },
  noDocuments: {
    i18nKey: 'noDocuments',
    defaults: 'You do not have any documents uploaded.'
  },
  readOnly:{
    i18nKey: 'readOnly',
    defaults: 'Read-Only'
  },
  getAstropay:{
    i18nKey: 'getAstropay',
    defaults: 'How to get your AstroPay Card?'
  },
  registerAstropay:{
    i18nKey: 'registerAstropay',
    defaults: 'Register for free at web.astropaycard.com or in the mobile App.'
  },
  purchaseAstropay:{
    i18nKey: 'purchaseAstropay',
    defaults: 'Purchase, Select the value and currency of your AstroPay Card.'
  },
  paymentAstropay:{
    i18nKey: 'paymentAstropay',
    defaults: 'Make the payment. Choose the local payment method and pay in local currency.'
  },
  useAstropay:{
    i18nKey: 'useAstropay',
    defaults: 'Ready to use. You will receive an email with the AstroPay Card information. Enjoy your AstroPay Card.'
  },
  accountDaysPleaseVerify: {
    i18nKey: 'accountDaysPleaseVerify',
    defaults: 'Your account is active. Please <0>verify your account</0> as soon as possible to avoid suspension.'
  },
  accepted:{
    i18nKey: 'accepted',
    defaults: 'Accepted'
  },
  notProcessed:{
    i18nKey: 'notProcessed',
    defaults: 'Not Processed'
  },
  passwordsNotSame:{
    i18nKey: 'passwordsNotSame',
    defaults: 'Passwords do not match!'
  },
  broker:{
    i18nKey: 'broker',
    defaults: 'Broker'
  },
  autoVerificationSent:{
    i18nKey: 'autoVerificationSent',
    defaults: 'Auto Verification Sent'
  },
  autoVerificationReceived:{
    i18nKey: 'autoVerificationReceived',
    defaults: 'Auto Verification Received'
  },
  autoVerificationRejected:{
    i18nKey: 'autoVerificationRejected',
    defaults: 'Auto Verification Rejected'
  },
  autoVerificationAccepted:{
    i18nKey: 'autoVerificationAccepted',
    defaults: 'Auto Verification Accepted'
  },
  autoVerificationError:{
    i18nKey: 'autoVerificationError',
    defaults: 'Auto Verification Failed'
  },
  expressAccountVerification:{
    i18nKey: 'expressAccountVerification',
    defaults: 'Express Account Verification'
  },
  pendingTranslation:{
    i18nKey: 'pendingTranslation',
    defaults: 'Pending Translation'
  },
  noEditLeveragePermissions:{
    i18nKey: 'noEditLeveragePermissions',
    defaults: 'You don\'t have permissions to edit leverage'
  },
  kycRejectedNotification: {
    i18nKey: 'kycRejectedNotification',
    defaults: 'Verification status has been temporarily rejected due to the following reason: <0>{{reason}}</0>.',
  },
  underMonitoringNotification: {
    i18nKey: 'underMonitoringNotification',
    defaults: 'Your deposit is <0>held</<0> and under manual review. Held deposits are reviewed with priority. <1>Contact Support</1> for futher questions. Reason: {{reason}}.',
  },
  depositCommisions: {
    i18nKey: 'depositCommisions',
    defaults: 'Deposit Commisions'
  },
  transferDepositCommisions: {
    i18nKey: 'transferDepositCommisions',
    defaults: 'Transfer Deposit Commisions',
  },
  transferb2b: {
    i18nKey: 'transferb2b',
    defaults: 'Transfer Deposit B2B',
  },
  reimbursementSettlement: {
    i18nKey: 'reimbursementSettlement',
    defaults: 'Reimbursement/Settlement',
  },
  demoFunds: {
    i18nKey: 'demoFunds',
    defaults: 'Demo Funds',
  },
  adjustmentCorrection: {
    i18nKey: 'adjustmentCorrection',
    defaults: 'Adjustment/Correction',
  },
  creditInAdvance: {
    i18nKey: 'creditInAdvance',
    defaults: 'Credit In Advance',
  },
  ibExpenses: {
    i18nKey: 'ibExpenses',
    defaults: 'IB Expenses',
  },
  contractorSalary: {
    i18nKey: 'contractorSalary',
    defaults: 'Contractor Salary',
  },
  contractorExpenses: {
    i18nKey: 'contractorExpenses',
    defaults: 'Contractor Expenses',
  },
  cfdModalMessage: {
    i18nKey: 'cfdModalMessage',
    defaults: 'You are about to acquire a product that is not simple and may be difficult to understand: Contract for Difference (CFD). The CNMV considers that due to the complexity and high risk, the product is not suitable for retail investors. The product you are about to acquire is a product with leverage. You should be aware that the losses acquired via trading this product may exceed the amount that is required to open the position.',
  },
  cfdModalAcknowledgement: {
    i18nKey: 'cfdModalAcknowledgement',
    defaults: 'I fully understand this product is complex and CNMV has determined that it is not convenient for me.',
  },
  leverageModalMessage: {
    i18nKey: 'leverageModalMessage',
    defaults: 'The financial product you are about to acquire (CFDs) is a product with leverage. You must be aware that losses may be greater than the amount disbursed initially for acquisition. The financial instrument is difficult to understand. Considering its complex nature and the risk associated, the CNMV considers that it is inappropriate for retail clients. The product that I am dealing in is complex and the CNMV considers it to be not suitable for me.',
  },
  leverageModalAcknowledgement: {
    i18nKey: 'leverageModalAcknowledgement',
    defaults: 'Please acknowledge of the understanding of risk involved in trading with margined products',
  },
  cfdModalClientSignature: {
    i18nKey: 'cfdModalClientSignature',
    defaults: 'Please type in your full name below to confirm the above',
  },
  signatureNotMatch: {
    i18nKey: 'signatureNotMatch',
    defaults: 'Signature does not match the client name.'
  },
  changeLeverageNoticeEu: {
    i18nKey: 'changeLeverageNoticeEu',
    defaults: 'The European Securities and Markets Authority introduced as of the 1st August 2018 leverage restrictions to the Sale of CFDs to retail investors',
  },
  leverageLimitations: {
    i18nKey: 'leverageLimitations',
    defaults: 'The leverage limitations are as follows:',
  },
  contactSupportEu: {
    i18nKey: 'contactSupportEu',
    defaults: 'To increase your leverage, send us a request to qualify as a <0>professional trader</0>. For more questions, please contact <1>support</1>.',
  },
  changeLeverageLimitation1: {
    i18nKey: 'changeLeverageLimitation1',
    defaults: '30:1 for major currency pairs',
  },
  changeLeverageLimitation2: {
    i18nKey: 'changeLeverageLimitation2',
    defaults: '20:1 for non-major currency pairs, gold and major indices',
  },
  changeLeverageLimitation3: {
    i18nKey: 'changeLeverageLimitation3',
    defaults: '10:1 for commodities other than gold and non-major equity indices',
  },
  changeLeverageLimitation4: {
    i18nKey: 'changeLeverageLimitation4',
    defaults: '5:1 for individual equities and other reference values',
  },
  changeLeverageLimitation5: {
    i18nKey: 'changeLeverageLimitation5',
    defaults: '2:1 for cryptocurrencies',
  },
  initialMargin:{
    i18nKey: 'initialMargin',
    defaults: 'Initial Margin',
  },
  entityRedirectionHeader: {
    i18nKey: 'entityRedirectionHeader',
    defaults: 'Redirection Notice',
  },
  entityRedirectionMessage: {
    i18nKey: 'entityRedirectionMessage',
    defaults: 'Our services are offered to your jurisdiction through our affiliate entity {{currentEntity}}, to which you were redirected',
  },
  deleteAccountSuccess: {
    i18nKey: 'deleteAccountSuccess',
    defaults: 'Your account has been deleted. You will be logged out.',
  },
  skipToStep:{
    i18nKey: 'skipToStep',
    defaults: 'Skip to step {{step}}'
  },
  skipButton:{
    i18nKey: 'skipButton',
    defaults: 'Skip'
  },
  signUpRetail:{
    i18nKey: 'signUpRetail',
    defaults: 'Open a Live Trading Account',
  },
  signUp2min:{
    i18nKey: 'signUp2min',
    defaults: 'Get Started in less than 49 seconds',
  },
  signUp2minHighlighted:{
    i18nKey: 'signUp2minHighlighted',
    defaults: 'Get Started in less than <0>{{seconds}} seconds</0>',
  },
  signUpAffiliate:{
    i18nKey: 'signUpAffiliate',
    defaults: 'Register as an <0>Affiliate Partner</0>',
  },
  signUpAFF:{
    i18nKey: 'signUpAFF',
    defaults: 'Register as an <0>Affiliate Partner</0>',
  },
  signUpMt5:{
    i18nKey: 'signUpMt5',
    defaults: 'Get Started with MT5',
  },
  emailExists:{
    i18nKey: 'emailExists',
    defaults: 'Email already exists',
  },
  affiliateEmailExists:{
    i18nKey: 'affiliateEmailExists',
    defaults: 'Affiliate email exists',
  },
  emailNotValidDomain:{
    i18nKey: 'emailNotValidDomain',
    defaults: 'Invalid email',
  },
  signUpCheckbox:{
    i18nKey: 'signUpCheckbox',
    defaults: 'I hereby verify that I am 18 years or older, I have read and accept your <0>General Terms & Conditions</0> of {{company}}, <1>Legal Documents </1>, <2>Personal Data Protection</2>',
  },
  signUpAffiliateCheckbox:{
    i18nKey: 'signUpAffiliateCheckbox',
    defaults: 'I am 18 years or older and I have read and accept your affiliate <0>General Terms & Conditions</0>, <1>Legal Documents </1>, <2>Personal Data Protection</2>, <3>Partner Agreement</3>, <4>Code of Conduct</4> & <5>Marketing Guidelines</5>',
  },
  isLessThan5CharsMoreThan15:{
    i18nKey: 'isLessThan5CharsMoreThan15',
    defaults: 'Password length has to be between 5 to 15 characters.',
  },
  noAnyLetter:{
    i18nKey: 'noAnyLetter',
    defaults: 'Password must contain at least one letter.',
  },
  noDigit:{
    i18nKey: 'noDigit',
    defaults: 'Password must contain at least one digit.',
  },
  skypeId:{
    i18nKey: 'skypeId',
    defaults: 'Skype',
  },
  website:{
    i18nKey: 'website',
    defaults: 'Website / Marketing Activity',
  },
  affiliateCountries:{
    i18nKey: 'affiliateCountries',
    defaults: 'What countries do you promote in',
  },
  expectedIn: {
    i18nKey: 'expectedIn',
    defaults: 'Expected in: {{processTime}}'
  },
  expiryDate: {
    i18nKey: 'expiryDate',
    defaults: 'Expiry Date'
  },
  invalidMonth: {
    i18nKey: 'invalidMonth',
    defaults: 'Month is invalid'
  },
  invalidYear: {
    i18nKey: 'invalidYear',
    defaults: 'Year is invalid'
  },
  pendingConfirmation: {
    i18nKey: 'pendingConfirmation',
    defaults: 'Pending confirmation'
  },
  documentsVerifiedFootNote: {
    i18nKey: 'documentsVerifiedFootNote',
    defaults: '*Please refer to our <0>Terms and Conditions</0> for our full documentation requirements'
  },
  eVerified:{
    i18nKey: 'eVerified',
    defaults: 'E-Verified'
  },
  countryRegulator: {
    i18nKey: 'countryRegulator',
    defaults: 'Your selected country regulator is:'
  },
  regulatorEntity: {
    i18nKey: 'regulatorEntity',
    defaults: 'Regulated by the {{regulator}}'
  },
  reviewCountryRegulator: {
    i18nKey: 'reviewCountryRegulator',
    defaults: 'Review country regulator <0>here</0>.'
  },
  regulator: {
    i18nKey: 'regulator',
    defaults: 'Regulator'
  },
  registeredUsers:{
    i18nKey: 'registeredUsers',
    defaults: 'registered users'
  },
  avgTrading:{
    i18nKey: 'avgTrading',
    defaults: 'avg. trading volume per month'
  },
  depositMethods:{
    i18nKey: 'depositMethods',
    defaults: 'deposit methods'
  },
  avgWithdrawal:{
    i18nKey: 'avgWithdrawal',
    defaults: 'avg. withdrawal time'
  },
  introducedInstant:{
    i18nKey: 'introducedInstant',
    defaults: '* Introduced instant credit card withdrawals'
  },
  supportTime:{
    i18nKey: 'supportTime',
    defaults: 'support response time'
  },
  companyFirms:{
    i18nKey: 'companyFirms',
    defaults: '{{company}} Group consists of the following Investment Firms'
  },
  leverageUp:{
    i18nKey: 'leverageUp',
    defaults: 'Leverage up to: 1:{{leverage}}'
  },
  leverageRetailClient30:{
    i18nKey: 'leverageRetailClient30',
    defaults: '<0>1:30 for retail clients</0>'
  },
  leverageRetailClient400:{
    i18nKey: 'leverageRetailClient400',
    defaults: '<0>1:400 for retail clients</0>'
  },
  leverageProfesionalClient400:{
    i18nKey: 'leverageProfesionalClient400',
    defaults: '<0>1:400 for professional clients</0>'
  },
  icfProtection:{
    i18nKey: 'icfProtection',
    defaults: 'ICF Protection'
  },
  negativeBalance:{
    i18nKey: 'negativeBalance',
    defaults: 'Negative balance protection'
  },
  insurance:{
    i18nKey: 'insurance',
    defaults: 'Professional indemnity insurance'
  },
  groupNumbers:{
    i18nKey: 'groupNumbers',
    defaults: 'Group in numbers'
  },
  regulationDialogTitle:{
    i18nKey: 'regulationDialogTitle',
    defaults: 'Local restrictions / Cross-border services'
  },
  regulationDialogTitleCysec:{
    i18nKey: 'regulationDialogTitleCysec',
    defaults: 'Important Notification'
  },
  regulationDialogText1:{
    i18nKey: 'regulationDialogText1',
    defaults: '{{trademark}}. (License No. {{license}} - Domain {{domain}}) is a financial services institution outside the European Union Area, which is subject to the supervision of the {{regulatorName}} ({{regulator}}).'
  },
  regulationDialogText2:{
    i18nKey: 'regulationDialogText2',
    defaults: 'This website may contain information in particular regarding financial services and products that could be regarded by a E.U. supervisory authority as an offer of financial services targeted in Europe.'
  },
  regulationDialogText3:{
    i18nKey: 'regulationDialogText3',
    defaults: 'By clicking on “continue” to be able to visit this website, you confirm that you have read and understood this notice and that you are visiting this website on your own initiative, without any encouragement whatsoever from {{trademark}}.'
  },
  regulationDialogText4:{
    i18nKey: 'regulationDialogText4',
    defaults: 'If you would like to visit the EU website please click cancel to be redirected.'
  },
  selectAll:{
    i18nKey: 'selectAll',
    defaults: 'Select All'
  },
  selectNone:{
    i18nKey: 'selectNone',
    defaults: 'Select None'
  },
  tradesmarterMessage: {
    i18nKey: 'tradesmarterMessage',
    defaults: 'Due to recent changes in the European Union regulatory environment in the relation to {{product}}, we have the obligation to inform you that your {{product}} account (only) will be deactivated by 2nd of July 2018',
  },
  tradesmarterSubMessage: {
    i18nKey: 'tradesmarterSubMessage',
    defaults: 'Please transfer your funds to a Forex account to continue trading with {{company}}.',
  },
  tradesmarterNote: {
    i18nKey: 'tradesmarterNote',
    defaults: 'Please note that if your account was already inactive due to inactivity, you will continue to incur inactivity fees until you transfer your funds to your Forex/CFD Account',
  },
  tradesmarterContactUs: {
    i18nKey: 'tradesmarterContactUs',
    defaults: 'Should you have any questions regarding the tranfer please do not hesitate to contact your {{company}} Account Manager or {{company}} support at <0>{{supportEmail}}</0>',
  },
  eVerificationFieldLabel: {
    i18nKey: 'eVerificationFieldLabel',
    defaults: 'Please type in the required details below, to be able to proceed',
  },
  eVerificationModal:{
    i18nKey: 'eVerificationModal',
    defaults: 'Please complete the information required below to get the best result through our auto-verification process. Note that we will not be able to automatically approve your account if you provide inaccurate or wrong profile details.',
  },
  phoneCode: {
    i18nKey: 'phoneCode',
    defaults: 'Phone Code'
  },
  billingAddress: {
    i18nKey: 'billingAddress',
    defaults: 'Billing Address'
  },
  billingAddressNote: {
    i18nKey: 'billingAddressNote',
    defaults: 'Your bank requires us to collect your address information to process the transaction.'
  },
  endingIn:{
    i18nKey: 'endingIn',
    defaults: 'ending in'
  },
  yourCards:{
    i18nKey: 'yourCards',
    defaults: 'Your Cards'
  },
  addNewCard:{
    i18nKey: 'addNewCard',
    defaults: 'Add new card'
  },
  smsVerification:{
    i18nKey: 'smsVerification',
    defaults: 'SMS Verification'
  },
  awaitingVerification:{
    i18nKey: 'awaitingVerification',
    defaults: 'Awaiting Verification'
  },
  smsCode:{
    i18nKey: 'smsCode',
    defaults: 'Please enter the 4-digit code you will receive shortly by SMS below'
  },
  enterCode:{
    i18nKey: 'enterCode',
    defaults: 'Enter 4-digit code'
  },
  resendSms:{
    i18nKey: 'resendSms',
    defaults: 'Resend SMS'
  },
  changePhoneNumber:{
    i18nKey: 'changePhoneNumber',
    defaults: 'Change Phone Number'
  },
  addPhoneNumber:{
    i18nKey: 'addPhoneNumber',
    defaults: 'Add Phone Number'
  },
  proceedSecure:{
    i18nKey: 'smsCodeproceedSecure',
    defaults: 'Proceed and Secure Account'
  },
  contactSupportProblems:{
    i18nKey: 'contactSupportProblems',
    defaults: 'Please contact support in case of problems'
  },
  codeSendTo:{
    i18nKey: 'codeSendTo',
    defaults: 'The verification code will be sent to: <0>{{phone}}</0>'
  },
  phoneChangedCodeSent:{
    i18nKey: 'phoneChangedCodeSend',
    defaults: 'Your phone number has been changed. A verification code has been sent.'
  },
  phoneConfirmation:{
    i18nKey: 'phoneConfirmation',
    defaults: 'Phone Confirmation'
  },
  improveAccountProtection:{
    i18nKey: 'improveAccountProtection',
    defaults: 'Improve your account protection by linking your phone number'
  },
  goBack:{
    i18nKey: 'goBack',
    defaults: 'Go Back'
  },
  unableToSend:{
    i18nKey: 'unableToSend',
    defaults: 'We were unable to send the verification code to the phone number provided.'
  },
  usedPhone:{
    i18nKey: 'usedPhone',
    defaults: 'Phone number provided already used by another client.'
  },
  rateLimit:{
    i18nKey: 'rateLimit',
    defaults: 'Too many requests, please try again later'
  },
  phoneVerificationRequestLimit:{
    i18nKey: 'phoneVerificationRequestLimit',
    defaults: 'SMS verification request limit reached. Please contact support.'
  },
  wrongCode:{
    i18nKey: 'wrongCode',
    defaults: 'You have provided a wrong code. Please try again.'
  },
  becomeVip: {
    i18nKey: 'becomeVip',
    defaults: 'Become A {{company}} VIP'
  },
  openA: {
    i18nKey: 'openA',
    defaults: 'OPEN A'
  },
  evenLowerSpreads: {
    i18nKey: 'evenLowerSpreads',
    defaults: 'Even lower spreads'
  },
  deticatedSupport: {
    i18nKey: 'deticatedSupport',
    defaults: 'dedicated vip support'
  },
  deticatedSupportText: {
    i18nKey: 'deticatedSupportText',
    defaults: 'Personal Account Manager & Dedicated Multi-lingual support available 24/5'
  },
  lowerSpreadsBlack: {
    i18nKey: 'lowerSpreadsBlack',
    defaults: 'black account holders enjoy 40% lower spreads'
  },
  lowerSpreadsBlackText: {
    i18nKey: 'lowerSpreadsBlackText',
    defaults: 'Receive 1 on 1 Education or Trading Sessions and access VIP events'
  },
  learnMoreBlack: {
    i18nKey: 'learnMoreBlack',
    defaults: 'Learn more about Black Account'
  },
  uploadRules: {
    i18nKey: 'uploadRules',
    defaults: 'The file(s) uploaded should be in image format (.png, .jpg), and be less than 1MB size each.',
  },
  uploadBigFileError: {
    i18nKey: 'uploadBigFileError',
    defaults: 'Files exceed maximum size: {{files}}',
  },
  uploadWrongFormatError: {
    i18nKey: 'uploadWrongFormatError',
    defaults: 'Files are not in required format: {{files}}',
  },
  searchAddress: {
    i18nKey: 'searchAddress',
    defaults: 'Search your address'
  },
  loading: {
    i18nKey: 'loading',
    defaults: 'Loading...',
  },
  nationalIdIT:{
    i18nKey: 'nationalIdIT',
    defaults: 'The NID number is a Codice Fiscale – which is a 16 digit code, all numbers must be entered',
  },
  nationalIdES:{
    i18nKey: 'nationalIdES',
    defaults: 'The NID number is NIF/NIE Number – which is an 8-9 alphanumeric character sequence',
  },
  nationalIdZA:{
    i18nKey: 'nationalIdZA',
    defaults: 'The NID number is the South African ID number - a 13-digit number which is defined by the following format: YYMMDDSSSSCAZ',
  },
  nationalIdBR:{
    i18nKey: 'nationalIdBR',
    defaults: 'The CPF number (stands for “Cadastro de Pessoas Físicas”) is an 11-digits individual taxpayer identification number. Example of CPF: 231.002.999-00',
  },
  note:{
    i18nKey: 'note',
    defaults: 'Note:',
  },
  nationalIdNumber: {
    i18nKey: 'nationalIdNumber',
    defaults: 'National ID Number'
  },
  nationalIdWhereIs:{
    i18nKey: 'nationalIdWhereIs',
    defaults: 'Where is the NID number?'
  },
  noOptions:{
    i18nKey: 'noOptions',
    defaults: 'No available options'
  },
  tradingCentral:{
    i18nKey: 'tradingCentral',
    defaults: 'Trading Central'
  },
  tcAnalystViews:{
    i18nKey: 'tcAnalystViews',
    defaults: 'TC Analysis',
  },
  tcMarketBuzz:{
    i18nKey: 'tcMarketBuzz',
    defaults: 'TC Market Buzz',
  },
  tcLabsNewsfeeder:{
    i18nKey: 'tcLabsNewsfeeder',
    defaults: 'TC Labs Newsfeeder',
  },
  tcTechnicalInsights:{
    i18nKey: 'tcTechnicalInsights',
    defaults: 'Technical Insights',
  },
  tcNewsletter:{
    i18nKey: 'tcNewsletter',
    defaults: 'Newsletter',
  },
  autochartist:{
    i18nKey: 'autochartist',
    defaults: 'Autochartist'
  },
  autochartistDisabled:{
    i18nKey: 'autochartistDisabled',
    defaults: 'Autochartist is DISABLED'
  },
  whyAutochartist:{
    i18nKey: 'whyAutochartist',
    defaults: 'Why Autochartist?'
  },
  autochartistSubtitle:{
    i18nKey: 'autochartistSubtitle',
    defaults: 'Autochartist covers most CFD assets such as Forex, Indices, Commodities, Stocks and Cryptos. Some of the benefits of incorporating Autochartist to your day-to-day trading include:'
  },
  autochartistText1:{
    i18nKey: 'autochartistText1',
    defaults: 'Save valuable time by not having to continuously scan the markets for opportunities'
  },
  autochartistText2:{
    i18nKey: 'autochartistText2',
    defaults: 'Optimize your stop-loss and take-profit levels using volatility analysis'
  },
  autochartistText3:{
    i18nKey: 'autochartistText3',
    defaults: 'Access market data analysis across multiple time frames in real  time'
  },
  autochartistText4:{
    i18nKey: 'autochartistText4',
    defaults: 'No installation needed. Find it on your Dashboard'
  },
  requestAccess:{
    i18nKey: 'requestAccess',
    defaults: 'Request Access'
  },
  confirmAutochartist:{
    i18nKey: 'confirmAutochartist',
    defaults: 'Please confirm that you would like to be contacted by one of our account managers to receive more information on how you can access Autochartist Performance Stats'
  },
  confirmAutochartistDirectActionP1:{
    i18nKey: 'confirmAutochartistDirectActionP1',
    defaults: 'Autochartist Performance Stats is an exclusive feature that enables you to sort forming trends according to the highest probability of hitting the forecast price and discover which setups have been the most successful in the past 6 months!',
  },
  confirmAutochartistDirectActionP2:{
    i18nKey: 'confirmAutochartistDirectActionP2',
    defaults: 'Accessing this tool requires a one-time 500 additional deposit. Would you like to proceed?',
  },
  confirm:{
    i18nKey: 'confirm',
    defaults: 'Confirm'
  },
  closingPosition:{
    i18nKey: 'closingPosition',
    defaults: 'Closing Position'
  },
  closeOrderConfirmation:{
    i18nKey: 'closeOrderConfirmation',
    defaults: 'Are you sure you want to close this position?'
  },
  orderClosed:{
    i18nKey: 'orderClosed',
    defaults: 'Order has closed'
  },
  confirmStreetNumber:{
    i18nKey: 'confirmStreetNumber',
    defaults: 'Please provide building number if applicable'
  },
  requestAccessSuccess:{
    i18nKey: 'requestAccessSuccess',
    defaults: 'You have requested access to the Autochartist VIP'
  },
  closePosition:{
    i18nKey: 'closePosition',
    defaults: 'Close Position'
  },
  transferFundsTitle:{
    i18nKey: 'transferFundsTitle',
    defaults: 'Internal Transfer'
  },
  transferFundsCard:{
    i18nKey: 'transferFundsCard',
    defaults: 'Transfer funds between your {{company}} accounts'
  },
  transferSendTitle:{
    i18nKey: 'transferSendTitle',
    defaults: 'External Transfer'
  },
  transferSendCard:{
    i18nKey: 'transferSend',
    defaults: 'Transfer funds to another {{company}} beneficiary account'
  },
  transferSpoaTitle:{
    i18nKey: 'transferSpoaTitle',
    defaults: 'SPOA Transfers'
  },
  transferSpoaCard:{
    i18nKey: 'transferSpoaCard',
    defaults: 'IB: Transfer funds from your account to another trading account'
  },
  transferIbTitle:{
    i18nKey: 'transferIbTitle',
    defaults: 'IB Transfers'
  },
  transferIbCard:{
    i18nKey: 'transferIbCard',
    defaults: 'IB: Transfer funds from your account to your sub IB\'s account'
  },
  affiliateClientId:{
    i18nKey: 'affiliateClientId',
    defaults: 'Client ID'
  },
  affiliateAccountId:{
    i18nKey: 'affiliateAccountId',
    defaults: 'Account ID'
  },
  findClient:{
    i18nKey: 'findClient',
    defaults: 'Find Client'
  },
  accountDetails:{
    i18nKey: 'accountDetails',
    defaults: 'Account Details:'
  },
  bdxBonus: {
    i18nKey: 'bdxBonus',
    defaults: 'Bonus',
  },
  bdxManual: {
    i18nKey: 'bdxManual',
    defaults: 'Deposit',
  },
  tradingCentralDescription:{
    i18nKey: 'tradingCentralDescription',
    defaults: 'An automated investment analytics and analyst research.'
  },
  autochartistDescription:{
    i18nKey: 'autochartistDescription',
    defaults: 'Get live analysis on key trends and emerging trading opportunities.'
  },
  forexCalculatorDescription:{
    i18nKey: 'forexCalculatorDescription',
    defaults: 'Calculate your positions` profit or loss, pip value and required margin.'
  },
  tradingAcademyDescription:{
    i18nKey: 'tradingAcademyDescription',
    defaults: 'Expand your trading knowledge with our wealth of e-courses and live webinars.'
  },
  unVerified: {
    i18nKey: 'unVerified',
    defaults: 'Unverified'
  },
  updateTermsTitle:{
    i18nKey: 'updateTermsTitle',
    defaults: 'Terms and Conditions Update'
  },
  decline: {
    i18nKey: 'decline',
    defaults: 'Decline'
  },
  clientCheckMsg: {
    i18nKey: 'clientCheckMsg',
    defaults: 'Please check account details above before clicking \'Continue\''
  },
  registrationError:{
    i18nKey: 'registrationError',
    defaults: 'Something went wrong. Please contact our support team for more information.'
  },
  connectionError:{
    i18nKey: 'connectionError',
    defaults: 'Connection Error. Please try again later.'
  },
  plan: {
    i18nKey: 'plan',
    defaults: 'Plan',
  },
  rawGrow: {
    i18nKey: 'rawGrow',
    defaults: 'Raw Grow',
  },
  rawPro: {
    i18nKey: 'rawPro',
    defaults: 'Raw Pro',
  },
  rawFree: {
    i18nKey: 'rawFree',
    defaults: 'Raw Free',
  },
  paymentMethod: {
    i18nKey: 'paymentMethod',
    defaults: 'Payment Method',
  },
  subscriptionActive: {
    i18nKey: 'subscriptionActive',
    defaults: 'Your plan will be automatically renewed on <0>{{renewalDate}}</0> and you\'ll be charged <1>{{renewalAmount}}</1>.',
  },
  changeOrCancel: {
    i18nKey: 'changeOrCancel',
    defaults: 'Change or Cancel',
  },
  yourPlan: {
    i18nKey: 'yourPlan',
    defaults: 'Your plan',
  },
  changePlan: {
    i18nKey: 'changePlan',
    defaults: 'Change plan',
  },
  changePlanTitle: {
    i18nKey: 'changePlanTitle',
    defaults: 'Change plan <0>{{plan}}</0>',
  },
  selectPlan: {
    i18nKey: 'selectPlan',
    defaults: 'Select plan',
  },
  free:{
    i18nKey: 'free',
    defaults: 'Free',
  },
  month:{
    i18nKey: 'month',
    defaults: 'Month',
  },
  raw_spread: {
    i18nKey: 'raw_spread',
    defaults: 'Raw Spread*',
  },
  rawSpreadExplanation: {
    i18nKey: 'rawSpreadExplanation',
    defaults: '*Direct Liquidity Provider Spreads with no markup',
  },
  no_Commission: {
    i18nKey: 'no_Commission',
    defaults: '0 Commission',
  },
  majors_only: {
    i18nKey: 'majors_only',
    defaults: 'Only Major FX pairs',
  },
  all_pairs: {
    i18nKey: 'all_pairs',
    defaults: 'All FX Pairs + Gold',
  },
  extra_insurance: {
    i18nKey: 'extra_insurance',
    defaults: 'Extra Insurance',
  },
  free_historic_tick_data:{
    i18nKey: 'free_historic_tick_data',
    defaults: 'Free Historic Tick Data',
  },
  volume:{
    i18nKey: 'volume',
    defaults: 'Trading Volume',
  },
  currentPlan:{
    i18nKey: 'currentPlan',
    defaults: 'Current Plan',
  },
  proceedButton: {
    i18nKey: 'proceedButton',
    defaults: 'Proceed',
  },
  choosePaymentMethod: {
    i18nKey: 'choosePaymentMethod',
    defaults: 'Choose Payment Method',
  },
  tradingAccountCurrency: {
    i18nKey: 'tradingAccountCurrency',
    defaults: 'Please note that this is the trading account currency',
  },
  paymentFor: {
    i18nKey: 'paymentFor',
    defaults: 'Payment for <0>{{plan}}</0>',
  },
  volumeConsumption: {
    i18nKey: 'volumeConsumption',
    defaults: 'Volume Consumption',
  },
  changePaymentMethod: {
    i18nKey: 'changePaymentMethod',
    defaults: 'Change Payment Method',
  },
  changePlanText: {
    i18nKey: 'changePlanText',
    defaults: 'Do you want to proceed with your current payment method?',
  },
  reactivate: {
    i18nKey: 'reactivate',
    defaults: 'Re-Activate',
  },
  activate: {
    i18nKey: 'activate',
    defaults: 'Activate',
  },
  subscriptionCancelled: {
    i18nKey: 'subscriptionCancelled',
    defaults: 'Your plan has been cancelled. Your trading conditions will be active until the end of your current billing cycle. You can choose to <1>re-activate</1> your subscription until <0>{{renewalDate}}</0>.',
  },
  subscriptionExpired: {
    i18nKey: 'subscriptionExpired',
    defaults: 'Your plan has been terminated. Please <1>choose your plan</1> to continue trading with zero commission. Otherwise you will be charged <0>{{fallBackCommission}} per $100.000</0> trading volume.',
  },
  subscriptionPendingChange:{
    i18nKey: 'subscriptionPendingChange',
    defaults: 'Your plan will be changed to <0>{{pendingPlan}}</0> on <0>{{renewalDate}}</0> and you\'ll be charged <0>{{renewalAmount}}</0>. Until then you enjoy the advantages of your current plan.',
  },
  growRawConsumptionNote: {
    i18nKey: 'growRawConsumptionNote',
    defaults: 'If you exceed your monthly volume threshold, your plan will be upgraded automatically.',
  },
  subscriptionPaymentHistory:{
    i18nKey: 'subscriptionPaymentHistory',
    defaults: '<0>View Payment History</0>',
  },
  subscription: {
    i18nKey: 'subscription',
    defaults: 'Subscription',
  },
  somethingWrongError: {
    i18nKey: 'somethingWrongError',
    defaults: "Something went wrong, please try again later"
  },
  maxProVolume: {
    i18nKey: 'maxProVolume',
    defaults: "You have reached your trading volume limit. Until <0>{{renewalDate}}</0> you will be charged <0>{{overTheLimitCommission}} per $100.000</0> trading volume."
  },
  cancelledMaxVolume: {
    i18nKey: 'cancelledMaxVolume',
    defaults: "Your plan has been cancelled and you have reached your trading volume limit. From now on, you will be charged <0>{{fallBackCommission}} per $100.000</0> trading volume."
  },
  cancelledMaxProVolume: {
    i18nKey: 'cancelledMaxProVolume',
    defaults: "Your plan has been cancelled and you have reached your trading volume limit. From now on, you will be charged <0>{{overTheLimitCommission}} per $100.000</0> trading volume."
  },
  checkPlans:{
    i18nKey: 'checkPlans',
    defaults: 'Check available plans'
  },
  subscriptionPendingFree:{
    i18nKey: 'subscriptionPendingFree',
    defaults: 'Your plan will be downgraded to <0>{{pendingPlan}}</0> on <0>{{renewalDate}}</0>. If you have open positions we will not be able to downgrade your plan. Please close all positions before <0>{{renewalDate}}</0> otherwise if you will continue trading, you will be charged <0>{{fallBackCommission}} per $100.000</0> trading volume.'
  },
  subscriptionFailedPayment:{
    i18nKey: 'subscriptionFailedPayment',
    defaults: 'Your payment for your current subscription plan has failed. Please <1>update your payment details</1> to continue trading with zero commission. Otherwise you will be charged <0>{{fallBackCommission}} per $100.000</0> trading volume.'
  },
  forexMt4Desc:{
    i18nKey: 'forexMt4Desc',
    defaults: 'Open a Basic Account and trade 250+ CFDs on the MetaTrader 4 - the world’s leading platform.'
  },
  forexMt5Desc:{
    i18nKey: 'forexMt5Desc',
    defaults: 'Open a Basic Account and access advanced trading & analysis tools on the MetaTrader 5.'
  },
  forexMt5standardDesc:{
    i18nKey: 'forexMt5standardDesc',
    defaults: 'Open a Standard account and access advanced trading & analysis tools on the MetaTrader 5.'
  },
  forexMt4rawDesc:{
    i18nKey: 'forexMt4rawDesc',
    defaults: 'Lower your trading costs with raw spreads from 0 pips on 40+ forex and gold pairs and gain VIP access to Trading Alerts, webinars and Autochartist.'
  },
  forexMt4primeDesc:{
    i18nKey: 'forexMt4primeDesc',
    defaults: 'Lower your trading costs with raw spreads from 0 pips on 40+ forex and gold pairs and gain VIP access to Trading Alerts, webinars and Autochartist.'
  },
  forexMt5invest_plusDesc:{
    i18nKey: 'forexMt5invest_plusDesc',
    defaults: 'Start trading more than 900 different shares from over 7 exchanges at Direct Market Access with or without leverage (1:5) and diversify with over 80 ETFs. Benefit from dividends paid on BUY positions on shares and $0 costs on unleveraged trading.'
  },
  forexRawAccountDesc:{
    i18nKey: 'forexRawAccountDesc',
    defaults: 'Open a Raw account and reduce your trading costs with our ultra-thin spreads.'
  },
  forexClassicAccountDesc:{
    i18nKey: 'forexClassicAccountDesc',
    defaults:  'Open a Live Account and trade more than 250 CFDs on world-leading platforms.'
  },
  forexClassicDemoAccountDesc:{
    i18nKey: 'forexClassicDemoAccountDesc',
    defaults:  'Open a Demo Account and test your trading skills with $10,000 virtual funds.'
  },
  requestHistoric:{
    i18nKey: 'requestHistoric',
    defaults: 'Request Historic Tick Data'
  },
  proFreeTicks:{
    i18nKey: 'proFreeTicks',
    defaults: 'I would like to request free tick data for my Raw Pro account from: {{startDate}} until {{endDate}}'
  },
  cardholderName:{
    i18nKey: 'cardholderName',
    defaults: 'Cardholder Name'
  },
  freeSubnote:{
    i18nKey: 'freeSubnote',
    defaults: '<1>You will not be charged for the Raw Free account.</1> We will pre-authorise your card in case your account reaches the trading volume limit and is automatically upgraded to the next tier.'
  },
  freeFallBack:{
    i18nKey: 'freeFallBack',
    defaults: 'We could not downgrade your plan because you had open positions during the downgrade process. Please close all of your positions to be able to switch to your selected plan. Otherwise if you will continue trading, you will be charged <0>{{fallBackCommission}} per $100.000</0> trading volume.'
  },
  dobField:{
    i18nKey: 'dobField',
    defaults: 'Fill your date of birth to proceed'
  },
  dobValidationMsg:{
    i18nKey: 'dobValidationMsg',
    defaults: 'Please filled in a valid date of birth date.'
  },
  addAccountPassDesc: {
    i18nKey: 'addAccountPassDesc',
    defaults: 'Please enter your account login password.'
  },
  overTheLimitLabel:{
    i18nKey: 'overTheLimitLabel',
    defaults: 'Volume Limit Reached - Commissions Plan'
  },
  signUpSubTypes:{
    i18nKey: 'signUpSubTypes',
    defaults: 'Get Started with a {{accountSubType}} account'
  },
  generalError: {
    i18nKey: 'generalError',
    defaults: 'Something went wrong, please try again.',
  },
  openPrice: {
    i18nKey: 'openPrice',
    defaults: 'Open Price',
  },
  closePrice: {
    i18nKey: 'closePrice',
    defaults: 'Close Price',
  },
  depositReturnedFunds: {
    i18nKey: 'depositReturnedFunds',
    defaults: "Returned Funds",
  },
  completeProfileGDC: {
    i18nKey: 'completeProfileGDC',
    defaults: 'Please complete your personal details, to be eligible for Express Account Verification.',
  },
  regulationPopupMessageFsc: {
    i18nKey: 'regulationPopupMessageFsc',
    defaults: 'You are about to register to BDS Markets, that is regulated by the Financial Service Commission - FSC (License no: C116016172), Republic of Mauritius.',
  },
  regulationPopupMessageCysec: {
    i18nKey: 'regulationPopupMessageCysec',
    defaults: 'Based on your selection, you will register for an account authorized and regulated by the Cyprus Securities and Exchange Commision.<0 /><1 />Before you proceed, please confirm your decision.',
  },
  regulationPopupMessageVfsc: {
    i18nKey: 'regulationPopupMessageVfsc',
    defaults: 'You are about to register to BDS Ltd, that is regulated by the Financial Services Authority (the “FSA”, licence no. SD047), Republic of Seychelles.',
  },
  regulationPopupNotification: {
    i18nKey: 'regulationPopupNotification',
    defaults: 'As the Company can operate in the European Union under the reverse solicitation regime, please confirm that the decision to register was made on your own exclusive initiative and that no solicitation has been made by BDSwiss nor any other entity within the group or any BDSwiss partner.',
  },
  noThanks: {
    i18nKey: 'noThanks',
    defaults: 'No, Thanks',
  },
  details: {
    i18nKey: 'details',
    defaults: 'Details'
  },
  totalDeposits: {
    i18nKey: 'totalDeposits',
    defaults: 'Total Deposits'
  },
  amountLeft: {
    i18nKey: 'amountLeft',
    defaults: 'Available Amount for withdraw'
  },
  createWithdrawal: {
    i18nKey: 'createWithdrawal',
    defaults: 'Create Withdrawal'
  },
  refundOptionDisabled: {
    i18nKey: 'refundOptionDisabled',
    defaults: 'This option will be enabled once prior options have been fully refunded'
  },
  registerForEventLogin:{
    i18nKey: 'registerForEventLogin',
    defaults: 'Please LOGIN below to <0>confirm your registration</0> for our Seminar in {{eventCountry}} on {{eventDate}}'
  },
  registerForEventRegistration:{
    i18nKey: 'registerForEventRegistration',
    defaults: 'Please register to <0>confirm your attendance</0> for our Seminar in {{eventCountry}} on {{eventDate}}'
  },
  changeLeveragePopupText1: {
    i18nKey: 'changeLeveragePopupText1',
    defaults: 'The current leverage of this account is <0>1:{{leverage}}</0>.'
  },
  changeLeveragePopupText2: {
    i18nKey: 'changeLeveragePopupText2',
    defaults: 'Leverage is to be changed to <0>1:{{leverageChange}}</0>?'
  },
  documentsOrSupport: {
    i18nKey: 'documentsOrSupport',
    defaults: 'Please upload valid documents <0>here</0> or contact our <1>Support Team</1>.'
  },
  reCompleteAppTest: {
    i18nKey: 'reCompleteAppTest',
    defaults: 'Please re-complete <0>now</0>.'
  },
  emailsOrSupport: {
    i18nKey: 'emailsOrSupport',
    defaults: 'Please check for emails from us for further details or contact our <0>Support Team</0>.'
  },
  kycApTestPending: {
    i18nKey: 'kycApTestPending',
    defaults: 'Please proceed to (re)complete the Appropriateness questionnaire <0>now</0>, otherwise your account may be suspended.'
  },
  kycStatusTempRejected: {
    i18nKey: 'kycStatusTempRejected',
    defaults: 'Verification status of your account has been temporarily rejected.'
  },
  isUnderMonitoringWithReason: {
    i18nKey: 'isUnderMonitoringWithReason',
    defaults: 'Any future deposits will be pending.',
  },
  emailsOrDocuments: {
    i18nKey: 'emailOrDocuments',
    defaults: 'Please check your inbox for emails from us and upload required documents <0>here</0>.',
  },
  reason:{
    i18nKey: 'reason',
    defaults: 'Reason: <0>{{reason}}</0>.',
  },
  acceptedFormats: {
    i18nKey: 'acceptedFormats',
    defaults: '{{acceptedFormats}} and {{lastAccepted}} files are accepted.',
  },
  maxFileSizeText: {
    i18nKey: 'maxFileSizeText',
    defaults: 'Max size: {{maxSize}} MB',
  },
  poiAdditionalText: {
    i18nKey: 'poiAdditionalText',
    defaults: 'Passport, national ID card or Driving License.',
  },
  porAdditionalText: {
    i18nKey: 'porAdditionalText',
    defaults: 'Bank statement, Card statement, Utility bill (electricity, gas, water, internet or telephone), Tax letter, Residence certificate',
  },
  pofAdditionalText: {
    i18nKey: 'pofAdditionalText',
    defaults: 'Verify your credit/debit card, virtual card, e-wallet, bank wire details, crypto-wallet to ensure fast and secure processing of your payments.',
  },
  makeSureDocuments: {
    i18nKey: 'makeSureDocuments',
    defaults: 'Please make sure that your documents are:',
  },
  makeSureDocumentsTextPoi1: {
    i18nKey: 'makeSureDocumentsTextPoi1',
    defaults: 'Your photo or scan is clear, with no blurs, light reflection or shadows',
  },
  makeSureDocumentsTextPoi2: {
    i18nKey: 'makeSureDocumentsTextPoi2',
    defaults: 'All edges of the document must be visible',
  },
  makeSureDocumentsTextPoi3: {
    i18nKey: 'makeSureDocumentsTextPoi3',
    defaults: 'Both sides of the document must be uploaded',
  },
  viewExamples: {
    i18nKey: 'viewExamples',
    defaults: 'View examples of valid documents',
  },
  makeSureDocumentsTextPor1: {
    i18nKey: 'makeSureDocumentsTextPor1',
    defaults: 'Your documents are issued within the last 3 months',
  },
  makeSureDocumentsTextPor3: {
    i18nKey: 'makeSureDocumentsTextPor3',
    defaults: 'Includes:',
  },
  makeSureDocumentsTextPor3Sub1: {
    i18nKey: 'makeSureDocumentsTextPor3Sub1',
    defaults: 'Your name',
  },
  makeSureDocumentsTextPor3Sub2: {
    i18nKey: 'makeSureDocumentsTextPor3Sub2',
    defaults: 'Your current address',
  },
  makeSureDocumentsTextPor3Sub3: {
    i18nKey: 'makeSureDocumentsTextPor3Sub3',
    defaults: 'Date of issuance',
  },
  makeSureDocumentsTextPor3Sub4: {
    i18nKey: 'makeSureDocumentsTextPor3Sub4',
    defaults: 'The details of the issuer / issuing authority',
  },
  pofCardInstructions: {
    i18nKey: 'pofCardInstructions',
    defaults: 'Please make sure to cover your card number apart from the last 4 digits (also in the back). The CVV number must also be covered. The front and back side must be uploaded.',
  },
  pofVirtualCardInstructions: {
    i18nKey: 'pofVirtualCardInstructions',
    defaults: 'Please provide us with a statement of the “source” bank account that was used to create the virtual card, highlighting the transfer made to {{company}}',
  },
  depositVericationCardTitle:{
    i18nKey: 'depositVericationCardTitle',
    defaults: 'Deposit Verification',
  },
  depositVericationCard:{
    i18nKey: 'depositVericationCard',
    defaults: 'Verify your deposits',
  },
  viewOnly:{
    i18nKey: 'viewOnly',
    defaults: 'View Only'
  },
  transferToDetails: {
    i18nKey: 'transferToDetails',
    defaults: 'Transfered to: {{type}} - {{id}}'
  },
  transferFromDetails: {
    i18nKey: 'transferFromDetails',
    defaults: 'Transfered from: {{type}} - {{id}}'
  },
  currentPrice:{
    i18nKey: 'currentPrice',
    defaults: 'Current Price'
  },
  transferSpoaClientTitle:{
    i18nKey: 'transferSpoaTitle',
    defaults: 'SPOA Transfers'
  },
  transferSpoaClientCard:{
    i18nKey: 'transferSpoaClientCard',
    defaults: 'Transfer funds from your account to your IB`s account'
  },
  emailVerification:{
    i18nKey: 'emailVerification',
    defaults: 'Awaiting Email Verification'
  },
  emailVerificationThankYou:{
    i18nKey: 'emailVerificationThankYou',
    defaults: 'Thank you for registering.'
  },
  emailVerificationText1:{
    i18nKey: 'emailVerificationText1',
    defaults: 'An email has been sent to <0>{{clientEmail}}</0> with a link to verify your account.'
  },
  emailVerificationText2:{
    i18nKey: 'emailVerificationText2',
    defaults: 'If you have not received the email after a few minutes, please check your spam folder or contact support.'
  },
  emailAreadyConfirmed: {
    i18nKey: 'emailAreadyConfirmed',
    defaults: 'Email aready confirmed! Go to <0>Dashboard</0>',
  },
  appropTestFailed:{
    i18nKey: 'appropTestFailed',
    defaults: 'You do not seem to possess the appropriate experience and knowledge. If you wish to trade derivatives with the Company, you must first gain some experience or education relevant to trading financial Instruments offered by {{company}}. You can resubmit the appropriateness test in {{days}} days.'
  },
  call: {
    i18nKey: 'call',
    defaults: 'Call'
  },
  infoCallText:{
    i18nKey: 'infoCallText',
    defaults: 'You will receive a call from number: {{callerNumber}}'
  },
  secureId: {
    i18nKey: 'secureId',
    defaults: 'Secure ID'
  },
  emailValidation: {
    i18nKey: 'emailValidation',
    defaults: 'Please use a valid email'
  },
  usefulInfo: {
    i18nKey: 'usefulInfo',
    defaults: 'Useful information:'
  },
  isignthisDepositInfo: {
    i18nKey: 'isignthisDepositInfo',
    defaults: 'Please note that in case your total deposit amount is 2,000 EUR (or currency equivalent) and above; additional identity verification will be requested via email.'
  },
  isignthisDepositInfo2: {
    i18nKey: 'isignthisDepositInfo2',
    defaults: 'Kindly upload the documents through the link provided in the email.  You may also find the link in your account dashboard.'
  },
  isignthisDepositNote: {
    i18nKey: 'isignthisDepositNote',
    defaults: '*Your deposit will remain pending until the documents are received.'
  },
  taxNumber: {
    i18nKey: 'taxNumber',
    defaults: 'Identification Number ({{taxNumberName}})'
  },
  methodNotAvailable: {
    i18nKey: 'methodNotAvailable',
    defaults: 'If your card/account is no longer available for receiving refunds, please contact our Customer Support <0>here</0> or proceed to change the withdrawal option <1>here</1>.',
  },
  withdrawalOptionsSameMethod: {
    i18nKey: 'withdrawalOptionsSameMethod',
    defaults: 'As per the company’s withdrawal policy, you must withdraw your funds using the same method you used to deposit (order to follow: card deposit - e-wallet deposit - etc).',
  },
  withdrawalOptionsAnotherOption: {
    i18nKey: 'withdrawalOptionsAnotherOption',
    defaults: '<0>Another payment option will be made available</0> <1>only</1> after the initial funds have been fully used up or withdrawn.',
  },
  chooseRegulator: {
    i18nKey: 'chooseRegulator',
    defaults: 'Choose Regulator',
  },
  wealthManagement: {
    i18nKey: 'wealthManagement',
    defaults: 'Wealth Management',
  },
  proceedWith: {
    i18nKey: 'proceedWith',
    defaults: 'Proceed with {{regulator}}',
  },
  regNotAllowedPopupcysec:{
    i18nKey: 'regNotAllowedPopupcysec',
    defaults: 'Please kindly note that our CySEC licenced entity only accepts EU/EEA residents as clients<0/> <1/>As your country of residence is outside the EEA, your account will be registered under our {{choosedEntity}} licensed entity. Thank you for understanding'
  },
  regNotAllowedPopup:{
    i18nKey: 'regNotAllowedPopup',
    defaults: 'Please kindly note that our {{entity}} licenced entity does not accept clients from your country of resident<0/> <1/> Your account will be registered under our {{choosedEntity}} licensed entity. Thank you for understanding'
  },
  forbiddenCountryPopup:{
    i18nKey: 'forbiddenCountryPopup',
    defaults: 'You are not allowed to register from your country of residence. Thank you for understanding.'
  },
  importantNotification:{
    i18nKey: 'importantNotification',
    defaults: 'Important Notification'
  },
  dueDiligenceSubmitted: {
    i18nKey: 'dueDiligenceSubmitted',
    defaults: 'Due Diligence submitted'
  },
  dueDiligenceRejected: {
    i18nKey: 'dueDiligenceRejected',
    defaults: 'Due Diligence rejected'
  },
  generalTitle: {
    i18nKey: 'generalTitle',
    defaults: 'General Verification',
  },
  ibVerificationTitle: {
    i18nKey: 'ibVerificationTitle',
    defaults: 'IB Verification',
  },
  ibCorporateVerificationTitle: {
    i18nKey: 'ibVerificationTitle',
    defaults: 'IB Verification',
  },
  underReview: {
    i18nKey: 'underReview',
    defaults: 'Under Review'
  },
  dueDiligenceUnderReview: {
    i18nKey: 'dueDiligenceUnderReview',
    defaults: 'Due Diligence under review'
  },
  company: {
    i18nKey: 'company',
    defaults: 'Company',
  },
  individual: {
    i18nKey: 'individual',
    defaults: 'Individual',
  },
  currency: {
    i18nKey: 'currency',
    defaults: 'Currency',
  },
  signUpIB:{
    i18nKey: 'signUpIB',
    defaults: 'Register as an Introducing Broker'
  },
  signUpIBHighlighted:{
    i18nKey: 'signUpIBHighlighted',
    defaults: 'Register as an <0>Introducing Broker</0>'
  },
  signUpIBCheckbox:{
    i18nKey: 'signUpIBCheckbox',
    // eslint-disable-next-line no-template-curly-in-string
    defaults: 'I hereby verify that I am 18 years or older, I have read and accept your <0>General Terms & Conditions</0> of {{company}}, <1>Legal Documents </1>, <2>Personal Data Protection</2>, <3>Code of Conduct</3>, <4>Marketing Guidelines</4>, <5>Partner  Agreement</5>, <6>Master IB Appendix</6> & <7>{{appendixNumber}}</7>',
  },
  ibAccountNotApproved: {
    i18nKey: 'ibAccountNotApproved',
    defaults: 'Your account is pending approval. Please verify your account to be able to login to IB portal.',
  },
  ibAccountNotApprovedText2:{
    i18nKey: 'ibAccountNotApprovedText2',
    defaults: 'For further questions, do not hesitate to contact us via email at {{email}}.',
  },
  pleaseWait: {
    i18nKey: 'pleaseWait',
    defaults: 'Please wait',
  },
  rememberMe: {
    i18nKey: 'rememberMe',
    defaults: 'Remember Me',
  },
  forgetMe: {
    i18nKey: 'forgetMe',
    defaults: 'Forget Me',
  },
  payInstantlyWith: {
    i18nKey: 'payInstantlyWith',
    defaults: 'Pay Instantly with:',
  },
  unlinkMyAccount: {
    i18nKey: 'unlinkMyAccount',
    defaults: 'Unlink my Account',
  },
  BDErrorAccountClosed:{
    i18nKey: 'BDErrorAccountClosed',
    defaults: 'Your account was closed. Please contact <0>support</0>.'
  },
  maxLengthError:{
    i18nKey: 'maxLengthError',
    defaults: 'Maximum characters {{length}}.',
  },
  waitingTimeDue: {
    i18nKey: 'waitingTimeDue',
    defaults: 'Your document will be processed in due course',
  },
  waitingTime: {
    i18nKey: 'waitingTime',
    defaults: 'Your document will be processed in approximately {{docProcessingTime}} {{working}} hours',
  },
  waitingTimeWorking: {
    i18nKey: 'waitingTimeWorking',
    defaults: 'working',
  },
  waitingTimeFootnote: {
    i18nKey: 'waitingTimeFootnote',
    defaults: 'please note that our backoffice team works Monday to Friday 24/5',
  },
  pleaseChooseAccount: {
    i18nKey: 'pleaseChooseAccount',
    defaults: 'Please choose one of the following accounts:',
  },
  almostDone:{
    i18nKey: 'almostDone',
    defaults: 'Almost Done!',
  },
  importantNotice:{
    i18nKey: 'importantNotice',
    defaults: 'Important Notice',
  },
  retakeTest:{
    i18nKey: 'retakeTest',
    defaults: 'Retake the test',
  },
  startTradingBtn: {
    i18nKey: 'startTradingBtn',
    defaults: 'Start Trading',
  },
  appropTestDesc: {
    i18nKey: 'appropTestDesc',
    defaults: 'The Appropriateness Test is mandatory for all {{company}} clients and was put in place to protect you as a client by ensuring that you have a good understanding of what trading forex and CFDs involves.'
  },
  helpSupport: {
    i18nKey: 'helpSupport',
    defaults: 'Need help? Please contact our <0>Support Team</0>.'
  },
  thankyouDepositAppropTest: {
    i18nKey: 'thankyouDepositAppropTest',
    defaults: 'Thank you for your deposit, your funds have been received and will appear on your account after you have completed your Appropriateness Test.'
  },
  minimumDepositFootnote: {
    i18nKey: 'minimumDepositFootnote',
    defaults: 'Please note that the minimum first deposit amount may differentiate from the {{amount}} limit (or trading account currency equivalent), depending on your country of residence and whether you have accessed our website through a third party or using a referral link.'
  },
  description:{
    i18nKey: 'description',
    defaults: 'Description',
  },
  readMore:{
    i18nKey: 'readMore',
    defaults: 'Read more'
  },
  hide: {
    i18nKey: 'hide',
    defaults: 'Hide'
  },
  leverageUpdateSuggestion: {
    i18nKey: 'leverageUpdateSuggestion',
    defaults: 'Your account has been set to a maximum <0>1:100</0> default Leverage. You can increase your leverage <1>here</1>. For more information, please contact our Support Team.'
  },
  refresh:{
    i18nKey: 'refresh',
    defaults: 'Refresh',
  },
  somethingWrongRefresh: {
    i18nKey: 'somethingWrongRefresh',
    defaults: "Something went wrong while fetching your accounts, please refresh the page."
  },
  customerSupport: {
    i18nKey: 'customerSupport',
    defaults: 'Should your documents not be processed in this time frame, contact our Customer Support or check for emails from us.'
  },
  depositResultHeldPartial: {
    i18nKey: 'depositResultHeldPartial',
    defaults: 'Due to insufficient funds your transaction was partially approved for the amount of <0>{{amount}}</0>. <1/>By clicking  <0>"Continue"</0> your deposit will be successful and your account will be credited.'
  },
  cancelTransaction: {
    i18nKey: 'cancelTransaction',
    defaults: 'Cancel Transaction'
  },
  credit: {
    i18nKey: 'credit',
    defaults: 'Credit'
  },
  affiliateDesc:{
    i18nKey: 'affiliateDesc',
    defaults: 'Open an Affiliate Account and benefit from our customised commission plans and daily withdrawal options.'
  },
  forexMt4ibDesc:{
    i18nKey: 'forexMt4ibDesc',
    defaults: 'Open an Introducing Broker account and start monetising your traffic with the help of our industry experts.'
  },
  moreDetails:{
    i18nKey: 'moreDetails',
    defaults: 'Enter your details'
  },
  addAccountDetailsDesc: {
    i18nKey: 'addAccountDetailsDesc',
    defaults: 'Please enter your skype ID, website and promoting countries.'
  },
  pleaseNote: {
    i18nKey: 'pleaseNote',
    defaults: 'Please note!'
  },
  partialDepositNote: {
    i18nKey: 'partialDepositNote',
    defaults: '{{type}} Account will be enabled automatically once a minimum account balance of <0 /> is reached.'
  },
  enableAccess: {
    i18nKey: 'enableAccess',
    defaults: 'Enable Access'
  },
  vipAutochartistStats: {
    i18nKey: 'vipAutochartistStats',
    defaults: 'Want to receive our VIP signals with Autochartist and Performance Stats?'
  },
  autochartistToggle: {
    i18nKey: 'autochartistToggle',
    defaults: 'I want to receive autochartist signals to my email.'
  },
  availableFundedAccount: {
    i18nKey: 'availableFundedAccount',
    defaults: 'Available for funded accounts.'
  },
  vipAccountTitle: {
    i18nKey: 'vipAccountTitle',
    defaults: 'VIP Account'
  },
  rawAccountTitle: {
    i18nKey: 'rawAccountTitle',
    defaults: 'Raw Account'
  },
  completedCompetitions: {
    i18nKey: 'completedCompetitions',
    defaults: 'Completed Competitions'
  },
  joinVipClubTitle2: {
    i18nKey: 'joinVipClubTitle2',
    defaults: 'Join our VIP Club & Enjoy Exclusive Benefits'
  },
  vipAccountPrivilaged: {
    i18nKey: 'vipAccountPrivilaged',
    defaults: '{{company}} offers two premium accounts VIP Account and Raw Account; both come with a set of benefits catered to high volume traders including complimentary trading alerts, live webinars, premium autochartist access, dedicated support and lower trading costs.'
  },
  liveAlertsTextVip: {
    i18nKey: 'liveAlertsTextVip',
    defaults: 'Receive Live Trading Alerts via Telegram with a proven success record directly from {{company}}’ seasoned traders'
  },
  zeroWaitingVip: {
    i18nKey: 'zeroWaitingVip',
    defaults: 'zero waiting time'
  },
  zeroWaitingTextVip: {
    i18nKey: 'zeroWaitingTextVip',
    defaults: 'Skip the waiting lists and get priority on all transactions from KYC to deposits and withdrawals'
  },
  lowerTradingCosts: {
    i18nKey: 'lowerTradingCosts',
    defaults: 'lower trading costs'
  },
  evenLowerSpreadsTextVip: {
    i18nKey: 'evenLowerSpreadsTextVip',
    defaults: 'Enjoy up to 40% lower spreads and commissions with our VIP account and ultra-thin spreads from 0.0 with our Raw Account!'
  },
  noFeesDepositsVip: {
    i18nKey: 'noFeesDepositsVip',
    defaults: '0 fees on cc deposits & withdrawals'
  },
  noFeesDepositsTextVip: {
    i18nKey: 'noFeesDepositsTextVip',
    defaults: 'Enjoy low-cost trading with no fees on credit card deposits or withdrawals over 20EUR'
  },
  fullAutochartistAccess: {
    i18nKey: 'fullAutochartistAccess',
    defaults: 'full autochartist access'
  },
  fullAutochartistAccessText: {
    i18nKey: 'fullAutochartistAccessText',
    defaults: 'Get notified on forming trends with confirmed support/resistance levels as well as performance stats on closed alerts'
  },
  spreadsFrom: {
    i18nKey: 'spreadsFrom',
    defaults: 'Spreads From'
  },
  from: {
    i18nKey: 'from',
    defaults: 'From'
  },
  openAccount: {
    i18nKey: 'openAccount',
    defaults: 'Open {{type}} account'
  },
  yourCapitalRisk: {
    i18nKey: 'yourCapitalRisk',
    defaults: 'Your capital is at risk'
  },
  activateVipRawAccount: {
    i18nKey: 'activateVipRawAccount',
    defaults: 'Activate your <0>{{type}}</0> account.'
  },
  waitingCovid19: {
    i18nKey: 'waitingCovid19',
    defaults: 'Extended waiting times due to COVID-19 safety measures.'
  },
  delays: {
    i18nKey: 'delays',
    defaults: 'Delays in Processing Times'
  },
  covidNotificationText: {
    i18nKey: 'covidNotificationText',
    defaults: 'At {{company}} we were quick to respond to the global COVID-19 crisis by following the local guidelines and restrictions and putting all the necessary measures in place to ensure the health and safety of our employees.'
  },
  covidNotificationText2: {
    i18nKey: 'covidNotificationText2',
    defaults: 'We continue to operate smoothly, our platforms are fully online and our trading services remain uninterrupted. However, due to the increasing workload and telecommuting challenges, we are currently experiencing minor delays in our Account Verification and Withdrawal processing times. We are onboarding new personnel to meet the increasing demand and hope to resolve this shortly.'
  },
  covidNotificationText3: {
    i18nKey: 'covidNotificationText3',
    defaults: 'We apologise for any inconvenience.'
  },
  forexMt4basicDesc:{
    i18nKey: 'forexMt4basicDesc',
    defaults: 'Trade 250+CFDs on leading platforms and access 24/5 multi-lingual support, daily briefs and live webinars. Spreads start at {{averageSpread}} pips and standard commissions apply. '
  },
  forexMt5basicDesc:{
    i18nKey: 'forexMt5basicDesc',
    defaults: 'Trade 250+CFDs on leading platforms and access 24/5 multi-lingual support, daily briefs and live webinars. Spreads start at {{averageSpread}} pips and standard commissions apply. '
  },
  forexMt4standardDesc:{
    i18nKey: 'forexMt4standardDesc',
    defaults: 'Trade 250+CFDs on leading platforms and access 24/5 multi-lingual support, daily briefs and live webinars. Spreads start at {{averageSpread}} pips and standard commissions apply. '
  },
  forexMt4blackDesc:{
    i18nKey: 'forexMt4blackDesc',
    defaults: 'Get more out of your account with reduced spreads from {{averageSpread}}, dedicated support, competitive commissions, VIP Trading Alerts, webinars and Autochartist tools.'
  },
  forexMt5blackDesc:{
    i18nKey: 'forexMt5blackDesc',
    defaults: 'Get more out of your account with reduced spreads from {{averageSpread}}, dedicated support, competitive commissions, VIP Trading Alerts, webinars and Autochartist tools.'
  },
  forexMt4proDesc:{
    i18nKey: 'forexMt4proDesc',
    defaults: 'Get more out of your account with reduced spreads from {{averageSpread}}, dedicated support, competitive commissions, VIP Trading Alerts, webinars and Autochartist tools.'
  },
  forexMt4zeroDesc: {
    i18nKey: 'forexMt4zeroDesc',
    defaults: 'Lower your trading costs with raw spreads from 0 pips on 40+ forex and gold pairs and gain VIP access to Trading Alerts, webinars and Autochartist.'
  },
  forexMt4zeroDemoDesc:{
    i18nKey: 'forexMt4zeroDemoDesc',
    defaults:  'Practice trading with raw spreads from 0 pips on 40+ forex and gold pairs and gain VIP access to Trading Alerts, webinars and Autochartist.'
  },
  forexMt4swiss5Desc:{
    i18nKey: 'forexMt4swiss5Desc',
    defaults: 'Lower your trading costs with raw spreads from 0 pips ($5 commission charged based on account base currency).'
  },
  forexMt4swiss8Desc:{
    i18nKey: 'forexMt4swiss8Desc',
    defaults: 'Lower your trading costs with raw spreads from 0 pips ($8 commission charged based on account base currency).'
  },
  forexMt4swiss11Desc:{
    i18nKey: 'forexMt4swiss11Desc',
    defaults: 'Lower your trading costs with raw spreads from 0 pips ($11 commission charged based on account base currency).'
  },
  compareAccountTypes:{
    i18nKey: 'compareAccountTypes',
    defaults: 'Compare account types'
  },
  minDepositAmount: {
    i18nKey: 'minDepositAmount',
    defaults: 'Minimum deposit amount'
  },
  averageSpread: {
    i18nKey: 'averageSpread',
    defaults: 'Average spread (EUR/USD)'
  },
  forexMetals: {
    i18nKey: 'forexMetals',
    defaults: 'Forex, Metals'
  },
  energies: {
    i18nKey: 'energies',
    defaults: 'Energies'
  },
  indices: {
    i18nKey: 'indices',
    defaults: 'Indices'
  },
  equities: {
    i18nKey: 'equities',
    defaults: 'Equities (Stocks)'
  },
  shares: {
    i18nKey: 'shares',
    defaults: 'Shares'
  },
  etfs: {
    i18nKey: 'etfs',
    defaults: 'ETFs'
  },
  benefits: {
    i18nKey: 'benefits',
    defaults: 'Benefits'
  },
  autochartistPerformanceStats: {
    i18nKey: 'autochartistPerformanceStats',
    defaults: 'Autochartist Performance Stats'
  },
  priorityServices: {
    i18nKey: 'priorityServices',
    defaults: 'Priority Services'
  },
  equivalentPerLot: {
    i18nKey: 'equivalentPerLot',
    defaults: '{{value}} (or equivalent) per lot'
  },
  ofVolume: {
    i18nKey: 'ofVolume',
    defaults: '{{value}} of the volume'
  },
  commissionValue: {
    i18nKey: 'commissionValue',
    defaults: '{{value}}'
  },
  minDepositAmountText: {
    i18nKey: 'minDepositAmountText',
    defaults: '{{value}} {{currencies}}'
  },
  leverageText: {
    i18nKey: 'leverageText',
    defaults: '{{value}}'
  },
  averageSpreadText: {
    i18nKey: 'averageSpreadText',
    defaults: '{{value}} pips'
  },
  availableAccounts: {
    i18nKey: 'availableAccounts',
    defaults: '{{available}} of {{maxAllowed}} available',
  },
  maximumAllowedAccounts: {
    i18nKey: 'maximumAllowedAccounts',
    defaults: 'It appears that you have reached the maximum allowed amount of Trading Accounts. If you wish to register an additional account or manage existing accounts, please contact our customer support team.',
  },
  contactUs: {
    i18nKey: 'contactUs',
    defaults: 'Contact Us',
  },
  limitReached: {
    i18nKey: 'limitReached',
    defaults: 'Limit Reached!',
  },
  twentyfourFiveSupport: {
    i18nKey: 'twentyfourFiveSupport',
    defaults: '24/5 Support',
  },
  dividendsPaid: {
    i18nKey: 'dividendsPaid',
    defaults: 'Dividends paid on long term positions',
  },
  dailyAnalysis: {
    i18nKey: 'dailyAnalysis',
    defaults: 'Daily Analysis',
  },
  freeLiveWebinars: {
    i18nKey: 'freeLiveWebinars',
    defaults: 'Free Live Webinars',
  },
  basicAccessAutochartist: {
    i18nKey: 'basicAccessAutochartist',
    defaults: 'Basic Access to Autochartist',
  },
  limitedAccessTradingAlerts: {
    i18nKey: 'limitedAccessTradingAlerts',
    defaults: 'Limited Access to Trading Alerts',
  },
  personalAccountManager: {
    i18nKey: 'personalAccountManager',
    defaults: 'Personal Account Manager',
  },
  accessExclusiveWebinars: {
    i18nKey: 'accessExclusiveWebinars',
    defaults: 'Access to Exclusive Webinars',
  },
  vipTradingAlerts: {
    i18nKey: 'vipTradingAlerts',
    defaults: 'VIP Trading Alerts via Telegram',
  },
  rawSpreadsFromZero: {
    i18nKey: 'rawSpreadsFromZero',
    defaults: 'Raw Spreads From 0',
  },
  copyTrading: {
    i18nKey: 'copyTrading',
    defaults: 'Copy Trading'
  },
  availableStrategies: {
    i18nKey: 'availableStrategies',
    defaults: 'Available Strategies'
  },
  totalTrades: {
    i18nKey: 'totalTrades',
    defaults: 'Total Trades'
  },
  winningTrades: {
    i18nKey: 'winningTrades',
    defaults: 'Winning Trades'
  },
  losingTrades: {
    i18nKey: 'losingTrades',
    defaults: 'Losing Trades'
  },
  closedProfit: {
    i18nKey: 'closedProfit',
    defaults: 'Closed Profit'
  },
  roi: {
    i18nKey: 'roi',
    defaults: 'ROI'
  },
  tradingLength: {
    i18nKey: 'tradingLength',
    defaults: 'Trading Length'
  },
  startCopying: {
    i18nKey: 'startCopying',
    defaults: 'Start Copying'
  },
  stopCopying: {
    i18nKey: 'stopCopying',
    defaults: 'Stop Copying'
  },
  copyTradingSuggestion: {
    i18nKey: 'copyTradingSuggestion',
    defaults: 'Your are copying trading strategy from account {{ibAccount}}.'
  },
  goToStrategies: {
    i18nKey: 'goToStrategies',
    defaults: 'Go to Strategies'
  },
  requestCopyTradingTitle: {
    i18nKey: 'requestCopyTradingTitle',
    defaults: 'You are about to <0>start copying</0> trades',
  },
  requestCopyTradingText: {
    i18nKey: 'requestCopyTradingText',
    defaults: 'When copy-trading another trader, all of his positions will be automatically replicated by your trading account.',
  },
  requestCopyTradingNoPerformanceFeeText: {
    i18nKey: 'requestCopyTradingNoPerformanceFeeText',
    defaults: 'You can always manage and adjust all open positions or choose to stop copy-trading altogether.',
  },
  requestCopyTradingText2: {
    i18nKey: 'requestCopyTradingText2',
    defaults: 'As the selected strategy is charging a <0>{{fee}}% performance fee</0>, You will not be able to manage and adjust all copied positions or trade on the account until you stop copying. Copying is done via the Equity Allocation model.',
  },
  cancelCopyTradingText: {
    i18nKey: 'cancelCopyTradingText',
    defaults: 'You are about to <0>cancel your</0> copying trading request.',
  },
  cancelRequest: {
    i18nKey: 'cancelRequest',
    defaults: 'Cancel Request',
  },
  requestStopCopyTradingTitle: {
    i18nKey: 'requestStopCopyTradingTitle',
    defaults: 'You are about to <0>stop copying</0> trades',
  },
  requestStopCopyTradingText: {
    i18nKey: 'requestStopCopyTradingText',
    defaults: 'Once you stop copying a strategy, your account will no longer replicate positions automatically. All existing positions will remain open until they reach their pre-determined Stop Loss or Take Profit Level, or they can be adjusted and closed manually by you.',
  },
  performaceSwitchLabel: {
    i18nKey: 'performaceSwitchLabel',
    defaults: 'View Copy Trading Performance',
  },
  performaceSwitchInfo: {
    i18nKey: 'performaceSwitchInfo',
    defaults: 'When enabled, you will be able to view and manage the performance of your copied positions.',
  },
  copying: {
    i18nKey: 'copying',
    defaults: 'Copying'
  },
  allocationMethodLabel: {
    i18nKey: 'allocationMethodLabel',
    defaults: 'Allocation Method',
  },
  percentageLabel: {
    i18nKey: 'percentageLabel',
    defaults: 'Percentage',
  },
  days:{
    i18nKey: 'days',
    defaults: 'Days',
  },
  depositPendingCopying:{
    i18nKey: 'depositPendingCopying',
    defaults: 'Start copying by depositing to your account.',
  },
  autochartistPerformanceStatsTitle: {
    i18nKey: 'autochartistPerformanceStatsTitle',
    defaults: 'Activate Autochartist Performance Stats',
  },
  bankAccount: {
    i18nKey: 'bankAccount',
    defaults: 'Bank Account',
  },
  clientHasSameLastnameAndDob: {
    i18nKey: 'clientHasSameLastnameAndDob',
    defaults: 'It seems that you already hold an account with {{entity}}. Please proceed with <0>logging</0> in to your Client Dashboard',
  },
  underagedClient: {
    i18nKey: 'underagedClient',
    defaults: 'Underaged Client',
  },
  explanationField: {
    i18nKey: 'explanationField',
    defaults: 'Please use your {{field}} as displayed in your identity document.',
  },
  yourRank: {
    i18nKey: 'yourRank',
    defaults: 'Your Rank'
  },
  minimumBalanceRequiredError: {
    i18nKey: 'minimumBalanceRequiredError',
    defaults: 'Minimum balance required:  <0>{{amount}}</0> <1> Deposit Now </1>'
  },
  competition: {
    i18nKey: 'competition',
    defaults: 'Competition'
  },
  stoppedParticipating: {
    i18nKey: 'stoppedParticipating',
    defaults: 'You have stopped participating in this competition'
  },
  stoppedParticipatingText: {
    i18nKey: 'stoppedParticipatingText',
    defaults: 'You have left this competition on the account <0>{{accountId}}</0> and all your progress has been reset. If you wish to participate in this competition again, you will have to use a different account, or wait for the next competition.'
  },
  selectDifferentAccount: {
    i18nKey: 'selectDifferentAccount',
    defaults: 'Select different account'
  },
  nicknameExists:{
    i18nKey: 'nicknameExists',
    defaults: 'nickname already exists',
  },
  showMore:{
    i18nKey: 'showMore',
    defaults: 'Show more',
  },
  seconds: {
    i18nKey: 'seconds',
    defaults: 'Seconds'
  },
  competitionEnded: {
    i18nKey: 'competitionEnded',
    defaults: 'Competition Ended'
  },
  competitionExitedMsg: {
    i18nKey: 'competitionExitedMsg',
    defaults: 'You have successfully exited competition'
  },
  signUpCompetition:{
    i18nKey: 'signUpCompetition',
    defaults: 'I have read and accept the <0>Trading Competition Terms & Conditions</0> of {{company}}',
  },
  enterCompetition:{
    i18nKey: 'enterCompetition',
    defaults: 'Enter Competition',
  },
  tradingCompetition: {
    i18nKey: 'tradingCompetition',
    defaults: 'Trading competition'
  },
  requestBodyAccessToCompetitionManage: {
    i18nKey: 'requestBodyAccessToCompetitionManage',
    defaults: 'In order to run your own Trading Competitions, you’ll need to obtain authorization from the Partner agent. Please submit your request to have this ability.'
  },
  requestBodyAccessToCompetitionPending: {
    i18nKey: 'requestBodyAccessToCompetitionPending',
    defaults: 'Request submitted - Please wait for our Agent partner to approve the request.'
  },
  submitARequest: {
    i18nKey: 'submitARequest',
    defaults: 'Submit a request'
  },
  requestSubmitted: {
    i18nKey: 'requestSubmitted',
    defaults: 'Request submitted'
  },
  tradingCompetitions: {
    i18nKey: 'tradingCompetitions',
    defaults: 'Trading Competitions'
  },
  setUpCompetition: {
    i18nKey: 'setUpCompetition',
    defaults: 'Set-up a competition'
  },
  setUpCompetitionDescription: {
    i18nKey: 'setUpCompetitionDescription',
    defaults: 'Competitions can help you promote to new leads, as well as, solidify your relationship with existing clients by providing them exclusive trading opportunities.'
  },
  termsLink: {
    i18nKey: 'termsLink',
    defaults: 'Terms link'
  },
  generalInformation: {
    i18nKey: 'generalInformation',
    defaults: 'General Information'
  },
  startDate: {
    i18nKey: 'startDate',
    defaults: 'Start date'
  },
  endDate: {
    i18nKey: 'endDate',
    defaults: 'End date'
  },
  yes: {
    i18nKey: 'yes',
    defaults: 'Yes'
  },
  no: {
    i18nKey: 'no',
    defaults: 'No'
  },
  acceptPreviousWinners: {
    i18nKey: 'acceptPreviousWinners',
    defaults: 'Accept previous winners'
  },
  chooseCompetitionLogo: {
    i18nKey: 'chooseCompetitionLogo',
    defaults: 'Choose competition logo'
  },
  rules:{
    i18nKey: 'rules',
    defaults: 'Rules'
  },
  rule:{
    i18nKey: 'rule',
    defaults: 'Rule'
  },
  prizes: {
    i18nKey: 'prizes',
    defaults: 'Prizes'
  },
  prizeFirst: {
    i18nKey: 'prizeFirst',
    defaults: 'First Prize'
  },
  prizeSecond: {
    i18nKey: 'prizeSecond',
    defaults: 'Second Prize'
  },
  prizeThird: {
    i18nKey: 'prizeThird',
    defaults: 'Third Prize'
  },
  options: {
    i18nKey: 'options',
    defaults: 'Options'
  },
  competitionOptionsNewlyCreatedAccount: {
    i18nKey: 'competitionOptionsNewlyCreatedAccount',
    defaults: 'Only newly created accounts can participate in the competition'
  },
  competitionOptionsDemoAccountsOnly: {
    i18nKey: 'competitionOptionsDemoAccountsOnly',
    defaults: 'Only demo accounts can participate in the competition'
  },
  competitionOptionsDefaultLeverageAccountsOnly: {
    i18nKey: 'competitionOptionsDefaultLeverageAccountsOnly',
    defaults: 'Only default leverage accounts can participate in the competition'
  },
  leaderboard: {
    i18nKey: 'leaderboard',
    defaults: 'Leaderboard'
  },
  nickname: {
    i18nKey: 'nickname',
    defaults: 'Nickname'
  },
  selectCompetitionAccount: {
    i18nKey: 'selectCompetitionAccount',
    defaults: 'Select Competition Account'
  },
  entryFee: {
    i18nKey: 'entryFee',
    defaults: 'Entry Fee'
  },
  minBalance: {
    i18nKey: 'minBalance',
    defaults: 'Minimum Balance'
  },
  participants: {
    i18nKey: 'participants',
    defaults: 'Participants'
  },
  daysRemaining: {
    i18nKey: 'daysRemaining',
    defaults: 'Days Remaining'
  },
  hours: {
    i18nKey: 'hours',
    defaults: 'Hours'
  },
  rank: {
    i18nKey: 'rank',
    defaults: 'Rank'
  },
  turnover: {
    i18nKey: 'turnover',
    defaults: 'Turnover'
  },
  startCompetition: {
    i18nKey: 'startCompetition',
    defaults: 'Start Competition'
  },
  competitionRules: {
    i18nKey: 'competitionRules',
    defaults: 'Competition Rules'
  },
  competitionRulesAreMandatory: {
    i18nKey: 'competitionRulesAreMandatory',
    defaults: 'At least one rule is mandatory'
  },
  competitionRulesAreDuplicates: {
    i18nKey: 'competitionRulesAreDuplicates',
    defaults: 'Cannot proceed due to duplicate rules'
  },
  quitCompetitionError: {
    i18nKey: 'quitCompetitionError',
    defaults: 'This account has quit competition'
  },
  differentCompetitionError: {
    i18nKey: 'differentCompetitionError',
    defaults: 'This account has is participating in a different competition'
  },
  exitCompetition: {
    i18nKey: 'exitCompetition',
    defaults: 'Exit Competition'
  },
  participatingAccount: {
    i18nKey: 'participatingAccount',
    defaults: 'Participating Account'
  },
  manage: {
    i18nKey: 'manage',
    defaults: 'Manage'
  },
  yourBalanceOnly: {
    i18nKey: 'yourBalanceOnly',
    defaults: 'Your Balance'
  },
  copyTradingError: {
    i18nKey: 'copyTradingError',
    defaults: 'This account is copying a strategy'
  },
  stopParticipating: {
    i18nKey: 'stopParticipating',
    defaults: 'You are about to stop participating in this competition'
  },
  stopParticipatingMsg: {
    i18nKey: 'stopParticipatingMsg',
    defaults: 'All your progress will be reset. If you wish to participate in this competition again, you will have to use a different account, or wait for the next competition.'
  },
  participatedCompetition:{
    i18nKey: 'participatedCompetition',
    defaults: 'Client participated again in this competition',
  },
  cannotParticipateCompetition:{
    i18nKey: 'cannotParticipateCompetition',
    defaults: 'This account cannot participate in a competition',
  },
  noClassicError: {
    i18nKey: 'noClassicError',
    defaults: 'Only Classic Accounts can participate in a Competition'
  },
  competitionIsNewlyCreatedAccountsOnlyError: {
    i18nKey: 'competitionIsNewlyCreatedAccountsOnlyError',
    defaults: 'This competition is only for newly created accounts'
  },
  emptyLeaderboardUpdated:{
    i18nKey: 'emptyLeaderboardUpdated',
    defaults: 'This competition doesn\'t have any participants yet'
  },
  verifyYourAccount:{
    i18nKey: 'verifyYourAccount',
    defaults: 'Verify your account'
  },
  verifyText:{
    i18nKey: 'verifyText',
    defaults: 'To begin trading and participating in the competition, please verify your account.'
  },
  lastUpdatedAt:{
    i18nKey: 'lastUpdatedAt',
    defaults: 'Last updated at {{updatedAt}}'
  },
  registrationRejectedUsCitizen: {
    i18nKey: 'registrationRejectedUsCitizen',
    defaults: 'Your account registration was rejected as you have been identified as a US Reportable Person.',
  },
  pepTitle: {
    i18nKey: 'pepTitle',
    defaults: 'Does any of the following statements apply to you?',
  },
  pepSubtitle: {
    i18nKey: 'pepSubtitle',
    defaults: 'I or any of my family members (consanguinity or civil form):',
  },
  tinUsError: {
    i18nKey: 'tinUsError',
    defaults: 'Please enter a valid U.S Tax Identification Number',
  },
  viewDetails: {
    i18nKey: 'viewDetails',
    defaults: 'View Details',
  },
  cancelCopyTradingText2: {
    i18nKey: 'cancelCopyTradingText2',
    defaults: 'Once you have cancelled the copy trading request, you will not be able to copy this strategy for 24 hours.',
  },
  cancelledRequest: {
    i18nKey: 'cancelledRequest',
    defaults: 'Cancelled Request',
  },
  creditingAccount: {
    i18nKey: 'creditingAccount',
    defaults: 'Crediting Account'
  },
  confirmValidPersonalDetails:{
    i18nKey: 'confirmValidPersonalDetails',
    defaults: 'Please ensure that your personal details and address match the ones on your identity document and utility bill respectively.'
  },
  houseNumberRequired:{
    i18nKey: 'houseNumberRequired',
    defaults: 'Please provide house number if applicable for enhanced account verification.'
  },
  noTransactionsCurrent: {
    i18nKey: 'noTransactionsCurrent',
    defaults: 'There are no transactions to display with the selected filters <1>{{totalTransactions}}</1>. <0>{{showMore}}</0>',
  },
  rowsInTable: {
    i18nKey: 'rowsInTable',
    defaults: 'Rows in table',
  },
  filtersApplied: {
    i18nKey: 'filtersApplied',
    defaults: 'filters applied',
  },
  entriesOf: {
    i18nKey: 'entriesOf',
    defaults: '{{entries}}{{showStar}} of {{entriesCount}}',
  },
  entriesOfInline: {
    i18nKey: 'entriesOfInline',
    defaults: '({{entries}}{{showStar}} of {{entriesCount}} Transactions)',
  },
  rollbackAddress: {
    i18nKey: 'rollbackAddress',
    defaults: 'Rollback Address',
  },
  rollbackAddressNote: {
    i18nKey: 'rollbackAddressNote',
    defaults: '<0>Rollback Address:</0> Please enter your private wallet address which will be used in case of a declined transaction. Do not enter an Exchange wallet address as any funds sent there could be lost.',
  },
  yourBankDetails: {
    i18nKey: 'yourBankDetails',
    defaults: 'Your Bank Details'
  },
  yourBankDetailsText: {
    i18nKey: 'yourBankDetailsText',
    defaults: 'Please carefully complete your bank account details below in order to proceed with your bank wire transfer.'
  },
  equalsMoneyBankWarning: {
    i18nKey: 'equalsMoneyBankWarning',
    defaults: 'Also note that "Equals Money UK Limited" formerly operated under the name Spectrum Payments Services, which can still be selected as it is the same bank.'
  },
  absaBankWarning: {
    i18nKey: 'absaBankWarning',
    defaults: 'Also note that "ABSA Bank (Seychelles) Ltd" formerly operated under the name Barclays Bank Seychelles Limited, which can still be selected as it is the same bank.'
  },
  yourBankAccountHolderName:{
    i18nKey: 'bankAccountHolderName',
    defaults: 'Your Bank Account Holder`s Name'
  },
  yourIbanAccountNumber: {
    i18nKey: 'ibanAccountNumber',
    defaults: 'Your IBAN / Account Number'
  },
  yourSwiftBic: {
    i18nKey: 'swiftBic',
    defaults: 'Your Bank SWIFT / BIC'
  },
  submittedWireDetails: {
    i18nKey: 'submittedWireDetails',
    defaults: 'You have successfully submitted your bank wire details. In order to proceed with your transfer, please <0>access your personal bank\'s payment facility directly (either via online banking or in person)</0> and use {{company}} banking details listed below.'
  },
  yourBankDetailsRequired: {
    i18nKey: 'yourBankDetailsRequired',
    defaults: 'This information is required in order to identify and successfully complete your bank wire transfer. Once you "Submit" your details, you will view the bank wire details of {{company}}.'
  },
  openPositionsUsCitizen: {
    i18nKey: 'openPositionsUsCitizen',
    defaults: '{{company}} does not provide services to US Reportable Persons. For more information or if you think you have been misclassified, please <0>contact support</0>.',
  },
  performanceFee: {
    i18nKey: 'performanceFee',
    defaults: 'Performance Fee'
  },
  managePositions: {
    i18nKey: 'managePositions',
    defaults: 'Manage Positions',
  },
  changeAccount: {
    i18nKey: 'changeAccount',
    defaults: 'Change Account'
  },
  strategyOpenPositions: {
    i18nKey: 'strategyOpenPositions',
    defaults: 'If you wish to start copying a performance fee strategy on this account, all open positions must be previously closed, otherwise please use a different account or create a new one. Once you start copying a performance fee strategy, manual trading will be disabled on the chosen account.'
  },
  copyingOpenPositions: {
    i18nKey: 'copyingOpenPositions',
    defaults: 'Performance Fee strategies are not available as you have open positions'
  },
  commissionType: {
    i18nKey: 'commissionType',
    defaults: 'Commission Type: {{fee}}% performance fee',
  },
  restrictedCopyStrategy: {
    i18nKey: 'restrictedCopyStrategy',
    defaults: 'You are restricted from copying this strategy for {{value}} {{label}}, as you have cancelled the copy request',
  },
  paypalDisclaimer1: {
    i18nKey: 'paypalDisclaimer1',
    defaults: 'Note: Please assure that you are using one of the below payment methods to deposit via your Paypal account:',
  },
  paypalDisclaimer2: {
    i18nKey: 'paypalDisclaimer2',
    defaults: "Paypal wallet balance",
  },
  paypalDisclaimer3: {
    i18nKey: 'paypalDisclaimer3',
    defaults: 'Bank account connected to Paypal wallet',
  },
  paypalDisclaimer4: {
    i18nKey: 'paypalDisclaimer4',
    defaults: 'Payments from cards connected to Paypal wallets can’t be processed.',
  },
  paypalRiskWarning1: {
    i18nKey: 'paypalRiskWarning1',
    defaults: 'CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage {{percentage}}% <0>of retail investor accounts lose money when trading CFDs with this provider.</0>'
  },
  paypalRiskWarning2: {
    i18nKey: 'paypalRiskWarning2',
    defaults: 'You should consider whether you understand how CFDs work and whether you can afford to take the high risk of losing your money.'
  },
  generalRiskDisclosure: {
    i18nKey: 'generalRiskDisclosure',
    defaults: 'General Risk Disclosure',
  },
  pleaseCompletePopup: {
    i18nKey: 'pleaseCompletePopup',
    defaults: 'The {{forceDocument}} Form is mandatory for your account verification. Please complete this information to be able to access your account.'
  },
  completeForm: {
    i18nKey: 'completeForm',
    defaults: 'Complete Form'
  },
  affiliateVerificationTitle: {
    i18nKey: 'affiliateVerificationTitle',
    defaults: 'Affiliate Verification',
  },
  affiliateCorporateVerificationTitle: {
    i18nKey: 'affiliateVerificationTitle',
    defaults: 'Affiliate Verification',
  },
  fsaFooter: {
    i18nKey: 'fsaFooter',
    defaults: 'BDS Ltd (Registration No. 8424660-1) is authorized and regulated by the Financial Services Authority (the FSA, licence no. SD047) Registered address: Suite 3, Global Village, Jivan’s Complex, Mont Fleuri, Mahe, Seychelles.',
  },
  acceptedAppendixesTitle:{
    i18nKey: 'acceptedAppendixesTitle',
    defaults: 'Accepted Appendixes',
  },
  minLengthError: {
    i18nKey: 'minLengthError',
    defaults: 'Minimum two characters are required.',
  },
  validationError: {
    i18nKey: 'validationError',
    defaults: 'Please use latin and non consecutive characters, spaces and dashes only.',
  },
  phoneValidation: {
    i18nKey: 'phoneValidation',
    defaults: 'Please use a valid phone number',
  },
  phoneValidationInternational: {
    i18nKey: 'phoneValidationInternational',
    defaults: 'Phone number must be in international format (i.e +254 XXX XXXX, without leading "O")',
  },
  streetNameRequired:{
    i18nKey: 'streetNameRequired',
    defaults: 'Please enter only the street name.',
  },
  changeAmount: {
    i18nKey: 'changeAmount',
    defaults: 'Change Amount'
  },
  freeMarginWarningTitle: {
    i18nKey: 'freeMarginWarningTitle',
    defaults: 'Requested amount is {{amount}}, free margin is {{freeMargin}}',
  },
  freeMarginWarningMessage1: {
    i18nKey: 'freeMarginWarningMessage1',
    defaults: 'Please note that you may run out of free margin and all your open positions will be stopped.',
  },
  freeMarginWarningMessage2: {
    i18nKey: 'freeMarginWarningMessage2',
    defaults: 'Also, if your free margin decreases below <0>{{amount}}</0>, your withdrawal request will be rejected or you can request a smaller withdrawal amount.',
  },
  notUploaded: {
    i18nKey: 'notUploaded',
    defaults: 'Not uploaded'
  },
  resubmit: {
    i18nKey: 'resubmit',
    defaults: 'Resubmit'
  },
  wantSkipWaitingList: {
    i18nKey: 'wantSkipWaitingList',
    defaults: 'Want to skip the waiting list?'
  },
  vipAccountsPriority: {
    i18nKey: 'vipAccountsPriority',
    defaults: 'VIP Accounts have a priority lane and are reviewed much faster, along with many other benefits!'
  },
  pleaseMakeSure: {
    i18nKey: 'pleaseMakeSure',
    defaults: 'Please make sure:'
  },
  properlyUploadDocuments: {
    i18nKey: 'properlyUploadDocuments',
    defaults: 'How to properly upload documents'
  },
  submitDocument: {
    i18nKey: 'submitDocument',
    defaults: 'Submit Document'
  },
  submitDocuments: {
    i18nKey: 'submitDocuments',
    defaults: 'Submit Documents'
  },
  change: {
    i18nKey: 'change',
    defaults: 'Change'
  },
  formatFileError: {
    i18nKey: 'formatFileError',
    defaults: 'Wrong file format selected'
  },
  sizeFileError: {
    i18nKey: 'sizeFileError',
    defaults: 'File size too large'
  },
  uploadProperly: {
    i18nKey: 'uploadProperly',
    defaults: 'How to properly upload documents'
  },
  takingPictures: {
    i18nKey: 'takingPictures',
    defaults: 'When taking pictures or scan of your documents, make sure that your hand does not cover any part of the {{document}}, and that all the information is clearly visible.'
  },
  documentsExamples: {
    i18nKey: 'documentsExamples',
    defaults: 'Please see the examples below and make sure that your {{document}} photo or scan is similar. If your document is submitted correctly, it increases your chances of successful verification.'
  },
  shouldInclude: {
    i18nKey: 'shouldInclude',
    defaults: 'Documents should include:'
  },
  instructions: {
    i18nKey: 'instructions',
    defaults: 'Instructions:'
  },
  verifedPaymentMethods: {
    i18nKey: 'verifedPaymentMethods',
    defaults: 'Verified payment methods:'
  },
  threeCCsWarning: {
    i18nKey: 'threeCCsWarning',
    defaults: 'Please note that maximum 3 cards are allowed to be used for depositing funds. To ensure prompt processing of funds, kindly provide your card copies.',
  },
  tooManyRequests:{
    i18nKey: 'tooManyRequests',
    defaults: 'Your account was locked due to too many failed login attempts. Please try again in 5 minutes.',
  },
  tooManyRequestsLogin:{
    i18nKey: 'tooManyRequestsLogin',
    defaults: 'Your account was locked due to too many login attempts. Please try again in 5 minutes'
  },
  prefixValidation: {
    i18nKey: 'prefixValidation',
    defaults: 'Please use a valid country code prefix',
  },
  ibanValidation: {
    i18nKey: 'ibanValidation',
    defaults: 'Please enter a valid iban / account number',
  },
  swiftCodeValidation: {
    i18nKey: 'swiftCodeValidation',
    defaults: 'Please enter a valid bank swift / bic',
  },
  routingNumberValidation: {
    i18nKey: 'routingNumberValidation',
    defaults: 'Please enter a valid routing number',
  },
  ibanSwiftNotCoherent: {
    i18nKey: 'ibanSwiftNotCoherent',
    defaults: 'IBAN / Swift not coherent',
  },
  unsolicitedTitle: {
    i18nKey: 'unsolicitedTitle',
    defaults: 'Warning: Beware of Phishing Scams using {{company}}\' name.'
  },
  bewareUnsolicitedEmailsTitle: {
    i18nKey: 'bewareUnsolicitedEmailsTitle',
    defaults: 'Beware of Phishing Scams using {{company}}\' name'
  },
  unsolicitedText1: {
    i18nKey: 'unsolicitedText1',
    defaults: 'As our company continues to grow, we’ve seen an increase in the number of phishing emails by scammers pretending to be {{company}} employees. Identity theft is a crime we take very seriously, and we would like to ask you to help us keep your account and information secure by following these simple precautions:'
  },
  unsolicitedListItem1: {
    i18nKey: 'unsolicitedListItem1',
    defaults: 'Always confirm the sender’s identity before replying to any email requests and before opening attachments or clicking on links, even if they appear to come from a legitimate source',
  },
  unsolicitedListItem2: {
    i18nKey: 'unsolicitedListItem2',
    defaults: 'All our communications will be either from a company email address that ends in “@{{emailDomain}}” or via our in-app and website chat',
  },
  unsolicitedListItem3: {
    i18nKey: 'unsolicitedListItem3',
    defaults: 'Always be aware that no {{company}} employee will ever ask you for your personal passwords, or to withdraw money to any third party accounts, or install any software to your computer',
  },
  unsolicitedListItem4: {
    i18nKey: 'unsolicitedListItem4',
    defaults: 'Anyone who gets in touch with you on your personal social media pretending to be a {{company}} support agent or third party operator is a scammer and must be reported immediately',
  },
  unsolicitedListItem5: {
    i18nKey: 'unsolicitedListItem5 ',
    defaults: 'If you have any doubts whatsoever about an email or communication you’ve received, please contact us directly.'
  },
  showMoreItems: {
    i18nKey: 'showMoreItems',
    defaults: 'Show more items',
  },
  settingsPortfolioManagementTitle: {
    i18nKey: 'settingsPortfolioManagementTitle',
    defaults: 'Portfolio Management',
  },
  settingsPortfolioManagementCaption: {
    i18nKey: 'settingsPortfolioManagementCaption',
    defaults: 'For Portfolio Management accounts only',
  },
  suitabilityAssessment: {
    i18nKey: 'suitabilityAssessment',
    defaults: 'Suitability Assessment'
  },
  portfolioManagementDesc1: {
    i18nKey: 'portfolioManagementDesc1',
    defaults: 'The  suitability  assessment  is  carried  out  for  all  potential  clients  to  which  the  Company  may  provide portfolio management services.  The purpose of the suitability assessment is to enable the CIF to act in the best interest of its clients by obtaining the required information to assess whether a specific product is suitable for the client or not.  The questionnaire examines three criteria:  client’s  knowledge  and  experience  in  the  investment  sector  specially  relating  to  the  particular category of financial instrument or service, client’s financial situation  and  client’s  investment objectives.  The potential client needs to achieve a certain score in all three criteria in order to be considered as a suitable portfolio management client.'
  },
  suitabilityTestSubmitted: {
    i18nKey: 'suitabilityTestSubmitted',
    defaults: 'Suitability Test submitted'
  },
  suitabilityTestMessageLowMediumCysec: {
    i18nKey: 'suitabilityTestMessageLowMediumCysec',
    defaults: `{{company}} (the "Company") would like to bring to your attention that, on the basis of the information provided to us by you, your risk appetite is classified as {{scoreResult}} and as derived from the Law you can invest in a <0>{{scoreResultStrategy}}</0>. Should you wish to invest with the Company in higher-risk strategies, even though the Company is warning you that these strategies may not be appropriate for you, you could still choose to proceed with your investment choice at your own risk.`,
  },
  suitabilityTestMessageHighCysec: {
    i18nKey: 'suitabilityTestMessageHighCysec',
    defaults: `{{company}} (the "Company") would like to bring to your attention that, on the basis of the information provided to us by you, your risk appetite is classified as {{scoreResult}} and as derived from the Law you are able to invest in a <0>{{scoreResultStrategy}}</0> and in any other strategies available.`,
  },
  suitabilityTestMessageLowMediumFsc: {
    i18nKey: 'suitabilityTestMessageLowMediumFsc',
    defaults: `{{company}} (the "Company")  would like to bring to your attention that, on the basis of the information provided to us by you, your risk appetite is classified as {{scoreResult}} and you can invest in a <0>{{scoreResultStrategy}}</0>. Should you wish to invest with the Company in higher-risk strategies, even though the Company is warning you that these strategies may not be appropriate for you, you could still choose to proceed with your investment choice at your own risk.`,
  },
  suitabilityTestMessageHighFsc: {
    i18nKey: 'suitabilityTestMessageHighFsc',
    defaults: `{{company}} (the "Company") would like to bring to your attention that, on the basis of the information provided to us by you, your risk appetite is classified as {{scoreResult}} and you are able to invest in a <0>{{scoreResultStrategy}}</0> and in any other strategies available.`,
  },
  low: {
    i18nKey: 'low',
    defaults: 'Low',
  },
  medium: {
    i18nKey: 'medium',
    defaults: 'Medium',
  },
  lowRiskStrategy: {
    i18nKey: 'lowRiskStrategy',
    defaults: 'low-risk strategy',
  },
  mediumRiskStrategy: {
    i18nKey: 'mediumRiskStrategy',
    defaults: 'medium-risk strategy',
  },
  highRiskStrategy: {
    i18nKey: 'highRiskStrategy',
    defaults: 'high-risk strategy',
  },
  suitabilityTestAlreadySubmitted: {
    i18nKey: 'suitabilityTestAlreadySubmitted',
    defaults: 'You have already submitted your suitability assessment.',
  },
  downloadPdf: {
    i18nKey: 'downloadPdf',
    defaults: 'Download PDF'
  },
  footerCySEC: {
    i18nKey: 'footerCySEC',
    defaults: 'BDSwiss Holding Ltd is licenced and regulated by the Cyprus Securities and Exchange Commission, Licence No 199/13 Address: Apostolou Andrea Street 11, Hyper Tower, 5th Floor, 4007 Mesa Yeitonia, Limassol, Cyprus Registered Address: Ioanni Stylianou 6, 2nd floor, Office 202, 2003 Nicosia, Cyprus. Tel: +357 25 053 940 | Fax: +357 25 260 262 | Email: info@{{emailDomain}}'
  },
  footerFSA: {
    i18nKey: 'footerFSA',
    defaults: 'BDS Ltd (Registration No. 8424660-1) is authorized and regulated by the Financial Services Authority (the “FSA”, licence no. SD047) Registered address: Suite 3, Global Village, Jivan’s Complex, Mont Fleuri, Mahe, Seychelles',
  },
  footerFSC: {
    i18nKey: 'footerFSC',
    defaults: 'BDS Markets (Registration No:143350) Address: Amicorp, 6th Floor, Tower 1, Nexteracom Building 72201 Ebene, Mauritius',
  },
  growingCommunity: {
    i18nKey: 'growingCommunity',
    defaults: 'A growing community',
  },
  accountNumber: {
    i18nKey: 'accountNumber',
    defaults: 'Account Number'
  },
  paymentProvider: {
    i18nKey: 'paymentProvider',
    defaults: 'Payment Provider'
  },
  voucher: {
    i18nKey: 'voucher',
    defaults: 'Voucher'
  },
  maximumAccounts: {
    i18nKey: 'maximumAccounts',
    defaults: 'Maximum Trading Account limit reached. If you wish to register an additional account or manage existing accounts, please contact our customer support team.',
  },
  accountSettings: {
    i18nKey: 'accountSettings',
    defaults: 'Account Settings'
  },
  copyTradingSettings: {
    i18nKey: 'copyTradingSettings',
    defaults: 'Copy Trading Settings'
  },
  provideCopyTrading: {
    i18nKey: 'provideCopyTrading',
    defaults: 'Provide Copy Trading'
  },
  changeFeePopupText1: {
    i18nKey: 'changeFeePopupText1',
    defaults: 'The current performance fee of this account is <0>{{fee}}%</0>.'
  },
  changeFeePopupText2: {
    i18nKey: 'changeFeePopupText2',
    defaults: 'Are you sure you want to change it to <0>{{feeChange}}%</0>?'
  },
  copyTradingWithdrawal: {
    i18nKey: 'copyTradingWithdrawal',
    defaults: 'Withdrawals are limited while your account is copying <0>{{ibAccount}}</0>. <1>Stop Copying</1>'
  },
  copyTradingWithdrawalP1: {
    i18nKey: 'copyTradingWithdrawalP1',
    defaults: 'As your account is copying a strategy which is charging a Performance Fee, withdrawals have been restricted.'
  },
  copyTradingWithdrawalP2: {
    i18nKey: 'copyTradingWithdrawalP2',
    defaults: 'In order to proceed with your withdrawal, you will need to stop copying for the specified account.'
  },
  copyTradingWithdrawalP3: {
    i18nKey: 'copyTradingWithdrawalP3',
    defaults: 'Once you have stopped copying:'
  },
  copyTradingWithdrawalP3T1: {
    i18nKey: 'copyTradingWithdrawalP3T1',
    defaults: 'All open positions are closed.'
  },
  copyTradingWithdrawalP3T2: {
    i18nKey: 'copyTradingWithdrawalP3T2',
    defaults: 'Any due performance fee’s are calculated and deducted.'
  },
  copyTradingWithdrawalP3T3: {
    i18nKey: 'copyTradingWithdrawalP3T3',
    defaults: 'Your account is returned to full access and withdrawals are enabled.'
  },
  accountName: {
    i18nKey: 'accountName',
    defaults: 'Account Name'
  },
  update: {
    i18nKey: 'update',
    defaults: 'Update'
  },
  providing: {
    i18nKey: 'providing',
    defaults: 'Providing'
  },
  stopProviding: {
    i18nKey: 'stopProviding',
    defaults: 'Stop Providing'
  },
  stopProvidingTitle: {
    i18nKey: 'stopProvidingTitle',
    defaults: 'You are about to <0> stop providing </0> Copy Trading'
  },
  stopProvidingText1: {
    i18nKey: 'stopProvidingText1',
    defaults: 'Once you choose to stop, your strategy will no longer be visible to your followers.'
  },
  stopProvidingText2: {
    i18nKey: 'stopProvidingText2',
    defaults: 'All of your existing followers will be notified, their copied positions will be closed, and their following on the given account will be discontinued.'
  },
  activeSince: {
    i18nKey: 'activeSince',
    defaults: 'Active since'
  },
  leverageNotice: {
    i18nKey: 'leverageNotice',
    defaults: 'Leverage Notice'
  },
  stopProvidingIsRestricted: {
    i18nKey: 'stopProvidingIsRestricted',
    defaults: 'Stop Providing is restricted'
  },
  activeFollowersText: {
    i18nKey: 'activeFollowersText',
    defaults: 'As you have active followers, your account is resrtricted from disabling providing. '
  },
  discontinueText: {
    i18nKey: 'discontinueText',
    defaults: 'If you wish to discontinue providing copy trading, all open and/or pending positions need to be closed.'
  },
  validationAccountNameError: {
    i18nKey: 'validationAccountNameError',
    defaults: 'You may only use latin characters, numbers, spaces or the following symbols: - . , _ / ! #.',
  },
  webinarRegistration: {
    i18nKey: 'webinarRegistration',
    defaults: 'Thank you for registering!'
  },
  webinarRegistrationText: {
    i18nKey: 'webinarRegistrationText',
    defaults: 'Please use this link to join the webinar: <0> {{joinUrl}} </0>'
  },
  webinarErrorText: {
    i18nKey: 'webinarErrorText',
    defaults: 'Webinar Registration Error'
  },
  footNote: {
    i18nKey: 'footNote',
    defaults: '*Please note that there are limited payment options available under this regulator.',
  },
  validateNumber: {
    i18nKey: 'validateNumber',
    defaults: 'Must be numeric',
  },
  validateBrazilianPhone: {
    i18nKey: 'validateBrazilianPhone',
    defaults: 'Must be numeric and start with 55',
  },
  referAndEarn: {
    i18nKey: 'referAndEarn',
    defaults: 'Refer A Earn',
  },
  howDoesWork: {
    i18nKey: 'howDoesWork',
    defaults: 'How does it work?',
  },
  startRefer: {
    i18nKey: 'startRefer',
    defaults: 'Start referring your friends and contacts to {{company}} and earn {{amountReferrer}} for each referral that meets the trading account requirements. Your referred friend also benefits from a {{amountReferral}} welcome reward.',
  },
  referAndEarnText1: {
    i18nKey: 'referAndEarnText1',
    defaults: 'Click “Copy Link” to copy your partner link',
  },
  referAndEarnText2: {
    i18nKey: 'referAndEarnText2',
    defaults: 'Share your link with your friends or contacts',
  },
  referAndEarnText3: {
    i18nKey: 'referAndEarnText3',
    defaults: 'Your friend must register through your link, deposit at least {{minDeposit}} and verify their account',
  },
  referAndEarnText4: {
    i18nKey: 'referAndEarnText4',
    defaults: 'Your referral must then place at least {{minTrades}} trades within a period of {{days}} days after registration',
  },
  referAndEarnText5: {
    i18nKey: 'referAndEarnText5',
    defaults: 'You will then be able to claim your {{amountReferrer}} reward and transfer it to your trading account',
  },
  yourPartnerLink: {
    i18nKey: 'yourPartnerLink',
    defaults: 'Your Partner Link',
  },
  copyLink: {
    i18nKey: 'copyLink',
    defaults: 'Copy Link',
  },
  yourStatus: {
    i18nKey: 'yourStatus',
    defaults: 'Your Status',
  },
  yourReward: {
    i18nKey: 'yourReward',
    defaults: 'Your Reward',
  },
  referralsReward: {
    i18nKey: 'referralsReward',
    defaults: 'Referrals Reward',
  },
  yourReferrals: {
    i18nKey: 'yourReferals',
    defaults: 'Your Referrals',
  },
  reward: {
    i18nKey: 'reward',
    defaults: 'Reward',
  },
  referrals: {
    i18nKey: 'referrals',
    defaults: 'Referrals',
  },
  activated: {
    i18nKey: 'activated',
    defaults: 'Activated',
  },
  registrationDate: {
    i18nKey: 'registrationDate',
    defaults: 'Registration Date',
  },
  claimReward: {
    i18nKey: 'claimReward',
    defaults: 'Claim Reward',
  },
  partnerMessage: {
    i18nKey: 'partnerMessage',
    defaults: 'Use your partner link to refer your friends and contacts to {{company}} for a chance to earn {{amount}} for each successful referral. Remember that your friends must register through your link in order to appear under “Your Referrals”.',
  },
  yourStatusMessage: {
    i18nKey: 'yourStatusMessage',
    defaults: 'As an Introducer, you get to claim {{amount}} every time you successfully refer someone to {{company}} using your Partner Link. To claim your reward your friend must register, verify their account, deposit at least {{minDeposit}} and place {{minTrades}} trades within {{days}} days.',
  },
  claimMessage: {
    i18nKey: 'claimMessage',
    defaults: 'You have {{amount}} of unclaimed Referral Rewards. Please select the account to which you would like to have your referral reward transferred to. If the selected account is in a different currency, then your reward will be automatically converted to your account’s default currency.',
  },
  claim: {
    i18nKey: 'claim',
    defaults: 'Claim',
  },
  copyShareReward: {
    i18nKey: 'copyShareReward',
    defaults: 'Please copy & share <0>your link</0> to start referring and receiving rewards.',
  },
  referralsLimit: {
    i18nKey: 'referralsLimit',
    defaults: 'Referrals limit reached!',
  },
  wishRefer: {
    i18nKey: 'wishRefer',
    defaults: 'If you wish to continue referring clients, please contact our partnership team.',
  },
  becomeAPartner: {
    i18nKey: 'becomeAPartner',
    defaults: 'Become a Partner',
  },
  referralLimitReached: {
    i18nKey: 'referralLimitReached',
    defaults: 'It appears you\'ve reached your maximum number of referrals.',
  },
  viewMyPerformance: {
    i18nKey: 'viewMyPerformance',
    defaults: 'View my performance',
  },
  trades: {
    i18nKey: 'trades',
    defaults: 'Trades',
  },
  congratulationsReferred: {
    i18nKey: 'congratulationsReferred',
    defaults: 'Congratulations! <0 />You have been referred.',
  },
  congratulationsSubtitle: {
    i18nKey: 'congratulationsSubtitle',
    defaults: 'In order to receive your {{amount}} welcome reward, please complete a few steps within {{days}} days of your registration.',
  },
  congratulationsText1: {
    i18nKey: 'congratulationsText1',
    defaults: 'Register and create your account.',
  },
  congratulationsText2: {
    i18nKey: 'congratulationsText2',
    defaults: 'Verify and Activate your client account.',
  },
  congratulationsText3: {
    i18nKey: 'congratulationsText3',
    defaults: 'Deposit and fund your trading account with a minimum of {{deposit}} Euro (or equivalent in other currencies).',
  },
  congratulationsText4: {
    i18nKey: 'congratulationsText4',
    defaults: 'Execute at least {{trades}} or more trades.',
  },
  register: {
    i18nKey: 'register',
    defaults: 'Register',
  },
  introducer: {
    i18nKey: 'introducer',
    defaults: 'Introducer',
  },
  clientDeclined: {
    i18nKey: 'clientDeclined',
    defaults: 'Client Declined',
  },
  activationOverdue: {
    i18nKey: 'activationOverdue',
    defaults: 'Activation Overdue',
  },
  unclaimedRewards: {
    i18nKey: 'unclaimedRewards',
    defaults: 'Unclaimed Rewards',
  },
  referralActivatedNotification:{
    i18nKey: 'referralActivatedNotification',
    defaults: 'Congratulations! You have received your {{amount}} referral reward! ',
  },
  referralAlreadyPartner:{
    i18nKey: 'referralAlreadyPartner',
    defaults: 'Congratulations! As a Partner you can now benefit from our customised commission plans.',
  },
  referralAlreadyPartnerTitle:{
    i18nKey: 'referralAlreadyPartnerTitle',
    defaults: 'You are a Partner!',
  },
  referralLinkUnavailableTitle:{
    i18nKey: 'referralLinkUnavailableTitle',
    defaults: 'Refferal link is unavailable',
  },
  referralLinkUnavailable:{
    i18nKey: 'referralLinkUnavailable',
    defaults: 'Your referral link will be available once you have verified your account.',
  },
  partnerLinkCopied:{
    i18nKey: 'partnerLinkCopied',
    defaults: 'Link copied to clipboard',
  },
  requestStopCopyTradingFeeTitle: {
    i18nKey: 'requestStopCopyTradingFeeTitle',
    defaults: 'Once you stop copying <0>{{strategy}}</0> which is charging a performance fee, your account will no longer open positions automatically.',
  },
  requestStopCopyTradingFeeText: {
    i18nKey: 'requestStopCopyTradingFeeText',
    defaults: 'All open and/or pending positions will be closed.',
  },
  requestStopCopyTradingFeeText2: {
    i18nKey: 'requestStopCopyTradingFeeText2',
    defaults: 'Any due performance fee will be calculated and deducted.',
  },
  requestStopCopyTradingFeeText3: {
    i18nKey: 'requestStopCopyTradingFeeText3',
    defaults: 'Copying of <0>{{strategy}}</0> will be disabled and your account will be returned to full access.',
  },
  requestStopCopyTradingFeeWeekend: {
    i18nKey: 'requestStopCopyTradingFeeWeekend',
    defaults: 'In the case that some markets are closed, positions will close automatically once the markets open.',
  },
  copyTradingWithdrawalPending:{
    i18nKey: 'copyTradingWithdrawalPending',
    defaults: 'Withdrawals are limited while performance fee’s for provider <0>{{ibAccount}}</0>, are calculated. Please wait until required calculations and deductions are done.',
  },
  performanceFeeDetails:{
    i18nKey: 'performanceFeeDetails',
    defaults: 'Performance Fee charge of strategy: {{providerName}}.',
  },
  documentProcessed: {
    i18nKey: 'documentProcessed',
    defaults: 'Your document will be processed within the next 24-48 hours',
  },
  bonus: {
    i18nKey: 'bonus',
    defaults: 'Bonus',
  },
  requiredLots: {
    i18nKey: 'requiredLots',
    defaults: 'Required Lots',
  },
  tradedLots: {
    i18nKey: 'tradedLots',
    defaults: 'Traded Lots',
  },
  bonusCopyTradingNotificationTitle: {
    i18nKey: 'bonusCopyTradingNotificationTitle',
    defaults: 'You cannot follow strategies on this account',
  },
  bonusCopyTradingNotification: {
    i18nKey: 'bonusCopyTradingNotification',
    defaults: 'As your account has an active bonus, you are not able to follow a strategy until your bonus is converted or removed. You can always use a different account to follow any of the available strategies.',
  },
  createNewAccount: {
    i18nKey: 'createNewAccount',
    defaults: 'Create New Account',
  },
  receiveBonus: {
    i18nKey: 'receiveBonus',
    defaults: 'Receive a First Time Bonus *',
  },
  receiveBonusNote: {
    i18nKey: 'receiveBonusNote',
    defaults: '* The available bonus amount is {{percentage}} of your first deposit, and could not exceed a total of {{maxAmount}}',
  },
  receiveAmountBonus: {
    i18nKey: 'receiveAmountBonus',
    defaults: 'You will receive a <0>{{amount}}</0> bonus with your deposit',
  },
  welcomeBonus: {
    i18nKey: 'welcomeBonus',
    defaults: 'Make your 1st deposit to receive your welcome bonus',
  },
  welcomeBonusDays: {
    i18nKey: 'welcomeBonusDays',
    defaults: '{{days}} days left to receive a welcome bonus on your 1st deposit',
  },
  bonusCompetitionError: {
    i18nKey: 'bonusCompetitionError',
    defaults: 'Accounts with Bonuses cannot participate in competitions. <0 /> Please select a different participating account',
  },
  bonusTerms: {
    i18nKey: 'bonusTerms',
    defaults: '* By opting in to receive the first-time Bonus, I have read and agree to <0>Terms and Conditions</0>.',
  },
  showGBFooter:{
    i18nKey: 'showGBFooter',
    defaults: 'Deemed authorised and regulated by the Financial Conduct Authority. The nature and extent of consumer protections may differ from those for firms based in the UK. Details of the Financial Services Contracts Regime, which allows EEA-based firms to operate in the UK for a limited period to carry on activities which are necessary for the performance of pre-existing contracts, are available on the Financial Conduct Authority’s website.',
  },
  emailExistsClosed: {
    i18nKey: 'emailExistsClosed',
    defaults: 'Email already exists. Contact <0>support</0> to re-activate your account',
  },
  somethingWrongRefreshText:{
    i18nKey: 'somethingWrongRefreshText',
    defaults: 'If you have viewed this page after multiple attempts, we may be experiencing some technical issues. We will be working hard to try and resolve the issues as soon as possible. If you wish to continue trading you may download the mobile MetaTrader platforms (MT4 for IOS | MT4 for Android). Thank you for your understanding.',
  },
  showGBFooter2:{
    i18nKey: 'showGBFooter2',
    defaults: '<0>TP Firm CASS Disclosure:</0> Please refer to our Terms and Conditions and ICF Policy regarding the treatment of your funds in the event of Company’s failure (applicable for UK clients).',
  },
  dormantMessage: {
    i18nKey: 'dormantMessage',
    defaults: 'Your account requires updated details. Please proceed with the following actions: {{actions}}.',
  },
  missingep: {
    i18nKey: 'missingep',
    defaults: '<0>resubmit</0> Economic Profile questions or contact our <1>Support Team</1>',
  },
  riskWarningBds: {
    i18nKey: 'riskWarningBds',
    defaults: 'CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage. {{percentage}} % of retail investor accounts lose money when trading CFDs with this provider. You should consider whether you understand how CFDs work and whether you can afford to take the high risk of losing your money. <3 />BDSTrading is registered trademark of BDSwiss Holding Ltd. BDSwiss Holding Ltd is authorized and regulated by the Cyprus Securities and Exchange Commission (the <2>“CySEC“</2>, license no. 199/13 and registration no. HE 300153). The address of BDSwiss Holding Ltd is Apostolou Andrea Street 11, Hyper Tower, 5th Floor, 4007 Mesa Yeitonia, Limassol, Cyprus. Please review the company policies regarding the regulation <0>here</0>. <5 /><8>*BDSTrading is the brand used by the Company for Swiss residents exclusively.</8>',
  },
  riskWarningBdsMau: {
    i18nKey: 'riskWarningBdsMau',
    defaults: 'Trading in Forex/ CFDs and Other Derivatives is highly speculative and carries a high level of risk. It is possible to lose all your capital. These products may not be suitable for everyone and you should ensure that you understand the risks involved. Seek independent advice if necessary. Speculate only with funds that you can afford to lose. <3 />BDS Markets is an investment firm, incorporated in the Parliamentary Republic of Mauritius (Company Number: 143350) and is authorised and regulated by the Financial Services Commission of Mauritius (<2>FSC</2>) under License Number: C116016172. Registered address: BDS Markets, 6th Floor, Tower 1, Nexteracom Building 72201 Ebene – Mauritius. Please review the Company policies regarding the regulation <0>here</0>. <5 /><8>*BDSTrading is the brand used by the Company for Swiss residents exclusively.</8> <6 /><7>bdstrading.com</7> is operated by BDS Swiss Markets Global Services Ltd which is the primary payment provider and website operator. BDS Swiss Markets Global Services Ltd is acting on behalf of its related company, BDS Markets.',
  },
  riskWarningBdsVa: {
    i18nKey: 'riskWarningBdsVa',
    defaults: 'Trading in Forex/ CFDs and Other Derivatives is highly speculative and carries a high level of risk. It is possible to lose all your capital. These products may not be suitable for everyone and you should ensure that you understand the risks involved. Seek independent advice if necessary. Speculate only with funds that you can afford to lose.<3 />Domain usage rights belong to BDS Ltd. BDS Ltd (Registration No. 8424660-1) is authorized and regulated by the Financial Services Authority (the <2>FSA</2>, licence no. SD047).Registered address: Suite 3, Global Village, Jivan’s Complex, Mont Fleuri, Mahe, Seychelles.<5 /><8>*BDSTrading is the brand used by the Company for Swiss residents exclusively.</8>',
  },
  bdsTradingRedirectionMessage: {
    i18nKey: 'bdsTradingRedirectionMessage',
    defaults: 'You will now be redirected to BDSTrading, a brand used by the Company for Swiss residents exclusively and is a registered trademark of {{company}}.',
  },
  messagePartnerAddAccount:{
    i18nKey: 'messagePartnerAddAccount',
    defaults: 'Please keep in mind that to get access to your Partner account you will need to upload some additional Verification documents.',
  },
  maybeLater: {
    i18nKey: 'maybeLater',
    defaults: 'Maybe Later'
  },
  additionalDocumentsNeeded: {
    i18nKey: 'additionalDocumentsNeeded',
    defaults: 'Additional Documents Needed'
  },
  pammForexDesc: {
    i18nKey: 'pammForexDesc',
    defaults: 'Automate your trading by investing in a specific strategy offered by a professional portfolio manager and benefit from proportional ROI at the end of the predetermined interval period.'
  },
  accountKycRequiredMsg: {
    i18nKey: 'accountKycRequiredMsg',
    defaults: 'The \'{{account}}\' is available only to verified clients. Please proceed with the verification of your account to be able to create a \'{{account}}\'.'
  },
  accountKycRequiredPendingMsg: {
    i18nKey: 'accountKycRequiredPendingMsg',
    defaults: 'The \'{{account}}\' is available only to verified clients. The verification process may take up to 48hours. Please check your email or dashboard for updates.'
  },
  capsLockON: {
    i18nKey: 'capsLockON',
    defaults: 'Caps Lock is On',
  },
  directa24Message: {
    i18nKey: 'directa24Message',
    defaults: 'If you want to deposit using Safari, you need to disable the following options in the Safari Settings:',
  },
  directa24MessageItem1: {
    i18nKey: 'directa24MessageItem1',
    defaults: 'Block All cookies',
  },
  directa24MessageItem2: {
    i18nKey: 'directa24MessageItem2',
    defaults: 'Prevent Cross-site cookies',
  },
  directa24MessageEnd: {
    i18nKey: 'directa24MessageEnd',
    defaults: 'You can also change the browser to Chrome, Firefox, or any other. Contact support if you need help',
  },
  loginVerification: {
    i18nKey: 'loginVerification',
    defaults: 'Login Verification',
  },
  loginVerificationText:{
    i18nKey: 'loginVerificationText',
    defaults: 'We have noticed that you are logged in from a different country than usual. To be able to proceed, we need to verify your account. We have sent you an email with a {{loginVerificationDigits}}-digit code. Please enter it below. If you have not received the verification email, try checking your spam folder.'
  },
  loginVerificationText2:{
    i18nKey: 'loginVerificationText2',
    defaults: 'Please note that the code is valid for 5 minutes only.'
  },
  code:{
    i18nKey: 'code',
    defaults: 'Code'
  },
  expiredToken: {
    i18nKey: 'expiredToken',
    defaults: 'Token is Expired. You will be logged out.',
  },
  invalidToken: {
    i18nKey: 'invalidToken',
    defaults: 'Token is invalid',
  },
  invalidPid: {
    i18nKey: 'invalidPid',
    defaults: 'Pid is invalid',
  },
  enterCodeBelow:{
    i18nKey: 'enterCodeBelow',
    defaults: 'Enter code below:'
  },
  communicationLanguage: {
    i18nKey: 'communicationLanguage',
    defaults: 'Communication Language',
  },
  validationAddressError: {
    i18nKey: 'validationAddressError',
    defaults: 'Please use latin characters, numbers and spaces only.',
  },
  swapFreeIslamicAccount: {
    i18nKey: 'swapFreeIslamicAccount',
    defaults: 'Swap Free Account',
  },
  swapFreeIslamicAccountText: {
    i18nKey: 'swapFreeIslamicAccountText',
    defaults: 'Swap free trading in compliance with Sharia Law',
  },
  swapFreeIslamicAccountPopupText1: {
    i18nKey: 'swapFreeIslamicAccountPopupText1',
    defaults: 'Swap Free Account allows swap free trading in compliance with the Sharia Law, which means you can trade in a Swap-free account without overnight fees.',
  },
  swapFreeIslamicAccountPopupText2: {
    i18nKey: 'swapFreeIslamicAccountPopupText2',
    defaults: 'Once enabled, all new positions on your account will be Swap free.',
  },
  swapFreeIslamicAccountPopupText3: {
    i18nKey: 'swapFreeIslamicAccountPopupText3',
    defaults: 'Existing positions will not be affected.',
  },
  swapFreeIslamicAccountPopupNote: {
    i18nKey: 'swapFreeIslamicAccountPopupNote',
    defaults: 'Note that this option is available for Classic and VIP accounts only and <0>is not available for Raw accounts</0>.',
  },
  swapFreeIslamicAccountPopupNoteAsia1: {
    i18nKey: 'swapFreeIslamicAccountPopupNoteAsia1',
    defaults: 'Note that the Swap-Free option is available for 90 days to all account types.',
  },
  termsApply: {
    i18nKey: 'termsApply',
    defaults: '<0>Terms and Conditions</0> apply.',
  },
  swapFreeAvailable: {
    i18nKey: 'swapFreeAvailable',
    defaults: 'The swap-free accounts are available only to verified clients.',
  },
  swapFreeIslamicAccountPopupText4: {
    i18nKey: 'swapFreeIslamicAccountPopupText4',
    defaults: 'Please proceed with the verification of your account before enabling the swap-free option.',
  },
  enabled: {
    i18nKey: 'enabled',
    defaults: 'Enabled',
  },
  enable: {
    i18nKey: 'enable',
    defaults: 'Enable',
  },
  disabled: {
    i18nKey: 'disabled',
    defaults: 'Disabled',
  },
  accountsFailedSwapFree: {
    i18nKey: 'accountsFailedSwapFree',
    defaults: 'Some accounts failed to enable Swap Free: ',
  },
  swapFreeKycRequiredPendingMsg: {
    i18nKey: 'swapFreeKycRequiredPendingMsg',
    defaults: 'The verification process may take up to 48hours. Please check your email or dashboard for updates.'
  },
  scanQRCode: {
    i18nKey: 'scanQRCode',
    defaults: 'Scan this QR Code to pay instantly.'
  },
  proceedWithBrowser: {
    i18nKey: 'proceedWithBrowser',
    defaults: 'Proceed with <0>web browser</0>.'
  },
  providerStats:{
    i18nKey: 'providerStats',
    defaults: 'Provider Stats',
  },
  totalCopiers:{
    i18nKey: 'totalCopiers',
    defaults: 'Total Copiers',
  },
  totalprofitPerPeriod:{
    i18nKey: 'totalprofitPerPeriod',
    defaults: 'Total Copied PnL',
  },
  totalprofitPerPeriodClosed:{
    i18nKey: 'totalprofitPerPeriodClosed',
    defaults: 'Total Closed Copied PnL',
  },
  allTimePerformance:{
    i18nKey: 'allTimePerformance',
    defaults: 'All Time Performance',
  },
  activeCopiers:{
    i18nKey: 'activeCopiers',
    defaults: 'Active Copiers',
  },
  profitPerPeriod:{
    i18nKey: 'profitPerPeriod',
    defaults: 'Copied P/L',
  },
  downloadFollowerPerformance:{
    i18nKey: 'downloadFollowerPerformance',
    defaults: 'Download Follower Performance',
  },
  accountId:{
    i18nKey: 'accountId',
    defaults: 'Account Id',
  },
  performanceFeePaid:{
    i18nKey: 'performanceFeePaid',
    defaults: 'Paid Performance Fee',
  },
  noFollowersDataAvailable:{
    i18nKey: 'noFollowersDataAvailable',
    defaults: 'No followers data available for the current month.',
  },
  startCopyingDate:{
    i18nKey: 'startCopyingDate',
    defaults: 'Starting Date',
  },
  stopCopyingDate:{
    i18nKey: 'stopCopyingDate',
    defaults: 'End Date',
  },
  closedWinningTrades: {
    i18nKey: 'closedWinningTrades',
    defaults: 'Closed Winning Trades'
  },
  closedLosingTrades: {
    i18nKey: 'closedLosingTrades',
    defaults: 'Closed Losing Trades'
  },
  openCopiedTrades: {
    i18nKey: 'openCopiedTrades',
    defaults: 'Open Copied trades',
  },
  providerReportDateNotification:{
    i18nKey: 'providerReportDateNotification',
    defaults: 'Provider Stats report shows statistics based on copied trades performed after {{copyTradingAutomationStartDate}}.',
  },
  riskWarningBDSwissGroup:{
    i18nKey: 'riskWarningBDSwissGroup',
    defaults: 'This website is for general information purposes only and is operated by BDSwiss AG. BDSwiss AG is the BDSwiss Group’s Holding Company domiciled in Grienbachstrasse 11, 6300, Zug, Switzerland. BDSwiss AG does NOT offer any financial products or services. <4/>Swiss Markets and BDSwiss are registered trademarks of BDSwiss Holding Ltd. BDSwiss Holding Ltd is authorized and regulated by the Cyprus Securities and Exchange Commission (the “CySEC”, license no. 199/13 and registration no. HE 300153). The address of BDSwiss Holding Ltd is Apostolou Andrea Street 11, Hyper Tower, 5th Floor, 4007 Mesa Yeitonia, Limassol, Cyprus. Please review the company policies regarding the regulation.<3/>BDS Markets is an investment firm, incorporated in the Parliamentary Republic of Mauritius (Company Number: 143350) and is authorised and regulated by the Financial Services Commission of Mauritius (FSC) under License Number: C116016172. Registered address: BDS Markets, 6th Floor, Tower 1, Nexteracom Building 72201 Ebene – Mauritius. Please review the company policies regarding the regulation.<5/>BDS Ltd (Registration No. 8424660-1) is authorized and regulated by the Financial Services Authority (the “FSA”, licence no. SD047) Registered address: Suite 3, Global Village, Jivan’s Complex, Mont Fleuri, Mahe, Seychelles. Please review the company policies regarding the regulation.<6/>BDSwiss GmbH (Registration No. HRB 160749B) is the registered Tied Agent of BDSwiss Holding Ltd in Germany. Registered address at c/o Mindspace Germany GmbH, Friedrichstraße 68, 10117, Berlin, Germany.'
  },
  forbiddenCountryPopupSpecific:{
    i18nKey: 'forbiddenCountryPopupSpecific',
    defaults: 'Registrations from {{country}} are currently not allowed. We are actively working to resolve this problem quickly, you may try again in a later stage. Thank you for your understanding.',
  },
  temporaryCeasingPopupText:{
    i18nKey: 'temporaryCeasingPopupText',
    defaults: 'Currently, the Company is updating its regulatory requirements, therefore, is temporarily ceasing any operations in {{countries}}. We will inform you accordingly upon restarting our operations.',
  },
  walletAddress: {
    i18nKey: 'walletAddress',
    defaults: 'Wallet Address',
  },
  max: {
    i18nKey: 'max',
    defaults: 'Max',
  },
  cryptoWalletDepositInstructionsTitle: {
    i18nKey: 'cryptoWalletDepositInstructionsTitle',
    defaults: 'How to deposit your {{company}} {{productName}} account?',
  },
  cryptoWalletDepositInstructions: {
    i18nKey: 'cryptoWalletDepositInstructions',
    defaults: 'Please proceed with a transfer from your <0>{{accountCurrency}}</0> Wallet Address to the below Wallet Address:',
  },
  walletCopied:{
    i18nKey: 'walletAddressCopied',
    defaults: 'Wallet Address copied to clipboard',
  },
  cryptoWalletVerificationRequired: {
    i18nKey: 'cryptoWalletVerificationRequired',
    defaults: 'Please proceed with the verification of your account to be able to check your wallet details.',
  },
  productVerificationRequired: {
    i18nKey: 'productVerificationRequired',
    defaults: '{{productName}} is available only to verified clients. ',
  },
  productVerificationPending: {
    i18nKey: 'productVerificationPending',
    defaults: 'The verification process may take up to 48hours. Please check your email or dashboard for updates.',
  },
  corporateNote: {
    i18nKey: 'corporateNote',
    defaults: 'Please note that by choosing to onboard as a “Company”, you will have to pass the mandatory <0>corporate verification</0> process. If you don’t have a registered company, please choose the “Individual” option.',
  },
  regulationPopupMessage3: {
    i18nKey: 'regulationPopupMessage3',
    defaults: 'By clicking "Accept" you will continue with your registration under the entity regulated by {{regulator}} {{regulatorCountry}}.',
  },
  chooseEntity: {
    i18nKey: 'chooseEntity',
    defaults: 'Choose Entity',
  },
  one_core: {
    i18nKey: 'one_core',
    defaults: '1 vCPU',
  },
  two_core: {
    i18nKey: 'two_core',
    defaults: '2 vCPU',
  },
  four_core: {
    i18nKey: 'four_core',
    defaults: '4 vCPU',
  },
  disk_space_40: {
    i18nKey: 'disk_space_40',
    defaults: 'Storage 40GB HDD',
  },
  disk_space_50: {
    i18nKey: 'disk_space_50',
    defaults: 'Storage 50GB HDD',
  },
  disk_space_100: {
    i18nKey: 'disk_space_100',
    defaults: 'Storage 100GB HDD',
  },
  memory_1gb: {
    i18nKey: 'memory_1gb',
    defaults: 'RAM 1GB',
  },
  memory_2gb: {
    i18nKey: 'memory_2gb',
    defaults: 'RAM 2GB',
  },
  memory_4gb: {
    i18nKey: 'memory_4gb',
    defaults: 'RAM 4GB',
  },
  os_windows: {
    i18nKey: 'os_windows',
    defaults: 'Win Server 2012 R2',
  },
  ipAddress: {
    i18nKey: 'ipAddress',
    defaults: 'IP Address',
  },
  terminate:{
    i18nKey: 'terminate',
    defaults: 'Terminate',
  },
  pendingRulesCompetitions: {
    i18nKey: 'pendingRulesCompetitions',
    defaults: 'Participant did not meet all the conditions and therefore has not been ranked yet',
  },
  remainingTrades: {
    i18nKey: 'remainingTrades',
    defaults: '{{trades}} left',
  },
  howToParticipateCompetition:{
    i18nKey: 'howToParticipateCompetition',
    defaults: 'How to participate in Competition?',
  },
  howToCalculateROICompetition:{
    i18nKey: 'howToCalculateROICompetition',
    defaults: 'To calculate your ROI ratio, we take your overall profit from the trades, divide it by the total amount you traded, and then multiply by 100',
  },
  officialOnlineTradingPartner:{
    i18nKey: 'officialOnlineTradingPartner',
    defaults: 'Official Online Trading Partner',
  },
  openTradesAffected:{
    i18nKey: 'openTradesAffected',
    defaults: 'Note that all the open trades will be affected by this change!',
  },
  referredByPartner:{
    i18nKey: 'referredByPartner',
    defaults: 'Referred by a partner',
  },
  partnerReferenceNumber:{
    i18nKey: 'partnerReferenceNumber',
    defaults: 'Partner reference number',
  },
  bonusAcceptedTerms:{
    i18nKey: 'bonusAcceptedTerms',
    defaults: 'I have read and accepted the <0>First Deposit Bonus Terms & Conditions</0> and wish to receive a Bonus.',
  },
  spoaTitle:{
    i18nKey: 'spoaTitle',
    defaults: 'Special Power of Attorney Authorisation',
  },
  spoaNote:{
    i18nKey: 'spoaNote',
    defaults: 'By clicking the Grant Authorisation button, the Introducing Broker with the below-submitted ID will be able to perform a deposit on your behalf to your {{company}} account.',
  },
  spoaAuthorizationText2: {
    i18nKey: 'spoaAuthorizationText2',
    defaults: 'You are participating in our Bonus Campaign.'
  },
  eBookDialogTitle: {
    i18nKey: 'eBookDialogTitle',
    defaults: 'Download Free Forex eBooks',
  },
  eBookDialogDescription: {
    i18nKey: 'eBookDialogDescription',
    defaults: 'Whether you are just learning the basics, or are an experienced trader looking to refine your strategy, {{company}} ebook library can help you take your trading skills to the next level. Our ebooks cover a range of insightful topics from basic fundamental principles to advanced trading strategies.',
  },
  technicalIndicatorTitle: {
    i18nKey: 'technicalIndicatorTitle',
    defaults: 'Technical Indicators Defined and Explained',
  },
  technicalIndicatorSubtitle: {
    i18nKey: 'technicalIndicatorSubtitle',
    defaults: 'A guide to understanding and applying the most popular technical indicators.',
  },
  technicalIndicatorListItem1: {
    i18nKey: 'technicalIndicatorListItem1',
    defaults: 'RSI - Relative Strength Index',
  },
  technicalIndicatorListItem2: {
    i18nKey: 'technicalIndicatorListItem2',
    defaults: 'Average Directional Index',
  },
  technicalIndicatorListItem3: {
    i18nKey: 'technicalIndicatorListItem3',
    defaults: 'Parabolic SAR',
  },
  technicalIndicatorListItem4: {
    i18nKey: 'technicalIndicatorListItem4',
    defaults: 'Moving Average Convergence and Divergence (MACD)',
  },
  technicalIndicatorListItem5: {
    i18nKey: 'technicalIndicatorListItem5',
    defaults: 'Bollinger Bands <0/>',
  },
  technicalIndicatorListItem6: {
    i18nKey: 'technicalIndicatorListItem6',
    defaults: 'Linearly Weighted Moving Average',
  },
  technicalIndicatorListItem7: {
    i18nKey: 'technicalIndicatorListItem7',
    defaults: 'Exponential Moving Average',
  },
  technicalIndicatorListItem8: {
    i18nKey: 'technicalIndicatorListItem8',
    defaults: 'Simple Moving Average',
  },
  technicalIndicatorListItem9: {
    i18nKey: 'technicalIndicatorListItem9',
    defaults: 'Stochastic Oscillator',
  },
  ebook: {
    i18nKey: 'ebook',
    defaults: 'Forex Electronic Book',
  },
  ebookDescription: {
    i18nKey: 'ebookDescription',
    defaults: 'Download a guide to understanding and applying the most popular technical indicators.',
  },
  downloadEbook: {
    i18nKey: 'downloadEbook',
    defaults: 'Download full ebook',
  },
  languageDisclaimer:{
    i18nKey: 'languageDisclaimer',
    defaults: 'Language selection will define the <0>communication</0> language with {{company}} team. All notifications, SMS and emails will be sent in your selected language.',
  },
  bech32WalletAddress: {
    i18nKey: 'bech32WalletAddress',
    defaults: 'Please be informed that the wallet address you are about to copy is a Bech32 address, before proceeding with the payment you should check that your wallet provider supports payments to Bech32 addresses.'
  },
  bitnukWithdrawalFee: {
    i18nKey: 'bitnukWithdrawalFee',
    defaults: 'A withdrawal fee of {{withdrawalFee}} will be applied.',
  },
  submittedWireDetailsWarning: {
    i18nKey: 'submittedWireDetailsWarning',
    defaults: 'Please note that you need to add your client reference number to the transfer, otherwise the payment will be anonymous to us and we won\'t be able to add the funds to your account.'
  },
  important: {
    i18nKey: 'important',
    defaults: 'Important',
  },
  corporateCompany: {
    i18nKey: 'corporateCompany',
    defaults: 'Company Name',
  },
  signUpCompany: {
    i18nKey: 'signUpCompany',
    defaults: 'Get Started as a company',
  },
  legalRepresentative: {
    i18nKey: 'legalRepresentative',
    defaults: 'Legal Representative',
  },
  companyOnboarding: {
    i18nKey: 'companyOnboarding',
    defaults: 'Company onboarding',
  },
  submitAndProceed: {
    i18nKey: 'submitAndProceed',
    defaults: 'Submit and proceed',
  },
  addDirectorsInformation: {
    i18nKey: 'addDirectorsInformation',
    defaults: 'You need to add information about all the company directors and beneficial owners who have effective control over the company of more than 25%.',
  },
  addNewOwnerDirector: {
    i18nKey: 'addNewOwnerDirector',
    defaults: 'Add a new owner or director',
  },
  addNewOwnerDirectorNext: {
    i18nKey: 'addNewOwnerDirectorNext',
    defaults: 'Add a new owner or director on the next step',
  },
  addAnotherField: {
    i18nKey: 'addAnotherField',
    defaults: 'Add another {{field}}',
  },
  next: {
    i18nKey: 'next',
    defaults: 'Next',
  },
  clickOrDrag:{
    i18nKey: 'clickOrDrag',
    defaults: 'Click or drag files to upload',
  },
  reupload:{
    i18nKey: 'reupload',
    defaults: 'Reupload',
  },
  beneficialOwners:{
    i18nKey: 'beneficialOwners',
    defaults: 'Beneficial Owners',
  },
  companyDocuments:{
    i18nKey: 'companyDocuments',
    defaults: 'Company Documents',
  },
  addNewDocument: {
    i18nKey: 'addNewDocument',
    defaults: 'Add new document',
  },
  reviewPrintSign: {
    i18nKey: 'reviewPrintSign',
    defaults: 'Please review, print, sign and upload below documents:',
  },
  submitAndComplete: {
    i18nKey: 'submitAndComplete',
    defaults: 'Submit and complete',
  },
  download: {
    i18nKey: 'download',
    defaults: 'Download',
  },
  applicationSubmitted: {
    i18nKey: 'applicationSubmitted',
    defaults: 'Your application is submitted for review',
  },
  completingCorporateForm: {
    i18nKey: 'completingCorporateForm',
    defaults: 'Thank you for completing the form. {{company}} team will review your application and get back to you within the next several days.',
  },
  meantimeYouCan: {
    i18nKey: 'meantimeYouCan',
    defaults: 'In the meantime, you can:',
  },
  familiriazeYourself: {
    i18nKey: 'familiriazeYourself',
    defaults: 'Familiarize yourself with our Dashboard',
  },
  openDemoTrades: {
    i18nKey: 'openDemoTrades',
    defaults: 'Open demo trades',
  },
  reachOutSupport: {
    i18nKey: 'reachOutSupport',
    defaults: 'Reach out to Support should you have any questions',
  },
  goToDashboard: {
    i18nKey: 'goToDashboard',
    defaults: 'Go to Dashboard',
  },
  corporateProgressSaved: {
    i18nKey: 'corporateProgressSaved',
    defaults: 'Your progress in filling in the onboarding application is saved on every step. If you close the page and log in later, you will land on the last unfinished step.',
  },
  legalRepresentativeTooltip: {
    i18nKey: 'legalRepresentativeTooltip',
    defaults: 'A person who has been entrusted with a prominent public function.',
  },
  legalRepresentativeNote: {
    i18nKey: 'legalRepresentativeNote',
    defaults: 'A Legal Representative is a person who fills-in the application and trades on the company’s behalf.',
  },
  show: {
    i18nKey: 'show',
    defaults: 'Show',
  },
  ownersCompanyNote: {
    i18nKey: 'ownersCompanyNote',
    defaults: 'We will later request the identification documents of the Beneficial owners of this company.',
  },
  remove: {
    i18nKey: 'remove',
    defaults: 'Remove',
  },
  sureRemoveOwner: {
    i18nKey: 'sureRemoveOwner',
    defaults: 'Are you sure you want to remove <0>{{owner}}</0> from Beneficiary/Director list?',
  },
  otherDocuments: {
    i18nKey: 'otherDocuments',
    defaults: 'Other documents',
  },
  corporateAccountUnderReview: {
    i18nKey: 'corporateAccountUnderReview',
    defaults: 'Your Corporate Account application is under review and you will not be able to deposit until it’s approved.',
  },
  registrationServerError: {
    i18nKey: 'registrationServerError',
    defaults: 'Registration is currently unavailable as we are experiencing some technical issues. We are working hard to try and resolve the issues as soon as possible. Please try again in a while. Thank you for your understanding.',
  },
  supportNotification: {
    i18nKey: 'supportNotification',
    defaults: 'Make sure you are speaking to us by asking for your Security Code at the start of your call!',
  },
  supportAlertTitle: {
    i18nKey: 'supportAlertTitle',
    defaults: 'Use the {{company}} Security Code System to protect yourself from telephone scammers!'
  },
  supportAlertText1: {
    i18nKey: 'supportAlertText1',
    defaults: 'To protect you from telephone scammers, we have a Security Code System in place. Always ensure that you are speaking to us by requesting your Security Code at the start of all your calls with {{company}}.'
  },
  supportAlertText2: {
    i18nKey: 'supportAlertText2',
    defaults: '<0>How it works</0>'
  },
  supportAlertText3: {
    i18nKey: 'supportAlertText3',
    defaults: 'Upon your request, the agent handling your call will send you a unique Security Code via email, and once you confirm receipt, will then spell out the code to you.'
  },
  supportAlertText4: {
    i18nKey: 'supportAlertText4',
    defaults: 'The code you hear from the agent should match the code in the email. This way you will know that you are speaking to us, and not to a scammer pretending to be us.'
  },
  supportAlertText5: {
    i18nKey: 'supportAlertText5',
    defaults: 'We highly recommend and strongly advise that you always request your Security Code when calling {{company}}.'
  },
  licenseAcknowledgementCountries: {
    i18nKey: 'licenseAcknowledgementCountries',
    defaults: 'I acknowledge and understand that {{company}} is not regulated by any of the {{authority}} authorities and operates from its Licence N°{{license}} issued by the {{regulation}} ({{regulationLabel}}) {{regulationCountry}}'
  },
  tradeCompanion:{
    i18nKey: 'tradeCompanion',
    defaults: 'Trade Companion'
  },
  tradeCompanionDescription:{
    i18nKey: 'tradeCompanionDescription',
    defaults: 'Take full advantage of professional in-depth analysis of your trades powered by Tableau.'
  },
  tradeCompanionDisclaimer:{
    i18nKey: 'tradeCompanionDisclaimer',
    defaults: 'On this page you can access trading statistics per all of your accounts to get insights for your future deals.'
  },
  tradeCompanionError:{
    i18nKey: 'tradeCompanionError',
    defaults: 'Something went wrong fetching your trading records. Please try again later.'
  },
  tradeCompanionFooter:{
    i18nKey: 'tradeCompanionFooter',
    defaults: 'Any and all information on the Trade Companion tool is provided for informative purpose only. All content mentioned does not constitute professional advice and the past performance of any trading system or methodology is not necessarily indicative of future results. No guarantee is made that you will be able to replicate the same results.'
  },
  forbiddenCountryPopupSpecificStrict:{
    i18nKey: 'forbiddenCountryPopupSpecificStrict',
    defaults: 'Registrations from {{country}} are not allowed, as we do not provide financial services in your country of residence.',
  },
  consecutiveSpaces: {
    i18nKey: 'consecutiveSpaces',
    defaults: 'Consecutive spaces between words are not allowed.',
  },
  profileAndSettings: {
    i18nKey: 'profileAndSettings',
    defaults: 'Profile & Settings',
  },
  profileAndSettingsNew: {
    i18nKey: 'profileAndSettingsNew',
    defaults: 'Profile & Settings',
  },
  registrationRedirect: {
    i18nKey: 'registrationRedirect',
    defaults: 'Based on your selection of country you will now be redirected to {{company}}, to register for an account authorized and regulated by the {{regulator}}.',
  },
  importantInformation: {
    i18nKey: 'importantInformation',
    defaults: 'Important Information',
  },
  payretailersLocalBanksNotice: {
    i18nKey: 'payretailersLocalBanksNotice',
    defaults: 'When using this payment method, withdrawals from your trading account will be processed via local bank transfer.',
  },
  emailExistsLogin:{
    i18nKey: 'emailExistsLogin',
    defaults: 'Email already exists, please try to <0>login</0> instead.',
  },
  successfulMigrationTitle: {
    i18nKey: 'successfulMigrationTitle',
    defaults: 'Your account was successfully migrated under {{trademark}}',
  },
  successfulMigrationText1: {
    i18nKey: 'successfulMigrationText1',
    defaults: 'You now enjoy greater investor protections including participation in the Investor Compensation Fund. All your personal and trading account information along with all your account assets, transaction history, liabilities, and balances were also transferred to {{trademark}}. This means that you will be able to pick up exactly where you left off when it comes to your trading.',
  },
  successfulMigrationText2: {
    i18nKey: 'successfulMigrationText2',
    defaults: 'Our {{regulator}} regulator does enforce a lower leverage ratio (of up to {{leverage}}) as a means to protect retail traders from unwarranted losses. However, you may choose to apply for a Professional Client categorisation if you believe you qualify for higher leverage. <0>Learn More</0>',
  },
  successfulMigrationText3: {
    i18nKey: 'successfulMigrationText3',
    defaults: 'Our {{regulator}} regulator does enforce a lower leverage ratio (of up to {{leverage}}) as a means to protect retail traders from unwarranted losses. However, you may choose to apply for a Professional Client categorisation by sending a direct request to <2>{{supportEmail}}</2>.',
  },
  successfulMigrationText4: {
    i18nKey: 'successfulMigrationText4',
    defaults: 'Kindly note that your account server and MT4/5 Account ID were updated, and you can find your new credentials directly on your dashboard. If you have any trouble accessing your account or platforms, please contact us directly via <1>Live Chat</1>.',
  },
  invalidCompany:{
    i18nKey: 'invalidCompany',
    defaults: '{{entity}} does not provide services to {{country}}',
  },
  acceptedDocuments: {
    i18nKey: 'acceptedDocuments',
    defaults: 'Accepted Documents:',
  },
  acceptedDocumentsTextPor1: {
    i18nKey: 'acceptedDocumentsTextPor1',
    defaults: 'Electricity, water, gas or internet bill',
  },
  acceptedDocumentsTextPor2: {
    i18nKey: 'acceptedDocumentsTextPor2',
    defaults: 'Bank statement / credit card statement / bank reference letter',
  },
  acceptedDocumentsTextPor3: {
    i18nKey: 'acceptedDocumentsTextPor3',
    defaults: 'Tax bill / Council tax bill (for the current year)',
  },
  acceptedDocumentsTextPor4: {
    i18nKey: 'acceptedDocumentsTextPor4',
    defaults: 'Social insurance statement',
  },
  acceptedDocumentsTextPor5: {
    i18nKey: 'acceptedDocumentsTextPor5',
    defaults: 'Government-issued Certificate of Residence or notarised Affidavit of Residence',
  },
  licenseAcknowledgementCountriesText2: {
    i18nKey: 'licenseAcknowledgementCountriesText2',
    defaults: 'I acknowledge and understand that {{company}} is not regulated by any {{authority}} financial authorities and operates via its license N°{{license}}, issued by the {{regulation}} ({{regulationLabel}}) {{regulationCountry}}. By registering with {{company}}, I understand that I do not have the same level of investor protections offered in my country of residence'
  },
  faqs: {
    i18nKey: 'faqs',
    defaults: 'FAQ\'s',
  },
  frequentlyAskedQuestions: {
    i18nKey: 'frequentlyAskedQuestions',
    defaults: 'Frequently asked questions',
  },
  seeAllFaqs: {
    i18nKey: 'seeAllFaqs',
    defaults: 'see all FAQs',
  },
  wireTransferNotificationBankNotAvailable: {
    i18nKey: 'wireTransferNotificationBankNotAvailable',
    defaults: 'Please note that as of {{date}}, <0>wire transfers via {{bankName}} are no longer available</0>. All deposits sent via this payment method will be automatically rejected by the bank. Kindly proceed with deleting the {{bankName}} transfer details from your saved online banking methods. Future wire transfers will be processed via alternative banking providers.',
  },
  migrateEpTestMessage:{
    i18nKey: 'migrateEpTestMessage',
    defaults: 'Due to your account\'s migration to {{company}}, some additional information is needed to complete your Economic Profile. Please take a moment and fill in the missing information on the form.',
  },
  migrateApTestMessage: {
    i18nKey: 'migrateApTestMessage',
    defaults: 'Due to your account\'s migration to {{company}}, some additional information is needed to complete your Appropriateness Test. Please take a moment and fill in the missing information on the form.',
  },
  readAcceptTerms:{
    i18nKey: 'readAcceptTerms',
    defaults: 'I have read and accept the <0>General Terms & Conditions</0>, <1>Legal Documents </1>, <2>Personal Data Protection</2> of {{company}}',
  },
  migratePartnersTitle:{
    i18nKey: 'migratePartnersTitle',
    defaults: '{{entity}} Account Requirements',
  },
  migratePartners:{
    i18nKey: 'migratePartners',
    defaults: 'Following relevant regulatory requirements, all {{company}} Partners working with {{basedClients}} clients are now required to maintain a separate account under our {{entity}} regulated entity.',
  },
  euBased:{
    i18nKey: 'euBased',
    defaults: 'EU-based',
  },
  migratePartnersText2:{
    i18nKey: 'migratePartnersText2',
    defaults: 'You are accordingly requested to provide a new email address so that we can create a separate partner account for you under {{entity}} regulated entity. Please also read and accept the relevant Terms and Conditions before you “Submit” a new email address.',
  },
  submittedWireDetailsWarningAdditional: {
    i18nKey: 'submittedWireDetailsWarningAdditional',
    defaults: 'Please be advised that you need to add your Client ID to Field 72: \'Sender to Receiver Information\' of your bank transfer, otherwise the payment will be anonymous, and we may not be able to add the funds to your account. Please note that all information must be provided in English.'
  },
  submittedWireDetailsWarningAdditionalFormatted: {
    i18nKey: 'submittedWireDetailsWarningAdditionalFormatted',
    defaults: 'Please be advised that you need to add your Client ID to <0>Field 72: \'Sender to Receiver Information\'</0> of your bank transfer, otherwise the payment will be anonymous, and we may not be able to add the funds to your account. Please note that all information <1>must</1> be provided in English.'
  },
  submittedWireDetailsWarningAdditionalReference: {
    i18nKey: 'submittedWireDetailsWarningAdditionalReference',
    defaults: 'Please note that you need to copy the content of the Reference field and paste it to your bank transfer, otherwise your payment might be lost. Please make sure that any additional information must be provided in English.'
  },
  walletSnackbar:{
    i18nKey: 'walletSnackbar',
    defaults: 'Use your {{wallet}} to deposit, store and withdraw Bitcoin or fund your trading accounts!',
  },
  unlockWallet:{
    i18nKey: 'unlockWallet',
    defaults: 'Unlock your {{wallet}}!',
  },
  bitwalletBenefits:{
    i18nKey: 'bitwalletBenefits',
    defaults: 'To use all the benefits of the {{company}} Bitcoin wallet you first need to complete your account verification. You will then be able to:',
  },
  bitwalletBenefit1:{
    i18nKey: 'bitwalletBenefit1',
    defaults: 'Deposit Bitcoin to your {{company}} {{wallet}} from any external wallet',
  },
  bitwalletBenefit2:{
    i18nKey: 'bitwalletBenefit2',
    defaults: 'Fund your trading accounts with Bitcoin',
  },
  bitwalletBenefit3:{
    i18nKey: 'bitwalletBenefit3',
    defaults: 'Transfer funds from your accounts to {{wallet}}',
  },
  bitwalletBenefit4:{
    i18nKey: 'bitwalletBenefit4',
    defaults: 'Withdraw Bitcoin from {{wallet}} to any external wallet',
  },
  completeVerification:{
    i18nKey: 'completeVerification',
    defaults: 'Complete verification',
  },
  bankTransferWithdrawalWarningTitle:{
    i18nKey: 'bankTransferWithdrawalWarningTitle',
    defaults: 'Bank wire withdrawals are experiencing delays!',
  },
  bankTransferWithdrawalWarningText:{
    i18nKey: 'bankTransferWithdrawalWarningText',
    defaults: 'Please be informed that our banking providers seem to be currently experiencing some delays in their processing of bank wire withdrawals as they are required to run additional AML/KYC checks on individual clients. We apologise for the inconvenience. If you have any other alternative banking or payment option as a preselected payment method, then you may choose to submit your withdrawal request via a different option.',
  },
  usdtOption: {
    i18nKey: 'usdtOption',
    defaults: 'USDT Option',
  },
  bankTransfer: {
    i18nKey: 'bankTransfer',
    defaults: 'Bank transfer'
  },
  bankTransferSignText: {
    i18nKey: 'bankTransferSignText',
    defaults: 'Before you send us a transfer you need to digitally sign the Payment acknowledgment - it will take several minutes.'
  },
  bankTransferJMFinancialText: {
    i18nKey: 'bankTransferJMFinancialText',
    defaults: 'Prior to sending us a wire transfer, we kindly request that you digitally sign the Payment Acknowledgment Form. This will only take several minutes.'
  },
  bankTransferJMFinancialAcknowledgmentText: {
    i18nKey: 'bankTransferJMFinancialAcknowledgmentText',
    defaults: 'You have successfully signed the Payment Acknowledgment Form! You can now proceed with the wire transfer.'
  },
  signNow: {
    i18nKey: 'signNow',
    defaults: 'Sign now'
  },
  documentExecutionError: {
    i18nKey: 'documentExecutionError',
    defaults: 'Failed to sign document, please try again!'
  },
  noAddress: {
    i18nKey: 'noAddress',
    defaults: 'No address was provided. Please add your address to your profile first.',
  },
  joinVipClubTitle: {
    i18nKey: 'joinVipClubTitle',
    defaults: 'Join our VIP Club'
  },
  vipAccountPrivilagedText2: {
    i18nKey: 'vipAccountPrivilagedText2',
    defaults: 'Designed for individuals looking to trade larger volumes at even more competitive conditions, {{company}} premium VIP and Raw accounts come with a set of exclusive benefits including live trading alerts, lower spreads and commissions, dedicated support and premium trading tools.'
  },
  upgradeAccountAccess: {
    i18nKey: 'upgradeAccountAccess',
    defaults: 'Upgrade your account to access exclusive tools and benefits'
  },
  openAccountType: {
    i18nKey: 'openAccountType',
    defaults: 'Open {{type}}'
  },
  takeAdvantage: {
    i18nKey: 'takeAdvantage',
    defaults: 'Take advantage of our most exclusive trading conditions'
  },
  vipAccountAdvantage: {
    i18nKey: 'vipAccountAdvantage',
    defaults: 'Lower Costs or Raw Spreads'
  },
  vipAccountAdvantageText: {
    i18nKey: 'vipAccountAdvantageText',
    defaults: 'Lower your trading costs by accessing $0 commissions on indices and/or raw spreads on all majors from 0.0 pips.'
  },
  vipAccountAdvantage2: {
    i18nKey: 'vipAccountAdvantage2',
    defaults: 'Real-Time VIP Trading Alerts'
  },
  vipAccountAdvantage2Text: {
    i18nKey: 'vipAccountAdvantage2Text',
    defaults: 'Live Trading Alerts via Telegram with actionable insights and key market updates from industry experts.'
  },
  vipAccountAdvantage3: {
    i18nKey: 'vipAccountAdvantage3',
    defaults: 'Trade Companion Personal Analytics'
  },
  vipAccountAdvantage3Text: {
    i18nKey: 'vipAccountAdvantage3Text',
    defaults: 'Monitor, analyse and improve your trading performance with key personal analytics available directly on your Dashboard.'
  },
  depositToUpgradeAutochartist: {
    i18nKey: 'depositToUpgradeAutochartist',
    defaults: 'Deposit 500 USD to access Autochartist Trading Signals and News Sentiment Analysis'
  },
  downloadAutochartistPlugin: {
    i18nKey: 'downloadAutochartistPlugin',
    defaults: 'Download MT4 & MT5 compatible autochartist plugin'
  },
  vipAccountAdvantage4: {
    i18nKey: 'vipAccountAdvantage4',
    defaults: 'Premium Autochartist Tools and Plugins'
  },
  vipAccountAdvantage4Text: {
    i18nKey: 'vipAccountAdvantage4Text',
    defaults: 'Receive the highest performing setups on forming trends with confirmed support/resistance levels.'
  },
  vipAccountAdvantage5: {
    i18nKey: 'vipAccountAdvantage5',
    defaults: 'Priority of Transactions'
  },
  vipAccountAdvantage5Text: {
    i18nKey: 'vipAccountAdvantage5Text',
    defaults: 'Get immediate priority on all processing requests including KYC account verifications, deposits and withdrawals.'
  },
  vipAccountAdvantage6: {
    i18nKey: 'vipAccountAdvantage6',
    defaults: 'WebTrader Trends Analysis Tool'
  },
  vipAccountAdvantage6Text: {
    i18nKey: 'vipAccountAdvantage6Text',
    defaults: 'Open a VIP account to access over 500 upcoming price trends with possible breakout levels plotted live on your WebTrader chart.'
  },
  vipAccountAdvantage7: {
    i18nKey: 'vipAccountAdvantage7',
    defaults: 'Exclusive Webinars & Events '
  },
  vipAccountAdvantage7Text: {
    i18nKey: 'vipAccountAdvantage7Text',
    defaults: 'Attend premium webinars hosted by our leading analysts and receive exclusive invitations to VIP seminars and events.'
  },
  vipAccountAdvantage8: {
    i18nKey: 'vipAccountAdvantage8',
    defaults: 'Dedicated Account Manager'
  },
  vipAccountAdvantage8Text: {
    i18nKey: 'vipAccountAdvantage8Text',
    defaults: 'You will be assigned to your personal {{company}} Account Manager who will provide you with expert support every step of the way.'
  },
  strategySecuritySettings: {
    i18nKey: 'strategySecuritySettings',
    defaults: 'Strategy Security Settings',
  },
  passwordProtected: {
    i18nKey: 'passwordProtected',
    defaults: 'Password Protected',
  },
  passwordProtection: {
    i18nKey: 'passwordProtection',
    defaults: 'Password Protection',
  },
  passwordProtectionText: {
    i18nKey: 'passwordProtectionText',
    defaults: 'Once the strategy is password protected, it will remain visible to all followers. A \'Password protected\' indication will be shown on the chosen strategy, with an explanation of how to unlock and enter the set password.',
  },
  passwordProtectedStrategy: {
    i18nKey: 'passwordProtectedStrategy',
    defaults: 'The Strategy you would like to subscribe to is Password Protected, please enter the Password and start copying trades.',
  },
  enterPasswordProtectStrategy: {
    i18nKey: 'enterPasswordProtectStrategy',
    defaults: 'Please enter a password to be able to proceed',
  },
  topUpDemo: {
    i18nKey: 'topUpDemo',
    defaults: 'Top Up Demo Account Balance',
  },
  amountWillBeAdded: {
    i18nKey: 'amountWillBeAdded',
    defaults: 'Amount will be added to the account balance',
  },
  enterAmount: {
    i18nKey: 'enterAmount',
    defaults: 'Enter Amount',
  },
  topUpBalanceSuccess: {
    i18nKey: 'topUpBalanceSuccess',
    defaults: 'Your demo balance has been topped up with {{amount}}! Go to <0>Dashboard</0>'
  },
  topUpDemoMax: {
    i18nKey: 'topUpDemoMax',
    defaults: 'Demo account balance is maxed.'
  },
  topUpBalanceError: {
    i18nKey: 'topUpBalanceError',
    defaults: 'Demo account has a maximum balance of {{amount}}'
  },
  topUpNumbersError: {
    i18nKey: 'topUpNumbersError',
    defaults: 'Please enter whole numbers without any symbols'
  },
  totalAccountBalanceUpdated: {
    i18nKey: 'totalAccountBalanceUpdated',
    defaults: 'Total account balance will be updated to this amount',
  },
  forexMt4blackDemoDesc:{
    i18nKey: 'forexMt4blackDemoDesc',
    defaults:  'Practice trading with reduced spreads, competitive commissions, VIP Trading Alerts, webinars and Autochartist tools.'
  },
  forexMt4proDemoDesc:{
    i18nKey: 'forexMt4proDemoDesc',
    defaults:  'Practice trading with reduced spreads, competitive commissions, VIP Trading Alerts, webinars and Autochartist tools.'
  },
  forexMt4rawDemoDesc:{
    i18nKey: 'forexMt4rawDemoDesc',
    defaults:  'Practice trading with raw spreads from 0 pips on 40+ forex and gold pairs and gain VIP access to Trading Alerts, webinars and Autochartist.'
  },
  forexMt4primeDemoDesc:{
    i18nKey: 'forexMt4primeDemoDesc',
    defaults:  'Practice trading with raw spreads from 0 pips on 40+ forex and gold pairs and gain VIP access to Trading Alerts, webinars and Autochartist.'
  },
  selectTradingAccount: {
    i18nKey: 'selectTradingAccount',
    defaults: 'Select Trading Account to Open',
  },
  noteAccessMt5: {
    i18nKey: 'noteAccessMt5',
    defaults: 'Please note that in order to access MT5 accounts you will need to click on the respective account directly under the accounts section of the dashboard.',
  },
  mt5TTradeNoteTitle: {
    i18nKey: 'mt5TTradeNoteTitle',
    defaults: 'Download MT5 or Open a New Trading Account',
  },
  mt5TTradeNote: {
    i18nKey: 'mt5TTradeNote',
    defaults: 'For easy access to your trading accounts through our webtrader please <0>open an MT4 trading account</0>, otherwise follow the steps below to access your MT5 account.',
  },
  fundYourAccountText: {
    i18nKey: 'fundYourAccountText',
    defaults: '<1>Fund your account<1>',
  },
  downloadServerDesktopApplication: {
    i18nKey: 'downloadServerDesktopApplication',
    defaults: '<0>Download</0> the {{server}} Desktop Application',
  },
  openServerApplication: {
    i18nKey: 'openServerApplication',
    defaults: 'Open the {{server}} Application',
  },
  addAccountLoginAccount: {
    i18nKey: 'addAccountLoginAccount',
    defaults: 'Add your account by clicking to «Login to a trade account»',
  },
  enterAccountDetails: {
    i18nKey: 'enterAccountDetails',
    defaults: 'Enter your account details:',
  },
  passwordMT5Note: {
    i18nKey: 'passwordMT5Note',
    defaults: '*Your Password*',
  },
  loginMT5Note: {
    i18nKey: 'loginMT5Note',
    defaults: '{{server}} account ID (found under <0>dashboard accounts</0>)',
  },
  live: {
    i18nKey: 'live',
    defaults: 'Live',
  },
  moreInfo: {
    i18nKey: 'moreInfo',
    defaults: 'More Info',
  },
  registrationDisabledError: {
    i18nKey: 'registrationDisabledError',
    defaults: 'We regret to inform you that we temporarily cannot accept new clients until further notice. Thank you for your understanding.',
  },
  pleaseNoteColon:{
    i18nKey: 'pleaseNoteColon',
    defaults: 'Please Note:'
  },
  noWithdrawalPop:{
    i18nKey: 'noWithdrawalPop',
    defaults: 'You will not be able to withdraw funds without uploading valid Proof(s) of Payment in this section due to Anti Money Laundering (AML) regulation.'
  },
  documentsSubjectReview:{
    i18nKey: 'documentsSubjectReview',
    defaults: '* The documents are subject to review and approval.'
  },
  willDoItLater:{
    i18nKey: 'willDoItLater',
    defaults: 'I’ll do it later'
  },
  uploadTheDoc:{
    i18nKey: 'uploadTheDoc',
    defaults: 'Upload the {{docCategory}}'
  },
  pof:{
    i18nKey: 'pof',
    defaults: 'POF'
  },
  tradingPsychologyTitle: {
    i18nKey: 'tradingPsychologyTitle',
    defaults: 'Psychology of Trading',
  },
  tradingPsychologySubtitle: {
    i18nKey: 'tradingPsychologySubtitle',
    defaults: 'A guide to understanding the psychology that could impact trading experience. <0 />Learn how to:',
  },
  tradingPsychologyListItem1: {
    i18nKey: 'tradingPsychologyListItem1',
    defaults: 'Establish the proper trading mindset',
  },
  tradingPsychologyListItem2: {
    i18nKey: 'tradingPsychologyListItem2',
    defaults: 'Develop and follow appropriate trading strategies',
  },
  tradingPsychologyListItem3: {
    i18nKey: 'tradingPsychologyListItem3',
    defaults: 'Increase your risk-taking capacity',
  },
  tradingPsychologyListItem4: {
    i18nKey: 'tradingPsychologyListItem4',
    defaults: 'Safeguard your trades and manage your risk',
  },
  tradingPsychologyListItem5: {
    i18nKey: 'tradingPsychologyListItem5',
    defaults: 'Control fear, greed and other negative emotions',
  },
  tradingPsychologyListItem6: {
    i18nKey: 'tradingPsychologyListItem6',
    defaults: 'Avoid trading emotionally',
  },
  tradingPsychologyListItem7: {
    i18nKey: 'tradingPsychologyListItem7',
    defaults: 'Understand trading biases and how to overcome them',
  },
  tradingPsychologyListItem8: {
    i18nKey: 'tradingPsychologyListItem8',
    defaults: 'Take care of your health during trading',
  },
  ebookNew: {
    i18nKey: 'ebookNew',
    defaults: 'Forex eBooks',
  },
  ebookDescriptionNew: {
    i18nKey: 'ebookDescriptionNew',
    defaults: 'Our ebooks cover a range of insightful topics from basic fundamental principles to advanced trading strategies.',
  },
  depositVerification: {
    i18nKey: 'depositVerification',
    defaults: 'Deposit Verification',
  },
  officialSponsorOf:{
    i18nKey: 'officialSponsorOf',
    defaults: 'Official Sponsor Of',
  },
  centDesc: {
    i18nKey: 'centDesc',
    defaults: 'Start trading in cents from as low as $10 and reduce your exposure by 100 times. Buy or Sell over 70 forex and commodity pairs with up to 1:2000 leverage, access a range of free tools, and enjoy 24/5 multi-lingual support.'
  },
  centMt5centDesc: {
    i18nKey: 'centMt5centDesc',
    defaults: 'Start trading in cents from as low as $10 and reduce your exposure by 100 times. Buy or Sell over 70 forex and commodity pairs with up to 1:2000 leverage, access a range of free tools, and enjoy 24/5 multi-lingual support.'
  },
  receiveInCUD: {
    i18nKey: 'receiveInCUD',
    defaults: 'You will receive {{amount}} USD cents (CUD) on your balance'
  },
  withdrawInCUD: {
    i18nKey: 'withdrawInCUD',
    defaults: 'You will withdraw {{amount}} USD cents (CUD) from your balance'
  },
  minDepositAmountUsd: {
    i18nKey: 'minDepositAmountUsd',
    defaults: 'Minimum deposit amount'
  },
  minDepositAmountUsdText: {
    i18nKey: 'minDepositAmountUsdText',
    defaults: '{{value}} USD'
  },
  averageMetalsEnergiesSpread: {
    i18nKey: 'averageMetalsEnergiesSpread',
    defaults: 'Average metals/energies spread',
  },
  averageMetalsEnergiesSpreadText: {
    i18nKey: 'averageMetalsEnergiesSpreadText',
    defaults: '{{value}} pips',
  },
  tradingCentralTools: {
    i18nKey: 'tradingCentralTools',
    defaults: 'Trading Central Tools',
  },
  upTo500Leverage: {
    i18nKey: 'upTo500Leverage',
    defaults: 'Up to 1:500 Leverage',
  },
  equivalentPerLotZero: {
    i18nKey: 'equivalentPerLotZero',
    defaults: '{{value}}'
  },
  forexMt4premiumDesc: {
    i18nKey: 'forexMt4premiumDesc',
    defaults: 'Trade gold, oil, and forex pairs with tighter spreads and higher leverage of up to 1:1000 on all fx pairs, up to 1:200 on gold and up to 1:100 on oil. Access free analysis tools including Autochartist and Trading Central, live webinars, expert support, and a wealth of educational resources.',
  },
  equityWarning: {
    i18nKey: 'equityWarning',
    defaults: 'The selected deposit amount will increase your account equity to over the {{amount}} threshold, which will in turn automatically reduce your account’s maximum leverage to 1:500.',
  },
  selectAmountEquityWarning: {
    i18nKey: 'selectAmountEquityWarning',
    defaults: 'Please note that if your equity exceeds {{amount}} as a result of this deposit, your leverage will be reduced to 1:500.',
  },
  upToLeverage: {
    i18nKey: 'upToLeverage',
    defaults: 'Up to 1:1000 Leverage*',
  },
  leverageNote: {
    i18nKey: 'leverageNote',
    defaults: '*Please note that if your account equity exceeds the $25,000 threshold, then your account’s maximum available leverage will be automatically reduced to 1:500.',
  },
  footerBonus: {
    i18nKey:'footerBonus',
    defaults: 'Your account is operated by <0>{{company}}</0>, a company belonging to the <1>BDSwiss</1> group of companies. Please ensure that you are aware of all our <2>legal documents</2>, <3>T&Cs</3>, <5>Bonus T&Cs</5> and <4>Privacy Policy</4>.<6> </6>'
  },
  loyaltyProgram: {
    i18nKey: 'loyaltyProgram',
    defaults: 'Loyalty program',
  },
  partnerLoyaltyProgram: {
    i18nKey: 'partnerLoyaltyProgram',
    defaults: 'Partners loyalty program',
  },
  partnerLoyaltyProgramEntryText: {
    i18nKey: 'partnerLoyaltyProgramEntryText',
    defaults: '{{company}} has launched the Loyalty program for affiliates and introducing brokers. All the prizes are guaranteed and based on your overall turnover.',
  },
  fullTermsAndConditions: {
    i18nKey: 'fullTermsAndConditions',
    defaults: '<0>Read full Terms and Conditions</0>',
  },
  seeMoreStatistics: {
    i18nKey: 'seeMoreStatistics',
    defaults: 'See more statistics',
  },
  imIn: {
    i18nKey: 'imIn',
    defaults: 'I’m in!',
  },
  prizeSubText: {
    i18nKey: 'prizeSubText',
    defaults: 'for {{amount}} billion',
  },
  loyaltyProgramConfirmationTitile: {
    i18nKey: 'loyaltyProgramConfirmationTitile',
    defaults: 'Partner loyalty program',
  },
  loyaltyProgramConfirmationText: {
    i18nKey: 'loyaltyProgramConfirmationText',
    defaults: 'Are you sure you want to participate in {{company}} partner Loyalty program?',
  },
  turnoverSince:{
    i18nKey: 'turnoverSince',
    defaults: 'Your turnover since {{startDate}}',
  },
  turnoverPrice:{
    i18nKey: 'turnoverPrice',
    defaults: '{{currentPrizeThreshold}}% of turnover for {{currentPrize}}',
  },
  turnoverLast12months:{
    i18nKey: 'turnoverLast12months',
    defaults: 'Turnover during the last 12 months',
  },
  turnoverLifetime:{
    i18nKey: 'turnoverLifetime',
    defaults: '{{turnoverLast12months}}% of lifetime turnover',
  },
  joinedLoyaltyProgramP1:{
    i18nKey: 'joinedLoyaltyProgramP1',
    defaults: 'Congratulations, you have joined the loyalty program!',
  },
  joinedLoyaltyProgramP2:{
    i18nKey: 'joinedLoyaltyProgramP2',
    defaults: 'Your turnover will be calculated from <0>{{startDate}}</0>.',
  },
  joinedLoyaltyProgramP3:{
    i18nKey: 'joinedLoyaltyProgramP3',
    defaults: 'To meet the minimum eligibility requirements, you need to onboard 10 new clients by <0>{{endDate}}</0>.',
  },
  clientsOnboarded:{
    i18nKey: 'clientsOnboarded',
    defaults: 'Clients onboarded: <0>{{totalClients}}</0>',
  },
  daysLeft:{
    i18nKey: 'daysLeft',
    defaults: 'Days left: <0>{{daysLeft}}</0>',
  },
  redeemBtn:{
    i18nKey: 'redeemBtn',
    defaults: 'Redeem',
  },
  redeemPrizeTitle: {
    i18nKey: 'redeemPrizeTitle',
    defaults: 'Redeeming a prize',
  },
  redeemPrizeConfirmationText: {
    i18nKey: 'redeemPrizeConfirmationText',
    defaults: 'Your request to redeem the {{prize}} will be sent to {{company}} team. We will get back to you as soon as possible!',
  },
  redeemed:{
    i18nKey: 'redeemed',
    defaults: 'Redeemed',
  },
  turnoverLifetimeTooltip:{
    i18nKey: 'turnoverLifetimeTooltip',
    defaults: 'Your overall turnover. Certain thresholds required for different prizes can be found below.',
  },
  turnoverLast12monthsTooltip:{
    i18nKey: 'turnoverLast12monthsTooltip',
    defaults: 'To redeem the prizes you have to make at least 25% of overall turnover during the last 12 months.',
  },
  redeemPrizeNotification: {
    i18nKey: 'redeemPrizeNotification',
    defaults: 'Congratulations! Your reward can be redeemed!',
  },
  redeemPrize: {
    i18nKey: 'redeemPrize',
    defaults: 'Redeem prize',
  },
  imInTerms: {
    i18nKey: 'imInTerms',
    defaults: 'By opting into the Partners Loyalty program, I expressly acknowledged I have read and accepted the T&Cs',
  },
  yearsOldWarning: {
    i18nKey: 'yearsOldWarning',
    defaults: 'Based on the information you provided, you are over {{ageLimitWarning}} years of age and will be classified as a Retail Client.',
  },
  cfdComplexInstruments: {
    i18nKey: 'cfdComplexInstruments',
    defaults: 'Please note that CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage. Before you proceed, you should consider whether you understand how CFDs work and the risks associated with trading leveraged products.',
  },
  quitRegistration: {
    i18nKey: 'quitRegistration',
    defaults: 'Quit registration',
  },
  understandWishToProceed: {
    i18nKey: 'understandWishToProceed',
    defaults: 'I understand and wish to proceed',
  },
  riskWarningTitle1: {
    i18nKey: 'riskWarningTitle1',
    defaults: 'Risk Warning'
  },
  telegramGroup:{
    i18nKey: 'telegramGroup',
    defaults: 'Telegram Group'
  },
  telegramGroupDescription:{
    i18nKey: 'telegramGroupDescription',
    defaults: 'Receive live market updates, potential trading opportunities and exclusive projections by our in-house analysts.'
  },
  telegramVIPAlerts:{
    i18nKey: 'telegramVIPAlerts',
    defaults: 'Telegram VIP Alerts'
  },
  telegramVIPAlertsDescription:{
    i18nKey: 'telegramVIPAlertsDescription',
    defaults: 'Exclusive VIP Alerts channel.'
  },
  amountInCurrency: {
    i18nKey: 'amountInCurrency',
    defaults: 'Amount in {{currency}}',
  },
  strategyAvatarSettings: {
    i18nKey: 'strategyAvatarSettings',
    defaults: 'Strategy Avatar'
  },
  dividendsPaidShares: {
    i18nKey: 'dividendsPaidShares',
    defaults: 'Dividends paid on shares/long-term positions',
  },
  valueOnMarketPrice: {
    i18nKey: 'valueOnMarketPrice',
    defaults: '{{value}} on market price',
  },
  unleveragedSharesDma: {
    i18nKey: 'unleveragedSharesDma',
    defaults: 'Unleveraged Shares (DMA)',
  },
  etfleveragedSharesEtfds: {
    i18nKey: 'etfleveragedSharesEtfds',
    defaults: 'Leveraged Shares & ETFs',
  },
  usd: {
    i18nKey: 'usd',
    defaults: '{{value}} USD'
  },
  availableAssets: {
    i18nKey: 'availableAssets',
    defaults: 'Available assets'
  },
  investPlusAssets: {
    i18nKey: 'investPlusAssets',
    defaults: '{{value}} Shares and ETFs'
  },
  zeroAssets: {
    i18nKey: 'zeroAssets',
    defaults: '{{value}} Shares and ETFs'
  },
  optionWithLeverage: {
    i18nKey: 'optionWithLeverage',
    defaults: 'Option to trade with or without leverage'
  },
  optionWithLeverageText: {
    i18nKey: 'optionWithLeverageText',
    defaults: '{{value}}'
  },
  demoBalanceUpdated: {
    i18nKey: 'demoBalanceUpdated',
    defaults: 'Demo balance updated successfully!'
  },
  demoReduceBalance: {
    i18nKey: 'demoReduceBalance',
    defaults: 'Please note that if you would like to reduce your balance you can do so from the <0>Withdrawals</0> section.'
  },
  extraSigningText1: {
    i18nKey: 'extraSigningText1',
    defaults: 'After signing is completed, please click <0>here</0>',
  },
  extraSigningText2: {
    i18nKey: 'extraSigningText2',
    defaults: 'or on the <0>Back button</0> to proceed to the bank transfer',
  },
  amountToWithdrawal: {
    i18nKey: 'amountToWithdrawal',
    defaults: 'Amount to withdraw',
  },
  withdrawalFee: {
    i18nKey: 'withdrawalFee',
    defaults: 'Withdrawal fee',
  },
  partnerManagerApproval: {
    i18nKey: 'partnerManagerApproval',
    defaults: 'Contact your Partner Manager for approval.'
  },
  locatedInEuropeNote: {
    i18nKey: 'locatedInEuropeNote',
    defaults: 'It appears that while you have selected a non-European country as your country of residence, our systems have detected that you are currently located in Europe. Before you proceed, please be aware that if your registered country does not match your country of residence on your KYC documentation, then your account will be migrated or closed.'
  },
  fundingAccountExample: {
    i18nKey: 'fundingAccountExample',
    defaults: 'Funding account with Client ID: {{clientId}}'
  },
  example: {
    i18nKey: 'example',
    defaults: 'Example'
  },
  warningTag: {
    i18nKey: 'warningTag',
    defaults: '<0>Warning</0>'
  },
  needDeposit: {
    i18nKey: 'needDeposit',
    defaults: 'into your existing {{accountType}} to unlock additional accounts'
  },
  bankAccountNumber: {
    i18nKey: 'bankAccountNumber',
    defaults: 'Bank Account Number'
  },
  ifscCode: {
    i18nKey: 'ifscCode',
    defaults: 'IFSC Code'
  },
  branchName: {
    i18nKey: 'branchName',
    defaults: 'Branch Name'
  },
  accessDenied: {
    i18nKey: 'accessDenied',
    defaults: 'Access Denied'
  },
  noPermissionUrl: {
    i18nKey: 'noPermissionUrl',
    defaults: 'You do not have permission to access {{url}}'
  },
  privateWalletAddressTitle: {
    i18nKey: 'privateWalletAddressTitle',
    defaults: 'Private Wallet Address'
  },
  privateWalletAddressCaption: {
    i18nKey: 'privateWalletAddressCaption',
    defaults: 'To prove ownership please upload screenshot of your private wallet with QR code showing.'
  },
  proofOfDepositTitle: {
    i18nKey: 'proofOfDepositTitle',
    defaults: 'Proof of Deposit'
  },
  proofOfDepositCaption: {
    i18nKey: 'proofOfDepositCaption',
    defaults: 'Please upload a screenshot showing deposit made to us with our wallet address/transaction hash.'
  },
  noPermissionAccess: {
    i18nKey: 'noPermissionAccess',
    defaults: 'Based on your IP location, you do not have permission to access this URL'
  },
  dividend: {
    i18nKey: 'dividend',
    defaults: 'Dividend',
  },
  notApplicableAcronym: {
    i18nKey: 'notApplicableAcronym',
    defaults: 'N/A'
  },
  residencePermit: {
    i18nKey: 'residencePermit',
    defaults: 'Residence Permit',
  },
  acceptedDocumentsTextPersonal: {
    i18nKey: 'acceptedDocumentsTextPersonal',
    defaults: 'Residence permit or a visa proving your right to remain within the European Economic Area',
  },
  makeSureDocumentsTextPersonal: {
    i18nKey: 'makeSureDocumentsTextPersonal',
    defaults: 'Your residence permit/visa is valid and up to date',
  },
  islamic: {
    i18nKey: 'islamic',
    defaults: 'Islamic'
  },
  swapFree: {
    i18nKey: 'swapFree',
    defaults: 'Swap Free'
  },
  tripleZero: {
    i18nKey: 'tripleZero',
    defaults: 'Triple Zero',
  },
  partnerZone: {
    i18nKey: 'partnerZone',
    defaults: 'Partner Zone'
  },
  events: {
    i18nKey: 'events',
    defaults: 'Events'
  },
  preferences: {
    i18nKey: 'preferences',
    defaults: 'Preferences'
  },
  supportText: {
    i18nKey: 'supportText',
    defaults: 'Support'
  },
  linkToIntroduceClients: {
    i18nKey: 'linkToIntroduceClients',
    defaults: 'Your link to introduce Clients'
  },
  linkToIntroduceIbs: {
    i18nKey: 'linkToIntroduceIbs',
    defaults: 'Your link to introduce IBs'
  },
  partnerStatus: {
    i18nKey: 'partnerStatus',
    defaults: 'Partner Status'
  },
  campaign: {
    i18nKey: 'campaign',
    defaults: 'Campaign'
  },
  goToPortal: {
    i18nKey: 'goToPortal',
    defaults: 'Go to Portal'
  },
  updgradeCampaignTitle: {
    i18nKey: 'updgradeCampaignTitle',
    defaults: 'Upgrade Campaign Type'
  },
  updgradeCampaignText1: {
    i18nKey: 'updgradeCampaignText1',
    defaults: 'We have a range of different campaign types available enabling you to choose how you will get paid.'
  },
  updgradeCampaignText2: {
    i18nKey: 'updgradeCampaignText2',
    defaults: 'Different campaigns work better for different people so you will need to contact your Business Development Manager in order to discuss the business model that you think will suit you best.'
  },
  updgradeCampaignText3: {
    i18nKey: 'updgradeCampaignText3',
    defaults: 'Please complete the form below to submit your request.'
  },
  sendRequest: {
    i18nKey: 'sendRequest',
    defaults: 'Send Request'
  },
  provideMoreDetails: {
    i18nKey: 'provideMoreDetails',
    defaults: 'Please provide more details'
  },
  bannerFirstTitle: {
    i18nKey: 'bannerFirstTitle',
    defaults: 'Partner Loyalty Programme'
  },
  bannerFirstSubTitle: {
    i18nKey: 'bannerFirstSubTitle',
    defaults: 'Claim exclusive prizes on top of your commissions!'
  },
  bannerFirstButtonText: {
    i18nKey: 'bannerFirstButtonText',
    defaults: 'Opt-in today'
  },
  bannerFirstCaptionText: {
    i18nKey: 'bannerFirstCaptionText',
    defaults: 'Up 1:1000 Leverage* | Lower Spreads | $0 Commissions'
  },
  bannerSecondTitle: {
    i18nKey: 'bannerSecondTitle',
    defaults: 'Expand your reach with a sponsored seminar or event!'
  },
  bannerSecondSubTitle: {
    i18nKey: 'bannerSecondSubTitle',
    defaults: 'Trade Crypto'
  },
  bannerSecondCaptionText: {
    i18nKey: 'bannerSecondCaptionText',
    defaults: 'Bitcoin, Litecoin, Ripple and Ethereum'
  },
  bannerSecondButtonText: {
    i18nKey: 'bannerSecondButtonText',
    defaults: 'Learn more'
  },
  bannerSecondListItem1: {
    i18nKey: 'bannerSecondListItem1',
    defaults: 'Venue hire & on-site support'
  },
  bannerSecondListItem2: {
    i18nKey: 'bannerSecondListItem2',
    defaults: 'Local speakers & presentations'
  },
  bannerSecondListItem3: {
    i18nKey: 'bannerSecondListItem3',
    defaults: 'Branded gift bags & prints'
  },
  bannerThirdTitle: {
    i18nKey: 'bannerThirdTitle',
    defaults: 'We focus on your referrals’ needs'
  },
  bannerThirdListItem1: {
    i18nKey: 'bannerThirdListItem1',
    defaults: 'Trading and investing on 1000+ assets'
  },
  bannerThirdListItem2: {
    i18nKey: 'bannerThirdListItem1',
    defaults: 'Award-winning tools and platforms'
  },
  bannerThirdListItem3: {
    i18nKey: 'bannerThirdListItem3',
    defaults: 'Expert research and analysis'
  },
  bannerForthTitle: {
    i18nKey: 'bannerForthTitle',
    defaults: 'Access Powerful Campaigns and Monetisation Tools'
  },
  bannerForthListItem1: {
    i18nKey: 'bannerForthListItem1',
    defaults: 'High-converting marketing funnels'
  },
  bannerForthListItem2: {
    i18nKey: 'bannerForthListItem2',
    defaults: 'Extensive library of banner ads'
  },
  bannerForthListItem3: {
    i18nKey: 'bannerForthListItem3',
    defaults: 'Exclusive offers & custom promotions'
  },
  bannerForthButtonText: {
    i18nKey: 'bannerForthButtonText',
    defaults: 'View Portal'
  },
  communication: {
    i18nKey: 'communication',
    defaults: 'Communication'
  },
  newClientRegistations: {
    i18nKey: 'newClientRegistations',
    defaults: 'New Client registrations'
  },
  partnerBonusesPromotionsAndCompetitions: {
    i18nKey: 'partnerBonusesPromotionsAndCompetitions',
    defaults: 'Partner bonuses, promotions and competitions'
  },
  newClientRegistrationEmail: {
    i18nKey: 'newClientRegistrationEmail',
    defaults: 'New client registration email'
  },
  videoTutorials: {
    i18nKey: 'videoTutorials',
    defaults: 'Video Tutorials'
  },
  viewAll: {
    i18nKey: 'viewAll',
    defaults: 'View All'
  },
  stillHaveQuestions: {
    i18nKey: 'stillHaveQuestions',
    defaults: 'Still have Questions? Contact Partner Support...'
  },
  startFrom100: {
    i18nKey: 'startFrom100',
    defaults: 'START FROM $100'
  },
  bannerButtonTermsAndConditions: {
    i18nKey: 'bannerButtonTermsAndConditions',
    defaults: 'Your capital is at risk.*T&Cs Apply'
  },
  eventsTitle: {
    i18nKey: 'eventsTitle',
    defaults: 'Want to organise an event? We can help!'
  },
  eventsText: {
    i18nKey: 'eventsText',
    defaults: 'Local events can help you promote to new leads, as well as solidify your relationship with existing clients by treating them to  exclusive educational seminars and networking opportunities.'
  },
  eventsListText1: {
    i18nKey: 'eventsListText1',
    defaults: '<0>Bespoke guidance and support</0> from our experienced Business Development Managers',
  },
  eventsListText2: {
    i18nKey: 'eventsListText2',
    defaults: '<0>Printed Marketing Materials, goody bags, and presentations</0> provided by us',
  },
  eventsListText3: {
    i18nKey: 'eventsListText3',
    defaults: '<0>Additional support such as venue hire, flights and accommodation</0>',
  },
  eventsRequestForm: {
    i18nKey: 'eventsRequestForm',
    defaults: 'Event Request Form',
  },
  countryOfEvent: {
    i18nKey: 'countryOfEvent',
    defaults: 'Country of event'
  },
  guests: {
    i18nKey: 'guests',
    defaults: 'Guests'
  },
  eventFormHelperText: {
    i18nKey: 'eventFormHelperText',
    defaults: 'Please add any additional comments which we can use to assist you... The more detail the better!'
  },
  viewGallery: {
    i18nKey: 'viewGallery',
    defaults: 'View Gallery'
  },
  mobile: {
    i18nKey: 'mobile',
    defaults: 'Mobile'
  },
  partnerBonusesPromotionsCompetitions: {
    i18nKey: 'partnerBonusesPromotionsCompetitions',
    defaults: 'Partner bonuses, promotions and competitions'
  },
  introduceClientsTooltip: {
    i18nKey: 'introduceClientsTooltip',
    defaults: 'This is your link to introduce traders. When they sign up via this link they will automatically be added to your network so you can earn commission from their trades.'
  },
  introduceIbsTooltip: {
    i18nKey: 'introduceIbsTooltip',
    defaults: 'This is your link to introduce other Introducing Brokers. When they sign up via this link they will automatically be added to your network and you will earn commission from the traders they introduce as well.'
  },
  partnerStatusTooltip: {
    i18nKey: 'partnerStatusTooltip',
    defaults: 'The status of your partner account. If your account is inactive/unverified you are required to take action. Please upload necessary documents or speak to Partner Support for more information.'
  },
  campaignTooltip: {
    i18nKey: 'campaignTooltip',
    defaults: 'The current campaign as agreed with your Business Development Manager. This determines how you will earn from your introduced clients.'
  },
  supportTicketSubmitted: {
    i18nKey: 'supportTicketSubmitted',
    defaults: 'Support Ticket Submitted!'
  },
  supportTicketSubmittedContent: {
    i18nKey: 'supportTicketSubmittedContent',
    defaults: 'Your personal Business Development Manager will contact you soon.'
  },
  gallery: {
    i18nKey: 'gallery',
    defaults: 'Gallery'
  },
  selectAccountText: {
    i18nKey: 'selectAccountText',
    defaults: 'Please choose one of the following accounts to open in your portal.'
  },
  pendingTooltip: {
    i18nKey: 'pendingTooltip',
    defaults: 'The current outstanding balance from your introducing activities that is pending to be credited to your Introducing Broker account.'
  },
  upgrade: {
    i18nKey:'upgrade',
    defaults: 'Upgrade'
  },
  supportHelperText: {
    i18nKey:'supportHelperText',
    defaults: 'Please type your question and a member of our Partner support team will be in touch...'
  },
  viewFullTerms: {
    i18nKey: 'viewFullTerms',
    defaults: '<0>View full Terms and Conditions</0>'
  },
  share: {
    i18nKey: 'share',
    defaults: 'Share'
  },
  shareReferralLink: {
    i18nKey: 'shareReferralLink',
    defaults: 'Share your Referral link'
  },
  emailYourFriends: {
    i18nKey: 'emailYourFriends',
    defaults: 'Email your friends'
  },
  inputYourFriends: {
    i18nKey: 'inputYourFriends',
    defaults: 'Input your friends’ email addresses to automatically send them your referral link.'
  },
  addEmail: {
    i18nKey: 'addEmail',
    defaults: 'Add email'
  },
  seperateEmails: {
    i18nKey: 'seperateEmails',
    defaults: 'Separate each person you would like to invite by a comma or a new line. You can send up to 10 emails at once!'
  },
  referalsReward: {
    i18nKey: 'referalsReward',
    defaults: 'Referal\'s Reward'
  },
  requiredVolume: {
    i18nKey: 'requiredVolume',
    defaults: 'Required Volume'
  },
  noteDepositsRefer: {
    i18nKey: 'noteDepositsRefer',
    defaults: 'Note: Deposits made in other currencies will be checked against the exchange rate at the time of deposit and the value of the reward will be granted accordingly'
  },
  becomeAnIb: {
    i18nKey: 'becomeAnIb',
    defaults: 'Want to earn even more? Become an IB!'
  },
  becomeAnIbSubtitle: {
    i18nKey: 'becomeAnIbSubtitle',
    defaults: 'Our Introducing Broker programme allows you to refer as many people as you like  and earn money every time they trade!'
  },
  becomeAnIbText1: {
    i18nKey: 'becomeAnIbText1',
    defaults: '<0>Exclusive loyalty programme</0> with rewards such as luxury watches, holidays and super cars. '
  },
  becomeAnIbText2: {
    i18nKey: 'becomeAnIbText2',
    defaults: '<0>No limit</0> on amount of referrals'
  },
  becomeAnIbText3: {
    i18nKey: 'becomeAnIbText3',
    defaults: '<0>Earn commission on your referrals’ trades</0>'
  },
  becomeAnIbText4: {
    i18nKey: 'becomeAnIbText4',
    defaults: '<0>Easily withdraw</0> all rewards with no additional conditions or restrictions'
  },
  becomeAnIbBtn: {
    i18nKey: 'becomeAnIbBtn',
    defaults: 'Want to earn even more? Become an IB!'
  },
  learnMore: {
    i18nKey: 'learnMore',
    defaults: 'Learn More'
  },
  volumeTitle: {
    i18nKey: 'volumeTitle',
    defaults: 'Volume'
  },
  noteReferralDataUpdate: {
    i18nKey: 'noteReferralDataUpdate',
    defaults: 'Note: It may take up to 24 hours for your referral data to be updated'
  },
  referAFriend: {
    i18nKey: 'referAFriend',
    defaults: 'Refer a Friend',
  },
  revealLink: {
    i18nKey: 'revealLink',
    defaults: 'Reveal Link',
  },
  acceptRevealLink: {
    i18nKey: 'acceptRevealLink',
    defaults: 'Accept & Reveal Link',
  },
  revealAcceptTerms: {
    i18nKey: 'revealAcceptTerms',
    defaults: 'In order to reveal your referral link please read and accept the program <0>Terms and Conditions.</0>',
  },
  continueReferringClients: {
    i18nKey: 'continueReferringClients',
    defaults: 'If you wish to contine referring clients please contact our IB department below.',
  },
  referralLimit: {
    i18nKey: 'referralLimit',
    defaults: 'Referral limit reached!',
  },
  referralLimitText: {
    i18nKey: 'referralLimitText',
    defaults: 'If you wish to continue referring clients, please contact our IB department.',
  },
  becomeAnIbButton: {
    i18nKey: 'becomeAnIbButton',
    defaults: 'Become an IB',
  },
  finalisePartnerRegistration: {
    i18nKey: 'finalisePartnerRegistration',
    defaults: 'Your personal Business Development Manager will contact you to assist you in finalising your partner registration.',
  },
  ibInterestSubmitted: {
    i18nKey: 'ibInterestSubmitted',
    defaults: 'IB Interest Submitted!',
  },
  deposited: {
    i18nKey: 'deposited',
    defaults: 'Deposited',
  },
  referReferralSubtitle: {
    i18nKey: 'referReferralSubtitle',
    defaults: 'In order to receive your welcome reward, please complete the below steps within {{days}} days from your registration:',
  },
  referReferralText1: {
    i18nKey: 'referReferralText1',
    defaults: 'Fund your trading account with a minimum of {{deposit}} USD (or equivalent in other currencies)',
  },
  referReferralText2: {
    i18nKey: 'referReferralText2',
    defaults: 'Trade more than the minimum volume requirement',
  },
  howMuchToEarn: {
    i18nKey: 'howMuchToEarn',
    defaults: 'How much can I earn?',
  },
  lots: {
    i18nKey: 'lots',
    defaults: 'lots',
  },
  zarReferralDeposit: {
    i18nKey: 'zarReferralDeposit',
    defaults: 'In order to be eligible for certain promotions such as Referral rewards, your ZAR deposit will be checked against the current ZAR/USD exchange rate. To be eligible for a reward, you will need to ensure that your deposit amount is above the respective reward thresholds in USD.',
  },
  creditRewardMessage: {
    i18nKey: 'creditRewardMessage',
    defaults: 'You have {{amount}} in unclaimed Referral Rewards. Please select the account to which you want to credit your referral reward. If the selected account is in a different currency, the reward amount will be converted into your account’s currency according to the latest spot price.',
  },
  claimRewardTerms: {
    i18nKey: 'claimRewardTerms',
    defaults: 'In order to claim your reward please confirm that you accept the <0>Terms and Conditions</0> by ticking the box.',
  },
  claimRewardVerify: {
    i18nKey: 'claimRewardVerify',
    defaults: 'In order to claim your reward you must first verify your account. You can verify your account from the <0>Profile & Settings section</0> on your dashboard.',
  },
  claimRewardText1: {
    i18nKey: 'claimRewardText1',
    defaults: 'Start referring your friends and contacts to {{company}} and earn up to {{amountReferrer}} for each referral that meets the trading requirements.'
  },
  claimRewardText2: {
    i18nKey: 'claimRewardText2',
    defaults: 'Your referred friend will also benefit from a welcome reward!',
  },
  referAndEarnText6: {
    i18nKey: 'referAndEarnText6',
    defaults: 'Your referral must then trade the minimum required volume within a period of {{days}} days post registration',
  },
  referAndEarnText7: {
    i18nKey: 'referAndEarnText7',
    defaults: 'You will then be able to claim your reward and transfer it to your trading account',
  },
  sendMaxErrorMeail: {
    i18nKey: 'sendMaxErrorMeail',
    defaults: 'Maximum email addresses {{max}}'
  },
  totalRewards: {
    i18nKey: 'totalRewards',
    defaults: 'Total Rewards',
  },
  amountFtdUsdReferral: {
    i18nKey: 'amountFtdUsdReferral',
    defaults: 'This is the amount in USD that your referral must deposit to earn the reward.',
  },
  creditRewardMessageReferee: {
    i18nKey: 'creditRewardMessageReferee',
    defaults: 'Please select an account to which you want to credit your reward. If the selected account is in a different currency, the reward amount will be converted into the account currency according to the latest spot price.',
  },
  congratulationsReferredSomeone: {
    i18nKey: 'congratulationsReferredSomeone',
    defaults: 'Congratulations! <0 />Someone you referred has signed up.',
  },
  congratulationsReferredSomeoneText: {
    i18nKey: 'congratulationsReferredSomeoneText',
    defaults: 'Now, earn commissions every time your referrals trade by becoming an IB!',
  },
  congratulationsReferredText1: {
    i18nKey: 'congratulationsReferredText1',
    defaults: 'Simple registration process.',
  },
  congratulationsReferredText2: {
    i18nKey: 'congratulationsReferredText2',
    defaults: 'Continuous income stream while your referrals are trading.',
  },
  congratulationsReferredText3: {
    i18nKey: 'congratulationsReferredText3',
    defaults: 'Referral commissions available for immediate withdrawal.',
  },
  congratulationsReferredText4: {
    i18nKey: 'congratulationsReferredText4',
    defaults: 'No limit to the amount you can earn.',
  },
  emailsValidation: {
    i18nKey: 'emailsValidation',
    defaults: 'Please use valid emails'
  },
  emailsHaveBeenSent: {
    i18nKey: 'emailsHaveBeenSent',
    defaults: 'Emails have been sent'
  },
  amountFtdUsdReferralFriend: {
    i18nKey: 'amountFtdUsdReferralFriend',
    defaults: 'This is the amount in USD that you must deposit on your first deposit to earn the reward.',
  },
  firstNotificationRefer: {
    i18nKey: 'firstNotificationRefer',
    defaults: 'The Refer a Friend Programme will be discontinued by June 1st, 2022. After this date, you will no longer be able to refer your friends to {{company}}. You may claim any unclaimed rewards until the 1st of June 2022. Otherwise, any unclaimed rewards will be automatically credited to your trading account following the programme’s termination',
  },
  secondNotificationRefer: {
    i18nKey: 'secondNotificationRefer',
    defaults: 'The promotion has now expired',
  },
  identityVerification: {
    i18nKey: 'identityVerification',
    defaults: 'Identity Verification',
  },
  initialInformationModalCysecText1: {
    i18nKey:'initialInformationModalCysecText1',
    defaults: 'Pursuant to CySEC and EU regulations, the Company is required to ask you to provide information regarding your knowledge and experience in CFDs trading so as to enable the Company to assess whether the services or products envisaged are appropriate for you.'
  },
  initialInformationModalCysecText2: {
    i18nKey:'initialInformationModalCysecText2',
    defaults: 'We strongly advise you to provide us with up-to date, accurate and complete information which is necessary for the purpose of enabling us to assess the appropriateness of our products for you.'
  },
  initialInformationModalCysecText3: {
    i18nKey:'initialInformationModalCysecText3',
    defaults: 'If you fail to provide sufficient information in this regard, we will not be able to assess whether you have the necessary knowledge and experience to understand the risks involved. If you still wish to proceed, you may do so, but we shall not be able to determine whether trading in CFDs is appropriate for you.'
  },
  goToPersonalDetails: {
    i18nKey: 'goToPersonalDetails',
    defaults: 'Go to Personal details',
  },
  level1AppropTestMessageCysec: {
    i18nKey: 'level1AppropTestMessageCysec',
    defaults: `Unfortunately, we are unable to proceed with your application. Based on the responses provided for your trading account application, you do not meet the eligibility criteria, as required by the European regulations, to trade in the leveraged products offered by our Company.<0/>
    We strongly encourage you to review the educational sections of our <1>website</1>, as well as open a demo account, which may both further enhance your understanding of the products we offer.<0/>
    Should your needs and circumstances change, and/or your investment knowledge and experience reach an adequate level, we may be able to reconsider your application in the future. Should you need further information and/or assistance please feel free to contact us at <2>support@{{emailDomain}}</2>`,
  },
  termsCysecP1: {
    i18nKey: 'termsCysecP1',
    defaults: 'I agree to allow the Company to process my personal data for meeting its regulatory obligations, for optimization and execution of its marketing campaigns and also agree to receive Company\'s newsletters, news and product updates. I declare that I carefully read and understood the entire content of the <0>Privacy Policy</0> of the Company.'
  },
  termsCysecP2: {
    i18nKey: 'termsCysecP2',
    defaults: 'I declare that I have carefully read, accepted and fully understood the entire content of the <0>Terms and Conditions</0>, <4>Order Execution Policy</4>, <3>Risk Disclosure</3>, <5>Client Categorisation Policy</5>, <6>Summary of Conflicts of Interest Policy</6>, <1>Privacy Policy</1>, <7>Key Information Documents</7> and other <2>legal documents</2>.'
  },
  termsCysecP3: {
    i18nKey: 'termsCysecP3',
    defaults: 'I confirm that I am aged 18 years or over and that the information provided by me in this account opening process is true and correct and that I will notify BDSwiss Holding Ltd in writing of any material changes. I further confirm that I do not breach any regulations of my country of residence in trading with BDSwiss Holding Ltd'
  },
  placeOfBirth: {
    i18nKey: 'placeOfBirth',
    defaults: 'Place of birth'
  },
  profession: {
    i18nKey: 'profession',
    defaults: 'Profession'
  },
  nationalId:{
    i18nKey: 'nationalId',
    defaults: 'O número NID é o número de CFD (o que significa Cadastro de Pessoas Físicas) e é um número de identificação fiscal individual de 11 dígitos. Exemplo do CFD: 231.002.999-00.',
  },
  nationalIdNumberBR:{
    i18nKey: 'nationalIdNumberBR',
    defaults: 'CPF',
  },
  nationalIdNumberPopupTitle: {
    i18nKey: 'nationalIdNumberPopupTitle',
    defaults: 'Your account requires updated details.',
  },
  nationalIdNumberPopupMessage: {
    i18nKey: 'nationalIdNumberPopupMessage',
    defaults: 'Please proceed with the following actions: update your Personal Identification Number. Please make sure to enter out your details <0>here</0>',
  },
  proceedToPayBR:{
    i18nKey: 'proceedToPayBR',
    defaults: 'Continuar para depositar',
  },
  relationType: {
    i18nKey: 'relationType',
    defaults: 'Relation Type'
  },
  documents: {
    i18nKey: 'documents',
    defaults: 'Documents'
  },
  corporateCannotChangeDetails: {
    i18nKey: 'corporateCannotChangeDetails',
    defaults: 'Your Corporate Account is {{corporateStatus}}, details cannot change after your application submission.If you want to change anything on your application please contact our <0>Support Team</0>.'
  },
  corporateNotSupported: {
    i18nKey: 'corporateNotSupported',
    defaults: 'Sorry, corporate users are not supported by this app',
  },
  uploaded:{
    i18nKey: 'uploaded',
    defaults: 'Uploaded',
  },
  profitAndLossesError:{
    i18nKey: 'profitAndLossesError',
    defaults: 'Start with "+" to fill out Profits and with "-" to fill out Losses',
  },
  riskWarningViverno: {
    i18nKey: 'riskWarningViverno',
    defaults: 'CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage. {{percentage}} % of retail investor accounts lose money when trading CFDs with this provider. You should consider whether you understand how CFDs work and whether you can afford to take the high risk of losing your money. Viverno is the trading name of BDSwiss Holding Ltd. BDSwiss Holding Ltd is authorized and regulated by the Cyprus Securities and Exchange Commission (the <2>“CySEC“</2>, license no. 199/13 and registration no. HE 300153). The address of BDSwiss Holding Ltd is Apostolou Andrea Street 11, Hyper Tower, 5th Floor, 4007 Mesa Yeitonia, Limassol, Cyprus. Please review the company policies regarding the regulation <0>here</0>. <3 />Registered address: Ioanni Stylianou 6, 2nd floor, Office 202, 2003 Nicosia, Cyprus.'
  },
  minimumDepositFootnoteNotThirdParty: {
    i18nKey: 'minimumDepositFootnoteNotThirdParty',
    defaults: 'Please note that the minimum first deposit amount may differentiate from the {{amount}} limit (or trading account currency equivalent), depending on your country of residence.'
  },
  approxYearlyIncomeQuestionptBR:{
    i18nKey: 'approxYearlyIncomeQuestionptBR',
    defaults: 'What is your approximate yearly income in Euro?',
  },
  approxNetWorthQuestionptBR:{
    i18nKey: 'approxNetWorthQuestionptBR',
    defaults: 'What is your approximate total net worth in Euro?',
  },
  tinReasonQuestionptBR:{
    i18nKey: 'tinReasonQuestionptBR',
    defaults: 'Se o Número de Cadastro de Pessoas Físicas não estiver disponível, selecione um motivo:',
  },
  iHaveTinOptionptBR:{
    i18nKey: 'iHaveTinOptionptBR',
    defaults: 'I have a TIN.',
  },
  noTinIssuedOptionptBR:{
    i18nKey: 'noTinIssuedOptionptBR',
    defaults: 'TINs are not issued in my Tax Jurisdiction.',
  },
  noTinRequiredOptionptBR:{
    i18nKey: 'noTinRequiredOptionptBR',
    defaults: 'No TIN is required in my Tax Jurisdiction.',
  },
  unableToObtainOptionptBR:{
    i18nKey: 'unableToObtainOptionptBR',
    defaults: 'I\'m unable to obtain a TIN or equivalent number.',
  },
  tinQuestionptBR:{
    i18nKey: 'tinQuestionptBR',
    defaults: 'Taxpayer Identification Number (TIN)',
  },
  tradingPlatform: {
    i18nKey: 'tradingPlatform',
    defaults: 'Trading Platform',
  },
  tradingPlatformTooltipTitle: {
    i18nKey: 'tradingPlatformTooltipTitle',
    defaults: 'Select the trading platform that you would like to use for your default trading account.',
  },
  tradingPlatformTooltipMT4Title: {
    i18nKey: 'tradingPlatformTooltipMT4Title',
    defaults: 'MetaTrader 4:',
  },
  tradingPlatformTooltipMT5Title: {
    i18nKey: 'tradingPlatformTooltipMT5Title',
    defaults: 'MetaTrader 5:',
  },
  tradingPlatformTooltipMT4Option1: {
    i18nKey: 'tradingPlatformTooltipMT4Option1',
    defaults: '4 Pending Order Types',
  },
  tradingPlatformTooltipMT4Option2: {
    i18nKey: 'tradingPlatformTooltipMT4Option2',
    defaults: '30 Technical Indicators',
  },
  tradingPlatformTooltipMT4Option3: {
    i18nKey: 'tradingPlatformTooltipMT4Option3',
    defaults: '31 Graphical Objects',
  },
  tradingPlatformTooltipMT4Option4: {
    i18nKey: 'tradingPlatformTooltipMT4Option4',
    defaults: '9 Chart Timeframes',
  },
  tradingPlatformTooltipMT4Option5: {
    i18nKey: 'tradingPlatformTooltipMT4Option5',
    defaults: '33 Analytical tools',
  },
  tradingPlatformTooltipMT4Option6: {
    i18nKey: 'tradingPlatformTooltipMT4Option6',
    defaults: '32bit Platform',
  },
  tradingPlatformTooltipMT4Option7: {
    i18nKey: 'tradingPlatformTooltipMT4Option7',
    defaults: 'Position Hedging',
  },
  tradingPlatformTooltipMT5Option1: {
    i18nKey: 'tradingPlatformTooltipMT5Option1',
    defaults: '6 Pending Order Types',
  },
  tradingPlatformTooltipMT5Option2: {
    i18nKey: 'tradingPlatformTooltipMT5Option2',
    defaults: '38 Technical Indicators',
  },
  tradingPlatformTooltipMT5Option3: {
    i18nKey: 'tradingPlatformTooltipMT5Option3',
    defaults: '44 Graphical Objects',
  },
  tradingPlatformTooltipMT5Option4: {
    i18nKey: 'tradingPlatformTooltipMT5Option4',
    defaults: '21 Chart Timeframes',
  },
  tradingPlatformTooltipMT5Option5: {
    i18nKey: 'tradingPlatformTooltipMT5Option5',
    defaults: '68 Analytical tools',
  },
  tradingPlatformTooltipMT5Option6: {
    i18nKey: 'tradingPlatformTooltipMT5Option6',
    defaults: '64bit Platform',
  },
  tradingPlatformTooltipMT5Option7: {
    i18nKey: 'tradingPlatformTooltipMT5Option7',
    defaults: 'Position Hedging or Netting',
  },
  tradingPlatformTooltipMT5Option8: {
    i18nKey: 'tradingPlatformTooltipMT5Option8',
    defaults: 'Economic Calendar',
  },
  somethingNewExciting: {
    i18nKey: 'somethingNewExciting',
    defaults: 'Something new & exciting is on the way',
  },
  chechBackForYou: {
    i18nKey: 'chechBackForYou',
    defaults: 'Please check back soon, as this is for you',
  },
  alreadyRegistered: {
    i18nKey: 'alreadyRegistered',
    defaults: 'Already have an account? <0>Log In</0>',
  },
  fourOfour: {
    i18nKey: 'fourOfour',
    defaults: '404'
  },
  redirectToHomePage: {
    i18nKey: 'redirectToHomePage',
    defaults: 'Click <0>here</0> to be redirected to Home Page'
  },
  depositWarningPayretailers: {
    i18nKey: 'depositWarningPayretailers',
    defaults: 'Kindly make sure you retain the Proof of Deposit and upload it under the Verification documents section (underneath Profile and Settings), right after the deposit was executed, as it may be required upon withdrawal.'
  },
  strategyAvatarRejected: {
    i18nKey: 'strategyAvatarRejected',
    defaults: 'Your avatar was declined. Contact your Partner Manager for details.'
  },
  level1AppropTestMessageCysecViverno: {
    i18nKey: 'level1AppropTestMessageCysecViverno',
    defaults: `Unfortunately, we are unable to proceed with your application. Based on the responses provided for your trading account application, you do not meet the eligibility criteria, as required by the European regulations, to trade in the leveraged products offered by our Company.<0/>
    We strongly encourage you to open a demo account to enhance your understanding of the products we offer.<0/>
    Should your needs and circumstances change, and/or your investment knowledge and experience reach an adequate level, we may be able to reconsider your application in the future. Should you need further information and/or assistance please feel free to contact us at <2>support@{{emailDomain}}</2>`,
  },
  instant: {
    i18nKey: 'instant',
    defaults: 'Instant',
  },
  day: {
    i18nKey: 'day',
    defaults: 'Day',
  },
  hour: {
    i18nKey: 'hour',
    defaults: 'Hour',
  },
  minute: {
    i18nKey: 'minute',
    defaults: 'Minute',
  },
  min: {
    i18nKey: 'min',
    defaults: 'Min',
  },
  noFees: {
    i18nKey: 'noFees',
    defaults: 'No Fees',
  },
  economicCalendar: {
    i18nKey: 'economicCalendar',
    defaults: 'Economic Calendar',
  },
  economicCalendarDescription: {
    i18nKey: 'economicCalendarDescription',
    defaults: 'An automated investment analytics and analyst research.',
  },
  depositTermsAndConditions: {
    i18nKey: 'depositTermsAndConditions',
    defaults: 'By proceeding with the deposit you accept <0>terms and conditions</0>',
  },
  companyName: {
    i18nKey: 'companyName',
    defaults: 'Company name',
  },
  companyAddress: {
    i18nKey: 'companyAddress',
    defaults: 'Company address',
  },
  depositWarmingMinimumDeposit: {
    i18nKey: 'depositWarmingMinimumDeposit',
    defaults: 'The minimum deposit amount of the payment method is higher than the selected amount. Please change the selected deposit amount or chose another payment method',
  },
  depositWarningMaximumDeposit: {
    i18nKey: 'depositWarningMaximumDeposit',
    defaults: 'The maximum deposit amount of the payment method is lower than the selected amount. Please change the selected deposit amount or chose another payment method',
  },
  cryptoDepositNote6: {
    i18nKey: 'cryptoDepositNote6',
    defaults: 'Please ensure that you provide a screenshot of your private wallet address with a QR code showing in order to avoid any delays upon withdrawal. You can upload it in the client portal area.',
  },
  informativePage: {
    i18nKey: 'informativePage',
    defaults: 'We no longer offer our sevices through this domain in your country. <0 />Please refer to the information already shared via email, and if further assistance is required, please contact our Customer Support.',
  },
  safeChargeLegal: {
    i18nKey: 'safeChargeLegal',
    defaults: 'There is a risk of substantial loss associated with Trading Forex and/or CFDs and/or other financial instruments offered on the website',
  },
  safeChargeOwnershipEu: {
    i18nKey: 'safeChargeOwnershipEu',
    defaults: 'Viverno Markets Ltd (ex BDSwiss Holding Ltd) is authorized and regulated by the Cyprus Securities and Exchange Commission (the “CySEC”, license no. 199/13 and registration no. HE 300153). The address of Viverno Markets Ltd (ex BDSwiss Holding Ltd) is Apostolou Andrea Street 11, Hyper Tower, 5th Floor, 4007 Mesa Yeitonia, Limassol, Cyprus.',
  },
  safeChargeOwnershipMau: {
    i18nKey: 'safeChargeOwnershipMau',
    defaults: 'Viverno Markets Ltd (ex BDSwiss Holding Ltd) with registered address: 6 Ioanni Stylianou, 2nd Floor, Flat/Office 202, 2003, Nicosia, Cyprus is acting as the payments processor for BDS Markets.',
  },
  safeChargeOwnershipSey: {
    i18nKey: 'safeChargeOwnershipSey',
    defaults: 'Viverno Markets Ltd (ex BDSwiss Holding Ltd) with registered address: 6 Ioanni Stylianou, 2nd Floor, Flat/Office 202, 2003, Nicosia, Cyprus is acting as the payments processor for BDS Ltd',
  },
  safeChargeDescription: {
    i18nKey: 'safeChargeDescription',
    defaults: 'The charge will appear on your bill as “{{company}} +44 2036705890”',
  },
  bentoWebtraderTitle: {
    i18nKey: 'bentoWebtraderTitle',
    defaults: 'WebTrader',
  },
  bentoAffiliatePortalTitle: {
    i18nKey: 'bentoAffiliatePortalTitle',
    defaults: 'Affiliate portal',
  },
  bentoIbPortalTitle: {
    i18nKey: 'bentoIbPortalTitle',
    defaults: 'IB portal',
  },
  bentoWebsiteTitle: {
    i18nKey: 'bentoWebsiteTitle',
    defaults: 'Website',
  },
  bentoTelegramChannelTitle: {
    i18nKey: 'bentoTelegramChannelTitle',
    defaults: 'Telegram channel',
  },
  bentoTelegramChannelSubtitle: {
    i18nKey: 'bentoTelegramChannelSubtitle',
    defaults: 'Market news, trading alerts & opportunities',
  },
  bentoTradingCentralTitle: {
    i18nKey: 'bentoTradingCentralTitle',
    defaults: 'Trading central',
  },
  bentoTradingCentralSubtitle: {
    i18nKey: 'bentoTradingCentralSubtitle',
    defaults: 'Automated investment analytics & analyst research',
  },
  bentoMobileQrCodeTitle: {
    i18nKey: 'bentoMobileQrCodeTitle',
    defaults: '{{company}} mobile app',
  },
  bentoMobileQrCodeSubtitle: {
    i18nKey: 'bentoMobileQrCodeSubtitle',
    defaults: 'Scan to download App iOS & Android',
  },
  downloadMobileAppFromStoreModalTitleDesktop: {
    i18nKey: 'downloadMobileAppFromStoreModalTitleDesktop',
    defaults: 'Scan to download {{company}} mobile App in <0>App Store or Google Play</0>:',
  },
  downloadMobileApkModalTitleDesktop: {
    i18nKey: 'downloadMobileApkModalTitleDesktop',
    defaults: 'Scan to download {{company}} mobile App with <0>Android APK</0> file:',
  },
  downloadMobileAppModalTitleMobile: {
    i18nKey: 'downloadMobileAppModalTitleMobile',
    defaults: 'Download {{company}} mobile App in <0>{{storeName}}</0> or with <0>Android APK</0> file:',
  },
  downloadMobileAppBannerTitle: {
    i18nKey: 'downloadMobileAppBannerTitle',
    defaults: '{{company}} App',
  },
  downloadMobileAppBannerSubtitle: {
    i18nKey: 'downloadMobileAppBannerSubtitle',
    defaults: 'Download & start trading',
  },
  downloadApp: {
    i18nKey: 'downloadApp',
    defaults: 'App Store or Google Play',
  },
  downloadAppTitle: {
    i18nKey: 'downloadAppTitle',
    defaults: 'Download app',
  },
  androidApk: {
    i18nKey: 'androidApk',
    defaults: 'Android APK',
  },
  forexMt4basicCategoryDesc: {
    i18nKey: 'forexMt4basicCategoryDesc',
    defaults: 'Open a Classic account and trade 250+ CFDs on MetaTrader 4 - the world\'s leading trading platform.',
  },
  forexMt4standardCategoryDesc: {
    i18nKey: 'forexMt4standardCategoryDesc',
    defaults: 'Open a Standard account and trade 250+ CFDs on MetaTrader 4 - the world\'s leading trading platform.',
  },
  forexMt5basicCategoryDesc: {
    i18nKey: 'forexMt5basicCategoryDesc',
    defaults: 'Open a Classic account and access advanced trading and analysis tools on MetaTrader 5.',
  },
  forexMt4blackCategoryDesc: {
    i18nKey: 'forexMt4blackCategoryDesc',
    defaults: 'Open a VIP account and enjoy trading alerts, priority services and exclusive webinars on MetaTrader 4 - the world\'s leading trading platform.',
  },
  forexMt4proCategoryDesc: {
    i18nKey: 'forexMt4bproCategoryDesc',
    defaults: 'Open a Pro account and enjoy trading alerts, priority services and exclusive webinars on MetaTrader 4 - the world\'s leading trading platform.',
  },
  forexMt4zeroCategoryDesc: {
    i18nKey: 'forexMt4zeroCategoryDesc',
    defaults: 'Open a Zero Spread account for spreads starting from 0.0 on MetaTrader 4 - the world\'s leading trading platform.',
  },
  forexMt4primeCategoryDesc: {
    i18nKey: 'forexMt4primeCategoryDesc',
    defaults: 'Open a Prime account for spreads starting from 0.0 on MetaTrader 4 - the world\'s leading trading platform.',
  },
  forexMt5zeroCategoryDesc: {
    i18nKey: 'forexMt5zeroCategoryDesc',
    defaults: 'Open a Zero Spread account for spreads starting from 0.0 on MetaTrader 5.',
  },
  forexMt5primeCategoryDesc: {
    i18nKey: 'forexMt5primeCategoryDesc',
    defaults: 'Open a Prime account for spreads starting from 0.0 on MetaTrader 5.',
  },
  forexMt5blackCategoryDesc: {
    i18nKey: 'forexMt5blackCategoryDesc',
    defaults: 'Open a VIP account and get the private treatment with access to timely trading alerts, priority services and exclusive webinars on MetaTrader 5.',
  },
  forexMt5proCategoryDesc: {
    i18nKey: 'forexMt5proCategoryDesc',
    defaults: 'Open a Pro account and get the private treatment with access to timely trading alerts, priority services and exclusive webinars on MetaTrader 5.',
  },
  forexMt4rawCategoryDesc: {
    i18nKey: 'forexMt4rawCategoryDesc',
    defaults: 'Open a RAW account for spreads starting from 0.0 on MetaTrader 4 - the world\'s leading trading platform.',
  },
  forexMt5rawCategoryDesc: {
    i18nKey: 'forexMt5rawCategoryDesc',
    defaults: 'Open a RAW account for spreads starting from 0.0 on MetaTrader 5.',
  },
  open: {
    i18nKey: 'open',
    defaults: 'Open',
  },
  educationalVideos: {
    i18nKey: 'educationalVideos',
    defaults: 'Educational Videos',
  },
  educationalVideosFundAccountNotification: {
    i18nKey: 'educationalVideosFundAccountNotification',
    defaults: 'Please fund your account to see the videos.',
  },
  complete: {
    i18nKey: 'complete',
    defaults: 'Complete',
  },
  notificationTextDefault: {
    i18nKey: 'notificationTextDefault',
    defaults: 'Please reply to our email or contact Customer Support',
  },
  promotions: {
    i18nKey: 'promotions',
    defaults: 'Promotions',
  },
  emptyPromotions: {
    i18nKey: 'emptyPromotions',
    defaults: 'You do not have any promotions yet'
  },
  notEligiblePromotions: {
    i18nKey: 'notEligiblePromotions',
    defaults: 'You cannot opt-in unless you successfully participate in the FTD Bonus',
  },
  bonusHandoutsPromotions: {
    i18nKey: 'bonusHandoutsPromotions',
    defaults: 'Bonus Handouts'
  },
  bonusHandoutsActivationPromotions: {
    i18nKey: 'bonusHandoutsActivationPromotions',
    defaults: 'Opt-in to activate your bonuses',
  },
  optInTermsAndConditionsPromotions: {
    i18nKey: 'optInTermsAndConditionsPromotions',
    defaults: 'By opting-in I agree to <0 /><1>the Terms and Conditions</1>',
  },
  optInPromotionsText: {
    i18nKey: 'optInPromotionsText',
    defaults: 'Opt-in',
  },
  optOutPromotionsText: {
    i18nKey: 'optOutPromotionsText',
    defaults: 'Opt-out',
  },
  availablePromotionsText: {
    i18nKey: 'availablePromotions',
    defaults: 'Available promotions',
  },
  timeLeftPromotionsText: {
    i18nKey: 'timeLeftPromotionsText',
    defaults: 'Time left:',
  },
  minDepositPromotionsText: {
    i18nKey: 'minDepositPromotionsText',
    defaults: 'Min. deposit:',
  },
  claimedPromotionText: {
    i18nKey: 'claimedPromotionText',
    defaults: 'Claimed:',
  },
  bonusPromotionText: {
    i18nKey: 'bonusPromotionText',
    defaults: 'Bonus:',
  },
  notOptedInPromotions: {
    i18nKey: 'notOptedInPromotions',
    defaults: 'You opted-out so there no promotions available',
  },
  riskWarningBDSwissGroupV1:{
    i18nKey: 'riskWarningBDSwissGroupV1',
    defaults: 'Trading in CFDs is highly speculative and carries a high level of risk. It is possible to lose all of your invested capital. These products may not be suitable for everyone, and you should ensure that you fully understand the risks taking into consideration your investment objectives, level of experience, personal circumstances as well as personal resources. Speculate only with funds that you can afford to lose. Seek independent advice if necessary. Please refer to our Risk Disclosure. <3 />BDSwiss is a trading name of BDS Markets and BDS Ltd. <5 />BDS Markets is a company incorporated under the laws of the Republic of Mauritius and is authorized and regulated by the Financial Services Commission of Mauritius (FSC) under license number C116016172, address: 6th Floor, Tower 1, Nexteracom Building 72201 Ebene. <6 />BDS Ltd is authorized and regulated by the Financial Services Authority Seychelles (FSA) under license number SD047, address: Tenancy 10, Marina House, Eden Island, Mahe. Payment transactions are managed by BDS Markets (Registration number: 143350).',
  },
  riskWarningBDSwissGroupV2:{
    i18nKey: 'riskWarningBDSwissGroupV2',
    defaults: 'Trading in CFDs is highly speculative and carries a high level of risk. It is possible to lose all of your invested capital. These products may not be suitable for everyone, and you should ensure that you fully understand the risks taking into consideration your investment objectives, level of experience, personal circumstances as well as personal resources. Speculate only with funds that you can afford to lose. Seek independent advice if necessary. Please refer to our Risk Disclosure. <3 />BDSwiss is a trading name of BDS Ltd. <5 />BDS Ltd is authorized and regulated by the Financial Services Authority Seychelles (FSA) under license number SD047, address: Tenancy 10, Marina House, Eden Island, Mahe. Payment transactions are managed by BDS Markets (Registration number: 143350).',
  },
  promoMaterials: {
    i18nKey: 'promoMaterials',
    defaults: 'Promo Materials',
  },
  promoMaterialsFilterPillsTitle: {
    i18nKey: 'propromoMaterialsFilterPillsTitlemoMaterials',
    defaults: 'Get marketing templates',
  },
  promoMaterialsSize: {
    i18nKey: 'promoMaterialsSize',
    defaults: 'All sizes',
  },
  promoMaterialsCampaigns: {
    i18nKey: 'promoMaterialsCampaigns',
    defaults: 'All campaigns',
  },
  promoMaterialsLanguages: {
    i18nKey: 'promoMaterialsLanguages',
    defaults: 'All languages',
  },
  promoMaterialsClearFilters: {
    i18nKey: 'promoMaterialsClearFilters',
    defaults: 'Clear Filters'
  },
  promoMaterialsBannerDesc: {
    i18nKey: 'promoMaterialsBannerDesc',
    defaults: 'To use the banner, select the required language and size from the dropdowns below and copy the code into your website or download it to your device.'
  },
  promoMaterialsDownloadAsset: {
    i18nKey: 'promoMaterialsDownloadAsset',
    defaults: 'Download {{asset}}',
  },
  promoMaterialsCopyCode: {
    i18nKey: 'promoMaterialsCopyCode',
    defaults: 'Copy Code',
  },
  promoMaterialsIbIdsFilter: {
    i18nKey: 'promoMaterialsIbIdsFilter',
    defaults: 'All IBs'
  },
  promoMaterialCodeCopied: {
    i18nKey: 'promoMaterialCodeCopied',
    defaults: 'Code copied!'
  },
  promoMaterialsLogoDesc: {
    i18nKey: 'promoMaterialsLogoDesc',
    defaults: 'To use the logo, select the required size from the dropdown below and download the image to your device.'
  },
  promoMaterialsBanners: {
    i18nKey: 'promoMaterialsBanners',
    defaults: 'Banners',
  },
  promoMaterialsLogos: {
    i18nKey: 'promoMaterialsLogos',
    defaults: 'Logos',
  },
  promoMaterialsVideos: {
    i18nKey: 'promoMaterialsVideos',
    defaults: 'Videos',
  },
  promoMaterialsPresentations: {
    i18nKey: 'promoMaterialsPresentations',
    defaults: 'Presentations',
  },
  promoMaterialsBrochures: {
    i18nKey: 'promoMaterialsBrochures',
    defaults: 'Brochures',
  },
  promoMaterialsLandingPages: {
    i18nKey: 'promoMaterialsLandingPages',
    defaults: 'Landing pages',
  },
  promoMaterialsNoAssets: {
    i18nKey: 'promoMaterialsNoAssets',
    defaults: 'No assets available',
  },
  promoMaterialsBanner: {
    i18nKey: 'promoMaterialsBanner',
    defaults: 'Banner',
  },
  promoMaterialsVideo: {
    i18nKey: 'promoMaterialsVideo',
    defaults: 'Video',
  },
  promoMaterialsBrochure: {
    i18nKey: 'promoMaterialsBrochure',
    defaults: 'Brochure',
  },
  promoMaterialsPresentation: {
    i18nKey: 'promoMaterialsPresentation',
    defaults: 'Presentation',
  },
  promoMaterialsLogo: {
    i18nKey: 'promoMaterialsLogo',
    defaults: 'Logo',
  },
  promoMaterialsLandingPage: {
    i18nKey: 'promoMaterialsLandingPage',
    defaults: 'Landing page',
  },
  promoMaterialsPresentationDesc: {
    i18nKey: 'promoMaterialsPresentationDesc',
    defaults: 'To use the presentation, select the required language from the dropdown below and click download below and copy the code into your website or download it to your device.'
  },
  promoMaterialsTopics: {
    i18nKey: 'promoMaterialsTopics',
    defaults: 'All topics',
  },
  promoMaterialsBrochureDesc: {
    i18nKey: 'promoMaterialsBrochureDesc',
    defaults: 'To use the brochure, select the required language from the dropdown below and copy the code into your website or download it to your device.'
  },
  promoMaterialsLandingPageDesc: {
    i18nKey: 'promoMaterialsLandingPageDesc',
    defaults: 'To use the landing page, select the required language from the dropdown below and share the link with your network.'
  },
  promoMaterialsVideoDesc: {
    i18nKey: 'promoMaterialsVideoDesc',
    defaults: 'To use the video, select the required language from the dropdown below and share the link with your network.'
  },
  fundingClientIdRemoteId: {
    i18nKey: 'fundingClientIdRemoteId',
    defaults: 'Funding of Client Account {{clientId}} - {{accountRemoteId}}'
  },
  submittedWireDetailsWarningAdditionalNew: {
    i18nKey: 'submittedWireDetailsWarningAdditionalNew',
    defaults: 'Please be advised that you need to add Funding of Client Account {{clientId}} - {{accountRemoteId}} to Field 72: \'Sender to Receiver Information\' of your bank transfer, otherwise the payment will be anonymous, and we may not be able to add the funds to your account. Please note that all information must be provided in English.'
  },
  submittedWireDetailsWarningAdditionalFormattedNew: {
    i18nKey: 'submittedWireDetailsWarningAdditionalFormattedNew',
    defaults: 'Please be advised that you need to add Funding of Client Account {{clientId}} - {{accountRemoteId}} to <0>Field 72: \'Sender to Receiver Information\'</0> of your bank transfer, otherwise the payment will be anonymous, and we may not be able to add the funds to your account. Please note that all information <1>must</1> be provided in English.'
  },
  commissionAgreementAcceptance: {
    i18nKey: 'commissionAgreementAcceptance',
    defaults: 'Commission Agreement Acceptance',
  },
  commissionAgreementInitial: {
    i18nKey: 'commissionAgreementInitial',
    defaults: 'By clicking the acknowledge button, you confirm that you have read and understood the <0>Affiliate Agreement</0>.',
  },
  acknowledge: {
    i18nKey: 'acknowledge',
    defaults: 'Acknowledge',
  },
  webTraderRedirectionPopUpTitle: {
    i18nKey: 'webTraderRedirectionPopUpTitle',
    defaults: 'Open WebTrader in',
  },
  webTraderRedirectionWebVersion: {
    i18nKey: 'webTraderRedirectionWebVersion',
    defaults: 'Web version',
  },
  webTraderRedirectionAppVersion: {
    i18nKey: 'webTraderRedirectionAppVersion',
    defaults: '{{company}} mobile app',
  },
  chipPayWithdrawalDescription1: {
    i18nKey: 'chipPayWithdrawalDescription1',
    defaults: 'The minimal amount of withdrawal is (100USDT, 0.01ETH, 0.003BTC).',
  },
  complaintForm: {
    i18nKey: 'complaintForm',
    defaults: 'Complaint Form',
  },
  years: {
    i18nKey: 'years',
    defaults: 'Years',
  },
  year: {
    i18nKey: 'year',
    defaults: 'Year',
  },
  months: {
    i18nKey: 'months',
    defaults: 'Months',
  },
  kindRegards: {
    i18nKey: 'kindRegards',
    defaults: 'Kind Regards,',
  },
  companyTeam: {
    i18nKey: 'companyTeam',
    defaults: '[{{company}} Team]',
  },
  weApologiseForInconvinienceCaused: {
    i18nKey: 'weApologiseForInconvinienceCaused',
    defaults: 'We apologise for any inconvenience caused and we are grateful for the trust you’ve placed in us.'
  },
  thankYouInAdvance: {
    i18nKey: 'thankYouInAdvance',
    defaults: 'Thank you in advance'
  },
  companyRetailClosureText: {
    i18nKey: 'companyRetailClosureText',
    defaults: 'This is to inform you that as of the 30th of May 2023, {{trademark}} ("the Company" or "{{company}}") will cease offering its services to Retail Clients. The Company will shift its operations to a model that services only <0> Eligible Counterparties.</0>',
  },
  companyRetailClosureSubtext: {
    i18nKey: 'companyRetailClosureSubtext',
    defaults: 'You are kindly instructed to close any open positions and withdraw any available balance by the 26th of May, 5 pm GMT+3. In the event that there are open positions in your trading account by the aforementioned date, the Company will proceed with closing them at the market price available at that time. The relevant Profit/Loss generated after the closing of the trades will be adjusted with your trading account balance.',
  },
  tradingVideos: {
    i18nKey: 'tradingVideos',
    defaults: 'Trading Videos'
  },
  tradingVideosCero01Title: {
    i18nKey: 'tradingVideosCero01Title',
    defaults: '1 - Introduction',
  },
  tradingVideosCero02Title: {
    i18nKey: 'tradingVideosCero02Title',
    defaults: '2 - Price and volume analysis according to Richard Wyckoff\'s theory',
  },
  tradingVideosCero03Title: {
    i18nKey: 'tradingVideosCero03Title',
    defaults: '3 - Major principles of Tape Reading',
  },
  tradingVideosCero04Title: {
    i18nKey: 'tradingVideosCero04Title',
    defaults: '4 - Secondary principles of Tape Reading',
  },
  tradingVideosCero05Title: {
    i18nKey: 'tradingVideosCero05Title',
    defaults: '5 - Accumulation phase',
  },
  tradingVideosCero06Title: {
    i18nKey: 'tradingVideosCero06Title',
    defaults: '6 - Uptrend phase',
  },
  tradingVideosCero07Title: {
    i18nKey: 'tradingVideosCero07Title',
    defaults: '7 - Distribution phase',
  },
  tradingVideosCero08Title: {
    i18nKey: 'tradingVideosCero08Title',
    defaults: '8 - Downward trend phase',
  },
  line: {
    i18nKey: 'line',
    defaults: 'LINE',
  },
  lineMessagingDescription: {
    i18nKey: 'lineMessagingDescription',
    defaults: 'Open the Friends tab in your LINE app, tap the add friends icon in the top right, select "QR code," and then scan this QR code.',
  },
  joinLineMessaging: {
    i18nKey: 'joinLineMessaging',
    defaults: 'Join our Line group chats'
  },
  emailVerificationSwitch: {
    i18nKey: 'emailVerificationSwitch',
    defaults: 'Switch to Email Verification'
  },
  phoneVerificationSwitch: {
    i18nKey: 'phoneVerificationSwitch',
    defaults: 'Switch to Phone Number Verification',
  },
  couldNotReceiveSecurityCode: {
    i18nKey: 'couldNotReceiveSecurityCode',
    defaults: 'In case you have not received the security code on your phone:',
  },
  resendEmail: {
    i18nKey: 'resendEmail',
    defaults: 'Resend Email',
  },
  usedEmail: {
    i18nKey: 'usedEmail',
    defaults: 'Email provided already used by another client.'
  },
  emailLoginVerificationRequestLimit: {
    i18nKey: 'emailLoginVerificationRequestLimit',
    defaults: 'Email verification request limit reached. Please contact support.'
  },
  unableToSendEmail: {
    i18nKey: 'unableToSendEmail',
    defaults: 'We were unable to send the verification code to the email provided.'
  },
  emailCode:{
    i18nKey: 'emailCode',
    defaults: 'Please enter the 6-digit code you will receive shortly by Email below'
  },
  couldNotReceiveEmailSecurityCode: {
    i18nKey: 'couldNotReceiveEmailSecurityCode',
    defaults: 'In case you have not received the security code by Email:',
  },
  enterEmailCode:{
    i18nKey: 'enterEmailCode',
    defaults: 'Enter 6-digit code'
  },
  notEnoughFreeMargin: {
    i18nKey: 'notEnoughFreeMargin',
    defaults: 'Not enough free margin to proceed with the request',
  },
  allAccountsTab: {
    i18nKey: 'allAccountsTab',
    defaults: 'All',
  },
  paymentDocuments: {
    i18nKey: 'paymentDocuments',
    defaults: 'Payment documents',
  },
  pofTitle: {
    i18nKey: 'pofTitle',
    defaults: 'Payment Documents',
  },
  refreshPageText: {
    i18nKey: 'refreshPageText',
    defaults: 'In case of issues please refresh the page.',
  },
  problemPersistContactText:{
    i18nKey: 'problemPersistContactText',
    defaults: 'If problems persist, please contact customer support.',
  },
  existingClients: {
    i18nKey: 'existingClients',
    defaults: 'Existing Client',
  },
  newClients: {
    i18nKey: 'newClients',
    defaults: 'New Client',
  },
  companyId: {
    i18nKey: 'companyId',
    defaults: '{{brandLabel}} ID',
  },
  useBDSwissIdOrEmail: {
    i18nKey: 'useBDSwissIdOrEmail',
    defaults: 'Use {{brandLabel}} ID or {{brandLabel}} Email Address by enabling the toggle'
  },
  termsAndCondtionsApply: {
    i18nKey: 'termsAndCondtionsApply',
    defaults: '<0>Terms & Conditions apply</0>',
  },
  thankYouForRegisteringWithUs: {
    i18nKey: 'thankYouForRegisteringWithUs',
    defaults: "Thank you for registering with us",
  },
  consentOfStoringInformation: {
    i18nKey: 'consentOfStoringInformation',
    defaults: 'By registering, you expressly consent to storing and using your provided information for our marketing initiatives.',
  },
  localDepositor: {
    i18nKey: 'localDepositor',
    defaults: 'Payment agent'
  },
  localDepositorCaption: {
    i18nKey: 'localDepositorCaption',
    defaults: 'Payment agent details',
  },
  localDepositorCaptionPendingRegistration: {
    i18nKey: 'localDepositorCaptionPendingRegistration',
    defaults: 'Payment agent registration',
  },
  pendingRegistration: {
    i18nKey: 'pendingRegistration',
    defaults: 'Pending registration'
  },
  ldTransfer: {
    i18nKey: 'ldTransfer',
    defaults: 'Payment Agent Transfer'
  },
  submitted: {
    i18nKey: 'submitted',
    defaults: 'Submitted'
  },
  terminated: {
    i18nKey: 'terminated',
    defaults: 'Terminated'
  },
  localDepositorSubmitted: {
    i18nKey: 'localDepositorSubmitted',
    defaults: 'Payment Agent Registration Submitted',
  },
  localDepositorRegistration: {
    i18nKey: 'localDepositorRegistration',
    defaults: 'Your personal Business Development Manager will contact you to assist you in finalising your Payment Agent registration.',
  },
  confirmation: {
    i18nKey: 'confirmation',
    defaults: 'Confirmation',
  },
  confirmationLocalDepositorTransfer: {
    i18nKey: 'confirmationLocalDepositorTransfer',
    defaults: 'Are you sure you want to proceed with this transfer? Payment Agent Deposit of {{transferFromCurrency}}{{transferAmount}} - from Payment Agent {{localDepositorId}} to Client {{localDepositorClient}}',
  },
  proceed: {
    i18nKey: 'proceed',
    defaults: 'Proceed',
  },
  selectAmountToTransfer: {
    i18nKey: 'selectAmountToTransfer',
    defaults: 'Select amount to transfer',
  },
  ldTransferIn: {
    i18nKey: 'ldTransferIn',
    defaults: 'Payment Agent Transfer in',
  },
  localDepositorEmail: {
    i18nKey: 'localDepositorEmail',
    defaults: 'Payment Agent Email: {{localDepositorEmail}}'
  },
  localDepositorPhone: {
    i18nKey: 'localDepositorPhone',
    defaults: 'Payment Agent Phone: {{localDepositorPhone}}'
  },
  localDepositorSubscriptions: {
    i18nKey: 'localDepositorSubscriptions',
    defaults: '{{localDepositorSubscriptionsCount}} subscribers'
  },
  totalSubscribers: {
    i18nKey: 'totalSubscribers',
    defaults: 'Total subscribers',
  },
  activeSubscribers: {
    i18nKey: 'activeSubscribers',
    defaults: 'Active subscribers',
  },
  ldTransfers: {
    i18nKey: 'ldTransfers',
    defaults: 'Payment Agent Transfers'
  },
  ldTransferSuccess: {
    i18nKey: 'ldTransferSuccess',
    defaults: 'Your Payment Agent transfer was completed successfully!',
  },
  ldTransferPending: {
    i18nKey: 'ldTransferPending',
    defaults: 'Your Payment Agent transfer is pending!',
  },
  ldDepositFromTo: {
    i18nKey: 'ldDepositFromTo',
    defaults: 'Payment Agent Deposit - from Payment Agent {{localDepositorId}} to {{clientTo}} {{clientId}}',
  },
  ldClientAcceptanceIntro: {
    i18nKey: 'ldClientAcceptanceIntro',
    defaults: 'By accepting the content of this form and continuing to use this service, for the purposes of this specific transaction and any other transaction to follow in thefuture, I hereby agree and acknowledge that:',
  },
  ldClientAcknowledgmentForm: {
    i18nKey: 'ldClientAcknowledgmentForm',
    defaults: 'Acknowledgment Form',
  },
  ldClientAcknowledgmentFormP1: {
    i18nKey: 'ldClientAcknowledgmentFormP1',
    defaults: 'The Company is hereby unequivocally and irrevocably authorized to transferthe withdrawal amount from MY wallet account to the wallet account of the Payment Agent under which I am subscribed from time to time.',
  },
  ldClientAcknowledgmentFormP2: {
    i18nKey: 'ldClientAcknowledgmentFormP2',
    defaults: 'The Company is solely responsible for transferring the withdrawal amount as aforesaid, i.e. from MY wallet account to the wallet account of the Payment Agent under  which I  am subscribed from time to time. From that point onwards it is MY exclusive responsibility to ensure that I receive   the withdrawal amount from the Payment Agent in the manner and form as agreed between MYSELF and the Payment Agent.',
  },
  ldClientAcknowledgmentFormP3: {
    i18nKey: 'ldClientAcknowledgmentFormP3',
    defaults: 'For deposits made through the Payment Agent, the Company is hereby unequivocally and irrevocably authorized to transfer the deposit amount from the wallet account of the Payment Agent under which I am subscribed from time to time to my wallet account. The Company is solely responsible for relying on the information and acting on the instructions of the Payment Agent under which I am subscribed.',
  },
  ldClientAcknowledgmentFormP4: {
    i18nKey: 'ldClientAcknowledgmentFormP4',
    defaults: 'The Company is not in any way connected in any arrangement between MYSELF and the Payment Agent.',
  },
  ldClientAcknowledgmentFormP5: {
    i18nKey: 'ldClientAcknowledgmentFormP5',
    defaults: 'The currency conversion rates as well as any other fees that the Payment Agent may charge me for using this service from time to time are not offered or charged by the Company.',
  },
  ldClientAcknowledgmentFormP6: {
    i18nKey: 'ldClientAcknowledgmentFormP6',
    defaults: 'Except to the extent herein stated, the Company is not and will not be held liable for any direct or indirect, or consequential loss or damage including but not limited to loss of profits, loss of sales, or loss of revenue.',
  },
  ldClientAcknowledgmentFormP7: {
    i18nKey: 'ldClientAcknowledgmentFormP7',
    defaults: 'The Company will deem the transactions carried out through this service to be genuine and made in good faith for the purposes of accommodating the funding of MY wallet account.',
  },
  ldClientAcknowledgmentAcceptance: {
    i18nKey: 'ldClientAcknowledgmentAcceptance',
    defaults: 'Accepted Payment Agent Acknowledgement Terms and conditions at {{ldAcknowledgementDate}}',
  },
  withdrawalViaNonLdMethods: {
    i18nKey: 'withdrawalViaNonLdMethods',
    defaults: 'You will first need to withdraw your funds using "Transfer to Payment Agent" payment method via your Payment Agent Client Wallet. Total Amount deposited via Payment Agent, <0>{{ldAmount}}</0>',
  },
  withdrawalOptionsClearLdWalletFirst: {
    i18nKey: 'withdrawalOptionsClearLdWalletFirst',
    defaults: 'As per the company’s withdrawal policy, you must withdraw your funds using the same method you used to deposit (order to follow: transfer to Payment Agent - Card Deposit - e-wallet deposit - etc).',
  },
  lpWidgetEmailIsUsed: {
    i18nKey: 'lpWidgetEmailIsUsed',
    defaults: 'Email already exists, please complete the Existing Client registration form.',
  },
  lpPriorityServiceOpeningTitle: {
    i18nKey: 'lpPriorityServiceOpeningTitle',
    defaults: 'Elevate Your Trading Experience With Priority Service',
  },
  lpPriorityServiceOpeningSubtitle: {
    i18nKey: 'lpPriorityServiceOpeningSubtitle',
    defaults: 'Providing premium benefits and expedited KYC processing to our dedicated clients.',
  },
  lpPriorityServiceOpeningNote: {
    i18nKey: 'lpPriorityServiceOpeningNote',
    defaults: 'Want to know more? Our dedicated team will be more than happy to guide you.',
  },
  lpPriorityServiceMainTitle: {
    i18nKey: 'lpPriorityServiceMainTitle',
    defaults: 'Excellence Through Collaboration & Shared Goals',
  },
  lpPriorityServiceMainItemTitle1: {
    i18nKey: 'lpPriorityServiceMainItemTitle1',
    defaults: 'Rapid Response Time',
  },
  lpPriorityServiceMainItemContent1: {
    i18nKey: 'lpPriorityServiceMainItemContent1',
    defaults: 'All your customer support requests will be handled with high priority, ensuring quick resolutions and less downtime. Elevate your support experience with immediate responses.',
  },
  lpPriorityServiceMainItemTitle2: {
    i18nKey: 'lpPriorityServiceMainItemTitle2',
    defaults: 'Speedy Verification',
  },
  lpPriorityServiceMainItemContent2: {
    i18nKey: 'lpPriorityServiceMainItemContent2',
    defaults: 'Experience an expedited Know Your Customer (KYC) process and account verification, reducing the time you need to wait. We’re eliminating hoops and hurdles, fast-tracking your investment journey.',
  },
  lpPriorityServiceMainItemTitle3: {
    i18nKey: 'lpPriorityServiceMainItemTitle3',
    defaults: 'Priority Account Management',
  },
  lpPriorityServiceMainItemContent3: {
    i18nKey: 'lpPriorityServiceMainItemContent3',
    defaults: 'With Priority Service, you are always at the top of our list. Your Account Manager will prioritise your requests and emails, providing a seamless, uninterrupted service, ensuring your needs are attended to promptly.',
  },
  lpPriorityServiceMainItemTitle4: {
    i18nKey: 'lpPriorityServiceMainItemTitle4',
    defaults: 'Seamless Transactions',
  },
  lpPriorityServiceMainItemContent4: {
    i18nKey: 'lpPriorityServiceMainItemContent4',
    defaults: 'Bypass the line and experience lightning-fast deposits and withdrawals, all with the necessary documentation in order, and best of all, enjoy the benefit of zero withdrawal fees.',
  },
  lpPriorityServiceMainItemContent5: {
    i18nKey: 'lpPriorityServiceMainItemContent5',
    defaults: '*To be eligible for this service, clients should deposit $5.000',
  },
  requestCallbackSuccess: {
    i18nKey: 'requestCallbackSuccess',
    defaults: 'You have successfully requested a callback',
  },
  lpPriorityServiceFooter: {
    i18nKey: 'lpPriorityServiceFooter',
    defaults: 'Start your exclusive journey with BDSwiss Priority Service - designed for those who seek faster, smarter, and more efficient trading.',
  },
  isLessThan8CharsMoreThan15:{
    i18nKey: 'isLessThan8CharsMoreThan15',
    defaults: 'Password length has to be between 8 to 15 characters.',
  },
  noSpecialCharacter:{
    i18nKey: 'noSpecialCharacter',
    defaults: 'Password must contain at least one special character.',
  },
  noAnyLowerLetter:{
    i18nKey: 'noAnyLowerLetter',
    defaults: 'Password must contain at least one lower case letter.',
  },
  noAnyUpperLetter:{
    i18nKey: 'noAnyUpperLetter',
    defaults: 'Password must contain at least one upper case letter.',
  },
  upTo2000Leverage: {
    i18nKey: 'upTo2000Leverage',
    defaults: 'Up to 1:2000 Leverage',
  },
  centcentCategoryDesc: {
    i18nKey: 'centcentCategoryDesc',
    defaults: 'Experience easy and flexible trading with our Cent Account for MT4 - the perfect blend of simplicity and efficiency.',
  },
  centMt5centCategoryDesc: {
    i18nKey: 'centMt5centCategoryDesc',
    defaults: 'Amplify your trading performance with our streamlined Cent Account on the MT5 platform.',
  },
  centMt5centDemoDesc: {
    i18nKey: 'centMt5centDemoDesc',
    defaults: 'Amplify your trading performance with our streamlined Cent Account on the MT5 platform.',
  },
  passwordStepMt5:{
    i18nKey: 'passwordStepMt5',
    defaults: 'MT5 accounts need to meet the MetaQuotes minimum password requirements. Click <0>here</0> to change the password.',
  },
  passwordVerification:{
    i18nKey: 'passwordVerification',
    defaults: 'Password Verification',
  },
  proceedOpeningAdditionalAccount:{
    i18nKey: 'proceedOpeningAdditionalAccount',
    defaults: 'To proceed with opening an additional account, click <0>here</0>.',
  },
  newPasswordDashboardAccounts:{
    i18nKey: 'newPasswordDashboardAccounts',
    defaults: 'New password should be used for logging to your Dashboard and MetaTrader Platform account(s).',
  },
  pendingKYCDocsWithActions: {
    i18nKey: 'pendingKYCDocsWithActions',
    defaults: 'Your account has pending action(s): {{missingActions}}. Please proceed with uploading the necessary documents.',
  },
  paymentVerificationAction: {
    i18nKey: 'paymentVerificationAction',
    defaults: 'Payment Verification',
  },
  passwordExplanationField:{
    i18nKey: 'passwordExplanationField',
    defaults: 'Password minimum length is at least 8 characters and the password must contain at least 1 character in upper and lower case, 1 digit and at least 1 special character.',
  },
  leverageType: {
    i18nKey: 'leverageType',
    defaults: 'Leverage Type'
  },
  defaultLeverage: {
    i18nKey: 'defaultLeverage',
    defaults: 'Default Leverage'
  },
  dynamicLeverage: {
    i18nKey: 'dynamicLeverage',
    defaults: 'Dynamic Leverage'
  },
  leverageTypeTooltip: {
    i18nKey: 'leverageTypeTooltip',
    defaults: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
  },
  selectLeverageType: {
    i18nKey: 'selectLeverageType',
    defaults: 'Select leverage type'
  },
  defaultLeverageWithNumber: {
    i18nKey: 'defaultLeverageWithNumber',
    defaults: 'Default (up to 1:400)'
  },
  dynamicLeverageWithNumber: {
    i18nKey: 'dynamicLeverageWithNumber',
    defaults: 'Dynamic (up to 1:2000)'
  },
  selectLeverageAccountAdd: {
    i18nKey: 'selectLeverageAccountAdd',
    defaults: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. '
  },
  default: {
    i18nKey: 'default',
    defaults: 'Default'
  },
  dynamic: {
    i18nKey: 'dynamic',
    defaults: 'Dynamic'
  },
  defaultDescription: {
    i18nKey: 'defaultDescription',
    defaults: 'Leverage ratio 1:400 offers efficient capital utilisation, while allowing you to manage your risk and market exposure responsibly to safeguard your investments.'
  },
  dynamicDescription: {
    i18nKey: 'dynamicDescription',
    defaults: 'Leverage ratio 1:2000 amplifies your trades significantly. Depending on the trading volume, the leverage will vary. It is important to manage your risk and market exposure cautiously for effective trading.'
  },
  defaultLeverageMax: {
    i18nKey: 'defaultLeverageMax',
    defaults: '1:400'
  },
  dynamicLeverageMax: {
    i18nKey: 'dynamicLeverageMax',
    defaults: 'up to 1:2000'
  },
  terminationModalMessage: {
    i18nKey: 'terminationModalMessage',
    defaults: 'As of {{terminationDate}}, we will transition all accounts under the {{brandLabel}} label to {{migratedTradingName}}. For existing clients, your current settings, funds, and trades will remain intact throughout this transition. You can access your trading account(s) under the {{migratedBrandLabel}} brand. Please ensure that you bookmark the below links for future access:<0/>Dashboard link - <1>{{dashboardLink}}</1> <2/>Website link - <3>{{websiteLink}}</3>',
  },
  okButton: {
    i18nKey: 'okButton',
    defaults: 'Ok',
  },
  oneToLeverage: {
    i18nKey: 'oneToLeverage',
    defaults: '1:{{leverage}}',
  },
  partnerEntityNotice: {
    i18nKey: 'partnerEntityNotice',
    defaults: 'Based on your selection, you will register for an account with {{brandLabel}} which is authorized and regulated by the {{regulatorLabel}} with license number {{licenceNumber}}. By clicking "Accept" you will continue with your registration.',
  },
  trade: {
    i18nKey: 'trade',
    defaults: 'Trade',
  },
  darkTheme: {
    i18nKey: 'darkTheme',
    defaults: 'Dark theme',
  },
  profileInformation: {
    i18nKey: 'profileInformation',
    defaults: '{{company}} Global ID: {{clientId}}',
  },
  newAccount: {
    i18nKey: 'newAccount',
    defaults: 'New account',
  },
  readOnlyLowerCase:{
    i18nKey: 'readOnlyLowerCase',
    defaults: 'Read-only'
  },
  educationalVideosNew: {
    i18nKey: 'educationalVideosNew',
    defaults: 'Educational videos',
  },
  changePasswordLowerCase: {
    i18nKey: 'changePasswordLowerCase',
    defaults: 'Change password'
  },
  passwordExplanationFieldNew:{
    i18nKey: 'passwordExplanationFieldNew',
    defaults: 'At least 8 characters, 1 upper and low case letters, 1 digit and 1 special character',
  },
  appendixDefault: {
    i18nKey: 'appendixDefault',
    defaults: 'Appendix Default',
  },
  appendix: {
    i18nKey: 'appendix',
    defaults: 'Appendix ${{appendixNumber}}',// eslint-disable-line no-template-curly-in-string
  },
  zulu: {
    i18nKey: 'zulu',
    defaults: 'Zulu'
  },
  forexMt5zulu_vipDesc:{
    i18nKey: 'forexMt5zulu_vipDesc',
    defaults: 'Investing made easy with Copy Trading! Go long or short?Join the most transparent social trading. Copy Top Performing Leaders easily and reach your investment goals.'
  },
  forexMt5zulu_zeroDesc:{
    i18nKey: 'forexMt5zulu_zeroDesc',
    defaults: 'Investing made easy with Copy Trading! Go long or short?Join the most transparent social trading. Copy Top Performing Leaders easily and reach your investment goals'
  },
  forexMt5zulu_centDesc:{
    i18nKey: 'forexMt5zulu_centDesc',
    defaults: 'Investing made easy with Copy Trading! Go long or short?Join the most transparent social trading. Copy Top Performing Leaders easily and reach your investment goals'
  },
  forexMt5zulu_classicDesc:{
    i18nKey: 'forexMt5zulu_classicDesc',
    defaults: 'Investing made easy with Copy Trading! Go long or short?Join the most transparent social trading. Copy Top Performing Leaders easily and reach your investment goals'
  },
  copytradeNow:{
    i18nKey: 'copytradeNow',
    defaults: 'Copytrade Now'
  },
  zuluAccountWelcome:{
    i18nKey: 'zuluAccountWelcome',
    defaults: 'Please note that your Copy Account can only be activated after the minimum required balance amount is reached'
  },
  zuluCopyTradingAccountOverview:{
    i18nKey: 'zuluCopyTradingAccountOverview',
    defaults: 'Please note that your Zulu VIP account can only be activated after the balance of USD 125.00 is reached. Until then, you won\'t be able to copytrade'
  },
  pendingZuluLinkedStatus:{
    i18nKey: 'pendingZuluLinkedStatus',
    defaults: 'Pending'
  },
  failedZuluLinkedStatus:{
    i18nKey: 'failedZuluLinkedStatus',
    defaults: 'Not Activated'
  },
  activeZuluLinkedStatus:{
    i18nKey: 'activeZuluLinkedStatus',
    defaults: 'Live'
  },
  failedZuluLinkedStatusTooltip:{
    i18nKey: 'failedZuluLinkedStatusTooltip',
    defaults: 'Please note that your Zulu VIP account can only be activated after the balance of USD 125.00 is reached. Until then, you won\'t be able to copytrade'
  },
  goToZuluTrade:{
    i18nKey: 'goToZuluTrade',
    defaults: 'Go to ZuluTrade'
  },
  flow1:{
    i18nKey: 'flow1',
    defaults: 'Download the BDS social app. Create your personal account. Confirm your account via email inbox link'
  },
  flow2:{
    i18nKey: 'flow2',
    defaults: 'Receive ZuluTrade.com account credentials'
  },
  flow3:{
    i18nKey: 'flow3',
    defaults: 'Click "Go to ZuluTrade" to log in'
  },
  flow4:{
    i18nKey: 'flow4',
    defaults: 'Follow a leader and start Copytrading'
  },
  notActivatedZuluAccountTooltip:{
    i18nKey: 'notActivatedZuluAccountTooltip',
    defaults: 'Please note that your <0>{{zuluAccountType}}</0> account can only be activated after the balance of USD <1>{{activationFtd}}</1> or equivalent is reached. Until then, you won\'t be able to copytrade'
  },
  copyAccountWarning:{
    i18nKey: 'copyAccountWarning',
    defaults: 'Please note that your Copy account can only be activated after the minimum required balance amount is reached'
  },
  somethingWrongErrorZulu: {
    i18nKey: 'somethingWrongErrorZulu',
    defaults: "Alternatively, you can manually log into <0>Zulutrade.com</0> with the credentials you have received via email"
  },
  transferErrorZulu: {
    i18nKey: 'transferErrorZulu',
    defaults: 'You need to transfer at least {{minDeposit}} to your {{zuluAccount}} in order to activate it'
  },
  copyTradingDialogTitle: {
    i18nKey: 'copyTradingDialogTitle',
    defaults: 'Copy Trading Available!'
  },
  copyTradingDialogSubTitle: {
    i18nKey: 'copyTradingDialogSubTitle',
    defaults: 'Explore our new Copy accounts for copy trading or become a Strategy Provider'
  },
  startCopyTrading: {
    i18nKey: 'startCopyTrading',
    defaults: 'Start copytrading'
  },
  openAccountCopyTrading: {
    i18nKey: 'openAccountCopyTrading',
    defaults: 'Open account'
  },
  minimumPartnerId: {
    i18nKey: 'minimumPartnerId',
    defaults: 'Partner ID should be at least 4 digits long',
  },
  maximumPartnerId: {
    i18nKey: 'maximumPartnerId',
    defaults: 'Partner ID should not exceed 10 digits in length'
  },
  registrationWidgetLPErrorLogin: {
    i18nKey: 'ErrorRegistrationWidgetLP',
    defaults: 'The account you are attempting to use does not exist. Please verify that you are entering the correct BDSwiss ID or email address.',
  },
  technicalViews:{
    i18nKey: 'technicalViews',
    defaults:'Technical Views'
  },
  marketBuzz:{
    i18nKey:'marketBuzz',
    defaults: 'Market Buzz'
  },
  newsdesk:{
    i18nKey: 'newsdesk',
    defaults:'Newsdesk'
  },
  technicalInsight:{
    i18nKey:'technicalInsight',
    defaults: 'Technical Insight'
  },
  scanQRCode12Pay:{
    i18nKey: 'scanQRCode12Pay',
    defaults: 'Scan the QR Code via a supported app to complete the transaction',
  },
  currentPasswordLowcase: {
    i18nKey: 'currentPasswordLowcase',
    defaults: 'Current password',
  },
  passwordConfirmationLowCase: {
    i18nKey: 'passwordConfirmationLowCase',
    defaults: 'Password confirmation',
  },
  copyTradingNotAvailableonDynamic:{
    i18nKey: 'copyTradingNotAvailableonDynamic',
    defaults: 'Copy Trading is not available for Dynamic Leverage',
  },
  copyTradingNotAvailableonDynamicAddAccount:{
    i18nKey: 'copyTradingNotAvailableonDynamicAddAccount',
    defaults: 'Dynamic (Copy Trading not available)',
  },
  cryptoCheckbox:{
    i18nKey: 'cryptoCheckbox',
    defaults: 'By checking this box, I declare I am the rightful owner of the wallet that will be used for depositing funds.',
  },
  dashboard:{
    i18nKey: 'dashboard',
    defaults: 'Dashboard',
  },
  client:{
    i18nKey: 'client',
    defaults: 'client',
  },
  bannerTradingAcademyTitle:{
    i18nKey: 'bannerTradingAcademyTitle',
    defaults: 'Trading academy',
  },
  bannerTradingAcademySubtitle:{
    i18nKey: 'bannerTradingAcademySubtitle',
    defaults: 'Learn strategies, analysis, and risk management',
  },
  bannerResearchAnalysisTitle:{
    i18nKey: 'bannerResearchAnalysisTitle',
    defaults: 'Research & Analysis',
  },
  bannerResearchAnalysisSubtitle:{
    i18nKey: 'bannerResearchAnalysisSubtitle',
    defaults: 'Expert insights for informed trading',
  },
  bannerEducationhalVideosSubtitle:{
    i18nKey: 'bannerEducationhalVideosSubtitle',
    defaults: 'Explore trading fundamentals to advanced techniques.',
  },
  bannerTelegramTitle:{
    i18nKey: 'bannerTelegramTitle',
    defaults: 'Telegram channel',
  },
  bannerTelegramSubtitle:{
    i18nKey: 'bannerTelegramSubtitle',
    defaults: 'Market news, trading alerts and opportunities',
  },
  bannerTradingToolsSubtitle:{
    i18nKey: 'bannerTradingToolsSubtitle',
    defaults: 'Power your trades with our cutting-edge trading tools',
  },
  marketAnalysis:{
    i18nKey: 'marketAnalysis',
    defaults: 'Market analysis',
  },
  seeAll:{
    i18nKey: 'seeAll',
    defaults: 'See all'
  },
  recentTransactions: {
    i18nKey: 'recentTransactions',
    defaults: 'Recent transactions'
  },
  depositFunds: {
    i18nKey: 'depositFunds',
    defaults: 'Deposit funds'
  },
  partnershipProgram:{
    i18nKey: 'partnershipProgram',
    defaults: 'Boost your earnings with BDSwiss Partnership program:'
  },
  moreInformation:{
    i18nKey: 'moreInformation',
    defaults: 'For more information, please request a call and our team will contact you soon.'
  },
  join:{
    i18nKey: 'join',
    defaults: 'Join'
  },
  requestACallback:{
    i18nKey: 'requestACallback',
    defaults: 'Request a callback'
  },
  becomePartnerInfo1:{
    i18nKey: 'becomePartnerInfo1',
    defaults: 'Up to $28 per lot'
  },
  becomePartnerInfo2:{
    i18nKey: 'becomePartnerInfo2',
    defaults: 'Sub-partner commissions'
  },
  becomePartnerInfo3:{
    i18nKey: 'becomePartnerInfo3',
    defaults: 'Daily payouts'
  },
  becomePartnerInfo4:{
    i18nKey: 'becomePartnerInfo4',
    defaults: 'No limits on commission'
  },
  partnerlinks:{
    i18nKey: 'partnerlinks',
    defaults: 'Partner links'
  },
  introduceClientLink:{
    i18nKey: 'introduceClientLink',
    defaults: 'Introduce Client link'
  },
  partnerId:{
    i18nKey: 'partnerId',
    defaults: 'Partner ID'
  },
  forexMt5copy_vipDesc:{
    i18nKey: 'forexMt5copy_vipDesc',
    defaults: 'Copy VIP merges premium features with the ability to follow experienced traders. It increases efficiency and execution.'
  },
  forexMt5copy_zeroDesc:{
    i18nKey: 'forexMt5copy_zeroDesc',
    defaults: 'Great for high-frequency traders or scalpers, Zero offers a cost-effective trading option. Combine that with the power of copytrading and you have more options and control.'
  },
  forexMt5copy_centDesc:{
    i18nKey: 'forexMt5copy_centDesc',
    defaults: 'The Cent account is designed for beginners and those who wish to trade with smaller amounts. Couple that with copying trades from experienced traders and you have the ideal starter account.'
  },
  forexMt5copy_classicDesc:{
    i18nKey: 'forexMt5copy_classicDesc',
    defaults: 'Classic is our most popular account, suitable for all types of traders. Pair it with some copy-trading abilities and it becomes dynamic and multi-faceted.'
  },
  forexMt5copy_vip_spDesc:{
    i18nKey: 'forexMt5copy_vip_spDesc',
    defaults: 'Copy VIP merges premium features with the ability to follow experienced traders. It increases efficiency and execution.'
  },
  forexMt5copy_zero_spDesc:{
    i18nKey: 'forexMt5copy_zero_spDesc',
    defaults: 'Great for high-frequency traders or scalpers, Zero offers a cost-effective trading option. Combine that with the power of copytrading and you have more options and control.'
  },
  forexMt5copy_cent_spDesc:{
    i18nKey: 'forexMt5copy_cent_spDesc',
    defaults: 'The Cent account is designed for beginners and those who wish to trade with smaller amounts. Couple that with copying trades from experienced traders and you have the ideal starter account.'
  },
  forexMt5copy_classic_spDesc:{
    i18nKey: 'forexMt5copy_classic_spDesc',
    defaults: 'Classic is our most popular account, suitable for all types of traders. Pair it with some copy-trading abilities and it becomes dynamic and multi-faceted.'
  },
  registration: {
    i18nKey: 'registration',
    defaults: 'Registration'
  },
  registrationP1: {
    i18nKey: 'registrationP1',
    defaults: 'Download the BDS social app'
  },
  registrationP2: {
    i18nKey: 'registrationP2',
    defaults: 'Create your personal account'
  },
  registrationP3: {
    i18nKey: 'registrationP3',
    defaults: 'Confirm your account via email inbox link'
  },
  settingUpServer: {
    i18nKey: 'settingUpServer',
    defaults: 'Setting-up a server'
  },
  settingUpServerP1: {
    i18nKey: 'settingUpServerP1',
    defaults: 'Click on Settings on Accounts page'
  },
  settingUpServerP2: {
    i18nKey: 'settingUpServerP2',
    defaults: 'Go to Account information in Settings and select the BDSwiss trading server'
  },
  linkAccount: {
    i18nKey: 'linkAccount',
    defaults: 'Link BDSwiss account'
  },
  linkAccountP1: {
    i18nKey: 'linkAccountP1',
    defaults: 'Enter your BDSwiss trading account number'
  },
  linkAccountP2: {
    i18nKey: 'linkAccountP2',
    defaults: 'Provide the password for your trading account'
  },
  linkAccountP3: {
    i18nKey: 'linkAccountP3',
    defaults: 'Select the option indicating that you are a copier or a strategy provider'
  },
  startCopyTradingP1: {
    i18nKey: 'startCopyTradingP1',
    defaults: 'Now you will be able to start copy trading through the BDS Social app'
  },
  startCopyTradingP2: {
    i18nKey: 'startCopyTradingP2',
    defaults: 'Explore traders to copy based on your preferences and investment goals or trade to benefit your followers'
  },
  scanDownloadApp: {
    i18nKey: 'scanDownloadApp',
    defaults: 'Scan to download the app in {{platform}}:'
  },
  clientTypesPartner: {
    i18nKey: 'clientTypesPartner',
    defaults: 'Partner',
  },
  clientTypesPartnerCorporate: {
    i18nKey: 'clientTypesPartnerCorporate',
    defaults: 'Partner Corporate',
  },
  inactivePartner: {
    i18nKey: 'inactivePartner',
    defaults: 'It looks like you already have an inactive Partner account. If you would like to reactivate your existing Partner account or create a new one, please contact Partner Support.'
  },
  callbackRequested: {
    i18nKey: 'callbackRequested',
    defaults: 'Callback requested'
  },
  partner: {
    i18nKey: 'partner',
    defaults: 'Partner'
  },
  partnerZoneLowerCase: {
    i18nKey: 'partnerZoneLowerCase',
    defaults: 'Partner zone'
  },
  noItemsToDisplay: {
    i18nKey: 'noItemsToDisplay',
    defaults: 'No items to display',
  },
  systemNotificationSuccess: {
    i18nKey: 'systemNotificationSuccess',
    defaults: 'Success',
  },
  systemNotificationError: {
    i18nKey: 'systemNotificationError',
    defaults: 'Error',
  },
  systemNotificationWarning: {
    i18nKey: 'systemNotificationWarning',
    defaults: 'Warning',
  },
  systemNotificationInfo: {
    i18nKey: 'systemNotificationInfo',
    defaults: 'Info',
  },
  equivalentCurrenciesAddAccount: {
    i18nKey: 'equivalentCurrenciesAddAccount',
    defaults: '* {{equivalentCurrencies}} equivalent amount ',
  },
}
