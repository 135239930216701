import React, {Component} from 'react'
import withStyles from '@mui/styles/withStyles'
import {flowRight as compose, map, includes, keys, get, find, size, filter, isEmpty, replace, isNil, toUpper} from 'lodash'
import {Grid, FormControl, Typography, InputLabel, Select, TextField, IconButton, Input, FormControlLabel, Checkbox, Chip,
  MenuItem, Button, FormLabel, FormGroup, ListItemText, FormHelperText} from '@mui/material'
import AppContext from '../Common/contexts/AppContext'
import {validateDate} from '../../common/utils/validations'
import {corporateStepCategories, corporateSteps, countries, corporateFields, beneficiaryTypeOptions} from '@bdswiss/common-enums'
import CountriesSelect from '../Common/CountriesSelect'
import CloseIcon from '@mui/icons-material/HighlightOffOutlined'
import {safeParseJSON} from '../../common/utils'
import HelpOutline from '@mui/icons-material/HelpOutline'
import classNames from 'classnames'
import {Trans, withNamespaces} from 'react-i18next'
import messages from '../../assets/messages'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import moment from 'moment'
import SelectDocument from '../Settings/Documents/SelectDocument'
import Images from '../Common/Images'
import GetAppIcon from '@mui/icons-material/GetApp'
import config from '../../config'
import CustomTooltip from '../Common/CustomTooltip'
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3'
import * as dateFnsLocales from 'date-fns/locale'
import {fnsLocales} from '../../common/utils/uioptions'

const styles = theme => ({
  firstItemHide: {
    display: 'none',
  },
  phone: {
    maxWidth: '50%',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '75%',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '62%',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset',
    }
  },
  textBlue: {
    color: theme.palette.primary.main,
  },
  formControl: {
    [theme.breakpoints.down('md')]: {
      marginTop: '20px !important',
    },
  },
  formControlTooltip: {
    width: '95%'
  },
  helpIcon: {
    marginTop: 25,
    cursor: 'pointer'
  },
  button: {
    paddingLeft: 0,
    textAlign: 'left',
    '&:hover': {backgroundColor:'transparent'},
  },
  addIcon: {
    marginRight: 5
  },
  displayInline: {
    display: 'inline-block',
  },
  hiddenCountry: {
    width: '55%',
    [theme.breakpoints.down('md')]: {
      width: '90%',
    }
  },
  hiddenButton: {
    top: 20,
    padding: 5
  },
  longLabel: {
    paddingTop: 25,
    [theme.breakpoints.down('md')]: {
      paddingTop: 55,
    }
  },
  file: {
    marginTop: 25
  },
  h3: {
    marginTop: 15,
    marginBottom: 5
  },
  error: {
    color: theme.palette.error.main,
  },
  tooltipDiv: {
    display: 'flex',
  },
  tooltipHelpIcon: {
    marginLeft: 8,
    marginTop: 8,
    [theme.breakpoints.down('md')]: {
      marginLeft: 4,
      marginTop: 4,
    }
  },
  textHelp: {
    color: theme.palette.grey[400],
    '&:hover':{
      color: theme.palette.primary.main,
    },
  },
  tooltipText:{
    color: theme.palette.secondary.light,
    fontSize:14
  },
  lineHeight: {
    lineHeight: '1.8rem'
  },
  nativeSelect: {
    minHeight: 20
  },
  fullWidth:{
    width: '100%'
  },
  textCenter:{
    textAlign: 'center'
  }
})

export const getStepQuestions = (viewer, activeStep, setState, reset, owner, documents)  => {
  const stepActive = find(corporateSteps, (corporateStep) => corporateStep.rank === activeStep)
  const fields = (!reset && safeParseJSON(get(viewer.corporateDetails, stepActive.key))) || {}
  const form = {}
  const hidden = []
  const selectedCountry = {}
  const hasOwner = !isNil(owner)
  if (hasOwner) setState({showOwnerForm: true, selectedOwner: owner,
    disabledFields: fields[owner].beneficiaryType === beneficiaryTypeOptions.company.value ?  corporateFields.relationType.key : '',
  })
  const questions = filter(corporateFields, (question) => {
    const steps = map(question.steps, 'key')
    const include = includes(steps, corporateSteps[stepActive.key].key)
    const questionOwner = fields && hasOwner && fields[owner][question.key]
    const memberField = question.memberField && question.memberField(activeStep)
    if (memberField) {
      const country = find(countries, (country) => country.key === get(viewer ,'address.country'))
      if (question.isCountry) {
        form[question.key] = toUpper(country.key)
        selectedCountry[question.key] = country
      } else {
        form[question.key] = viewer[memberField]
      }
    } else if (include) {
      const clientDocument = documents && find(documents, (clientDoc) => question.key === clientDoc.type)
      form[question.key] = clientDocument || questionOwner || fields[question.key] || ''
      if (form[question.key] && question.isCountry) {
        if (question.isPrefix)
          selectedCountry[question.key] = find(question.options, (country) => country.callingCode === replace(form[question.key], '+', '') || includes(country.callingCodes, replace(form[question.key], '+', '')))
        else
          selectedCountry[question.key] = find(question.options, (country) => country.value === form[question.key])
      }
      if (question.defaultChecked && (!questionOwner && !fields[question.key])) form[question.key] = 'true'
      if (question.multiselect && !questionOwner) form[question.key] = []
      if (question.isHidden && form[question.key]) hidden.push(question.key)
      if (question.multipleCheckbox) form[question.key] = fields[question.key] || []
    }
    return include
  })
  setState({form, questions, selectedCountry, initialForm: form, ownersAndDirectors: fields, hidden,
    formSubmitted: !find(questions, (question) => isEmpty(form[question.value]) && (documents || (!documents && question.required(form, undefined, activeStep))))})
}

class Questions extends Component {
  static contextType = AppContext

  checkValues() {
    const {form, hidden, setOuterState, step} = this.props
    filter(keys(form), (question) => {
      if (corporateFields[question] && !corporateFields[question].required(form, undefined, step, hidden) && !corporateFields[question].relevant(form, undefined, step, hidden)) {
        setOuterState('form', question, '')
      }
    })
  }

  renderSelectComponent(q, native, required) {
    const {form, classes, errors, handleChange, setOuterState, disabled} = this.props
    const {locale} = this.context
    return (native) ? <React.Fragment>
      <InputLabel htmlFor={`${q.value}-simple`} error={errors[q.key]} required={required}>
        {q.localization.t(locale)}
      </InputLabel>
      <Select
        variant="standard"
        native
        error={errors[q.key]}
        id={`${q.value}-simple`}
        name={q.value}
        value={form[q.key] || ''}
        onChange={(e) => {
          handleChange(q.key, e.target.value, q.alert)
          q.alert && q.alert(e.target.value) && setOuterState('alert', q.key, true)
        }}
        disabled={!!disabled}
        classes={{select: classes.nativeSelect}}> <option value='' key='' className={classes.firstItemHide}></option>
        {map(q.options, (option) =>
          (!option.disable) && (!option.hidden) && <option key={option.key} value={option.key}>
            {q.options[option.key].label}
          </option>
        )}
      </Select>
    </React.Fragment>: <Grid container>
      <Grid item xs={q.tooltip ? 11 : 12}><TextField
        variant="standard"
        label={q.localization.t(locale)}
        select
        error={errors[q.key]}
        id={`${q.value}-simple`}
        name={q.value}
        value={form[q.key] || ''}
        onChange={(e) => {
          handleChange(q.key, e.target.value, q.alert)
          q.alert && q.alert(e.target.value) && setOuterState('alert', q.key, true)
        }}
        disabled={!!disabled}
        InputProps={{
          className: q.longLabel ? classes.longLabel : classes.formControl,
        }}
        className={classes.fullWidth}
        required={required}>
        {
          map(q.options, (option) => (
            <MenuItem key={option.key} value={option.key} >
              {q.options[option.key].label}
            </MenuItem>
          ))
        }
      </TextField></Grid>
      {q.tooltip && <Grid item xs={1} className={classes.textCenter}><CustomTooltip
        title={<Typography variant='caption' className={classes.tooltipText}>{q.tooltip.localization.t(locale)}</Typography>}
        placement={'top'}
      >
        <HelpOutline onClick={() => setOuterState('tooltip', q.key, true)} className={classNames(classes.helpIcon, classes.textHelp)} />
      </CustomTooltip></Grid>}
    </Grid>
  }

  render() {
    const {form, classes, questions, errors, handleChange, selectedCountry, setOuterState, step, hidden, errorFiles,
      handleFileUpload, uploadedFile, disabled, handleDeleteChip, disabledFields, removeFileFromFileList} = this.props
    const {themePreference, locale} = this.context
    const {weblinks} = config
    const categoriesKeys = map(corporateStepCategories, 'key')
    const firstFieldPerCategory = {}
    map(categoriesKeys, (category) => {
      const first = find(questions, (question) => includes(question.categories, category))
      if (first) firstFieldPerCategory[get(first, 'key')] = category
    })
    const stepActive = find(corporateSteps, (corporateStep) => corporateStep.rank === step)

    return map(questions, (q, questionKey) => {
      const {options} = q
      const countOfHidden = q.multiple && size(filter(hidden, (value) => includes(value, q.key)))
      let birthdayFormat, birthdayValidFormat
      if (q.date && form[q.key]) {
        birthdayFormat = q.date && form[q.key] && validateDate(form[q.key], true, true)
        birthdayValidFormat = birthdayFormat && moment(form[q.key], birthdayFormat).format()
      }
      const subTooltip = includes(keys(firstFieldPerCategory), q.key) && corporateStepCategories[firstFieldPerCategory[q.key]].tooltip
      const required = !!q.required(form, 'key', step, hidden)
      const memberField = q.memberField && q.memberField(step)

      return (required || q.relevant(form, 'key', step, hidden)) && <React.Fragment key={q.key}>
        {(includes(keys(firstFieldPerCategory), q.key)) && <Grid item xs={12}>
          <Typography variant='h3' className={classNames(classes.h3, subTooltip && classes.tooltipDiv)}>
            {corporateStepCategories[firstFieldPerCategory[q.key]].localization.t(locale)}
            {subTooltip && <CustomTooltip
              title={<Typography variant='caption' className={classes.tooltipText}>{subTooltip.localization.t(locale)}</Typography>}
              placement={'top-start'}
            >
              <HelpOutline className={classNames(classes.tooltipHelpIcon, classes.textHelp)} />
            </CustomTooltip>
            }
          </Typography>
          {corporateStepCategories[firstFieldPerCategory[q.key]].subLabel &&
            <Typography variant='subtitle1' className={classNames(classes.file)}>
              {corporateStepCategories[firstFieldPerCategory[q.key]].subLabel.localization.t(locale)}
            </Typography>}
        </Grid>}
        {q.subCategory && <Grid item xs={12}>
          <Typography variant='body1'>
            {q.subCategory.localization.t(locale)}
          </Typography>
        </Grid>}
        <Grid key={`${questionKey}`} item xs={q.isPrefix ? 5 : (q.isPhone && !memberField) ? 7 : 12} sm={q.isPrefix ? 3 : (q.isPhone && !memberField) ? 8 : 12}>
          {q.dropdown ? (q.isCountry) ? <React.Fragment>
            <Grid id={q.key} key={`${questionKey}`} item xs={12} lg={q.isPrefix ? 12 : 7} md={q.isPrefix ? 12 : 9} sm={q.isPrefix ? 12 : 8} className={q.isHidden ? classNames(classes.hiddenCountry, classes.displayInline) : ''}>
              <CountriesSelect
                countryList={options}
                handleChangeField={(e) => {
                  const selectedCountry = find(countries,(country) => country.value === e)
                  setOuterState('selectedCountry', [q.key],  selectedCountry)
                  handleChange(q.key, q.isPrefix ? `+${get(selectedCountry,'callingCode','')}` : e)
                }}
                handleChange={() => {}}
                setStateOuter={() => {}}
                errors={errors}
                value={selectedCountry && selectedCountry[q.key]? (q.isPrefix) ? get(selectedCountry[q.key], 'value', '') : get(selectedCountry[q.key], 'label', '') : ''}
                name={q.key}
                label={q.localization.t(locale)}
                phonePrefix={q.isPrefix}
                prefixValue={form[q.key] || ''}
                disabled={disabled}
                showRequired={required}
              />
            </Grid>
            {q.isHidden && <Grid id={q.key} item xs={1} sm={1} className={classes.displayInline}>
              <IconButton
                className={classes.hiddenButton}
                onClick={()=> {
                  const index = hidden.indexOf(q.key)
                  if (!disabled) {
                    if (index > -1) {
                      hidden.splice(index, 1)
                    }
                    handleChange(q.key, '')
                    setOuterState('hidden', hidden)
                    if (q.isCountry) {
                      setOuterState('selectedCountry', q.key, {})
                      setOuterState('errors', q.key, false)
                    }
                    this.checkValues()
                  }
                }}
                size="large">
                <CloseIcon />
              </IconButton>
            </Grid>}
          </React.Fragment>: <Grid id={q.key} key={`${questionKey}`} item xs={12} lg={7} md={9} sm={8}>
            <FormControl variant="standard" fullWidth={true} key={q.value}>
              {this.renderSelectComponent(q, q.key === corporateFields.nationality.key, required)}
            </FormControl>
          </Grid> : ''
          }
          {q.textfield && <Grid id={q.key} item xs={12} lg={7} md={9} sm={8} className={(q.isPhone && !memberField) ? classes.phone: ''}>
            <TextField
              variant="standard"
              required={required}
              id={q.key}
              name={q.value}
              label={q.localization.t(locale)}
              fullWidth
              value={form[q.key] || ''}
              onChange={(e) => handleChange(q.key, e.target.value)}
              error={errors[q.key]}
              disabled={!!disabled || (q.disabled && q.disabled(step))}
              InputProps={{
                className: classes.formControl,
              }} />
            {errors[q.key] && includes(q.key, ['profit']) && <FormHelperText className={classes.error}><Trans {...messages.profitAndLossesError} /></FormHelperText>}
          </Grid>}
          {q.date &&  <Grid id={q.key} item xs={12} lg={7} md={9} sm={8}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={get(dateFnsLocales, locale) || get(dateFnsLocales, fnsLocales[locale]) || get(dateFnsLocales, fnsLocales['default'])}>
              <DatePicker
                id={q.key}
                label={q.localization.t(locale)}
                format="dd/MM/yyyy"
                value={form[q.key] && birthdayValidFormat ? new Date(birthdayValidFormat) : null}
                onChange={(selectedDate) => handleChange(q.key,  moment(new Date(selectedDate)).format('DD/MM/YYYY'))}
                className={classes.fullWidth}
                slotProps={{
                  textField: {
                    required,
                    helperText: null,
                    error: !!errors[q.key],
                  },
                }}
                disabled={!!disabled}
              />
            </LocalizationProvider>
          </Grid>}
          {q.checkbox && <Grid id={q.key} item xs={12} sm={12}>
            <FormControl variant="standard">
              <FormControlLabel
                control={
                  <Checkbox
                    required
                    checked={!!form[q.key]}
                    onChange={(e) => handleChange(q.key, e.target.checked ? 'checked' : '', null, true)}
                    color="primary"
                    value={form[q.key]}
                    className={errors[q.key] ? classes.error : ''}
                    disabled={!!disabled}
                  />
                }
                label={q.localization.t(locale)}
              />
            </FormControl>
          </Grid>}
          {q.multiselect && <Grid id={q.key} item xs={12} lg={7} md={9} sm={8}>
            <FormControl variant="standard" fullWidth>
              <InputLabel error={errors[q.key]} required={required}>{q.localization.t(locale)}</InputLabel>
              <Select
                variant="standard"
                multiple
                value={form[q.key]}
                onChange={(e) => handleChange(q.key, e.target.value)}
                input={<Input id="select-multiple-chip" />}
                renderValue={selected => (
                  <div className={classes.chips}>
                    {map(form[q.key], (value) => (
                      <Chip key={value} label={value} className={classes.chip}
                        onDelete={() => !(!!disabled || includes(disabledFields, q.key)) && handleDeleteChip(q.key, value)}/>
                    ))}
                  </div>
                )}
                disabled={!!disabled || includes(disabledFields, q.key)}
                error={errors[q.key]}>
                {map(q.options, (option) => (
                  <MenuItem key={option.key} value={option.value}>
                    <Checkbox checked={form[q.key].indexOf(option.value) > -1} />
                    <ListItemText primary={option.localization.t(locale)} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>}
          {q.multipleCheckbox && <Grid id={q.key} item xs={12} lg={7} md={9} sm={8}>
            <FormControl variant="standard">
              <FormLabel
                error={errors[q.key]}
                className={classes.lineHeight}
              >
                <Typography variant='body2' className={errors[q.key] ? (classes.error): ''}>{q.localization.t(locale)}{required ? '*' : ''}</Typography>
              </FormLabel>
              <FormGroup>
                {map(q.options, (answer, answerKeySub) =>
                  <FormControlLabel
                    key={answerKeySub}
                    value={answerKeySub}
                    label={answer.localization.t(locale)}
                    onChange={(e) => handleChange(q.key, e.target.value, q)}
                    control={<Checkbox
                      checked={includes(form[q.key], answerKeySub)}
                      color="primary"
                    />}
                    disabled={!!disabled}
                  />

                )}
              </FormGroup>
            </FormControl>
          </Grid>}
          {q.multiple && countOfHidden < q.multiple(form, undefined, stepActive.key) && <Grid id={q.key} item lg={7} xs={12} md={9} sm={8}>
            <Button variant={'text'} onClick={() => {
              const sum = q.multiple(form, undefined, stepActive.key)
              for (let count = 0; count < sum; count ++) {
                if (!includes(hidden, `${q.key}${count+1}`)) {
                  hidden.push(`${q.key}${count+1}`)
                  setOuterState('hidden', hidden)
                  break
                }
              }
            }}
            className={classNames(classes.textBlue, classes.button)}
            disabled={!!disabled}>
              <AddCircleOutlineIcon className={classes.addIcon}/>
              <Trans {...messages.addAnotherField} values={{field: q.multipleLabel ? q.multipleLabel.localization.t(locale) : q.localization.t(locale)}} />
            </Button>
          </Grid>}
          {q.file && <Grid container>
            <Grid item xs={12}>
              <Typography variant="h3" className={classes.h3}>
                {q.localization.t(locale)}
              </Typography>
            </Grid>
            <Grid item sm={8} xs={12} className={classes.item}>
              <Typography variant='body1'>
                <Trans {...messages.reviewPrintSign} />
              </Typography>
            </Grid>
            <Grid item xs={12} lg={7} md={9} sm={8}>
              <a href={weblinks[q.key].replace('{lang}', locale)} target='_blank' rel='noreferrer noopener'>
                <Button className={classes.button} size="small" color="primary">
                  <GetAppIcon />
                  <Trans {...messages.download} />
                </Button>
              </a>
            </Grid>
            <Grid id={q.key} item lg={7} xs={12} md={9} sm={8} className={classes.file}>
              <SelectDocument
                uploadedFile={uploadedFile[q.key]}
                onChange={(e, files) => handleFileUpload('uploadedFile', e, q.key, files)}
                key={q.key}
                type={'upload'}
                status={(uploadedFile[q.key] && (includes(keys(errorFiles), q.key) ? 'error': 'success')) || (errors[q.key] && 'error')}
                errors={!isEmpty(errorFiles) && errorFiles[q.key]}
                fileKey={q.key}
                category={`document-${themePreference}`}
                src={Images['upload-icon.png']}
                classesNames={classes}
                corporate={true}
                showFile={!uploadedFile[q.key] && form[q.key]}
                disabled={!!disabled}
                removeFileFromFileList={removeFileFromFileList}
              />
            </Grid>
          </Grid>}
        </Grid>
      </React.Fragment>
    })
  }
}

export default compose(withStyles(styles, {withTheme: true}),withNamespaces())(Questions)
