import React, {Component} from 'react'
import {flowRight as compose, get, isEmpty} from 'lodash'
import {Trans, withNamespaces} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import PageTitle from '../Common/PageTitle'
import {Grid, SvgIcon, Typography} from '@mui/material'
import PromotionCard from './PromotionCard'
import {isMobile} from '../../common/utils'
import messages from '../../assets/messages'
import PromotionOptInCard from './PromotionOptInCard'
import PageBody from '../Common/PageBody'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'
import {ReactComponent as PromotionsSad} from '../../assets/images/promotionsSad.svg'
import {withApollo} from 'react-apollo'
import {PROMOTIONS_CARDS_QUERY} from '../../graphql/queries'
import Loading from '../Common/Loading'

const styles = theme => ({
  availablePromotionsItem: {
    marginTop: '40px',
    marginBottom: '16px',
  },
  availablePromotionsText: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
  },
  noPadding: {
    paddingLeft: '0px',
    '&.MuiGrid-item': {
      paddingLeft: '0px',
    }
  },
  noPromotions: {
    textAlign: 'center',
    color: theme.palette.grey[600]
  },
  noPromotionsImage: {
    verticalAlign: 'middle',
  }
})

class PromotionsContainer extends Component {
  constructor() {
    super()
    this.state = {
      promotions: [],
      loading: false,
    }
  }

  // We need to fetch promotions only when the client is opted-in ONLY.
  fetchPromotions() {
    const {client: apolloClient, optInPromotionsValue} = this.props
    if (!optInPromotionsValue) return
    this.setState({loading: true})
    apolloClient.query({query: PROMOTIONS_CARDS_QUERY, fetchPolicy: 'network-only'})
      .then(({data: {loading}, data}) => this.setState({loading, promotions: get(data, 'checkClientLoginsEligiblePromotion', [])}))
      .catch((_) => this.setState({loading: false}))
  }

  componentDidMount() {
    this.fetchPromotions()
  }

  componentDidUpdate(prevProps, _) {
    const {optInPromotionsValue} = this.props
    const {optInPromotionsValue:prevOptInPromotionsValue} = prevProps
    if (optInPromotionsValue !== prevOptInPromotionsValue && optInPromotionsValue) this.fetchPromotions()
  }

  render() {
    const {classes, history, promotionsEnabled, optInPromotionsValue, t} = this.props
    const {loading, promotions} = this.state
    if (loading) return (<Loading />)
    const isMobileDevice = isMobile()
    return (<React.Fragment>
      <PageTitle
        hideArrow={!isMobile()}
        title={t(messages.promotions.i18nKey, messages.promotions.defaults)}
        customClasses={{header: classes.noPadding}}
        onBack={() => isMobile ? history.push('/more') : history.push('/accounts')}
      />
      <PageBody>
        <Grid container direction='row' spacing={!isMobileDevice ? 3 : 1}>
          {isMobileDevice && <Grid item xs={12} sm={12} key='optInMobile'>
            <PromotionOptInCard promotionsEnabled={promotionsEnabled} optInPromotionsValue={optInPromotionsValue}/>
          </Grid>}
          {isMobileDevice && <Grid item xs={12} sm={12} key='availablePromotionsItem' className={classes.availablePromotionsItem}>
            <Typography variant='body1' className={classes.availablePromotionsText}>
              <Trans {...messages.availablePromotionsText} />
            </Typography>
          </Grid>}
          {promotionsEnabled && optInPromotionsValue && promotions.map((promotion) => (
            <Grid item xs={12} sm={6} key={get(promotion, 'mpid')} className={classes.noPadding}>
              <PromotionCard key={get(promotion, 'mpid')} promotion={promotion}/>
            </Grid>
          ))}
          {(!promotionsEnabled || isEmpty(promotions) || !optInPromotionsValue) &&
          <Grid item xs={12} sm={12} key='noPromotions'>
            <Typography variant='body2' className={classes.noPromotions}>
              {!promotionsEnabled && <Trans {...messages.notEligiblePromotions} />}
              {isEmpty(promotions) && promotionsEnabled && optInPromotionsValue && <Trans {...messages.emptyPromotions} />}
              {!optInPromotionsValue && promotionsEnabled && <Trans {...messages.notOptedInPromotions} />}
              &nbsp;<span>
                <SvgIcon className={classes.noPromotionsImage}><PromotionsSad /></SvgIcon>
              </span>
            </Typography>
          </Grid>}
        </Grid>
      </PageBody>
    </React.Fragment>)
  }
}

PromotionsContainer.propTypes = {
  optInPromotionsValue: PropTypes.bool.isRequired,
  promotionsEnabled: PropTypes.bool.isRequired,
}

export default compose(
  withApollo,
  withNamespaces(),
  withRouter,
  withStyles(styles, {withTheme: true}),
)(PromotionsContainer)
