const regalxOverrides = {
  palette: {
    mode: 'light',
    error: {
      main: '#EF2D34',
    },
    green: {
      color: '#1DCD9A',
    },
    lightblue: {
      color: '#E8F7FE',
    },
    yellow: {
      color: '#FF9F00',
    },
    grey: {
      color: 'rgba(0,0,0,0.75)',
    },
  },
}

export default regalxOverrides
