import React, {Component} from 'react'
import {graphql} from 'react-apollo'
import {find, flowRight as compose, get, trim} from 'lodash'
import moment from 'moment'
import withStyles from '@mui/styles/withStyles'
import Button from '@mui/material/Button'
import {Trans, withNamespaces} from 'react-i18next'
import messages from '../../../assets/messages'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import AppContext from '../../Common/contexts/AppContext'
import {withRouter} from 'react-router-dom'
import Card from '@mui/material/Card'
import {ACCOUNTS_QUERY, CLIENT_DATA_QUERY} from '../../../graphql/queries'
import {getDatesDifference, safeParseJSON} from '../../../common/utils/general'
import {AlertDialog} from '../../Common/Dialog'
import classNames from 'classnames'
import {isMobile} from '../../../common/utils/browser'
import {currencies} from '@bdswiss/common-enums'
import Amount from '../../Common/Amount'
import {CLIENT_EXIT_COMPETITION_MUTATION} from '../../../graphql/competitions/mutations'
import LogoDefaultForCompetition
  from '../../PartnerZone/TradingCompetition/SubComponents/SubComponents/LogoDefaultForCompetition'

const styles = theme => ({
  competitionImg: {
    width: 50,
    height: 50,
    marginLeft: 0,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 40,
    },
    objectFit: 'cover',
    borderRadius: 4,
  },
  imgDiv: {
    margin: 'auto'
  },
  displayInline: {
    display: 'inline-flex',
  },
  cardRoot: {
    '&:hover': {
      cursor: 'default',
    }
  },
  time: {
    color: theme.palette.error.main,
    fontSize: 25,
    padding: '0 3px',
  },
  exitBtn: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'inherit'
    }
  },
  textCenter: {
    textAlign: 'center'
  },
  redText: {
    color: theme.palette.error.main,
  },
  inlineBlock: {
    display: 'inline-block',
  },
  textCard: {
    fontSize: 15
  }
})

class CompetitionCard extends Component {
  static contextType = AppContext
  state = {
    errorMessage: false,
    loading: false,
    openExitPopup: false
  }

  competitionExit(currentEntry) {
    const {competition, client, entry} = this.props
    const variables = {memberId: client.id, accountId: currentEntry.id, competitionId: competition.id}
    this.setState({loading: true})
    this.props.clientExitCompetition({variables})
      .then(() => {
        this.setState({loading: false, status: 'success', openExitPopup: false})
      })
      .catch(() => {
        this.setState({loading: false, errorMessage: true, status: 'failure'})
      })
    entry(currentEntry)
  }

  getSecondsLeft(stop) {
    if (!stop && !this.secondsInterval) {
      this.secondsInterval = setInterval(() => {
        this.setState({})
      }, 1000)
    }
  }

  componentWillUnmount() {
    clearInterval(this.secondsInterval)
  }

  render() {
    const {classes, competition, accounts, noHover, onClick, exitClick, t} = this.props
    const {errorMessage, loading, openExitPopup, status} = this.state

    const now = moment()
    const time = getDatesDifference(now, moment((get(competition, 'endDate'))))
    if (time.active) {
      this.getSecondsLeft(!time.active || competition?.competitionCreatedByClientData?.isRejected)
    }

    const currentEntry = find(accounts, (a) => a.competitions && find(a.competitions, (e) =>
      get(e, 'competitionDetails.id') === get(competition, 'id') && e.active))
    const rules = safeParseJSON(get(competition, 'rules'))
    let entryFee = get(find(rules, (r) => r.rule === 'minimumBalance'), 'values.balance')
    entryFee = <Amount value={entryFee} currency={currencies.USD.value} locale="en-US"/>
    const competitionName = get(competition, 'name', '').split('-')

    const renderThirdBlockLogic = () => {
      if (competition?.competitionCreatedByClientData?.isRejected) {
        return <Typography variant="caption" className={classes.redText}>
          <Trans {...messages.rejected} />
        </Typography>
      }
      if (time.active) {
        return <React.Fragment>
          <Typography variant="body1"><Trans {...messages.daysRemaining} /></Typography>
          {time.millisecondsSum > 60000 && <span className={classes.inlineBlock}>
            <span
              className={classes.time}>{(time.daysRemaining) ? time.daysRemaining : (time.hoursRemaining) ? time.hoursRemaining : time.minutesRemaining}</span>
            <Typography variant="body1" className={classNames(classes.displayInline, classes.textCard)}>
              <Trans {...messages[`${(time.daysRemaining) ? 'days' : (time.hoursRemaining) ? 'hours' : 'minutes'}`]} />
            </Typography></span>}
          {(!isMobile() || time.millisecondsSum < 60000) && <span className={classes.inlineBlock}>
            <span
              className={classes.time}>{(time.daysRemaining) ? time.hoursRemaining : (time.hoursRemaining) ? time.minutesRemaining : time.secondsRemaining}</span>
            <Typography variant="body1" className={classNames(classes.displayInline, classes.textCard)}>
              <Trans {...messages[`${(time.daysRemaining) ? 'hours' : (time.hoursRemaining) ? 'minutes' : 'seconds'}`]} />
            </Typography></span>}
        </React.Fragment>
      } else {
        return <Typography variant="caption" className={classes.redText}>
          <Trans {...messages.competitionEnded} />
        </Typography>
      }
    }

    return <Card classes={(noHover) && {root: classes.cardRoot}} onClick={() => onClick && onClick(competition)}>
      <Grid container>
        {
          competition.icon
            ? <Grid item xs={2}>
              <img alt={get(competition, 'name')} src={get(competition, 'icon')} className={classes.competitionImg}/>
            </Grid>
            : <Grid item xs={2}>
              <LogoDefaultForCompetition/>
            </Grid>
        }
        <Grid item xs={5} className={classes.divCenter}>
          <Typography variant="body1">{trim(competitionName[0])}</Typography>
          {competitionName[1] && <Typography variant="body1">{trim(competitionName[1])}</Typography>}
          {(!currentEntry || !time.active) ? <Grid container>
            <Grid item xs={12}>
              <Typography variant="caption" className={classes.displayInline}>
                <Trans {...messages.minBalance} />: &nbsp;</Typography>
              <Typography variant="caption" className={classes.displayInline}>{entryFee}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" className={classes.displayInline}>
                <Trans {...messages.participants} />: &nbsp;</Typography>
              <Typography variant="caption"
                className={classes.displayInline}> {get(competition, 'participantsCount', 0)} </Typography>
            </Grid>
          </Grid> : <Grid item xs={12}>
            <Button color={'primary'} className={classes.exitBtn}
              onClick={() => exitClick && this.setState({openExitPopup: true})}>
              <Trans {...messages.exitCompetition} />
            </Button>
          </Grid>
          }
        </Grid>
        <Grid item xs={5} className={classNames(classes.textCenter, isMobile() && classes.imgDiv)}>
          {renderThirdBlockLogic()}
        </Grid>
      </Grid>
      <AlertDialog open={openExitPopup}
        title={t(messages.stopParticipating.i18nKey, messages.stopParticipating.defaults)}
        onClose={() => this.setState({openExitPopup: false})}
        agreeText={t(messages.continue.i18nKey, messages.continue.defaults)}
        onAgree={() => this.competitionExit(currentEntry)}
        disagreeText={t(messages.cancel.i18nKey, messages.cancel.defaults)}
        closeBtn={true} buttonLoading={loading} buttonStatus={status}
        errorText={errorMessage && t(messages.somethingWrongError.i18nKey, messages.somethingWrongError.defaults)}>
        <Typography variant="subtitle1">
          <Trans {...messages.stopParticipatingMsg} />
        </Typography>
      </AlertDialog>
    </Card>
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withRouter,
  withNamespaces(),
  graphql(CLIENT_EXIT_COMPETITION_MUTATION, {
    name: 'clientExitCompetition',
    options: {
      refetchQueries: [{query: ACCOUNTS_QUERY}, {query: CLIENT_DATA_QUERY}],
    },
  }),
)(CompetitionCard)
