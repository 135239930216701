import React, {Component} from 'react'
import PropTypes from 'prop-types'
import AppContext from '../Common/contexts/AppContext'
import {withRouter, Link} from 'react-router-dom'
import classNames from 'classnames'
import {flowRight as compose, get} from 'lodash'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import {withNamespaces, Trans} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import messages from '../../assets/messages'
import Images from './Images'
import PageTitle from './PageTitle'
import PageSubTitle from './PageSubTitle'
import {withMediaQuery} from '../Common'

const closeImageLight = Images['icon-close-light.svg']
const closeImageDark = Images['icon-close-dark.svg']

const styles = theme => ({
  content: {
    textAlign:'center',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      width: 960,
      margin: 'auto'
    },
  },
  image: {
    width: 300,
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    }
  },
  centerItem: {
    textAlign: 'center',
  },
  iconClose: {
    width: 30,
    height: 30,
    position: 'relative',
    backgroundSize: '30px 30px',
    display: 'block',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer'
  },
  darkClose: {
    backgroundImage: `url(${closeImageDark})`,
  },
  lightClose: {
    backgroundImage: `url(${closeImageLight})`,
  },
  children: {
    textAlign: 'center',
    fontSize:16,
    fontWeight:300,
    color: theme.palette.secondary.dark,
  },
  appBar: {
    boxShadow: 'none',
  },
  fullWidth:{
    [theme.breakpoints.down('sm')]: {
      width: '97%',
    }
  },
  linkAction:{
    paddingTop:8,
    display:'block'
  },
  cancelButton: {
    [theme.breakpoints.up('md')]: {
      marginRight: 10
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10
    }
  },
})
class UiNotification extends Component {
  static contextType = AppContext

  render() {
    const {classes, children, status, type, onClose, widthDown, buttonMessage, buttonAction, linkAction, open, hideClose,
      hideButton, subTitle, linkActionMessage, title, titleComponents, location, subTitleChildren, cancelButton, onCancel, content, subTitleCenter, hideTitle} = this.props
    const {themePreference} = this.context
    const buttonOnClick = buttonAction || onClose
    const bgImage = Images[`${type}-${status}-${themePreference}.png`] || Images[`${type}-${themePreference}.svg`] || Images[`${type}-${status}-${themePreference}.svg`]
    const uinotification = !['startTrading', 'filters'].includes(status) ? 'uinotification' : ''
    const buttonOnClose = onCancel || onClose

    return (
      <Dialog
        fullScreen
        open={open}
        onClose={() => onClose(status)}
        disableEnforceFocus={true}
        PaperProps={{style: {margin: 0}}}
      >
        <Grid container spacing={3} padding={2} alignItems="center" alignContent='center' className={classes.content}>
          {!hideClose && <Grid container item justifyContent="flex-end">
            <div className={classNames(classes.iconClose, classes[`${themePreference}Close`])} onClick={() => onClose(status)} />
          </Grid>}
          <Grid item xs={12} className={classes.centerItem}>
            {!hideTitle && <PageTitle modal {...{uinotification}}>
              <Trans {...messages[title || status]} components={titleComponents}/>
            </PageTitle>}
            {subTitle && <PageSubTitle subTitleCenter={subTitleCenter}>{subTitle}</PageSubTitle>}
            {subTitleChildren && <Grid item xs={12} className={classes.children}>
              {subTitleChildren}
            </Grid>}
          </Grid>
          <Grid item xs={12} className={classes.centerItem}>
            {
              bgImage &&
                <img
                  className={classes.image}
                  src={bgImage}
                  alt={status}
                />
            }
          </Grid>
          <Grid item xs={12} className={classes.children}>
            {content || children}
          </Grid>
          {!hideButton && <Grid item xs={12}>
            {cancelButton && <Button
              fullWidth={widthDown}
              color="secondary" size="large"
              variant="contained"
              onClick={() => buttonOnClose()}
              className={classNames(classes.cancelButton, classes.fullWidth)}
            >
              {cancelButton}
            </Button>}
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => buttonOnClick(status)}
              fullWidth={widthDown}
              className={classes.fullWidth}
            >
              {buttonMessage}
            </Button>
            {linkAction &&
              <Link to={linkAction} className={classes.linkAction} onClick={() => linkAction === get(location, 'pathname') && onClose(status)}>
                <Typography variant="body2" color="primary">
                  {linkActionMessage}
                </Typography>
              </Link>
            }
          </Grid>
          }
        </Grid>
      </Dialog>
    )
  }
}

UiNotification.propTypes = {
  status: PropTypes.oneOf(['success', 'failure', 'pending', 'skip', 'tradingAlerts','startTrading', 'filters', 'referral']),
  type: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  buttonMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  hideButton: PropTypes.bool,
  subTitle: PropTypes.object,
  subTitleCenter: PropTypes.bool,
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withMediaQuery('md'),
  withRouter,
  withNamespaces(),
)(UiNotification)
