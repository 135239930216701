import React, {Component} from 'react'
import {find, flowRight as compose} from 'lodash'
import classnames from 'classnames'
import Grid from '@mui/material/Grid'
import {withRouter} from 'react-router-dom'
import Button from '@mui/material/Button'
import {Trans, withNamespaces} from 'react-i18next'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import {CustomDialog} from '../Common/Dialog/index'
import messages from '../../assets/messages'
import {isForexAccount, isDemoAccount} from '../../common/utils/accounts'
import {getFormattedAmount} from '../../common/utils/general'

const styles = {
  dialog: {
    textAlign: 'center',
  },
  price: {
    fontSize: 22,
    fontWeight: 500,
  },
  orange: {
    color: '#ef9756'
  },
  green: {
    color: '#67ad5b'
  },
  list: {
    listStyle: 'none',
    padding: 0,
    margin: '20px 0 30px 0',
  },
  li: {
    fontWeight: 500,
  },
  button: {
    color: 'white',
    width: '90%',
    textTransform: 'none',
  },
  buttonReal: {
    background: '#67ad5b',
    '&:hover': {
      background: '#67ad5b',
    }
  },
  buttonDemo: {
    background: '#ef9756',
    '&:hover': {
      background: '#ef9756',
    }
  },
  buttonTitle: {
    fontWeight: 500,
  },
}

class WelcomeModal extends Component {
  constructor(props) {
    super(props)
    this.state = {open: true}
  }

  render() {
    const {accounts, classes, locale, history} = this.props
    const {open} = this.state
    const demoAccount = find(accounts, (a) => isForexAccount(a) && isDemoAccount(a)) || {}
    const realAccount = find(accounts, (a) => isForexAccount(a) && !isDemoAccount(a)) || {}
    const {currency: demoAccountCurrency, balance: demoAccountBalance} = demoAccount
    const {currency: realAccountCurrency, balance: realAccountBalance} = realAccount

    return (
      <CustomDialog customClasses={classes} open={open} title={<Trans {...messages.webtraderWelcomeTitle} />}>
        <Grid container>
          <Grid item sm={6}>
            <Typography variant="body2"><Trans {...messages.webtraderWelcomePracticeCredit} /></Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography variant="body2"><Trans {...messages.webtraderWelcomeRealAccountCreated} /></Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item sm={6}>
            <div className={classnames([classes.price, classes.orange])}>
              {getFormattedAmount({amount: demoAccountBalance, currency: demoAccountCurrency, locale})}
            </div>
            <ul className={classes.list}>
              <li className={classnames([classes.li, classes.orange])}>{<Trans {...messages.webtraderWelcomeNoWithdrawals} />}</li>
            </ul>
            <Button
              className={classnames([classes.button, classes.buttonDemo])}
              onClick={() => this.setState({open: false})}
            >
              <Trans className={classes.buttonTitle} {...messages.webtraderWelcomeStartTradingTitle} />
              <br />
              <Trans {...messages.webtraderWelcomeStartTradingDemoSubtitle} />
            </Button>
          </Grid>
          <Grid item sm={6}>
            <div className={classnames([classes.price, classes.green])}>
              {getFormattedAmount({amount: realAccountBalance, currency: realAccountCurrency, locale})}
            </div>
            <ul className={classes.list}>
              <li className={classnames([classes.li, classes.green])}>{<Trans {...messages.webtraderWelcomeEasyWithdrawals} />}</li>
            </ul>
            <Button
              className={classnames([classes.button, classes.buttonReal])}
              onClick={() => history.push(`/transactions/deposit/${realAccount.id}`)}
            >
              <Trans className={classes.buttonTitle} {...messages.webtraderWelcomeStartTradingTitle} />
              <br />
              <Trans {...messages.webtraderWelcomeStartTradingRealSubtitle} />
            </Button>
          </Grid>
        </Grid>
      </CustomDialog>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  withRouter,
)(WelcomeModal)
