import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {flowRight as compose, filter, get, map} from 'lodash'
import {depositVendors, frontends, letknowpayCryptoCurrencies , speedlightpayCryptoCurrencies} from '@bdswiss/common-enums'
import {withNamespaces, Trans} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import InputAdornment from '@mui/material/InputAdornment'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined'
import messages from '../../../../assets/messages'
import {getPlatform, findCompany, safeParseJSON, logEventCustomParams, getSourceBasedOnLocationPrevPath} from '../../../../common/utils'
import {withCreateDeposit, PaymentActionButton} from './helpers'
import config from '../../../../config'

const style = (theme) => ({
  cryptoMessage: {
    color: theme.palette.red.color,
    fontStyle:'italic'
  },
  warningMessage: {
    fontWeight: 400,
    margin: '0 50px 0 35px',
  },
  walletOwnerMessage: {
    margin: '24px 0 0 0',
  },
  formControlLabelVAligned: {
    display:'flex',
    alignItems:'flex-start',
  },
  checkboxControlVAligned: {
    paddingTop:'24px',
  },
})
class CryptoProvider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cryptoVendors: [depositVendors.letknowpay.value , depositVendors.speedlightpay.value],
      rightfulWalletAddressOwner: false,
    }
  }


  getDepositWalletAddress() {
    const {account, amount, providerProperties: {provider, paymentKey, id: paymentOptionId}, onError, useVirtualPaymentPage, bonusAmount, bonusTerms,
      history: {location}} = this.props
    const {cryptoCurrency, cryptoVendors} = this.state
    const isCryptoVendor = cryptoVendors.includes(provider)
    if (isCryptoVendor && !cryptoCurrency) {
      this.setState({cryptoCurrencyError: true})
      return
    }
    this.setState({gettingWalletAddress: true})

    const firebaseParams = {
      source: getSourceBasedOnLocationPrevPath(get(location, 'state', {prevPath: get(location, 'pathname')})),
      login: account.id,
      currency: account.currency,
      vendor: provider,
      amount,
    }
    logEventCustomParams('depositAttempted', firebaseParams)

    const variables = {
      accountId: account.id,
      amount,
      vendor: provider,
      paymentOptionId,
      frontend: frontends.web2.value,
      args: JSON.stringify({
        platform: getPlatform(),
        depositCurrency: isCryptoVendor ? cryptoCurrency : paymentKey,
        useVirtualPaymentPage,
        bonusAmount,
        bonusTerms,
      }),
    }

    this.props.createDepositRequest({variables})
      .then(({data: {newDeposit}}) => {
        const {payment: {url: data}} = newDeposit
        const parsedData = safeParseJSON(data) || data
        const walletAddress = typeof parsedData === 'string' ? parsedData : parsedData.address
        const tag = typeof parsedData === 'string' ? '' : parsedData.tag
        const qrCode = typeof parsedData === 'string' ? '' : parsedData.qrCode
        const rate = typeof parsedData === 'string' ? '' : parsedData.rate
        const cryptoAmount = typeof parsedData === 'string' ? '' : parsedData.cryptoAmount
        this.setState({walletAddress, tag, qrCode, rate, cryptoAmount, gettingWalletAddress: false})
      })
      .catch((e) => {
        this.setState({gettingWalletAddress: false})
        onError && onError(e)
      })
  }

  handleCryptoCurrencyChange = (event) => {
    this.setState({cryptoCurrency: event.target.value, cryptoCurrencyError: false})
  }

  render() {
    const {classes, providerProperties: {provider, paymentKey}, canProceed} = this.props
    const {forcedCompany} = config
    const companyObject = findCompany(forcedCompany)
    const {brandLabel: companyLabel} = companyObject
    const {rightfulWalletAddressOwner, cryptoVendors, gettingWalletAddress, walletAddress, qrCode, rate, cryptoAmount, walletCopied, tag, tagCopied, cryptoCurrency, cryptoCurrencyError} = this.state
    const isCryptoVendor = cryptoVendors.includes(provider)
    const isWalletOwner = map(filter(depositVendors, {walletAcknowledgement: true}), 'key')
    const walletType = isCryptoVendor ? cryptoCurrency : paymentKey
    const cryptoCurrencies = provider === depositVendors.letknowpay.value ? letknowpayCryptoCurrencies : speedlightpayCryptoCurrencies

    return (
      <React.Fragment>
        {
          !walletAddress &&
          <React.Fragment>
            {isCryptoVendor && <FormControl variant="standard" fullWidth error={cryptoCurrencyError}>
              <InputLabel><Trans {...messages.cryptoCurrency} /></InputLabel>
              <Select
                variant="standard"
                value={cryptoCurrency}
                onChange={this.handleCryptoCurrencyChange}>
                {map(cryptoCurrencies, (currency) => <MenuItem key={currency.key} value={currency.value}>{currency.label}</MenuItem>)}
              </Select>
            </FormControl>}
            {isWalletOwner.includes(provider) && <Grid item xs={12}>
              <FormControlLabel
                className={classes.formControlLabelVAligned}
                control={
                  <Checkbox
                    className={classes.checkboxControlVAligned  }
                    name="rightfulWalletAddressOwner"
                    checked={rightfulWalletAddressOwner}
                    onChange={(e) => this.setState({rightfulWalletAddressOwner: e.target.checked})}
                  />
                }
                label= {<Typography variant="subtitle2" className={classes.walletOwnerMessage}><Trans {...messages.cryptoCheckbox} /></Typography>}
              />
            </Grid>}
            <PaymentActionButton
              onClick={() => this.getDepositWalletAddress()}
              buttonMessage={messages.getCryptoWalletAddress}
              disable={canProceed || (!rightfulWalletAddressOwner && isWalletOwner.includes(provider))}
              loading={gettingWalletAddress}
            />
            <br/>
            {provider === depositVendors.btcproxy.value && <Typography variant="subtitle2" paragraph className={classes.warningMessage}>
              <Trans {...messages.bech32WalletAddress} />
            </Typography>}
          </React.Fragment>
        }
        <Grid container alignItems="center" alignContent="center" justifyContent="center" spacing={3}>
          {
            rate && <Grid item xs={12}>
              <Typography variant="body2">
                <Trans {...messages.ProvisionalExchangeRate} values={{rate}}/>
              </Typography>
            </Grid>
          }
          {
            cryptoAmount && <Grid item xs={12}>
              <Typography variant="body2">
                <Trans {...messages.depositAmount} values={{walletType, amount: cryptoAmount}}/>
              </Typography>
            </Grid>
          }
          {
            walletAddress &&
            <Grid item xs={12}>
              <Typography variant="body2" paragraph>
                <Trans {...messages.copyCryptoWalletAddress} values={{walletType}}/>
              </Typography>
              {qrCode && <Grid container justifyContent="center" spacing={0}>
                <Grid item>
                  <img alt={walletAddress} src={'data:image/png;base64,' + qrCode}/>
                </Grid>
              </Grid>
              }
              <TextField
                id="crypto-deposit-wallet-address"
                variant="outlined"
                disabled
                fullWidth
                value={walletAddress}
                onCopy={() => this.setState({walletCopied: true})}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CopyToClipboard text={walletAddress} onCopy={() => this.setState({walletCopied: true})}>
                        <IconButton aria-label="Copy to clipboard" size="large">
                          <FileCopyOutlined  />
                        </IconButton>
                      </CopyToClipboard>
                    </InputAdornment>
                  ),
                }}
              />
              <br />
              {
                walletCopied &&
                <Typography variant="caption">
                  <Trans {...messages.cryptoWalletAddressCopied} values={{walletType, company: companyLabel}}/>
                </Typography>
              }
              {
                !walletCopied &&
                <Typography variant="caption" className={classes.cryptoMessage}>
                  <Trans {...messages.belowWalletAddressMessage} values={{walletType}}/>
                </Typography>
              }
            </Grid>
          }
          {tag &&
            <Grid item xs={12}>
              <Typography variant="body2" paragraph>
                <Trans {...messages.cryptoTag} />
              </Typography>
              <TextField
                id="crypto-deposit-tag"
                variant="outlined"
                disabled
                fullWidth
                value={tag}
                onCopy={() => this.setState({tagCopied: true})}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CopyToClipboard text={tag} onCopy={() => this.setState({tagCopied: true})}>
                        <IconButton aria-label="Copy to clipboard" size="large">
                          <FileCopyOutlined  />
                        </IconButton>
                      </CopyToClipboard>
                    </InputAdornment>
                  ),
                }}
              />
              <br />
              {tagCopied &&
                <Typography variant="caption">
                  <Trans {...messages.cryptoTagCopied} />
                </Typography>
              }
            </Grid>
          }
          {/* <Grid item>
            <Typography align="center" variant="h6">
              <Trans {...messages.importantNotes} />
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary={<Trans {...messages.cryptoDepositNote1} values={{company: companyLabel}} />} />
              </ListItem>
              <ListItem>
                <ListItemText primary={<Trans {...messages.cryptoDepositNote2} />} />
              </ListItem>
              <ListItem>
                <ListItemText primary={<Trans {...messages.cryptoDepositNote6} />} />
              </ListItem>
              <ListItem>
                <ListItemText primary={<Trans {...messages.cryptoDepositNote4} />} />
              </ListItem>
              <ListItem>
                <ListItemText primary={<Trans {...messages.cryptoDepositNote5} />} />
              </ListItem>
            </List>
          </Grid> */}
        </Grid>
      </React.Fragment>
    )
  }
}

CryptoProvider.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  amount: PropTypes.number.isRequired,
  providerProperties: PropTypes.shape({
    name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    paymentKey: PropTypes.string.isRequired,
    paymentOptionId: PropTypes.number.isRequired,
  }).isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

export default compose(
  withNamespaces(),
  withStyles(style),
  withCreateDeposit
)(CryptoProvider)
