import React from 'react'
import {Redirect, Route, Switch, withRouter} from 'react-router-dom'
import TradingTools from './Tools'

const ToolsRoutes = (props) => (
  <Switch>
    <Route exact path={`${props.match.path}/tools`} component={TradingTools} />
    <Route path={`${props.match.path}/:parentPath/:childPath+`} render={({match}) => <Redirect to={`${props.match.path}/${match.params.parentPath}`} />} />
    <Route path={`${props.match.path}`} component={TradingTools} />
  </Switch>
)

export default withRouter(ToolsRoutes)
