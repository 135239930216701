import React from 'react'
import {map, get, flowRight as compose} from 'lodash'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import {withNamespaces, Trans} from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Typography from '@mui/material/Typography'
import {CustomDialog} from '../../Common/Dialog'
import messages from '../../../assets/messages'

const useStyles = makeStyles({
  button:{
    margin: 5,
    minWidth: 150
  },
  grid: {
    textAlign: 'center',
  },
})

const IbAccountSelectModal = (({open, t, onClose, account, onSelectAccount}) => {
  const classes = useStyles()
  const onSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      title={t(messages.selectAccount.i18nKey, messages.selectAccount.defaults)}
    >
      <Grid container direction={onSmallScreen ? 'column' : 'row'} justifyContent="center" spacing={1} alignItems="center">
        <Grid item xs={12} className={classes.grid}>
          <Typography variant="body1"><Trans {...messages.pleaseChooseAccount} /></Typography>
        </Grid>
        <Grid item xs={12} className={classes.grid}>
          {map(get(account, 'ibId', []), (ibId) => (
            <Button
              variant="outlined"
              size="large"
              color="primary"
              className={classes.button}
              key={ibId}
              onClick={() => onSelectAccount(ibId)}
            >
              {ibId}
            </Button>
          ))}
        </Grid>
      </Grid>
    </CustomDialog>
  )
})

export default compose(
  withNamespaces(),
)(IbAccountSelectModal)
