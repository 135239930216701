import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {frontends} from '@bdswiss/common-enums'
import {getPlatform, getSourceBasedOnLocationPrevPath, logEventCustomParams} from '../../../../common/utils'
import {withCreateDeposit, PaymentActionButton} from './helpers'
import Grid from '@mui/material/Grid'
import Input from '@mui/material/Input'
import Typography from '@mui/material/Typography'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputAdornment from '@mui/material/InputAdornment'
import CreditCardIcon from '@mui/icons-material/CreditCardRounded'
import withStyles from '@mui/styles/withStyles'
import {withNamespaces, Trans} from 'react-i18next'
import messages from '../../../../assets/messages'
import {debounce, isNumber, isEmpty, values, flowRight as compose, get} from 'lodash'
import SelfPostingForm from './SelfPostingForm'
import {validateIsStringContainsEmoji} from '../../../../common/utils/validations'

const style = (theme) => ({
  formControl: {
    margin: theme.spacing(1)
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  textCenter:{
    textAlign: 'center'
  },
})

const validators = {
  cardNumber: (value) => {
    if (!value) return messages.requiredField
    if (!isNumber(+value) || value.length < 16 || value.length > 19) return messages.invalidCardNumber
    if (validateIsStringContainsEmoji(value)) return messages.invalidValue
  },
  cardName: (value) => {
    if (!value) return messages.requiredField
    if (validateIsStringContainsEmoji(value)) return messages.invalidValue
  }
}

const initialState = {form: {cardNumber: '', cardName: ''}, errors: {}, creatingDeposit: false, doSubmitForm: false}

class RPNPayProvider extends Component {
  constructor(props) {
    super(props)
    this.validateFields = debounce(this.validateFields, 200)
    this.state = initialState
  }

  handleChange(key, value) {
    const {form} = this.state
    this.setState({
      form: {
        ...form,
        [key]: value
      }
    }, () => this.validateFields())
  }

  validateFields() {
    const {form} = this.state
    const errors = {}
    Object.keys(form).forEach((k) => {
      const validate = validators[k]
      errors[k] = validate && validate(form[k])
    })
    this.setState({errors})
  }

  formIsValid() {
    const {form, errors} = this.state
    return isEmpty(values(errors).filter((v) => v)) && !isEmpty(values(form).filter((v) => v))
  }

  doCreateDeposit() {
    const {account, providerProperties: {provider, paymentKey, id: paymentOptionId}, amount, onError, useVirtualPaymentPage, bonusAmount, bonusTerms,
      history: {location}} = this.props
    const {form: {cardNumber, cardName}} = this.state

    if (!this.formIsValid()) return

    this.setState({creatingDeposit: true})

    const firebaseParams = {
      source: getSourceBasedOnLocationPrevPath(get(location, 'state', {prevPath: get(location, 'pathname')})),
      login: account.id,
      currency: account.currency,
      vendor: provider,
      amount,
    }
    logEventCustomParams('depositAttempted', firebaseParams)

    const variables = {
      accountId: account.id,
      amount,
      vendor: provider,
      paymentOptionId,
      frontend: frontends.web2.value,
      args: JSON.stringify({
        platform: getPlatform(),
        paymentKey,
        useVirtualPaymentPage,
        cardNumber,
        cardName,
        bonusAmount,
        bonusTerms,
      }),
    }

    this.props.createDepositRequest({variables})
      .then(({data: {newDeposit}}) => {
        const {payment} = newDeposit
        const parsedFormData = JSON.parse(payment.url)
        this.setState({formData: parsedFormData, doSubmitForm: true})
      })
      .catch((e) => {
        this.setState({creatingDeposit: false})
        onError && onError(e)
      })
  }

  render() {
    const {classes, canProceed} = this.props
    const {form: {cardNumber, cardName}, errors, creatingDeposit, formData, doSubmitForm} = this.state

    return (
      <React.Fragment>
        <SelfPostingForm formData={formData} doSubmitForm={doSubmitForm} />
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              <Trans {...messages.enterCardDetails} />
            </Typography>
          </Grid>
          <Grid item xs={12} lg={12}>
            <FormControl variant="standard" className={classes.formControl} fullWidth>
              <InputLabel htmlFor="rpnpay-card-number">
                <Trans {...messages.cardNumber} />
              </InputLabel>
              <Input
                id="rpnpay-card-number"
                name="rpnpay-card-number"
                type="text"
                error={!!errors.cardNumber}
                value={cardNumber}
                onChange={(e) => this.handleChange('cardNumber', e.target.value)}
                inputProps={{pattern: '[0-9]*', inputMode:'numeric', autoComplete: 'off'}}
                startAdornment={
                  <InputAdornment position="start" >
                    <CreditCardIcon />
                  </InputAdornment>
                }
              />
              {
                errors.cardNumber &&
                <FormHelperText className={classes.error}><Trans {...errors.cardNumber} /></FormHelperText>
              }
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={12}>
            <FormControl variant="standard" className={classes.formControl} fullWidth>
              <InputLabel htmlFor="rpnpay-card-name">
                <Trans {...messages.fullName} />
              </InputLabel>
              <Input
                id="rpnpay-card-name"
                type="text"
                error={!!errors.cardName}
                value={cardName}
                onChange={(e) => this.handleChange('cardName', e.target.value)}
              />
              {
                errors.cardName &&
                <FormHelperText className={classes.error}><Trans {...errors.cardName} /></FormHelperText>
              }
            </FormControl>
          </Grid>
          <PaymentActionButton
            loading={creatingDeposit}
            disable={canProceed || !this.formIsValid()}
            onClick={() => this.doCreateDeposit()}
          />
        </Grid>
      </React.Fragment>
    )
  }
}

RPNPayProvider.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  providerProperties: PropTypes.shape({
    name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    paymentKey: PropTypes.string.isRequired,
    paymentOptionId: PropTypes.number.isRequired,
  }).isRequired,
  amount: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

export default compose(
  withStyles(style),
  withNamespaces(),
  withCreateDeposit
)(RPNPayProvider)
