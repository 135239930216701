import React from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import withStyles from '@mui/styles/withStyles'
import {Link} from 'react-router-dom'
import {getCurrentTheme, isDarkTheme} from '../../../common/utils/general'
import Images from '../Images'
import config from '../../../config'

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.default
  },
  menuIcon: {
    display: 'inline-block',
  },
  [theme.breakpoints.up('sm')]: {
    menuIcon: {
      display: 'none'
    }
  },
  logo: {
    maxWidth:230,
    maxHeight: 80,
  },
})



export const HeaderDialog = (props) => {
  const {logoFileName, logoWhiteFileName, name: companyName} = config
  const themePreference = getCurrentTheme()
  let logo = ''
  try {
    const logoAsset = isDarkTheme(themePreference) ? logoWhiteFileName : logoFileName
    logo = Images[logoAsset]
  } catch (e)
  {
    //
  }

  return (<AppBar position="sticky"
    elevation={0}
    color="default"
    className={props.classes.root}
  >
    <Toolbar>
      {!props.linkTo && <img src={logo} alt={companyName} className={props.classes.logo}/>}
      {props.linkTo && <Link to={props.linkTo}><img src={logo} alt={props.appName} className={props.classes.logo}/></Link>}
    </Toolbar>
  </AppBar>)
}

export default withStyles(styles, {withTheme: true})(HeaderDialog)
