import React from 'react'
import {map, get, flowRight as compose, upperCase} from 'lodash'
import PropTypes from 'prop-types'
import Input from '@mui/material/Input'
import Select from '@mui/material/Select'
import {Mutation} from 'react-apollo'
import {countries} from '@bdswiss/common-enums'
import {withNamespaces, Trans} from 'react-i18next'
import useMediaQuery from '@mui/material/useMediaQuery'
import Typography from '@mui/material/Typography'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Grid from '@mui/material/Grid'
import withStyles from '@mui/styles/withStyles'
import makeStyles from '@mui/styles/makeStyles'
import messages from '../../../../assets/messages'
import {BEGIN_DEPOSIT_MUTATION, CREATE_DEPOSIT_FROM_SAVED_PCT_MUTATION} from '../../../../graphql/mutations'
import {CLIENT_DATA_QUERY} from '../../../../graphql/queries'
import LoadingButton from '../../../Common/LoadingButton'
import config from '../../../../config'

export const withCreateDeposit = (PaymentMethodComponent) => (class extends React.Component {
  render() {
    return <Mutation mutation={BEGIN_DEPOSIT_MUTATION} refetchQueries={[{query: CLIENT_DATA_QUERY}]}>
      {(createDepositRequest) =>  <PaymentMethodComponent createDepositRequest={createDepositRequest} {...this.props} />}
    </Mutation>
  }
})

export const withCreateDepositPCT = (PaymentMethodComponent) => (class extends React.Component {
  render() {
    return <Mutation mutation={CREATE_DEPOSIT_FROM_SAVED_PCT_MUTATION}>
      {(createDepositRequestPCT) =>  <PaymentMethodComponent createDepositRequestPCT={createDepositRequestPCT} {...this.props} />}
    </Mutation>
  }
})

const useProceedToPayStyle = makeStyles((theme) => ({
  rootGrid: {
    flexGrow: 1,
  },
  topSpacing: {
    marginTop: theme.spacing(5),
  }
}))

export const PaymentActionButton = (props) => {
  const {onClick, loading, disable, buttonMessage, noMargin, country} = props
  const onSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const classes = useProceedToPayStyle()
  const btnMessage = buttonMessage ||  messages[`proceedToPay${upperCase(country)}`] || messages.proceedToPay

  return (
    <Grid container justifyContent="center" alignItems="center" direction="row" className={classes.rootGrid}>
      <Grid item className={onSmallScreen || noMargin ? '' : classes.topSpacing}>
        <LoadingButton
          id='loadingButton'
          onClick={onClick}
          disabled={ disable || loading || false}
          hideProgressBar = {disable}
          noMargin={noMargin}
        >
          <Trans {...btnMessage} />
        </LoadingButton>
      </Grid>
    </Grid>
  )
}

PaymentActionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  disable: PropTypes.bool,
  buttonMessage: PropTypes.shape({
    i18nKey: PropTypes.string.isRequired,
    defaults: PropTypes.string.isRequired
  })
}

const billingAddressDetailsStyle = (theme) => ({
  formControl: {
    margin: `${theme.spacing(1)} 0`
  },
  address: {
    fontWeight: 400,
    margin: '10px 0 0 0',
  },
  addressSubtitle: {
    marginTop: 15,
  },
  countrySelect: {
    height: '1.3em',
    paddingBottom: 10
  },
  error: {
    color: theme.palette.red.color
  },
})

export const BillingAddressDetails = compose(
  withNamespaces(),
  withStyles(billingAddressDetailsStyle),
)((props) => {
  const {classes, errors, form, handleChange, onCardFocus} = props

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Typography variant='h4' className={classes.address}><Trans {...messages.billingAddress} /></Typography>
        <Typography variant='subtitle2' className={classes.addressSubtitle}><Trans {...messages.billingAddressNote} /></Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="standard" className={classes.formControl} fullWidth>
          <InputLabel htmlFor="street">
            <Trans {...messages.streetLabel} />
          </InputLabel>
          <Input
            id="street"
            type="text"
            name="street"
            error={!!errors.street}
            onChange={(e) => handleChange(e)}
            onFocus={() => onCardFocus && onCardFocus()}
            inputProps={{autoComplete: 'off'}}
          />
          {
            errors.street &&
            <FormHelperText className={classes.error}><Trans {...errors.street} /></FormHelperText>
          }
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl variant="standard" className={classes.formControl} fullWidth>
          <InputLabel htmlFor="city">
            <Trans {...messages.cityLabel} />
          </InputLabel>
          <Input
            id="city"
            type="text"
            name="city"
            error={!!errors.city}
            onChange={(e) => handleChange(e)}
            inputProps={{autoComplete: 'off'}}
          />
          {
            errors.city &&
            <FormHelperText className={classes.error}><Trans {...errors.city} /></FormHelperText>
          }
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl variant="standard" className={classes.formControl} fullWidth>
          <InputLabel htmlFor="zip">
            <Trans {...messages.zipLabel} />
          </InputLabel>
          <Input
            id="zip"
            type="text"
            name="zip"
            error={!!errors.zip}
            onChange={(e) => handleChange(e)}
            inputProps={{autoComplete: 'off'}}
          />
          {
            errors.zip &&
            <FormHelperText className={classes.error}><Trans {...errors.zip} /></FormHelperText>
          }
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="standard" fullWidth className={classes.formControl}>
          <InputLabel htmlFor="country">
            <Trans {...messages.countryLabel} />
          </InputLabel>
          <Select
            variant="standard"
            native
            id="country"
            name="country"
            value={get(form, 'country', '')}
            onChange={(e) => handleChange(e)}
            error={errors.country}
            inputProps={{className: classes.countrySelect}}>
            {map(countries, (option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </React.Fragment>
  )
})

BillingAddressDetails.propTypes = {
  form: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
}

const companyDetailsStyle = (theme) => ({
  bold: {
    fontWeight: theme.typography.fontWeightMedium
  },
  spacing: {
    padding: theme.spacing(2)
  },
  underline: {
    textDecoration: 'underline'
  }
})

export const CompanyDetails = compose(
  withNamespaces(),
  withStyles(companyDetailsStyle),
)(({company, classes}) => {
  const {cardPayMessage} = config
  if (!cardPayMessage) return null

  return <Grid container className={classes.spacing}>
    <Grid item xs="12">
      <Typography paragraph>
        <Trans {...messages.depositTermsAndConditions} components={[
          <a
            target="_blank"
            rel="noopener noreferrer"
            className={classes.underline}
            href="https://viverno.com/app/uploads/Viverno_TCs_V1.0_July-2022-1.pdf"
          >terms and conditions</a>
        ]}/>
      </Typography>
    </Grid>
    <Grid item xs="12" sm="4">
      <Typography className={classes.bold}>
        <Trans {...messages.companyName}/>:
      </Typography>
    </Grid>
    <Grid item xs="12" sm="8">
      <Typography>BDSwiss Holding Ltd</Typography>
    </Grid>
    <Grid item xs="12" sm="4">
      <Typography className={classes.bold}>
        <Trans {...messages.companyAddress}/>:
      </Typography>
    </Grid>
    <Grid item xs="12" sm="8">
      <Typography>Apostolou Andrea Street 11,</Typography>
      <Typography>Hyper Tower, 5th Floor,</Typography>
      <Typography>4007 Mesa Yeitonia,</Typography>
      <Typography>Limassol, Cyprus.</Typography>
    </Grid>
  </Grid>}
)
