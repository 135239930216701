import React, {Component} from 'react'
import {map, get, flowRight as compose, maxBy} from 'lodash'
import {Trans, withNamespaces} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import messages from '../../assets/messages'
import Typography from '@mui/material/Typography'
import classNames from 'classnames'
import CloseIcon from '@mui/icons-material/Close'
import Grid from '@mui/material/Grid'
import {isMobile, setCookie, getCookie} from '../../common/utils'
import Button from '@mui/material/Button'
import {AlertDialog} from '../Common/Dialog'
import {referAndEarnDetails} from '../../common/utils/uioptions'
import AppContext from '../Common/contexts/AppContext'

const styles = theme => ({
  cardDiv: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 8,
    padding: 12,
    margin: 12
  },
  closeIcon: {
    position: 'absolute',
    [theme.direction === 'rtl' ? 'left' : 'right']: 30,
    color: theme.palette.secondary.main,
  },
  pointer: {
    cursor: 'pointer',
  },
  ol: {
    color: theme.palette.primary.main,
    fontSize: 16,
    paddingLeft: 15
  },
  li: {
    paddingLeft: 5
  },
  img: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      maxHeight: 250,
      maxWidth: 250
    },
  },
  subTitle: {
    paddingBottom: 15,
  },
  howDoesWork: {
    paddingBottom: 0,
    marginTop: -110
  },
  link: {
    '&:hover':{
      backgroundColor: theme.palette.background.default,
    },
  },
  closeIconGrid: {
    margin: -12
  },
  linkExternal: {
    color:theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight: 400
  },
})

class Instructions extends Component {
  static contextType = AppContext

  constructor(props) {
    const cookie = getCookie('instructions_closed')
    super(props)
    this.state = {
      show: !isMobile() && !cookie,
      cookie
    }
  }

  closeInstructions() {
    const {cookie} = this.state
    this.setState({show: false})
    !cookie && setCookie('instructions_closed', true, 30)
  }

  renderContent() {
    const {classes, awards, referCampaign} = this.props
    const {companyObject} = this.context
    const company = companyObject.whiteLabelBrand || companyObject.brandLabel
    const {referrerInstructions, minTrades, days, currency, minDeposit} = referAndEarnDetails
    const referTiers = get(referCampaign, 'referTiers')
    const maxDepositRule = maxBy(referTiers, 'ftdAmount')

    return <Grid container className={classNames(!isMobile() && classes.cardDiv)} spacing={3}>
      {!isMobile() && <Grid item xs={12} className={classes.closeIconGrid}>
        <CloseIcon className={classNames(classes.closeIcon, classes.pointer)} onClick={() => this.closeInstructions()}/>
      </Grid>}
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {!isMobile() && <Grid item xs={12} className={classes.subTitle}>
            <Typography variant='h2'><Trans {...messages.howDoesWork} /></Typography>
          </Grid>}
          <Grid item xs={12}>
            <Typography variant='body1'><Trans {...messages.claimRewardText1} values={{company, amountReferrer: `$${get(maxDepositRule, 'referrerReward')}`}}/></Typography>
            <Typography variant='body1'><Trans {...messages.claimRewardText2}/></Typography>
          </Grid>
          <Grid item xs={12}>
            <ol className={classes.ol}>
              {map(referrerInstructions, (key) => <li key={key} className={classes.li}>
                <Typography variant='body1'>
                  <Trans {...messages[key]} values={{amountReferrer: `${currency}${get(awards, 'referrerAward')}` , minDeposit: `${currency}${minDeposit}`, minTrades, days}}/></Typography>
              </li>)
              }
            </ol>
          </Grid>
          <Typography variant='body1'><Trans {...messages.viewFullTerms} components={[<a href={get(referCampaign, 'referTerms')} target='_blank' rel='noreferrer noopener' className={classes.linkExternal}>terms</a>]}/></Typography>
        </Grid>
      </Grid>
    </Grid>
  }

  render() {
    const {classes, t} = this.props
    const {show} = this.state

    return <Grid container spacing={0} justifyContent="flex-end">
      {!show ? <Button color="primary" className={classNames(classes.link, classes.howDoesWork)}
        onClick={() => this.setState({show: true})}>
        <Trans {...messages.howDoesWork} />
      </Button> : !isMobile() && show ? this.renderContent() : <AlertDialog
        title={t(messages.howDoesWork.i18nKey, messages.howDoesWork.defaults)}
        open={show}
        onClose={() => this.closeInstructions()}
      >
        {this.renderContent()}
      </AlertDialog>}
    </Grid>
  }
}

export default  compose(
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
)(Instructions)
