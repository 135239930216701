import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {flowRight as compose, get} from 'lodash'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import withStyles from '@mui/styles/withStyles'
import {getPlatform, getSourceBasedOnLocationPrevPath, logEventCustomParams} from '../../../../common/utils'
import {frontends} from '@bdswiss/common-enums'
import {withCreateDeposit, PaymentActionButton} from './helpers'

const style = (theme) => ({
  iframe: {
    overflow: 'scroll',
    width: '100%',
    minHeight: '99%'
  },
  fullHeight: {
    height: '100%',
  },
  note: {
    color: theme.palette.red.color,
  },
  root: {
    zIndex: 2147483640
  }
})

class GenericHtmlIFrameProvider extends Component {

  state = {gettingHtml: false, htmlResponse: ''}

  getHtml() {
    const {account, providerProperties:{provider, paymentKey, id: paymentOptionId}, amount, onError, useVirtualPaymentPage, bonusAmount, bonusTerms,
      history: {location}} = this.props

    this.setState({gettingHtml: true})

    const firebaseParams = {
      source: getSourceBasedOnLocationPrevPath(get(location, 'state', {prevPath: get(location, 'pathname')})),
      login: account.id,
      currency: account.currency,
      vendor: provider,
      amount,
    }
    logEventCustomParams('depositAttempted', firebaseParams)

    const variables = {
      accountId: account.id,
      amount,
      vendor: provider,
      paymentOptionId,
      frontend: frontends.web2.value,
      args: JSON.stringify({
        platform: getPlatform(),
        paymentKey,
        useVirtualPaymentPage,
        bonusAmount,
        bonusTerms,
      }),
    }

    this.props.createDepositRequest({variables})
      .then(({data: {newDeposit}}) => {
        const {payment} = newDeposit
        const parsedFormData = payment.url
        return parsedFormData
      })
      .then((data) => {
        // html response
        this.setState({htmlResponse: data})
      })
      .catch((e) => {
        this.setState({gettingHtml: false})
        onError && onError(e)
      })
  }

  changeLocation(e) {
    try {
      if (get(e, 'target.contentWindow.location.host') === window.location.host) {
        e.persist()
        this.setState({selfPostHtmlResponse: ''}, () => {
          window.location.replace(get(e, 'target.contentWindow.location.href'))
        })
      }
    } catch (e) {
      // ignore iframe error
    }
  }

  render() {
    const {canProceed, classes, providerProperties: {name: providerName}} = this.props
    const {gettingHtml, htmlResponse} = this.state
    return (
      <React.Fragment>
        <PaymentActionButton
          disable={canProceed}
          loading={gettingHtml}
          onClick={() => this.getHtml()}
        />
        {htmlResponse &&
          <Dialog fullWidth disableEscapeKeyDown fullScreen open aria-labelledby={providerName} classes={{root: classes.root}} disableEnforceFocus>
            <DialogContent className={classes.fullHeight}>
              <iframe frameBorder="0" title={providerName} srcDoc={htmlResponse} className={classes.iframe} onLoad={(e) => this.changeLocation(e)} ></iframe>
            </DialogContent>
          </Dialog>
        }
      </React.Fragment>
    )
  }
}

GenericHtmlIFrameProvider.propTypes = {
  createDepositRequest: PropTypes.func.isRequired,
  providerProperties: PropTypes.shape({
    name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    paymentKey: PropTypes.string.isRequired,
    paymentOptionId: PropTypes.number,
  }).isRequired,
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired
  }).isRequired,
  amount: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

export default compose(
  withStyles(style),
  withCreateDeposit,
)(GenericHtmlIFrameProvider)
