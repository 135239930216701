import React from 'react'
import {Redirect, Route, Switch, withRouter} from 'react-router-dom'
import GeneralStep from './GeneralStep'
import BeneficiariesDirectorsStep from './BeneficiariesDirectorsStep'
import RepresentativeStep from './RepresentativeStep'
import FinancialInformationStep from './FinancialInfoStep'
import AppropiatenessTestStep from './AppropiatenessTestStep'
import DocumentsStep from './DocumentsStep'
import SigningStep from './SigningStep'

const CorporateRoutes = ({match: {path}}) =>(
  <Switch>
    <Route exact path={`${path}/general`} component={GeneralStep} />
    <Route exact path={`${path}/beneficiariesDirectors`} component={BeneficiariesDirectorsStep} />
    <Route exact path={`${path}/representative`} component={RepresentativeStep} />
    <Route exact path={`${path}/financialInformation`} component={FinancialInformationStep} />
    <Route exact path={`${path}/appropiatenessTest`} component={AppropiatenessTestStep} />
    <Route exact path={`${path}/documents`} component={DocumentsStep} />
    <Route exact path={`${path}/signing`} component={SigningStep} />
    <Route path={`${path}/:parentPath/:childPath+`} render={({match}) => <Redirect to={`${path}/${match.params.parentPath}`} />} />
    <Redirect from={`${path}/`} to={`${path}`} />
  </Switch>
)

export default withRouter(CorporateRoutes)
