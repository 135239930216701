import React from 'react'
import {withApollo} from 'react-apollo'
import {withRouter} from 'react-router-dom'
import {flowRight as compose, concat, forEach, map, isEmpty} from 'lodash'
import {Trans, withNamespaces} from 'react-i18next'
import PageBody from '../Common/PageBody'
import PageTitle from '../Common/PageTitle'
import messages from '../../assets/messages'
import AppContext from '../Common/contexts/AppContext'
import {TRADING_VIDEOS_QUERY} from '../../graphql/queries'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import {isMobile, isIos} from '../../common/utils'
import {CustomDialog} from '../Common/Dialog'

const styles = theme => ({
  thumbnailImage: {
    borderRadius: '6px'
  },
  thumbnailTitle: {
    paddingTop: '8px',
    fontSize: '14px',
    lineHeight: '1.5rem',
  },
  closeIcon: {
    position: 'absolute',
    top: 15,
    right: 17,
    color: theme.palette.grey[400],
    cursor: 'pointer',
    [`${theme.breakpoints.down('lg')} and (orientation: portrait)`]: {
      zIndex: 1,
      top: isIos ? 14 : 15,
      right: isIos() ? 47 : 17,
    },
    [`${theme.breakpoints.down('lg')} and (orientation: landscape)`]: {
      top: 4,
      right: isIos() ? 60 : 30,
      zIndex: 1,
    },
  },
  dialog: {
    '& .MuiDialogTitle-root': {
      textAlign: 'left',
    },
  },
  dialogMobile: {
    '& .MuiDialogContent-root': {
      overflowY: 'hidden'
    },
    '& .MuiBackdrop-root': {
      backgroundColor: 'black'
    },
    '& .MuiPaper-root': {
      backgroundColor: 'black'
    },
    '& .MuiDialog-paper': {
      margin: 0
    }
  },
  item: {
    padding: `${theme.spacing(2)} ${theme.spacing(2)} 0px 0px`
  },
  itemMobile: {
    padding: `${theme.spacing(2)} ${theme.spacing(2)} 0px ${theme.spacing(2)}`
  }
})

class EducationalVideos extends React.Component {
  static contextType = AppContext

  constructor() {
    super()
    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    const {client: apolloClient, history} = this.props

    apolloClient.query({
      query: TRADING_VIDEOS_QUERY,
      variables: {
        clientId: this.context.clientId,
      }
    })
      .then((res) => {
        let tradingVideos = []

        forEach(res.data.getTradingVideos, (video) => {
          tradingVideos = concat(tradingVideos, video.tradingVideos)
        })

        this.setState({loading: false, tradingVideos: tradingVideos})
      })
      .catch((error) => {
        history.pop()
      })
  }

  handleVideoModalOpen(selectedVideo) {
    this.setState({showVideoModal: true, selectedVideo: selectedVideo})
  }

  handleVideoModalClose() {
    this.setState({showVideoModal: false, selectedVideo: null})
  }

  render() {
    const {t, history, classes} = this.props
    const {tradingVideos, selectedVideo} = this.state

    return (
      <React.Fragment>
        <PageTitle
          hideArrow={!isMobile()}
          title={t(messages.tradingVideos.i18nKey, messages.tradingVideos.defaults)}
          onBack={() => isMobile ? history.push('/more') : history.push('/accounts')}
        />
        <PageBody removePadding>
          <Grid container>
            {map(tradingVideos, (video) => {
              const {key, thumbUrl} = video

              return (
                <Grid item
                  xs={isMobile() ? 12 : 3}
                  className={isMobile() ? classes.itemMobile : classes.item}
                  key={`${key}`}
                  onClick={() => this.handleVideoModalOpen(video)}
                >
                  <Grid container direction='column'>
                    <Grid item>
                      <img className={classes.thumbnailImage} src={thumbUrl} alt='test' width='100%' />
                    </Grid>
                    <Grid item>
                      <Typography className={classes.thumbnailTitle} variant='body2'> <Trans {...messages[`tradingVideos${key}Title`]} /> </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
          {!isEmpty(selectedVideo) &&
            <CustomDialog
              open={this.state.showVideoModal}
              maxWidth={isMobile() ? 'xs' : 'lg'}
              title={isMobile() ? '' : <Trans {...messages[`tradingVideos${selectedVideo.key}Title`]} />}
              customClasses={{
                closeIcon: classes.closeIcon,
                dialog: isMobile() ? classes.dialogMobile : classes.dialog
              }}
              onClose={() => this.handleVideoModalClose()}
              onCloseIcon={() => this.handleVideoModalClose()}
            >
              <video controls autoPlay={isMobile() ? true : false} src={selectedVideo.videoUrl} width='100%' />
            </CustomDialog>
          }
        </PageBody>
      </React.Fragment>
    )
  }

}
export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  withApollo,
  withRouter,
)(EducationalVideos)
