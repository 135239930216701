import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {Trans, withNamespaces} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import messages from '../../assets/messages'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import classNames from 'classnames'
import HelpIcon from '@mui/icons-material/HelpOutline'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import Popper from '@mui/material/Popper'
import Tooltip from '@mui/material/Tooltip'
import {get, flowRight as compose, replace, split, map, orderBy, filter, size, isEmpty, toLower, forEach, maxBy} from 'lodash'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import AppContext from '../Common/contexts/AppContext'
import {referAndEarnDetails} from '../../common/utils/uioptions'
import {logEventCustomParams} from '../../common/utils/firebaseEvents'
import Images from '../Common/Images'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import Email from '@mui/icons-material/Email'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import Avatar from '@mui/material/Avatar'
import TextField from '@mui/material/TextField'
import {AlertDialog} from '../Common/Dialog'
import FormHelperText from '@mui/material/FormHelperText'
import {graphql} from 'react-apollo'
import {SEND_REFER_FRIENDS_EMAIL} from '../../graphql/mutations'
import {Button, Table, TableBody, TableHead, TableRow, TableCell} from '@mui/material'
import {isMobile} from '../../common/utils/browser'
import config from '../../config'
import {validateEmail} from '../../common/utils/validations'

const styles = theme => ({
  displayFlex: {
    display: 'flex'
  },
  icon: {
    marginBottom: -3,
    marginLeft: 3,
    fontSize: 16,
    color: theme.palette.secondary.main,
  },
  cardHover: {
    width: '100%',
    '&:hover':{
      backgroundColor: theme.palette.cardBackground.color,
      cursor: 'default',
    },
  },
  opacity: {
    opacity: 0.5
  },
  inline: {
    display: 'inline-block'
  },
  pointer: {
    cursor: 'pointer',
  },
  link: {
    color: theme.palette.primary.main,
  },
  customTooltip:{
    fontSize: 12
  },
  toolTipStatus: {
    marginLeft: 79,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 7
    },
  },
  toolTipPartner: {
    marginLeft: 7
  },
  verticalAlign:{
    verticalAlign: 'middle'
  },
  avatar: {
    display: 'inline-flex',
    margin: 12,
    backgroundColor: theme.palette.primary.main,
    '&:hover':{
      cursor: 'pointer',
    },
  },
  popper: {
    padding: 24,
    boxShadow: '0 1px 4px 0 rgba(0,0,0,0.04), 0 4px 16px 0 rgba(0,0,0,0.08)',
    backgroundColor: 'inherit',
    textAlign: 'center'
  },
  inlineFlex: {
    display: 'inline-flex'
  },
  verticalCenter: {
    alignItems: 'center',
    height: '100%',
  },
  blurryText: {
    color: 'transparent',
    textShadow: '0 0 5px rgba(0,0,0,0.5)',
  },
  limitReached: {
    backgroundColor: theme.palette.lightyellow.color,
    padding: 12,
    margin: '0 12px 12px',
  },
  error:{
    color: theme.palette.red.color,
  },
  referralLinkSection: {
    marginLeft: 12
  },
  startIcon: {
    marginLeft: 0,
    marginTight: 8,
    marginBottom: 5,
  },
  depositNote: {
    marginBottom: 20
  }
})

class ReferralDetails extends Component {
  static contextType = AppContext
  state = {
    open: false,
    openStatus: false,
    clickedEvent: false,
    openShare: false,
    anchorElReveal: null,
    sendEmails: false,
    openDeposit: false,
    openReveal: false,
    errorSizeEmail: false,
    buttonLoading: false,
    buttonStatus: '',
    submitMessageError: '',
    emailsError: '',
  }

  copyLink() {
    logEventCustomParams('referAndEarnCopyLinkClicked', {
      userId: get(this.context, 'clientId'),
    })
    this.setState({clickedEvent: true})
  }

  handleClickShareButton = event => {
    const {currentTarget} = event
    this.setState(state => ({
      anchorElShare: currentTarget,
      openShare: !state.openShare,
    }))
  }

  renderReferralLink(referralDetails, company) {
    const {t, classes, reveleadLink, referCampaign} = this.props
    const {brand} = config
    const {currency} = referAndEarnDetails
    const splitLink = split(replace(referralDetails.link, toLower(brand),`${toLower(brand)}###`),'###')
    const referTiers = get(referCampaign, 'referTiers')
    const maxDepositRule = maxBy(referTiers, 'ftdAmount')

    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Typography variant='caption' className={classes.inline}><Trans {...messages.yourPartnerLink} /></Typography>
          <ClickAwayListener onClickAway={() => this.setState({open: false})}>
            <Tooltip title={t(messages.partnerMessage.i18nKey, {company, amount: `${currency}${get(maxDepositRule, 'referrerReward')}`})}
              placement="top"
              PopperProps={{
                disablePortal: true,
              }}
              onClose={() => this.setState({open: false})}
              open={this.state.open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              className={classNames(classes.pointer, classes.icon)}
              classes={{tooltip: classNames(classes.customTooltip, classes.toolTipPartner)}}>
              <HelpIcon className={classes.icon} onClick={() => this.setState({open: true})}/>
            </Tooltip>
          </ClickAwayListener>
        </Grid>
        {reveleadLink ? <Grid item xs={12}>
          <ClickAwayListener onClickAway={() => this.setState({openShare: false, anchorElShare: ''})}>
            <span>
              <Typography variant='body1' className={classes.inlineFlex}>
                {get(referralDetails, 'link')}
              </Typography>
              <Button
                onClick={this.handleClickShareButton}
                variant="text"
                size="medium"
                color="primary"
                className={classes.referralLinkSection}
                startIcon={<SaveAltIcon classes={{root: classes.startIcon}}/>}
              >
                <Trans {...messages.share} />
              </Button>
              <Popper id={'popper'} open={this.state.openShare} anchorEl={this.state.anchorElShare} placement="top"
                modifiers={{
                  arrow: {
                    enabled: true,
                    element: this.state.anchorElShare,
                  }}}
                className={classes.popper}>
                <div>
                  <div>
                    <div>
                      <Typography variant='body1'>
                        <Trans {...messages.shareReferralLink} />
                      </Typography>
                    </div>
                    <div>
                      <Avatar className={classes.avatar} onClick={() => this.setState({openShare: false, anchorElShare: ''})}>
                        <CopyToClipboard text={get(referralDetails, 'link')}>
                          <FileCopyOutlinedIcon />
                        </CopyToClipboard>
                      </Avatar>
                      <Avatar className={classes.avatar} onClick={() => this.setState({sendEmails: true, openShare: false, anchorElShare: ''})}>
                        <Email />
                      </Avatar>
                    </div>
                  </div>
                </div>
              </Popper>
            </span>
          </ClickAwayListener>
        </Grid> : <Grid item xs={12}>
          {map(splitLink, (link, index) => <span key={index} className={index && classes.blurryText}>{index ? replace(link, /./g, '*') : link}</span>)}
          <Button
            onClick={() => this.setState({openReveal: true})}
            variant="text"
            size="medium"
            color="primary"
          >
            <Trans {...messages.revealLink} />
          </Button>
        </Grid>}
      </React.Fragment>
    )
  }

  renderRerralLimitPartner() {
    const {classes} = this.props
    return <Grid item xs={12} className={classes.limitReached}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h4'><Trans {...messages.referralLimit} /></Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='subtitle2'><Trans {...messages.continueReferringClients} /></Typography>
        </Grid>
      </Grid>
    </Grid>
  }

  verificationLink() {
    logEventCustomParams('referAndEarnVerifyClicked', {
      userId: get(this.context, 'clientId'),
    })
    return this.props.history.push('/accounts/verification')
  }

  renderReferralUnverified() {
    const {classes} = this.props

    return <React.Fragment>
      <Grid item xs={12} className={classNames(classes.displayFlex, classes.verticalCenter)}>
        <Typography variant='body2' className={classNames(classes.inline, classes.verticalAlign)}><Trans {...messages.referralLinkUnavailable} /></Typography>
        <Button
          onClick={() => this.verificationLink()}
          variant="text"
          size="medium"
          color="primary"
          className={classes.button}
        >
          <Trans {...messages.verifyAccount} />
        </Button>
      </Grid>
    </React.Fragment>
  }

  sendEmails(emailsToSend) {
    const {t} = this.props
    this.setState({buttonLoading: true, buttonStatus: ''})
    this.props.sendReferFriendsEmail({variables: {emailsToSend}})
      .then(() => {
        this.setState({buttonLoading: false, buttonStatus: 'success'})
        this.context.showNotification({
          type: 'document-upload',
          status: 'success',
          title: 'emailsHaveBeenSent',
          buttonMessage: this.props.t(messages.close.i18nKey, messages.close.defaults),
          onClose: () => this.setState({sendEmails: false, emails: [], buttonStatus: ''}),
        })
      })
      .catch(() => {
        this.setState({buttonLoading: false, buttonStatus: 'failure', submitMessageError: t(messages.somethingWrongError.i18nKey,messages.somethingWrongError.defaults)})
      })
  }

  validateEmails() {
    const {t} = this.props
    const {emails} = this.state
    const {referEmailsFriends} = referAndEarnDetails
    const emailsToSend = filter(split(replace(replace(emails, /[\r\n]/gm, ','), /\s/g,''), ','))

    if (isEmpty(emailsToSend)) return
    if (size(emailsToSend) > referEmailsFriends) {
      this.setState({errorSizeEmail: true})
      return
    }
    let valid = true
    forEach(emailsToSend, (email) => {
      if (!validateEmail(email)) {
        valid = false
        this.setState({emailsError: t(messages.emailsValidation.i18nKey,messages.emailsValidation.defaults)})
        return false
      }
    })
    valid && this.sendEmails(emailsToSend)
  }

  render() {
    const {classes, referralDetails, t, limitReached, isIbOrAffiliate, isVerified, referCampaign,
      revealLink, closeReveal, statusReveal, loadingReveal} = this.props
    const {companyObject} = this.context
    const company = companyObject.whiteLabelBrand || companyObject.brandLabel
    const {referEmailsFriends, minDeposit, currency} = referAndEarnDetails
    const {sendEmails, emails, emailsError, openReveal, errorSizeEmail, buttonLoading, buttonStatus, submitMessageError} = this.state

    const referTiers = orderBy(get(referCampaign, 'referTiers'), 'ftdAmount', 'desc')

    return (
      <Grid container spacing={3}>
        {(!limitReached && !isIbOrAffiliate) ? <Grid item xs={12} className={classes.displayFlex}>
          {!isMobile() && <Grid item>
            <img className={''} src={Images['refer_icon.png']} alt={'Referral Rink'} />
          </Grid>}
          <Grid item xs={12} md={10} className={classes.referralLinkSection}>
            {!isVerified ? this.renderReferralUnverified() : this.renderReferralLink(referralDetails, company)}
          </Grid>
        </Grid> : this.renderRerralLimitPartner()}
        {!limitReached && <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Trans {...messages.deposit} />
                  <ClickAwayListener onClickAway={() => this.setState({openDeposit: false})}>
                    <Tooltip title={t(messages.amountFtdUsdReferral.i18nKey, messages.amountFtdUsdReferral.defaults)}
                      placement="top"
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={() => this.setState({openDeposit: false})}
                      open={this.state.openDeposit}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      className={classNames(classes.pointer, classes.icon)}
                      classes={{tooltip: classNames(classes.customTooltip, classes.toolTipPartner)}}>
                      <HelpIcon className={classes.icon} onClick={() => this.setState({openDeposit: true})}/>
                    </Tooltip>
                  </ClickAwayListener>
                </TableCell>
                <TableCell><Trans {...messages.requiredVolume} /></TableCell>
                <TableCell><Trans {...messages.yourReward} /></TableCell>
                <TableCell><Trans {...messages.referalsReward} /></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {map(referTiers, (tier, index) => {
                let prevFtdAmount = parseFloat(get(referTiers, `[${index+1}].ftdAmount`) || minDeposit)
                if (index && prevFtdAmount !== minDeposit) prevFtdAmount = prevFtdAmount + 1
                return <TableRow key={index}>
                  <TableCell>{(!index) ? `${get(tier, 'ftdAmount')}+` : `${currency}${prevFtdAmount} - ${currency}${get(tier, 'ftdAmount')}`}</TableCell>
                  <TableCell>{get(tier, 'volumeRequirement')} <Trans {...messages.lots} /></TableCell>
                  <TableCell>{currency}{get(tier, 'referrerReward')}</TableCell>
                  <TableCell>{currency}{get(tier, 'refereeReward')}</TableCell>
                </TableRow>})}
            </TableBody>
          </Table>
        </Grid>}
        {!limitReached && <Grid item xs={12} className={classes.depositNote}>
          <Typography variant='subtitle2'><Trans {...messages.noteDepositsRefer} /></Typography>
        </Grid>}
        <AlertDialog
          open={sendEmails}
          onClose={() => this.setState({sendEmails: false, buttonLoading: false, buttonStatus: '', submitMessageError: '', emails: '', errorSizeEmail: '', emailsError: ''})}
          title={<Trans {...messages.emailYourFriends} />}
          agreeText={t(messages.send.i18nKey, messages.send.defaults)}
          onAgree={() => this.validateEmails()}
          disagreeText={t(messages.cancel.i18nKey, messages.cancel.defaults)}
          onDisagree={() => this.setState({sendEmails: false, buttonLoading: false, buttonStatus: '', submitMessageError: '', emails: '', errorSizeEmail: '', emailsError: ''})}
          errorText={errorSizeEmail ? <Trans {...messages.sendMaxErrorMeail} values={{max: referEmailsFriends}}/> :
            (emailsError ? emailsError : (submitMessageError ? submitMessageError : ''))}
          hideProgressBar={errorSizeEmail || !!buttonStatus || !!emailsError}
          buttonLoading={buttonLoading}
          buttonStatus={buttonStatus}
          buttonDisabled={!!buttonStatus}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography variant='body1'><Trans {...messages.inputYourFriends} /></Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                type="text"
                value={emails}
                label={<Trans {...messages.addEmail} />}
                onChange={(e) => this.setState({emails: e.target.value, emailsError: '', status: '', errorSizeEmail: false})}
                error={!!emailsError}
                fullWidth={true}
                multiline />
              <FormHelperText className={classes.helperText}><Trans {...messages.seperateEmails} /></FormHelperText>
            </Grid>
          </Grid>
        </AlertDialog>
        <AlertDialog
          open={openReveal && !closeReveal}
          onClose={() => this.setState({openReveal: false})}
          title={<Trans {...messages.revealLink} />}
          agreeText={t(messages.acceptRevealLink.i18nKey, messages.acceptRevealLink.defaults)}
          onAgree={() => revealLink()}
          disagreeText={t(messages.cancel.i18nKey, messages.cancel.defaults)}
          onDisagree={() => this.setState({openReveal: false})}
          buttonLoading={loadingReveal}
          buttonStatus={statusReveal}
        >
          <Typography variant='body1'><Trans {...messages.revealAcceptTerms} components={[<a href={get(referCampaign, 'referTerms')} target='_blank' rel='noreferrer noopener' className={classNames(classes.pointer, classes.link)}>terms</a>]}/></Typography>
        </AlertDialog>
      </Grid>
    )
  }
}

export default  compose(
  withNamespaces(),
  withRouter,
  withStyles(styles, {withTheme: true}),
  graphql(SEND_REFER_FRIENDS_EMAIL, {
    name: 'sendReferFriendsEmail',
  }),
)(ReferralDetails)
