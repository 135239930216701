import React, {Component} from 'react'
import {graphql} from 'react-apollo'
import {withNamespaces, Trans} from 'react-i18next'
import {Loading} from '../../../Common/Loading'
import {countries} from '@bdswiss/common-enums'
import {isEmpty, get, filter, flowRight as compose, some} from 'lodash'
import Grid from '@mui/material/Grid'
import messages from '../../../../assets/messages'
import {PROFILE_SETTINGS_QUERY, CLIENT_DATA_QUERY} from '../../../../graphql/queries'
import AppContext from '../../../Common/contexts/AppContext'
import {isMobile, getCountries} from '../../../../common/utils'
import withStyles from '@mui/styles/withStyles'
import {withRouter} from 'react-router-dom'
import {UPDATE_LOCAL_DEPOSITOR_MUTATION} from '../../../../graphql/mutations'
import {FormHelperText, TextField} from '@mui/material'
import LoadingButton from '../../../Common/LoadingButton'
import CountriesSelect from '../../../Common/CountriesSelect'
import {validateEmail, validatePhone} from '../../../../common/utils/validations'

const gridScroll = 'scroll-grid'

const styles = theme => ({
  textLeft:{
    textAlign: 'left'
  },
  button:{
    marginTop:0
  },
  errorMessage: {
    color: theme.palette.error.main,
    display: 'inline-block',
    verticalAlign: 'bottom',
    margin: '13px 13px 13px 0'
  },
})

class LocalDepositorForm extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    this.state = {
      form: {
        email: '',
        prefix: '',
        phone: ''
      },
      prefixFlag: '',
      errors: {},
    }
  }

  componentDidMount() {
    const {viewer, history} = this.props

    if (!get(viewer, 'isLocalDepositor')) {
      history.push('/settings/profile')
    }
  }

  handleClose(history, state) {
    return history.push('/settings/profile')
  }

  validateForm() {
    const {history} = this.props
    const {form, form: {prefix, phone, email}} = this.state
    const errors = this.state.errors || {}
    for (const field of Object.keys(form)) {
      if (field === 'email') {
        errors[field] = isEmpty(form[field]) || !validateEmail(form[field])
      } else if (field === 'phone') {
        errors[field] = isEmpty(form[field]) || !validatePhone(form[field])
      } else {
        errors[field] = isEmpty(form[field])
      }
    }

    if (some(errors)) {
      this.setState({errors})
      return
    }
    const data = {
      localDepositorPhone: prefix + phone,
      localDepositorEmail: email
    }

    this.setState({loading:true})
    this.props.updateLocalDepositor({variables: data}).then((res) => {
      this.setState({loading:false})
      this.context.showNotification({
        type: 'document-upload',
        status: 'success',
        title: 'localDepositorSubmitted',
        buttonMessage: this.props.t(messages.close.i18nKey, messages.close.defaults),
        onClose: () => history.push('/settings'),
      })
    })
      .catch((e) => {
        this.setState({loading:false})
      })
  }

  handleChange(prop, value) {
    this.setState(state => ({
      lastInput: prop,
      form: {...state.form,[prop]: value,},
      errors: {...state.errors,[prop]: !value},
    }))
  }

  async getCountries(product, company) {
    await getCountries(product, company)
      .then((res) => {
        this.setState({allowedCountries: res.countries})
      })
      .catch((err) => err)
  }

  handleChangePrefix(value) {
    this.setState({showCountriesPrefix: false, prefixFlag: value})
  }

  setStateOuter = (prop, value) => {
    this.setState({[prop]: value})
  }

  render() {
    const {t, loading, classes, showPhone, showEmail, viewer} = this.props
    const {submitLoading, status, form: {email, phone}, errors, prefixFlag}= this.state
    if (loading || !viewer) return <Loading />
    const countryList = filter(countries, a => !a.hidden && !a.forbidden)
    return (
      <Grid container id={gridScroll} spacing={2}>
        <Grid item xs={12}>
          {!showPhone && <Grid container spacing={3}>
            <Grid item xs={!isMobile() ? 3 : 6} id="prefixGrid" className={classes.textLeft}>
              <CountriesSelect
                countryList={countryList}
                handleChangeField={this.handleChangePrefix.bind(this)}
                handleChange={this.handleChange.bind(this)}
                setStateOuter={this.setStateOuter.bind(this)}
                errors={errors}
                value={prefixFlag}
                name="prefix"
                label={t(messages.phoneCode.i18nKey, messages.phoneCode.defaults)}
                phonePrefix
              />
            </Grid>
            <Grid item xs={!isMobile() ? 3 : 6}>
              <TextField
                variant="standard"
                fullWidth
                required
                id="phone"
                name="phone"
                label={t(messages.phone.i18nKey, messages.phone.defaults)}
                error={errors.phone}
                onChange={(e) => this.handleChange('phone', e.target.value.replace(/^0+/, ''))}
                value={phone}
                inputProps={{pattern: '[0-9]*', inputMode:'numeric'}} />
            </Grid>
          </Grid>}
        </Grid>
        <Grid item xs={12}>
          {showPhone && <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="standard"
                fullWidth
                required
                id="email"
                label={t(messages.phone.i18nKey, messages.phone.defaults)}
                onChange={(e) => this.handleChange('email', e.target.value)}
                error={get(errors, 'phone', false)}
                disabled
                value={get(viewer, 'localDepositorPhone')} />
            </Grid>
          </Grid>}
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="standard"
                fullWidth
                required
                id="email"
                label={t(messages.email.i18nKey, messages.email.defaults)}
                onChange={(e) => this.handleChange('email', e.target.value)}
                error={get(errors, 'email', false)}
                value={!showEmail ? email : get(viewer, 'localDepositorEmail')}
                disabled={showEmail} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <LoadingButton
                fullWidth={isMobile()}
                id='loadingButton'
                onClick={()=> this.validateForm()}
                disabled={submitLoading || status==='failure' || !!showEmail || !!showPhone}
                hideProgressBar={!submitLoading}
                status={status}
                className={classes.button}
                noMargin={true}
              ><Trans {...messages.submit} />
              </LoadingButton>
              {status==='failure' &&
                <FormHelperText className={classes.errorMessage}><Trans {...messages.generalError} /></FormHelperText>}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default compose(
  withNamespaces(),
  withRouter,
  withStyles(styles, {withTheme: true}),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading}, data}) => {
      const clientId = get(data, 'viewer.id')
      const viewer = get(data, 'viewer')
      return {
        error,
        loading,
        clientId,
        viewer,
      }
    }
  }),
  graphql(UPDATE_LOCAL_DEPOSITOR_MUTATION, {
    name: 'updateLocalDepositor',
    options: {
      refetchQueries: [{query: PROFILE_SETTINGS_QUERY}],
    },
    update: cache => {
      cache.writeData({data: {props: []}})
    },
  }),
)(LocalDepositorForm)
