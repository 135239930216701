import React, {Component} from 'react'

export class NotFound extends Component {
  render() {
    return (
      <div>
        404 - Not Found
      </div>
    )
  }
}

export default NotFound
