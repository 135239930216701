import React from 'react'
import PropTypes from 'prop-types'
import {map, values, first, flowRight as compose, get} from 'lodash'
import {withNamespaces, Trans} from 'react-i18next'
import {withRouter} from 'react-router-dom'
import withStyles from '@mui/styles/withStyles'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import FormGroup from '@mui/material/FormGroup'
import FormLabel from '@mui/material/FormLabel'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import Images from '../../Common/Images'
import messages from '../../../assets/messages'
import {isMobile} from '../../../common/utils/browser'
import queryString from 'qs'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import {InputLabel} from '@mui/material'
import {getAccountLabel, getAccountSubtype} from '../../../common/utils/accounts'
import AppContext from '../../Common/contexts/AppContext'

const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    marginTop: 0,
    marginBottom: 0,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 4,
    },
  },
  filterArea: {
    backgroundColor: theme.palette.extralightgrey.color,
    padding: theme.spacing(1),
  },
  caption: {
    color: theme.palette.secondary.main,
    fontSize: ' 0.85rem',
    textAlign: 'justify',
  },
  filterSection: {
    margin: 0,
    padding: '12px 0',
  },
  filtersDiv: {
    textAlign: 'right',
    minWidth: 130,
  },
  filters: {
    color: theme.palette.secondary.main,
    fontSize: 16,
    margin: 0,
    padding: 0,
  },
  filterIcon: {
    marginLeft: 8,
  },
  fullWidthMob: {
    width: '100%',
    margin: 0,
    padding: 0,
  },
  checkbox: {
    padding: '6px 6px 6px 0',
    marginLeft: -3,
  },
  rows: {
    margin: '10px 5px',
  },
})

class PaymentsFilter extends React.Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    this.state = {
      ...props.filter,
      limit: props.rows || first(props.transactionsLimits),
    }
  }

  componentDidMount() {
    const urlParams = queryString.parse(this.props.location.search.replace('?', ''))
    const filter = this.props.filter
    this.setState({filter})
    if (urlParams.subscription) {
      this.setFilter({subscription: true})
      this.setState({isToggleOn: true})
    }
  }

  setFilter(newFilter) {
    this.setState(newFilter)
    this.props.onChange({...this.state, ...newFilter})
  }

  rowSelection = transactionsLimits => (
    <FormControl variant="standard" fullWidth>
      <InputLabel>
        <Trans {...messages.showMoreItems} />
      </InputLabel>
      <Select
        variant="standard"
        value={this.state.limit || first(transactionsLimits)}
        onChange={e => this.setFilter({limit: e.target.value})}>
        {transactionsLimits.map(limit => (
          <MenuItem key={limit} value={limit}>
            {limit}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )

  render() {
    const {accounts, classes, t, transactionsLimits} = this.props
    const {
      isToggleOn,
      completed,
      pending,
      rejected,
      deposit,
      withdrawal,
      subscription,
      transfer,
      ldTransfer,
      dividend,
    } = this.state
    const {locale} = this.context
    const accountId = this.props.accountId || this.state.accountId
    const allClientsAccountTypesSelectOption = {
      value: 'all',
      label: `${t(messages.allAccounts.i18nKey, messages.allAccounts.defaults)}`,
      key: 'all',
      id: 'all',
    }

    const accountTypesSelectOptions = this.props.accountId
      ? [...values(accounts)]
      : [allClientsAccountTypesSelectOption, ...values(accounts)]
    const toggleFilters = isMobile() ? true : isToggleOn

    return (
      <Grid container className={!isMobile() ? classes.filterArea : ''}>
        <Grid container spacing={isMobile() ? 0 : 3} direction={isMobile() ? 'column' : 'row'} alignItems="flex-start">
          {!toggleFilters && <Grid item xs={10}></Grid>}
          {toggleFilters && !this.props.accountId && (
            <Grid item xs className={isMobile() ? classes.fullWidthMob : ''}>
              <FormControl variant="standard" component="fieldset" className={classes.formControl}>
                <FormLabel component="legend" className={classes.caption}>
                  {t(messages.account.i18nKey, messages.account.defaults)}
                </FormLabel>
                <FormGroup className={isMobile() ? classes.fullWidthMob : ''}>
                  <TextField
                    variant="standard"
                    id="standard-select-currency-native"
                    select
                    value={accountId}
                    name="isAccount"
                    onChange={e => this.setFilter({accountId: e.target.value})}
                    SelectProps={{
                      native: true,
                    }}
                    margin="normal"
                    InputProps={{
                      classes: {root: isMobile() ? classes.fullWidthMob : ''},
                    }}>
                    {map(accountTypesSelectOptions, option => (
                      <option key={option.id} value={option.id}>
                        {option.id !== 'all'
                          ? `${getAccountLabel(option, locale, getAccountSubtype(get(option, 'accountSubtype')), t)} - ${option.remoteId || option?.id}`
                          : option.label}
                      </option>
                    ))}
                  </TextField>
                </FormGroup>
              </FormControl>
            </Grid>
          )}
          {toggleFilters && (
            <Grid item xs className={classes.filterSection}>
              <FormControl variant="standard" component="fieldset" className={classes.formControl}>
                <FormLabel component="legend" className={classes.caption}>
                  {t(messages.transactionStatus.i18nKey, messages.transactionStatus.defaults)}
                </FormLabel>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={completed}
                        name="isCompleted"
                        onChange={(_, completed) => this.setFilter({completed})}
                        value="completed"
                        color="primary"
                        className={isMobile() ? classes.checkbox : ''}
                      />
                    }
                    label={t(messages.completed.i18nKey, messages.completed.defaults)}
                    className={isMobile() ? classes.fullWidthMob : ''}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={pending}
                        name="isPending"
                        onChange={(_, pending) => this.setFilter({pending})}
                        value="pending"
                        color="primary"
                        className={isMobile() ? classes.checkbox : ''}
                      />
                    }
                    label={t(messages.pending.i18nKey, messages.pending.defaults)}
                    className={isMobile() ? classes.fullWidthMob : ''}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={rejected}
                        name="isRejected"
                        onChange={(_, rejected) => this.setFilter({rejected})}
                        value="rejected"
                        color="primary"
                        className={isMobile() ? classes.checkbox : ''}
                      />
                    }
                    label={t(messages.rejected.i18nKey, messages.rejected.defaults)}
                    className={isMobile() ? classes.fullWidthMob : ''}
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          )}
          {toggleFilters && (
            <Grid item xs>
              <FormControl variant="standard" component="fieldset" className={classes.formControl}>
                <FormLabel component="legend" className={classes.caption}>
                  {t(messages.transactionTypes.i18nKey, messages.transactionTypes.defaults)}
                </FormLabel>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={withdrawal}
                        name="isWithdrawal"
                        onChange={(_, withdrawal) => this.setFilter({withdrawal})}
                        color="primary"
                        className={isMobile() ? classes.checkbox : ''}
                      />
                    }
                    label={t(messages.withdrawal.i18nKey, messages.withdrawal.defaults)}
                    className={isMobile() ? classes.fullWidthMob : ''}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={deposit}
                        name="isDeposit"
                        onChange={(_, deposit) => this.setFilter({deposit})}
                        color="primary"
                        className={isMobile() ? classes.checkbox : ''}
                      />
                    }
                    label={t(messages.deposit.i18nKey, messages.deposit.defaults)}
                    className={isMobile() ? classes.fullWidthMob : ''}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={ldTransfer}
                        name="isLdTransfer"
                        onChange={(_, ldTransfer) => this.setFilter({ldTransfer})}
                        color="primary"
                        className={isMobile() ? classes.checkbox : ''}
                      />
                    }
                    label={t(messages.ldTransfers.i18nKey, messages.ldTransfers.defaults)}
                    className={isMobile() ? classes.fullWidthMob : ''}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={transfer}
                        name="isTranser"
                        onChange={(_, transfer) => this.setFilter({transfer})}
                        color="primary"
                        className={isMobile() ? classes.checkbox : ''}
                      />
                    }
                    label={t(messages.transfer.i18nKey, messages.transfer.defaults)}
                    className={isMobile() ? classes.fullWidthMob : ''}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={subscription}
                        name="isSubscription"
                        onChange={(_, subscription) => this.setFilter({subscription})}
                        color="primary"
                        className={isMobile() ? classes.checkbox : ''}
                      />
                    }
                    label={t(messages.subscription.i18nKey, messages.subscription.defaults)}
                    className={isMobile() ? classes.fullWidthMob : ''}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={dividend}
                        name="isDividend"
                        onChange={(_, dividend) => this.setFilter({dividend})}
                        color="primary"
                        className={isMobile() ? classes.checkbox : ''}
                      />
                    }
                    label={t(messages.dividend.i18nKey, messages.dividend.defaults)}
                    className={isMobile() ? classes.fullWidthMob : ''}
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          )}
          {!isMobile() && (
            <Grid item xs={2} className={classes.filtersDiv}>
              <Grid container>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    size="small"
                    style={{background: 'transparent', boxShadow: 'none'}}
                    className={classes.filters}
                    onClick={() => this.setState({isToggleOn: !this.state.isToggleOn})}
                  >
                    <img
                      src={Images['filterIcon.png']}
                      alt={t(messages.filters.i18nKey, messages.filters.defaults)}
                      className={classes.filterIcon}
                    />
                    {t(messages.filters.i18nKey, messages.filters.defaults)}
                    {toggleFilters ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                  </Button>
                </Grid>
                {toggleFilters && (
                  <Grid item xs={12}>
                    {this.rowSelection(transactionsLimits)}
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
          {isMobile() && (
            <Grid container spacing={2}>
              <Grid item xs={3} className={classes.rows}>
                {this.rowSelection(transactionsLimits)}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    )
  }
}

PaymentsFilter.propTypes = {
  accountId: PropTypes.number,
  accounts: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default compose(withStyles(styles), withRouter, withNamespaces())(PaymentsFilter)
