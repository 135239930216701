import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import Grid from '@mui/material/Grid'
import classNames from 'classnames'
import isFinite from 'lodash/isFinite'
import get from 'lodash/get'

const styles = (theme) => ({
  header: {
    marginBottom: ({mb}) => theme.spacing(isFinite(mb) ? mb : 2),
    marginTop: ({mt}) => theme.spacing(isFinite(mt) ? mt : 2),
  },
  action: {
    marginTop: 10,
    marginRight: 10,
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    }
  },
})


const PageSubTitle = props => <Grid container justifyContent={props.subTitleCenter ? 'center' : 'space-between'} >
  <Grid item >
    <Typography component={props.component ? props.component : 'h3'} variant={props.variant ? props.variant : 'h3'} gutterBottom className={classNames(props.classes.header, get(props, 'customClasses.header'))}>
      {props.children}
    </Typography>
  </Grid>
  {props.rightAction && <Grid item className={props.classes.action}>
    {props.rightAction}
  </Grid>}
</Grid>


PageSubTitle.propTypes = {
  children: PropTypes.any.isRequired,
  classes: PropTypes.object.isRequired,
  mt: PropTypes.number,
  mb: PropTypes.number,
}
export default withStyles(styles)(PageSubTitle)
