import React, {Component} from 'react'
import {flowRight as compose, toLower} from 'lodash'
import withStyles from '@mui/styles/withStyles'
import classNames from 'classnames'
import Card from '@mui/material/Card'
import messages from '../../assets/messages'
import {Trans, withNamespaces} from 'react-i18next'
import {withRouter} from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Images from './Images'
import CreditCardIcon from '@mui/icons-material/CreditCardRounded'
import Typography from '@mui/material/Typography'

const styles = theme => ({
  logo:{
    width:50,
    padding:'0 5px',
    color: theme.palette.secondary.dark
  },
  paddingBottom:{
    paddingBottom:'1rem'
  },
  methodBox:{
    paddingLeft: 5,
  },
  highlightedText:{
    fontWeight : 400
  }
})
export class BillingInfoCard extends Component {

  constructor(props) {
    super(props)

    this.state = {
      openChangeModal: false,
      openCancelReactivateModal: false,
      loadingProceed: false,
      loadingCancelReactivate: false,
    }
  }

  render() {
    const {classes, billingInfo, t} = this.props
    return (
      <Grid container>
        <Grid item className={classes.paddingBottom} xs={12}>
          <Card>
            <Grid container>
              <Grid item className={classNames(classes.title,classes.paddingBottom)} xs={12}>
                <Typography variant='subtitle1'><Trans {...messages.paymentMethod} /></Typography>
              </Grid>
            </Grid >
            <Grid container alignItems='center'>
              <Grid item xs={2}>
                {billingInfo && Images[`${toLower(billingInfo.cardType)}.png`] && <img alt={billingInfo.cardType} src={Images[`${toLower(billingInfo.cardType)}.png`]} className={classes.logo} />}
                {!Images[`${toLower(billingInfo.cardType)}.png`] && <CreditCardIcon className={classes.logo}/>}
              </Grid>
              <Grid item xs={9} className={classes.methodBox}>
                <Typography variant='body1'>**** <span className={classes.highlightedText}>{billingInfo.lastFourDigits}</span></Typography>
                <Typography variant='body1'>{t(messages.expiryDate.i18nKey, messages.expiryDate.defaults)} <span className={classes.highlightedText}>{billingInfo.month}/{billingInfo.year}</span></Typography>
              </Grid>
            </Grid >
          </Card>
        </Grid>
      </Grid>
    )
  }
}

export default compose(
  withNamespaces(),
  withStyles(styles),
  withRouter)(BillingInfoCard)
