import React from 'react'
import {Trans} from 'react-i18next'

import {styled} from '@mui/system'
import {Button} from '@mui/material'

const DownloadButton = styled(Button)(({theme}) => ({
  '&.MuiButton-root': {
    width: '100%',
    margin: '20px 0 5px',
    padding: '10px 16px',
    fontSize: 14,
    fontWeight: 400,
    borderRadius: 8,
    color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255)' : 'rgba(0, 0, 0, 0.87)',
    backgroundColor: theme.palette.mode === 'dark' ? '#5D5E62' : '#E3E6ED',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? '#4C4C4F' : '#CDD0D7',
    },
    '&:active': {
      backgroundColor: theme.palette.mode === 'dark' ? '#666667' : '#D7D7D7',
    },
  },
}))

const ModalDownloadButton = ({active, translationKey, defaultTranslation, ...props}) => (
  <DownloadButton className={active} {...props}>
    <Trans i18nKey={translationKey} defaults={defaultTranslation} />
  </DownloadButton>
)

export default ModalDownloadButton
