import React, {Component} from 'react'
import {withNamespaces, Trans} from 'react-i18next'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import {CustomDialog} from '../Dialog'
import LoadingButton from '../LoadingButton'
import messages from '../../../assets/messages'
import {map, flowRight as compose} from 'lodash'
import config from '../../../config'
import withStyles from '@mui/styles/withStyles'
import Typography from '@mui/material/Typography'
import Images from '../Images'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import ClickAwayListener from '@mui/material/ClickAwayListener'

const styles = theme => ({
  description: {
    fontSize:15,
    fontStyle:'italic'
  },
  tooltipImg: {
    width:300,
    [theme.breakpoints.down('md')]: {
      width:280
    }
  },
  exampleText:{
    lineHeight:1
  },
  exampleBtn:{
    padding:'0 11px',
    '&:hover':{
      background:'none'
    }
  },
  tooltipBackground:{
    background:'none'
  }
})

export class ElectronicVerifcationModal extends Component {

  constructor(props) {
    super(props)
    this.state = {
      form: {
        nationalId: '',
      },
      open: false
    }
  }

  handleTooltipClose = () => {
    this.setState({open: false})
  }

  handleTooltipToggle = () => {
    this.setState(state => ({open: !state.open}))
  }

  validateForm(name, value) {
    this.setState(state => ({
      form: {
        ...state.form,
        [name]: value
      },
      formErrors: {
        ...state.formErrors,
        [name]: !value,
      }
    }))
  }

  onSubmit () {
    const {form} = this.state
    const {onSubmit} = this.props

    this.setState({closeModal: true})
    onSubmit(form)
  }

  renderModal() {
    const {formErrors, form} = this.state
    const {fields, country, classes} = this.props
    const {gdcSuppportedCountriesExtraFields} = config
    return (
      <Grid container spacing={2}>
        <Grid item>
          <Trans {...messages.eVerificationModal} />
        </Grid>
        {map(fields, (field) =>
          (<React.Fragment key={field}>
            {messages[`${field}${country.toUpperCase()}`] && (<Grid item className={classes.description}>
              <Trans {...messages.note} /><Trans {...messages[`${field}${country.toUpperCase()}`]} />
              <ClickAwayListener onClickAway={this.handleTooltipClose}>
                <Tooltip
                  placement="bottom"
                  onClose={this.handleTooltipClose}
                  open={this.state.open}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  classes={{tooltip:classes.tooltipBackground}}
                  title={
                    <React.Fragment>
                      <img src={Images[`${field}${country.toUpperCase()}.jpg`] || Images[`${field}${country.toUpperCase()}.png`]} alt={field} className={classes.tooltipImg} />
                    </React.Fragment>
                  }
                >
                  <Button onClick={this.handleTooltipToggle} className={classes.exampleBtn}><Typography  variant='caption' color='primary' className={classes.exampleText}><Trans  {...messages[`${field}WhereIs`]} /></Typography></Button>
                </Tooltip>
              </ClickAwayListener>

            </Grid>)}
            <Grid item xs={12} >
              <FormControl variant="standard" fullWidth>
                <TextField
                  variant="standard"
                  type="text"
                  value={form[field]}
                  label={ <Trans {...messages[gdcSuppportedCountriesExtraFields[field].label]} /> }
                  onChange={(e)=>this.validateForm(field, e.target.value)}
                  error={!form[field]}
                  helperText={(!form[field] && <Trans {...messages.requiredField} />)} />
              </FormControl>
            </Grid>
          </React.Fragment>
          )
        )}
        <Grid item  xs={12}>
          <LoadingButton
            fullWidth
            hideProgressBar={!formErrors}
            disabled={!formErrors}
            onClick={() => this.onSubmit()}
            onKeyPress={(e) => e.charCode === 13 && this.onSubmit()}
          > <Trans {...messages.submit} />
          </LoadingButton>
        </Grid>
      </Grid>
    )
  }

  render() {
    const {open, onClose} = this.props
    const {closeModal} = this.state
    const openModal = (closeModal) ? !closeModal : open
    return (
      <CustomDialog
        open={openModal}
        onClose={onClose}
        title={ <Trans {...messages.attention} />}
      >
        {this.renderModal()}
      </CustomDialog>
    )
  }
}

export default compose(
  withStyles( styles, {withTheme: true}),
  withNamespaces())(ElectronicVerifcationModal)
