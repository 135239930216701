import React from 'react'
import {withNamespaces, Trans} from 'react-i18next'
import {get, map, reject, isNil, isEmpty, flowRight as compose} from 'lodash'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import {graphql} from 'react-apollo'
import Grid from '@mui/material/Grid'
import Avatar from '@mui/material/Avatar'
import Card from '@mui/material/Card'
import messages from '../../../assets/messages'
import Images from '../../Common/Images'
import {isMobile} from '../../../common/utils/browser'
import {withRouter, Redirect} from 'react-router-dom'
import {IB_CLIENTS_QUERY, PAYMENTS_ACCOUNTS_QUERY, SPOA_AUTHORIZATION_QUERY, RELATED_IB_QUERY} from '../../../graphql/queries'
import {Loading} from '../../Common/Loading'
import {hasIntroducingBrokerAccount} from '../../../common/utils/accounts'
import classNames from 'classnames'
import {findCompany} from '../../../common/utils'
import {logEventCustomParams} from '../../../common/utils/firebaseEvents'
import config from '../../../config'
import AppContext from '../../Common/contexts/AppContext'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  avatar: {
    margin: 10,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.lightblue.color,
    [theme.breakpoints.down('sm')]: {
      margin: '0 10px 0 0',
      height: 35,
      width: 35,
      lineHeight: 37,
    }
  },
  blueAvatar: {
    backgroundColor: theme.palette.lightblue.color,
  },
  redAvatar: {
    backgroundColor: theme.palette.lightred.color,
  },
  subtitle: {
    color: theme.palette.grey.color,
    fontWeight:300
  },
  tool: {
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      right: 0,
      top: -15
    }
  },
  toolOuter: {
    [theme.breakpoints.down('sm')]: {
      position: 'relative'
    }
  }
})

class TransferOptions extends React.Component {
  static contextType = AppContext

  state = {
  }

  componentDidMount() {
    logEventCustomParams('transferIntent', {
      userId: get(this.context, 'clientId'),
    })
  }

  render() {
    const {classes, t, loading, ibClientsLoading, clientsIbLoading, ibClients, clientsIb, history, match:{path}, accounts, accountsLoading,
      relatedIbsLoading, relatedIbs, canIbTransfer} = this.props
    const {forcedCompany} = config
    if (loading || ibClientsLoading || accountsLoading || clientsIbLoading || relatedIbsLoading) return <Loading />

    const hasIbAccount = hasIntroducingBrokerAccount(accounts)
    const companyObj = findCompany(forcedCompany)
    const company = companyObj.brandLabel
    const hasIbClients = ibClients.length > 0
    const hasIB =  (!isNil(clientsIb) && (!isEmpty(get(clientsIb, 'accounts'))))
    const hasRelatedIbs = relatedIbs.length > 0

    const cards = [
      {key: 'transferFunds', icon: 'transfer-internal.svg', cssClass: 'redAvatar', relativePath: `${path}/transferFunds`, show:true, params:{title:{company:company},subtitle:{company:company}}},
      {key: 'transferSend', icon: 'transfer-external.svg', cssClass: 'redAvatar', relativePath: `${path}/transferSend`, show: false, params:{title:{company:company},subtitle:{company:company}}},
      {key: 'transferSpoa', icon: 'transfer-spoa.svg',  cssClass: 'blueAvatar', relativePath: `${path}/transferSpoa`, show:hasIbClients},
      {key: 'transferSpoaClient', icon: 'transfer-spoa.svg',  cssClass: 'blueAvatar', relativePath: `${path}/transferSpoa`, show:hasIB},
      {key: 'transferIb', icon: 'transfer-spoa.svg',  cssClass: 'blueAvatar', relativePath: `${path}/transferIb`, show: canIbTransfer && hasIbAccount && hasRelatedIbs},
    ]

    const cardsShown = (reject(cards,{'show': false}))
    if (cardsShown.length === 1)
      return <Redirect to={{pathname: `${path}/transferFunds`}}/>

    return (
      <div className={classes.root}>
        <Grid container spacing={isMobile()?1:3}>
          {map(cardsShown, (card)=>
            <Grid item xs={12}key={card.key}>
              <Grid container>
                <Grid item xs={12} md={6} key={card.key}>
                  <Card>
                    <Grid container wrap="nowrap" spacing={isMobile()?1:2} onClick={() => history.push(card.relativePath)} className={classes.toolOuter}>
                      <Grid item>
                        <Avatar className={classNames(classes.avatar,classes[card.cssClass])}>
                          <img src={Images[card.icon]} alt={t(messages[`${card.key}Title`].i18nKey, messages[`${card.key}Title`].defaults)} />
                        </Avatar>
                      </Grid>
                      <Grid item xs>
                        <Typography component="h3" variant="subtitle1">
                          <Trans {...messages[`${card.key}Title`]} values={card.params && card.params.title} />
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom  className={classes.subtitle}>
                          <Trans {...messages[`${card.key}Card`]} values={card.params && card.params.subtitle} />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
    )
  }
}

export default compose(
  withNamespaces(),
  withRouter,
  graphql(IB_CLIENTS_QUERY, {
    props: ({data: {error, loading: ibClientsLoading}, data}) => {
      const ibClients = get(data, 'getIbClients', [])
      return {
        error,
        ibClientsLoading,
        ibClients,
      }
    }
  }),
  graphql(RELATED_IB_QUERY, {
    props: ({data: {error, loading: relatedIbsLoading}, data}) => {
      const relatedIbs = get(data, 'getRelatedIbs', [])
      return {
        error,
        relatedIbsLoading,
        relatedIbs,
      }
    }
  }),
  graphql(SPOA_AUTHORIZATION_QUERY, {
    props: ({data: {error, loading: clientsIbLoading}, data}) => ({
      error,
      clientsIbLoading,
      clientsIb: get(data, 'viewer.spoaRemoteClient'),
    })
  }),
  graphql(PAYMENTS_ACCOUNTS_QUERY, {
    props: ({data: {error, loading: accountsLoading}, data}) => {
      const accounts = reject(get(data, 'viewer.accounts'), (a)=> (a.isArchived || a.isViewOnly))
      const canIbTransfer = get(data, 'viewer.canIbTransfer')
      return {
        error,
        accountsLoading,
        accounts,
        canIbTransfer
      }
    }
  }),
  withStyles(styles, {withTheme: true}),
)(TransferOptions)
