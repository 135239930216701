import React from 'react'
import {Route, Switch, withRouter, Redirect} from 'react-router-dom'
import AccountOverview from './AccountOverview'

const SMPrimeWalletRoutes = ({match: {path,url}}) => (
  <Switch>
    <Redirect exact from={`${path}/transactions`}  to={{pathname: '/transactions/:accountId/deposit', state: {prevPath: url}}}/>
    <Route path={`${path}/:parentPath/:childPath+`} render={({match}) => <Redirect to={`${path}/${match.params.parentPath}`} />} />
    <Route path={`${path}`} component={AccountOverview} />
  </Switch>
)

export default withRouter(SMPrimeWalletRoutes)
