import React, {Component, Fragment} from 'react'
import {flowRight as compose, filter, first, get, isEmpty, last, orderBy} from 'lodash'
import {graphql} from 'react-apollo'
import {Trans, withNamespaces} from 'react-i18next'
import messages from '../../assets/messages'
import {CustomDialog} from '../Common/Dialog'
import {Box, Button, Typography} from '@mui/material'
import {isIBClient, isMobile, logEventCustomParams} from '../../common/utils'
import {REQUEST_CALLBACK_BECOME_PARTNER_MUTATION} from '../../graphql/mutations'
import withStyles from '@mui/styles/withStyles'
import {ReactComponent as TickIcon} from '../../assets/images/tick.svg'
import {ReactComponent as CallbackIcon} from '../../assets/images/callback.svg'
import {withRouter} from 'react-router-dom'
import AppContext from '../Common/contexts/AppContext'
import {accountTypes, companies, dueDiligenceStatuses, kycStatuses} from '@bdswiss/common-enums'
import {CLIENT_DATA_QUERY} from '../../graphql/queries'
import config from '../../config'

const styles = theme => ({
  callBackButton: {
    backgroundColor: '#E3E6ED',
    height: '40px',
    width: '164px',
    padding: '10px 20px 10px 20px',
    borderRadius: '6px',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
  },
  joinButton: {
    height: '40px',
    width: '68px',
    padding: '10px 20px 10px 20px',
    borderRadius: '6px',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
  },
  infos: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  closeIcon: {
    position: 'absolute',
    top: 24,
    [theme.direction === 'rtl' ? 'left' : 'right']: 24,
    color: theme.palette.grey[400],
    cursor: 'pointer'
  },
  dialogTitle: {
    textAlign: 'start'
  },
  infoBox: {
    gap: '16px',
    display: 'grid',
    height: '311px',
    [theme.breakpoints.down('md')]: {
      height: '344px',
      gap: '12px',
    },
  },
  dialog: {
    width: '540px',
    paddingLeft: '0px',
    paddingRight: '0px',
    [theme.breakpoints.down('md')]: {
      width: '325px',
    },
  },
  moreInformations: {
    display: 'flex',
    overflow: 'hidden',
    gap:'16px',
    borderBottom: '1px solid var(--stroke-1, #EBEBEB)',
    paddingLeft: '24px',
    paddingRight: '24px',
    borderTop: '1px solid var(--stroke-1, #EBEBEB)',
    paddingBottom:'16px',
    paddingTop:'16px',
    'align-items': 'center',
    [theme.breakpoints.down('md')]: {
      display: '-webkit-box',
      '-webkit-box-align' : 'center',
      borderTop: 'none',
      borderBottom: 'none',
      paddingTop:'0',
      paddingBottom:'0',
    },
  },
  boxButtons:{
    display: 'flex',
    gap:'16px',
    padding: '16px 24px 0px 24px',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex',
      flexDirection: 'column-reverse',
    },
  },
  infoStyles:{
    display: 'flex',
    gap:'16px',
    paddingLeft: '24px'
  },
  disableCallback:{
    color: '#C2C2C2',
    fontSize: '14px',
    letterSpacing: '0.25px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  enableCallback:{
    fontSize: '14px',
    letterSpacing: '0.25px',
    fontWeight: 400,
    lineHeight: '20px',
    color: theme.palette.mode === 'dark' ? '#0C0C0C': '#000000'
  },
  joinText:{
    color: '#FFFFFF',
    fontSize: '14px',
    letterSpacing: '0.25px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  informations:{
    color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.5px',
  },
  subtitle:{
    color: theme.palette.mode === 'dark' ? '#999999' : '#5C5C5C',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.5px',
  },
  inactiveParnerText:{
    color: theme.palette.secondary.dark,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.5px',
    [theme.breakpoints.down('md')]:{
      color: '#FFFFFF',
    },
  },
  inactivePartnerBox:{
    padding: '0px 24px 16px 24px',
    [theme.breakpoints.down('md')]:{
      padding: '0px 16px 16px 24px',
    },
  }
})
class BecomePartnerDialog extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state= {
      open: true,
      onClose: true,
      data: null,
    }
  }
  componentDidMount() {
    logEventCustomParams('becomePartnerModalPresented', {
      userId: get(this.props, 'viewer.id'),
    })
  }
  renderContent() {
    const {history, classes, viewer} = this.props
    const {showPartnerZone} = config
    const company =  get(viewer, 'company')
    const clientType = get(viewer, 'clientType')
    const filterAccounts = first(filter(accountTypes, {subCategory: 'ib', company: company}))
    const hasPartners = companies[company].hasPartners
    const disableRequestCallback= get(viewer, 'requestCallbackBecomePartner')
    const isIb = isIBClient(clientType)
    const dueDiligences = get(viewer, 'dueDiligences')
    const dueDiligence = last(orderBy(filter(dueDiligences, a => a.status !== dueDiligenceStatuses.rejected.value)))
    const partnerZone = get(viewer, 'kycStatus') === kycStatuses.approved.value && !isEmpty(dueDiligence)
    const conditionsInactivePartner = isIb && partnerZone && showPartnerZone
    return (
      <Fragment>
        {!conditionsInactivePartner && <Box className= {classes.infoBox}>
          <Box style={{paddingLeft: '24px'}}>
            <Typography className={classes.subtitle}>
              <Trans  {...messages.partnershipProgram} />
            </Typography>
          </Box>
          <Box className={classes.infoStyles}>
            <TickIcon></TickIcon>
            <Typography className={classes.informations}>
              <Trans {...messages.becomePartnerInfo1} />
            </Typography>
          </Box>
          <Box className={classes.infoStyles}>
            <TickIcon></TickIcon>
            <Typography className={classes.informations}>
              <Trans {...messages.becomePartnerInfo2} />
            </Typography>
          </Box>
          <Box className={classes.infoStyles}>
            <TickIcon></TickIcon>
            <Typography className={classes.informations}>
              <Trans {...messages.becomePartnerInfo3} />
            </Typography>
          </Box>
          <Box className={classes.infoStyles}>
            <TickIcon></TickIcon>
            <Typography className={classes.informations}>
              <Trans {...messages.becomePartnerInfo4} />
            </Typography>
          </Box>
          <Box className={classes.moreInformations}>
            <CallbackIcon></CallbackIcon>
            <Typography className={classes.subtitle}>
              <Trans  {...messages.moreInformation} />
            </Typography>
          </Box>
        </Box>}
        {conditionsInactivePartner && <Box className={classes.inactivePartnerBox}>
          <Typography className={classes.inactiveParnerText}>
            <Trans {...messages.inactivePartner}/></Typography>
        </Box>}
        <Box className={classes.boxButtons}>
          <Button
            disabled={!!disableRequestCallback}
            onClick={ () => {
              this.handleRequestCallback()
              logEventCustomParams('becomePartnerCallbackRequestAttempted', {
                userId: get(this.props, 'viewer.id'),
              })
            }}
            fullWidth={isMobile()}
            color="secondary"
            size="medium"
            noMargin={true}
            className={classes.callBackButton}
          > <Typography className={!!disableRequestCallback ? classes.disableCallback : classes.enableCallback}>{!!disableRequestCallback ? <Trans {...messages.callbackRequested}/> : <Trans {...messages.requestACallback}/>}</Typography>
          </Button>
          <Button
            autoFocus
            fullWidth={isMobile()}
            color="primary"
            variant="contained"
            size="medium"
            noMargin={true}
            className={classes.joinButton}
            style= {{display: !hasPartners || conditionsInactivePartner ? 'none' : 'flex'}}
            onClick={ () => {
              history.push( {
                pathname: '/accounts/add-account',
                state: {accountType: filterAccounts.key}
              })
              logEventCustomParams('joinPartnerAttempted', {
                userId: get(this.props, 'viewer.id'),
              })
            }}
          > <Typography className={classes.joinText}><Trans  {...messages.join} /> </Typography> </Button>
        </Box>
      </Fragment>
    )}
  handleRequestCallback() {
    this.props.requestCallbackBecomePartner()
      .then((res) => {
        if (res['data']['requestCallbackBecomePartner']) {
          logEventCustomParams('becomePartnerCallbackRequestCompleted', {
            userId: get(this.props, 'viewer.id'),
          })
        }
      })
      .catch((error) => {
        logEventCustomParams('becomePartnerCallbackRequestFailed', {
          userId: get(this.props, 'viewer.id'),
          reason: error
        })
      })
  }

  render() {
    const {open, onClose, classes} = this.props

    return (
      <React.Fragment>
        <CustomDialog
          open={open}
          onClose={onClose}
          closeBtn={true}
          onCloseIcon={onClose}
          customClasses={{closeIcon: classes.closeIcon, dialogTitle: classes.dialogTitle, dialogContent: classes.dialog}}
          title={ <Trans {...messages.becomeAPartner} />}
        >
          {this.renderContent()}
        </CustomDialog>
      </React.Fragment>
    )
  }
}

export default compose(
  withRouter,
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
  graphql(REQUEST_CALLBACK_BECOME_PARTNER_MUTATION, {
    name: 'requestCallbackBecomePartner',
    options: {
      refetchQueries: [{query: CLIENT_DATA_QUERY}],
    }
  })
)(BecomePartnerDialog)

