import React, {Component} from 'react'
import InputLabel from '@mui/material/InputLabel'
import IconButton from '@mui/material/IconButton'
import Visibility from '@mui/icons-material/Visibility'
import FormControl from '@mui/material/FormControl'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Input from '@mui/material/Input'
import FormHelperText from '@mui/material/FormHelperText'
import InputAdornment from '@mui/material/InputAdornment'
import {Trans} from 'react-i18next'
import messages from '../../assets/messages'
import PropTypes from 'prop-types'
export class Password extends Component {

  constructor(props) {
    super(props)
    this.changeCapsState = this.changeCapsState.bind(this)
    this.state = {
      capsActivated: null,
      showCaps: false
    }
  }

  componentDidMount() {
    const input = document.getElementById(this.props.id || 'password')
    input && input.addEventListener('keydown', this.changeCapsState, true)
  }

  changeCapsState(e) {
    if (e instanceof KeyboardEvent) this.setState({capsActivated: e.getModifierState('CapsLock')})
  }

  render() {
    const {showPassword, classes, onChange, onClickShow, error, id, label, errorText, onKeyDown, onKeyPress,
      fullWidth, value, showErrorText, disabled, required} = this.props
    const {capsActivated, showCaps} = this.state
    const showError = (error || (showErrorText && errorText))
    return (
      <FormControl
        variant="standard"
        required={!!required}
        fullWidth
        aria-describedby="password-helper-text"
        className={classes.formControl}>
        <InputLabel htmlFor="password" required={!!required} error={!!showError}>
          <Trans {...messages[label || 'password']} />
        </InputLabel>
        <Input
          id={id || 'password'}
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => onChange(e)}
          error={!!error}
          onFocus={() => this.setState({showCaps: true})}
          onBlur={() => this.setState({showCaps: false})}
          onKeyDown={(e) => onKeyDown && onKeyDown(e)}
          onKeyPress={(e) => onKeyPress && onKeyPress(e)}
          value={value}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={(e) => onClickShow(e)}
                size="large">
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          fullWidth={!!fullWidth}
          disabled={!!disabled}
          required={!!required}
        />
        {showError &&
        <FormHelperText className={classes.error || classes.errorMessage} id="password-helper-text">
          {errorText}
        </FormHelperText>
        }
        {!showError && capsActivated && showCaps &&
        <FormHelperText id="password-caps-lock-notification">
          <Trans {...messages.capsLockON} />
        </FormHelperText>
        }
      </FormControl>
    )
  }
}

Password.propTypes = {
  showPassword: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClickShow: PropTypes.func.isRequired,
}

export default (Password)
