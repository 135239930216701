import React, {Component} from 'react'
import {Trans, withNamespaces} from 'react-i18next'
import {flowRight as compose, get, last, orderBy, find} from 'lodash'
import AppContext from '../Common/contexts/AppContext'
import withStyles from '@mui/styles/withStyles'
import {Link, withRouter} from 'react-router-dom'
import Grid from '@mui/material/Grid'
import {graphql} from 'react-apollo'
import {CLIENT_DATA_QUERY} from '../../graphql/queries'
import CorporateStepper from './CorporateStepper'
import AppropriatenessTest from '../Settings/Profile/AppropriatenessTest'
import {corporateSteps, corporateStatuses, appropTestStatuses} from '@bdswiss/common-enums'
import NotificationBar from '../Common/NotificationBar'
import messages from '../../assets/messages'

const styles = theme => ({
  notificationBox: {
    marginTop: 20,
    position: 'relative'
  },
  infoCTA: {
    color: theme.palette.notificationBar.blueTextColor,
  },
})

class AppropiatenessTestStep extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    this.state = {activeStep: corporateSteps.appropiatenessTest.rank}
  }

  render() {
    const {viewer, classes, history} = this.props
    const {activeStep} = this.state
    const {locale} = this.context
    const corporateStatus = get(viewer, 'corporateStatus')
    const canProcessAppropTest = get(viewer,'canProcessAppropTest')
    const appropTest =  last(orderBy(get(viewer, 'appropTests'), ['createdAt']))
    const appropTestStatus = get(appropTest, 'status')

    const disabledCorporate = corporateStatus && (appropTestStatus !== appropTestStatuses.rejected.value
      || (appropTestStatus === appropTestStatuses.rejected.value && !canProcessAppropTest))
    const prevStep = find(corporateSteps, (step) => step.rank === activeStep - 1)

    return <React.Fragment>
      {!corporateStatus && <CorporateStepper activeStep={activeStep - 1} viewerStep={get(viewer, 'corporateSignupStep')}/>}
      {corporateStatus && <NotificationBar
        status="info"
        classes={{notificationBox: classes.notificationBox}}
        title={<Trans {...messages.corporateCannotChangeDetails}
          values={{corporateStatus: corporateStatus && corporateStatuses[corporateStatus].localization.t(locale)}}
          components={[<Link className={classes.infoCTA} to={'/support'}>support</Link>]}
        />}
      />}
      <Grid item xs={12}>
        <AppropriatenessTest corporate={true} activeStep={activeStep} disabledCorporate={disabledCorporate}
          corporateBack={() => history.push(`/corporate/${prevStep.key}`)}/>
      </Grid>
    </React.Fragment>
  }
}

export default compose(
  withNamespaces(),
  withRouter,
  withStyles(styles, {withTheme: true}),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {loading, error}, data}) => ({
      loading,
      error,
      viewer: get(data, 'viewer')
    })
  }),
)(AppropiatenessTestStep)
