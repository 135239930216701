import {depositStatuses, withdrawalStatuses} from '@bdswiss/common-enums'

export function getTransactionStatusCategory(status) {
  switch (status) {
    case depositStatuses.failed.key:
    case depositStatuses.rejected.key:
    case depositStatuses.cancelled.key:
    case withdrawalStatuses.rejected.key: {
      return 'rejected'
    }
    case depositStatuses.held.key:
    case depositStatuses.pending.key:
    case depositStatuses.incomplete.key:
    case withdrawalStatuses.pending.key:
    case withdrawalStatuses.notProcessed.key: {
      return 'pending'
    }
    case depositStatuses.reversed.key:
    case depositStatuses.completed.key:
    case depositStatuses.authorized.key:
    case depositStatuses.chargeback.key:
    case withdrawalStatuses.accepted.key:
    case withdrawalStatuses.reversed.key:
    case withdrawalStatuses.processing.key:
    case depositStatuses.partiallyReversed.key:
    case withdrawalStatuses.partiallyReversed.key: {
      return 'completed'
    }
    default: {
      return status
    }
  }
}
