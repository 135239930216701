import React from 'react'
import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'
//TODO: CHANGE THIS TO ENV VARIABLE
const bugsnagClient = bugsnag({
  apiKey: 'cb01135a609a505ab78d0354b6c26d75',
  notifyReleaseStages: ['production'],
  releaseStage: process.env.NODE_ENV,
  appVersion: process.env.BD_APP_VERSION,
  filters: [
    'authtoken',
    /^password$/i,
  ],
  logger: null,
})

bugsnagClient.use(bugsnagReact, React)

export const BugsnagErrorBoundary = bugsnagClient.getPlugin('react')

export default bugsnagClient
