import React from 'react'
import {get, flowRight as compose} from 'lodash'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import {withRouter} from 'react-router-dom'
import {graphql} from 'react-apollo'
import {withNamespaces, Trans} from 'react-i18next'
import {Loading} from '../../Common/Loading'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import Amount from '../../Common/Amount'
import PageSubTitle from '../../Common/PageSubTitle'
import messages from '../../../assets/messages'
import {ACCOUNTS_QUERY} from '../../../graphql/queries'
import {logEventCustomParams} from '../../../common/utils/firebaseEvents'
import AppContext from '../../Common/contexts/AppContext'

const styles = theme => ({
  root: {
    marginTop: 30,
  },
  amount: {
    marginTop: 10,
    display: 'block',
  },
  amountCurrency: {
    fontSize: 24,
    fontWeight: 200,
    padding: '0 3px',
  },
  amountValue: {
    fontSize: 36,
    fontWeight: 200,
  },
  amountFraction: {
    fontSize: 24,
    fontWeight: 200,
  },
})

class AffiliateAccountOverview extends React.Component {
  static contextType = AppContext

  render() {
    const {loading, account, classes} = this.props

    if (loading) return <Loading />
    logEventCustomParams('accountOverview', {
      userId: get(this.context, 'clientId'),
    })
    return (
      <div className={classes.root}>
        <PageSubTitle variant='h3'><Trans {...messages.financialInfo} /></PageSubTitle>
        <Grid container>
          <Grid item lg={3} xs={12}>
            <Typography variant='caption'><Trans {...messages.commission} /></Typography>
            <Amount
              value={account.balance} currency={account.currency} locale='en-US' classes={{
                root: classes.amount,
                currency: classes.amountCurrency,
                value: classes.amountValue,
                fraction: classes.amountFraction
              }} />
          </Grid>
        </Grid>
      </div>
    )
  }
}

AffiliateAccountOverview.propTypes = {
  account: PropTypes.object,
}

export default compose(
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
  withRouter,
  graphql(ACCOUNTS_QUERY, {
    options: (props) => ({variables: {id: Number(props.match.params.accountId)}}),
    props: ({data: {error, loading}, data}) => ({
      error,
      loading,
      account: get(data, 'viewer.accounts.0') || {}
    })
  }),
)(AffiliateAccountOverview)
