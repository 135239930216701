import {flowRight as compose} from 'lodash'
import {Trans, withNamespaces} from 'react-i18next'
import messages from '../../../../assets/messages'
import {useState} from 'react'
import {REQUEST_ACCESS_TO_MANAGE_COMPETITIONS_MUTATION} from '../../../../graphql/competitions/mutations'
import {graphql} from 'react-apollo'
import NotificationBar from '../../../Common/NotificationBar'

function RequestBlockToHaveAccessManage(props) {
  const {handleUpdate, requestAccessToManageCompetitions, viewer} = props
  const [isLoading, setIsLoading] = useState(false)

  const submitRequest = async () => {
    if (isLoading) return

    try {
      setIsLoading(true)
      await requestAccessToManageCompetitions({
        variables: {
          memberId: viewer.id
        }
      })
    } catch (e) {
      console.log(e) /* eslint-disable-line */
    } finally {
      setIsLoading(false)
      handleUpdate()
    }
  }

  return (
    <div>
      <NotificationBar
        status={'info'}
        title={<Trans {...messages.requestBodyAccessToCompetitionManage}/>}
        ctaAction={() => submitRequest()}
        ctaMessage={<Trans {...messages.submitARequest}/>}
      >
      </NotificationBar>
    </div>
  )
}


export default compose(
  withNamespaces(),
  graphql(REQUEST_ACCESS_TO_MANAGE_COMPETITIONS_MUTATION, {
    name: 'requestAccessToManageCompetitions',
  }),
)(RequestBlockToHaveAccessManage)
