const accuracyAccountsDarkOverrides = {
  palette: {
    mode: 'dark',
    yellow: {
      color: '#FF9F00',
    },
    grey: {
      color: 'rgba(255,255,255,0.75)',
    },
    lightgrey: {
      color: 'rgba(255,255,255,0.12)',
    },
    extralightgreyBackground: {
      color: 'rgba(255,255,255,0.12)',
    },
  },
}

export default accuracyAccountsDarkOverrides
