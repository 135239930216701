

import React, {Component} from 'react'
import {find, first, findIndex, flowRight as compose, values, get, isEmpty, map, filter, orderBy, last, includes, toString, upperCase} from 'lodash'
import {graphql} from 'react-apollo'
import {Trans, withNamespaces} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import messages from '../../assets/messages'
import PageTitle from '../Common/PageTitle'
import PageBody from '../Common/PageBody'
import PartnerZoneRoutes from './routes'
import AppContext from '../Common/contexts/AppContext'
import {Badge} from '@mui/material'
import classNames from 'classnames'
import moment from 'moment'
import {CLIENT_DATA_QUERY} from '../../graphql/queries'
import config from '../../config'
import {isMenaCountry, isMobile, logEventCustomParams} from '../../common/utils'
import {isIBClient} from '../../common/utils/accounts'
import {dueDiligenceStatuses} from '@bdswiss/common-enums'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  badgeNew: {
    width: 'fit-content',
    height: 16,
    top: 0,
    backgroundColor: theme.palette.red.color,
    color: theme.palette.red.textColor,
    position: theme.direction === 'rtl' ? 'relative' : 'absolute',
    borderRadius: '32px',
    padding: 5,
    [theme.breakpoints.down('sm')]: {
      marginRight: 10,
    },
  },
  loyaltyNewBadge: {
    whiteSpace: 'nowrap'
  },
})

const partnerZonetabs = [
  {key: 'overview', title: messages.overview, relativePath: '/overview', disabled: false, childPaths: []},
  {key: 'loyaltyProgram', title: messages.loyaltyProgram, relativePath: '/loyalty-program', disabled: false, childPaths: []},
  {key: 'promoMaterials', title: messages.promoMaterials, relativePath: '/promo-materials', disabled: false,
    childPaths:['/banner', '/videos', '/brochures', '/logos', '/landing-pages', '/presentations']},
  {key: 'events', title: messages.events, relativePath: '/events', disabled: false, childPaths: []},
  {key: 'tradingCompetition', id: 'tab-ib-comp', title: messages.tradingCompetition, relativePath: '/trading-competition', disabled: false, childPaths: []},
  {key: 'preferences', title: messages.preferences, relativePath: '/preferences', disabled: false, childPaths: []},
  {key: 'suppport', title: messages.supportText, relativePath: '/support', disabled: false, childPaths: []},
]
class PartnerZone extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {
      value: findIndex(partnerZonetabs, first(partnerZonetabs)),
    }
  }

  componentDidMount() {
    const {history, viewer} = this.props
    const {showPartnerZone, common: {partnersLoyaltyAllowed}} = config
    const dueDiligences = get(viewer, 'dueDiligences')
    const dueDiligence = last(orderBy(filter(dueDiligences, (a)=> a.status !== dueDiligenceStatuses.rejected.value)))
    const partnerZone = (!isEmpty(dueDiligence)) || includes(partnersLoyaltyAllowed, toString(get(viewer, 'id')))
    const {clientType} = this.context
    if (!isIBClient(clientType) || !showPartnerZone || !partnerZone) {
      history.push('/accounts')
    }
  }

  handleChange(value) {
    const {history, match} = this.props
    const currentTab = partnerZonetabs[value]
    if (currentTab.key === 'promoMaterials') logEventCustomParams('promoMaterialsTabClicked', {
      platform: isMobile() ? 'mobile' : 'desktop',
      userId: get(this.props, 'viewer.id')
    })
    history.push(`${match.url}${currentTab.relativePath}`)
    this.setState({value: value})
  }

  render() {
    const {t, location, match, history, classes, viewer} = this.props
    const {common:{partnersLoyaltyNewBadgeEndDate, partnersLoyaltyTerminationDate, promoMaterialsNewBadgeEndDate},
      partnersLoyaltyProgram} = config
    // if (includes(promoMaterialsAllowedClientIds, toString(viewer.id))) {
    //   partnerZonetabs = reject(partnerZonetabs, {key: 'promoMaterials'})
    // }
    if (isMenaCountry(upperCase(get(viewer, 'address.country')))) {
      const idx = findIndex(partnerZonetabs, (tab) => tab.key === 'tradingCompetition')
      if (idx > -1) partnerZonetabs.splice(idx, 1)
    }
    const partnersLoyaltyAnalytics = first(get(viewer, 'partnersLoyaltyAnalytics'))
    const today = moment()
    const eligiblePrize = find(get(partnersLoyaltyAnalytics, 'partnersLoyaltyEligibility'), (prize) => moment(get(prize, 'endEligibilityDate')) > today)
    const showNewBadge = today < moment(partnersLoyaltyNewBadgeEndDate)
    const showPromoMaterialsNewBadge = today < moment(promoMaterialsNewBadgeEndDate)
    const partnerLoyaltyTerminated = (today > moment(partnersLoyaltyTerminationDate)) && isEmpty(eligiblePrize)
    const subRoute = values(partnerZonetabs).filter(settings => `${match.url}${settings.relativePath}`===location.pathname
    || (!isEmpty(settings.childPaths) && includes(map(settings.childPaths, (c) => `${match.url}${settings.relativePath}${c}`)), location.pathname)).length > 0
    const currentTab =
    find(partnerZonetabs, (tab) => location.pathname.indexOf(`${match.url}${tab.relativePath}`) > -1) || first(partnerZonetabs)

    const currentTabIndex = findIndex(partnerZonetabs, currentTab)

    return (
      <React.Fragment>
        {subRoute && <PageTitle hideArrow title={t(messages.partnerZone.i18nKey, messages.partnerZone.defaults)}  onBack={() => history.push('/accounts')}>
          <Tabs value={currentTabIndex} onChange={(_, i) => this.handleChange(i)} variant={isMobile() ? 'scrollable' : 'standard'}>
            {map(filter(partnerZonetabs, ['disabled', false]), (tab) => {
              if (tab.key === 'loyaltyProgram' && !partnerLoyaltyTerminated && partnersLoyaltyProgram) {
                return !tab.disabled && <Tab key={tab.key} label={<Badge badgeContent={showNewBadge &&
                  t(messages.new.i18nKey, messages.new.defaults)} classes={{badge: showNewBadge ? classNames(classes.badgeNew) :
                  classNames(classes.invisible)}}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}>{<Trans {...tab.title} />}</Badge>} className={classes.loyaltyNewBadge} />
              }
              else if (tab.key === 'promoMaterials' && showPromoMaterialsNewBadge) {
                return !tab.disabled && <Tab
                  key={tab.key}
                  label={<Badge
                    badgeContent={t(messages.new.i18nKey, messages.new.defaults)}
                    classes={{badge: classes.badgeNew}}
                    invisible={!showPromoMaterialsNewBadge}
                    anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                    <Trans {...tab.title} />
                  </Badge>}/>
              }
              else {
                /* eslint-disable object-curly-spacing */
                return !tab.disabled && <Tab key={tab.key} {...(tab?.id && { id: tab.id })} label={<Trans {...tab.title} />} />
              }
            })}
          </Tabs>
        </PageTitle>
        }
        <PageBody removePadding= {!subRoute}>
          <PartnerZoneRoutes />
        </PageBody>
      </React.Fragment>
    )
  }
}

export default compose(
  withStyles(styles),
  withNamespaces(),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading, viewer}}) => ({error, loading, viewer})
  }),
)(PartnerZone)
