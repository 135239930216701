import gql from 'graphql-tag'
import {APPROP_TEST_FIELDS_FRAGMENT, ACCOUNTS_FIELDS_FRAGMENT, WITHDRAWAL_FIELDS_FRAGMENT, DOCUMENT_FIELDS_FRAGMENT,
  DEPOSIT_FIELDS_FRAGMENT, GLOBAL_QUESTIONNAIRE_QUERY, DUE_DILIGENCE_FIELDS_FRAGMENT,
  SUITABILITY_TEST_FIELDS_FRAGMENT} from './queries'


export const PAMM_SSO_LINK_MUTATION = gql`
  mutation($accountId: Int!) {
    data: createPAMMSingleSignOnLink(accountId: $accountId) {
      link
    }
  }
`

export const AFFILIATE_SSO_LINK_QUERY = gql`
  query($accountId: Int!) {
    data: createAffiliateSingleSignOnLink(accountId: $accountId) {
      link
    }
  }
`

export const IB_SSO_LINK_MUTATION = gql`
  mutation($accountId: Int!, $ibId: String) {
    data: createIBSingleSignOnLink(accountId: $accountId, ibId: $ibId) {
      link
    }
  }
`

export const CUBOID_AUTHKEY_MUTATION = gql`
  mutation ($accountId: Int!) {
    authDetails: createCuboidAuthKey(accountId: $accountId) {
      key
      expiry
    }
  }
`

export const TRADESMARTER_AUTHKEY_MUTATION = gql`
   mutation ($accountId: Int!) {
    authDetails: createTradesmarterAuthKey(accountId: $accountId) {
      key
    }
  }
`

export const CLOSE_AI_POSITION_MUTATION = gql`
  mutation ($accountId: Int!, $ticket: Int!) {
    closeAIPosition(accountId: $accountId, ticket: $ticket)
  }
`

export const OPEN_FOREX_TRADE_MUTATION = gql`
  mutation ($accountId: Int!, $symbol: String!, $cmd: String!, $volume: Float!) {
    openTrade(accountId: $accountId, symbol: $symbol, cmd: $cmd, volume: $volume)
  }
`

export const APPROP_TEST_MUTATION = gql`
  ${APPROP_TEST_FIELDS_FRAGMENT}
  mutation ($answers: AppropTestAnswersInput!) {
    createOwnAppropTestV2(answers: $answers) {
      ...AppropTestFields
    }
  }
`

export const CREATE_GOLD_SUBSCRIPTION_MUTATION = gql`
  ${ACCOUNTS_FIELDS_FRAGMENT}
  mutation(
    $accountId: Int!,
    $customAmount: Float,
    $plan: GoldSubscriptionPlan!,
    $tokenId: String!,
  )
  {
    account: createGoldSubscription(
      accountId: $accountId,
      customAmount: $customAmount,
      plan: $plan,
      tokenId: $tokenId
    )
    {
      ...AccountFields
    }
  }
`

export const TERMINATE_GOLD_SUBSCRIPTION_MUTATION = gql`
  ${ACCOUNTS_FIELDS_FRAGMENT}
  mutation($accountId: Int!, $subscriptionId: String!) {
    account: terminateGoldSubscription(accountId: $accountId, subscriptionId: $subscriptionId) {
      ...AccountFields
    }
  }
`

export const CREATE_WITHDRAWAL_MUTATION = gql`
  ${WITHDRAWAL_FIELDS_FRAGMENT}
  mutation(
    $amount: Float!,
    $paymentVendor: WithdrawalPaymentVendorsType!,
    $paymentFields: WithdrawalPaymentFieldsInputType!,
    $accountId: Int!,
    $platform: String!
    $rate: Float
  )
  {
    withdrawal: createWithdrawalRequest(
      amount: $amount,
      paymentVendor: $paymentVendor,
      paymentFields: $paymentFields,
      accountId: $accountId,
      platform: $platform,
      rate: $rate
    )
    {
      ...WithdrawalFields
    }
  }
`
export const CANCEL_WITHDRAWAL_MUTATION = gql`
  ${WITHDRAWAL_FIELDS_FRAGMENT}
  mutation($withdrawalId: Int!) {
    cancelWithdrawal(withdrawalId: $withdrawalId) {
      ...WithdrawalFields
    }
  }
`

//TODO:GQLSERVER Update Mutation to Return NewDeposit and Withdrawal
export const TRANSFER_FUNDS_MUTATION = gql`
   mutation(
    $amount: Float!,
    $fromAccountId: Int!,
    $toAccountId: Int!,
    $args: String
  )
  {
    fundTransfer: createTransferFunds(
      amount: $amount,
      fromAccountId: $fromAccountId,
      toAccountId: $toAccountId,
      args: $args
    )
      {
      deposit {
        status
      }
    }
  }
`
export const LD_TRANSFER_MUTATION = gql`
   mutation(
    $amount: Float!,
    $fromAccountId: Int!,
    $toClientId: Int!
  )
  {
    createLDTransfer(
      amount: $amount,
      fromAccountId: $fromAccountId,
      toClientId: $toClientId,
    )
      {
      deposit {
        status
      }
    }
  }
`

export const BEGIN_CRYPTO_TRANSACTION_MUTATION = gql`
  mutation(
    $accountId: Int!,
    $amount:Float!,
    $type: CryptoTransactionTypeType!,
    $destinationWallet:String,
    $args:String
  )
  {
    newCryptoTransaction: beginCryptoTransaction(
      accountId: $accountId,
      amount: $amount,
      type: $type,
      destinationWallet:$destinationWallet,
      args: $args
    )
    {
      id
      status
    }
  }
`

export const COMPLETE_CRYPTO_TRANSACTION_MUTATION = gql`
  mutation($id: Int!) {
    transaction: completeOwnCryptoTransaction(id: $id) {
      id
      status
    }
  }
`

export const CANCEL_CRYPTO_TRANSACTION_MUTATION = gql`
  mutation($id: Int!) {
    transaction: cancelOwnCryptoTransaction(id: $id) {
      id
      status
    }
  }
`

export const UPDATE_OWN_EMAIL_MUTATION = gql`
  mutation($email: String!) {
    updateOwnEmail(email: $email) {
      emailPendingChange
    }
  }
`

//TODO: Fetch All User Data Or Optimistic Update
export const UPDATE_OWN_DETAILS_MUTATION = gql`
  mutation(
    $firstName: String,
    $lastName: String,
    $nationalIdNumber: String,
    $nationalIdNumber2: String,
    $phone: String,
    $birthday: String,
    $country: Country,
    $nationality: Nationality,
    $line1: String,
    $city: String,
    $zip: String,
    $region: String,
    $secondaryEmail1: String,
    $secondaryEmail2: String,
    $secondaryEmail3: String,
    $mifirId: String,
    $mifirType: String,
    $secondaryPhone1: String,
    $placeOfBirth: String,
    $profession: String,
  )
  {
    updateOwnDetails(
      firstName: $firstName,
      lastName: $lastName,
      nationalIdNumber: $nationalIdNumber,
      nationalIdNumber2: $nationalIdNumber2,
      phone: $phone,
      birthday: $birthday,
      mifirId: $mifirId,
      mifirType: $mifirType,
      nationality: $nationality,
      placeOfBirth: $placeOfBirth,
      profession: $profession,
      secondaryPhones: {
        secondaryPhone1: $secondaryPhone1,
      }
      secondaryEmails: {
        secondaryEmail1: $secondaryEmail1,
        secondaryEmail2: $secondaryEmail2,
        secondaryEmail3: $secondaryEmail3,
      }
      address: {
        country: $country,
        line1: $line1,
        city: $city,
        zip: $zip,
        region: $region,
      }
    )
    {
      id
    }
  }
`

export const UPDATE_ECONOMIC_PROFILE_MUTATION = gql`
  mutation(
    $transactionPurpose: String,
    $approxExpectedDeposit: String,
    $originOfFunds: String,
    $approxNetWorth: String,
    $approxYearlyIncome: String,
    $sourceOfFunds: String,
    $natureOfTransactions: String,
    $jobTitle: String,
    $politicallyExposed: YesNo,
    $usCitizen: YesNo,
    $taxJurisdictionCountry: Country,
    $tin: String,
    $tinReason: String,
    $tinClarify: String,
    $transactionPurposeClarify: String,
    $natureOfTransactionsClarify: String,
    $sourceOfFundsClarify: String,
    $politicallyExposedReason: String,
    $expectedCountryOfOriginDestinationFunds: Country,
  )
  {
    updateOwnDetails(
      globalQuestionnaire: {
        transactionPurpose: $transactionPurpose,
        approxExpectedDeposit: $approxExpectedDeposit,
        originOfFunds: $originOfFunds,
        natureOfTransactions: $natureOfTransactions,
        approxNetWorth: $approxNetWorth,
        approxYearlyIncome: $approxYearlyIncome,
        sourceOfFunds: $sourceOfFunds,
        jobTitle: $jobTitle,
        politicallyExposed: $politicallyExposed,
        usCitizen: $usCitizen,
        taxJurisdictionCountry: $taxJurisdictionCountry
        tin: $tin
        tinReason: $tinReason
        tinClarify: $tinClarify
        transactionPurposeClarify: $transactionPurposeClarify
        natureOfTransactionsClarify: $natureOfTransactionsClarify
        sourceOfFundsClarify: $sourceOfFundsClarify
        politicallyExposedReason: $politicallyExposedReason,
        expectedCountryOfOriginDestinationFunds: $expectedCountryOfOriginDestinationFunds,
      }
    )
    {
      answers {
       ${GLOBAL_QUESTIONNAIRE_QUERY}
      }
    }
  }
`

export const UPDATE_SIGNUP_STEP_MUTATION = gql`
  mutation($signupStep: String!) {
    updateOwnSignupStep(signupStep: $signupStep) {
      signupStep
    }
  }
`

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation ($currentPassword: String!, $newPassword: String!) {
    updateOwnPassword(currentPassword: $currentPassword, newPassword: $newPassword)
  }
`
export const CHANGE_ACCOUNT_PASSWORD_MUTATION = gql`
  mutation ($accountId: Int!, $newPassword: String!) {
    updateOwnAccountPassword(accountId: $accountId ,newPassword: $newPassword)
  }
`

export const CONFIRM_EMAIL_MUTATION = gql`
  mutation($token: String!) {
    confirmOwnEmail(token: $token) {
      email
      emailConfirmed
      emailPendingChange
    }
  }
`

export const SEND_PHONE_VERIFICATION_CODE_MUTATION = gql`
mutation ($phone: String!, $type: PhoneVerificationType) {
  sendSmsVerification(phone: $phone, type: $type) {
    id
  }
}`

export const PHONE_VERIFICATION_MUTATION = gql`
mutation ($phoneVerificationId: Int!, $code: String!) {
  verifyPhone(phoneVerificationId: $phoneVerificationId, code: $code)
}`

export const SIGN_UPLOAD_URL_MUTATION = gql`
  mutation ($clientId: Int!) {
    signedDetails: signUploadUrl(clientId: $clientId) {
      key
      signedUrl
      plainUrl
    }
  }
`

export const CREATE_OWN_DOCUMENT_MUTATION = gql`
  ${DOCUMENT_FIELDS_FRAGMENT}
  mutation ($key: String!,$type: DocumentType!, $internalTag: String, $paymentMethodId: Int, $fileDescription: String, $owner: String, $fileType: String) {
    newDocument: createOwnDocument(
      key: $key
      type: $type
      internalTag: $internalTag
      paymentMethodId: $paymentMethodId
      fileDescription: $fileDescription
      owner: $owner
      fileType: $fileType
    ) {
      ...DocumentFields
      queuePosition
      docProcessingTime
    }
  }
`

export const DELETE_DOCUMENT_MUTATION = gql`
  mutation ($documentId: Int!) {
    removeOwnDocument(
      id: $documentId
    ) {
      id
    }
  }
`

export const CHANGE_COMPANY_MUTATION = gql`
  ${ACCOUNTS_FIELDS_FRAGMENT}
  mutation($clientId: Int!, $newCompany: Company!) {
    updatedClient: changeCompany(clientId: $clientId, newCompany: $newCompany) {
      id
      company
      companyFrontEndUrl
      accounts(orderBy: createdAt, hidden: false) {
        ...AccountFields
      }
    }
  }
`

export const NOTICE_ACKNOWLEDGEMENT_MUTATION = gql`
  mutation ($type: clientNoticeAcknowlegdementTypeType!, $noticeId: Int!) {
    addClientNoticeAcknowledgement(type: $type, noticeId: $noticeId) {
      id
    }
  }
`

export const SUBSCRIBE_TO_AI_ACCOUNT_MUTATION = gql`
  mutation ($accountId: Int!) {
    subscribeToAI(accountId: $accountId)
  }
`

export const UNSUBSCRIBE_FROM_AI_ACCOUNT_MUTATION = gql`
  mutation ($accountId: Int!) {
    unsubscribeFromAI(accountId: $accountId)
  }
`

export const BEGIN_DEPOSIT_MUTATION = gql`
  ${DEPOSIT_FIELDS_FRAGMENT}
  mutation(
        $amount: Float!,
        $accountId: Int!,
        $vendor: PermittedClientDepositVendor!,
        $paymentOptionId: Int,
        $frontend: Frontend!,
        $args: String,
        $ccTempToken: String
      )
      {
        newDeposit: beginDeposit(
          amount: $amount,
          accountId: $accountId,
          vendor: $vendor,
          paymentOptionId: $paymentOptionId,
          frontend: $frontend,
          args: $args,
          ccTempToken: $ccTempToken
        )
        {
          deposit {
            ...DepositFields
          }
          vendor
          payment {
            __typename
            ... on PaymentUrl {
              url
            }
            ... on WirecardPayment {
              payload
            }
            ... on CreditCardPaymentType {
              payload
            }
            ... on JMFinancialPaymentType {
              payload
            }
          }
        }
      }
`
export const CREATE_DEPOSIT_FROM_NEW_PCT_MUTATION = gql`
  ${DEPOSIT_FIELDS_FRAGMENT}
  mutation(
    $amount: Float!,
    $accountId: Int!,
    $token: String!,
    $bin: String!,
    $lastFour: String!
    $expiryMonth: Int!,
    $expiryYear: Int!
  )
  {
    newDeposit: createDepositWithNewPCT(
      amount: $amount,
      accountId: $accountId,
      token: $token,
      bin: $bin,
      lastFour: $lastFour,
      expiryMonth: $expiryMonth,
      expiryYear: $expiryYear
    )
    {
      ...DepositFields
      account {
        ... on BaseAccount {
          balance
        }
      }
    }
  }
`

export const CREATE_DEPOSIT_FROM_SAVED_PCT_MUTATION = gql`
  ${DEPOSIT_FIELDS_FRAGMENT}
  mutation(
    $amount: Float!,
    $accountId: Int!,
    $paymentCardTokenId: Int!,
    $frontend: Frontend!,
    $args: String,
  )
  {
    newDeposit: createDepositWithSavedPCT(
      amount: $amount,
      accountId: $accountId,
      paymentCardTokenId: $paymentCardTokenId,
      frontend: $frontend,
      args: $args,
    ) {
      deposit {
        ...DepositFields
      }
      vendor
      payment {
        __typename
        ... on PaymentUrl {
          url
        }
        ... on WirecardPayment {
          payload
        }
        ... on CreditCardPaymentType {
          payload
        }
      }
    }
  }
`

export const REMOVE_PCT_MUTATION = gql`
  mutation($paymentCardTokenId: Int!) {
    removePCT(paymentCardTokenId: $paymentCardTokenId)
  }
`

export const GDPR_OPT_IN_SMS_MUTATION = gql`
   mutation($value: Boolean) {
    gdprToggleOptInSms(value: $value)
  }
`

export const TOGGLE_HAS_HOT_ASSETS = gql`
   mutation (
      $clientId: Int!,
      $hasHotAssets: Boolean!
      ) {
        toggleHasHotAssets (
          clientId: $clientId
          hasHotAssets: $hasHotAssets
        ){
          id
        }
      }
`

export const GDPR_OPT_IN_MARKETING_MUTATION = gql`
  mutation($value: Boolean) {
    gdprToggleOptInMarketing(value: $value)
  }
`

export const GDPR_DELETE_ACCOUNT_MUTATION = gql`
  mutation ($password: String!) {
    gdrpDeleteOwnMember(password: $password)
  }
`

export const GDPR_DOWNLOAD_DATA_MUTATION = gql`
  mutation { gdrpDownloadData }
`

export const SPOA_AUTHORIZATION_MUTATION = gql`
   mutation ($remoteClientId: Int!, $isAuthorized: Boolean!, $acceptedSpoaBonusTerms: Boolean) {
    spoaAuthorize(remoteClientId: $remoteClientId, isAuthorized: $isAuthorized, acceptedSpoaBonusTerms: $acceptedSpoaBonusTerms)
  }
`

export const UPDATE_LEVERAGE = gql`
  mutation($accountId: Int!, $leverage: Int!) {
    updateOwnLeverage(accountId: $accountId, leverage: $leverage)
  }
`

export const ADD_ACCOUNT_MUTATION = gql`
  mutation($password: String, $currency: NewAccountCurrency!, $accountType: ClientOpenPermittedAccountTypeType!, $accountSubtype: AccountSubtype, $args: String, $leverageType: String) {
    account: createOwnAccount(password: $password, currency: $currency, accountType: $accountType, accountSubtype : $accountSubtype, args: $args, leverageType: $leverageType) {
      ... on BaseAccount {
        id
      }
    }
  }
`

export const CREATE_SUPPORT_TICKET = gql`
  mutation($name: String!, $email: String!, $topic: String!, $message: String!, $to: String, $attachments: [String], $method: String, $callbackDate: DateTime, $preferableLanguage: String, $appVersion: String, $browserName: String, $browserEngine: String, $browserVersion: String, $deviceManufacturer: String, $deviceSystemName: String, $deviceSystemVersion: String, $deviceArchitecture: String, $deviceLocale: String, $deviceCountry: String, $deviceTimezone: String) {
    createOwnSupportTicket(name: $name, email: $email, topic: $topic, message: $message, to: $to, attachments: $attachments, method: $method, callbackDate: $callbackDate, preferableLanguage: $preferableLanguage, appVersion: $appVersion, browserName: $browserName, browserEngine: $browserEngine, browserVersion: $browserVersion, deviceManufacturer: $deviceManufacturer, deviceSystemName: $deviceSystemName, deviceSystemVersion: $deviceSystemVersion, deviceArchitecture: $deviceArchitecture, deviceLocale: $deviceLocale, deviceCountry: $deviceCountry, deviceTimezone: $deviceTimezone)
  }
`

export const CREATE_COMPLAINT_FORM = gql`
  mutation(
    $outcome: String
    $disputeAmount: String
    $dateTimeOfIssue: String
    $emailTicketNumber: String
    $fullNameAndSurname: String!
    $datesOfCommunication: String
    $tradingAccountNumber: String!
    $positionsUnderDispute: String
    $registeredEmailAddress: String!
    $descriptionOfComplaint: String
    $attachSupportingEvidence: [String]
    $customerSupportRepresentative: String
    $descriptionOfComplaintResolution: String
    $complaintEmail: String!
    $complaintSubject: String!
  ) {
    createOwnComplaintForm(
      outcome: $outcome
      disputeAmount: $disputeAmount
      complaintEmail: $complaintEmail
      dateTimeOfIssue: $dateTimeOfIssue
      complaintSubject: $complaintSubject
      emailTicketNumber: $emailTicketNumber
      fullNameAndSurname: $fullNameAndSurname
      datesOfCommunication: $datesOfCommunication
      tradingAccountNumber: $tradingAccountNumber
      positionsUnderDispute: $positionsUnderDispute
      registeredEmailAddress: $registeredEmailAddress
      descriptionOfComplaint: $descriptionOfComplaint
      attachSupportingEvidence: $attachSupportingEvidence
      customerSupportRepresentative: $customerSupportRepresentative
      descriptionOfComplaintResolution: $descriptionOfComplaintResolution
    )
  }
`

export const UPDATE_LOCALE = gql`
  mutation($locale: Locale!) {
    updateOwnLocale(locale: $locale) {
      locale
    }
  }
`
export const REFILL_DEMO_BALANCE = gql`
  mutation($login: String!, $amount: Float) {
    refillDemoBalance(login: $login, amount: $amount)
  }
`
export const SIGN_CLIENT_NOTICE_MUTATION = gql `
  mutation(
    $noticeId: String!
    $signature: String!
    $signDate: DateTime!,
  ) {
    signClientNotice(
      noticeId: $noticeId
      signature: $signature
      signDate: $signDate
    ) {
      status
    }
  }
`

export const ELECTRONIC_ID_VERIFICATION = gql`
mutation (
  $nationalId: String
)
 {
  doElectronicIDVerification(nationalId: $nationalId) {
    id
    identityReliability
    addressReliability
    rawResult
    createdAt
  }
}`

export const CREATE_INTERNAL_TRANSFER = gql`
  mutation(
    $amount: Float!,
    $fromAccountId: Int!,
    $toAccountId: Int!,
    $args: String
    ){
      createInternalTransfer(
        amount: $amount,
        fromAccountId: $fromAccountId,
        toAccountId: $toAccountId,
        args: $args
      ){
        deposit {
          status
				}
      }
    }
`

export const CREATE_RAW_SUBSCRIPTION_MUTATION = gql`
  mutation(
    $accountId: Int!,
    $plan: rawSubscriptionPlanType!,
    $tokenId: String
    $tokenThreeD: String
  ) {
    createRawSubscription(accountId: $accountId, plan: $plan, tokenId: $tokenId, tokenThreeD: $tokenThreeD) {
      tokenThreeD
      subscription {
        id
      }
    }
  }
`

export const CHANGE_RAW_SUBSCRIPTION_MUTATION = gql`
  mutation(
    $accountId: Int!,
    $plan: rawSubscriptionPlanType!
  ) {
    changeRawSubscription(plan: $plan, accountId: $accountId){
      id
    }
  }
`

export const CHANGE_RAW_BILLING_INFO_MUTATION = gql`
  mutation(
    $accountId: Int!,
    $tokenId: String!
    $tokenThreeD: String
  ) {
    updateRawSubscriptionBillingInfo(accountId: $accountId, tokenId: $tokenId, tokenThreeD: $tokenThreeD) {
      tokenThreeD
      subscription {
        id
      }
    }
  }
`

export const CANCEL_RAW_SUBSCRIPTION_MUTATION = gql`
  mutation(
    $accountId: Int!,
  ) {
    cancelRawSubscription(accountId: $accountId){
      id
    }
  }
`

export const REACTIVATE_RAW_SUBSCRIPTION_MUTATION = gql`
  mutation(
    $accountId: Int!,
  ) {
    reactivateRawSubscription(accountId: $accountId){
      id
    }
  }
`

export const DUE_DILIGENCE_MUTATION = gql`
  ${DUE_DILIGENCE_FIELDS_FRAGMENT}
  mutation($answers: DueDiligenceAnswersInputType) {
    createDueDiligence(answers: $answers) {
      ...DueDiligenceFields
    }
  }
`

export const SUITABILITY_TEST_MUTATION = gql`
  ${SUITABILITY_TEST_FIELDS_FRAGMENT}
  mutation(
    $answers: SuitabilityTestAnswersInputType
    $currency: String
    $subTypeSelection: String
  ) {
    createSuitabilityTest(answers: $answers, subTypeSelection: $subTypeSelection, currency: $currency) {
      ...SuitabilityTestFields
    }
  }
`

export const CANCEL_PARTIAL_DEPOSIT_MUTATION = gql`
  mutation ($depositId: Int!){
    action: cancelDeposit(id: $depositId) {
      id
      status
      amount
    }
  }
`

export const COMPLETE_PARTIAL_DEPOSIT_MUTATION = gql`
  mutation ($depositId: Int!){
    action: completeOwnDeposit(id: $depositId) {
      id
      status
      amount
    }
  }
`

export const UPSERT_COPY_TRADING = gql`
  mutation(
    $id: Int,
    $accountCopying: Int,
    $account: Int,
    $action: String,
    $method: String,
    $percentage: String,
    $provider: CopyTradingProviderType
  ) {
    upsertCopyTrading(id: $id, accountCopying: $accountCopying, account: $account,
      action: $action, method: $method, percentage: $percentage, provider: $provider) {
      id
    }
  }
`

export const UPDATE_THEME_PREFERENCE = gql`
mutation($themePreference: ThemePreference!) {
    updateThemePreference(themePreference: $themePreference) {
      id
    }
  }
`

export const UPDATE_ACCOUNT_NAME_MUTATION = gql`
  mutation($accountId: Int!, $name: String!) {
    updateAccountName(accountId: $accountId, name: $name)
  }
`

export const WEBINAR_URL_MUTATION = gql`
  mutation ($webinarId: String!, $locationTrack: String) {
    webinarUrl(webinarId: $webinarId, locationTrack: $locationTrack) {
      joinUrl
      status
    }
  }`

export const CLAIM_REWARD_MUTATION = gql`
  mutation($accountId: Int!, $rewardType: String!) {
    claimReward(accountId: $accountId, rewardType: $rewardType){
      claimedRewards
      pendingRewards
    }
  }
`

export const UPDATE_OWN_PERFORMANCE_FEE_MUTATION = gql`
  mutation($accountId: Int!, $performanceFee: Float!) {
    updateOwnPerformanceFee(accountId: $accountId, performanceFee: $performanceFee)
  }
`

export const PROVIDE_COPY_TRADING_MUTATION = gql`
  mutation($accountId: Int!, $provideCopyTrading: Boolean!) {
    provideCopyTrading(accountId: $accountId, provideCopyTrading: $provideCopyTrading)
  }
`
export const ENABLE_SWAP_FREE = gql`
  mutation {
    enableSwapFree {
      failedAccountRemoteIds
    }
  }
`

// export const TRADE_COMPANION_CLOSE_NOTIFCATION_MUTATION = gql`
//   mutation {
//     tradeCompanionCloseNotification
//   }
// `

// export const TOGGLE_TRADE_COMPANION_MUTATION = gql`
//   mutation($clientId: Int!, $toggleTradeCompanion: Boolean!) {
//     toggleTradeCompanion(
//       clientId: $clientId
//       toggleTradeCompanion: $toggleTradeCompanion
//     ) {
//       id
//     }
//   }
// `

export const ADD_METADATA_TO_CLIENT_MUTATION = gql`
  mutation($metadata: String!) {
    addMetadataToClient(metadata: $metadata)
  }
`

export const ADD_METADATA_TO_ACCOUNT_MUTATION = gql`
  mutation($accountId: Int!, $metadata: String!) {
    addMetadataToAccount(accountId: $accountId, metadata: $metadata)
  }
`

export const TOGGLE_PASSWORD_STRATEGY_MUTATION = gql`
  mutation($accountId: Int!, $password: String) {
    togglePasswordStrategy(accountId: $accountId, password: $password)
  }
`

export const CHECK_PROTECTED_PASSWORD_STRATEGY_MUTATION = gql`
  mutation($accountId: Int!, $password: String!) {
    checkProtectedPasswordStrategy(accountId: $accountId, password: $password)
  }
`

export const CLIENT_PARTNERS_LOYALTY_MUTATION = gql`
  mutation($id: Int, $action: String!, $clientId: Int!, $prize: String) {
    upsertPartnersLoyalty(id: $id, action: $action, clientId: $clientId, prize: $prize){
      id
    }
  }
`

export const UPDATE_STRATEGY_AVATAR_MUTATION = gql`
  mutation($accountId: Int!, $avatarImage: String!) {
    updateStrategyAvatar(accountId: $accountId, avatarImage: $avatarImage)
  }
`

export const CREATE_EVENT_REQUEST_FORM = gql`
  mutation($clientId: Int!, $email: String, $message: String!, $eventDate: String, $phone: String, $guests: String, $country: String, $natureOfRequest: String, $type: String) {
    eventRequestForm(clientId: $clientId, email: $email, message: $message, eventDate: $eventDate, phone: $phone, guests: $guests,  country: $country, natureOfRequest: $natureOfRequest, type: $type)
  }
`

export const UPGRADE_CAMPAIGN_TYPE_FORM = gql`
  mutation($clientId: Int!, $email: String, $message: String!, $phone: String, $sendRequestToPartnerManagerEmail: Boolean, $ibId: String) {
    upgradeCampaignTypeTicket(clientId: $clientId, email: $email, message: $message, phone: $phone, sendRequestToPartnerManagerEmail: $sendRequestToPartnerManagerEmail, ibId: $ibId)
  }
`

export const IB_INTERESTED_MUTATION = gql`
  mutation($sendEmail: Boolean) {
    ibInterested(sendEmail: $sendEmail)
  }
`

export const ACCEPT_REVEAL_REFER_A_FRIEND_MUTATION = gql`
  mutation {
    acceptRevealReferAFriend
  }
`

export const SEND_REFER_FRIENDS_EMAIL = gql`
  mutation($emailsToSend: [String]!) {
    sendReferFriendsEmail(emailsToSend: $emailsToSend)
  }
`

export const ADD_CORPORATE_DETAILS = gql`
  mutation($step: Int!, $answers: String) {
    addCorporateDetails(step: $step, answers: $answers){
      id
      corporateSignupStep
    }
  }
`

export const ADD_CORPORATE_STEP = gql`
  mutation($step: Int) {
    addCorporateStep(step: $step){
      id
      corporateSignupStep
    }
  }
`

export const OPT_IN_PROMOTION = gql`
  mutation($value: Boolean!) {
    optInPromotions(value: $value)
  }
`

export const ACKNOWLEDGE_AFFILIATE_TERMS = gql`
  mutation($status: String) {
    acknowledgeAffiliateTerms(status: $status)
  }
`

export const ADD_MIGRATION_TERMS_ACTIVITY_LOG_MUTATION = gql`
  mutation($entity: String!) {
    addMigrationTermsActivityLog(entity: $entity)
  }
`

export const SEND_EMAIL_LOGIN_VERIFICATION_CODE_MUTATION = gql`
mutation {
  sendEmailLoginVerification
}`

export const EMAIL_LOGIN_VERIFICATION_MUTATION = gql`
mutation ($code: String!) {
  verifyEmailLogin(code: $code)
}`

export const UPDATE_LOCAL_DEPOSITOR_MUTATION = gql`
  mutation(
    $localDepositorPhone: String,
    $localDepositorEmail: String,
  ){
    updateOwnDetails(
      localDepositorPhone: $localDepositorPhone,
      localDepositorEmail: $localDepositorEmail
    ){
      id
    }
  }
`

export const REQUEST_CALLBACK_PRIORITY_SERVICE = gql`
  mutation {
    requestCallbackPriorityService
  }
`

export const REQUEST_ZULU_TRADE_SSO_LINK = gql`
  mutation ($accountId:  Int!) {
    createZuluSSOLink(accountId: $accountId){
      statusCode
      errorMessage
      ssoUrl
    }
  }
`
export const REQUEST_CALLBACK_BECOME_PARTNER_MUTATION = gql`
  mutation {
    requestCallbackBecomePartner
  }
`
