import React, {Component} from 'react'
import {Trans, withNamespaces} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import messages from '../../assets/messages'
import {Typography, Button} from '@mui/material'
import Grid from '@mui/material/Grid'
import {flowRight as compose, map} from 'lodash'
import Images from '../Common/Images'
import classNames from 'classnames'
import config from '../../config'
import AppContext from '../Common/contexts/AppContext'
import {withRouter} from 'react-router'
import {isMobile} from '../../common/utils'
import {logEventCustomParams} from '../../common/utils/firebaseEvents'

const styles = theme => ({
  cardDiv: {
    border: `1px solid ${theme.palette.lightgrey.color}`,
    borderRadius: 8,
    padding: 12,
    margin: 12
  },
  fullWidth: {
    width: '100%'
  },
  buttons: {
    padding: '12px 0',
  },
  bold: {
    fontWeight: 400,
  },
  ul: {
    paddingLeft: 16,
    listStyle: 'none'
  },
  li: {
    '&::before': {
      content:  '\'·\'',
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      display: 'inline-block',
      marginLeft: -16,
      fontSize: 24
    }
  },
  displayInline: {
    display: 'inline-block'
  },
  liText: {
    marginLeft: 8,
    verticalAlign: 'text-top',
    marginTop: -8,
  },
  becomeAnIb: {
    marginTop: 0,
    marginBottom: 12
  },
})

class BecomeIb extends Component {
  static contextType = AppContext

  learnMoreClicked() {
    const {ibPartnersLink} = config
    logEventCustomParams('referAndEarnLearnMoreClicked')
    window.open(ibPartnersLink, '_blank')
  }

  render() {
    const {classes, becomeAnIb} = this.props
    const becomeIbTexts = ['becomeAnIbText1', 'becomeAnIbText2', 'becomeAnIbText3', 'becomeAnIbText4']
    return <Grid container spacing={2} className={classes.cardDiv}>
      {!isMobile() && <Grid item xs={6}>
        <img className={classes.fullWidth} src={Images['refer_become_ib.png']} alt={'Become IB'} />
      </Grid>}
      <Grid item xs={12} md={6}>
        <Typography variant='h3' className={classes.becomeAnIb}><Trans {...messages.becomeAnIbBtn}/></Typography>
        <Typography variant='body1'><Trans {...messages.becomeAnIbSubtitle} /></Typography>
        <ul className={classes.ul}>
          {map(becomeIbTexts, (becomeIbText) => <li key={becomeIbText} className={classes.li}>
            <Typography variant='body1' className={classNames(classes.displayInline, classes.liText)}>
              <Trans {...messages[becomeIbText]} components={[<span key={'span'} className={classes.bold}> </span>]}/>
            </Typography>
          </li>)}
        </ul>
        <Grid container spacing={2} className={classes.buttons}>
          <Grid item xs={12} md={'auto'}>
            <Button
              onClick={() => becomeAnIb()}
              variant="contained"
              size="large"
              color="primary"
              fullWidth={isMobile()}
            >
              <Trans {...messages.becomeAnIbButton} />
            </Button>
          </Grid>
          <Grid item xs={12} md={'auto'}>
            <Button
              onClick={() => this.learnMoreClicked()}
              variant="contained"
              size="large"
              color="secondary"
              fullWidth={isMobile()}
            >
              <Trans {...messages.learnMore} />
            </Button>

          </Grid>
        </Grid>
      </Grid>
    </Grid>
  }
}

export default  compose(
  withNamespaces(),
  withRouter,
  withStyles(styles, {withTheme: true}),
)(BecomeIb)
