import {flowRight as compose} from 'lodash'
import {Trans, withNamespaces} from 'react-i18next'
import messages from '../../../../../assets/messages'
import NotificationBar from '../../../../Common/NotificationBar'
import withStyles from '@mui/styles/withStyles'

const styles = theme => ({
  notificationBarWrapper: {
    marginTop: 30,
    marginBottom: 30,
  },
})

function AlertForSubmittedForm(props) {
  const {classes} = props

  return (
    <div className={classes.notificationBarWrapper}>
      <NotificationBar
        status={'info'}
        title={<Trans {...messages.competitionSubmittedAlert}/>}
      />
    </div>
  )
}

export default compose(
  withNamespaces(),
  withStyles(styles),
)(AlertForSubmittedForm)
