import React from 'react'
import {get, flowRight as compose} from 'lodash'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Button from '@mui/material/Button'
import {withRouter} from 'react-router-dom'
import {graphql} from 'react-apollo'
import {withNamespaces, Trans} from 'react-i18next'
import {Loading} from '../../Common/Loading'
import withStyles from '@mui/styles/withStyles'
import PammRoutes from './routes'
import PammModal from './PammModal'
import PageTitle from '../../Common/PageTitle'
import messages from '../../../assets/messages'
import {ACCOUNTS_QUERY} from '../../../graphql/queries'
import {PAMM_SSO_LINK_MUTATION} from '../../../graphql/mutations'
import {getAccountLabel, getAccountSubtype, isPAMMCysecProduct} from '../../../common/utils/accounts'
import {isMobile} from '../../../common/utils/browser'
import PageBody from '../../Common/PageBody'
import AppContext from '../../Common/contexts/AppContext'

const styles = theme => ({
  titleItem: {
    flex: 1,
  }
})

class PammAccountView extends React.Component {
  static contextType = AppContext

  state = {
    showModal: false
  }

  checkAvailability() {
    const {account} = this.props
    if (isPAMMCysecProduct(account)) {
      this.setState({showModal: true})
    } else {
      this.onPammPortal()
    }
  }

  onPammPortal() {
    const {account, createPAMMSingleSignOnLink} = this.props
    const newTab = window.open('about:blank', '_blank')
    newTab.document.write('<h4>Please wait</h4>')

    createPAMMSingleSignOnLink({variables: {accountId: account.id}}).then((res) => {
      newTab.location = res.data.data.link
    }).catch((e) => {
      newTab.close()
    })
  }

  render() {
    const {loading, account, classes, history, t} = this.props
    const {showModal} = this.state
    const {locale, themePreference} = this.context
    if (loading) return <Loading />
    const tabs = [
      {path: '', label: t(messages.overview.i18nKey, messages.overview.defaults)},
      {path: '/transactions', label: t(messages.payments.i18nKey, messages.payments.defaults)},
    ]
    const tabIndex = tabs.findIndex(tab =>
      tab.path.length > 0 && window.location.href.endsWith(tab.path)
    )
    const activeTab = tabIndex >= 0 ? tabIndex : 0

    const pageTitle = getAccountLabel(account, locale, getAccountSubtype(get(account,'accountSubtype')), t)

    return (<div>
      <PageTitle
        themePreference={themePreference}
        title={pageTitle}
        onBack={() => history.push('/accounts')}
        withoutPadding={isMobile()}
        rightAction={
          <React.Fragment>
            <Button
              onClick={() => this.checkAvailability()}
              variant="contained"
              size="large"
              color="primary"
            >
              <Trans {...messages.managedAccount} />
            </Button>
          </React.Fragment>
        }
        subtitle={
          <Trans {...messages.accountID} values={{accountId: account.login}} />
        }
      >
        <Tabs
          variant={isMobile()? 'scrollable' : 'standard'}
          value={activeTab}
          onChange={(_, activeTab) => this.setState({activeTab})}
        >
          {tabs.map((tab, key) =>
            <Tab
              key={key}
              label={tab.label}
              textColor="inherit"
              onClick={() => history.push(`/accounts/${account.id}${tab.path}`)} />
          )}
        </Tabs>
      </PageTitle>
      <PageBody>
        <PammRoutes />
        <PammModal
          open={showModal}
          onAccept={() => this.onPammPortal()}
          onCancel={() => this.setState({showModal: false})}
        />
      </PageBody>
    </div>)
  }
}

export default compose(
  withNamespaces(),
  withRouter,
  withStyles(styles, {withTheme: true}),
  graphql(ACCOUNTS_QUERY, {
    props: ({data: {error, loading}, data, ownProps: {match}}) => {
      const {accountId} = match.params
      const account = get(data, 'viewer.accounts', []).find(account => account.id === Number(accountId))
      return {
        error,
        loading,
        account,
      }
    }
  }),graphql(PAMM_SSO_LINK_MUTATION, {
    name: 'createPAMMSingleSignOnLink',
  }),
)(PammAccountView)

// PAMM_SSO_LINK_MUTATION
