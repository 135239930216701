import PropTypes from 'prop-types'
import React, {Component} from 'react'
import Grid from '@mui/material/Grid'
import Input from '@mui/material/Input'
import {withRouter} from 'react-router-dom'
import {frontends} from '@bdswiss/common-enums'
import {withNamespaces, Trans} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import {debounce, isEmpty, values, flowRight as compose, get} from 'lodash'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import {PaymentActionButton, withCreateDeposit} from './helpers'
import messages from '../../../../assets/messages'
import {getPlatform, getSourceBasedOnLocationPrevPath, logEventCustomParams} from '../../../../common/utils'
import {validateEmail, validateIsStringContainsEmoji} from '../../../../common/utils/validations'

const style = (theme) => ({
  formControl: {
    margin: theme.spacing(1)
  },
  error: {
    color: theme.palette.error.main
  },
})

const validators = {
  email: (value) => {
    if (!value) return messages.requiredField
    return (!validateEmail(value) || validateIsStringContainsEmoji(value)) && messages.emailValidation
  },
}

const initialState = {form: {email: ''}, errors: {}, creatingDeposit: false}

class EmailRequiredProvider extends Component {

  constructor(props) {
    super(props)
    this.validateFields = debounce(this.validateFields, 200)
    this.state = initialState
  }

  handleChange(key, value) {
    const {form} = this.state
    this.setState({
      form: {
        ...form,
        [key]: value
      }
    }, () => this.validateFields())
  }

  validateFields() {
    const {form} = this.state
    const errors = {}
    Object.keys(form).forEach((k) => {
      const validate = validators[k]
      errors[k] = validate && validate(form[k])
    })
    this.setState({errors})
  }

  formIsValid() {
    const {form, errors} = this.state
    return isEmpty(values(errors).filter((v) => v)) && !isEmpty(values(form).filter((v) => v))
  }

  doCreateDeposit() {
    if (!this.formIsValid()) return

    this.setState({creatingDeposit: true})

    const {amount, account: {id: accountId, currency}, onError, useVirtualPaymentPage,
      providerProperties: {provider, id: paymentOptionId}, bonusAmount, bonusTerms,
      history: {location}} = this.props
    const {form: {email}} = this.state

    const firebaseParams = {
      source: getSourceBasedOnLocationPrevPath(get(location, 'state', {prevPath: get(location, 'pathname')})),
      login: accountId,
      currency: currency,
      vendor: provider,
      amount,
    }
    logEventCustomParams('depositAttempted', firebaseParams)

    const variables = {
      accountId: accountId,
      amount,
      vendor: provider,
      paymentOptionId,
      frontend: frontends.web2.value,
      args: JSON.stringify({
        platform: getPlatform(),
        email,
        useVirtualPaymentPage,
        bonusAmount,
        bonusTerms,
      }),
    }

    this.props.createDepositRequest({variables})
      .then(({data: {newDeposit}}) => {
        const {payment: {url}} = newDeposit
        window.location = url
      })
      .catch((e) => {
        onError && onError(e)
        this.setState({creatingDeposit: false})
      })
  }

  render() {
    const {classes, canProceed} = this.props
    const {form: {email}, errors, creatingDeposit} = this.state

    return (
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12}>
          <FormControl variant="standard" className={classes.formControl} fullWidth>
            <InputLabel htmlFor="email">
              <Trans {...messages.email} />
            </InputLabel>
            <Input
              id="email"
              type="text"
              value={email}
              onChange={(e) => this.handleChange('email', e.target.value)}
            />
            {errors.email &&
              <FormHelperText className={classes.error}><Trans {...errors.email} /></FormHelperText>
            }
          </FormControl>
        </Grid>
        <PaymentActionButton
          loading={creatingDeposit}
          disable={canProceed || !this.formIsValid()}
          onClick={() => this.doCreateDeposit()}
        />
      </Grid>
    )
  }
}

EmailRequiredProvider.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  amount: PropTypes.number.isRequired,
  providerProperties: PropTypes.shape({
    name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    paymentKey: PropTypes.string.isRequired,
    paymentOptionId: PropTypes.number.isRequired,
  }).isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

export default compose(
  withStyles(style),
  withCreateDeposit,
  withNamespaces(),
  withRouter,
)(EmailRequiredProvider)
