import React, {Component} from 'react'
import {flowRight as compose} from 'lodash'
import withStyles from '@mui/styles/withStyles'
import {Trans} from 'react-i18next'
import messages from '../../assets/messages'
import Typography from '@mui/material/Typography'
import {AlertDialog} from './Dialog'
import AppContext from './contexts/AppContext'
import {Link} from 'react-router-dom'

const styles = theme => ({
  link: {
    color: theme.palette.primary.main,
  },
  supportText:{
    textAlign: 'center',
    paddingTop: '1rem',
    fontWeight: 400
  },
  highlight:{
    paddingBottom: '1rem',
    fontWeight: 400
  }
})

class PortfolioManagementExplanationModal extends Component {
  static contextType = AppContext

  render() {
    const {classes} = this.props

    return (
      <div>
        <AlertDialog
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.props.open}
          onClose={this.props.onClose}
          title={<Trans {...messages.whyIsThisNeeded} />}
        >
          <Typography variant="subtitle1">
            <Trans {...messages.portfolioManagementDesc1} />
          </Typography>
          <Typography variant="subtitle1"  className={classes.supportText}>
            <Trans {...messages.helpSupport} components={[<Link to="/support" className={classes.link}> </Link>]}/>
          </Typography>
        </AlertDialog>
      </div>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
)(PortfolioManagementExplanationModal)
