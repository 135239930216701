import PropTypes from 'prop-types'
import {Trans} from 'react-i18next'
import {withRouter} from 'react-router-dom'
import React, {Component} from 'react'
import {flowRight as compose, get} from 'lodash'
import withStyles from '@mui/styles/withStyles'
import {frontends, kycStatuses} from '@bdswiss/common-enums'
import messages from '../../../../assets/messages'
import NotificationBar from '../../../Common/NotificationBar'
import {withCreateDeposit, PaymentActionButton} from './helpers'
import {getPlatform, getSourceBasedOnLocationPrevPath, logEventCustomParams, safeParseJSON} from '../../../../common/utils'
import BankWireTransferProvider from './BankWireTransferProvider'

const styles = theme => ({
  warningCTA: {
    color: theme.palette.notificationBar.yellowTextColor,
  },
})

class JMFinancialProvider extends Component {

  state = {loading: false}

  submit() {
    const {providerProperties: {provider, paymentKey, id: paymentOptionId}, account, amount, onError, useVirtualPaymentPage, bonusAmount, bonusTerms,
      history: {location}} = this.props

    this.setState({loading: true})

    const firebaseParams = {
      source: getSourceBasedOnLocationPrevPath(get(location, 'state', {prevPath: get(location, 'pathname')})),
      login: account.id,
      currency: account.currency,
      vendor: provider,
      amount,
    }
    logEventCustomParams('depositAttempted', firebaseParams)

    const variables = {
      accountId: account.id,
      amount,
      vendor: provider,
      paymentOptionId,
      frontend: frontends.web2.value,
      args: JSON.stringify({
        platform: getPlatform(),
        paymentKey,
        useVirtualPaymentPage,
        bonusAmount,
        bonusTerms,
      }),
    }

    this.props.createDepositRequest({variables})
      .then(({data: {newDeposit}}) => {
        const {payment: {payload}} = newDeposit
        const data = safeParseJSON(payload)
        window.goSell.config({
          gateway: {
            publicKey: data.publicKey,
            merchant_id: null,
            language: 'en',
            contactInfo: false,
            supportedCurrencies: 'all',
            supportedPaymentMethods: 'all',
            saveCardOption: false,
            customerCards: true,
            onClose: () => {},
            onLoad:() => {
              setTimeout(() => {window.goSell.openPaymentPage()}, 2000)
            },
          },
          customer: {
            first_name: data.firstName,
            middle_name: '',
            last_name: data.lastName,
            email: data.email,
          },
          order: {
            amount: data.amount,
            currency: data.currency,
          },
          transaction: {
            mode: 'charge',
            charge: {
              saveCard: false,
              threeDSecure: true,
              reference: {
                transaction: data.reference,
                order: data.reference,
              },
              metadata: {},
              receipt: {
                email: false,
                sms: true,
              },
              redirect: data.redirectUrl,
              post: data.post,
            }
          },
        })

      })
      .catch((e) => {
        this.setState({loading: false})
        onError && onError(e)
      })
  }

  render() {
    const {loading} = this.state
    const {viewer, history, amount, account, providerProperties, onError, onSubmit, canProceed} = this.props
    return (
      <React.Fragment>
        {viewer.kycStatus !== kycStatuses.approved.value
          ? <NotificationBar
            status="warning"
            title={<Trans {...messages.paymentMethodKycRequired} />}
            ctaMessage={<Trans {...messages.verifyAccount} />}
            ctaAction={() => history.push('/settings/profile/documents')}
          />
          : get(providerProperties, 'paymentKey') === 'creditCard' ? <PaymentActionButton
            disable={canProceed}
            loading={loading}
            onClick={() =>  this.submit()}
          />: <BankWireTransferProvider viewer={viewer} amount={amount} account={account} providerProperties={providerProperties} onError={onError} onSubmit={onSubmit} />}
      </React.Fragment>
    )
  }
}

JMFinancialProvider.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  amount: PropTypes.number.isRequired,
  providerProperties: PropTypes.shape({
    name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    paymentKey: PropTypes.string,
    paymentOptionId: PropTypes.number.isRequired,
  }).isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

export default compose(
  withRouter,
  withStyles(styles, {withTheme: true}),
  withCreateDeposit
)(JMFinancialProvider)
