import React, {Component} from 'react'
import {flowRight as compose, get} from 'lodash'
import {Trans, withNamespaces} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import Images from '../Common/Images'
import {Grid, SvgIcon, Typography} from '@mui/material'
import PropTypes from 'prop-types'
import messages from '../../assets/messages'
import {graphql} from 'react-apollo'
import {OPT_IN_PROMOTION} from '../../graphql/mutations'
import {PROMOTIONS_QUERY} from '../../graphql/queries'
import LoadingButton from '../Common/LoadingButton'
import {ReactComponent as PromotionsStar} from '../../assets/images/promotionsStar.svg'
import AppContext from '../Common/contexts/AppContext'
import {getItem, logEventCustomParams} from '../../common/utils'
import config from '../../config'

const styles = theme => ({
  root: {
    height: '120px',
    borderRadius: '8px',
    overflow: 'hidden',
    position: 'relative',
    flexWrap: 'nowrap',
    backgroundColor: ({promotionsEnabled}) => promotionsEnabled ? theme.palette.primary.main : theme.palette.grey[600],
    [theme.breakpoints.down('sm')]:{
      maxWidth: '343px',
      height: '158px',
      flexWrap: 'wrap',
    }
  },
  image: {
    borderRadius: '8px',
  },
  bold: {
    fontWeight: '500',
  },
  secondSection: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row-reverse',
      margin: '44px 0px 0px 16px',
      justifyContent: 'unset',
    }
  },
  giftImageItem: {
    position: 'absolute',
    width: '162px',
    height: '198px',
    top: '15px',
    transform: theme.direction === 'rtl' ? 'scaleX(-1)' : 'scaleX(1)',
    [theme.direction === 'rtl' ? 'right' : 'left']: '51px',
    [theme.breakpoints.down('sm')]:{
      position: 'absolute',
      width: '170px',
      height: '206px',
      [theme.direction === 'rtl' ? 'left' : 'right']: '-64px',
      [theme.direction === 'rtl' ? 'right' : 'left']: 'unset',
      top: '-33px',
      transform: theme.direction === 'rtl' ? 'matrix(1, 0, 0, 1, 0, 0)' : 'matrix(-1, 0, 0, 1, 0, 0)',
    },
    '& img':{
      width: '100%',
    },
  },
  handoutsItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    flexWrap: 'nowrap',
    gap: '8px',
    margin: theme.direction === 'rtl' ? '24px 239px 0px 0px' : '24px 0px 0px 239px',
    [theme.breakpoints.down('sm')]: {
      margin: theme.direction === 'rtl' ? '16px 16px 0px 0px' : '16px 0px 0px 16px',
      gap: 'unset',
    },
  },
  handoutsIconContainer: {
    display: 'flex',
    flex: '0 0 auto',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40px',
    height: '40px',
    background: theme.palette.secondary.light,
    borderRadius: '6px',
    [theme.breakpoints.down('sm')]:{
      display: 'none',
    }
  },
  handoutsContainer: {
    flexWrap: 'nowrap',
    gap: '12px',
  },
  handoutsTitle: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '34px',
    lineHeight: '40px',
    color: theme.palette.secondary.light,
    [theme.breakpoints.down('sm')]:{
      fontSize: '24px',
      lineHeight: '32px',
      letterSpacing: '0.18px',
    },
  },
  optInActivationMessage: {
    visibility: ({optInPromotionsValue}) => optInPromotionsValue ? 'hidden' : 'visible',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.5px',
    color: theme.palette.secondary.light,
    [theme.breakpoints.down('sm')]:{
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.25px',
    },
    [theme.breakpoints.down('lg')]:{
      fontSize: '13px',
    },
  },
  optInTermsAndConditionsText: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '11px',
    lineHeight: '14px',
    textAlign: theme.direction === 'rtl' ? 'left' : 'right',
    letterSpacing: '0.4px',
    color: theme.palette.secondary.light,
    [theme.breakpoints.down('sm')]: {
      textAlign: theme.direction === 'rtl' ? 'right' : 'left',
      fontSize: '9px',
      lineHeight: '12px',
      letterSpacing: '0.4px',
      marginTop: '4px',
      [theme.direction === 'rtl' ? 'marginRight' : 'marginLeft']: '20px'
    },
  },
  optInTermsAndConditionsItem: {
    margin: theme.direction === 'rtl' ? '62px 0px 0px 24px' : '62px 24px 0px 0px',
    [theme.breakpoints.down('sm')]: {
      margin: theme.direction === 'rtl' ? '0px 24px 0px 0px' : '0px 12px 0px 12px',
    }
  },
  optInButtonItem: {
    margin: theme.direction === 'rtl' ? '56px 0px 0px 24px' : '56px 24px 0px 0px',
    [theme.breakpoints.down('sm')]: {
      margin: theme.direction === 'rtl' ? '0px 16px 0px 0px' : '0px',
    }
  },
  optInButton: {
    height: '40px',
    padding: '8px 24px 8px 24px',
    background: ({promotionsEnabled, optInPromotionsValue}) => promotionsEnabled ?
      optInPromotionsValue ? 'rgba(255, 255, 255, 0.2)': theme.palette.secondary.light
      : theme.palette.secondary.light,
    whiteSpace: 'nowrap',
    '&:hover': {
      background: ({promotionsEnabled, optInPromotionsValue}) => promotionsEnabled ?
        optInPromotionsValue ? 'rgba(255, 255, 255, 0.3)': theme.palette.secondary.light
        : theme.palette.secondary.light,
    },
    [theme.breakpoints.down('lg')]: {
      padding: '8px 16px 8px 16px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '4px 16px 4px 16px',
      height: '32px',
      background: theme.palette.secondary.light,
      '&:hover': {
        background: theme.palette.secondary.light,
      },
    },
  },
  optInButtonText: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '0.25px',
    color: ({promotionsEnabled, optInPromotionsValue}) => promotionsEnabled ?
      optInPromotionsValue ? theme.palette.secondary.light : theme.palette.primary.main
      : promotionsEnabled ? theme.palette.primary.main : theme.palette.secondary.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.1px',
    }
  },
  promotionsStarIcon: {
    '& svg path': {
      fill: ({promotionsEnabled}) => promotionsEnabled ? theme.palette.primary.main : theme.palette.grey[600],
    } ,
  }
})

class PromotionOptInCard extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {
      status: '',
      loading: false,
    }
  }

  handleToggleOptIn() {
    const {companyObject} = this.context
    const {optInPromotions, optInPromotionsValue} = this.props
    const promotionOptToggleValue = !optInPromotionsValue

    this.setState({loading:true, status: ''})
    optInPromotions({variables: {value: promotionOptToggleValue}}).then((_res) => {
      this.setState({loading:false, status: ''})
    }).catch((e) => {
      this.setState({loading:false, status: 'failure'})
    })

    const params = {
      source: 'Dashboard',
      brand: get(companyObject, 'brandLabel'),
      type: promotionOptToggleValue ? 'OptIn' : 'OptOut',
      userId: get(this.context, 'clientId'),
    }
    logEventCustomParams('optInButtonClicked', params)
  }

  render() {
    const {classes, promotionsEnabled, optInPromotionsValue} = this.props
    const {loading, status} = this.state
    const promotionsGiftImage = Images[`promotionsGift${promotionsEnabled ? '' : 'Gray'}.png`]
    const {weblinks} = config
    const locale = getItem('locale','en')
    return <Grid container className={classes.root}>
      <Grid item className={classes.giftImageItem}>
        <img className={classes.image} src={promotionsGiftImage} alt='promotionsGift'/>
      </Grid>
      <Grid item xs sm className={classes.handoutsItem}>
        <Grid container alignItems='center' className={classes.handoutsContainer}>
          <Grid item className={classes.handoutsIconContainer}>
            <SvgIcon className={classes.promotionsStarIcon}><PromotionsStar/></SvgIcon>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant='body1' className={classes.handoutsTitle}>
              <Trans {...messages.bonusHandoutsPromotions} />
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography className={classes.optInActivationMessage}>
            <Trans {...messages.bonusHandoutsActivationPromotions}/>
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={4} className={classes.secondSection}>
        <Grid item xs sm className={classes.optInTermsAndConditionsItem}>
          <Typography paragraph className={classes.optInTermsAndConditionsText}>
            <Trans {...messages.optInTermsAndConditionsPromotions} components={[
              <br key='br' />,
              <a key='a'
                target='_blank'
                rel='noopener noreferrer'
                className={classes.bold}
                href={weblinks.legalDocuments.replace('{lang}', locale)}>the Terms and Conditions</a>
            ]}/>
          </Typography>
        </Grid>
        <Grid item xs={2} sm={4} className={classes.optInButtonItem}>
          <LoadingButton
            id='optInButton'
            className={classes.optInButton}
            variant='text'
            size='small'
            color='inherit'
            disabled={!promotionsEnabled || loading || status === 'failure'}
            status={status}
            noMargin={true}
            hideProgressBar={!loading || !!status}
            onClick={() => this.handleToggleOptIn()}>
            <Typography className={classes.optInButtonText}>
              {optInPromotionsValue && <Trans {...messages.optOutPromotionsText} />}
              {!optInPromotionsValue && <Trans {...messages.optInPromotionsText} />}
            </Typography>
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  }
}

PromotionOptInCard.propTypes = {
  optInPromotionsValue: PropTypes.bool.isRequired,
  promotionsEnabled: PropTypes.bool.isRequired,
}

export default compose(
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
  graphql(OPT_IN_PROMOTION, {
    name: 'optInPromotions',
    options: () => ({
      refetchQueries: [{query: PROMOTIONS_QUERY}],
    }),
  }),
)(PromotionOptInCard)
