import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {graphql} from 'react-apollo'
import {isString, isEmpty, some, get, map, flowRight as compose, trim, find, omit, filter, includes, omitBy, isNull} from 'lodash'
import {withNamespaces, Trans} from 'react-i18next'
import Grid from '@mui/material/Grid'
import withStyles from '@mui/styles/withStyles'
import Typography from '@mui/material/Typography'
import {bankWireBanks, currencies, depositVendors, frontends, fundingCategories} from '@bdswiss/common-enums'
import messages from '../../../../assets/messages'
import {findCompany, getSourceBasedOnLocationPrevPath, logEventCustomParams, safeParseJSON} from '../../../../common/utils'
import {checkIbanMatchSwiftCountry} from '../../../../common/utils/general'
import TextField from '@mui/material/TextField'
import LoadingButton from '../../../Common/LoadingButton'
import FormHelperText from '@mui/material/FormHelperText'
import {BEGIN_DEPOSIT_MUTATION} from '../../../../graphql/mutations'
import NotificationBar from '../../../Common/NotificationBar'
import {JM_FINANCIAL_BANK_TRANSFER_DETAILS_QUERY, CLIENT_DATA_QUERY} from '../../../../graphql/queries'
import {isValidIBAN, isValidBIC, electronicFormatIBAN} from 'ibantools'
import {PDFDownloadLink} from '@react-pdf/renderer'
import {BankTransferDetailsPDF} from './BankTransferDetailsPDF'
import Images from '../../../Common/Images'
import Icon from '@mui/material/Icon'
import {Button, LinearProgress} from '@mui/material'
import {eSignDocument, eSignDocumentConfirmation} from '../../../../common/utils/requests'
import {FullScreenDialog} from '../../../Common/Dialog'
import PageTitle from '../../../Common/PageTitle'
import moment from 'moment'
import {wireTransferNotAvailableBanks} from '../../../../common/utils/uioptions'
import {withRouter} from 'react-router-dom'
import config from '../../../../config'
import {validateIsStringContainsEmoji} from '../../../../common/utils/validations'

const style = theme => ({
  greyText:{
    color: theme.palette.secondary.main
  },
  boldText:{
    fontWeight: 500
  },
  title: {
    marginTop: 0,
    marginBottom: 8
  },
  errorMessage:{
    color:  theme.palette.error.main,
  },
  noteText:{
    fontWeight: 300,
  },
  error: {
    color: theme.palette.error.main,
  },
  helperText: {
    paddingTop: 5
  },
  pdfImg: {
    height: 20,
    paddingRight: 10,
    marginBottom: -4,
  },
  warningIcon: {
    color: theme.palette.yellow.color,
    verticalAlign: 'sub',
    marginRight: '2px',
    fontSize: 20
  },
  bold: {
    fontWeight: 400,
  },
  signNowImg: {
    height: 20,
    paddingRight: 10,
    marginBottom: -4,
  },
  signNowButton: {
    marginTop: 10,
    padding: 0,
    '&:hover':{
      backgroundColor: 'transparent'
    },
    '&:active':{
      backgroundColor: theme.palette.background.default,
    },
  },
  iframe:{
    overflow: 'scroll',
    minHeight: '75vh',
    width: '100%'
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight:400,
  },
  test: {
    padding: 10,
    textAlign: 'center',
  },
  linearRoot: {
    width: '100%',
  },
  underline: {
    textDecoration: 'underline'
  },
  italic: {
    fontStyle: 'italic'
  },
  displayBlock: {
    display: 'block'
  }
})


const transferInfoOrderedKeyMessageMap = {
  recipient: messages.recipient,
  iban: 'IBAN',
  creditingAccount: messages.creditingAccount,
  swift: 'BIC-SWIFT-SEPA',
  bank: messages.bankName,
  address: messages.address,
  city: messages.cityName,
  country: messages.countryName,
  currency: messages.currency,
  name: messages.name,
  bankAccountNumber: messages.bankAccountNumber,
  ifscCode: messages.ifscCode,
  branchName: messages.branchName,
}


class BankWireTransferProvider extends Component {

  constructor(props) {
    super(props)
    this.state = {
      form: {
        bankName:'',
        bankAccountHolderName: '',
        iban:'',
        swiftCode:'',
      },
      errors: {},
      loading: false,
      showPopup: false,
      documentSigned: false,
      documentExecutionError: false,
      iframeLoading: true,
      selectedAccount: 'new',
    }
  }

  handleChange (name, value) {
    this.setState(state => ({
      form: {
        ...state.form,
        [name]: value
      },
      errors: {
        ...state.errors,
        [name]: !value,
        ibanSwiftNotCoherent: false
      },
      status: ''
    }))
  }

  handleAccountChange(selected) {
    if (selected === 'new') {
      this.setState(state => ({
        form: {
          bankName: '',
          bankAccountHolderName: '',
          iban: '',
          swiftCode: '',
        },
        errors: {
          bankName: false,
          bankAccountHolderName: false,
          iban: false,
          swiftCode: false,
          ibanSwiftNotCoherent: false
        },
        status: '',
        selectedAccount: selected,
      }))
      return
    }

    const {bankAccounts} = safeParseJSON(this.props.providerProperties.additionalFields)
    const selectedBankDetails = find(bankAccounts, {iban: selected}) || {}

    this.setState(state => ({
      form: {
        bankName: get(selectedBankDetails, 'bankName'),
        bankAccountHolderName: get(selectedBankDetails, 'bankAccountHolderName'),
        iban: get(selectedBankDetails, 'iban'),
        swiftCode: get(selectedBankDetails, 'swiftCode'),
      },
      errors: {
        bankName: isNull(selectedBankDetails, 'bankName'),
        bankAccountHolderName: isNull(selectedBankDetails, 'bankAccountHolderName'),
        iban: isNull(selectedBankDetails, 'iban'),
        swiftCode: isNull(selectedBankDetails, 'swiftCode'),
        ibanSwiftNotCoherent: false
      },
      status: '',
      selectedAccount: selected,
    }))
  }

  handleSubmit() {
    const {form} = this.state
    const {account, amount, bonusAmount, providerProperties:{provider, paymentKey}, bonusTerms, id: paymentOptionId,
      history: {location}} = this.props
    const errors = {}
    form.iban = form.iban && electronicFormatIBAN(form.iban).toUpperCase()
    form.swiftCode = form.swiftCode && trim(form.swiftCode).toUpperCase()
    const isIBANValid = form.iban && isValidIBAN(form.iban)
    const isBICValid = form.swiftCode && isValidBIC(form.swiftCode)
    for (const field of Object.keys(form)) {
      errors[field] = isEmpty(form[field])
      if (field === 'iban' && !isEmpty(form[field])) {
        errors[field] = !isIBANValid && 'validation'
        if (errors[field] && (!isNaN( form[field]) && form[field].length >= 6 && form[field].length <= 20)) {
          errors[field] = false
        }
      }
      if (field === 'swiftCode' && (form.iban && isIBANValid) && !isEmpty(form[field])) {
        errors[field] = !isBICValid  && 'validation'
      }
    }
    errors.ibanSwiftNotCoherent = !errors.iban && !errors.swiftCode && isIBANValid && checkIbanMatchSwiftCountry(form.iban, form.swiftCode)
    errors.bankName = validateIsStringContainsEmoji(form.bankName)
    errors.bankAccountHolderName = validateIsStringContainsEmoji(form.bankAccountHolderName)

    if (some(errors)) {
      this.setState({errors})
      return
    }

    this.setState({status: '', loading: true})

    const firebaseParams = {
      source: getSourceBasedOnLocationPrevPath(get(location, 'state', {prevPath: get(location, 'pathname')})),
      login: account.id,
      currency: account.currency,
      vendor: provider,
      amount,
    }
    logEventCustomParams('depositAttempted', firebaseParams)

    const variables = {
      accountId: account.id,
      amount: amount,
      currency: account.currency,
      args: JSON.stringify({paymentFields: form, bonusAmount, paymentKey, bonusTerms}),
      vendor: paymentKey === 'wt' ? depositVendors.bankWire.value : depositVendors[provider].value,
      paymentOptionId,
      frontend: frontends.web2.value,
    }

    this.props.beginDeposit({variables}).then((succ) => {
      this.setState({loading: false, status: 'success', submitMessageError: ''})
    })
      .catch((err) => {
        this.setState({loading: false, status: 'failure', submitMessageError: get(err, 'graphQLErrors[0].message') || err.message})
      })
  }

  eSignDocumentAction(paymentProvider) {
    const {amount} = this.props
    const depositAmount = String(amount)
    eSignDocument(paymentProvider, depositAmount).then((res) => {
      if (res.result === 'success') {
        this.setState({
          showPopup: true,
          iFrameUrl: get(res.embeddedSigningSessions[0], 'embeddedSessionURL'),
          folderId: res && get(res.folder, 'folderId'),
          folderName: res && get(res.folder, 'folderName')
        })
      }
    }).catch((e) => {
      console.log(e) //eslint-disable-line no-console
    })
  }

  eSignDocumentConfirmed(paymentProvider) {
    const {folderId, folderName} = this.state
    eSignDocumentConfirmation(paymentProvider, folderId, folderName).then((res) => {
      const documentExecuted = get(res, 'folder.envelopeStatus') === 'EXECUTED'
      this.setState({showPopup: true})
      if (res.result === 'success' && documentExecuted) {
        this.setState({showPopup: false, documentSigned: true, showExecutionError: false, iframeLoading: true})
      } else {
        this.setState({showPopup: false, showExecutionError: true, iframeLoading: true})
      }
    })
  }

  isSubmitButtonDisabledWhenAnyFieldsEmpty() {
    const {form: {bankName, bankAccountHolderName, iban, swiftCode}} = this.state
    return !bankName || !bankAccountHolderName || !iban || !swiftCode
  }

  render() {
    const {account: {id: accountId, currency, remoteId}, viewer: {id: clientId, paymentMethods}, classes, t, providerProperties,
      getJMFinancialBankTransferDetails, history, canProceed} = this.props
    const {
      form: {bankName, bankAccountHolderName, iban, swiftCode},
      loading,
      status,
      submitMessageError,
      errors,
      showPopup,
      // iFrameUrl,
      documentSigned,
      showExecutionError,
      iframeLoading,
      selectedAccount,
    } = this.state

    const {bankAccounts} = safeParseJSON(providerProperties.additionalFields)

    const {forcedCompany} = config
    const companyObject = findCompany(forcedCompany)
    const companyBrandLabel = companyObject.brandLabel
    const paymentProvider = get(providerProperties, 'provider')
    const bankDetails = find(get(providerProperties, 'bankDetails'), (details) => details.clientAccountCurrency === currency) ||
      (
        paymentProvider !== depositVendors.easypay.key &&
        find(get(providerProperties, 'bankDetails'), (details) => details.clientAccountCurrency === currencies.EUR.value)
      )
    const currencyCheck = currency !== get(bankDetails, 'clientAccountCurrency') && paymentProvider !== depositVendors.easypay.key
    const bankTranferInfo = ['bankWire', depositVendors.easypay.key].includes(paymentProvider) ? bankDetails : getJMFinancialBankTransferDetails
    const showBankDetails = documentSigned || ['bankWire', depositVendors.easypay.key].includes(paymentProvider)
    const visibleBankDetails = omitBy(bankTranferInfo, (i, key) => ['__typename', 'clientAccountCurrency', 'reference'].includes(key) || !i)
    const swiftCodeLabel = paymentProvider === depositVendors.easypay.key ? messages.ifscCode : messages.yourSwiftBic

    const today = moment().format()
    const notAvailableBanks = filter(wireTransferNotAvailableBanks, (bank) => {
      const endDate = moment(bank.startDate).add(bank.activeDays, 'd')
      return includes(get(bank, 'availablePayments'), paymentProvider)  &&
        moment(today).isSameOrBefore(moment(endDate).format(), 'day')
    })
    const showDepositVerificationButton = find(paymentMethods, (method) => !method.confirmed && method.fundingCategory === fundingCategories.bankWire.value && method.details === iban
      && !depositVendors[paymentProvider].signatureRequired)
    return (
      <Grid container justifyContent="flex-start" alignItems="flex-start" direction="column">
        {(status !== 'success') ? <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3" className={classes.title}>
              <Trans {...messages.yourBankDetails} />
            </Typography>
            {!isEmpty(notAvailableBanks) && map(notAvailableBanks, (bank) => (<NotificationBar
              status="warning"
              key={bank.key}
              title={<Trans {...messages.wireTransferNotificationBankNotAvailable}
                values={{bankName: bank.label, date: moment(bank.startDate).format('LL')}}
                components={[<span className={classes.bold} key={'b'}>text</span>]}
              />}
            />)
            )}
            <Typography variant="body1">
              <Trans {...messages.yourBankDetailsText} />
            </Typography>
            {this.props?.providerProperties?.paymentKey ===  bankWireBanks.spectrum.key ?
              <Typography variant="body1">
                <Trans {...messages.equalsMoneyBankWarning} />
              </Typography>
              : null
            }
            {this.props?.providerProperties?.paymentKey ===  bankWireBanks.absa.key ?
              <Typography variant="body1">
                <Trans {...messages.absaBankWarning} />
              </Typography>
              : null
            }
            <Typography variant="caption" color='error'>
              *<Trans {...messages.yourBankDetailsRequired} values={{company: companyBrandLabel}}/>
            </Typography>
          </Grid>

          {!!bankAccounts && !!bankAccounts.length && <Grid item xs={12}>
            <FormControl variant="standard" fullWidth>
              <InputLabel htmlFor="selectAccount">
                <Trans {...messages.account} />
              </InputLabel>
              <Select
                variant="standard"
                value={selectedAccount}
                onChange={(e) => this.handleAccountChange(e.target.value)}>
                <MenuItem key="new" value="new">
                  <Trans {...messages.createNewAccount} />
                </MenuItem>
                {map(bankAccounts, (account) => <MenuItem key={account.iban} value={account.iban}>{account.iban} {account.bankName}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>}

          {selectedAccount === 'new' && <>
            <Grid item xs={12} sm={12}>
              <TextField
                variant="standard"
                required
                id="bankName"
                name="bankName"
                label={t(messages.bankName.i18nKey, messages.bankName.defaults)}
                fullWidth
                autoComplete="bankName"
                error={errors.bankName}
                value={bankName}
                onChange={(e) => this.handleChange('bankName', e.target.value)} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                variant="standard"
                required
                id="bankAccountHolderName"
                name="bankAccountHolderName"
                label={t(messages.yourBankAccountHolderName.i18nKey, messages.yourBankAccountHolderName.defaults)}
                fullWidth
                autoComplete="bankAccountHolderName"
                error={errors.bankAccountHolderName}
                value={bankAccountHolderName}
                onChange={(e) => this.handleChange('bankAccountHolderName', e.target.value)} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                variant="standard"
                required
                id="iban"
                name="iban"
                label={t(messages.yourIbanAccountNumber.i18nKey, messages.yourIbanAccountNumber.defaults)}
                fullWidth
                autoComplete="iban"
                error={!!errors.iban}
                value={iban}
                onChange={(e) => this.handleChange('iban', e.target.value)}
                inputProps={{style: {textTransform: 'uppercase'}}} />
              {get(errors, 'iban') === 'validation' &&
                <FormHelperText className={classes.error}><Trans {...messages.ibanValidation} /></FormHelperText>}
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                variant="standard"
                required
                id="swiftCode"
                name="swiftCode"
                label={t(swiftCodeLabel.i18nKey, swiftCodeLabel.defaults)}
                fullWidth
                autoComplete="swiftCode"
                error={!!errors.swiftCode}
                value={swiftCode}
                onChange={(e) => this.handleChange('swiftCode', e.target.value)} />
              {(get(errors, 'swiftCode') === 'validation' || errors.ibanSwiftNotCoherent) &&
                <FormHelperText className={classes.error}>
                  <Trans {...messages[errors.ibanSwiftNotCoherent ? 'ibanSwiftNotCoherent' : 'swiftCodeValidation']} /></FormHelperText>}
            </Grid>
          </>}
          <Grid item xs={12}>
            <LoadingButton
              id='loadingButton'
              onClick={() => this.handleSubmit()}
              hideProgressBar={!loading}
              disabled={canProceed || this.isSubmitButtonDisabledWhenAnyFieldsEmpty()}
              status={status}
            ><Trans {...messages.submit} />
            </LoadingButton>
            {status === 'failure' && <FormHelperText className={classnames(classes.helperText, classes.error)}>
              {submitMessageError}</FormHelperText>}
          </Grid>
        </Grid> : <Grid container spacing={3}>
          {depositVendors[paymentProvider].signatureRequired ?  <Grid container spacing={3}>
            <Grid item xs={12}>
              {documentSigned && <NotificationBar
                status="success"
                title={<Trans {...messages.bankTransferJMFinancialAcknowledgmentText}/>}
              />}
              {!documentSigned && <NotificationBar
                status="success"
                title={<Trans {...messages.bankTransferJMFinancialText}/>}
              />}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3" className={classes.title}>
                <Trans {...messages.bankTransfer} />
              </Typography>
              {!showBankDetails && <Typography variant="body1">
                <Trans {...messages.bankTransferSignText} />
              </Typography>}
              {!showBankDetails && <Button
                disableFocusRipple
                disableRipple
                id='signNowButton'
                onClick={() => this.eSignDocumentAction(paymentProvider)}
                variant="text"
                color="primary"
                className={classes.signNowButton}
              >
                <span>
                  <img src={Images['sign-up.png']} alt={'signNow'} className={classes.signNowImg}/>
                  <Trans {...messages.signNow} />
                </span>

              </Button>}
              {showExecutionError && <FormHelperText className={classnames(classes.helperText, classes.error)}>
                <Trans {...messages.documentExecutionError} />
              </FormHelperText>}
            </Grid>
          </Grid> : <Grid item xs={12}>
            <NotificationBar
              status="success"
              title={<Trans {...messages.submittedWireDetails}
                values={{company: companyBrandLabel}}
                components={[<span className={classes.bold}>access your personal bank\'s payment facility directly (either via online banking or in person)</span>]}
              />}
            />
            <NotificationBar
              noMargin
              status="warning"
              title={<React.Fragment>
                <span className={classes.displayBlock}>
                  <Icon className={classes.warningIcon}> warning </Icon>
                  <Trans {...messages.warningTag} components={[<span className={classes.bold}> </span>]}/>: <Trans
                    {...messages[get(bankDetails, 'reference') ? 'submittedWireDetailsWarningAdditionalReference' : 'submittedWireDetailsWarningAdditionalFormattedNew']}
                    components={[<span className={classes.italic}>field</span>, <span className={classes.underline}>must</span>]}
                  />
                </span>
                <span variant="body1" className={classnames(classes.underline, classes.displayBlock)}>
                  <Trans {...messages.example} />:
                </span>
                <span variant="body1" className={classes.displayBlock}>
                  <Trans {...messages.fundingAccountExample} values={{clientId: '123456 - 1234567'}}/>
                </span>
              </React.Fragment>}
            />
          </Grid>}
          {!depositVendors[paymentProvider].signatureRequired && <Grid item xs={12}>
            <Typography variant="h3" className={classnames(classes.title, (currencyCheck) ? classes.boldText : '')}>
              {(currencyCheck)
                ?<Trans
                  {...messages.bankTransferTitle2}
                />
                :<Trans
                  {...messages.bankTransferTitle}
                  values={{company: companyBrandLabel}}
                />}
            </Typography>
            <Typography variant="body1" className={classnames(classes.greyText, classes.noteText)}>
              <Trans {...messages.wireTransferNote} />
            </Typography>
          </Grid>}
          <React.Fragment>
            {showBankDetails && <Grid item xs={12}>
              <PDFDownloadLink
                document={<BankTransferDetailsPDF transferInfo={bankTranferInfo || {}}
                  clientId={clientId}
                  accountId={accountId}
                  remoteId={remoteId}
                  paymentProvider={paymentProvider}
                />
                }
                fileName={`${companyBrandLabel}-banktransferdetails.pdf`}
              >
                {({blob, url, loading, error}) => (loading ? 'Loading document...' : <Typography variant={'body2'} color="primary">
                  <span>
                    <img src={Images['downloadPdf.png']} alt={'downloadPdf'} className={classes.pdfImg}/>
                    <Trans {...messages.downloadPdf} />
                  </span>
                </Typography>
                )}
              </PDFDownloadLink>
            </Grid>}
            {showBankDetails && <Grid item xs={12}>
              <Typography variant="body1" className={classes.greyText}><Trans {...messages.referenceNumber} /></Typography>
              {(get(bankDetails, 'reference') && paymentProvider !== 'bankWire') && <Typography variant="body1">{bankDetails.reference}</Typography>}
              <Typography variant="body1">
                <Trans {...messages.fundingClientIdRemoteId} values={{clientId: clientId, accountRemoteId: remoteId}}/>
              </Typography>
            </Grid>}
            {showBankDetails && map(visibleBankDetails, (details, key) => {
              const title = transferInfoOrderedKeyMessageMap[key]
              const translatedTitle = isString(title) ? title : <Trans {...title} />
              return <Grid item xs={12} key={key}>
                <Typography variant="body1" className={classes.greyText}>{translatedTitle}</Typography>
                <Typography variant="body1">{details}</Typography>
              </Grid>
            })}
            {showDepositVerificationButton && <Button
              onClick={() => history.push('/settings/profile/documents/upload/pof')}
              color="primary"
              variant="contained"
              size="large">
              <Trans {...messages.paymentDocuments} />
            </Button>}
          </React.Fragment>
        </Grid>}
        {showPopup && <FullScreenDialog
          desktopOnly
          fullScreen
          open={true}
          onClose={() => this.eSignDocumentConfirmed(paymentProvider)}
        >
          <PageTitle modal onBack={() => this.eSignDocumentConfirmed(paymentProvider)}><Trans {...messages.signNow} /></PageTitle>
          <React.Fragment>
            {<Grid container justifyContent='center'>
              <Grid item xs={12} className={classes.test}>
                <Typography variant="body1">
                  <Trans {...messages.extraSigningText1}
                    components={[
                      <span onClick={() => this.eSignDocumentConfirmed(paymentProvider)}
                        className={classes.link}>here</span>,
                    ]}/>
                </Typography>
                <Typography variant="body1">
                  <Trans {...messages.extraSigningText2}
                    components={[<span className={classes.bold}>Back button</span>]}/></Typography>
              </Grid>
            </Grid>}
            {iframeLoading && <LinearProgress classes={{root: classes.linearRoot}} />}
            {/*{iFrameUrl && <iframe*/}
            {/*  src={iFrameUrl}*/}
            {/*  frameBorder="0"*/}
            {/*  id="tradeCompanion"*/}
            {/*  title="tradeCompanion"*/}
            {/*  onLoad={() => this.setState({iframeLoading: false})}*/}
            {/*  className={classes.iframe}*/}
            {/*/>}*/}
          </React.Fragment>
        </FullScreenDialog>
        }
      </Grid>
    )
  }
}


BankWireTransferProvider.propTypes = {
  viewer: PropTypes.object.isRequired,
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  amount: PropTypes.number.isRequired,
  providerProperties: PropTypes.shape({
    name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    paymentKey: PropTypes.string,
    paymentOptionId: PropTypes.number,
  }).isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

export default compose(
  withStyles(style, {withTheme: true}),
  withNamespaces(),
  withRouter,
  graphql(BEGIN_DEPOSIT_MUTATION, {
    name: 'beginDeposit',
    options: {
      refetchQueries: [{query: CLIENT_DATA_QUERY}],
    },
  }),
  graphql(JM_FINANCIAL_BANK_TRANSFER_DETAILS_QUERY, {
    options: (props) => ({variables: {accountId: get(props.account, 'id')}}),
    props: ({data: {error: errorBankDetails, loading: loadingBankDetails}, data}) => {
      const getJMFinancialBankTransferDetails = get(data, 'getJMFinancialBankTransferDetails')
      return {
        errorBankDetails,
        loadingBankDetails,
        getJMFinancialBankTransferDetails : omit(getJMFinancialBankTransferDetails,'__typename'),
      }
    }
  }),
)(BankWireTransferProvider)
