import PropTypes from 'prop-types'
import {flowRight as compose, get, some, values, find, map, filter, toUpper} from 'lodash'
import React, {Component} from 'react'
import {frontends, cardpayPaymentTypes, cardpayDocumentTypes} from '@bdswiss/common-enums'
import {Trans} from 'react-i18next'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Input from '@mui/material/Input'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import withStyles from '@mui/styles/withStyles'
import {getPlatform, getSourceBasedOnLocationPrevPath, logEventCustomParams} from '../../../../common/utils'
import messages from '../../../../assets/messages'
import {withCreateDeposit, PaymentActionButton, CompanyDetails} from './helpers'
import DescriptionPaymentMethod from './DescriptionPaymentMethod'

const styles = theme => ({
  error: {
    color: theme.palette.red.color
  },
})

class CardpayRedirectProvider extends Component {

  state = {gettingUrl: false, errors: {}, form: {identity: '', documentType: ''}}

  getUrl() {
    const {providerProperties: {provider, paymentKey, id: paymentOptionId}, account, amount, onError, useVirtualPaymentPage, bonusAmount, bonusTerms,
      history: {location}} = this.props

    const form = this.state.form.identity
      ? {
        ...this.state.form,
        identity: this.state.form.identity.replace(/[^\d]/g, '')
      }
      : this.state.form

    this.setState({gettingUrl: true})

    const firebaseParams = {
      source: getSourceBasedOnLocationPrevPath(get(location, 'state', {prevPath: get(location, 'pathname')})),
      login: account.id,
      currency: account.currency,
      vendor: provider,
      amount,
    }
    logEventCustomParams('depositAttempted', firebaseParams)

    const variables = {
      accountId: account.id,
      amount,
      vendor: provider,
      paymentOptionId,
      frontend: frontends.web2.value,
      args: JSON.stringify({
        platform: getPlatform(),
        paymentKey,
        useVirtualPaymentPage,
        bonusAmount,
        bonusTerms,
        form,
      }),
    }

    this.props.createDepositRequest({variables})
      .then(({data: {newDeposit}}) => {
        const {payment: {url}} = newDeposit
        window.location = url
      })
      .catch((e) => {
        this.setState({gettingUrl: false})
        onError && onError(e)
      })
  }

  handleChange = (name, value) => {
    this.setState({
      form: {
        ...this.state.form,
        [name]: value,
      },
      errors: {
        ...this.state.errors,
        [name]: !value,
      }
    })
  }

  render() {
    const {providerProperties: {provider, paymentKey}, classes, viewer: {address: {country} = {}, company}, canProceed} = this.props
    const {gettingUrl, form, errors} = this.state
    const requiresCustomerId = get(find(cardpayPaymentTypes, {value: paymentKey}), 'requiresCustomerId', false)
    const documentTypes = filter(cardpayDocumentTypes, (documentType) => documentType.country.value === toUpper(country))
    const nationalIdNumberPlaceholder = messages[`nationalIdNumber${toUpper(country)}`] || messages.nationalIdNumber
    const nationalIdCaption =  messages[`nationalId${toUpper(country)}`] || messages.nationalId
    return (
      <React.Fragment>
        <DescriptionPaymentMethod provider={provider}/>
        {paymentKey === cardpayPaymentTypes.pagoEfectivo.value && <Grid item xs={12}>
          <FormControl variant="standard" fullWidth>
            <InputLabel htmlFor="documentType">
              <Trans {...messages.documentType} />
            </InputLabel>
            <Select
              variant="standard"
              value={get(form, 'documentType', '')}
              onChange={(e) => this.handleChange('documentType', e.target.value)}>
              {map(documentTypes, (documentType) => <MenuItem key={documentType.key} value={documentType.value}>{documentType.label}</MenuItem>)}
            </Select>
            {errors.documentType &&
            <FormHelperText className={classes.error}><Trans {...messages.requiredField} /></FormHelperText>
            }
          </FormControl>
        </Grid>}
        {requiresCustomerId && <Grid item xs={12}>
          <FormControl variant="standard" fullWidth>
            <InputLabel htmlFor="identity">
              <Trans  {...nationalIdNumberPlaceholder} />
            </InputLabel>
            <Input
              id="identity"
              type="text"
              value={get(form, 'identity', '')}
              error={!!errors.identity}
              onChange={(e) => this.handleChange('identity', e.target.value)}
              inputProps={{pattern: '[0-9]*', inputMode:'numeric', autoComplete: 'off'}}
            />
            <FormHelperText><Trans {...nationalIdCaption} /></FormHelperText>
            {errors.identity &&
            <FormHelperText className={classes.error}><Trans {...messages.requiredField} /></FormHelperText>
            }
          </FormControl>
        </Grid>}
        <PaymentActionButton
          loading={gettingUrl}
          onClick={() =>  this.getUrl()}
          disable={canProceed || (requiresCustomerId && (some(values(errors)) || !form.identity))}
          country={country}
        />
        <CompanyDetails company={company} />
      </React.Fragment>
    )
  }
}

CardpayRedirectProvider.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  amount: PropTypes.number.isRequired,
  providerProperties: PropTypes.shape({
    name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    paymentKey: PropTypes.string,
    paymentOptionId: PropTypes.number.isRequired,
  }).isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withCreateDeposit
)(CardpayRedirectProvider)
