import React, {Component} from 'react'
import {graphql} from 'react-apollo'
import {map, flowRight as compose, get, isEmpty, replace, includes, toUpper} from 'lodash'
import {withNamespaces, Trans} from 'react-i18next'
import Grid from '@mui/material/Grid'
import withStyles from '@mui/styles/withStyles'
import Typography from '@mui/material/Typography'
import messages from '../../assets/messages'
import Button from '@mui/material/Button'
import classNames from 'classnames'
import AppContext from '../Common/contexts/AppContext'
import {AlertDialog} from '../Common/Dialog'
import Images from '../Common/Images'
import {isMobile, getCurrentTheme, removeItem} from '../../common/utils'
import {eBookHelperText} from '../../common/utils/uioptions'
import config from '../../config'
import {fireDownloadEbookEvent} from '../../common/utils/tagsManager'
import {CLIENT_DATA_QUERY} from '../../graphql/queries'
import Loading from './Loading'
import queryString from 'qs'
import PageTitle from './PageTitle'
import {Card} from '@mui/material'
import {withRouter} from 'react-router-dom'
import {logEventCustomParams} from '../../common/utils/firebaseEvents'

const styles = theme => ({
  iconClose: {
    width: 25,
    height: 25,
    position: 'absolute',
    right: 9,
    top: 12,
    backgroundSize: '20px 20px',
    paddingLeft: 10,
    display: 'block',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
    backgroundImage: `url(${Images[`icon-close-${getCurrentTheme()}.svg`]})`,
    [theme.breakpoints.down('sm')]: {
      backgroundSize: '20px 20px',
      position: 'initial',
    },
  },
  closeDiv: {
    position: 'relative',
  },
  textCenter: {
    textAlign: 'center',
  },
  img: {
    maxWidth: 250,
    textAlign: 'center',
  },
  title: {
    fontWeight: 400,
    marginTop: -5,
    lineHeight: 'normal',
  },
  pointContent: {
    paddingBottom: 20,
  },
  list: {
    padding: 0,
    textAlign: 'left',
    '&::before': {
      color: theme.palette.red.color,
    },
  },
  ul: {
    listStyle: 'none',
    '&::before': {
      color: theme.palette.red.color,
    },
  },
  zeroMarginTop: {
    color: theme.palette.red.color,
    marginTop: 0,
    paddingLeft: 15,
    paddingBottom: 0,
    paddingTop: 0,
    lineHeight: 1.8,
  },
  inline: {
    display: 'inline',
  },
  downloadEbook: {
    height: 20,
    paddingRight: 10,
    paddingLeft: 10,
    marginBottom: -4,
  },
  downloadBtn: {
    marginTop: 10,
  },
  marginBottom: {
    [theme.breakpoints.up('md')]: {
      marginBottom: '2rem',
    },
  },
  logo: {
    width: 30,
    height: '100%',
  },
  typeBadge: {
    height: 40,
    width: 40,
    borderRadius: 20,
    marginRight: 18,
    [theme.breakpoints.down('sm')]: {
      marginRight: '10px',
      marginTop: '-7px',
    },
  },
  cardContent: {
    flexGrow: 1,
    marginTop: '4px',
  },
  divClose: {
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(-2),
    },
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingLeft: 10,
  },
  ebookItemWrap: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      margin: `${theme.spacing(1)} 0px`,
    },
  },
  alertDialogRoot: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3)
    },
  },
})

class eBookDialog extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      selectedEbook: '',
    }
  }

  componentDidMount() {
    const {location, viewer} = this.props
    const {
      featuresConfig: {tools},
    } = config
    const urlParamsString = replace(location.search, '?', '') || replace(get(location.state, 'from.search'), '?', '')
    const urlParams = queryString.parse(urlParamsString)
    logEventCustomParams('forexEbookClicked', {userId: get(viewer, 'id')})
    if (get(urlParams, 'ebook') && get(tools.ebook.ebooks, urlParams.ebook))
      this.setState({selectedEbook: urlParams.ebook})
  }

  componentWillUnmount() {
    removeItem('ebook')
  }

  downloadEbook(clientId, ebook, ebookKey) {
    const {gTagContainerId} = config
    const {locale} = this.context
    if (gTagContainerId) {
      fireDownloadEbookEvent(clientId, ebookKey)
    }
    return window.open(
      replace(
        get(ebook, 'url'),
        '{lang}',
        `${ebook.translations && includes(ebook.translations, locale) ? `_${toUpper(locale)}` : ''}`,
      ),
      '_blank',
    )
  }

  checkRedirectOnClose() {
    const {history, location} = this.props
    const queryParams = queryString.parse(location.search.replace('?', ''))
    const locationTrack = get(location, 'state.locationTrack') || get(queryParams, 'locationTrack')
    removeItem('ebook')
    if (!isEmpty(locationTrack)) {
      return history.push('/')
    } else {
      return history.push('/tools')
    }
  }

  renderDialog() {
    const {classes, viewer} = this.props
    const {selectedEbook} = this.state
    const {
      featuresConfig: {tools},
    } = config

    return (
      <AlertDialog
        fullScreen
        open={!!selectedEbook}
        onClose={() => this.setState({selectedEbook: ''})}
        maxWidth={false}
        alertBodyClass={classes.alertDialogRoot}
      >
        <Grid item xs={12} key={selectedEbook}>
          <Grid container spacing={isMobile() ? 1 : 2} align={!isMobile() ? 'left' : 'center'}>
            <Grid container justifyContent="flex-end">
              <Grid item className={classes.divClose}>
                <div className={classes.iconClose} onClick={() => this.setState({selectedEbook: ''})} />
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4} className={classes.textCenter}>
              <img
                className={classNames(classes.img)}
                src={Images[`${selectedEbook}-ebook.png`]}
                alt={`${selectedEbook}-ebook.png`}
              />
            </Grid>
            <Grid item xs={12} lg={8}>
              <Typography variant="h3" className={classes.title} align={!isMobile() ? 'left' : 'center'}>
                <Trans {...messages[`${selectedEbook}Title`]} />
              </Typography>
              <Typography variant="body1" className={classes.pointContent} align={!isMobile() ? 'left' : 'center'}>
                <Trans {...messages[`${selectedEbook}Subtitle`]} components={[<br key="br" />]} />
              </Typography>
              <ul className={classNames(classes.zeroMarginTop)}>
                {map(eBookHelperText[selectedEbook].points, key => (
                  <li key={key} className={classNames(classes.list)}>
                    <Typography variant="body1" classes={{root: classes.inline}}>
                      <Trans
                        {...messages[key]}
                        components={[<span key={key} dangerouslySetInnerHTML={{__html: '&#174;'}} />]}
                      />{' '}
                    </Typography>
                  </li>
                ))}
              </ul>
              <Button
                id="donwloadButton"
                onClick={() =>
                  this.downloadEbook(get(viewer, 'id'), get(tools.ebook.ebooks, selectedEbook), selectedEbook)
                }
                variant="contained"
                color="primary"
                className={classes.downloadBtn}
              >
                <span>
                  <img src={Images['downloadPdf-light.png']} alt={'downloadEbook'} className={classes.downloadEbook} />
                  <Trans {...messages.download} />
                </span>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </AlertDialog>
    )
  }

  render() {
    const {t, classes, viewer, viewerLoading, history} = this.props
    const {selectedEbook} = this.state
    const {featuresConfig: {tools}} = config
    const {themePreference, companyObject} = this.context

    if (!viewer || viewerLoading) return <Loading />

    return (
      <PageTitle
        themePreference={themePreference}
        onBack={() => history.push('/tools')}
        title={t(messages.eBookDialogTitle.i18nKey, messages.eBookDialogTitle.defaults)}
      >
        <Grid container sx={{p: {xs: 2, sm: 0}}}>
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.marginBottom}>
              <Trans {...messages.eBookDialogDescription} values={{company: companyObject.brandLabel}} />
            </Typography>
          </Grid>
          {map(get(tools.ebook, 'ebooks'), (ebookValue, ebookKey) => (
            <Grid container className={classes.ebookItemWrap} key={ebookKey}>
              <Grid item xs={12} md={8} lg={6}>
                <Card onClick={() => this.setState({selectedEbook: ebookKey})}>
                  <Grid container>
                    <Grid item xs={2} lg={1}>
                      <img alt={ebookKey} src={Images[`${ebookKey}Item.svg`]} className={classes.logo} />
                    </Grid>
                    <Grid item xs={10} lg={11} className={classes.item}>
                      <Typography variant="subtitle1">
                        <Trans {...messages[`${ebookKey}Title`]} />
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          ))}
        </Grid>
        {selectedEbook && this.renderDialog()}
      </PageTitle>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  withRouter,
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading: viewerLoading}, data}) => ({
      error,
      viewerLoading,
      viewer: get(data, 'viewer'),
    }),
  }),
)(eBookDialog)
