import React from 'react'
import Intl from 'intl'
import _ from 'lodash'
import {currencies} from '@bdswiss/common-enums'

const Amount = (props) => {
  const {locale, currency, value} = props
  const classes = _.defaults(props.classes || {}, {
    root: 'amount'
  })
  const isCrypto = _.get(currencies[currency], 'isCrypto', false)
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: isCrypto ? 8 : 2,
  })
  const hasBalance = value || value >= 0
  if (!hasBalance) {return <span className={classes.root}>-</span>}
  const parts = formatter.formatToParts(value).map(part => {
    const type =
      ['integer', 'group'].includes(part.type) ? 'value' : (part.type === 'decimal' ? 'fraction' : part.type)
    return {...part, type}
  }).reduce((parts, next) => {
    if (parts.length > 0 && parts[parts.length - 1].type === next.type) {

      const copy = [...parts]
      const lastPart = copy[copy.length - 1]
      copy[copy.length - 1] = {...lastPart, value: lastPart.value + next.value}
      return copy
    } else {
      return [...parts, next]
    }
  }, [])
  return <span className={classes.root}>
    {
      parts.map((part, index) =>
        <span key={index} className={classes[part.type] || part.type}>{part.value}</span>
      )
    }
  </span>
}

export default Amount
