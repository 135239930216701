import React, {Component} from 'react'
import withStyles from '@mui/styles/withStyles'
import Grid from '@mui/material/Grid'
import {withNamespaces, Trans} from 'react-i18next'
import {Typography} from '@mui/material'
import messages from '../../../../assets/messages'
import Amount from '../../../Common/Amount'
import {isMobile} from '../../../../common/utils'
import {get} from 'lodash'
import classNames from 'classnames'

const fontRatio = isMobile() ? 0.5 : 0.7
const styles = theme => ({
  amountCurrency: {
    fontSize: Math.floor(24 * fontRatio),
    fontWeight: 300,
    padding: '0 3px',
  },
  amountValue: {
    fontSize: Math.floor(36 * fontRatio),
    fontWeight: 300,
  },
  amountFraction: {
    fontSize: Math.floor(24 * fontRatio),
    fontWeight: 300,
  },
  providerItem: {
    marginTop: 0,
    textAlign: 'left',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  providerGrid: {
    padding: '0px 10px',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  providerCardItem:{
    marginTop: isMobile() ? 2 : 5,
    display: 'block',
    fontSize: Math.floor(36 * fontRatio),
    fontWeight: 300,
  },
  textRight: {
    textAlign: 'right'
  },
  notificationBox: {
    backgroundColor: theme.palette.extralightgrey.color,
    marginTop: 15
  }
})

class ProviderStatsCard extends Component {

  constructor(props) {
    super(props)
    this.state = {
      status: '',
      loading: false
    }
  }

  render() {
    const {classes, account, summaries, allTime, activeMonth} = this.props
    return <Grid container>
      <Grid item xs={12} className={classes.providerGrid}>
        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
          <Grid item className={classes.providerItem} xs={isMobile() ? 6 : 'auto'}>
            <Typography variant='caption'><Trans {...messages.totalCopiers} /></Typography>
            <Typography variant='body1' className={classes.providerCardItem}>{allTime ? get(summaries, 'totalCopiers',0) : get(summaries, 'copiersPerMonth')}</Typography>
          </Grid>
          {allTime ? <Grid item className={classNames(classes.providerItem, isMobile() && classes.textRight)} xs={isMobile() ? 6 : 'auto'}>
            <Typography variant='caption'><Trans {...messages.activeCopiers} /></Typography>
            <Typography variant='body1' className={classes.providerCardItem}>{get(summaries, 'activeCopiers')}</Typography>
          </Grid> : <Grid item className={classNames(classes.providerItem, isMobile() && classes.textRight)} xs={isMobile() ? 6 : 'auto'}>
            <Typography variant='caption'><Trans {...messages.openCopiedTrades} /></Typography>
            <Typography variant='body1' className={classes.providerCardItem}>{get(summaries, 'openTradesPerMonth',0)}</Typography>
          </Grid>
          }
          <Grid item className={classNames(classes.providerItem)} xs={isMobile() ? 6 : 'auto'}>
            <Typography variant='caption'><Trans {...messages[(activeMonth || allTime) ? 'losingTrades' : 'closedLosingTrades']} /></Typography>
            <Typography variant='body1' className={classes.providerCardItem}>{allTime ? get(summaries, 'totalLosing', 0) : get(summaries, 'losingPerMonth')}</Typography>
          </Grid>
          <Grid item className={classNames(classes.providerItem, isMobile() && classes.textRight)} xs={isMobile() ? 6 : 'auto'}>
            <Typography variant='caption'><Trans {...messages[(activeMonth || allTime) ? 'winningTrades' : 'closedWinningTrades']} /></Typography>
            <Typography variant='body1' className={classes.providerCardItem}>{allTime ? get(summaries, 'totalWinning', 0) : get(summaries, 'winningPerMonth')}</Typography>
          </Grid>
          <Grid item className={classNames(classes.providerItem)} xs={isMobile() ? 6 : 'auto'}>
            <Typography variant='caption'><Trans {...messages[(activeMonth || allTime) ? 'totalprofitPerPeriod' : 'totalprofitPerPeriodClosed']} /></Typography>
            <Typography variant='body1' className={classes.providerCardItem}>
              <Amount
                value={allTime ? get(summaries, 'totalProfit') : get(summaries, 'profitPerMonth')} currency={account.currency} locale='en-US' classes={{
                  currency: classes.amountCurrency,
                  value: classes.amountValue,
                  fraction: classes.amountFraction
                }} />
            </Typography>
          </Grid>
          <Grid item className={classNames(classes.providerItem, isMobile() && classes.textRight)} xs={isMobile() ? 6 : 'auto'}>
            <Typography variant='caption'><Trans {...messages.profit} /></Typography>
            <Typography variant='body1' className={classes.providerCardItem}>
              <Amount
                value={allTime ? get(summaries, 'providerTotalPerformanceFee') :
                  activeMonth ? get(summaries, 'currentPerformanceFee') : get(summaries, 'providerMonthlyPerformanceFee')} currency={account.currency} locale='en-US' classes={{
                  currency: classes.amountCurrency,
                  value: classes.amountValue,
                  fraction: classes.amountFraction
                }} />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  }
}

export default withStyles(styles, {withTheme: true})(withNamespaces()(ProviderStatsCard))
