import React, {Component} from 'react'
import withStyles from '@mui/styles/withStyles'
import LinearProgress from '@mui/material/LinearProgress'
import {rawSubscriptionPlans} from '@bdswiss/common-enums'
import Typography from '@mui/material/Typography'
import messages from '../../../../assets/messages'
import PageSubTitle from '../../../Common/PageSubTitle'
import {find, flowRight as compose, get} from 'lodash'
import {Trans, withNamespaces} from 'react-i18next'
import {getFormattedAmount, getSubscriptionStatusMsg, getPlanAmount, getPlanCommissions} from '../../../../common/utils/general'
import moment from 'moment'
import classNames from 'classnames'
import {Link, withRouter} from 'react-router-dom'
import NotificationBar from '../../../Common/NotificationBar'

const styles = theme => ({
  volume:{
    paddingTop: 10
  },
  currentPlan:{
    float: 'left'
  },
  nextPlan:{
    float: 'right'
  },
  progressPlans:{
    color: theme.palette.secondary.light,
    fontSize: 12,
    fontWeight: 500
  },
  progressDiv:{
    zIndex: 9,
    position: 'relative',
    padding: '0px 10px',
    marginTop: -19
  },
  bar:{
    height: 18,
    borderRadius: 5
  },
  textBlue:{
    color: theme.palette.primary.main,
  },
  pointer: {
    cursor:'pointer'
  },
  bold: {
    color: theme.palette.grey.color,
  },
  inlineTypography: {
    paddingLeft: '1rem',
    display:'inline-block'
  },
  highlight:{
    fontWeight:400
  },
  warningCTA: {
    color: theme.palette.notificationBar.yellowTextColor,
  },
})

export class PlanBar extends Component {

  render() {
    const {classes, account:{subscription, volumeTraded, isInFallback, isInOverTheLimit}, t, location: {pathname}} = this.props
    const maxVolume = rawSubscriptionPlans[subscription['plan']].maxVolume
    const currentVolume = volumeTraded
    const barValue = currentVolume * 100 / maxVolume
    const currentPlan = rawSubscriptionPlans[subscription['plan']].label
    const nextPlan = find(rawSubscriptionPlans, plan => rawSubscriptionPlans[subscription['plan']].order+1 === get(plan, 'order'))
    const subscriptionPlan = subscription && rawSubscriptionPlans[subscription.plan]
    const pendingPlan = subscription && subscription.pendingPlan &&  rawSubscriptionPlans[subscription.pendingPlan]
    const noteKey = getSubscriptionStatusMsg(subscription, pendingPlan, currentVolume, isInFallback ,'volumeBar')
    const {overTheLimitCommission,fallBackCommission} = getPlanCommissions(subscriptionPlan)

    return <React.Fragment>
      {subscription && subscription.isExpired && <NotificationBar
        status='warning'
        title={        <Trans {...messages.subscriptionExpired}
          values={{fallBackCommission}}
          components={[
            <span className={classes.highlight}>commission</span>,
            <Link to={`${pathname}/plan`} className={classNames(classes.warningCTA, classes.pointer)}>renewalDate</Link>,
          ]}/>}
      />}
      {subscription && !subscription.isExpired && <React.Fragment>
        <PageSubTitle>
          <Trans {...messages.volumeConsumption} />
          <Link to={`${pathname}/plan`}>
            <Typography variant='body2' className={classNames(classes.textBlue,classes.inlineTypography)}>
              <Trans {...messages.checkPlans} />
            </Typography>
          </Link>
          <Typography variant="caption" className={classes.showNote}><Trans
            {...messages[noteKey]}
            values={{renewalDate: String(moment(subscription.nextPaymentDate).format('DD/MM/YYYY HH:mm')),
              pendingPlan: pendingPlan && pendingPlan.label,
              renewalAmount: getPlanAmount(subscriptionPlan,pendingPlan),
              overTheLimitCommission,
              fallBackCommission
            }}
            components={[
              <span className={classes.bold}>renewalDate</span>,
              <Link to={`${pathname}/plan`} className={classNames(classes.textBlue, classes.pointer)}>renewalDate</Link>,
            ]}
          /></Typography>
        </PageSubTitle>
        <LinearProgress variant="determinate" color="primary" value={barValue} classes={{root:  classes.bar}} />
        <div className={classes.progressDiv}>
          <Typography variant="caption" className={classes.progressPlans}>
            <span>{currentPlan}{isInOverTheLimit && ` - ${t(messages.overTheLimitLabel.defaults)}`}</span><span className={classes.nextPlan}>{nextPlan && nextPlan.label}</span>
          </Typography>
        </div>
        <Typography variant="body1" className={classes.volume}>
          {t(messages.volume.i18nKey, messages.volume.defaults)}: {getFormattedAmount({currency: 'USD',amount: currentVolume})} / {getFormattedAmount({currency: 'USD',amount:maxVolume})}
        </Typography></React.Fragment>}
    </React.Fragment>
  }
}

export default compose(
  withNamespaces(),
  withStyles(styles),
  withRouter,
)(PlanBar)
