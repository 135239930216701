import {includes} from 'lodash'
import config from '../../config'

/*
  A script is injectable if:
  1. There is a createPixelId value (from config)
  2. There is a campaign id (from url params)
  3. The campaign id is a subset of the monitored campaign ids (from config)
*/
const isInjectable = (campaignId) =>  {
  const {common: {smartPixel: {createPixelId, monitorCampaignIds}}} = config
  return createPixelId && campaignId && includes(monitorCampaignIds, campaignId)
}

// Extracts the campaign id from the url params
const getCampaignIdFromUrlParams = () => new URLSearchParams(window.location.search).get('campaign')

/*
 Generates smart pixel script and sets it on the DOM (body).
 Wrapped the injected script with try/catch in order to avoid breaking the registration component
 in case the third party script is not working (for any strange reason ..)
*/
export const setSmartPixelScript = () => {
  try {
    const {common: {smartPixel: {createPixelId, scriptId}}} = config
    const campaignId = getCampaignIdFromUrlParams()
    if (!isInjectable(campaignId)) return
    const smartPixelElement = document.createElement('script')
    smartPixelElement.id = `${scriptId}-${campaignId}`
    smartPixelElement.type = 'text/javascript'
    smartPixelElement.async = true
    smartPixelElement.text = `
    try {
      window._txq = window._txq || [];
      var s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = '//st.hybrid.ai/txsp.js';
      (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(s);
      _txq.push(['createPixel', '${createPixelId}']);
      _txq.push(['track', 'PageView']);
    } catch(_e){/* do nothing */}`
    document.getElementsByTagName('body').item(0).appendChild(smartPixelElement)
  } catch (_e) {/* do nothing */}
}

/*
  Executes smart pixel button macro code only if smart pixel script is already injected.
  To identify if smart pixel script is injected, we are searching the DOM to find any script
  that starts with a specific id (script id from config).
*/
export const executeSmartPixelButtonScript = (buttonId) => {
  try {
    const {common: {smartPixel: {scriptId}}} = config
    if (document.querySelectorAll(`script[id^="${scriptId}"]`).length === 0) return
    window._txq = window._txq || []
    window._txq.push(['track', `${buttonId}`])
  } catch (_e) {/* do nothing */}
}

