import moment from 'moment'
import {Link, withRouter} from 'react-router-dom'
import React, {Component} from 'react'
import {withNamespaces, Trans} from 'react-i18next'
import {isEmpty, isNull, first, get, find, includes, filter, orderBy, head, flowRight as compose, some, pickBy,
  map, ceil, isNil, trim} from 'lodash'
import {graphql} from 'react-apollo'
import {tradingStatuses, suspendedTradingStatusReasons, accountTypes, companies, kycStatuses,
  kycRejectionReasons, underMonitoringReasons, appropTestStatuses, scoringResultsV2,
  tradingStatusReasons, notificationTextTypes,
  accountSubtypes} from '@bdswiss/common-enums'
import Grid from '@mui/material/Grid'
import withStyles from '@mui/styles/withStyles'
import AppContext from '../Common/contexts/AppContext'
import NotificationBar from '../Common/NotificationBar'
import {PROFILE_SETTINGS_QUERY, CLIENT_DATA_QUERY} from '../../graphql/queries'
import {isForexAccount, isDemoAccount, isVipOrRaw, getAccountSubtype, isIntroducingBrokerAccount,
  hasCopyTradingAccount,
  isCopyTradingAccount} from '../../common/utils/accounts'
import {disableDepositWithSpecificCountries, getMissingVerifications, hasHeldDeposit,
  safeParseJSON} from '../../common/utils/general'
import messages from '../../assets/messages'
import config from '../../config'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import AppropTestExplanation from '../Common/AppropTestExplanationModal'
import {accountCategoriesDetails, warningNotiication} from '../../common/utils/uioptions'
import {logEventCustomParams} from '../../common/utils/firebaseEvents'
import {isMobile} from '../../common/utils'

const styles = theme => ({
  warningIcon: {
    color: theme.palette.yellow.color,
    verticalAlign: 'sub',
    marginRight: '2px'
  },
  link: {
    color: theme.palette.primary.main,
  },
  highlight:{
    fontWeight:400
  },
  helpIcon: {
    color: theme.palette.grey.color,
    marginBottom: '-2px',
    paddingTop: 5,
    cursor: 'pointer'
  },
  tolltipText:{
    fontSize: 12,
    backgroundColor: theme.palette.lightgrey.color,
    color: theme.palette.secondary.dark,
    fontWeight: 300
  },
  textBold: {
    fontWeight: 400
  },
  warningLink: {
    color: theme.palette.error.main,
    [theme.direction === 'rtl' ? 'marginRight' : 'marginLeft']: 5,
  },
  successCTA: {
    color: theme.palette.notificationBar.greenTextColor,
  },
  infoCTA: {
    color: theme.palette.notificationBar.blueTextColor,
  },
  warningCTA: {
    color: theme.palette.notificationBar.yellowTextColor,
  },
  errorCTA: {
    color: theme.palette.notificationBar.redTextColor,
  },
})

class ClientNotificationBar extends Component {
  static contextType = AppContext

  state = {
    openPopup: false,
  }

  //Appears on account level when an account is readonly and suspended OR account is readonly and has outstanding deposit OR when account is readonly
  checkAccountVerification(account, isMissingApTest, vipOrRawReadOnlyAccount, missingVerifications) {
    const {classes, viewer, profileSettingsLoading, viewerLoading, history,
      viewer: {company, ftdDate, kycStatus}} = this.props
    const isReadOnly = get(account, 'isReadOnly', '')
    const isDemo = get(account, 'isDemo', '')
    const {blockedDeposit} = this.context

    let message
    if (isDemo || profileSettingsLoading || viewerLoading) return

    const kycDays = get(find(companies, {value: company}), 'kycDeadline')
    const kycDaysExtension = get(find(companies, {value: company}), 'kycDeadlineExtension', 0)
    const kycDeadline = moment(ftdDate).add(kycDays+kycDaysExtension, 'day')

    let remainingDays
    if (!blockedDeposit && kycDeadline.isAfter(moment()) && kycStatus !== kycStatuses.approved.key) {
      remainingDays = kycDeadline.diff(moment(), 'days')
    }

    const notificationStatus = vipOrRawReadOnlyAccount ? 'info' : 'warning'

    if (vipOrRawReadOnlyAccount && !disableDepositWithSpecificCountries(viewer)) {
      const selectedAccount =  get(vipOrRawReadOnlyAccount,'accountSubtype') || get(account,'accountSubtype')
      const selectedSubType = getAccountSubtype(selectedAccount)
      const specificAccount = account ?  `/transactions/${account.id}/deposit` :
        vipOrRawReadOnlyAccount.id ? `/transactions/${vipOrRawReadOnlyAccount.id}/deposit` : '/transactions'
      message = <NotificationBar
        status={notificationStatus}
        title={<Trans {...messages.activateVipRawAccount} values={{type:get(selectedSubType, 'label')}}
          components={[<span className={classes.highlight}>subtype</span>]}/>}
        ctaMessage={<Trans {...messages.depositNow} />}
        ctaAction={() => history.push(specificAccount)}
      />
    } else if (isReadOnly && missingVerifications.length > 0) {
      message =
        <NotificationBar
          status={notificationStatus}
          title={<Trans {...messages.accountReadonlyVerificationNeeded}
            components={[
              <Link to={'/accounts/verification'} className={classes.warningCTA}> </Link>
            ]}
          />}
        />
    } else if (remainingDays && missingVerifications.length > 0) {
      message =
        <NotificationBar
          status={notificationStatus}
          title={remainingDays > 0
            ? <Trans {...messages.accountDaysPleaseVerify}
              values={{days: remainingDays}}
              components={[
                <Link to={'/accounts/verification'} className={classes.warningCTA}> </Link>
              ]}
            />
            : <Trans {...messages.accountPleaseVerify}
              components={[
                <Link to={'/accounts/verification'} className={classes.warningCTA}> </Link>
              ]}
            />
          }
        />
    } else if (missingVerifications.length > 0 && kycStatus !== kycStatuses.rejected.key) {
      message =
        <NotificationBar status={notificationStatus}
          title={<Trans {...messages.accountPleaseVerify}
            components={[
              <Link to={'/accounts/verification'} className={classes.warningCTA}> </Link>
            ]}
          />}
        />
    } else if (isReadOnly) {
      message =
        <NotificationBar status={notificationStatus}
          title={<Trans {...messages.isReadonlyAccount} />}
          ctaMessage={<Trans {...messages.contactSupportForMore} />}
          ctaAction={() => history.push('/support')}
        />
    }

    if (!account && isMissingApTest) message = ''

    return message
  }

  copyTradingNotificationBasedOnPath(hasCopyTradingAccount, account) {
    const {pathname, classes} = this.props
    return pathname === 'accountOverview' && isCopyTradingAccount(account) ?
      <Trans
        {...messages.notActivatedZuluAccountTooltip}
        values={{
          zuluAccountType: accountSubtypes[account?.accountSubtype].label,
          activationFtd: (accountSubtypes[account?.accountSubtype].activationFtd).toFixed(2),
        }}
        components={[
          <span className={classes.highlight}>zuluAccountType</span>,
          <span className={classes.highlight}>activationFtd</span>
        ]}
      />
      : <Trans {...messages.zuluAccountWelcome} />
  }

  //1st to appear. On Dashboard and account/overview. Appears when depositedAmount is zero
  checkIfHasDepositedAmount(account, eligibleBonus, hasCopyTradingAccount) {
    const {viewer, history} = this.props
    const showDepositMessage= ((!account || (account && !account.isDemo)) && !disableDepositWithSpecificCountries(viewer)) ? true : false
    const specificAccount = account ? `/transactions/${account.id}/deposit` : '/transactions'
    const showBonusMessage = (!account && get(eligibleBonus, 'eligibleBonus'))
    const showBonusDays = get(eligibleBonus, 'daysLeft')

    return showDepositMessage && <NotificationBar
      status="info"
      title={(showBonusMessage && showBonusDays > 0) ? (showBonusDays <= get(eligibleBonus, 'daysRemainingNotification')) ? <Trans {...messages.welcomeBonusDays} values={{days: showBonusDays}}/>
        : <Trans {...messages.welcomeBonus} /> : hasCopyTradingAccount || isCopyTradingAccount(account) ? this.copyTradingNotificationBasedOnPath(hasCopyTradingAccount, account) : <Trans {...messages.depositPending} />}
      ctaMessage={hasCopyTradingAccount || isCopyTradingAccount(account) ? null : <Trans {...messages.depositNow} />}
      ctaAction={hasCopyTradingAccount || isCopyTradingAccount(account) ? null : () => {
        logEventCustomParams('depositUploadCTAPresented', {
          userId: get(viewer, 'id'),
        })
        history.push(specificAccount)
      }}
    />
  }

  // 2nd to appear. On Dashboard. Appears when the client has a held deposit.
  checkIfHasHeldDeposits() {
    const {classes, viewer: {underMonitoring, underMonitoringReasonCode, locale, externalVerificationUrl, id: userId}} = this.props
    const verifyLink = externalVerificationUrl ? <a href={externalVerificationUrl} className={classes.warningCTA}>
      <Trans {...messages.verifyAccount} />
    </a> : <Link to="/accounts/verification" className={classes.warningCTA}> <Trans {...messages.verifyAccount} /> </Link>

    if (underMonitoring && [underMonitoringReasons.thirdPartyDeposits.key,
      underMonitoringReasons.cardCopiesRequired.key].includes(underMonitoringReasonCode)) {
      logEventCustomParams('UnderMonitoringAlertPresented', {
        userId,
      })
      return <NotificationBar
        status="warning"
        title={<Trans {...messages.underMonitoringNotification}
          components={[
            <Link to={'/transactions/history'} className={classes.warningCTA}> </Link>,
            <Link to={'/support'} className={classes.warningCTA}> </Link>
          ]}
          values={{reason: underMonitoringReasons[underMonitoringReasonCode].localization.t(locale)}}
        />}
      />
    } else {
      logEventCustomParams('DepositHeldAlertPresented', {
        userId: get(this.props, 'viewer.id'),
      })
      return <NotificationBar
        status="warning"
        title={<React.Fragment>
          <Trans {...messages.depositHeldNotification}
            components={[
              <Link to={'/transactions/history'} className={classes.warningCTA}> </Link>
            ]}
          />
          {verifyLink}
        </React.Fragment>}
      />
    }
  }

  checkIfHasRejectedKyc() {
    const {classes, viewer: {kycStatusReasonCode, locale}} = this.props
    return <NotificationBar
      status="warning"
      title={(kycStatusReasonCode === 'other')
        ? <React.Fragment>
          <Trans {...messages.kycStatusTempRejected} />
          {this.renderActionMessage('', 'warning')}
        </React.Fragment>
        : <React.Fragment>
          <Trans {...messages.kycRejectedNotification} values={{reason: kycRejectionReasons[kycStatusReasonCode] ?
            kycRejectionReasons[kycStatusReasonCode].localization.t(locale) : kycRejectionReasons['other'].localization.t(locale)}}
          components={[<span className={classes.highlight}>reason</span>]} />
          {(kycStatusReasonCode === 'resubmissionOfAppTest')
            ? <Trans {...messages.reCompleteAppTest} components={[
              <Link key={'app'} to={'/settings/profile/appropriateness-test'} className={classes.link}>  </Link>]} />
            : this.renderActionMessage('documentsOrSupport', 'warning')}
        </React.Fragment>}
    />
  }

  openPopup() {
    this.setState({openPopup: true})
  }

  closePopup () {
    this.setState({openPopup: false})
  }

  // 3rd to appear. On Dashboard. Appears when tradingStatus is newAccount(Missing AP Test) or appropTest is empty
  checkIfHasAppropTest(latestAptest, canProcessAppropTest, today) {
    const {classes} = this.props
    const {companyObject} = this.context
    const {failedAppropTestTrainingPeriod} = config

    const lastDayAppropTest = latestAptest && moment(get(latestAptest, 'createdAt')).add(failedAppropTestTrainingPeriod, 'days')
    const daysLeft = lastDayAppropTest && ceil(lastDayAppropTest.diff(today, 'days', true))

    if (!isEmpty(latestAptest) && latestAptest.status === appropTestStatuses.rejected.value && !canProcessAppropTest) {
      logEventCustomParams('AppropTestFailedAlertNegativeIntent', {
        userId: get(this.props, 'viewer.id'),
      })
      return <NotificationBar
        status="error"
        title={<Trans {...messages.appropTestFailed}
          values={{company: companyObject.whiteLabelBrand || companyObject.trademark, days: daysLeft}}
        />}
      />
    } else {
      logEventCustomParams('AppropriatenessTestAlertNegativeIntent', {
        userId: get(this.props, 'viewer.id'),
      })
      return <NotificationBar
        status="error"
        component={'span'}
        title={<React.Fragment>
          <Trans {...messages.appropriatenessTestNotification}
            components={[<Link to="/settings/profile/appropriateness-test" className={classes.errorCTA}><Trans {...messages.appropriatenessTestLink} /></Link>]}/>
          <InfoIcon className={classes.helpIcon} color="secondary" onClick={() => this.openPopup()}/>
          <Trans {...messages.helpSupport} components={[<Link to="/support" className={classes.errorCTA}> </Link>]}/>
        </React.Fragment>}
      />
    }
  }

  //4th to appear. On Dashboard. Appears when tradingStatus is suspended and tradingStatusReasonCode is not null
  checkIfSuspended(locale, reason) {
    const {classes} = this.props
    const suspendedReason = suspendedTradingStatusReasons[reason] && suspendedTradingStatusReasons[reason].localization.t(locale)

    const documentsOrSupport = [
      suspendedTradingStatusReasons.missingKycDoc.value,
      suspendedTradingStatusReasons.missingProofAddress.value,
      suspendedTradingStatusReasons.missingProofOfId.value
    ]

    const emailsOrDocuments = [
      suspendedTradingStatusReasons.thirdPartyTransaction.value,
      suspendedTradingStatusReasons.missingProofOfPayment.value
    ]

    const general = [
      suspendedTradingStatusReasons.other.value,
      suspendedTradingStatusReasons.chargebackCase.value,
      suspendedTradingStatusReasons.fraud.value
    ]
    logEventCustomParams('SuspendedTradingStatusWithReasonAlertNegativeIntent', {
      userId: get(this.props, 'viewer.id'),
    })
    return <NotificationBar
      status="error"
      title={<div>
        <Trans {...messages.isSuspendedTradingStatusWithReason} />
        {!includes(general, reason) && <Trans {...messages.reason} values={{reason: suspendedReason}} components={[<span className={classes.highlight}>reason</span>]}/>}
        {includes(documentsOrSupport, reason)
          ? this.renderActionMessage('documentsOrSupport', 'error')
          : (includes(emailsOrDocuments, reason))
            ? this.renderActionMessage('emailsOrDocuments', 'error')
            : this.renderActionMessage('', 'error')
        }
      </div>}
    />
  }

  //5th to appear. On Dashboard. Appears kyc status is AP Pending
  checkIfAppPending() {
    const {classes} = this.props
    logEventCustomParams('KycApTestPendingAlertPresented', {
      userId: get(this.props, 'viewer.id'),
    })
    return <NotificationBar
      status="warning"
      title={<Trans {...messages.kycApTestPending}  components={[
        <Link key={'app'} to={'/settings/profile/appropriateness-test'} className={classes.warningCTA}> </Link>]}
      />}
    />
  }

  //6th to appear. On Dashboard. Appears client is under monitoring
  checkIfUnderMonitoring(locale) {
    const {classes, viewer: {underMonitoringReasonCode}} = this.props
    const underMonitoringReason = underMonitoringReasons[underMonitoringReasonCode] && underMonitoringReasons[underMonitoringReasonCode].localization.t(locale)
    const general = [
      underMonitoringReasons.other.value,
      underMonitoringReasons.compliance.value,
      underMonitoringReasons.numberFailedTransactions.value,
      underMonitoringReasons.suspiciousTradingBehavior.value,
      underMonitoringReasons.sameWithdrawalMethodForDifferentProfiles.value
    ]
    logEventCustomParams('UnderMonitoringAlertPresented', {
      userId: get(this.props, 'viewer.id'),
    })
    return <NotificationBar
      status="warning"
      title={<React.Fragment>
        <Trans {...messages.isUnderMonitoringWithReason} />
        {!includes(general, underMonitoringReasonCode) &&  <Trans {...messages.reason} values={{reason:underMonitoringReason}} components={[<span className={classes.highlight}>reason</span>]}/>}
        {(underMonitoringReasonCode === underMonitoringReasons.pepOrUs.value || includes(general, underMonitoringReasonCode))
          ? this.renderActionMessage('', 'warning')
          : this.renderActionMessage('emailsOrDocuments', 'warning')
        }
      </React.Fragment>}
    />
  }

  //7th to appear. On Dashboard. Appears on ap test mau with 1:100 (middle score)
  leverageUpdateMsg(getAccounts) {
    const {classes, viewer:{appropTests}} = this.props
    const getAccount = !isEmpty(getAccounts) && head(orderBy(getAccounts, ['balance'], ['desc']))
    const appropTestScoreCheck = appropTests &&
      get(appropTests[0], 'scoreResult') === scoringResultsV2.productNotSuitableLeverage100.value
    logEventCustomParams('LeverageUpdateSuggestionAlertPresented', {
      userId: get(this.props, 'viewer.id'),
    })
    return appropTestScoreCheck && <NotificationBar status="warning"
      title={<Trans {...messages.leverageUpdateSuggestion} components={[
        <span className={classes.textBold}>1:100</span>,
        <Link key={'app'} to={`/accounts/${getAccount.id}/settings`} className={classes.warningCTA}> </Link>]}
      />}
    />
  }

  //8th to appear. On Dashboard. Appears on allowCopyTrading
  copyTradingMsg(account) {
    const {history} = this.props
    logEventCustomParams('CopyTradingSuggestionAlertPresented', {
      userId: get(this.props, 'viewer.id'),
    })

    return <NotificationBar
      status="success"
      title={<Trans {...messages.copyTradingSuggestion} values={{ibAccount: account.copyTrading.accountCopying.remoteId}} />}
      ctaMessage={<Trans {...messages.goToStrategies} />}
      ctaAction={() => history.push(`${account.id}/copyTrading`)}
    />
    // return getCopyTradingAccount && <NotificationBar status="success">
    //   <Trans {...messages.copyTradingSuggestion} values={{ibAccount: account.copyTrading.accountCopying.remoteId}} />
    //   <Link to={`${account.id}/copyTrading`} className={classes.successCTA}>  </Link>
    // </NotificationBar>
  }

  //9th to appear. On Dashboard. Appears on affiliate & ib to redeem prize for loyalty program
  partnerLoyaltyRedeem(account) {
    const {classes, viewer, history} = this.props
    const partnersLoyaltyAnalytics = first(get(viewer, 'partnersLoyaltyAnalytics'))
    const eligiblePrizes = safeParseJSON(get(partnersLoyaltyAnalytics, 'eligiblePrizes'))

    return !isEmpty(eligiblePrizes) && <NotificationBar
      status="success"
      title={<Trans {...messages.redeemPrizeNotification} />}
      ctaMessage={<Trans {...messages.redeemPrize} />}
      ctaAction={() => history.push('/loyalty-program')}
    />
  }

  renderActionMessage(type, notificationType) {
    const  {classes} = this.props
    switch (type) {
      case 'documentsOrSupport': return (
        <Trans {...messages.documentsOrSupport} components={[
          <Link key={'doc'} to={'/settings/profile/documents'} className={classes[`${notificationType}CTA`]} onClick={()=> logEventCustomParams('depositUploadCTAPresented')}> </Link>,
          <Link key={'sup'} to={'/support'} className={classes[`${notificationType}CTA`]}>  </Link>]} />
      )
      case 'emailsOrDocuments': return (
        <Trans {...messages.emailsOrDocuments} components={[
          <Link key={'doc'} to={'/settings/profile/documents'} className={classes[`${notificationType}CTA`]} onClick={()=> logEventCustomParams('depositUploadCTAPresented')}> </Link>]} />
      )
      default:
        return <Trans {...messages.emailsOrSupport} components={[<Link key={'sup'} to={'/support'} className={classes[`${notificationType}CTA`]}>  </Link>]} />
    }
  }

  showDormantMessage() {
    const  {classes, viewer: {missingDocuments, missingEP}, t}= this.props

    const missingDocs = safeParseJSON(missingDocuments)
    const dormantFlags = {
      poiUpload: !!missingDocs.poi || missingDocs.rp === true,
      porUpload: !!missingDocs.por,
      missingep: missingEP,
    }
    const flags = pickBy(dormantFlags, (f) => f === true)
    if (!isEmpty(flags)) {
      const actions = map(Object.keys(flags), (f) => t(messages[f]?.i18nKey, messages[f]?.defaults)).join(', ')
      return <NotificationBar status="warning"
        title={<React.Fragment>
          <Trans
            {...messages.dormantMessage}
            components={[
              <Link key={'ep'} to={'/settings/profile/personal-profile/economic'} className={classes.warningCTA}> </Link>,
              <Link key={'sup'} to={'/support'} className={classes.warningCTA}> </Link>
            ]}
            values={{actions}}
          />&nbsp;
          {(dormantFlags.poiUpload || dormantFlags.porUpload) && this.renderActionMessage('documentsOrSupport', 'warning')}
        </React.Fragment>}
      />
    }
  }

  showBitnukMessage() {
    const {hasBitnuk} = this.props
    const {locale} = this.context

    return <NotificationBar
      status="info"
      title={<Trans {...messages.walletSnackbar} values={{wallet: get(hasBitnuk, 'accountType.localization') && hasBitnuk.accountType.localization.t(locale)}}/>}
    />
  }

  showWarningMessage() {
    const {viewer: {tradingStatusReasonCode, tradingStatusReason}, t, history} = this.props
    const {companyObject} = this.context
    const getWarningType = trim(tradingStatusReason.substring(tradingStatusReason.indexOf(':') + 1))
    const {locale} = this.context
    const warningAction = !includes(get(warningNotiication[tradingStatusReasonCode], 'disableBtn'), getWarningType) && warningNotiication[tradingStatusReasonCode]
    const linkTo = !isNil(warningAction) && get(warningAction, 'leadsTo')
    const buttonName = warningAction && get(warningAction, 'buttonName')
    const params= {
      type: getWarningType,
      brand: companies[companyObject.key].brandLabel,
      reason: tradingStatusReasons[tradingStatusReasonCode].label,
      userId: get(this.props, 'viewer.id'),
    }
    logEventCustomParams('warningCTAPresented', params)
    const paramsIntent = {
      source: 'CTA',
      brand:companies[companyObject.key].brandLabel,
      reason: tradingStatusReasons[tradingStatusReasonCode].label,
      type: getWarningType,
      userId: get(this.props, 'viewer.id'),
    }
    return <NotificationBar
      status="error"
      title={warningAction
        ? get(notificationTextTypes, get(tradingStatusReasons[tradingStatusReasonCode], 'notificationText')).localization.t(locale)
        : <Trans {...messages.notificationTextDefault} />
      }
      ctaMessage={warningAction && !isNil(warningAction) && t(get(buttonName, 'i18nKey'), get(buttonName, 'defaults'))}
      ctaAction={() => {
        if (warningAction && !isNil(warningAction)) {
          logEventCustomParams('warningUpdateIntent', paramsIntent)
          history.push(linkTo)
        }
      }}
    />
  }

  render() {
    const {account, accounts, latestAptest, viewer, globalQuestionnaire, viewer: {depositedAmount, kycStatus, canProcessAppropTest,
      underMonitoring, eligibleBonus, missingDocuments, missingEP, partnersLoyaltyAnalytics, manualDeposits}, hasBitnuk} = this.props
    const {locale, tradingStatus, tradingStatusReasonCode, companyObject} = this.context
    const {openPopup} = this.state
    const {productConfigs, accountVerification} = config
    const hasZeroBalanceAccount = (account && account.balance <= 0) || (accounts && isEmpty(filter(accounts, (a) =>
      a.balance > 0 && !isDemoAccount(a) && !isIntroducingBrokerAccount(a))))
    const hasDepositedAmount = !hasZeroBalanceAccount || (!isNull(depositedAmount) && depositedAmount > 0)
    const hasHeldDeposits = !manualDeposits && (accounts ? hasHeldDeposit(accounts) : hasHeldDeposit([account]))
    const isMissingApTest = companies[companyObject.key].requiresAPTest &&
      ((isEmpty(latestAptest) && tradingStatus === tradingStatuses.newAccount.key) || (!isEmpty(latestAptest) && latestAptest.status === appropTestStatuses.rejected.value) ||
      (isEmpty(latestAptest) && hasDepositedAmount))
    const isSuspended = tradingStatus === tradingStatuses.suspended.key && tradingStatusReasonCode
    const hasApTestPending = kycStatus === kycStatuses.pendingAppropTest.value
    const isKycRejected = kycStatus === kycStatuses.rejected.key
    const getAccounts = filter(accounts, (a) => a.leverage === 100 && !isDemoAccount(a))
    const vipOrRawReadOnlyAccount = accounts ? find(accounts, (a) => isVipOrRaw(a) && a.isReadOnly) :
      get(account,'accountSubtype') && isVipOrRaw(account) && get(account,'isReadOnly')
    const dormantFlags = {
      ...safeParseJSON(missingDocuments),
      missingEP,
    }
    const showDormantMessage = some(dormantFlags, (f) => f === true)
    const today = moment().format()
    const showBitnukDetails = accountCategoriesDetails['cryptoWallet'].bitnuk
    const bitnukEndDate = moment(showBitnukDetails.startDate).add(showBitnukDetails.activeDays, 'd')
    const showBitnuk = hasBitnuk && moment(today).isSameOrBefore(moment(bitnukEndDate).format(), 'day')
    const partnersLoyaltyEligibility = get(first(partnersLoyaltyAnalytics), 'partnersLoyaltyEligibility')
    const eligibleToRedeem = moment(get(first(partnersLoyaltyEligibility), 'endEligibilityDate')) > moment()
    const showWarningNotification = tradingStatus === tradingStatuses.warning.value
    let category =  get(accountTypes, `${get(account, '__typename')}.category`)

    if (account && isForexAccount(account)) category = 'forex' //TODO: to be removed - fix category name

    const verificationFields = get(productConfigs, `${category}.accountVerification`, accountVerification)
    const missingVerifications =  getMissingVerifications(viewer, latestAptest, globalQuestionnaire, '', verificationFields)
    const getCopyTradingAccount = get(account,'copyTrading.isActive') && isNull(get(account,'copyTrading.stopRequestedDate'))
    const notifications = [
      [showDormantMessage, this.showDormantMessage],
      [!showDormantMessage && showBitnuk, this.showBitnukMessage],
      [!showDormantMessage && !showBitnuk && !isSuspended && !underMonitoring && isMissingApTest && !isKycRejected && !hasApTestPending, this.checkIfHasAppropTest, [latestAptest, canProcessAppropTest, today]],
      [!showDormantMessage && !showBitnuk && !isSuspended && !underMonitoring && !isMissingApTest && !hasHeldDeposits && ((accounts && !hasDepositedAmount) || hasZeroBalanceAccount), this.checkIfHasDepositedAmount, [account, eligibleBonus, hasCopyTradingAccount(accounts)]],
      [!showDormantMessage && !showBitnuk && !isSuspended && !underMonitoring && !isMissingApTest && hasHeldDeposits, this.checkIfHasHeldDeposits],
      [!showDormantMessage && !showBitnuk && !isSuspended && !underMonitoring && isKycRejected, this.checkIfHasRejectedKyc],
      [!showDormantMessage && !showBitnuk && !isSuspended && !underMonitoring && !isMissingApTest && !hasHeldDeposits && (hasDepositedAmount && !hasZeroBalanceAccount) && (vipOrRawReadOnlyAccount || missingVerifications.length), this.checkAccountVerification, [account, isMissingApTest, vipOrRawReadOnlyAccount, missingVerifications]],
      [!showDormantMessage && !showBitnuk && !isSuspended && !underMonitoring && hasApTestPending, this.checkIfAppPending],
      [!showDormantMessage && !showBitnuk && !isSuspended && underMonitoring, this.checkIfUnderMonitoring, [locale]],
      [!showDormantMessage && !showBitnuk && isSuspended, this.checkIfSuspended, [locale, tradingStatusReasonCode]],
      [!showDormantMessage && !showBitnuk && !isSuspended && !underMonitoring && !isMissingApTest && !hasHeldDeposits && !isKycRejected && !isEmpty(getAccounts) && (hasDepositedAmount && !hasZeroBalanceAccount), this.leverageUpdateMsg, [getAccounts]],
      [!showDormantMessage && !showBitnuk && !isSuspended && !underMonitoring && account && getCopyTradingAccount, this.copyTradingMsg, [account]],
      [!showDormantMessage && !isSuspended && !underMonitoring && !isMissingApTest && !hasHeldDeposits && !isKycRejected && !isEmpty(first(partnersLoyaltyAnalytics)) && eligibleToRedeem, this.partnerLoyaltyRedeem, [account]],
    ]

    if (!showWarningNotification && notifications.every(([condition]) => !condition)) {
      return null
    }

    return (
      <Grid item xs={12}>
        <Grid container spacing={isMobile() ? 2 : 3}>
          {showWarningNotification
            ? <Grid item xs={12}>{this.showWarningMessage()}</Grid>
            : <Grid item xs={12}>
              {notifications.map(([condition, renderFn, args], index) => !!condition && <span key={index}>{renderFn?.apply(this, args)}</span>)}
              <AppropTestExplanation open={openPopup} onClose={() => this.closePopup()} />
            </Grid>
          }
        </Grid>
      </Grid>
    )
  }
}

export default compose(
  withRouter,
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {loading: viewerLoading, error: viewerError}, data}) => {
      const viewer = get(data, 'viewer')
      return {
        viewerLoading,
        viewerError,
        viewer
      }
    }
  }),
  graphql(PROFILE_SETTINGS_QUERY, {
    props: ({data: {loading: profileSettingsLoading, error:  profileSettingsError}, data}) => {
      const latestAptest = first(get(data, 'viewer.appropTests'))
      const globalQuestionnaire = get(data, 'viewer.globalQuestionnaire')
      return {
        profileSettingsLoading,
        profileSettingsError,
        latestAptest,
        globalQuestionnaire
      }
    }
  }),
)(ClientNotificationBar)
