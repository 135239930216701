
const importAll = (r) => {
  const images = {}
    r.keys().map((item) => { images[item.replace('./', '')] = r(item) }) // eslint-disable-line
  return images
}

const Images = importAll(require.context('../../assets/images', false, /\.(png|jpe?g|svg)$/))

export default Images
