import React from 'react'
import {get, map, isFunction, flowRight as compose, floor, isEmpty} from 'lodash'
import PropTypes from 'prop-types'
import {withNamespaces, Trans} from 'react-i18next'
import Grid from '@mui/material/Grid'
import {countries, withdrawalPaymentVendors, directa24TaxNumberLabels, currencies} from '@bdswiss/common-enums'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import {AlertDialog} from '../../Common/Dialog'
import messages from '../../../assets/messages'
import {isCentAccount} from '../../../common/utils/accounts'
import classNames from 'classnames'
import {acknowledgmentLD} from '../../../common/utils/uioptions'
import {Checkbox, FormControl, FormControlLabel} from '@mui/material'
import {withMediaQuery} from '../../Common'

const styles = theme => ({
  subtitleGrid: {
    padding: 8,
  },
  labelField: {
    paddingRight: 4,
  },
  valueField: {
    paddingLeft: 4,
    paddingTop:6
  },
  list: {
    padding: 0,
    textAlign: 'justify',
    '&::before': {
      color: theme.palette.red.color,
    },
  },
  ol: {
    listStyle: 'none',
    '&::before': {
      color: theme.palette.red.color,
    },
  },
  zeroMarginTop:{
    marginTop:0,
    paddingLeft: 15,
    paddingBottom: 0,
    paddingTop: 0,
    lineHeight: 1.8,
    fontSize: 12,
    fontWeight: 400,
  },
  ldTerms: {
    'overflow-y': 'auto',
    height: 300,
    border: `1px solid ${theme.palette.grey[400]}`,
  },
  checkBoxError: {
    color: theme.palette.error.main,
  },
})

const ConfirmWithdrawalModal = (props) => {
  const {open, classes, t, locale, methodFields, form, widthDown, onSubmit, onCancel, accountLabel, formattedAmount,
    buttonLoading, country, method, availableMethod, account, dynamicFields, amount, fee, netAmount, uploadDocuments, ldAcknowledgementDate, ldAccept,
    buttonStatus, handleChangeLd, errors} = props

  const localeParams = {}
  const extraFields = []
  if (method === withdrawalPaymentVendors.directa24.value) {
    localeParams.id_type = get(directa24TaxNumberLabels, country.toLowerCase()) ? directa24TaxNumberLabels[country.toLowerCase()].label : 'ID'
  } else if (method === withdrawalPaymentVendors.payRetailers.value) {
    localeParams.id_type = '#'
  } else if (method === withdrawalPaymentVendors.thunderxpay.key) {
    const rate = Number(get(dynamicFields, 'rates.rate'))
    const rateInUSD = get(dynamicFields, 'rateInUSD')
    const value = isCentAccount(account)
      ? amount / currencies.CUD.baseCurrencyRate * rate
      : amount * rateInUSD * rate
    extraFields.push({
      message: messages.amountInCurrency,
      value: floor(value, 2),
    })
  }
  const showLDTerms = isEmpty(ldAcknowledgementDate) && method === 'ldTransfer'
  const disagreeTextLabel = method === 'ldTransfer' ? t(messages.back.i18nKey, messages.back.defaults) :
    t(messages.edit.i18nKey, messages.edit.defaults)
  return (
    <AlertDialog
      open={open}
      title={t(messages.submitWithdrawalTitle.i18nKey, messages.submitWithdrawalTitle.defaults)}
      agreeText={t(messages.submit.i18nKey, messages.submit.defaults)}
      onAgree={() => onSubmit()}
      disagreeText={disagreeTextLabel}
      onDisagree={() => onCancel()}
      buttonLoading={buttonLoading || false}
      buttonStatus={buttonStatus}
      buttonDisabled={buttonStatus === 'failure'}
    >
      <Grid container direction="row" alignContent="center" justifyContent="center" spacing={2} className={classes.grid}>
        <Grid item xs={12} className={classes.subtitleGrid}>
          <Typography variant="body2">
            <Trans {...messages.correctDetails} />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction={widthDown ? 'column' : 'row'} justifyContent={widthDown ? 'flex-start' : 'flex-end'}>
            <Grid item md={6} xs={12} className={classes.labelField}>
              <Typography variant="body2">
                <Trans {...messages.account} />:
              </Typography>
            </Grid>
            <Grid item md={6} xs={12} className={classes.valueField}>
              <Typography variant="body1">
                {accountLabel}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction={widthDown ? 'column' : 'row'} justifyContent={widthDown ? 'flex-start' : 'flex-end'}>
            <Grid item md={6} xs={12} className={classes.labelField}>
              <Typography variant="body2">
                <Trans {...messages.amount} />:
              </Typography>
            </Grid>
            <Grid item md={6} xs={12} className={classes.valueField}>
              <Typography variant="body1">
                {formattedAmount}
              </Typography>
            </Grid>
            {fee && <React.Fragment>
              <Grid item md={6} xs={12}  className={classes.labelField}>
                <Typography variant="body2">
                  <Trans {...messages.withdrawalFee} />:
                </Typography>
              </Grid>
              <Grid item md={6} xs={12}  className={classes.valueField}>
                <Typography variant="body1">
                  {fee}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} className={classes.labelField}>
                <Typography variant="body2">
                  <Trans {...messages.amountToWithdrawal} />:
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} className={classes.valueField}>
                <Typography variant="body1">
                  {netAmount}
                </Typography>
              </Grid>
            </React.Fragment>}
          </Grid>
        </Grid>
        {extraFields.map((field) => (
          <Grid item xs={12}>
            <Grid container direction={widthDown ? 'column' : 'row'}>
              <Grid
                container item md={6} xs={12}
                justifyContent={widthDown ? 'flex-start' : 'flex-end'}
                className={classes.labelField}
              >
                <Typography variant="body2">
                  <Trans {...field.message} values={{currency: 'THB'}} />:
                </Typography>
              </Grid>
              <Grid container item md={6} xs={12} justifyContent="flex-start" className={classes.valueField}>
                <Typography variant="body1">
                  {field.value}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
        {map((methodFields), (field, i) => {
          const options = isFunction(field.options)
            ? field.options({
              withdrawalPaymentVendor: method,
              country: get(countries[country], 'value'),
              paymentOption: get(availableMethod, 'paymentOption'),
              bankCode: get(form, 'provider'),
            }) : field.options
          const label = field.localization.t(locale, localeParams)
          const fieldValue = get(form, `${field.key}_label`) ||
           (options ? get(options[get(form, field.key, '')], 'label') : get(form, field.key, ''))
          if (!fieldValue) return null
          return <Grid item xs={12} key={i}>
            <Grid container direction={widthDown ? 'column' : 'row'} justifyContent={widthDown ? 'flex-start' : 'flex-end'}>
              <Grid container item md={6} xs={12} className={classes.labelField}>
                <Typography variant="body2">
                  {label}:
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} className={classes.valueField}>
                <Typography variant="body1">
                  {fieldValue}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        })}
        {map(uploadDocuments, (doc, i) => <Grid item xs={12} key={i}>
          <Grid container direction={widthDown ? 'column' : 'row'} justifyContent={widthDown ? 'flex-start' : 'flex-end'}>
            <Grid container item md={6} xs={12} className={classes.labelField}>
              <Typography variant="body2">
                <Trans {...messages[`${Object.keys(doc)[0]}Title`]} />:
              </Typography>
            </Grid>
            <Grid item md={6} xs={12} className={classes.valueField}>
              <Typography variant="body1">
                {get(Object.values(doc)[0], 'name')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>)}
        {showLDTerms && <Grid item xs={12} className={classes.ldTerms}>
          <Typography variant="caption">
            <Trans {...messages.ldClientAcceptanceIntro} />
          </Typography>
          <ol className={classNames(classes.zeroMarginTop)}>
            {map(acknowledgmentLD, (key) =>
              <li key={key} className={classNames(classes.list)}>
                <Typography variant="caption" classes={{root: classes.inline}}>
                  <Trans {...messages[key]} /> </Typography>
              </li>
            )}
          </ol>
        </Grid>}
        {showLDTerms && <Grid item xs={12}>
          <FormControl variant="standard">
            <FormControlLabel
              classes={{label:classes.checkboxLabel}}
              control={
                <Checkbox
                  required
                  checked={!!ldAccept}
                  onChange={(e) => handleChangeLd('ldAccept', e.target.checked? 'accepted' : '')}
                  color="primary"
                  value={ldAccept}
                  className={get(errors, 'ldAccept') ? classes.checkBoxError : ''}
                />
              }
              label={ <Trans {...messages.accept} />}
            />
          </FormControl>
        </Grid>}
      </Grid>
    </AlertDialog>
  )
}

ConfirmWithdrawalModal.propTypes = {
  locale: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  methodFields: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  method: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  formattedAmount: PropTypes.string.isRequired,
  accountLabel: PropTypes.string.isRequired,
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withMediaQuery('md'),
  withNamespaces(),
)(ConfirmWithdrawalModal)
