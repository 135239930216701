import React from 'react'
import {Trans, withNamespaces} from 'react-i18next'
import {graphql, withApollo} from 'react-apollo'
import {currencies, depositStatuses} from '@bdswiss/common-enums'
import {get, find, isEmpty, reject, some, flowRight as compose, filter} from 'lodash'
import Grid from '@mui/material/Grid'
import Hidden from '@mui/material/Hidden'
import withStyles from '@mui/styles/withStyles'
import {Loading} from '../../Common/Loading'
import AmountInput from '../../Common/AmountInput'
import messages from '../../../assets/messages'
import {CREATE_INTERNAL_TRANSFER, LD_TRANSFER_MUTATION} from '../../../graphql/mutations'
import {PAYMENTS_ACCOUNTS_QUERY, CLIENT_DATA_QUERY, LOCAL_DEPOSITOR_DETAILS} from '../../../graphql/queries'
import {isLdWalletAccount, isLdClientWalletAccount} from '../../../common/utils/accounts'
import AppContext from '../../Common/contexts/AppContext'
import LoadingButton from '../../Common/LoadingButton'
import PageSubTitle from '../../Common/PageSubTitle'
import Images from '../../Common/Images'
import Typography from '@mui/material/Typography'
import FormHelperText from '@mui/material/FormHelperText'
import {AlertDialog} from '../../Common/Dialog'
import AccountSearchSelect from '../../Common/AccountSearchSelect'
import AccountSelect from '../../Common/AccountSelect'

const styles = theme => ({
  errors: {
    color: theme.palette.error.main,
    marginLeft: 0,
    paddingLeft: 0,
    listStyle: 'none'
  },
  transferAmount:{
    paddingTop: 30,
    textAlign:'center'
  },
  transferIcon: {
    alignItems:'center',
    display: 'flex',
    justifyContent: 'center',
    transform: `rotate(${theme.direction === 'rtl' ? '180deg' : 0})`,
  },
  root: {
    flexGrow: 1,
  },
  error:{
    color: theme.palette.error.main,
    display: 'inline-block',
    verticalAlign: 'bottom',
    margin: '13px 13px 13px 0'
  },
  clientCheckMsg:{
    textAlign: 'center',
    marginTop: 10
  },
  textBlue:{
    color: theme.palette.primary.main,
  },
  toGrid: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
    }
  },
})

class Transfer extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {
      transferFrom: '',
      transferFromAccount: '',
      transferFromCurrency: 'USD',
      accountsWithdrawal: [],
      transferTo: '',
      transferToAccount: '',
      transferAmount: null,
      accountsDeposit: [],
      errors: [],
      errorsFields:{},
      errorsFieldsAffiliate:{},
      showNotification: false,
      failureMessage: '',
      submitButtonDisabled: false,
      affiliateClientId: '',
      affiliateAccountId: '',
      affiliateEmail: '',
      affiliateError: '',
      affiliateSuccess:{},
      clientCheckMsg:false,
      showConfirmationPopup: false,
    }
  }

  componentDidMount() {
    const {viewer, history} = this.props
    if (!get(viewer, 'isLocalDepositor'))
      history.push('/transactions')
  }

  preSelectLocalDepositorAccount() {
    const {accounts} = this.props
    return find(accounts, (a)=> isLdWalletAccount(a) || isLdClientWalletAccount(a))
  }

  listItemData = a => ({
    id: a.remoteId,
    key: a.remoteId,
    disabled: false,
    ...a
  })

  validAccountsWithdrawal = accounts => accounts
    .filter(a => isLdWalletAccount(a))
    .map(this.listItemData)

  validAccountsDeposit = accounts =>
    accounts
      .filter(a => isLdClientWalletAccount(a))
      .map(this.listItemData)

  handleClientToChange(prop, value) {
    const client = find(this.props.localDepositorSubscriptions, o => o.subscribedMemberId === value)
    if (value !== this.state[prop]) {
      this.setState(state => ({
        transferToAccount: get(client, 'subscribedMemberId') || '',
        transferTo: get(client, 'subscribedMemberId') || '',
        errorsFields: {...state.errorsFields,[prop]: !value}
      }))
    }
  }

  handleChange (name, value) {
    this.setState(state => ({
      [name]: value,
      errorsFields: {
        ...state.errorsFields,
        [name]: !value,
      }
    }))
  }

  isValid(fromAccount, accountsDeposit) {
    const {viewer} = this.props
    const {transferAmount, transferTo} = this.state
    const errors = []
    const errorsFields={}
    const selectedAccountId = get(this.preSelectLocalDepositorAccount(), 'id')
    if (get(viewer, 'isLocalDepositor')) {
      this.setState({transferFromAccount: selectedAccountId, transferFrom: selectedAccountId})
    }

    if (transferTo === '') {
      errorsFields['transferTo'] = true
    }

    if (transferAmount <= 0)
      errorsFields['transferAmount'] = true

    this.setState({errors})

    if (some(errorsFields) || errors.length !== 0) {
      this.setState({errorsFields})
      return
    }
    this.setState({showConfirmationPopup: true})
  }

  handleChangeAccount(prop, value) {
    if (prop === 'transferFromAccount') {
      const accounts = [...this.props.accounts, ...this.state.accountsWithdrawal]
      const transferFromCurrency = find(accounts, ['id', value]).currency
      this.setState(state => ({
        [prop]: value,
        errorsFields: {...state.errorsFields,[prop]: !value},
        transferFromCurrency,
      }))
    } else {
      this.setState(state => ({
        [prop]: value,
        errorsFields: {...state.errorsFields,[prop]: !value}
      }))
    }
  }


  handleProceedLDTransferClick(fromAccount, toClientId) {
    const {transferAmount} = this.state
    const {history} = this.props
    const variables = {
      fromAccountId: fromAccount.id,
      toClientId,
      amount: transferAmount,
    }
    this.setState({submitButtonDisabled: true})
    this.props.ldTransferMutation({variables})
      .then((res) => {
        this.setState({submitButtonDisabled: false})
        if (res?.data?.createLDTransfer?.deposit?.status === depositStatuses.held.key) {
          this.context.showNotification({
            type: 'payment',
            status: 'pending',
            content: <Trans {...messages.ldTransferPending} />,
            buttonMessage: <Trans {...messages.continue} />
          })
        } else {
          this.context.showNotification({
            type: 'payment',
            status: 'success',
            content: <Trans {...messages.ldTransferSuccess} />,
            buttonMessage: <Trans {...messages.continue} />
          })
        }
      })
      .then(() => history.push('/accounts'))
      .catch((err) => {
        this.setState({submitButtonDisabled: false})
        this.context.showNotification({
          type: 'payment',
          status: 'failure',
          content: get( err, 'graphQLErrors[0].message') || err.message,
          buttonMessage: <Trans {...messages.cancel} />
        })
      })
  }

  render() {
    const {loadingLocalDepositor, accountsLoading, classes, t, viewer, localDepositorSubscriptions, accounts} = this.props
    if (loadingLocalDepositor || accountsLoading) {
      return (<Loading />)
    }
    const {transferFromAccount, transferFromCurrency, transferToAccount, transferAmount, errors, errorsFields,
      submitButtonDisabled, affiliateSuccess, clientCheckMsg, showConfirmationPopup} = this.state
    const accountsWithdrawal = this.validAccountsWithdrawal(accounts)
    const accountsDeposit = this.validAccountsDeposit(accounts)
    const forceSelectedAccount = this.preSelectLocalDepositorAccount()
    const forceSelectedAccountCurrency =  get(find(accountsWithdrawal, ['id', forceSelectedAccount]), 'currency') || 'EUR'

    const {themePreference} = this.context
    return (
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container spacing={2} className={classes.toGrid}>
              <Grid item xs={12}>
                <PageSubTitle>
                  <Trans {...messages.ldTransferIn} />
                </PageSubTitle>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Grid container spacing={2} className={classes.toGrid}>
              <Grid item xs={12}>
                <AccountSelect
                  accounts={filter(this.props.accounts, (a)=> isLdWalletAccount(a))}
                  label={t(messages.transferFromAccount.i18nKey, messages.transferFromAccount.defaults)}
                  onChange={this.handleChange}
                  value={get(forceSelectedAccount, 'id') || ''}
                  id="transferFromAccount-selector"
                  name="transferFromAccount"
                  locale={this.context.locale}
                  t={t}
                  disabled={true}
                />
              </Grid>
            </Grid>
          </Grid>

          <Hidden only="xs">
            <Grid item sm={2} className={classes.transferIcon}>
              <img src={Images[`icon-arrow-right-${themePreference}.png`]} alt={t(messages.transferToAccount.i18nKey, messages.transferToAccount.defaults)} />
            </Grid>
          </Hidden>

          <Grid item xs={12} sm={5}>
            <Grid container spacing={2} className={classes.toGrid}>
              <Grid item xs={12}>
                <AccountSearchSelect
                  localDepositorSubscriptions={localDepositorSubscriptions}
                  label={t(messages.transferTo.i18nKey, messages.transferTo.defaults)}
                  handleAccountChange={this.handleClientToChange.bind(this)}
                  value={transferToAccount}
                  name="transferTo"
                  locale={this.context.locale}
                  errors={errorsFields}
                  t={t}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container justifyContent="center">
          <Grid item sm={6} md={4} className={classes.transferAmount}>
            <PageSubTitle>
              <Trans {...messages.selectAmountToTransfer} />
            </PageSubTitle>
            <AmountInput
              jumbo
              onChange={e => this.handleChange('transferAmount', e.target.value)}
              value={transferAmount}
              id="transferAmount"
              name="transferAmount"
              currency={transferFromCurrency || forceSelectedAccountCurrency}
              locale={this.context.locale}
              TextFieldProps={{
                error: errorsFields.transferAmount,
                helperText: errorsFields.transferAmount &&
                t(messages.noZeroTransferAmount.i18nKey, messages.noZeroTransferAmount.defaults)
              }}
            />
            {errors.length > 0 &&
            <ul className={classes.errors}>
              {errors.map(error => <li key={error}>{error}</li>)}
            </ul>}
            <LoadingButton
              disabled={submitButtonDisabled}
              variant="contained"
              color="primary"
              size="large"
              onClick={() => this.isValid(transferFromAccount, accountsDeposit)}
            >
              <Trans {...messages.continue} />
            </LoadingButton>
            {clientCheckMsg && !isEmpty(affiliateSuccess) && <Grid item xs={12}>
              <FormHelperText classes={{root: classes.clientCheckMsg}}>
                <Typography variant="caption" className={classes.textBlue}>
                  <Trans {...messages.clientCheckMsg} />
                </Typography>
              </FormHelperText>
            </Grid>}
          </Grid>
        </Grid>
        <AlertDialog
          open={showConfirmationPopup}
          title={t(messages.confirmation.i18nKey, messages.confirmation.defaults)}
          children={<Typography variant="body1">
            <Trans {...messages.confirmationLocalDepositorTransfer}
              values={{
                transferAmount,
                transferFromCurrency: get(currencies[transferFromCurrency], 'symbol'),
                localDepositorId: get(viewer, 'id'),
                localDepositorClient: transferToAccount,
              }} />
          </Typography>}
          agreeText={t(messages.proceed.i18nKey, messages.proceed.defaults)}
          onAgree={() => this.handleProceedLDTransferClick(forceSelectedAccount, transferToAccount)}
          disagreeText={t(messages.cancel.i18nKey, messages.cancel.defaults)}
          onDisagree={() => this.setState({showConfirmationPopup: false})}
          buttonLoading={submitButtonDisabled}
        />
      </div>
    )
  }
}

export default compose(
  withNamespaces(),
  withApollo,
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading}, data}) => {
      const viewer = get(data, 'viewer')
      return {
        error,
        viewerLoading: loading,
        viewer,
      }
    }
  }),
  graphql(LD_TRANSFER_MUTATION, {
    name: 'ldTransferMutation',
    options: {
      refetchQueries: [{query: PAYMENTS_ACCOUNTS_QUERY}],
    }
  }),
  graphql(PAYMENTS_ACCOUNTS_QUERY, {
    props: ({data: {error, loading: accountsLoading}, data, ownProps: {match:{path}}}) => {
      const accounts = reject(get(data, 'viewer.accounts'), 'isArchived')
      return {
        error,
        accountsLoading,
        accounts,
      }
    }
  }),
  graphql(CREATE_INTERNAL_TRANSFER, {
    name: 'fundInternalTransferMutaion',
  }),
  graphql(LOCAL_DEPOSITOR_DETAILS, {
    props: ({data: {error, loading}, data}) => ({
      errorLocalDepositor: error,
      loadingLocalDepositor: loading,
      localDepositorSubscriptions: get(data, 'viewer.localDepositorSubscriptions'),
      localDepositorSubscriptionsCount: get(data, 'viewer.localDepositorSubscriptionsCount'),
    })
  }),
  withStyles(styles, {withTheme: true}),
)(Transfer)
