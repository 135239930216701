/* eslint-disable no-console */
import {ApolloClient} from 'apollo-client'
import {InMemoryCache, IntrospectionFragmentMatcher, defaultDataIdFromObject} from 'apollo-cache-inmemory'
import {HttpLink} from 'apollo-link-http'
import {onError} from 'apollo-link-error'
import {ApolloLink} from 'apollo-link'
import introspectionQueryResultData from './fragmentTypes.json'
import config from '../config'

export const getApolloClient = () => {
  const {graphqlEndpoint} = config
  const cache =  new InMemoryCache({
    fragmentMatcher: new IntrospectionFragmentMatcher({introspectionQueryResultData}),
    dataIdFromObject: object => {
      switch (object.__typename) {
        case 'Client': return object.__typename
        default: return defaultDataIdFromObject(object)
      }
    }
  })


  return new ApolloClient({
    link: ApolloLink.from([
      onError(({graphQLErrors, networkError}) => {
        if (graphQLErrors) {
          graphQLErrors.map(({message, locations, path}) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
          )
        }
        if (networkError) console.log(`[Network error]: ${networkError}`)
      }),
      new HttpLink({
        uri: graphqlEndpoint,
        credentials: 'include'
      })
    ]),
    cache
  })
}
