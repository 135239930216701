import React, {Component} from 'react'
import withStyles from '@mui/styles/withStyles'
import {Trans, withNamespaces} from 'react-i18next'
import messages from '../../../assets/messages'
import Typography from '@mui/material/Typography'
import classNames from 'classnames'
import {flowRight as compose, get, map} from 'lodash'
import Images from '../../Common/Images'
import Grid from '@mui/material/Grid'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import {safeParseJSON} from '../../../common/utils/general'

const styles = theme => ({
  rulesDiv: {
    padding: 20,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 8,
  },
  rulesList: {
    marginLeft: 11,
    color: theme.palette.black.color,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 20,
      marginLeft: 0,
    }
  },
  infoIcon: {
    marginBottom: -6,
    marginRight: 10,
  },
  blueIcon: {
    color: theme.palette.primary.main,
  },
  leadershipIndex: {
    paddingLeft: 12
  },
  displayInline: {
    display: 'inline-flex',
  },
  textCentered: {
    textAlign: 'center'
  },
  prizeText: {
    paddingTop: 3,
    width: 113,
    margin: 'auto'
  },
})

class CompetitionRules extends Component {

  render() {
    const {classes, competition, rules} = this.props
    const prizes = map(safeParseJSON(get(competition, 'prizes')), (value, key) => ({key, value}))
    return <Grid container className={classes.rulesDiv}>
      <Grid item xs={12}>
        <InfoIcon className={classNames(classes.infoIcon, classes.blueIcon)}/>
        <Typography variant="subtitle1" className={classes.displayInline}> <Trans {...messages.competitionRules} />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ul className={classes.rulesList}>
          <li>
            <Typography variant="body1">
              <Trans {...messages.competitionOptionsDefaultLeverageAccountsOnly} />
            </Typography>
          </li>
          {map(rules, (value, key) => <li key={key}>
            <Typography variant="body1" className={classes.displayInline}>
              {value.label}
            </Typography>
          </li>)}
          {competition.isNewlyCreatedAccountsOnly && <li>
            <Typography variant="body1">
              <Trans {...messages.competitionOptionsNewlyCreatedAccount} />
            </Typography>

          </li>}
          {competition.isDemoAccountsOnly && <li>
            <Typography variant="body1">
              <Trans {...messages.competitionOptionsDemoAccountsOnly} />
            </Typography>
          </li>}
        </ul>
      </Grid>
      <Grid item xs={12}>
        <Grid container className={classes.textCentered}>
          {map(prizes, (value, index) => <Grid item xs={4} key={index}>
            {Images[`place${index + 1}-leadership.png`] ?
              <img src={Images[`place${index + 1}-leadership.png`]} alt={`${index + 1}`}/>
              : <span className={classes.leadershipIndex}>{index + 1}</span>}
            <Typography variant="body1" className={classNames(classes.prizeText)}>
              {value.value}
            </Typography>
          </Grid>)}
        </Grid>
      </Grid>
    </Grid>
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
)(CompetitionRules)
