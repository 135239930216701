import React, {Component} from 'react'
import Grid from '@mui/material/Grid'
import {graphql} from 'react-apollo'
import {Redirect} from 'react-router-dom'
import {Trans, withNamespaces} from 'react-i18next'
import Typography from '@mui/material/Typography'
import {accountTypes, corporateStatuses, depositVendors, fundingCategories, kycStatuses, safechargePaymentTypes} from '@bdswiss/common-enums'
import {countBy, filter, find, first, floor, flowRight as compose, get, groupBy, includes, inRange, isEmpty, keys, max, size, sortBy, toLower, isNil, reject, min, has} from 'lodash'
import {Loading} from '../../Common/Loading'
import {getCurrencyRate, getItem, isMobile, isPAMMForexAccount, logEventCustomParams, safeParseJSON, scrollElementIntoView} from '../../../common/utils'
import {disableDepositWithSpecificCountries} from '../../../common/utils/general'
import messages from '../../../assets/messages'
import {ACCOUNTS_QUERY, CLIENT_DATA_QUERY, CONFIG_QUERY, COUNTRY_PAYMENT_OPTIONS_QUERY, PAYMENTS_ACCOUNTS_QUERY} from '../../../graphql/queries'
import AppContext from '../../Common/contexts/AppContext'
import AccountAmountSelection from './AccountAmountSelection'
import PaymentMethodSelection from './PaymentMethodSelection'
import {PaymentProviderFactory} from './PaymentProviders'
import {blockedDepositAccount, isDemoAccount, isForexAccount, isLdClientWalletAccount} from '../../../common/utils/accounts'
import withStyles from '@mui/styles/withStyles'
import NotificationBar from '../../Common/NotificationBar'
import DateOfBirthForm from '../../Common/DateOfBirthForm'
import ClientNotificationBar from '../../Accounts/ClientNotificationBar'
import config from '../../../config'
import {REFILL_DEMO_BALANCE} from '../../../graphql/mutations'
import ApplePayProvider from './PaymentProviders/ApplePayProvider'
import GooglePayProvider from './PaymentProviders/GooglePayProvider'


const styles = (theme)=> ({
  paymentProviderSection:{
    // padding: isMobile() ? '12px' : '100px 12px 12px 12px !important'
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  threeCCsWarning: {
    color: theme.palette.red.color,
    marginTop: 15,
    textAlign: 'center',
  },
  bold: {
    fontWeight: 500,
  },
  infoCTA: {
    color: theme.palette.notificationBar.blueTextColor,
  },
  warningCTA: {
    color: theme.palette.notificationBar.yellowTextColor,
  },
})

const DefaultPreFilledAmounts = [100, 200, 500, 1000, 2000]
const PaymentMethodProviderInfoGridId = 'payment-provider-grid'

export class Deposit extends Component {

  static contextType = AppContext

  constructor(props) {
    super(props)
    this.state = {
      amount: first(DefaultPreFilledAmounts),
      currentStep: 0,
      showIframe: false,
      bonus: false,
      showMaximumEquityWarning: false,
      depositLoading: false,
      status: '',
      isDoneWithPreselectedCustomAmount: false
    }
    this.paymentInputSectionRef = React.createRef()
  }

  async componentDidMount() {
    logEventCustomParams('depositIntent', {
      source: 'manual',
      userId: get(this.props, 'viewer.id')
    })
    await this.definePreselectedCustomAmount()
  }

  async componentDidUpdate() {
    await this.definePreselectedCustomAmount()
  }

  definePreselectedCustomAmount = async () => {
    /* eslint-disable prefer-const */
    let {selectedAccountId, isDoneWithPreselectedCustomAmount} = this.state
    if (isDoneWithPreselectedCustomAmount) return

    const {history: {location}, match: {params: urlParams}} = this.props

    if (!selectedAccountId) selectedAccountId = Number(get(urlParams, 'accountId'))
    const selectedAccount = this.findSelectedAccount(selectedAccountId)

    if (this.hasPreselectedCustomAmount() && selectedAccount) {
      const {currency: accountCurrency} = selectedAccount
      const {currency: preselectedCurrency, amount: preselectedAmount} = location.state.preselectedCustom

      if (accountCurrency !== preselectedCurrency) {
        const {amount} = await getCurrencyRate(preselectedCurrency, accountCurrency, preselectedAmount)
        this.setState({amount: amount, isDoneWithPreselectedCustomAmount: true})
        return
      }

      this.setState({amount: preselectedAmount, isDoneWithPreselectedCustomAmount: true})
    }
  }

  hasPreselectedCustomAmount() {
    const {history: {location}} = this.props
    return has(location.state, 'preselectedCustom.amount') && has(location.state, 'preselectedCustom.currency')
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const derivedState = {}
    const {match: {params: urlParams}, history} = nextProps
    let selectedAccountId = isEmpty(urlParams) ? get(find(nextProps.accounts,(a) => isForexAccount(a) && !isDemoAccount(a) && !isLdClientWalletAccount(a)), 'id') : Number(get(urlParams, 'accountId'))

    if (nextProps.accounts) {
      const selectedAccountUrl = find(nextProps.accounts, {id: selectedAccountId})
      if (!isEmpty(selectedAccountUrl) && isLdClientWalletAccount(selectedAccountUrl)) {
        if (!find(derivedState.liveAccounts, {id: selectedAccountId})) {
          selectedAccountId = get(first(derivedState.liveAccounts), 'id')
        }
        const defaultFirstTradingAccount = find(nextProps.accounts,(a) => isForexAccount(a) && !isDemoAccount(a))
        derivedState.selectedAccountId = isNil(selectedAccountId) ? get(defaultFirstTradingAccount, 'id') : selectedAccountId
        history.push({pathname:`/transactions/${selectedAccountId}/deposit`, state: history.location.state})
      }
      const accountsWithoutPAMMForex = filter(nextProps.accounts, (a) => !isPAMMForexAccount(a.__typename))
      derivedState.liveAccounts = sortBy(filter(accountsWithoutPAMMForex, (a) => !blockedDepositAccount(a)),  'isDemo', 'desc')
    }

    if (nextProps.countryPaymentOptions) {
      const allPaymentProviders = get(nextProps.countryPaymentOptions, 'data', [])
      const safechargeProvider = find(allPaymentProviders, (o) =>
        (get(o, 'paymentOption.provider') === depositVendors.safecharge.key) && get(o, 'paymentOption.paymentKey') === safechargePaymentTypes.creditCard.value )
      if (safechargeProvider && !isMobile()) {
        derivedState.selectedPaymentMethodId = safechargeProvider.id
      }
    }

    if (!prevState.selectedAccountId && size(derivedState.liveAccounts) > 0) {
      const filteredAccounts = filter(derivedState.liveAccounts , (a)=> !isLdClientWalletAccount(a))
      if (!find(filteredAccounts, {id: selectedAccountId})) {
        selectedAccountId = get(first(filteredAccounts), 'id')
      }
      derivedState.selectedAccountId = selectedAccountId
      history.push({pathname:`/transactions/${selectedAccountId}/deposit`, state: history.location.state})
      return derivedState
    }
    return null
  }

  handleSelectedAccountChanged(newSelectedAccountId, maximumAccountEquity) {
    const {history} = this.props
    const selectedAccount = find(this.props.accounts, {id: newSelectedAccountId})
    const accountType = accountTypes[get(selectedAccount, '__typename')]
    const maxEquity = maximumAccountEquity[accountType.subCategory]
    const equity = get(selectedAccount, 'marginInfo.equity', 0)

    this.setState({
      selectedAccountId: newSelectedAccountId,
      showMaximumEquityWarning: maxEquity && (maxEquity - equity) < Number(this.state.amount),
      isDoneWithPreselectedCustomAmount: false
    })
    history.push({pathname:`/transactions/${newSelectedAccountId}/deposit`, state: history.location.state})
  }

  handleSelectedAmountChanged(newAmount, maximumAccountEquity, ignoreEquityCheck) {
    const selectedAccount = find(this.props.accounts, {id: this.state.selectedAccountId})
    const accountType = accountTypes[get(selectedAccount, '__typename')]
    const maxEquity = maximumAccountEquity[accountType.subCategory]
    const equity = get(selectedAccount, 'marginInfo.equity', 0)

    this.setState({
      amount: newAmount,
      showMaximumEquityWarning: maxEquity && (maxEquity - equity) < Number(newAmount) && !ignoreEquityCheck,
      status: '',
      ...(ignoreEquityCheck ? {currentStep: this.state.currentStep + 1} : {})
    })
  }

  handlePaymentMethodSelected(paymentMethod, provider, fundingCategory) {
    const {accounts, viewer} = this.props
    const {amount, selectedAccountId} = this.state
    const selectedAccount = find(accounts, {id: selectedAccountId})
    const currency =  get(selectedAccount, 'currency')
    this.setState({selectedPaymentMethodId: paymentMethod}, () => scrollElementIntoView(PaymentMethodProviderInfoGridId, 250))
    const params = {
      amount: amount,
      vendor: provider,
      currency: currency,
      login: selectedAccountId,
      method: fundingCategory,
      userId: get(viewer, 'id')
    }
    logEventCustomParams('depositMethodSelected', params)
  }

  handlePaymentError(e) {
    const {showActualError, message} = get(e, 'graphQLErrors[0]', {})

    const notificationParams = {
      type: 'payment',
      status: 'failure',
      buttonMessage: <Trans {...messages.close} />,
      content: showActualError ? message : <Trans {...messages.depositErrorRetry} />
    }
    this.context.showNotification(notificationParams)
  }

  generatedPrefilledAmounts(minimumDeposit) {
    if (this.hasPreselectedCustomAmount()) return []

    const firstTier = inRange(minimumDeposit, 1, 50)
    const secondTier = inRange(minimumDeposit, 50, 1000)
    return [
      minimumDeposit * (firstTier ? 40 : secondTier ? 20 : 5),
      minimumDeposit * (firstTier ? 20 : secondTier ? 10 : 4),
      minimumDeposit * (firstTier ? 10 : secondTier ? 5 : 3),
      minimumDeposit * (firstTier ? 5 : 2),
      minimumDeposit
    ]
  }

  appropTestMessage(accounts) {
    return <ClientNotificationBar accounts={accounts}/>
  }

  onShowIframe = () => {
    this.setState({showIframe: true})
  }

  iframeGrid = () => <Grid item xs={12} id="deposit-methods-container" />

  onNextStep(selectedAccount) {
    const {currentStep} = this.state
    if (selectedAccount.isDemo)
      this.topUpDemoBalance(selectedAccount)
    else
      this.setState({currentStep: currentStep+1, showMaximumEquityWarning: false})
  }

  topUpDemoBalance(demoAccount) {
    const {t, viewer} = this.props
    const {amount} = this.state
    this.setState({status: '', depositLoading: true})
    logEventCustomParams('depositAmountSelected', {amount: amount, userId: get(viewer, 'id')})
    let params
    this.props.topUpDemoBalance({variables: {login: demoAccount.remoteId, amount}})
      .then(() => {
        this.setState({depositLoading: false, status: 'success', submitMessageError: ''})
        params = {
          type: get(demoAccount, 'accountSubtype'),
          amount: get(demoAccount, 'balance'),
        }
        logEventCustomParams('resetDemoFundsCompleted', params)
      })
      .catch((err) => {
        if (err.networkError) {
          this.setState({depositLoading: false, status: 'failure',
            submitMessageError: t(messages.networkError.i18nKey, messages.networkError.defaults),
          })
          logEventCustomParams('resetDemoFundsError', {reason: 'networkError'})
        } else {
          this.setState({depositLoading: false, status: 'failure',
            submitMessageError: get(err, 'graphQLErrors[0].message') || err.message,
          })
          params = {
            reason: get( err, 'graphQLErrors[0].message') || err.message,
          }
          logEventCustomParams('resetDemoFundsError', params)
        }

      })
  }

  findSelectedAccount(selectedAccountId) {
    const {accounts} = this.props
    return find(accounts, {id: selectedAccountId}) || find(accounts,(a) => isForexAccount(a) && !isDemoAccount(a) && !isLdClientWalletAccount(a))
  }

  render() {
    const {loading, viewerLoading, accountsLoading, configLoading, accounts, viewer, countryPaymentOptions,
      classes, configVariables, history} = this.props

    if (loading || viewerLoading || accountsLoading || configLoading) return <Loading />
    const {currentStep, amount, selectedAccountId, selectedPaymentMethodId, liveAccounts, showIframe, bonus, showMaximumEquityWarning, status,
      depositLoading, submitMessageError} = this.state
    const {blockedDeposit} = this.context
    const {countriesBonusTerms} = config
    const {ftdDate} = viewer
    const minimumDepositDefault = get(configVariables,'minimumDepositDefault')
    const maximumAccountEquity = safeParseJSON(get(configVariables, 'maximumAccountEquity'))
    const locale = get(viewer, 'locale') || getItem('locale', 'en')
    const selectedAccount = this.findSelectedAccount(selectedAccountId)

    const accountMinimumDeposit = get(selectedAccount, 'minimumDeposit')
    const accountMinimumPartialDeposit = get(selectedAccount, 'minimumPartialDeposit')
    const accountMinimumBonusDeposit = get(selectedAccount, 'minimumBonusDeposit')

    const accountMaximumAllowedDeposit = get(selectedAccount, 'maximumDepositAllowed')

    const prefilledAmount = bonus ? accountMinimumBonusDeposit : accountMinimumDeposit
    const preFilledAmounts = this.generatedPrefilledAmounts(prefilledAmount)

    // Use the below to whitelist test users for new PSPs in production
    const allowedDemoClients = [] // ex. 'elias.georgiou+16111arg@bdswiss.com'
    const restrictedVendors = [] // ex. depositVendors.beeteller.value
    const allPaymentProviders = reject(
      get(countryPaymentOptions, 'data', []),
      (opt) =>
        config.paypal.env === 'production'
        && restrictedVendors.includes(get(opt, 'paymentOption.provider'))
        && !allowedDemoClients.includes(get(viewer, 'email'))
    )

    const avaliablePaymentProviders = filter(allPaymentProviders, (p) => PaymentProviderFactory.hasImplementation(get(p, 'paymentOption.provider'), get(p, 'paymentOption.paymentKey')))
    const selectedPaymentOption = find(get(countryPaymentOptions, 'data'), {id: selectedPaymentMethodId})
    const PaymentProvider = PaymentProviderFactory.get(get(selectedPaymentOption, 'paymentOption.provider'),get(selectedPaymentOption, 'paymentOption.paymentKey'))

    const allowedDeposit = !get(viewer,'verificationActions.blockDeposit')
    const missingDob = viewer && isEmpty(viewer.birthday)

    const totalCCsUsed = countBy(viewer.paymentMethods, (p) => p.fundingCategory === fundingCategories.card.key &&
      (p.details || '-') !== '-'
    ).true
    const provider = get(selectedPaymentOption, 'paymentOption.provider')
    const paymentKey = get(selectedPaymentOption, 'paymentOption.paymentKey')
    const kycRequired = get(depositVendors[provider], 'kycRequired', false)
    const paymentOption = find(get(depositVendors[provider], 'options') || {}, {value: paymentKey}) ||
    find(get(depositVendors[provider], 'options') || {}, {key: paymentKey})
    const fundingCategory = get(depositVendors[provider], 'fundingCategory') || get(paymentOption, 'fundingCategory')
    const warningPaytrailers = ['payretailers', 'pix', 'boleto']

    /*AccountTypes without Deposit or with specific conditions*/
    if (blockedDeposit || disableDepositWithSpecificCountries(get(this.props, 'viewer'))) {
      return (<Redirect to="/transactions/withdraw" />)
    }

    if (showIframe) {
      return (
        <Grid container spacing={0}>
          {this.iframeGrid()}
        </Grid>
      )
    }

    const accountEligibleBonus = get(selectedAccount, 'eligibleBonus')
    const viewerEligibleBonus = get(viewer, 'eligibleBonus')
    const eligibleBonus = accountEligibleBonus && get(accountEligibleBonus, 'accountTypeBonus') === get(selectedAccount, 'accountSubtype')
      ? accountEligibleBonus : (get(viewerEligibleBonus, 'daysLeft') > 0 ? viewerEligibleBonus : {})
    const bonusAmount = amount && amount * get(eligibleBonus, 'percentage')
    const finalBonusAmount = bonusAmount && floor((bonusAmount < get(accountEligibleBonus, 'maxAmount') ? bonusAmount : get(accountEligibleBonus, 'maxAmount')), 2)
    const sendBonus = get(eligibleBonus, 'eligibleBonus') && get(selectedAccount, 'eligibleBonus.eligibleBonus') && bonus
    const clientCountry = get(viewer, 'address.oldCountry') || get(viewer, 'address.country')
    const bonusCountry = find(countriesBonusTerms, (bonus) => includes(bonus.countries, clientCountry))
    const bonusTerms =  sendBonus && (get(eligibleBonus, 'terms') || get((bonusCountry || config.weblinks), 'bonusTerms').replace('{lang}', locale))

    const accountType = accountTypes[get(selectedAccount, '__typename')]
    const maxEquity = maximumAccountEquity[get(accountType, 'subCategory')]
    const equity = get(selectedAccount, 'marginInfo.equity', 0)
    const showSelectAmountEquityWarning = maxEquity && (max(preFilledAmounts) + equity) > Number(maxEquity) &&
      !selectedAccount.leverageChangedBasedOnEquity
    const pendingCorporate = get(viewer, 'corporateStatus') === corporateStatuses.pending.value

    const minDeposits = get(selectedPaymentOption, 'paymentOption.minDepositAmounts')
    const paymentOptionMaxDepositAmounts = get(selectedPaymentOption, 'paymentOption.maxDepositAmounts')
    const selectedAcountMinDeposit = find(minDeposits, {currency: get(selectedAccount, 'currency')})
    const paymentOptionMaxDeposit = find(paymentOptionMaxDepositAmounts, {currency: get(selectedAccount, 'currency')})
    const accountMaximumDepositAllowed = get(selectedAccount, 'maximumDepositAllowed')

    const paymentOptionMinDeposit = selectedAcountMinDeposit
      ? selectedAcountMinDeposit.amount
      : get(selectedAccount, 'minimumPartialDeposit') > 0
        ? get(selectedAccount, 'minimumPartialDeposit')
        : get(selectedAccount, 'minimumDeposit')

    const maxDepositAllowed = paymentOptionMaxDeposit && accountMaximumDepositAllowed
      ? min([Number(paymentOptionMaxDeposit.amount), Number(accountMaximumDepositAllowed.amount)])
      : paymentOptionMaxDeposit && !accountMaximumDepositAllowed
        ? paymentOptionMaxDeposit.amount
        : !paymentOptionMaxDeposit && accountMaximumDepositAllowed
          ? accountMaximumDepositAllowed.amount
          : Number.MAX_VALUE.toString()

    const applepayPaymentProvider = find(allPaymentProviders, {paymentOption: {provider: depositVendors.safecharge.value, paymentKey: safechargePaymentTypes.applepay.value}})
    const googlepayPaymentProvider = find(allPaymentProviders, {paymentOption: {provider: depositVendors.safecharge.value, paymentKey: safechargePaymentTypes.googlepay.value}})

    return (
      pendingCorporate ? <Grid container>
        <Grid item xs={12}>
          <NotificationBar status="info" title={<Trans {...messages.corporateAccountUnderReview} />} />
        </Grid>
      </Grid> : <Grid container spacing={0}>
        {isEmpty(liveAccounts) && <NotificationBar
          status="info"
          title={<Trans {...messages.noAccounts} />}
          ctaMessage={<Trans {...messages.addNewAccount} />}
          ctaAction={() => history.push('/accounts/add-account')}
        />}
        {status==='success' &&
        <Grid item xs={12} sm={12}>
          <NotificationBar
            noMargin
            status='success'
            title={<Trans {...messages.demoBalanceUpdated} />}
            ctaMessage={<Trans {...messages.goToDashboard} />}
            ctaAction={() => history.push('/')}
          />
        </Grid>}
        {this.iframeGrid()}
        { !allowedDeposit && this.appropTestMessage(accounts)}
        {
          currentStep === 0 && !isEmpty(liveAccounts) && allowedDeposit &&
          <Grid item xs={12}>
            <AccountAmountSelection
              accounts={filter(liveAccounts , (a)=> !isLdClientWalletAccount(a))}
              selectedAccountId={selectedAccountId}
              amount={amount}
              onSelectedAccountChanged={(accountId) => this.handleSelectedAccountChanged(accountId, maximumAccountEquity)}
              onAmountChanged={(newAmount, ignoreEquityCheck) =>
                this.handleSelectedAmountChanged(newAmount, maximumAccountEquity, ignoreEquityCheck)
              }
              showMaximumEquityWarning={showMaximumEquityWarning && !selectedAccount.leverageChangedBasedOnEquity}
              showSelectAmountEquityWarning={showSelectAmountEquityWarning}
              onNextStep={() => this.onNextStep(selectedAccount)}
              preFilledAmounts={preFilledAmounts}
              minimumDeposit={accountMinimumDeposit}
              maximumDeposit={accountMaximumAllowedDeposit}
              locale={locale}
              ftdDate={ftdDate}
              minimumDepositDefault={Number(get(safeParseJSON(minimumDepositDefault),'USD', 0))}
              maximumAccountEquity={maximumAccountEquity}
              eligibleBonus={get(selectedAccount, 'eligibleBonus')}
              bonusAmount={finalBonusAmount}
              bonus={bonus}
              changeSwitch={() => this.setState({bonus: !bonus})}
              country={clientCountry || ''}
              bonusTerms={bonusTerms}
              depositLoading={depositLoading}
              depositStatus={status}
              submitMessageError={submitMessageError}
              viewer={viewer}
              minimumPartialDeposit={accountMinimumPartialDeposit}
              minimumBonusDeposit={accountMinimumBonusDeposit}
            />
          </Grid>
        }
        {
          currentStep === 1 &&
          <Grid item container xs={12} direction="row" spacing={3}>
            <Grid item xs={12} sm={8} lg={6}>
              <PaymentMethodSelection
                account={selectedAccount}
                locale={locale}
                amount={amount}
                countryPaymentOptions={avaliablePaymentProviders}
                selectedPaymentMethodId={selectedPaymentMethodId}
                onPaymentMethodSelected={(paymentMethod) => this.handlePaymentMethodSelected(paymentMethod, provider, fundingCategory) }
                goBack={() => this.setState({currentStep: currentStep-1})}
                bonusAmount={sendBonus && finalBonusAmount}
              >
                {googlepayPaymentProvider && !isMobile() && <GooglePayProvider
                  style={{marginBottom: 4}}
                  viewer={viewer}
                  account={selectedAccount}
                  amount={Number(amount)}
                  providerProperties={get(googlepayPaymentProvider, 'paymentOption')}
                  onError={(e) => this.handlePaymentError(e)}
                  showIframe={() => this.onShowIframe()}
                  history={history}
                  bonusAmount={sendBonus}
                  bonusTerms={bonusTerms}
                  canProceed={!(Number(paymentOptionMinDeposit) <= Number(amount) && Number(maxDepositAllowed) >= Number(amount))}/>
                }

                {applepayPaymentProvider && <ApplePayProvider
                  style={{marginBottom: 4}}
                  viewer={viewer}
                  account={selectedAccount}
                  amount={Number(amount)}
                  providerProperties={get(applepayPaymentProvider, 'paymentOption')}
                  onError={(e) => this.handlePaymentError(e)}
                  showIframe={() => this.onShowIframe()}
                  history={history}
                  bonusAmount={sendBonus}
                  bonusTerms={bonusTerms}
                  canProceed={!(Number(paymentOptionMinDeposit) <= Number(amount) && Number(maxDepositAllowed) >= Number(amount))}/>
                }
              </PaymentMethodSelection>
            </Grid>
            { missingDob &&
            <Grid id={PaymentMethodProviderInfoGridId} item  xs={12} sm={8} lg={6} className={classes.paymentProviderSection}>
              <DateOfBirthForm />
            </Grid>
            }
            { !missingDob && PaymentProvider &&
              <Grid id={PaymentMethodProviderInfoGridId} item  xs={12} sm={8} lg={6} className={classes.paymentProviderSection}>
                {(includes(warningPaytrailers, toLower(get(selectedPaymentOption, 'paymentOption.paymentKey')))
                  || includes(warningPaytrailers, toLower(get(selectedPaymentOption, 'paymentOption.provider')))) &&
                  <NotificationBar
                    status="warning"
                    title={<Trans {...messages.depositWarningPayretailers} components={[<span className={classes.bold} key={'bold'}>bold</span>]}/>}
                  />
                }
                {Number(paymentOptionMinDeposit) > Number(amount) &&
                  <NotificationBar status="warning" title={<Trans {...messages.depositWarmingMinimumDeposit} />} />
                }
                {Number(maxDepositAllowed) < Number(amount) &&
                  <NotificationBar status="warning" title={<Trans {...messages.depositWarningMaximumDeposit} />} />
                }

                {kycRequired && viewer.kycStatus !== kycStatuses.approved.value
                  ? <NotificationBar
                    status="warning"
                    title={<Trans {...messages.paymentMethodKycRequired} />}
                    ctaMessage={<Trans {...messages.verifyAccount} />}
                    ctaAction={() => history.push('/settings/profile/documents')}
                  />
                  : <PaymentProvider
                    key={selectedPaymentMethodId}
                    viewer={viewer}
                    account={selectedAccount}
                    amount={Number(amount)}
                    providerProperties={get(selectedPaymentOption, 'paymentOption')}
                    onError={(e) => this.handlePaymentError(e)}
                    showIframe={() => this.onShowIframe()}
                    history={history}
                    bonusAmount={sendBonus}
                    bonusTerms={bonusTerms}
                    canProceed={!(Number(paymentOptionMinDeposit) <= Number(amount) && Number(maxDepositAllowed) >= Number(amount))}
                  />}
                {fundingCategory === fundingCategories.card.value && totalCCsUsed >= 2 &&
                  <Typography className={classes.threeCCsWarning} variant="caption">
                    <Trans {...messages.threeCCsWarning} />
                  </Typography>
                }
              </Grid>
            }
          </Grid>
        }
      </Grid>
    )
  }
}

export default compose(
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
  graphql(COUNTRY_PAYMENT_OPTIONS_QUERY, {
    options: {fetchPolicy: 'no-cache'},
    props: ({data: {error, loading}, data}) => {
      const allCountryPaymentOptions = groupBy(get(data,'countryPaymentOptions'), 'country')
      const viewerCountryKey = find(keys(allCountryPaymentOptions), (k) => k !== '_default')
      const countryPaymentOptions = allCountryPaymentOptions[viewerCountryKey] || allCountryPaymentOptions._default
      return {
        countryPaymentOptions: {
          data: countryPaymentOptions,
          loading,
          error
        }
      }
    },
  }),
  graphql(PAYMENTS_ACCOUNTS_QUERY, {
    props: ({data: {error, loading: accountsLoading}, data}) => ({
      error,
      accountsLoading,
      accounts: get(data, 'viewer.accounts'),
    })
  }),
  graphql(CONFIG_QUERY, {
    props: ({data: {error, loading: configLoading}, data}) => ({
      error,
      configLoading,
      configVariables: get(data, 'config'),
    })
  }),
  graphql(CLIENT_DATA_QUERY, {
    options: () => ({fetchPolicy: 'network-only'}),
    props: ({data: {error, loading: viewerLoading}, data}) => {
      const viewer = get(data, 'viewer')
      return {
        error,
        viewerLoading,
        viewer,
      }
    }
  }),
  graphql(REFILL_DEMO_BALANCE, {
    name: 'topUpDemoBalance',
    options: {
      refetchQueries: [{query: ACCOUNTS_QUERY}],
    }
  })
)(Deposit)
