import React from 'react'
import {Redirect, Route, Switch, withRouter} from 'react-router-dom'
import Documents from '../Documents'
import AppropriatenessTest from './AppropriatenessTest'
import DueDiligence from './DueDiligence'
import SpoaAuthorization from '../SpoaAuthorization'
import Profile from './index'
import PortfolioManagement from './PortfolioManagement'
import LocalDepositor from './LocalDepositor'
import PersonalDetails from './PersonalProfile/PersonalDetails'
import EconomicProfile from './PersonalProfile/EconomicProfile'

const ProfileRoutes = ({match: {path}}) => (
  <Switch>
    <Route path={`${path}/personal-profile`} component={PersonalDetails} />
    <Route path={`${path}/economic-profile`} component={EconomicProfile} />
    <Route path={`${path}/documents`} component={Documents} />
    <Route exact path={`${path}/appropriateness-test`} component={AppropriatenessTest} />
    <Route exact path={`${path}/due-diligence`} component={DueDiligence} />
    <Route exact path={`${path}/portfolio-management`} component={PortfolioManagement} />
    <Route exact path={`${path}/spoa-authorization`} component={SpoaAuthorization} />
    <Route exact path={`${path}/local-depositor`} component={LocalDepositor} />
    <Route path={`${path}/:parentPath/:childPath+`} render={({match}) => <Redirect to={`${path}/${match.params.parentPath}`} />} />
    <Route path={`${path}`} component={Profile} />
  </Switch>
)

export default withRouter(ProfileRoutes)
