import React, {Component} from 'react'
import withStyles from '@mui/styles/withStyles'
import {flowRight as compose, omit} from 'lodash'
import Tooltip from '@mui/material/Tooltip'
import {ClickAwayListener} from '@mui/material'
import {isMobile} from '../../common/utils'

const styles = theme => ({
  tooltipLight: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  toolTip:{
    '& p':{
      color: theme.palette.common.white
    }
  },
  arrow: {
    color: theme.palette.common.black,
  },
  arrowLight: {
    color: theme.palette.common.white,
  },
})

class CustomTooltip extends Component {

  constructor(props) {
    super(props)

    this.state = {
      openPopper: false
    }
  }

  render() {
    const {classes, title, placement, children, hideArrow, tooltipLight, arrowLight} = this.props
    const {openPopper} = this.state
    const popperProps = {}
    const mobile = isMobile()
    if (mobile) {
      popperProps.open = openPopper
      popperProps.onClick = () => this.setState({openPopper: !openPopper})
      popperProps.onClickAway = () => this.setState({openPopper: false})
    }
    return <ClickAwayListener onClickAway={() => popperProps.onClickAway && popperProps.onClickAway()}>
      <span onClick={() => popperProps.onClick && popperProps.onClick()}>
        <Tooltip
          title={title}
          classes={{
            tooltip: tooltipLight ? classes.tooltipLight : classes.toolTip,
            arrow: arrowLight ? classes.arrowLight : classes.arrow,
          }}
          placement={placement}
          arrow={!hideArrow}
          disableFocusListener={mobile}
          disableHoverListener={mobile}
          disableTouchListener={mobile}
          {...omit(popperProps, ['onClickAway'])}
        >
          {children}
        </Tooltip>
      </span>
    </ClickAwayListener>
  }
}

export default compose(withStyles(styles))(CustomTooltip)
