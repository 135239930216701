import React, {Component}  from 'react'

import {styled} from '@mui/system'
import {IconButton} from '@mui/material'

import AppContext from './contexts/AppContext'
import {getCurrentTheme, isDarkTheme} from '../../common/utils'
import {ReactComponent as CloseIconLight} from '../../assets/images/close-icon-light.svg'
import {ReactComponent as CloseIconDark} from '../../assets/images/close-icon-dark.svg'

const ButtonWrap = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  '& button': {
    color: '#999',
  },
}))

class CloseButton extends Component {
  static contextType = AppContext

  render() {
    const themePreference = getCurrentTheme(this.context)
    const {onClose, ...rest} = this.props

    return (
      <ButtonWrap>
        <IconButton
          edge={'start'}
          size="small"
          onClick={onClose}
          {...rest}
        >
          {isDarkTheme(themePreference) ? <CloseIconLight/> : <CloseIconDark/>}
        </IconButton>
      </ButtonWrap>
    )
  }
}

export default CloseButton


