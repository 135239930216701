import React, {Component} from 'react'
import {withNamespaces, Trans} from 'react-i18next'
import {get, find, first, flowRight as compose} from 'lodash'
import {graphql} from 'react-apollo'
import {Link, withRouter} from 'react-router-dom'
import moment from 'moment'
import withStyles from '@mui/styles/withStyles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import {PROFILE_SETTINGS_QUERY, CLIENT_DATA_QUERY} from '../../../graphql/queries'
import messages from '../../../assets/messages'
import PageTitle from '../../Common/PageTitle'
import PageBody from '../../Common/PageBody'
import AppContext from '../../Common/contexts/AppContext'
import AccountVerificationStatus from './Status'
import {Loading} from '../../Common/Loading'
import config from '../../../config'
import {companies, kycStatuses} from '@bdswiss/common-enums'
import {getVerificationRow, getCorporateVerificationRow} from '../../Common/AccountVerification/VerificationHelper'
import AccountVerificationItem  from '../../Common/AccountVerification/Item'
import CustomNotificationAlert from '../../Common/CustomNotificationAlert'
import {isCorporateClient} from '../../../common/utils/accounts'
import {isMobile} from '../../../common/utils'

const styles = theme => ({
  verificationList: {
    width: '100%',
    tableLayout: 'fixed',
    '& td': {
      padding: '0 12px'
    },
  },
  container: {
    marginBottom: -15
  }
})

class AccountVerification extends Component {
  static contextType = AppContext

  verifyAll() {
    const {clientData, globalQuestionnaire, appropTests, clientData: {clientType, kycStatus, company, ftdDate}, history} = this.props
    const {productConfigs, accountVerification} = config
    let verificationFields
    if (this.context.blockedDeposit && clientType && productConfigs[clientType]) {
      verificationFields = productConfigs[clientType].accountVerification
    } else {
      verificationFields = accountVerification
    }

    const verifications = isCorporateClient(clientType) ? getCorporateVerificationRow(clientData, history.location.pathname)
      : getVerificationRow(clientData, appropTests,globalQuestionnaire,verificationFields)

    let remainingDays
    const kycDays = get(find(companies, {value: company}), 'kycDeadline')
    const kycDeadline = moment(ftdDate).add(kycDays, 'day')
    if (!this.context.blockedDeposit && kycDeadline.isAfter(moment()) && kycStatus !== kycStatuses.approved.key) {
      remainingDays = kycDeadline.diff(moment(), 'days')
    }

    return {
      verifications,
      status: (kycStatus !== kycStatuses.approved.key) && verifications.find(({status}) => status !== 'done'),
      remainingDays,
    }
  }

  render() {
    const {themePreference} = this.context
    const {t, clientLoading, profileLoading, classes} = this.props
    if (clientLoading || profileLoading) return <Loading />

    const {verifications, status, remainingDays} = this.verifyAll()

    return (
      <React.Fragment>
        <PageTitle
          themePreference={themePreference}
          title={t(messages.accountVerification.i18nKey, messages.accountVerification.defaults)}
          withoutPadding={isMobile()}
        />
        <PageBody removePadding>
          <Grid container>
            <Grid item xs={12} className={classes.container}>
              <CustomNotificationAlert/>
            </Grid>
            <Grid item xs={12} sm={8} md={6}>

              {status && <AccountVerificationStatus link={status.link}>
                <Trans {...messages.accountPending} />
              </AccountVerificationStatus>}

              <Table id='verificationList' className={classes.verificationLink}>
                <TableBody>
                  {verifications.map((item) => <AccountVerificationItem {...item} />)}
                </TableBody>
              </Table>

            </Grid>
          </Grid>
          <Grid item xs={12} sm={8} lg={6}>
            {remainingDays && <Typography variant="body1" >
              <Trans {...messages.daysToVerifyAccount}
                values={{days: remainingDays}}
                components={[<span className={classes.pendingAccountText}>days</span>,
                  <Link to={'/'} className={classes.submitLink}>trading now</Link>,
                ]} />
            </Typography>}
          </Grid>
        </PageBody>
      </React.Fragment>
    )
  }
}

export default compose(
  withRouter,
  withNamespaces(),
  withStyles( styles, {withTheme: true}),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {loading, error}, data, ownProps: {history}}) => ({
      clientLoading: loading,
      error,
      history,
      clientData: get(data, 'viewer'),
    }),
    options: {
      fetchPolicy: 'network-only',
    },
  }),
  graphql(PROFILE_SETTINGS_QUERY, {
    props: ({data: {loading, error}, data}) => {
      const appropTests = first(get(data, 'viewer.appropTests'))
      const globalQuestionnaire = get(data, 'viewer.globalQuestionnaire')
      return {
        profileLoading: loading,
        error,
        appropTests,
        globalQuestionnaire
      }
    }
  }),
)(AccountVerification)
