import React, {Component} from 'react'
import AppContext from './contexts/AppContext'
import Jdenticon from 'react-jdenticon'
import {withNamespaces} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import {isEmpty} from 'lodash'

const styles = theme => ({
  strategyIcon: {
    borderRadius: '50%',
    verticalAlign: 'middle',
    overflow: 'hidden',
    'object-fit': 'cover',
    '&:hover':{
      backgroundColor: 'transparent'
    },
  },
})
class JdenticonImage extends Component {
  static contextType = AppContext

  jdenticon (accountName) {
    const {classes, size} = this.props
    const customSize = !isEmpty(size) ? String(size) : '100'
    return (<div className={classes.strategyIcon}>
      <Jdenticon size={customSize} value={accountName}/>
    </div>)
  }

  render() {
    const {accountName} = this.props
    return (
      this.jdenticon(accountName)
    )
  }
}

export default withStyles(styles, {withTheme: true})(withNamespaces()(JdenticonImage))
