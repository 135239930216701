import React from 'react'
import PropTypes from 'prop-types'
import {graphql, withApollo} from 'react-apollo'
import {withRouter} from 'react-router-dom'
import {withNamespaces, Trans} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import Typography from '@mui/material/Typography'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Grid from '@mui/material/Grid'
import {Loading} from '../../Common/Loading'
import {CLIENT_DATA_QUERY, FOREX_ACCOUNT_QUERY} from '../../../graphql/queries'
import {get, isEmpty, flowRight as compose, flatMap, map} from 'lodash'
import messages from '../../../assets/messages'
import ClientNotificationBar from '../ClientNotificationBar'
import AppContext from '../../Common/contexts/AppContext'
import ForexTradingInfo from './ForexTradingInfo'
import PlanBar from './Plan/Bar'
import {logEventCustomParams} from '../../../common/utils/firebaseEvents'
import {isCopyTradingAccount, isDemoAccount} from '../../../common/utils/accounts'
import Paper from '@mui/material/Paper'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {copyTradingStepsActivation} from '../../../common/utils/uioptions'
import {isMobile} from '../../../common/utils'
import {List, ListItem, ListItemText, SvgIcon} from '@mui/material'

const styles = theme => ({
  wrapper: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  root: {
    marginTop: 30,
  },
  positionsTypeHeader: {
    margin: 0,
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  positionsTypeHeaderIcon: {
    top: 4,
    position: 'relative',
  },
  flowItem: {
    padding: 24,
    width: 256,
    height: 248,
    borderRadius: '8px',
    border: `1px solid ${theme.palette.lightgrey.color}`,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      textAlign: 'center',
    },
  },
  arrowFlow: {
    width: 24,
    height: 24,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'center',
    },
  },
  listItemText: {
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 400,
    'line-height': '16px',
    'letter-spacing': '0.4px'
  },
  customIcon: {
    '& svg path': {
      color: '#ff6600',
      fill: theme.palette.mode === 'dark' ? 'white' : 'black',
    },
  },
  stepTitle: {
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.5px'
  }
})


class ForexAccountOverview extends React.Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    this.state = {positionsType: 'open'}
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.account !== prevState.account) {
      if (get(nextProps, 'account.isArchived') || get(nextProps, 'account.isViewOnly')) {
        return ({positionsType: 'closed'})
      }
    }
    return null
  }

  selectPositionsType(positionsType) {
    const params = {
      accountType: isDemoAccount(get(this.props, 'account')) ? 'demo' : 'real',
      userId: get(this.context, 'clientId'),
    }
    switch (positionsType) {
      case 'pending':
        logEventCustomParams('viewPendingTrades', params)
        break
      case 'closed':
        logEventCustomParams('viewClosedTrades', params)
        break
      case 'open':
        logEventCustomParams('expandOpenOrders', params)
        break
      default:
        break
    }
    this.setState({
      positionsType,
      positionsMenuEl: null
    })
  }

  render() {
    const {loading, account, classes, viewer} = this.props
    const {positionsMenuEl, positionsType} = this.state
    const hasSubscription = !isEmpty(account.subscription)
    if (loading) return <Loading />
    logEventCustomParams('accountOverview', {
      userId: get(this.context, 'clientId'),
    })

    return (<Grid container spacing={3}>
      <ClientNotificationBar account={account} pathname={'accountOverview'}/>
      {hasSubscription && <Grid item xs={12}><PlanBar account={account} /></Grid>}

      {isCopyTradingAccount(account) && <Grid item xs={12}>
        <Grid container spacing={isMobile() ? 0 : 2} alignItems="center" className={classes.wrapper}>
          {flatMap(copyTradingStepsActivation, ({icon: Icon, message, points, title}, index) => [
            <Grid key={index * 2} item xs={isMobile() ? 12 : 'auto'}>
              <Paper className={classes.flowItem}>
                <Grid
                  container
                  direction={{xs: 'row', sm: 'column'}}
                  alignItems={{xs: 'start', sm: 'start'}}
                  justifyContent={{xs: 'start', sm: 'flex-start'}}
                  spacing={isMobile() ? 2 : 0}
                >
                  <Grid item>
                    <SvgIcon className={classes.customIcon}><Icon/></SvgIcon>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" className={classes.stepTitle}>
                      <Trans {...title} />
                    </Typography>
                  </Grid>
                </Grid>
                <List disablePadding dense={true}>
                  {map(points, (a, index) => <ListItem style={{padding: 0}} key={`list${index}`}>
                    <ListItemText
                      primary={<Typography variant="caption" className={classes.listItemText}>
                        <Trans {...a} />
                      </Typography>}
                    />
                  </ListItem>)}
                </List>
              </Paper>
            </Grid>,
            index < copyTradingStepsActivation.length - 1 && ( // Add ArrowForwardIcon if not the last item
              <Grid key={index * 2 + 1} item xs={isMobile() ? 12 : 'auto'} className={classes.arrowFlow}>
                {!isMobile() ? <ArrowForwardIcon style={{fontSize: '18px'}} /> : <KeyboardArrowDownIcon />}
              </Grid>
            ),
          ])}
        </Grid>
      </Grid>
      }
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component='h3' variant="h3" gutterBottom
              className={classes.positionsTypeHeader}
              aria-owns={positionsMenuEl ? 'simple-menu' : undefined}
              aria-haspopup="true"
              onClick={e => this.setState({positionsMenuEl: e.currentTarget})}>
              <Trans {...messages[`tradesHeader_${positionsType}`]} />
              <ArrowDropDown className={classes.positionsTypeHeaderIcon} />
            </Typography>
            <Menu
              id="positions-types"
              anchorEl={positionsMenuEl}
              open={Boolean(positionsMenuEl)}
              onClose={() => this.selectPositionsType(positionsType)}
            >
              <MenuItem onClick={() => this.selectPositionsType('open')}><Trans {...messages.openTrades} /></MenuItem>
              <MenuItem onClick={() => this.selectPositionsType('closed')}><Trans {...messages.closedTrades} /></MenuItem>
              <MenuItem onClick={() => this.selectPositionsType('pending')}><Trans {...messages.pendingTrades} /></MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ForexTradingInfo viewer={viewer} account={account} status={positionsType} enablePagination={positionsType === 'closed'} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>)
  }
}

ForexAccountOverview.propTypes = {
  account: PropTypes.object,
}

export default compose(
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
  withRouter,
  withApollo,
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {loading}, data}) => ({
      viewerLoading: loading,
      viewer: get(data, 'viewer'),
    })
  }),
  graphql(FOREX_ACCOUNT_QUERY, {
    options: (props) => ({variables: {id: props.match.params.accountId}, fetchPolicy: 'network-only'}),
    props: ({data: {error, loading}, data}) => {
      const account = get(data, 'viewer.accounts.0') || {}
      return {
        error,
        loading,
        account,
      }
    }
  }),
)(ForexAccountOverview)
