import React from 'react'
import {flowRight as compose, get, map} from 'lodash'
import AppContext from './contexts/AppContext'
import {Box, Typography} from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import {Trans, withNamespaces} from 'react-i18next'
import messages from '../../assets/messages'
import {withRouter} from 'react-router-dom/cjs/react-router-dom.min'
import {CLIENT_DATA_QUERY, MARKETANALYSIS_QUERY} from '../../graphql/queries'
import {graphql, withApollo} from 'react-apollo'
import Carousel from 'react-multi-carousel'
import Images from './Images'
import config from '../../config'
import {isMobile, logEventCustomParams} from '../../common/utils'
import CustomCarouselArrow from '../Dashboard/CustomCarouselArrow'
import LoadingWrapper from '../Dashboard/LoadingWrapper'


const styles = (theme, props) => ({
  title:{
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '30px',
    letterSpacing: '0.15px',
    textAlign:'left'
  },
  postDate:{
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: theme.palette.secondary.main,
  },
  postTitle:{
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.5px',
  },
  postDescription:{
    fontWeight: 400,
    fontSize: '16px',
    letterSpacing: '0.5px',
    lineHeight: '24px',
    color: theme.palette.mode === 'dark' ? '#C2C2C2' : '#5C5C5C',
  },
  seeAll:{
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing:'0.5px',
    color: theme.palette.mode === 'dark' ? '#419CFF' : '#007AFF',
    textAlign: isMobile() ? 'center' : 'right',
    cursor: 'pointer',
  },
  box:{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingBottom: '24px'
  },
  carousel:{
    position: 'unset',
    paddingBottom: isMobile() ? '24px': '0px',
  },
  slider:{
    gap: '24px',
  },
  itemClass: {
    maxWidth: (props) => props.maxItemWidth.lgUp,
    [theme.breakpoints.down('lg')]: {
      maxWidth: (props) => props.maxItemWidth.lgDown,
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: (props) => props.maxItemWidth.mdDown,
    },
    maxHeight: '300px',
  },
  postContainer: {
    display: 'flex',
    cursor: 'pointer',
    maxHeight: '300px',
    gap: theme.spacing(2),
    flexDirection: 'column',
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1),
    border: theme.palette.mode === 'dark' ? '1px solid #1F1F1F' : '1px solid #EBEBEB',
    '&:hover':{
      backgroundColor: theme.palette.mode === 'dark' ? '#181818' : '#FAFAFA',
    },
  },
  postDateContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
})

class MarketAnalysis extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      data: null,
      additionalTransfrom: 0,
    }
  }

  getResponsiveObject() {
    return ({
      'xl':{
        breakpoint: {max: 10000, min: 901},
        items: 3,
        slidesToSlide: 3,
      },
      'lg': {
        breakpoint: {max: 900, min: 601},
        items: 2,
        partialVisibilityGutter: 100,
        slidesToSlide: 2,
      },
      'sm': {
        breakpoint: {max: 600, min: 376},
        items: 1,
        partialVisibilityGutter: 50,
        slidesToSlide: 1,
      },
      'xs': {
        breakpoint: {max: 375, min: 0},
        items: 1,
        partialVisibilityGutter: 70,
        slidesToSlide: 1,
      }
    })
  }
  formatDate(dateString) {
    const dateOnlyString = dateString.split(' at ')[0]
    const date = new Date(dateOnlyString)
    const day = date.getDate()
    const month = date.toLocaleString('default', {month: 'long'})
    const year = date.getFullYear()
    return `${day} ${month}, ${year}`
  }

  getItemTransform(nextSlide, carouselState) {
    const {theme} = this.props
    const {deviceType, currentSlide} = carouselState

    const isRtlTheme = theme.direction === 'rtl'

    switch (deviceType) {
      case 'xl': {
        const additionalTransfrom = isRtlTheme
          ? this.state.additionalTransfrom + (nextSlide - currentSlide) * 8
          : this.state.additionalTransfrom + (nextSlide - currentSlide) * -8
        this.setState({additionalTransfrom: additionalTransfrom})
        break
      }
      case 'lg': {
        const additionalTransfrom = isRtlTheme
          ? this.state.additionalTransfrom + (nextSlide - currentSlide) * 8
          : this.state.additionalTransfrom + (nextSlide - currentSlide) * -8
        this.setState({additionalTransfrom: additionalTransfrom})
        break
      }
      case 'sm': {
        const additionalTransfrom = isRtlTheme
          ? this.state.additionalTransfrom + (nextSlide - currentSlide) * 10
          : this.state.additionalTransfrom + (nextSlide - currentSlide) * -10
        this.setState({additionalTransfrom: additionalTransfrom})
        break
      }
      case 'xs': {
        const additionalTransfrom = isRtlTheme
          ? this.state.additionalTransfrom + (nextSlide - currentSlide) * 24
          : this.state.additionalTransfrom + (nextSlide - currentSlide) * -24
        this.setState({additionalTransfrom: additionalTransfrom})
        break
      }
      default: {
        this.setState({additionalTransfrom: 0})
      }
    }
  }

  render() {
    const {clientId} = this.context
    const {classes, posts, viewer, loading, loadingClient, loadingMarketAnalysis, theme}= this.props
    const loadingComponent= loading || loadingClient || loadingMarketAnalysis
    const responsive = this.getResponsiveObject()

    return (
      <React.Fragment>
        <Box className={classes.box}>
          <LoadingWrapper loading={loadingComponent}>
            <Typography  className={classes.title}> <Trans {...messages.marketAnalysis}/> </Typography>
          </LoadingWrapper>
          {!isMobile() && <LoadingWrapper loading={loadingComponent}>
            <Typography className={classes.seeAll} onClick={() => {
              logEventCustomParams('seeAllArticlesClicked', {
                userId:  clientId,
              })
              window.open(config.weblinks.websiteUrl + '/research-analysis/market-preview/')}
            }> <Trans {...messages.seeAll} /> </Typography>
          </LoadingWrapper>}
        </Box>
        <Carousel
          responsive={responsive}
          showDots={false}
          partialVisible={true}
          autoPlay={false}
          arrows={isMobile() ? false : true}
          className={classes.carousel}
          sliderClass={classes.slider}
          infinite={false}
          itemClass={classes.itemClass}
          rtl={theme.direction === 'rtl'}
          additionalTransfrom={this.state.additionalTransfrom}
          customLeftArrow={ <CustomCarouselArrow location={'marketAnalysis'} direction='left'/>}
          customRightArrow={<CustomCarouselArrow location={'marketAnalysis'} direction='right'/>}
          beforeChange={(nextSlide, carouselState) =>{
            this.getItemTransform(nextSlide, carouselState)
          }}
        >
          {map(posts, (a, index) =>
            <Box className={classes.postContainer}
              key={index}
              onClick={() => {
                window.open(a.url)
                logEventCustomParams('analysisArticleClicked', {
                  userId: get(viewer, 'id'),
                  articleId: a.id
                })
              }}
            >
              <Box className={classes.postDateContainer}>
                <LoadingWrapper loading={loadingComponent} variant={'circular'}>
                  <img alt='post icon' src= {Images['market_analysis_post.svg']} />
                </LoadingWrapper>
                <LoadingWrapper loading={loadingComponent}>
                  <Typography variant='body2' className={classes.postDate}> {this.formatDate(a.date)}</Typography>
                </LoadingWrapper>
              </Box>
              <LoadingWrapper loading={loadingComponent}>
                <Typography variant='body1' className={classes.postTitle}> {a.title} </Typography>
              </LoadingWrapper>
              <div style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                <LoadingWrapper loading={loadingComponent}>
                  <Typography variant='body1' className={classes.postDescription}>
                    <span dangerouslySetInnerHTML={{__html: a.desc.replace(/_/g, '')}} />
                  </Typography>
                </LoadingWrapper>
              </div>
            </Box>
          )}
        </Carousel>
        {isMobile() && <LoadingWrapper loading={loadingComponent}>
          <Typography className={classes.seeAll} onClick={() => {
            logEventCustomParams('seeAllArticlesClicked', {
              userId:  clientId,
            })
            window.open(config.weblinks.websiteUrl + '/research-analysis/market-preview/')}
          }> <Trans {...messages.seeAll} /> </Typography>
        </LoadingWrapper>}
      </React.Fragment>
    )
  }
}

export default compose(
  withRouter,
  withNamespaces(),
  withApollo,
  withStyles(styles, {withTheme: true}),
  graphql(MARKETANALYSIS_QUERY,{
    options: () => ({
      fetchPolicy: 'network-only',
    }),
    props: ({data: {loading, error}, data}) =>
      ({
        loadingMarketAnalysis: loading,
        errorMarketAnalysis: error,
        posts: get(data, 'marketAnalysisPost')
      }),
  }),
  graphql(CLIENT_DATA_QUERY, {
    options: () => ({fetchPolicy: 'network-only'}),
    props: ({data: {error, loading}, data}) => ({
      errorClient: error,
      loadingClient: loading,
      viewer: get(data, 'viewer'),
    }),
  })
)(MarketAnalysis)
