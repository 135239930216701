import i18n from 'i18next'
import {reactI18nextModule} from 'react-i18next'
import backend from 'i18next-xhr-backend'
import {includes, map, filter} from 'lodash'
import {getCurrentTheme} from './common/utils/general'
import AppContext from './components/Common/contexts/AppContext'
import {isRtlLang} from './common/utils/uioptions'
import {languages} from '@bdswiss/common-enums'

const transformLocale = (locale) => {
  switch (locale) {
    case 'ph': return 'fil'
    default: return locale
  }
}

const checkDisabledLocale = (locale) => !includes(map(filter(languages, (lang) => !lang.disabled && lang.client), 'value'), locale)

class I18n {
  static contextType = AppContext
  init(locale) {
    i18n
      .use(backend)
      .use(reactI18nextModule)
      .init({
        lng: transformLocale(locale),
        fallbackLng: 'en',
        keySeparator: false,
        interpolation: {
          escapeValue: false
        },
        backend: {
          loadPath: `/locales/${checkDisabledLocale(locale) ? 'en' : '{{lng}}'}.json`
        },
      })
    this.i18n = i18n

    if (includes(isRtlLang, locale)) {
      document.body.setAttribute('dir', 'rtl')
    } else {
      document.body.setAttribute('dir', 'ltr')
    }

    return this.i18n
  }

  changeLanguage(locale, context, callback) {
    this.i18n.changeLanguage(transformLocale(locale), callback)
    const currentDirection = document.body.getAttribute('dir')
    if (includes(isRtlLang, locale)) {
      document.body.setAttribute('dir', 'rtl')
    } else {
      document.body.setAttribute('dir', 'ltr')
    }
    if (currentDirection!==document.body.getAttribute('dir')) {
      context.toggleTheme(getCurrentTheme(), locale)
    }
  }
}

const i18nApp = new I18n()
export default i18nApp
