import React, {Component} from 'react'
import url from 'url'
import {get, find, flowRight as compose} from 'lodash'
import {withRouter} from 'react-router'
import {graphql} from 'react-apollo'
import Dialog from '@mui/material/Dialog'
import withStyles from '@mui/styles/withStyles'
import config from '../../config'
import {getItem} from '../../common/utils'
import {CLIENT_DATA_QUERY, ACCOUNTS_QUERY} from '../../graphql/queries'
import {TRADESMARTER_AUTHKEY_MUTATION} from '../../graphql/mutations'
import {getCookie} from '../../common/utils/browser'

const styles = theme => ({
  hidden: {
    display: 'none'
  },
  wrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    height: '100%',
    margin: '0 auto',
    backgroundColor: '#1e1e1e',
  },
  iframe: {
    overflow: 'scroll',
    height: '100%',
    width: '100%',
    minWidth: 820,
    [theme.breakpoints.down('lg')]: {
      position: 'absolute',
      height: '100%',
      minWidth: 0
    },
  },
  paper: {
    flexDirection: 'column'
  },
  toolBar:{
    minHeight:20,
    paddingLeft:5
  },
  root: {
    zIndex: 2147483640
  }
})

class Tradesmarter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      authKey: null,
      acquireAuthKey:this.acquireAuthKey.bind(this),
      checkIframeLoaded: this.checkIframeLoaded.bind(this)
    }
  }

  componentDidMount() {
    if (this.props.account) {
      this.acquireAuthKey(this.props)
      this.checkIframeLoaded(this.props)
    }
  }


  UNSAFE_componentWillReceiveProps(props) {
    if (props.account && get(props, 'account.id') !== get (this.props, 'account.id')) {
      this.acquireAuthKey(props)
      this.checkIframeLoaded(props)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.messageListener)
    this.clearCookie('userID')
  }

  clearCookie(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.bdswiss.com`
  }

  authenticated() {
    this.setState({authenticated: true})
    this.checkSessionState()
  }

  checkIframeLoaded(props) {
    const {match} = props || this.props
    if (getCookie('userID') === get(match, 'params.login')) {
      this.authenticated()
      return
    }
    setTimeout(() => {
      this.checkIframeLoaded(props)
    }, 100)
  }

  checkSessionState() {
    if (isNaN(getCookie('userID'))) {
      this.acquireAuthKey()
      this.checkIframeLoaded()
    } else {
      setTimeout(() => {
        this.checkSessionState()
      }, 300)
    }
  }

  acquireAuthKey(props) {
    const {account, getTradesmarterAuthKey} = props || this.props

    this.setState({authenticated: false})

    const variables = {accountId: account.id}
    getTradesmarterAuthKey({variables})
      .then((res) => {
        this.setState({
          authKey: get(res, 'data.authDetails.key'),
        })
      })
      .catch((e) => {
        // TODO: handle error
        // this.context.addNotification({
        //   message: authKeyFailure,
        //   level: notificationType.error,
        // })
      })
  }

  onClose() {
    const {account, history} = this.props
    history.push(`/accounts/${account.id}`)
  }

  render() {
    const {classes, account, viewer} = this.props
    const {authKey} = this.state
    const authenticated = this.state ? this.state.authenticated : false

    const locale = get(viewer, 'locale') || getItem('locale', 'en')
    if (authKey == null || account == null || account.id == null) {
      return null
    }

    if (!authenticated) {
      const tradesmarterUrl = url.format({
        host: config.tradeSmarterLoginUrl,
        query: {
          session: authKey,
          keepSession: 1,
          redirectUrl: config.tradeSmarterTraderUrl,
        },
      })

      return (
        <div className={classes.hidden}>
          <iframe
            src={tradesmarterUrl}
            frameBorder="0"
            id="authenticator"
            title="authenticator"
            className={classes.iframe}
          />
        </div>
      )
    } else {
      const iframeUrl = `/iframe/newbinaryoptions?locale=${locale}`
      return (
        <Dialog
          open
          fullScreen
          onClose={() => this.onClose()}
          keepMounted
          classes={{paper: classes.paper, root: classes.root}}
          disableEnforceFocus={true}
        >
          <div className={classes.wrapper}>
            <iframe
              src={iframeUrl}
              frameBorder="0"
              name="tradesmarterTrader"
              id="tradesmarterTrader"
              title="tradesmarterTrader"
              className={classes.iframe}
            />
          </div>
        </Dialog>
      )
    }
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  graphql(ACCOUNTS_QUERY, {
    props: ({data: {error, loading}, data, ownProps: {match}}) => {
      const accounts = get(data, 'viewer.accounts')
      const account = find(accounts, {remoteId: match.params.login})
      return {
        error,
        loading,
        account,
      }
    }
  }),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading}, data}) => ({
      error,
      loading,
      viewer: get(data, 'viewer'),
    })
  }),
  graphql(TRADESMARTER_AUTHKEY_MUTATION, {
    name: 'getTradesmarterAuthKey',
  }),
  withRouter
)(Tradesmarter)
