import React, {Component} from 'react'
import {Trans, withNamespaces} from 'react-i18next'
import {flowRight as compose, get, find, includes, isEmpty, trim, findKey, some, pickBy, isEqual, isNil} from 'lodash'
import AppContext from '../Common/contexts/AppContext'
import withStyles from '@mui/styles/withStyles'
import {Link, withRouter} from 'react-router-dom'
import {corporateFields, corporateSteps, corporateStatuses} from '@bdswiss/common-enums'
import Grid from '@mui/material/Grid'
import {validateCharacters, validateDOB, validateEmail} from '../../common/utils/validations'
import {scrollElementIntoView} from '../../common/utils'
import messages from '../../assets/messages'
import {graphql} from 'react-apollo'
import {ADD_CORPORATE_DETAILS} from '../../graphql/mutations'
import {CLIENT_DATA_QUERY} from '../../graphql/queries'
import ButtonsToolbar from './ButtonsToobar'
import CorporateStepper from './CorporateStepper'
import Questions, {getStepQuestions} from './Questions'
import NotificationBar from '../Common/NotificationBar'

const gridScroll = 'scroll-grid'

const styles = theme => ({
  notificationBox: {
    marginTop: 20,
    position: 'relative'
  },
  infoCTA: {
    color: theme.palette.notificationBar.blueTextColor,
  },
})

class FinancialInformationStep extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    const activeStep = corporateSteps.financialInformation.rank
    this.state = {
      showDate: {},
      form: {},
      errors : {},
      questions: [],
      loading: false,
      status: '',
      formSubmitted: false,
      initialForm: {},
      formChanged: {},
      activeStep,
      nextStep: find(corporateSteps, (step) => step.rank === activeStep + 1),
      prevStep: find(corporateSteps, (step) => step.rank === activeStep - 1)
    }
  }

  componentDidMount() {
    const {viewer} = this.props
    const {activeStep} = this.state
    getStepQuestions(viewer, activeStep, (state) => this.setState(state))
  }

  handleChange (name, valueInc, question) {
    const {form, initialForm} = this.state
    let value = valueInc
    if (question && question.multipleCheckbox) {
      value = form[name]
      if (value.indexOf(valueInc) >= 0) value.splice(value.indexOf(valueInc), 1)
      else value.push(valueInc)
    }

    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        [name]: value
      },
      errors: {
        ...state.errors,
        [name]: !value,
      },
      formChanged:{
        ...state.formChanged,
        [name]: isEmpty(initialForm) || !isEqual(value, initialForm[name]),
      },
      status: ''
    }))
  }

  scrollUp(errors) {
    this.setState(() => scrollElementIntoView(isEmpty(errors) ? gridScroll : findKey(errors), 250))
  }

  handleSubmit() {
    const {form, activeStep, hidden, formSubmitted, nextStep} = this.state
    const {history} = this.props
    const errors = {}
    const submitted = formSubmitted
    for (const field of Object.keys(form)) {
      if (corporateFields[field].required(form, undefined,activeStep, hidden)) {
        if (field === 'email') {
          errors[field] = isEmpty(form[field]) || !validateEmail(form[field])
        } else if (field === 'firstName' || field === 'lastName') {
          const nameError = validateCharacters(trim(form[field]))
          errors[field] = !!nameError
        } else if (field === 'birthday') {
          errors[field] = !validateDOB(form[field])
        } else if (field === 'profitsAndLosses') {
          errors[field] = !(new RegExp('^[+-][0-9.,]+$').test(form[field]))
        }
        else {
          errors[field] = isEmpty(form[field])
        }
      }
    }

    if (some(errors)) {
      this.setState({errors},this.scrollUp(errors))
      return
    }

    this.setState({loading: true, status: ''})
    this.props.addCorporateDetails({variables: {step: activeStep, answers: JSON.stringify(pickBy(form))}}).then((res) => {
      this.setState({loading: false, status: '', formSubmitted: true},
        history.push({pathname: `/corporate/${nextStep.key}`, state: {step: !submitted ? activeStep + 1 : ''}}))
    }).catch((err) => {
      this.setState({loading: false, status: 'failure'})
    })
  }

  setOuterState (name, key ,value) {
    !isNil(value) ? this.setState(state => ({
      [name]: {
        ...state[name],
        [key]: value
      }
    })) : this.setState({[name]: key})
  }

  render() {
    const {classes, t, viewer, history} = this.props
    const {showDate, selectedCountry, errors, form, questions, disabled, loading, status, formChanged, formSubmitted, activeStep,
      nextStep, prevStep}= this.state
    const {locale} = this.context
    const isFormChanged = includes(formChanged, true)
    const corporateStatus = get(viewer, 'corporateStatus')

    return <React.Fragment>
      {!corporateStatus && <CorporateStepper activeStep={activeStep - 1} viewerStep={get(viewer, 'corporateSignupStep')}/>}
      {corporateStatus && <NotificationBar
        status="info"
        classes={{notificationBox: classes.notificationBox}}
        title={<Trans {...messages.corporateCannotChangeDetails}
          components={[<Link className={classes.infoCTA} to={'/support'}>support</Link>]}
          values={{corporateStatus: corporateStatus && corporateStatuses[corporateStatus].localization.t(locale)}}
        />}
      />}
      <Grid container spacing={3} id={gridScroll}>
        <Questions
          setOuterState={(name, key ,value) => this.setOuterState(name, key ,value)}
          showDate={showDate}
          selectedCountry={selectedCountry}
          questions={questions}
          form={form}
          errors={errors}
          handleChange={(name, value, question) => this.handleChange(name, value, question)}
          step={activeStep}
          hidden={this.state.hidden}
          disabled={get(viewer, 'corporateStatus')}
        />
        {!corporateStatus && <Grid item xs={12}>
          <ButtonsToolbar
            handleSubmit={() => (isFormChanged || !formSubmitted) ? this.handleSubmit() : history.push(`/corporate/${nextStep.key}`)}
            disabled={disabled || loading}
            status={status}
            label={t(messages[(isFormChanged || !formSubmitted) ? 'submitAndProceed' : 'next'].i18nKey, messages[(isFormChanged || !formSubmitted) ? 'submitAndProceed' : 'next'].defaults)}
            onBack={() => history.push(`/corporate/${prevStep.key}`)}
          />
        </Grid>}
      </Grid>
    </React.Fragment>
  }
}

export default compose(
  withNamespaces(),
  withRouter,
  withStyles(styles, {withTheme: true}),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {loading, error}, data}) => ({
      loading,
      error,
      viewer: get(data, 'viewer')
    })
  }),
  graphql(ADD_CORPORATE_DETAILS, {
    name: 'addCorporateDetails',
    options: {
      refetchQueries: [{query: CLIENT_DATA_QUERY}],
    }
  }),
)(FinancialInformationStep)
