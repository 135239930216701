import React from 'react'
import {Redirect, Route, Switch, withRouter} from 'react-router-dom'
import UploadDocument from './UploadDocument'
import ChooseDocument from './ChooseDocument'
import DocumentsHistory from './DocumentsHistory'

const DocumentRoutes =  ({match: {path}}) => (
  <Switch>
    <Route exact path={`${path}/upload/:category`} component={ChooseDocument} />
    <Route exact path={`${path}/upload/`} component={UploadDocument} />
    <Route exact path={`${path}/history`} component={DocumentsHistory} />
    <Route path={`${path}/:parentPath/:childPath+`} render={({match}) => <Redirect to={`${path}/${match.params.parentPath}`} />} />
    <Route path={`${path}`} component={UploadDocument} />
  </Switch>
)

export default withRouter(DocumentRoutes)
