import {Trans} from 'react-i18next'
import React, {Component} from 'react'
import Grid from '@mui/material/Grid'
import withStyles from '@mui/styles/withStyles'
import messages from '../../assets/messages'
import LoadingButton from '../Common/LoadingButton'
import FormHelperText from '@mui/material/FormHelperText'
import {flowRight as compose} from 'lodash'
import {Button} from '@mui/material'
import {withRouter} from 'react-router'
import {isMobile} from '../../common/utils'

const styles = theme => ({
  buttons: {
    margin: '5px',
    [theme.breakpoints.down('md')]: {
      margin: '5px  0'
    }
  },
  toolbar: {
    marginTop: 30
  },
})

class ButtonsToolbar extends Component {
  render() {
    const {handleSubmit, label, classes, loading, status, disabled, hideProgressBar, onBack, hideButton, hideBack, scrollUp} = this.props

    return (
      <Grid item className={classes.toolbar}>
        {!hideBack && onBack && <Button className={classes.buttons} variant="contained"
          size="large" color="secondary" fullWidth={isMobile()} onClick={() => {
            onBack()
            scrollUp && scrollUp()
          }}>
          <Trans {...messages.back} />
        </Button>}
        {!hideButton && <LoadingButton
          id='loadingButton'
          onClick={() => {
            handleSubmit()
            scrollUp && scrollUp()
          }}
          disabled={disabled || loading || false}
          hideProgressBar={hideProgressBar}
          status={status}
          className={classes.buttons}
          noMargin={true}
          fullWidth={isMobile()}
        >{label}
        </LoadingButton>}
        {status==='failure' &&
        <FormHelperText className={classes.errorMessage}><Trans {...messages.generalError} /></FormHelperText>}
      </Grid>
    )
  }
}

export default compose(withRouter, withStyles(styles, {withTheme: true}))(ButtonsToolbar)
