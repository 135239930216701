import React from 'react'
import PropTypes from 'prop-types'
import {graphql,withApollo} from 'react-apollo'
import {withNamespaces, Trans} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import Grid from '@mui/material/Grid'
import messages from '../../../assets/messages'
import {isMobile} from '../../../common/utils/browser'
import Typography from '@mui/material/Typography'
import Amount from '../../Common/Amount'
import {AlertDialog} from '../../Common/Dialog/index'
import {MT4Connector, FeedEvent} from '@bdswiss/mt4-connector'
import config from '../../../config'
import {withRouter, Link} from 'react-router-dom'
import {Loading} from '../../Common/Loading'
import Autorenew from '@mui/icons-material/Autorenew'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import {FOREX_POSITIONS_COUNT, FOREX_POSITIONS} from '../../../graphql/queries'
import ForexPositions from './ForexPositions'
import PageSubTitle from '../../Common/PageSubTitle'
import ForexTradeNow from './ForexTradeNow'
import {
  convertToCamelCaseAndRemoveUnderscores,
  disableDepositWithSpecificCountries,
  getItem,
  hasWebTrader,
  isMenaCountry
} from '../../../common/utils'
import Images from '../../Common/Images'
import {get, find, unionBy, reject, some, orderBy, union, assign, flowRight as compose, startCase, upperCase} from 'lodash'
import AppContext from '../../Common/contexts/AppContext'
import classNames from 'classnames'
import {isForexAccount, isZuluAccount} from '../../../common/utils/accounts'
import {accountTypes} from '@bdswiss/common-enums'
import NotificationBar from '../../Common/NotificationBar'
import {Fade} from '../../Common/Fade'
import {Tooltip} from '@mui/material'
import Help from '@mui/icons-material/HelpOutlineOutlined'
import {logEventCustomParams} from '../../../common/utils/firebaseEvents'
import {REQUEST_ZULU_TRADE_SSO_LINK} from '../../../graphql/mutations'

const fontRatio = isMobile() ? 0.5 : 1
const styles = theme => ({
  amount: {
    marginTop: isMobile() ? 2 : 10,
    display: 'block',
  },
  amountCurrency: {
    fontSize: Math.floor(24 * fontRatio),
    fontWeight: 200,
    padding: '0 3px',
  },
  amountValue: {
    fontSize: Math.floor(36 * fontRatio),
    fontWeight: 200,
  },
  amountFraction: {
    fontSize: Math.floor(24 * fontRatio),
    fontWeight: 200,
  },
  financialInfoItem: {
    marginBottom: 20,
    color: theme.palette.secondary.dark,
  },
  noTradesIcon: {
    maxWidth: 180,
    minWidth:120,
    width:'75%'
  },
  noPositionsLink: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  buttonProgress:{
    color: theme.palette.primary.main,
  },
  buttonProgressCenter: {
    textAlign: 'center'
  },
  refetchButton: {
    textAlign: 'right',
    '&.loading svg': {
      animation: '$spin 2s linear infinite',
    }
  },
  marginLevelAmount:{
    marginTop:isMobile() ? 2 : 10,
  },
  error: {
    color: theme.palette.red.color,
  },
  success: {
    backgroundColor: theme.palette.green.color,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  textLeft:{
    textAlign: 'left'
  },
  helpIcon: {
    color: theme.palette.secondary.main,
    marginLeft: 5,
    fontSize: 14,
    background:'none',
  },
  displayInline: {
    display: 'inline-flex',
  },
  customTooltip:{
    fontSize: 12,
  },
  helpBtn:{
    padding: 0,
    '&:hover':{
      background:'none'
    }
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(-360deg)',
    },
    '100%': {
      transform: 'rotate(0deg)',
    }
  },
})


class ForexTradingInfo extends React.Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    this.state = {
      mt4: MT4Connector.Instance,
      openCloseOrderModal: false,
      closeOrderModalError: false,
      closeOrderModalErrorMessage: '',
      showNotification: false,
      mt4Success: false,
      open: false,
    }
  }


  closeOrderModalClose() {
    const {mt4, orderStatus, orderNumber} = this.state
    const type = get(mt4, 'login.server') === 'demo' ? 'Demo' : 'Real'
    logEventCustomParams(`cancel${startCase(orderStatus)}${type}OrderAttempted`, {positionsId: orderNumber})
    this.setState({openCloseOrderModal: false})
  }

  componentDidMount() {
    const {productConfigs:{forex:{mt4SocketUrl}}}= config
    const {account} = this.props
    const accountSocketUrl = account.metaTraderApiUrl || mt4SocketUrl
    const login = {login: account.login,server: account.isDemo ? 'demo' : 'real',version: 3,platform: 'web'}
    const mt4= MT4Connector.Instance
    const feed = mt4.getFeed()
    this.setState({subscription:feed.subscribe(this.processFeedMessages)})
    mt4.connect(accountSocketUrl, login)
  }

  componentWillUnmount() {
    const {subscription, mt4} = this.state
    subscription && subscription.unsubscribe()
    mt4.disconnect()
  }

  processFeedMessages = (message) => {
    const {type, payload} = message


    if (type === FeedEvent.BALANCE_UPDATE) {
      const {balance, freeMargin, equity, margin, credit} = payload
      this.setState({mt4Balance : credit > 0 ? balance - credit : balance,mt4FreeMargin: freeMargin,mt4Equity:equity, mt4Margin: margin, mt4Bonus: credit})
    }
    if (type === FeedEvent.LOGIN_SUCCESS) {
      this.setState({positions : orderBy(payload.trades,'order'), pendingPositions : orderBy(payload.pendingOrders,'order'), mt4Success : true})
    }

    if (type === FeedEvent.PRICES) {
      this.setState({assets: payload})
    }

    if (type === FeedEvent.PRICES_UPDATE) {
      const mergedAssets = assign(this.state.assets,payload)
      this.setState({assets : mergedAssets})
    }

    if (type === FeedEvent.ORDER_CLOSED) {
      const mergedClosed = orderBy(union([payload],this.state.closedPositions), 'order')
      this.setState({closedPositions : mergedClosed})
    }

    if (type === FeedEvent.POSITIONS_UPDATE) {
      const currentPositions = reject(this.state.positions, (activePositions) =>
        some(this.state.closedPositions, {order: activePositions.order} )
      )

      const merged = orderBy(unionBy(payload,currentPositions, 'order'), 'order')
      this.setState({positions : merged})
    }

    if (type === FeedEvent.PENDING_ORDER_CANCELED ) {
      const currentPendingPositions = reject(this.state.pendingPositions, (activePositions) =>
        payload.order === activePositions.order
      )
      this.setState({pendingPositions : currentPendingPositions})
    }

    if (type === FeedEvent.PENDING_ORDER_OPENED) {
      const mergedPending = orderBy(unionBy([payload],this.state.pendingPositions, 'order'), 'order')
      this.setState({pendingPositions : mergedPending})
    }

    if (type === FeedEvent.PENDING_ORDER_UPDATED) {
      const mergedPending = orderBy(unionBy([payload],this.state.pendingPositions, 'order'), 'order')
      this.setState({pendingPositions : mergedPending})
    }

    if (type === FeedEvent.PENDING_ORDER_ACTIVATED ) {
      const currentPendingPositions = reject(this.state.pendingPositions, (activePositions) =>
        payload.order === activePositions.order
      )
      this.setState({pendingPositions : currentPendingPositions})
    }
  }

  orderClose(order, status) {
    this.setState({openCloseOrderModal : true, orderNumber: order, orderStatus: status})
  }

  orderCloseConfirmation() {
    const {mt4, orderNumber, orderStatus} = this.state
    get(mt4, 'login.server') === 'demo' ?
      logEventCustomParams((orderStatus === 'pending') ? 'cancelPendingDemoOrderAttempted' : 'closeDemoOrderAttempted', {positionsId: orderNumber}) :
      logEventCustomParams((orderStatus === 'pending') ? 'cancelPendingRealOrderAttempted' : 'closeRealOrderAttempted', {positionsId: orderNumber});
    (orderStatus === 'pending')
      ? mt4
        .cancelPendingOrder({order: orderNumber})
        .subscribe(()=> {
          get(mt4, 'login.server') === 'demo' ?
            logEventCustomParams('cancelPendingDemoOrderCompleted', {positionsId: orderNumber}) :
            logEventCustomParams('cancelPendingRealOrderCompleted', {positionsId: orderNumber})
          this.setState({openCloseOrderModal : false, showNotification: true})
          setTimeout(() => {this.setState({showNotification: false})}, 5000)
        }, (error) => {
          get(mt4, 'login.server') === 'demo' ?
            logEventCustomParams('cancelPendingDemoOrderFailed', {positionsId: orderNumber, reason: error.message}) :
            logEventCustomParams('cancelPendingRealOrderFailed', {positionsId: orderNumber, reason: error.message})
          this.setState({openCloseOrderModal : false, closeOrderModalError: true, closeOrderModalErrorMessage: error.message, showNotification: true})
          setTimeout(() => {this.setState({showNotification: false})}, 5000)
        })
      : mt4
        .closeOrder({order: orderNumber})
        .pipe()
        .subscribe(()=> {
          get(mt4, 'login.server') === 'demo' ?
            logEventCustomParams('closeDemoOrderCompleted', {positionsId: orderNumber}) :
            logEventCustomParams('closeRealOrderCompleted', {positionsId: orderNumber})
          this.setState({openCloseOrderModal : false, showNotification: true})
          setTimeout(() => {this.setState({showNotification: false})}, 5000)
        }, (error) => {
          get(mt4, 'login.server') === 'demo' ?
            logEventCustomParams('closeDemoOrderFailed', {positionsId: orderNumber, reason: error.message}) :
            logEventCustomParams('closeRealOrderFailed', {positionsId: orderNumber, reason: error.message})
          this.setState({openCloseOrderModal : false, closeOrderModalError: true, closeOrderModalErrorMessage: error.message, showNotification: true})
          setTimeout(() => {this.setState({showNotification: false})}, 5000)
        })
  }

  refetchPositions (status) {
    this.setState({isClosedPositionsFetching: true})
    this.props.refetchPositions({
      id: Number(this.props.match.params.accountId),
      status: status,
      limit: status === 'closed' ? 10 : 100,
      offset: 0}).then((res) => {
      this.setState({
        isClosedPositionsFetching: false,
        positions: get(res, 'data.viewer.accounts.0.forexPositions', []),
      })
    }).catch((e) => {
      console.log(e) //eslint-disable-line no-console
      this.setState({isClosedPositionsFetching: false})
    })
  }

  bonusTooltip() {
    const {account} = this.props
    return (<React.Fragment>
      <Grid container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={1}>
        <Grid item xs={9}><Trans {...messages.requiredLots}/></Grid>
        <Grid item xs={3}>{get(account, 'bonus.requiredLots')}</Grid>
        <Grid item xs={9}><Trans {...messages.tradedLots}/></Grid>
        <Grid item xs={3}>{get(account, 'bonus.lotsTraded')}</Grid>
      </Grid>
    </React.Fragment>)
  }

  handleTooltipClose = () => {
    this.setState({open: false})
  }

  handleTooltipToggle = () => {
    this.setState(state => ({open: !state.open}))
  }

  checkLeverage(account) {
    const {t} = this.props
    const leverageTypeInitial = get(account, 'leverageType') || 'default_leverage'
    const leverage = convertToCamelCaseAndRemoveUnderscores(leverageTypeInitial)
    const leverageType = t(messages[leverage]?.i18nKey, messages[leverage]?.defaults)
    const leverageValue = leverageTypeInitial ? t(messages.oneToLeverage.i18nKey, {leverage: get(account, 'leverage')}) : t(messages[`${leverage}Max`]?.i18nKey, messages[`${leverage}Max`]?.defaults)

    return {
      leverageType,
      leverageValue
    }
  }


  getZuluSSOLink (account) {
    const {t, classes} = this.props
    const variables = {
      accountId: get(account, 'id')
    }
    this.setState({loadingZuluBtn: true})
    const newTab = window.open('about:blank', '_blank')
    newTab.document.write('<h4>Please wait</h4>')
    this.props.requestZuluTradeSSOLink({variables})
      .then((res) => {
        if (res.data.createZuluSSOLink?.statusCode === 500) {
          this.setState((state) => ({
            loadingZuluBtn: false,
            statusZuluBtn: '',
            showZuluError: true,
          }))
          newTab.close()
          this.context.showNotification({
            type: 'document-upload',
            status: 'failure',
            title: 'somethingWrongError',
            buttonMessage: <Trans {...messages.close} />,
            content: <Trans
              {...messages.somethingWrongErrorZulu}
              components={[
                <a target="_blank" rel="noopener noreferrer" href={'https://www.zulutrade.com'} className={classes.link}>Zulutrade.com</a>
              ]}
            />
          })
        } else {
          this.setState((state) => ({
            loadingZuluBtn: false,
            statusZuluBtn: 'success',
            submitMessageSuccess: t(messages.success.i18nKey, messages.success.defaults)
          }))
          newTab.location = res?.data?.createZuluSSOLink?.ssoUrl
        }
      })
      .catch((error) => {
        this.setState({
          loadingZuluBtn: false,
          statusZuluBtn: '',
          submitMessageError: t(messages.somethingWrongError.i18nKey, messages.somethingWrongError.defaults)
        })
      })
  }

  render() {
    const {viewer, classes,account,loading, status:positionsType, match: {url}, allPositionsLoading,
      positionsClosedCount, positionsOpenedCountLoading, positionsOpenedCount, t} = this.props
    const {mt4, positions, openCloseOrderModal, closeOrderModalError, closeOrderModalErrorMessage, showNotification,
      orderNumber, mt4Success, pendingPositions, assets, isClosedPositionsFetching} = this.state
    const {locale, company, themePreference} = this.context
    const {downloadTrader, hideMt4Mentions} = config
    const percentFormatter = new Intl.NumberFormat({
      style: 'percent', maximumFractionDigits: 0,
    })

    if (loading && positionsOpenedCountLoading) return <Loading />

    const downloadTraderLabel = accountTypes[account.__typename] && isForexAccount(account) ?
      downloadTrader[accountTypes[account.__typename].category].shortLabel : downloadTrader.default.shortLabel

    const positionsCount = mt4Success ? mt4.positions.size : positionsOpenedCount
    const pendingPositionsCount = mt4Success ? mt4.pendingOrders.size : 0

    let mt4Balance,mt4FreeMargin,mt4Equity, mt4Margin, mt4Bonus
    if (mt4Success) {
      mt4Balance = this.state.mt4Balance
      mt4FreeMargin = this.state.mt4FreeMargin
      mt4Equity = this.state.mt4Equity
      mt4Margin = this.state.mt4Margin
      mt4Bonus = this.state.mt4Bonus
    } else {
      mt4Balance = account.balance
      mt4FreeMargin = account.balance
      mt4Equity = account.balance
      mt4Bonus = account.credit
    }

    const selectedPosition = find((positionsType === 'pending') ? pendingPositions : positions,(p) => p.order===orderNumber)
    const positionsList = positionsType === 'pending' ? pendingPositions : positions
    const leverage = this.checkLeverage(account)
    const showCopyTradeNow = isZuluAccount(account)
    return (
      <React.Fragment>
        <Fade in={showNotification} timeout={{
          appear: 1000,
          enter: 1000,
          exit: 1000,
        }}>
          <NotificationBar
            status={closeOrderModalError ? 'error' : 'success'}
            title={<React.Fragment>
              {orderNumber}: {closeOrderModalError ? <React.Fragment><Trans {...messages.pageError}/>: {closeOrderModalErrorMessage}</React.Fragment> : <Trans {...messages.orderClosed} />}
            </React.Fragment>}
          />
        </Fade>
        <AlertDialog
          open={openCloseOrderModal}
          onClose={() => this.closeOrderModalClose()}
          onAgree={() => this.orderCloseConfirmation()}
          onDisagree={() => this.closeOrderModalClose()}
          title={t(messages.closingPosition.i18nKey, messages.closingPosition.defaults)}
          disagreeText={t(messages.cancel.i18nKey, messages.cancel.defaults)}
          agreeText={t(messages.confirm.i18nKey, messages.confirm.defaults)}
        >
          <Grid container direction="row" alignContent="center" justifyContent="center">
            <Grid item xs={12}>
              <Typography variant="body1" className={classes.popupText}>
                <Trans {...messages.closeOrderConfirmation} />
              </Typography>
              <Typography variant="body1" className={classes.popupText}>
                {orderNumber}: {selectedPosition && selectedPosition.symbol}
              </Typography>
            </Grid>
          </Grid>
        </AlertDialog>
        <Grid container spacing={3} mt={-2}>
          {(positionsType === 'closed') &&
          <Grid item xs={12} className={classNames([classes.refetchButton, {loading: isClosedPositionsFetching}])}>
            <Button disabled={isClosedPositionsFetching} color={'primary'} onClick={()=> this.refetchPositions(positionsType)}><Autorenew /></Button>
          </Grid>}
          <Grid item xs={12} className={!isMobile()? classes.buttonProgressCenter : ''}>
            {allPositionsLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            {((positionsCount > 0 && positionsType === 'open') || (pendingPositionsCount > 0 && positionsType === 'pending') ||
              (positionsType === 'closed' && positionsClosedCount > 0)) && !allPositionsLoading &&
              <ForexPositions account={account} onClosePosition={(order) => this.orderClose(order, positionsType)}
                status={positionsType} enablePagination={positionsType === 'closed'}
                positions={((positionsType !== 'closed')) ? positionsList : false }
                assets={assets}
              />
            }
            {((positionsCount === 0 && positionsType === 'open')  || (pendingPositionsCount === 0 && positionsType === 'pending') ||
              (positionsType === 'closed' && positionsClosedCount === 0)) &&
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={6}>
                  <Grid container spacing={0} alignItems="center">
                    <Grid item xs={6}><img className={classes.noTradesIcon} src={Images[`no-trades-${themePreference}.png`]} alt='' /></Grid>
                    <Grid item xs={6}>
                      <Typography variant='body1' className={classes.textLeft}><Trans {...messages[`tradesEmpty_${positionsType}`]} /></Typography>
                      <Grid container spacing={2} alignItems="center">
                        {!showCopyTradeNow && mt4Balance > 0 && <React.Fragment>
                          {hasWebTrader() && <Grid item xs={12} sm={6} p={2}>
                            <ForexTradeNow type='link' account={account} company={company} locale={locale || getItem('locale', 'en')} alignLeft/>
                          </Grid>}
                          <Grid item>
                            {!hideMt4Mentions &&  <Link to={`${url}/downloadTrader`} >
                              <Typography variant="body2" color="primary" className={classes.textLeft}>
                                <Trans {...messages.downloadMT4} values={{product:downloadTraderLabel}}/>
                              </Typography>
                            </Link>}
                          </Grid>
                        </React.Fragment>
                        }
                        {mt4Balance <= 0 && !disableDepositWithSpecificCountries(viewer) &&
                          <Grid item>
                            <Link to={`/transactions/${account.id}/deposit`} >
                              <Typography variant="body2" color="primary" className={classes.textLeft}>
                                <Trans {...messages.depositNow}/>
                              </Typography>
                            </Link>
                          </Grid>}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <PageSubTitle variant='h3'><Trans {...messages.financialInfo} /></PageSubTitle>
            <Grid container>
              <Grid item lg={4} xs={6} className={classes.financialInfoItem}>
                <Typography variant='caption'><Trans {...messages.balance} /></Typography>
                <Amount
                  value={mt4Balance || 0} currency={account.currency} locale='en-US' classes={{
                    root: classes.amount,
                    currency: classes.amountCurrency,
                    value: classes.amountValue,
                    fraction: classes.amountFraction
                  }} />
              </Grid>
              <Grid item lg={4} xs={6} className={classes.financialInfoItem}>
                <Typography variant='caption'><Trans {...messages.freeMargin} /></Typography>
                <Amount
                  value={mt4FreeMargin || 0} currency={account.currency} locale='en-US' classes={{
                    root: classes.amount,
                    currency: classes.amountCurrency,
                    value: classes.amountValue,
                    fraction: classes.amountFraction
                  }} />
              </Grid>
              <Grid item lg={4} xs={6} className={classes.financialInfoItem}>
                <Typography variant='caption'><Trans {...messages.equity} /></Typography>
                <Amount
                  value={mt4Equity || 0} currency={account.currency} locale='en-US' classes={{
                    root: classes.amount,
                    currency: classes.amountCurrency,
                    value: classes.amountValue,
                    fraction: classes.amountFraction
                  }} />
              </Grid>
              <Grid item lg={4} xs={6} className={classes.financialInfoItem}>
                <Typography variant='caption'><Trans {...messages.marginLevel} /></Typography>
                {mt4Margin ?
                  <div className={classNames(classes.marginLevelAmount,classes.amountValue)}>{percentFormatter.format(mt4Equity*100/mt4Margin)}%</div> :
                  <div className={classNames(classes.marginLevelAmount,classes.amountValue)}>-</div>}
              </Grid>
              {mt4Bonus > 0 && <Grid item lg={4} xs={6} className={classes.financialInfoItem}>
                <Typography variant="caption" className={classes.displayInline}> <Trans {...messages.bonus} /> </Typography>
                <Tooltip
                  open={this.state.open}
                  title={this.bonusTooltip()}
                  placement="top-start"
                  classes={{tooltip: classes.customTooltip}}
                >
                  <span className={classes.helpBtn}
                    onMouseEnter={this.handleTooltipToggle}
                    onMouseLeave={this.handleTooltipToggle}
                  >
                    <Help className={classes.helpIcon}/>
                  </span>
                </Tooltip>
                <Amount
                  value={mt4Bonus || 0} currency={account.currency} locale='en-US' classes={{
                    root: classes.amount,
                    currency: classes.amountCurrency,
                    value: classes.amountValue,
                    fraction: classes.amountFraction
                  }} />
              </Grid>}
              <Grid item lg={4} xs={6} className={classes.financialInfoItem}>
                <Typography variant='caption'><Trans {...messages.openPositions} /></Typography>
                <div className={classNames(classes.marginLevelAmount,classes.amountValue)}>{positionsCount}</div>
              </Grid>
              {isForexAccount(account) && !isMenaCountry(upperCase(get(viewer, 'address.country'))) && <Grid item lg={4} xs={6} className={classes.financialInfoItem}>
                <Typography variant='caption'>{leverage?.leverageType}</Typography>
                <div className={classNames(classes.marginLevelAmount,classes.amountValue)}>{leverage?.leverageValue}</div>
              </Grid>}
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

ForexTradingInfo.propTypes = {
  account: PropTypes.object.isRequired,
  status:  PropTypes.oneOf(['open', 'closed', 'pending']).isRequired,
}

export default compose(
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
  withApollo,
  withRouter,
  graphql(FOREX_POSITIONS_COUNT, {
    options: (props) => ({variables: {id: Number(props.match.params.accountId), status: 'closed'}, fetchPolicy: 'network-only'}),
    props: ({data: {error, loading: positionsClosedCountLoading}, data}) => {
      const positionsClosedCount = get(data, 'viewer.accounts.0.forexPositionsCount', 0)
      return {
        error,
        positionsClosedCountLoading,
        positionsClosedCount,
      }
    }
  }),
  graphql(FOREX_POSITIONS_COUNT, {
    options: (props) => ({variables: {id: Number(props.match.params.accountId), status: 'open'}, fetchPolicy: 'network-only'}),
    props: ({data: {error, loading: positionsOpenedCountLoading}, data}) => {
      const positionsOpenedCount = get(data, 'viewer.accounts.0.forexPositionsCount', 0)
      return {
        error,
        positionsOpenedCountLoading,
        positionsOpenedCount,
      }
    }
  }),
  graphql(FOREX_POSITIONS, {
    options: (props) => ({
      variables: {id: Number(props.match.params.accountId), status: props.status, limit: 1, offset: 0},
      fetchPolicy: 'network-only'
    }),
    props: ({data: {viewer, error, loading: allPositionsLoading}, data}) => {
      const allPositions = get(viewer, 'accounts.0.forexPositions') || []
      return {
        error,
        allPositionsLoading,
        allPositions,
        refetchPositions: data.refetch
      }
    }
  }),
  graphql(REQUEST_ZULU_TRADE_SSO_LINK, {
    name: 'requestZuluTradeSSOLink',
  }),
)(ForexTradingInfo)
