import React, {Component} from 'react'
import withStyles from '@mui/styles/withStyles'
import {flowRight as compose, map} from 'lodash'
import {Step, StepLabel, Stepper, Typography} from '@mui/material'
import {corporateSteps} from '@bdswiss/common-enums'
import AppContext from '../Common/contexts/AppContext'
import {withRouter} from 'react-router'
import {isMobile} from '../../common/utils'
import Tabs from '@mui/material/Tabs'
import AppBar from '@mui/material/AppBar'
import classNames from 'classnames'

const styles = theme => ({
  stepTitle:{
    lineHeight: 1.5
  },
  root: {
    padding: 0,
  },
  step: {
    '&:hover':{
      cursor: 'pointer'
    },
  },
  scrollButtons: {
    color: theme.palette.secondary.main,
    maxWidth: 30,
    opacity: 0.5,
  },
  appBar:{
    boxShadow: 'none',
  },
  tabs:{
    marginBottom: 0,
    backgroundColor: theme.palette.background.default,
    paddingTop: 10
  },
  flexContainer:{
    borderBottom: 'none',
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
  },
  indicator:{
    display: 'none'
  },
  div: {
    padding: '0 10px',
    cursor: 'pointer',
    whiteSpace: 'pre-line',
    minWidth: 90,
  },
  circle: {
    backgroundColor: theme.palette.lightgrey.color,
    borderRadius: '50%',
    height: 30,
    width: 30,
    textAlign: 'center',
    margin: 'auto'
  },
  active: {
    backgroundColor : theme.palette.primary.main,
  },
  activeText: {
    color: theme.palette.primary.contrastText,
    fontWeight: 400,
  },
  caption: {
    maxWidth: 150,
    textAlign: 'center'
  }
})

class CorporateStepper extends Component {
  static contextType = AppContext

  render() {
    const {activeStep, classes, history, viewerStep} = this.props
    const {locale} = this.context

    return !isMobile() ? <Stepper activeStep={activeStep} alternativeLabel classes={{root: classes.root}}>
      {map(corporateSteps, (step) => {
        const availableStep = viewerStep >= step.rank
        return <Step key={step.key} onClick={() => availableStep && history.push(`/corporate/${step.key}`)}>
          <StepLabel classes={{root: availableStep ? classes.step : ''}}>
            <Typography variant='body2' className={classes.stepTitle}>{step.localization.t(locale)}</Typography>
          </StepLabel>
        </Step>
      })}
    </Stepper> : <AppBar position="static" classes={{root:classes.appBar}}>
      <Tabs
        value={activeStep}
        indicatorColor='primary'
        textColor='primary'
        scrollable
        scrollButtons
        classes={{root:classes.tabs,flexContainer:classes.flexContainer,indicator:classes.indicator,scrollButtons:classes.scrollButtons}}
        allowScrollButtonsMobile>
        {map(corporateSteps, (step)=> {
          const availableStep = viewerStep >= step.rank
          const active = activeStep === (step.rank - 1)
          return <div key={step.rank}
            className={classes.div}
            onClick={() => availableStep && history.push(`/corporate/${step.key}`)}>
            <div className={classNames(classes.circle, active && classes.active)}>
              <Typography variant={'body1'} className={(active) ? classes.activeText : ''}>
                {step.rank}
              </Typography>
            </div>
            <Typography variant={'caption'} className={classes.caption}>
              {step.localization.t(locale)}
            </Typography>
          </div>
        })}
      </Tabs>
    </AppBar>
  }
}

export default compose(withRouter,withStyles(styles, {withTheme: true}))(CorporateStepper)
