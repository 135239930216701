import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'
import {withNamespaces} from 'react-i18next'
import {frontends} from '@bdswiss/common-enums'
import {flowRight as compose, get, toUpper} from 'lodash'
import {withCreateDeposit} from './helpers'
import config from '../../../../config'
import {getCurrentTheme, getItem, getPlatform, getSourceBasedOnLocationPrevPath, isDarkTheme, logEventCustomParams, safeParseJSON} from '../../../../common/utils'
import GooglePayButton from '@google-pay/button-react'


class GooglePayProvider extends Component {

  constructor(props) {
    super(props)

    this.state = {
      errors: {},
    }
  }

  createDeposit() {
    const {providerProperties, amount, account, onError, bonusAmount, bonusTerms, history: {location}} = this.props

    const args = {
      paymentKey: providerProperties.paymentKey,
      platform: getPlatform(),
      useCardFlow: true,
      bonusTerms: bonusTerms,
      bonusAmount: bonusAmount,
      cardDetails: {},
      safechargeVersion: 2,
    }

    const firebaseParams = {
      source: getSourceBasedOnLocationPrevPath(get(location, 'state', {prevPath: get(location, 'pathname')})),
      login: account.id,
      currency: account.currency,
      vendor: providerProperties.provider,
      amount,
    }
    logEventCustomParams('depositAttempted', firebaseParams)

    const variables = {
      accountId: account.id,
      amount: amount,
      vendor: providerProperties.provider,
      frontend: frontends.web2.value,
      paymentOptionId: providerProperties.id,
      args: JSON.stringify(args),
    }

    this.props.createDepositRequest({variables})
      .then(({data: {newDeposit: {payment}}}) => JSON.parse(payment.payload))
      .then((payload) => {
        this.setState({
          sessionToken: payload.sessionToken,
          clientUniqueId: payload.clientUniqueId,
        })
      })
      .catch((e) => {
        onError && onError(e)
      })
  }

  doDeposit(paymentData) {
    const {providerProperties, viewer, account, history} = this.props
    const {sessionToken, clientUniqueId} = this.state
    const additionalFields = safeParseJSON(providerProperties.additionalFields)

    const sfc = window.SafeCharge({
      env: config.common.safecharge.env,
      merchantId: additionalFields.merchantId,
      merchantSiteId: additionalFields.merchantSiteId,
    })

    const paymentPayload = {
      sessionToken: sessionToken,
      merchantId: additionalFields.merchantId,
      merchantSiteId: additionalFields.merchantSiteId,
      clientUniqueId: clientUniqueId,
      userDetails: {
        firstName: viewer.firstName,
        lastName: viewer.lastName,
        email: viewer.email,
        phone: viewer.phone,
      },
      paymentOption: {
        card: {
          externalToken: {
            externalTokenProvider: 'GooglePay',
            mobileToken: JSON.stringify(paymentData.paymentMethodData),
          },
        },
      }
      ,
      billingAddress: {
        firstName: viewer.firstName,
        lastName: viewer.lastName,
        email: viewer.email,
        country: toUpper(get(viewer, 'address.country')),
      },
    }
    sfc.createPayment(paymentPayload, (res) => {
      if (!res.cancelled && res.result === 'APPROVED') {
        history.push(`/transactions/${account.id}/deposit/result/success`)
      } else {
        history.push(`/transactions/${account.id}/deposit/result/failed`)
      }
    })

  }

  render() {
    const allowCreditCards = true
    const allowPrepaidCards = false
    const allowedCardAuthMethods = ['PAN_ONLY', 'CRYPTOGRAM_3DS']
    const allowedCardNetworks = ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA']
    const assuranceDetailsRequired = false
    const billingAddressParameters = {format: 'MIN', phoneNumberRequired: false}
    const billingAddressRequired = false
    const checkoutOption = 'COMPLETE_IMMEDIATE_PURCHASE'
    const tokenizationType = 'PAYMENT_GATEWAY' // 'DIRECT'
    const totalPriceStatus = 'FINAL'

    const gateway = config?.googlepay?.gateway // 'nuveidigital'
    const gatewayMerchantId = config?.googlepay?.gatewayMerchantId // 'googletest'
    const googleMerchantId = config?.googlepay?.googleMerchantId // 'BCR2DN6TR6Y2XRC2'
    const merchantName = config?.googlepay?.merchantName // 'Google Pay webSDK'

    const country = toUpper(get(this.props, 'viewer.address.country'))
    const currency = toUpper(this.props.account.currency)
    const amount = this.props.amount.toString()

    const paymentRequest = {
      apiVersion: 2,
      apiVersionMinor: 0,
      allowedPaymentMethods: [{
        type: 'CARD',
        parameters: {
          allowedAuthMethods: allowedCardAuthMethods,
          allowedCardNetworks: allowedCardNetworks,
          allowCreditCards: allowCreditCards,
          allowPrepaidCards: allowPrepaidCards,
          assuranceDetailsRequired: assuranceDetailsRequired,
          billingAddressRequired: billingAddressRequired,
          billingAddressParameters: billingAddressParameters,
        },
        tokenizationSpecification: {
          type: tokenizationType,
          parameters: {gateway, gatewayMerchantId},
        }
      }],
      transactionInfo: {
        countryCode: country,
        currencyCode: currency,
        totalPriceStatus: totalPriceStatus,
        totalPrice: amount,
        checkoutOption: checkoutOption,
      },
      merchantInfo: {
        merchantId: googleMerchantId,
        merchantName: merchantName,
      }
    }

    const themePreference = getCurrentTheme(this.context)

    return (<GooglePayButton
      style={{...this.props.style, width: '100%', height: '48px'}}
      buttonType="pay"
      environment="TEST"
      buttonColor={isDarkTheme(themePreference) ? 'white' : 'black'}
      buttonLocale={getItem('locale', 'en')}
      buttonSizeMode="fill"
      paymentRequest={paymentRequest}
      onClick={()=>  this.createDeposit()}
      onLoadPaymentData={paymentData => this.doDeposit(paymentData)}
      // onReadyToPayChange={readyToPay => this.createDeposit(readyToPay)}
    />)
  }
}

GooglePayProvider.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  amount: PropTypes.number.isRequired,
  providerProperties: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    paymentKey: PropTypes.string.isRequired,
  }).isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

export default compose(
  withNamespaces(),
  withRouter,
  withCreateDeposit,
)(GooglePayProvider)

