import React, {Component} from 'react'
import {Trans, withNamespaces} from 'react-i18next'
import {flowRight as compose, isEmpty, findKey, get, includes, find, some, omit, uniq, trim, pickBy, isNil, filter} from 'lodash'
import AppContext from '../Common/contexts/AppContext'
import withStyles from '@mui/styles/withStyles'
import {Link, withRouter} from 'react-router-dom'
import Grid from '@mui/material/Grid'
import {scrollElementIntoView} from '../../common/utils'
import ButtonsToolbar from './ButtonsToobar'
import CorporateStepper from './CorporateStepper'
import messages from '../../assets/messages'
import Questions, {getStepQuestions} from './Questions'
import {appropTestPoliticallyExposedReason, corporateSteps, corporateFields, corporateStatuses, countries} from '@bdswiss/common-enums'
import {validateCharacters, validateDOB, validateEmail, validateCountryCodePrefix, validateAddress} from '../../common/utils/validations'
import {graphql} from 'react-apollo'
import {ADD_CORPORATE_DETAILS} from '../../graphql/mutations'
import {CLIENT_DATA_QUERY} from '../../graphql/queries'
import PepDialog from '../Common/PepDialog'
import NotificationBar from '../Common/NotificationBar'

const gridScroll = 'scroll-grid'

const styles = theme => ({
  notificationBox: {
    marginTop: 20,
    position: 'relative'
  },
  infoCTA: {
    color: theme.palette.notificationBar.blueTextColor,
  },
})

class RepresentativeStep extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    const activeStep = corporateSteps.representative.rank
    this.state = {
      form: {},
      errors : {},
      questions: [],
      loading: false,
      disabled: false,
      status: '',
      initialForm: {},
      formChanged: {},
      prefixChanged: {},
      hidden: [],
      activeStep,
      nextStep: find(corporateSteps, (step) => step.rank === activeStep + 1),
      prevStep: find(corporateSteps, (step) => step.rank === activeStep - 1)
    }
  }

  componentDidMount() {
    const {viewer} = this.props
    const {activeStep} = this.state
    getStepQuestions(viewer, activeStep, (state) => this.setState(state))
  }

  scrollUp(errors) {
    this.setState(() => scrollElementIntoView(isEmpty(errors) ? gridScroll : findKey(errors), 250))
  }

  handleChange (name, value, prefixChange, checkbox) {
    const {initialForm, form, prefixChanged} = this.state
    const pepRemoveReason = get(form, 'politicallyExposedReason') && name === corporateFields.politicallyExposedPerson.value && value === corporateFields.politicallyExposedPerson.options.no.value

    if (get(corporateFields[name], 'isCountry') && !prefixChange) {
      if (corporateFields[name].isPrefix) {
        this.setState(state => ({prefixChanged: {...state.prefixChanged, [name]: true}}))
      } else if (!prefixChanged[name]) {
        const country = find(corporateFields[name].options, (country) => country.value === value)
        const nextPrefix = corporateFields[name].prefixChange
        if (nextPrefix && !prefixChanged[nextPrefix]) {
          this.setState(state => ({
            selectedCountry: {...state.selectedCountry, [nextPrefix]: country}}),
          this.handleChange (nextPrefix, `+${get(country,'callingCode', )}`, true))
        }
      }
    }

    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        [name]: value,
        politicallyExposedReason: (pepRemoveReason) ?  '' : (name === 'politicallyExposedReason') ? value : form.politicallyExposedReason
      },
      errors: {
        ...state.errors,
        [name]: !checkbox && !value,
      },
      formChanged:{
        ...state.formChanged,
        [name]: isEmpty(initialForm) || (value !== initialForm[name]),
      },
      status: ''
    }))
  }

  handleSubmit() {
    const {form, activeStep, formSubmitted, hidden, nextStep} = this.state
    const {history} = this.props
    const exceptions = ['correspondenceAddress']
    const submitted = formSubmitted
    const errors = {}
    const memberFields= []
    const addressFields = ['city', 'correspondenceCity', 'cityAddress', 'streetAddress', 'correspondenceStreet']

    for (const field of Object.keys(form)) {
      if (corporateFields[field] && corporateFields[field].required(form, undefined, activeStep, hidden)) {
        if (field === 'email') {
          errors[field] = isEmpty(form[field]) || !validateEmail(form[field])
        } else if (field === 'firstName' || field === 'lastName') {
          const nameError = validateCharacters(trim(form[field]))
          errors[field] = !!nameError
        } else if (field === 'birthday') {
          errors[field] = !validateDOB(form[field])
        } else if (corporateFields[field].isPhone) {
          errors[field] = isEmpty(form[field]) || !validateCountryCodePrefix(form[field], filter(countries, (country) => !country.forbidden))
        } else if (includes(addressFields, field)) {
          const addressError = validateAddress(form[field])
          errors[field] = !!addressError
        }
        else {
          errors[field] = isEmpty(form[field])
        }
      }
      if (corporateFields[field] && corporateFields[field].memberField && corporateFields[field].memberField(activeStep)) {
        memberFields.push({[field]: {[corporateFields[field].memberField(activeStep)]: form[field]}})
      }
    }
    if (some(omit(errors, uniq(exceptions)))) {
      this.setState({errors},this.scrollUp(errors))
      return
    }
    this.setState({loading: true, status: ''})
    this.props.addCorporateDetails({variables: {step: activeStep, answers: JSON.stringify(pickBy(omit(form, memberFields)))}}).then((res) => {
      this.setState({loading: false, status: ''},
        history.push({pathname: `/corporate/${nextStep.key}`, state: {step: !submitted ? activeStep + 1 : ''}}))
    }).catch((err) => {
      this.setState({loading: false, status: 'failure'})
    })

  }

  setOuterState (name, key ,value) {
    !isNil(value) ? this.setState(state => ({
      [name]: {
        ...state[name],
        [key]: value
      }
    })) : this.setState({[name]: key})
  }

  onAgree(key) {
    const {form: {politicallyExposedReason}} = this.state
    if (!politicallyExposedReason)
      this.setState({pepCheckboxError: true})
    else
      this.setState(state => ({
        form: {
          ...state.form,
          politicallyExposedPerson: (politicallyExposedReason === appropTestPoliticallyExposedReason.none.value)
            ? corporateFields.politicallyExposedPerson.options.no.value : corporateFields.politicallyExposedPerson.options.yes.value,
        },
        alert: {
          ...state.alert,
          [key]: false
        },
        pepCheckboxError: false
      }))
  }

  scrollUpSubmit() {
    scrollElementIntoView('scroll-grid-corporate', 250)
  }

  render() {
    const {classes, t, viewer, history} = this.props
    const {disabled, loading, status, selectedCountry, questions, form, errors, pepCheckboxError, alert, formChanged,
      closeNotifitication, formSubmitted, activeStep, nextStep, prevStep}= this.state
    const {locale} = this.context
    const isFormChanged = includes(formChanged, true)
    const corporateStatus = get(viewer, 'corporateStatus')

    return <React.Fragment>
      {!corporateStatus && <CorporateStepper activeStep={activeStep - 1} viewerStep={get(viewer, 'corporateSignupStep')}/>}
      {(!formSubmitted || corporateStatus) && !closeNotifitication && <NotificationBar
        status="info"
        classes={{notificationBox: classes.notificationBox}}
        title={<Trans {...messages[(corporateStatus) ? 'corporateCannotChangeDetails' : 'legalRepresentativeNote']}
          components={[<Link className={classes.infoCTA} to={'/support'}>support</Link>]}
          values={{corporateStatus: corporateStatus && corporateStatuses[corporateStatus].localization.t(locale)}}
        />}
        onClose={!corporateStatus ? () => this.setState({closeNotifitication: true}) : false}
      />}
      <Grid container id={gridScroll} spacing={3}>
        <Questions
          setOuterState={(name, key ,value) => this.setOuterState(name, key ,value)}
          selectedCountry={selectedCountry}
          questions={questions}
          form={form}
          errors={errors}
          handleChange={(name, value, _, checkbox) => this.handleChange(name, value, _, checkbox)}
          step={activeStep}
          disabled={corporateStatus}
          hidden={this.state.hidden}
        />
        {!corporateStatus && <ButtonsToolbar
          handleSubmit={() => (isFormChanged || !formSubmitted) ? this.handleSubmit() : history.push(`/corporate/${nextStep.key}`)}
          disabled={disabled || loading || false}
          status={status}
          label={t(messages[(isFormChanged || !formSubmitted) ? 'submitAndProceed' : 'next'].i18nKey, messages[(isFormChanged || !formSubmitted) ? 'submitAndProceed' : 'next'].defaults)}
          onBack={() => history.push(`/corporate/${prevStep.key}`)}
          scrollUp={this.scrollUpSubmit}
        />}
      </Grid>
      <PepDialog open={get(alert, 'politicallyExposedPerson')} onChange={(e) => this.handleChange('politicallyExposedReason', e.target.value)}
        onAgree={() => this.onAgree('politicallyExposedPerson')} pepCheckboxError={pepCheckboxError} form={form}/>
    </React.Fragment>
  }
}

export default compose(
  withNamespaces(),
  withRouter,
  withStyles(styles, {withTheme: true}),
  graphql(ADD_CORPORATE_DETAILS, {
    name: 'addCorporateDetails',
    options: {
      refetchQueries: [{query: CLIENT_DATA_QUERY}],
    }
  }),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {loading, error}, data}) => ({
      loading,
      error,
      viewer: get(data, 'viewer')
    })
  }),
)(RepresentativeStep)
