import React, {forwardRef} from 'react'
import Intl from 'intl'
import PropTypes from 'prop-types'
import {keyBy} from 'lodash'
import NumberFormat from 'react-number-format'
import withStyles from '@mui/styles/withStyles'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import {InputAdornment} from '@mui/material'

const styles = (theme) => ({
  inputRoot: {
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: 'normal',
  },
  inputRootJumbo: {
    fontSize: 85,
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: 'normal',
    direction: 'ltr'
  },
  inputAdornmentRoot: {
    color: theme.palette.grey[400],
    padding: 5,
  }
})


export const AmountFormat = forwardRef((props, ref) => {
  const {onChange, value, ...otherProps} = props
  return (
    <NumberFormat
      {...otherProps}
      value={value}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        })
      }}
    />
  )
})

AmountFormat.propTypes = {
  onChange: PropTypes.func.isRequired,
}

const AmountInput = withStyles(styles, {withTheme: true})((props) => {
  const {locale = 'en', classes, onChange, value, name, id, currency = 'EUR', jumbo, TextFieldProps} = props
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  })
  const testValue = '100000.00'
  const valueFormattedToParts = keyBy(formatter.formatToParts(testValue), 'type')
  const valueFormatted = formatter.format(testValue)
  const formattedCurrency = valueFormattedToParts['currency'].value
  const decimalSeparator = valueFormattedToParts['decimal'].value
  const thousandSeparator = valueFormattedToParts['group'].value
  const currencyPrefixed = valueFormatted.indexOf(formattedCurrency) === 0
  const adornmentKey = currencyPrefixed ? 'startAdornment' : 'endAdornment'
  const adornmentProp = {[adornmentKey]:  <InputAdornment classes={{root: classes.inputAdornmentRoot}} position={'end'}> {formattedCurrency} </InputAdornment>}
  const amountFormatProps = {decimalSeparator, thousandSeparator, isNumericString: true}
  const inputRootClass = jumbo ? classes.inputRootJumbo : classes.inputRoot

  return (
    <FormControl variant="standard" className={classes.formControl} fullWidth>
      <TextField
        variant="standard"
        id={id}
        name={name}
        value={value}
        onChange={(e) => onChange(e, e.target.value)}
        inputRef={props.ref}
        InputProps={{
          ...adornmentProp,
          inputComponent: AmountFormat,
          inputProps: {...amountFormatProps}, placeholder: '0', classes: {root: inputRootClass}
        }}
        {...TextFieldProps} />
    </FormControl>
  )
})

AmountInput.propTypes = {
  jumbo: PropTypes.bool,
  value: PropTypes.any,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  locale: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  TextFieldProps: PropTypes.object, // For Additional Text Field Props
}

export default AmountInput
