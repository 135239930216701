import withStyles from '@mui/styles/withStyles'
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined'
import {flowRight} from 'lodash'
import {Box} from '@mui/material'

const styles = theme => ({
  logo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

function LogoDefaultForCompetition(props) {
  const {classes} = props
  return (
    <Box
      className={classes.logo}
      sx={{
        width: 50,
        height: 50,
        borderRadius: 1,
        bgcolor: 'error.light',
      }}
    >
      <EmojiEventsOutlinedIcon sx={{
        color: 'error.main',
        width: 32,
        height: 32,
      }}/>
    </Box>
  )
}

export default flowRight(
  withStyles(styles),
)(LogoDefaultForCompetition)
