import React from 'react'
import {every, get, flowRight as compose, omitBy} from 'lodash'
import {graphql} from 'react-apollo'
import {Trans, withNamespaces} from 'react-i18next'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Typography from '@mui/material/Typography'
import PageTitle from '../Common/PageTitle'
import messages from '../../assets/messages'
import config from '../../config'
import {SPOA_AUTHORIZATION_MUTATION} from '../../graphql/mutations'
import {SPOA_AUTHORIZATION_QUERY} from '../../graphql/queries'
import AppContext from '../../components/Common/contexts/AppContext'
import withStyles from '@mui/styles/withStyles'
import LoadingButton from '../Common/LoadingButton'
import {isMobile} from '../../common/utils'
import NotificationBar from '../Common/NotificationBar'
import Loading from '../Common/Loading'
import {spoaStatuses} from '@bdswiss/common-enums'

const styles = theme => ({
  error: {
    color: theme.palette.red.color
  },
  link: {
    color: theme.palette.primary.main,
  },
})

class SpoaAuthorization extends React.Component {
  static contextType = AppContext

  initialFormState = {
    isValid: false,
    fields: {
      remoteClientId: {
        value: '',
        isValid: 'false',
      },
      agree: {
        value: false,
        isValid: 'false',
      },
      acceptedSpoaBonusTerms: {
        value: false,
        optional: true
      }
    }
  }

  state = {
    form: this.initialFormState,
    loading: false,
    status: '',
  }

  validateForm() {
    const {form} = this.state
    const isValid = every(omitBy(form.fields, 'optional'), o => o.isValid === true)
    this.setState({
      form: {
        ...form,
        isValid,
      }
    })
    return isValid
  }

  handleChange(prop, value) {
    let fields = this.state.form.fields
    fields = {
      ...this.state.form.fields,
      [prop]: {
        ...this.state.form.fields[prop],
        value: value,
        isValid: (prop === 'remoteClientId') ? RegExp(/^\d{4,10}$/).test(value) : !!value
      }
    }

    this.setState({form: {fields}}, () => this.validateForm())
  }

  handleSubmit = () => {
    const {hasGrantedSpoa, spoaRemoteClient, mutate} = this.props
    const {form: {fields: {acceptedSpoaBonusTerms}}} = this.state
    const {locale} = this.context
    this.setState({loading: true})
    if (hasGrantedSpoa) { // revoke
      mutate({variables: {remoteClientId: spoaRemoteClient.id, isAuthorized: false}})
        .then(({data: {spoaAuthorize}}) => {
          const response = JSON.parse(spoaAuthorize)
          const spoaStatus = spoaStatuses[response.value]
          this.context.showNotification({
            type: 'document-upload',
            status: spoaStatus.value === 'successfulRevocation' ? 'success' : 'failure',
            content: get(spoaStatus, 'localization') ? spoaStatus.localization.t(locale) : get(spoaStatus, 'label'),
            buttonMessage: <Trans {...messages.continue} />,
            onClose: () => this.setState({status: ''}),
            buttonAction: this.setState({status: ''}),
          })
          this.setState({loading: false, status: 'success'})
        })
        .catch(console.log) // eslint-disable-line
        .finally(() => this.setState({form: this.initialFormState}))
    } else { // grant
      if (this.validateForm()) { // check form is valid
        const remoteClientId = this.state.form.fields.remoteClientId.value
        mutate({variables: {remoteClientId, isAuthorized: true, acceptedSpoaBonusTerms: acceptedSpoaBonusTerms.value}})
          .then(({data: {spoaAuthorize}}) => {
            const response = JSON.parse(spoaAuthorize)
            const spoaStatus = spoaStatuses[response.value]
            this.context.showNotification({
              type: 'document-upload',
              status: spoaStatus.value === 'successfulAuthorization' ? 'success' : 'failure',
              content: get(spoaStatus, 'localization') ? spoaStatus.localization.t(locale) : get(spoaStatus, 'label'),
              buttonMessage: <Trans {...messages.continue} />,
              onClose: () => this.setState({status: ''}),
              buttonAction: this.setState({status: ''}),
            })
            this.setState({loading: false, status: 'success'})
          })
          .catch(console.log) // eslint-disable-line
      }
    }
  }

  render() {
    const {history, hasGrantedSpoa, spoaRemoteClient, classes, loadingSpoa} = this.props
    const {form: {fields: {remoteClientId, agree, acceptedSpoaBonusTerms}, isValid}, status, loading, submitError} = this.state
    const {locale, eligibleBonus, companyObject, acceptedTermsSpoaBonus} = this.context

    if (loadingSpoa) return <Loading />

    return (
      <React.Fragment>
        <PageTitle
          withoutPadding={isMobile()}
          onBack={() => history.push('/settings/profile')}
        >
          <Trans {...messages.spoaTitle} />
        </PageTitle>
        <Grid container direction="column" sx={{p: {xs: 2, lg: 0}}}>
          {(!hasGrantedSpoa) ? <React.Fragment>
            <Grid item md={8} xs={12}>
              <NotificationBar
                status="info"
                title={<Trans {...messages.spoaNote} values={{company: get(companyObject, 'brandLabel')}}/>}
              />
            </Grid>
            <Grid item xs={8}>
              <FormControl variant="standard" error={!remoteClientId.isValid}>
                <TextField
                  variant="standard"
                  name="remoteClientId"
                  label={<Trans {...messages.ibClientId} />}
                  value={remoteClientId.value}
                  onChange={(e) => this.handleChange('remoteClientId', e.target.value)}
                  fullWidth
                  error={!remoteClientId.isValid} />
              </FormControl>
            </Grid>

            <Grid item md={8} xs={12}>
              <FormControl variant="standard">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="agree"
                      checked={agree.value}
                      onChange={(e) => this.handleChange('agree', e.target.checked)}
                      color="primary"
                      className={!agree.isValid ? classes.error : ''}
                    />}
                  label={<Typography variant="body1">
                    <Trans
                      {...messages.spoaAcceptedTerms}
                      components={[<a className={classes.link} href={config.weblinks.termsAndConditions.replace('{lang}', locale)} rel="noopener noreferrer" target="_blank"> </a>]} />
                  </Typography>}
                />
              </FormControl>
              {eligibleBonus && <FormControl variant="standard">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="acceptedSpoaBonusTerms"
                      checked={acceptedSpoaBonusTerms.value}
                      onChange={(e) => this.handleChange('acceptedSpoaBonusTerms', e.target.checked)}
                      color="primary"
                    />}
                  label={<Typography variant="body1"><Trans
                    {...messages.bonusAcceptedTerms}
                    components={[<a className={classes.link} href={config.weblinks.bonusTerms.replace('{lang}', locale)} rel="noopener noreferrer" target="_blank"> </a>]} />
                  </Typography>}
                />
              </FormControl>}
            </Grid>
          </React.Fragment>
            : <React.Fragment>
              <Grid item md={8} xs={12}>
                <Typography variant="body1">
                  <Trans {...messages.spoaAuthorization} values={{
                    id: spoaRemoteClient.id,
                    firstName: spoaRemoteClient.firstName,
                    lastName: spoaRemoteClient.lastName,
                  }} />
                </Typography>
              </Grid>
              {(isValid ? acceptedSpoaBonusTerms.value : acceptedTermsSpoaBonus) && <Grid item md={8} xs={12}>
                <Typography variant="body1">
                  <Trans {...messages.spoaAuthorizationText2} />
                </Typography>
              </Grid>}
            </React.Fragment>}

          <Grid item>
            <LoadingButton
              id='loadingButton'
              onClick={this.handleSubmit}
              fullWidth={isMobile()}
              disabled={!hasGrantedSpoa ? (!isValid || loading) : loading}
              status={status}
              hideProgressBar={!hasGrantedSpoa && !isValid}
            >{hasGrantedSpoa ? <Trans {...messages.revokeAuthorization} /> : <Trans {...messages.grantAuthorization} />}
            </LoadingButton>
            {status==='failure' &&
              <FormHelperText className={classes.error}>{submitError}</FormHelperText>}
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

export default compose(
  withStyles(styles),
  withNamespaces(),
  graphql(SPOA_AUTHORIZATION_MUTATION, {
    options: {
      refetchQueries: [{query: SPOA_AUTHORIZATION_QUERY}],
    }
  }),
  graphql(SPOA_AUTHORIZATION_QUERY, {
    props: ({data: {error, loading}, data}) => ({
      error,
      loadingSpoa: loading,
      spoaRemoteClient: get(data, 'viewer.spoaRemoteClient'),
      hasGrantedSpoa: Boolean(get(data, 'viewer.spoaRemoteId')),
    })
  }),
)(SpoaAuthorization)
