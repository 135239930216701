import React, {Component} from 'react'
import {graphql, withApollo} from 'react-apollo'
import {get, find, map, reject, values, isEqual, some, isEmpty, filter, keys, flowRight as compose, trim, omitBy,
  endsWith, floor, includes, omit, reduce} from 'lodash'
import {withNamespaces, Trans} from 'react-i18next'
import Grid from '@mui/material/Grid'
import Select from '@mui/material/Select'
import Hidden from '@mui/material/Hidden'
import Checkbox from '@mui/material/Checkbox'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import withStyles from '@mui/styles/withStyles'
import FormHelperText from '@mui/material/FormHelperText'
import FormControlLabel from '@mui/material/FormControlLabel'
import {withdrawalPaymentVendors, withdrawalPaymentFields, currencies, documentTypes} from '@bdswiss/common-enums'
import ConfirmWithdrawalModal from './ConfirmWithdrawalModal'
import WithdrawalFields from './WithdrawalFields'
import PageSubTitle from '../../Common/PageSubTitle'
import config from '../../../config'
import messages from '../../../assets/messages'
import {getPlatform, isMobile, putFile} from '../../../common/utils/browser'
import {getAccountLabel, getAccountSubtype, isAffiliatesAccount, isCentAccount, isIntroducingBrokerAccount, isTradesmarterAccount, isWalletAccount} from '../../../common/utils/accounts'
import {checkIbanMatchSwiftCountry, getFormattedAmount, extractCountryCode} from '../../../common/utils/general'
import AppContext from '../../Common/contexts/AppContext'
import {isValidIBAN, isValidBIC, electronicFormatIBAN} from 'ibantools'
import {hideWithdrawalWarningsCountries, withdrawalWarnings, withdrawalPSPMessagesFilter} from '../../../common/utils/uioptions'
import CloseIcon from '@mui/icons-material/Close'
import SelectDocument from '../../Settings/Documents/SelectDocument'
import {acceptedImageFormats, maxFileSize} from '../../../common/utils/variables'
import Images from '../../Common/Images'
import {checkFileMimeType, validateIsStringContainsEmoji} from '../../../common/utils/validations'
import {logEventCustomParams} from '../../../common/utils/firebaseEvents'
import {ADD_METADATA_TO_CLIENT_MUTATION, CREATE_OWN_DOCUMENT_MUTATION, SIGN_UPLOAD_URL_MUTATION} from '../../../graphql/mutations'
import {PROFILE_SETTINGS_QUERY} from '../../../graphql/queries'
import {Button} from '@mui/material'
import Promise from 'bluebird'
import moment from 'moment'
import {withMediaQuery} from '../../Common'
import classNames from 'classnames'

const styles = theme => ({
  error: {
    color: theme.palette.red.color
  },
  messageGrid: {
    marginTop: 30,
  },
  highlight: {
    cursor: 'pointer',
    color: theme.palette.primary.main
  },
  listItem: {
    marginTop: 10
  },
  paddingLeft:{
    paddingLeft:40
  },
  bolder:{
    fontWeight:400
  },
  noticeDiv: {
    backgroundColor: theme.palette.lightyellow.color,
    padding: 12,
    marginLeft: 20,
    position: 'relative'
  },
  displayInline: {
    display: 'inline'
  },
  close: {
    position: 'absolute',
    fontSize: 18,
    right: 5,
    top: 5,
    cursor: 'pointer',
    color: theme.palette.grey[500],
  },
  hidden: {
    display: 'none'
  },
  uploadDiv: {
    marginTop: 10,
  },
  uploadSection: {
    display: 'flex',
    'flex-direction': 'column',
    background: '#FAFAFA',
    'border-radius': '8px',
    marginBottom:10,
  },
  uploadSectionCaption: {
    paddingBottom: 10,
    fontWeight: 400
  },
  itemCenter: {
    textAlign: 'center',
    marginTop: 10,
  },
  termsAndConditionsWrap: {
    [theme.breakpoints.down('lg')]: {
      paddingLeft: theme.spacing(3),
    },
    '& .MuiGrid-item': {
      margin: `${theme.spacing(2)} 0 0`,
    },
  },
  withdrawalInformationUl: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 12,
    },
  },
  select: {
    marginBottom: theme.spacing(2),
  },
  formControlLabelVAligned: {
    display:'flex',
    alignItems:'flex-start',
  },
  checkboxControlVAligned: {
    paddingTop:'3px',
  },
})

const getMethodFields = (method, {country, paymentOption, account, currency, form}) => (
  values(withdrawalPaymentFields).filter((field) =>
    ((field.visible && field.visible({})) ||
        field.commonField ||
        (field.paymentVendors && field.paymentVendors.includes(method) && !field.visible)) &&
      field.key !== 'amount' &&
      ((field.hidden &&
        !field.hidden({
          country,
          paymentOption,
          paymentVendor: method,
          isPartner: isAffiliatesAccount(account) || isIntroducingBrokerAccount(account),
          currency,
          values: form
        })) || !field.hidden)
  )
)

class ChooseMethod extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    this.state = {
      method: '',
      methodFields: [],
      form: {},
      errors: {},
      confirmWithdrawalOpen: false,
      buttonLoading: false,
      showWarning: false,
      errorFiles: {},
      uploadLoading: false,
      status: '',
      ldAccept: false,
    }
    if (!isEmpty(get(props, 'availableMethod'))) {
      const method = get(props, 'availableMethod.vendor')
      const methodFields = getMethodFields(method, {
        country: get(props, 'viewer.country'),
        paymentOption: get(props.availableMethod, 'paymentOption'),
        account: props.account,
        form: {}
      })
      this.state.method = method
      this.state.methodFields = methodFields
      for (const field of methodFields) {
        this.state.form[field.key] = get(props, `availableMethod[${field.key}]`) || ''
      }
    }
  }

  async uploadDocument(docType, file, internalTag, fileDescription) {
    const {paymentMethodId} = this.state
    if (!file) return Promise.resolve()
    const {signUploadUrl, createOwnDocument, viewer: {clientId}} = this.props

    return signUploadUrl({variables: {clientId: clientId}}).then((res) => {
      const {key, signedUrl} = res.data.signedDetails
      return putFile(file, signedUrl).then(() =>
        createOwnDocument({variables: {type: docType, key, internalTag, paymentMethodId, fileDescription: fileDescription}})
      )
    })
  }

  onSubmit(documentsUpload) {
    const {onSubmit, amount, account, viewer: {ldAcknowledgementDate}} = this.props
    const {method, methodFields, form, ldAccept} = this.state

    this.setState({buttonLoading: true})

    const errors = {}
    for (const field of methodFields) {
      errors[field.key] = !form[field.key] && !(field.optional && field.optional === true)
      && !(field.hidden && field.hidden({currency: form.cryptoCurrency}) === true)
    }

    if (method === 'ldTransfer' && isEmpty(ldAcknowledgementDate) && ldAccept !== 'accepted') {
      errors.ldAccept = true
    }
    if (some(errors)) {
      this.setState({errors, buttonLoading: false})
      return
    }
    this.setState({status: ''})
    const variables = {
      amount,
      paymentVendor: method,
      paymentFields: omitBy(form, (_, k) => endsWith(k, '_label')),
      accountId: account.id,
      platform: JSON.stringify(getPlatform()),
      rate: get(this.state.dynamicFields, 'rates.rate') ,
    }
    if (get(withdrawalPaymentVendors[method], 'documentsUpload')) {
      Promise.map(documentsUpload, async (file, key) => {
        const documentType = Object.keys(file)
        await this.uploadDocument(documentType[0], Object.values(file)[0], documentTypes.other.key, documentType[0]).then((res) => {
        }).catch(() => {})
      }).then((res) => {
        onSubmit(variables)
        this.setState({buttonLoading: false, status: 'success'})
      }).catch((e) => {
        this.setState({status: 'failure', buttonLoading: false})
      })
    } else {
      onSubmit(variables)
      this.setState({buttonLoading: false})
    }
    if (method === 'ldTransfer' && isEmpty(ldAcknowledgementDate)) {
      this.props.addMetadataToClient({variables: {metadata: JSON.stringify({ldAcknowledgementDate: moment()})}})
    }
  }

  handleChangeLd(prop, value) {
    this.setState(state => ({
      [prop]: value,
    }))
  }

  clickWithdrawal() {
    const {method, methodFields, form, errorFiles} = this.state
    const {viewer} = this.props
    if (isEmpty(get(viewer, 'ldAcknowledgementDate')) && method === 'ldTransfer') {
      this.setState({showLdAcknowledgement: true})
    }
    const errors = {}
    form.iban = form.iban && electronicFormatIBAN(form.iban).toUpperCase()
    form.swiftCode = form.swiftCode && trim(form.swiftCode).toUpperCase()
    const isIBANValid = form.iban && isValidIBAN(form.iban)
    const isBICValid = form.swiftCode && isValidBIC(form.swiftCode)
    const isValidRoutingNumber = /^[0-9]{1,12}$/.test(form.routingNumber)
    for (const field of methodFields) {
      errors[field.key] = !form[field.key] && !(field.optional && field.optional === true)
      && !(field.hidden && field.hidden({currency: form.cryptoCurrency, country: get(this.props, 'viewer.country'), paymentVendor: method}) === true)

      if (field.key === 'iban' && !isEmpty(form[field.key])) {
        errors[field.key] = !isIBANValid && 'validation'
        if (errors[field.key] && (!isNaN(form[field.key]) && form[field.key].length >= 6 && form[field.key].length <= 20)) {
          errors[field.key] = false
        }
      }

      if (field.key === 'swiftCode' && isIBANValid && !isEmpty(form[field.key])) {
        errors[field.key] = !isBICValid  && 'validation'
      }
      if (field.key === withdrawalPaymentFields.routingNumber.key && !isValidRoutingNumber) {
        errors[field.key] = 'validation'
      }
      if (validateIsStringContainsEmoji(form[field.key])) {
        errors[field.key] = 'validation'
      }
    }

    errors.ibanSwiftNotCoherent = form.iban && form.swiftCode && !errors.iban && !errors.swiftCode &&
      isIBANValid && checkIbanMatchSwiftCountry(form.iban, form.swiftCode)

    if (get(withdrawalPaymentVendors[method], 'documentsUpload')) {
      map(get(withdrawalPaymentVendors[method], 'documentsUpload'), (field) => errors[field] = !this.state[field])
    }
    if (some(errors)) {
      this.setState({errors})
      return
    }
    if (get(withdrawalPaymentVendors[method], 'documentsUpload') && some(errorFiles)) {
      this.setState({errorFiles})
      return
    }
    this.setState({confirmWithdrawalOpen: true})
  }

  onMethodChange(method) {
    const {method: currentMethod, form} = this.state
    const methodFields = getMethodFields(method, {
      country: get(this.props, 'viewer.country'),
      account: this.props.account,
      form
    })

    if (!isEqual(method, currentMethod)) {
      this.setState({
        method,
        methodFields,
        form: {},
        errors: {},
      })
    }
  }

  handleChange(prop, value, label) {
    const {form, errors, method, methodFields: currentMethodFields} = this.state
    const currentField = find(currentMethodFields, {key: prop})
    const country = [
      withdrawalPaymentFields.iban.key,
      withdrawalPaymentFields.swiftCode.key
    ].includes(prop) ? extractCountryCode(trim(value)) : get(this.props, 'viewer.country')
    const currency = prop === 'cryptoCurrency' ? value : ''
    const newForm = {
      ...form,
      [prop]: value,
    }
    const update = {
      form: newForm,
      errors: {
        ...errors,
        [prop]: !value && !(currentField.optional && currentField.optional === true)
        && !(currentField.hidden && currentField.hidden({currency} === true)),
        ibanSwiftNotCoherent: false
      },
      methodFields: ['paymentOption', 'cryptoCurrency', 'bankAccountType', 'nuveiUpo', 'bankTransferDetails'].includes(prop)
        ? getMethodFields(method, {
          country,
          paymentOption: value,
          account: this.props.account,
          currency,
          form: newForm,
        })
        : currentMethodFields,
    }

    if (label) {
      update.form[`${prop}_label`] = label
    }

    this.setState(update)
  }

  renderTnCandSubmit(termsAndConditionsField) {
    const {classes, account, widthDown} = this.props
    const {method, methodFields, form, errors} = this.state
    const isCryptoVendor = map(filter(withdrawalPaymentVendors, {walletAcknowledgement: true}), 'key')

    const {locale} = this.context
    const termsLink = isTradesmarterAccount(account) ? config.weblinks.binaryTerms :config.weblinks.termsAndConditions.replace('{lang}', locale)

    const notFilledRequiredFields = reduce(methodFields, (acc, field) => {
      if (!field.optional && !get(form, field.key)) {
        acc = {
          ...acc,
          [field.key]: form[field.key]
        }
      }

      return acc
    }, {})

    return <Grid container className={classes.termsAndConditionsWrap}>
      {termsAndConditionsField &&
        <Grid item xs={12}>
          <FormControlLabel
            className={classes.formControlLabelVAligned}
            control={
              <Checkbox
                checked={!!get(form, termsAndConditionsField.key, '')}
                onChange={(e) => this.handleChange(termsAndConditionsField.key, e.target.checked ? 'accept' : '')}
                value={get(form, termsAndConditionsField.key, '')}
                className={classNames(get(errors, termsAndConditionsField.key, false) ? classes.error : '', classes.checkboxControlVAligned)}
              />
            }
            label={isCryptoVendor.includes(method)
              ? <Trans
                {...messages.cryptoWithdrawalTermsAndConditions}
                components={[
                  <a target="_blank" rel="noopener noreferrer" href={termsLink} className={classes.highlight}>termsLink</a>
                ]}
              />
              :
              <Trans
                {...messages.withdrawalTermsAndConditions}
                components={[
                  <a target="_blank" rel="noopener noreferrer" href={termsLink} className={classes.highlight}>termsLink</a>
                ]}
              />
            }
          />
        </Grid>}
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => this.clickWithdrawal()}
          disabled={isEmpty(method) || !isEmpty(notFilledRequiredFields)}
          fullWidth={widthDown}
        >
          <Trans {...messages.withdraw} />
        </Button>
      </Grid>
    </Grid>
  }

  renderExtraFields() {
    const {account, amount} = this.props

    switch (this.state.method) {
      case withdrawalPaymentVendors.thunderxpay.key: {
        const rate = get(this.state.dynamicFields, 'rates.rate')
        const rateInUSD = get(this.state.dynamicFields, 'rateInUSD')
        const thbAmount = isCentAccount(account)
          ? amount / currencies.CUD.baseCurrencyRate * rate
          : amount * rateInUSD * rate

        return rate && <Grid item xs={12}>
          <TextField
            variant="standard"
            label={<Trans {...messages.amountInCurrency} values={{currency: 'THB'}}/>}
            value={floor(thbAmount, 2)}
            fullWidth
            disabled />
        </Grid>
      }
      default:
        return
    }
  }

  handleFileUpload(prop, e) {
    this.setState({[prop]: e.target.files[0], uploadStatus: ''})
    const {errorFiles} = this.state
    map(e.target.files,(file) => {
      try {
        checkFileMimeType(file).then( res => {
          this.setState({fileChecked: true})
          const fileFormat = res && res.indexOf('image') === -1
          const fileSize = file.size > maxFileSize * 1024 * 1024
          if (fileFormat || fileSize) {
            logEventCustomParams('documentUploadError', {
              reason: 'wrong fileFormat or fileSize',
              userId: get(this.context, 'clientId'),
            })
            this.setState(prevState => ({
              errorFiles: {
                ...prevState.errorFiles,
                [prop]: {
                  name: file.name,
                  error: fileFormat ? 'format' : 'size'
                }
              }
            }))
          }
          else {
            const errors = omit(errorFiles,[prop])
            this.setState(prevState => ({
              errorFiles:errors,
              errors: {
                ...prevState.errors,
                [prop]: false,
              }
            }))
          }
        })
        e.target.value = null //reset input value to force reupload action in failure
      } catch (e) {
        console.log(e) /* eslint-disable-line */
      }
    })
  }

  render() {
    const {classes, account, amount, viewer: {locale, country, company, ldAcknowledgementDate}, goBack, availableMethod, buttonStatus, clickButtonEdit, t, submitLoading} = this.props
    const {method, methodFields, form, errors, confirmWithdrawalOpen, buttonLoading, showWarning, dynamicFields,
      errorFiles, ldAccept} = this.state
    const {companyObject, clientType} = this.context
    const {featuresConfig: {showWithdrawalWarning}, transactionsConfig} = config
    const {availableWithdrawalOptions} = account
    const accountLabel = getAccountLabel(account, locale, getAccountSubtype(get(account,'accountSubtype')), t)
    const formattedAmount = isCentAccount(account)
      ? getFormattedAmount({amount: amount / currencies.CUD.baseCurrencyRate, currency: currencies.CUD.baseCurrency, locale})
      : getFormattedAmount({amount, currency: account.currency, locale})
    const availableMethods = reject(withdrawalPaymentVendors,
      (v) => (v.disabled && v.disabled({company, country, clientType})) || v.key === withdrawalPaymentVendors.goldDelivery.key ||
        !availableWithdrawalOptions.includes(v.key)
    )
    const termsAndConditionsField = find(withdrawalPaymentFields, {key: 'termsAndConditions'})

    const groupName = get(withdrawalPaymentVendors[method], 'groupName', 'other')
    let footnote
    if (get(withdrawalPaymentVendors[method], 'hasFootnote', false)) {
      footnote = messages[`${method}WithdrawalFootnote`]
    }
    const methodMessages = groupName || method ? filter(keys(messages),
      (m) => (groupName && m.indexOf(`${groupName}WithdrawalDescription`) === 0)
      || (method && m.indexOf(`${method}WithdrawalDescription`) === 0
      && (!includes(get(withdrawalPSPMessagesFilter[method], 'content'), m)
      || (includes(get(withdrawalPSPMessagesFilter[method], 'content'), m)
      && includes(get(withdrawalPSPMessagesFilter[method], 'regulators'), companyObject?.regulator)))
      )).reduce((m, i) => {
      m[i] = messages[i]
      return m
    }, {}): {}

    let refineMessages
    if (get(hideWithdrawalWarningsCountries[country], 'excluded')) {
      refineMessages = filter(methodMessages, (m)=> !includes(get(hideWithdrawalWarningsCountries[country], 'excluded'), get(m, 'i18nKey')))
    } else {
      refineMessages = methodMessages
    }
    const hasWarning = showWithdrawalWarning && showWarning && get(withdrawalWarnings, method)

    const formatsAccepted = acceptedImageFormats.split(', ')
    const documentsUpload = map(get(withdrawalPaymentVendors[method], 'documentsUpload'), (a)=> ({[a]: get(this.state, a)}))
    const walletAccount = isWalletAccount(account.__typename)
    const skipAttention = isEmpty(methodMessages) && !walletAccount

    return (
      <Grid container spacing={isMobile() ? 2 : 3}>
        <Grid item xs={12}>
          <PageSubTitle mb={0}>
            <Trans
              {...messages.withdrawalMessage}
              values={{amount: formattedAmount, accountLabel}}
              components={[
                <span onClick={() => goBack()} className={classes.highlight}>amount</span>,
                <span onClick={() => goBack()} className={classes.highlight}>account</span>
              ]}
            />
          </PageSubTitle>
        </Grid>
        <Grid item xs={12} sm={8} lg={6}>
          <Grid container direction="column">
            {this.renderExtraFields()}
            <Grid item xs={12}>
              <FormControl variant="standard" fullWidth>
                <InputLabel htmlFor="select-method"><Trans {...messages.withdrawalMethod} /></InputLabel>
                <Select
                  variant="standard"
                  value={method}
                  onChange={(e) => this.onMethodChange(e.target.value)}
                  error={get(errors, 'method', false)}
                  inputProps={{
                    name: 'method',
                    id: 'select-method',
                  }}
                  classes={{root: classes.select}}
                  disabled={!isEmpty(availableMethod)}>
                  {map(availableMethods, (m) => (
                    <MenuItem
                      value={m.key}
                      key={m.key}
                    >
                      {m.localization && m.localization.t(locale)}
                    </MenuItem>
                  ))}
                </Select>
                {get(errors, 'method', false) &&
                  <FormHelperText className={classes.error}><Trans {...messages.requiredField} /></FormHelperText>
                }
                {footnote && <FormHelperText><Trans {...footnote} /></FormHelperText>}
              </FormControl>
            </Grid>
            <WithdrawalFields
              country={country}
              account={account}
              errors={errors}
              form={form}
              availableMethod={availableMethod}
              methodFields={methodFields}
              method={method}
              currency={account.currency}
              handleChange={this.handleChange.bind(this)}
              setDynamicFields={(dynamicFields) => {
                if (!this.state.dynamicFields) {
                  this.setState({dynamicFields})
                }
              }}
            />
            {get(withdrawalPaymentVendors[method], 'documentsUpload') && <Grid item xs={12}>
              <Grid container className={classes.uploadDiv}>
                {map(get(withdrawalPaymentVendors[method],'documentsUpload'), (field, index) => <Grid item xs={12} key={field} md={12} className={classes.uploadSection}>
                  <Typography variant="body1" className={classes.uploadSectionCaption}>
                    <Trans {...messages[`${field}Title`]} />
                  </Typography>
                  <Typography variant="caption" className={classes.uploadSectionCaption}>
                    <Trans {...messages[`${field}Caption`]} />
                  </Typography>
                  <SelectDocument
                    uploadedFile={this.state[field]}
                    onChange={(e) => this.handleFileUpload(field, e)}
                    fileKey={index+1}
                    category={documentTypes.other.value}
                    status={(!this.state[field] && !errors[field]) ?  '' : (get(errors, field) || includes(keys(errorFiles), field) ? 'error' : 'success')}
                    errors={!isEmpty(errorFiles) && errorFiles[field]}
                    selectedDocTypeKey={documentTypes.other.value}
                    src={Images['upload-generic.png']}
                    srcSuccess={Images['upload-generic-success.png']}
                    srcError={Images['upload-generic-error.png']}
                  />
                  <Typography variant="caption" className={classes.itemCenter}>
                    <Trans {...messages.acceptedFormats} values={{acceptedFormats: acceptedImageFormats.replace(/(.*),.*/, '$1'), lastAccepted: formatsAccepted[formatsAccepted.length-1]}}/>&nbsp;
                    <Trans {...messages.maxFileSizeText} values={{maxSize: maxFileSize}}/>
                  </Typography>
                </Grid>)}
              </Grid>
            </Grid>}
            {/* <Grid item xs={12}>
              <Typography variant="caption">
                <Trans {...messages.ldClientAcknowledgmentAcceptance}  values={{ldAcknowledgementDate: moment(ldAcknowledgementDate).format('lll')}}/>
              </Typography>
            </Grid> */}
            {!isMobile() && this.renderTnCandSubmit(termsAndConditionsField)}
          </Grid>
        </Grid>
        <Grid item sm={6} xs={12}>
          {hasWarning && showWarning && <Grid container spacing={3} className={classes.noticeDiv}>
            <CloseIcon className={classes.close} onClick={()=> this.setState({showWarning: false})}/>
            <Grid item xs={12}>
              <Typography variant='h4' className={classes.displayInline}>
                <Trans {...messages[get(hasWarning, 'title')]} />
              </Typography>
            </Grid>
            {map(get(hasWarning, 'content'), (content, index) => <Grid item xs={12} key={index}>
              <Typography variant='body1'>
                <Trans {...messages[content]} />
              </Typography>
            </Grid>)}
          </Grid>}
          <Hidden mdDown>
            {!skipAttention && <Grid item sm={12} className={classes.paddingLeft}>
              <PageSubTitle>
                <Trans {...messages.attention} />
              </PageSubTitle>
            </Grid>}
          </Hidden>
          <Grid container direction="column" spacing={1}>
            <Grid item xs={12} sm={11}>
              <ul key="information" className={classes.withdrawalInformationUl}>
                {walletAccount ?
                  <li className={classes.listItem}>
                    <Typography variant='body1'>
                      <Trans {...messages.generalWithdrawalDescriptionPartner} />
                    </Typography>
                  </li>
                  : <React.Fragment>
                    {map((refineMessages), (m) => <li key={m.i18nKey} className={classes.listItem}>
                      <Typography variant='body1'>
                        <Trans
                          {...m}
                          values={{
                            company: companyObject.brandLabel,
                            hours: get(transactionsConfig, `withdrawal.${method}.waitingHours`) || '48',
                          }}
                          components={[
                            <span className={classes.bolder}>highlight</span>,
                            <span className={get(transactionsConfig, `withdrawal.${method}.notShowSepaMsg`) ? classes.hidden : ''}>message</span>,
                          ]}
                        />
                      </Typography>
                    </li>)}
                  </React.Fragment>}
              </ul>
            </Grid>
          </Grid>
        </Grid>
        {isMobile() && this.renderTnCandSubmit(termsAndConditionsField)}
        <ConfirmWithdrawalModal
          open={confirmWithdrawalOpen}
          locale={locale}
          methodFields={methodFields}
          form={form}
          onSubmit={() => this.onSubmit(documentsUpload)}
          onCancel={() => {
            this.setState({confirmWithdrawalOpen: false})
            clickButtonEdit()
          }}
          method={method}
          formattedAmount={formattedAmount}
          amount={amount}
          accountLabel={accountLabel}
          buttonLoading={buttonLoading || submitLoading}
          country={country}
          availableMethod={availableMethod}
          dynamicFields={dynamicFields}
          account={account}
          buttonStatus={buttonStatus}
          uploadDocuments={documentsUpload}
          ldAcknowledgementDate={ldAcknowledgementDate}
          ldAccept={ldAccept}
          handleChangeLd={(prop, value) => this.handleChangeLd(prop, value)}
          errors={errors}
        />
      </Grid>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withMediaQuery('md'),
  withNamespaces(),
  withApollo,
  graphql(SIGN_UPLOAD_URL_MUTATION, {
    name: 'signUploadUrl',
  }),
  graphql(CREATE_OWN_DOCUMENT_MUTATION, {
    name: 'createOwnDocument',
    options: {
      refetchQueries: [{query: PROFILE_SETTINGS_QUERY}],
    }
  }),
  graphql(ADD_METADATA_TO_CLIENT_MUTATION, {
    name: 'addMetadataToClient',
  }),
)(ChooseMethod)
