import React from 'react'
import {Redirect, Route, Switch, withRouter} from 'react-router-dom'
import Privacy from './Privacy'
import ChangePassword from './ChangePassword'
import ProfileRoutes from './Profile/routes'

const SettingRoutes = ({match: {path}}) => (
  <Switch>
    <Route path={`${path}/profile`} component={ProfileRoutes} />
    <Route exact path={`${path}/privacy`} component={Privacy} />
    <Route exact path={`${path}/change-password`} component={ChangePassword} />
    <Route path={`${path}/:parentPath/childPath+`} render={({match}) => <Redirect to={`${path}/${match.params.parentPath}`} />} />
    <Route path={`${path}`} component={ProfileRoutes} />
  </Switch>
)

export default withRouter(SettingRoutes)
