import React, {Component} from 'react'
import Grid from '@mui/material/Grid'
import Input from '@mui/material/Input'
import PersonIcon from '@mui/icons-material/Person'
import {withNamespaces, Trans} from 'react-i18next'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@mui/material/InputAdornment'
import withStyles from '@mui/styles/withStyles'
import withTheme from '@mui/styles/withTheme'
import config from '../../../../config'
import messages from '../../../../assets/messages'
import Images from '../../../../components/Common/Images'
import {PaymentActionButton, BillingAddressDetails} from './helpers'
import {flowRight as compose, get} from 'lodash'
import {getSourceBasedOnLocationPrevPath, logEventCustomParams} from '../../../../common/utils'

const style = (theme) => ({
  top: {
    paddingTop: 25,
  },
  personIcon: {
    color: '#ccd0d8',
    marginLeft: 7,
  },
})

class CheckoutProvider extends Component {

  state = {loading: false, isCardValid: false}

  constructor(props) {
    super(props)
    this.cardValidationChanged = this.cardValidationChanged.bind(this)
    this.frameValidationChanged = this.frameValidationChanged.bind(this)
  }

  componentDidMount() {
    const {cardValidationChanged, frameValidationChanged, props: {doCreateDeposit, theme}} = this

    window.Frames.init({
      publicKey: config.checkout.publicKey,
      cardValidationChanged,
      frameValidationChanged,
      cardSubmitted: () => {
      },
      cardTokenized: (data) => {
        doCreateDeposit(data.token)
      },
      cardTokenizationFailed: (event) => {
        // eslint-disable-next-line no-console
        console.log('cardTokenizationFailed', event)
        // catch the error
      },
      localization: {
        cardNumberPlaceholder: '',
        expiryMonthPlaceholder: '',
        expiryYearPlaceholder: '',
        cvvPlaceholder: '',
      },
      style: {
        base: {
          font: '16px/1.4 Roboto, Oxygen, Ubuntu, Cantarell, sans-serif',
          boxSizing: 'border-box',
          color: theme.palette.secondary.dark,
        },
      },
    })
  }

  cardValidationChanged(event) {
    this.setState({isCardValid: event.isValid})
  }

  frameValidationChanged() {
    this.setState({isCardValid: window.Frames.isCardValid()})
  }

  render() {
    const {classes, creatingDeposit, errors, form, handleChange, selectedCardTokenId, formIsValid, canProceed} = this.props
    const {loading, isCardValid} = this.state

    return (
      <Grid container justifyContent="center" spacing={4} className={classes.top}>
        <Grid item xs={12} md={10}>
          <Typography variant="caption"><Trans {...messages.cardNumber} /></Typography>
          <div className="input-container card-number">
            <div className="icon-container">
              <img id="icon-card-number" src={Images['deposit-card.svg']} alt="Card Number" />
            </div>
            <div className="card-number-frame" />
          </div>
        </Grid>
        <Grid item xs={12} md={10}>
          <FormControl variant="standard" className={classes.formControl} fullWidth>
            <Typography variant="caption"><Trans {...messages.fullName} /></Typography>
            <Input
              id="fullName"
              type="text"
              name="name"
              error={!!errors.name}
              onChange={(e) => handleChange(e)}
              inputProps={{autoComplete: 'off'}}
              startAdornment={
                <InputAdornment position="start" >
                  <PersonIcon className={classes.personIcon} />
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={10}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={6}>
              <Typography variant="caption"><Trans {...messages.expiryDate} /></Typography>
              <div className="input-container expiry-date">
                <div className="icon-container">
                  <img id="icon-expiry-date" src={Images['exp-date.svg']} alt="Expiry date" />
                </div>
                <div className="expiry-date-frame" />
              </div>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="caption"><Trans {...messages.cvv} /></Typography>
              <div className="input-container cvv">
                <div className="icon-container">
                  <img id="icon-cvv" src={Images['cvv.svg']} alt="CVV" />
                </div>
                <div className="cvv-frame" />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={10}>
          <Grid container justifyContent="center" spacing={2}>
            <BillingAddressDetails
              errors={errors}
              form={form}
              handleChange={(e) => handleChange(e)}
            />
          </Grid>
        </Grid>
        <PaymentActionButton
          loading={loading || creatingDeposit}
          disable={canProceed || !isCardValid}
          onClick={() => {
            const {account, provider, amount, history: {location}} = this.props
            if (!formIsValid() && !selectedCardTokenId) {
              return
            }
            this.setState({loading: true})

            const firebaseParams = {
              source: getSourceBasedOnLocationPrevPath(get(location, 'state', {prevPath: get(location, 'pathname')})),
              login: account.id,
              currency: account.currency,
              vendor: provider,
              amount,
            }
            logEventCustomParams('depositAttempted', firebaseParams)

            if (!selectedCardTokenId) {
              window.Frames.cardholder = {
                name: form.name,
                billingAddress: {
                  addressLine1: form.street,
                  addressLine2: '',
                  zip: form.zip,
                  city: form.city,
                  state: '',
                  country: form.country,
                },
              }
            }
            window.Frames.submitCard()
          }}
        />
      </Grid>
    )
  }
}

export default compose(
  withNamespaces(),
  withStyles(style),
)(withTheme(CheckoutProvider))
