import React, {Component} from 'react'
import {get} from 'lodash'

import {IconButton} from '@mui/material'

import AppContext from '../Common/contexts/AppContext'
import DownloadMobileAppModal from './DownloadMobileAppModal'
import {ReactComponent as QRCodeIcon} from '../../assets/images/qr-сode-icon.svg'
import {isAndroid, isIos, isMobile, logEventCustomParams} from '../../common/utils'

class DownloadMobileApp extends Component {
  static contextType = AppContext

  handleClickDownloadMobileAppBtn = (event) => {
    const {onToggleModalState} = this.props
    const {companyObject} = this.context

    const firebaseParams = {
      type: isMobile() ? 'links' : 'QR',
      brand: companyObject.brandLabel,
      source: isIos() ? 'iOS' : isAndroid() ? 'Android' : 'Desktop',
      userId: get(this.context, 'clientId'),
    }

    logEventCustomParams('onQRCodeButtonClicked', firebaseParams)

    onToggleModalState('anchorDownloadMobileAppBtn', event)

    logEventCustomParams('onQRCodePopupPresented', firebaseParams)
  }

  render() {
    const {anchorEl, onToggleModalState} = this.props
    return (
      <>
        <IconButton edge="start" color='secondary' onMouseOver={(event) => this.handleClickDownloadMobileAppBtn(event)}>
          <QRCodeIcon />
        </IconButton>

        <DownloadMobileAppModal
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => onToggleModalState('anchorDownloadMobileAppBtn', null)}
        />
      </>
    )
  }
}

export default DownloadMobileApp
