import PropTypes from 'prop-types'
import {flowRight as compose, filter, get, map} from 'lodash'
import React, {Component} from 'react'
import {depositVendors, frontends} from '@bdswiss/common-enums'
import {getPlatform, getSourceBasedOnLocationPrevPath, logEventCustomParams} from '../../../../common/utils'
import {withCreateDeposit, PaymentActionButton} from './helpers'
import DescriptionPaymentMethod from './DescriptionPaymentMethod'
import {Checkbox, FormControlLabel, Grid, Typography} from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import messages from '../../../../assets/messages'
import {Trans} from 'react-i18next'

const style = (theme) => ({
  walletOwnerMessage: {
    margin: '24px 0 0 0',
  },
  formControlLabelVAligned: {
    display:'flex',
    alignItems:'flex-start',
    margin: '0 0 0 0',
  },
  checkboxControlVAligned: {
    paddingTop:'24px',
  },
})

class GenericRedirectProvider extends Component {

  constructor(props) {
    super(props)
    this.state = {
      rightfulWalletAddressOwner: false,
      gettingUrl: false,
    }
  }

  getUrl() {
    const {providerProperties: {provider, paymentKey, id: paymentOptionId}, account, amount, onError, useVirtualPaymentPage, bonusAmount, bonusTerms,
      history: {location}} = this.props

    this.setState({gettingUrl: true})

    const firebaseParams = {
      source: getSourceBasedOnLocationPrevPath(get(location, 'state', {prevPath: get(location, 'pathname')})),
      login: account.id,
      currency: account.currency,
      vendor: provider,
      amount,
    }
    logEventCustomParams('depositAttempted', firebaseParams)

    const variables = {
      accountId: account.id,
      amount,
      vendor: provider,
      paymentOptionId,
      frontend: frontends.web2.value,
      args: JSON.stringify({
        platform: getPlatform(),
        paymentKey,
        useVirtualPaymentPage,
        bonusAmount,
        bonusTerms,
      }),
    }

    this.props.createDepositRequest({variables})
      .then(({data: {newDeposit}}) => {
        const {payment: {url}} = newDeposit
        window.location = url
      })
      .catch((e) => {
        this.setState({gettingUrl: false})
        onError && onError(e)
      })
  }

  render() {
    const {providerProperties: {provider}, canProceed, classes} = this.props
    const {gettingUrl,rightfulWalletAddressOwner} = this.state
    const isCryptoVendor = map(filter(depositVendors, {walletAcknowledgement: true}), 'key')
    return (
      <React.Fragment>
        <DescriptionPaymentMethod provider={provider}/>
        {isCryptoVendor.includes(provider) && <Grid item xs={12}>
          <FormControlLabel
            className={classes.formControlLabelVAligned}
            control={
              <Checkbox
                className={classes.checkboxControlVAligned}
                name="rightfulWalletAddressOwner"
                checked = {rightfulWalletAddressOwner}
                onChange={(e) => this.setState({rightfulWalletAddressOwner: e.target.checked})}
              />
            }
            label= {<Typography variant="subtitle2" className={classes.walletOwnerMessage}><Trans {...messages.cryptoCheckbox} /></Typography>}
          />
        </Grid>}
        <PaymentActionButton
          disable={canProceed || (!rightfulWalletAddressOwner && isCryptoVendor.includes(provider))}
          loading={gettingUrl}
          onClick={() =>  this.getUrl()}
        />
      </React.Fragment>
    )
  }
}

GenericRedirectProvider.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  amount: PropTypes.number.isRequired,
  providerProperties: PropTypes.shape({
    name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    paymentKey: PropTypes.string,
    paymentOptionId: PropTypes.number.isRequired,
  }).isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

export default compose(
  withStyles(style),
  withCreateDeposit
)(GenericRedirectProvider)
