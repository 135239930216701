import React from 'react'
import {withRouter} from 'react-router-dom'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import withStyles from '@mui/styles/withStyles'
import {isMobile} from '../../common/utils/browser'
import classNames from 'classnames'
import {get} from 'lodash'
import Images from './Images'

const styles = (theme) => ({
  header: {
    paddingLeft: 0,
    paddingRight: 0,
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'center',
    minHeight: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      paddingTop: 40
    }
  },
  mobileHeader: {
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.15px',
    }
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 0,
    marginRight: 0,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
    }
  },
  mobileTitleOnly: {
    marginLeft: 16,
    height: 60,
    display: 'flex',
    alignItems: 'center',
  },
  appBar: {
    minWidth: '100%',
    zIndex: 1,
  },
  backButton: {
    padding: '0px',
    [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      margin: `14px ${theme.spacing(2)} 0px`,
    },
    [theme.breakpoints.down('sm')]: {
      margin: `10px ${theme.spacing(2)} 0px`,
    },
  },
  childrenContainer: {
    padding: '0 !important',
  },
  removeAppBarShadow:{
    boxShadow: 'none'
  },
  modalTitle:{
    flexGrow: 0,
    maxWidth: '100%',
    flexBasis: '100%'
  },
  container: {
    marginBottom: 40,
    flexWrap: 'initial',
    [theme.breakpoints.down('lg')]: {
      marginBottom: 0,
      [theme.direction === 'rtl' ? 'marginRight': 'marginLeft']: (props) => !props.withoutPadding && theme.spacing(2),
    },
  },
  pageTitleOnly: {
    display: 'flex',
    alignItems: 'center',
  },
})

const PageTitle = props => {
  const mobile = isMobile()
  const onClick = props.onBack || props.history.goBack
  const titleStyle = mobile && (!props.hideArrow ? '' : props.classes.mobileTitleOnly)
  const justifyProp = mobile &&  props.justifyProp

  return (<React.Fragment>
    {(mobile || props.title) && <Grid container className={((!props.modal && !props.withoutPadding) ?
      props.classes.appBar : '')} justifyContent={justifyProp || 'space-between'} alignContent="center">
      <Grid item className={(props.uinotification ? props.classes.modalTitle : '')}>
        <Grid container classes={{container: props.classes.container}}>
          {!props.modal && !props.hideArrow && <Grid item>
            <IconButton
              aria-label="back"
              onClick={() => onClick()}
              className={props.classes.backButton}
            >
              <img src={mobile
                ? Images['arrow-left.svg']
                : Images[`back-button-${props.themePreference}.svg`]}
              alt='back-button' />
            </IconButton>
          </Grid>}
          <Grid item className={(props.uinotification ? props.classes.modalTitle : (titleStyle || ''), !props.subtitle ? props.classes.pageTitleOnly : '')}>
            <Typography component='h1' variant="h1" className={classNames(mobile ?
              props.classes.mobileHeader : props.classes.header, get(props, 'customClasses.header'))}>
              {props.title || props.children}
            </Typography>
            {props.subtitle && <Typography variant='caption'>{props.subtitle}</Typography>}
          </Grid>
        </Grid>
      </Grid>
      {props.rightAction && <Grid item className={props.classes.action}>
        {props.rightAction}
      </Grid>}
      {props.title && <Grid item xs={12} classes={{item: props.classes.childrenContainer}}>
        {props.children}
      </Grid>
      }
    </Grid>
    }
    {(!mobile && !props.title) &&  <Typography component='h1' variant="h1" gutterBottom className={classNames((mobile ?
      props.classes.mobileHeader : props.classes.header), get(props, 'customClasses.header'))}>
      {props.children}
    </Typography>}
  </React.Fragment>)
}

PageTitle.propTypes = {
  children: PropTypes.any,
  classes: PropTypes.object.isRequired,
  // rightAction: PropTypes.component,
  title: PropTypes.string,
}

export default withStyles(styles)(withRouter(PageTitle))
