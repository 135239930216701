import React from 'react'
import {Route, Switch, withRouter, Redirect} from 'react-router-dom'
import Overview from './Overview'

const MamRoutes = ({match: {path}}) => (
  <Switch>
    <Redirect exact from={`${path}/transactions`} to="/transactions/history"/>
    <Route path={`${path}/:parentPath/:childPath+`} render={({match}) => <Redirect to={`${path}/${match.params.parentPath}`} />} />
    <Route path={`${path}`} component={Overview} />
  </Switch>
)

export default withRouter(MamRoutes)
