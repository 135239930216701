import MobileDetect from 'mobile-detect'
import platform from 'platform'
import {capitalize, fill, get, truncate} from 'lodash'
import meta from 'moment-timezone/data/meta/latest.json'

export function getPlatform() {
  const md = new MobileDetect(window.navigator.userAgent)
  const isMobile = md.mobile()

  return {
    platform: 'web',
    platformType: isMobile ? 'mobile' : 'desktop',
    operatingSystem: `${platform.os.family}/${platform.os.architecture}`,
    device: platform.product,
    browser: platform.name,
  }
}

export function isTablet() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera
  const platform = navigator.platform

  const isIOS = /iPad|iPhone|iPod/.test(userAgent) || (platform === 'MacIntel' && 'ontouchend' in document)
  const isAndroid = /Android/.test(userAgent) && !/Mobile/.test(userAgent)

  return (isIOS || isAndroid) && [
    '(min-width: 768px)', '(max-width: 1366px)',
    '(min-height: 1024px)', '(max-height: 2048px)'
  ].every(query => window.matchMedia(query).matches)
}

export function isMobile() {
  const md = new MobileDetect(window.navigator.userAgent)
  return !!md.mobile() || !!md.versionStr('Mobile') || isTablet()
}

export function isIos() {
  const md = new MobileDetect(window.navigator.userAgent)
  return !!md.mobile() && md.is('iOS')
}

export function isAndroid() {
  const md = new MobileDetect(window.navigator.userAgent)
  return !!md.mobile() && md.is('AndroidOS')
}

export function putFile(file, signedUrl, RequestClass = window.XMLHttpRequest) {
  return new Promise((resolve, reject) => {
    const xhr = new RequestClass()
    xhr.open('PUT', signedUrl)
    const randomName = fill(new Array(15), '*')
      .map(() => String.fromCharCode(Math.floor(Math.random() * (122 - 97)) + 97))
      .join('')
    xhr.setRequestHeader('Content-Disposition', `filename=${encodeURIComponent(randomName)}`)
    xhr.setRequestHeader('x-amz-acl', 'public-read')
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve()
        } else {
          reject(`PUT: Wrong status code ${xhr.status}`)
        }
      }
    }
    xhr.send(file)
  })
}

export function scrollElementIntoView(elementId, delay = 0, scrollOptions = {block: 'start', behaviour: 'smooth'}) {
  const el = document.getElementById(elementId)
  el &&
    setTimeout(() => {
      el && el.scrollIntoView(scrollOptions)
    }, delay)
}

export function setCookie(name, value, expirationDays, domain = '') {
  const d = new Date()
  d.setTime(d.getTime() + expirationDays * 24 * 60 * 60 * 1000)
  return (document.cookie = `${name}=${value};expires=${d.toUTCString()};path=/;domain=${domain};`)
}

export function getCookie(name) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) {
    return parts.pop().split(';').shift()
  }
}

export function setCookieTheme(theme) {
  const arrayHostname = window.location.hostname.split('.')
  const hostname = [arrayHostname[arrayHostname.length - 2], arrayHostname[arrayHostname.length - 1]].join('.')
  setCookie('theme', capitalize(theme), 30, hostname)
}

export function addClassToElement(id, className) {
  const element = document.getElementById(id)
  element && element.classList.add(className)
}

export function removeClassToElement(id, className) {
  const element = document.getElementById(id)
  element && element.classList.remove(className)
}

export function getUrlParam(name) {
  return new URL(window.location.toString()).searchParams.get(name)
}

export function getCountryByTimeZone(timeZone) {
  return get(
    meta.countries,
    [get(meta.zones, [timeZone.replace('Calcutta', 'Kolkata').replace('Kiev', 'Kyiv'), 'countries', 0]), 'name'],
    'N/A',
  )
}

export function replaceBrowserLocale(locale) {
  const localesToReplace = {
    'fil': 'ph',
    'fil_PH': 'ph',
    'tl_PH': 'ph',
    'nb-NO': 'no',
    'nb': 'no',
  }

  return localesToReplace[locale] || locale
}

export function truncateEmail(email, startLength, endLength) {
  const [user, domain] = email.split('@')
  if (user.length > startLength + endLength) {
    const truncatedUser = `${truncate(user, {length: startLength + 3, omission: ''})}...${user.substring(user.length - endLength)}`
    return `${truncatedUser}@${domain}`
  }
  return email
}
