import {map, get, has, filter, flowRight as compose} from 'lodash'
import React, {Component} from 'react'
import {Trans, withNamespaces} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import Grid from '@mui/material/Grid'
import Radio from '@mui/material/Radio'
import FormLabel from '@mui/material/FormLabel'
import Typography from '@mui/material/Typography'
import RadioGroup from '@mui/material/RadioGroup'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import messages from '../../../../assets/messages'
import AppContext from '../../../Common/contexts/AppContext'
import FormHelperText from '@mui/material/FormHelperText'
import {Checkbox, FormGroup} from '@mui/material'
import {isMobile} from '../../../../common/utils'
import {accountTypes} from '@bdswiss/common-enums'

const styles = theme => ({
  questions: {
    marginBottom:20,
    width: '100%'
  },
  answersGroup:{
    margin:16
  },
  answersGroupTextField:{
    margin:16,
    width: '80%'
  },
  question: {
    marginBottom:10
  },
  answer:{
    lineHeight:1.5
  },
  error:{
    color: theme.palette.red.color,
  },
  errorText:{
    color: theme.palette.red.color,
    marginTop: 0,
    marginLeft: 18
  },
  onlyText:{
    visibility: 'hidden'
  }
})

export class PortfolioManagementQuestions extends Component {
  static contextType = AppContext

  render() {
    const {form, formState, errors, activeStep, onChange, classes, questions, disableSubmit} = this.props
    const {locale, companyObject} = this.context

    return map(questions, (q, questionKey) => {
      const {step, options} = q
      const answerKey = get(form, `answers[${questionKey}]` ,'')
      const hasError = formState === 'validation' && has(errors, questionKey)
      const fullWidthKey = q.key === 'mostSpecific' || q.key === 'riskWillingness'
      const getSubTypes = filter(accountTypes, (p)=> p.subCategory === 'fundManagement' && (get(companyObject, 'value') === p.company))
      const firstStep = getSubTypes.length <= 1
      return step.stepPosition(disableSubmit, firstStep) === activeStep &&
      <Grid key={`${questionKey}-row`} container>
        <Grid key={`${questionKey}-column-1`} item xs={isMobile() ? 12 : 9}>
          <FormControl
            variant="standard"
            key={questionKey}
            component="fieldset"
            className={classes.questions}>
            <FormLabel
              id={questionKey}
              component="legend"
              error={hasError}
              className={classes.question}>
              <Typography variant='body2' className={hasError ? (classes.error): ''}>
                {q.orderLabel}. {q.localization.t(locale, {domain: companyObject.emailDomain})}</Typography>
              {hasError && <FormHelperText className={classes.errorText}>
                <Trans {...messages.requiredField} /></FormHelperText>}
            </FormLabel>
            <FormGroup
              row
              className={classes.answersGroup}
            >
              {q.multiselect && map(options, (answer, answerKeySub) => (
                !has(answer, ['disable']) && <FormControlLabel
                  style={{width: '100%', minWidth: '100%'}}
                  key={answerKeySub}
                  value={answerKeySub}
                  classes={{label: classes.answer}}
                  label={answer.localization.t(locale, {domain: companyObject.emailDomain})}
                  onChange={(e) => onChange(e.target, questionKey)}
                  disabled={disableSubmit}
                  control={<Checkbox
                    checked={answerKey.includes(answerKeySub)}
                    color="primary"
                    classes={{root: get(answer, 'onlyText') && classes.onlyText}}/>}
                />)
              )}
              {q.radioBtn && <RadioGroup
                row
                name={questionKey}
                value={answerKey}
                onChange={(e) => onChange(e.target, questionKey)}
                className={classes.answersGroup}
              >
                {map(options, (answer, answerKey) =>
                  (!has(answer, ['disable']) && <FormControlLabel
                    style={!fullWidthKey ? {width: '49%', minWidth: '310px'}
                      : {width: '100%', minWidth: '100%', marginBottom: 12}}
                    key={answerKey}
                    disabled={disableSubmit}
                    value={answerKey}
                    classes={{label: classes.answer}}
                    label={answer.localization.t(locale, {domain: companyObject.emailDomain})}
                    control={<Radio
                      color="primary"/>}
                  />)
                )}
              </RadioGroup>}
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
    })
  }
}

export default compose(
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
)(PortfolioManagementQuestions)
