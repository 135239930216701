import {depositVendors, safechargePaymentTypes, cardpayPaymentTypes} from '@bdswiss/common-enums'
import VoltProvider from './VoltProvider'
import DLocalProvider from './DLocalProvider'
import KalixaProvider from './KalixaProvider'
import PaypalProvider from './PaypalProvider'
import CryptoProvider from './CryptoProvider'
import RPNPayProvider from './RPNPayProvider'
import KlapayProvider from './KlapayProvider'
import ExactlyProvider from './ExactlyProvider'
import PayzoffProvider from './PayzoffProvider'
import DusupayProvider from './DusupayProvider'
import UbiqpayProvider from './UbiqpayProvider'
import FuntoPayProvider from './FuntoPayProvider'
import OpenBankingProvider from './safechargeOpenBanking'
import Help2PayProvider from './Help2PayProvider'
import EmailRequiredProvider from './EmailRequiredProvider'
import MifinityProvider from './MifinityProvider'
import AstropayProvider from './AstropayProvider'
import NganLuongProvider from './NganLuongProvider'
import BridgerPayProvider from './BridgerPayProvider'
import CreditCardProvider from './CreditCardProvider'
import RPNPayUSDTProvider from './RPNPayUSDTProvider'
import JMFinancialProvider from './JMFinancialProvider'
import Transact365Provider from './Transact365Provider'
import PayRetailersProvider from './PayRetailersProvider'
import CardpayCryptoProvider from './CardpayCryptoProvider'
import CardpayRedirectProvider from './CardpayRedirectProvider'
import GenericIFramedProvider from './GenericIFramedProvider'
import GenericRedirectProvider from './GenericRedirectProvider'
import BankWireTransferProvider from './BankWireTransferProvider'
import GenericSelfPostingProvider from './GenericSelfPostingProvider'
import BeetellerProvider from './BeetellerProvider'
import GenericHtmlIframeProvider from './GenericHtmlIFrameProvider'
import WallettecProvider from './WallettecProvider'
import _1_2PAY from './1-2Pay'

export const PaymentProviderFactory = {
  get: (providerKey, paymentKey) => {
    switch (providerKey) {
      // generic providers
      case depositVendors.dotpay.value:
      case depositVendors.fasapay.value:
      case depositVendors.globepay.value:
      case depositVendors.paymentAsia.value:
      case depositVendors.cashu.value:
      case depositVendors.appex.value:
      case depositVendors.thunderxpay.value:
      case depositVendors.usepay.value:
        return GenericSelfPostingProvider
      case depositVendors.link4pay.value:
        return GenericHtmlIframeProvider
      case depositVendors.zotapay.value:
      case depositVendors.skrill.value:
      case depositVendors.directa24.value:
      case depositVendors.powercash21.value:
      case depositVendors.easyeft.value:
      case depositVendors.echelonpay.value:
      case depositVendors.otc365.value:
      case depositVendors.unionpay.value:
      case depositVendors.korapay.value:
      case depositVendors.razorpay.value:
      case depositVendors.paymaxis.value:
      case depositVendors.fincra.value:
        return GenericRedirectProvider
      case depositVendors.dinpay.value:
      case depositVendors.isignthis.value:
      case depositVendors.rave.value:
      case depositVendors.finrax.value:
        return GenericIFramedProvider
      case depositVendors.ubiqpay.value:
        return UbiqpayProvider
      case depositVendors.wallettec.value:
        return WallettecProvider
      // custom providers
      case depositVendors.funtopay.value:
        return FuntoPayProvider
      case depositVendors.coinbase.value:
      case depositVendors.btcproxy.value:
      case depositVendors.letknowpay.value:
      case depositVendors.speedlightpay.value:
        return CryptoProvider
      case depositVendors.dlocal.value:
        return DLocalProvider
      case depositVendors.kalixa.value:
      case depositVendors.paytrust.value:
        return KalixaProvider
      case depositVendors.cardpay.value:
        if (paymentKey === cardpayPaymentTypes.bitcoin.value) {
          return CardpayCryptoProvider
        }
        return CardpayRedirectProvider
      case depositVendors.wirecard.value:
      case depositVendors.checkout.value:
        return CreditCardProvider
      case depositVendors.neteller.value:
        return EmailRequiredProvider
      case depositVendors.safecharge.value:
        switch (paymentKey) {
          case safechargePaymentTypes.creditCard.value:
            return CreditCardProvider
          case safechargePaymentTypes.openBanking.value:
          case safechargePaymentTypes.instantOpenBanking.value:
            return OpenBankingProvider
          case safechargePaymentTypes.interac.value:
            return GenericRedirectProvider
          default:
            return GenericIFramedProvider
        }
      case depositVendors.bankWire.value:
      case depositVendors.easypay.value:
        return BankWireTransferProvider
      case depositVendors.payzoff.value:
        return PayzoffProvider
      case depositVendors.nganLuong.value:
        return NganLuongProvider
      case depositVendors.paypal.value:
        return PaypalProvider
      case depositVendors.help2pay.value:
        return Help2PayProvider
      case depositVendors.bridgerPay.value:
        return BridgerPayProvider
      case depositVendors.rpnpay.value:
        return paymentKey === 'USDT' ? RPNPayUSDTProvider : RPNPayProvider
      case depositVendors.dusupay.value:
        return DusupayProvider
      case depositVendors.transact365.value:
        return Transact365Provider
      case depositVendors.payRetailers.value:
        return PayRetailersProvider
      case depositVendors.klarpay.value:
        return KlapayProvider
      case depositVendors.jmFinancial.value:
        return JMFinancialProvider
      case depositVendors.mifinity.value:
        return MifinityProvider
      case depositVendors.astropay.value:
        return AstropayProvider
      case depositVendors.exactly.value:
        return ExactlyProvider
      case depositVendors.volt.value:
        return VoltProvider
      case depositVendors.beeteller.value:
        return BeetellerProvider
      case depositVendors._1_2_pay.value:
        return _1_2PAY
      default:
        return null
    }
  },
  hasImplementation(providerKey, paymentKey = '') {
    return !!PaymentProviderFactory.get(providerKey, paymentKey)
      && ![
        safechargePaymentTypes.applepay.value,
        safechargePaymentTypes.googlepay.value,
      ].includes(paymentKey)
  }
}
