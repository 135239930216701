import React, {Component} from 'react'
import {graphql} from 'react-apollo'
import {flowRight as compose, get, isNil} from 'lodash'
import {FormControlLabel, FormGroup, Grid, Switch, Typography} from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import {Trans, withNamespaces} from 'react-i18next'
import messages from '../../assets/messages'
import AppContext from '../Common/contexts/AppContext'
import {ADD_METADATA_TO_CLIENT_MUTATION, GDPR_OPT_IN_MARKETING_MUTATION} from '../../graphql/mutations'
import {CLIENT_DATA_QUERY} from '../../graphql/queries'
import {isMobile} from '../../common/utils'

const styles = theme => ({
  switchRoot: {
    float: 'right',
  },
  switchPosition: {
    'margin-left': 0,
    'margin-right': 0,
    '& label': {fontWeight: 400}
  },
  rowItem: {
    'border-bottom': '1px solid rgba(0, 0, 0, 0.12)',
    'margin-bottom': '15px',
  },
  communicationsTitle: {
    marginBottom: 35,
  }
})
const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none',
      },
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[500]}`,
    backgroundColor: theme.palette.grey[500],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({classes, ...props}) => (
  <Switch
    focusVisibleClassName={classes.focusVisible}
    disableRipple
    classes={{
      root: classes.root,
      switchBase: classes.switchBase,
      thumb: classes.thumb,
      track: classes.track,
      checked: classes.checked,
    }}
    {...props}
  />
))
export class Preferences extends Component {
  static contextType = AppContext

  render() {
    const {classes, viewer: {partnerBonusesAndCompetitions, partnerClientRegisteredEmail}, addMetadataToClient} = this.props
    return (
      <Grid container>
        <Grid item xs={12} className={classes.communicationsTitle}>
          <Typography variant="h1"><Trans {...messages.communication} /></Typography>
        </Grid>
        <Grid item xs={isMobile() ? 12 : 8}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className={classes.rowItem}
          >
            <Grid item xs={!isMobile() ? 6 : 9}>
              <Typography variant='body1'><Trans {...messages.partnerBonusesPromotionsAndCompetitions} /></Typography>
            </Grid>
            <Grid item xs={!isMobile() ? 6 : 3}>
              <FormGroup row={true} className={classes.switchRoot}>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={partnerBonusesAndCompetitions}
                      onChange={e => addMetadataToClient({variables: {metadata: JSON.stringify({partnerBonusesAndCompetitions: e.target.checked})}})}
                      name="partnerBonusesAndCompetitionsSwitch"
                    />
                  }
                  labelPlacement={'end'}
                  label={<Typography variant='body2'><Trans {...messages.email} /></Typography>}
                  className={classes.switchPosition}
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className={classes.rowItem}
          >
            <Grid item xs={!isMobile() ? 6 : 9}>
              <Typography variant='body1'><Trans {...messages.newClientRegistrationEmail} /></Typography>
            </Grid>
            <Grid item xs={!isMobile() ? 6 : 3}>
              <FormGroup row={true} className={classes.switchRoot}>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={isNil(partnerClientRegisteredEmail) || partnerClientRegisteredEmail}
                      onChange={e => addMetadataToClient({variables: {metadata: JSON.stringify({partnerClientRegisteredEmail: e.target.checked})}})}
                      name="newClientRegistrationEmailSwitch"
                    />
                  }
                  labelPlacement={'end'}
                  label={<Typography variant='body2'><Trans {...messages.email} /></Typography>}
                  className={classes.switchPosition}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading}, data}) => ({
      error,
      loading,
      viewer: get(data, 'viewer')
    })
  }),
  graphql(GDPR_OPT_IN_MARKETING_MUTATION, {
    name: 'toggleOptInMarketing',
    options: {
      refetchQueries: [{query: CLIENT_DATA_QUERY}],
    }
  }),
  graphql(ADD_METADATA_TO_CLIENT_MUTATION, {
    name: 'addMetadataToClient',
    options: () => ({
      refetchQueries: [{query: CLIENT_DATA_QUERY}],
    }),
  }),
)(Preferences)
