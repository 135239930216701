import React, {Component} from 'react'
import {Trans} from 'react-i18next'
import {flowRight as compose, includes} from 'lodash'

import {styled} from '@mui/system'
import {Popover} from '@mui/material'

import config from '../../config'
import ModalTitle from './ModalTitle'
import ModalQRCode from './ModalQRCode'
import ModalDivider from './ModalDivider'
import messages from '../../assets/messages'
import AppContext from '../Common/contexts/AppContext'
import {isRtlLang} from '../../common/utils/uioptions'
import ModalDownloadButton from './ModalDownloadButton'
import {getCurrentTheme, isAndroid, isDarkTheme, isIos, isMobile} from '../../common/utils'

import {ReactComponent as DownloadAndroidApkIcon} from '../../assets/images/download-apk.svg'
import {ReactComponent as DownloadFromGooglePlayLightIcon} from '../../assets/images/download-playstore.svg'
import {ReactComponent as DownloadFromGooglePlayDarkIcon} from '../../assets/images/download-playstore-dark.svg'

const PopoverContainer = styled(Popover)(({theme}) => ({
  '& .MuiPopover-paper': {
    width: isMobile() ? 344 : 280,
    marginTop: 10,
    padding: 24,
    background: theme.palette.mode === 'dark' ? '#1E1E1E' : theme.palette.background.paper,
    border: 0,
    borderRadius: 8,
    boxShadow: theme.palette.mode === 'dark' ? 'none' : '0px 6px 20px rgba(0, 0, 0, 0.12)',
  },
  '& .MuiBackdrop-root': {
    backgroundColor: isMobile() ? 'rgba(0, 0, 0, 0.7)' : 'transparent',
  },
}))

const ModalContent = styled('div')({
  '& .MuiDialogContent-root': {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
})

const DownloadButtonsWrap = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: 40,
  '& a:first-of-type': {
    margin: 10,
  },
  '& a:last-of-type': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    width: 120,
    margin: 8,
    textDecoration: 'none',
    color: 'inherit',
  },
})

const storeName = () => {
  switch (true) {
    case isIos():
      return 'App Store'
    case isAndroid():
      return 'Google Play'
    default:
      return ''
  }
}

const downloadButton = {
  androidApk: {
    sourceKey: 'androidApk',
    translationKey: 'androidApk',
    defaultTranslation: 'Android APK',
  },
  storeLink: {
    sourceKey: 'storeLink',
    translationKey: 'downloadApp',
    defaultTranslation: 'App Store or Google Play',
  },
}

class DownloadMobileAppModal extends Component {
  static contextType = AppContext
  state = {
    downloadOption: 'androidApk',
  }

  toggleDownloadOption = () => {
    this.setState((prevState) => ({
      downloadOption: prevState.downloadOption === 'androidApk' ? 'storeLink' : 'androidApk'
    }))
  }

  handleClickDownloadMobileAppBtn = (event) => {
    if (!isMobile()) {
      this.toggleDownloadOption()
    } else {
      event.preventDefault()
      window.location.href = event.currentTarget.getAttribute('href')
    }
  }

  render() {
    const {downloadOption} = this.state

    const {anchorEl, open, onClose} = this.props
    const {common: {appLinks}} = config
    const userCompanyLabel = this.context.companyObject.brandLabel
    const {locale} = this.context
    const themePreference = getCurrentTheme(this.context)

    return (
      <PopoverContainer
        id={'DownloadMobileApp'}
        open={open}
        onClose={() => onClose()}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: isMobile() ? 'center' : 50,
          horizontal: isMobile() ? 'center' : includes(isRtlLang, locale) ? 'left' : 'right',
        }}
        transformOrigin={{
          vertical: isMobile() ? 'center' : 'top',
          horizontal: isMobile() ? 'center' : includes(isRtlLang, locale) ? 'left' : 'right'
        }}
        PaperProps={{
          style: {
            top: '50%',
            transform: isMobile() ? 'translateY(-50%)' : ' none',
          },
        }}
        slotProps={{
          root: {
            slotProps: {
              backdrop: {invisible: !isMobile()},
            }
          }
        }}
      >
        <ModalTitle close={isMobile() ? () => onClose() : null}>
          {isMobile()
            ? (
              <Trans
                {...messages.downloadMobileAppModalTitleMobile}
                components={[<span style={{fontWeight: 'bold'}}>storeName</span>]}
                values={{company: userCompanyLabel, storeName: storeName()}}
              />
            )
            : downloadOption === 'androidApk'
              ? (
                <Trans
                  {...messages.downloadMobileAppFromStoreModalTitleDesktop}
                  components={[<span style={{fontWeight: 'bold'}}>App Store or Google Play</span>]}
                  values={{company: userCompanyLabel}}
                />
              )
              : (
                <Trans
                  {...messages.downloadMobileApkModalTitleDesktop}
                  components={[<span style={{fontWeight: 'bold'}}>Android APK</span>]}
                  values={{company: userCompanyLabel}}
                />
              )
          }
        </ModalTitle>

        <ModalContent>
          {isMobile() ? (
            <DownloadButtonsWrap>
              <a href={appLinks.googlePlayStoreUrl} onClick={this.handleClickDownloadMobileAppBtn}>
                {isDarkTheme(themePreference) ? <DownloadFromGooglePlayDarkIcon /> : <DownloadFromGooglePlayLightIcon />}
              </a>
              <a href={appLinks.androidApkFileUrl} onClick={this.handleClickDownloadMobileAppBtn}>
                <DownloadAndroidApkIcon />
                Android APK
              </a>
            </DownloadButtonsWrap>
          ) : (
            <>

              <ModalQRCode
                value={downloadOption === 'androidApk' ? appLinks.autoDetectStoreUrl : appLinks.androidApkFileUrl}
              />

              <ModalDivider />

              <ModalDownloadButton
                key={downloadButton[downloadOption].sourceKey}
                active={true}
                translationKey={downloadButton[downloadOption].translationKey}
                defaultTranslation={downloadButton[downloadOption].defaultTranslation}
                data-key={downloadButton[downloadOption].sourceKey}
                onClick={this.handleClickDownloadMobileAppBtn}
              />
            </>
          )}
        </ModalContent>
      </PopoverContainer>
    )
  }
}

export default compose(
)(DownloadMobileAppModal)
