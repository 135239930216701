import React, {Component} from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import {get, some, find, floor, flowRight as compose, toNumber, map, isEmpty} from 'lodash'
import {withNamespaces, Trans} from 'react-i18next'
import {currencies, kycStatuses} from '@bdswiss/common-enums'
import messages from '../../../assets/messages'
import AmountInput from '../../Common/AmountInput'
import AccountSelect from '../../Common/AccountSelect'
import PageSubTitle from '../../Common/PageSubTitle'
import FreeMarginWarning from './FreeMarginWarning'
import {getFormattedAmount, isMobile} from '../../../common/utils'
import NotificationBar from '../../Common/NotificationBar'
import {isBitnukAccount, isCentAccount, isForexAccount, isAffiliatesAccount} from '../../../common/utils/accounts'
import {REFILL_DEMO_BALANCE} from '../../../graphql/mutations'
import {graphql} from 'react-apollo'
import {ACCOUNTS_QUERY} from '../../../graphql/queries'
import {withRouter} from 'react-router-dom'
import LoadingButton from '../../Common/LoadingButton'
import {FormHelperText} from '@mui/material'
import {withMediaQuery} from '../../Common'
import withStyles from '@mui/styles/withStyles'

const validators = {
  amount: (initialAmount, account) => {
    const amount = isCentAccount(account) ? initialAmount * 100 : initialAmount
    if (!amount) {
      return {
        hasError: true,
        message: messages.requiredField,
      }
    }
    if ((isForexAccount(account) || isAffiliatesAccount(account)) && account.freeMargin < amount) {
      return {
        hasError: true,
        message: messages.notEnoughFreeMargin,
      }
    }
    if ((!isForexAccount) && (amount > (account.balance - account.pendingAmount))) {
      return {
        hasError: true,
        message: messages.insufficientFunds,
      }
    }
    if (amount <= 0) {
      return {
        hasError: true,
        message: messages.positiveNumber,
      }
    }
    return {
      hasError: false,
      message: {},
    }
  },
  accountId: (accountId, account) => ({
    hasError: !accountId,
    message: !accountId ? messages.requiredField : {}
  })
}

const styles = (theme) => ({
  submitButton: {
    marginTop: theme.spacing(2),
  },
})

class ChooseAccount extends Component {
  constructor(props) {
    super(props)
    const {accounts, viewer: {kycStatus}, accountId, amount, match} = props
    this.state = {
      kycStatusNotApproved: kycStatus !== kycStatuses.approved.key,
      form: {
        accountId: accountId || toNumber(get(match, 'params.accountId')) || get(accounts, '[0].id'),
        amount: amount || '',
      },
      errors: {},
      showFreeMarginWarning: false,
      loading: false,
      status: ''
    }
  }

  chooseMethod() {
    const {onContinue, accounts} = this.props
    const {form, form: {accountId, amount}} = this.state
    const selectedAccount = find(accounts, {id: accountId})

    const errors = {}
    for (const field of Object.keys(form)) {
      errors[field] = validators[field](form[field], selectedAccount)
    }

    if (some(errors, (e) => e.hasError)) {
      this.setState({errors})
      return
    }

    const {freeMargin} = selectedAccount
    const isAmountNearFreeMargin = isForexAccount(selectedAccount) &&
      amount >= floor(freeMargin * 0.9, 2) // 10% below

    if (isAmountNearFreeMargin) {
      this.setState({showFreeMarginWarning: true})
    } else {
      onContinue(accountId, isCentAccount(selectedAccount) ? amount * 100 : amount)
    }
  }

  handleChange = e => {
    const {accounts, getSelectedAccount} = this.props
    const {form: {accountId}} = this.state
    const selectedAccount = find(accounts, {id: accountId})
    if (e.target.name === 'accountId') {
      getSelectedAccount(find(accounts, {id: e.target.value}))
    }
    this.setState(state => ({
      form: {
        ...state.form,
        [e.target.name]: e.target.value
      },
      errors: {
        ...state.errors,
        [e.target.name]: validators[e.target.name](e.target.value, selectedAccount)
      }
    }))
  }

  topDownDemoBalance(accountSelected) {
    const {t} = this.props
    const {form: {amount}} = this.state
    this.setState({status: '', loading: true})
    this.props.topDownDemoBalance({variables: {login: accountSelected.remoteId, amount: amount * -1}})
      .then(() => {
        this.setState({loading: false, status: 'success', submitMessageError: ''})
      })
      .catch((err) => {
        if (err.networkError) {
          this.setState({loading: false, status: 'failure',
            submitMessageError: t(messages.networkError.i18nKey, messages.networkError.defaults),
          })
        } else {
          this.setState({loading: false, status: 'failure',
            submitMessageError: get(err, 'graphQLErrors[0].message') || err.message,
          })
        }

      })
  }

  getErrorMessage(errorMessage) {
    let message = ''
    if (get(errorMessage, 'code') === 'errors.account.ldClientWalletHasPendingAmount') {
      message = get(errorMessage, 'message')
    } else if (get(errorMessage, 'code') === 'errors.account.ldClientWalletHasPendingAmount') {
      message = get(errorMessage, 'message')
    } else if (get(errorMessage, 'code.id') === 'depositHasPendingAmountLeft') {
      message = get(errorMessage.message, 'label')
    }
    return message
  }

  render() {
    const {accounts, viewer: {locale}, t, widthDown, onContinue, disabledAction, classes, errorMessage, history} = this.props
    const {form: {accountId, amount}, errors, showFreeMarginWarning, status, loading, submitMessageError} = this.state
    const selectedAccount = find(accounts, {id: accountId}) || accounts[0]
    const withdrawalAccounts = map(accounts, (a) => ({
      id:  a.id,
      remoteId:  a.remoteId,
      balance: a.balance,
      currency: a.currency,
      __typename: a.__typename,
      pendingAmount: a.pendingAmount,
      accountName: a.accountName,
      accountSubtype: a.accountSubtype,
      isDemo: a.isDemo,
    }))
    const withdrawalFee = `${selectedAccount.withdrawalFee} ${selectedAccount.currency}`

    return (
      <Grid container>
        {status ==='success' &&
        <Grid item xs={12} sm={12} mt={isMobile() ? 2 : 3}>
          <NotificationBar
            status='success'
            noMargin={true}
            title={<Trans {...messages.demoBalanceUpdated} />}
            ctaMessage={<Trans {...messages.goToDashboard} />}
            ctaAction={() => history.push('/')}
          />
        </Grid>}
        <Grid item xs={12} sm={12} lg={10}>
          <Grid container direction="column">
            <Grid item xs={12}>
              <PageSubTitle><Trans {...messages.chooseAccount} /> </PageSubTitle>
            </Grid>
            <Grid item xs={12}>
              <AccountSelect
                accounts={withdrawalAccounts}
                label={t(messages.withdrawAccounts.i18nKey, messages.withdrawAccounts.defaults)}
                onChange={this.handleChange}
                value={accountId}
                id="account-selector"
                name="accountId"
                locale={locale}
                t={t}
              />
              {isBitnukAccount(selectedAccount) && <Typography variant='caption'>
                <Trans {...messages.bitnukWithdrawalFee} values={{withdrawalFee}} />
              </Typography>}
            </Grid>
            <Grid item xs={12}>
              {isCentAccount(selectedAccount) && (amount || 0) > 0 && <NotificationBar
                noMargin
                status="info"
                title={<Trans {...messages.withdrawInCUD} values={{amount: Number(amount * 100).toLocaleString()}} />}
              />}
            </Grid>
            <Grid item xs={12}>
              <PageSubTitle>
                <Trans {...messages.selectAmount} />
              </PageSubTitle>
              <AmountInput
                jumbo
                onChange={e => this.handleChange({target: {name: 'amount', value: e.target.value}})}
                value={amount}
                id="amount-input"
                name="ammount"
                currency={isCentAccount(selectedAccount) ? currencies.USD.value : get(selectedAccount, 'currency')}
                locale={locale}
                TextFieldProps={{
                  error: get(errors, 'amount.hasError', false),
                  helperText: get(errors, 'amount.hasError', false) &&
                    t(get(errors, 'amount.message').i18nKey, get(errors, 'amount.message').defaults)
                }}
                disabled={disabledAction}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            onClick={() => (!selectedAccount.isDemo) ? this.chooseMethod() : this.topDownDemoBalance(selectedAccount)}
            disabled={disabledAction || loading}
            fullWidth={widthDown}
            status={status}
            className={classes.submitButton}
          >
            <Trans {...messages.continue} />
          </LoadingButton>
          {status==='failure' && <FormHelperText className={classes.errorMessage}>{submitMessageError}</FormHelperText>}
          {!isEmpty(errorMessage) && <FormHelperText className={classes.errorMessage}>{this.getErrorMessage(errorMessage)}</FormHelperText>}
          {get(errorMessage, 'code') === 'errors.account.ldClientWalletHasPendingAmount' && <Grid item xs={12}>
            <PageSubTitle>
              <Trans {...messages.attention} />
            </PageSubTitle>
            <Typography variant="caption"><Trans {...messages.withdrawalOptionsClearLdWalletFirst}/></Typography>
          </Grid>}
        </Grid>
        <FreeMarginWarning
          open={showFreeMarginWarning}
          onSubmit={() => this.setState({showFreeMarginWarning: false})}
          onCancel={() => onContinue(accountId, amount)}
          amount={getFormattedAmount({amount, currency: selectedAccount.currency, locale})}
          freeMargin={getFormattedAmount({amount: selectedAccount.freeMargin, currency: selectedAccount.currency, locale})}
        />
      </Grid>
    )
  }
}

export default compose(
  withMediaQuery('sm'),
  withNamespaces(),
  withRouter,
  withStyles(styles, {withTheme: true}),
  graphql(REFILL_DEMO_BALANCE, {
    name: 'topDownDemoBalance',
    options: {
      refetchQueries: [{query: ACCOUNTS_QUERY}],
    }
  })
)(ChooseAccount)
