import queryString from 'qs'
import React, {Component} from 'react'
import {withNamespaces, Trans} from 'react-i18next'
import {companies, countries} from '@bdswiss/common-enums'
import withStyles from '@mui/styles/withStyles'
import {Box, FormHelperText, Grid, Link, Tab, Tabs, TextField, Typography} from '@mui/material'
import {
  flowRight as compose,
  concat,
  filter,
  find,
  get,
  includes,
  isEmpty,
  keys,
  map,
  omit,
  pick,
  size,
  sortBy,
  toLower,
} from 'lodash'

import i18nApp from '../../../i18n'
import config from '../../../config'
import messages from '../../../assets/messages'
import AppContext from '../../Common/contexts/AppContext'
import CountriesSelect from '../../Common/CountriesSelect'
import {europeCountries, registerWidgetLPTabs} from '../../../common/utils/uioptions'
import {
  checkEmail,
  createLead,
  getCountries,
  getIPCountry,
  isMobile,
  safeParseJSON,
  storeItem,
  updateExistingLead,
} from '../../../common/utils'
import SwitchButton from '../../Common/SwitchButton'
import Password from '../../Common/Password'
import LoadingButton from '../../Common/LoadingButton'

const styles = theme => ({
  errorRoot: {
    lineHeight: 1.5,
  },
  successRoot: {
    width: '100%',
    lineHeight: 1.5,
    borderRadius: '6px',
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.lightgreen.color,
    padding: `${theme.spacing(1)} 0px`,
  },
  successMessage: {
    color: theme.palette.green.color,
    textAlign: 'center',
    display: 'inline-block',
  },
  errorMessage: {
    color: theme.palette.error.main,
    display: 'inline-block',
    verticalAlign: 'bottom',
    margin: '13px 13px 13px 0',
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight: 400,
  },
  tabsContainer: {
    padding: `${theme.spacing(3)} 0px`,
  },
  formContainer: {
    padding: `0px ${theme.spacing(3)}`,
  },
})

class RegisterWidgetLP extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {
      activeTab: 'existing',
      listCountries: [],
      loading: false,
      prefixChanged: false,
      countryChanged: false,
      showPassword: false,
      form: {},
      formErrors: {},
    }
  }

  componentDidMount() {
    const {location} = this.props

    const urlParams = queryString.parse(location.search.replace('?', ''))
    this.setState(state => ({form: {...state.form, ...urlParams}}))
    const getCountriesParams = [undefined, undefined]
    this.getCountries(...getCountriesParams).then(async () => {
      const country = get(urlParams, 'country')
      this.getCountry(country && country.toUpperCase())
    })
  }

  async getCountries(product, company) {
    const {
      common: {allowedRegisterEUCountriesUnderCampaign},
    } = config
    await getCountries(product, company)
      .then(res => {
        if (isEmpty(res.countries) && window.location.pathname !== '/register') {
          window.location.href = '/register'
          return
        }
        const {supportedCompanies, registrationCountriesDisabledTemporary} = config
        let countriesAllowed = res.countries
        if (registrationCountriesDisabledTemporary)
          countriesAllowed = filter(
            countriesAllowed,
            country => !includes(registrationCountriesDisabledTemporary, get(country, 'value')),
          )
        if (supportedCompanies)
          countriesAllowed = filter(
            countriesAllowed,
            country => !country.isEU || includes(allowedRegisterEUCountriesUnderCampaign, country?.value),
          )
        const country = get(this.state, 'form.country', '') || get(this.state, 'countryFromIp', '')
        const countryCode =
          size(countriesAllowed) === 1
            ? get(countriesAllowed, '[0].value')
            : !isEmpty(res.countries) && includes(map(countriesAllowed, 'value'), country)
              ? country
              : ''
        const selectedCountryCode = !isEmpty(countryCode)
          ? find(res.countries, country => country.value === countryCode)
          : ''
        const prefixCode = !isEmpty(countryCode) ? `+${countries[toLower(countryCode)].callingCode}` : ''
        this.setState(state => ({
          form: {...state.form, country: countryCode, prefix: prefixCode},
          countryFlag: selectedCountryCode,
          prefixFlag: countryCode,
          listCountries: countriesAllowed,
        }))
      })
      .catch(err => err)
  }

  getCountry(paramCountry) {
    const {
      featuresConfig: {countriesSpecificLocale},
      registrationCountriesDisabledTemporary,
      supportedCompanies,
      common: {allowedRegisterEUCountriesUnderCampaign, forceEntityOfCountry},
    } = config

    getIPCountry()
      .then(res => {
        this.setState(state => ({countryFromIp: res['country_code']}))
        const {listCountries} = this.state
        let countryList = listCountries
        if (registrationCountriesDisabledTemporary)
          countryList = filter(
            countryList,
            country => !includes(registrationCountriesDisabledTemporary, get(country, 'value')),
          )
        if (supportedCompanies)
          countryList = filter(
            countryList,
            country => !country.isEU || includes(allowedRegisterEUCountriesUnderCampaign, country?.value),
          )
        const singleCheck = paramCountry ? paramCountry : countryList.length === 1 ? get(countryList[0], 'value') : ''
        let countryCode =
          singleCheck && Boolean(find(countryList, {value: singleCheck})) ? singleCheck : res['country_code']
        const forceEntityCountries = keys(safeParseJSON(forceEntityOfCountry))
        if (
          supportedCompanies &&
          includes(europeCountries, res['country_code']) &&
          !includes(forceEntityCountries, res['country_code'])
        )
          countryCode = ''
        if (Boolean(find(countryList, {value: countryCode}))) {
          this.handleFormChange('prefix', `+${countries[toLower(res['country_code'])].callingCode}`)
          this.handleChangeCountry(countryCode)
          this.handleFormChange('country', countryCode)
          this.handleChangePrefix(countryCode)
          const countriesLocale = JSON.parse(countriesSpecificLocale)
          if (get(countriesLocale, countryCode)) {
            const storageLang = window.sessionStorage && sessionStorage.getItem('lang')
            if (!storageLang || storageLang !== countriesLocale[countryCode]) {
              window.sessionStorage && sessionStorage.setItem('lang', countriesLocale[countryCode])
              i18nApp.init(countriesLocale[countryCode])
              storeItem('locale', countriesLocale[countryCode])
            }
          }
        }
      })
      .catch(err => err)
  }

  handleFormChange(key, value) {
    this.setState(state => ({
      status: '',
      submitMessageError: '',
      submitMessageSuccess: '',
      form: {
        ...state.form,
        [key]: value,
      },
    }))
  }

  handleFormErrorChange(key, value) {
    this.setState(state => ({
      formErrors: {
        ...state.formErrors,
        [key]: value,
      },
    }))
  }

  handleOuterChange = (key, value) => {
    this.setState(state => ({[key]: value}))
  }

  handleTabChanged(tabIndex) {
    this.setState(state => ({activeTab: tabIndex}))
  }

  handleChangeCountry(selectedCountry) {
    const {prefixChanged, countryChanged} = this.state
    const selectedCountryObj = find(countries, country => country.value === selectedCountry)

    if (prefixChanged && !countryChanged) {
      this.handleOuterChange('prefixChanged', false)
    }

    if (!prefixChanged || !countryChanged) {
      this.handleChangePrefix(selectedCountry)
      this.handleOuterChange('countryFlag', selectedCountryObj)
      this.handleFormChange('prefix', `+${get(selectedCountryObj, 'callingCode', '')}`)
    }
  }

  handleChangePrefix(selectedCountry) {
    this.handleOuterChange('showCountriesPrefix', false)
    this.handleOuterChange('prefixFlag', selectedCountry)
  }

  handleClickShowPassword() {
    this.setState(state => ({
      showPassword: !state.showPassword,
    }))
  }

  validateForm() {
    const {t} = this.props
    const {
      activeTab,
      form,
      form: {useEmail, email},
    } = this.state
    const activeTabObj = find(registerWidgetLPTabs, {key: activeTab})
    const requiredFields = activeTabObj.requiredFields

    this.setState(state => ({
      status: '',
      loading: true,
      formErrors: {},
      submitMessageError: '',
    }))

    for (const fieldKey of requiredFields) {
      if (isEmpty(form[fieldKey])) {
        this.setState(state => ({formErrors: {...state.formErrors, [fieldKey]: true}}))
      } else {
        this.setState(state => ({formErrors: {...omit(state.formErrors, [`${fieldKey}`])}}))
      }
    }

    const ignoreIdOrEmail = activeTab === 'new' ? [] : useEmail ? ['id'] : ['email']
    this.setState(state => ({formErrors: {...omit(state.formErrors, ignoreIdOrEmail)}}))

    if (activeTab === 'new') {
      checkEmail(email).then(res => {
        if (!res) {
          this.handleSubmit()
        }

        if (res === 'rateLimit') {
          this.setState({
            loading: false,
            status: 'failure',
            submitMessageError: t(messages.rateLimit.i18nKey, messages.rateLimit.defaults),
          })

          return
        }

        if (res === 'emailIsUsed') {
          this.setState({
            loading: false,
            status: 'failure',
            submitMessageError: t(messages.lpWidgetEmailIsUsed.i18nKey, messages.lpWidgetEmailIsUsed.defaults),
          })

          return
        }

        this.setState(state => ({
          formErrors: {
            ...state.formErrors,
            emailNotValidDomain: res === 'invalidEmail',
            affiliateEmailExists: res === 'affiliateEmailIsUsed',
            closedAccount: res === 'emailIsUsedAccountClosed',
          },
        }))
      })
    } else {
      this.handleSubmit()
    }
  }

  handleSubmit() {
    const {t} = this.props
    const {form, activeTab} = this.state
    const activeTabObj = find(registerWidgetLPTabs, {key: activeTab})

    const payloadFields = concat(activeTabObj.fields, activeTabObj.urlParamFields)

    const payload = pick(form, payloadFields)

    if (activeTab === 'existing') {
      updateExistingLead(payload)
        .then(res => {
          if (res.result === 'error') {
            this.setState(state => ({
              loading: false,
              status: 'failure',
              submitMessageError: t(messages.registrationWidgetLPErrorLogin.i18nKey, messages.registrationWidgetLPErrorLogin.defaults)
            }))
          } else {
            this.setState(state => ({
              loading: false,
              status: 'success',
              submitMessageSuccess: t(
                messages.thankYouForRegisteringWithUs.i18nKey,
                messages.thankYouForRegisteringWithUs.defaults,
              ),
            }))
          }
        })
        .catch(error => {
          this.setState(state => ({
            loading: false,
            status: '',
          }))
        })
    } else if (activeTab === 'new') {
      createLead(payload)
        .then(res => {
          if (res.result === 'error') {
            this.setState(state => ({
              loading: false,
              status: '',
            }))
          } else {
            this.setState(state => ({
              loading: false,
              status: 'success',
              submitMessageSuccess: t(
                messages.thankYouForRegisteringWithUs.i18nKey,
                messages.thankYouForRegisteringWithUs.defaults,
              ),
            }))
          }
        })
        .catch(error => {
          this.setState(state => ({
            loading: false,
            status: '',
          }))
        })
    }
  }

  renderExistingClientForm() {
    const {t, classes} = this.props
    const {companyObject} = this.context
    const {
      form: {useEmail, id, email, password},
      formErrors,
      showPassword,
    } = this.state

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container direction="row" justifyContent="center">
            <Grid item xs={9}>
              <Typography variant="body2">
                <Trans
                  {...messages.useBDSwissIdOrEmail}
                  values={{brandLabel: companies[companyObject.key].brandLabel}}
                />
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Grid
                container
                direction="row"
                justifyContent={isMobile() ? 'flex-start' : 'flex-end'}
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <SwitchButton
                    iOSSwitch
                    checked={useEmail}
                    onChange={e => this.handleFormChange('useEmail', e.target.checked)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {!useEmail && (
          <Grid item xs={12}>
            <TextField
              variant="standard"
              required
              fullWidth
              id="companyId"
              value={id}
              label={<Trans {...messages.companyId} values={{brandLabel: companies[companyObject.key].brandLabel}} />}
              onChange={e => this.handleFormChange('id', e.target.value)}
              error={get(formErrors, 'id', false)} />
          </Grid>
        )}
        {useEmail && (
          <Grid item xs={12}>
            <TextField
              variant="standard"
              required
              fullWidth
              id="email"
              value={email}
              label={t(messages.email.i18nKey, messages.email.defaults)}
              onChange={e => this.handleFormChange('email', e.target.value)}
              error={get(formErrors, 'email', false)} />
          </Grid>
        )}
        <Grid item xs={12}>
          <Password
            required
            fullWidth
            classes={classes}
            showPassword={showPassword}
            onChange={e => {
              this.handleFormChange('password', e.target.value)
            }}
            error={get(formErrors, 'password', false)}
            onClickShow={() =>
              this.setState(state => ({
                showPassword: !state.showPassword,
              }))
            }
            value={password}
          />
        </Grid>
      </Grid>
    )
  }

  renderNewClientForm() {
    const {supportEmail} = config
    const {t, classes} = this.props
    const {
      listCountries,
      form: {firstName, lastName, email, phone},
      formErrors,
      countryFlag,
      prefixFlag,
    } = this.state

    const countryList = listCountries || []

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            fullWidth
            required
            id="firstName"
            value={firstName}
            label={t(messages.firstName.i18nKey, messages.firstName.defaults)}
            onChange={e => this.handleFormChange('firstName', e.target.value)}
            error={get(formErrors, 'firstName', false)} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            fullWidth
            required
            id="lastName"
            value={lastName}
            label={t(messages.lastName.i18nKey, messages.lastName.defaults)}
            onChange={e => this.handleFormChange('lastName', e.target.value)}
            error={get(formErrors, 'lastName', false)} />
        </Grid>
        <Grid item xs={12} id="countryGrid">
          <CountriesSelect
            countryList={sortBy(countryList, 'label')}
            handleChangeField={this.handleChangeCountry.bind(this)}
            handleChange={this.handleFormChange.bind(this)}
            setStateOuter={this.handleOuterChange.bind(this)}
            errors={formErrors}
            value={countryFlag && countryFlag['label']}
            name="country"
            label={t(messages.countryLabel.i18nKey, messages.countryLabel.defaults)}
            showRequired
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={5} id="prefixGrid">
              <CountriesSelect
                phonePrefix
                showRequired
                countryList={countryList}
                handleChangeField={this.handleChangePrefix.bind(this)}
                handleChange={this.handleFormChange.bind(this)}
                setStateOuter={this.handleOuterChange.bind(this)}
                errors={formErrors}
                value={prefixFlag}
                name="prefix"
                label={t(messages.phoneCode.i18nKey, messages.phoneCode.defaults)}
              />
            </Grid>
            <Grid item xs={7}>
              <TextField
                variant="standard"
                fullWidth
                required
                id="phone"
                name="phone"
                value={phone}
                label={t(messages.phone.i18nKey, messages.phone.defaults)}
                error={formErrors.phone}
                onChange={e => this.handleFormChange('phone', e.target.value)}
                inputProps={{pattern: '[0-9]*', inputMode: 'numeric'}} />
              {get(formErrors, 'phone', false) && (
                <FormHelperText className={classes.errorMessage}>
                  <Trans {...messages.phoneValidation} />
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            required
            fullWidth
            id="email"
            value={email}
            label={t(messages.email.i18nKey, messages.email.defaults)}
            onChange={e => this.handleFormChange('email', e.target.value)} />
          {formErrors.emailExists && (
            <FormHelperText className={classes.errorMessage}>
              <Trans
                {...messages.emailExistsLogin}
                components={[
                  <Link
                    to={`/login?email=${encodeURIComponent(email)}`}
                    className={classes.link}
                    underline="hover">
                    <Trans {...messages.login} />
                  </Link>,
                ]}
              />
            </FormHelperText>
          )}
          {formErrors.closedAccount && (
            <FormHelperText className={classes.errorMessage}>
              <Trans
                {...messages.emailExistsClosed}
                components={[
                  <a href={`mailto:${supportEmail}`} className={classes.link}>
                    support
                  </a>,
                ]}
              />
            </FormHelperText>
          )}
          {formErrors.emailNotValidDomain && (
            <FormHelperText className={classes.errorMessage}>
              <Trans {...messages.emailNotValidDomain} />
            </FormHelperText>
          )}
          {formErrors.affiliateEmailExists && (
            <FormHelperText className={classes.errorMessage}>
              <Trans {...messages.affiliateEmailExists} />
            </FormHelperText>
          )}
          {get(formErrors, 'email', false) && (
            <FormHelperText className={classes.errorMessage}>
              <Trans {...messages.emailValidation} />
            </FormHelperText>
          )}
        </Grid>
      </Grid>
    )
  }

  render() {
    const {classes} = this.props
    const {activeTab, loading, status, submitMessageError, submitMessageSuccess} = this.state
    const {
      weblinks: {termsAndConditions},
    } = config

    return (
      <React.Fragment>
        <Box display="flex" flexDirection="column">
          <Box>
            <Tabs
              variant="fullWidth"
              value={activeTab}
              className={classes.tabsContainer}
              onChange={(_, tabIndex) => this.handleTabChanged(tabIndex)}
            >
              {map(registerWidgetLPTabs, tab => (
                <Tab key={tab.key} value={tab.key} label={<Trans {...tab.title} />} />
              ))}
            </Tabs>
          </Box>
          <Box display="flex" flexDirection="column" gap="8px" className={classes.formContainer}>
            <Grid container direction="column" xs={12} md={6}>
              <Grid item xs={12}>
                {activeTab === 'existing' && this.renderExistingClientForm()}
              </Grid>
              <Grid item xs={12}>
                {activeTab === 'new' && this.renderNewClientForm()}
              </Grid>
            </Grid>
            <Grid container direction="column" xs={12} md={6} spacing={3}>
              <Grid item xs={12}>
                <LoadingButton
                  fullWidth
                  id="loadingButton"
                  disabled={loading}
                  onClick={() => {
                    this.validateForm()
                  }}
                  hideProgressBar={!loading}
                >
                  <Trans {...messages.register} />
                </LoadingButton>
                {status === 'failure' && (
                  <FormHelperText classes={{root: classes.errorRoot}} className={classes.errorMessage}>
                    {submitMessageError}
                  </FormHelperText>
                )}
                {status === 'success' && (
                  <FormHelperText classes={{root: classes.successRoot}} className={classes.successMessage}>
                    {submitMessageSuccess}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" align="center">
                  <Trans {...messages.yourCapitalRisk} />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" align="center">
                  <Trans
                    {...messages.termsAndCondtionsApply}
                    components={[
                      <a target="_blank" rel="noopener noreferrer" href={termsAndConditions} className={classes.link}>
                        termsAndCondtionsApply
                      </a>,
                    ]}
                  />
                </Typography>
                <Typography variant="body2" align="center">
                  <Trans {...messages.consentOfStoringInformation} />
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </React.Fragment>
    )
  }
}

export default compose(withNamespaces(), withStyles(styles, {withTheme: true}))(RegisterWidgetLP)
