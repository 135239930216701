import React, {Component} from 'react'
import {graphql} from 'react-apollo'
import {withNamespaces, Trans} from 'react-i18next'
import Grid from '@mui/material/Grid'
import HeaderDialog from '../../Common/Dialog/HeaderDialog'
import config from '../../../config'
import {isMobile} from '../../../common/utils/browser'
import UploadDocument from '../../Settings/Documents/UploadDocument'
import {signupSteps} from '@bdswiss/common-enums'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import withStyles from '@mui/styles/withStyles'
import AppContext from '../../Common/contexts/AppContext'
import {LinearProgress} from '@mui/material'
import Hidden from '@mui/material/Hidden'
import PersonalDetails from '../../Settings/Profile/PersonalProfile/PersonalDetails'
import {CLIENT_DATA_QUERY} from '../../../graphql/queries'
import {get, includes, first, flowRight as compose} from 'lodash'
import Typography from '@mui/material/Typography'
import {Loading} from '../../Common/Loading'
import messages from '../../../assets/messages'
import {getMissingVerifications} from '../../../common/utils/general'

const styles = theme => ({
  root: {
    paddingTop: 40,
  },
  steps:{
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  mainBody:{
    maxWidth: theme.mainBodyWidth,
    margin:'auto',
    minHeight: '100%',
    position: 'relative',
  },
})

class RegisterStep3 extends Component {
  static contextType = AppContext

  state = {
    profileCompleted: false,
  }

  getSteps() {
    const {locale,companyObject} = this.context
    const steps = []

    for (const step of companyObject.registrationSteps)
      steps.push(signupSteps[step].localization.t(locale))

    return steps
  }

  steps(stepNumber) {
    const {classes} = this.props
    const steps = this.getSteps()
    return (
      <div>
        <Stepper activeStep={stepNumber-1} alternativeLabel classes={{root:classes.steps}}>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    )
  }

  handleChangeParent (name, value) {
    this.setState(state => ({[name]: value}))
  }

  render() {
    const {history, classes, loadingProp, loading, viewer} = this.props
    const {profileCompleted} = this.state
    const {accountVerification} = config

    if (loading && !viewer) return <Loading />

    const {companyObject} = this.context
    const stepNumber = companyObject.registrationSteps.length
    const electronicVerification = viewer && get(viewer, 'eIdVerificationApplicable')
    const missingVerifications = getMissingVerifications(viewer,first(get(viewer, 'appropTests')),get(viewer, 'globalQuestionnaire'),'',accountVerification)
    const  profileCompletedFlag = profileCompleted || !includes(missingVerifications,'profile')

    return (
      <div className={classes.mainBody}>
        <HeaderDialog />
        {loadingProp && <LinearProgress />}
        {!loadingProp &&
          <Grid container>
            {!isMobile() && <Hidden smDown>
              <Grid item sm={3} />
            </Hidden>}
            <Grid item sm={8} className={classes.root}>
              <Grid container>
                <Grid item xs={12}>
                  {this.steps(stepNumber)}
                </Grid>
                {(electronicVerification && (!profileCompletedFlag)) ?
                  <Grid item xs={12}>
                    <Typography variant="body2">
                      <Trans {...messages.completeProfileGDC} />
                    </Typography>
                    <PersonalDetails register handleChangeParent={this.handleChangeParent.bind(this)}/>
                  </Grid>
                  : <Grid item xs={12}>
                    <UploadDocument register history={history}/>
                  </Grid>
                }
              </Grid>
            </Grid>
          </Grid>
        }
      </div>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading}, data}) => ({
      error,
      loading,
      viewer: get(data, 'viewer'),
    })
  }
  ),
)(RegisterStep3)
