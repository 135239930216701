import {every, flowRight as compose, get} from 'lodash'
import PropTypes from 'prop-types'
import React, {Component} from 'react'
import Grid from '@mui/material/Grid'
import Input from '@mui/material/Input'
import {frontends} from '@bdswiss/common-enums'
import withStyles from '@mui/styles/withStyles'
import {withNamespaces, Trans} from 'react-i18next'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import messages from '../../../../assets/messages'
import {getPlatform, getSourceBasedOnLocationPrevPath, logEventCustomParams} from '../../../../common/utils'
import {withCreateDeposit, PaymentActionButton} from './helpers'

const style = (theme) => ({
  formControl: {
    margin: theme.spacing(1)
  },
  bold: {
    fontWeight: 500,
  },
  textCenter: {
    textAlign: 'justify'
  }
})

class CardpayCryptoProvider extends Component {

  state = {gettingUrl: false, form: {rollbackAddress: ''}, errors: {rollbackAddress: ''}}

  handleChange(key, value) {
    const {form} = this.state
    this.setState({
      form: {
        ...form,
        [key]: value
      }
    }, () => this.validateFields())
  }

  validateFields() {
    const {form} = this.state
    const errors = {}
    Object.keys(form).forEach((k) => {
      errors[k] = !form[k] ? messages.requiredField : ''
    })
    this.setState({errors})
    return errors
  }

  getUrl() {
    const {providerProperties: {provider, paymentKey, id: paymentOptionId}, account, amount, onError, useVirtualPaymentPage, bonusAmount, bonusTerms,
      history: {location}} = this.props

    const errors = this.validateFields()
    if (every(errors)) {
      return
    }

    this.setState({gettingUrl: true})

    const firebaseParams = {
      source: getSourceBasedOnLocationPrevPath(get(location, 'state', {prevPath: get(location, 'pathname')})),
      login: account.id,
      currency: account.currency,
      vendor: provider,
      amount,
    }
    logEventCustomParams('depositAttempted', firebaseParams)

    const variables = {
      accountId: account.id,
      amount,
      vendor: provider,
      paymentOptionId,
      frontend: frontends.web2.value,
      args: JSON.stringify({
        platform: getPlatform(),
        paymentKey,
        useVirtualPaymentPage,
        rollbackAddress: this.state.form.rollbackAddress,
        bonusAmount,
        bonusTerms,
      }),
    }

    this.props.createDepositRequest({variables})
      .then(({data: {newDeposit}}) => {
        const {payment: {url}} = newDeposit
        window.location = url
      })
      .catch((e) => {
        this.setState({gettingUrl: false})
        onError && onError(e)
      })
  }

  render() {
    const {classes, canProceed} = this.props
    const {gettingUrl, form: {rollbackAddress}, errors} = this.state
    // const {forcedCompany} = config
    // const companyObject = findCompany(forcedCompany)
    // const {brandLabel: companyLabel} = companyObject

    return (
      <React.Fragment>
        <Grid container direction="row" spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <FormControl variant="standard" className={classes.formControl} fullWidth>
              <InputLabel htmlFor="rollback-address">
                <Trans {...messages.rollbackAddress} />
              </InputLabel>
              <Input
                id="rollback-address"
                type="text"
                error={!!errors.rollbackAddress}
                value={rollbackAddress}
                onChange={(e) => this.handleChange('rollbackAddress', e.target.value)}
              />
              {errors.rollbackAddress &&
                <FormHelperText className={classes.error}><Trans {...errors.rollbackAddress} /></FormHelperText>
              }
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <PaymentActionButton
              loading={gettingUrl}
              disable={canProceed || every(errors)}
              onClick={() =>  this.getUrl()}
              noMargin
            />
          </Grid>
          {/* <Grid item xs={12}>
            <Typography align="center" variant="h6">
              <Trans {...messages.importantNotes} />
            </Typography>
            <List>
              <ListItem>
                <ListItemText className={classes.textCenter} primary={<Trans {...messages.rollbackAddressNote} components={[<span className={classes.bold}>Rollback Address</span>]} />} />
              </ListItem>
              <ListItem>
                <ListItemText className={classes.textCenter} primary={<Trans {...messages.cryptoDepositNote1} values={{company: companyLabel}} />} />
              </ListItem>
              <ListItem>
                <ListItemText className={classes.textCenter} primary={<Trans {...messages.cryptoDepositNote2} />} />
              </ListItem>
              <ListItem>
                <ListItemText className={classes.textCenter} primary={<Trans {...messages.cryptoDepositNote6} />} />
              </ListItem>
              <ListItem>
                <ListItemText className={classes.textCenter} primary={<Trans {...messages.cryptoDepositNote4} />} />
              </ListItem>
              <ListItem>
                <ListItemText className={classes.textCenter} primary={<Trans {...messages.cryptoDepositNote5} />} />
              </ListItem>
            </List>
            <CompanyDetails company={company} />
          </Grid> */}
        </Grid>
      </React.Fragment>
    )
  }
}

CardpayCryptoProvider.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  amount: PropTypes.number.isRequired,
  providerProperties: PropTypes.shape({
    name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    paymentKey: PropTypes.string,
    paymentOptionId: PropTypes.number.isRequired,
  }).isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

export default compose(
  withCreateDeposit,
  withStyles(style),
  withNamespaces(),
)(CardpayCryptoProvider)
