import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'
import classNames from 'classnames'
import {withNamespaces, Trans} from 'react-i18next'
import {get, countBy, filter, map, isEmpty, includes, last, orderBy, flowRight as compose, find, first, ceil, lowerCase, every, isNull, values, omit} from 'lodash'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import {graphql} from 'react-apollo'
import Grid from '@mui/material/Grid'
import Done from '@mui/icons-material/Done'
import Missing from '@mui/icons-material/Clear'
import Avatar from '@mui/material/Avatar'
import Card from '@mui/material/Card'
import {Loading} from '../../Common/Loading'
import ClientNotificationBar from '../../Accounts/ClientNotificationBar'
import messages from '../../../assets/messages'
import config from '../../../config'
import {DOCUMENT_CATEGORY_TYPES, getMissingVerifications, getMissingDocs} from '../../../common/utils/general'
import {PROFILE_SETTINGS_QUERY, CLIENT_DATA_QUERY, SPOA_AUTHORIZATION_QUERY, ACCOUNTS_QUERY} from '../../../graphql/queries'
import AppContext from '../../Common/contexts/AppContext'
import {kycStatuses, companies, dueDiligenceStatuses, suitabilityTestScoreResult, depositVendors, corporateStatuses, localDepositorStatuses, tradingStatuses, countries} from '@bdswiss/common-enums'
import NotificationBar from '../../Common/NotificationBar'
import {hasWalletProductAccount, isAffiliateClient, isCorporateClient}  from '../../../common/utils/accounts'
import SwapFreeDialog from './SwapFreeDialog'
import CustomTooltip from '../../Common/CustomTooltip'
import {logEventCustomParams} from '../../../common/utils/firebaseEvents'
import moment from 'moment'
import {profileSettingsCards} from '../../../common/utils/uioptions'
import Box from '@mui/material/Box'

const styles = theme => ({
  cardAvatar: {
    backgroundColor: theme.palette.freshGrey.backgroundColor,
    [theme.breakpoints.down('sm')]: {
      width: '40px',
      height: '40px',
      lineHeight: 37,
    }
  },
  notValid: {
    fontSize: '12px',
    color: theme.palette.red.color,
  },
  valid: {
    color: theme.palette.green.color,
    fontSize: '12px',
    fontWeight:400
  },
  underReview: {
    color: theme.palette.yellow.color,
    fontSize: '12px',
    fontWeight:400
  },
  pending: {
    color: theme.palette.yellow.color,
    fontSize: 14,
    fontWeight:400
  },
  approved: {
    color: theme.palette.green.color,
    fontSize: '12px',
    fontWeight:400
  },
  rejected: {
    color: theme.palette.error.main,
    fontSize: 14,
    fontWeight:400
  },
  statusIcon: {
    verticalAlign: 'middle',
    fontSize: 14,
    marginRight:8
  },
  doneIcon: {
    color: theme.palette.green.color
  },
  removeIcon: {
    color:theme.palette.error.main
  },
  subtitle: {
    color: theme.palette.grey.color,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
  },
  toolTip:{
    color: theme.palette.secondary.light,
    fontSize:14,
    fontWeight:300,
    padding:3
  },
  tool: {
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      right: 0,
      top: -15
    }
  },
  toolOuter: {
    [theme.breakpoints.down('sm')]: {
      position: 'relative'
    }
  },
  disabledCard:{
    opacity:0.5
  },
  pendingRegistration: {
    color: theme.palette.yellow.color,
    fontSize: '12px',
  },
  submitted: {
    color: theme.palette.yellow.color,
    fontSize: '12px',
  },
  terminated: {
    color: theme.palette.error.main,
    fontSize: 12,
    fontWeight:400
  },
  cardRoot: {
    position: 'relative',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      minWidth: theme.spacing(70)
    },
  },
  cardContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(2),
    }
  },
  cardTooltipContainer: {
    width: 'auto',
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      top: theme.spacing(1),
      right: theme.spacing(1),
    }
  },
  cardTitle: {
    fontWeight: 400,
    fontSize: theme.spacing(2),
    lineHeight: theme.spacing(3),
  },
  cardTitleSubtitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  }
})

class Profile extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    const {viewer} = props
    this.state = {
      visibleCategoryTypes: filter(DOCUMENT_CATEGORY_TYPES, (t) => t.show(viewer) && t.mandatory(viewer)),
      arrowRef: null,
    }
  }


  handleArrowRef = node => {
    this.setState({
      arrowRef: node,
    })
  }

  getOkDocs() {
    const {visibleCategoryTypes} = this.state
    const missingDocs = getMissingDocs(this.props.viewer)
    return visibleCategoryTypes.length - (get(countBy(missingDocs), 'true') || 0)
  }

  getKYCTooltip(kycStatusApproved) {
    const {classes, viewer: {pendingUploadDocuments}} = this.props
    const missingDocs = getMissingDocs(this.props.viewer)

    return (
      <div>
        {!kycStatusApproved && map(missingDocs, (missing, key) => {
          const docStatus = get(pendingUploadDocuments[key], 'status', '')
          const caption = missing ? messages[`${key}Title`] : messages[`${key}Title${docStatus}`]

          return <div key={key} className={classes.toolTip}>
            {!missing
              ? <Done className={classNames({[classes.statusIcon]: true, [classes.doneIcon]: true})}/>
              : <Missing className={classNames({[classes.statusIcon]: true, [classes.removeIcon]: true})}/>
            }
            <Trans {...caption} />
          </div>
        })}
        {kycStatusApproved &&  (<div className={classes.toolTip}>
          <Done className={classNames({[classes.statusIcon]: true, [classes.doneIcon]: true})}/>
          <Trans {...messages.completed} />
        </div>)}
      </div>
    )
  }

  getApprTestStatus(appropTest) {
    const {classes} = this.props
    return (
      <div>
        {!isEmpty(appropTest) ?
          <Typography variant='body1' className={classes.toolTip}>
            <Trans {...messages.appropriatenessTestSubmitted} /></Typography>
          :
          <Typography variant='body1' className={classes.toolTip}>
            <Trans {...messages.appropriatenessTestMissing} /></Typography>
        }
      </div>
    )
  }

  checkDetails(client) {
    return Boolean(client)
  }

  checkEconomicDetails(client) {
    return Boolean(get(client, 'globalQuestionnaire'))
  }

  checkPersonalProfile(classes, t, missingVerification,verificationFields) {
    let check =  0, totalFields=0

    if (includes(verificationFields, 'profile')) {
      totalFields++
      if (!includes(missingVerification, 'profile'))
        check++
    }
    if (includes(verificationFields, 'economicProfile')) {
      totalFields++
      if (!includes(missingVerification, 'economicProfile'))
        check++
    }

    const checkStatus =  <Typography variant="subtitle1" className={check === totalFields ? classes.valid : classes.notValid}>
      {check}/{totalFields} {t(messages.profileSettingsStatus.i18nKey,
        messages.profileSettingsStatus.defaults)}</Typography>
    return checkStatus
  }

  fieldStatus = (classes, t, missingVerification, verificationFields) => {
    let firstCheck

    if (includes(verificationFields, 'profile')) {
      if (!includes(missingVerification, 'profile')) {
        firstCheck = <div  className={classes.toolTip}>
          <Done className={classNames({[classes.statusIcon]: true, [classes.doneIcon]: true})}/>
          {t(messages.profileSettingsTooltip1.i18nKey, messages.profileSettingsTooltip1.defaults)}
        </div>
      } else {
        firstCheck = <div  className={classes.toolTip}>
          <Missing className={classNames({[classes.statusIcon]: true, [classes.removeIcon]: true})}/>
          {t(messages.profileSettingsTooltip1Pending.i18nKey, messages.profileSettingsTooltip1Pending.defaults)}
        </div>
      }
    }

    let secondCheck
    if (includes(verificationFields, 'economicProfile')) {
      if (!includes(missingVerification, 'economicProfile')) {
        secondCheck = <div  className={classes.toolTip}>
          <Done className={classNames({[classes.statusIcon]: true, [classes.doneIcon]: true})}/>
          {t(messages.profileSettingsTooltip2.i18nKey, messages.profileSettingsTooltip2.defaults)}
        </div>
      } else {
        secondCheck = <div  className={classes.toolTip}>
          <Missing className={classNames({[classes.statusIcon]: true, [classes.removeIcon]: true})}/>
          {t(messages.profileSettingsTooltip2Pending.i18nKey, messages.profileSettingsTooltip2Pending.defaults)}
        </div>
      }
    }

    return (<div>{firstCheck}{secondCheck}</div>)
  }

  appropTestPath() {
    const {history,  match: {path}, viewer:{canProcessAppropTest}} = this.props
    const {companyObject, clientId} = this.context
    logEventCustomParams('apStarted_web', {entity: get(companyObject, 'label'), userId: clientId})
    if (canProcessAppropTest) {
      history.push(`${path}/appropriateness-test`)
    }
  }

  dueDiligencePath() {
    const {history,  match: {path}} = this.props
    if (!this.restrictDueDiligence()) {
      history.push(`${path}/due-diligence`)
    }
  }

  portfolioManagementPath() {
    const {history,  match: {path},} = this.props
    history.push(`${path}/portfolio-management`)
  }

  getDueDiligenceStatus(dueDiligence) {
    const {classes} = this.props
    let status
    if (get(dueDiligence,'status') === dueDiligenceStatuses.approved.key) {
      status = messages.dueDiligenceSubmitted
    } else if (get(dueDiligence,'status') === dueDiligenceStatuses.rejected.key) {
      status = messages.dueDiligenceRejected
    } else if (get(dueDiligence,'status') === dueDiligenceStatuses.pending.key) {
      status = messages.dueDiligenceSubmitted
    } else if (get(dueDiligence,'status') === dueDiligenceStatuses.underReview.key) {
      status = messages.dueDiligenceUnderReview
    } else {
      status = messages.missing
    }
    return (
      <div>
        {<Typography variant='body1' className={classes.toolTip}>
          <Trans {...status} />
        </Typography>}
      </div>
    )
  }

  getSuitabilityTestStatus(suitabilityTest) {
    const {classes} = this.props
    let status
    if (get(suitabilityTest,'scoreResult') === suitabilityTestScoreResult.high.key) {
      status = suitabilityTestScoreResult.high.label
    } else if (get(suitabilityTest,'scoreResult') === suitabilityTestScoreResult.medium.key) {
      status = suitabilityTestScoreResult.medium.label
    } else if (get(suitabilityTest,'scoreResult') === suitabilityTestScoreResult.low.key) {
      status = suitabilityTestScoreResult.low.label
    } else {
      status = messages.missing
    }
    return (
      <div>
        {<Typography variant='body1' className={classes.toolTip}>
          {!isEmpty(suitabilityTest) ? status : <Trans {...messages.missing} />}
        </Typography>}
      </div>
    )
  }

  getLocalDepositorStatus(localDepositorStatus) {
    const {classes} = this.props
    const status = get(localDepositorStatuses,`[${localDepositorStatus}].label`) || messages.missing
    return (
      <div>
        <Typography variant='body1' className={classes.toolTip}>
          {!isEmpty(localDepositorStatus) ? status : <Trans {...messages.missing} />}
        </Typography>
      </div>
    )
  }

  restrictDueDiligence() {
    const {dueDiligence} = this.props
    const {clientType} = this.context
    return isAffiliateClient(clientType) && !isEmpty(dueDiligence)
  }

  handleChangeParent(name, value) {
    this.setState({[name]: value})
  }

  tooltipTitle(card, verificationFields, missingVerification, kycStatusApproved, corporateStatus) {
    const {dueDiligence, viewer: {localDepositorStatus}} = this.props

    if (card.isCorporate) return corporateStatus

    switch (card.key) {
      case 'kyc': {
        return this.getKYCTooltip(kycStatusApproved)
      }
      case 'dueDiligence': {
        return this.getDueDiligenceStatus(dueDiligence)
      }
      case 'localDepositor': {
        return this.getLocalDepositorStatus(localDepositorStatus)
      }
      default: {
        return ''
      }
    }
  }

  tooltipContent(card, verificationFields, missingVerification, kycStatusApproved, okDocs, corporateStatus) {
    const {classes, t, viewer, dueDiligence, hasGrantedSpoa, suitabilityTest,
      viewer: {swapFree, localDepositorStatus}} = this.props
    const {gdcExcludedCountries} = config
    const {visibleCategoryTypes} = this.state

    if (card.isCorporate) return <Typography variant="caption" className={classes[lowerCase(corporateStatus)]}>{corporateStatus}</Typography>
    const hideDocuments = get(viewer, 'eIdVerificationApplicable') && !includes(gdcExcludedCountries, viewer['address']['country'])
    const dueDiligenceStatus = get(dueDiligence, 'status')

    switch (card.key) {
      case 'profile':
      case 'economicProfile':
      case 'appropriatenessTest': {
        return includes(missingVerification, card.key)
          ? <Typography className={classes.notValid} variant='caption'> <Trans {...messages.notCompleted} /> </Typography>
          : <Typography className={classes.valid} variant='caption'> <Trans {...messages.completed} /> </Typography>
      }
      case 'kyc': {
        return <Typography variant="caption" className={((!hideDocuments && okDocs === visibleCategoryTypes.length) || kycStatusApproved) ? classes.valid : classes.notValid}>
          {kycStatusApproved && t(messages.completed.i18nKey, messages.completed.defaults)}
          {!kycStatusApproved && !hideDocuments && (`${okDocs}/${visibleCategoryTypes.length} ${t(messages.documentSettingsStatus.i18nKey,
            messages.documentSettingsStatus.defaults)}`)}
          {!kycStatusApproved && hideDocuments && t(messages.missing.i18nKey, messages.missing.defaults)}
        </Typography>
      }
      case 'dueDiligence': {
        return <Typography variant="caption" className={!isEmpty(dueDiligence) ? classes[dueDiligenceStatus] : classes.notValid}>
          {!isEmpty(dueDiligence) ? <Trans {...messages[dueDiligenceStatus]}/> : <Trans {...messages.missing}/>}
        </Typography>
      }
      case 'spoa': {
        return <Typography variant="caption" className={hasGrantedSpoa ? classes.valid : classes.notValid}>
          {hasGrantedSpoa ? <Trans {...messages.granted} /> : <Trans {...messages.missing} />}
        </Typography>
      }
      case 'suitabilityTest': {
        return <Typography variant="caption" className={!isEmpty(suitabilityTest) ?
          classes.valid : classes.notValid}>
          {!isEmpty(suitabilityTest) ? t(messages.completed.i18nKey, messages.completed.defaults) :
            t(messages.missing.i18nKey, messages.missing.defaults)}
        </Typography>
      }
      case 'swapFree': {
        return <Typography variant="caption" className={swapFree ? classes.valid : classes.disabled}>
          {swapFree ? <Trans {...messages.enabled}/> : <Trans {...messages.disabled}/>}
        </Typography>
      }
      case 'localDepositor': {
        return <Typography variant="caption" className={!isEmpty(localDepositorStatus) ? classes[localDepositorStatus] : classes.notValid}>
          {!isEmpty(localDepositorStatus) ? t(messages[localDepositorStatus]?.i18nKey, messages[localDepositorStatus]?.defaults) :
            t(messages.missing.i18nKey, messages.missing.defaults)}
        </Typography>
      }
      default: {
        return ''
      }
    }
  }

  clickableCard(card) {
    const {viewer:{swapFree, canProcessAppropTest, localDepositorStatus}, history, location:{pathname}} = this.props
    const disableLocalDepositorClick = localDepositorStatus !== localDepositorStatuses.submitted.value

    if (card.isCorporate) return history.push({pathname: `/corporate/${card.key}`, state: {prevPath: pathname}})

    if (card.key === 'appropTest') {
      return canProcessAppropTest
    } else if (card.key === 'dueDiligence') {
      return !this.restrictDueDiligence()
    } else if (card.key === 'swapFree') {
      return !swapFree && this.setState({showSwapFreePopup: true})
    } else if (card.key === 'localDepositor') {
      return disableLocalDepositorClick
    }

    return true
  }

  render() {
    const {loading, viewerLoading, spoaLoading, accountsLoading, classes, t, appropTest, history, match: {path}, canGrantSpoa,
      hasPortfolioManagement, economicProfile, viewer, dueDiligence, accounts,
      viewer: {depositedAmount, allowSwapFree, locale, isLocalDepositor, localDepositorStatus, address}} = this.props

    const AutomateSwapFreeCountry = get(address, 'country')
    const automateSwapFree = countries[AutomateSwapFreeCountry]?.swapFreeAutomate
    let swap_free_old_logic = false
    if (!moment(viewer.registration).isAfter(config.common.swap_free_old_logic_date) && automateSwapFree) {
      swap_free_old_logic = true
    }
    if (loading || viewerLoading || spoaLoading || accountsLoading) return <Loading />
    const {showSwapFreePopup} = this.state

    const okDocs = this.getOkDocs()

    const {productConfigs, accountVerification, failedAppropTestTrainingPeriod} = config
    const {clientType, blockedDeposit, companyObject, tradingStatus, themePreference} = this.context

    let verificationFields
    if (blockedDeposit && clientType && productConfigs[clientType]) {
      verificationFields  = productConfigs[clientType].accountVerification
    } else {
      verificationFields = accountVerification
    }

    const latestAptest = first(appropTest)
    const missingVerification = getMissingVerifications(viewer, latestAptest, economicProfile, '', verificationFields)

    const kycStatusApproved = (viewer['kycStatus'] === kycStatuses.approved.key)

    const canProcessAppropTest = get(viewer,'canProcessAppropTest')
    const appropTestRequired = companies[companyObject.key].requiresAPTest && verificationFields.indexOf('appropriatenessTest') > 0
    const paymentMethods = get(viewer, 'paymentMethods')
    const firstPendingWireTransfer = !depositedAmount && find(paymentMethods, {fundingCategory: depositVendors.bankWire.value,
      confirmed: false})

    const clientDeposited = depositedAmount > 0 || firstPendingWireTransfer
    const showDueDiligence = config.common.dueDilligenceRequired[clientType] || !isEmpty(dueDiligence)
    const isCorporate = isCorporateClient(clientType)
    const corporateStatus = get(viewer, 'corporateStatus') && corporateStatuses[viewer.corporateStatus].localization.t(locale)

    const today = moment()
    const lastDayAppropTest = latestAptest && moment(get(latestAptest, 'createdAt')).add(failedAppropTestTrainingPeriod, 'days')
    const daysLeft = lastDayAppropTest && ceil(lastDayAppropTest.diff(today, 'days', true))
    const hideCoporateCards = every(values(omit(viewer.corporateDetails, ['__typename'])), (value) => isNull(value))
    const showWarningNotification = tradingStatus === tradingStatuses.warning.value

    return (
      <React.Fragment>
        <Grid container>
          {showWarningNotification && <ClientNotificationBar/>}
          {appropTestRequired && !canProcessAppropTest && <NotificationBar
            status='error'
            title={<Trans {...messages.appropTestFailed} values={{company: companyObject.trademark, days: daysLeft}} />}
          />}
          {map(profileSettingsCards, (card, key) => {
            if (card.show(isCorporate, clientDeposited, appropTestRequired, hasWalletProductAccount(accounts), showDueDiligence,
              canGrantSpoa, hasPortfolioManagement, allowSwapFree, hideCoporateCards, isLocalDepositor, kycStatusApproved, automateSwapFree, swap_free_old_logic))
              return <Grid container key={key}>
                <Grid item xs={12} lg={6}>
                  <Card className={classes.cardRoot} onClick={() => this.clickableCard(card) ? (card.url) ? history.push(`${path}/${card.url}`) : this.clickableCard(card) : ''}>
                    <Box className={classes.cardContentContainer}>
                      <Box>
                        <Avatar className={classes.cardAvatar}>
                          <img className={classes.cardIcon} src={card[themePreference]} alt={key} />
                        </Avatar>
                      </Box>
                      <Box width='100%'>
                        <Typography component="h3" variant="subtitle1" className={classes.cardTitle}>
                          {messages[card.title(locale)] ? t(messages[card.title(locale)].i18nKey, messages[card.title(locale)].defaults) : card.title(locale)}
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom  className={classes.subtitle}>
                          {messages[card.subtitle(locale, localDepositorStatus)]
                            ? t(messages[card.subtitle(locale, localDepositorStatus)].i18nKey,
                              messages[card.subtitle(locale, localDepositorStatus)].defaults)
                            : card.subtitle(locale, localDepositorStatus)
                          }
                        </Typography>
                      </Box>
                    </Box>
                    <Box className={classes.cardTooltipContainer}>
                      <CustomTooltip
                        placement='top-start'
                        title={this.tooltipTitle(card, verificationFields, missingVerification, kycStatusApproved, corporateStatus)}
                      >
                        <span>{this.tooltipContent(card, verificationFields, missingVerification, kycStatusApproved, okDocs, corporateStatus)}</span>
                      </CustomTooltip>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
          })}
        </Grid>
        {showSwapFreePopup && <SwapFreeDialog showSwapFreePopup={showSwapFreePopup} locale={locale}
          okDocs={okDocs} kycStatusApproved={kycStatusApproved} handleChangeParent={this.handleChangeParent.bind(this)}/>}
      </React.Fragment>
    )
  }
}

Profile.propTypes = {
  classes: PropTypes.object.isRequired,
}
export default compose (
  withNamespaces(),
  withRouter,
  withStyles(styles),
  graphql(PROFILE_SETTINGS_QUERY, {
    props: ({data: {error, loading}, data}) => {
      const economicProfile = get(data, 'viewer.globalQuestionnaire')
      const appropTest = get(data, 'viewer.appropTests')
      const dueDiligences = get(data, 'viewer.dueDiligences')
      let dueDiligence = last(orderBy(filter(dueDiligences, ['status', 'approved']), ['createdAt']))
      if (!dueDiligence) {
        dueDiligence = last(orderBy(dueDiligences, ['createdAt']))
      }
      const suitabilityTests = get(data, 'viewer.suitabilityTests')
      let suitabilityTest = last(orderBy(filter(suitabilityTests, ['status', 'approved']), ['createdAt']))
      if (!suitabilityTest) {
        suitabilityTest = last(orderBy(suitabilityTests, ['createdAt']))
      }
      return {
        error,
        loading,
        economicProfile,
        appropTest,
        dueDiligence: dueDiligence || {},
        suitabilityTest: suitabilityTest || {},
        documents: get(data, 'viewer.documents', []),
        canGrantSpoa: get(data, 'viewer.canGrantSpoa', false),
        hasPortfolioManagement: get(data, 'viewer.hasPortfolioManagement', false)
      }
    }
  }),
  graphql(SPOA_AUTHORIZATION_QUERY, {
    props: ({data: {error, loading: spoaLoading}, data}) => ({
      error,
      spoaLoading,
      spoaRemoteClient: get(data, 'viewer.spoaRemoteClient'),
      hasGrantedSpoa: Boolean(get(data, 'viewer.spoaRemoteId')),
    })
  }),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading: viewerLoading}, data}) => ({
      error,
      viewerLoading,
      viewer: get(data, 'viewer')
    })
  }),
  graphql(ACCOUNTS_QUERY, {
    props: ({data: {loading: accountsLoading, error}, data}) => ({
      accountsLoading,
      error,
      accounts: get(data, 'viewer.accounts'),
    })
  }),
)(Profile)
