import React from 'react'
import {graphql} from 'react-apollo'
import withStyles from '@mui/styles/withStyles'
import Grid from '@mui/material/Grid'
import {withNamespaces, Trans} from 'react-i18next'
import {get, omit, flowRight as compose} from 'lodash'
import messages from '../../../assets/messages'
import {ACCOUNTS_QUERY} from '../../../graphql/queries'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import {DesktopWindows, Smartphone} from '@mui/icons-material'
import config from '../../../config'
import Images from '../../Common/Images'
import Paper from '@mui/material/Paper'
import classNames from 'classnames'
import Typography from '@mui/material/Typography'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import ClientNotificationBar from '../ClientNotificationBar'
import PageSubTitle from '../../Common/PageSubTitle'
import {isIos, isAndroid, isMobile} from '../../../common/utils/browser'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import AppContext from '../../Common/contexts/AppContext'
import {accountTypes, mt4Servers, whiteLabels, mt5Servers} from '@bdswiss/common-enums'
import {isWhiteLabel} from '../../../common/utils'
import {getForcedServerName} from '../../../common/utils/accounts'
import {Loading} from '../../Common/Loading'
import CustomTooltip from '../../Common/CustomTooltip'
import {logEventCustomParams} from '../../../common/utils/firebaseEvents'

const styles = theme => ({
  textBlue:{
    color: `${theme.palette.primary.main}`
  },
  listItem:{
    paddingLeft: '0px'
  },
  icon: {
    margin:'0',
    width: '24px',
    height: '24px'
  },
  informationLink:{
    paddingTop: '20px'
  },
  loginText:{
    color: theme.palette.secondary.main,
    fontSize: '16px'
  },
  loginDetails:{
    fontWeight: '300',
    lineHeight: '2.5rem'
  },
  textItalic:{
    fontStyle: 'italic'
  },
  textCenter:{
    textAlign: 'center'
  },
  mt4download:{
    padding: '1.5rem 1.5rem 0',
    maxWidth: '100%',
  },
  copyButton: {
    padding: '0',
    '&:hover':{
      backgroundColor:'transparent'
    }
  },
  tooltipText:{
    color: '#ffffff',
  },
  tableCell:{
    paddingLeft:0,
    borderBottom: 'none',
    paddingBottom: 9,
    paddingTop: 5,
  },
  table:{
    marginBottom: 40,
    marginTop: 15
  },
  list: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 5
    }
  },
  subTitle: {
    margin: 0,
  },
})

class ForexAccountDownloadTrader extends React.Component {
  static contextType = AppContext
  state = {
    arrowRef: null,
  }

  handleArrowRef = node => {
    this.setState({
      arrowRef: node,
    })
  }

  getLink = (account, config, item) => {
    const {downloadTrader} = config
    const traderLinks = accountTypes[account.__typename] && downloadTrader[accountTypes[account.__typename].category]
      ? downloadTrader[accountTypes[account.__typename].category] : downloadTrader.default

    if (item === 'iosAndroid' && isIos()) {
      return traderLinks.appUrls.ios
    } else if (item === 'iosAndroid' && isAndroid()) {
      return traderLinks.appUrls.android
    } else {
      return traderLinks.Links[item]
    }
  }

  getIcon = (item, classes) => {
    switch (item) {
      case 'windows': return  <img className={classes.icon} alt='windows' src={Images['windows-brands.svg']}  />
      case 'iosAndroid': return <Smartphone />
      case 'mac': return  <img className={classes.icon} alt='mac' src={Images['apple-brands.svg']} />
      default: return  <DesktopWindows />
    }
  }

  trackDownloadEvent(item, productType) {
    const params = {
      os: item,
      type: productType,
      userId: get(this.context, 'clientId'),
    }
    return logEventCustomParams('downloadTradingPlatform', params)
  }

  render() {
    const {classes, account, t, loading} = this.props
    const {downloadTrader, brand, removeMT4Information} = config
    const {locale, companyObject} = this.context
    if (loading) return <Loading />
    const traderConfig = accountTypes[account.__typename] && downloadTrader[accountTypes[account.__typename].category]
      ? downloadTrader[accountTypes[account.__typename].category] : downloadTrader.default
    const links = !isMobile() ? omit(traderConfig.Links, 'iosAndroid') : traderConfig.Links

    const {forcedServerName, forcedServerExampleImage} = getForcedServerName(account, Images)
    const whiteLabel = isWhiteLabel()
    logEventCustomParams('viewTradingPlatformDetails', {
      userId: get(this.context, 'clientId'),
    })
    const productType = traderConfig.shortLabel
    return (
      <Grid container spacing={isMobile() ? 2 : 3}>
        <ClientNotificationBar account={account} />
        <Grid item sm={12} md={6}>
          <PageSubTitle customClasses={{header: classes.subTitle}}>
            <Trans {...messages.downloadMT4Subtitle} values={{product: productType}}/>
          </PageSubTitle>
          <List className={classes.list}>
            {Object.keys(links).map((item, index) => (
              <a href={this.getLink(account, config, item)} key={index} target='_blank' rel="noopener noreferrer" onClick={()=> this.trackDownloadEvent(item, productType)}>
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classNames(classes.textBlue, classes.icon)}>{this.getIcon(item,classes)}</ListItemIcon>
                  <ListItemText inset primary={<Trans {...messages[item]} values={{product: productType}}/>} className={classes.textBlue} disableTypography={true}>
                  </ListItemText>
                </ListItem>
              </a>
            ))}
          </List>
          {!removeMT4Information && traderConfig.downloadTraderInfo && <Typography variant='body1' className={classes.informationLink}><a className={classNames(classes.textBlue, classes.loginDetails)} href={traderConfig.downloadTraderInfo.replace('{lang}', locale)} target='_blank' rel="noopener noreferrer" onClick={()=> logEventCustomParams('MT4ViewMoreInfo', {
            userId: get(this.context, 'clientId'),
          })}><Trans {...messages.mt4MoreInfo} values={{product: productType}}/></a></Typography>}
        </Grid>
        <Grid item xs={12} md={6}>
          <PageSubTitle customClasses={{header: classes.subTitle}}>{t(messages.loginDetails.i18nKey, messages.loginDetails.defaults)}</PageSubTitle>
          <Grid container>
            <Table className={!isMobile()?classes.table:''}>
              <TableBody>
                <TableRow>
                  <TableCell className={classNames(classes.tableCell, classes.loginText)}>{t(messages.login.i18nKey, messages.login.defaults)}</TableCell>
                  <TableCell className={classes.tableCell}>
                    <CustomTooltip
                      title={<Typography variant='body1' className={classes.tooltipText}>{t(messages.clickToCopy.i18nKey, messages.clickToCopy.defaults)}</Typography>}
                      placement={'right'}
                    >
                      <React.Fragment>
                        <CopyToClipboard text={account.login}>
                          <Button className={classNames(classes.copyButton, classes.loginDetails)}>
                            <Typography variant='body1'>{account.login}</Typography>
                          </Button>
                        </CopyToClipboard>
                      </React.Fragment>
                    </CustomTooltip>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classNames(classes.tableCell, classes.loginText)}>{t(messages.password.i18nKey, messages.password.defaults)}</TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography variant='body1' className={classNames(classes.textItalic, classes.loginDetails)}>
                      *<Trans {...messages.yourPasswordText} />*
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classNames(classes.tableCell, classes.loginText)}>{t(messages.server.i18nKey, messages.server.defaults)}</TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography variant='body1' className={classes.loginDetails}>
                      {forcedServerName || ((account.server && (!whiteLabel || (whiteLabel && get(whiteLabels[brand], 'internal')))) ?
                        (get(mt4Servers[account.server], `name[${companyObject['key']}]`|| get(mt5Servers, [account.server, 'name', companyObject['key']], ''))) :
                        (account.isDemo ? traderConfig.serverName.demo : whiteLabel && !get(whiteLabels[brand], 'internal') ? get(traderConfig.serverName, account.server) : traderConfig.serverName.real)) || (get(mt4Servers[account.server], `name[${companyObject['key']}]`)|| get(mt5Servers, [account.server, 'name', companyObject['key']], ''))}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {!isMobile() && !get(traderConfig, 'exampleImages.showEmpty') && <Paper>
              <img alt="download" className={classes.mt4download} src={forcedServerExampleImage || (account.isDemo ?
                Images[traderConfig.exampleImages.demo] :
                (Images[traderConfig.exampleImages[account.server]] || Images[traderConfig.exampleImages.real] ))} />
              <Typography variant='body1' className={classNames(classes.textItalic, classes.textCenter,classes.loginDetails)}>
                <Trans {...messages.mt4Example} values={{product: productType}}/>
              </Typography>
            </Paper>
            }
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default compose(
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
  graphql(ACCOUNTS_QUERY, {
    props: ({data: {error, loading}, data, ownProps: {match}}) => {
      const {accountId} = match.params
      const account = get(data, 'viewer.accounts', []).find(account => account.id === Number(accountId))
      return {
        error,
        loading,
        account,
      }
    }
  })
)(ForexAccountDownloadTrader)
