import React, {Component} from 'react'
import {map, sortBy, isEmpty, filter, flowRight as compose, get} from 'lodash'
import PropTypes from 'prop-types'
import {Trans} from 'react-i18next'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import {frontends, nganLuongBankCodeOptions, nganLuongPaymentMethods} from '@bdswiss/common-enums'
import {getPlatform, getSourceBasedOnLocationPrevPath, logEventCustomParams} from '../../../../common/utils'
import messages from '../../../../assets/messages'
import {withCreateDeposit, PaymentActionButton} from './helpers'

class NganLuongProvider extends Component {
  state = {bankCode: '', paymentMethod: ''}

  getUrl() {
    const {
      providerProperties: {provider, paymentKey, id: paymentOptionId},
      account,
      amount,
      onError,
      useVirtualPaymentPage,
      bonusAmount,
      bonusTerms,
      history: {location}
    } = this.props
    const {bankCode, paymentMethod} = this.state

    this.setState({gettingUrl: true})

    const firebaseParams = {
      source: getSourceBasedOnLocationPrevPath(get(location, 'state', {prevPath: get(location, 'pathname')})),
      login: account.id,
      currency: account.currency,
      vendor: provider,
      amount,
    }
    logEventCustomParams('depositAttempted', firebaseParams)

    const variables = {
      accountId: account.id,
      amount,
      vendor: provider,
      paymentOptionId,
      frontend: frontends.web2.value,
      args: JSON.stringify({
        platform: getPlatform(),
        paymentKey,
        bankCode,
        paymentMethod,
        useVirtualPaymentPage,
        bonusAmount,
        bonusTerms,
      }),
    }

    this.props.createDepositRequest({variables})
      .then(({data: {newDeposit}}) => {
        const {payment: {url}} = newDeposit
        window.location = url
      })
      .catch((e) => {
        this.setState({gettingUrl: false})
        onError && onError(e)
      })
  }

  handleBankCodeChange = ({target: {value}}) => this.setState({bankCode: value})

  handlePaymentMethodChange = ({target: {value}}) => this.setState({paymentMethod: value, bankCode: ''})

  render() {
    const {providerProperties: {providerBankCode}, canProceed} = this.props
    const {gettingUrl, bankCode, paymentMethod} = this.state
    const bankOptions = filter(nganLuongBankCodeOptions, (b) => b.methods.includes(paymentMethod))
    let providerPaymentMethod
    if (!paymentMethod && providerBankCode) {
      providerPaymentMethod = nganLuongPaymentMethods.ibOnline.value
      this.setState({paymentMethod: providerPaymentMethod, bankCode: providerBankCode.toLowerCase()})
    }

    return (
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12}>
          <FormControl variant="standard" fullWidth>
            <InputLabel htmlFor="payment_method"><Trans {...messages.paymentMethod} /></InputLabel>
            <Select
              variant="standard"
              value={paymentMethod}
              onChange={this.handlePaymentMethodChange}
              inputProps={{
                name: 'payment_method',
                id: 'payment_method',
              }}>
              {map(sortBy(nganLuongPaymentMethods, 'label'), o => <MenuItem value={o.value}
                key={o.key}>{o.label}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
        {!isEmpty(bankOptions) && <Grid item xs={12}>
          <FormControl variant="standard" fullWidth>
            <InputLabel htmlFor="bank_code"><Trans {...messages.bank} /></InputLabel>
            <Select
              variant="standard"
              value={bankCode}
              onChange={this.handleBankCodeChange}
              inputProps={{
                name: 'bank_code',
                id: 'bank_code',
              }}>{map(sortBy(bankOptions, 'label'), o => <MenuItem value={o.key} key={o.key}>{o.label}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>}
        <Grid item xs={12}>
          <PaymentActionButton
            disable={canProceed || (!paymentMethod || (paymentMethod && !isEmpty(bankOptions) && !bankCode))}
            loading={gettingUrl}
            onClick={() => this.getUrl()}
          />
        </Grid>
      </Grid>
    )
  }
}

NganLuongProvider.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  amount: PropTypes.number.isRequired,
  providerProperties: PropTypes.shape({
    name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    paymentKey: PropTypes.string,
    paymentOptionId: PropTypes.number.isRequired,
  }).isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

export default compose(withCreateDeposit)(NganLuongProvider)
