import 'react-app-polyfill/ie9'
import React from 'react'
import ReactDOM from 'react-dom/client'
import {ApolloProvider} from 'react-apollo'
import {BrowserRouter} from 'react-router-dom'
import * as serviceWorker from './serviceWorker'
import {BugsnagErrorBoundary} from './bugsnag'
import {getApolloClient} from './graphql/utils'
import './common/utils/polyfill'
import reportWebVitals from './reportWebVitals'
import AppProvider from './AppProvider'
import './index.css'
import './checkout.css'

const apolloClient = getApolloClient()

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<BugsnagErrorBoundary>
  <ApolloProvider client={apolloClient}>
    <BrowserRouter>
      <AppProvider />
    </BrowserRouter>
  </ApolloProvider>
</BugsnagErrorBoundary>)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
