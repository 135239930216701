import React, {Component} from 'react'
import {flowRight as compose, get} from 'lodash'
import withStyles from '@mui/styles/withStyles'
import Button from '@mui/material/Button'
import {Trans} from 'react-i18next'
import messages from '../../assets/messages'
import config from '../../config'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import {setCookie, isMobile} from '../../common/utils/browser'
import {CustomDialog} from './Dialog'
import PageTitle from './PageTitle'
import AppContext from '../Common/contexts/AppContext'
import {companies, regulators} from '@bdswiss/common-enums'

const styles = theme => ({
  regTitle:{
    fontWeight: 500,
    padding: '20px 0'
  },
  cancelButton:{
    margin: '0 15px',
  },
  regulatorPopupContent:{
    [theme.breakpoints.down('md')]: {
      '& h1':{
        fontSize:'1rem'
      },
      '& p':{
        fontSize: 12
      }
    }
  },
  regulatorPopupBtns:{
    [theme.breakpoints.down('md')]: {
      margin:'auto'
    }
  }
})

class RegulationDialog extends Component {
  static contextType = AppContext

  state = {
    open: true,
  }

  handleClose = () => {
    this.setState({open: false})
  }

  regulationAccepted() {
    this.handleClose()
    const cookieDomain = window.location.hostname.match(/[^.]+\.\w+$/) ?
      window.location.hostname.match(/[^.]+\.\w+$/)[0] : window.location.hostname
    setCookie('RegulationAccepted', new Date().toISOString(), 1, '.'+cookieDomain)
  }

  regulationNotAccepted() {
    const {euRegulation: {cancelationRedirect}} = config
    this.setState({open: false})
    window.location.href = cancelationRedirect
  }

  render() {
    const {classes} = this.props
    const {companyObject, locale} = this.context
    const regulator = get(companies[companyObject['key']], 'regulator')
    return (
      <div>
        <CustomDialog
          open={this.state.open}
          onClose={this.handleClose}
          maxWidth='lg'
          disableEscapeKeyDown
        >
          <Grid container spacing={isMobile() ? 1 : 3} className={classes.regulatorPopupContent}>
            <Grid item sm={12} align='center'>
              <PageTitle modal>
                <Trans {...messages.regulationDialogTitle} />
              </PageTitle>
            </Grid>
            <Grid item sm={12}>
              <Typography variant='body1'>
                <Trans {...messages.regulationDialogText1} values={{trademark:companyObject['trademark'],
                  domain:companyObject['domain'], regulator: get(regulators[regulator], 'label'), license: get(regulators[regulator], 'license'),
                  regulatorName: get(regulators[regulator], 'name').localization.t(locale)}}/>
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography variant='body1'>
                <Trans {...messages.regulationDialogText2} />
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography variant='body1'>
                <Trans {...messages.regulationDialogText3} values={{trademark:companyObject['trademark']}}/>
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography variant='body1'>
                <Trans {...messages.regulationDialogText4} />
              </Typography>
            </Grid>
            <Grid item sm={12} className={classes.regulatorPopupBtns} align={isMobile() ? 'center' : 'right'}>
              <Button onClick={() =>this.regulationNotAccepted()}
                color="secondary"
                variant="contained"
                size={isMobile() ? 'medium' : 'large'}
                className={classes.cancelButton}
              >
                <Trans {...messages.cancel} />
              </Button>
              <Button
                onClick={() =>this.regulationAccepted()}
                color="primary"
                variant="contained"
                size={isMobile() ? 'medium' : 'large'}
              >
                <Trans {...messages.continue} />
              </Button>
            </Grid>
          </Grid>
        </CustomDialog>
      </div>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
)(RegulationDialog)
