import React, {Component} from 'react'
import {isEmpty, some, omit, range, padStart, map, get, filter, every, flowRight as compose} from 'lodash'
import {graphql} from 'react-apollo'
import withStyles from '@mui/styles/withStyles'
import {withNamespaces, Trans} from 'react-i18next'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import messages from '../../../assets/messages'
import classNames from 'classnames'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import Fab from '@mui/material/Fab'
import Images from '../../Common/Images'
import FormHelperText from '@mui/material/FormHelperText'
import LoadingButton from '../../Common/LoadingButton'
import {CLIENT_DATA_QUERY} from '../../../graphql/queries'
import {CREATE_SUPPORT_TICKET} from '../../../graphql/mutations'
import config from '../../../config'
import {validateEmail, validateDate} from '../../../common/utils/validations'
import AppContext from '../../Common/contexts/AppContext'
import {isMobile} from '../../../common/utils'
import {languages} from '@bdswiss/common-enums'
import moment from 'moment'
import {getLocaleMoment, isDarkTheme} from '../../../common/utils/general'
import {fnsLocales, momentLocales} from '../../../common/utils/uioptions'
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3'
import * as dateFnsLocales from 'date-fns/locale'

function generateOptions(start,end) {
  const generateOptions = {}
  const options = range(start,end+1).map((option) => ({key: option, label: padStart(option, 2, '0')}))
  options.forEach((y) => {
    generateOptions[y.key] = y
  })
  return generateOptions
}

const styles = (theme) => ({
  blackPaper: {
    backgroundColor: theme.palette.common.black,
  },
  blackWhiteText: {
    color: `${theme.palette.background.default}`,
  },
  blackGreyText: {
    'font-weight': 'inherit',
    color: theme.palette.grey[400],
  },
  blackSelectItem: {
    color: theme.palette.grey[400],
    '&:hover':{
      backgroundColor: theme.palette.secondary.main,
      color:  theme.palette.secondary.dark,
    },
    '&$blackSelectedItem':{
      backgroundColor: theme.palette.secondary.main,
      color:  theme.palette.secondary.dark,
    }
  },
  blackSelectedItem:{
    backgroundColor: theme.palette.secondary.main,
    color:  theme.palette.secondary.dark,
  },
  blackSelect:{
    '&:focus':{
      backgroundColor: theme.palette.common.black,
      color: theme.palette.grey[400],
    }
  },
  blackMenuPaper:{
    backgroundColor: theme.palette.common.black,
    border: `1px solid ${theme.palette.grey[400]}`
  },
  cssOutlinedInput:{
    '&$blackGreyBorder':{
      borderBottom: `1px solid ${theme.palette.lightgrey.color}`,
      color: theme.palette.lightgrey.color
    }
  },
  blackGreyBorder: {
    '&:after': {
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
  },
  blackCssLabel: {
    color: theme.palette.grey[400],
    '&$blackGreyText': {
      color: theme.palette.grey[400],
    },
  },
  error:{
    color: theme.palette.error.main,
  },
  form: {
    maxWidth:900,
    margin: 'auto'
  },
  formFields: {
    maxWidth:600,
    paddingTop: 50,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
  buttonClose: {
    background: 'none',
    float: 'right',
    boxShadow: 'none',
    color: theme.palette.black.color,
    '&:hover':{
      backgroundColor: theme.palette.background.default,
    }
  },
  blackButtonClose: {
    color: theme.palette.background.default,
    '&:hover':{
      backgroundColor: theme.palette.common.black,
    }
  },
  button: {
    boxShadow: 'none',
    backgroundColor:  theme.palette.primary.color,
  },
  blackButton: {
    backgroundColor:'#9c8258',
    '&:hover':{
      backgroundColor:'#b79a6c'
    }
  },
  riskTitle: {
    paddingBottom: '2rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '2rem',
    },
  },
  icon: {
    color: theme.palette.black.color,
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  dateInput:{
    width: '100%'
  }
})

class CallBackForm extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    const {viewer:{firstName, lastName, phone, email}} = props
    this.state = {
      form: {
        fullName: [firstName, lastName].join(' ') || '',
        phoneNumber: phone || '',
        email: email || '',
        timeHours:'',
        minutes:'',
        callbackDate:'',
        preferableLanguage: ''
      },
      errors: {},
      loadingButton:false,
      status: '',
    }
  }

  componentDidMount() {
    const {locale} = this.context
    moment.locale(getLocaleMoment(locale, momentLocales.excluded))
  }

  handleChange (name, value) {
    this.setState(state => ({
      form: {
        ...state.form,
        [name]: value
      },
      errors: {
        ...state.errors,
        [name]: !value,
      }
    }))
  }

  formatNumber (num) {return ('0'+num).slice(-2)}

  handleSubmit () {
    const {history, t} = this.props
    const {form, form:{callbackDate, timeHours, minutes}} = this.state
    const errors = {}
    const exceptions = []
    for (const field of Object.keys(form)) {
      if (field === 'email') {
        errors[field] = isEmpty(form[field]) || !validateEmail(form[field])
      } else if (field === 'callbackDate') {
        const callbackDateFormat =  form[field] && validateDate(form[field], true)
        const callbackDateValidFormat = form[field] && moment(form[field],callbackDateFormat).format('YYYY-MM-DD')
        const isWeekend = moment(callbackDateValidFormat).weekday() === 0 ||
          moment(callbackDateValidFormat).weekday() === 6
        errors[field] = isEmpty(form[field]) || isWeekend
      } else {
        errors[field] = isEmpty(form[field])
      }
    }
    const callbackDateFormat =  callbackDate && validateDate(callbackDate, true)
    const callbackDateValidFormat = callbackDate && moment(callbackDate,callbackDateFormat).format('YYYY-MM-DD')
    const checkTime = moment(`${callbackDateValidFormat} ${timeHours}:${minutes}`).format('YYYY-MM-DD HH:mm:ss')
    if (history.location.state.type === 'support') {
      errors.minutes =  errors.minutes || false
      errors.timeHours = errors.timeHours || false
    } else {
      if (!moment(checkTime).isBetween(`${callbackDateValidFormat} 09:29:59`, `${callbackDateValidFormat} 19:30:01`)) {
        errors.minutes = errors.minutes || true
        errors.timeHours = errors.timeHours || true
      }
    }

    if (some(omit(errors, exceptions))) {
      this.setState({errors})
      return
    }

    const callBackType = history.location.state.type
    const topic = `${callBackType} callback to`
    const message = `
    <p>Name: ${form.fullName}</p>
    <p>Email: ${form.email}</p>
    <p>Date: ${form.callbackDate}</p>
    <p>Time: ${this.formatNumber(form.timeHours)}:${this.formatNumber(form.minutes)}</p>
    <p>Phone: ${form.phoneNumber}</p>
    <p>Preferable Language: ${form.preferableLanguage}</p>`

    const to=get(config,`${callBackType}AccountEmail`, config.supportAccountEmail)

    this.setState({loadingButton: true,status: ''})
    const {featuresConfig: {contactMethods}} = config
    this.props.mutate({variables: {
      name:form.fullName,
      email:form.email,
      topic:`${topic} ${form.fullName}`,
      message: message,
      to,
      method: contactMethods.oneOnOneSession.key,
      callbackDate: form.callbackDate,
      preferableLanguage: form.preferableLanguage
    }}).then(() => {
      this.setState({submitted: true, loadingButton: false,status: 'success',submitMessageError: ''})
    }).catch((err) => {
      if (err.networkError) {
        this.setState({loadingButton: false,status: 'failure',
          submitMessageError: t(messages.networkError.i18nKey, messages.networkError.defaults),
        })
      } else {
        const error = get( err, 'graphQLErrors[0].message') || err.message
        this.setState({loadingButton: false,status: 'failure',
          submitMessageError: error,
        })
      }

    })
  }

  render() {
    const {classes, t, history, viewer:{phone, locale}} = this.props
    const {form : {fullName, phoneNumber, email, timeHours, minutes, callbackDate, preferableLanguage},
      errors, submitted, status, loadingButton, submitMessageError} = this.state
    const {companyObject, themePreference} = this.context
    const {brandLabel: companyLabel} = companyObject
    const callBackType = history.location.state.type
    const clientLanguagesSupport = map(filter(languages, (a) => a.support && !a.disabled), 'value')
    const clientLanguagesAccountManagers = map(filter(languages, (a) => a.accountManager && !a.disabled), 'value')
    const callbackDateFormat =  callbackDate && validateDate(callbackDate, true)
    const callbackDateValidFormat = callbackDate && moment(callbackDate,callbackDateFormat).format('YYYY-MM-DD')
    let clientLanguages, getAvailablehours
    if (callBackType === 'support') {
      clientLanguages = clientLanguagesSupport
      getAvailablehours = generateOptions(0,23)
    } else {
      clientLanguages = clientLanguagesAccountManagers
      getAvailablehours = generateOptions(9,19)
    }

    const isVIPAccount = callBackType === 'black'
    return (
      <Dialog
        fullScreen
        open={true}
        PaperProps={{classes: {root: classes[`${callBackType}Paper`]}}}
        disableEnforceFocus={true}
      >
        <Grid container className={classNames(classes.form)}
          justifyContent='center'>
          <Grid container className={classes.form} align='center' justifyContent='center'>
            <Grid item xs={12}>
              <Fab className={classNames(classes[`${callBackType}ButtonClose`],
                classes.buttonClose)}
              onClick={() => submitted ?
                (isVIPAccount ? history.push('/') : history.push(history.location.state.prevPath))
                : history.push(history.location.state.prevPath)}>
                <CloseIcon className={isDarkTheme(themePreference) ? classes.icon : ''}/>
              </Fab>
            </Grid>
            {submitted && <Grid item xs={12}>
              <Grid item xs={12} align='center'>
                {isVIPAccount && <img src={Images['document-upload-success-black.png']} alt={'success'}/>}
                {!isVIPAccount && <img src={Images[`document-upload-success-${themePreference}.png`]} alt={'success'}/>}
                <Typography variant="h1" className={classNames(classes[`${callBackType}WhiteText`],
                  classes.riskTitle)}>
                  <Trans {...messages.thankYou} />
                </Typography>
                <Typography variant="body1" className={classes[`${callBackType}WhiteText`]}>
                  <Trans {...messages.blackContact} values={{company: companyLabel}}/>
                </Typography>
              </Grid>
            </Grid>}
            {!submitted &&  <Grid container align='center' justifyContent='center'>
              <Grid item xs={12}>
                <Typography variant="h1" className={classNames(classes[!isDarkTheme(themePreference) ? `${callBackType}WhiteText` : 'darkWhiteText'],
                  classes.riskTitle)}>
                  <Trans {...messages[`${callBackType}TitleCallback`]} />
                </Typography>
              </Grid>
              <Grid container className={classes.formFields} spacing={3}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant="standard"
                    id="fullName"
                    required
                    name="fullName"
                    label={t(messages.fullName.i18nKey, messages.fullName.defaults)}
                    fullWidth
                    disabled={!isEmpty(fullName)}
                    error={this.state.errors.fullName}
                    InputLabelProps={{classes: {root: classes[`${callBackType}CssLabel`], disabled:classes[`${callBackType}GreyText`]}}}
                    InputProps={{classes: {root: classes.cssOutlinedInput, disabled:classes[`${callBackType}GreyBorder`]}}}
                    value={fullName}
                    onChange={(e) => this.handleChange('fullName', e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant="standard"
                    required
                    id="phoneNumber"
                    name="phoneNumber"
                    label={t(messages.phoneNumber.i18nKey, messages.phoneNumber.defaults)}
                    fullWidth
                    disabled={!isEmpty(phone)}
                    autoComplete="phoneNumber"
                    error={errors['phoneNumber']}
                    InputLabelProps={{classes: {root: classes[`${callBackType}CssLabel`], disabled:classes[`${callBackType}GreyText`]}}}
                    InputProps={{classes: {root: classes.cssOutlinedInput, disabled:classes[`${callBackType}GreyBorder`]}}}
                    value={phoneNumber}
                    onChange={(e) => this.handleChange('phoneNumber', e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant="standard"
                    required
                    id="email"
                    name="email"
                    disabled={!isEmpty(email)}
                    label={t(messages.email.i18nKey, messages.email.defaults)}
                    fullWidth
                    autoComplete="email"
                    error={errors['email']}
                    InputLabelProps={{classes: {root: classes[`${callBackType}CssLabel`], disabled:classes[`${callBackType}GreyText`]}}}
                    InputProps={{classes: {root: classes.cssOutlinedInput, disabled:classes[`${callBackType}GreyBorder`]}}}
                    value={email}
                    onChange={(e) => this.handleChange('email', e.target.value)} />
                </Grid>
                <Grid item xs={12} className={classes.noteDate} align='left'>
                  <Typography variant="body1" className={!every(['callbackDate','minutes','timeHours'], (v) => !errors[v]) ? classes.error : classes[`${callBackType}GreyText`]}>
                    <Trans {...messages[`${callBackType}AccountDateNote`]} />
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={get(dateFnsLocales, locale) || get(dateFnsLocales, fnsLocales[locale]) || get(dateFnsLocales, fnsLocales['default'])}>
                    <DatePicker
                      id="callbackDate"
                      name="callbackDate"
                      label={t(messages.date.i18nKey, messages.date.defaults)}
                      format="dd/MM/yyyy"
                      value={callbackDate ? new Date(callbackDateValidFormat) : null}
                      onChange={(selectedDate) => this.handleChange('callbackDate', moment(selectedDate).format('DD/MM/YYYY'))}
                      className={classes.dateInput}
                      minDate={new Date(moment().add(1, 'days').format('YYYY-MM-DD'))}
                      maxDate={new Date(moment().add(1, 'months').format('YYYY-MM-DD'))}
                      slotProps={{
                        textField: {
                          helperText: null,
                          required: true,
                          error: !!errors['callbackDate']
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="standard" className={classNames(classes.formControl)} fullWidth>
                    <InputLabel className={classes[`${callBackType}GreyText`]}
                      required
                      error={errors['timeHours']}
                      classes={{root: classes[`${callBackType}CssLabel`]}}
                    >
                      <Trans {...messages.timeHours} />
                    </InputLabel>
                    <Select
                      variant="standard"
                      value={timeHours}
                      onChange={(e) => this.handleChange('timeHours', e.target.value)}
                      className={classNames(classes[`${callBackType}GreyText`],
                        classes[`${callBackType}GreyBorder`])}
                      error={errors['timeHours']}
                      align='left'
                      MenuProps={{classes: {paper: classes[`${callBackType}MenuPaper`]}}}
                      classes={{select: classes[`${callBackType}Select`]}}>
                      {map(getAvailablehours,((hour) => <MenuItem key={hour.key} value={String(hour.key)}
                        classes = {{root:classes[`${callBackType}SelectItem`],
                          selected: classes[`${callBackType}SelectedItem`]}}>
                        {this.formatNumber(String(hour.key))}</MenuItem>))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="standard" className={classNames(classes.formControl)} fullWidth>
                    <InputLabel className={classes[`${callBackType}GreyText`]}
                      required
                      error={errors['minutes']}
                      classes={{root: classes[`${callBackType}CssLabel`]}}
                    >
                      <Trans {...messages.minutes} />
                    </InputLabel>
                    <Select
                      variant="standard"
                      value={minutes}
                      onChange={(e) => this.handleChange('minutes', e.target.value)}
                      className={classNames(classes[`${callBackType}GreyText`],
                        classes[`${callBackType}GreyBorder`])}
                      error={errors['minutes']}
                      align='left'
                      MenuProps={{classes: {paper: classes[`${callBackType}MenuPaper`]}}}
                      classes={{select: classes[`${callBackType}Select`]}}>
                      {map(generateOptions(0,59),((minute) => <MenuItem key={minute.key}
                        value={String(minute.key)}  classes = {{root:classes[`${callBackType}SelectItem`],
                          selected: classes[`${callBackType}SelectedItem`]}}>
                        {this.formatNumber(String(minute.key))}</MenuItem>))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl variant="standard" className={classNames(classes.formControl)} fullWidth>
                    <InputLabel className={classes[`${callBackType}GreyText`]}
                      required
                      error={errors['preferableLanguage']}
                      classes={{root: classes[`${callBackType}CssLabel`]}}
                    >
                      <Trans {...messages.preferableLanguage} />
                    </InputLabel>
                    <Select
                      variant="standard"
                      value={preferableLanguage}
                      onChange={(e) => this.handleChange('preferableLanguage', e.target.value)}
                      className={classNames(classes[`${callBackType}GreyText`], classes.greyBorder)}
                      error={errors['preferableLanguage']}
                      align='left'
                      MenuProps={{classes: {paper: classes[`${callBackType}MenuPaper`]}}}
                      classes={{select: classes[`${callBackType}Select`]}}>
                      {map(clientLanguages, lang =>
                        <MenuItem key={languages[lang].key} value={languages[lang].label}
                          classes = {{root:classes[`${callBackType}SelectItem`],
                            selected: classes[`${callBackType}SelectedItem`]}}>{languages[lang].label}
                        </MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
                {callBackType !== 'support' && <Grid item xs={12} align='left'>
                  <Typography variant="body1" className={classes[`${callBackType}GreyText`]}>
                    <Trans {...messages.blackAccountNote} />
                  </Typography>
                </Grid>}
                <Grid item xs={12} className={classes.gridPadding}>
                  <LoadingButton
                    id='loadingButton'
                    onClick={() => this.handleSubmit()}
                    fullWidth={isMobile()}
                    disabled={loadingButton}
                    status={status}
                    className={classNames(classes[`${callBackType}Button`], classes.button)}
                  ><Trans {...messages.requestCallback} />
                  </LoadingButton>
                  {status==='failure' &&
                  <FormHelperText className={classes.errorMessage}>{submitMessageError}</FormHelperText>}
                </Grid>
              </Grid>
            </Grid>
            }
          </Grid>
        </Grid>
      </Dialog>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {loading, error}, data}) => ({
      loading,
      error,
      viewer: get(data, 'viewer'),
    })
  }),
  graphql(CREATE_SUPPORT_TICKET),
)(CallBackForm)
