import React, {Component} from 'react'
import {graphql} from 'react-apollo'
import {get, flowRight as compose, includes, debounce} from 'lodash'
import {withNamespaces} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import {storeItem, removeItem, getSessInfoFromLocalStorage, getItem, setCookieTheme} from '../common/utils'
import {UPDATE_THEME_PREFERENCE} from '../graphql/mutations'
import {CLIENT_DATA_QUERY} from '../graphql/queries'
import {withRouter} from 'react-router-dom'
import {Grid} from '@mui/material'
import SunnyIcon from '@mui/icons-material/WbSunnyOutlined'
import NightIcon from '@mui/icons-material/Brightness3Outlined'
import AppContext from './Common/contexts/AppContext'
import {themePreferenceOptions} from '@bdswiss/common-enums'
import {getCurrentTheme, isDarkTheme} from '../common/utils/general'
import classNames from 'classnames'
import SwitchButton from './Common/SwitchButton'

const styles = theme => ({
  mainBody:{
    maxWidth: theme.mainBodyWidth,
    margin:'auto',
    minHeight: '100%',
    position: 'relative',
  },
  icon: {
    margin: '0 5px',
    width: '13px',
    height: '13px',
    color: theme.palette.primary.main,
    transform: 'rotate(165deg)',
    cursor: 'pointer',
  },
  toggleThemeContainer: {
    margin: 'auto',
    position: 'relative',
    maxWidth: theme.mainBodyWidth,
    padding: '0 1rem',
    alignItems: 'center',
  },
  darkIcon:{
    color: theme.palette.secondary.main,
    '&.active':{
      color: theme.palette.primary.main,
    }
  },
  lightIcon:{
    color: theme.palette.green.color,
    '&.active':{
      color: theme.palette.secondary.main,
    }
  },
  labelPlacementStart: {
    marginRight: 5,
    marginLeft: 5,
  },
  toggleThemeContainerProfile:{
    margin: 'auto',
    position: 'relative',
    maxWidth: theme.mainBodyWidth,
  },
  labelPlacementProfile: {
    marginRight: 0,
    marginLeft: theme.direction === 'rtl' ? 0 : 15,
  }
})

class ThemeSelection extends Component {
  static contextType = AppContext

  state = {
    theme: getCurrentTheme(this.context)
  }

  componentDidMount() {
    this.setState({theme: getCurrentTheme(this.context)})
  }

  updateThemePreference = () => {

    const {updateThemePreference, viewer} = this.props
    const theme = getCurrentTheme(this.context)
    const changeTheme = theme === themePreferenceOptions.light.value ?
      themePreferenceOptions.dark.value : themePreferenceOptions.light.value

    removeItem('themePreference')
    storeItem('themePreference', changeTheme)
    storeItem('pendingPreference', changeTheme)
    setCookieTheme(changeTheme)
    this.context.toggleTheme(changeTheme)
    if (viewer && getSessInfoFromLocalStorage()) {
      debounce(() => {
        if (getItem('pendingPreference') && getCurrentTheme(viewer, true) !== getItem('pendingPreference')) {
          updateThemePreference({variables: {themePreference: getItem('pendingPreference')}})
            .then((_) => {
              this.context.toggleTheme(getItem('pendingPreference'))
              removeItem('pendingPreference')
            })
        }
      }, 20000)()
    }
  }

  render() {
    const {classes} = this.props
    const theme = getCurrentTheme(this.context)
    const themeValue = isDarkTheme(theme) ? true : false
    const loginRegistration = includes(get(this.props.location, 'pathname'), 'login')

    return (
      <div className={classes.mainBody}>
        {loginRegistration ? <Grid container id="toggleTheme" className={classes.toggleThemeContainer}>
          <Grid item>
            {(theme.direction !== 'rtl') ? <NightIcon className={classNames(classes.icon, classes.darkIcon, (themeValue ? 'active' : ''))} onClick={this.updateThemePreference} />
              : <SunnyIcon className={classNames(classes.icon, classes.lightIcon, (themeValue ? 'active' : ''))} onClick={this.updateThemePreference}/>}
          </Grid>
          <Grid item>
            <SwitchButton checked={!themeValue} onChange={this.updateThemePreference} classesLabel={{labelPlacementStart: classes.labelPlacementStart}}/>
          </Grid>
          <Grid item>
            {(theme.direction === 'rtl') ? <NightIcon className={classNames(classes.icon, classes.darkIcon, (themeValue ? 'active' : ''))} onClick={this.updateThemePreference} />
              : <SunnyIcon className={classNames(classes.icon, classes.lightIcon, (themeValue ? 'active' : ''))} onClick={this.updateThemePreference}/>}
          </Grid>
        </Grid> : <Grid container id="toggleTheme" className={classes.toggleThemeContainerProfile}>
          <Grid item>
            <SwitchButton style={{marginLeft: 0, marginRight: 0}} iOSSwitch={true} checked={themeValue} onChange={this.updateThemePreference} classesLabel={{labelPlacementStart: classes.labelPlacementProfile}}/>
          </Grid>
        </Grid>}
      </div>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  withRouter,
  graphql(UPDATE_THEME_PREFERENCE, {
    name: 'updateThemePreference',
    options: {
      refetchQueries: [{query: CLIENT_DATA_QUERY}],
    }
  }),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading: viewerLoading}, data}) => ({
      error,
      viewerLoading,
      viewer: get(data, 'viewer'),
    })
  })
)(ThemeSelection)
