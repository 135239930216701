import React, {Component} from 'react'
import {find} from 'lodash'
import classnames from 'classnames'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Table from '@mui/material/Table'
import {withNamespaces, Trans} from 'react-i18next'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import PropTypes from 'prop-types'
import Images from '../Common/Images'
import AppContext from '../Common/contexts/AppContext'
import messages from '../../assets/messages'
import CreditCardIcon from '@mui/icons-material/CreditCardRounded'

const brandMappings = [
  {
    brand: 'VISA',
    key: 'visa',
  },
  {
    brand: 'DINERS CLUB INTERNATIONAL',
    key: 'diners',
  },
  {
    brand: 'MASTERCARD',
    key: 'mastercard',
  },
  {
    brand: 'MAESTRO',
    key: 'maestro',
  },
  {
    brand: 'VISA/DANKORT',
    key: 'visa',
  },
]

const styles = theme => ({
  cardRoot: {
    cursor: 'pointer',
    position:'relative',
  },
  cardSelected: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  imageCell: {
    width: '7%',
  },
  logoCell: {
    width: '15%',
  },
  logo: {
    width: 50,
  },
  cell: {
    padding: 0,
    verticalAlign: 'middle',
    borderBottom: 0
  },
})

class CardToken extends Component {
  static contextType = AppContext

  render() {
    const {classes, cardToken, isSelected, onClick} = this.props

    const brand = find(brandMappings, {brand: cardToken.brand})
    const cardRootClasses = [classes.cardRoot]
    if (isSelected) {
      cardRootClasses.push(classes.cardSelected)
    }

    return <Card classes={{root: classnames(cardRootClasses)}} onClick={() => onClick && onClick(cardToken.id)}>
      <Grid container>
        <Grid item className={classes.image} xs={12}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className={classnames([classes.cell, classes.imageCell])}>
                  <img
                    alt={isSelected ? 'Selected' : 'Not Selected'}
                    src={isSelected ? Images['control-radio-on.png'] : Images['control-radio-off.png']}
                  />
                </TableCell>
                <TableCell className={classnames([classes.cell, classes.logoCell])}>
                  {brand && <img
                    alt={cardToken.brand}
                    src={Images[`${brand.key}.png`]}
                    className={classes.logo}
                  />}
                  {!brand && <CreditCardIcon className={classes.logo}/>}
                </TableCell>
                <TableCell className={classes.cell}>
                  <Typography variant='subtitle1'><Trans {...messages.endingIn} /> {cardToken.lastFour}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Card>
  }
}

CardToken.propTypes = {
  cardToken: PropTypes.object,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
}

export default withStyles(styles, {withTheme: true})(withNamespaces()(CardToken))
