import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Grid from '@mui/material/Grid'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {debounce, flowRight as compose, get, isEmpty, values} from 'lodash'
import {frontends} from '@bdswiss/common-enums'
import {Trans, withNamespaces} from 'react-i18next'
import {withRouter} from 'react-router-dom'
import withStyles from '@mui/styles/withStyles'

import messages from '../../../../assets/messages'
import {getPlatform, getSourceBasedOnLocationPrevPath, logEventCustomParams} from '../../../../common/utils'
import {PaymentActionButton, withCreateDeposit} from './helpers'

const style = (theme) => ({
  formControl: {
    margin: theme.spacing(1)
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  }
})

const validators = {
  name: (value) => !value && messages.requiredField,
  email: (value) => !value && messages.requiredField,
}

const initialState = {form: {}, errors: {}, creatingDeposit: false}

class VoltProvider extends Component {

  constructor(props) {
    super(props)
    this.validateFields = debounce(this.validateFields, 200)
    this.state = initialState
  }

  handleChange(key, value) {
    const {form} = this.state
    this.setState({
      form: {
        ...form,
        [key]: value
      }
    }, () => this.validateFields())
  }

  validateFields() {
    const {form} = this.state
    const errors = {}
    Object.keys(form).forEach((k) => {
      const validate = validators[k]
      errors[k] = validate && validate(form[k])
    })
    this.setState({errors})
  }

  formIsValid() {
    const {form, errors} = this.state
    return isEmpty(values(errors).filter((v) => v)) && !isEmpty(values(form).filter((v) => v))
  }

  doCreateDeposit() {
    if (!this.formIsValid()) return

    this.setState({creatingDeposit: true})

    const {
      providerProperties: {provider, paymentKey, id: paymentOptionId}, amount, account, onError, useVirtualPaymentPage,
      bonusAmount, bonusTerms, history: {location}
    } = this.props
    const {form} = this.state

    const firebaseParams = {
      source: getSourceBasedOnLocationPrevPath(get(location, 'state', {prevPath: get(location, 'pathname')})),
      login: account.id,
      currency: account.currency,
      vendor: provider,
      amount,
    }
    logEventCustomParams('depositAttempted', firebaseParams)

    const variables = {
      accountId: account.id,
      amount,
      vendor: provider,
      paymentOptionId,
      frontend: frontends.web2.value,
      args: JSON.stringify({
        paymentKey,
        platform: getPlatform(),
        useVirtualPaymentPage,
        fields: {
          ...form,
        },
        bonusAmount,
        bonusTerms,
      }),
    }

    this.props.createDepositRequest({variables})
      .then(({data: {newDeposit}}) => {
        const {payment} = newDeposit
        window.location = payment.url
      })
      .catch((e) => {
        this.setState({creatingDeposit: false})
        onError && onError(e)
      })
  }

  render() {
    const {classes, canProceed} = this.props
    const {form, errors, creatingDeposit} = this.state
    const country = get(this.props, 'viewer.address.country')

    return (
      <Grid container direction="row" spacing={3}>

        <Grid item xs={12}>
          <FormControl variant="standard" fullWidth>
            <InputLabel htmlFor="name">Name</InputLabel>
            <Input id="name" type="text" name="name" value={form['name'] || ''}
              onChange={(e) => this.handleChange('name', e.target.value)}/>

            {errors.name &&
              <FormHelperText className={classes.error}><Trans {...errors.name} /></FormHelperText>
            }
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl variant="standard" fullWidth>
            <InputLabel htmlFor="email">Email</InputLabel>
            <Input id="email" type="text" name="email" value={form['email'] || ''}
              onChange={(e) => this.handleChange('email', e.target.value)}/>

            {errors.email &&
              <FormHelperText className={classes.error}><Trans {...errors.email} /></FormHelperText>
            }
          </FormControl>
        </Grid>

        {country === 'br' && <Grid item xs={12}>
          <FormControl variant="standard" fullWidth>
            <InputLabel htmlFor="documentId">Document Id</InputLabel>
            <Input id="documentId" type="text" name="documentId" value={form['documentId'] || ''}
              onChange={(e) => this.handleChange('documentId', e.target.value)}/>
          </FormControl>
        </Grid>}

        <Grid item xs={12}>
          <PaymentActionButton
            loading={creatingDeposit}
            disable={canProceed || !this.formIsValid()}
            onClick={() => this.doCreateDeposit()}
          />
        </Grid>
      </Grid>
    )
  }
}

VoltProvider.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  amount: PropTypes.number.isRequired,
  providerProperties: PropTypes.shape({
    name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    paymentKey: PropTypes.string.isRequired,
    paymentOptionId: PropTypes.number.isRequired,
  }).isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

export default compose(
  withStyles(style),
  withNamespaces(),
  withRouter,
  withCreateDeposit,
)(VoltProvider)
