import React from 'react'

import {IconButton} from '@mui/material'

import BentoMenuModal from './BentoMenuModal'

import {ReactComponent as BentoMenuIcon} from '../../assets/images/bento-menu/bento-menu-icon.svg'

const BentoMenu = ({anchorEl, onToggleModalState, viewer}) => (
  <>
    <IconButton
      onClick={(event) => onToggleModalState('anchorBentoMenuBtn', event)}>
      <BentoMenuIcon/>
    </IconButton>

    <BentoMenuModal
      viewer={viewer}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => onToggleModalState('anchorBentoMenuBtn', null)}
    />
  </>
)

export default BentoMenu
