import {map, get, has, flowRight as compose} from 'lodash'
import React, {Component} from 'react'
import {Trans, withNamespaces} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import Grid from '@mui/material/Grid'
import Radio from '@mui/material/Radio'
import Hidden from '@mui/material/Hidden'
import FormLabel from '@mui/material/FormLabel'
import Typography from '@mui/material/Typography'
import RadioGroup from '@mui/material/RadioGroup'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import messages from '../../../../assets/messages'
import AppContext from '../../../Common/contexts/AppContext'
import FormHelperText from '@mui/material/FormHelperText'

const styles = theme => ({
  questions: {
    marginBottom:20
  },
  answersGroup:{
    margin:16
  },
  question: {
    marginBottom:10
  },
  answer:{
    lineHeight:1.5
  },
  error:{
    color: theme.palette.red.color,
  },
  errorText:{
    color: theme.palette.red.color,
    marginTop: 0,
    marginLeft: 18
  },
  onlyText:{
    visibility: 'hidden'
  },
})

export class APTestQuestions extends Component {
  static contextType = AppContext

  render() {
    const {t, form, formState, errors, activeStep, onChange, classes, questions, disabledCorporate} = this.props
    const {locale, companyObject} = this.context

    return map(questions, (q, questionKey) => {
      const {step: {stepPosition}, options, description} = q
      const answerKey = get(form, `answers[${questionKey}]` ,'')
      const hasError = formState === 'validation' && has(errors, questionKey)

      return stepPosition === activeStep &&
      <Grid key={`${questionKey}-row`} container>
        <Grid key={`${questionKey}-column-1`} item xs={9}>
          <FormControl
            variant="standard"
            key={questionKey}
            component="fieldset"
            className={classes.questions}>
            <FormLabel
              id={questionKey}
              component="legend"
              error={hasError}
              className={classes.question}>
              <Typography variant='body2' className={hasError ? (classes.error): ''}>{q.orderLabel(companyObject.value)}. {q.localization.t(locale, {domain: companyObject.emailDomain})}</Typography>
              {hasError && <FormHelperText className={classes.errorText}><Trans {...messages.requiredField} /></FormHelperText>}
            </FormLabel>
            <RadioGroup
              row
              name={questionKey}
              value={answerKey}
              onChange={(e) => onChange(e.target)}
              className={classes.answersGroup}
            >
              {map(options, (answer, answerKey) =>
                (!has(answer, ['disable']) && <FormControlLabel
                  style={{width: '49%', minWidth: '310px'}}
                  key={answerKey}
                  value={answerKey}
                  classes={{label: classes.answer}}
                  label={answer.localization.t(locale, {domain: companyObject.emailDomain})}
                  control={<Radio
                    disabled={!!disabledCorporate}
                    color="primary"/>}
                />)
              )}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Hidden lgDown>
          <Grid key={`${questionKey}-column-2`} item xs={3} sm={3}>
            {description &&
              <Typography variant="body1">
                {t(messages[description].i18nKey, messages[description].defaults)}
              </Typography>}
          </Grid>
        </Hidden>
      </Grid>
    })
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces()
)(APTestQuestions)
