import {flowRight as compose} from 'lodash'
import {Trans, withNamespaces} from 'react-i18next'
import messages from '../../../../assets/messages'
import NotificationBar from '../../../Common/NotificationBar'

function RequestBlockToHaveAccessManageAlreadyPending() {
  return (
    <NotificationBar status={'success'} title={<Trans {...messages.requestBodyAccessToCompetitionPending}/>}/>
  )
}

export default compose(
  withNamespaces(),
)(RequestBlockToHaveAccessManageAlreadyPending)
