import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {withNamespaces} from 'react-i18next'
import PlacesAutocomplete from 'react-places-autocomplete'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import TextField from '@mui/material/TextField'
import messages from '../../assets/messages'
import withStyles from '@mui/styles/withStyles'
import {flowRight as compose} from 'lodash'
import classNames from 'classnames'
import Typography from '@mui/material/Typography'


const styles = theme => ({
  suggestionsList:{
    background: theme.palette.background,
    position: 'absolute',
    boxShadow:theme.boxShadowStyle,
    zIndex:1,
  },
  suggestionItem:{
    cursor: 'pointer',
    zIndex: 1,
  },
  suggestionItemText:{
    lineHeight:'1.5rem'
  },
  activeItem:{
    backgroundColor: theme.palette.lightgreyBackgroundSolid.color
  },
  standardItem:{
    backgroundColor: theme.palette.background.default,
  },
  helperTextInfo:{
    color: theme.palette.primary.main
  }
})

class AddressAutocomplete extends Component {


  constructor(props) {
    super(props)

    this.state = {
      selectedAddress: false
    }
  }

  handleFocus = (event) => {event.target.select()}

  handleSelect = (value) => {
    this.setState({selectedAddress: true})
    this.props.onSelect(value)
  }

  render() {
    const {address, error, onChange, t,classes, country} = this.props
    const searchOptions = {
      types: ['address'],
      componentRestrictions: {country: country},
    }
    return (
      <PlacesAutocomplete
        value={address}
        onChange={(value) => onChange(value)}
        onSelect={(value) => this.handleSelect(value)}
        debounce={1000}
        searchOptions = {searchOptions}
        shouldFetchSuggestions={address.length > 3}
        onError={() => {}}
      >
        {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
          <div>
            <TextField
              variant="standard"
              {...getInputProps({
                required: true,
                fullWidth: true,
                id: 'line1',
                label: address
                  ? t(messages.streetLabel.i18nKey, messages.streetLabel.defaults)
                  : t(messages.searchAddress.i18nKey, messages.searchAddress.defaults),
                error: error,
                onFocus:this.handleFocus
              })} />
            <List className={classes.suggestionsList}>
              {loading && (
                <ListItem className={classes.suggestionItem}>
                  <ListItemText disableTypography primary={<Typography className={classes.suggestionItemText} variant='body2'>{t(messages.loading.i18nKey, messages.loading.defaults)}</Typography>} />
                </ListItem>
              )}
              {suggestions.map(suggestion => {
                const style = suggestion.active ? 'activeItem' : 'standardItem'
                const className=classNames(classes.suggestionItem, classes[style])
                return (
                  <ListItem
                    {...getSuggestionItemProps(suggestion, {
                      className,
                    })}
                    key={suggestion.description}
                  >
                    <ListItemText disableTypography primary={<Typography className={classes.suggestionItemText} variant='body2'>{suggestion.description}</Typography>} />
                  </ListItem>
                )
              })}
            </List>
          </div>
        )}
      </PlacesAutocomplete>
    )
  }
}

AddressAutocomplete.propTypes = {
  address: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  error: PropTypes.bool,
}

export default compose(
  withStyles(styles),
  withNamespaces(),
)(AddressAutocomplete)

