import gql from 'graphql-tag'
import {omitBy, includes, reject} from 'lodash'
import {withdrawalPaymentFields, depositStatuses, appropTestQuestionsV2,
  dueDiligenceQuestions, suitabilityTestQuestions} from '@bdswiss/common-enums'

const PAYMENT_FIELDS = omitBy(withdrawalPaymentFields,
  (f) => includes(['amount', 'withdrawalReason', 'withdrawalReasonText'], f.key) ||
  f.isInternal ||
  (f.visible && !f.visible({}))
)

const VISIBLE_DEPOSIT_STATUSES = reject(Object.keys(depositStatuses), (s) => s === 'pending').join()

export const CLIENT_BASIC_INFO_FIELDS = `
  firstName
  lastName
  nationalIdNumber
  nationalIdNumber2
  birthday
  nationality
  phone
  profession
  placeOfBirth
`

export const CLIENT_MIFIR_INFO_FIELDS = `
  mifirId
  mifirType
`

export const SECONDARY_PHONES_QUERY = `
  secondaryPhones {
    secondaryPhone1
  }
`

export const COPY_TRADING_FIELDS = `
  id
  createdAt
  updatedAt
  startRequestedDate
  stopRequestedDate
  startCopyingDate
  stopCopyingDate
  isActive
  account {
    __typename
    ... on ForexAccount {
      id
    }
  }
  accountCopying {
    accountId
    remoteId
    accountName
  }
  deletedAt
`

export const SECONDARY_EMAILS_QUERY = `
  secondaryEmails {
    secondaryEmail1
    secondaryEmail2
    secondaryEmail3
  }
`

export const ADDRESS_QUERY = `
  address {
    line1
    houseNumber
    city
    zip
    region
    country
    oldCountry
  }
`

export const GLOBAL_QUESTIONNAIRE_QUERY = `
  globalQuestionnaire {
    transactionPurpose
    approxExpectedDeposit
    originOfFunds
    natureOfTransactions
    approxNetWorth
    approxYearlyIncome
    sourceOfFunds
    jobTitle
    politicallyExposed
    usCitizen
    taxJurisdictionCountry
    tin
    tinReason
    transactionPurposeClarify
    natureOfTransactionsClarify
    sourceOfFundsClarify
    tinClarify
    politicallyExposedReason
    expectedCountryOfOriginDestinationFunds
  }
`

export const PAYMENT_CARD_TOKEN_FIELDS_FRAGMENT = gql`
  fragment PaymentCardTokenFields on PaymentCardToken {
    id
    brand
    lastFour
    expiryMonth
    expiryYear
    vendor
    meta
  }
`

export const AI_POSITIONS_FRAGMENT = gql`
  fragment AIAccountFields on BaseForexAccount {
    margin
    equity
    forexPositionsCount(tradingPositionStatus: all)
    forexPositions(tradingPositionStatus: all limit: 5000 offset: 0) {
      ticket
      symbol
      command
      volume
      openTime
      openRate
      closeTime
      closeRate
      profit
      stopLoss
      takeProfit
      commission
      swaps
    }
  }
`

export const CLIENT_BASIC_INFO_FRAGMENT = gql`
  fragment ClientBasicInfo on Client {
    ${CLIENT_BASIC_INFO_FIELDS}
    nickname
  }
`

export const CLIENT_MIFIR_INFO_FRAGMENT = gql`
  fragment MifirInfo on Client {
    ${CLIENT_MIFIR_INFO_FIELDS}
  }
`

export const SETTINGS_FIELDS_FRAGMENT = gql`
  fragment ClientSettingsFields on Client {
    registrationCampaigns {
      campaign {
        tradingVideosCero
        redirectWebTraderFtd
        type
      }
    }
    optInMarketing
    optInSms
    canGrantSpoa
    hasPortfolioManagement
    signupStep
    canEditLeverage
    hasCampaign
    hasHotAssets
    emailConfirmed
    emailPendingChange
    isEmailVerificationRequired
    partnerBonusesAndCompetitions
    partnerClientRegisteredEmail
  }
`

export const SPOA_FIELDS_FRAGMENT = gql`
  fragment SpoaFields on Client {
    spoaRemoteId
    spoaRemoteClient {
      id
      firstName
      lastName
      accounts{
        __typename
        ... on BaseAccount {
          currency
          id
          remoteId
          accountName
          hidden
        }
     }
    }
  }
`

export const SECONDARY_PHONES_FRAGMENT = gql`
  fragment SecondaryPhones on Client {
    ${SECONDARY_PHONES_QUERY}
  }
`

export const SECONDARY_EMAILS_FRAGMENT = gql`
  fragment SecondaryEmails on Client {
    ${SECONDARY_EMAILS_QUERY}
  }
`

export const CLIENT_ADDRESS_FRAGMENT = gql`
  fragment Address on Client {
    ${ADDRESS_QUERY}
  }
`

export const GLOBAL_QUESTIONNAIRE_FRAGMENT = gql`
  fragment GlobalQuestionnaire on Client {
   ${GLOBAL_QUESTIONNAIRE_QUERY}
  }
`

export const TRADING_STATUS_FRAGMENT = gql`
  fragment TradingStatusInfo on Client {
    tradingStatusReasonCode
    tradingStatusReason
    tradingStatus
  }
`

export const KYC_STATUS_FRAGMENT = gql`
  fragment KYCStatusInfo on Client {
    kycStatus
    kycStatusReasonCode
    kycStatusReason
  }
`

export const ELECTRONIC_ID_VERIFICATION_FRAGMENT = gql`
  fragment ElectronicIDVerification on Client {
    eIdVerificationResults {
      id
      identityReliability
      rawResult
      createdAt
    }
    eIdVerificationApplicable
  }
`

export const DEPOSIT_FIELDS_FRAGMENT = gql `
  fragment DepositFields on Deposit {
    currency
    id
    status
    amount
    createdAt
    account {
      __typename
      ... on BaseAccount {
        id
        remoteId
      }
    }
    payment {
      vendor
      meta
      details
      transferFromAccount {
        __typename,
        ... on BaseAccount {
          id
          remoteId
        }
      }
    }
  }
`

export const SUBSCRIPTIONS_FIELDS_FRAGMENT = gql `
  fragment SubscriptionFields on Subscription {
    currency
    id
    status
    amount
    createdAt
    account {
      __typename
      ... on BaseAccount {
        id
        remoteId
      }
    }
    payment {
      vendor
      meta
      transferFromAccount {
        __typename,
        ... on BaseAccount {
          id
          remoteId
        }
      }
    }
  }
`

export const WITHDRAWAL_FIELDS_FRAGMENT = gql`
  fragment WithdrawalFields on Withdrawal {
    id
    status
    rejectionReasonCode
    createdAt
    type
    currency
    amount
    withdrawalReason
    withdrawalReasonText
    paymentVendor
    queuePosition
    paymentFields {
      ${Object.keys(PAYMENT_FIELDS).join(' ')}
      goldValue
    }
    transferToAccount {
      __typename
      ... on BaseAccount {
        id
        remoteId
      }
    }
    account {
      __typename
      ... on BaseAccount {
        id
        remoteId
      }
    }
  }
`

export const ACCOUNTS_FIELDS_FRAGMENT = gql `
  ${AI_POSITIONS_FRAGMENT}
  fragment AccountFields on Account {
    ... on BaseAccount {
      id
      __typename
      createdAt
      currency
      balance
      login
      remoteId
      mobile
      hidden
      outstandingActivationDeposit
      minimumDepositMet
      isDemo
      hasHeldDeposits
      bonusOffers(status: [userApproved, autoApproved], orderBy: createdAt, orderDirection: descending) {
        id
        type
        status
        currency
        amount
        createdAt
      }
      availableWithdrawalOptions
      balance
      minimumDeposit
      copyTrading {
        ${COPY_TRADING_FIELDS}
      }
      copyTradingCancelledRequests{
        ${COPY_TRADING_FIELDS}
      }
      accountName
      passwordProtectedStrategies
      accountSubType
      maximumDepositAllowed {
        currency
        amount
      }
      exchangeRateForCompetition
      minimumTransfer
      minimumDepositMet
    }
    ... on BaseOptionAccount {
      spotOptionId
    }
    ... on BaseForexAccount {
      leverage
      leverageType
      accountSubtype
      isReadOnly
      isViewOnly
      isTripleZero
      mobile
      allowedLeverages
      isArchived
      marginInfo {
        balance
      }
      server
      metaTraderApiUrl
      copyTrading {
        ${COPY_TRADING_FIELDS}
      }
      copyTradingCancelledRequests{
        ${COPY_TRADING_FIELDS}
      }
      competitions {
        startDate
        exitDate
        active
        competitionDetails {
          id
          rules
          name
          prizes
          icon
          termsLink
        }
      }
      credit
      provideCopyTrading
      performanceFee
      showProviderStats
      zuluLinkedStatus
      provideCopyTradingStartDate
      swapFree
    }
    ... on BaseTradesmarterAccount {
      withdrawableBalance
      isViewOnly
    }
    ... on BaseCryptoCoinAccount {
      coins
      walletAddress
      depositWalletAddress
    }
    ... on BaseGoldAccount {
      gold
      subscriptions {
        id
        state
        amountCent
        planName
        activationDate
        lastBillingDate
        nextBillingDate
      }
    }
    ... on AIPAMMAccount {
      ...AIAccountFields
    }
    ... on AIPAMMMauritiusAccount {
      ...AIAccountFields
    }
    ... on AIPAMMDemoAccount {
      ...AIAccountFields
    }
    ... on AIPAMMMauritiusDemoAccount {
      ...AIAccountFields
    }
    ... on BaseAffiliateAccount {
      serviceFields {
        affiliateStatus
      }
    }
    ... on BaseCryptoWalletAccount {
      cryptoBalance
      cryptoTransactions {
        id
        type
        amount
        currency
        status
        destinationWallet
        createdAt
      }
    }
    ... on BaseFiatWalletAccount {
      balance
    }
    ... on BaseSMPrimeWalletAccount {
      margin
      freeMargin
      equity
    }
    ... on BaseForexRawAccount {
      volumeTraded
      isInFallback
      subscription {
        id
        plan
        nextPaymentDate
        cancelledAt
        isActive
        isExpired
        pendingPlan
        lastPaymentFailed
        billingInfo {
          fullName
          address1
          zip
          cardType
          month
          year
          lastFourDigits
        }
      }
    }
    ... on BaseIntroducingBrokerAccount {
      approved
      portalAccess
      parentIbId
      ibId
      ignoreIbTransfers
      partnerDetails{
        campaigns
        unpaidComm
        paidComm
        ibId
        ibCampaignUrl
        clientCampaignUrl
      }
    }
    ... on BaseBitnukWalletAccount {
      walletAddress
    }
  }
`

export const DOCUMENT_FIELDS_FRAGMENT = gql`
  fragment DocumentFields on Document {
    id
    type
    status
    createdAt
    rejectionReasonCode
    rejectionReason
    fileDescription
    url
    owner
    fileType
  }
`

export const PROFILE_CHANGES_ANSWERS_FRAGEMENT = gql`
  fragment ProfileChangeAnswerFields on ProfileChangeAnswers {
    ${CLIENT_BASIC_INFO_FIELDS}
    ${CLIENT_MIFIR_INFO_FIELDS}
    ${ADDRESS_QUERY}
    ${GLOBAL_QUESTIONNAIRE_QUERY}
    ${SECONDARY_PHONES_QUERY}
    ${SECONDARY_EMAILS_QUERY}
  }
`

export const APPROP_TEST_FIELDS_FRAGMENT = gql`
  fragment AppropTestFields on AppropTest {
    status
    rejectionReasonCode
    createdAt
    forexPoints
    scoreResult
    answers {
      ${Object.keys(appropTestQuestionsV2).join(' ')}
    }
  }
`
export const DUE_DILIGENCE_FIELDS_FRAGMENT = gql`
  fragment DueDiligenceFields on DueDiligence {
    status
    createdAt
    answers {
      ${Object.keys(dueDiligenceQuestions).join(' ')}
    }
  }
`

export const SUITABILITY_TEST_FIELDS_FRAGMENT = gql`
  fragment SuitabilityTestFields on SuitabilityTest {
    status
    createdAt
    answers {
      ${Object.keys(suitabilityTestQuestions).join(' ')}
    }
    scoreResult
    portfolioPoints
  }
`

export const CLIENT_DATA_QUERY = gql `
  ${CLIENT_ADDRESS_FRAGMENT}
  ${SECONDARY_PHONES_FRAGMENT}
  ${SECONDARY_EMAILS_FRAGMENT}
  ${CLIENT_BASIC_INFO_FRAGMENT}
  ${CLIENT_MIFIR_INFO_FRAGMENT}
  ${KYC_STATUS_FRAGMENT}
  ${ELECTRONIC_ID_VERIFICATION_FRAGMENT}
  ${TRADING_STATUS_FRAGMENT}
  ${SETTINGS_FIELDS_FRAGMENT}
  ${APPROP_TEST_FIELDS_FRAGMENT}
  ${DUE_DILIGENCE_FIELDS_FRAGMENT}
  ${SUITABILITY_TEST_FIELDS_FRAGMENT}
  ${GLOBAL_QUESTIONNAIRE_FRAGMENT}
  ${DOCUMENT_FIELDS_FRAGMENT}
  query {
    viewer {
      __typename
      ... on Client {
        ...ClientBasicInfo
        ...ClientSettingsFields
        ...SecondaryPhones
        ...SecondaryEmails
        ...Address
        ...MifirInfo
        ...TradingStatusInfo
        ...KYCStatusInfo
        ...ElectronicIDVerification
        id
        affiliateAggreementUrls
        affiliateAggreementStatus
        affiliateAggreementTemplate
        email
        externalVerificationUrl
        locale
        themePreference
        clientType
        company
        whiteLabel
        depositedAmount
        registration
        ftdDate
        missingDocuments
        missingEP
        pendingUploadDocuments {
          poi {
            allowUpload
            status
          }
          por {
            allowUpload
            status
          }
          partner {
            allowUpload
            status

          }
          personal {
            allowUpload
            status
          }
        }
        pendingNoticesCount
        underMonitoring
        underMonitoringReasonCode
        kycStatus
        kycStatusReason
        kycStatusReasonCode
        autoChartistEnabled
        tradingCentralEnabled
        hasTelegram
        isPhoneVerified
        isEmailLoginVerified
        isEmailLoginVerificationRequired
        isPhoneVerificationRequired
        isClientHasAccessToCreateTradingCompetition
        isClientRequestedAccessToCreateTradingCompetition
        canProcessAppropTest
        website
        skypeId
        affiliateCountries{
          countriesPromoted
        }
        signableNoticeAckSignatures{
          noticeId
          signature
          dateSigned
        }
        appropTests(orderBy: createdAt, orderDirection: descending, limit: 1) {
          ...AppropTestFields
        }
        suitabilityTests(orderBy: createdAt, orderDirection: descending, limit: 1) {
          ...SuitabilityTestFields
        }
        dueDiligences(orderBy: createdAt, orderDirection: descending, limit: 1) {
          ...DueDiligenceFields
        }
        paymentCardTokens {
          id
          lastFour
          brand
          vendor
          meta
        }
        ...GlobalQuestionnaire
        documents(orderBy: createdAt, orderDirection: descending) {
          ...DocumentFields
        }
        verificationActions {
          forcedVerification
          blockDeposit
        }
        pendingNotices {
          id
          version
          validFromDate
          type
          title
          content
          createdAt
          localizationKey
          noticePeriod
          isContentHtml
          acknowledgements {
            acknowledgementType
          }
        }
        paymentMethods {
          id
          details
          fundingCategory
          confirmed
          relatedDocumentIds
          pendingUpload{
            allowUpload
            status
          }
        }
        allowedAccountTypes
        allowedAccountLimits
        clientAllowCopyTrading
        eligibleBonus {
          eligibleBonus
          daysLeft
          percentage
          daysRemainingNotification
        }
        manuallyAllowedAccounts
        manualDeposits
        pendingLoginVerification
        swapFree
        allowSwapFree
        hasPartnerAccount
        acceptedTermsSpoaBonus
        euMigration
        fromCompany
        multipleRegulation
        acceptEuMigrationTerms
        aggregatedBalanceEur
        showPasswordProtectedStrategies
        bonusTerms
        partnersLoyalty {
          id
          redeemedPrizes
          createdAt
        }
        partnersLoyaltyAnalytics {
          memberId
          totalClients
          clients90days
          turnoverUsd
          turnoverUsdLastYear
          performancePercentage
          eligible
          currentPrize
          currentPrizeThreshold
          eligiblePrizes
          completed
          partnersLoyaltyEligibility {
            memberId
            prizeId
            eligibilityDate
            endEligibilityDate
          }
        }
        linkedPartnerClientId
        hasFirstReferrer
        corporateSignupStep
        corporateStatus
        corporateDetails{
          general
          beneficiariesDirectors
          representative
          financialInformation
        }
        educationalVideosLevels
        tradingVideos
        promotionsEligibility
        localDepositorPhone
        localDepositorEmail
        isLocalDepositor
        localDepositorStatus
        ldAcknowledgementDate
        copyTradingNotificationAppearanceTimes
        isContractor
        requestCallbackBecomePartner
      }
    }
  }
`

export const CLIENT_PROFILE_QUERY = gql `
  ${CLIENT_ADDRESS_FRAGMENT}
  ${SECONDARY_PHONES_FRAGMENT}
  ${SECONDARY_EMAILS_FRAGMENT}
  ${CLIENT_BASIC_INFO_FRAGMENT}
  ${KYC_STATUS_FRAGMENT}
  query {
    viewer {
      ... on Client {
        id
        ...ClientBasicInfo
        ...SecondaryPhones
        ...SecondaryEmails
        ...Address
        ...KYCStatusInfo
        email
        emailPendingChange
        localDepositorPhone
        localDepositorEmail
        isLocalDepositor
        localDepositorStatus
      }
    }
  }
`

export const CLIENT_REFER_CAMPAIGN_QUERY = gql `
  query {
    viewer {
      ... on Client {
        referCampaign {
          referTerms
          referTiers {
            ftdAmount
            volumeRequirement
            referrerReward
            refereeReward
          }
        }
      }
    }
  }
`

export const PROFILE_SETTINGS_QUERY = gql`
  ${GLOBAL_QUESTIONNAIRE_FRAGMENT}
  ${APPROP_TEST_FIELDS_FRAGMENT}
  ${DUE_DILIGENCE_FIELDS_FRAGMENT}
  ${SUITABILITY_TEST_FIELDS_FRAGMENT}
  ${DOCUMENT_FIELDS_FRAGMENT}
  ${PROFILE_CHANGES_ANSWERS_FRAGEMENT}
  query {
    viewer {
      ... on Client {
        isLocalDepositor
        localDepositorStatus
        localDepositorPhone
        localDepositorEmail
        canGrantSpoa
        hasPortfolioManagement
        themePreference
        ...GlobalQuestionnaire
        appropTests(orderBy: createdAt, orderDirection: descending, limit: 1) {
          ...AppropTestFields
        }
        profileChanges(orderBy: createdAt, orderDirection: descending, status: pending) {
          id
          answers {
            ...ProfileChangeAnswerFields
          }
        }
        dueDiligences {
          ...DueDiligenceFields
        }
        suitabilityTests(orderBy: createdAt, orderDirection: descending, limit: 1) {
          ...SuitabilityTestFields
        }
        documents(orderBy: createdAt, orderDirection: descending) {
          ...DocumentFields
        }
        verificationActions {
          forcedVerification
          blockDeposit
        }
      }
    }
  }
`

export const ACCOUNTS_QUERY = gql`
  ${ACCOUNTS_FIELDS_FRAGMENT}
  query ($id: Int){
    viewer {
      ... on Client {
        accounts(orderBy: createdAt, hidden: false, id: $id) {
          ...AccountFields
        }
      }
    }
  }
`

export const WITHDRAWABLE_METHODS_QUERY = gql`
  query ($id: Int){
    viewer {
      ... on Client {
        accounts(id: $id) {
          ... on BaseAccount {
            withdrawablePaymentMethods {
              id
              order
              details
              vendor
              cardNumber
              vendorAccountEmail
              vendorAccountId
              iban
              swiftCode
              cryptoCurrency
              amount
              currency
              amountLeft
              bankCode
              accountNumber
              provider
              paymentOption
            }
          }
        }
      }
    }
  }
`

export const LOCAL_DEPOSITOR_DETAILS = gql`
  query{
    viewer {
      ... on Client {
        localDepositorPhone
        localDepositorEmail
        localDepositorSubscriptions {
          id
          subscribedMemberFullName
          localDepositorId
          subscribedMemberId
          isSubscriptionActive
          createdAt
          updatedAt
        }
        localDepositorSubscriptionsCount
      }
    }
  }
`

export const PAYMENTS_ACCOUNTS_QUERY = gql`
  ${WITHDRAWAL_FIELDS_FRAGMENT}
  query {
    viewer {
      ... on Client {
        canIbTransfer
        accounts(orderBy: createdAt, hidden: false) {
          __typename
          ... on BaseAccount {
            id
            currency
            balance
            isDemo
            hidden
            remoteId
            availableWithdrawalOptions
            withdrawals(orderBy: createdAt) {
              ...WithdrawalFields
            }
            minimumDeposit
            accountName
            accountSubType
            minimumDepositMet
            minimumTransfer
            minimumPartialDeposit
            minimumBonusDeposit
            freeMargin
            maximumDepositAllowed {
              currency
              amount
            }
          }
          ... on BaseForexAccount {
            isArchived
            isViewOnly
            accountSubtype
            isReadOnly
            freeMargin
            withdrawalsBlocked
            copyTrading {
              id
              isActive
              startRequestedDate
              stopRequestedDate
              accountCopying {
                accountId
                remoteId
                accountName
              }
            }
            eligibleBonus {
              eligibleBonus
              accountTypeBonus
              daysLeft
              percentage
              maxAmount
              terms
            }
            swapFree
          }
          ... on BaseForexPremiumAccount {
            leverageChangedBasedOnEquity
            marginInfo {
              equity
            }
          }
          ... on BaseForexPremiumAccount {
            leverageChangedBasedOnEquity
            marginInfo {
              equity
            }
          }
          ... on BaseForexRawAccount {
            subscription {
              id
              plan
              nextPaymentDate
              cancelledAt
              isActive
              isExpired
              pendingPlan
              lastPaymentFailed
            }
          }
          ... on BaseIntroducingBrokerAccount {
            ignoreIbTransfers
          }
          ... on BaseTradesmarterAccount {
            withdrawableBalance
            isViewOnly
          }
          ... on BaseBitnukWalletAccount {
            withdrawalFee
          }
        }
      }
    }
  }
`

export const PAYMENTS_HISTORY_QUERY = gql`
  ${DEPOSIT_FIELDS_FRAGMENT}
  ${SUBSCRIPTIONS_FIELDS_FRAGMENT}
  ${WITHDRAWAL_FIELDS_FRAGMENT}
  ${PAYMENT_CARD_TOKEN_FIELDS_FRAGMENT}
  query {
    viewer {
      ... on Client {
        accounts(orderBy: createdAt, hidden: false) {
          ... on BaseAccount {
            id
            currency
            balance
            isDemo
            hidden
            withdrawals(orderBy: createdAt) {
            ...WithdrawalFields
            }
            deposits(status: [${VISIBLE_DEPOSIT_STATUSES}], orderBy: createdAt, includeSubscriptions:false) {
              ...DepositFields
            }
            subscriptionDeposits {
              ...SubscriptionFields
            }
          }
          ... on BaseForexAccount {
            accountSubtype
          }
        }
        paymentCardTokens {
          ...PaymentCardTokenFields
        }
      }
    }
  }
`

export const PAYMENT_CARD_TOKEN_QUERY = gql`
  ${PAYMENT_CARD_TOKEN_FIELDS_FRAGMENT}
  query {
    viewer {
      ... on Client {
        paymentCardTokens {
          ...PaymentCardTokenFields
        }
      }
    }
  }
`

export const DLOCAL_BANKS_QUERY = gql`
  query {
    viewer {
      ... on Client {
          dlocalBanksList{
          code
          name
          logo
          paymentType
        }
      }
    }
  }
`

export const FUNTOPAY_WITHDRAWAL_BANKS_QUERY = gql`
  query {
    funtopayWithdrawalBanks {
      banks
    }
  }
`

export const COUNTRY_PAYMENT_OPTIONS_QUERY = gql`
  query {
    countryPaymentOptions {
      id
      country
      paymentOption {
        id
        name
        provider
        logoUrl
        localizationKey
        paymentKey
        additionalFields
        bankDetails {
          clientAccountCurrency
          recipient
          iban
          creditingAccount
          swift
          bank
          address
          city
          country
          currency
          reference
          name
          bankAccountNumber
          ifscCode
          branchName
        }
        ccType
        processingTime
        processingTimeValue
        transactionFee
        minDepositAmounts {
          currency
          amount
        }
        maxDepositAmounts {
          currency
          amount
        }
      }
      enabled
      rank
      popular
    }
  }
`

export const PENDING_NOTICES_QUERY = gql`
  {
    viewer {
      ... on Client {
        pendingNotices {
          id
          version
          validFromDate
          type
          title
          content
          createdAt
          localizationKey
          noticePeriod
          isContentHtml
          acknowledgements {
            acknowledgementType
          }
        }
      }
    }
  }
`

export const DISPLAY_BANNER_QUERY = gql`
  query($id:String!, $type:String!) {
    banner: bannerDisplay(locale: $locale, type: $type) {
      file
      link
    }
  }
`

export const MARKED_UP_RATE_QUERY = gql`
  query ($fromCurrency: String!, $toCurrency: String!) {
    getMarkedUpRate(fromCurrency: $fromCurrency, toCurrency: $toCurrency)
  }
`

export const IB_CLIENTS_QUERY = gql`
  query {
    getIbClients {
      id
      firstName
      lastName
      accounts {
        __typename
        ... on BaseAccount {
          id
          remoteId
          currency
          accountName
          hidden
        }
      }
    }
  }
`

export const RELATED_IB_QUERY = gql`
  query {
    getRelatedIbs {
      clientId: id
      firstName
      lastName
      parentIbId
      id: ibId
      accountId
    }
  }
`

export const IB_CLIENT_ACCOUNTS_QUERY = gql`
  query GetIbClienAccounts($clientId: Int!) {
    getIbClientAccounts(clientId: $clientId) {
      __typename
      ... on BaseAccount{
        id
        remoteId
        currency
        accountName
      }
    }
  }
`

export const FOREX_ACCOUNT_QUERY = gql`
  query forexAcount($id: Int) {
    viewer {
      ...on Client {
        accounts(id: $id) {
          ...on BaseAccount {
            id
            login
            currency
            hasHeldDeposits
            leverageType
          }
          ...on BaseForexAccount {
            leverageType
            credit
            outstandingActivationDeposit
            minimumDepositMet
            isArchived
            isDemo
            isReadOnly
            isViewOnly
            balance
            server
            accountSubtype
            metaTraderApiUrl
            copyTrading {
              ${COPY_TRADING_FIELDS}
            }
            copyTradingCancelledRequests{
              ${COPY_TRADING_FIELDS}
            }
            marginInfo {
              freeMargin
              equity
              level
            }
            bonus {
              amount
              requiredLots
              lotsTraded
            }
            leverage
          }
          ... on BaseForexRawAccount {
            volumeTraded
            isInFallback
            isInOverTheLimit
            subscription {
              id
              plan
              volumeToTrade
              nextPaymentDate
              isActive
              isExpired
              pendingPlan
              createdAt
              updatedAt
              deletedAt
              cancelledAt
              lastPaymentFailed
              billingInfo {
                fullName
                address1
                zip
                cardType
                month
                year
                lastFourDigits
              }
            }
          }
        }
      }
    }
  }
`

export const FOREX_POSITIONS = gql`
  query forexPositions($id: Int!, $status: TradingPositionStatus!, $limit: Int!, $offset: Int!) {
    viewer {
      ...on Client {
        accounts(id: $id) {
          ...on BaseForexAccount {
            forexPositions(tradingPositionStatus: $status, limit: $limit, offset: $offset) {
              ticket
              symbol
              command
              volume
              openTime
              openRate
              closeTime
              closeRate
              profit
              stopLoss
              takeProfit
              commission
              swaps
              investment
              leverage
              leverageType
            }
          }
        }
      }
    }
  }
`

export const FOREX_POSITIONS_COUNT = gql`
  query forexPositionsCount($id: Int!, $status: TradingPositionStatus!) {
    viewer {
      ...on Client {
        accounts(id: $id) {
          ...on BaseForexAccount {
            forexPositionsCount(tradingPositionStatus: $status)
          }
        }
      }
    }
  }
`

export const FOREX_ACCOUNT_SETTINGS = gql`
  query forexAcountSetting($id: Int) {
    viewer {
      ...on Client {
        accounts(id: $id) {
          ...on BaseForexAccount {
            id
            remoteId
            leverage
            leverageType
            allowedLeverages
            performanceFee
            provideCopyTrading
            showProviderStats
            zuluLinkedStatus
            provideCopyTradingStartDate
            hasOpenPositions
            accountSubtype
            isReadOnly
            isViewOnly
            isArchived
          }
          ... on BaseAccount{
            accountName
            strategyAvatar
            strategyAvatarStatus
            isDemo
            passwordProtectedStrategies
            remoteId
          }
        }
        canEditLeverage
        company
      }
    }
  }
`

export const ACCOUNTS_TYPES_QUERY = gql`
query {
  viewer {
    ... on Client {
      accounts(orderBy: createdAt, hidden: false) {
        __typename
      }
    }
  }
}
`
export const FIND_CLIENT_QUERY = gql`
  query FindClient($clientId: Int, $remoteId: Int, $email: String, $type: ClientSearchType!){
    findClient(clientId: $clientId, remoteId: $remoteId, email: $email, type: $type) {
      firstName
      clientId
      lastName
      email
      accountId
      remoteId
  }
}
`

export const SPOA_AUTHORIZATION_QUERY = gql `
    ${SPOA_FIELDS_FRAGMENT}
    query {
      viewer {
        ... on Client
        { ...SpoaFields }
      }
    }`

export const THUNDERXPAY_DEPOSIT_OPTIONS_QUERY = gql`
query GetThunderxPayDepositOptions($country: String!) {
  getThunderxPayOptions(country: $country) {
    options
  }
}
`

export const DYNAMIC_WITHDRAWAL_FIELDS_QUERY = gql`
  query DynamicWithdrawalFields($providerName: String!, $currency: String) {
    dynamicWithdrawalFields(vendorName: $providerName, currency: $currency) {
      fields
    }
  }
`

export const CONFIG_QUERY = gql`
  query {
    config {
      minimumDepositDefault
      textToSpeechVerificationEnabled
      failedAppropTestTrainingPeriod
      maximumAccountEquity
    }
  }
`

export const PAYMENT_METHODS_QUERY = gql`
  query PaymentMethods($depositId: Int) {
    viewer {
      ... on Client {
        paymentMethods(depositId: $depositId) {
          id
          confirmed
          details
          fundingCategory
          pendingUpload{
            allowUpload
            status
          }
        }
      }
    }
  }
`

export const TRANSACTIONS_CLIENT_QUERY = gql`
  query Transactions($offset: Int, $limit: Int) {
    viewer {
      ... on Client {
        transactions(offset: $offset, limit: $limit) {
          id
          paymentFields {
            ${Object.keys(PAYMENT_FIELDS).join(' ')}
            withdrawalFee
            withdrawalNetAmount
          }
          currency
          createdAt
          status
          amount
          withdrawalType
          withdrawalRejectionReason
          withdrawalRejectionReasonText
          withdrawalPendingReason
          category
          vendor
          transactionType
          depositDetails
          withdrawalQueuePosition
          meta
          showCancelPartialDeposit
          depositPaymentFields{
            bankAccountHolderName,
            iban,
            swiftCode,
            bankName,
            transferToAccount,
          }
          transferAccount {
            memberId
            firstName
            lastName
            id
            remoteId
            type
            accountName
          }
          account {
            __typename
            ... on BaseAccount {
              id
              remoteId
              accountName
            }
          }
        }
        transactionsCount
      }
    }
  }
`

export const ACCOUNT_HAS_OPEN_POSITIONS_QUERY = gql`
  query accountHasOpenPositions($accountId: Int!) {
    viewer {
      ...on Client {
        accounts(id: $accountId) {
          ...on BaseForexAccount {
            hasOpenPositions
          }
        }
      }
    }
  }
`

export const CLIENT_HAS_OPEN_POSITIONS_QUERY = gql`
  query accountHasOpenPositions {
    viewer {
      ...on Client {
        hasOpenPositions
      }
    }
  }
`

export const ENABLE_AUTO_CHARTIST_QUERY = gql`
  query EnableAutoChartist {
    viewer {
      ...on Client {
        enableAutoChartist
      }
    }
  }
`

export const ACKNOWLEDGED_NOTICES_QUERY = gql`
  {
    viewer {
      ... on Client {
        acknowledgedNotices {
          acknowledgementType
          notice {
            id
            createdAt
            type
            title
            template
            values
            content
            isContentHtml
          }
          createdAt
        }
      }
    }
  }
`
export const JM_FINANCIAL_BANK_TRANSFER_DETAILS_QUERY = gql`
  query GetJMFinancialBankTransferDetails($accountId: Int!) {
      getJMFinancialBankTransferDetails(accountId: $accountId) {
        recipient
        iban
        creditingAccount
        swift
        bank
        address
        city
        country
        currency
      }
  }
`

export const PAYRETAILERS_PAYMENT_METHODS_QUERY = gql`
{
  payretailersPaymentMethods {
    paymentMethodId
    name
    channel
    group
    imageUrl
    country
    currency
    requirePersonalId
  }
}
`

export const REFERRAL_DETAILS_QUERY = gql`
  query referralDetails($platform: String){
    viewer {
      ... on Client {
        linkedPartnerClientId
        kycStatus
        tradingStatus
        referAndEarn
        referralDetails(platform: $platform) {
          link
          referrals {
            id
            referralId
            status
            amountReferrer
            registrationDate
            deposit
            volume
          }
          countReferrals
          rewardsObj {
            claimedRewards
            pendingRewards
          }
          awards
          conditions
          activatedReferralAwards{
            amount
            activatedAt
            claimedAt
          }
          oldReferrer
          referAndEarnReveleadLink
        }
      }
    }
  }
`

export const REFERRED_BY_QUERY = gql`
  query {
    viewer {
      ... on Client {
        referredBy(notDeclined: true) {
          referrerId
        }
      }
    }
  }
`

export const VPS_SERVICE_PASSWORD_QUERY = gql`
query VpsServicePassword($serviceId: Int!) {
  getVpsServicePassword(serviceId: $serviceId) {
        password
      }
}
`

export const HELP2PAY_DEPOSIT_BANKS_QUERY = gql`
  query Help2payDepositBanks($currency: String!) {
    help2PayDepositBanks(currency: $currency)
}
`

export const SC_OPEN_BANKING_DEPOSIT_BANKS_QUERY = gql`
  query SafeChargePaymentMethods($country: String!, $currency: String!) {
    safeChargePaymentMethods(country: $country, currency: $currency)
}
`

export const UBIQPAY_PAYMENT_METHODS_QUERY = gql`
  query UbiqpayPaymentMethods($country: String!, $paymentKey: String!, $currency: String!) {
    ubiqpayPaymentMethods(country: $country, paymentKey: $paymentKey, currency: $currency)
}
`

export const FAQ_QUERY = gql`
query Faq($customDomain: String)
{
  faq(customDomain: $customDomain) {
    faqTitle
    faqLink
    faqContent
  }
}
`

export const TRADING_CENTRAL_LINK = gql`
query TradingCentralLink($page: String, $theme: String) {
  tradingCentralLink(page: $page, theme: $theme)
}
`

export const PRIZES_QUERY = gql`
  query {
    prizes(orderBy:threshold) {
      id
      imageLink
      prizeLabel
      prizeKey
      prizeThreshold
    }
  }
`

export const PARTNER_FAQ_QUERY = gql`
{
  partnerFaq {
    faqQuestion
    faqAnswer
  }
}
`

export const PARTNER_TUTORIAL_VIDEOS_QUERY = gql`
{
  partnerTutorialVideos{
    title
    url
    type
    thumbnail
  }
}
`

export const PARTNER_GALLERY_QUERY = gql`
{
  partnerGallery {
    imageTitle
    thumbnail
    src
    thumbnailWidth
    thumbnailHeight
  }
}
`

export const EDUCATIONAL_VIDEOS_QUERY = gql`
  query GetEducationalVideos($clientId: Int!) {
    getEducationalVideos(clientId: $clientId) {
      level {
        value
      }
      educationalVideos {
        key
        title
        thumbUrl
        videoUrl
      }
    }
  }
`

export const TRADING_VIDEOS_QUERY = gql`
  query GetTradingVideos($clientId: Int!) {
    getTradingVideos(clientId: $clientId) {
      type
      tradingVideos {
        key
        thumbUrl
        videoUrl
      }
    }
  }
`

export const PROMO_MATERIALS_QUERY = gql`
query PromoMaterials(
  $type: PromoMaterialTypeType,
  $size: PromoMaterialSizeType,
  $campaign: String,
  $language: Language,
  $topic: PromoMaterialTopicType,
  $uniqueAssetName: Boolean,
  $assetName: String,
  $width: Int,
  $height: Int,
  $offset: Int,
  $limit: Int
  ){
  promoMaterials(
    type: $type,
    size: $size,
    campaign: $campaign,
    language: $language,
    topic: $topic,
    uniqueAssetName: $uniqueAssetName,
    assetName: $assetName,
    width: $width,
    height: $height,
    offset: $offset,
    limit: $limit,
    orderBy: createdAt,
    orderDirection: descending) {
    id
    type
    assetName
    assetDescription
    campaignName
    language
    size
    topic
    width
    height
    assetUrl
    sourceFileUrl
    sourceFilePreviewUrl
    meta
  }
  promoMaterialsCount(
    type: $type, 
    uniqueAssetName: $uniqueAssetName,
    width: $width,
    height: $height,
    assetName: $assetName,
    size: $size,
    campaign: $campaign,
    language: $language,
    topic: $topic
  )
}`

export const PROMOTIONS_QUERY = gql `
  query {
    viewer {
      __typename
      ... on Client {
        id
        optInPromotions
        promotionsEnabled
        promotionsEligibility
      }
    }
  }
`

export const PROMOTIONS_CARDS_QUERY = gql`
query CheckClientLoginsEligiblePromotion {
  checkClientLoginsEligiblePromotion {
    mpid
    title
    description
    isActive
    startingDate
    endingDate
    minimumDeposit
    countries
    bonusTypes {
      login
      type
      amountType
      amountValue
      receivedBonus
      maxBonus
    }
  }
}`

export const NOTIFICATION_AREA_QUERY = gql`
  query notifications($company: Company!, $country: Country!, $shuffle: Boolean!, $limit: Int!, $fallsBetweenDate: DateTime! ) {
    notifications(company: $company, country: $country, shuffle: $shuffle, limit: $limit, fallsBetweenDate: $fallsBetweenDate) {
      id
      templateName
      title
      message
      imageUrl
      link
    }
  }
`

export const AVAILABLE_POF_DOCUMENTS_QUERY = gql`
  query {
    viewer {
      __typename
      ... on Client {
        availablePOFDocuments {
          key
          value
          label
          doubleSided
          fundingCategories
          subCategories
          localizationLabel
          groupName
          disabled
          sideName
          category
        }
      }
    }
  }
`

export const MARKETANALYSIS_QUERY = gql`
{ 
  marketAnalysisPost {
    id
    slug
    title
    desc
    date
    url
  }
}
`
export const SOCIALMEDIALINKS_QUERY = gql`
{
  socialMediaLinks {
    facebook
    x
    instagram 
    telegram
    youtube
    tiktok
    linkedin
  }
}
`
