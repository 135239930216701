import React from 'react'
import AppContext from '../../Common/contexts/AppContext'
import {withRouter} from 'react-router-dom'
import {Trans, withNamespaces} from 'react-i18next'
import {flowRight as compose} from 'lodash'
import withStyles from '@mui/styles/withStyles'
import {CustomDialog} from '../../Common/Dialog'
import {Button, Grid, SvgIcon} from '@mui/material'
import {ReactComponent as CloseIcon} from '../../../assets/images/close-icon-dark.svg'
import {ReactComponent as WebsiteIcon} from '../../../assets/images/website-icon.svg'
import {ReactComponent as DownloadIcon} from '../../../assets/images/bento-qrcode-mob.svg'
import messages from '../../../assets/messages'
import classNames from 'classnames'
import {getCurrentTheme, isDarkTheme} from '../../../common/utils'

const styles = theme => ({
  dialogPaper: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    borderRadius: '8px',
  },
  dialogPaperDark: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    borderRadius: '8px',
    backgroundColor: theme.palette.grey[900],
  },
  closeIcon: {
    backgroundColor: 'transparent',
    color: theme.palette.grey[400],
    [theme.direction === 'rtl' ? 'left' : 'right']: '15px',
  },
  dialogTitleRoot: {
    margin: '0px',
    padding: '24px 24px 8px 24px',
    [theme.breakpoints.down(300)]: {
      padding: '24px 12px 8px 12px',
    },
    textAlign: theme.direction === 'rtl' ? 'right': 'left',
  },
  dialogContentRoot: {
    whiteSpace: 'nowrap',
    padding: '0px 24px 24px 24px',
    [theme.breakpoints.down(300)]: {
      padding: '0px 12px 24px 12px',
    },
  },
  titleFont: {
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '24px',
  },
  font: {
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '24px',
  },
  buttonFontLetterSpacing: {
    letterSpacing: '0.25px',
  },
  titleFontLetterSpacing: {
    letterSpacing: '0.15px',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  buttonContainer: {
    display: 'flex',
    whiteSpace: 'nowrap',
    alignItems: 'baseline',
    gap: '7px',
  },
  button: {
    borderRadius: '6px',
    padding: '12px 36px 12px 36px'
  },
  webVersionButton: {
    backgroundColor: '#D6EAFF',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: '#D6EAFF',
      color: theme.palette.primary.main,
    },
    '&.active': {
      backgroundColor: '#D6EAFF',
      color: theme.palette.primary.main,
    }
  },
  darkWebVersionButtonColor: {
    backgroundColor: '#133050',
  },
  websiteIcon: {
    '& path': {
      fill: theme.palette.primary.main,
    },
  },
  downloadIcon: {
    '& path': {
      fill: theme.palette.secondary.light,
    },
  },
})

class ForexWebtraderRedirectionModal extends React.Component {
  static contextType = AppContext

  getCustomTitle(_) {
    const {classes, onCloseRedirectionModal} = this.props
    return (
      <Grid container justifyContent='space-between'>
        <Grid item className={classNames(classes.titleFont, classes.titleFontLetterSpacing)}>
          <Trans {...messages.webTraderRedirectionPopUpTitle}/>
        </Grid>
        <Grid item>
          <SvgIcon
            viewBox='-6 -3 24 24'
            className={classes.closeIcon}
            onClick={() => onCloseRedirectionModal()}
            component={CloseIcon}
          />
        </Grid>
      </Grid>
    )
  }

  getWebVersionElement(isDark) {
    const {classes, onMobileTrade} = this.props
    return (
      <Button
        variant='contained'
        size='medium'
        color='primary'
        onClick={() => onMobileTrade()}
        className={classNames(classes.button, classes.webVersionButton, isDark ? classes.darkWebVersionButtonColor : '')}
        disableElevation
        fullWidth
      >
        <Grid className={classes.buttonContainer}>
          <Grid item>
            <SvgIcon
              viewBox='0 0 15 16'
              component={WebsiteIcon}
              className={classes.websiteIcon}
              style={{position: 'relative', fontSize: '15px', verticalAlign: 'middle', top: '-3px'}}
            />
          </Grid>
          <Grid item className={classNames(classes.font, classes.buttonFontLetterSpacing)}>
            <Trans {...messages.webTraderRedirectionWebVersion}/>
          </Grid>
        </Grid>
      </Button>
    )
  }

  getAppVersionElement(_) {
    const {classes, onOpenMobileApp} = this.props
    const {companyObject} = this.context
    return (
      <Button
        variant='contained'
        size='medium'
        color='primary'
        onClick={() => onOpenMobileApp()}
        className={classes.button}
        disableElevation
        fullWidth
      >
        <Grid className={classes.buttonContainer}>
          <Grid item>
            <SvgIcon
              viewBox='0 0 14 14'
              component={DownloadIcon}
              className={classes.downloadIcon}
              style={{position: 'relative', fontSize: '14px', verticalAlign: 'middle', top: '-2.5px'}}
            />
          </Grid>
          <Grid item className={classNames(classes.font, classes.buttonFontLetterSpacing)}>
            <Trans {...messages.webTraderRedirectionAppVersion} values={{company: companyObject?.brandLabel}}/>
          </Grid>
        </Grid>
      </Button>
    )
  }

  render() {
    const {classes, showRedirectionModal, onCloseRedirectionModal} = this.props
    const themePreference = getCurrentTheme(this.context)
    const isDark = isDarkTheme(themePreference)
    return (
      <CustomDialog
        open={showRedirectionModal}
        onClose={() => onCloseRedirectionModal()}
        title={this.getCustomTitle(isDark)}
        customClasses={{
          dialogTitle: classes.dialogTitleRoot,
          dialogContent: classes.dialogContentRoot,
          classes: {paper: isDark ? classes.dialogPaperDark : classes.dialogPaper}
        }}
        backdropProps={{style: {backgroundColor: 'rgba(0,0,0,0.7)'}}}
        disabledTypography
      >
        <Grid className={classes.buttonsContainer}>
          <Grid item>{this.getAppVersionElement(isDark)}</Grid>
          <Grid item>{this.getWebVersionElement(isDark)}</Grid>
        </Grid>
      </CustomDialog>
    )
  }
}

export default compose(
  withNamespaces(),
  withRouter,
  withStyles(styles, {withTheme: true}),
)(ForexWebtraderRedirectionModal)
