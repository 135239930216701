import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {get, isEmpty, map, omit} from 'lodash'

class SelfPostingForm extends Component {

  state = {}

  componentDidUpdate(prevProps) {
    const {formSubmitted} = this.state
    if (!formSubmitted && !prevProps.doSubmitForm && this.props.formData && this.props.doSubmitForm) {
      document.getElementById('payment-form').submit()
    }
  }

  render() {
    const {formData, onSubmit} = this.props
    if (!formData || isEmpty(formData)) return <div />

    const formAction = get(formData, 'payment_url')
    const formMethod = get(formData, 'method') || 'post'
    const cleanFormData = omit(formData, ['method', 'payment_url'])

    return (
      <form id={'payment-form'} action={formAction} method={formMethod} onSubmit={() => {onSubmit && onSubmit(); this.setState({formSubmitted: true})}}>
        {map(cleanFormData, (value, key) => <input type="hidden" name={key}  key={key} value={value} readOnly />)}
      </form>
    )
  }
}

SelfPostingForm.propTypes = {
  formData: PropTypes.object,
  onSubmit: PropTypes.func,
  doSubmitForm: PropTypes.bool.isRequired,
}

export default SelfPostingForm
