import React, {Component} from 'react'
import {withNamespaces, Trans} from 'react-i18next'
import queryString from 'qs'
import {companies,} from '@bdswiss/common-enums'
import withStyles from '@mui/styles/withStyles'
import {Box, FormHelperText, Grid, Tab, Tabs, TextField, Typography} from '@mui/material'
import {flowRight as compose, concat,  find, get, isEmpty,  map, omit, pick,} from 'lodash'
import config from '../../../config'
import messages from '../../../assets/messages'
import AppContext from '../../Common/contexts/AppContext'
import {registerWidgetLPTabs} from '../../../common/utils/uioptions'
import {isMobile, updateExistingLead,} from '../../../common/utils'
import SwitchButton from '../../Common/SwitchButton'
import Password from '../../Common/Password'
import LoadingButton from '../../Common/LoadingButton'
import RegisterWidget from './RegisterWidget'

const styles = theme => ({
  errorRoot: {
    lineHeight: 1.5
  },
  successRoot: {
    width: '100%',
    lineHeight: 1.5,
    borderRadius: '6px',
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.lightgreen.color,
    padding: `${theme.spacing(1)} 0px`,
  },
  successMessage: {
    color:  theme.palette.green.color,
    textAlign: 'center',
    display: 'inline-block',
  },
  errorMessage: {
    color:  theme.palette.error.main,
    display: 'inline-block',
    verticalAlign: 'bottom',
    margin: '13px 13px 13px 0'
  },
  link: {
    color:theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight:400
  },
  tabsContainer: {
    padding: `${theme.spacing(3)} 0px`
  },
  formContainer: {
    padding: `0px ${theme.spacing(3)}`
  },
  tcpSize: {
    fontSize: '12px',
  },
  buttonRegSize: {
    fontSize: '15px',
  }
})

class RegisterWidgetLP extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {
      activeTab: 'existing',
      listCountries: [],
      loading: false,
      prefixChanged: false,
      countryChanged: false,
      showPassword: false,
      form: {},
      formErrors: {},
    }
  }

  componentDidMount() {
    const {location} = this.props
    const urlParams = queryString.parse(location.search.replace('?', ''))
    this.setState((state) => ({form: {...state.form, ...urlParams}}))
  }

  handleFormChange(key, value) {
    this.setState((state) => ({
      status: '',
      submitMessageError: '',
      submitMessageSuccess: '',
      form: {
        ...state.form,
        [key]: value
      },
    }))
  }

  handleFormErrorChange(key, value) {
    this.setState((state) => ({
      formErrors: {
        ...state.formErrors,
        [key]: value
      },
    }))
  }

  handleTabChanged(tabIndex) {
    this.setState((state) => ({activeTab: tabIndex}))
  }

  handleClickShowPassword() {
    this.setState(state => ({
      showPassword: !state.showPassword
    }))
  }

  validateForm() {

    const {activeTab, form, form: {useEmail}} = this.state
    const activeTabObj = find(registerWidgetLPTabs, {key: activeTab})
    const requiredFields = activeTabObj.requiredFields

    this.setState((state) => ({
      status: '',
      loading: true,
      formErrors: {},
      submitMessageError: ''
    }))

    for (const fieldKey of requiredFields) {
      if (isEmpty(form[fieldKey])) {
        this.setState((state) => ({formErrors: {...state.formErrors, [fieldKey]: true}}))
      } else {
        this.setState((state) => ({formErrors: {...omit(state.formErrors, [`${fieldKey}`])}}))
      }
    }

    const ignoreIdOrEmail = activeTab === 'new' ? [] : useEmail ? ['id'] : ['email']
    this.setState((state) => ({formErrors: {...omit(state.formErrors, ignoreIdOrEmail)}}))

    if (activeTab === 'existing') {

      this.handleSubmit()
    }
  }

  handleSubmit() {
    const {t} = this.props
    const {form, activeTab} = this.state
    const activeTabObj = find(registerWidgetLPTabs, {key: activeTab})

    const payloadFields = concat(activeTabObj.fields, activeTabObj.urlParamFields)

    const payload = pick(form, payloadFields)

    if (activeTab === 'existing') {
      updateExistingLead(payload)
        .then((res) => {
          if (res.result === 'error') {
            this.setState((state) => ({
              loading: false,
              status: 'failure',
              submitMessageError: t(messages.registrationWidgetLPErrorLogin.i18nKey, messages.registrationWidgetLPErrorLogin.defaults)
            }))
          } else {
            this.setState((state) => ({
              loading: false,
              status: 'success',
              submitMessageSuccess: t(messages.thankYouForRegisteringWithUs.i18nKey, messages.thankYouForRegisteringWithUs.defaults)
            }))
          }
        })
        .catch((error) => {
          this.setState((state) => ({
            loading: false,
            status: '',
          }))
        })
    }
  }

  renderExistingClientForm() {
    const {t, classes, loading} = this.props
    const {companyObject} = this.context
    const {form: {useEmail, id, email, password}, formErrors, showPassword} = this.state
    const {weblinks: {termsAndConditions}} = config

    return <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container direction='row' justifyContent='center'>
          <Grid item xs={9}>
            <Typography variant='body2'>
              <Trans {...messages.useBDSwissIdOrEmail} values={{brandLabel: companies[companyObject.key].brandLabel}} />
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Grid container direction='row' justifyContent={isMobile() ? 'flex-start' : 'flex-end'} alignItems='center' spacing={1}>
              <Grid item>
                <SwitchButton
                  iOSSwitch
                  checked={!!useEmail}
                  onChange={(e) => this.handleFormChange('useEmail', e.target.checked)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {!useEmail && <Grid item xs={12}>
        <TextField
          required
          fullWidth
          id="companyId"
          value={id}
          label={<Trans {...messages.companyId} values={{brandLabel: companies[companyObject.key].brandLabel}} />}
          onChange={(e) => this.handleFormChange('id', e.target.value)}
          error={get(formErrors, 'id', false)}
        />
      </Grid>}
      {useEmail && <Grid item xs={12}>
        <TextField
          required
          fullWidth
          id="email"
          value={email}
          label={t(messages.email.i18nKey, messages.email.defaults)}
          onChange={(e) => this.handleFormChange('email', e.target.value)}
          error={get(formErrors, 'email', false)}
        />
      </Grid>}
      <Grid item xs={12}>
        <Password
          required
          fullWidth
          classes={classes}
          showPassword={showPassword}
          onChange={(e)=>{
            this.handleFormChange('password', e.target.value)
          }}
          error={get(formErrors, 'password', false)}
          onClickShow={() => this.setState(state => ({
            showPassword: !state.showPassword
          }))}
          value={password}
        />
        <LoadingButton
          className={classes.buttonRegSize}
          fullWidth
          id='loadingButton'
          disabled={!!loading}
          onClick={() => {
            this.validateForm()
          }}
          hideProgressBar={!loading}

        >
          <Trans {...messages.register} />
        </LoadingButton>
        <Grid item xs={12}>
          <Typography variant='body2' align='center' className={classes.tcpSize}>
            <Trans {...messages.yourCapitalRisk} />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body2' align='center' className={classes.tcpSize}>
            <Trans
              {...messages.termsAndCondtionsApply}
              components={[
                <a target="_blank" rel="noopener noreferrer" href={termsAndConditions} className={classes.link}>termsAndCondtionsApply</a>
              ]}
            />
          </Typography>
          <Typography variant='body2' align='center' className={classes.tcpSize}>
            <Trans {...messages.consentOfStoringInformation} />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  }

  renderNewClientForm() {
    const {location, match, history} = this.props
    const allowfullwidth = true
    return  <Grid container justifyContent='flex-start' >
      <RegisterWidget location={location} match={match} history={history} allowfullwidth={allowfullwidth} hideLiveChat={true} />
    </Grid>

  }

  render() {
    const {classes} = this.props
    const {activeTab, status, submitMessageError, submitMessageSuccess} = this.state

    return <React.Fragment>
      <Box display='flex' flexDirection='column'>
        <Box>
          <Tabs
            variant='fullWidth'
            value={activeTab}
            className={classes.tabsContainer}
            onChange={(_, tabIndex) => this.handleTabChanged(tabIndex)}
          >
            {map(registerWidgetLPTabs, (tab) => <Tab key={tab.key} value={tab.key} label={<Trans {...tab.title} />} />)}
          </Tabs>
        </Box>
        <Box display='flex' flexDirection='column' gap='8px'  className={classes.formContainer}>
          <Grid container>
            <Grid item xs={12} md={6}>
              {activeTab === 'existing' && this.renderExistingClientForm()}
              {activeTab === 'new' && this.renderNewClientForm()}
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              {status ==='failure' &&
              <FormHelperText classes={{root: classes.errorRoot}} className={classes.errorMessage}>
                {submitMessageError}
              </FormHelperText>}
              {status === 'success' &&
              <FormHelperText classes={{root: classes.successRoot}} className={classes.successMessage}>
                {submitMessageSuccess}
              </FormHelperText>}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </React.Fragment>
  }
}

export default compose(
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
)(RegisterWidgetLP)
