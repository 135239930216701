import React, {Component} from 'react'
import {get, flowRight as compose} from 'lodash'
import {withNamespaces, Trans} from 'react-i18next'
import {withRouter} from 'react-router-dom'
import Grid from '@mui/material/Grid'
import withStyles from '@mui/styles/withStyles'
import messages from '../../assets/messages'
import NotificationBar from '../Common/NotificationBar'
import {graphql} from 'react-apollo'
import {CONFIRM_EMAIL_MUTATION} from '../../graphql/mutations'
import {CLIENT_DATA_QUERY} from '../../graphql/queries'
import Loading from '../Common/Loading'
import {logEventCustomParams} from '../../common/utils/firebaseEvents'

const styles = theme => ({
  successDiv:{
    backgroundColor: theme.palette.lightgreen.color,
    padding: '15px 10px',
    marginBottom: 40
  },
  successMessage:{
    color: theme.palette.green.color,
  },
  failedDiv:{
    backgroundColor: theme.palette.lightred.color,
    padding: '15px 10px',
    marginBottom: 40
  },
  failedMessage:{
    color: theme.palette.red.color,
  },
  textLink: {
    color: theme.palette.primary.main,
  },
  successCTA: {
    color:  theme.palette.notificationBar.greenTextColor,
  },
})

export class EmailVerification extends Component {
  constructor(props) {
    super(props)
    this.state ={
      token: props.match.params.confirmEmailToken,
      successMessage: '',
    }
  }

  componentDidMount() {
    const {token} = this.state
    logEventCustomParams('emailVerificationRequired', {userId: get(this.props, 'client.id')})
    return this.props.confirmOwnEmail({variables: {token}})
      .then(() => {
        logEventCustomParams('emailVerificationSuccessful', {userId: get(this.props, 'client.id')})
        this.setState({successMessage: true})
      })
      .catch((error) => {
        logEventCustomParams('emailVerificationFailed', {
          reason: error,
          userId: get(this.props, 'client.id')
        })
        this.setState({successMessage: false})
      })
  }

  render() {
    const {classes, client, history} = this.props
    const {successMessage} = this.state
    if (successMessage === '') return <Loading />
    const alreadyConfirmed = client.emailConfirmed && !successMessage

    return (
      <Grid item xs={12} sm={10}>
        {!alreadyConfirmed && <NotificationBar
          status={successMessage ? 'success' : 'error'}
          title={successMessage
            ? <Trans {...messages.successfulEmailConfirmation} />
            : <Trans {...messages.failedEmailConfirmation} />
          }
        />}
        {alreadyConfirmed && <NotificationBar
          status='success'
          title={<Trans {...messages.emailAreadyConfirmed} />}
          ctaMessage={<Trans {...messages.goToDashboard} />}
          ctaAction={() => history.push('/')}
        />}
      </Grid>
    )
  }
}

export default compose (
  withRouter,
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  graphql(CONFIRM_EMAIL_MUTATION, {
    name: 'confirmOwnEmail',
    update: cache => {
      cache.writeData({data: {props: []}})
    },
  }),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {loading, error}, data, ownProps: {history}}) => ({
      clientLoading: loading,
      error,
      history,
      client: get(data, 'viewer'),
    })
  }),
)(EmailVerification)
