import React from 'react'
import {Redirect, Route, Switch, withRouter} from 'react-router-dom'
import TransferOptions from './TransferOptions'
import Transfer from './Transfer'

const TransferRoutes = ({match: {path}}) =>(
  <Switch>
    <Route exact path={`${path}/transferFunds`} component={Transfer}/>
    <Route exact path={`${path}/transferSend`} component={Transfer}/>
    <Route exact path={`${path}/transferSpoa`} component={Transfer}/>
    <Route exact path={`${path}/transferIb`} component={Transfer}/>
    <Route path={`${path}/:parentPath/:childPath+`} render={({match}) => <Redirect to={`${path}/${match.params.parentPath}`} />} />
    <Route path={`${path}`} component={TransferOptions}/>
  </Switch>
)

export default withRouter(TransferRoutes)
