import React, {Component} from 'react'
import {map, omit, flowRight as compose} from 'lodash'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import AppBar from '@mui/material/AppBar'
import {tabStats} from '../../common/utils/uioptions'
import {Trans} from 'react-i18next'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import messages from '../../assets/messages'

const styles = theme => ({
  greyText:{
    color: theme.palette.secondary.main,
    fontWeight: 500,
    textTransform: 'capitalize'
  },
  scrollButtons: {
    color: theme.palette.secondary.main,
    maxWidth: 30,
    opacity: 0.5,
  },
  regStats:{
    position: 'relative'
  },
  stats:{
    paddingLeft:'0 !important'
  },
  appBar:{
    boxShadow: 'none',
    marginLeft: -12
  },
  tabs:{
    marginBottom: 0,
    backgroundColor: theme.palette.background.default,
    paddingTop: 10
  },
  tab:{
    fontSize: 13,
    padding: '0 3px !important'
  },
  flexContainer:{
    borderBottom: 'none',
    backgroundColor: theme.palette.background.default,
    maxWidth: 190,
    boxShadow: 'none',
  },
  selectedTab:{
    fontWeight: 500,
    fontSize: 20
  },
  indicator:{
    display: 'none'
  },
  spaceship:{
    width: '90%',
    position: 'absolute',
    top: 32,
    [theme.direction === 'rtl' ? 'marginRight' : 'marginLeft']: -12
  },
  details: {
    padding: '10px 0',
  },
  spaceshipItem: {
    marginBottom: -24
  }
})


export class GroupTradingStats extends Component {

  state = {
    value: 8
  }

  handleChangeTab = (event, value) => {
    this.setState({value:value})
  }

  renderTab(tab) {
    const {classes} = this.props
    const yearStats = tabStats
    const exceptions = ['src','alt']

    return <Grid container className={classes.regStats} spacing={3}>
      {map((omit(yearStats[tab],exceptions)), (stat, value) =>
        <Grid key={value} item xs={12} className={classes.stats}>
          <Typography variant='subtitle2' className={classes.greyText}>
            <Trans {...messages[value]} />
          </Typography>
          <Typography variant='h2' className={classes.details}>
            {stat}&nbsp;
          </Typography>
        </Grid>
      )}
      {(tabStats[tab].src) && <Grid item xs={12} classes={{item: classes.spaceshipItem}}>
        <img src={tabStats[tab]['src']} alt={tabStats[tab]['alt']} className={classes.spaceship}/>
      </Grid>}
    </Grid>
  }

  render() {
    const {classes} = this.props
    const {value} = this.state
    const years = Object.keys(tabStats)

    return (
      <div className={classes.root}>
        <AppBar position="static" classes={{root:classes.appBar}}>
          <Tabs
            value={value}
            onChange={this.handleChangeTab}
            indicatorColor='primary'
            textColor='primary'
            variant={'scrollable'}
            scrollButtons
            classes={{root:classes.tabs,flexContainer:classes.flexContainer,indicator:classes.indicator,scrollButtons:classes.scrollButtons}}
            allowScrollButtonsMobile>
            {map(years, (year)=><Tab key={year} label={year} classes={{root:classes.tab,selected:classes.selectedTab}} />)}
          </Tabs>
        </AppBar>
        {map(years, (year,index)=>
          <div key={index}>{value === index && this.renderTab(year)}</div>
        )}
      </div>
    )
  }
}

export default compose(withStyles(styles, {withTheme: true}))(GroupTradingStats)
