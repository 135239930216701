import PropTypes from 'prop-types'
import React, {Component} from 'react'
import Grid from '@mui/material/Grid'
import {flowRight as compose, get} from 'lodash'
import {frontends} from '@bdswiss/common-enums'
import {withNamespaces, Trans} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import Typography from '@mui/material/Typography'
import {withCreateDeposit, PaymentActionButton} from './helpers'
import DescriptionPaymentMethod from './DescriptionPaymentMethod'
import messages from '../../../../assets/messages'
import {getPlatform, getSourceBasedOnLocationPrevPath, logEventCustomParams} from '../../../../common/utils'

const list = ['registerAstropay','purchaseAstropay','paymentAstropay','useAstropay']

const style = (theme) => ({
  textCenter:{
    textAlign: 'center'
  },
})

class AstropayProvider extends Component {
  state = {gettingUrl: false}

  getUrl() {
    const {providerProperties: {provider, paymentKey}, account, amount, onError, useVirtualPaymentPage, bonusAmount,
      history: {location}} = this.props

    this.setState({gettingUrl: true})

    const firebaseParams = {
      source: getSourceBasedOnLocationPrevPath(get(location, 'state', {prevPath: get(location, 'pathname')})),
      login: account.id,
      currency: account.currency,
      vendor: provider,
      amount,
    }
    logEventCustomParams('depositAttempted', firebaseParams)

    const variables = {
      accountId: account.id,
      amount,
      vendor: provider,
      frontend: frontends.web2.value,
      args: JSON.stringify({
        platform: getPlatform(),
        paymentKey,
        useVirtualPaymentPage,
        bonusAmount,
      }),
    }

    this.props.createDepositRequest({variables})
      .then(({data: {newDeposit}}) => {
        const {payment: {url}} = newDeposit
        window.location = url
      })
      .catch((e) => {
        this.setState({gettingUrl: false})
        onError && onError(e)
      })
  }

  render() {
    const {providerProperties: {provider}, classes, canProceed} = this.props
    const {gettingUrl} = this.state

    return (
      <React.Fragment>
        <Grid item lg={12}>
          <Typography variant="h3" className={classes.textCenter}>
            <Trans {...messages.getAstropay} />
          </Typography>
          <ul>
            {
              list.map((item) => <li key={item}>
                <Typography variant="body1">
                  <Trans {...messages[item]} />
                </Typography>
              </li>
              )
            }
          </ul>
        </Grid>
        <DescriptionPaymentMethod provider={provider}/>
        <PaymentActionButton
          loading={gettingUrl}
          disable={canProceed}
          onClick={() =>  this.getUrl()}
        />
      </React.Fragment>
    )
  }
}

AstropayProvider.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  amount: PropTypes.number.isRequired,
  providerProperties: PropTypes.shape({
    name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    paymentKey: PropTypes.string,
  }).isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

export default compose(
  withStyles(style),
  withNamespaces(),
  withCreateDeposit
)(AstropayProvider)
