import React, {Component} from 'react'
import QRCode from 'react-qr-code'

import {styled} from '@mui/system'

import AppContext from '../Common/contexts/AppContext'
import {getCurrentTheme, isDarkTheme} from '../../common/utils'

const QRCodeWrap = styled('div')(({theme}) => ({
  display: 'flex',
  border: '1px solid',
  borderColor: theme.palette.mode === 'dark' ? '#FFFFFF1A' : '#EBEBEB',
  borderRadius: 8,
  margin: '20px 0',
  '& svg': {
    width: '100%',
    height: '100%',
    padding: '25px',
  },
}))

class ModalQRCode extends Component {
  static contextType = AppContext

  render() {
    const themePreference = getCurrentTheme(this.context)
    const {value} = this.props

    return (
      <QRCodeWrap>
        <QRCode
          size={180}
          value={value}
          fgColor={isDarkTheme(themePreference) ? '#FFFFFF' : '#1F1F1F'}
          bgColor={isDarkTheme(themePreference) ? '#1F1F1F' : '#FFFFFF'}
        />
      </QRCodeWrap>
    )
  }

}

export default ModalQRCode
