import React from 'react'
import PropTypes from 'prop-types'
import {graphql, withApollo} from 'react-apollo'
import {withNamespaces, Trans} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import Table from '@mui/material/Table'
import Tooltip from '@mui/material/Tooltip'
import CloseIcon from '@mui/icons-material/Close'
import TableBody from '@mui/material/TableBody'
import TableFooter from '@mui/material/TableFooter'
import TablePagination from '@mui/material/TablePagination'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {FOREX_POSITIONS, FOREX_POSITIONS_COUNT} from '../../../graphql/queries'
import {get, flowRight as compose} from 'lodash'
import moment from 'moment'
import messages from '../../../assets/messages'
import {isMobile} from '../../../common/utils/browser'
import classNames from 'classnames'
import Typography from '@mui/material/Typography'

const styles = theme => ({
  symbolCell: {
    fontSize: 16,
    fontWeight: 300,
  },
  mobileTrade: {
    margin: '10px 0',
  },
  mobileProfit: {
    fontSize: 16,
    fontWeight: 300,
    textAlign: 'right',
  },
  mobileDate: {
    color: theme.palette.secondary.main,
    textAlign: 'right',
  },
  infoCell: {
    color: theme.palette.secondary.main,
  },
  buy: {
    textTransform: 'uppercase',
    color: theme.palette.green.color,
  },
  sell: {
    textTransform: 'uppercase',
    color: theme.palette.red.color,
  },
  loss: {
    color: theme.palette.red.color,
  },
  profit: {
    color: theme.palette.green.color,
  },
  paginationSelectIcon: {
    right: '-5px',
  },
  closePosition: {
    textAlign: 'center',
  },
  closePositionButton: {
    cursor: 'pointer',
    width: 15,
    height: 15,
    color: theme.palette.error.main,
    fontWeight: 300,
    paddingRight: 0,
    paddingLeft: 0,
    minWidth: 30,
  },
  [theme.breakpoints.down('lg')]: {
    paginationAction: {
      marginLeft: 0,
    },
    paginationToolbar: {
      paddingLeft: 0,
    },
    closePosition: {
      textAlign: 'right',
    },
  },
  tableCell:{
    padding: '4px 25px 4px 24px',
    whiteSpace: 'nowrap'
  },
  centered:{
    textAlign:'center'
  },
  closePositionText:{
    color: theme.palette.error.main,
    lineHeight:'2.5rem',
    textDecoration:'underline',
    cursor: 'pointer',
  }
})

const ForexPositionsBodyComponent = props => {
  const {account, classes, positions, DBpositions, onClosePosition, status, assets} = props
  const currencyFormatter = new Intl.NumberFormat({}, {
    style: 'currency', currency: account.currency
  })
  const numberFormatter = new Intl.NumberFormat({},{minimumFractionDigits: 5})

  let ForexPositions
  let canClose = false
  if (positions) {
    ForexPositions = positions
    canClose = true
  }
  else  ForexPositions = DBpositions

  return (
    <TableBody>
      {ForexPositions.map(row => {
        const checkLeverageType = get(account, 'leverageType') === 'dynamic_leverage' ? <Trans {...messages.dynamic} /> :  `x${row.leverage}`
        return (
          <TableRow key={row.order || row.ticket}>
            <TableCell classes={{body: classNames(classes.infoCell,classes.tableCell)}}>
              {status === 'closed' ? moment(row.closeTime).format('MMM D YYYY, HH:mm') : moment(row.openTime).format('MMM D YYYY, HH:mm') }
            </TableCell>
            <TableCell classes={{body: classes.tableCell}}>{row.symbol}</TableCell>
            <TableCell classes={{body: classNames((row.type ? (row.type === 'buy' ? classes.buy : classes.sell) : ((row.command === 'buy') ? classes.buy : classes.sell)),classes.tableCell)}}>
              {row.type || row.command}
            </TableCell>
            {status !== 'pending' && <TableCell>{currencyFormatter.format(row.investment)}</TableCell>}
            <TableCell classes={{body: classNames(classes.infoCell,classes.tableCell)}}>{checkLeverageType}</TableCell>
            <TableCell classes={{body: classNames(status !== 'pending' ? classes.infoCell: '',classes.tableCell)}}>
              {status === 'pending' ? numberFormatter.format(row.openPrice).replace(/[0]+$/,'') : currencyFormatter.format(row.investment * row.leverage)}
            </TableCell>
            {status !== 'pending'  && <TableCell classes={{body: classNames(row.profit < 0 ? classes.loss : classes.profit,classes.tableCell)}}>
              {currencyFormatter.format(row.profit)}
            </TableCell>}
            {status === 'pending'  &&
            <TableCell classes={{body: classes.infoCell}}>{row.type === 'buy' ?  numberFormatter.format(assets[row.symbol].mbid).replace(/[0]+$/,'') :
              numberFormatter.format(assets[row.symbol].mask).replace(/[0]+$/,'')}</TableCell>}
            {(status !== 'closed') && canClose && <TableCell className={classNames(classes.closePosition,classes.tableCell)}>
              <Tooltip title={<Trans {...messages.closePosition} />} placement="right">
                <CloseIcon className={classes.closePositionButton} onClick={()=> onClosePosition(row.order, status)}/>
              </Tooltip>
            </TableCell>}
          </TableRow>
        )}
      )}
    </TableBody>
  )
}

const MobileForexPositionsBodyComponent = props => {
  const {account, classes, positions,DBpositions, onClosePosition, status, assets} = props
  const currencyFormatter = new Intl.NumberFormat({}, {
    style: 'currency', currency: account.currency
  })

  let ForexPositions
  let canClose = false
  if (positions) {
    ForexPositions = positions
    canClose = true
  }
  else  ForexPositions = DBpositions

  return (<List>
    {ForexPositions.map(row =>
      <React.Fragment key={row.order || row.ticket}>
        <Grid container justifyContent="space-between" className={classes.mobileTrade}>
          <Grid item xs={(status !== 'closed') && canClose ? 5 : 6}>
            <div className={classes.symbolCell}>{row.symbol}</div>
            <div className={classes.infoCell}>
              {row.type || row.command.toUpperCase()} {(status!=='pending') ? currencyFormatter.format(row.investment) : row.type === 'buy' ?  assets[row.symbol].mbid : assets[row.symbol].mask}
            </div>
          </Grid>
          <Grid item xs={(status !== 'closed') && canClose ? 5 : 6}>
            <div className={classes.mobileDate}>{status === 'closed' ? moment(row.closeTime).format('MMM D YYYY, HH:mm') : moment(row.openTime).format('MMM D YYYY, HH:mm')}</div>
            {(status!=='pending') ? <div className={[row.profit < 0 ? classes.loss : classes.profit, classes.mobileProfit].join(' ')}>
              {currencyFormatter.format(row.profit)}
            </div>
              : <div className={[row.type === 'buy' ? classes.buy : classes.sell, classes.mobileProfit].join(' ')}>{row.openPrice}</div>
            }
          </Grid>
          {(status !== 'closed') && canClose && <Grid item xs={2} className={classes.closePosition}>
            <Typography className={classes.closePositionText} onClick={()=> onClosePosition(row.order, status)}><Trans {...messages.close} /></Typography>
          </Grid>}
        </Grid>
        <Divider />
      </React.Fragment>
    )}
  </List>)
}

const ForexPositionsBody = compose(
  withNamespaces(),
  graphql(FOREX_POSITIONS, {
    options: (props) => {
      const page = get(props, 'page', 0)
      const rowsPerPage = get(props, 'rowsPerPage', 25)
      return {
        variables: {id: props.account.id, status: props.status, offset: page * rowsPerPage, limit: rowsPerPage},
        fetchPolicy: 'network-only'
      }
    },
    props: ({data: {viewer, error, loading}}) => {
      const DBpositions = get(viewer, 'accounts.0.forexPositions') || []
      return {
        error,
        loading,
        DBpositions,
      }
    }
  })
)(isMobile() ? MobileForexPositionsBodyComponent : ForexPositionsBodyComponent)


class MobileForexPositions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {page: 0, rowsPerPage: 10}
  }

  render() {
    const {count, enablePagination, classes} = this.props
    const {page, rowsPerPage} = this.state
    return (
      <Grid container>
        <Grid item xs={12}>
          <ForexPositionsBody {...this.props} page={this.state.page} rowsPerPage={this.state.rowsPerPage} />
          {enablePagination &&
          <React.Fragment>
            <Table>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(e, page) => this.setState({page})}
                    onRowsPerPageChange={e => this.setState({rowsPerPage: e.target.value})}
                    classes={{actions: classes.paginationAction, toolbar: classes.paginationToolbar,
                      selectIcon: classes.paginationSelectIcon}}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </React.Fragment>
          }
        </Grid>
      </Grid>
    )
  }
}

class ForexPositions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {page: 0, rowsPerPage: 10}
  }

  render() {
    const {count, enablePagination, status, classes, positions} = this.props
    const {page, rowsPerPage} = this.state

    return <React.Fragment>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell classes={{root:classes.tableCell}}>{status === 'closed' ? <Trans {...messages.timeClosed} /> : <Trans {...messages.openTime} />}</TableCell>
            <TableCell classes={{root:classes.tableCell}}><Trans {...messages.symbol} /></TableCell>
            <TableCell classes={{root:classes.tableCell}}><Trans {...messages.direction} /></TableCell>
            {status !=='pending' && <TableCell classes={{root:classes.tableCell}}><Trans {...messages.initialMargin} /></TableCell>}
            <TableCell classes={{root:classes.tableCell}}><Trans {...messages.multiplier} /></TableCell>
            <TableCell classes={{root:classes.tableCell}}>{status === 'pending' ? <Trans {...messages.openPrice} /> : <Trans {...messages.value} />}</TableCell>
            {status !== 'pending' && <TableCell classes={{root:classes.tableCell}}><Trans {...messages.profit} /></TableCell>}
            {status === 'pending' && <TableCell classes={{root:classes.tableCell}}><Trans {...messages.currentPrice} /></TableCell>}
            {(status === 'open' || status ==='pending') && positions && <TableCell classes={{root:classNames(classes.tableCell, classes.centered)}}><Trans {...messages.action} /></TableCell>}
          </TableRow>
        </TableHead>
        <ForexPositionsBody {...this.props} page={this.state.page} rowsPerPage={this.state.rowsPerPage} />
        {
          enablePagination &&
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(e, page) => this.setState({page})}
                  onRowsPerPageChange={e => this.setState({rowsPerPage: e.target.value})}
                />
              </TableRow>
            </TableFooter>
        }
      </Table>
    </React.Fragment>
  }
}

ForexPositions.propTypes = {
  account: PropTypes.object.isRequired,
  enablePagination: PropTypes.bool,
  status: PropTypes.oneOf(['open', 'closed', 'pending']).isRequired,
}

export default compose(
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
  withApollo,
  graphql(FOREX_POSITIONS_COUNT, {
    options: (props) => ({variables: {id: props.account.id, status: props.status}, fetchPolicy: 'network-only'}),
    props: ({data: {viewer, error, loading}}) => {
      const count = get(viewer, 'accounts.0.forexPositionsCount', 0)
      return {
        error,
        loading,
        count,
      }
    }
  }),
)(isMobile() ? MobileForexPositions : ForexPositions)
