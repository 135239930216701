import {map, filter, size, get, flowRight as compose} from 'lodash'
import React, {Component} from 'react'
import {withNamespaces} from 'react-i18next'
import {suitabilityTestSteps, accountTypes} from '@bdswiss/common-enums'
import {Typography, Grid, Button} from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'
import withStyles from '@mui/styles/withStyles'
import AppContext from '../../../Common/contexts/AppContext'
import PortfolioManagementExplanationModal from '../../../Common/PortfolioManagementExplanationModal'
import messages from '../../../../assets/messages'
export class PortfolioManagementHeader extends Component {
  static contextType = AppContext

  state = {
    openPopup: false,
  }

  handleOpen = () => {
    this.setState({openPopup: true})
  }

  handleClose = () => {
    this.setState({openPopup: false})
  }
  render() {
    const {t, progress, activeStep, disableSubmit} = this.props
    const {locale, companyObject} = this.context
    const getSubTypes = filter(accountTypes, (p)=> p.subCategory === 'fundManagement' && (get(companyObject, 'value') === p.company))
    const checkSuitabilityTestSteps = filter(suitabilityTestSteps, (s) => !s.skip(disableSubmit  || (s.key === suitabilityTestSteps.accountSelection.key && getSubTypes.length <= 1)))

    return (
      <div>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button onClick={()=> this.handleOpen()} align="right">
            <Typography align="right" variant="body2" color="primary">
              {t(messages.whyIsThisNeeded.i18nKey, messages.whyIsThisNeeded.defaults)} </Typography>
          </Button>
        </Grid>
        <LinearProgress variant="determinate" color="primary" value={progress}/>
        <Typography variant="body1"> {activeStep + 1}/{size(checkSuitabilityTestSteps)} </Typography>
        {map(checkSuitabilityTestSteps, (step, stepKey) => {
          const firstStep = getSubTypes.length <= 1
          return activeStep === step.stepPosition(disableSubmit, firstStep) && (<Typography key={stepKey} variant="h3">
            {step.localization.t(locale)} </Typography>)
        })}
        <PortfolioManagementExplanationModal open={this.state.openPopup} onClose={() => this.handleClose()} />
      </div>
    )
  }
}

export default compose(withStyles({withTheme: true}),withNamespaces())(PortfolioManagementHeader)
