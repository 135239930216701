import React from 'react'
import {flowRight as compose, filter, first, get, isEmpty, map, omitBy} from 'lodash'
import {graphql} from 'react-apollo'
import {withRouter} from 'react-router-dom/cjs/react-router-dom.min'
import {Trans, withNamespaces} from 'react-i18next'
import {CLIENT_DATA_QUERY, SOCIALMEDIALINKS_QUERY} from '../../graphql/queries'
import withStyles from '@mui/styles/withStyles'
import Typography from '@mui/material/Typography'
import AppContext from './contexts/AppContext'
import messages from '../../assets/messages'
import {promotionalBanners} from '../../common/utils/uioptions'
import {isMobile} from '../../common/utils'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import {logEventCustomParams} from '../../common/utils/firebaseEvents'
import LoadingWrapper from '../Dashboard/LoadingWrapper'
import Box from '@mui/material/Box'

const styles= theme => ({
  banner: {
    backgroundColor: '#181818',
    height: '320px',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
    color: '#FFFFFF',
    display: 'flex',
    padding: '24px',
    gap: theme.spacing(1),
    flexDirection: 'column',
    borderRadius: theme.spacing(1),
  },
  bannerTitle:{
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing:'0.15px',
    textAlign: theme.direction === 'rtl' ? 'right' : 'left',
  },
  bannerSubtitle:{
    fontSize:'14px',
    fontWeight: 400,
    lineHeight:'20px',
    letterSpacing:'0.25px',
    textAlign: theme.direction === 'rtl' ? 'right' : 'left',
  },
  item: {
    maxWidth: (props) => props.maxItemWidth.lgUp,
    [theme.breakpoints.down('md')]: {
      maxWidth: (props) => props.maxItemWidth.mdDown,
    },
  },
  slider:{
    gap: theme.spacing(2),
  }
})

class PromotionalBanners extends React.Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    this.state = {
      additionalTransfrom: 0,
    }
  }

  getItemTransform(nextSlide, carouselState) {
    const {deviceType, currentSlide} = carouselState

    switch (deviceType) {
      case 'xl': {
        const additionalTransfrom = this.state.additionalTransfrom + (nextSlide - currentSlide) * -8
        this.setState({additionalTransfrom: additionalTransfrom})
        break
      }
      case 'lg': {
        const additionalTransfrom = this.state.additionalTransfrom + (nextSlide - currentSlide) * -8
        this.setState({additionalTransfrom: additionalTransfrom})
        break
      }
      case 'sm': {
        const additionalTransfrom = this.state.additionalTransfrom + (nextSlide - currentSlide) * -10
        this.setState({additionalTransfrom: additionalTransfrom})
        break
      }
      case 'xs': {
        const additionalTransfrom = this.state.additionalTransfrom + (nextSlide - currentSlide) * -16
        this.setState({additionalTransfrom: additionalTransfrom})
        break
      }
      default: {
        this.setState({additionalTransfrom: 0})
      }
    }
  }

  getResponsiveObject(theme) {
    if (!theme) {
      return ({
        'xl':{
          breakpoint: {max: 10000, min: 901},
          items: 4,
        },
        'lg': {
          breakpoint: {max: 900, min: 601},
          items: 3,
        },
        'sm': {
          items: 2,
          breakpoint: {max: 600, min: 376},
        },
        'xs': {
          items: 1,
          breakpoint: {max: 375, min: 0},
          partialVisibilityGutter: 60,
        }
      })
    }

    return ({
      'xl':{
        items: 4,
        breakpoint: {max: 10000, min: theme.breakpoints.values.xl},
      },
      'lg': {
        items: 3,
        breakpoint: {max: theme.breakpoints.values.xl - 1, min: theme.breakpoints.values.md},
      },
      'sm': {
        items: 1,
        breakpoint: {max: theme.breakpoints.values.md - 1, min: theme.breakpoints.values.sm},
      },
      'xs': {
        items: 1,
        breakpoint: {max: theme.breakpoints.values.sm - 1, min: theme.breakpoints.values.xs},
        partialVisibilityGutter: 60,
      }
    })
  }

  render() {
    const {classes , viewer, theme, links, loadingClient}= this.props
    const responsive = this.getResponsiveObject()
    const sortedLinks= omitBy(links, value => isEmpty(value) || value === 'socialMediaType')

    return (
      <Carousel
        responsive={responsive}
        autoPlay={false}
        showDots={false}
        arrows={false}
        draggable={false}
        centerMode={false}
        itemClass={classes.item}
        sliderClass={classes.slider}
        partialVisible={true}
        infinite={false}
        slidesToSlide={1}
        rtl={theme?.direction === 'rtl'}
        swipeable={isMobile() ? true : false}
        rewindWithAnimation={false}
        additionalTransfrom={this.state.additionalTransfrom}
        beforeChange={(nextSlide, carouselState) =>{
          this.getItemTransform(nextSlide, carouselState)
        }}
      >
        {map(filter(promotionalBanners, (banner) => banner.show(viewer)), (a, index) => (
          <LoadingWrapper key={index} loading={loadingClient} classes={{root: classes.lightGreyLoading}}> <Box
            className={classes.banner}
            style={{backgroundImage: `url(${a.image})`}}
            onClick= {() =>{
              window.open (a?.link || sortedLinks?.telegram)
              logEventCustomParams('promotionalBannerClicked', {
                userId: get(this.props, 'viewer.id'),
                bannerType: a.title.defaults
              })
            }}
          >
            <Typography variant="text" className={classes.bannerTitle}>  <Trans {...messages[a.title.i18nKey]}/> </Typography>
            <Typography variant="text" className={classes.bannerSubtitle}>  <Trans {...messages[a.subtitle.i18nKey]}/></Typography>
          </Box>
          </LoadingWrapper>
        ))}
      </Carousel>
    )
  }
}

export default compose(
  withRouter,
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
  graphql(CLIENT_DATA_QUERY, {
    options: () => ({fetchPolicy: 'network-only'}),
    props: ({data: {error, loading}, data}) => ({
      errorClient: error,
      loadingClient: loading,
      viewer: get(data, 'viewer'),
    }),
  }),
  graphql(SOCIALMEDIALINKS_QUERY,{
    options: () => ({
      fetchPolicy: 'network-only',
    }),
    props: ({data: {loading, error}, data}) =>
      ({
        loadingSocialMedia: loading,
        errorSocialMedia: error,
        links: first(get(data, 'socialMediaLinks'))
      }),
  }),
)(PromotionalBanners)
