import React, {Component} from 'react'
import {get, flowRight as compose, last} from 'lodash'
import {graphql} from 'react-apollo'
import Typography from '@mui/material/Typography'
import {withNamespaces, Trans} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import {LinearProgress} from '@mui/material'
import PageTitle from '../Common/PageTitle'
import FormHelperText from '@mui/material/FormHelperText'
import LoadingButton from '../Common/LoadingButton'
import {isMobile, setCookie} from '../../common/utils/browser'
import Grid from '@mui/material/Grid'
import PageBody from '../Common/PageBody'
import messages from '../../assets/messages'
import {FullScreenDialog} from '../Common/Dialog'
import config from '../../config'
import {CLIENT_DATA_QUERY, ACCOUNTS_QUERY} from '../../graphql/queries'
import {CHANGE_COMPANY_MUTATION} from '../../graphql/mutations'
import {companies, whiteLabels, campaignTypes} from '@bdswiss/common-enums'
import {Redirect} from 'react-router-dom'
import {findCompany, isWhiteLabel, removeSessInfoFromLocalStorage} from '../../common/utils'
import {logoutRequest} from '../../common/utils/requests'
import {hasWalletProductAccount} from '../../common/utils/accounts'
import {logEventCustomParams} from '../../common/utils/firebaseEvents'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  subtitle1Strong: {
    fontWeight: 500,
  },
  highlight: {
    cursor: 'pointer',
    color: theme.palette.primary.main
  },
  loadingButtonMobile: {
    width: '100%',
  },
  loadingButtonMobileHeight: {
    marginTop: 10,
  }

})

class Migrate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      status: '',
      submitMessageError:'',
      redirectUrlTo: false
    }
  }

  rejectMigration() {
    const {history, location} = this.props
    const redirectTo = get(location , 'state.redirectTo', '/')
    setCookie('__mig-rejected', true, 7)
    history.push(redirectTo)
  }

  confirmMigration() {
    const {t, viewer} = this.props
    const {featuresConfig: {migrateCompany}} = config
    const whiteLabel = isWhiteLabel()
    const toCompany = (whiteLabel ? whiteLabels[migrateCompany.toCompany].company.value : migrateCompany.toCompany) || ''
    const clientId = get(viewer, 'id')
    this.setState({status: '', loading: true})
    this.props.migrateAccount({variables: {clientId, newCompany: toCompany}}).then((res) => {
      const {companyFrontEndUrl} = get(res, 'data.updatedClient')
      logoutRequest()
        .then(() => {
          const params = {
            reason: `migrated - ${clientId}`,
            userId: clientId,
          }
          logEventCustomParams('logout', params)
          removeSessInfoFromLocalStorage()
          if (companyFrontEndUrl) window.location = companyFrontEndUrl
          else window.location.href = '/login'
        })
    })
      .catch((err) => {
        if (err.networkError) {
          this.setState({loading: false,status: 'failure',
            submitMessageError: t(messages.networkError.i18nKey, messages.networkError.defaults),
          })
        } else {
          this.setState({loading: false,status: 'failure',
            submitMessageError: get( err, 'graphQLErrors[0].message') || err.message,
          })
        }

      })
  }

  render() {
    const {classes, viewerLoading, viewer, accounts} = this.props
    const {loading,submitMessageError,status} = this.state
    const {featuresConfig: {migrateCompany}, forceCompany} = config
    const company = findCompany(forceCompany)
    const toCompany = isWhiteLabel() ? whiteLabels[migrateCompany.toCompany] : companies[migrateCompany.toCompany]
    const migrateTerms = migrateCompany.companyMigrationTerms || ''
    if (viewerLoading) return (<LinearProgress />)

    const registrationCampaign = last(get(viewer, 'registrationCampaigns'))
    const partnerCampaign = registrationCampaign && get(registrationCampaign, 'type') !== campaignTypes.regular.value

    if (!migrateCompany.enable || partnerCampaign || hasWalletProductAccount(accounts) || get(viewer, 'euMigration')) {
      return <Redirect to='/' />
    }

    return (
      <FullScreenDialog
        desktopOnly
        open={true}
        onClose={() => this.rejectMigration()}
      >
        <PageTitle
          onBack={() => this.rejectMigration()}>
          <Trans {...messages.accountMigration} />
        </PageTitle>
        <PageBody>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant='body1' gutterBottom>
                <Trans {...messages.dearTrader} />
              </Typography>
              <Typography variant='body1' paragraph>
                <Trans {...messages.clientMigrationText1} values={{domain: toCompany.domain}}/>
              </Typography>
              <Typography variant='body1' paragraph>
                <Trans {...messages.clientMigrationText2} values={{companyDomain: company.domain}} />
              </Typography>
              <Typography variant='body1' paragraph>
                <Trans {...messages.clientMigrationText3}
                  components={[<span className={classes.subtitle1Strong}>BDS Markets</span>]}/>
              </Typography>
              <Typography variant='body1' paragraph>
                <Trans {...messages.clientMigrationText31} />
              </Typography>
              <Typography variant='body1'>
                <Trans {...messages.clientMigrationText4} values={{domain: toCompany.domain}}
                  components={[<span className={classes.subtitle1Strong}>Confirm</span>]}/>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Grid item className={isMobile() ? classes.loadingButtonMobile : ''}>
              <LoadingButton
                id='loadingButton'
                onClick={() => this.confirmMigration()}
                disabled={loading}
                status={status}
                fullWidth={isMobile()}
                className={isMobile() ? classes.loadingButtonMobileHeight : ''}
              ><Trans {...messages.migrateAccept} />
              </LoadingButton>
              {status==='failure' &&
                <FormHelperText className={classes.errorMessage}>{submitMessageError}</FormHelperText>}
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            {migrateTerms &&<Grid item xs={12}>
              <Typography variant='body1'>
                <Trans {...messages.bdswissMigrationConfirmDisclaimer}
                  components={[
                    <a target="_blank" rel="noopener noreferrer"
                      href={migrateTerms} className={classes.highlight}>Terms and Conditions</a>
                  ]}/>
              </Typography>
            </Grid>
            }
          </Grid>
        </PageBody>
      </FullScreenDialog>
    )
  }
}

export default compose(
  withStyles(styles),
  withNamespaces(),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {loading: viewerLoading, error: viewerError}, data}) => {
      const viewer = get(data, 'viewer')
      return {
        viewerLoading,
        viewerError,
        viewer
      }
    }
  }),
  graphql(CHANGE_COMPANY_MUTATION, {
    name: 'migrateAccount',
    options: {
      refetchQueries: [{query: ACCOUNTS_QUERY}],
    },
    update: cache => {
      cache.writeData({data: {props: []}})
    },
  }),
  graphql(ACCOUNTS_QUERY, {
    options: () => ({fetchPolicy: 'network-only'}),
    props: ({data: {error, loading}, data}) => ({
      error,
      loading,
      accounts: get(data, 'viewer.accounts'),
    })
  }),
)(Migrate)
