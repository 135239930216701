import {graphql} from 'react-apollo'
import withStyles from '@mui/styles/withStyles'
import {Trans, withNamespaces} from 'react-i18next'
import {CLIENT_DATA_QUERY} from '../../graphql/queries'
import {CREATE_SUPPORT_TICKET} from '../../graphql/mutations'
import {flowRight as compose, get, map} from 'lodash'
import {isMobile} from '../../common/utils/browser'
import PageTitle from '../Common/PageTitle'
import AppContext from '../Common/contexts/AppContext'
import React, {useContext, useEffect, useState} from 'react'
import messages from '../../assets/messages'
import {Button} from '@mui/material'
import {Alert} from '@mui/lab'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import {
  autoSelectTradingAccountWithHighestBalance,
  getAutochartistDetails,
  getAutochartistNewsSentimentAnalysis,
} from '../../common/utils'
import Loading from '../Common/Loading'
import * as enums from '@bdswiss/common-enums'

const styles = theme => ({
  iframe: {
    width: '100%',
    height: '90vh',
    border: 'none',
  },
  margin__bottom_30: {
    marginBottom: 30
  }
})

const autochartistTabs = [
  {
    key: 'trading-signals',
    title: messages.tradingSignalsSmall,
  },
  {
    key: 'news-sentiment-analysis',
    title: messages.newsSentimentAnalysis,
  }
]


function Autochartist(props) {
  const context = useContext(AppContext)
  const {themePreference, locale, accounts} = context
  const {t, history, classes} = props

  const [iframeTradingSignals, setIframeTradingSignals] = useState(<Loading/>)
  const [iframeSentimentAnalysis, setIFrameSentimentAnalysis] = useState(<Loading/>)
  const [autochartistDownloadLink, setAutochartistDownloadLink] = useState('')
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [selectedForexWebtraderState, setForexWebtraderState] = useState(null)
  const {clientData: {autoChartistEnabled}} = props

  useEffect(() => {
    (async function() {
      const {iFrameUrl: IFrameUrlAutochartistDetails = ''} = await getAutochartistDetails(locale)
      const {iFrameUrl: iFrameUrlSentimentAnalysis = ''} = await getAutochartistNewsSentimentAnalysis(locale, themePreference)
      setIframeTradingSignals(
        <iframe
          title={'trading-signals'}
          src={IFrameUrlAutochartistDetails}
          className={classes.iframe}
        />
      )
      setIFrameSentimentAnalysis(
        <iframe
          title={'news-sentiment-analysis'}
          src={iFrameUrlSentimentAnalysis}
          className={classes.iframe}
        />
      )
    })()
    if (autoChartistEnabled) {
      setAutochartistDownloadLink('https://ac-ai-ea.s3.eu-west-1.amazonaws.com/AutochartistMTEASetup.exe')
    }
  }, [autoChartistEnabled, classes.iframe, locale, themePreference])

  useEffect(() => {
    window.addEventListener('message', handleMessageEventTradeNow)
    return () => {
      window.removeEventListener('message', handleMessageEventTradeNow)
    }
  })

  const handleMessageEventTradeNow = (event) => {
    /*eslint-disable-next-line */
    const {type, value, sentiment} = event.data
    if (type !== 'tradeNow') return
    setIFrameSentimentAnalysis(<Loading/>)
    setForexWebtraderState({value, sentiment})
  }

  useEffect(() => {
    if (selectedForexWebtraderState) {
      (async () => {
        const account = await autoSelectTradingAccountWithHighestBalance(accounts)
        openForexWebtrader(account.login)
      })()
    }
    /* eslint-disable-next-line */
  }, [selectedForexWebtraderState])

  const openForexWebtrader = (accountId) => {
    const preselectedAssetSymbol = selectedForexWebtraderState.value.replace('-', '')

    const state = {
      preselectedAssetSymbol
    }

    if (selectedForexWebtraderState.sentiment !== 'neutral') {
      state.preselectedTradeMode = selectedForexWebtraderState.sentiment === 'positive'
        ? enums.tradingAlertsActions.buy.value
        : enums.tradingAlertsActions.sell.value
    }

    history.push({
      pathname: `/trade/forex/${accountId}`,
      state
    })
  }

  const handleClickDepositWithPreselectedAmount = () => {
    history.push({
      pathname: '/transactions/deposit',
      state: {
        preselectedCustom: {
          amount: 500,
          currency: enums.currencies.USD.value,
        }
      }
    })
  }

  /* eslint-disable arrow-body-style */
  const renderDepositToUpgrade = () => {
    return !autoChartistEnabled
      ? <Alert
        className={classes.margin__bottom_30}
        icon={false}
        severity="info"
        action={
          <Button
            size="small"
            variant={'text'}
            color="primary"
            onClick={() => handleClickDepositWithPreselectedAmount()}
          >
            <Trans {...messages.deposit}/>
          </Button>
        }
      ><Trans {...messages.depositToUpgradeAutochartist}/></Alert>
      : ''
  }

  /* eslint-disable arrow-body-style */
  const renderDownloadPlugin = () => {
    return autoChartistEnabled
      ? <Alert
        className={classes.margin__bottom_30}
        icon={false}
        severity="info"
        action={
          <Button
            size="small"
            variant={'text'}
            color="primary"
            onClick={() => window.open(autochartistDownloadLink)}
          >
            <Trans {...messages.download}/>
          </Button>
        }
      ><Trans {...messages.downloadAutochartistPlugin}/></Alert>
      : ''
  }

  const renderTabs = () => {
    if (!autoChartistEnabled) return ''

    return <Tabs
      value={activeTabIndex}
      className={classes.margin__bottom_30}
      onChange={(e, index) => setActiveTabIndex(index)}
      variant={isMobile() ? 'scrollable' : 'standard'}
      scrollButtons={false}
    >
      {map(autochartistTabs, (tab) => <Tab key={tab.key} label={<Trans {...tab.title} />}/>)}
    </Tabs>
  }

  return (
    <PageTitle
      themePreference={themePreference}
      onBack={() => history.push('/tools')}
      title={t(messages.autochartist.i18nKey, messages.autochartist.defaults)}
      withoutPadding={isMobile()}
    >
      {renderDepositToUpgrade()}
      {renderTabs()}
      {renderDownloadPlugin()}
      {activeTabIndex === 0 && iframeTradingSignals}
      {activeTabIndex === 1 && iframeSentimentAnalysis}
    </PageTitle>
  )
}

export default compose(
  withStyles(styles),
  withNamespaces(),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {loading, error}, data, ownProps: {history}}) => ({
      clientLoading: loading,
      error,
      history,
      clientData: get(data, 'viewer'),
    }),
  }),
  graphql(CREATE_SUPPORT_TICKET),
)(Autochartist)
