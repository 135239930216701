import React, {Component} from 'react'
import {withNamespaces} from 'react-i18next'
import {AlertDialog} from '../../../Common/Dialog'

export class PortfolioManagementPopupMessage extends Component {
  render() {
    return (
      <AlertDialog
        open={this.props.open}
        title={this.props.title}
        children={this.props.children}
        agreeText={this.props.agreeText}
        onAgree={this.props.onAgree}
        onClose={this.props.onClose}
        justifyContent={'center'}
        alignItems={'center'}
      />
    )
  }
}

export default withNamespaces()(PortfolioManagementPopupMessage)
