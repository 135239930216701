import React, {Component} from 'react'
import {filter, find, first, flowRight as compose, get, includes, lowerCase, map, replace, split} from 'lodash'
import {withNamespaces} from 'react-i18next'
import {Redirect, withRouter} from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles'
import withStyles from '@mui/styles/withStyles'
import config from './config'
import appThemes from './themes'
import TopLevelRoutes from './routes'
import i18nApp from './i18n'
import {
  firebaseInit,
  getIPCountry,
  getItem,
  logEventCustomParams,
  replaceBrowserLocale,
  storeItem
} from './common/utils'
import {isMobile} from './common/utils/browser'
import AppContext from './components/Common/contexts/AppContext'
import {findCompany, getCurrentTheme, isDarkTheme} from './common/utils/general'
import {countries, languages} from '@bdswiss/common-enums'
import {isRtlLang, momentLocales} from './common/utils/uioptions'
import Images from './components/Common/Images'
import queryString from 'qs'
import ThemeSelection from './components/ThemeSelection'
import SystemNotification from './components/Common/SystemNotification'

const styles = theme => ({
  mainBody: {
    maxWidth: theme.mainBodyWidth,
    margin: 'auto',
    minHeight: '100%',
    position: 'relative',
  },
  affiliateBackground: {
    background: `url(${Images[get(config, 'registrationBackground.affiliate')]}) no-repeat center center fixed`,
    'background-size': 'cover',
    overflow: 'hidden',
  },
})

// TODO: #refactor: remove code soon
//
// const registrationWidget = includes(get(window, 'location.pathname'), '/register-widget')
// const websiteLanguage = getCookie('current_website_language')
// const supportedLanguages = map(filter(languages, (a) => a.client && !a.disabled), 'value')
// if (websiteLanguage && includes(supportedLanguages,websiteLanguage) && !registrationWidget) {
//   storeItem('locale', websiteLanguage)
//   setCookie('current_website_language', null, 0, `.${window.location.hostname}`)
// }

i18nApp.init(getItem('locale', 'en'))

class AppProvider extends Component {
  static contextType = AppContext
  supportedLanguages = map(filter(languages, (a) => a.client && !a.disabled), 'value')

  state = {
    theme: getCurrentTheme(),
    locale: getItem('locale', 'en'),
    systemNotificationType: '',
    systemNotificationMessage: '',
    systemNotificationCustomMessage: false,
  }

  async componentDidMount() {
    this.clearUnnecessaryCookiesAndStorage()

    const {location} = this.props
    const urlParams = queryString.parse(location.search.replace('?', ''))
    const urlLang = get(urlParams, 'lang')

    if (urlLang && includes(this.supportedLanguages, urlLang)) {
      return this.setLocale(urlLang)
    }

    const storageLang = getItem('locale')
    if (storageLang && includes(this.supportedLanguages, storageLang)) {
      return this.setLocale(storageLang)
    }

    await this.autoDetectLocale()
  }

  clearUnnecessaryCookiesAndStorage() {
    const {location: {pathname}} = this.props
    const topLevelRoutes = get(TopLevelRoutes(), 'props.children')
    const loggedOutRoutes = topLevelRoutes && filter(map(topLevelRoutes, (child) => get(child, 'props.path') || get(child, 'props.to')), (route) => route && !includes(['/'], route))
    const isRegistrationPath = includes(pathname, '/register')

    /** Not logged in routes */
    /** Clear unnecessary Local Storage values */
    if (includes(loggedOutRoutes, replace(pathname, /\/+$/, '')) || isRegistrationPath) {
      if (window?.localStorage && window.localStorage.getItem('activity_detector')) {
        window.localStorage.removeItem('activity_detector')
      }
    }
  }

  async getCountry() {
    return await getIPCountry()
      .then(res => lowerCase(res['country_code']))
      .catch(err => err)
  }

  setLocale(locale) {
    if (!locale) {
      return
    }

    i18nApp.init(locale)
    storeItem('locale', locale)
    this.setState({locale: locale})
  }

  async autoDetectLocale() {
    const userId = get(this.context, 'clientId')

    const defaultLocale = get(momentLocales, 'default')
    const browserLocale = replaceBrowserLocale(first(split(window.navigator.userLanguage || window.navigator.language, '-', 1)))

    const countryCode = await this.getCountry()
    const {
      locale: countryLocale,
      label: countryLabel
    } = find(countries, (countryObj, key) => key === countryCode) || {locale: '', label: ''}

    if (browserLocale === defaultLocale) {
      if (includes(this.supportedLanguages, countryLocale)) {
        this.setLocale(countryLocale)
        runFirebaseEvent(countryLocale)
        return
      }
    }
    if (includes(this.supportedLanguages, browserLocale)) {
      this.setLocale(browserLocale)
      runFirebaseEvent(browserLocale)
      return
    }

    this.setLocale(defaultLocale)

    function runFirebaseEvent(locale) {
      const startFirebase = firebaseInit()
      const params = {
        locale,
        country: countryLabel,
        userId,
      }
      startFirebase && logEventCustomParams('changeLanguageAutomated', params)
    }
  }

  getTheme(partnerRegistration) {
    const themePreference = this.state.theme
    let appTheme
    const currentLocale = this.state.locale || getItem('locale', 'en')
    const isRtl = currentLocale && includes(isRtlLang, currentLocale) ? 'Rtl' : ''
    if (isDarkTheme(themePreference) && !partnerRegistration) {
      appTheme = appThemes[`${config.brand}Dark${isRtl}`]
    } else {
      appTheme = appThemes[`${config.brand}${isRtl}`]
    }
    return appTheme
  }
  showSystemNotification = (systemNotificationType, systemNotificationMessage, systemNotificationCustomMessage) => {
    this.setState({
      systemNotificationType: systemNotificationType,
      systemNotificationMessage: systemNotificationMessage,
      systemNotificationCustomMessage: systemNotificationCustomMessage
    })
  }
  hideSystemNotification () {
    this.setState({
      systemNotificationType: '',
      systemNotificationMessage: '',
      systemNotificationCustomMessage: false,
    })
  }

  render() {
    const {classes, location} = this.props
    const {systemNotificationType, systemNotificationMessage, systemNotificationCustomMessage} = this.state
    const {maintenance, forcedCompany, showInformativePageOnly} = config
    const pathnameValue = get(split(get(location, 'pathname'), '/'), '[2]')
    const partnerRegistration = includes(get(this.props.location, 'pathname'), 'register') && !!get(config, `registrationBackground[${pathnameValue}]`)
    const registrationWidget = includes(get(this.props.location, 'pathname'), 'register-widget')
    const informativePage = includes(get(this.props.location, 'pathname'), 'informativePage')
    const loginPage = includes(get(this.props.location, 'pathname'), 'login')

    document.dir = includes(isRtlLang, this.state.locale) ? 'rtl' : ''

    if (maintenance && location.pathname.indexOf('register') === 1) {
      return <Redirect to={{pathname: '/maintenance'}}/>
    }

    if (showInformativePageOnly && location.pathname.indexOf('informativePage') !== 1) {
      return <Redirect to={{pathname: '/informativePage'}}/>
    }

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={this.getTheme(partnerRegistration)}>
          <AppContext.Provider
            value={{
              toggleTheme: (selectedTheme, locale) => this.setState({theme: partnerRegistration ? 'light' : selectedTheme}, () => this.setLocale(locale)),
              themePreference: this.state.theme,
              showNotification: () => {
              },
              locale: this.state.locale,
              company: config.key,
              companyObject: findCompany(forcedCompany),
              showSystemNotification: this.showSystemNotification
            }}
          >
            <CssBaseline>
              <div
                className={!registrationWidget ? classes[`${pathnameValue}Background`] || classes.mainBody : classes.mainBody}
              >
                <TopLevelRoutes location={location} />
                {!partnerRegistration && !registrationWidget && !informativePage && loginPage && !isMobile() && (
                  <ThemeSelection/>)}
              </div>
              {systemNotificationMessage && systemNotificationType &&
              <SystemNotification
                type={systemNotificationType}
                message={systemNotificationMessage}
                customMessage={systemNotificationCustomMessage}
                hideSystemNotification = {() => this.hideSystemNotification()}
              />}
            </CssBaseline>
          </AppContext.Provider>
        </ThemeProvider>
      </StyledEngineProvider>
    )
  }
}

export default compose(withStyles(styles, {withTheme: true}), withNamespaces(), withRouter)(AppProvider)
