import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {Trans, withNamespaces} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import messages from '../../assets/messages'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import classNames from 'classnames'
import HelpIcon from '@mui/icons-material/HelpOutline'
import LockIcon from '@mui/icons-material/LockOutlined'
import FlagIcon from '@mui/icons-material/FlagOutlined'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import {find, flowRight as compose, get, includes, keys, map, size, toString} from 'lodash'
import AppContext from '../Common/contexts/AppContext'
import {LinearProgress} from '@mui/material'
import {isMobile, safeParseJSON} from '../../common/utils'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import moment from 'moment'
import {RedeemPrizeDialog} from './RedeemPrizeDialog'
import {AFFILIATE_SSO_LINK_QUERY, CLIENT_PARTNERS_LOYALTY_MUTATION, IB_SSO_LINK_MUTATION} from '../../graphql/mutations'
import {graphql} from 'react-apollo'
import {CLIENT_DATA_QUERY} from '../../graphql/queries'
import CheckIcon from '@mui/icons-material/Check'
import {partnersLoyaltyProgressBarStatus} from '../../common/utils/uioptions'
import {hasAffiliateAccount, hasIntroducingBrokerAccount, isAffiliateClient, isAffiliatesAccount, isIntroducingBrokerAccount} from '../../common/utils/accounts'
import CustomTooltip from '../Common/CustomTooltip'
import CloseIcon from '@mui/icons-material/Close'

const styles = theme => ({
  displayFlex: {
    display: 'flex'
  },
  icon: {
    marginBottom: -3,
    marginLeft: 3,
    fontSize: 16,
    color: theme.palette.red.textColor,
    backgroundColor: theme.palette.red.color,
    width: 24,
    height: 24,
    borderRadius: 40,
    padding: 3,
  },
  iconDisabled: {
    backgroundColor: theme.palette.secondary.main,
  },
  cardHover: {
    width: '100%',
    '&:hover':{
      backgroundColor: theme.palette.cardBackground.color,
      cursor: 'default',
    },
  },
  opacity: {
    opacity: 0.5
  },
  inline: {
    display: 'inline-block'
  },
  pointer: {
    cursor: 'pointer',
  },
  link: {
    color: theme.palette.primary.main,
  },
  highlightText:{
    fontWeight:400
  },
  cardDiv: {
    border: `1px solid ${theme.palette.lightgreen.color}`,
    backgroundColor: theme.palette.lightgreen.color,
    borderRadius: 8,
    padding: 15,
    position: 'relative',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  card: {
    display: 'flex',
    padding: '10px 10px 10px 0',
    borderBottom: 'none',
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    '&:hover':{
      cursor: 'default',
      backgroundColor: theme.palette.background.default,
    }
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  content: {
    flex: '1 0 auto',
    padding: `0 0 0 ${theme.spacing(2)}`,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  cover: {
    minWidth: 80,
    height: 80,
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: 12,
    backgroundSize: '90%',
  },
  itemCenter: {
    textAlign: 'center',
  },
  checkIcon: {
    color: theme.palette.green.color,
    marginBottom: -7,
    [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: 10,
    width: 16
  },
  redeemedText: {
    color: theme.palette.green.color,
    fontWeight: 400,
  },
  inProgressText: {
    fontWeight: 400,
  },
  highlight: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    marginBottom: -6,
    marginLeft: 3,
    fontSize: 16,
    width: 24,
    height: 24,
    borderRadius: 40,
    padding: 3,
  },
  linearBarBackground: {
    backgroundColor: theme.palette.lightgrey.color,
  },
  greyBar: {
    backgroundColor: theme.palette.grey.color,
  },
  redBarColor: {
    backgroundColor: theme.palette.red.color
  },
  yellowBarColor: {
    backgroundColor: theme.palette.yellow.color,
  },
  greenBarColor: {
    backgroundColor: theme.palette.green.color,
  },
  redTextColor: {
    color: theme.palette.red.color,
  },
  yellowTextColor: {
    color: theme.palette.yellow.color,
  },
  greenTextColor: {
    color: theme.palette.green.color,
  },
  howDoesWork: {
    padding: 0,
  },
  closeIcon: {
    cursor: 'pointer',
    top: 5,
    right: 5,
    color: theme.palette.grey[400],
    fontSize: 20,
    position: 'absolute'
  }
})

class PartnerLoyaltyDetails extends Component {
  static contextType = AppContext

  state = {
    showMessage: false,
    closeShowMessage: false,
    open: false,
    turnoverLifetimeTooltip: false,
    loading: false,
    showPopup: false,
    status: '',
    redeemPrize: '',
    redeemKey: ''
  }

  turnoverLifetime() {
    const {classes, prizes,partnerLoyalty: {createdAt}, partnersLoyaltyAnalytics} = this.props
    const turnoverUsd = get(partnersLoyaltyAnalytics, 'turnoverUsd', 0)
    const currentPrizeThreshold = get(partnersLoyaltyAnalytics, 'currentPrizeThreshold', 0)
    const progressBar = find(partnersLoyaltyProgressBarStatus, (a) => a.min <= currentPrizeThreshold && a.max >= currentPrizeThreshold)
    const lastPrize = find(prizes, ['prizeKey', get(partnersLoyaltyAnalytics, 'currentPrize', 'cashPrize')])
    const showPercentageSection = get(partnersLoyaltyAnalytics, 'eligible') && get(partnersLoyaltyAnalytics, 'completed')
    return <React.Fragment>
      <Grid item xs={12}>
        <Grid container>
          <Grid container item xs={10} justifyContent="flex-start">
            <Typography variant={'subtitle1'} className={classes.inline}>
              <Trans {...messages.turnoverSince} values={{startDate: moment(createdAt).format('MMMM, Do YYYY')}}/>
            </Typography>
          </Grid>
          <Grid container item xs={2} justifyContent="flex-end">
            <CustomTooltip
              title={<Typography variant='body1' className={classes.tooltipText}>
                {<Trans {...messages.turnoverLifetimeTooltip} />}</Typography>}
              placement={'top'}
            >
              <HelpIcon className={classes.highlight} onClick={() => this.setState({turnoverLifetimeTooltip: true})}/>
            </CustomTooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <LinearProgress variant="determinate" value={currentPrizeThreshold}
          classes={{
            colorPrimary: classes.linearBarBackground,
            barColorPrimary:  classes[`${progressBar.key}BarColor`],
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid container item xs justifyContent="flex-start">
            <Typography variant={'subtitle1'} className={classes.inline}>{`$${toString(turnoverUsd)}`}</Typography>
          </Grid>
          {!showPercentageSection && <Grid container item xs justifyContent="flex-end">
            <Typography variant={'subtitle1'} className={classNames(classes.inline, classes[`${progressBar.key}TextColor`])}>
              <Trans {...messages.turnoverPrice}
                values={{currentPrizeThreshold: currentPrizeThreshold, currentPrize: get(lastPrize, 'prizeLabel')}}/>
            </Typography>
          </Grid>}
        </Grid>
      </Grid>
    </React.Fragment>
  }

  showPrizes() {
    const {classes, prizes, partnersLoyaltyAnalytics, partnerLoyalty, clientId,
      redeemPrizeAction} = this.props
    const {showPopup, redeemPrize, redeemKey} = this.state
    const {companyObject} = this.context
    const company = companyObject.whiteLabelBrand || companyObject.brandLabel
    const redeemedPrizes = get(partnerLoyalty, 'redeemedPrizes')
    const eligiblePrizes = get(partnersLoyaltyAnalytics, 'eligiblePrizes')
    const partnersLoyaltyEligibility = map(get(partnersLoyaltyAnalytics, 'partnersLoyaltyEligibility'), (eligiblePrize) => {
      const item = find(prizes, (prize) => Number(prize.id) === eligiblePrize.prizeId)
      return get(item, 'prizeKey')
    })

    const eligiblePrizesSize = size(safeParseJSON(eligiblePrizes))
    const getRedeemedPrizes= safeParseJSON(redeemedPrizes)
    return <React.Fragment>
      <Grid item xs={12}>
        <Grid container spacing={isMobile() ? 2 : 3}>
          {map(prizes, (prize, index) => {
            const eligibleBtns =  map(get(partnersLoyaltyAnalytics, 'partnersLoyaltyEligibility'), (eligiblePrize) => {
              const item = find(prizes, (prize) => Number(prize.id) === eligiblePrize.prizeId &&
                moment(get(eligiblePrize, 'endEligibilityDate')) > moment())
              return get(item, 'prizeKey')
            })
            const eligibleToRedeem = includes(partnersLoyaltyEligibility, prize.prizeKey) && !includes(keys(getRedeemedPrizes), prize.prizeKey)
            const showBtn = includes(eligibleBtns, prize.prizeKey)
            return <Grid item xs={12} key={prize.prizeKey}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                {!isMobile() && <Grid item xs={1}>
                  {index === (eligiblePrizesSize + size(getRedeemedPrizes)) ? <FlagIcon className={classes.icon} />
                    : includes(partnersLoyaltyEligibility, prize.prizeKey) ?
                      <CheckIcon className={classNames(classes.icon)} /> :
                      <LockIcon className={classNames(classes.icon, classes.iconDisabled)} />}
                </Grid>}
                <Grid item xs={!isMobile() ? 7 : 8}>
                  <Card className={classes.card}>
                    <CardMedia
                      className={classes.cover}
                      image={prize.imageLink}
                      title={prize.prizeLabel}
                    />
                    <div className={classes.details}>
                      <CardContent className={classes.content}>
                        <Typography variant="body1">
                          {prize.prizeLabel}
                        </Typography>
                        <Typography variant="caption">
                          <Trans {...messages.prizeSubText} values={{amount: prize.prizeThreshold.toFixed(2)}} />
                        </Typography>
                      </CardContent>
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={4} className={classes.itemCenter}>
                  {includes(keys(getRedeemedPrizes), prize.prizeKey) && <Typography variant='body1' className={classes.redeemedText}>
                    <CheckIcon className={classes.checkIcon} />
                    <Trans {...messages.redeemed}/>
                  </Typography>}
                  {index === (eligiblePrizesSize + size(getRedeemedPrizes)) &&
                    <Typography variant='body1' color="primary" className={classes.inProgressText}>
                      <Trans {...messages.inProgress}/></Typography>}
                  {eligibleToRedeem && <Button
                    variant="contained"
                    size={!isMobile() ? 'large' : 'small'}
                    color="primary"
                    disabled={!showBtn}
                    className={classes.button}
                    key={`${prize.prizeKey}-redeem`}
                    onClick={() => this.setState({showPopup: true, redeemPrize: prize.prizeLabel, redeemKey: prize.prizeKey})}
                  >
                    <Trans {...messages.redeemBtn} />
                  </Button>}
                </Grid>
              </Grid>
            </Grid>})}
        </Grid>
      </Grid>
      <RedeemPrizeDialog
        open={showPopup}
        company={company}
        clientId={clientId}
        prize={redeemPrize}
        prizeKey={redeemKey}
        partnerLoyalty={partnerLoyalty}
        action={(variables)=> redeemPrizeAction(variables)}
        onClose={() => this.setState({showPopup: false})}
      />
    </React.Fragment>
  }

  toPartnerPortal() {
    const {client: apolloClient, accounts} = this.props
    const {clientType} = this.context
    const newTab = window.open('about:blank', '_blank')
    if (isAffiliateClient(clientType)) {
      const affiliateAccount = hasAffiliateAccount(accounts) && find(accounts, (__typename) => isAffiliatesAccount(__typename))
      apolloClient.query({query: AFFILIATE_SSO_LINK_QUERY, variables: {accountId: affiliateAccount.id}, fetchPolicy:'network-only'}).then((res) => {
        newTab.location = res.data.data.link
      }).catch((e) => {
        newTab.close()
      })
    } else {
      const {createIBSingleSignOnLink, accounts} = this.props
      const ibAccount = hasIntroducingBrokerAccount(accounts) && find(accounts, (__typename) => isIntroducingBrokerAccount(__typename))
      createIBSingleSignOnLink({variables: {accountId: ibAccount.id}}).then((res) => {
        newTab.location = res.data.data.link
      }).catch((e) => {
        newTab.close()
      })
    }
  }

  render() {
    const {classes, partnerLoyalty, partnersLoyaltyAnalytics} = this.props
    const {showMessage, closeShowMessage} = this.state
    const {createdAt} = partnerLoyalty
    const endDate =  moment(createdAt).add(90, 'd').format('YYYY-MM-DD HH:mm:ss')
    const now = moment()
    const daysLeft = moment(createdAt).add(90, 'day').diff(now, 'days')
    const onBoardedClients= get(partnersLoyaltyAnalytics, 'clients90days', 0)
    const showWelcomeSection = get(partnersLoyaltyAnalytics, 'eligible')
    return  <React.Fragment>
      <Grid container>
        {isMobile() && <Grid container pt={2} pb={2}>
          <Grid item xs={12}>
            <Grid container>
              {!showWelcomeSection &&<Grid container item xs justifyContent="flex-start">
                {!closeShowMessage && <Button color="primary" className={classNames(classes.link, classes.howDoesWork)}
                  onClick={() => this.setState({showMessage: true, closeShowMessage: true})}>
                  <Trans {...messages.showMore} />
                </Button>}
              </Grid>}
              <Grid container item xs justifyContent="flex-end">
                <Button
                  onClick={() =>this.toPartnerPortal()}
                  color="primary"
                  disableFocusRipple
                  disableRipple
                  variant="text"
                  className={classNames(classes.link, classes.howDoesWork)}
                >
                  <Trans {...messages.seeMoreStatistics} />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        }
        {(!isMobile() || showMessage) && !showWelcomeSection && <Grid item xs={12}>
          <Grid container className={classes.cardDiv}>
            {isMobile() && <CloseIcon onClick={() => this.setState({showMessage: false, closeShowMessage: false})} className={classes.closeIcon}/>}
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body1" className={classes.highlightText}>
                    <Trans {...messages.joinedLoyaltyProgramP1} />
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    <Trans {...messages.joinedLoyaltyProgramP2}
                      values={{startDate: moment(createdAt).format('hh:mm:ss, dddd MMMM Do YYYY')}}
                      components={[<span className={classes.highlightText}>startDate</span>]}
                    />
                  </Typography>
                  <Typography variant="body1">
                    <Trans {...messages.joinedLoyaltyProgramP3}
                      values={{endDate: moment(endDate).format('MMMM, Do YYYY')}}
                      components={[<span className={classes.highlightText}>endDate</span>]}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    <Trans {...messages.clientsOnboarded}
                      values={{totalClients: onBoardedClients}}
                      components={[<span className={classes.highlightText}>totalClients</span>]}
                    />
                  </Typography>
                  <Typography variant="body1">
                    <Trans {...messages.daysLeft}
                      values={{daysLeft: daysLeft}}
                      components={[<span className={classes.highlightText}>daysLeft</span>]}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>}
        <Grid item xs={12} md={12} lg={12} className={classes.displayFlex}>
          <Card classes={{root: classes.cardHover}}>
            <Grid container spacing={1}>
              {this.turnoverLifetime()}
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} mt={isMobile() ? 2 : 3}>
          {this.showPrizes(partnerLoyalty, partnersLoyaltyAnalytics)}
        </Grid>
      </Grid>
    </React.Fragment>
  }
}

export default  compose(
  withNamespaces(),
  withRouter,
  withStyles(styles, {withTheme: true}),
  graphql(CLIENT_PARTNERS_LOYALTY_MUTATION, {
    name: 'redeemPrizeAction',
    options: {
      refetchQueries: () => [{query: CLIENT_DATA_QUERY}],
    },
  }),
  graphql(IB_SSO_LINK_MUTATION, {
    name: 'createIBSingleSignOnLink',
  }),
)(PartnerLoyaltyDetails)
