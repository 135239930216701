import React from 'react'
import {Redirect, Route, Switch, withRouter} from 'react-router-dom'
import History from './History'
import Deposit from './Deposits/Deposit'
import DepositNotification from './Deposits/DepositNotification'
import Withdraw from './Withdrawals/Withdraw'
import Transfers from './Transfers'
import DepositAction from './Deposits/DepositAction'
import LocalDepositorTransfer from './LocalDepositorTransfer'

const PaymentRoutes = ({match: {path}}) =>(
  <Switch>
    <Route path={`${path}/:accountId/deposit/result/:resultstatus`} component={DepositNotification} />
    <Route path={`${path}/:accountId/:action/deposit/:depositId`} component={DepositAction} />
    <Route exact path={`${path}/:accountId/deposit`} component={Deposit} />
    <Route exact path={`${path}/:accountId/withdraw`} component={Withdraw} />
    <Redirect exact from={`${path}/:accountId/withdraw/:childPath+`} to={`${path}/:accountId/withdraw/`} />
    <Route exact path={`${path}/withdraw`} component={Withdraw} />
    <Route path={`${path}/:accountId/transfer`} component={Transfers} />
    <Route path={`${path}/transfer`} component={Transfers} />
    <Route exact path={`${path}/history`} component={History} />
    <Route exact path={`${path}/local-depositor-transfer`} component={LocalDepositorTransfer} />
    <Route path={`${path}/:parentPath/:childPath+`} render={({match}) => <Redirect to={`${path}/${match.params.parentPath}`} />} />
    <Route path={`${path}`} component={Deposit} />
  </Switch>
)

export default withRouter(PaymentRoutes)
