import React, {Component} from 'react'
import {find, flowRight as compose, get} from 'lodash'
import {Trans, withNamespaces} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import AppContext from '../../Common/contexts/AppContext'
import Select, {components} from 'react-select'
import messages from '../../../assets/messages'
import {getCurrentTheme, isDarkTheme} from '../../../common/utils'
import {ReactComponent as CheckIcon} from '../../../assets/images/check.svg'
import {ReactComponent as ChevronIcon} from '../../../assets/images/chevron.svg'
import PropTypes from 'prop-types'

const styles = (theme) => ({
  iconOptionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  checkIcon: {
    '& svg path': {
      fill: theme.palette.secondary.dark,
    },
  },
})

const cStyles = (theme, isDark, customStyles) => ({
  control: (baseStyles, _) => ({
    ...baseStyles,
    backgroundColor: isDark ? '#000000' : get(baseStyles, 'backgroundColor'),
    border: isDark ? `1px solid ${theme.palette.grey[700]}` : get(baseStyles, 'border'),
  }),
  menu: (baseStyles, _) => ({
    ...baseStyles,
    backgroundColor: isDark ? theme.palette.grey[900] : get(baseStyles, 'backgroundColor'),
  }),
  placeholder: (baseStyles, _) => ({
    ...baseStyles,
    color: isDark ? theme.palette.secondary.light : get(baseStyles, 'color')
  }),
  option: (baseStyles, {isSelected}) => ({
    ...baseStyles,
    color: isDark ? theme.palette.secondary.light : '#000000',
    backgroundColor: isDark ? theme.palette.grey[900] : isSelected ? 'transparent' : get(baseStyles, 'backgroundColor'),
    '&:hover': {
      backgroundColor: isDark ? '#2A2A2A' : theme.palette.extralightgrey.color,
    },
  }),
  singleValue: (baseStyles, _) => ({
    ...baseStyles,
    color: isDark ? theme.palette.secondary.light : get(baseStyles, 'color'),
  }),
  ...customStyles,
})

class Filter extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {}
  }

  findValue(options, value) {
    return find(options, (option) => option.value === value)
  }

  // Custom check icon wrapper
  getOptionIcon(props) {
    const {classes} = this.props
    const {Option} = components
    return (<Option {...props}>
      <div className={classes.iconOptionContainer}>
        <div>{props.data.label}</div>
        {props.isSelected && <div className={classes.checkIcon}><CheckIcon /></div>}
      </div>
    </Option>)
  }

  // Custom drop down icon wrapper
  getDropdownIndicatorIcon(props) {
    const {DropdownIndicator} = components
    return (<DropdownIndicator {...props}>
      <ChevronIcon />
    </DropdownIndicator>)
  }

  render() {
    const {theme, customStyles, id, title, value, onChangeHandle, options, disabled = false, isHidden = false} = this.props
    const themePreference = getCurrentTheme(this.context)
    const isDark = isDarkTheme(themePreference)

    return (
      <React.Fragment>
        {isHidden ? null : <Select
          id={id}
          styles={cStyles(theme, isDark, customStyles)}
          options={options}
          value={this.findValue(options, value) || ''}
          onChange={(e) => onChangeHandle(id, e)}
          placeholder={<Trans {...messages[title]}/>}
          isDisabled={disabled}
          components={{Option: (props) => this.getOptionIcon(props), DropdownIndicator: (props) => this.getDropdownIndicatorIcon(props)}}
        />
        }
      </React.Fragment>
    )
  }
}

Filter.propTypes = {
  id: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChangeHandle: PropTypes.func.isRequired,
  customStyles: PropTypes.object,
  options: PropTypes.any,
  disabled: PropTypes.bool,
  isHidden: PropTypes.bool
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces()
)(Filter)
