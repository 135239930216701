import React, {Component} from 'react'
import {flowRight as compose, get} from 'lodash'
import Grid from '@mui/material/Grid'
import {withNamespaces} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import Dialog from '@mui/material/Dialog'
import CloseIcon from '@mui/icons-material/Close'
import config from '../../../config'
import AppContext from '../../Common/contexts/AppContext'
import {Fab} from '@mui/material'

const styles = (theme) => ({
  button: {
    background: 'none',
    marginLeft: 'auto',
    boxShadow: 'none',
    color: theme.palette.black.color,
    '&:hover':{
      backgroundColor: theme.palette.background.default,
    },
    '&:active':{
      backgroundColor: theme.palette.background.default,
    }
  },
  container:{
    [theme.breakpoints.down('md')]: {
      height: '100%'
    },
  }
})

class CompareAccountTypes extends Component {

  static contextType = AppContext

  render() {
    const {locale, themePreference} = this.context
    const {classes, history, location} = this.props
    const {accountTypesComparisonUrl, forcedCompany, forcedCompanyDeails} = config
    let accountsComparisonUrl = accountTypesComparisonUrl.replace('{lang}', locale)
    const country = get(location, 'state.country')
    if (forcedCompany) {
      accountsComparisonUrl = `${accountsComparisonUrl}?theme=${themePreference}&company=${get(forcedCompanyDeails, 'brandLabel')}&country=${country}`
    } else {
      accountsComparisonUrl = `${accountsComparisonUrl}?theme=${themePreference}&country=${country}`
    }

    return  <Dialog
      fullScreen
      open={true}
      disableEnforceFocus={true}
    >
      <Grid container className={classes.container}>
        <Fab className={classes.button} onClick={() => history.push('/accounts/add-account')}>
          <CloseIcon/>
        </Fab>
        <iframe
          src={accountsComparisonUrl}
          frameBorder="0"
          name="CompareAccountTypes"
          id="CompareAccountTypes"
          title="CompareAccountTypes"
          style={{overflow: 'scroll', height: '100%', width: '100%'}}
        />
      </Grid>
    </Dialog>
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
)(CompareAccountTypes)

