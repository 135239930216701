import React, {Component} from 'react'
import {flowRight as compose, get, includes, isEmpty, map} from 'lodash'
import {Button, Card, CardMedia, Grid, Typography} from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import {Trans, withNamespaces} from 'react-i18next'
import messages from '../../assets/messages'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import {isDarkTheme, isMobile} from '../../common/utils'
import AppContext from '../Common/contexts/AppContext'
import classNames from 'classnames'
import {isRtlLang} from '../../common/utils/uioptions'

const styles = theme => ({
  card: {
    borderRadius: 5,
    padding: '75px 50px',
    margin: '0px 25px',
    width: '500px',
    display: 'flex',
    justifyContent: 'center',
    boxShadow: 'none',
  },
  itemPadding: {
    padding: 0,
  },
  media: {
    height: '100%',
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
      background: theme.palette.extralightgrey.color,
    },
  },
  mediaRtl: {
    transform: 'scaleX(-1)',
  },
  banner: {
    width: '100%',
    padding: 0,
    height: '100%',
    boxShadow: 'none',
  },
  buttonAction: {
    boxShadow: 'none',
  },
  btnImIn: {
    marginBottom: 16,
  },
  buttonActionSubText: ({item}) => ({
    fontSize: 10,
    [((!isMobile() && get(item, 'bannerButton.termsRtlEnabled', true)) || isMobile())
      && theme.direction === 'rtl' ? 'paddingRight' : 'paddingLeft']: 10,
  }),
  slideTitle: {
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
    paddingBottom: 0,
  },
  slideSubTitle: {
    fontSize: 20,
    paddingBottom: 0,
  },
  slideCaptionText: {
    fontSize: 15,
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItemIcon: {
    color: theme.palette.primary.main,
    'font-weight': '500',
  },
  bannerSubTitlePadding: {
    paddingTop: 0
  },
  horizontalList: {
    listStyle: 'none',
    display: 'flex',
    paddingLeft: 0,
    fontWeight: '500',
    [theme.breakpoints.down('sm')]: {
      display:'block',
      marginTop: 0,
      marginBottom: 0,
      paddingBottom: 0,
      paddingTop: 0,
    },
  },
  horizontalItems: {
    display: 'flex',
    paddingRight: 10,
    fontWeight: '500',
  },
  verticalMiddle: {
    verticalAlign: 'middle',
  },
  verticalList: {
    listStyle: 'none',
    paddingLeft: 0,
    fontWeight: '500'
  },
  verticalStyle: {
    verticalAlign: 'middle',
  },
  verticalItems: {
    paddingRight: 10,
    display: 'inline-flex',
    verticalAlign: 'middle',
    fontWeight: '500'
  },
  horizontalReverse: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'start',
  },
})
export class CarouselSlide extends Component {
  static contextType = AppContext
  render() {
    const {classes, item, toIBPortal} = this.props
    const {locale, themePreference} = this.context
    const backgroundImage = isDarkTheme(themePreference) && isMobile() ? '#333333' : item[`${themePreference}Photo`]
    const checkButtonUrl = get(item, 'bannerButton.path') === 'toIbPortal'
    const isRtl = !isEmpty(locale && includes(isRtlLang, locale) ? 'Rtl' : '')

    return (<React.Fragment>
      {!isMobile() && <Card classes={{root: classes.banner}} key={item.key}>
        <CardMedia
          className={classNames(classes.media, get(item, 'mediaRtlEnabled', true) && isRtl ? classes.mediaRtl : '')}
          image={backgroundImage}
          title={get(item, 'bannerTitle.key')}
        >
          <Grid container padding={3} className={isRtl ? classes.mediaRtl : ''}>
            <Grid container item xs={12}>
              <Grid container spacing={3}>
                {get(item, 'bannerTitle.show') && <Grid item xs={get(item, 'titleWidth')} className={classes.itemPadding}>
                  <Typography variant="h2" className={classes.slideTitle}>
                    <Trans {...messages[get(item, 'bannerTitle.key')]} /></Typography>
                </Grid>}
                {get(item, 'bannerSubTitle.show') && <Grid item xs={12} className={classes.bannerSubTitlePadding}>
                  <Typography variant="body1" className={classes.slideSubTitle}>
                    <Trans {...messages[get(item, 'bannerSubTitle.key')]} /></Typography>
                </Grid>}
                {get(item, 'bannerCaptionText.show') && <Grid item xs={12}>
                  <Typography variant="caption" className={classes.slideCaptionText}>
                    <Trans {...messages[get(item, 'bannerCaptionText.key')]} /></Typography>
                </Grid>}
                {get(item, 'bannerButton.show') && <Grid container item xs={6}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    className={get(item, 'bannerButton.rtlEnabled', false) && isRtl ? classes.horizontalReverse : ''}
                  >
                    <Grid item className={classes.btnImIn}>
                      <Button
                        id='in'
                        onClick={() => checkButtonUrl ? toIBPortal() :this.props.history.push(get(item, 'bannerButton.path'))}
                        variant="contained"
                        size="large"
                        color="primary"
                        className={classes.buttonAction}
                      >
                        <Trans {...messages[get(item, 'bannerButton.key')]} />
                      </Button>
                    </Grid>
                    {get(item, 'bannerButton.showTerms') && <Grid item className={classes.buttonActionSubText}>
                      <Typography variant='caption'><Trans {...messages.bannerButtonTermsAndConditions} /></Typography>
                    </Grid>}
                  </Grid>
                </Grid>}
                {get(item, 'bannerListHorizontal.show') && <Grid item xs={12}>
                  <ul className={classes.horizontalList}>
                    {map(get(item, 'bannerListHorizontal.items'), (keyPoint,index) => <li key={index} className={classes.verticalMiddle}>
                      <Typography variant='body1' className={classes.horizontalItems}>
                        <CheckCircleOutlineIcon className={classes.listItemIcon}/><Trans {...messages[keyPoint]} />
                      </Typography>
                    </li>)}
                  </ul>
                </Grid>}
                {get(item, 'bannerListVertical.show') && <Grid item xs={4}>
                  <ul className={classes.verticalList}>
                    {map(get(item, 'bannerListVertical.items'), (keyPoint,index) => <li key={index} className={classes.verticalStyle}>
                      <Typography variant='body1' className={classes.verticalItems}>
                        <CheckCircleOutlineIcon className={classes.listItemIcon}/><Trans {...messages[keyPoint]} />
                      </Typography>
                    </li>)}
                  </ul>
                </Grid>}
              </Grid>
            </Grid>
          </Grid>
        </CardMedia>
      </Card>}
      {isMobile() && <Card classes={{root: classes.banner}} key={item.key}>
        <CardMedia
          className={classes.media}
          title="Contemplative Reptile"
        >
          <Grid container padding={2}>
            <Grid container item xs={12}>
              <Grid container spacing={1}>
                {get(item, 'bannerTitle.show') && <Grid item xs={12} className={classes.itemPadding}>
                  <Typography variant="body2" className={classes.slideTitle}>
                    <Trans {...messages[get(item, 'bannerTitle.key')]} /></Typography>
                </Grid>}
                {get(item, 'bannerSubTitle.show') && <Grid item xs={12} className={classes.bannerSubTitlePadding}>
                  <Typography variant="body1" className={classes.slideSubTitle}>
                    <Trans {...messages[get(item, 'bannerSubTitle.key')]} /></Typography>
                </Grid>}
                {get(item, 'bannerListHorizontal.show') && <Grid item xs={12}>
                  <ul className={classes.horizontalList}>
                    {map(get(item, 'bannerListHorizontal.items'), (keyPoint,index) => <li key={index} className={classes.verticalMiddle}>
                      <Typography variant='body1' className={classes.horizontalItems}>
                        <CheckCircleOutlineIcon className={classes.listItemIcon}/><Trans {...messages[keyPoint]} />
                      </Typography>
                    </li>)}
                  </ul>
                </Grid>}
                {get(item, 'bannerListVertical.show') && <Grid item xs={12}>
                  <ul className={classes.verticalList}>
                    {map(get(item, 'bannerListVertical.items'), (keyPoint,index) => <li key={index} className={classes.verticalStyle}>
                      <Typography variant='body1' className={classes.verticalItems}>
                        <CheckCircleOutlineIcon className={classes.listItemIcon}/><Trans {...messages[keyPoint]} />
                      </Typography>
                    </li>)}
                  </ul>
                </Grid>}
                {get(item, 'bannerButton.show') && <Grid container item xs={12}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                  >
                    <Grid item className={classes.btnImIn}>
                      <Button
                        id='in'
                        onClick={() => checkButtonUrl ? toIBPortal() :this.props.history.push(get(item, 'bannerButton.path'))}
                        variant="contained"
                        size="small"
                        color="primary"
                        className={classes.buttonAction}
                      >
                        <Trans {...messages[get(item, 'bannerButton.key')]} />
                      </Button>
                    </Grid>
                    {get(item, 'bannerButton.showTerms') && <Grid item className={classes.buttonActionSubText}>
                      <Typography variant='caption'><Trans {...messages.bannerButtonTermsAndConditions} /></Typography>
                    </Grid>}
                  </Grid>
                </Grid>}
              </Grid>
            </Grid>
          </Grid>
        </CardMedia>
      </Card>}
    </React.Fragment>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
)(CarouselSlide)
