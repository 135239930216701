import {Trans} from 'react-i18next'
import React, {Component} from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import withStyles from '@mui/styles/withStyles'
import messages from '../../../../assets/messages'
import LoadingButton from '../../../Common/LoadingButton'
import FormHelperText from '@mui/material/FormHelperText'
import {flowRight as compose} from 'lodash'
import {withRouter} from 'react-router-dom'

const styles = theme => ({
  button:{
    marginTop:0
  },
  errorMessage: {
    color: theme.palette.error.main,
    display: 'inline-block',
    verticalAlign: 'bottom',
    margin: '13px 13px 13px 0'
  },
})

export class APTestButtonsToolbar extends Component {
  render() {
    const {activeStep, previousStep, nextStep, submitForm, classes, submitButtonFeatures:{submitLoading,status}, disableSubmit,
      corporate, disabledCorporate, corporateBack} = this.props

    return (
      <Grid container spacing={2}>
        {(activeStep > 0 || (corporate && corporateBack)) &&
        <Grid item>
          <Button color="secondary" variant="contained" size="large" onClick={(corporate && !activeStep) ? corporateBack : previousStep}>
            <Trans {...messages.back} />
          </Button>
        </Grid>}
        {activeStep < 2 &&
        <Grid item>
          <Button color="primary" size="large" variant="contained" onClick={nextStep}>
            <Trans {...messages.continue} />
          </Button>
        </Grid>}
        {activeStep === 2 && (!corporate || (corporate && !disabledCorporate)) &&
        <Grid item>
          <LoadingButton
            id='loadingButton'
            onClick={submitForm}
            disabled={(submitLoading || status==='failure' || disableSubmit) && !(corporate && disableSubmit)}
            hideProgressBar={(!submitLoading && status==='failure') || disableSubmit}
            status={status}
            className={classes.button}
          ><Trans {...messages[corporate && disableSubmit ? 'next' : 'submit']} />
          </LoadingButton>
          {status==='failure' &&
            <FormHelperText className={classes.errorMessage}><Trans {...messages.generalError} /></FormHelperText>}
        </Grid>}
      </Grid>
    )
  }
}

export default compose(withRouter, withStyles(styles, {withTheme: true}))(APTestButtonsToolbar)
