import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {get, map, flowRight as compose} from 'lodash'
import {withNamespaces, Trans} from 'react-i18next'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import AppContext from './contexts/AppContext'
import messages from '../../assets/messages'
import NotificationBar from './NotificationBar'
import {AlertDialog} from './Dialog'
import moment from 'moment'
import {getCookie, isWhiteLabel, setCookie} from '../../common/utils'
import config from '../../config'
import {logEventCustomParams} from '../../common/utils/firebaseEvents'

const styles = theme => ({
  button: {
    color: theme.palette.primary.main,
    padding: '0 5px',
    fontSize: 16,
    '&:hover':{
      cursor:'pointer'
    },
    fontWeight: 'normal'
  },
  highlight: {
    fontWeight: 400,
  },
})

class CustomNotificationAlert extends Component {
  static contextType = AppContext

  state = {
    showNotification: false,
    showSupportNotification: false,
  }

  renderCovid19Notification() {
    const {classes, t} = this.props
    const {showNotification} = this.state
    const {companyObject} = this.context
    const alertContent = ['covidNotificationText', 'covidNotificationText2', 'covidNotificationText3']
    const alertContentValues = [{company: companyObject.brandLabel}]
    logEventCustomParams('WaitingCovid19AlertNegativeIntent', {
      userId: get(this.context, 'clientId'),
    })
    return <React.Fragment>
      <NotificationBar
        status="error"
        title={<Trans {...messages.waitingCovid19} />}
        ctaMessage={<Trans {...messages.readMore} />}
        ctaAction={() => this.setState({showNotification: true})}
      />
      {showNotification && <AlertDialog
        open
        onDisagree={() => {
          setCookie('covid19Notification', true, 30, `.${window.location.hostname}`)
          this.setState({showNotification: false})
        }}
        onClose={() => this.setState({showNotification: false})}
        title={t(messages.delays.i18nKey, messages.delays.defaults)}
        disagreeText={t(messages.dismiss.i18nKey, messages.dismiss.defaults)}
      >
        {map(alertContent, (content, key) =>
          <React.Fragment key={key}>
            <Typography variant="body1">
              <Trans {...messages[content]} values={alertContentValues && alertContentValues[key]}/>
            </Typography>
            <br />
          </React.Fragment>
        )}
      </AlertDialog>}
    </React.Fragment>
  }

  renderUnsolicitedNotification() {
    const {classes, t, history} = this.props
    const {showNotification} = this.state
    const {companyObject} = this.context
    const brandLabel = companyObject.whiteLabelBrand || companyObject.brandLabel
    const emailDomain = isWhiteLabel() ? companyObject.domain : companyObject.emailDomain
    return <React.Fragment>
      <NotificationBar
        status="warning"
        title={<Trans {...messages.unsolicitedTitle} values={{company: brandLabel}} />}
        ctaMessage={<Trans {...messages.readMore} />}
        ctaAction={() => this.setState({showNotification: true})}
      />
      {showNotification && <AlertDialog
        open
        onDisagree={() => {
          setCookie('unsolicitedNotification', true, 5, `.${window.location.hostname}`)
          this.setState({showNotification: false})
        }}
        onAgree={() => {
          history.push('/support')
        }}
        onClose={() => this.setState({showNotification: false})}
        title={t(messages.bewareUnsolicitedEmailsTitle.i18nKey, {company: brandLabel})}
        disagreeText={t(messages.dismiss.i18nKey, messages.dismiss.defaults)}
        agreeText={t(messages.contactUs.i18nKey, messages.contactUs.defaults)}
      >
        {<React.Fragment>
          <Typography variant="body1">
            <Trans {...messages.unsolicitedText1} values={{company: brandLabel}} />
          </Typography>
          <ul>
            <li><Typography variant="body1"><Trans {...messages.unsolicitedListItem1} /></Typography></li>
            <li><Typography variant="body1"><Trans {...messages.unsolicitedListItem2} values={{emailDomain: emailDomain}}/></Typography></li>
            <li><Typography variant="body1"><Trans {...messages.unsolicitedListItem3} values={{company: brandLabel}}/></Typography></li>
            <li><Typography variant="body1"><Trans {...messages.unsolicitedListItem4} values={{company: brandLabel}}/></Typography></li>
            <li><Typography variant="body1"><Trans {...messages.unsolicitedListItem5} /></Typography></li>
          </ul>
        </React.Fragment>}
      </AlertDialog>}
    </React.Fragment>
  }

  renderSecurityCodeNotification() {
    const {classes, t} = this.props
    const {showSupportNotification} = this.state
    const {companyObject} = this.context
    const alertSupportContent = ['supportAlertText1', 'supportAlertText2', 'supportAlertText3',
      'supportAlertText4', 'supportAlertText5']
    const alertContentValues = {company: companyObject.brandLabel}

    return <React.Fragment>
      <NotificationBar
        noMargin
        status="warning"
        title={<Trans {...messages.supportNotification} />}
        ctaMessage={<Trans {...messages.readMore} />}
        ctaAction={() => this.setState({showSupportNotification: true})}
      />
      {showSupportNotification && <AlertDialog
        open
        onDisagree={() => {
          this.setState({showSupportNotification: false})
        }}
        onClose={() => this.setState({showSupportNotification: false})}
        title={t(messages.supportAlertTitle.i18nKey, {company: companyObject.brandLabel})}
        disagreeText={t(messages.dismiss.i18nKey)}
      >
        {map(alertSupportContent, (content, key) =>
          <React.Fragment key={key}>
            <Typography variant="body1">
              <Trans {...messages[content]}
                components={[<span className={classes.highlight}>How it works</span>]}
                values={alertContentValues} />
            </Typography>
            <br />
          </React.Fragment>
        )}
      </AlertDialog>}
    </React.Fragment>
  }

  render() {
    const {global, support} = this.props
    const {featuresConfig: {externalSupport}} = config
    const today = moment()
    if (global) {
      const warningMaxDateNotification = moment('2020-10-23 23:59')
      const unsolicitedNotification = !getCookie('unsolicitedNotification') && (today < warningMaxDateNotification)
      return unsolicitedNotification && this.renderUnsolicitedNotification()
    } else {
      const covid19MaxDateNotification = moment('2020-06-19 23:59')
      const covid19Notification = !getCookie('covid19Notification') && (today < covid19MaxDateNotification)
      return (<React.Fragment>
        {covid19Notification && this.renderCovid19Notification()}
        {support && !externalSupport && this.renderSecurityCodeNotification()}
      </React.Fragment>)
    }
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  withRouter,
)(CustomNotificationAlert)
