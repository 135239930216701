import React, {Component} from 'react'
import {withNamespaces, Trans} from 'react-i18next'
import {filter, get} from 'lodash'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import {companies, whiteLabels} from '@bdswiss/common-enums'
import messages from '../../assets/messages'
import PageTitle from '../Common/PageTitle'
import PageBody from '../Common/PageBody'
import {FullScreenDialog} from '../Common/Dialog'
import {storeSessInfoInLocalStorage, isWhiteLabel, isMobile} from '../../common/utils'
import {getRelatedEntities} from '../../common/utils/requests'
import config from '../../config'

const styles = theme => ({
  cardRoot: {
    marginBottom: theme.spacing(2),
    maxWidth: 500,
    '&:hover': {
      cursor: 'pointer'
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth: '100%',
    },
  },
  summary: {
    fontSize: 13,
    paddingTop:7,
  },
})

export class EntitySelection extends Component {

  constructor(props) {
    super(props)
    this.state = {
      entities: (this.props.location.state)?this.props.location.state.entities:''
    }
  }

  storeSession(relatedEntities, company) {
    const sessionInfo = {
      relatedEntities,
      company,
    }
    storeSessInfoInLocalStorage(sessionInfo)
  }

  selectEntity(entity,entities) {
    const {history, location} = this.props
    const {key} = config
    const redirectTo = get(location , 'state.redirectTo', '/')
    this.storeSession(entities, entity.entity)
    if (key === entity.entity)
      history.push(redirectTo)
    else
      window.location = entity.frontendUrl
  }

  getEntities() {
    const {history, location} = this.props

    return getRelatedEntities()
      .then((res) => {
        if (!res.error) {
          location.state.entities = res['data']
          this.setState({entities: res['data']})
        }
        else
          history.push('/login')
      })
      .catch((err) => {history.push('/login')})
  }

  render() {
    const {classes, location} = this.props
    const {entities} = this.state
    const {brand, customDomain, customDomainsCompanies} = config

    if (entities === '')
      this.getEntities()

    return (
      <FullScreenDialog
        desktopOnly
        open
      >
        <PageTitle withoutPadding={isMobile()}>
          <Trans {...messages.selectAccount} />
        </PageTitle>
        <PageBody>
          {entities && entities.map(e => {
            const customDomainCompany = customDomain && get(customDomainsCompanies, e.entity)
            const domain = isWhiteLabel()
              ? filter(whiteLabels, (whitelabel) => whitelabel.company.value === e.entity && whitelabel.category === brand)[0].domain
              : customDomainCompany ? customDomainsCompanies[e.entity] : companies[e.entity].domain
            return <Card classes={{root: classes.cardRoot}} key={e.entity} onClick={() => this.selectEntity(e,location.state.entities)}>
              <Grid container justifyContent="flex-start">
                <Grid item>
                  <Typography variant='subtitle1'>{companies[e.entity].trademark}</Typography>
                  <Typography variant='caption'>
                    {`${!customDomainCompany ? 'https://' : ''}${domain}`}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          })}
        </PageBody>
      </FullScreenDialog>
    )
  }
}

export default withStyles(styles, {withTheme: true})(withNamespaces()(EntitySelection))
