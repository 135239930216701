import PropTypes from 'prop-types'
import React, {Component} from 'react'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import Dialog from '@mui/material/Dialog'
import {depositVendors, frontends} from '@bdswiss/common-enums'
import ListItem from '@mui/material/ListItem'
import {withNamespaces, Trans} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import Typography from '@mui/material/Typography'
import {get, filter, keys, map, isEmpty, flowRight as compose} from 'lodash'
import DialogContent from '@mui/material/DialogContent'
import messages from '../../../../assets/messages'
import {getPlatform, getSourceBasedOnLocationPrevPath, logEventCustomParams} from '../../../../common/utils'
import {withCreateDeposit, PaymentActionButton} from './helpers'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

const style = (theme) => ({
  iframe: {
    overflow: 'scroll',
    width: '100%',
    minHeight: '99%'
  },
  fullHeight: {
    height: '100%',
  },
  note: {
    color: theme.palette.red.color,
  },
  root: {
    zIndex: 2147483640
  },
  walletOwnerMessage: {
    margin: '24px 0 0 0',
  },
  formControlLabelVAligned: {
    display:'flex',
    alignItems:'flex-start',
    margin: '0 0 0 0',
  },
  checkboxControlVAligned: {
    paddingTop:'24px',
  },
})

class GenericIFramedProvider extends Component {

  constructor(props) {
    super(props)
    this.state = {
      rightfulWalletAddressOwner: false,
      openFrameDialog: false,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.iframeUrl) {
      return {openFrameDialog: true, iframeUrl: nextProps.iframeUrl}
    }
    return null
  }

  getIframeUrl() {
    const {providerProperties: {provider, paymentKey, id: paymentOptionId}, account, amount, onError, bonusAmount, bonusTerms,
      history: {location}} = this.props

    this.setState({gettingIframeUrl: true})

    const firebaseParams = {
      source: getSourceBasedOnLocationPrevPath(get(location, 'state', {prevPath: get(location, 'pathname')})),
      login: account.id,
      currency: account.currency,
      vendor: provider,
      amount,
    }
    logEventCustomParams('depositAttempted', firebaseParams)

    const variables = {
      accountId: account.id,
      amount,
      vendor: provider,
      paymentOptionId,
      frontend: frontends.web2.value,
      args: JSON.stringify({
        platform: getPlatform(),
        paymentKey,
        bonusAmount,
        bonusTerms,
      }),
    }

    this.props.createDepositRequest({variables})
      .then(({data: {newDeposit}}) => {
        const {payment: {url: iframeUrl}} = newDeposit
        this.setState({iframeUrl, gettingIframeUrl: false, openFrameDialog: true})
      })
      .catch((e) => {
        this.setState({gettingIframeUrl: false})
        onError && onError(e)
      })
  }

  changeLocation(e) {
    try {
      if (get(e, 'target.contentWindow.location.host') === window.location.host) {
        e.persist()
        this.setState({iframeUrl: ''}, () => {
          window.location.replace(get(e, 'target.contentWindow.location.href'))
        })
      }
    } catch (e) {
      // ignore iframe error
    }
  }

  render() {
    const {openFrameDialog, iframeUrl, gettingIframeUrl, rightfulWalletAddressOwner} = this.state
    const {classes, providerProperties: {name: providerName, provider}, canProceed} = this.props
    const isCryptoVendor = map(filter(depositVendors, {walletAcknowledgement: true}), 'key')
    const methodInfoMessages = filter(keys(messages), (m) => m.indexOf(`${provider}DepositInfo`) === 0).reduce((m, i) => {
      m[i] = messages[i]
      return m
    }, {})
    const methodNoteMessages = filter(keys(messages), (m) => m.indexOf(`${provider}DepositNote`) === 0).reduce((m, i) => {
      m[i] = messages[i]
      return m
    }, {})


    return (
      <React.Fragment>
        {isCryptoVendor.includes(provider) && <Grid item xs={12}>
          <FormControlLabel
            className={classes.formControlLabelVAligned}
            control={
              <Checkbox
                className={classes.checkboxControlVAligned}
                name="rightfulWalletAddressOwner"
                checked = {rightfulWalletAddressOwner}
                onChange={(e) => this.setState({rightfulWalletAddressOwner: e.target.checked})}
              />
            }
            label= {<Typography variant="subtitle2" className={classes.walletOwnerMessage}><Trans {...messages.cryptoCheckbox} /></Typography>}
          />
        </Grid>}
        {!iframeUrl &&
          <PaymentActionButton
            disable={canProceed || (!rightfulWalletAddressOwner && isCryptoVendor.includes(provider))}
            loading={gettingIframeUrl}
            onClick={() => this.getIframeUrl()}
          />
        }
        <Dialog fullWidth disableEscapeKeyDown fullScreen open={openFrameDialog} aria-labelledby={providerName} classes={{root: classes.root}}
          disableEnforceFocus={true}>
          <DialogContent className={classes.fullHeight}>
            <iframe allow="clipboard-write" frameBorder="0" title={providerName} src={iframeUrl} className={classes.iframe} onLoad={(e) => this.changeLocation(e)} ></iframe>
          </DialogContent>
        </Dialog>
        <br />
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            {!isEmpty(methodInfoMessages) && <Typography align="center" variant="h3">
              <Trans {...messages.usefulInfo} />
            </Typography>}
            <List>
              {map((methodInfoMessages), (m) =>
                <ListItem key={m.i18nKey}>
                  <Typography variant="body1"><Trans {...m} /></Typography>
                </ListItem>
              )}
              {map((methodNoteMessages), (m) =>
                <ListItem key={m.i18nKey}>
                  <Typography className={classes.note} variant="caption"><Trans {...m} /></Typography>
                </ListItem>
              )}
            </List>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

GenericIFramedProvider.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  amount: PropTypes.number.isRequired,
  providerProperties: PropTypes.shape({
    name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    paymentKey: PropTypes.string.isRequired,
    paymentOptionId: PropTypes.number.isRequired,
  }).isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

export default compose(
  withNamespaces(),
  withStyles(style),
  withCreateDeposit
)(GenericIFramedProvider)
