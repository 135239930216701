import React, {Component} from 'react'
import {flowRight as compose} from 'lodash'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import AppContext from '../Common/contexts/AppContext'
import config from '../../config'
import Images from '../Common/Images'
import {Trans} from 'react-i18next'
import messages from '../../assets/messages'
import {Redirect} from 'react-router-dom'

const styles = theme => ({
  container: {
    backgroundColor: '#EFEFEF',
    height: '100%',
    textAlign: 'center',
    alignItems: 'center',
    position: 'absolute'
  },
  bold: {
    fontWeight: 500
  },
  header: {
    marginTop: 40
  },
  insideContainer: {
    maxWidth: 1600,
    margin: '0 auto'
  },
  fullWidth: {
    width: '100%'
  }
})

class Maintenance extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const {classes} = this.props
    const {logoFileName, maintenance} = config

    if (!maintenance) return <Redirect to={{pathname: '/'}} />

    return <Grid container className={classes.container}>
      <Grid container className={classes.insideContainer} spacing={3}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <img className={classes.fullWidth} src={Images[`${logoFileName}`]} alt={'logo'} />
            </Grid>
            <Grid item xs={12} className={classes.header}>
              <Typography variant='h2' className={classes.bold}><Trans {...messages.somethingNewExciting} /></Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h3'><Trans {...messages.chechBackForYou} /></Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <img src={'https://viverno.com/app/uploads/crane.gif'} alt={'maintenance'} />
        </Grid>
      </Grid>
    </Grid>
  }
}

export default compose(withStyles(styles, {withTheme: true}))(Maintenance)
