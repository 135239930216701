import React, {Component} from 'react'
import {flowRight as compose} from 'lodash'
import withStyles from '@mui/styles/withStyles'
import {Trans} from 'react-i18next'
import messages from '../../assets/messages'
import Typography from '@mui/material/Typography'
import {AlertDialog} from './Dialog'
import AppContext from './contexts/AppContext'

const styles = theme => ({
  paddingTop: {
    paddingTop: '1rem',
  },
})

class AppropTestInitial extends Component {
  static contextType = AppContext

  render() {
    const {classes, open, onClose} = this.props

    return (
      <div>
        <AlertDialog
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={onClose}
          title={<Trans {...messages.importantInformation}/>}
        >
          <Typography variant="body1" className={classes.paddingTop}>
            <Trans {...messages.initialInformationModalCysecText1} />
          </Typography>
          <Typography variant="body1" className={classes.paddingTop}>
            <Trans {...messages.initialInformationModalCysecText2} />
          </Typography>
          <Typography variant="body1" className={classes.paddingTop}>
            <Trans {...messages.initialInformationModalCysecText3} />
          </Typography>
        </AlertDialog>
      </div>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
)(AppropTestInitial)
