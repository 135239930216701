
import React, {Component} from 'react'
import {Snackbar, Box, SnackbarContent, Typography} from '@mui/material'
import {Trans, withNamespaces} from 'react-i18next'
import {flowRight as compose} from 'lodash'
import AppContext from './contexts/AppContext'
import {systemNotificationTypes} from '../../common/utils/uioptions'
import {withStyles} from '@mui/styles'
import classNames from 'classnames'

const styles= theme => ({
  title:{
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.5px',
    textAlign: 'left',
    '&.success': {
      color: theme.palette.notificationBar.greenTextColor,
    },
    '&.error': {
      color: theme.palette.notificationBar.redTextColor,
    },
    '&.warning': {
      color: theme.palette.notificationBar.yellowTextColor,
    },
    '&.info': {
      color: theme.palette.notificationBar.blueTextColor,
    },
  },
  message:{
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.5px',
    textAlign: 'left'
  },
  box:{
    display: 'flex',
    gap: '8px',
    height: '24px',
    width: '328px'
  },
  notificationBox:{
    gap: '8px',
    display: 'grid',
  },
  snackbar: {
    display: 'flex',
    alignItems: 'center',
    boxShadow: 'none',
    width: '360px',
    height: 'fit-content',
    padding: '12px 16px 12px 16px',
    gap: '8px',
    borderRadius: '6px',
    '&.success': {
      backgroundColor: theme.palette.notificationBar.greenBackground,
    },
    '&.error': {
      backgroundColor: theme.palette.notificationBar.redBackground,
    },
    '&.warning': {
      backgroundColor: theme.palette.notificationBar.yellowBackground,
    },
    '&.info': {
      backgroundColor: theme.palette.notificationBar.blueBackground,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
      width: 'auto',
      top: '16px',
      left: '16px',
      right: '16px',
      position: 'fixed'
    },
  },
})

class SystemNotification extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {
      open: true
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.message !== prevState.message || nextProps.type !== prevState.type) {
      return {
        open: true,
        message: nextProps.message,
        type: nextProps.type,
        customMessage: nextProps.customMessage
      }
    }
    else {
      nextProps.hideSystemNotification()
      return {
        open: false,
        message: '',
        type: '',
      }
    }
  }
  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      this.setState({open: false})
    }
    this.setState({open: false})
  }
  render() {
    const {open} = this.state
    const {classes, message ,type, customMessage, theme} = this.props
    const notificationType = systemNotificationTypes.find(notification => notification.type === type)
    return (
      <Box>
        <Snackbar
          anchorOrigin={
            {vertical: 'top',
              horizontal: 'right',
              [theme.breakpoints.down('sm')]: {
                vertical: 'top',
                horizontal: 'center'
              }
            }
          }
          sx={{zIndex: 99999}}
          open={open}
          onClose={this.handleClose}
          autoHideDuration={7000}
        >
          <SnackbarContent
            sx={{
              '.MuiSnackbarContent-message': {
                padding: 0
              },
            }}
            className={classNames(classes.snackbar, type)}
            message={
              <Box className={classNames(classes.notificationBox, type)}>
                <Box className={classes.box}>
                  <Box>
                    {notificationType?.icon}
                  </Box>
                  <Box>
                    <Typography className={classNames(classes.title, type)}> <Trans {...notificationType?.title} /> </Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography className={classes.message}> {customMessage ? message : <Trans {...message}/>} </Typography>
                </Box>
              </Box>
            }
          />
        </Snackbar>
      </Box>
    )
  }
}
export default compose(
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
)(SystemNotification)
