import {map, get, has, omit, includes, keys, isEmpty, flowRight as compose} from 'lodash'
import React, {Component} from 'react'
import {Trans, withNamespaces} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import Grid from '@mui/material/Grid'
import Radio from '@mui/material/Radio'
import Hidden from '@mui/material/Hidden'
import FormLabel from '@mui/material/FormLabel'
import Typography from '@mui/material/Typography'
import RadioGroup from '@mui/material/RadioGroup'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import messages from '../../../../assets/messages'
import AppContext from '../../../Common/contexts/AppContext'
import FormHelperText from '@mui/material/FormHelperText'
import {Checkbox, FormGroup, TextField} from '@mui/material'
import SelectDocument from '../../Documents/SelectDocument'
import {checkFileMimeType} from '../../../../common/utils/validations'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import {isMobile} from '../../../../common/utils/browser'
import DueDiligenceDescriptionModal from '../../../Common/DueDiligenceDescriptionModal'
import {maxFileSize} from '../../../../common/utils/variables'
import Images from '../../../Common/Images'
import {logEventCustomParams} from '../../../../common/utils/firebaseEvents'
import classNames from 'classnames'
import CustomTooltip from '../../../Common/CustomTooltip'

const styles = theme => ({
  questions: {
    marginBottom:20,
    width: '100%'
  },
  answersGroup:{
    margin:16
  },
  answersGroupTextField:{
    margin: 16,
    width: '80%'
  },
  question: {
    marginBottom:10
  },
  answer:{
    lineHeight:1.5
  },
  error:{
    color: theme.palette.red.color,
  },
  errorText:{
    color: theme.palette.red.color,
    marginTop: 0,
    marginLeft: 18
  },
  onlyText:{
    visibility: 'hidden'
  },
  helpIcon: {
    marginBottom: '-5px',
    paddingTop: 5,
    cursor: 'pointer'
  },
  formControlLabelVAligned: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: '8px',
  },
  checkboxControlVAligned: {
    paddingTop: '0px'
  },
  lineHeight: {
    lineHeight: '28px',
  },
  toolTip: {
    '& p': {
      color: theme.palette.secondary.dark,
    },
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.boxShadowStyle,
  },
  arrow: {
    color: theme.palette.background.default,
  },
})

export class DueDiligenceQuestions extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {
      uploadedFile: {},
      errorFiles: {},
      openPopup: {promoteProducts: false, servicesOffer: false},
    }
  }

  openPopup(key) {
    this.setState(state => ({
      openPopup: {
        ...state.openPopup,
        [key]: true
      },
    }))
  }

  closePopup () {
    this.setState({openPopup: false})
  }

  handleFileUpload(prop, e, questionKey, files) {
    const filesToUpload =  get(e, 'target.files') ? e.target.files : files
    const updatedProp = filesToUpload[0]
    this.setState(prevState => ({
      [prop]: {
        ...prevState[prop],
        [questionKey]: updatedProp,
      }
    }), this.props.onChange(filesToUpload[0], questionKey))
    const {errorFiles} = this.state
    map(filesToUpload,(file) => {
      try {
        checkFileMimeType(file).then( res => {
          this.setState({fileChecked: true})
          const fileFormat = res && res.indexOf('image') === -1 && res.indexOf('pdf') === -1
          const fileSize = file.size > maxFileSize * 1024 * 1024
          if (fileFormat || fileSize) {
            logEventCustomParams('documentUploadError', {
              location: 'DueDiligence',
              reason: 'wrong fileFormat or fileSize',
              userId: get(this.context, 'clientId'),
            })
            this.setState(prevState => ({
              errorFiles: {
                ...prevState.errorFiles,
                [questionKey]: {
                  name: file.name,
                  error: fileFormat ? 'format' : 'size'
                }
              }
            }))
          }
          else {
            const errors = omit(errorFiles,[questionKey])
            this.setState({errorFiles:errors})
          }
        })

      } catch (e) {
        console.log(e) /* eslint-disable-line */
      }
    })
  }

  render() {
    const {form, formState, errors, activeStep, onChange, classes, questions} = this.props
    const {locale, companyObject, themePreference} = this.context
    const {uploadedFile, errorFiles, openPopup} = this.state
    return map(questions, (q, questionKey) => {
      const {step: {stepPosition}, options, description} = q
      const answerKey = get(form, `answers[${questionKey}]` ,'')
      const hasError = formState === 'validation' && has(errors, questionKey)
      const termsAnswerKey = q.key === 'termsConditions'
      return stepPosition === activeStep && !has(q, ['deprecated']) &&
      <Grid key={`${questionKey}-row`} container>
        <Grid key={`${questionKey}-column-1`} item xs={12} sm={9}>
          <FormControl
            variant="standard"
            key={questionKey}
            component="fieldset"
            className={classes.questions}>
            <FormLabel
              id={questionKey}
              component="legend"
              error={hasError}
              className={classes.question}>
              <Typography variant='body2' className={classNames(hasError ? (classes.error): '', classes.lineHeight)}>
                {q.orderLabel}. {q.localization.t(locale, {domain: companyObject.emailDomain})}
                {description ?
                  isMobile() ?
                    <InfoIcon onClick={() => this.openPopup(q.key)} color='secondary' className={classes.helpIcon}/>
                    : description?.isTooltipOnly
                      ? <CustomTooltip
                        title={<React.Fragment>
                          <Typography variant='subtitle2'>
                            {q.description.localization.t(locale, {domain: companyObject.emailDomain})}
                          </Typography>
                        </React.Fragment>}
                        classes={{toolTip: classes.toolTip, arrow: classes.arrow}}
                        placement='bottom-start'>
                        <InfoIcon onClick={() => this.openPopup(q.key)} color='secondary' className={classes.helpIcon}/>
                      </CustomTooltip>
                      : ''
                  : ''
                }
              </Typography>
              {hasError && <FormHelperText className={classes.errorText}>
                <Trans {...messages.requiredField} /></FormHelperText>}
            </FormLabel>
            <FormGroup
              row
              className={!q.textfield ? classes.answersGroup : classes.answersGroupTextField}
            >
              {q.multiselect && map(options, (answer, answerKeySub) =>
              {
                const answerKeyValue = termsAnswerKey ? 'termsAnswer' : answerKeySub
                return (!has(answer, ['disable']) && !get(answer, 'deprecated') && <FormControlLabel
                  className={classes.formControlLabelVAligned}
                  style={{width: '100%', ...(!termsAnswerKey ? {minWidth: '310px'}: {minWidth: '100%'})}}
                  key={answerKeySub}
                  value={answerKeyValue}
                  classes={{label: classes.answer}}
                  label={answer.localization.t(locale, {domain: companyObject.emailDomain})}
                  onChange={(e) => onChange(e.target, questionKey)}
                  control={<Checkbox
                    className={classes.checkboxControlVAligned}
                    checked={answerKey.includes(answerKeyValue)}
                    color="primary"
                    classes={{root: get(answer, 'onlyText') && classes.onlyText}}/>}
                />)
              }
              )}
              {q.textfield && <Grid item xs={12} style={{width: '50%', minWidth: '50%'}} key={q.value}>
                <TextField
                  variant="standard"
                  required={q.mandatory(form.answers, 'key')}
                  id={q.key}
                  name={q.value}
                  label={q.localization.t(locale)}
                  fullWidth
                  multiline
                  value={answerKey || ''}
                  onChange={(e) => onChange(e.target.value, questionKey)} /></Grid>}
              {q.radioBtn && !has(q, ['deprecated']) && <RadioGroup
                row
                name={questionKey}
                value={answerKey}
                onChange={(e) => onChange(e.target, questionKey)}
                className={classes.answersGroup}
              >
                {map(options, (answer, answerKey) =>
                  (!has(answer, ['deprecated']) && <FormControlLabel
                    style={{width: '49%', minWidth: '310px'}}
                    key={answerKey}
                    value={answerKey}
                    classes={{label: classes.answer}}
                    label={answer.localization.t(locale, {domain: companyObject.emailDomain})}
                    control={<Radio
                      color="primary"/>}
                  />)
                )}
              </RadioGroup>}
              {q.uploadBtn && <Grid container>
                <Grid item xs={5}>
                  <SelectDocument
                    uploadedFile={uploadedFile[questionKey]}
                    onChange={(e, files) => this.handleFileUpload('uploadedFile', e, questionKey, files)}
                    key={answerKey}
                    type={'upload'}
                    status={uploadedFile[questionKey] && (includes(keys(errorFiles), questionKey) ? 'error': 'success')}
                    errors={!isEmpty(errorFiles) && errorFiles[questionKey]}
                    fileKey={questionKey}
                    category={`document-${themePreference}`}
                    src={Images[`upload-document-${themePreference}.png`]}
                  />
                </Grid>
              </Grid>
              }
            </FormGroup>
          </FormControl>
        </Grid>
        <Hidden lgDown>
          <Grid key={`${questionKey}-column-2`} item xs={3} sm={3}>
            {description && !description?.isTooltipOnly &&
              <Typography variant='body1' className={classes.lineHeight}>
                {q.description.localization.t(locale, {domain: companyObject.emailDomain})}
              </Typography>}
          </Grid>
        </Hidden>
        {isMobile() && <DueDiligenceDescriptionModal
          open={get(openPopup, [q.key], false)}
          q={q} onClose={() => this.closePopup()}
        />}
      </Grid>
    })
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces()
)(DueDiligenceQuestions)
