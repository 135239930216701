import React from 'react'
import {Redirect, Route, Switch, withRouter} from 'react-router-dom'
import Overview from './Overview'
import LoyaltyProgram from '../LoyaltyProgram'
import Preferences from './Preferences'
import Support from './Support'
import Events from './Events'
import PromoMaterials from './PromoMaterials'
import TradingCompetition from './TradingCompetition'

const PartnerZoneRoutes = ({match: {path}}) => (
  <Switch>
    <Route exact path={`${path}/overview`} component={Overview} />
    <Route exact path={`${path}/loyalty-program`} component={LoyaltyProgram} />
    <Route path={`${path}/promo-materials`} component={PromoMaterials} />
    <Route exact path={`${path}/events`} component={Events} />
    <Route exact path={`${path}/preferences`} component={Preferences} />
    <Route exact path={`${path}/support`} component={Support} />
    <Route path={`${path}/:parentPath/:childPath+`} render={({match}) => <Redirect to={`${path}/${match.params.parentPath}`} />} />
    <Route path={`${path}/trading-competition`} component={TradingCompetition} />
    <Redirect exact from={`${path}`} to={`${path}/overview`} />
  </Switch>
)

export default withRouter(PartnerZoneRoutes)
