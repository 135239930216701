import React, {Component} from 'react'
import {graphql} from 'react-apollo'
import withStyles from '@mui/styles/withStyles'
import {Loading} from '../../../Common/Loading'
import Grid from '@mui/material/Grid'
import classNames from 'classnames'
import AppContext from '../../../Common/contexts/AppContext'
import {AlertDialog} from '../../../Common/Dialog/index'
import {rawSubscriptionPlans} from '@bdswiss/common-enums'
import messages from '../../../../assets/messages'
import {ACCOUNTS_QUERY} from '../../../../graphql/queries'
import {CANCEL_RAW_SUBSCRIPTION_MUTATION, REACTIVATE_RAW_SUBSCRIPTION_MUTATION, CHANGE_RAW_SUBSCRIPTION_MUTATION} from '../../../../graphql/mutations'
import {Trans, withNamespaces} from 'react-i18next'
import {map, get, size, flowRight as compose} from 'lodash'
import {Typography} from '@mui/material'
import {getSubscriptionStatusMsg, getPlanAmount, getPlanCommissions, isFirstRawPlan} from '../../../../common/utils/general'
import NotificationBar from '../../../Common/NotificationBar'
import moment from 'moment'
import {Link, withRouter} from 'react-router-dom'
import PageSubTitle from '../../../Common/PageSubTitle'
import PlanCard from '../../../Common/PlanCard'

const styles = theme => ({
  title: {
    color: theme.palette.secondary.dark,
    fontSize: 24,
  },
  subheader: {
    color: theme.palette.primary.main,
    fontSize: 35,
    paddingTop: 20
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(2),
    },
  },
  cardLoadingActions: {
    display: 'block',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(2),
    },
  },
  cardContent: {
    flex: '1 0 auto',
    justifyContent: 'center',
  },
  cardPricing: {
    textAlign: 'left'
  },
  cardRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    '&:hover':{
      boxShadow: `0 0 5px 0px ${theme.palette.primary.main}`
    }
  },
  cardSelected:{
    boxShadow: `0 0 5px 0px ${theme.palette.primary.main}`
  },
  textGreen:{
    color: theme.palette.green.color
  },
  textYellow:{
    color: theme.palette.yellow.color
  },
  listItem: {
    fontSize: 14,
    paddingLeft: 4,
    paddingRight: 4,
  },
  item: {
    padding: 5
  },
  currentButton:{
    '&:hover':{
      backgroundColor: 'transparent'
    }
  },
  highlightedText: {
    color: theme.palette.primary.main,
  },
  errorMessage:{
    color:  theme.palette.error.main,
  },
  dialogTitle:{
    marginBottom: 10,
    textAlign: 'center'
  },
  symbol:{
    color:  theme.palette.secondary.dark,
    fontSize: 20
  },
  hightlight: {
    fontWeight: 400,
  },
  links: {
    textAlign: 'right',
    marginTop: 17,
    [theme.breakpoints.down('md')]: {
      marginTop: 5
    },
    cursor:'pointer'
  },
  textBlue:{
    color: theme.palette.primary.main
  },
  inlineTypography: {
    paddingLeft: '1rem',
    display:'inline-block',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
    },
  },
  proFreeTicks:{
    paddingRight: 20,
    [theme.breakpoints.down('md')]: {
      paddingRight: 0,
      display:'block',
      lineHeight: '32px'
    }
  },
  [theme.breakpoints.down('md')]: {
    topDiv:{
      paddingBottom: '1rem'
    }
  }
})
export class ChangePlan extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)

    this.state = {
      openChangeModal: false,
      openCancelReactivateModal: false,
      loadingProceed: false,
      loadingCancelReactivate: false,
    }
  }

  openChangeModal(plan) {
    this.setState({
      openChangeModal: true,
      changePlan: plan
    })
  }

  closeChangeModal() {
    this.setState({
      openChangeModal: false,
    })
  }

  onChangePaymentMethod() {
    const {history, location:{pathname}} = this.props
    const {changePlan} = this.state
    this.setState({openChangeModal: false})
    history.push({pathname:`${pathname}/payment`, state: {changePlan: changePlan}})
  }

  onProceed() {
    const {t, account,location:{pathname}} = this.props
    const {changePlan} = this.state
    this.setState({loadingProceed:true, statusProceed:''})
    this.props.changeRawSubscription({
      variables: {accountId: account.id, plan: changePlan.key}
    }).then((succ) => {
      this.setState({loadingProceed: false,statusProceed: 'success'})
      window.location.href = pathname
    })
      .catch((err) => {
        if (err.networkError) {
          this.setState({loadingProceed: false,statusProceed: 'failure',
            submitMessageError: t(messages.networkError.i18nKey, messages.networkError.defaults),
          })
        } else {

          this.setState({loadingProceed: false,statusProceed: 'failure',
            submitMessageError: get( err, 'graphQLErrors[0].message') || err.message,
          })
        }
      })
  }

  cancelSubscription() {
    const {t, account,location:{pathname}} = this.props
    this.setState({loadingCancelReactivate: true,statusCancelReactivate: '',cancelReactivateError: ''})
    this.props.cancelRawSubscription({
      variables: {accountId: account.id}
    }).then((succ) => {
      this.setState({loadingCancelReactivate: false,statusCancelReactivate: 'success',cancelReactivateError: ''})
      window.location.href = pathname
    })
      .catch((err) => {
        if (err.networkError) {
          this.setState({loadingCancelReactivate: false,statusCancelReactivate: 'failure',
            cancelReactivateError: t(messages.networkError.i18nKey, messages.networkError.defaults),
          })
        } else {
          this.setState({loadingCancelReactivate: false,statusCancelReactivate: 'failure',
            cancelReactivateError: get( err, 'graphQLErrors[0].message') || err.message,
          })
        }
      })
  }

  reActivateRawSubscription() {
    const {t, account,location:{pathname}} = this.props
    this.setState({loadingCancelReactivate: true,statusCancelReactivate: '',cancelReactivateError: ''})
    this.props.reActivateRawSubscription({
      variables: {accountId: account.id}
    }).then((succ) => {
      this.setState({loadingCancelReactivate: false,statusCancelReactivate: 'success',cancelReactivateError: ''})
      window.location.href = pathname
    })
      .catch((err) => {
        if (err.networkError) {
          this.setState({loadingCancelReactivate: false,statusCancelReactivate: 'failure',
            cancelReactivateError: t(messages.networkError.i18nKey, messages.networkError.defaults),
          })
        } else {
          this.setState({loadingCancelReactivate: false,statusCancelReactivate: 'failure',
            cancelReactivateError: get( err, 'graphQLErrors[0].message') || err.message,
          })
        }
      })
  }

  renderChangeRawDialog() {
    const {openChangeModal, changePlan, loadingProceed, statusProceed,submitMessageError} = this.state
    const {classes, t, account} = this.props
    const subscription = (account && account.subscription) || ''
    const billingInfo = subscription.billingInfo ? `${subscription.billingInfo.cardType} - ${subscription.billingInfo.lastFourDigits} - ${subscription.billingInfo.month}/${subscription.billingInfo.year}` : ''

    return <AlertDialog
      open={openChangeModal}
      onClose={() => this.closeChangeModal()}
      onAgree={(e) => this.onProceed()}
      onDisagree={() => this.onChangePaymentMethod()}
      disagreeText={t(messages.changePaymentMethod.i18nKey, messages.changePaymentMethod.defaults)}
      agreeText={t(messages.proceedButton.i18nKey, messages.proceedButton.defaults)}
      buttonLoading={loadingProceed}
      buttonStatus={statusProceed}
      errorText={submitMessageError}
      title = { <Typography variant='h4' className={classes.dialogTitle}>
        <Trans
          {...messages.changePlanTitle}
          values={{plan: changePlan && changePlan.label}}
          components={[<span className={classes.highlightedText}>plan</span>]}
        />
      </Typography>}
      closeBtn
      disabledTypography
    >
      <Typography variant='body1'>
        <Trans
          {...messages.changePlanText}
          values={{planAmount: getPlanAmount(changePlan)}}
          components={[<span className={classes.hightlight}>plan</span>]}
        /></Typography>
      <Typography variant='body1' component='span' className={classes.hightlight}>({billingInfo})</Typography>
    </AlertDialog>
  }

  renderCancelReactivateModal(subscription) {
    const {t} = this.props
    const {loadingCancelReactivate, statusCancelReactivate, cancelReactivateError, openCancelReactivateModal} = this.state

    return <AlertDialog
      open={openCancelReactivateModal}
      onClose={() =>this.setState({openCancelReactivateModal: false})}
      onAgree={() => subscription.isActive ? this.cancelSubscription() : this.reActivateRawSubscription()}
      onDisagree={() =>this.setState({openCancelReactivateModal: false})}
      disagreeText={t(messages.cancel.i18nKey, messages.cancel.defaults)}
      agreeText={t(messages.proceedButton.i18nKey, messages.proceedButton.defaults)}
      buttonLoading={loadingCancelReactivate}
      buttonStatus={statusCancelReactivate}
      errorText={cancelReactivateError}
    >
      <Trans
        {...messages.submitWithdrawalTitle}
      />
    </AlertDialog>
  }

  getSubscriptionStatus(subscription, subscriptionStatusMsg) {

    const notificationMessage = subscriptionStatusMsg
    const notificationStatus = !subscription.isActive ? 'warning' : 'info'
    const actionText  = !subscription.isExpired ? 'changePlan' : 'activate'
    const currentActionText  = subscription.isActive ? 'cancel' :  subscription.isExpired ? 'activate' : 'reactivate'
    return {notificationMessage, notificationStatus, actionText, currentActionText}
  }

  buttonAction(subscription, plan, isCurrentPlanBtn) {
    const {location:{pathname}, history} = this.props
    if (subscription) {
      if (isCurrentPlanBtn) {
        return  subscription.isExpired ?
          history.push({pathname:`${pathname}/payment`, state: {changePlan: plan}}) :
          this.setState({openCancelReactivateModal: true})
      } else {
        return subscription.isActive ?
          ((plan.key !== subscription.plan) ?
            this.openChangeModal(plan)
            : '' )
          : (subscription.isExpired ?
            history.push({pathname:`${pathname}/payment`, state: {changePlan: plan}})
            : '')

      }
    }
  }


  render() {
    const {loading, classes, onCardClick, location:{pathname}, history, account} = this.props
    const {statusCancelReactivate, cancelReactivateError, openChangeModal, openCancelReactivateModal} = this.state
    const subscription = (account && account.subscription) || ''
    const subscriptionPlan = subscription && rawSubscriptionPlans[subscription.plan]
    const showNotification = subscriptionPlan && (!get(subscriptionPlan, 'isFree') || (get(subscriptionPlan, 'isFree') && !subscription.isActive) || (isFirstRawPlan(subscriptionPlan) && account.isInFallback))
    const pendingPlan = subscription && subscription.pendingPlan && rawSubscriptionPlans[subscription.pendingPlan]
    if (loading) return <Loading />

    const volumeTraded = (account && account.volumeTraded) || 0
    const isInFallback = (account && account.isInFallback) || false
    const subscriptionStatusMsg = account && subscription && getSubscriptionStatusMsg(subscription, pendingPlan, volumeTraded, isInFallback)
    const subscriptionStatus = subscription && this.getSubscriptionStatus(subscription, subscriptionStatusMsg)
    const topPlan =  account && subscription && rawSubscriptionPlans[subscription['plan']].order === size(rawSubscriptionPlans)

    const {overTheLimitCommission,fallBackCommission} = getPlanCommissions(subscriptionPlan)
    return (
      <React.Fragment>
        {openChangeModal && this.renderChangeRawDialog()}
        {openCancelReactivateModal && this.renderCancelReactivateModal(subscription)}
        <Grid item xs={12}>
          {subscription && showNotification && <NotificationBar
            status={subscriptionStatus.notificationStatus}
            title={<Trans
              {...messages[subscriptionStatus.notificationMessage]}
              values={{renewalDate: String(moment(subscription.nextPaymentDate).format('DD/MM/YYYY HH:mm')),
                renewalAmount: getPlanAmount(subscriptionPlan,pendingPlan),
                pendingPlan: pendingPlan && pendingPlan.label,
                overTheLimitCommission,
                fallBackCommission}}
              components={[
                <span className={classes.hightlight}>renewalDate</span>,
                <span className={classes.hightlight}>renewalAmount</span>,
                <span className={classes.hightlight}>plan</span>
              ]}
            />}
          />}
        </Grid>
        {subscription && <Grid container className={classes.topDiv}>
          <Grid item xs={6}>
            <PageSubTitle>{subscriptionPlan.label}
              <Link to={`${pathname}/payment`}>
                <Typography variant='body2' className={classNames(classes.textBlue,classes.inlineTypography)}>
                  <Trans {...messages.changePaymentMethod} />
                </Typography>
              </Link>
            </PageSubTitle>

          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={0}>
              <Grid item xs={12} className={classes.links}>
                <Typography variant='body2' className={classNames(classes.textBlue)}>
                  {topPlan && <Link to={'/support?account'} className={classNames(classes.textBlue, classes.proFreeTicks)} >
                    <Trans {...messages.requestHistoric} />
                  </Link>
                  }
                  <Trans
                    {...messages.subscriptionPaymentHistory}
                    components={[<span onClick={() => history.push({
                      pathname: '/transactions/history',
                      search: '?subscription=enabled',
                      state: {prevPath: pathname}
                    })}>history</span>]}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>}

        <Grid container spacing={5}>
          {map((rawSubscriptionPlans), plan => {
            const currentPlan = (subscription && !subscription.isExpired && plan.key === subscription.plan) || ''
            return <Grid item key={plan.label} xs={12} md={4}>
              <PlanCard  plan={plan} subscription={subscription} currentPlan={currentPlan}  subscriptionStatus={subscriptionStatus}
                onClick={() => onCardClick ? onCardClick(plan) : (subscription.isActive && ((!currentPlan) ? this.openChangeModal(plan) : ''))}
                buttonAction ={(current) => this.buttonAction(subscription,plan,current)}
                showActions ={!onCardClick}
                currentState= {{statusCancelReactivate,cancelReactivateError}} />
            </Grid>
          })}
          <Grid item xs={12}>
            <Typography variant='caption' >
              <Trans
                {...messages.rawSpreadExplanation}
              />
            </Typography>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

export default compose(
  withNamespaces(),
  withStyles(styles),
  withRouter,
  graphql(CANCEL_RAW_SUBSCRIPTION_MUTATION, {
    name: 'cancelRawSubscription',
    options: {
      refetchQueries: [{query: ACCOUNTS_QUERY}],
    }
  }),
  graphql(REACTIVATE_RAW_SUBSCRIPTION_MUTATION, {
    name: 'reActivateRawSubscription',
    options: {
      refetchQueries: [{query: ACCOUNTS_QUERY}],
    }
  }),
  graphql(CHANGE_RAW_SUBSCRIPTION_MUTATION, {
    name: 'changeRawSubscription',
    options: {
      refetchQueries: [{query: ACCOUNTS_QUERY}],
    }
  }),
  graphql(ACCOUNTS_QUERY, {
    props: ({data: {error, loading}, data, ownProps: {match}}) => {
      const {accountId} = (match && match.params) || {}
      const account = accountId && get(data, 'viewer.accounts', []).find(account => account.id === Number(accountId))
      return {
        error,
        loading,
        account,
      }
    }
  }))(ChangePlan)
