import React, {Component} from 'react'
import {map, flowRight as compose} from 'lodash'
import {officialOnlineTradingSponsors} from '../../common/utils/uioptions'
import {Trans} from 'react-i18next'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import messages from '../../assets/messages'
import AppContext from './contexts/AppContext'
import Images from '../../components/Common/Images'

const styles = theme => ({
  greyText:{
    color: theme.palette.secondary.main,
    fontWeight: 500,
    textTransform: 'capitalize'
  },
  stats:{
    paddingLeft:'0 !important'
  },
  details: {
    padding: '10px 0',
  },
  sponsor: {
    width: '70%',
    height: 50
  }
})


export class Sponsorships extends Component {
  static contextType = AppContext

  render() {
    const {classes} = this.props
    const {themePreference} =this.context

    return <Grid item xs={12} className={classes.stats}>
      <Typography variant='subtitle2' className={classes.greyText}>
        <Trans {...messages.officialSponsorOf} />
      </Typography>
      {map(officialOnlineTradingSponsors, (sponsor, key) =>
        <span key={key}>
          {(sponsor.src) ?
            <img src={Images[`${sponsor.src}-${themePreference}.svg`]} alt={sponsor.alt} className={classes.sponsor}/> :
            sponsor.text ? <Typography variant='h2' className={classes.details}>
              {sponsor.text}
            </Typography> : ''}
        </span>
      )}
    </Grid>
  }
}

export default compose(withStyles(styles, {withTheme: true}))(Sponsorships)
