import React, {Component} from 'react'
import {flowRight as compose, get, map} from 'lodash'
import withStyles from '@mui/styles/withStyles'
import {Trans, withNamespaces} from 'react-i18next'
import messages from '../../assets/messages'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import {AlertDialog} from './Dialog'
import AppContext from '../Common/contexts/AppContext'
import {appropTestPoliticallyExposedReason} from '@bdswiss/common-enums'
import {FormControl, FormControlLabel, Radio, RadioGroup} from '@mui/material'

const styles = theme => ({
  subtitle: {
    paddingBottom: 20
  },
  error: {
    color: theme.palette.error.main,
  },
})

class PepDialog extends Component {
  static contextType = AppContext

  render() {
    const {classes, open, t, pepCheckboxError, form, onAgree, onChange} = this.props
    const {locale} = this.context

    return (
      <AlertDialog
        open={open || false}
        onAgree={() => onAgree()}
        title={t(messages.pepTitle.i18nKey, messages.pepTitle.defaults)}
        agreeText={t(messages.continue.i18nKey, messages.continue.defaults)}
      >
        <Grid container direction="row" alignContent="center" justifyContent="center">
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.subtitle}>
              <Trans {...messages.pepSubtitle} />
            </Typography>
          </Grid>
          <FormControl variant="standard" component="fieldset">
            <RadioGroup
              aria-label="Gender"
              name="gender1"
              value={get(form, 'politicallyExposedReason')}
              onChange={(e) => onChange(e)}
            >
              {map(appropTestPoliticallyExposedReason, (option) =>
                <FormControlLabel key={option.key} value={option.value} control={<Radio color="primary" className={pepCheckboxError ? classes.error: ''}/>}
                  label={<Typography variant="subtitle2" className={pepCheckboxError ? classes.error: ''}>
                    {option.localization.t(locale)}
                  </Typography>} />
              )}
            </RadioGroup>
          </FormControl>
        </Grid>
      </AlertDialog>
    )
  }
}

export default compose(
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
)(PepDialog)
