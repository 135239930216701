import React from 'react'
import {flowRight as compose, isEmpty} from 'lodash'
import NoTransactionsSection from './NoTransactionsSection'
import {isMobile} from '../../../common/utils'
import DesktopView from './DesktopView'
import MobileView from './MobileView'

class RecentTransactionsSection extends React.Component {
  render() {
    const {loading, showSensitiveData, transactions} = this.props

    return isEmpty(transactions)
      ? <NoTransactionsSection loading={loading} />
      : isMobile()
        ? <MobileView loading={loading} transactions={transactions} showSensitiveData={showSensitiveData}/>
        : <DesktopView loading={loading} transactions={transactions} showSensitiveData={showSensitiveData}/>
  }
}

export default compose(

)(RecentTransactionsSection)
