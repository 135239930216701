import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {frontends, help2payDepositBanks, countries} from '@bdswiss/common-enums'
import {map, sortBy, filter, flowRight as compose, get, includes} from 'lodash'
import {Trans} from 'react-i18next'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import {graphql} from 'react-apollo'
import {withCreateDeposit, PaymentActionButton} from './helpers'
import SelfPostingForm from './SelfPostingForm'
import messages from '../../../../assets/messages'
import {getPlatform} from '../../../../common/utils/browser'
import {HELP2PAY_DEPOSIT_BANKS_QUERY} from '../../../../graphql/queries'
import {getSourceBasedOnLocationPrevPath, logEventCustomParams} from '../../../../common/utils'

class Help2PayProvider extends Component {
  state = {doSubmitForm: false, bankCode: '', formData: {}}

  doCreateDeposit() {
    const {account, providerProperties:{provider, paymentKey, id: paymentOptionId}, amount, onError, useVirtualPaymentPage, bonusAmount, bonusTerms,
      history: {location}} = this.props

    this.setState({creatingDeposit: true})

    const firebaseParams = {
      source: getSourceBasedOnLocationPrevPath(get(location, 'state', {prevPath: get(location, 'pathname')})),
      login: account.id,
      currency: account.currency,
      vendor: provider,
      amount,
    }
    logEventCustomParams('depositAttempted', firebaseParams)

    const variables = {
      accountId: account.id,
      amount,
      vendor: provider,
      paymentOptionId,
      frontend: frontends.web2.value,
      args: JSON.stringify({
        platform: getPlatform(),
        paymentKey,
        useVirtualPaymentPage,
        bankCode: this.state.bankCode,
        bonusAmount,
        bonusTerms,
      }),
    }

    this.props.createDepositRequest({variables})
      .then(({data: {newDeposit}}) => {
        const {payment} = newDeposit
        const parsedFormData = JSON.parse(payment.url)
        this.setState({formData: parsedFormData, doSubmitForm: true})
      })
      .catch((e) => {
        this.setState({creatingDeposit: false})
        onError && onError(e)
      })
  }

  handlebankCodeChange = ({target: {value}}) => this.setState({bankCode: value})

  render() {
    const {onSubmit, help2payDepositBanksOnline, loading, error, canProceed} = this.props
    const {formData, doSubmitForm, creatingDeposit, bankCode} = this.state

    let banks
    if (loading) {
      banks = {loading: {label: 'Loading...'}}
    } else if (error) {
      banks = {error: {label: 'Error loading banks!'}}
    } else {
      banks = sortBy(filter(help2payDepositBanks, (bank) => includes(help2payDepositBanksOnline, bank.value)), 'label')
    }

    return (
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12}>
          <FormControl variant="standard" fullWidth>
            <InputLabel htmlFor="bank_code"><Trans {...messages.bank} /></InputLabel>
            <Select
              variant="standard"
              value={bankCode}
              onChange={this.handlebankCodeChange}
              inputProps={{
                name: 'bank_code',
                id: 'bank_code',
              }}>
              {map(banks, o => <MenuItem value={o.value} key={o.key}>{o.label}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <PaymentActionButton
            loading={creatingDeposit}
            onClick={() => this.doCreateDeposit()}
            disable={canProceed || !bankCode}
          />
        </Grid>
        <SelfPostingForm formData={formData} doSubmitForm={doSubmitForm} onSubmit={onSubmit}/>
      </Grid>
    )
  }
}

Help2PayProvider.propTypes = {
  createDepositRequest: PropTypes.func.isRequired,
  providerProperties: PropTypes.shape({
    name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    paymentKey: PropTypes.string.isRequired,
    paymentOptionId: PropTypes.number.isRequired,
  }).isRequired,
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired
  }).isRequired,
  amount: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

export default compose(
  withCreateDeposit,
  graphql(HELP2PAY_DEPOSIT_BANKS_QUERY, {
    options: (props) => ({variables: {currency: get(countries[get(props, 'viewer.address.country', '').toLowerCase()], 'currency')}}),
    props: ({data: {error, loading}, data}) => ({
      error,
      loading,
      help2payDepositBanksOnline: get(data, 'help2PayDepositBanks', []),
    })
  }),
)(Help2PayProvider)
