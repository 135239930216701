import React, {Component} from 'react'
import {flowRight as compose, get, includes} from 'lodash'
import {Paper, Typography} from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import {logEventCustomParams} from '../../../common/utils'
import config from '../../../config'

const styles = theme => ({
  paper: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: theme.spacing(1),
    backgroundColor: ({isDark}) => isDark ? theme.palette.cardBackground.color : theme.palette.extralightgrey.color,
    border: ({isDark}) => `1px solid ${theme.palette.grey[isDark ? 900 : 200]}`,
    textDecoration: 'none',
    color: 'inherit',
    width: '100%',
    maxHeight: 280,
    cursor: 'pointer',
  },
  imgBackground: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundImage: ({item}) => `url('${item.imageUrl}')`,
    backgroundSize: 'cover',
    backgroundPosition: 'left',
    backgroundRepeat: 'no-repeat',
    transform: theme.direction === 'rtl' ? 'scaleX(-1)' : 'scaleX(1)',
  },
  content: {
    position: 'relative',
    padding: theme.spacing(3),
    maxWidth: '70%',
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    [theme.breakpoints.between('xs', 'md')]: {
      padding: ({isMobileDevice}) => theme.spacing(isMobileDevice ? 2 : 3),
      maxWidth: ({isMobileDevice}) => isMobileDevice ? '100%' : '70%',
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      padding: ({isMobileDevice}) => theme.spacing(isMobileDevice ? 2 : 3),
      maxWidth: ({isMobileDevice}) => isMobileDevice ? '85%' : '70%',
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
      maxWidth: '70%',
    },
  },
  noMargin: {
    margin: 0,
  },
  contentColor: {
    color: theme.palette.primary.contrastText,
  },
  title: {
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '32px',
    letterSpacing: '0.18px',
    [theme.breakpoints.down('md')]: {
      fontSize: ({isMobileDevice}) => isMobileDevice ? '16px' : '24px',
      lineHeight: ({isMobileDevice}) => isMobileDevice ? '24px' : '32px',
      letterSpacing: ({isMobileDevice}) => isMobileDevice ? '0.5px' : '0.18px',
    },
  },
  message: {
    fontSize: '16px',
    fontWeight: 300,
    lineHeight: '24px',
    letterSpacing: '0.5px',
    [theme.breakpoints.down('md')]: {
      fontSize: ({isMobileDevice}) => isMobileDevice ? '14px' : '16px',
      lineHeight: ({isMobileDevice}) => isMobileDevice ? '20px' : '24px',
      letterSpacing: ({isMobileDevice}) => isMobileDevice ? '0.25px' : '0.5px',
    },
  },
})

export class NotificationSlide extends Component {
  componentDidMount() {
    this.handleLoadImage()
  }

  handleLoadImage = () => {
    const {item, handleImageLoaded} = this.props
    const img = new Image()
    img.src = item.imageUrl
    img.onload = handleImageLoaded
  }

  handleOnClick = (item) => {
    logEventCustomParams('whatsNewWidgetClicked', {notificationId: item.id, templateName: item.templateName})
    let link = item.link
    const frontendUrl = get(config, 'frontendUrl')
    if (!includes(item.link, frontendUrl)) {
      const path = item?.link?.split('/')?.pop()
      link = `${frontendUrl}/${path}`
    }
    window.open(link, '_blank', 'noreferrer')
  }

  render() {
    const {classes, item} = this.props
    return (
      <Paper
        key={`item_${item.id}`}
        className={classes.paper}
        elevation={0}
        onClick={() => this.handleOnClick(item)}
      >
        <div className={classes.imgBackground} />
        <div className={classes.content}>
          <Typography variant='h3' className={classNames(classes.noMargin, classes.contentColor, classes.title)}>
            {item.title}
          </Typography>
          <Typography variant='body1' className={classNames(classes.contentColor, classes.message)}>
            {item.message}
          </Typography>
        </div>
      </Paper>
    )
  }
}

NotificationSlide.propTypes = {
  item: PropTypes.object.isRequired,
  isDark: PropTypes.bool.isRequired,
  handleImageLoaded: PropTypes.func.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
}

export default compose(withStyles(styles, {withTheme: true}))(NotificationSlide)
