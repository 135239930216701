import {graphql} from 'react-apollo'
import {Link} from 'react-router-dom'
import React, {Component} from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import {FormHelperText} from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import {flowRight as compose, get} from 'lodash'
import Container from '@mui/material/Container'
import {withNamespaces, Trans} from 'react-i18next'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import config from '../../../config'
import Images from '../../Common/Images'
import {disableDepositWithSpecificCountries, isMobile} from '../../../common/utils'
import messages from '../../../assets/messages'
import LoadingButton from '../../Common/LoadingButton'
import AppContext from '../../Common/contexts/AppContext'
import {REQUEST_CALLBACK_PRIORITY_SERVICE} from '../../../graphql/mutations'
import classNames from 'classnames'

const styles = theme => ({
  paper: {
    backgroundColor: theme.palette.common.black,
  },
  button: {
    width: '260px',
    height: '66px',
    boxShadow: 'none',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.green.color,

    '&:hover': {
      backgroundColor: theme.palette.green.color,
    }
  },
  item: {
    width: '50%',
    borderRadius: '25px',
    backgroundColor: '#181818',
    padding: `${theme.spacing(2)} ${theme.spacing(2)}`,

    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  whiteLeftAlignedText: {
    textAlign: 'left',
    color: theme.palette.common.white,
  },
  whiteLeftAlignedBoldText: {
    fontWeight: 500,
    textAlign: 'left',
    color: theme.palette.common.white,
  },
  whiteCenterAlignedText: {
    textAlign: 'center',
    color: theme.palette.common.white,
  },
  whiteCenterAlignedBoldText: {
    fontWeight: 500,
    textAlign: 'center',
    color: theme.palette.common.white,
  },
  buttonClose: {
    padding: '0px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
  },
  closeIcon:{
    fontSize: 35,
    [theme.breakpoints.down('md')]: {
      fontSize: 25,
    },
  },
  topBar: {
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.common.black,
  },
  success: {
    color: theme.palette.green.color
  },
  failure: {
    color: theme.palette.red.color
  },
  fullWidth: {
    width: '100%'
  }
})

class PriorityServiceLP extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    this.state = {
      loading: '',
      status: '',
    }
  }

  handleRequestCallback() {
    this.setState({loading: true})

    this.props.requestCallbackPriorityService()
      .then((res) => {
        if (res['data']['requestCallbackPriorityService']) this.setState({loading: false, status: 'success'})
      })
      .catch((error) => {
        this.setState({loading: false, status: 'failure'})
      })
  }

  render() {
    const {classes, history, location} = this.props
    const {logoWhiteFileName, name: logoAlt} = config
    const {loading, status} = this.state
    const {country} = this.context

    const closePath = (location.search && location.search === '?close')
      ? '/'
      : get(location, 'state.from')
        ? location.state.from
        : null

    return <Dialog
      open
      fullScreen
      disableEnforceFocus
      PaperProps={{classes: {root: classes.paper}}}
    >
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <AppBar position='static' className={classes.topBar}>
              <Grid container>
                <Grid item xs={11} md={11}>
                  <img src={Images[logoWhiteFileName]} alt={logoAlt} />
                </Grid>
                <Grid item xs={1} md={1}>
                  <IconButton
                    className={classes.buttonClose}
                    onClick={() => closePath ? history.push(closePath) : history.goBack()}
                  >
                    <CloseIcon className={classes.closeIcon}/>
                  </IconButton>
                </Grid>
              </Grid>
            </AppBar>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction='row'>
              <Grid item xs={12} md={6}>
                <Grid container direction='column' spacing={3}>
                  <Grid item xs={12}>
                    <Typography className={classes.whiteLeftAlignedBoldText} variant='h1'> <Trans {...messages.lpPriorityServiceOpeningTitle} /> </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography className={classes.whiteLeftAlignedText} variant='h4'> <Trans {...messages.lpPriorityServiceOpeningSubtitle} /> </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <LoadingButton
                      className={classes.button}
                      disabled={loading || status}
                      hideProgressBar={status}
                      status={status}
                      noBackgroundColor={status}
                      onClick={() => {this.handleRequestCallback()}}
                    >
                      <Trans {...messages.requestCallback} />
                    </LoadingButton>
                    {status==='success' && <FormHelperText component="div" className={classes.success}><Trans {...messages.requestCallbackSuccess} /></FormHelperText>}
                    {status==='failure' && <FormHelperText component="div" className={classes.failure}><Trans {...messages.somethingWrongError} /></FormHelperText>}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className={classes.whiteLeftAlignedText} variant='subtitle2'> <Trans {...messages.lpPriorityServiceOpeningNote} /> </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {!isMobile() && <Grid item xs={12} md={6}>
                <img src={Images['figures_up_arrow.png']} alt='figures_up_arrow'/>
              </Grid>}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography className={classes.whiteCenterAlignedBoldText} variant='h1'> <Trans {...messages.lpPriorityServiceMainTitle} /> </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box display='flex' flexDirection='column' gap='32px'>
                  <Box display='flex' flexDirection={isMobile() ? 'column' : 'row'} gap='32px'>
                    <Box display='flex' flexDirection='column' gap='32px' className={classes.item}>
                      <Box display='flex'>
                        <img src={Images['star.svg']} alt='star' />
                      </Box>
                      <Box display='flex'>
                        <Typography className={classes.whiteLeftAlignedText} variant='h4'> <Trans {...messages.lpPriorityServiceMainItemTitle1} /> </Typography>
                      </Box>
                      <Box display='flex'>
                        <Typography className={classes.whiteLeftAlignedText} variant='caption'> <Trans {...messages.lpPriorityServiceMainItemContent1} /> </Typography>
                      </Box>
                    </Box>
                    <Box display='flex' flexDirection='column' gap='32px' className={classes.item}>
                      <Box display='flex'>
                        <img src={Images['contact_star.svg']} alt='contact_star' />
                      </Box>
                      <Box display='flex'>
                        <Typography className={classes.whiteLeftAlignedText} variant='h4'> <Trans {...messages.lpPriorityServiceMainItemTitle2} /> </Typography>
                      </Box>
                      <Box display='flex'>
                        <Typography className={classes.whiteLeftAlignedText} variant='caption'> <Trans {...messages.lpPriorityServiceMainItemContent2} /> </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box display='flex' flexDirection={isMobile() ? 'column' : 'row'} gap='32px'>
                    <Box display='flex' flexDirection='column' gap='32px' className={classes.item}>
                      <Box display='flex'>
                        <img src={Images['contact_arrow.svg']} alt='contact_arrow' />
                      </Box>
                      <Box display='flex'>
                        <Typography className={classes.whiteLeftAlignedText} variant='h4'> <Trans {...messages.lpPriorityServiceMainItemTitle3} /> </Typography>
                      </Box>
                      <Box display='flex'>
                        <Typography className={classes.whiteLeftAlignedText} variant='caption'> <Trans {...messages.lpPriorityServiceMainItemContent3} /> </Typography>
                      </Box>
                    </Box>
                    <Box display='flex' flexDirection='column' gap='32px' className={classes.item}>
                      <Box display='flex'>
                        <img src={Images['arrows_left_right.svg']} alt='arrows_left_right' />
                      </Box>
                      <Box display='flex'>
                        <Typography className={classes.whiteLeftAlignedText} variant='h4'> <Trans {...messages.lpPriorityServiceMainItemTitle4} /> </Typography>
                      </Box>
                      <Box display='flex'>
                        <Typography className={classes.whiteLeftAlignedText} variant='caption'> <Trans {...messages.lpPriorityServiceMainItemContent4} /> </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classNames(classes.whiteCenterAlignedText, classes.fullWidth)}>
            <Typography className={classes.whiteCenterAlignedText} variant='caption' > <Trans {...messages.lpPriorityServiceMainItemContent5} /> </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {!disableDepositWithSpecificCountries(null, country) && (
                  <Box display='flex' justifyContent="center" alignItems="center">
                    <Link to="/transactions"> <Button className={classes.button}> <Trans {...messages.depositNow} /> </Button> </Link>
                  </Box>
                )}
                <Box display='flex' justifyContent="center" alignItems="center">
                  <Typography className={classes.whiteCenterAlignedText} variant='caption'> <Trans {...messages.yourCapitalRisk} /> </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.whiteCenterAlignedText} variant='body1'> <Trans {...messages.lpPriorityServiceFooter} /> </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  }
}

export default compose(
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
  graphql(REQUEST_CALLBACK_PRIORITY_SERVICE, {
    name: 'requestCallbackPriorityService',
  }),
)(PriorityServiceLP)
