import {flowRight as compose, get, map} from 'lodash'
import TradingCompetitionForm from './SubComponents/TradingCompetitionForm'
import {graphql} from 'react-apollo'
import {CLIENT_DATA_QUERY} from '../../../graphql/queries'
import RequestBlockToHaveAccessManage from './SubComponents/RequestBlockToHaveAccessManage'
import React, {useState} from 'react'
import RequestBlockToHaveAccessManageAlreadyPending
  from './SubComponents/RequestBlockToHaveAccessManageAlreadyPending'
import {GET_COMPETITIONS_CREATED_BY_CLIENT_QUERY} from '../../../graphql/competitions/queries'
import moment from 'moment/moment'
import PageBody from '../../Common/PageBody'
import CompetitionCard from '../../Accounts/TradingCompetitions/CompetitionCard'
import {isMobile, safeParseJSON} from '../../../common/utils'
import CompetitionRules from '../../Accounts/TradingCompetitions/CompetitionRules'
import {Trans} from 'react-i18next'
import messages from '../../../assets/messages'
import Leaderboard from '../../Accounts/TradingCompetitions/Leaderboard'
import {FullScreenDialog} from '../../Common/Dialog'
import {currencies} from '@bdswiss/common-enums'
import Amount from '../../Common/Amount'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Loading from '../../Common/Loading'

function TradingCompetition(props) {
  const {viewer, refetchClientData, competitions = [], refetchCompetitionsCreatedByClient, isCompetitionsCreatedByClientLoading} = props
  const [needsUpdate, setNeedsUpdate] = useState(false)
  const [selectedFinishedCompetition, setSelectedFinishedCompetition] = useState(null)

  const handleUpdate = async (callback) => {
    await refetchClientData()
    await refetchCompetitionsCreatedByClient()
    setNeedsUpdate(!needsUpdate)
    if (callback) callback()
  }

  if (isCompetitionsCreatedByClientLoading) return <Loading/>

  //eslint-disable-next-line arrow-body-style
  const renderRequestLogic = () => {
    return viewer.isClientRequestedAccessToCreateTradingCompetition
      ? <RequestBlockToHaveAccessManageAlreadyPending/>
      : <RequestBlockToHaveAccessManage
        handleUpdate={handleUpdate}
        viewer={viewer}
      ></RequestBlockToHaveAccessManage>
  }

  const renderCompetitionLogic = () => {
    if (!competitions.length) {
      return <TradingCompetitionForm
        handleUpdate={handleUpdate}
      />
    }
    const [lastCompetition] = competitions
    if (lastCompetition.competitionCreatedByClientData?.isRejected) {
      return <TradingCompetitionForm
        handleUpdate={handleUpdate}
      />
    }
    if (!lastCompetition.isPublished) {
      return <TradingCompetitionForm
        competition={lastCompetition}
        handleUpdate={handleUpdate}
      />
    }
    if (
      lastCompetition.isPublished
      && moment().valueOf() < moment(lastCompetition.endDate).valueOf()
    ) {
      return renderCompetitionDetails(lastCompetition)
    }
    return <TradingCompetitionForm
      handleUpdate={handleUpdate}
    />
  }

  const renderCompetitionDetails = (selectedCompetitionsForDetails) => {
    const rules = safeParseJSON(get(selectedCompetitionsForDetails, 'rules'))
    return (
      <Grid container spacing={isMobile() ? 1 : 4}>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Grid container spacing={isMobile() ? 1 : 3}>
            <Grid item xs={12}>
              <CompetitionCard
                competition={selectedCompetitionsForDetails}
                noHover
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="displayInline"><Trans {...messages.totalDeposits} />: </Typography>
              <Typography variant="displayInline"><Amount value={selectedCompetitionsForDetails.totalDeposits} currency={currencies.USD.value} locale='en-US'/></Typography>
            </Grid>
            <Grid item xs={12}>
              <CompetitionRules rules={rules} competition={selectedCompetitionsForDetails}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} style={isMobile() ? {marginTop: 20} : {}}>
          <Typography variant="h4">
            <Trans {...messages.leaderboard} />
          </Typography>
          <Leaderboard
            competition={get(selectedCompetitionsForDetails, 'id')}
            data={selectedCompetitionsForDetails}
          />
        </Grid>
      </Grid>
    )
  }

  const renderFinishedCompetitions = () => {
    const finishedCompetitions = competitions.filter(competition => {
      const isPublishedAndFinished = (competition.isPublished && moment().valueOf() > moment(competition.endDate).valueOf())
      const isRejected = get(competition.competitionCreatedByClientData, 'isRejected')
      return isPublishedAndFinished || isRejected
    })

    if (!finishedCompetitions.length) return null

    return (
      <PageBody>
        {
          selectedFinishedCompetition
            ? <FullScreenDialog
              desktopOnly
              open={true}
              onClose={() => setSelectedFinishedCompetition(null)}
            >
              {
                renderCompetitionDetails(selectedFinishedCompetition)
              }
            </FullScreenDialog>
            : <div>
              <Typography variant="h1" align={'center'} style={{margin: 30}}>
                <Trans {...messages.completedCompetitions} />
              </Typography>
              <Grid container spacing={isMobile() ? 1 : 3}>
                {
                  map(finishedCompetitions, (c) =>
                    <Grid item xs={12} key={c.id}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={7}>
                          <CompetitionCard
                            competition={c}
                            onClick={(c) => setSelectedFinishedCompetition(c)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>)
                }
              </Grid>
            </div>
        }
      </PageBody>
    )
  }

  return (
    <div>
      {
        viewer.isClientHasAccessToCreateTradingCompetition
          ? renderCompetitionLogic()
          : renderRequestLogic()
      }
      {
        renderFinishedCompetitions()
      }
    </div>
  )
}

export default compose(
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading, viewer, refetch}}) => ({
      error,
      loading,
      refetchClientData: refetch,
      viewer
    })
  }),
  graphql(GET_COMPETITIONS_CREATED_BY_CLIENT_QUERY, {
    skip: props => !props.viewer,
    options: props => ({
      variables: {
        createdByMemberId: props.viewer.id
      }
    }),
    props: ({data: {error, loading, competitionsCreatedByClient, refetch}}) => ({
      error,
      isCompetitionsCreatedByClientLoading: loading,
      competitions: competitionsCreatedByClient,
      refetchCompetitionsCreatedByClient: refetch
    })
  })
)(TradingCompetition)
