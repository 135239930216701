import React, {Component} from 'react'
import withStyles from '@mui/styles/withStyles'
import Grid from '@mui/material/Grid'
import {withNamespaces, Trans} from 'react-i18next'
import AppContext from '../../../Common/contexts/AppContext'
import PropTypes from 'prop-types'
import {Typography} from '@mui/material'
import messages from '../../../../assets/messages'
import {AlertDialog} from '../../../Common/Dialog'
import {isEmpty, get, map} from 'lodash'
import NotificationBar from '../../../Common/NotificationBar'

const styles = theme => ({
  alertTitle: {
    fontSize: 24
  },
  deleteAccount:{
    backgorundColor: theme.palette.red.color,
    color: theme.palette.background.default,
    fontSize:16,
    paddingTop:0
  },
  highlight: {
    color: theme.palette.red.color,
  },
  ol: {
    paddingLeft: 16,
    color: theme.palette.lightgrey.color,
    fontWeight: 400,
    marginBottom: 0
  },
  li: {
    padding: '0px 0px 15px 15px',
  },
  emphasize: {
    fontWeight: 400,
  },
})

class StopStrategyDialog extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    this.state = {
      fulldialogOpen: false,
      localLoading:false
    }
  }

  handleChange = event => {
    this.setState({[event.target.name]: event.target.value})
  }

  submitRequest() {
    const {onClick} = this.props
    this.setState({localLoading: true})
    onClick && onClick()
  }

  render() {
    const {classes, open, close, t, loading, status, strategy} = this.props
    const {localLoading}= this.state
    const performanceFee= get(strategy, 'performanceFee')
    const strategyName = get(strategy, 'accountName')
    const textWithFee = ['requestStopCopyTradingFeeText', 'requestStopCopyTradingFeeText2', 'requestStopCopyTradingFeeText3']

    return <Grid container>
      <AlertDialog
        open={open}
        onAgree={() =>  this.submitRequest()}
        onDisagree={close}
        disagreeText={t(messages.cancel.i18nKey, messages.cancel.defaults)}
        agreeText={t(messages.stopCopying.i18nKey, messages.stopCopying.defaults)}
        title={<Typography variant='body1' className={classes.alertTitle}>
          <Trans {...messages.requestStopCopyTradingTitle}
            components={[
              <span  className={classes.highlight}>stop copying</span>,
            ]}
          />
        </Typography>}
        buttonLoading={isEmpty(status) ? loading : localLoading}
        buttonStatus={status}
        errorText={status === 'failure' ? t(messages.somethingWrongError.i18nKey, messages.somethingWrongError.defaults) : ''}
      >
        <Grid container direction="row" alignContent="center" justifyContent="center">
          <Grid item xs={12}>
            <Typography variant='body1'>
              <Trans {...messages[(performanceFee) ? 'requestStopCopyTradingFeeTitle' : 'requestStopCopyTradingText']}
                values={{strategy: strategyName}} components={[<span className={classes.emphasize}>strategy</span>]} />
            </Typography>
          </Grid>
          {!!performanceFee && <React.Fragment>
            <ol className={classes.ol}>
              {map(textWithFee, (text) => <li key={text} className={classes.li}>
                <Typography variant='body1'>
                  <Trans {...messages[text]}
                    values={{strategy: strategyName}}  components={[<span className={classes.emphasize}>strategy</span>]}
                  />
                </Typography>
              </li>)}
            </ol>
            <NotificationBar status="warning" noMargin title={<Trans {...messages.requestStopCopyTradingFeeWeekend} />} />
          </React.Fragment>
          }
        </Grid>
      </AlertDialog>
    </Grid>
  }
}

StopStrategyDialog.propTypes = {
  strategy: PropTypes.object,
  onClick: PropTypes.func,
}
export default withStyles(styles, {withTheme: true})(withNamespaces()(StopStrategyDialog))
