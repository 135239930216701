import React, {Component} from 'react'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import {frontends, wallettecPaymentMethods} from '@bdswiss/common-enums'
import {Trans, withNamespaces} from 'react-i18next'
import {flowRight as compose, get, map, isEmpty, values} from 'lodash'
import {FormControl, Grid, InputLabel, LinearProgress, debounce} from '@mui/material'
import messages from '../../../../assets/messages'
import {getPlatform, getSourceBasedOnLocationPrevPath, logEventCustomParams} from '../../../../common/utils'
import {PaymentActionButton, withCreateDeposit} from './helpers'
import FormHelperText from '@mui/material/FormHelperText'
import withStyles from '@mui/styles/withStyles'
import {withRouter} from 'react-router-dom/cjs/react-router-dom.min'


const style = (theme) => ({
  formControl: {
    margin: `${theme.spacing(1)} 0`
  },
  error: {
    color: theme.palette.error.main
  },
  listItemRoot: {
    borderBottom: `1px solid ${theme.palette.lightgrey.color}`,
    padding: '6px 0 7px',
  },
  bankIcon: {display: 'inline'},
  bankImage: {maxWidth: '60px'},
  paymentMethod: {
    color: 'rgba(0, 0, 0, 0.5) !important'
  },
  paymentMethodSelected: {
    color: 'rgba(0, 0, 0, 0.87) !important'
  },
})


class WallettecProvider extends Component {
  constructor(props) {
    super(props)
    this.validateFields = debounce(this.validateFields, 200)
    const {viewer: {address: {country} = {}}} = props
    const isSingleOption = wallettecPaymentMethods[country].options.length === 1
    this.state = {
      paymentInstructions: '',
      form: {
        paymentMethod: isSingleOption ? wallettecPaymentMethods[country].options[0].value : '',
        phoneNumber: '',
        voucherNumber:'',
      },
      errors: {},
      creatingDeposit: false
    }
    this.validators = this.getValidators(props)
  }
  getValidators(props) {
    const {providerProperties: {paymentMethod}} = props
    return {
      paymentMethod: (value) => {
        if (!value) return messages.requiredField
      },
      phoneNumber: (value) => {
        if (!value) return messages.requiredField
      },
      voucherNumber: (value) => {
        if (paymentMethod === 'vodafone' && !value) return messages.requiredField
      },
    }
  }

  handleChange(key, value) {
    const {form} = this.state
    this.setState({
      form: {
        ...form,
        [key]: value
      }
    }, () => this.validateFields())
  }

  validateFields() {
    const {form} = this.state
    const errors = {}
    Object.keys(form).forEach((k) => {
      const validate = this.validators[k]
      errors[k] = validate && validate(form[k])
    })
    this.setState({errors})
  }

  formIsValid() {
    const {form, errors} = this.state
    return isEmpty(values(errors).filter((v) => v)) && !isEmpty(values(form).filter((v) => v))
  }

  doCreateDeposit() {
    const {form} = this.state

    this.setState({creatingDeposit: true})
    const {
      providerProperties: {provider, paymentKey}, amount, account, onError, history: {location},
    } = this.props

    const firebaseParams = {
      source: getSourceBasedOnLocationPrevPath(get(location, 'state', {prevPath: get(location, 'pathname')})),
      login: account.id,
      currency: account.currency,
      vendor: provider,
      amount,
    }
    logEventCustomParams('depositAttempted', firebaseParams)

    const args = {
      paymentKey,
      platform: getPlatform(),
      ...form,
    }

    const variables = {
      accountId: account.id, amount, vendor: provider, frontend: frontends.web2.value, args: JSON.stringify(args),
    }

    this.props.createDepositRequest({variables})
      .then(({data}) => {
        const {newDeposit} = data
        const {payment: {url}} = newDeposit
        const {history} = this.props
        if (['success', 'pending', 'failed'].includes(url) ) {
          history.push(`/transactions/${account.id}/deposit/result/${url}`)
        } else {
          this.setState({paymentInstructions: url})
        }
      })
      .catch((e) => {
        this.setState({creatingDeposit: false})
        onError && onError(e)
      })
  }

  isValidPhone = (phone) => /^\+[1-9][0-9]{5,14}$/.test(phone)

  render() {
    const {canProceed, viewer: {address: {country} = {}}, loading, error, classes} = this.props
    const {form, creatingDeposit, paymentInstructions, phoneNumber} = this.state

    if (paymentInstructions) {
      return <div dangerouslySetInnerHTML={{__html: paymentInstructions}}/>
    }

    if (loading) {
      document.body.classList.add('loadingApp')
      return <LinearProgress/>
    }

    document.body.classList.remove('loadingApp')

    if (error) {
      return <div>{(error.graphQLErrors && error.graphQLErrors[0].message) || error.message || 'failed to load'}</div>
    }
    const isSingleOption = wallettecPaymentMethods[country].options.length === 1

    return (
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12}>

          <FormControl variant="standard" fullWidth>
            <InputLabel htmlFor="payment-method-selector">
              <Trans {...messages.paymentMethod}/>
            </InputLabel>
            <Select
              variant="standard"
              id="paymentMethod"
              value={get(form, 'paymentMethod', '')}
              onChange={(e) => this.handleChange('paymentMethod' , e.target.value)}
              disabled={isSingleOption}>
              {map(wallettecPaymentMethods[country].options, (paymentMethod) => <MenuItem key={paymentMethod.value} value={paymentMethod.value}>{paymentMethod.label}</MenuItem>)}
            </Select>
          </FormControl>

          <FormControl variant="standard" fullWidth>
            <TextField
              variant="standard"
              id= "phoneNumber"
              type="text"
              value={get(form, 'phoneNumber', '')}
              label={<Trans {...messages.phoneNumber} />}
              onChange={(e) => this.handleChange('phoneNumber', e.target.value)}
              error={this.state.phoneNumber && !this.isValidPhone(phoneNumber)} />
            <FormHelperText className={this.state.phoneNumber  && !this.isValidPhone(phoneNumber) && classes.errorMessage}>
              <Trans {...messages.phoneValidationInternational} />
            </FormHelperText>
          </FormControl>

          {form.paymentMethod === 'vodafone' &&
            <FormControl variant="standard" fullWidth>
              <TextField
                variant="standard"
                id="voucherNumber"
                type="text"
                value={get(form, 'voucherNumber', '')}
                label={<Trans {...messages.voucher} />}
                onChange={(e) => this.handleChange('voucherNumber', e.target.value)} />
            </FormControl>
          }

          <PaymentActionButton
            loading={creatingDeposit}
            disable={canProceed || !this.formIsValid()}
            onClick={() => this.doCreateDeposit()}
          />
        </Grid>
      </Grid>
    )
  }
}

WallettecProvider.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  providerProperties: PropTypes.shape({
    name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    paymentKey: PropTypes.string.isRequired,
  }).isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

export default compose(
  withStyles(style),
  withCreateDeposit,
  withNamespaces(),
  withRouter,
)(WallettecProvider)
