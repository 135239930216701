import React, {Component} from 'react'
import AppContext from '../../Common/contexts/AppContext'
import Grid from '@mui/material/Grid'
import {withNamespaces, Trans} from 'react-i18next'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import messages from '../../../assets/messages'
import Images from '../../Common/Images'
import {isMobile} from '../../../common/utils/browser'
import {includes, flowRight as compose} from 'lodash'
import classNames from 'classnames'
import {CustomDialog} from '../../Common/Dialog'
import PageTitle from '../../Common/PageTitle'
import {getCurrentTheme} from '../../../common/utils'

const styles = (theme) => ({
  iconClose: {
    width: 46,
    height: 30,
    position: 'relative',
    left: 7,
    backgroundSize: '30px 30px',
    paddingLeft: 10,
    display: 'block',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
    backgroundImage: `url(${Images[`icon-close-${getCurrentTheme()}.svg`]})`,
    [theme.breakpoints.down('sm')]: {
      backgroundSize: '20px 20px',
    },
  },
  popup: {
    maxWidth: 1000,
    margin: '0 auto'
  },
  textCenter:{
    textAlign: 'center'
  },
  imgMobile: {
    width: '100%'
  },
  dialogContent: {
    marginTop: theme.spacing(2),
  }
})

class DocumentDetails extends Component {
  static contextType = AppContext

  render() {
    const {classes, category, selectedDocTypeKey, t, displayTypes, onClose, getLocalizedLabel} = this.props

    return <CustomDialog
      fullScreen
      open
      customClasses={classes}
    >
      <Grid container alignItems="center" alignContent='center' className={classNames(classes.popup, classes.textCenter)} spacing={0}>
        <Grid item container justifyContent="flex-end">
          <div className={classes.iconClose} onClick={() => onClose()} />
        </Grid>
        <Grid item xs={12} className={classes.centerItem}>
          <PageTitle hideArrow={isMobile()} modal>
            <Trans {...messages.uploadProperly} />
          </PageTitle>
        </Grid>
        <Grid item xs={12} className={classes.centerItem}>
          <Typography variant="body1">
            <Trans {...messages.takingPictures}
              values={{document: includes(selectedDocTypeKey, 'other') ? t(messages[`${category}Title`].i18nKey) : getLocalizedLabel(displayTypes[selectedDocTypeKey])}}/>
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.centerItem}>
          <img src={Images['poi-document.png']} alt={'takingPictures'} className={isMobile() ? classes.imgMobile : ''} />
        </Grid>
        {Images[`${category}-${selectedDocTypeKey}.png`] && <React.Fragment>
          <Grid item xs={12} className={classes.centerItem}>
            <Typography variant="body1">
              <Trans {...messages.documentsExamples}
                values={{document: includes(selectedDocTypeKey, 'other') ? t(messages[`${category}Title`].i18nKey) : getLocalizedLabel(displayTypes[selectedDocTypeKey])}} />
            </Typography>
          </Grid>
          <Grid item xs={12} md={(displayTypes[selectedDocTypeKey] && displayTypes[selectedDocTypeKey].doubleSided) ? 6 : 12} className={classes.centerItem}>
            <img src={Images[`${category}-${selectedDocTypeKey}.png`]} alt={'documentsExamples'} className={isMobile() ? classes.imgMobile : ''} />
          </Grid></React.Fragment>}
        {displayTypes[selectedDocTypeKey] && displayTypes[selectedDocTypeKey].doubleSided && Images[`${category}-${selectedDocTypeKey}-back.png`] && <Grid item xs={12} md={6} className={classes.centerItem}>
          <img src={Images[`${category}-${selectedDocTypeKey}-back.png`]} alt={'documentsExamplesBack'} className={isMobile() ? classes.imgMobile : ''} />
        </Grid>}
      </Grid>
    </CustomDialog>
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces()
)(DocumentDetails)
