import React, {Component} from 'react'
import {graphql, withApollo} from 'react-apollo'
import {flowRight as compose, filter, includes, map, get, find, first, omitBy, isEmpty} from 'lodash'

import {styled} from '@mui/system'
import {Grid, Menu} from '@mui/material'

import config from '../../config'
import BentoDivider from './BentoDivider'
import BentoMenuItem from './BentoMenuItem'
import AppContext from '../Common/contexts/AppContext'
import ForexWebtraderModal from '../Accounts/Forex/ForexWebtraderModal'
import {bentoMenuOptions, isRtlLang} from '../../common/utils/uioptions'
import {
  isAffiliatesAccount, isBitnukAccount, isCentAccount, isForexAccount, isIntroducingBrokerAccount, isMAMAccount,
  isMobile, logEventCustomParams
} from '../../common/utils'

import {SOCIALMEDIALINKS_QUERY} from '../../graphql/queries'
import {AFFILIATE_SSO_LINK_QUERY, IB_SSO_LINK_MUTATION} from '../../graphql/mutations'

const BentoMenu = styled(Menu)(({theme}) => ({
  '& .MuiPopover-paper': {
    borderRadius: 8,
    boxShadow: theme.palette.mode === 'dark' ? 'none' : '0px 6px 20px rgba(0, 0, 0, 0.12)',
    backgroundColor: theme.palette.mode === 'dark' ? '#181818' : theme.palette.background.paper,
    border: 0,
  },
  '& .MuiBackdrop-root': {
    backgroundColor: isMobile() ? 'rgba(0, 0, 0, 0.7)' : 'transparent',
  },
}))

const BentoMenuList = styled(Grid)(({theme}) => ({
  flexDirection: 'column',
  width: 320,
  padding: '8px 0',
  [theme.breakpoints.down('md')]: {
    width: 280,
  },
}))

class BentoMenuModal extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    this.handleBentoOnClick = this.handleBentoOnClick.bind(this)
    this.state = {
      anchorWebtraderBtn: null,
    }
  }

  handleToggleModalState = (anchor, event) => {
    this.setState({[anchor]: event?.currentTarget})
  }

  handleBentoOnClick(key, event) {
    const {links, onClose} = this.props
    const firebaseParams = {
      BentoMenuItem: bentoMenuOptions[key].firebaseEvent,
      userId: get(this.context, 'clientId'),
    }
    logEventCustomParams('bentoMenuItemClick', firebaseParams)
    switch (key) {
      case 'webtrader': {
        onClose()
        this.handleToggleModalState('anchorWebtraderBtn', event)
        break
      }
      case 'affiliate': {
        const {accounts} = this.context
        const affiliateAccount = find(accounts, (account) => isAffiliatesAccount(account))
        onClose()
        this.toAffiliatePortal(affiliateAccount)
        break
      }
      case 'website': {
        onClose()
        window.open(`${get(config, 'weblinks.websiteUrl')}`, '_blank')
        break
      }
      case 'ib': {
        const {accounts} = this.context
        const ibAccount = find(accounts, (account) => isIntroducingBrokerAccount(account))
        onClose()
        this.toIBPortal(ibAccount)
        break
      }
      case 'telegramGroup':
      case 'tradingCentral': {
        const {featuresConfig: {tools}} = config
        const {clientId: userId} = this.context
        const sortedLinks= omitBy(links, value => isEmpty(value) || value === 'socialMediaType')
        key === 'ebook' ? logEventCustomParams('forexEbookClicked', {userId}) : logEventCustomParams(`${key}Clicked`, {userId})
        onClose()
        if (get(tools[key], 'internal')) {
          window.open(`/trading-tools/${key}`, '_blank')
        } else {
          window.open (sortedLinks?.telegram)
        }
        break
      }
      default: {
        onClose()
        break
      }
    }
  }

  toIBPortal(account) {
    const {client: apolloClient} = this.props
    const newTab = window.open('about:blank', '_blank')
    newTab.document.write('<h4>Please wait</h4>')

    apolloClient.mutate({
      mutation: IB_SSO_LINK_MUTATION,
      variables: {accountId: account.id},
    }).then((res) => {
      newTab.location = res.data.data.link
    }).catch((e) => {
      newTab.close()
    })
  }

  toAffiliatePortal(account) {
    const {client: apolloClient} = this.props
    const newTab = window.open('about:blank', '_blank')
    newTab.document.write('<h4>Please wait</h4>')

    apolloClient.query({
      query: AFFILIATE_SSO_LINK_QUERY,
      variables: {accountId: account.id},
      fetchPolicy:'network-only'
    }).then((res) => {
      newTab.location = res.data.data.link
    }).catch((e) => {
      newTab.close()
    })
  }

  render() {
    const {anchorWebtraderBtn} = this.state
    const {viewer, anchorEl, open, onClose} = this.props
    const {locale} = this.context
    const id = open ? 'simple-popover' : undefined
    const {accounts} = this.context
    const {featuresConfig: {bentoMenu}} = config
    const tradingAccounts = filter(accounts, (account) => !isAffiliatesAccount(account) && !isIntroducingBrokerAccount(account)
      && !isMAMAccount(account) && (isForexAccount(account) || isCentAccount(account))
      && !account.isArchived && !account.isViewOnly && !isBitnukAccount(account))
    const section = filter(bentoMenuOptions, (option) => option.enabled(bentoMenu) && option.show(viewer, tradingAccounts))

    return (
      <>
        <BentoMenu
          id={id}
          open={open}
          onClose={() => onClose()}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: isMobile() ? 42 : 58,
            horizontal: includes(isRtlLang, locale) ? 'left' : 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: includes(isRtlLang, locale) ? 'left' : 'right'
          }}
          slotProps={{
            root: {
              slotProps: {
                backdrop: {invisible: !isMobile()},
              }
            }
          }}
        >
          <BentoMenuList container>
            {map(section, (option) => (
              option.titleKey === 'divider'
                ? <BentoDivider key={option.key} />
                : <BentoMenuItem key={option.key} option={option} onClick={this.handleBentoOnClick}/>
            ))}
          </BentoMenuList>
        </BentoMenu>

        <ForexWebtraderModal
          tradingAccounts={tradingAccounts}
          showAccountsModal={Boolean(anchorWebtraderBtn)}
          onClose={() => this.handleToggleModalState('anchorWebtraderBtn', null)}
        />
      </>
    )
  }
}

export default compose(
  withApollo,
  graphql(SOCIALMEDIALINKS_QUERY,{
    options: () => ({
      fetchPolicy: 'network-only',
    }),
    props: ({data: {loading, error}, data}) =>
      ({
        loadingSocialMedia: loading,
        errorSocialMedia: error,
        links: first(get(data, 'socialMediaLinks'))
      }),
  }),
)(BentoMenuModal)
