const mobileOverrides = {
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: '1.8rem',
        },
        h3: {
          fontSize: 18,
          fontWeight: 300,
          lineHeight: '32px',
          marginTop: 10,
          marginBottom: 5,
        },
        h4: {
          fontSize: '1.1rem',
        },
        h5: {
          fontSize: 16,
        },
        subtitle1: {
          fontSize: 15,
          lineHeight: 1.4,
        },
        subtitle2: {
          fontSize: 12,
        },
        body1: {
          fontSize: 14,
          letterSpacing: 'normal',
        },
        body2: {
          fontSize: 14,
        },
        caption: {
          fontSize: 11,
          lineHeight: 1.5,
          display: 'block',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          lineHeight: 'auto',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginTop: 0,
          marginBottom: 0,
          boxShadow: 'none',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          padding: 15,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          margin: 25,
        },
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
  },
}

export default mobileOverrides
