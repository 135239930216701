import React, {Component} from 'react'
import {flowRight as compose} from 'lodash'
import {FullScreenDialog} from '../Common/Dialog'
import PageTitle from '../Common/PageTitle'
import PageBody from '../Common/PageBody'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import {withNamespaces, Trans} from 'react-i18next'
import messages from '../../assets/messages'
import AppContext from '../Common/contexts/AppContext'

const styles = theme => ({
  pageBody:{
    marginLeft: 30,
    [theme.breakpoints.down('md')]:{
      marginLeft: 0,
    },
  },
  hightlight: {
    fontWeight: 400,
  },
})

export class EmailVerificationNeeded extends Component {
  static contextType = AppContext

  closeDialog() {
    this.context.logout()
  }

  render() {
    const {t, classes, history} = this.props
    const clientEmail = history.location.state && history.location.state.email ? history.location.state.email : history.push('/accounts')

    return (
      <FullScreenDialog
        desktopOnly
        open
        onClose={() => this.closeDialog()}
      >
        <PageTitle
          onBack={() => this.closeDialog()}
          title={t(messages.emailVerification.i18nKey, messages.emailVerification.defaults)}
        />
        <PageBody className={classes.pageBody}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant='body1' gutterBottom>
                <Trans {...messages.emailVerificationThankYou} />
              </Typography>
              <Typography variant='body1'>
                <Trans {...messages.emailVerificationText1} values={{clientEmail: clientEmail}} components={[<span className={classes.hightlight}>email</span>]}/>
              </Typography>
              <Typography variant='body1'>
                <Trans {...messages.emailVerificationText2} />
              </Typography>
            </Grid>
          </Grid>
        </PageBody>
      </FullScreenDialog>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
)(EmailVerificationNeeded)
