import React, {Component} from 'react'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import {withNamespaces, Trans} from 'react-i18next'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import messages from '../../../assets/messages'
import {isMobile} from '../../../common/utils/browser'
import {get, isEmpty, flowRight as compose, includes, keys, find, size} from 'lodash'
import classNames from 'classnames'
import CheckIcon from '@mui/icons-material/CheckCircleOutline'
import CloseIcon from '@mui/icons-material/HighlightOffOutlined'
import FormHelperText from '@mui/material/FormHelperText'
import JdenticonImage from '../../Common/JdenticonImage'
import Images from '../../Common/Images'
import ErrorOutlineOutlined from '@mui/icons-material/ErrorOutlineOutlined'
import Button from '@mui/material/Button'

const styles = (theme) => ({
  upload: {
    height: 210,
    cursor: 'pointer',
    position: 'relative',
    [theme.breakpoints.down('lg')]: {
      height:'100%',
      padding: '10px 0',
    },
  },
  uploadedImage: {
    maxWidth: '100%',
    height: '100%',
  },
  alignCenter: {
    textAlign: 'center'
  },
  uploadPaper: {
    height: 210,
    width: '100%',
    borderRadius: 10,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.lightgrey.color : theme.palette.extralightgrey.color,
    [theme.breakpoints.down('lg')]: {
      height:'100%'
    },
  },
  input: {
    display: 'none',
  },
  defaultImage: {
    maxWidth: 150,
    maxHeight: 100,
  },
  icon: {
    position: 'absolute',
    [theme.direction === 'rtl' ? 'right' : 'left']: 15,
    top: 15,
    fontSize: 20
  },
  checkIcon:{
    color: theme.palette.green.color,
  },
  closeIcon:{
    color: theme.palette.error.main,
  },
  error: {
    border: `1px solid ${theme.palette.error.main}`
  },
  success: {
    border: `1px solid ${theme.palette.green.color}`
  },
  img: {
    maxHeight: 70,
  },
  overflowHidden: {
    overflow: 'hidden',
    wordBreak: 'break-word'
  },
  textHeight: {
    maxHeight: 50
  },
  helperText: {
    marginTop: 0
  },
  borderRadiusOptional: {
    borderRadius: '50%',
    width: 100,
    height: 100,
    maxWidth: 100,
    maxHeight: 100,
    'object-fit': 'cover',
  },
  dragAndDrop: {
    border: `1px dashed ${theme.palette.lightgrey.color}`
  },
  corporatePaper: {
    border: `1px solid ${theme.palette.lightgrey.color}`,
    boxShadow: 'none'
  },
  corporateHover: {
    '&:hover':{
      border: `1px solid ${theme.palette.primary.main}`
    },
  },
  cursorNormal: {
    cursor: 'unset'
  },
  corporateHeight:{
    height: 130,
    [theme.breakpoints.down('md')]: {
      height: 100,
    },
  },
  alignRight: {
    textAlign: 'right'
  },
  displayInline: {
    display: 'inline-block'
  },
  changeDiv: {
    verticalAlign: 'top',
    paddingTop: 12,
    [theme.breakpoints.down('md')]: {
      paddingTop: 20
    },
  },
  deleteDiv: {
    padding: 12
  },
  errorContainer: {
    padding: '0px 20px'
  },
  infoIcon: {
    marginTop: 5
  },
  errorText: {
    verticalAlign: 'top',
    padding: '5px 10px'
  },
  fullHeight: {
    height: '100%'
  },
  itemFlex: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center'
  },
  showFileHeight: {
    height: 100,
  },
  changePadding: {
    paddingRight: 20,
    [theme.breakpoints.down('md')]: {
      paddingRight: 0
    },
  },
  corporateDouble: {
    height: 130,
    paddingTop: 0,
    paddingBottom: 0
  },
  corporateError: {
    padding: '27px 0'
  },
  changeErrorDiv: {
    paddingTop: 7
  },
  submitButton: {
    marginTop: 40
  },
})

class SelectDocument extends Component {

  constructor(props) {
    super(props)
    this.state = {
      dragAndDrop: false
    }
    window.addEventListener('dragenter', (event) => {
      this.setState({dragAndDrop: true})
    })
    window.addEventListener('dragleave', (event) => {
      if (event && !event.relatedTarget) this.setState({dragAndDrop: false})
    })
    window.addEventListener('drop', (event) => {
      this.setState({dragAndDrop: false})
    })
    window.addEventListener('focusout', (event) => {
      this.setState({dragAndDrop: false})
    })
  }

  fileNameSize(name) {
    if (!name) return ''
    return (size(name) > 20) ? `${name.substring(0, 17)}...` : name
  }

  removeFileFromFileList(key) {
    const input = document.getElementById(`paper-upload-file-${key}`)
    if (input) input.value = ''
  }

  render() {
    const {classes, onChange, fileKey, category, status, errors, selectedDocTypeKey, src, srcSuccess, srcError, multiple, showLabel, borderRadiusOptional,
      pendingConfirmation, jdenticon, avatar, classesNames, corporate, showFile, disabled, setOuterState, initialForm, showSubmitButton, strategyAvatarRejected} = this.props
    let {uploadedFile} = this.props
    const {dragAndDrop} = this.state

    const multipleUploadedFiles= multiple && uploadedFile && uploadedFile.length > 1
    const multipleUploadedFilesLength = uploadedFile &&  uploadedFile.length
    if (multiple) {
      uploadedFile = uploadedFile[0]
    }
    const dropZone = document.getElementById(`drop_zone-${fileKey}`)
    if (dropZone) {
      dropZone.ondragover = function (e) {e.preventDefault()}
      dropZone.ondrop = function (e) {
        e.preventDefault()
        onChange(e, e.dataTransfer.files, fileKey)
      }
    }
    const corporateAndUploaded = corporate && uploadedFile
    const fileTypeImages = {pdf: 'application/pdf'}
    const fileTypeImage = find(keys(fileTypeImages), key => fileTypeImages[key] === (get(showFile, 'fileType') || get(uploadedFile, 'type')))
    const showAvatarInitialState = (!avatar || (avatar && uploadedFile))

    return (
      <div id={`drop_zone-${fileKey}`}>
        <input
          className={classes.input}
          id={`paper-upload-file-${fileKey}`}
          type="file"
          onChange={(e) => onChange(e)}
          multiple={multiple}
          disabled={disabled}
        />
        <label htmlFor={`paper-upload-file-${fileKey}`}>
          <Paper className={classNames(classes.uploadPaper, status && showAvatarInitialState && (status === 'error' ? classes.error: classes.success),
            dragAndDrop ? classes.dragAndDrop : status !== 'error' && corporate && [classes.corporatePaper, !disabled && classes.corporateHover],
            corporate && [(showFile) ? classes.showFileHeight : classes.corporateHeight, !isEmpty(errors) && classes.errorContainer, showLabel && classes.corporateDouble])}>
            <Grid
              container
              alignContent={!isMobile() ? 'center' : 'stretch'}
              justifyContent={!corporateAndUploaded ? 'center' : 'flex-start'}
              spacing={1}
              className={classNames(classes.upload, classes.overflowHidden, corporate && [(showFile) ? classes.showFileHeight : classes.corporateHeight,
                disabled && classes.cursorNormal, showLabel && classes.corporateDouble, !isEmpty(errors) && classes.corporateError])}
            >
              {!corporate && status && status === 'success' && showAvatarInitialState && <CheckIcon className={classNames(classes.icon, classes.checkIcon)} />}
              {!corporate && status && status === 'error' && showAvatarInitialState && <CloseIcon className={classNames(classes.icon, classes.closeIcon)} />}
              {showLabel && <Grid item xs={12} className={classes.alignCenter}>
                <Typography variant="body1">
                  <Trans {...messages[(includes(fileKey, '2')) ? 'backSide' : 'frontSide']} />
                </Typography>
              </Grid>}
              {(uploadedFile || showFile) && (!corporate || (corporate && isEmpty(errors))) && <Grid item xs={!corporate ? 5 : 2} className={classNames(classes.alignCenter, classes.img)}>
                <img className={classes.uploadedImage} src={status === 'error' ? srcError || src :
                  (corporate && fileTypeImage)
                    ? (!showFile) ? Images[`document-${fileTypeImage}.png`] : Images[`document-${fileTypeImage}.png`]
                    : (!showFile) ? URL.createObjectURL(uploadedFile) : get(showFile, 'url')}
                alt="" onError={(e)=> {
                  if (status !== 'error') e.target.src = srcSuccess || src
                }}
                />
              </Grid>}
              {(uploadedFile || showFile) && ((!corporate) ?
                <Grid item xs={12} className={classes.alignCenter}>
                  {multipleUploadedFiles ?
                    <Typography component="span" variant="caption" color="primary">({multipleUploadedFilesLength})</Typography> :
                    <Typography variant="caption" classes={{root: classNames(classes.overflowHidden, classes.textHeight)}}>{get(uploadedFile, 'name')}</Typography>}
                </Grid> : <React.Fragment>
                  {isEmpty(errors) && <React.Fragment>
                    <Grid item xs={6}>
                      <Grid container className={classes.fullHeight}>
                        <Grid item xs={12}>
                          <Typography variant="body1" className={classNames(classes.itemFlex, classes.fullHeight)}>
                            {showFile ? this.fileNameSize(showFile.fileDescription): this.fileNameSize(uploadedFile.name)}
                          </Typography>
                        </Grid>
                        {!showFile && <Grid item xs={12}>
                          <Typography variant="body1">
                            {uploadedFile.size > 1048576 ? `${(uploadedFile.size / 1048576).toFixed(2)} MB` :
                              uploadedFile.size > 1024 ? `${(uploadedFile.size / 1024).toFixed(2)} KB` : `${uploadedFile.size} Bytes`}
                          </Typography>
                        </Grid>}
                      </Grid>
                    </Grid>
                  </React.Fragment>}
                  {!isEmpty(errors) && <Grid item xs={9} className={classNames(classes.closeIcon)}>
                    <div className={classNames(classes.displayInline)}><ErrorOutlineOutlined className={classes.infoIcon}/></div>
                    <Typography variant="body1" className={classNames(classes.displayInline, classes.errorText)} classes={{root: classes.closeIcon}}>
                      <Trans {...messages[`${errors.error}FileError`]}/>
                    </Typography>
                  </Grid>}
                  <Grid item xs={isEmpty(errors) ? 4 : 3} className={classes.alignRight}>
                    {!disabled && <Typography variant="body2" color="primary" className={classNames(isEmpty(errors) && classes.changeDiv, classes.displayInline, showFile && classes.changePadding, !isEmpty(errors) && classes.changeErrorDiv)}>
                      <Trans {...messages[!showFile ? 'change' : 'reupload']} />
                    </Typography>}
                    {isEmpty(errors) && !showFile && <div className={classNames(classes.deleteDiv, classes.displayInline)} onClick={(e) => {
                      e.preventDefault()
                      onChange(e, [])
                      this.removeFileFromFileList(fileKey)
                      if (initialForm && !initialForm[fileKey]) setOuterState('form', fileKey, false)
                    }}>
                      <img src={Images['trash-delete-remove.png']} alt="" />
                    </div>}
                  </Grid>
                </React.Fragment>)}
              {!uploadedFile && !showFile && <Grid item xs={8} className={classes.alignCenter}>
                {!jdenticon && <img className={classNames(classes.defaultImage, category && classes[`${category.replace('-front','').replace('-back','')}Default`], classes[selectedDocTypeKey], borderRadiusOptional ? classes.borderRadiusOptional : '')}
                  src={!uploadedFile && src}
                  alt={<Trans {...messages.clickOrDrag} />} />}
                {jdenticon &&  <JdenticonImage accountName={jdenticon} size={'100'}/>}
              </Grid>}
              {!isEmpty(errors) && showAvatarInitialState && !corporate && <Grid item xs={12}>
                <FormHelperText error className={classNames(classes.alignCenter, classes.helperText)}>  <Trans {...messages[`${errors.error}FileError`]} /> </FormHelperText>
              </Grid>}
              {!corporateAndUploaded && !showFile && <Grid item xs={12} className={classes.alignCenter}>
                <Typography variant="body2" color="primary">
                  <Trans {...messages[(uploadedFile) ? 'change' : 'clickOrDrag']} />
                </Typography>
              </Grid>}
              {(pendingConfirmation || strategyAvatarRejected) && avatar && !uploadedFile && <Grid item xs={12}>
                <FormHelperText error className={classNames(classes.alignCenter, classes.helperText)}>
                  <Typography component="span" variant="caption">
                    {pendingConfirmation && !strategyAvatarRejected ? <Trans {...messages.partnerManagerApproval}/> : <Trans {...messages.strategyAvatarRejected} />}
                  </Typography>
                </FormHelperText>
              </Grid>}
            </Grid>
          </Paper>
        </label>
        {showSubmitButton && <Grid container className={classes.submitButton}>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" size="large"
              onClick={() => showSubmitButton()} fullWidth={isMobile()}>
              <Trans {...messages.submit} />
            </Button>
          </Grid>
        </Grid>}
      </div>
    )
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withNamespaces()
)(SelectDocument)
