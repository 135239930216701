import React, {Component} from 'react'
import {get} from 'lodash'
import {Trans} from 'react-i18next'
import messages from '../../assets/messages'
import Typography from '@mui/material/Typography'
import {AlertDialog} from './Dialog'
import AppContext from './contexts/AppContext'

class DueDiligenceDescriptionModal extends Component {
  static contextType = AppContext

  render() {
    const {classes, q} = this.props
    const {locale, companyObject} = this.context

    return (
      <div>
        <AlertDialog
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.props.open}
          onClose={this.props.onClose}
          title={<Trans {...messages.description}  />}
        >
          {get(q, 'description', false) &&
            <Typography variant="body1">
              {q.description.localization.t(locale, {domain: companyObject.emailDomain})}
            </Typography>}
        </AlertDialog>
      </div>
    )
  }
}

export default (DueDiligenceDescriptionModal)
