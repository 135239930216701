import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {flowRight as compose, get} from 'lodash'
import {frontends} from '@bdswiss/common-enums'
import {getPlatform, getSourceBasedOnLocationPrevPath, logEventCustomParams} from '../../../../common/utils'
import {withCreateDeposit, PaymentActionButton} from './helpers'

class KalixaProvider extends Component {

  state = {}

  doCreateDeposit() {
    const {account, providerProperties: {provider, paymentKey, id: paymentOptionId}, amount, onError, useVirtualPaymentPage, bonusAmount, bonusTerms,
      history: {location}} = this.props

    this.setState({creatingDeposit: true})

    const firebaseParams = {
      source: getSourceBasedOnLocationPrevPath(get(location, 'state', {prevPath: get(location, 'pathname')})),
      login: account.id,
      currency: account.currency,
      vendor: provider,
      amount,
    }
    logEventCustomParams('depositAttempted', firebaseParams)

    const variables = {
      accountId: account.id,
      amount,
      vendor: provider,
      paymentOptionId,
      frontend: frontends.web2.value,
      args: JSON.stringify({
        platform: getPlatform(),
        paymentKey,
        useVirtualPaymentPage,
        bonusAmount,
        bonusTerms,
      }),
    }

    const newTab = window.open('about:blank', '_blank')

    this.props.createDepositRequest({variables})
      .then(({data: {newDeposit}}) => {
        const {payment: {url: paymentUrl}} = newDeposit
        newTab.location = paymentUrl
        this.setState({creatingDeposit: false})
      })
      .catch((e) => {
        newTab && newTab.close()
        this.setState({creatingDeposit: false})
        onError && onError(e)
      })
  }

  render() {
    const {canProceed} = this.props
    const {creatingDeposit} = this.state
    return (
      <PaymentActionButton
        disable={canProceed}
        loading={creatingDeposit}
        onClick={() => this.doCreateDeposit()}
      />
    )
  }
}

KalixaProvider.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  providerProperties: PropTypes.shape({
    name: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    paymentKey: PropTypes.string.isRequired,
    paymentOptionId: PropTypes.number.isRequired,
  }).isRequired,
  amount: PropTypes.number.isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
}

export default compose(
  withCreateDeposit
)(KalixaProvider)
