import {FormControl, InputLabel, ListItemIcon, MenuItem, Select} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import useMediaQuery from '@mui/material/useMediaQuery'
import {get, isEmpty, map, omit} from 'lodash'
import React, {useCallback, useState} from 'react'
import {getAccountLabel, getAccountSubtype, getFormattedAmount} from '../../common/utils'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import messages from '../../assets/messages'
import {Trans} from 'react-i18next'
import {ReactComponent as PromotionsCheck} from '../../assets/images/promotionsCheck.svg'
import {ReactComponent as PromotionsChevron} from '../../assets/images/promotionsChevron.svg'
import {accountTypes} from '@bdswiss/common-enums'
import {serversLabel} from '../../common/utils/uioptions'

const useStyles = makeStyles(theme => ({
  input:{
    [theme.direction === 'rtl' ? 'paddingRight' : 'paddingLeft' ]: '12px',
    top: '6px',
    color: 'rgba(255, 255, 255, 0.6)',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '12px',
    letterSpacing: '0.4px',
    '&.Mui-focused': {
      color: 'rgba(255, 255, 255, 0.6)'
    },
    '&.focusSelection': {
      '&.Mui-focused': {
        color: theme.palette.grey[600],
      },
    },
    [theme.breakpoints.down('sm')]: {
      top: '2px',
    },
  },
  formControl:{
    background: 'rgba(255, 255, 255, 0.2)',
    borderRadius: '8px',
    [theme.direction === 'rtl' ? 'paddingRight' : 'paddingLeft' ]: '8px',
    width: '132px',
    height: '40px',
    [theme.breakpoints.down('sm')]: {
      width: '124px',
      height: '32px',
    },
    zIndex: '2',
  },
  select: {
    bottom: '5px',
    '& .MuiSelect-icon': {
      margin: theme.direction === 'rtl' ? '5px 10px 10px 5px' : '3px 10px 10px 3px',
      '& path': {
        fill: theme.palette.secondary.light,
      }
    },
    '& .MuiSelect-select:focus': {
      background: 'transparent',
    },
    '& .MuiInputBase-input': {
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      color: `${theme.palette.secondary.light} !important`,
      '& .hideAccountLabel': {
        display: 'none',
      },
      '& .hideAccountBalance': {
        display: 'none',
      },
      paddingRight: theme.direction === 'rtl' ? '0px' : 'unset',
    },
    '&.focusSelection': {
      '& .MuiInputBase-input': {
        color:'#000000 !important',
      },
      '& svg': {
        fill: '#000000',
      },
      '& .MuiSelect-icon': {
        '& path': {
          fill: '#000000',
        }
      },
    },
    '& svg': {
      fill: theme.palette.secondary.light,
    },
    [theme.breakpoints.down('sm')]: {
      bottom: '9px',
    },
    '& .hideAccountCheck': {
      display: 'none',
    },
    '& .hideAccountBadge': {
      display: 'none',
    },
  },
  menuItem: {
    gap: '10px',
    '& .hideAccountShortLabel': {
      display: 'none',
    },
    '&:hover': {
      backgroundColor: ({dark}) => dark ? theme.palette.grey[900]: theme.palette.secondary.light,
    },
    '&.Mui-selected': {
      backgroundColor: ({dark}) => dark ? theme.palette.grey[900]: theme.palette.secondary.light,
      '&:hover': {
        backgroundColor: ({dark}) => dark ? theme.palette.grey[900]: theme.palette.secondary.light,
      },
    },
  },
  menuItemWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  accountCheckIcon: {
    justifyContent: 'flex-end',
    '& svg path': {
      fill: theme.palette.secondary.dark,
    },
  },
  selectedFocused: {
    zIndex: '1301',
    background: theme.palette.secondary.light,
    color: 'transparent',
  },
  balance: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '11px',
    lineHeight: '12px',
    color: theme.palette.grey[600],
  },
  accountDetails: {
    display: 'flex',
    gap: '4px',
    justifyContent: 'left',
  },
  accountBadge: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    padding: '6px 6px',
    height: '32px',
    width: '32px',
    textTransform: 'uppercase',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '10px',
    lineHeight: '13px',
    letterSpacing: '0.066px',
    backgroundColor:  theme.palette.lightred.color,
    color: theme.palette.red.color,
  },
  ib: {
    color: theme.palette.green.color,
    backgroundColor:  theme.palette.lightgreen.color,
  },
  basic: {
    color: `${get(theme.palette, 'mainDark.color') || theme.palette.primary.main}`,
    backgroundColor:  `${get(theme.palette, 'main.color') || theme.palette.lightblue.color}`,
  },
  raw: {
    color: theme.palette.cyan.color,
    backgroundColor:  theme.palette.lightcyan.color,
  },
  black: {
    color: theme.palette.purple.color,
    backgroundColor:  theme.palette.lightpurple.color,
  },
  invest_plus: {
    color: theme.palette.harlequin.color,
    backgroundColor:  theme.palette.lightharlequin.color,
  },
  forexMt4: {
    color: theme.palette.red.color,
    backgroundColor:  theme.palette.lightred.color,
  },
  pammForex: {
    color: theme.palette.green.color,
    backgroundColor: theme.palette.lightgreen.color,
  },
  spotOption: {
    color: theme.palette.green.color,
    backgroundColor: theme.palette.lightgreen.color,
  },
  tradeSmarter: {
    color: theme.palette.green.color,
    backgroundColor: theme.palette.lightgreen.color,
  },
  default: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.lightblue.color,
  },
  affiliate: {
    color: theme.palette.yellow.color,
    backgroundColor: theme.palette.lightyellow.color,
  },
  swapFree: {
    color: theme.palette.green.color,
    backgroundColor: theme.palette.lightgreen.color,
  },
  paperStyle: {
    transform: `translateX(${theme.direction === 'rtl' ? 8: -8}px) translateY(-22px) !important`,
    borderRadius: '8px',
    backgroundColor: ({dark}) => dark ? theme.palette.grey[900]: theme.palette.secondary.light,
    [theme.breakpoints.down('sm')]: {
      maxWidth: ({mobileMaxWidth}) => `${mobileMaxWidth}px !important`,
      transform: 'translateY(-22px) !important',
    },
  }
}))

const GeneralAccountSelect = ({t, accounts, locale, fullWidth, id, label, theme, ...otherProps}) => {
  const [isOpen, setIsOpen] = useState(false)
  const classes = useStyles()
  const lowRes = useMediaQuery((theme) => theme.breakpoints.down('lg'))

  const handleSelectOpen = useCallback(() => {
    isOpen ? setIsOpen(false) : setIsOpen(true)
  }, [isOpen])

  const handleSelectClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  return (
    <FormControl
      variant="standard"
      fullWidth={fullWidth}
      className={classNames(classes.formControl, isOpen ? classes.selectedFocused : '')}>
      {label && !isEmpty(accounts) && <InputLabel id={id} className={classNames(classes.input, isOpen ? 'focusSelection' : '')}>
        {`${label}:`}</InputLabel>}
      <Select
        variant="standard"
        labelId={id}
        className={classNames(classes.select, isOpen ? 'focusSelection' : '')}
        open={isOpen}
        onOpen={handleSelectOpen}
        onClose={handleSelectClose}
        MenuProps={{
          anchorOrigin: {
            vertical: 'top',
            horizontal: lowRes ? 'center' : theme.direction === 'rtl' ? 'right':'left',
          },
          transformOrigin: {
            vertical: 'bottom',
            horizontal: lowRes ? 'center' : theme.direction === 'rtl' ? 'right':'left',
          },
          BackdropProps: {invisible: false},
          autoFocus: false,
          classes: {paper:classes.paperStyle}
        }}
        IconComponent={(props) => <PromotionsChevron {...props}/>}
        {...omit(otherProps, ['dark', 'mobileMaxWidth'])}>
        {map(accounts, (a) => {
          const accountSubtype = getAccountSubtype(get(a,'accountSubtype'))
          const accountLabel = getAccountLabel(a, locale, accountSubtype, t)
          const accountShortLabel = get(accountSubtype, 'shortLabel', '')
          const formattedBalance = getFormattedAmount({locale, amount: a.balance, currency: a.currency})
          const hasBalance = a.balance || a.balance >= 0
          const category = get(accountTypes[a.__typename], 'category')
          const serverLabel = serversLabel[category]
          const classesCategory = classes[accountTypes[a.__typename].subCategory] || (!a.isDemo && classes[a.accountSubtype]) || classes[a.category]
          return (<MenuItem value={a.id} key={a.id} className={classes.menuItem}>
            <div className={classNames(classes.accountBadge, classesCategory, 'hideAccountBadge')}> {serverLabel}</div>
            <div className={classes.menuItemWrapper}>
              <div className={classes.accountDetails}>
                {accountLabel && <div className='hideAccountLabel'>{`${accountLabel} ·`}</div>}
                {accountShortLabel && <div className='hideAccountShortLabel'>{`${accountShortLabel}`}</div>}
                <div>{`#${get(a, 'remoteId')}`}</div>
              </div>
              {isOpen && hasBalance && <div className={classNames(classes.balance, 'hideAccountBalance')}>
                <Trans {...messages.balance} />: &nbsp;
                {formattedBalance}
              </div>}
            </div>
            {otherProps.value===String(a.id) && <ListItemIcon className={classNames(classes.accountCheckIcon, 'hideAccountCheck')}><PromotionsCheck /></ListItemIcon>}
          </MenuItem>)
        })}
      </Select>
    </FormControl>
  )
}

GeneralAccountSelect.propTypes = {
  id: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  accounts: PropTypes.arrayOf(PropTypes.shape({
    id:  PropTypes.number.isRequired,
    accountSubtype: PropTypes.string.isRequired,
    remoteId :  PropTypes.string.isRequired,
    balance: PropTypes.number,
    currency: PropTypes.string.isRequired,
    __typename: PropTypes.string.isRequired
  })).isRequired,
  dark: PropTypes.bool.isRequired,
  mobileMaxWidth: PropTypes.number.isRequired,
}

export default (GeneralAccountSelect)
