import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {flowRight as compose} from 'lodash'
import {graphql} from 'react-apollo'
import {depositStatuses} from '@bdswiss/common-enums'
import {Loading} from '../../Common/Loading'
import {CANCEL_PARTIAL_DEPOSIT_MUTATION, COMPLETE_PARTIAL_DEPOSIT_MUTATION} from '../../../graphql/mutations'


const validActions = ['complete', 'cancel']

class DepositAction extends Component {

  componentDidMount() {
    const {match: {params: {action, accountId, depositId}}, history} = this.props

    if (validActions.includes(action)) {
      const depositAction = action === 'complete' ? this.props.completeDeposit : this.props.cancelDeposit
      depositAction({
        variables: {depositId}
      }).then((res) => {
        const resultStatus = res.data.action.status === depositStatuses.completed.key
          ? 'success' : res.data.action.status === depositStatuses.failed.key ? 'failed' : 'pending'
        history.push(`/transactions/${accountId}/deposit/result/${resultStatus}`)
      }).catch((e) => {
        history.push(`/transactions/${accountId}/deposit/result/pending`)
      })
    } else {
      history.push(`/transactions/${accountId}`)
    }
  }

  render() {
    return <Loading />
  }
}

export default compose(
  withRouter,
  graphql(CANCEL_PARTIAL_DEPOSIT_MUTATION, {
    name: 'cancelDeposit',
  }),
  graphql(COMPLETE_PARTIAL_DEPOSIT_MUTATION, {
    name: 'completeDeposit',
  }),
)(DepositAction)
