import React, {Component} from 'react'
import {flowRight as compose, toLower, startCase, get} from 'lodash'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import AppContext from '../Common/contexts/AppContext'
import config from '../../config'
import Images from '../Common/Images'
import {Trans, withNamespaces} from 'react-i18next'
import messages from '../../assets/messages'
import {getCurrentTheme, isDarkTheme} from '../../common/utils'
import {Button} from '@mui/material'
import {Redirect} from 'react-router-dom'
import {withRouter} from 'react-router'
import LivechatBubble from '../Common/LivechatBubble'
import {graphql} from 'react-apollo'
import {CLIENT_DATA_QUERY} from '../../graphql/queries'

const styles = theme => ({
  container: {
    height: '100%',
    textAlign: 'center',
    alignItems: 'center',
    position: 'absolute'
  },
  insideContainer: {
    maxWidth: 550,
    margin: '0 auto',
    '& > .MuiGrid-item': {
      padding: theme.spacing(1),
    },
  },
  img: {
    minWidth: 300
  }
})

class InformativePage extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {}
  }

  click () {
    if (window && window.LC_API) {
      window.LC_API.open_chat_window()
    }
  }

  render() {
    const {classes, t, viewer} = this.props
    const {showInformativePageOnly, logoFileName, logoWhiteFileName, name: companyName} = config
    const themePreference = getCurrentTheme()
    const logoAsset = isDarkTheme(themePreference) ? logoWhiteFileName : logoFileName
    const logo = Images[logoAsset]

    if (!showInformativePageOnly) return <Redirect to={{pathname: '/'}} />

    return <Grid container className={classes.container}>
      {<LivechatBubble fullName={get(viewer, 'fullName')} email={get(viewer,'email')} />}
      <Grid container className={classes.insideContainer}>
        <Grid item xs={12}>
          <img src={logo} alt={companyName} className={classes.minWidth} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body1'><Trans {...messages.informativePage} components={[<br key={'br'}/>]}/></Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={() => this.click()}
            variant="contained"
            size="large"
            color="primary"
          >
            {startCase(toLower(`${t(messages.contactSupport.i18nKey, messages.contactSupport.defaults)}`))}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  }
}

export default compose(
  withRouter,
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading}, data}) => ({
      error,
      loading,
      viewer: get(data, 'viewer')
    })
  }),
)(InformativePage)
