import React, {Component} from 'react'
import {Trans, withNamespaces} from 'react-i18next'
import {get, some, isEmpty, find, flowRight as compose} from 'lodash'
import withStyles from '@mui/styles/withStyles'
import Grid from '@mui/material/Grid'
import {CLIENT_PROFILE_QUERY, CONFIG_QUERY} from '../../graphql/queries'
import {UPDATE_OWN_DETAILS_MUTATION} from '../../graphql/mutations'
import {graphql} from 'react-apollo'
import {Loading} from '../Common/Loading'
import {FullScreenDialog} from '../Common/Dialog'
import PageTitle from '../Common/PageTitle'
import messages from '../../assets/messages'
import {Typography} from '@mui/material'
import TextField from '@mui/material/TextField'
import LoadingButton from '../Common/LoadingButton'
import FormHelperText from '@mui/material/FormHelperText'
import {accountTypes, phoneVerificationTypes} from '@bdswiss/common-enums'
import Images from '../Common/Images'
import {validatePhone} from '../../common/utils/validations'
import CountriesSelect from '../Common/CountriesSelect'
import TextsmsOutlined from '@mui/icons-material/TextsmsOutlined'
import AppContext from '../Common/contexts/AppContext'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import {callerNumber} from '../../common/utils/variables'
import {getCountries} from '../../common/utils/requests'

const styles = theme => ({
  errorMessage:{
    color:  theme.palette.error.main,
    display: 'inline-block',
    verticalAlign: 'bottom',
    margin: '13px 13px 13px 0'
  },
  chip: {
    padding: 10,
    cursor: 'pointer'
  },
  messageIcon:{
    marginRight: 30,
    verticalAlign: 'top',
    [theme.breakpoints.down('md')]: {
      marginRight: 10,
    }
  },
  countriesMenu:{
    'overflow-y': 'auto',
    'overflow-x': 'hidden',
    maxHeight: '50vh',
    width: '100%',
    marginLeft: 0,
    paddingLeft: 0,
    border: '1px solid #e8e8e8',
    listStyleType: 'none'
  },
  countryOptions:{
    padding: 10,
    borderTop: '1px solid #e8e8e8',
    cursor: 'pointer',
    fontSize: 16,
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      padding: '10px 0',
    }
  },
  searchInp:{
    padding: '5px 15px',
    [theme.breakpoints.down('md')]: {
      padding: 5,
    }
  },
  prefixTextField:{
    cursor: 'pointer',
  },
  prefixCountry:{
    cursor: 'pointer',
    marginRight:0,
  },
  flatItem: {
    marginRight: 10,
  },
  successMessage:{
    color: theme.palette.green.color,
    display: 'inline-block',
    verticalAlign: 'bottom',
    margin: '13px 13px 13px 0'
  },
  fontBold:{
    fontWeight: 400
  },
  textCenter:{
    textAlign: 'center'
  },
  textLeft:{
    textAlign: 'left'
  },
  img:{
    width:170,
    height:150
  },
  button:{
    marginTop: -15
  },
  infoText:{
    fontSize: '0.75rem',
    color: theme.palette.grey.color
  }
})

class PhoneVerification extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {
      form: {prefix:'', phone: ''},
      errors: {},
      status: '',
      showPhone: false,
      phoneMessageError: '',
      loading: false,
      loadingPhone: false,
      statusPhone: '',
      showCountries: false,
      prefixFlag: '',
      showCountriesPrefix: false,
    }
  }

  componentDidMount() {
    const {companyObject, accounts} = this.context
    let walletAccount = find(accounts, (a) => get(accountTypes[a.__typename], 'walletProduct'))
    if (walletAccount) walletAccount = find(accountTypes, (accountType) => accountType.key === get(walletAccount, '__typename'))
    this.getCountries(walletAccount && walletAccount.value, companyObject.key)
  }

  handleChange (name, value) {
    this.setState(state => ({
      form: {
        ...state.form,
        [name]: value
      },
      errors: {
        ...state.errors,
        [name]: !value,
      }
    }))
  }

  handleChangePrefix(value) {
    this.setState({showCountriesPrefix: false, prefixFlag: value})
  }

  setStateOuter = (prop, value) => {
    this.setState({[prop]: value})
  }

  searchCountry(id,value) {
    const li = document.getElementById(id).getElementsByTagName('li')
    for (let i = 0; i < li.length; i++) {
      if (li[i].innerHTML.toUpperCase().indexOf(value) > -1) {
        li[i].style.display = ''
      } else {
        li[i].style.display = 'none'
      }
    }
  }

  changePhone() {
    const {updateDetails, checkTime, handleChangeParent, showPhone} = this.props
    const {form, form:{phone, prefix, verificationType}} = this.state
    const variables = {phone: prefix + phone, type: verificationType}
    const errors = {}

    for (const field of Object.keys(form)) {
      if (field === 'phone') {
        errors[field] = isEmpty(form[field]) || !validatePhone(form[field])
      }
      else {
        errors[field] = isEmpty(form[field])
      }
    }

    if (some(errors)) {
      this.setState({errors})
      return
    }

    this.setState({loadingPhone: true})
    updateDetails({variables})
      .then((_res) => {
        this.setState({loadingPhone: true,statusPhone: 'success', disableBarPhone: true})
        checkTime(variables)
        setTimeout(() => handleChangeParent('showPhone', !showPhone), 2000)
      })
      .catch((err) => {
        this.setState({loadingPhone: false, statusPhone: 'failure', phoneMessageError: err.error ? err.error : ''})
      })
  }

  closeDialog() {
    this.context.logout()
  }

  async getCountries(product, company) {
    await getCountries(product, company)
      .then((res) => {
        this.setState({allowedCountries: res.countries})
      })
      .catch((err) => err)
  }

  render() {
    const {classes, loading, t, handleChangeParent, showPhone, submitTime, secondsToTime, disableSend,
      viewer:{phone : clientPhone}, configVariables} = this.props
    if (loading) return <Loading />
    const {form: {prefix, phone, verificationType}, loadingPhone, statusPhone, phoneMessageError, errors,
      disableBarPhone, prefixFlag, allowedCountries} = this.state
    const countryList = allowedCountries || []
    const phoneForce = isEmpty(clientPhone)
    const textToSpeechEnabled = get(configVariables, 'textToSpeechVerificationEnabled')

    return (
      <FullScreenDialog
        desktopOnly
        open
        onClose={() => phoneForce ? this.closeDialog() : handleChangeParent('showPhone',!showPhone)}
      >
        <Grid container spacing={3} className={classes.textCenter} justifyContent="center">
          <PageTitle onBack={() =>  phoneForce ? this.closeDialog() : handleChangeParent('showPhone',!showPhone)}>
            <Trans {...messages.phoneConfirmation} />
          </PageTitle>
          <Grid item xs={12}>
            <img src={Images['phone_verification.png']} alt="phone" className={classes.img}/>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <Trans {...messages.improveAccountProtection} />
            </Typography>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={3}  justifyContent="center">
                <Grid item xs={5} sm={3} id="prefixGrid" className={classes.textLeft}>
                  <CountriesSelect
                    countryList={countryList}
                    handleChangeField={this.handleChangePrefix.bind(this)}
                    handleChange={this.handleChange.bind(this)}
                    setStateOuter={this.setStateOuter.bind(this)}
                    errors={errors}
                    value={prefixFlag}
                    name="prefix"
                    label={t(messages.phoneCode.i18nKey, messages.phoneCode.defaults)}
                    phonePrefix
                  />
                </Grid>
                <Grid item xs={7} sm={5} className={classes.phoneInp}>
                  <TextField
                    variant="standard"
                    fullWidth
                    required
                    id="phone"
                    name="phone"
                    label={t(messages.phone.i18nKey, messages.phone.defaults)}
                    error={errors.phone}
                    onChange={(e) => this.handleChange('phone', e.target.value.replace(/^0+/, ''))}
                    value={phone}
                    inputProps={{pattern: '[0-9]*', inputMode:'numeric'}} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={5}>
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} sm={6} id="sendsms">
                      <FormControl variant="standard">
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="verificationType"
                              value={phoneVerificationTypes.sms.value}
                              checked={verificationType === phoneVerificationTypes.sms.value || !verificationType}
                              onChange={(e) => this.handleChange('verificationType', e.target.value)}
                              color="primary"
                            />}
                          label={<Trans {...messages.resendSms} />}
                        />
                      </FormControl>
                    </Grid>
                    {textToSpeechEnabled && <Grid item xs={12} sm={6} id="call">
                      <FormControl variant="standard">
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="verificationType"
                              value={phoneVerificationTypes.textToSpeech.value}
                              checked={verificationType === phoneVerificationTypes.textToSpeech.value}
                              onChange={(e) => this.handleChange('verificationType', e.target.value)}
                              color="primary"
                            />}
                          label={<Trans {...messages.call} />}
                        />
                      </FormControl>
                    </Grid>}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {verificationType === phoneVerificationTypes.textToSpeech.value && <Grid item xs={12}>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={5} className={classes.infoText}>
                  <Trans {...messages.infoCallText} values={{callerNumber}} />
                </Grid>
              </Grid>
            </Grid>}
            {prefix && phone &&
              <Grid item xs={12}>
                <Typography variant="body1">
                  <Trans {...messages.codeSendTo} values={{phone:`${prefix} ${phone}`}}
                    components={[
                      <span className={classes.fontBold}>phone</span>]}/>
                </Typography>
              </Grid>}

            <Grid item xs={12}>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={6}>
                  <LoadingButton
                    id='loadingButtonPhone'
                    onClick={() => this.changePhone()}
                    disabled={loadingPhone || disableSend}
                    status={statusPhone}
                    hideProgressBar={disableBarPhone || disableSend}
                  >
                    <TextsmsOutlined className={classes.messageIcon}/>
                    {phoneForce ? t(messages.addPhoneNumber.i18nKey, messages.addPhoneNumber.defaults) :
                      t(messages.changePhoneNumber.i18nKey, messages.changePhoneNumber.defaults)}{disableSend && `: ${secondsToTime(submitTime)}`}
                  </LoadingButton>
                  {statusPhone && <Grid item xs={12}>{(statusPhone==='failure')
                    ?<FormHelperText className={classes.errorMessage}>{phoneMessageError}</FormHelperText>
                    :<FormHelperText className={classes.successMessage}>{t(messages.phoneChangedCodeSent.i18nKey, messages.phoneChangedCodeSent.defaults)}</FormHelperText>}
                  </Grid>}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FullScreenDialog>
    )
  }
}

export default compose (
  withStyles(styles),
  withNamespaces(),
  graphql(CLIENT_PROFILE_QUERY, {
    props: ({data: {error, loading}, data}) => {
      const viewer = get(data, 'viewer')
      return {
        error,
        loading,
        viewer:viewer,
      }
    }
  }),
  graphql(CONFIG_QUERY, {
    props: ({data: {error, loading: configLoading}, data}) => ({
      error,
      configLoading,
      configVariables: get(data, 'config'),
    })
  }),
  graphql(UPDATE_OWN_DETAILS_MUTATION, {
    name: 'updateDetails',
    options: {
      refetchQueries: [{query: CLIENT_PROFILE_QUERY}],
    },
    update: cache => {
      cache.writeData({data: {props: []}})
    },
  }),
)(PhoneVerification)
