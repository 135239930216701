import React from 'react'
import {Trans, withNamespaces} from 'react-i18next'
import {graphql, withApollo} from 'react-apollo'
import {currencies} from '@bdswiss/common-enums'
import Button from '@mui/material/Button'
import {get, find, isEmpty, reject, some, includes, flowRight as compose, floor, filter, map, isNil} from 'lodash'
import Grid from '@mui/material/Grid'
import Hidden from '@mui/material/Hidden'
import withStyles from '@mui/styles/withStyles'
import {Loading} from '../../Common/Loading'
import AmountInput from '../../Common/AmountInput'
import messages from '../../../assets/messages'
import {TRANSFER_FUNDS_MUTATION, CREATE_INTERNAL_TRANSFER} from '../../../graphql/mutations'
import {PAYMENTS_ACCOUNTS_QUERY, IB_CLIENTS_QUERY, FIND_CLIENT_QUERY, CLIENT_DATA_QUERY, SPOA_AUTHORIZATION_QUERY,
  RELATED_IB_QUERY} from '../../../graphql/queries'
import {isDemoAccount, isMAMMasterAccount, isAffiliatesAccount, isPAMMFundManagerAccount, isForexBdxAccount,
  isIntroducingBrokerAccount, isEmptySubscriptionAccount, isBitnukAccount, isLdWalletAccount,
  isLdClientWalletAccount, isZuluAccount, getAccountLabel, getAccountSubtype} from '../../../common/utils/accounts'
import AppContext from '../../Common/contexts/AppContext'
import LoadingButton from '../../Common/LoadingButton'
import PageSubTitle from '../../Common/PageSubTitle'
import Images from '../../Common/Images'
import AccountSearchSelect from '../../Common/AccountSearchSelect'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import SearchOutlined from '@mui/icons-material/SearchOutlined'
import FormHelperText from '@mui/material/FormHelperText'
import classNames from 'classnames'
import config from '../../../config'
import {logEventCustomParams} from '../../../common/utils/firebaseEvents'
import {getFormattedAmount, safeParseJSON} from '../../../common/utils'

const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    display: 'flex',
  },
  errors: {
    color: theme.palette.error.main,
    marginLeft: 0,
    paddingLeft: 0,
    listStyle: 'none'
  },
  transferAmount:{
    paddingTop: 30,
    textAlign:'center'
  },
  transferIcon: {
    alignItems:'center',
    display: 'flex',
    justifyContent: 'center',
    transform: `rotate(${theme.direction === 'rtl' ? '180deg' : 0})`,
  },
  root: {
    flexGrow: 1,
  },
  blueAvatar: {
    margin: 10,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.lightblue.color,
    [theme.breakpoints.down('sm')]: {
      margin: '0 10px 0 0',
      height: 35,
      width: 35,
      lineHeight: 37,
    }
  },
  subtitle: {
    color: theme.palette.grey.color,
    fontWeight:300
  },
  tool: {
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      right: 0,
      top: -15
    }
  },
  toolOuter: {
    [theme.breakpoints.down('sm')]: {
      position: 'relative'
    }
  },
  affiliateDetails:{
    marginTop: 15,
    marginBottom: 0,
  },
  searchDiv:{
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
    }
  },
  searchIcon:{
    bottom: 10,
    position: 'absolute',
    cursor: 'pointer'
  },
  error:{
    color: theme.palette.error.main,
    display: 'inline-block',
    verticalAlign: 'bottom',
    margin: '13px 13px 13px 0'
  },
  errText:{
    color: theme.palette.error.main,
  },
  succText:{
    color: theme.palette.green.color,
  },
  clientCheckMsg:{
    textAlign: 'center',
    marginTop: 10
  },
  textBlue:{
    color: theme.palette.primary.main,
  },
  amountButton: {
    backgroundColor: theme.palette.extralightgreyBackground.color,
    textTransform: 'none',
  },
  subTitle: {
    marginTop: 0,
  },
})

class Transfer extends React.Component {
  static contextType = AppContext

  state = {
    transferFrom: '',
    transferFromAccount: '',
    transferFromCurrency: '',
    accountsWithdrawal: [],
    transferTo: '',
    transferToAccount: '',
    transferAmount: null,
    accountsDeposit: [],
    errors: [],
    errorsFields:{},
    errorsFieldsAffiliate:{},
    showNotification: false,
    failureMessage: '',
    submitButtonDisabled: false,
    affiliateClientId: '',
    affiliateAccountId: '',
    affiliateEmail: '',
    affiliateError: '',
    affiliateSuccess:{},
    clientCheckMsg:false
  }

  listItemData = a => ({
    id: a.remoteId,
    key: a.remoteId,
    disabled: false,
    ...a
  })

  validAccountsWithdrawal = accounts =>
    accounts
      .filter(a => !isDemoAccount(a) && !isMAMMasterAccount(a) && !isPAMMFundManagerAccount(a) && !isForexBdxAccount(a) && !isEmptySubscriptionAccount(a) && ((this.props.pathSend && isAffiliatesAccount(a)) || !this.props.pathSend)
        && ((this.props.pathSpoa && !isIntroducingBrokerAccount(a)) || !this.props.pathSpoa) && ((this.props.pathIb && isIntroducingBrokerAccount(a) && !get(a, 'ignoreIbTransfers')) || !this.props.pathIb))
      .map(this.listItemData)
      .map(a => ({...a, disabled: a.balance <= 0}))

  validAccountsDeposit = accounts =>
    accounts
      .filter(a => !isDemoAccount(a) && !isMAMMasterAccount(a) && !isAffiliatesAccount(a) && !isPAMMFundManagerAccount(a) && !isForexBdxAccount(a)
         && !isEmptySubscriptionAccount(a) && ((this.props.pathSpoa && !isBitnukAccount(a)) || !this.props.pathSpoa) && ((this.props.pathIb && isIntroducingBrokerAccount(a)) || !isIntroducingBrokerAccount(a)) && !a.isViewOnly)
      .map(this.listItemData)

  handleClientToChange(prop, value, clients) {
    if (value !== this.state[prop]) {
      const client = find(clients, o => o.id === value)
      this.setState(state => ({
        [prop]: value,
        accountsDeposit: get(client, 'accounts', []),
        transferToAccount: (this.props.pathIb && get(client , 'accountId')) || '',
        errorsFields: {...state.errorsFields,[prop]: !value}
      }))
    }
  }

  handleClientFromChange(prop, value) {
    const clients = !isEmpty(this.props.ibClients) ? this.props.ibClients : this.props.clientsIb
    if (value !== this.state[prop]) {
      const client = find(clients, o => o.id === value)
      this.setState(state => ({
        [prop]: value,
        accountsWithdrawal: get(client, 'accounts', []),
        transferFromAccount: '',
        errorsFields: {...state.errorsFields,[prop]: !value}
      }))
    }
  }

  resetError() {
    this.setState({errors: []})
  }

  handleChange (name, value) {
    this.resetError()
    this.setState(state => ({
      [name]: value,
      errorsFields: {
        ...state.errorsFields,
        [name]: !value,
      }
    }))
  }

  isValid(fromAccount, accountsDeposit) {
    const {locale} = this.context
    const {t, ibClients, clientsIb, match: {params: urlParams}, pathSend, pathSpoa, pathIb, accounts} = this.props
    const {transferAmount, transferFromAccount, transferToAccount, transferFrom, transferTo, affiliateSuccess} = this.state
    const errors = []
    const errorsFields={}
    const selectedAccountId = Number(get(urlParams, 'accountId') || '')
    const forceSelectedAccount =  transferFrom ==='' && selectedAccountId

    if (pathSend)
      this.findClient(true)
    // no transfer accounts
    if (transferFromAccount === '' && transferToAccount === '') {
      errors.push(t(messages.noAccountsSelected.i18nKey, messages.noAccountsSelected.defaults))
    } else {
      // same transfer accounts
      if (transferFromAccount === transferToAccount && !(transferFromAccount === '' && transferToAccount === '')) {
        errors.push(t(messages.noSameTransferAccounts.i18nKey, messages.noSameTransferAccounts.defaults))
      }
    }
    const transferToAccountDetails = find(accounts, {id: transferToAccount})
    const transferFromAccountDetails = find(accounts, {id: transferFromAccount})
    if (isZuluAccount(transferToAccountDetails)) {
      const minDepositMetTranferAmount = get(transferToAccountDetails, 'minimumTransfer')
      const currencyFromConversion = get(safeParseJSON(minDepositMetTranferAmount),transferFromAccountDetails?.currency)
      const minDeposit = isNil(currencyFromConversion) ? get(transferToAccountDetails, 'minimumDeposit') : currencyFromConversion
      const checkCurrency = isNil(currencyFromConversion) ? transferToAccountDetails?.currency : transferFromAccountDetails?.currency
      const label = getAccountLabel(transferToAccountDetails, locale, getAccountSubtype(get(transferToAccountDetails,'accountSubtype')), t)
      const minTransferAmount = getFormattedAmount({amount: minDeposit, currency: checkCurrency, locale})
      if (transferAmount < minDeposit) {
        errorsFields['transferTo'] = true
        errors.push(<Trans {...messages.transferErrorZulu}
          values={{minDeposit: minTransferAmount, zuluAccount: label}}
        />)
      }
    }
    const fields = {transferAmount,transferFromAccount,transferToAccount}
    if (( (!isEmpty(ibClients) && ibClients.length > 0) || (!isEmpty(clientsIb) && clientsIb.length > 0)) && pathSpoa) {
      fields.transferTo = transferTo
    }

    if (pathIb && transferTo === '') {
      errorsFields['transferTo'] = true
    }

    for (const field of Object.keys(fields)) {
      if (field==='transferToAccount' && (!isEmpty(affiliateSuccess))) {
        continue
      }
      if (!(field==='transferFromAccount' && forceSelectedAccount))
        errorsFields[field] = ( !fields[field] || fields[field]===0 )
    }

    if (transferAmount <= 0)
      errorsFields['transferAmount'] = true

    this.setState({errors})

    if (some(errorsFields) || errors.length !== 0) {
      this.setState({errorsFields})
      return
    }
    this.handleSubmit(fromAccount, accountsDeposit)
  }

  handleSubmit(fromAccount, accountsDeposit) {
    const {transferFromAccount, transferToAccount, transferAmount, affiliateSuccess, transferTo} = this.state
    const {history, match: {params: urlParams}, viewer} = this.props
    this.setState({submitButtonDisabled: true})
    const variables = {
      fromAccountId: transferFromAccount || Number(get(urlParams, 'accountId')),
      toAccountId: transferToAccount || affiliateSuccess.accountId || transferTo,
      amount: transferAmount,
    }
    const toType = find(accountsDeposit, (option) => option.id === get(variables, 'toAccountId'))
    const params = {
      from_id: get(variables, 'fromAccountId'),
      from_type: get(fromAccount, '__typename'),
      to_id: get(variables, 'toAccountId'),
      to_type: get(toType, '__typename'),
      amount: get(variables, 'amount'),
      userId: get(viewer, 'id'),
    }
    logEventCustomParams('transferRequest', params)
    if (!isEmpty(affiliateSuccess)) {
      this.props.fundInternalTransferMutaion({variables})
        .then((success) => {
          const params = {
            from_id: get(variables, 'fromAccountId'),
            from_type: get(fromAccount, '__typename'),
            to_id: get(variables, 'toAccountId'),
            to_type: get(toType, '__typename'),
            amount: get(variables, 'amount'),
            userId: get(viewer, 'id'),
          }
          logEventCustomParams('transferRequestSuccess', params)
          this.setState({submitButtonDisabled: false})
          this.context.showNotification({
            type: 'payment',
            status: 'success',
            content: <Trans {...messages.transferSuccess} />,
            buttonMessage: <Trans {...messages.continue} />
          })
        })
        .then(() => history.push('/accounts'))
        .catch((err) => {
          logEventCustomParams('transferRequestError', {
            reason: get( err, 'graphQLErrors[0].message') || err.message,
            ...params
          })
          this.setState({submitButtonDisabled: false})
          this.context.showNotification({
            type: 'payment',
            status: 'failure',
            content: get( err, 'graphQLErrors[0].message') || err.message,
            buttonMessage: <Trans {...messages.cancel} />
          })
        })
    }
    else {
      this.props.fundTransferMutation({variables})
        .then(() => {
          const params = {
            from_id: get(variables, 'fromAccountId'),
            from_type: get(fromAccount, '__typename'),
            to_id: get(variables, 'toAccountId'),
            to_type: get(toType, '__typename'),
            amount: get(variables, 'amount'),
            userId: get(viewer, 'id'),
          }
          logEventCustomParams('transferRequestSuccess', params)
          this.setState({submitButtonDisabled: false})
          this.context.showNotification({
            type: 'payment',
            status: 'success',
            content: <Trans {...messages.transferSuccess} />,
            buttonMessage: <Trans {...messages.continue} />
          })
        })
        .then(() => history.push('/accounts'))
        .catch((err) => {
          logEventCustomParams('transferRequestError', {
            reason: get( err, 'graphQLErrors[0].message') || err.message,
            ...params
          })
          this.setState({submitButtonDisabled: false})
          this.context.showNotification({
            type: 'payment',
            status: 'failure',
            content: get( err, 'graphQLErrors[0].message') || err.message,
            buttonMessage: <Trans {...messages.cancel} />
          })
        })
    }
  }

  handleChangeAccount(prop, value) {
    if (prop === 'transferFromAccount') {
      const accounts = [...this.props.accounts, ...this.state.accountsWithdrawal]
      const transferFromCurrency = find(accounts, ['id', value]).currency
      this.setState(state => ({
        [prop]: value,
        errorsFields: {...state.errorsFields,[prop]: !value},
        transferFromCurrency,
      }))
    } else {
      this.setState(state => ({
        [prop]: value,
        errorsFields: {...state.errorsFields,[prop]: !value}
      }))
    }
  }

  findClient(btn) {
    const {affiliateClientId, affiliateAccountId, affiliateEmail} = this.state
    const form = {affiliateClientId, affiliateAccountId, affiliateEmail}
    const {transferFields: {affiliate: affiliateFields}} = config
    const errorsFieldsAffiliate = {}
    this.setState({clientCheckMsg: btn})
    for (const field of Object.keys(form)) {
      for (const affField of affiliateFields) {
        if (includes(field.toLowerCase(), affField))
          errorsFieldsAffiliate[field] = isEmpty(form[field])
      }
    }
    if (some(errorsFieldsAffiliate)) {
      this.setState({errorsFieldsAffiliate})
      return
    }
    this.props.client.query({
      query:FIND_CLIENT_QUERY,
      variables: {clientId: affiliateClientId,remoteId: affiliateAccountId, email: affiliateEmail,type:'affiliate'},
    }).then((success) => {
      this.setState({affiliateError: '', affiliateSuccess:success.data.findClient, errorsFieldsAffiliate:{}})
    })
      .catch((err) => this.setState({affiliateError:err.message.replace('GraphQL error: ', ''), affiliateSuccess:{}}))
  }

  showAffiliateDetails() {
    const {classes} = this.props
    const {affiliateSuccess} = this.state
    const {transferFields: {affiliate: affiliateFields}} = config

    return <React.Fragment>
      <Typography variant="caption">
        <Trans {...messages.accountDetails}  />
      </Typography>
      <Typography variant="caption" className={classes.succText}>
        {affiliateSuccess['firstName']} {affiliateSuccess['lastName']}
      </Typography>
      {!includes(affiliateFields,'email') && <Typography variant="caption" className={classes.succText}>
        {affiliateSuccess['email']}
      </Typography>}
    </React.Fragment>
  }

  render() {
    const {accountsLoading, ibClientsLoading, clientsIbLoading, ibClients, clientsIb, classes, t, match: {params: urlParams}, pathSpoa, pathSend, pathIb, relatedIbs, relatedIbsLoading} = this.props
    if (accountsLoading || ibClientsLoading || clientsIbLoading || relatedIbsLoading) return <Loading />
    const {transferFrom, transferFromAccount, transferFromCurrency, transferTo, transferToAccount, transferAmount, errors, errorsFields,
      errorsFieldsAffiliate, submitButtonDisabled, affiliateClientId, affiliateAccountId, affiliateEmail, affiliateError, affiliateSuccess, clientCheckMsg} = this.state
    const {transferFields} = config
    const affiliateFields = get(transferFields, 'affiliate','')
    const accountsWithdrawal = !isEmpty(this.state.accountsWithdrawal)
      ? this.validAccountsWithdrawal(this.state.accountsWithdrawal)
      : this.validAccountsWithdrawal(this.props.accounts)
    let accountsDeposit = !isEmpty(this.state.accountsDeposit)
      ? this.validAccountsDeposit(this.state.accountsDeposit)
      : this.validAccountsDeposit(this.props.accounts)

    const selectedAccountId = Number(get(urlParams, 'accountId') || '')
    const forceSelectedAccount =  (transferFrom ==='' && selectedAccountId)
    const forceSelectedAccountCurrency =  get(find(accountsWithdrawal, ['id', forceSelectedAccount]), 'currency') || 'EUR'

    const clientsAccounts = pathIb ? relatedIbs : (!isEmpty(ibClients) ? ibClients : clientsIb)
    const isFromCurrencyCryptoOrVirtual = get(currencies[transferFromCurrency || forceSelectedAccountCurrency], 'isCrypto', false)
      || get(currencies[transferFromCurrency || forceSelectedAccountCurrency], 'isVirtualCurrency', false)
    const bigInputCurrencies = ['ZAR']

    const {themePreference} = this.context

    const fromAccount = find(accountsWithdrawal, (option) => option.id === (transferFromAccount || forceSelectedAccount))

    const checkTransferFromAccount =  find(accountsWithdrawal, ['id', transferFromAccount])

    if (checkTransferFromAccount && (isIntroducingBrokerAccount(checkTransferFromAccount) || isAffiliatesAccount(checkTransferFromAccount))) {
      accountsDeposit = filter(accountsDeposit, (a)=> !isLdWalletAccount(a) && !isLdClientWalletAccount(a))
    }
    const clientsAccountsFiltered = map(clientsAccounts, (client) => ({
      ...client,
      ...(client?.accounts?.length && {accounts: client?.accounts?.filter?.(account => !account?.hidden)})
    }))
    return (
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12} sm={5}>
            <Grid container>
              <Grid item xs={12}>
                <PageSubTitle customClasses={{header: classes.subTitle}}>
                  <Trans {...messages.transferFromAccount} />
                </PageSubTitle>
              </Grid>
              <Grid item xs={12}>
                <AccountSearchSelect
                  accounts={accountsWithdrawal}
                  label={t(messages.transferFromAccount.i18nKey, messages.transferFromAccount.defaults)}
                  handleAccountChange={this.handleChangeAccount.bind(this)}
                  value={transferFromAccount || forceSelectedAccount}
                  name="transferFromAccount"
                  locale={this.context.locale}
                  errors={errorsFields}
                  t={t}
                />
              </Grid>
            </Grid>
          </Grid>

          <Hidden only="xs">
            <Grid item sm={2} className={classes.transferIcon}>
              <img src={Images[`icon-arrow-right-${themePreference}.png`]} alt={t(messages.transferToAccount.i18nKey, messages.transferToAccount.defaults)} />
            </Grid>
          </Hidden>

          <Grid item xs={12} sm={5}>
            {(clientsAccountsFiltered?.length > 0 && (pathSpoa || pathIb)) && <Grid container>
              <Grid item xs={12}>
                <PageSubTitle customClasses={{header: classes.subTitle}}>
                  <Trans {...messages.transferTo} />
                </PageSubTitle>
              </Grid>
              <Grid item xs={12}>
                <AccountSearchSelect
                  clients={clientsAccountsFiltered}
                  label={t(messages.transferTo.i18nKey, messages.transferTo.defaults)}
                  handleAccountChange={(prop, value) => this.handleClientToChange(prop, value, clientsAccountsFiltered)}
                  value={transferTo}
                  name="transferTo"
                  locale={this.context.locale}
                  errors={errorsFields}
                  t={t}
                />
              </Grid>
            </Grid>}
            {!pathSend && !pathIb && <Grid container>
              <Grid item xs={12}>
                <PageSubTitle customClasses={{header: classes.subTitle}}>
                  <Trans {...messages.transferToAccount} />
                </PageSubTitle>
              </Grid>
              <Grid item xs={12}>
                <AccountSearchSelect
                  accounts={accountsDeposit}
                  label={t(messages.transferToAccount.i18nKey, messages.transferToAccount.defaults)}
                  handleAccountChange={this.handleChangeAccount.bind(this)}
                  value={transferToAccount}
                  name="transferToAccount"
                  locale={this.context.locale}
                  errors={errorsFields}
                  t={t}
                />
              </Grid>
            </Grid>}
            {pathSend && <Grid container>
              <Grid item xs={12}>
                <PageSubTitle customClasses={{header: classes.subTitle}}>
                  <Trans {...messages.transferToAccount} />
                </PageSubTitle>
              </Grid>
              {includes(affiliateFields,'clientId') && <Grid item xs={10} sm={5}>
                <TextField
                  variant="standard"
                  required
                  id="affiliateClientId"
                  name="affiliateClientId"
                  label={t(messages.affiliateClientId.i18nKey, messages.affiliateClientId.defaults)}
                  fullWidth
                  autoComplete="affiliateClientId"
                  value={affiliateClientId}
                  error={errorsFieldsAffiliate.affiliateClientId}
                  onChange={(e) => {
                    this.handleChange('affiliateClientId', e.target.value)
                    this.setState({affiliateSuccess:{}})
                  }} />
              </Grid>}
              {includes(affiliateFields,'accountId') && <Grid item xs={10} sm={5}>
                <TextField
                  variant="standard"
                  required
                  id="affiliateAccountId"
                  name="affiliateAccountId"
                  label={t(messages.affiliateAccountId.i18nKey, messages.affiliateAccountId.defaults)}
                  fullWidth
                  autoComplete="affiliateAccountId"
                  value={affiliateAccountId}
                  error={errorsFieldsAffiliate.affiliateAccountId}
                  onChange={(e) => {
                    this.handleChange('affiliateAccountId', e.target.value)
                    this.setState({affiliateSuccess:{}})
                  }} />
              </Grid>}
              {includes(affiliateFields,'email') && <Grid item xs={10}>
                <TextField
                  variant="standard"
                  required
                  id="affiliateEmail"
                  name="affiliateEmail"
                  label={t(messages.email.i18nKey, messages.email.defaults)}
                  fullWidth
                  autoComplete="affiliateEmail"
                  value={affiliateEmail}
                  error={errorsFieldsAffiliate.affiliateEmail}
                  onChange={(e) => {
                    this.handleChange('affiliateEmail', e.target.value)
                    this.setState({affiliateSuccess:{}})
                  }} />
              </Grid>}
              <Grid item xs={2} className={classes.searchDiv}>
                <SearchOutlined
                  className={classNames(classes.searchIcon, (affiliateError)?classes.errText:(!isEmpty(affiliateSuccess))?classes.succText:'')}
                  color="secondary" onClick={() => this.findClient(false)}
                />
              </Grid>
            </Grid>}
          </Grid>
          {(affiliateError || !isEmpty(affiliateSuccess)) && <Grid container spacing={1}>
            <Hidden smDown>
              <Grid item xs={7}></Grid>
            </Hidden>
            <Grid item md={5} xs={12}>
              {affiliateError && <FormHelperText className={classes.error}>{affiliateError}</FormHelperText>}
              {!isEmpty(affiliateSuccess) && this.showAffiliateDetails()}
            </Grid>
          </Grid>}
        </Grid>

        <Grid container justifyContent="center">
          <Grid item sm={6} md={(isFromCurrencyCryptoOrVirtual || includes(bigInputCurrencies, transferFromCurrency)) ? 7 : 4} className={classes.transferAmount}>
            <PageSubTitle customClasses={{header: classes.subTitle}}>
              <Trans {...messages.selectAmount} />
            </PageSubTitle>
            <AmountInput
              jumbo
              onChange={e => this.handleChange('transferAmount', e.target.value)}
              value={transferAmount}
              id="transferAmount"
              name="transferAmount"
              currency={transferFromCurrency || forceSelectedAccountCurrency}
              locale={this.context.locale}
              TextFieldProps={{
                error: errorsFields.transferAmount,
                helperText: errorsFields.transferAmount &&
                t(messages.noZeroTransferAmount.i18nKey, messages.noZeroTransferAmount.defaults)
              }}
            />
            {errors.length > 0 && transferAmount > 0 &&
            <ul className={classes.errors}>
              {errors.map(error => <li key={error}>{error}</li>)}
            </ul>}
            {fromAccount && isBitnukAccount(fromAccount) && <Grid container item xs={12} spacing={3}>
              <Grid item xs={12} md={4}>
                <Button
                  fullWidth
                  variant="contained"
                  className={classes.amountButton}
                  onClick={(e) => this.handleChange('transferAmount', floor(get(fromAccount, 'balance') / 4, 8))}
                >
                  <Typography variant="body2">25%</Typography>
                </Button>
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  fullWidth
                  variant="contained"
                  className={classes.amountButton}
                  onClick={(e) => this.handleChange('transferAmount', floor(get(fromAccount, 'balance') / 2, 8))}
                >
                  <Typography variant="body2">50%</Typography>
                </Button>
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  fullWidth
                  variant="contained"
                  className={classes.amountButton}
                  onClick={(e) => this.handleChange('transferAmount', get(fromAccount, 'balance'))}
                >
                  <Typography variant="body2"><Trans {...messages.max} /></Typography>
                </Button>
              </Grid>
            </Grid>}
            <LoadingButton
              disabled={submitButtonDisabled}
              variant="contained"
              color="primary"
              size="large"
              onClick={() => this.isValid(fromAccount, accountsDeposit)}
            >
              <Trans {...messages.continue} />
            </LoadingButton>
            {clientCheckMsg && !isEmpty(affiliateSuccess) && <Grid item xs={12}>
              <FormHelperText classes={{root: classes.clientCheckMsg}}>
                <Typography variant="caption" className={classes.textBlue}>
                  <Trans {...messages.clientCheckMsg} />
                </Typography>
              </FormHelperText>
            </Grid>}
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default compose(
  withNamespaces(),
  withApollo,
  graphql(TRANSFER_FUNDS_MUTATION, {
    name: 'fundTransferMutation',
    options: {
      refetchQueries: [{query: PAYMENTS_ACCOUNTS_QUERY}],
    }
  }),
  graphql(IB_CLIENTS_QUERY, {
    props: ({data: {error, loading: ibClientsLoading}, data}) => {
      const ibClients = get(data, 'getIbClients', [])
      return {
        error,
        ibClientsLoading,
        ibClients,
      }
    }
  }),
  graphql(RELATED_IB_QUERY, {
    props: ({data: {error, loading: relatedIbsLoading}, data}) => {
      const relatedIbs = get(data, 'getRelatedIbs', [])
      return {
        error,
        relatedIbsLoading,
        relatedIbs,
      }
    }
  }),
  graphql(SPOA_AUTHORIZATION_QUERY, {
    props: ({data: {error, loading: clientsIbLoading}, data}) => ({
      error,
      clientsIbLoading,
      clientsIb: [get(data, 'viewer.spoaRemoteClient')],
    })
  }),
  graphql(PAYMENTS_ACCOUNTS_QUERY, {
    props: ({data: {error, loading: accountsLoading}, data, ownProps: {match:{path}}}) => {
      const accounts = reject(get(data, 'viewer.accounts'), 'isArchived')
      const pathSpoa =  includes(path,'transferSpoa')
      const pathSend =  includes(path,'transferSend')
      const pathIb =  includes(path, 'transferIb')
      return {
        error,
        accountsLoading,
        accounts,
        pathSpoa,
        pathSend,
        pathIb
      }
    }
  }),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading}, data}) => {
      const viewer = get(data, 'viewer')
      return {
        error,
        viewerLoading: loading,
        viewer,
      }
    }
  }),
  graphql(CREATE_INTERNAL_TRANSFER, {
    name: 'fundInternalTransferMutaion',
  }),
  withStyles(styles, {withTheme: true}),
)(Transfer)
