import React, {Component} from 'react'
import {Trans} from 'react-i18next'

import {styled} from '@mui/system'
import {MenuItem, Typography} from '@mui/material'

import messages from '../../assets/messages'
import AppContext from '../Common/contexts/AppContext'

const ListItem = styled(MenuItem)(({theme}) => ({
  display: 'flex',
  flexWrap: 'wrap',
  padding: '10px 24px',
  transition: '0.2s',
  whiteSpace: 'normal',
  '&:hover': {
    cursor: 'pointer',
  },
  [theme.breakpoints.down('md')]: {
    padding: '10px 16px',
  },
  [theme.breakpoints.up('sm')]: {
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? 'rgb(42, 42, 42)' : theme.palette.grey[100],
    },
  },
}))

const ListIcon = styled('div')(({theme}) => ({
  display: 'flex',
  '& > svg': {
    fill: theme.palette.mode === 'light' ? '#000' : '#fff',
  },
}))

const ListItemTitle = styled(Typography)(({theme}) => ({
  fontSize: '14px',
  fontWeight: 400,
  [theme.direction === 'rtl' ? 'paddingRight' : 'paddingLeft']: '14px',
}))

const ListItemSubtitle = styled(Typography)(({theme}) => ({
  width: '100%',
  color: '#858585',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '16px',
  letterSpacing: '0.4px',
  [theme.direction === 'rtl' ? 'paddingRight' : 'paddingLeft']: '34px',
}))

class BentoMenuItem extends Component {
  static contextType = AppContext

  render() {
    const {option, onClick} = this.props
    const {companyObject} = this.context

    return (
      <ListItem onClick={(event) => onClick(option.key, event)}>
        <ListIcon>
          {option.icon}
        </ListIcon>

        <ListItemTitle>
          <Trans {...messages[option.titleKey]} values={{company: companyObject.brandLabel}}/>
        </ListItemTitle>

        <ListItemSubtitle>
          <Trans {...messages[option.subtitleKey]} />
        </ListItemSubtitle>
      </ListItem>
    )
  }
}

export default BentoMenuItem
