import React, {Component} from 'react'
import AppContext from './contexts/AppContext'
import {flowRight as compose, get, isBoolean, isEmpty, map, slice} from 'lodash'
import withStyles from '@mui/styles/withStyles'
import {Accordion, AccordionDetails, AccordionSummary, List, ListItem, ListItemText, Typography} from '@mui/material'
import classNames from 'classnames'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {Trans} from 'react-i18next'
import messages from '../../assets/messages'

const styles = theme => ({
  pointer: {
    cursor: 'pointer',
  },
  accordionFull: {
    display: 'inline-block',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  heading:{
    fontWeight: 400,
  },
  faqContent: {
    '& a' :{
      color: theme.palette.primary.main
    },
    '& em' :{
      fontStyle: 'normal'
    }
  },
  centerLink: {
    textAlign: 'center',
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  itemRoot: {
    border: `1px solid ${theme.palette.lightgrey.color}`,
    borderRadius: 8,
    boxShadow: 'none',
  },
  expandIcon : {
    color: theme.palette.primary.main,
  },
  borderBelowExpanded: {
    borderBottom: `1px solid ${theme.palette.lightgrey.color}`,
    width: '100%',
    margin:'auto',
  },
  itemTitleExpanded: {
    color: theme.palette.primary.main,
  },
  cardRoot: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  expandedTitle: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 8,
  }
})

class AccordionCustom extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
      activeQuestion: false,
      setShow: false,
      listItems: props.viewMore ? !isEmpty(props.items) && slice(props.items, 0, 5)  : props.items
    }
  }

  handleChange(key) {
    this.setState(state => ({
      activeQuestion: key,
      expanded: !state.expanded ? `panel${key}`: false,
    }))
  }

  viewMoreClick() {
    this.setState(state => ({
      setShow: !state.setShow,
      listItems: !state.setShow ? this.props.items : slice(this.props.items, 0, 5),
    }))
  }

  render() {
    const {classes, viewMore} = this.props
    const {expanded, listItems, setShow, activeQuestion} = this.state
    return (<List>
      {map(listItems, (faq, key) => <React.Fragment key={key}>
        <ListItem classes={{root: classNames(classes.pointer, classes.accordionFull)}}>
          <Accordion expanded={expanded === `panel${key}`} onChange={()=> this.handleChange(key)} className={classes.itemRoot} classes={{root: classes.cardRoot}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className={!isBoolean(expanded) && activeQuestion === key ? classes.expandIcon : ''} />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              classes={{root: classes.cardRoot, expanded: classes.expandedTitle, content: classes.cardRoot}}
            >
              <span className={!isBoolean(expanded) && activeQuestion === key ? classes.borderBelowExpanded : ''}>
                <Typography className={classNames(classes.heading, !isBoolean(expanded) && activeQuestion === key ? classes.itemTitleExpanded : '')}>{get(faq, 'faqQuestion')}</Typography>
              </span>
            </AccordionSummary>
            <AccordionDetails classes={{root: classes.cardRoot}}>
              <Typography variant='body1' className={classNames(classes.faqContent)}>
                <span dangerouslySetInnerHTML={{__html: faq.faqAnswer}} />
              </Typography>
            </AccordionDetails>
          </Accordion>
        </ListItem>
      </React.Fragment>
      )}
      {viewMore && !setShow && <ListItem classes={{root: classNames(classes.pointer, classes.centerLink)}}
        onClick={() => this.viewMoreClick()}>
        <ListItemText>
          <Typography variant='subtitle1' className={classes.link}>
            <Trans {...messages.seeAllFaqs} />
          </Typography>
        </ListItemText>
      </ListItem>}
    </List>)
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
)(AccordionCustom)
