import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import Forex from './Forex'
import Tradesmarter from './Tradesmarter'

const routes = ({match}) => (
  <Switch>
    <Route exact path={`${match.path}/forex/:login`} component={Forex} />
    <Route exact path={`${match.path}/options/:login`} component={Tradesmarter} />
    <Route path={`${match.path}/:parentPath/:childPath+`} render={({match}) => <Redirect to={`${match.path}/${match.params.parentPath}`} />} />
  </Switch>
)

export default routes
